import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import _ from 'lodash';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { ActionBar, Page, StField, StLabel, SubmitButton } from 'shared/components';
import { FormikErrors } from 'shared/components/Formik';
import personnelGroup from 'shared/shapes/personnelGroup';

/**
 * Henkilöstöryhmän luomis & muokkaus lomake
 * Nimi on ainoa pakollinen tieto
 */
@connect((state, props) => {
    const id = _.get(props, 'match.params.id', null);
    return {
        personnelGroup: select.personnelGroup.getGroupById(state, parseInt(id, 10)),
        isNew: ! parseInt(id, 10),
        isPosting: _.get(state, 'loading.effects.personnelGroup.postGroups', false),
        isPutting: _.get(state, 'loading.effects.personnelGroup.putGroup', false),
    };
})
export default class PersonnelGroupForm extends Component {

    static propTypes = {
        dispatch: PropTypes.func,
        isNew: PropTypes.bool,
        routes: PropTypes.object,
        personnelGroup,
        isPosting: PropTypes.bool,
        isPutting: PropTypes.bool,
    };

    static defaultProps = {
        dispatch() {},
        isNew: false,
        routes: {},
        personnelGroup: {},
        isPosting: false,
        isPutting: false,
    };

    onSubmit = (model) => {
        const { dispatch, isNew, routes } = this.props;
        if (isNew) {
            dispatch.personnelGroup.postGroups(model, routes.PERSONNEL_GROUPS);
        } else {
            dispatch.personnelGroup.putGroup(_.omit(model, ['id', 'isRemovable']), { group: model.id, route: routes.PERSONNEL_GROUPS });
        }
    };

    renderForm() {
        const { isNew, personnelGroup, isPosting, isPutting } = this.props;
        return (
            <Formik
                onSubmit={this.onSubmit}
                initialValues={isNew ? { name: '', description: '' } : personnelGroup}
                validationSchema={yup.object().shape({
                    name: yup.string().required(_trans('validation.required')),
                })}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="o-form o-form--responsive">
                        <StLabel>
                            {_trans('company_manager.personnel_groups.form.name')}
                        </StLabel>
                        <StField>
                            <Field name="name" type="text" />
                            <FormikErrors name="name" />
                        </StField>
                        <StLabel>
                            {_trans('company_manager.personnel_groups.form.description')}
                        </StLabel>
                        <StField>
                            <Field name="description" type="text" />
                        </StField>
                        <ActionBar>
                            <SubmitButton isPending={isPosting || isPutting}>
                                {_trans('button.save')}
                            </SubmitButton>
                        </ActionBar>
                    </form>
                )}
            </Formik>
        );
    }

    render() {
        const { isNew, routes, } = this.props;
        return (
            <Page
                heading={isNew ? _trans('company_manager.personnel_groups.add_new') : _trans('company_manager.personnel_groups.edit_existing')}
                goBack={{ to: routes.PERSONNEL_GROUPS, text: _trans('link.return'), }}
            >
                {this.renderForm()}
            </Page>
        );
    }
}
