import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ErrorState } from 'shared/components';
import { DataTable } from 'shared/containers/DataTable7';
import { useGet } from 'shared/hooks/useApi';
import { pendingStates } from 'shared/constants/insuranceStates';
import Panel from 'shared/components/Panel';

export const TytalHistoryTable = () => {
    const { id } = useParams();
    const [{ isLoading, error, data }] = useGet(Routing.generate('api_1_get_benefitdecision_tytals_archive', { benefitDecision: id }));

    if (error) {
        return (<ErrorState message={error.message}/>);
    }

    if (! isLoading && ! data) {
        return '';
    }
    return (
        <Panel heading={_trans('Työtapaturmavakuutukset', {}, 'extract')}>
            <p>{_trans(`Alla olevassa taulukossa esitetään nykyisen avustuspäätöksen ja työnantajan perusteella saatavat historiatiedot työtapaturmavakuutuksista.
                Oiman hallinnoima tarkoittaa, että vakuutus kuuluu Oima-palvelun hallinnoiman työtapaturmavakuutuksen piiriin.
                 Mikäli haluat muuttaa vakuutusta ole yhteydessä asiakaspalveluun.`, {}, 'insurance')}</p>

            <DataTable
                data={data?.data ?? []}
                isResizable
                isFilterable={false}
                isLoading={isLoading}
                emptyDataMessage={_trans('Historiaa ei löytynyt', {}, 'extract')}
                columns={[
                    {
                        'Header': _trans('Yhtiö', {}, 'extract'),
                        'minWidth': 140,
                        'accessor': 'insurance.institution.shortName',
                    },
                    {
                        'Header': _trans('Vakuutusnumero', {}, 'extract'),
                        'minWidth': 140,
                        'accessor': ({ insurance }) => pendingStates.includes(insurance.pohjolaApiState) ? _trans('Odottaa numeroa', {}, 'insurance') : insurance.number,
                    },
                    {
                        'Header': _trans('Tyyppi', {}, 'extract'),
                        'minWidth': 140,
                        'accessor': 'insurance.typeAsString',
                    },
                    {
                        'Header': _trans('Oiman hallinnoima', {}, 'extract'),
                        'minWidth': 140,
                        'accessor': ({ insurance }) => insurance.isHandledBySuoratyo ? _trans('Kyllä', {}, 'extract') : _trans('Ei', {}, 'extract')
                    },
                    {
                        'Header': _trans('Alkupäivä', {}, 'extract'),
                        'minWidth': 140,
                        'accessor': 'startDate',
                        'type': 'date'
                    },
                    {
                        'Header': _trans('Päättymispäivä', {}, 'extract'),
                        'minWidth': 160,
                        'accessor': 'expireDate',
                        'type': 'date',
                        'defaultValue': _trans('Toistaiseksi', {}, 'extract')
                    }
                ]}
            />
        </Panel>
    );
};

TytalHistoryTable.propTypes = {
    insurance: PropTypes.object,
};

TytalHistoryTable.defaultProps = {
    insurance: {},
};

export default TytalHistoryTable;
