import api from 'api';

/**
 * Avustuspäätös.
 */
export const benefitDecision = {
    state: {},

    reducers: {
        setBenefitDecision: (state, benefitDecision) => ({ ...benefitDecision }),
    },

    selectors: {
        getBenefitDecision: (state) => state?.decision ?? {},
        getEmployer: (state) => state?.decision?.employer ?? {},
        getEmployerPayslipDeliveryMethod: (state) => state.decision?.employerPayslipDeliveryMethod ?? null,
        getBeneficiary: (state) => state?.decision?.beneficiary ?? {},
        isCommuneControlled: (state) => state?.decision?.communeControlled ?? false,
        isSelfEmployed: (state) => state?.decision?.beneficiaryIsEmployer ?? false,
        isBeneficiaryIdentified: (state) => state?.decision?.isBeneficiaryIdentified ?? false,
    },

    effects: (dispatch) => ({
        async fetchBenefitDecisionForEmployer(benefitDecisionId) {
            try {
                const response = await api.get(`/api/contracts/v1/benefits/${benefitDecisionId}`);
                this.setBenefitDecision(response);
                return response;
            } catch (e) {
                console.log(e);
                dispatch.notifications.addError('Avustuspäätöstä ei voitu hakea.', {}, 'jobContract');
            }
        },
        async fetchBenefitDecision(benefitDecisionId){
            try {
                const response = await api.get(`/commune/api/v1/benefitdecisions/${benefitDecisionId}`);
                this.setBenefitDecision(response);
                return response;
            } catch (e) {
                console.log(e);
                dispatch.notifications.addError('Avustuspäätöstä ei voitu hakea.', {}, 'jobContract');
            }
        }
    }),
};
