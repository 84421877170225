import { getBlockProps } from 'shared/ReForm/utils/getBlockProps';
import { blocks as blockTypes } from 'shared/ReForm/constants/blocks';
import { inputTypes } from 'shared/ReForm/constants/inputTypes';
import { arrayBlockNames, arrayBlocks } from 'ContractV3/constants/arrayBlocks';

/**
 * Vetää json pompsista iteroiden nimen ja mahdollisen oletusarvon.
 * @param json
 * @param store
 * @param initialValues
 */
export const generateInitialValues = (json, store, initialValues = {}) => json.reduce((acc, item) => {
    const { type, block } = item;
    const { name, blocks, defaultValue } = getBlockProps(item);

    // We must go deeper...
    if (Array.isArray(blocks)) {
        // Tärkeä! Ilman acc:n passaamista tässä collectiveAgreementVariablesiin tulee vain yksi arvo mikäli annetaan pohjaan yksittäin.
        const values = generateInitialValues(blocks, store, acc);
        // Dynaaminen taulukko joten tämän alta tulevat name:t muodossa rootName.childName (esim. salaries.amount).
        if (block === blockTypes.ARRAY) {
            // Ei lisätä kustannustenkorvauksia jos kunta
            const hiddenFieldValues = (store?.isCommune && name === arrayBlockNames.salaries)
                ? {}
                : arrayBlocks[name]?.hiddenFieldValues ?? {};
            return Object.assign(
                {},
                acc,
                // Jos array blockilta löytyy piilotettuja kenttiä (kts. arrayBlocks.js ja hiddenFields.js), lisätään myös ne jatkoksi.
                // Syy tälle se ettei tarvitse erikseen tehdä custom komponentteja yksistään piilossa olevia kenttiä varten
                // ja lisäksi se ettei näitä tarvitse käsin muistaa lisätä vaan ne tulevat matkaan automaattisesti.
                { [name]: [Object.assign({}, generateInitialValues(blocks, store), hiddenFieldValues)] }
            );
        }
        return {
            ...acc,
            ...values,
        };
    }

    // Jos oletusarvo on funktio syötetään sille annetun storen sisältö jonka perusteella resolvoidaan komponentin
    // oletusarvo.
    const value = typeof defaultValue === 'function'
        ? defaultValue(store)
        : defaultValue;

    if (! name) return acc;

    // Objektinotaatio
    const match = /^[A-Za-z0-9]+(\.)[A-Za-z0-9_]+$/.test(name);
    if (match) {
        // Objektinotaatio => dimension.209. Parsitaan "päänimi" ja sen id (loppuosa).
        const namePieces = name.split('.');
        const rootName = namePieces[0];
        const key = namePieces[1];
        const doesExist = acc[rootName] ?? false;

        // Löytyykö initialValuesista ko. nimi? Jos ei luodaa sen niminen array.
        // Jos löytyy lisätään array jatkoksi.
        return {
            ...acc,
            [rootName]: doesExist
                ? { ...acc[rootName], [key]: value }
                : { [key]: value }
        };
    }

    // Jos checkbox, kaavitaan alkuarvot optionsien sisältä ellei name:a löydy päätasolta
    if (type === inputTypes.CHECKBOX && !name) {
        const options = item.options.reduce((acc, option) => ({
            ...acc,
            [option.name]: defaultValue || false,
        }), {});

        return {
            ...acc,
            ...options,
        };
    }

    // Muutoin normaalisti key: value parina.
    return {
        ...acc,
        [name]: value
    };
}, initialValues);
