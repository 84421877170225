import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { format } from 'date-fns';
import Page from 'shared/components/Page';
import { DataTable } from 'shared/containers/DataTable7';
import { TabContainer, TabList, TabItem, TabPanel } from 'shared/components/Tab';
import { Button, Feedback, IconButton, LinkList } from 'shared/components';
import BaseForm from 'shared/UserDetails/containers/shared/EmployeeWorkExperience/BaseForm';
import contractSelectors from 'Contract/contractSelectors';
import { editType } from 'shared/constants/workExperienceTypes';
import alertTypes from 'shared/constants/alertTypes';
import ExperienceCell from 'shared/UserDetails/components/ExperienceCell';
import { generateRoutes } from 'ContractV3/constants/routes';
import { isCommune } from 'shared/utils/commonUtils';
import resolveUrl from 'shared/utils/resolveUrl';

const routes = generateRoutes(isCommune());

const EmployeeWorkExperience = ({
    userId,
    hasEditRole,
    jobContractId,
    isEmployee,
    isEmployer,
}) => {
    const dispatch = useDispatch();
    const employeeWorkExperience = useSelector(select.employeeWorkExperience.getEmployeeWorkExperience);
    const isEditForm = useSelector(select.employeeWorkExperience.isEditForm);
    const isLoading = useSelector((state) => (
        state.loading.effects.employeeWorkExperience.fetchEmployeeWorkExperience
        || state.loading.effects.employeeWorkExperience.fetchEmployeeWorkExperienceByContract
    ));
    const contractJobTitleId = useSelector(select.contract
        ? select.contract.getJobTitleId
        : contractSelectors.getJobTitle
    );

    const contractJobTitleName = useSelector(select.contract
        ? select.contract.getJobTitleName
        : contractSelectors.getJobTitleName
    );
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isAddForm = useSelector(select.employeeWorkExperience.isAddForm);
    const changedContracts = useSelector(select.employeeWorkExperience.getChangedContracts);
    
    const selectableCollectiveAgreements = useSelector(select.employeeWorkExperience.getSelectableCollectiveAgreements);
            
        
    useEffect(() => {
        if (jobContractId) {
            dispatch.employeeWorkExperience.fetchEmployeeWorkExperienceByContract(userId, jobContractId);
        } else {
            dispatch.employeeWorkExperience.fetchEmployeeWorkExperience(userId);
        }
    }, [userId, jobContractId]);

    const renderWorkExperienceRows = (history) => (
        <DataTable
            isLoading={isLoading}
            canExport
            exportFileName={'kokemuskuukaudet_tt_' + userId}
            data={_.orderBy(history, ['dateCreated'], ['desc'])}
            columns={[
                {
                    Header: _trans('users.section.work_experience.title'),
                    accessor: 'workExperienceMonths',
                    sortable: false,
                    filterable: false,
                    /* eslint-disable-next-line */
                    Cell: ({ value, row: { original: { workExperienceYears } } }) => (
                        <ExperienceCell
                            years={workExperienceYears}
                            totalMonths={value}
                        />
                    )
                },
                {
                    Header: _trans('users.section.work_experience.type'),
                    accessor: (row) => row.type === 1
                        ? _trans('users.section.work_experience.user_set')
                        : _trans('users.section.work_experience.generated'),
                    sortable: false,
                    filterable: false,
                },
                {
                    id: 'targetDate',
                    Header: _trans('users.section.work_experience.target_date'),
                    accessor: (row) => row.targetDate ? format(new Date(row.targetDate), 'MM/yyyy') : '',
                    defaultValue: '',
                    sortable: false,
                    filterable: false,
                },
                {
                    Header: _trans('users.section.work_experience.creation_date'),
                    accessor: 'dateCreated',
                    type: 'date',
                    defaultValue: '',
                    sortable: false,
                    filterable: false,
                },
                {
                    Header: _trans('users.section.work_experience.description'),
                    accessor: 'description',
                    sortable: false,
                    filterable: false,
                },
            ]}
            emptyDataMessage={_trans('users.section.work_experience.empty')}
            emptyDataCallToAction={hasEditRole && (
                <Button
                    primary
                    mdIcon="add"
                    onClick={() => {
                        dispatch.employeeWorkExperience.setIsAddForm(true);
                        dispatch.employeeWorkExperience.setEditType(editType.NEW);
                    }}
                >
                    {_trans('users.section.work_experience.add')}
                </Button>
            )}
        />
    );

    const renderTabs = () => {
        if (employeeWorkExperience.length === 0) {
            return renderWorkExperienceRows([]);
        }

        const [tabItems, tabPanels] = employeeWorkExperience
            .reduce((acc, data, index) => {
                const [accTabItems, accTabPanels] = acc;
                const workExpHistory = data.history ?? [];
                const targetCollectiveAgreementName = data.targetCollectiveAgreementName ?? null;

                const tabItem = (
                    <TabItem
                        key={'tab-' + index}
                        tabId={'tab_' + index}
                    >
                        <div>
                            <div>{targetCollectiveAgreementName ? `${data.subtitle} / ${targetCollectiveAgreementName}` : data.subtitle}</div>
                        </div>
                    </TabItem>
                );
                const tabPanel = (
                    <TabPanel tabId={'tab_' + index} key={'tab-panel' + index}>
                        {renderWorkExperienceRows(workExpHistory)}
                    </TabPanel>
                );

                return [
                    [...accTabItems, tabItem],
                    [...accTabPanels, tabPanel],
                ];
            }, [[], []]);

        return (
            <TabContainer>
                <TabList>
                    {tabItems}
                </TabList>
                {tabPanels}
            </TabContainer>
        );
    };

    const renderActionItems = () => {
        const isJobContractExperienceNull = jobContractId && _.isEmpty(_.filter(employeeWorkExperience, ['id', contractJobTitleId]));

        if (_.isEmpty(employeeWorkExperience) || isJobContractExperienceNull || isEditForm || isAddForm) return null;

        // Ei näytetä vielä kuntapuolelle lisää uusi -nappia, koska ei ole kuin yksi työnimike
        return (
            <div>
                <IconButton
                    mdIcon="edit"
                    onClick={() => dispatch.employeeWorkExperience.setIsEditForm(true)}
                >
                    {_trans('button.edit')}
                </IconButton>
                {(! jobContractId && ! isCommune) && (
                    <IconButton
                        primary={! isCommune}
                        mdIcon="add"
                        modifierClass="u-margin-right-tiny"
                        onClick={() => {
                            dispatch.employeeWorkExperience.setIsAddForm(true);
                            dispatch.employeeWorkExperience.setEditType(editType.NEW);
                        }}
                    >
                        {_trans('button.add_new')}
                    </IconButton>
                )}
            </div>
        );
    };

    const renderChangedContractsFeedback = () => {
        const links = changedContracts.map(({ contractId, contractDataId }) => ({
            name: contractId,
            route: resolveUrl(routes.SALARY, { contractId, contractDataId }),
        }));

        return (
            <Feedback
                hasIcon
                isDismissable
                onFeedbackClose={() => dispatch.employeeWorkExperience.setChangedContracts([])}
                type={alertTypes.WARNING}
            >
                {_trans('users.section.work_experience.salary_change_info')}
                <LinkList links={links} target="_blank"/>
            </Feedback>

        );
    };

    // Näytetään otsikossa työnimikkeen nimi, jos ollaan työntekijän tiedoissa
    // ja on vain yhdelle työnimikkeelle kokemusta, ollaan sopimuksella
    // tai ollaan lisäämässä uuttaa kokemusta työnimikkeelle
    const heading = (_.isEmpty(employeeWorkExperience) || employeeWorkExperience.length > 1 || isAddForm) && ! jobContractId
        ? _trans('users.section.work_experience.employee_title')
        : _trans('users.section.work_experience.employee_title_with_subtitle', {
            subtitle: contractJobTitleName ? contractJobTitleName : _.get(_.head(employeeWorkExperience), 'subtitle')
        });
    // TT:lle näytetään tällä hetkellä vain sopparilla työkokemus, niin annataan oma otsikko
    const employeeAndEmployerHeading = _trans('users.section.work_experience.title_with_subtitle', { subtitle: contractJobTitleName });

    return (
        <Page
            heading={isEmployee || isEmployer ? employeeAndEmployerHeading : heading}
            actionItems={hasEditRole && renderActionItems()}
        >
            {changedContracts.length > 0 && renderChangedContractsFeedback()}
            {(isEditForm || isAddForm) && (
                <BaseForm 
                    userId={userId} 
                    jobContractId={jobContractId} 
                    selectableCollectiveAgreements={selectableCollectiveAgreements} 
                />
            )}
            {(! isEditForm && ! isAddForm) && renderTabs()}
        </Page>
    );
};

EmployeeWorkExperience.propTypes = {
    userId: PropTypes.number,
    jobContractId: PropTypes.number,
    hasEditRole: PropTypes.bool,
    isEmployee: PropTypes.bool,
    isEmployer: PropTypes.bool,
};

EmployeeWorkExperience.defaultProps = {
    userId: -1,
    jobContractId: null,
    hasEditRole: false,
    isEmployee: false,
    isEmployer: false,
};

export default EmployeeWorkExperience;

