import React from 'react';
import PropTypes from 'prop-types';
import {
    AccordionItem as AccessibleAccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from 'react-accessible-accordion';
import MDIcon from 'shared/components/MDIcon';
import IconText from 'shared/components/IconText';

/**
 * Renderöi accordionin yhden itemin ja sen sisällön.
 * Itemissä voi käyttää mitä tahansa muita komponentteja.
 */
export const AccordionItem = ({ children, icon, title, customTitle, uuid }) => {
    /**
     * Renderöi joko pelkän titlen tai jos icon-prop on annettu myös ikonin.
     */
    const renderTitle = () => {
        if (customTitle !== null) {
            return (
                <span className="c-accordion__item-title">
                    {customTitle}
                </span>
            );
        }

        if (icon !== null) {
            return <IconText mdIcon={icon} text={title}/>;
        }

        return (
            <span className="c-accordion__item-title u-text-truncate">
                {title}
            </span>
        );
    };

    return (
        <AccessibleAccordionItem className="c-accordion__item" uuid={uuid?.toString()}>
            <AccordionItemHeading>
                <AccordionItemButton className="c-accordion__title o-stack o-stack--justify">
                    {renderTitle()}
                    <MDIcon icon="expand_more" size="small" modifierClass="c-accordion__chevron"/>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="c-accordion__body">
                {children}
            </AccordionItemPanel>
        </AccessibleAccordionItem>
    );
};

AccordionItem.propTypes = {
    /**
     * Otsikko.
     */
    title: PropTypes.string,

    /**
     * Content.
     */
    children: PropTypes.node.isRequired,

    /**
     * Accordion item icon.
     */
    icon: PropTypes.string,

    /**
     * Customzied icon. Can be a React node. This overrides title.
     */
    customTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),

    /**
     * Universal unique id. Can be used with preExpanded on Accordion component.
     */
    uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AccordionItem.defaultProps = {
    title: '',
    icon: null,
    customTitle: null,
    uuid: null,
};
