
/**
 * Estää lomapalkan, -rahan & -korvauksen generoinnin eli vähentää lomapäiviä ja lomarahapäiviä saldolta
 */
export const SUB_TYPE_OMIT_ALL = 1;

/**
 * Estää lomarahan generoinnin eli vähentää lomarahapäiviä
 */
export const SUB_TYPE_OMIT_HOLIDAY_BONUS = 2;

export const subTypes = [
    {
        value: SUB_TYPE_OMIT_ALL,
        content: _trans('time_registration.additional_data.bypass_holiday_days.form_name_sub_type_omit_all'),
    },
    {
        value: SUB_TYPE_OMIT_HOLIDAY_BONUS,
        content: _trans('time_registration.additional_data.bypass_holiday_days.form_name_sub_type_omit_holiday_bonus'),
    }
];
