import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/Button';

/**
 * Oletusfiltteri taulukon sarakkeille.
 * @param filterValue
 * @param setFilter
 * @param filterable
 * @param placeholder
 * @returns {*}
 * @constructor
 */
export const DefaultColumnFilter = ({ column: { filterValue, setFilter, disableFilter, Header, placeholder } }) => {
    const inputElement = useRef(null);
    if (disableFilter) {
        return null;
    }
    return (
        <div className="u-position-relative u-1/1">
            <input
                type="text"
                ref={inputElement}
                aria-label={Header}
                value={filterValue || ''}
                onChange={(event) => {
                    setFilter(event.target.value || undefined); // Undefined poistaa filtteröinnin kokonaan
                }}
                placeholder={placeholder}
                className="u-1/1"
            />
            {filterValue && filterValue !== '' && (
                <Button
                    flat
                    mdIcon="clear"
                    modifierClass="o-pin o-pin--right o-pin--top"
                    title={_trans('Tyhjennä kenttä.', {}, 'common')}
                    onClick={() => {
                        setFilter(undefined);
                        // Tekstin tyhjennyksen jälkeen takaisin filtterikenttään
                        inputElement.current.focus();
                    }}
                />
            )}
        </div>
    );
};

DefaultColumnFilter.propTypes = {
    column: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
};

DefaultColumnFilter.defaultProps = {
    placeholder: '',
};
