import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { DataTable } from 'shared/containers/DataTable7';

export const PublicAttachments = ({ name, attachments }) => {
    const [field,,helpers] = useField(name);
    const selectedAttachments = (field.value ?? []);

    // Esivalitaan rivit
    const selectedRows = selectedAttachments
        .reduce((acc, cur) => {
            const index = attachments.findIndex((attachment) => cur.id === attachment.id);
            if (index === -1) {
                return acc;
            }
            return { ...acc, [index]: true };
        }, {});
    const count = selectedAttachments.length;

    return (
        <div className="u-margin-bottom-small" id={name}>
            <DataTable
                isSelectable
                defaultSelectedRows={selectedRows}
                isFilterable={attachments.length >= 10}
                onSelect={(value) => {
                    if (value.length !== count) {
                        helpers.setValue(value);
                    }
                }}
                defaultSortBy={[
                    {
                        id: 'created',
                        desc: true
                    }
                ]}
                columns={[
                    {
                        Header: _trans('Liitetiedoston nimi', {}, 'common'),
                        accessor: 'description',
                        // eslint-disable-next-line react/prop-types
                        Cell: ({ value, row: { original } }) => (
                            <a
                                // eslint-disable-next-line react/prop-types
                                href={`/api/v2/attachments/${original.id}/download`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {value}
                            </a>
                        ),
                    },
                    {
                        Header: _trans('Lisätty', {}, 'common'),
                        accessor: 'created',
                        type: 'dateTime',
                        // Pitäis ehkä laitella type: 'dateTime':lle oma sortType automaagisesti
                        // Ilman tätä uudet liitteet eivät mene oikeille paikoilleen listalla.
                        sortType: useMemo(() => (rowA, rowB, columnId) => {
                            const a = new Date(rowA.values[columnId]).getTime();
                            const b = new Date(rowB.values[columnId]).getTime();
                            return a > b ? 1 : -1;
                        }, [])
                    }
                ]}
                data={attachments}
            />
        </div>
    );
};

PublicAttachments.propTypes = {
    name: PropTypes.string.isRequired,
    attachments: PropTypes.array.isRequired,
};
