import userRoles from 'shared/constants/userRoles';

/**
 * Generoi linkin roolin tai roolin ja yrityksen id:n perusteella.
 * @param userRole
 * @param target
 * @returns {(function(*): *)|(function(*): *)|null|(function(): *)}
 */
export const getRouteResolverByRole = (userRole, target) => {
    switch (userRole) {
        case userRoles.EMPLOYER:
        case userRoles.EMPLOYEE:
        case userRoles.FAMILY_CARER:
        case userRoles.BOOKKEEP:
            return () => Routing.generate('suoratyo_home', { role: userRole, target });

        case userRoles.COMPANY_APPROVER:
            return (item) => Routing.generate('suoratyo_dimension_approval', { role: userRole, activeCompany: item.companyId });

        case userRoles.COMPANY_OPERATOR:
            return (item) => Routing.generate('suoratyo_home', { role: userRole, target, activeCompany: item.companyId ?? null });

        case userRoles.COMMUNE_OPERATOR:
            return (item) => Routing.generate('suoratyo_home', { role: userRole, target, activeCommune: item.id });

        default:
            return null;
    }
};
