import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PopupButton } from '@typeform/embed-react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import Button from 'shared/components/Button';
import ActionBar from 'shared/components/ActionBar';
import { materialActionTypes } from 'shared/constants/materials';
import { translateUserRole } from 'shared/constants/userRoles';

const THUMB_UP = 1;
const THUMB_DOWN = -1;

/**
 * Palautteen keruu: peukut ja palaute.
 * @param materialId
 * @param materialName
 * @param analyticsCategory
 * @param typeFormId
 * @returns {JSX.Element}
 * @constructor
 */
export const MaterialFeedback = ({ materialId, materialName, analyticsCategory, typeFormId }) => {
    const dispatch = useDispatch();
    const userRole = useSelector(select.userMetadata.getUserRole);
    const [hasClicked, setHasClicked] = useState(false);
    const analytics = useSelector((state) => select.materialAnalytics.getAnalyticsByMaterialId(state, materialId));
    const thumbAnalytic = analytics.find((data) => data.actionType === materialActionTypes.THUMBS);
    const thumbValue = parseInt(thumbAnalytic?.actionValue ?? '0', 10);
    // Jos jo "äänestetty", näytetään mitä käyttäjä äänesti jottei äänestä moneen kertaan
    const [thumbDirection, setThumbDirection] = useState(thumbValue);

    return (
        <div aria-describedby={materialId}>
            <span className="u-color-grey-800 u-hide u-show@medium">{_trans('Oliko tästä hyötyä?', {}, 'analytics')}</span>
            <ActionBar alignItems="left" modifierClass="u-display-inline-block u-margin-left-small@medium">
                <Button
                    flat
                    modifierClass={classNames('c-thumbs', {
                        'c-thumbs--inactive': thumbDirection === THUMB_DOWN,
                        'c-thumbs--up': thumbDirection === THUMB_UP,
                        'c-thumbs--animate': hasClicked,
                        'c-thumbs--instant': ! hasClicked,
                    })}
                    title={thumbDirection === THUMB_UP
                        ? _trans('Olet peukuttanut ylös: tästä materiaalista oli hyötyä.', {}, 'analytics')
                        : _trans('Peukku ylös: tästä materiaalista oli hyötyä.', {}, 'analytics')}
                    mdIcon="thumb_up"
                    isIconFilled={thumbDirection === THUMB_UP}
                    onClick={() => {
                        setHasClicked(true);
                        setThumbDirection(THUMB_UP);
                        // Jos äänestetty jo päivitetään vain äänestystä
                        if (thumbAnalytic) {
                            dispatch.materialAnalytics.putAction(Object.assign({}, thumbAnalytic, {
                                actionValue: THUMB_UP
                            }));
                        } else {
                            dispatch.materialAnalytics.postAction({
                                materialId,
                                materialName,
                                actionType: materialActionTypes.THUMBS,
                                actionValue: THUMB_UP,
                                analyticsCategory,
                            });
                        }

                    }}
                />
                <Button
                    flat
                    modifierClass={classNames('c-thumbs', {
                        'c-thumbs--inactive': thumbDirection === THUMB_UP,
                        'c-thumbs--down': thumbDirection === THUMB_DOWN,
                        'c-thumbs--animate': hasClicked,
                        'c-thumbs--instant': ! hasClicked,
                    })}
                    title={thumbDirection === THUMB_DOWN
                        ? _trans('Olet peukuttanut alas: tästä materiaalista ei ollut hyötyä.', {}, 'analytics')
                        : _trans('Peukku alas: tästä materiaalista ei ollut hyötyä.', {}, 'analytics')}
                    mdIcon="thumb_down"
                    isIconFilled={thumbDirection === THUMB_DOWN}
                    onClick={() => {
                        setHasClicked(true);
                        setThumbDirection(THUMB_DOWN);
                        // Jos äänestetty jo päivitetään vain äänestystä
                        if (thumbAnalytic) {
                            console.log(thumbAnalytic);
                            dispatch.materialAnalytics.putAction(Object.assign({}, thumbAnalytic, {
                                actionValue: THUMB_DOWN
                            }));
                        } else {
                            dispatch.materialAnalytics.postAction({
                                materialId,
                                materialName,
                                actionType: materialActionTypes.THUMBS,
                                actionValue: THUMB_DOWN,
                                analyticsCategory,
                            });
                        }
                    }}
                />
                <PopupButton
                    id={typeFormId}
                    style={{ padding: 12 }}
                    hidden={{
                        role: translateUserRole(userRole),
                        material_name: materialName,
                        material_id: materialId,
                    }}
                    size={40}
                    autoClose={1500}
                >
                    <span className="u-text-underline">{_trans('Kirjoita palautetta', {}, 'common')}</span>
                </PopupButton>
            </ActionBar>
        </div>
    );
};

MaterialFeedback.propTypes = {
    materialId: PropTypes.string.isRequired,
    materialName: PropTypes.string.isRequired,
    analyticsCategory: PropTypes.string.isRequired,
    typeFormId: PropTypes.string.isRequired,
};
