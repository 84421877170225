const ALLOWANCE_TYPE_WEEKLY = 1;

const ALLOWANCE_TYPE_MONTHLY = 2;

const ALLOWANCE_TYPE_CUSTOM = 3;

// ekat 7 bittiä on päiviä ja viimenen on otetaanko mukaan arkipyhät
const dayOptions = [
    {
        id: 0b11111000,
        name: _trans('Ma - Pe (ei arkipyhiä)', {}, 'benefit_decision_new')
    },
    {
        id: 0b11111001,
        name: _trans('Ma - Pe', {}, 'benefit_decision_new')
    },
    {
        id: 0b00000100,
        name: _trans('Lauantaisin', {}, 'benefit_decision_new')
    },
    {
        id: 0b00000010,
        name: _trans('Sunnuntaisin', {}, 'benefit_decision_new')
    },
    {
        id: 0b00000110,
        name: _trans('Viikonloppuisin', {}, 'benefit_decision_new')
    },
    {
        id: 0b11111111, // arkipyhät kans
        name: _trans('Minä päivänä tahansa', {}, 'benefit_decision_new')
    },
];

const timeOptions = [
    {
        id: 0b100,
        name: _trans('Normaali työn aikaväli', {}, 'benefit_decision_new')
    },
    {
        id: 0b010,
        name: _trans('Iltatyön aikaväli', {}, 'benefit_decision_new')
    },
    {
        id: 0b001,
        name: _trans('Yötyön aikaväli', {}, 'benefit_decision_new')
    },
    {
        id: 0b110,
        name: _trans('Normaali työn tai iltatyön aikaväli', {}, 'benefit_decision_new')
    },
    {
        id: 0b101,
        name: _trans('Normaali työn tai yötyön aikaväli', {}, 'benefit_decision_new')
    },
    {
        id: 0b011,
        name: _trans('Iltatyön tai yötyön aikaväli', {}, 'benefit_decision_new')
    },
    {
        id: 0b111,
        name: _trans('mihin aikaan tahansa', {}, 'benefit_decision_new')
    },
];

const durationOptions = [
    {
        id: 0,
        name: _trans('viikossa', {}, 'benefit_decision_new')
    },
    {
        id: 1,
        name: _trans('päivässä', {}, 'benefit_decision_new')
    },
];

const durationOptionsMonth = [
    {
        id: 0,
        name: _trans('kuukaudessa', {}, 'benefit_decision_new')
    },
    {
        id: 1,
        name: _trans('päivässä', {}, 'benefit_decision_new')
    },
];

const durationOptionsCustom = [
    {
        id: 0,
        name: _trans('yhteensä', {}, 'common')
    },
    {
        id: 1,
        name: _trans('päivässä', {}, 'common')
    },
];

const actionOptions = [
    {
        id: 0,
        name: _trans('Lisät maksetaan', {}, 'benefit_decision_new')
    },
    {
        id: 1,
        name: _trans('Lisiä ei makseta', {}, 'benefit_decision_new')
    },
];

function resolveOptionType(allowanceType) {
    let options;
    allowanceType = parseInt(allowanceType);
    switch (allowanceType){
        case ALLOWANCE_TYPE_WEEKLY:
            options = durationOptions;
            break;
        case ALLOWANCE_TYPE_MONTHLY:
            options = durationOptionsMonth;
            break;
        case ALLOWANCE_TYPE_CUSTOM:
            options = durationOptionsCustom;
            break;
        default:
            options = durationOptions;
    }
    return options;
}

export {
    dayOptions,
    timeOptions,
    durationOptions,
    actionOptions,
    resolveOptionType
};
