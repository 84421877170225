import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from 'shared/components/Logo';

/**
 * Yläpalkin logo linkillä joka vie päänäkymään.
 * @param isCommune
 * @param hasBackArrow
 * @returns {JSX.Element}
 * @constructor
 */
export const PageHeaderLogo = ({ isCommune, hasBackArrow }) => (
    <a href={isCommune ? '/commune/home' : '/home'} className="c-logo u-padding-horizontal-small" style={{ height: 'inherit' }}>
        {hasBackArrow && (
            <span style={{ height: 36 }} className="u-display-inline-block">
                <i className="material-icons-outlined u-color-default" style={{ marginTop: 16 }}>
                    arrow_back
                </i>
            </span>
        )}
        <Logo size={36} style={{ marginTop: 8, marginLeft: 6 }} />
    </a>
);

PageHeaderLogo.propTypes = {
    isCommune: PropTypes.bool,
    hasBackArrow: PropTypes.bool,
};

PageHeaderLogo.defaultProps = {
    isCommune: false,
    hasBackArrow: false,
};
