import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { CheckboxField, HiddenField } from 'shared/components/Formik';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import { FormField } from 'shared/components/Formik';

/**
 * Vapaidenkerrytyscheckbox.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const HolidayGeneration = (props) => {
    const { label, ...rest } = getFieldProps(props);
    const [field] = useField(rest.name);

    const isHidden = props.attributes?.isHidden ?? false;
    if (isHidden) {
        return <HiddenField name={rest.name} value={field.value} />;
    }

    return (
        <FormField {...rest} isContentFormField={false}>
            <CheckboxField name={rest.name} label={label} />
        </FormField>
    );
};

HolidayGeneration.propTypes = {
    name: PropTypes.string.isRequired,
    attributes: PropTypes.object.isRequired,
};

export default HolidayGeneration;
