import { produce } from 'immer';
import api from 'api';

/**
 * Päivittää yläpalkissa olevan notifikaatiobadgen custom-eventillä.
 * @param count
 */
const updateSurchargeNotification = (count) => {
    const event = new CustomEvent('surchargeNotification', { detail: { count: count > -1 ? count : 0 } });
    document.dispatchEvent(event);
};

export default {
    state: {
        /**
         * Lisätyt lisämaksut.
         */
        list: [],
        /**
         * Vahvistetut sisäisesti laskutettavat lisämaksut
         */
        handledInternalList: [],
        /**
         * Vahvistetut ulkoisesti laskutettavat lisämaksut
         */
        handledExternalList: [],
        /**
         * Muokattavan lisämaksun id.
         */
        id: null,
        /**
         * Onko ulkoiset palvelumaksut käytössä.
         */
        hasExternalServiceCharges: false,

        isDialogOpen: false,

        pricingList: [],
        offlinePaymentId: null,
        /**
         * Onko vahvistetuissa maksuissa valittuna ulkoiset laskut
         */
        isExternalTypeSelected: null,
        /**
         * Maksuerien / laskutusjaksojen listat
         */
        offlinePayments: [],
        salesInvoices: [],
        /**
         * Valittu maksuerä / laskutusjakso
         */
        selectedOfflinePayment: null,
        selectedSalesInvoice: null,
        /**
         * Vahvistettujen lisämaksujen kokonaismäärä
         */
        handledExternalTotalCount: 0,
        handledInternalTotalCount: 0,
    },

    reducers: {
        setSurcharges: (state, list) => ({ ...state, list }),
        setHandledInternalSurcharges: (state, handledInternalList) => ({ ...state, handledInternalList }),
        setHandledExternalSurcharges: (state, handledExternalList) => ({ ...state, handledExternalList }),
        setOfflinePaymentId: (state, offlinePaymentId) => ({ ...state, offlinePaymentId }),
        setHasExternalServiceCharges: (state, hasExternalServiceCharges) => ({ ...state, hasExternalServiceCharges }),
        setSurchargePricingList: (state, pricingList) => ({ ...state, pricingList }),
        setEditableSurcharge: (state, id) => ({ ...state, id }),
        addSurcharge: (state, surcharge) => produce(state, (draftState) => {
            draftState.list.unshift(surcharge);
        }),
        modifySurcharge: (state, surcharge) => produce(state, (draftState) => {
            const id = surcharge.id;
            const index = state.list.findIndex((surcharge) => surcharge.id === id);
            if (index > -1) {
                draftState.list[index] = surcharge;
            }
        }),
        removeSurcharge: (state, index) => produce(state, (draftState) => {
            draftState.list.splice(index, 1);
        }),
        removeHandledSurcharge: (state, index, isExternal) => produce(state, (draftState) => {
            if (isExternal) {
                draftState.handledExternalList.splice(index, 1);
                --draftState.handledExternalTotalCount;

                const invoiceIndex = state.salesInvoices.findIndex((invoice) => invoice.data.id === state.selectedSalesInvoice.data.id);
                if (invoiceIndex > -1) {
                    draftState.salesInvoices[invoiceIndex].count = parseInt(draftState.salesInvoices[invoiceIndex].count) - 1;
                }
            } else {
                draftState.handledInternalList.splice(index, 1);
                --draftState.handledInternalTotalCount;

                const offlineIndex = state.offlinePayments.findIndex((invoice) => invoice.data.id === state.selectedOfflinePayment.data.id);
                if (offlineIndex > -1) {
                    draftState.offlinePayments[offlineIndex].count = parseInt(draftState.offlinePayments[offlineIndex].count) - 1;
                }
            }
        }),
        setIsDialogOpen: (state, isDialogOpen) => ({ ...state, isDialogOpen }),
        setIsExternalTypeSelected: (state, isExternalTypeSelected) => ({ ...state, isExternalTypeSelected }),
        setOfflinePayments: (state, offlinePayments) => ({ ...state, offlinePayments }),
        setSalesInvoices: (state, salesInvoices) => ({ ...state, salesInvoices }),
        setSelectedOfflinePayment: (state, selectedOfflinePayment) => ({ ...state, selectedOfflinePayment }),
        setSelectedSalesInvoice: (state, selectedSalesInvoice) => ({ ...state, selectedSalesInvoice }),
        setHandledExternalTotalCount: (state, handledExternalTotalCount) => ({ ...state, handledExternalTotalCount }),
        setHandledInternalTotalCount: (state, handledInternalTotalCount) => ({ ...state, handledInternalTotalCount }),
    },
    selectors: {
        getSurcharges: (state) => state.list,
        getNonHandledInternalSurcharges: (state) => state.list.filter((surcharge) => ! surcharge.isExternal),
        getHandledInternalSurcharges: (state) => state.handledInternalList,
        getHandledExternalSurcharges: (state) => state.handledExternalList,
        /**
         * Yhteensä-summa.
         * @param state
         */
        getTotal: (state) => state.list.reduce(
            (total, surcharge) => total + (surcharge.unitCount * surcharge.unitPrice), 0
        ),
        getHandledExternalTotal: (state) => state.handledExternalList.reduce(
            (total, surcharge) => total + (surcharge.unitCount * surcharge.unitPrice), 0
        ),
        getHandledInternalTotal: (state) => state.handledInternalList.reduce(
            (total, surcharge) => total + (surcharge.unitCount * surcharge.unitPrice), 0
        ),
        getSurchargePricingList: (state) => state.pricingList,
        /**
         * Hakee muokkaustilassa olevan lisämaksun.
         * @param state
         * @param surchargeList
         * @returns {*}
         */
        getEditableSurcharge: (state, surchargeList) => {
            const surcharge = state.list.find((surcharge) => surcharge.id === state.id);
            if (!state.id || !surcharge) {
                return false;
            }
            const { id, name, unitPrice, unitCount, description, isExternal, invoiceDimension, invoiceRowDimension } = surcharge;

            // Etsitään alkup. pricingItem surchargen attribuutista kaivamalla
            const pricingItemId = surcharge.attributes?.pricing_item_id;
            const pricingItem = surchargeList.find((surcharge) => surcharge.validPricingItem?.pricingItemId === pricingItemId);
            if (!pricingItem) {
                return false;
            }

            return {
                id,
                name,
                standardPricingId: pricingItem?.standardPricingId,
                unitPrice: _numberFormat(unitPrice),
                unitCount: _numberFormat(unitCount),
                description,
                isExternal,
                state: surcharge.state,
                invoiceDimension: invoiceDimension ?? '',
                invoiceRowDimension: invoiceRowDimension ?? '',
            };
        },
        getEditableSurchargeId: (state) => state.id,
        /**
         * Hakee alkuperäisen hintatiedon, jotta voidaan palata takaisin alkuperäiseen halutesssaan.
         * @param state
         * @param standardPricingId
         * @returns {{minServiceFee: string}|null}
         */
        getOriginalUnitPrice: (state, standardPricingId) => {
            const originalSurcharge = state.pricingList.find((surcharge) => surcharge.standardPricingId === standardPricingId);
            return originalSurcharge ? originalSurcharge.validPricingItem.minServiceFee : null;
        },
        isDialogOpen: (state) => state.isDialogOpen,
        getOfflinePaymentId: (state) => state.offlinePaymentId,
        hasExternalServiceCharges: (state) => state.hasExternalServiceCharges,
        isExternalTypeSelected: (state) => state.isExternalTypeSelected,
        getOfflinePayments: (state) => state.offlinePayments,
        getSalesInvoices: (state) => state.salesInvoices,
        getSelectedOfflinePayment: (state) => state.selectedOfflinePayment,
        getSelectedSalesInvoice: (state) => state.selectedSalesInvoice,
        getHandledExternalTotalCount: (state) => state.handledExternalTotalCount,
        getHandledInternalTotalCount: (state) => state.handledInternalTotalCount,
    },
    effects: (dispatch) => ({
        async fetchSurchargePricingList() {
            try {
                const json = await api.get('/api/v1/surcharges/pricing-list');
                if (json.status === 'ok') {
                    this.setSurchargePricingList(json.data);
                }
            } catch (e) {
                dispatch.notifications.addError('Lisämaksuhinnastoa ei voitu ladata.');
            }
        },

        /**
         * Hakee vahvistamattomat lisämaksut
         * @returns {Promise<void>}
         */
        async fetchSurcharges() {
            try {
                const json = await api.get(`/api/v1/surcharges?state=state_open`);
                if (json.status === 'ok') {
                    this.setSurcharges(json.data);
                    updateSurchargeNotification((json.data ?? []).length);
                    this.setOfflinePaymentId(json.meta?.offlinePaymentId ?? null);
                    this.setHasExternalServiceCharges(json.meta?.hasExternalServiceCharges ?? false);
                    this.setIsExternalTypeSelected(json.meta?.hasExternalServiceCharges ?? false);
                }
            } catch (e) {
                dispatch.notifications.addError('Lisämaksuja ei voitu ladata.');
            }
        },

        /**
         * Hakee vahvistetut sisäisesti laskutettavat lisämaksut
         * @returns {Promise<void>}
         */
        async fetchHandledInternalSurcharges(offlinePaymentId) {
            try {
                const json = offlinePaymentId
                    ? await api.get(`/api/v1/surcharges/handled/internal?id=${offlinePaymentId}`)
                    : await api.get(`/api/v1/surcharges/handled/internal`);
                if (json.status === 'ok') {
                    this.setHandledInternalSurcharges(json.data);
                    this.setHandledInternalTotalCount(json.meta.totalCount);
                    if (! offlinePaymentId) {
                        this.setOfflinePayments(json.meta.offlinePayments);
                        this.setSelectedOfflinePayment({
                            ...json.meta.currentOfflinePayment,
                            label: json.meta?.currentOfflinePayment?.data?.defaultPayday ?? '',
                        });
                    }
                }
            } catch (e) {
                dispatch.notifications.addError('Vahvistettuja sisäisesti laskutettavia lisämaksuja ei voitu ladata');
            }
        },

        /**
         * Hakee vahvistetut ulkoisesti laskutettavat lisämaksut
         * @returns {Promise<void>}
         */
        async fetchHandledExternalSurcharges(salesInvoiceId) {
            try {
                const json = salesInvoiceId
                    ? await api.get(`/api/v1/surcharges/handled/external?id=${salesInvoiceId}`)
                    : await api.get(`/api/v1/surcharges/handled/external`);
                if (json.status === 'ok') {
                    this.setHandledExternalSurcharges(json.data);
                    this.setHandledExternalTotalCount(json.meta.totalCount);

                    if (! salesInvoiceId) {
                        const current = json.meta.currentSalesInvoice;
                        this.setSalesInvoices(json.meta.salesInvoices);

                        if (current.data) {
                            this.setSelectedSalesInvoice({
                                ...current,
                                label: `${current.data.dimension ?? ''} ${_toLocaleDate(current.data.deliveryStartDate)} - ${_toLocaleDate(current.data.deliveryEndDate)}`,
                            });
                        }
                    }
                }
            } catch (e) {
                dispatch.notifications.addError('Vahvistettuja ulkoisesti laskutettavia lisämaksuja ei voitu ladata');
            }
        },


        /**
         * Lisää uuden lisämaksun tai muokkaa sitä.
         * @param pricingItem
         * @param values
         * @param rootState
         * @returns {Promise<boolean>}
         */
        async saveSurcharge({ pricingItem, values }, rootState) {
            try {
                const { id, isExternal, unitPrice, unitCount, ...rest } = values;

                const payload = {
                    unitPrice: _numberParser(unitPrice),
                    unitCount: _numberParser(unitCount),
                    pricingItem,
                    isExternal,
                    ...rest,
                };

                const isNew = !id;
                const json = isNew
                    ? await api.post('/api/v1/surcharges', payload)
                    : await api.put(`/api/v1/surcharges/${id}`, payload);

                if (json.status === 'ok') {
                    const { unitPrice, unitCount, ...rest } = json.data;
                    const surcharge = {
                        unitPrice: _numberParser(unitPrice.toString()),
                        unitCount: _numberParser(unitCount.toString()),
                        ...rest,
                    };

                    if (isNew) {
                        // Päivitetään lukumäärä
                        const surcharges = rootState.surcharges?.list ?? [];
                        updateSurchargeNotification(surcharges.length + 1);

                        this.addSurcharge(surcharge);
                        dispatch.notifications.addSuccess('Lisämaksu lisätty.');
                    } else {
                        this.modifySurcharge(surcharge);
                        dispatch.notifications.addSuccess('Lisämaksun muutokset tallennettu.');
                    }
                } else {
                    dispatch.notifications.addError('Lisämaksua ei voitu lisätä.');
                }
            } catch (e) {
                console.log(e);
            }
        },

        /**
         * Poistaa avoimen lisämaksun.
         * @param surchargeId
         * @param index
         * @param rootState
         * @returns {Promise<void>}
         */
        async deleteSurcharge({ surchargeId, index }, rootState) {
            try {
                const json = await api.del(`/api/v1/surcharges/${surchargeId}`);
                if (json.status === 'ok') {
                    const surcharges = rootState.surcharges?.list ?? [];
                    updateSurchargeNotification(surcharges.length - 1);

                    dispatch.notifications.addSuccess('Lisämaksu poistettu.');
                    this.removeSurcharge(index);
                }
            } catch (e) {
                dispatch.notifications.addError('Lisämaksua ei voitu poistaa.');
            }
        },

        async deleteHandledSurcharge({ surchargeId, index, isExternal }) {
            try {
                const json = await api.del(`/api/v1/surcharges/handled/${surchargeId}`);
                console.log(json);
                if (json.status === 'ok') {
                    dispatch.notifications.addSuccess('Lisämaksu poistettu');
                    this.removeHandledSurcharge(index, isExternal);
                }
            } catch (e) {
                console.log(e);
                dispatch.notifications.addError('Lisämaksua ei voitu poistaa.');
            }
        },

        /**
         * Käsittelee avoimet lisämaksut.
         * @returns {Promise<void>}
         */
        async handleSurcharges() {
            try {
                const json = await api.post(`/api/v1/surcharges/handle`);
                if (json.status === 'ok') {
                    dispatch.notifications.addSuccess('Lisämaksut käsitelty.');
                    await this.fetchSurcharges();
                    await this.fetchHandledInternalSurcharges();
                    await this.fetchHandledExternalSurcharges();
                }
            } catch (e) {
                console.log(e);
                dispatch.notifications.addError('Lisämaksuja ei voitu käsitellä.');
            }
        },
    }),
};
