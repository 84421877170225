/**
 * makeOkResponsen ja makeErrorResponsen palauttamat typet.
 * Lisää tähän muutkin tarpeelliset. Muista kategorisointi kommenteilla.
 */
export default {
    // User.php
    /**
     * Käyttäjä on alaikäinen (monininaisiin asioihin)
     */
    ERROR_UNDERAGE: 'user_underage',

    /**
     * Käyttäjän tunnistautuminen ei ole ok
     */
    ERROR_NOT_IDENTIFIED: 'user_not_identified',

    /**
     * Käyttäjä ei ole suorittanut kaikkia tarpeellisia alustavia toimenpiteitä (kuten tupas tai vakuutukset)
     */
    ERROR_WIZARD_NOT_COMPLETE: 'user_wizard_not_complete',

    /**
     * Käyttäjää ei löytynyt, eli mitä todennäköisimmin hän ei ole rekisteröitynyt
     */
    ERROR_USER_NOT_FOUND: 'user_not_found',

    /**
     * Käyttäjä on jo lisätty yrityksen käyttäjäksi.
     */
    ERROR_USER_HAS_EXISTING_COMPANY_RELATION: 'user_has_existing_company_relation',

    /**
     * Käyttäjä on kutsuttu palveluun sähköpostitse
     */
    USER_INVITED: 'user_invited',
};

