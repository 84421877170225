/**
 * Sivunvaihtopalikka. Käytössä ainoastaan tulosteissa.
 */
import { blocks } from 'shared/ReForm/constants/blocks';

export const pageBreakAfterSchema = {
    type: 'object',
    title: 'Sivunvaihto tulosteessa',
    properties: {
        block: { const: blocks.PAGE_BREAK_AFTER },
        condition: {
            title: 'Sivunvaihtotapa',
            enum: ['auto' ,'all', 'always', 'avoid', 'avoid-column', 'avoid-page', 'avoid-region', 'column', 'left', 'page', 'recto', 'region', 'right', 'verso', 'initial', 'inherit']
        },
    },
    required: ['block'],
    additionalProperties: false,
};
