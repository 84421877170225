import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { componentSizes } from 'shared/constants';

function CirclePlaceholder({ color, size, isAutoSized }) {
    let style = {};

    if (typeof size === 'number' && !isAutoSized) {
        style = {
            width: size,
            height: size,
        };
    }

    if (color !== null) {
        style.color = color;
    }

    return (
        <div
            className={classNames('o-placeholder o-placeholder--circle o-ratio', {
                'o-placeholder--tiny': size === componentSizes.TINY && !isAutoSized,
                'o-placeholder--small': size === componentSizes.SMALL && !isAutoSized,
                'o-placeholder--large': size === componentSizes.LARGE && !isAutoSized,
                'o-pin o-pin--corners': isAutoSized,
            })}
            style={style}
        >
            <div className="o-placeholder__item u-shape-round o-ratio__content" />
        </div>
    );
}

CirclePlaceholder.defaultProps = {
    color: null,
    size: componentSizes.MEDIUM,
    isAutoSized: false,
};

CirclePlaceholder.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.oneOf([..._.map(componentSizes), '']), PropTypes.number]),
    isAutoSized: PropTypes.bool,
};

export default CirclePlaceholder;
