import React, { Component, Fragment } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import moment from 'moment';
import BroadcastChannel from 'broadcast-channel';
import classNames from 'classnames';
import api from 'api';
// Jostain ihme syystä app.js ei tykännyt, että komponentit importtattiin index.js.
import Dialog from 'shared/components/Dialog';
import Button from 'shared/components/Button';
import svgIconTypes from 'shared/constants/svgIconTypes';
import MDIcon from 'shared/components/MDIcon';
import EmptyState from 'shared/components/EmptyState';
import Collapse from 'shared/components/Collapse/Collapse';
import commonUtils from 'shared/utils/commonUtils';
import ProgressBar from 'shared/components/ProgressBar';

// Sen elementin id johon dialogi kiinnitetään
const DIALOG_ROOT_ID = 'logout-dialog';

// Channelin nimi
export const BROADCAST_CHANNEL = 'logoutDialog';

// 15 minuuttia (30min - 15min = 15min)
const REMAINING_TIMER = 15 * 60 * 1000;

// Ne elementit jotka blurrataan kun dialogi on auki.
const elements = [
    document.getElementById('root'),
    document.getElementById('top-header'),
];

export default class LogoutDialog extends Component {
    state = {
        isSessionExpired: false,
        timeLeft: moment.duration(),
        isUpdatingSession: false,
        broadcastChannel: new BroadcastChannel(BROADCAST_CHANNEL),
        isLoginPending: false,
        loginError: false,
        failedLoginCount: 0,
        _username: '',
        _password: '',
        isVismaConnectUser: false,
    };

    componentDidMount() {
        const { broadcastChannel } = this.state;
        this.loginTimeout = setTimeout(() => this.setState({ isSessionExpired: true }), REMAINING_TIMER);
        this.setState({ timeLeft: moment.duration(REMAINING_TIMER, 'milliseconds') });
        this.countdownInterval = setInterval(this.resolveCountdown, 1000);

        broadcastChannel.postMessage('active');
        broadcastChannel.addEventListener('message', this.onBroadcastMessage);

        // Blurrataan taustaelementit
        elements.map((element) => {
            if (element) {
                commonUtils.addClass(element, 'u-blur');
            }
        });

        this.fetchSelfUser();
    }

    componentWillUnmount() {
        const { broadcastChannel } = this.state;
        clearTimeout(this.loginTimeout);
        clearInterval(this.countdownInterval);
        broadcastChannel.removeEventListener('message', this.onBroadcastMessage);
    }

    async fetchSelfUser(){
        const resp = await fetch('/api/v2/user/self', {
            credentials: 'same-origin',
        });
        const json = await resp.json();
        this.setState({
            isVismaConnectUser: json.isVismaConnectUser,
            _username: json.username,
        });
    }

    async postLogin() {
        this.setState({ isLoginPending: true, loginError: false, failedLoginCount: 0 });
        let { failedLoginCount } = this.state;
        const formData = new FormData();
        formData.append('_username', this.state._username);
        formData.append('_password', this.state._password);
        try {
            const resp = await fetch('/login_check?reAuthDialog=1', {
                method: 'POST',
                body: formData,
                credentials: 'same-origin',
            });
            if (resp.ok) {
                this.setState({ isLoginPending: false, loginError: false, failedLoginCount: 0 });
                this.reloadSession();
                return true;
            }

            this.setState({ isLoginPending: false, loginError: true, failedLoginCount: ++failedLoginCount });
            return false;
        } catch (e) {
            this.setState({ isLoginPending: false, loginError: true, failedLoginCount: ++failedLoginCount });
            return false;
        }
    }

    /**
     * Tutkii millainen viesti saatiin.
     * message - ajastin päälle.
     * active - kertoo PageHeaderille että focus on siirrettävä tähän.
     * @param {string} message
     */
    onBroadcastMessage = (message) => {
        if (message === 'message') {
            this.clearDialog();
            addLogoutTimer();
        }
    }

    resolveCountdown = () => {
        if (this.state.isSessionExpired) {
            clearInterval(this.countdownInterval);
        } else {
            const timeLeft = this.state.timeLeft.subtract(1, 'seconds');
            this.setState({ timeLeft });
        }
    };

    /**
     * Päivittää session ellei se ole ehtinyt jo erääntyä.
     */
    reloadSession = () => {
        const { broadcastChannel } = this.state;
        clearInterval(this.countdownInterval);

        this.setState({
            isUpdatingSession: true,
        });

        api.get('/api/v2/ping')
            .then(
                () => broadcastChannel.postMessage('message').then(() => this.clearDialog()),
                () => this.setState({ loginError: true })
            );
    };

    clearDialog() {
        clearTimeout(this.loginTimeout);

        // Otetaan blurri pois taustaelementeiltä
        elements.map((element) => {
            if (element) {
                commonUtils.removeClass(element, 'u-blur');
            }
        });

        unmountComponentAtNode(document.getElementById(DIALOG_ROOT_ID));
    }

    renderTimeLeft() {
        const { timeLeft } = this.state;
        const seconds = timeLeft.seconds();
        const minutes = timeLeft.minutes();
        return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;

    }

    renderDialogContent() {
        const { isSessionExpired, isLoginPending, loginError, _username, _password, isVismaConnectUser } = this.state;

        if (isSessionExpired) {
            if (isVismaConnectUser) {
                return (
                    <article className="c-login">
                        <p>
                            {_trans('logout_dialog.is_expired.message')}
                        </p>
                        <Button
                            target="_blank"
                            href={`/login/refresh-session?username=${_username}`}
                            size="large"
                            modifierClass="u-text-center"
                        >
                            {_trans('Päivitä käyttäjäistunto', {}, 'common')}
                        </Button>
                    </article>
                );
            }

            return (
                <article className="c-login">
                    <p>
                        {_trans('logout_dialog.is_expired.message')}
                    </p>
                    {loginError && (
                        <span
                            className="c-feedback o-flag o-flag--small c-feedback--error u-padding-small u-margin-bottom-small"
                            role="alert"
                            aria-live="assertive"
                        >
                            <span className="c-feedback__icon o-flag__img">
                                <MDIcon icon="error" isFilled />
                            </span>
                            {_trans('Väärä tunnus/salasana', {}, 'common')}
                        </span>
                    )}
                    <form className="o-form o-form--vertical c-login__form" spellCheck={false}>
                        <span className="o-form__label-container u-align-middle">
                            <label
                                aria-required
                                htmlFor="_username"
                                className="is-required"
                            >
                                {_trans('Sähköposti', {}, 'common')}
                            </label>
                        </span>
                        <div className="o-form__field">
                            <input readOnly type="email" id="_username" value={_username} onChange={(ev) => this.setState({ _username: ev.target.value })} className="u-1/1" />
                        </div>
                        <span className="o-form__label-container u-align-middle">
                            <label
                                aria-required
                                htmlFor="_password"
                                className="is-required"
                            >
                                {_trans('Salasana', {}, 'common')}
                            </label>
                        </span>
                        <div className="o-form__field u-margin-bottom u-padding-bottom-small u-margin-bottom-large@medium u-padding-bottom-none@medium">
                            <input type="password" id="_password" value={_password} onChange={(ev) => this.setState({ _password: ev.target.value })} className="u-1/1" />
                        </div>
                        <Button
                            preventDefault
                            inProgress={isLoginPending}
                            type="submit"
                            size="large"
                            modifierClass={classNames('u-1/1', {
                                'u-animation-shake': loginError,
                            })}
                            primary
                            onClick={() => this.postLogin()}
                        >
                            {_trans('Kirjaudu sisään', {}, 'common')}
                        </Button>
                        <Collapse isOpen={this.state.failedLoginCount > 2}>
                            <div className="u-padding-top-small u-text-center">
                                <a href="/recovery">
                                    {_trans('Unohditko salasanasi?', {}, 'common')}
                                </a>
                            </div>
                        </Collapse>
                    </form>
                </article>
            );
        }
        return (
            <EmptyState
                icon={svgIconTypes.TIME}
                message={_trans('logout_dialog.is_expiring.title')}
                // subText={_trans('logout_dialog.is_expiring.message', { time: this.renderTimeLeft() })}
                callToAction={(
                    <Fragment>
                        <div className="u-margin-bottom-small">
                            <ProgressBar value={this.state.timeLeft} max={REMAINING_TIMER} valueText={this.renderTimeLeft()} />
                        </div>
                        <Button
                            primary
                            size="large"
                            onClick={this.reloadSession}
                            inProgress={this.state.isUpdatingSession}
                            modifierClass="u-1/1"
                        >
                            {_trans('logout_dialog.is_expiring.button')}
                        </Button>
                    </Fragment>
                )}
            />
        );
    }

    render() {
        return (
            <div>
                <Dialog
                    canUnderlayClickExit={false}
                    onClose={this.reloadSession}
                    isOpen
                    title={this.state.isSessionExpired ? _trans('logout_dialog.is_expired.title') : _trans('logout_dialog.is_expiring.title')}
                    maxWidth={368}
                >
                    {this.renderDialogContent()}
                </Dialog>
            </div>
        );
    }
}
// Api kutsuihin tämä lisätään, jotta resetoidaan timeri, jos käydään bäkkärillä
export const addLogoutTimer = () => {
    if (window.logoutTimer) {
        clearTimeout(window.logoutTimer);
        // Viestitetään muille tabeille, että session on voimassa ja dialogi voidaan sulkea.
        const channel = new BroadcastChannel(BROADCAST_CHANNEL);
        channel.postMessage('message');
    }
    if (window.LOGIN_TIMEOUT) {
        window.logoutTimer = setTimeout(() => { render(<LogoutDialog/>, document.getElementById(DIALOG_ROOT_ID)); }, window.LOGIN_TIMEOUT);
    }
};
