import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import {
    PreviousEmployerSignature
} from '../../../components/Signatures/PreviousEmployerSignature';
import Button from 'shared/components/Button';
import MDIcon from 'shared/components/MDIcon';
import { signatureTypes } from 'shared/constants/signatureTypes';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { uniqueBy } from 'shared/utils/collectionHelpers';
import { Value } from 'shared/components/Value';


const findSignatureByUserId = ({ signatures, signerId, employerSignatureType }) => (
    signatures.find((signer) => signer?.type === employerSignatureType && signer.signerId === signerId)
);

/**
 * Työnantajan allekirjoitus.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const SignaturesEmployer = (props) => {
    const { value, employerSignatureType } = props;

    const dispatch = useDispatch();

    const isAssignmentContract = useSelector(select.contract.isAssignmentContract);
    const isSigning = useSelector((state) => state.loading.effects.postSignature);
    const jobContactDataId = useSelector(select.contract.getContractDataId);
    const userId = useSelector(select.userMetadata.getUserId);
    const isEmployee = useSelector(select.userMetadata.isEmployee);
    const isEmployer = useSelector(select.userMetadata.isEmployer);
    const isProposalContract = useSelector(select.contract.isProposalContract);
    const isBuildMode = useSelector(select.contract.isBuildMode);
    const signatures = useSelector(select.contract.getSignatures);
    const allSignatures = signatures.filter((signature) => signature.type !== employerSignatureType);
    const uniqueSignatures = uniqueBy(allSignatures, (signatureItem) => signatureItem.signerId);

    const signerId = parseInt(value, 10);
    const signature = findSignatureByUserId({ signatures, signerId, employerSignatureType });
    const signerDetails = signature?.signerDetails;
    const signTime = signature?.signTime;

    const hasPreviousSignatures = useSelector(select.contract.hasPreviousSignatures);
    const isReSignatureNeeded = useSelector(select.contract.isReSignatureNeeded);

    const summaryLabel = _trans('Hyvinvointialueen edustaja', {}, 'jobContract');

    // Ei vaadita uusia allekirjoituksia. Kerrotaan milloin viimeksi allekirjoitettu ja kenen toimesta.
    if (hasPreviousSignatures && isReSignatureNeeded === false) {
        return (
            <SummaryListItem term={summaryLabel}>
                <PreviousEmployerSignature
                    employerSignatureType={employerSignatureType}
                />
            </SummaryListItem>
        );
    }

    // Allekirjoitettu
    const isSigned = signature?.isSigned ?? false;

    // Onko samat käyttäjäid:t.
    const isAllowedToSign = userId === signerId;

    if (isEmployer && ! isSigned) {
        return (
            <SummaryListItem term={summaryLabel}>
                <Value>
                    {isProposalContract
                        ? signerDetails?.fullName ?? '-'
                        : _trans('Hyvinvointialue valitsee.', {}, 'jobContract')}
                </Value>
            </SummaryListItem>
        );
    }

    // Valittu allekirjoittaja. Näytä tiedot ja napit vaihtoon ja allekirjoitukseen.
    return (
        <SummaryListItem term={summaryLabel}>
            {! isSigned && (
                <div className="u-visibility-screen">
                    <strong className="u-display-block">
                        {(isEmployer || isEmployee) && ! isAssignmentContract
                            ? _trans('Hyvinvointialueen edustaja', {}, 'jobContract')
                            : signerDetails?.fullName ?? '-'}
                    </strong>
                    {/* TT:lle turha näyttää allekirjoittajan spostia */}
                    {! isEmployee && <span>{signerDetails?.email ?? '-'}</span>}
                </div>
            )}
            {! isSigned && (
                <strong className="u-visibility-print">
                    {signerDetails?.fullName ?? '-'}
                </strong>
            )}
            {(isProposalContract && isAllowedToSign && ! isSigned) && (
                <Button
                    inProgress={isSigning}
                    positive={! isBuildMode}
                    mdIcon="check"
                    disabled={isBuildMode}
                    modifierClass="u-1/1 u-margin-top-small"
                    onClick={() => {
                        dispatch.contract.postSignature({ contractDataId: jobContactDataId })
                            .then(({ data, status }) => {
                                if (status === 'ok') {
                                    const signatures = data.signatures ?? [];
                                    // Päivitetään sopparin tila
                                    dispatch.contract.setSignatures(signatures);
                                    dispatch.contract.setContractData(data);
                                } else {
                                    dispatch.notifications.addError(_trans('Sopimusta ei voitu allekirjoittaa.', {}, 'jobContract'));
                                }
                            });
                    }}
                >
                    {_trans('Allekirjoita', {}, 'jobContract')}
                </Button>
            )}
            {(! isSigned && isProposalContract && ! isAllowedToSign) && (
                <Fragment>
                    <div className="u-muted u-visibility-screen">
                        {_trans(_trans('Odottaa osapuolen 1/%signatureCount% allekirjoitusta', {}, 'jobContract'), {
                            signatureCount: (uniqueSignatures ?? []).length + 1
                        })}
                    </div>
                    <div className="u-visibility-print">
                        {_trans(_trans('Odottaa osapuolen 1/%signatureCount% allekirjoitusta', {}, 'jobContract'), {
                            signatureCount: (uniqueSignatures ?? []).length + 1
                        })}
                    </div>
                </Fragment>
            )}
            {isSigned && (
                <Fragment>
                    <MDIcon icon="check_circle" size="small" modifierClass="u-color-positive u-align-middle u-margin-right-tiny" isFilled />
                    <span className="u-align-middle">
                        {_transMd(_trans('**%signer%** allekirjoitti **%signTime%**', {
                            signer: (isEmployer || isEmployee)
                                ? _trans('Hyvinvointialue', {}, 'common')
                                : signerDetails?.fullName ?? '-',
                            signTime: _toLocaleDate(signTime, '', _dateTimeFormat)
                        }, 'jobContract'))}
                    </span>
                </Fragment>
            )}
        </SummaryListItem>
    );
};

SignaturesEmployer.propTypes = {
    value: PropTypes.number.isRequired,
    employerSignatureType: PropTypes.string,
};

SignaturesEmployer.defaultProps = {
    employerSignatureType: signatureTypes.TYPE_EMPLOYER_SPECIFIC,
};

export default SignaturesEmployer;
