import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renderöi sivupalkin listan.
 *
 * @param children
 * @returns {*}
 * @constructor
 */
function SideBarList({ children }) {
    return (
        <ul className="c-side-bar__list o-list-bare" role="menu" aria-label="Sivuvalikko">
            {children}
        </ul>
    );
}

SideBarList.propTypes = {
    /**
     * Listan sisältö.
     */
    children: PropTypes.node.isRequired,
};

export default SideBarList;
