export const fixedTermContractReason = {
    FIXED_TERM_CONTRACT_REASON_EMPLOYEE_REQUEST: 0,
    FIXED_TERM_CONTRACT_REASON_SUBSTITUTION: 1,
    FIXED_TERM_CONTRACT_REASON_PREDEFINED_TASK: 2,
    FIXED_TERM_CONTRACT_REASON_OTHER_REASON: 3,
};

export const fixedTermAssignmentContractReason = {
    FIXED_TERM_ASSIGNMENT_CONTRACT_COMMUNE_DECISION: 4,
    FIXED_TERM_ASSIGNMENT_CONTRACT_FAMILY_CARE_ALLOWANCE_FREE: 5,
    FIXED_TERM_ASSIGNMENT_CONTRACT_PERMANENT_INTERCHANGE: 6,
    FIXED_TERM_CONTRACT_REASON_OTHER_REASON: 3,
};

const fixedTermContractReasonMap = {
    [fixedTermContractReason.FIXED_TERM_CONTRACT_REASON_EMPLOYEE_REQUEST]: _trans('constant.fixed_term_contract_reason.employee_request'),
    [fixedTermContractReason.FIXED_TERM_CONTRACT_REASON_SUBSTITUTION]: _trans('constant.fixed_term_contract_reason.substitution'),
    [fixedTermContractReason.FIXED_TERM_CONTRACT_REASON_PREDEFINED_TASK]: _trans('constant.fixed_term_contract_reason.predefined_task'),
    [fixedTermContractReason.FIXED_TERM_CONTRACT_REASON_OTHER_REASON]: _trans('constant.fixed_term_contract_reason.other_reason'),
    [fixedTermAssignmentContractReason.FIXED_TERM_ASSIGNMENT_CONTRACT_COMMUNE_DECISION]: _trans('constant.fixed_term_contract_reason.commune_decision'),
    [fixedTermAssignmentContractReason.FIXED_TERM_ASSIGNMENT_CONTRACT_FAMILY_CARE_ALLOWANCE_FREE]: _trans('constant.fixed_term_contract_reason.family_care_allowance_free'),
    [fixedTermAssignmentContractReason.FIXED_TERM_ASSIGNMENT_CONTRACT_PERMANENT_INTERCHANGE]: _trans('constant.fixed_term_contract_reason.permanent_interchange')
};

export function translateFixedTermContractReason(type) {
    return type in fixedTermContractReasonMap ? fixedTermContractReasonMap[type] : _trans('Tuntematon', {}, 'common');
}
