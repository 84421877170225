import React from 'react';
import PropTypes from 'prop-types';

export default function ExperienceCell({ years, totalMonths }) {
    // Bäkkäri palauttaa kertyneen kokemuksen vuosina tai kuukausina
    // Kerrotaan vuodet 12kk ja poistetaan saatu kk määrä kertyneistäkuukausista,
    // niin saadaan haluttu vuosi ja kk määrä. esim 1v 4kk
    const months = totalMonths - years * 12;

    return (
        <div>
            {_trans('users.section.work_experience.experience_year_month_total', { years, months, totalMonths })}
        </div>
    );
}

ExperienceCell.propTypes = {
    years: PropTypes.number.isRequired,
    totalMonths: PropTypes.number.isRequired,
};
