import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { select } from '@rematch/select';
import { useSelector } from 'react-redux';
import { HiddenField, SelectField } from 'shared/components/Formik';
import { payslipDeliveryMethodTypes } from 'shared/constants/payslipDeliveryMethodTypes';
import { getEmployerPayslipDeliveryMethodOptions } from 'ContractV3/constants/getPayslipDeliveryMethods';
import validators from 'shared/validators';
import { Value } from 'shared/components/Value';
import { Placeholder } from 'shared/components';

/**
 * Työnantajan palkkalaskelman toimitustapa.
 * @param name
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const EmployerPayslipDeliveryMethod = ({ name }) => {
    const [field] = useField(name);
    const employer = useSelector(select.benefitDecision.getEmployer);
    const employerEmail = employer?.email;

    const isLoadingBenefitDecision = useSelector((state) => state.benefitDecision.fetchBenefitDecision);
    const benefitDecisionId = useSelector(select.contract.getBenefitDecisionId);
    const options = useMemo(() => (
        // Jos avustuspäätös löytyy oletetaan että ollaan henkkaripuolella
        getEmployerPayslipDeliveryMethodOptions(! isNaN(benefitDecisionId))
    ), [benefitDecisionId]);

    // Työnantajalla validi sähköposti
    const hasValidEmail = validators.isRequired(employerEmail) && validators.isEmail(employerEmail);

    // Kaikilla työntekijöillä validi osoite
    //const hasValidAddresses = employees.every((employee) => (employee?.fullAddress ?? '').toString().trim() !== '');

    // Avustuspäätökseltä valuu jo tieto toimitustavasta. Liekkö tämä olis järkevä olla defaulttina fixed value kun
    // joka avustuspäätöksellä tämä valitaan.
    const preSelectedPayslipDeliveryMethod = useSelector(select.benefitDecision.getEmployerPayslipDeliveryMethod);
    const payslipDeliveryMethod = parseInt(field.value, 10);
    const selectedOption = useMemo(() => {
        const targetOption = preSelectedPayslipDeliveryMethod !== null
            ? preSelectedPayslipDeliveryMethod
            : payslipDeliveryMethod;

        return options.find((option) => option.value === targetOption);
    }, [preSelectedPayslipDeliveryMethod, payslipDeliveryMethod, options]);

    // Valittuna jokin sähköpostitoimitustapa
    const isEmailDelivery = [
        payslipDeliveryMethodTypes.PAYSLIP_DELIVERY_METHOD_E_MAIL,
        payslipDeliveryMethodTypes.PAYSLIP_DELIVERY_METHOD_ENCRYPTED_E_MAIL,
        payslipDeliveryMethodTypes.PAYSLIP_DELIVERY_METHOD_EMAIL_NOTIFICATION,
    ].includes(payslipDeliveryMethod);

    return (
        <Fragment>
            {preSelectedPayslipDeliveryMethod !== null && (
                <Value>
                    {selectedOption?.label ?? '-'}
                    <HiddenField name={name} value={selectedOption?.value} />
                </Value>
            )}
            {preSelectedPayslipDeliveryMethod === null && (
                <Placeholder isPending={!! isLoadingBenefitDecision} type="text" rows={2} modifierClass="o-form__text">
                    <SelectField
                        name={name}
                        aria-describedby={(isEmailDelivery && hasValidEmail) ? 'employerDeliveryInfo' : null}
                        validate={() => {
                            // Sähköposti toimitustapana mutta ei sähköpostia
                            if (isEmailDelivery && ! hasValidEmail) {
                                return _trans('contract.form.salary.employer_email_delivery_error');
                            }

                            // Ei valittua toimitustapaa
                            if (! field.value) {
                                return _trans('Kenttä on pakollinen.', {}, 'common');
                            }
                        }}
                        options={options}
                        placeholder={_trans('Valitse', {}, 'common')}
                    />
                </Placeholder>
            )}
            {(selectedOption?.description && ! isEmailDelivery) && (
                <div className="o-form__text">
                    {_trans(selectedOption?.description)}
                </div>
            )}
            {(selectedOption?.description && (isEmailDelivery && hasValidEmail)) && (
                <div id="employerDeliveryInfo" className="o-form__text">
                    {_transMd(
                        selectedOption?.description,
                        { email: employer.email },
                        { useParagraphs: true },
                        'contract'
                    )}
                </div>
            )}
        </Fragment>
    );
};

EmployerPayslipDeliveryMethod.propTypes = {
    name: PropTypes.string.isRequired,
};

export default EmployerPayslipDeliveryMethod;
