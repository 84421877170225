import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { DecimalField, FormField } from 'shared/components/Formik';
import InputGroup from 'shared/components/InputGroup';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { salaryTypes } from 'shared/constants/salaryTypes';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';
import InputPostfix from 'shared/components/InputPostfix';

const getPostFixBySalaryType = (salaryType) => {
    switch (parseInt(salaryType, 10)) {
        case salaryTypes.PER_HOUR:
            return _trans('tunti', {}, 'common');
        case salaryTypes.PER_DAY:
            return _trans('hoitopäivä', {}, 'common');
        case salaryTypes.PER_MONTH:
            return _trans('kuukausi', {}, 'common');
        default:
            return '';
    }
};
/**
 * Erityislisä. OPH-spesifinen.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InitialPersonalSalary = ({ label, name, arrayName }) => {
    const [salaryTypeField] = useField(fieldNames.SALARY_TYPE);
    const salaryType = salaryTypeField.value;

    // Onko taulukkoliksat käytössä
    const [isTableSalaryInUseField] = useField(resolveArrayName(arrayName, fieldNames.IS_TABLE_SALARY_IN_USE));
    const hasMinimumTableSalary = useSelector(select.tableSalary.isMinimumTableSalaries);
    const isTableSalaryInUse = isTableSalaryInUseField?.value ?? true;
    const isEmployer = useSelector(select.userMetadata.isEmployer);
    const hasSalaryRelatedComponentsVisible = useSelector(select.contract.hasSalaryRelatedComponentsVisible);

    if ((! isTableSalaryInUse && ! hasMinimumTableSalary) || isEmployer || ! hasSalaryRelatedComponentsVisible) return null;

    return (
        <FormField label={label} name={name}>
            <InputGroup>
                <DecimalField name={name} size={6}/>
                <InputPostfix postfix={`${_currencySymbol} / ${getPostFixBySalaryType(salaryType)}`}/>
            </InputGroup>
        </FormField>
    );
};
InitialPersonalSalary.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    arrayName: PropTypes.string.isRequired,
};
export default InitialPersonalSalary;
