import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconText from 'shared/components/IconText';
import MDIcon from 'shared/components/MDIcon';
import { useDel } from 'shared/hooks/useApi';
import confirm from 'shared/utils/confirm';

const resolveIcon = (mimeType) => {
    switch (mimeType) {
        case 'image/jpg':
        case 'image/png':
            return 'image';
        default:
            return 'description';
    }
};

/**
 * Tarjoaa linkin liitteeseen ja halutessa linkinpoistonapin.
 * @param attachment
 * @param isRemovable
 * @param contractData
 * @param onDelete
 * @returns {JSX.Element}
 * @constructor
 */
export const Attachment = ({ attachment, isRemovable, contractData, onDelete }) => {
    const [{ isPending }, deleteAttachment] = useDel(`/api/v2/attachments/${attachment.id}`);

    const attachmentName = attachment.description;
    // Jos tallennettu soppari haetaan liitteet suoraan siltä
    const href = contractData > 0
        ? `/api/contracts/v1/contract-data/${contractData}/attachments/${attachment.id}/download`
        : `/api/v2/attachments/${attachment.id}/download`;

    return (
        <div className={classNames('c-card c-card--link o-stack o-stack--inline', { 'u-muted': isPending })}>
            <a href={href} className="u-display-inline-block u-padding-vertical-small u-text-decoration-none" target="_blank" title={_trans('Avautuu uuteen ikkunaan.', {}, 'common')} rel="noreferrer">
                <IconText
                    iconSize="medium"
                    mdIcon={resolveIcon(attachment.mimeType)}
                    text={attachment.description}
                    modifierClass="u-padding-horizontal-small"
                />
            </a>
            {isRemovable && (
                <button
                    type="button"
                    title={_trans('Poista liite %attachmentName%.', { attachmentName }, 'common')}
                    className="u-padding-small"
                    onClick={() => {
                        confirm(_transMd('Haluatko varmasti poistaa liitteen **%attachmentName%**?', { attachmentName }, 'common'), {
                            proceedLabel: _trans('Poista liite', {}, 'common'),
                            alert: true,
                        }).then(() => {
                            deleteAttachment().then((response) => {
                                onDelete(response);
                            });
                        });
                    }}
                    disabled={isPending}
                >
                    <MDIcon icon="delete" />
                </button>
            )}
        </div>
    );
};

Attachment.propTypes = {
    attachment: PropTypes.object.isRequired,
    isRemovable: PropTypes.bool,
    contractData: PropTypes.number,
    onDelete: PropTypes.func,
};

Attachment.defaultProps = {
    isRemovable: false,
    contractData: -1,
    onDelete() {},
};

