import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { select } from '@rematch/select';
import { useSelector, useDispatch } from 'react-redux';
import menuBarItem from 'shared/shapes/menuBarItem';
import BigMenu from 'shared/components/BigMenu';
import BigList from 'shared/components/BigList';
import HamburgerButton from 'shared/components/HamburgerButton';

/**
 * Tylsä hampurilaismenu (mäkkäri). Vangitaan fokus valikon sisään jos se on auki.
 * Mahdollisesti asteittain muutetaan myöhemmin alaosavalikoihin mobiilissa.
 * @param items
 * @returns {*}
 * @constructor
 */
const HamburgerMenu = ({ items }) => {
    const dispatch = useDispatch();
    const isMenuOpen = useSelector(select.header.isMainMenuOpen);
    const currentRoute = useSelector(select.header.getCurrentRoute);

    return (
        <Fragment>
            <div
                role="none"
                className={classNames('c-hamburger-bar o-pin o-pin--fixed o-pin--top o-pin--left', {
                    'is-open': isMenuOpen
                })}
            >
                <HamburgerButton
                    aria-haspopup={true}
                    aria-expanded={isMenuOpen}
                    aria-label={_trans('menubar.label')}
                    isOpen={isMenuOpen}
                    onClick={() => {
                        dispatch.header.toggleMainMenuOpen();
                    }}
                />
            </div>
            <BigMenu isOpen={isMenuOpen} ariaLabelledBy="hamburgerMenu" animation="fromLeft">
                <BigList
                    id="hamburgerMenu"
                    items={items}
                    currentRoute={currentRoute}
                    hasSvgIcons
                />
            </BigMenu>
        </Fragment>
    );
};

HamburgerMenu.propTypes = {
    items: PropTypes.arrayOf(menuBarItem).isRequired,
};

export default HamburgerMenu;
