import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Control, actions } from 'react-redux-form/lib/immutable';
import Button from 'shared/components/Button';
import { actions as notificationActions } from 'shared/stores/notifications';
import userRoles from 'shared/constants/userRoles';
import { validators } from 'shared/validators';
import { StLabel, StErrors } from 'shared/components/StForm/';
import InputGroup from 'shared/components/InputGroup';
import { partiesActions, partiesSelectors } from 'shared/Parties/parties';
import { contractActions } from 'Contract/stores/contract';

/**
 * Lisätään olemassa olevan käyttäjän sähköposti LocalFormilla joka on irrallaan contract-storesta.
 * Liitetään LocalFormin tiedot storeen vasta kun lomake on validi ja submitoitu.
 */
class PartyEmailForm extends Component {

    static propTypes = {
        dispatch: PropTypes.func,
        userRole: PropTypes.number,
        isPostingParty: PropTypes.bool,
        partyEmail: PropTypes.string,
        isPartyEmailValid: PropTypes.bool,
        hasPartyStAccount: PropTypes.bool,
    };

    static defaultProps = {
        dispatch() {},
        userRole: null,
        isPostingParty: false,
        partyEmail: '',
        isPartyEmailValid: false,
        hasPartyStAccount: false,
    };

    onSubmitForm() {
        const { dispatch, partyEmail, isPartyEmailValid } = this.props;

        //Validoidaan vielä varmuuden vuoksi
        dispatch(actions.validate('contract.partyEmail', {
            isEmail: validators.isEmail,
            isRequired: validators.isRequired,
        }));
        if (isPartyEmailValid) {
            // tallennetaan tilapäinen käyttäjä pelkällä sähköpostilla
            dispatch(partiesActions.userPostTemp({ email: partyEmail }));
            // TA osoitteen päivitys lomakeelle
            dispatch(contractActions.updateEmployerAddress());
        }
    }

    render() {
        const { userRole, isPostingParty, hasPartyStAccount } = this.props;

        let partyId = _trans('contract.employee.genitive2');

        if (userRole === userRoles.EMPLOYEE) {
            partyId = _trans('contract.employer.genitive2');
        }

        return (
            <div>
                <StLabel htmlFor="partyEmail">{_trans('contract.form.parties.party.email.label', { party: partyId })}</StLabel>
                <InputGroup>
                    <Control
                        type="email"
                        id="partyEmail"
                        model=".partyEmail"
                        style={{ flexGrow: '2' }}
                        size="24"
                        validators={{
                            isRequired: () => hasPartyStAccount ? validators.isRequired : null,
                            isEmail: () => hasPartyStAccount ? validators.isEmail: null,
                        }}
                        validateOn="change"
                        onKeyDown={(event) => event.keyCode === 13 ? this.onSubmitForm() : null}
                    />
                    <Button
                        onClick={() => this.onSubmitForm()}
                        primary
                        isPending={isPostingParty}
                    >
                        {_trans('button.accept')}
                    </Button>
                </InputGroup>
                <StErrors model=".partyEmail"/>
                <small className="o-help">
                    {_trans('contract.form.parties.party.st_account_email.help', { 'party': partyId })}
                </small>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userRole: partiesSelectors.getUserRole(state),
    isPostingParty: partiesSelectors.isPostingParty(state),
    partyEmail: state.contract.get('partyEmail'),
    isPartyEmailValid: state.forms.contract.partyEmail.valid,
    hasPartyStAccount: partiesSelectors.partyHasStAccount(state),
});

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(notificationActions, dispatch),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(PartyEmailForm);
