import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import UserBlock from 'shared/components/UserBlock';

/**
 * Joku toinen työntekijä (esim. yhteyshenkilö). Pelkkää metaa.
 * Näillä vain yhteystiedot näkyvillä.
 * @returns {JSX.Element}
 * @constructor
 */
const OtherEmployee = ({ value, label }) => {
    const otherEmployee = useSelector((state) => select.employees.getEmployeeById(state, value));
    const isEmployee = useSelector(select.userMetadata.isEmployee);
    const isLoading = useSelector((state) => state.loading.effects.employees.fetchEmployees);

    const details = isLoading
        ? []
        : [
            [otherEmployee.fullAddress],
            [otherEmployee.phoneNumber],
            [otherEmployee.email]
        ].filter((detail) => detail.toString().trim() !== '');

    return (
        <SummaryListItem term={label}>
            {/*TODO: User/constants/routes.js refaktorointi => exporttaa vain routet ulos, ei komponentteja*/}
            <UserBlock
                isLoading={isLoading}
                user={otherEmployee}
                size="small"
                relationType="employee"
                hasLinkToDetails={! isEmployee}
            />
            {details.map((detail, key) => (
                <Fragment key={key}>
                    {detail}<br/>
                </Fragment>
            ))}
        </SummaryListItem>
    );
};

OtherEmployee.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
};

OtherEmployee.defaultProps = {
    label: '',
};

export default OtherEmployee;
