import React from 'react';
import PropTypes from 'prop-types';
import { BooleanValue } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Summary/shared/BooleanValue';

/**
 * Säännöllinen työaika valinta.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */

const HasWeeklyWorkHours = ({ value, name }) => <BooleanValue name={name} value={value} />;
HasWeeklyWorkHours.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default HasWeeklyWorkHours;
