import React from 'react';
import PropTypes from 'prop-types';

/**
 *
 * @param focusedOption
 * @param focusOption
 * @param option
 * @param key
 * @param style
 * @returns {*}
 * @constructor
 */
const LinkRenderer = ({ focusedOption, focusOption, option, key, style }) => (
    <a
        href={option.url}
        onMouseEnter={() => focusOption(option)}
        className={`
                VirtualizedSelectOption
                VirtualizedSelectOption${focusedOption === option ? '-has-focus' : ''}
                u-color-default
            `}
        style={style}
        key={key}
        aria-label={`${_.get(option, 'mainContent', '')}, ${_.get(option, 'secondaryContent', '')}`}
    >
        <div className="u-1/1 o-stack o-stack--justify">
            {option.mainContent ? option.mainContent : ''}
            {option.secondaryContent ? option.secondaryContent : ''}
        </div>
    </a>
);



export default LinkRenderer;

LinkRenderer.propTypes = {
    /**
     * Itse valinta ja sen arvot.
     * tarvitaan optionin sisään url ja ainakin mainContent
     * jos haluaa esim. badgen oikeaan laitaan niin se lisätään secondaryContentin kautta
     */
    option: PropTypes.object.isRequired,
    /**
     * Valittu valinta.
     */
    focusedOption: PropTypes.object.isRequired,
    /**
     * Mitä tehdään kun valinta saa fokuksen.
     */
    focusOption: PropTypes.func.isRequired,
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    style: PropTypes.object.isRequired,
};
