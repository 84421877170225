import moment from 'moment';
import _ from 'lodash';

const deepDiffMapper = {
    VALUE_CREATED: 'created',
    VALUE_UPDATED: 'updated',
    VALUE_DELETED: 'deleted',
    VALUE_UNCHANGED: 'unchanged',
    map (originalObject, newObject) {
        if (_.isFunction(originalObject) || _.isFunction(newObject)) {
            throw 'Invalid argument. Function given, object expected.';
        }
        if (this.isValue(originalObject) || this.isValue(newObject)) {
            return {
                type: this.compareValues(originalObject, newObject),
                data: (originalObject === undefined) ? newObject : originalObject
            };
        }

        const diff = {};
        for (const key in originalObject) {
            if (_.isFunction(originalObject[key])) {
                continue;
            }

            let value2 = undefined;
            if (undefined !== newObject[key]) {
                value2 = newObject[key];
            }

            diff[key] = this.map(originalObject[key], value2);
        }
        for (const key in newObject) {
            if (_.isFunction(newObject[key]) || (undefined !== diff[key])) {
                continue;
            }

            diff[key] = this.map(undefined, newObject[key]);
        }

        return diff;

    },
    compareValues (originalValue, newValue) {
        if (originalValue === newValue) {
            return this.VALUE_UNCHANGED;
        }
        if (_.isDate(originalValue) && _.isDate(newValue) && originalValue.getTime() === newValue.getTime()) {
            return this.VALUE_UNCHANGED;
        }
        if (moment.isMoment(originalValue) && moment.isMoment(newValue) && originalValue.isSame(newValue)) {
            return this.VALUE_UNCHANGED;
        }
        if (undefined === originalValue) {
            return this.VALUE_CREATED;
        }
        if (undefined === newValue) {
            return this.VALUE_DELETED;
        }
        if (originalValue == newValue) { // lisätarkistus röyhkiästi laiskalla vertailulla ettei tuu diffejä 1 !== "1"
            return this.VALUE_UNCHANGED;
        }

        return this.VALUE_UPDATED;
    },
    isValue (obj) {
        return !_.isObject(obj) && !_.isArray(obj);
    }
};

export default deepDiffMapper;
