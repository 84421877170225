import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { LocalForm, Control } from 'react-redux-form';
import { List } from 'immutable';
import { userSelectors, userActions } from '../../stores/user';
import { StLabel, StField, StHelp, StErrors } from 'shared/components/StForm';
import DateInput from 'shared/components/DateInput';
import validators from 'shared/validators';
import Page from 'shared/components/Page';
import ActionBar from 'shared/components/ActionBar/ActionBar';
import SubmitButton from 'shared/components/SubmitButton';
import DataTable from 'shared/containers/DataTable';
import Feedback from 'shared/components/Feedback';
import alertTypes from 'shared/constants/alertTypes';
import Button from 'shared/components/Button';

@connect((state) => ({
    userId: userSelectors.getUserId(state),
    hasActiveFenniaInsurance: userSelectors.hasActiveFenniaInsurance(state),
    contracts: state.employerAccidentInsurances.get('contracts', List()),
    isCommune: select.userMetadata.isCommune(state),
}))
export default class UserAccidentInsurances extends Component {

    state = {
        isLoading: false,
        hasFetched: false,
        selectedDate: null,
    };

    static propTypes = {
        userId: PropTypes.number,
        dispatch: PropTypes.func,
        ui: PropTypes.object,
        hasActiveFenniaInsurance: PropTypes.bool,
        contracts: PropTypes.object,
        isCommune: PropTypes.bool,
    };

    static defaultProps = {
        userId: -1,
        dispatch: () => {},
        ui: {},
        hasActiveFenniaInsurance: false,
        contracts: {},
        isCommune: false,
    };

    onSubmit = (model) => {
        const { dispatch, userId } = this.props;
        this.setState({ isLoading: true, selectedDate: model.startDate });
        dispatch(userActions.fetchEmployerAccidentInsurances(userId, model.startDate)).then(() => this.setState({ isLoading: false, hasFetched: true }));
    };

    renderAccidentInsuranceDataTable() {
        const { contracts, hasActiveFenniaInsurance, isCommune } = this.props;

        if (! hasActiveFenniaInsurance) {
            return (
                <Feedback type={alertTypes.ERROR}>
                    {_trans('users.accident_insurance.no_active_fennia')}
                </Feedback>
            );
        }

        return (
            <DataTable
                isLoading={this.state.isLoading}
                data={contracts.toJS()}
                emptyDataMessage={_trans('users.accident_insurance.no_contracts')}
                emptyDataIcon="handshake"
                actionsColumn={<InsuranceActionColumn isCommune={isCommune} date={this.state.selectedDate} />}
                columns={[
                    {
                        Header: _trans('role.employee'),
                        accessor: 'currentMasterData.employeeName',
                    },
                    {
                        Header: _trans('users.accident_insurance.start_date'),
                        accessor: 'startDate',
                        type: 'date',
                        width: 140,
                    },
                    {
                        Header: _trans('users.accident_insurance.end_date'),
                        accessor: 'endDate',
                        type: 'date',
                        defaultValue: _trans('date_range.indefinitely'),
                        width: 140,
                    }
                ]}
            />
        );
    }

    render() {
        return (
            <Page heading={_trans('users.accident_insurance.title')}>
                <LocalForm
                    className="o-form o-form--vertical"
                    onSubmit={(model) => this.onSubmit(model)}
                >
                    <StLabel>
                        {_trans('users.accident_insurance.date')}
                    </StLabel>
                    <StField>
                        <Control
                            model=".startDate"
                            component={DateInput}
                            minimumDate={new Date('1970-01-01')}
                            validators={{
                                isRequired: validators.isRequired,
                                isISODate: validators.isISODate,
                            }}
                            aria-describedby="startDateHelp"
                        />
                        <StErrors model=".startDate"/>
                        <StHelp id="startDateHelp">
                            {_trans('users.accident_insurance.help_text')}
                        </StHelp>
                    </StField>
                    <ActionBar hasMargin>
                        <SubmitButton isPending={this.state.isLoading}>
                            {_trans('button.search')}
                        </SubmitButton>
                    </ActionBar>
                </LocalForm>
                {this.state.hasFetched && this.renderAccidentInsuranceDataTable()}
            </Page>
        );
    }
}

const InsuranceActionColumn = (props) => (
    <span>
        <Button
            flat
            href={Routing.generate(props.isCommune ? 'suoratyo_commune_insurance_user_ltt_create_claim' : 'suoratyo_insurance_ltt_create_claim', {
                jobContractData: _.get(props.original, 'currentMasterData.jobContractDataId', null),
                date: props.date
            })}
        >
            {_trans('users.accident_insurance.claim')}
        </Button>
        <Button
            flat
            modifierClass="u-margin-left-tiny"
            href={Routing.generate(props.isCommune ? 'suoratyo_commune_insurance_user_ltt_create_certificate' : 'suoratyo_insurance_ltt_create_certificate', {
                jobContractData: _.get(props.original, 'currentMasterData.jobContractDataId', null),
                date: props.date
            })}
        >
            {_trans('users.accident_insurance.certificate')}
        </Button>
    </span>
);

InsuranceActionColumn.propTypes = {
    original: PropTypes.object,
    date: PropTypes.string,
    isCommune: PropTypes.bool,
};


InsuranceActionColumn.defaultProps = {
    original: {},
    date: '',
    isCommune: false,
};
