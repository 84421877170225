import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Wrapper from 'vendor/react-aria-tabpanel/Wrapper';
import { TabList, TabItem, TabPanel } from 'shared/components/Tab';
import Badge from 'shared/components/Badge';
import MDIcon from 'shared/components/MDIcon';
import MDSpinner from 'shared/components/MDSpinner';

/**
 * Renderöi tabit ja niiden vastaavat sisällöt.
 * Tabit ja tabien sisällöt voi joko renderöidä itse manuaalisesti komponenteilla tai antaa
 * TabContainerille tabs-objektin joka pitää sisällään tiedot tabien ja tabien sisällöistä.
 */
export default class TabContainer extends PureComponent {

    state = {
        activeTabId: null,
    };

    /**
     * Tutkitaan onko hashit käytössä ja löytyykö osoiteriviltä tabin id:tä vastaavaa hashia.
     */
    UNSAFE_componentWillMount() {
        this.updateHash(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateHash(nextProps);
    }

    updateHash(props) {
        if (props.isUsingHash) {
            this.setState({
                activeTabId: window.location.hash.substring(5) || '',
            });
        }
    }

    static renderIcon(tab) {
        const icon = _.get(tab, 'icon', null);

        if (icon) {
            return (
                <div className="u-text-center u-margin-bottom-tiny u-position-relative">
                    <MDIcon icon={icon} />
                    { _.has(tab, 'badgeType') && TabContainer.renderBadge(tab)}
                </div>
            );
        }
    }

    /**
     * Tabin valinnainen badge.
     * @param tab
     * @returns {*}
     */
    static renderBadge(tab) {
        const badge = _.get(tab, 'badge', null);
        const hasIcon = _.has(tab, 'icon');
        const isPending = _.get(tab, 'isPending', null);

        if (isPending) {
            if (!hasIcon) {
                return (
                    <span className="u-margin-left-tiny u-align-middle">
                        <MDSpinner size="small" />
                    </span>
                );
            } else {
                return (
                    <span className="o-pin" style={{ top: '-6px', right: '-6px' }}>
                        <MDSpinner size="small" />
                    </span>
                );
            }
        }

        if (badge !== null) {
            return (
                <Badge
                    value={badge}
                    isBall={hasIcon}
                    overlap={hasIcon}
                    stroke={hasIcon}
                    type={_.get(tab, 'badgeType', 'neutral')}
                />
            );
        }

        if (_.has(tab, 'badgeType')) {
            return (
                <Badge
                    type={_.get(tab, 'badgeType', 'neutral')}
                />
            );
        }
    }

    renderTabItems() {
        const {
            tabs,
            isUsingHash,
        } = this.props;

        return (
            <TabList hasMargin>
                {_.map(tabs, (tab, tabId) => {
                    const hasIcon = _.has(tab, 'icon');
                    const isHidden = _.get(tab, 'hidden', false);

                    return (
                        <TabItem
                            tabId={tabId}
                            useHash={isUsingHash}
                            key={tabId}
                            hasIcon={hasIcon}
                            hidden={isHidden}
                        >
                            { TabContainer.renderIcon(tab) }
                            <div>
                                <span className="u-align-middle">
                                    {_.get(tab, 'label', '')}
                                </span>
                                { !hasIcon && TabContainer.renderBadge(tab)}
                            </div>
                        </TabItem>
                    );
                })}
            </TabList>
        );
    }

    renderTabPanels() {
        const {
            tabs,
            isInactiveContentUnmounted,
        } = this.props;

        return _.map(tabs, (tab, tabId) => {
            if (_.get(tab, 'hidden', false)) {
                return null;
            }
            return (
                <TabPanel
                    tabId={tabId}
                    key={tabId}
                    isInactiveContentUnmounted={isInactiveContentUnmounted}
                >
                    {_.get(tab, 'content', '')}
                </TabPanel>
            );
        });
    }

    render() {
        const {
            tabs,
            children,
        } = this.props;

        // Hashin antama id ylikirjoittaa oletuksena valitun tab-id:n.
        let restProps = {};
        const activeTabId = this.state.activeTabId || this.props.initialTabId;
        if (activeTabId) {
            restProps = {
                activeTabId,
            };
        }

        return (
            <Wrapper
                letterNavigation={false}
                {...restProps}
            >
                { _.size(tabs) > 0 ? this.renderTabItems() : children }
                { _.size(tabs) > 0 && this.renderTabPanels() }
            </Wrapper>
        );
    }
}

TabContainer.defaultProps = {
    children: null,
    isUsingHash: false,
    initialTabId: null,
    tabs: {},
    isInactiveContentUnmounted: false,
};

TabContainer.propTypes = {
    /**
     * Muu sisältö (TabItemit ja TabPanelit). Annetaan vain jos tabs-objektia ei käytetä.
     */
    children: PropTypes.node,

    /**
     * Käytetäänkö tabien valinnan muistamiseen hashia.
     * HUOM! Et voi käyttää tätä jos React Routerin hashHistory on käytössä.
     */
    isUsingHash: PropTypes.bool,

    /**
     * Oletuksena valittu tabi.
     */
    initialTabId: PropTypes.string,

    /**
     * Tabit voidaan antaa myös objektina jossa objektin avain on tabin id,
     * ja arvo on objekti jolla on vähintään label ja content.
     */
    tabs: PropTypes.object,

    /**
     * Renderöidäänkö tabin sisältö vasta kun se on aktiivinen.
     */
    isInactiveContentUnmounted: PropTypes.bool,
};
