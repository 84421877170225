import React, { Component, Fragment, } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, connect } from 'formik';
import collectiveAgreementVariableShape from 'shared/shapes/collectiveAgreementVariableShape';
import variableTypes from 'shared/constants/collectiveAgreementVariableTypes';
import { Popover } from 'shared/components';
import { FormikErrors } from 'shared/components/Formik';

/**
 * Renderöi TES:in mukaiset valinnat.
 * HUOM! Olettaa että käytössä on Formik. Ei toimi siis yksinään. Pulttaa tämä kiinni Formikin alle.
 * Katso mallia vaikka QuickPayment/JobTitle/containers/CollectiveAgreementForm.jsx
 */
class CollectiveAgreementVariables extends Component {
    static propTypes = {
        formik: PropTypes.object,
    };

    static defaultProps = {
        formik: {},
    };

    isBooleanVariable = (variable) => _.get(variable, 'type') === 'boolean';
    getName = (variable) => _.get(variable, 'name', 'undefined');
    getIdx = (index, placeholder = '') => `${this.props.name}.${index}.${placeholder}`;
    getId = (variable) => `${this.getName(variable)}_variable`;
    getLabel = (variable, placeholder = 'label') => _trans(`contract.form.tes_variables.${this.getName(variable)}.${placeholder}`);
    getTooltip = (variable) => _trans(`contract.form.tes_variables.${this.getName(variable)}.tooltip`);
    getOptions = (variable) => _.get(variable, 'options.values', []);
    getDefaultValue = (variable) => {
        const defaultValue = _.get(variable, 'defaultValue', null);
        return this.isBooleanVariable(variable) ? defaultValue === '1' : defaultValue;
    };
    isVariableDisabled = (variable) => ! _.get(variable, 'isModifiable', true);

    /**
     * Yksinkertainen kyllä/ei -switch.
     * @param variable
     * @param key
     * @returns {*}
     */
    renderCheckbox = (variable, key) => {
        const name = this.getIdx(key, 'value');

        return (
            <label
                className="o-stack o-stack--justify u-margin-bottom"
                htmlFor={this.getId(variable)}
            >
                <span className="o-stack--inline">
                    <span aria-describedby={this.getId(variable)}>
                        {this.getLabel(variable, 'text')}
                    </span>
                    <Popover position="top" describedById={this.getId(variable)}>
                        <div style={{ maxWidth: '320px' }}>
                            {this.getTooltip(variable)}
                        </div>
                    </Popover>
                </span>
                <Field
                    type="checkbox"
                    id={this.getId(variable)}
                    name={name}
                    disabled={this.isVariableDisabled(variable)}
                />
                <FormikErrors name={name} />
            </label>
        );
    };

    /**
     * Päivien lukumäärän kysely.
     * @param variable
     * @param key
     * @returns {*}
     */
    renderNumber = (variable, key) => {
        const name = this.getIdx(key, 'count');

        return (
            <div className="u-margin-bottom">
                <label
                    className="o-stack o-stack--justify"
                    htmlFor={this.getId(variable)}
                >
                    <span className="o-stack--inline">
                        <span aria-describedby={this.getId(variable)}>
                            {this.getLabel(variable, 'count_text')}
                        </span>
                        <Popover position="top" describedById={this.getId(variable)}>
                            <div style={{ maxWidth: '320px' }}>
                                {this.getTooltip(variable)}
                            </div>
                        </Popover>
                    </span>
                    <Field
                        type="tel"
                        name={name}
                        size={3}
                        disabled={this.isVariableDisabled(variable)}
                    />
                    <Field
                        type="hidden"
                        name={this.getIdx(key, 'value')}
                        value={true}
                    />
                </label>
                <FormikErrors name={name} />
            </div>
        );
    }

    /**
     * Alasvetovalikko. Mukana myös päivien lukumäärän kysely.
     * @param variable
     * @param key
     * @returns {*}
     */
    renderOptions = (variable, key) => {
        const name = this.getIdx(key, 'value');

        return (
            <Fragment>
                {this.renderNumber(variable, key)}
                <label className="o-stack o-stack--justify u-margin-bottom">
                    {this.getLabel(variable)}
                    <Field
                        as="select"
                        name={name}
                        disabled={this.isVariableDisabled(variable)}
                    >
                        {this.getOptions(variable).map((option, key) => {
                            const hasAmount = _.has(option, 'amount');
                            const amount = hasAmount ? ` (${_currency(option.amount)})` : '';
                            const name = _trans(_.get(option, 'name'));
                            return (
                                <option value={_.get(option, 'id')} key={key}>
                                    {`${name} ${amount}`}
                                </option>
                            );
                        })}
                    </Field>
                </label>
                <FormikErrors name={name} />
            </Fragment>
        );
    }

    /**
     * Tyypin mukaan renderöinti.
     * @param variable
     * @param key
     * @returns {*}
     */
    renderVariable = (variable, key) => {
        const variableType = _.get(variable, 'type');
        const isHidden = _.get(variable, 'isHidden', false);

        if (isHidden) return null;

        switch (variableType) {
            case variableTypes.NUMBER:
                return this.renderNumber(variable, key);
            case variableTypes.BOOLEAN:
                return this.renderCheckbox(variable, key);
            case variableTypes.CHOICE:
                return this.renderOptions(variable, key);
            default:
                return null;
        }
    };

    render() {
        const {
            name,
            variables,
        } = this.props;

        return (
            <FieldArray
                name={name}
                render={() => variables.map((variable, key) => (
                    <Fragment key={key}>
                        {this.renderVariable(variable, key)}
                    </Fragment>
                ))}
            />
        );
    }
}
CollectiveAgreementVariables.propTypes = {
    name: PropTypes.string.isRequired,

    /**
     * Valintalista: choice, boolean, choice_chain...
     */
    variables: PropTypes.arrayOf(collectiveAgreementVariableShape).isRequired,
};

export default connect(CollectiveAgreementVariables);
