import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import classNames from 'classnames';

function SummaryList({ list, hasBorder, termWidth }) {
    let termStyle = {};

    if (termWidth !== null) {
        termStyle = {
            width: isNaN(termWidth) ? termWidth : `${termWidth}px`,
        };
    }

    const summaryList = _map(list, (item, key) => {
        const term = _.get(item, 'term', null);
        const description = _.get(item, 'description', null);

        if (term) {
            return (
                <li className="o-summary-list__item" key={key}>
                    <span className="o-summary-list__term" style={termStyle} role="term">
                        {term}
                    </span>
                    <span className="o-summary-list__description" role="definition">
                        {description}
                    </span>
                </li>
            );
        }
    });

    const summaryListClass = classNames('o-summary-list o-list-bare', {
        'o-summary-list--borderless': !hasBorder,
    });

    return (
        <ul className={summaryListClass}>
            {summaryList}
        </ul>
    );
}

SummaryList.propTypes = {
    list: PropTypes.array.isRequired,
    hasBorder: PropTypes.bool,

    /**
     * Termin leveys pikseleinä tai CSS-luokkana (_utilities.widths.scss).
     */
    termWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SummaryList.defaultProps = {
    hasBorder: true,
    termWidth: null,
};

export default SummaryList;
