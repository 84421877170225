import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';
import { StLabel, StField, StErrors, StHelp } from 'shared/components/StForm';
import { validators } from 'shared/validators';

export default class PhoneNumber extends PureComponent {
    render() {
        const {
            model,
        } = this.props;

        return (
            <div>
                <StLabel htmlFor="phoneNumber">
                    {_trans('userForm.phoneNumber.label')}
                </StLabel>
                <StField>
                    <Control.text
                        {...this.props}
                        id="phoneNumber"
                        model={model}
                        size={20}
                        maxLength={20}
                        validators={{
                            isPhoneNumber: validators.isNumeric,
                        }}
                        aria-describedby="phoneNumberHelp"
                    />
                    <StHelp id="phoneNumberHelp">
                        {_trans('userForm.phoneNumber.help')}
                    </StHelp>
                    <StErrors model={model}/>
                </StField>
            </div>
        );
    }
}

PhoneNumber.defaultProps = {
    model: '.phoneNumber',
};

PhoneNumber.propTypes = {
    model: PropTypes.string,
};
