import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { rolePages } from '../constants';
import Button from 'shared/components/Button';

export const RoleSelectorHeading = ({ id, heading, labelProps }) => {
    const dispatch = useDispatch();
    const hasSelectedRole = useSelector(select.header.hasSelectedRole);
    const rolePage = useSelector(select.roleSelector.getPage);

    return (
        <div className="u-margin-bottom u-position-relative">
            {rolePage !== rolePages.ROLE_LIST && (
                <Button
                    flat
                    size="large"
                    mdIcon="arrow_back"
                    hasPadding={false}
                    modifierClass="o-pin o-pin--left o-pin--top"
                    title={_trans('user_menu.change_role')}
                    onClick={() => dispatch.roleSelector.setPage(rolePages.ROLE_LIST)}
                />
            )}
            <label className="c-role-selector__heading u-text-center u-display-block u-margin-none" id={id} {...labelProps}>
                {heading}
            </label>
            {hasSelectedRole && (
                <Button
                    flat
                    size="large"
                    id="closeRoleSelector"
                    title={_trans('button.close')}
                    mdIcon="clear"
                    hasPadding={false}
                    modifierClass="o-pin o-pin--right o-pin--top"
                    onClick={() => dispatch.header.setIsRoleSelectorVisible(false)}
                />
            )}
        </div>
    );
};

RoleSelectorHeading.propTypes = {
    /**
     * Yksilöivä tunniste. Aria-listaa varten.
     */
    id: PropTypes.string.isRequired,

    heading: PropTypes.string.isRequired,

    labelProps: PropTypes.object,
};

RoleSelectorHeading.defaultProps = {
    labelProps: {},
};
