import React from 'react';
import { select } from '@rematch/select';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { taxCardTypes } from 'shared/constants/taxCardTypes';
import { TaxCardTypeSelector } from 'shared/TaxCard/containers/TaxCardTypeSelector';
import { ActionBar, IconButton, Layout, StLabel, SubmitButton, Feedback } from 'shared/components';
import { TaxCardFields } from 'shared/TaxCard/containers/TaxCardFields';
import { SourceTaxCardFields } from 'shared/TaxCard/containers/SourceTaxCardFields';
import { NoTaxCardFields } from 'shared/TaxCard/containers/NoTaxCardFields';
import { validationSchema } from 'shared/TaxCard/components/taxCardValidationSchema';
import { types } from 'shared/constants/contract';
import { FormikErrors } from 'shared/components/Formik';

const resolveTaxCardBaseType = (contractType) => {
    switch (contractType) {
        case types.ASSIGNMENT_CONTRACTS_FAMILY_CARE:
            return taxCardTypes.COMPENSATION_TAX_CARD_FAMILY_CARE;
        case types.ASSIGNMENT_CONTRACTS_RELATIVE_CARE:
            return taxCardTypes.COMPENSATION_TAX_CARD_RELATIVE_CARE;
        case types.ASSIGNMENT_CONTRACTS_COMPENSATION_EARNER:
            return taxCardTypes.COMPENSATION_TAX_CARD_PERSONAL;
        default:
            return taxCardTypes.TYPE_B;
    }
};

export const TaxCardForm = () => {
    const dispatch = useDispatch();
    const isEditMode = useSelector(select.taxCards.isEditMode);
    const selectedTaxCardId = useSelector(select.taxCards.getSelectedTaxCardId);
    const taxCard = useSelector((state) => select.taxCards.getTaxCardById(state, selectedTaxCardId));
    const contractType = useSelector(select.taxCards.getContractType);

    const isOverrideVeroFetch = useSelector(select.taxCards.isOverrideVeroFetch);
    const isPrepaymentRegisterCarer = useSelector(select.taxCards.isPrePaymentRegisterEmployee);
    // Selvitetään perusverokortin tyyppi toimeksiantosopimukselle vs muille soppareille
    const taxCardBaseType = resolveTaxCardBaseType(contractType);

    const normalTaxCardTypes = [
        taxCardTypes.TYPE_B,
        taxCardTypes.COMPENSATION_TAX_CARD_FAMILY_CARE,
        taxCardTypes.COMPENSATION_TAX_CARD_RELATIVE_CARE,
        taxCardTypes.COMPENSATION_TAX_CARD_PERSONAL,
    ];

    return (
        <div>
            {isPrepaymentRegisterCarer && (
                <Feedback type="info">
                    {_transMd(`
Huom. Ennakkoperintärekisteri

Huomioithan, että hoitaja kuuluu ennakkoperintärekisteriin, niin veroprosentiksi pitää asettaa 0%.
Näissä tilanteissa emme ilmoita tulorekisteriin työntekijän osuutta eläkevakuutuksesta.
`, true)}
                </Feedback>
            )}
            <Formik
                enableReinitialize
                initialValues={{
                    type: taxCard?.type ?? taxCardBaseType,
                    startDate: taxCard?.startDate ?? '',
                    taxDeduction1: taxCard?.taxDeduction1 ?? '',
                    taxDeduction2: taxCard?.taxDeduction2 ?? '',
                    incomeLimit1: taxCard?.incomeLimit1 ?? '',
                    isOverrideVeroFetch: taxCard?.isOverrideVeroFetch ?? false,
                    /**
                     * Jos lisätään uutta verokorttia, niin ei aseteta automaattisesti nollaksi.
                     * OmaVerosta haetun verokortin prosentin muokaamisessa voidaan asettaa 0 -> muuten ei voisi tallentaa
                     */
                    previousSalary: selectedTaxCardId ? taxCard?.previousSalary ?? 0 : '',
                    // Lähdeverokortin kentät
                    dateOfArrival: taxCard?.attributes?.date_of_arrival?.date ?? '',
                    citizenship: taxCard?.attributes?.citizenship ?? '',
                    hasA1E101: taxCard?.attributes?.has_a1_e101 ?? '',
                    entitledToIncomeReduction: taxCard?.attributes?.entitled_to_income_reduction ?? '',
                    addressInCountryOfOrigin: taxCard?.attributes?.address_in_country_of_origin ?? '',
                    excludesSava: taxCard?.attributes?.excludes_sava ?? '',
                }}
                onSubmit={(model) => {
                    const postableModel = {
                        ...model,
                        taxDeduction1: String(model.taxDeduction1).replace(',', '.'),
                        taxDeduction2: String(model.taxDeduction2).replace(',', '.'),
                        incomeLimit1: String(model.incomeLimit1).replace(',', '.').replace(/\s/g, ''),
                        previousSalary: String(model.previousSalary).replace(',', '.').replace(/\s/g, ''),
                        isOverrideVeroFetch
                    };
                    dispatch.taxCards.postTaxCard(postableModel, selectedTaxCardId);
                    dispatch.taxCards.setIsEditMode(false);
                }}
                validationSchema={Yup.object().shape({ ...validationSchema })}
            >
                {({ handleSubmit, values }) => (
                    <form className="o-form o-form--responsive" onSubmit={handleSubmit}>
                        {/* Verokortin tyypin valitsin */}
                        {isEditMode &&
                            <Layout.Container verticalGutter gutterSize="small" modifierClass="u-margin-bottom">
                                <Layout.Item>
                                    <StLabel>
                                        {_trans('tax_card.form.type.select_label')}
                                    </StLabel>
                                    <FormikErrors name="type" />
                                </Layout.Item>
                                <TaxCardTypeSelector />
                            </Layout.Container>
                        }
                        {/* Formin kentät*/}
                        {normalTaxCardTypes.includes(values.type) && <TaxCardFields />}
                        {values.type === taxCardTypes.TAX_AT_SOURCE && <SourceTaxCardFields />}
                        {values.type === taxCardTypes.NO_TAX_CARD && <NoTaxCardFields />}
                        {/* Peruuta / tallenna napit */}
                        {isEditMode &&
                            <ActionBar modifierClass="u-margin-top">
                                <IconButton
                                    mdIcon="arrow_back"
                                    onClick={() => dispatch.taxCards.setIsEditMode(false)}
                                    ghost
                                >
                                    {_trans('link.return_without_saving')}
                                </IconButton>
                                <SubmitButton>
                                    {_trans('button.save')}
                                </SubmitButton>
                            </ActionBar>
                        }
                    </form>
                )}
            </Formik>
        </div>

    );
};
