/**
 * SideBar-komponentin omat sisäiset asetukset.
 * @type {string}
 */

const GROUP_NAME = 'groupName';
const GROUP_ICON = 'groupIcon';
// Ryhmän otsikon sisältö. Käytännössä mitä vain mutta 99% tapauksista riittää groupName ja groupIcon.
const GROUP_HEADER = 'groupHeader';
const GROUP_ROUTES = 'routes';

const ROUTE_NAME = 'routeName';
const ROUTE_TITLE = 'title';
const ROUTE_CONTENT = 'content';
const ROUTE_VISIBLE = 'visible';
const ROUTE_BADGE = 'badge';
const ROUTE_LINK = 'href';
const ROUTE_IS_ADMIN = 'isAdmin';

const obligatoryKeys = [ROUTE_NAME, ROUTE_TITLE, ROUTE_CONTENT];

export default {
    GROUP_NAME,
    GROUP_ICON,
    GROUP_HEADER,
    GROUP_ROUTES,

    ROUTE_NAME,
    ROUTE_TITLE,
    ROUTE_CONTENT,
    ROUTE_VISIBLE,
    ROUTE_BADGE,
    ROUTE_LINK,
    ROUTE_IS_ADMIN,

    obligatoryKeys,
};
