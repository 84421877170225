import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * Valittu operaattori. Tallennettu objektina metadataan, ei pelkkänä userId:nä.
 * USE CASE: esim. perhehoitajan vastuutyöntekijä. Metatietoa.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const Operator = ({ value }) => (
    <Fragment>
        <strong>{value?.fullName ?? '-'}</strong><br/>
        {value?.email ?? '-'}
    </Fragment>
);

Operator.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default Operator;
