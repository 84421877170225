import api from 'api';
import { benefitDecisionStates } from 'shared/constants/ContractV3/benefitDecisionStates';

/**
 * Avustuspäätökset.
 */
export const benefitDecisions = {
    state: {
        active: [],
        ending: [],
        expired: [],
        closed: [],
    },

    reducers: {
        setActiveBenefitDecisions: (state, active) => ({ ...state, active }),
    },

    selectors: {
        getActiveBenefitDecisions: (state) => state?.active ?? [],
    },

    effects: (dispatch) => ({
        async fetchBenefitDecisions({ state }) {
            try {
                const json = await api.get(`/commune/api/v1/benefitdecisions?state=${state}`);
                return json?.data ?? [];
            } catch (e) {
                console.log(e);
                return false;
            }
        },

        async fetchActiveBenefitDecisions() {
            const response = await this.fetchBenefitDecisions({ state: benefitDecisionStates.ACTIVE });
            if (response) {
                return this.setActiveBenefitDecisions(response);
            }
            dispatch.notifications.addError('Voimassa olevia avustuspäätöksiä ei voitu hakea.', {}, 'jobContract');
        },

        async fetchEmployerBenefitDecisions() {
            try {
                const json = await api.get(`/api/v3/benefits/employer/decisions`);
                if (json.data) {
                    return this.setActiveBenefitDecisions(json.data);
                }
            } catch (e) {
                console.log(e);
                return false;
            }
        }
    }),
};
