import React from 'react';
import PropTypes from 'prop-types';
import { Field } from './Field';

/**
 * Password field.
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const PasswordField = ({ children, ...rest }) => (
    <Field type="password" {...rest}>
        {children}
    </Field>
);

PasswordField.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

PasswordField.defaultProps = {
    children: undefined,
};
