import React, { useState, useEffect } from 'react';
import { useGet } from 'shared/hooks/useApi';
import MDIcon from 'shared/components/MDIcon';
import { MenuBarItem } from 'shared/components/MenuBarItem';
import Badge from 'shared/components/Badge';
import { isCommune } from 'shared/utils/commonUtils';

const getTooltipText = (count) => `${_trans('surcharges.tooltip')} ${_transChoice('surcharges.tooltip_count', count)}`;

/**
 * TODO: Nuuskimaan vahvistamattomien lisämaksujen määrä ja summa.
 * @returns {JSX.Element}
 * @constructor
 */
// eslint-disable-next-line react/display-name
export const SurchargeMenuBarItem = React.memo(() => {
    const [count, setCount] = useState(0);
    const [tooltip, setTooltip] = useState('');
    const [{ data, isLoading }] = useGet(`/api/v1/surcharges?state=state_open`);

    /**
     * Päivitetään badge jos saadaa tieto lisämaksun lisäämisestä.
     */
    useEffect(() => {
        const onSurchargeNotification = (e) => {
            const count = e.detail.count;
            setCount(count);
            setTooltip(getTooltipText(count));
        };
        document.addEventListener('surchargeNotification', onSurchargeNotification);
        return () => {
            document.removeEventListener('surchargeNotification', onSurchargeNotification);
        };
    }, []);

    useEffect(() => {
        if (! isLoading && data) {
            const count = data.total ?? 0;
            setCount(count);
            setTooltip(getTooltipText(count));
        }
    }, [data, setCount, isLoading]);

    return (
        <MenuBarItem tooltip={tooltip} href={`${isCommune() ? '/commune/' : '/'}home/surcharges`} title={_trans('menubar.surcharges.title')}>
            <MDIcon icon="receipt_long" modifierClass="u-color-admin" />
            { count > 0 && <Badge value={count} overlap animate type="negative" /> }
        </MenuBarItem>
    );
});

