import React from 'react';
import * as Yup from 'yup';
import { select } from '@rematch/select';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Total } from '../components/Total';
import { SurchargeAutoComplete } from './SurchargeAutoComplete';
import { SurchargeDimensionSelector } from './SurchargeDimensionSelector';
import Dialog from 'shared/components/Dialog';
import FormikMaxTextArea from 'shared/components/Formik/FormikMaxTextArea';
import validators, { errorMessages } from 'shared/validators';
import StLabel from 'shared/components/StForm/StLabel';
import StField from 'shared/components/StForm/StField';
import FormikErrors from 'shared/components/Formik/FormikErrors';
import ActionBar from 'shared/components/ActionBar';
import Button from 'shared/components/Button';
import SubmitButton from 'shared/components/SubmitButton';
import { isCommune } from 'shared/utils/commonUtils';
import confirm from 'shared/utils/confirm';

export const SurchargeDialog = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(select.surcharges.isDialogOpen);

    const surchargeList = useSelector(select.surcharges.getSurchargePricingList);
    const surcharge = useSelector((state) => select.surcharges.getEditableSurcharge(state, surchargeList));
    const surchargeOriginalUnitPrice = useSelector((state) => select.surcharges.getOriginalUnitPrice(state, surcharge.standardPricingId));

    // Kustannuspaikkojen / dimensioiden lataus
    const isLoadingCommuneSettings = useSelector((state) => state.loading.effects.communeSettings.fetchCommuneSettings);
    const isLoadingDimensionTypes = useSelector((state) => state.loading.effects.dimensions.fetchDimensionTypes);
    const isLoadingDimensions = isLoadingCommuneSettings || isLoadingDimensionTypes;

    // Jos kunta => kustannuspaikat, jos yritys => palvelumaksujen lajitteludimensiot
    const costCenters = useSelector(select.communeSettings.getCostCenters);
    const invoiceRowDimensionTypes = useSelector(select.dimensions.getSurchargeDimensionTypes);
    const invoiceDimensionType = useSelector(select.dimensions.getInvoiceGroupingDimensionType);
    const hasInvoiceDimensionType = Object.values(invoiceDimensionType).length > 0;

    // Kunnilla kustannuspaikat suoriltaan, yrityksillä palvelumaksujen lajitteludimensiot yhdistettynä
    const invoiceRowDimensions = isCommune() && invoiceRowDimensionTypes.length === 0
        ? costCenters.map((costCenter) => ({
            value: costCenter,
            name: costCenter,
        }))
        // Rullataan kaikki palvelumaksujen lajitteludimensiot läpi ja tehdään niistä yksiulotteinen lista
        : invoiceRowDimensionTypes.reduce((acc, cur) => ([
            ...acc,
            ...cur.dimensions.map(({ value, description }) => ({
                value: `${value} ${description ?? ''}`,
                name: `${value} - ${description ?? ''}`
            }))
        ]), []);

    const hasExternalServiceCharges = useSelector(select.surcharges.hasExternalServiceCharges);
    const isNewSurcharge = !surcharge;
    const isSaving = useSelector((state) => state.loading.effects.surcharges.saveSurcharge);
    const dialogTitle = isNewSurcharge ? 'Uusi lisämaksu' : 'Muokkaa lisämaksua';

    const entityText = isCommune()
        ? 'Hyvinvointialueen asetuksissa'
        : 'Yrityksen asetuksissa';

    const stateText = hasExternalServiceCharges
        ? 'päällä'
        : 'pois päältä';

    return (
        <Dialog
            isOpen={isOpen}
            title={dialogTitle}
            onClose={() => {
                dispatch.surcharges.setIsDialogOpen(false);
                dispatch.surcharges.setEditableSurcharge(null);
            }}
            initialFocus={ isNewSurcharge ? '#surchargeSelector' : '[name="unitCount"]'}
        >
            <Formik
                initialValues={isNewSurcharge
                    ? {
                        standardPricingId: '',
                        unitCount: '1',
                        unitPrice: '0',
                        description: '',
                        isExternal: hasExternalServiceCharges,
                        invoiceRowDimension: invoiceRowDimensions.length > 0 ? null : '',
                        invoiceDimension: hasInvoiceDimensionType ? null : '',
                    }
                    : surcharge
                }
                validateOnBlur
                validationSchema={
                    Yup.object().shape({
                        standardPricingId: Yup.string().required(_trans('validation.required')),
                        unitCount: Yup.string().test('unitCount', errorMessages.isDecimal, validators.isDecimal),
                        unitPrice: Yup.string().test('unitPrice', 'Hinta ei voi olla negatiivinen.', validators.isPositiveDecimal),
                        description: Yup.string(),
                        isExternal: Yup.bool(),
                        invoiceRowDimension: Yup
                            .string()
                            // Sallitaan myös null
                            .nullable()
                            // Kunhan ei lopussa ole null vaan jotakin valittuna
                            .test('dimension', errorMessages.isRequired, (value) => value !== null),
                        invoiceDimension: hasInvoiceDimensionType
                            ? Yup.string()
                                .nullable()
                                .test('dimension', errorMessages.isRequired, (value) => value !== null)
                            : Yup.string()
                                .nullable(),

                    })
                }
                onSubmit={(model) => {
                    const { standardPricingId } = model;
                    // Yritetään etsiä hintatiedon id
                    const pricingItem = surchargeList.find((surcharge) => surcharge.standardPricingId === standardPricingId);
                    const pricingItemId = pricingItem?.validPricingItem?.pricingItemId;

                    // Tallennus ja dialogi kiinni
                    dispatch.surcharges.saveSurcharge({
                        pricingItem: pricingItemId,
                        values: model,
                    }).then(() => dispatch.surcharges.setIsDialogOpen(false));
                }}
            >
                {({ setFieldValue, values }) => (
                    <Form className="o-form o-form--responsive">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <StLabel isRequired>
                            Lisämaksu
                        </StLabel>
                        <StField>
                            {isNewSurcharge && (
                                <SurchargeAutoComplete
                                    onSelect={(product) => {
                                        const { validPricingItem } = product;
                                        const unitPrice = validPricingItem ? validPricingItem.minServiceFee : '';
                                        setFieldValue('unitPrice', unitPrice);
                                    }}
                                />
                            )}
                            <FormikErrors name="standardPricingId" />
                            { !isNewSurcharge && (
                                <b>{surcharge.name} ({_currency(surchargeOriginalUnitPrice)})</b>
                            )}
                        </StField>

                        <StLabel isRequired>
                            {_trans('unit.count')}
                        </StLabel>
                        <StField>
                            <Field type="tel" name="unitCount" size={3} onFocus={(event) => event.target.select()} />
                            <FormikErrors name="unitCount" />
                        </StField>

                        <StLabel isRequired>
                            {_trans('unit.price')}
                        </StLabel>
                        <StField>
                            <Field type="tel" name="unitPrice" size={3} onFocus={(event) => event.target.select()} />
                            <FormikErrors name="unitPrice" />
                        </StField>

                        <StLabel>
                            {_trans('text.total')}
                        </StLabel>
                        <StField>
                            <strong className="c-heading-title">
                                <Total />
                            </strong>
                        </StField>

                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <StLabel tooltip={`Laskutustapatieto haettu automaattisesti ${isCommune() ? ' kunnan' : ' yrityksen'} asetuksista. Ota ulkoinen laskutus pois päältä mikäli asiakas maksaa lisämaksun maksuerän mukana`}>
                            Laskutus
                        </StLabel>
                        <StField>
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label>
                                <Field
                                    type="checkbox"
                                    name="isExternal"
                                    onChange={() => {
                                        // Varmistetaan vielä että käyttäjä varmasti haluaa muuttaa automaattisesti
                                        // asetuksista haettua arvoa JOS se poikkeaa valitusta. Jos muutetaan takaisin
                                        // automaattisesti haettuun arvoon ei kysellä.
                                        if (hasExternalServiceCharges === values.isExternal) {
                                            confirm(`Haluatko varmasti muuttaa automaattista asetusta? ${entityText} laskutustieto on ${stateText}`, {
                                                proceedLabel: 'Kyllä, muuta asetus',
                                                alert: true,
                                            }).then(
                                                () => {
                                                    setFieldValue('isExternal', !values.isExternal);
                                                },
                                                () => {
                                                }
                                            );
                                        } else {
                                            setFieldValue('isExternal', ! values.isExternal);
                                        }
                                    }}
                                />
                                <span className="u-align-middle">Ulkoinen laskutus</span>
                            </label>
                            <span>
                                {_transMd('surcharges.dialog.external_service_charge_help', {
                                    entity: entityText,
                                    url: isCommune() ? '/commune/settings' : '/company/settings',
                                    state: stateText,
                                }, { targetBlank: true })}
                            </span>
                        </StField>

                        {hasInvoiceDimensionType && (
                            <SurchargeDimensionSelector
                                dimensions={(invoiceDimensionType?.dimensions ?? []).map(({ value, description }) => ({
                                    value: `${value} ${description ?? ''}`,
                                    name: `${value} - ${description ?? ''}`
                                }))}
                                isLoadingDimensions={isLoadingDimensions}
                                label="Laskun dimensio"
                                name="invoiceDimension"
                                placeholder="Valitse laskun dimensio"
                            />
                        )}

                        <SurchargeDimensionSelector
                            dimensions={invoiceRowDimensions}
                            isLoadingDimensions={isLoadingDimensions}
                            label={isCommune() && invoiceRowDimensionTypes.length === 0
                                ? 'Kustannuspaikka'
                                : (hasInvoiceDimensionType ? 'Rivitiedon dimensio' : 'Dimensio') }
                            name="invoiceRowDimension"
                            placeholder={isCommune() && invoiceRowDimensionTypes.length === 0
                                ? 'Valitse kustannuspaikka'
                                : (
                                    hasInvoiceDimensionType
                                        ? 'Valitse laskun rivitiedon dimensio'
                                        : 'Valitse dimensio'
                                )}
                        />

                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <StLabel>
                            Selite
                        </StLabel>
                        <StField>
                            <FormikMaxTextArea name="description" rows={4} maxLength={255} modifierClass="u-1/1" />
                        </StField>
                        <ActionBar>
                            <Button flat onClick={() => dispatch.surcharges.setIsDialogOpen(false)}>
                                {_trans('button.cancel')}
                            </Button>
                            <SubmitButton primary isPending={isSaving}>
                                Tallenna lisämaksu
                            </SubmitButton>
                        </ActionBar>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};
