import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Value } from 'shared/components/Value';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { Placeholder } from 'shared/components';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';
import { get } from 'shared/utils/get';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { resolveSalaryPostFix } from 'ContractV3/utils/salaries/resolveSalaryPostFix';
import { salaryTypes } from 'shared/constants/salaryTypes';

/**
 * Palkka yhteensä.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TotalSalary = (props) => {
    const { name, label, arrayName, values } = props;
    const isJobContract = useSelector(select.contract.isJobContract);
    const isAssignmentContract = useSelector(select.contract.isAssignmentContract);
    const salaryType = values[fieldNames.SALARY_TYPE];

    const partTimePercentage = parseFloat(get(values, resolveArrayName(arrayName, fieldNames.PART_TIME_PERCENTAGE)));
    const initialPersonalSalary = parseFloat(get(values, resolveArrayName(arrayName, fieldNames.INITIAL_PERSONAL_SALARY)));
    const initialSalary = parseFloat(get(values, resolveArrayName(arrayName, fieldNames.INITIAL_SALARY)));
    const isPendingSalary = get(values, resolveArrayName(arrayName, fieldNames.IS_PENDING_SALARY));

    const isFetchingTableSalary = useSelector((state) => state.loading.effects.tableSalary.fetchTableSalary);

    const totalSalary = (isJobContract && parseInt(salaryType, 10) === salaryTypes.PER_MONTH)
        ? (parseFloat(initialSalary) + initialPersonalSalary) * (partTimePercentage / 100)
        : parseFloat(initialSalary) + initialPersonalSalary;

    if (isNaN(totalSalary) || initialPersonalSalary < 0) return null;

    const divisorGroups = values[fieldNames.CONTRACT_GROUP_DIVISORS];

    const divisors = (divisorGroups ?? []).map((employee) => ({
        userName: employee.userName,
        divisorPercent: employee.divisor,
        divisor: (employee.divisor / 100) * totalSalary
    }));

    return (
        <Fragment>
            <SummaryListItem term={label}>
                <Placeholder isPending={isFetchingTableSalary}>
                    <Value ariaDescribedBy={name}>
                        {isPendingSalary
                            ? _trans('Odottaa indeksikorotuksia', {}, 'jobContract')
                            : resolveSalaryPostFix(salaryType, _currency(totalSalary))
                        }
                    </Value>
                </Placeholder>
            </SummaryListItem>

            {isAssignmentContract && divisors.length > 1 && divisors.map((employee, key) => (
                <SummaryListItem key={key} term={employee.userName}>
                    <Value>
                        {_currency(employee.divisor)} ({employee.divisorPercent.toFixed(0)}%)
                    </Value>
                </SummaryListItem>
            ))}
        </Fragment>
    );
};

TotalSalary.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    arrayName: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
};

export default TotalSalary;
