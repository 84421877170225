import { fromJS, List } from 'immutable';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { promised as request } from 'BubbleWrapAgent';
import { actions as notificationActions } from 'shared/stores/notifications';

/**
 * Action types and creators
 */
const namespace = 'contractVersions';

const CONTRACT_VERSIONS_GET_REQUEST = `${namespace}/CONTRACT_VERSIONS_GET_REQUEST`;
const contractVersionsGetRequest = () => ({ type: CONTRACT_VERSIONS_GET_REQUEST });

const CONTRACT_VERSIONS_GET_OK = `${namespace}/CONTRACT_VERSIONS_GET_OK`;
const contractVersionsGetOk = (list) => ({ type: CONTRACT_VERSIONS_GET_OK, list: List(list) });

const CONTRACT_VERSIONS_GET_FAILED = `${namespace}/CONTRACT_VERSIONS_GET_FAILED`;
const contractVersionsGetFailed = () => ({ type: CONTRACT_VERSIONS_GET_FAILED });

const CONTRACT_VERSIONS_RESET = `${namespace}/CONTRACT_VERSIONS_RESET`;
const resetContractVersions = () => ({ type: CONTRACT_VERSIONS_RESET });
/**
 * Initial state
 */
const initialState = {
    versions: List([]),
    isFetchingContractVersions: false,
};

/**
 * Reducer
 */
export const contractVersionsReducer = (state = fromJS(initialState), action) => {
    switch (action.type) {

        // Get contract versions
        case CONTRACT_VERSIONS_GET_REQUEST:
            return state
                .set('isFetchingContractVersions', true);

        case CONTRACT_VERSIONS_GET_OK:
            return state
                .set('versions', action.list)
                .set('isFetchingContractVersions', false);

        case CONTRACT_VERSIONS_GET_FAILED:
            return state
                .set('isFetchingContractVersions', false);

        case CONTRACT_VERSIONS_RESET:
            return state
                .set('versions', List([]));

        default:
            return state;
    }
};

/**
 * Actions
 */

/**
 * Hakee sopimuksen eri versiot ja järjestää ne uusimmasta vanhimpaan.
 * @param contractId
 */
const fetchContractVersions = (contractId) => (dispatch) => {
    dispatch(contractVersionsGetRequest());

    request
        .get(Routing.generate('contracts_api_1_get_contract_versions', {
            contract: contractId,
        }))
        .then((response) => {
            if (response.statusCode === 200) {
                // Järjestetään versiot uusimmasta vanhimpaan
                const contractVersions = _.sortBy(response.body, 'dataStartDate').reverse();
                dispatch(contractVersionsGetOk(contractVersions));
            } else {
                dispatch(contractVersionsGetFailed());
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('notifications.error.fetch_job_titles_failed')
                }));
                return response;
            }
        }, () => {
            dispatch(contractVersionsGetFailed());
            dispatch(notificationActions.addNotification({
                type: 'error',
                message: _trans('notifications.error.fetch_job_titles_failed')
            }));
        });
};

/**
 * Selectors
 */
const getRootSelector = (state) => state.contractVersions;
const getContractVersions = (state) => getRootSelector(state).get('versions');
const isFetchingContractVersions = (state) => getRootSelector(state).get('isFetchingContractVersions');
const hasUnacceptedVersions = () => createSelector(
    getContractVersions,
    (versions) => {
        console.log(versions);
        /*if (_.includes(
                    [
                        contractDataStates.ACCEPTED_PROPOSAL,
                        contractDataStates.PROPOSAL,
                        contractDataStates.REJECTED_PROPOSAL,
                        contractDataStates.DRAFT,
                    ], latestData.dataType)
            ) {*/
    }
);

/**
 * Exports
 */
export const contractVersionsActions = {
    fetchContractVersions,
    resetContractVersions,
};

export const contractVersionsSelectors = {
    getContractVersions,
    isFetchingContractVersions,
    hasUnacceptedVersions,
};

export default {
    reducer: contractVersionsReducer,
    actions: contractVersionsActions,
    selectors: contractVersionsSelectors,
};
