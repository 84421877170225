import React from 'react';
import PropTypes from 'prop-types';
import { Field } from './Field';

/**
 * List of radiobuttons.
 * @param children
 * @param name
 * @param options
 * @param valueKey
 * @param labelKey
 * @returns {JSX.Element}
 * @constructor
 */
export const RadioListField = ({ name, options, valueKey, labelKey, ...rest }) => (
    <ul className="o-list-bare u-margin-none u-line-height-default" role="radiogroup" aria-labelledby={name} aria-required={rest.required || false} id={name}>
        {options.map((option, key) => (
            <li className="o-list-bare__item u-margin-vertical-tiny" key={key}>
                <label>
                    <Field type="radio" name={name} disabled={option.disabled ?? false} value={option[valueKey]} {...rest} />
                    {option[labelKey]}
                </label>
            </li>
        ))}
    </ul>
);

RadioListField.propTypes = {
    name: PropTypes.string.isRequired,
    /**
     * List of options for checkboxes.
     */
    options: PropTypes.array.isRequired,
    /**
     * Key to be used for value.
     */
    valueKey: PropTypes.string,
    /**
     * Key to be used for label.
     */
    labelKey: PropTypes.string,
};

RadioListField.defaultProps = {
    valueKey: 'value',
    labelKey: 'label',
    required: false,
};
