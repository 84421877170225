import React, { Fragment, useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { select } from '@rematch/select';
import { useSelector, useDispatch } from 'react-redux';
import { ForeclosureInformationFields } from './ForeclosureInformationFields';
import { NonPaymentRestrictionFields } from './NonPaymentRestrictionFields';
import { ForeclosureContactFields } from './ForeclosureContactFields';
import {
    ActionBar,
    Badge,
    Button,
    StLabel,
    StField,
    StHelp,
    SubmitButton,
} from 'shared/components';
import validators, { errorMessages } from 'shared/validators';
import { CheckboxField, Form, FormField, FormikErrors, FormikRfReferenceNumber } from 'shared/components/Formik';
import {
    EXPIRES_UNTIL_DATE,
    INDEFINITE,
    MAX_FORECLOSURE_AMOUNT
} from 'shared/UserDetails/containers/shared/Foreclosure/constants';

const initialValues = {
    // Kirjauspäivä
    // startDate: '',
    // Maksukiellon numero
    payBanNumber: '',
    // Maksukiellon RF-viite
    payBanRFReferenceNumber: 'RF',
    // Onko tieto saapunut elektronisesti vaiko syötetty paperilta
    // isElectronic: false,

    // Perittävän ulosoton määrä. Jos merkitään toistaiseksi voimassa olevaksi asetetaan pellin alla tämä 99999999999999.
    foreclosureAmount: MAX_FORECLOSURE_AMOUNT,

    // Onko ulosotto voimassa toistaiseksi (eli ulosotetaanko maailman tappiin asti)
    isIndefiniteAmount: true,

    isOneThird: false,

    // Muualta saatu tulo / kk
    otherReceivedIncome: '',
    // Velalliselle jätettävä suojaosuus
    employeeRefugeIncome: '',
    // Velalliselle lisäksi jätettävä määrä / kjk
    employeeRefugeIncomeExtra: '',
    // Vapaakuukaudet
    noForeclosureMonths: [],
    // Ulosoton maksimimäärä kk:ssa
    maxForeclosurePerMonth: '',
    // Rajoitukset. Lista alku- ja loppuvuodesta sekä kuukaudesta ja rahamäärästä.
    restrictions: [],
    // Omat muistiinpanot
    description: '',

    // Täppä jolla kulukorvauksia ei lasketa ulosoton piiriin
    isFamilyCareCostReimbursementsExcluded: false,
    isTravelingCompensationsExcluded: false,

    // Asiaa hoitavan viranomaisen tiedot
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    enforcementOfficeName: '',
    enforcementOfficeAddress: '',
    enforcementOfficeEmail: '',
    showDescription: false,
    foreclosureType: INDEFINITE,
};

export const ForeclosureForm = hot(({ values }) => {
    const isAllowAssignmentContracts = useSelector(select.foreclosure.isAllowAssignmentContracts);
    const employee = useSelector(select.foreclosure.getUserId);
    const open = useSelector(select.foreclosure.getOpenForeclosure);
    const isNew = ! (open?.foreclosureId);
    const isCopy = useSelector(select.foreclosure.isCopy);
    const isSaving = useSelector((state) => state.loading?.effects.foreclosure.saveForeclosure ?? false);
    const [isShowDescription, setShowDescription] = useState(values.showDescription ?? false);
    const dispatch = useDispatch();
    const { foreclosureId } = values;
    const hasMaterial = values?.foreclosureMaterial?.foreclosureMaterialId ?? false;

    const collectedForeclosedAmount = useSelector(select.foreclosure.getCollectedForeclosedAmount);

    /*Yup.addMethod(Yup.array, 'unique', function(errorMessage, path) {
        console.log('PATH', path);
        return this.test('unique', errorMessage, function(restrictions) {
            const { path, createError } = this;
            console.log(path, restrictions, errorMessage);
            const monthSpans = restrictions.map(({ duration }) => ({
                start: duration.start,
                end: duration.end,
            }));
            const errorIndex = restrictions.findIndex(({ duration }, ridx) => {
                // if (! validators.isMonthSpan(duration)) return false;

                const test = monthSpans.filter((monthSpan, index) => index !== ridx);
                console.log(test);

                console.log(duration, ridx, validators.isMonthSpan(duration) && validators.isMonthSpanUnavailable(duration, test));
                return (
                    validators.isMonthSpan(duration) && validators.isMonthSpanUnavailable(duration, test)
                );
            });
            console.log(errorIndex);

            if (! errorIndex) {
                return createError({ path: `${path}.0.duration`, message: errorMessage });
            }
            // return createError({ path: `${path}.0.duration`, message: errorMessage });
        });
    });*/

    return (
        <Formik
            validateOnBlur
            onSubmit={(values) => {
                if (isCopy) {
                    dispatch.foreclosure.makeCopyAndCloseCurrent(values);
                } else {
                    dispatch.foreclosure.saveForeclosure({ employee, values });
                }
            }}
            initialValues={Object.assign({}, initialValues, values)}
            validationSchema={Yup.object().shape({
                payBanNumber: Yup.string().required(_trans('validation.required')),
                payBanRFReferenceNumber: Yup.string()
                    .required(_trans('validation.required'))
                    .test('payBanRFReferenceNumber', errorMessages.isRf, validators.isRf),
                foreclosureAmount: Yup.string()
                    .required(_trans('validation.required'))
                    .test('foreclosureAmount', errorMessages.isPositiveDecimal, validators.isPositiveDecimal)
                    .when('foreclosureType', {
                        // Tarkistetaan vain jos "Toistaiseksi" ei ole päällä eikä ole uusi maksukielto eikä kopio
                        is: (foreclosureType) => foreclosureType !== INDEFINITE && !isNew && !isCopy,
                        then: Yup.string()
                            .test(
                                'withinLimits',
                                _trans('foreclosure.validation.foreclosure_amount_exceeds_forclosed_amount', {
                                    foreclosedAmount: _currency(collectedForeclosedAmount),
                                }),
                                // Perittävän summan on oltava suurempi kuin jo perityn summan ja yli 0.
                                (value) => collectedForeclosedAmount === 0 || (parseFloat(value) > collectedForeclosedAmount && collectedForeclosedAmount > 0))
                    }),
                otherReceivedIncome: Yup.string()
                    .test(
                        'otherReceivedIncome',
                        errorMessages.isPositiveDecimal,
                        (value) => !value || (value && validators.isPositiveDecimal(value))
                    ),
                employeeRefugeIncome: Yup.string()
                    .required(_trans('validation.required'))
                    .test(
                        'employeeRefugeIncome',
                        errorMessages.isPositiveDecimal,
                        validators.isPositiveDecimal
                    ),
                employeeRefugeIncomeExtra: Yup.string()
                    // Jos rajoituskuukausia on annettu ei sallita Velalliselle lisäksi jätettävään määrään
                    // mitään.
                    .when('restrictions', {
                        is: (restrictions) => restrictions.length > 0,
                        then: Yup.string().test(
                            'notRequiredEmployeeRefugeIncomeExtra',
                            _trans('foreclosure.validation.employee_refuge_income_extra_not_required'),
                            validators.isNotRequired
                        ),
                        otherwise: Yup.string().test(
                            'employeeRefugeIncomeExtra',
                            errorMessages.isPositiveDecimal,
                            (value) => !value || (value && validators.isPositiveDecimal(value))
                        ),
                    }),
                noForeclosureMonths: Yup.array().of(
                    Yup.object({
                        start: Yup.string(),
                        end:Yup.string(),
                    })
                        .required(_trans('validation.required'))
                        .test('noForeclosureMonths', errorMessages.isMonthSpan, validators.isMonthSpan)
                ),
                maxForeclosurePerMonth: Yup.string()
                    .test(
                        'maxForeclosurePerMonth',
                        errorMessages.isPositiveDecimal,
                        (value) => !value || (value && validators.isPositiveDecimal(value))
                    ),
                restrictions: Yup.array().of(
                    Yup.object({
                        duration: Yup.object({
                            start: Yup.string(),
                            end:Yup.string(),
                        })
                            .required(_trans('validation.required'))
                            .test('duration', errorMessages.isMonthSpan, validators.isMonthSpan),
                        amount: Yup.string()
                            .required(_trans('validation.required'))
                            .test('amount', errorMessages.isPositiveDecimal, validators.isPositiveDecimal),
                    })
                ),
                description: Yup.string(),
                contactName: Yup.string(),
                contactPhone: Yup.string(),
                contactEmail: Yup.string().email(_trans('validation.isEmail')),
                enforcementOfficeName: Yup.string(),
                enforcementOfficeAddress: Yup.string(),
                enforcementOfficeEmail: Yup.string().email(_trans('validation.isEmail')),
                endDate: Yup.string().when('foreclosureType', {
                    is: (foreclosureType) => foreclosureType === EXPIRES_UNTIL_DATE,
                    then: Yup.string().test('endDate', errorMessages.isDate, (value) => validators.isDate(value)),
                }),
            })}
        >
            <Form className="o-form o-form--responsive">
                <fieldset>
                    <legend>
                        {_trans('foreclosure.form.pay_ban.legend')}
                    </legend>
                    <StLabel htmlFor="payBanNumber" isRequired>
                        {_trans('foreclosure.form.pay_ban.number.label')}
                    </StLabel>
                    <StField id="payBanNumber">
                        <Field
                            type="text"
                            name="payBanNumber"
                        />
                        <FormikErrors name="payBanNumber" />
                    </StField>
                    <StLabel htmlFor="payBanRFReferenceNumber" isRequired>
                        {_trans('foreclosure.form.pay_ban.rf_reference_number.label')}
                    </StLabel>
                    <StField>
                        <FormikRfReferenceNumber
                            id="payBanRFReferenceNumber"
                            name="payBanRFReferenceNumber"
                            aria-describedby="payBanRFReferenceNumberHelp"
                        />
                        <StHelp id="payBanRFReferenceNumberHelp">
                            {_trans('foreclosure.form.pay_ban.rf_reference_number.help')}
                        </StHelp>
                        <FormikErrors name="payBanRFReferenceNumber" />
                    </StField>
                    {values.foreclosureId && (
                        <Fragment>
                            <StLabel htmlFor="foreclosureType">
                                {_trans('foreclosure.form.pay_ban.type.label')}
                            </StLabel>
                            <StField>
                                <Badge
                                    id="foreclosureType"
                                    mdIcon={ hasMaterial ? 'bolt' : 'article' }
                                    type="neutral"
                                    value={_trans(`foreclosure.form.pay_ban.type.options.${ hasMaterial ? 'electronically' : 'on_paper' }`)}
                                />
                            </StField>
                        </Fragment>
                    )}
                    {(isAllowAssignmentContracts) && (
                        <FormField
                            name="isFamilyCareCostReimbursementsExcluded"
                            label={_trans('foreclosure.form.foreclosure.isFamilyCareCostReimbursementsExcluded.label')}
                            helpText={_trans('foreclosure.form.foreclosure.isFamilyCareCostReimbursementsExcluded.help')}
                        >
                            <CheckboxField
                                name="isFamilyCareCostReimbursementsExcluded"
                                label={_trans('foreclosure.form.foreclosure.isFamilyCareCostReimbursementsExcluded.text')}
                            />
                        </FormField>
                    )}
                    {(isAllowAssignmentContracts) && (
                        <FormField
                            name="isTravelingCompensationsExcluded"
                            label={_trans('Kilometrikorvaukset', {}, 'extract')}
                            helpText={_trans('Valitse tämä vain tilanteessa jossa ulosottoviranomaiselta on päätös että ulosottoa ei pidätetä kilometrikorvauksista.', {}, 'extract')}
                        >
                            <CheckboxField
                                name="isTravelingCompensationsExcluded"
                                label={_trans('Ei peritä ulosottoa', {}, 'extract')}
                            />
                        </FormField>
                    )}
                </fieldset>

                <ForeclosureInformationFields />
                <NonPaymentRestrictionFields />
                <ForeclosureContactFields />
                <StLabel htmlFor="description" tooltip={_trans('Muistiinpanot eivät vaikuta ulosoton pidätykseen', {}, 'extract')}>
                    {_trans('Maksukiellon muistiinpanot', {}, 'extract')}
                </StLabel>
                <StField>
                    <ActionBar>
                        <Button flat mdIcon="edit" onClick={() => setShowDescription(!isShowDescription)}>
                            {_trans('Kirjoita ulosoton muistiinpano', {}, 'extract')}
                        </Button>
                    </ActionBar>
                    {(isShowDescription) && (
                        <Fragment>
                            <Field
                                placeholder={_trans('Muistiinpanot eivät vaikuta ulosoton pidätykseen', {}, 'extract')}
                                as="textarea"
                                id="description"
                                name="description"
                                rows={4}
                                className="u-1/1"
                                aria-describedby="descriptionHelp"
                            />
                            <StHelp id="descriptionHelp">
                                {_trans('foreclosure.form.foreclosure.description.help')}
                            </StHelp>
                        </Fragment>
                    )}
                </StField>
                <ActionBar modifierClass="u-margin-top">
                    <Button mdIcon="arrow_back" onClick={() => dispatch.foreclosure.goBack()} flat>
                        {_trans('button.back')}
                    </Button>
                    <SubmitButton isPending={isSaving}>
                        {_trans((foreclosureId && ! isCopy)
                            ? 'foreclosure.form.modify_foreclosure.button'
                            : 'foreclosure.form.add_foreclosure.button'
                        )}
                    </SubmitButton>
                </ActionBar>
            </Form>
        </Formik>
    );
});
