import _transObj from 'shared/utils/_transObj';

/**
 * Optionsit lokalisaation mukaan.
 * @param options
 * @returns {*|*[]}
 */
export const parseOptions = (options) => (
    Array.isArray(options)
        ? options.map((option) => ({
            name: option.name,
            value: option.value,
            label: _transObj(option.label),
        }))
        : []
);
