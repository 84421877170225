import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Apukomponentti hyvin monesti käytettyyn esitystapaan: kuva vasemmalla, sisältö (teksti) oikealla.
 * @param props
 * @returns {*}
 * @constructor
 */
const Media = (props) => {
    const {
        image,
        verticalAlign,
        children,
        isImageAfterContent,
    } = props;

    return (
        <div
            className={classNames('o-flag o-flag--tiny', {
                'o-flag--top': verticalAlign === 'top',
                'o-flag--bottom': verticalAlign === 'bottom',
                'o-flag--reverse': isImageAfterContent,
            })}
        >
            <span className="o-flag__img u-font-size-0">
                {image}
            </span>
            <span className="o-flag__body">
                {children}
            </span>
        </div>
    );
};

Media.propTypes = {
    image: PropTypes.node.isRequired,
    verticalAlign: PropTypes.oneOf(['', 'top', 'bottom']),
    children: PropTypes.node.isRequired,
    isImageAfterContent: PropTypes.bool,
};

Media.defaultProps = {
    verticalAlign: '',
    isImageAfterContent: false,
};

export default Media;

