import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import copy from 'clipboard-copy';

/**
 * Leikepöydälle kopioinnin automatisointi. Käärii sisäänsä
 */
export default class ClipboardCopy extends PureComponent {
    state = {
        hasCopied: false,
    };

    /**
     * Kopioidaan annettu teksti leikepöydälle.
     */
    onClipboardCopy = () => {
        // Kopioidaan uudestaan 450ms kuluttua (animaation kesto).
        if (! this.state.hasCopied) {
            copy(this.props.textToCopy);
            this.setState({
                hasCopied: true,
            });

            setTimeout(() => this.setState({ hasCopied: false }), 450);
        }
    };

    render() {
        const { children, isButtonWrapped } = this.props;

        if (! isButtonWrapped && React.Children.count(children) !== 1) {
            console.error('Must have only one child when not wrapped.');
            return null;
        }

        if (! isButtonWrapped && typeof children !== 'string') {
            return (
                <span className="u-position-relative">
                    {React.cloneElement(children, {
                        onClick: this.onClipboardCopy,
                    })}
                    <span
                        className={classNames('c-clipboard-copy__text o-pin o-pin--top o-pin--left u-pointer-events-none', {
                            'c-clipboard-copy__text--copied': this.state.hasCopied,
                        })}
                        data-label={_trans('text.copied')}
                    />
                </span>
            );
        }

        return (
            <button onClick={this.onClipboardCopy} className="c-clipboard-copy u-cursor-pointer">
                {children}
                <span
                    className={classNames('c-clipboard-copy__text u-padding-left-tiny u-position-relative', {
                        'c-clipboard-copy__text--copied': this.state.hasCopied,
                    })}
                    data-label={_trans('text.copied')}
                >
                    {_trans('text.copy_to_clipboard')}
                </span>
            </button>
        );
    }
}

ClipboardCopy.propTypes = {
    // Sisältö jonka ympärille kääritään.
    children: PropTypes.node.isRequired,

    // Leikepöydälle kopioitava teksti.
    textToCopy: PropTypes.string.isRequired,

    // Kääritäänkö sisältö erikseen nappiin vai luodaanko vain kopio jossa kopiointieventit
    isButtonWrapped: PropTypes.bool,
};

ClipboardCopy.defaultProps = {
    isButtonWrapped: false,
};

