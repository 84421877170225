import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';
import Popover from 'shared/components/Popover';
import Button from 'shared/components/Button';
import OptionList from 'shared/components/OptionList';
import InputGroup from 'shared/components/InputGroup';

/**
 * Yhdistelmänappi ARIA-tuella.
 * Käytetään useamman actionin yhdistämiseen saman napin alle.
 */
const ComboButton = (props) => {
    const {
        isDisabled,
        isPending,
        isPrimary,
        isFullWidth,
        modifierClass,
        title,
        titleForCaret,
        isDisabledCaret,
        hrefTo
    } = props;

    const [dimensions, setDimensions] = useState({ width: -1, height: -1 });

    /**
     * Jotain valittiin. Välitä ylöspäin.
     * @param item
     */
    const onSelect = (item) => {
        const { options } = props;
        const index = options.findIndex((option) => option.label === item.label);

        // Etsitään mahdollinen action joka ammutaan kun item valitaan
        if (index in options) {
            const option = options[index];
            if (typeof option.onSelect === 'function') {
                option.onSelect();
            }
        }

        props.onSelect(item);
    };

    /**
     * Valittu primaariaction. Parsitaan ainoastaan tärkeät arvot mukaan.
     * @param item
     * @param event
     */
    const onPrimarySelect = (item, event) => {
        event.stopPropagation();

        onSelect({
            index: -1, // Koska ensimmäistä itemiä ei ole
            action: item.action ?? null,
            value: item.value ?? null,
            label: item.label ?? null,
        });
    };

    const primaryAction = props.options[0];
    const options = props.options.slice(1);

    return (
        <Measure
            bounds
            // Päivittää koko komponentin leveyden ja korkeuden.
            // Käytetään tarvittaessa Popoverin leveyden määrittämiseen.
            onResize={(contentRect) => setDimensions(contentRect.bounds)}
        >
            {({ measureRef }) => (
                <span ref={measureRef}>
                    <InputGroup
                        modifierClass={`${modifierClass} ${isFullWidth ? 'u-1/1' : ''}`}
                        title={title}
                    >
                        <Button
                            disabled={isDisabled}
                            mdIcon={_.get(primaryAction, 'icon', null)}
                            href={hrefTo ? hrefTo : null}
                            onClick={(event) => onPrimarySelect(primaryAction, event)}
                            modifierClass="u-padding-horizontal u-margin-right-none"
                            inProgress={isPending}
                            primary={isPrimary}
                        >
                            { primaryAction.label && primaryAction.label }
                        </Button>
                        <Popover
                            popoverType="list"
                            position="bottom-end"
                            isArrowVisible={false}
                            hasContentPadding={false}
                            minWidth={dimensions.width}
                        >
                            <Button
                                title={titleForCaret}
                                primary={isPrimary}
                                mdIcon="keyboard_arrow_down"
                                iconSize="tiny"
                                disabled={isDisabled || isPending || isDisabledCaret}
                                modifierClass="c-combo-button__trigger"
                            />
                            {({ onClosePopover }) => (
                                <OptionList
                                    items={options}
                                    onSelect={(item) => {
                                        onSelect(item);

                                        // Suljetaan Popover valinnan jälkeen
                                        onClosePopover();
                                    }}
                                />
                            )}
                        </Popover>
                    </InputGroup>
                </span>
            )}
        </Measure>
    );
};

ComboButton.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        onSelect: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        icon: PropTypes.string,
    })),
    hrefTo: PropTypes.string,
    onSelect: PropTypes.func,
    isDisabled: PropTypes.bool,
    isPending: PropTypes.bool,
    isPrimary: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    modifierClass: PropTypes.string,
    isDisabledCaret: PropTypes.bool,
    title: PropTypes.string,
    titleForCaret: PropTypes.string,
};

ComboButton.defaultProps = {
    options: {},
    onSelect: () => {},
    hrefTo: '',
    isDisabled: false,
    isPending: false,
    isPrimary: false,
    isFullWidth: false,
    modifierClass: '',
    isDisabledCaret: false,
    title: '',
    titleForCaret: ''
};

export default ComboButton;
