import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import Button from 'shared/components/Button';
import mainBreakPoints from 'shared/constants/breakpoints';

/**
 * Luo napin ikonilla. Erikoiskomponentti joka pienillä laitteilla teksti häviää ja pelkkä ikoni jää näkyviin.
 * TODO: Breakpointit sekä JS:ään että SCSS:ään yhdestä yhteisestä yml-asetustiedostosta.
 * @param props
 * @returns {*}
 * @constructor
 */
function IconButton(props) {
    return (
        <Button mdIcon={props.mdIcon} {...props}>
            {useMediaQuery({ query: `(min-device-width: ${props.minDeviceWidth}px)` }) && (
                <span>
                    {props.children}
                </span>
            )}
        </Button>
    );
}

IconButton.propTypes = {
    mdIcon: PropTypes.string.isRequired,
    minDeviceWidth: PropTypes.number,
    children: PropTypes.node.isRequired,
};

IconButton.defaultProps = {
    minDeviceWidth: mainBreakPoints.SMALL,
};

export default IconButton;
