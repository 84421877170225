import _ from 'lodash';
import moment from 'moment';
import api from 'api';
import { taxCardTypes } from 'shared/constants/taxCardTypes';

// Tämä näkyy sen aikaa kun ladataan tietoja.
const placeholderTaxCards = [
    {
        taxCardId: 1,
        startDate: '2019-01-01',
        taxDeduction1: 10,
        incomeLimit1: 500,
    },
    {
        taxCardId: 2,
        startDate: '2019-01-01',
        taxDeduction1: 10,
        incomeLimit1: 500,
    },
];

export default {
    state: {
        taxCards: placeholderTaxCards,
        activeTaxCard: [],
        activeTaxCardId: null,
        usedTaxCards: [],
        veroFetchedTaxCards: [],
        relatedContracts: [],
        selectedTaxCardId: null,
        jobContractDataId: null,
        contractType: null,
        veroTaxCard: null,
        isEditMode: false,
        isVeroApiEnabled: false,
        isPrePaymentRegisterEmployee: false,
        openPayrollsWithOldTaxCard: [],
        isOverrideVeroFetch: false,
        taxCardTypeOverride: '',
    },
    reducers: {
        setTaxCards: (state, payload) => ({ ...state, taxCards: payload }),
        setActiveTaxCard: (state, payload) => ({ ...state, activeTaxCard: payload }),
        setActiveTaxCardId: (state, payload) => ({ ...state, activeTaxCardId: payload }),
        setUsedTaxCards: (state, payload) => ({ ...state, usedTaxCards: payload }),
        setVeroFetchedTaxCards: (state, payload) => ({ ...state, veroFetchedTaxCards: payload }),
        setSelectedTaxCardId: (state, payload) => ({ ...state, selectedTaxCardId: payload }),
        setJobContractDataId: (state, payload) => ({ ...state, jobContractDataId: payload }),
        setContractType: (state, payload) => ({ ...state, contractType: payload }),
        setIsEditMode: (state, payload) => ({ ...state, isEditMode: payload }),
        setIsVeroApiEnables: (state, payload) => ({ ...state, isVeroApiEnabled: payload }),
        setRelatedContracts: (state, payload) => ({ ...state, relatedContracts: payload }),
        setIsPrePaymentRegisterEmployee: (state, payload) => ({ ...state, isPrePaymentRegisterEmployee: payload }),
        setOpenPayrollsWithOldTaxCard: (state, payload) => ({ ...state, openPayrollsWithOldTaxCard: payload }),
        setIsOverrideVeroFetch: (state, payload) => ({ ...state, isOverrideVeroFetch: payload }),
        setTaxCardTypeOverride: (state, taxCardTypeOverride) => ({ ...state, taxCardTypeOverride }),
    },
    selectors: {
        isPrePaymentRegisterEmployee: (state) => state.isPrePaymentRegisterEmployee,
        getTaxCards: (state) => state.taxCards,
        getActiveTaxCard: (state) => state.activeTaxCard,
        getActiveTaxCardId: (state) => state.activeTaxCardId,
        isOverrideVeroFetch: (state) => state.isOverrideVeroFetch,
        getPendingTaxCards: (state) => state.taxCards.filter((taxCard) => {
            if (state.activeTaxCard.length === 0) {
                return taxCard.isPending;
            }

            if (state.activeTaxCardId === taxCard.taxCardId) {
                return false;
            }

            if (taxCard.isVeroFetched && ! taxCard.isActiveVeroTaxCard) {
                return false;
            }

            // Näytetään tulossa olevan jos on seauraavalle vuodelle, kuin voimassa oleva
            if (new Date(state.activeTaxCard.startDate).getFullYear() < new Date(taxCard.startDate).getFullYear()) {
                return true;
            }

            // Jos kortti on edelliselle vuodelle kuin aktiivinen, ei näytetä tulossa olevana.
            if (new Date(state.activeTaxCard.startDate).getFullYear() > new Date(taxCard.startDate).getFullYear()) {
                return false;
            }

            // Kumpikaan ei ole veroapista tullut
            if (! state.activeTaxCard.isVeroFetched && ! taxCard.isVeroFetched) {
                return taxCard.isPending;
            }

            // Kumpikin on tullu apista
            if (state.activeTaxCard.isVeroFetched && taxCard.isVeroFetched) {
                // Jos haku päivä on suurempi kuin aktiivisen hakupäivä
                return new Date(state.activeTaxCard.veroFetchDate) < new Date(taxCard.veroFetchDate);
            }

            // Aktiivinen on käsin syötetty, vertaillaan apista tulleeseen.
            if (taxCard.isVeroFetched) {
                // Näytetään apista tullut jos sen hakupäivä on myöhemmin kun käsin syötetty on luotu
                return new Date(state.activeTaxCard.created) < new Date(taxCard.veroFetchDate);
            }

            // päivnvastoin kuin edellesessä kommentissa
            return new Date(state.activeTaxCard.veroFetchDate) < new Date(taxCard.created);
        }),
        getExpiredTaxCards: (state) => state.taxCards.filter((taxCard) => {
            if (state.activeTaxCard.length === 0) {
                return ! taxCard.isPending;
            }

            if (state.activeTaxCardId === taxCard.taxCardId) {
                return false;
            }
            
            if (taxCard.isVeroFetched && ! taxCard.isActiveVeroTaxCard) {
                return true;
            }

            if (new Date(state.activeTaxCard.startDate).getFullYear() < new Date(taxCard.startDate).getFullYear()) {
                return false;
            }

            if (new Date(state.activeTaxCard.startDate).getFullYear() > new Date(taxCard.startDate).getFullYear()) {
                return true;
            }

            // Kumpikaan ei ole veroapista tullut
            if (! state.activeTaxCard.isVeroFetched && ! taxCard.isVeroFetched) {
                return taxCard.taxCardId !== state.activeTaxCard.taxCardId && ! taxCard.isPending;
            }

            // Kumpikin on tullu apista
            if (state.activeTaxCard.isVeroFetched && taxCard.isVeroFetched) {
                // Jos haku päivä on suurempi kuin aktiivisen hakupäivä. Jos hakupäivä on aiempi kuin aktiivisen
                return new Date(taxCard.veroFetchDate) < new Date(state.activeTaxCard.veroFetchDate);
            }

            if (taxCard.isVeroFetched) {
                return new Date(taxCard.veroFetchDate) < new Date(state.activeTaxCard.created);
            }

            return new Date(taxCard.created) < new Date(state.activeTaxCard.veroFetchDate);
        }),
        getPayrollIdsWithOldTaxCard: (state) => (state.openPayrollsWithOldTaxCard ?? []),
        getUsedTaxCards: (state) => state.usedTaxCards ?? [],
        getSelectedTaxCardId: (state) => state.selectedTaxCardId,
        getRelatedContracts: (state) => state.relatedContracts.map((relatedContract) => relatedContract.payer ),
        fetchVeroTaxCard: (state) => state.veroTaxCard,
        getTaxCardById: (state, taxCardId) => state.taxCards.find((taxCard) => taxCard?.taxCardId === taxCardId),
        isReadOnly: (state, taxCardId) => (state.usedTaxCards ?? []).includes(taxCardId),
        isVeroFetchedTaxCard: (state, taxCardId) => (state.veroFetchedTaxCards ?? []).includes(taxCardId),
        // lodash mäkeen tästä
        getLatestTaxCard: (state) => _.last(_.sortBy(state.taxCards, (taxCard) => moment(taxCard?.startDate ?? ''))),
        getContractType: (state) => state.contractType,
        isEditMode: (state) => state.isEditMode,
        isVeroApiEnabled: (state) => state.isVeroApiEnabled,
        getTaxCardTypeOverride: (state) => state.taxCardTypeOverride,
    },
    effects: (dispatch) => ({
        /**
         * Haetaan verokortit ja jaotellaan ne omiin kategorioihin
         *
         * @param contractDataId
         * @returns {Promise<void>}
         */
        async fetchTaxCards(contractDataId) {
            try {
                const json = await api.get(
                    Routing.generate('api_1_get_contract_taxcards', { jobContractData: contractDataId, ...getCurrentTaxYear() })
                );
                // Eritellään tässä vaiheessa omiin lokeroihin.
                await this.setTaxCards(json?.taxcards ?? []);
                await this.setActiveTaxCard(json?.activeTaxCard ?? []);
                await this.setActiveTaxCardId(json?.activeTaxCardId);
                //Vähän muotoillaan dataa, koska bäkkäriltä se tulee objektina, jossa key ja value on samat.
                await this.setUsedTaxCards(Object.values(json?.usedTaxCards ?? {}));
                await this.setVeroFetchedTaxCards(Object.values(json?.veroFetched ?? {}));
                await this.setIsVeroApiEnables(json?.isVeroApiEnabled);
                await this.setRelatedContracts(json?.relatedContracts ?? []);
                await this.setIsPrePaymentRegisterEmployee(json.isPrePaymentRegisterEmployee);
                await this.setOpenPayrollsWithOldTaxCard(json.openPayrollsWithOldTaxCard);
                this.setTaxCardTypeOverride(json.taxCardTypeOverride ?? '');
            } catch (e) {
                // Nollataan kortit, jottei placeholder tavarat näy.
                await this.setTaxCards([]);
                dispatch.notifications.addError(_trans('tax_card.form.messages.fetch_error'));
                console.error(e);
            }
        },

        /**
         * Hakee Veroapista verokortin
         *
         * @param contractDataId
         * @returns {Promise<void>}
         */
        async fetchVeroTaxCard(contractDataId) {
            const response = await api.post(Routing.generate('api_1_post_contract_refresh_taxcard_veroapi', { jobContractData: contractDataId }));
            if (response.status === 'ok') {
                // ei dataa, eli ei päivitetty
                if (!response.data){
                    dispatch.notifications.addInfo(_trans('Haettu verokortti vastasi voimassa olevaa verokorttia. Muutoksia ei tehty.', {}, 'extract'));
                } else if (response.data === 'fetched_card_is_not_active') {
                    dispatch.notifications.addSuccess(_trans('Verokortti haettiin onnistuneesti OmaVerosta.', {}, 'extract'));
                } else {
                    dispatch.notifications.addSuccess(_trans('Verokortti haettiin onnistuneesti OmaVerosta ja otettiin käyttöön', {}, 'extract'));
                }
            }
            else {
                const message = response?.message ?? 'tax_card.form.messages.fetch_error';

                dispatch.notifications.addError(_trans(message));
            }

            await this.fetchTaxCards(contractDataId);
        },

        /**
         * Uuden verokortin lisäys taikka muokkaus.
         * Melkein varmaa voisi refactoroida API:t, kun postilla lisätään sekä muokataan...
         *
         * @param model
         * @param rootState
         * @param taxCardId
         * @returns {Promise<void>}
         */
        async postTaxCard(model, rootState, taxCardId) {
            try {
                model = await resolvePostBody(model);
                const response = await api.post(Routing.generate('api_1_post_contract_taxcards', {
                    taxCard: taxCardId,
                    jobContractData: rootState.taxCards.jobContractDataId,
                }), model);
                if (response?.error) {
                    dispatch.notifications.addError(response?.message);
                    return;
                }
                await this.fetchTaxCards(rootState.taxCards.jobContractDataId);
                await this.setSelectedTaxCardId(null);
                await this.setIsOverrideVeroFetch(null);
                dispatch.notifications.addSuccess(_trans('tax_card.form.messages.save_success'));
            } catch (e) {
                dispatch.notifications.addError(_trans('tax_card.form.messages.save_error'));
                console.error(e);
            }
        },

        /**
         * Poistaa käyttämättömän verokortin
         *
         * @param taxCardId
         * @param rootState
         * @returns {Promise<void>}
         */
        async deleteTaxCard(taxCardId, rootState) {
            try {
                await api.del(Routing.generate('api_1_delete_taxcard', {
                    taxCard: taxCardId,
                    contractData: rootState.taxCards.jobContractDataId,
                }));
                await this.fetchTaxCards(rootState.taxCards.jobContractDataId);
                await this.setSelectedTaxCardId(null);
                await this.setIsOverrideVeroFetch(null);
                await dispatch.notifications.addSuccess(_trans('tax_card.form.messages.delete_success'));
            } catch (e) {
                console.error(e);
                await dispatch.notifications.addError(_trans('tax_card.form.messages.delete_error'));
            }
        },
    })
};

/**
 * Resolve valitun tyypin perusteella modellin, joka postataan.
 *
 * @param model
 * @returns {Promise<object>}
 */
const resolvePostBody = (model) => new Promise((resolve) => {
    switch (model.type) {
        case taxCardTypes.NO_TAX_CARD:
            resolve(_.pick(model, ['taxDeduction1', 'type']));
            break;
        case taxCardTypes.TYPE_B:
        case taxCardTypes.COMPENSATION_TAX_CARD_FAMILY_CARE:
        case taxCardTypes.COMPENSATION_TAX_CARD_RELATIVE_CARE:
            resolve(_.pick(model, ['type', 'startDate', 'incomeLimit1', 'taxDeduction1', 'taxDeduction2', 'previousSalary', 'isOverrideVeroFetch']));
            break;
        case taxCardTypes.TAX_AT_SOURCE:
            // Poistetaan kenttiä, joita ei tarvita
            resolve(_.omit(model, ['incomeLimit1', 'taxDeduction2', 'attributes', 'created', 'edited', 'isImported', 'taxCardId', 'isVeroFetched']));
            break;
        default:
            resolve(model);
            break;
    }
});

/**
 * Palauttaa tämän hetkisen verovuoden alku- sekä loppupvm. (tämän vuoden alku - ens vuoden tammikuun loppu)
 *
 * @returns {{endDate: ISODate, startDate: ISODate}}
 */
const getCurrentTaxYear = () => ({
    startDate: moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD'),
    // endDate: moment().startOf('year').add(1, 'years').endOf('month').format('YYYY-MM-DD'),
});

