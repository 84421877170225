import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import confirm from 'shared/utils/confirm';
import Button from 'shared/components/Button';
import ActionBar from 'shared/components/ActionBar';

export const HandledSurchargeActions = ({ original, index, isClosed }) => {
    const dispatch = useDispatch();
    const [inProgress, setInProgress] = useState(false);
    const isRemoving = useSelector((state) => state.loading.effects.surcharges.deleteHandledSurcharge);
    const isExternal = useSelector(select.surcharges.isExternalTypeSelected);

    return (
        <ActionBar alignItems="left">
            <Button
                mdIcon="delete"
                disabled={isClosed}
                flat
                title={_trans('button.remove')}
                inProgress={isRemoving && inProgress}
                onClick={() => {
                    confirm('Oletko varma että haluat poistaa lisämaksun?', {
                        proceedLabel: 'Poista lisämaksu',
                        alert: true,
                    }).then(() => {
                        const surchargeId = original?.id ?? null;
                        if (surchargeId !== null) {
                            setInProgress(true);
                            dispatch.surcharges.deleteHandledSurcharge({ surchargeId, index, isExternal }).then(() => setInProgress(false));
                        } else {
                            dispatch.notifications.addError('Lisämaksun id:tä ei löytynyt.');
                        }
                    });
                }}
            />
        </ActionBar>
    );
};

HandledSurchargeActions.propTypes = {
    original: PropTypes.object,
    index: PropTypes.number,
    isClosed: PropTypes.bool,
};

HandledSurchargeActions.defaultProps = {
    original: {},
    index: 0,
    isClosed: false,
};
