/**
 * Allekirjoittajatyypit.
 * Signature.php:stä.
 * @type {{TYPE_GENERIC: string, TYPE_USER_SPECIFIC: string, TYPE_EMPLOYER_SPECIFIC: string}}
 */
export const signatureTypes = {
    // geneerinen allekirjoitus kunnille ja yrityksille jonka voi allekirjoittaa kuka vain
    TYPE_GENERIC: 'generic',

    // käyttäjäkohtainen allekirjoitus voi allekirjoittaa vain annettu käyttäjä
    TYPE_USER_SPECIFIC: 'user_specific',

    // käyttäjäkohtainen allekirjoitus voi allekirjoittaa vain annettu HA:n operaattori
    TYPE_COMMUNE_USER_SPECIFIC: 'commune_user_specific',

    // Työnantajakohtainen
    TYPE_EMPLOYER_SPECIFIC: 'employer_specific',
};
