export const referenceTypes = {
    /**
     * Ilmoitetaan Oiman Y-tunnuksella Tyvi-kanavasta ja käytetään Oiman sijaismaksajaviitteitä
     */
    REFERENCE_TYPE_SUORATYO: 1,

    /**
     * Yrityksen omat viitteet. Ilmoitetaan Tyvi-kanavaan yrityksen omalla y-tunnuksella ja käytetään yrityksen
     * omia viitteitä.
     */
    REFERENCE_TYPE_COMPANY: 2,

    /**
     * Henkilökohtaiset viitteet. Ei ilmoiteta erikseen mitään. Maksetaan henkilökohtaisilla viitteillä.
     */
    REFERENCE_TYPE_PERSONAL: 3,
};
