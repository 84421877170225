import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Value } from 'shared/components/Value';
import { Placeholder } from 'shared/components';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';

/**
 * Valittu TES.
 * @returns {JSX.Element}
 * @constructor
 * @param props
 */
const CollectiveAgreement = (props) => {
    const { label, name, attributes } = props;
    const isLoading = useSelector((state) => state.loading.effects.collectiveAgreement.fetchCollectiveAgreements);
    const collectiveAgreement = useSelector(select.contract.getCollectiveAgreement);

    // Onko valtisin piilotettuna
    if (attributes.isHidden ?? false) return null;

    return (
        <SummaryListItem term={label}>
            <Placeholder isPending={isLoading}>
                <Value ariaDescribedBy={name}>
                    {collectiveAgreement?.name}
                </Value>
            </Placeholder>
        </SummaryListItem>
    );
};

CollectiveAgreement.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    attributes: PropTypes.object.isRequired,
};

export default CollectiveAgreement;
