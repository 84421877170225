import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { AutoCompleteVirtualized } from 'shared/containers';
import { getFormikErrorClassName } from 'shared/utils/formUtils';

export const AutoCompleteField = (props) => {
    const { name, items, placeholder, customSelect, defaultValue, isDisabled, isPostRequest } = props;
    const [field, meta, helpers] = useField(name);
    const errorClassName = getFormikErrorClassName(meta);

    return (
        <AutoCompleteVirtualized
            {...props}
            id={name}
            className={errorClassName}
            onSelect={(value) => {
                helpers.setValue(value);
                customSelect(value);
            }}
            items={items}
            placeholder={placeholder}
            defaultValue={defaultValue === '' ? field.value : defaultValue}
            disabled={isDisabled}
            isPostRequest={isPostRequest}
        />
    );
};

AutoCompleteField.propTypes = {
    name: PropTypes.string,
    items: PropTypes.array,
    placeholder: PropTypes.string,
    customSelect: PropTypes.func,
    defaultValue: PropTypes.string,
    isDisabled: PropTypes.bool,
    hasSingleValue: PropTypes.bool,
    isPostRequest: PropTypes.bool,
};

AutoCompleteField.defaultProps = {
    name: undefined,
    items: [],
    placeholder: '',
    customSelect() {},
    defaultValue: '',
    isDisabled: false,
    hasSingleValue: true,
    isPostRequest: false,
};
