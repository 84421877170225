import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import Button from 'shared/components/Button';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { salaryTypes } from 'shared/constants/salaryTypes';
import { FormField } from 'shared/components/Formik';
import { getFlatValues } from 'shared/ReForm/utils/getFlatValues';

/**
 * Lisää uuden palkkion.
 * @param name
 * @param blocks
 * @param arrayHelpers
 * @returns {JSX.Element|null}
 * @constructor
 */
const AddNewSalaryButton = ({ name, blocks, arrayHelpers }) => {
    const [field, , helpers] = useField(name);
    const [salaryTypeField] = useField(fieldNames.SALARY_TYPE);
    const salaryType = parseInt(salaryTypeField.value, 10);
    const salaryCount = Array.isArray(field.value) ? field.value.length : 0;
    const salaryTypeDimensions = useSelector(select.dimensions.getSalaryTypeDimensions);
    const dimensions = (Array.isArray(salaryTypeDimensions) && salaryTypeDimensions.length > 0)
        ? salaryTypeDimensions[0].dimensions ?? []
        : [];

    const maxSalaryCount = dimensions.length;

    // Arvot jotka alustetaan.
    const values = useMemo(() => getFlatValues(blocks), [blocks]);
    // console.log(blocks, values);

    useEffect(() => {
        if (salaryType !== salaryTypes.PER_DAY && (field.value ?? []).length > 1) {
            // Poistetaan myös ylimääräiset palkkiot
            helpers.setValue([field.value[0]]);
            //arrayHelpers.remove(1);
        }
    }, [salaryTypeField.value, field.value]);

    // Jos valittuna ei ole vuorokausipalkkio ei näytetä useamman palkkion lisäämisnappia tai jos vuorokausipalkkioita ei voi lisätä edes yhtä
    if (salaryType !== salaryTypes.PER_DAY || maxSalaryCount === 0) {
        return null;
    }

    return (
        <FormField isContentFormField={false}>
            <Button
                mdIcon="add"
                // Max 3 salaaritietoa
                disabled={salaryCount >= maxSalaryCount}
                onClick={() => {
                    arrayHelpers.push(Object.fromEntries(values));
                }}
            >
                {_trans('contract.form.salary.add_another_sub_salary')}
            </Button>
        </FormField>
    );
};

AddNewSalaryButton.propTypes = {
    name: PropTypes.string.isRequired,
    arrayHelpers: PropTypes.object.isRequired,
    blocks: PropTypes.array.isRequired,
};

export default AddNewSalaryButton;
