import React, { Fragment } from 'react';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Feedback, InputAbbr, InputGroup, StField, StLabel } from 'shared/components';
import { FormikChoiceList, FormikDateInput, FormikErrors } from 'shared/components/Formik';
import taxCardShape from 'shared/shapes/taxCard';
import { TaxCardOverrideVeroFetchField } from 'shared/TaxCard/containers/TaxCardOverrideVeroFetchField';

export const SourceTaxCardFields = ({ taxCard }) => {
    const showOnlyText = ! useSelector(select.taxCards.isEditMode);

    return (
        <Fragment>
            <StLabel>
                {_trans('tax_card.form.start_date.label')}
            </StLabel>
            <StField>
                {showOnlyText
                    ? <b>{_toLocaleDate(taxCard.startDate)}</b>
                    : (
                        <Fragment>
                            <FormikDateInput name="startDate" />
                            <FormikErrors name="startDate" />
                        </Fragment>
                    )
                }
            </StField>
            <StLabel>
                {_trans('tax_card.form.tax_deduction.label')}
            </StLabel>
            <StField>
                {showOnlyText
                    ? <b>{_numberFormat(taxCard.taxDeduction1)} {_trans('unit.percent_short')}</b>
                    : (
                        <Fragment>
                            <InputGroup>
                                <Field size={1} type="text" name="taxDeduction1" />
                                <InputAbbr abbr="%" definition={_trans('unit.percent')} />
                            </InputGroup>
                            <FormikErrors name="taxDeduction1" />
                        </Fragment>
                    )
                }
            </StField>
            {! showOnlyText &&
                <Feedback
                    type="info"
                    title={_trans('tax_card.form.messages.source_tax_card_help.title')}
                    modifierClass="u-margin-bottom-small"
                >
                    <a
                        href="https://www.vero.fi/syventavat-vero-ohjeet/ohje-hakusivu/49113/ulkomailta-suomeen-tulevan-ty%C3%B6ntekij%C3%A4n-verotus4/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {_trans('tax_card.form.messages.source_tax_card_help.message')}
                    </a>
                </Feedback>
            }
            <StLabel>
                {_trans('tax_card.form.date_of_arrival.label')}
            </StLabel>
            <StField>
                {showOnlyText
                    ? <b>{_toLocaleDate(taxCard.attributes.date_of_arrival.date)}</b>
                    : (
                        <Fragment>
                            <FormikDateInput name="dateOfArrival" />
                            <FormikErrors name="dateOfArrival" />
                        </Fragment>
                    )
                }
            </StField>
            <StLabel>
                {_trans('tax_card.form.citizenship.label')}
            </StLabel>
            <StField>
                {showOnlyText
                    ? <b>{taxCard.attributes.citizenship}</b>
                    : (
                        <Fragment>
                            <Field type="text" name="citizenship"/>
                            <FormikErrors name="citizenship" />
                        </Fragment>
                    )
                }
            </StField>
            <StLabel>
                {_trans('tax_card.form.has_a1_e101.label')}
            </StLabel>
            <StField>
                {showOnlyText
                    ? <b>{taxCard.attributes.has_a1_e101 ? _trans('button.yes') : _trans('button.no')}</b>
                    : (
                        <Fragment>
                            <FormikChoiceList
                                id="hasA1E101"
                                name="hasA1E101"
                                alignment="horizontal"
                                itemModifierClass="c-choice--one"
                                options={[
                                    {
                                        value: true,
                                        label: _trans('button.yes'),
                                    },
                                    {
                                        value: false,
                                        label: _trans('button.no'),
                                    },
                                ]}
                            />
                            <FormikErrors name="hasA1E101" />
                        </Fragment>
                    )
                }
            </StField>
            <StLabel>
                {_trans('tax_card.form.entitled_to_income_reduction.label')}
            </StLabel>
            <StField>
                {showOnlyText
                    ? <b>{taxCard.attributes.entitled_to_income_reduction ? _trans('button.yes') : _trans('button.no')}</b>
                    : (
                        <Fragment>
                            <FormikChoiceList
                                id="entitledToIncomeReduction"
                                name="entitledToIncomeReduction"
                                alignment="horizontal"
                                itemModifierClass="c-choice--one"
                                options={[
                                    {
                                        value: true,
                                        label: _trans('button.yes'),
                                    },
                                    {
                                        value: false,
                                        label: _trans('button.no'),
                                    },
                                ]}
                            />
                            <FormikErrors name="entitledToIncomeReduction" />
                        </Fragment>
                    )
                }
            </StField>
            <StLabel>
                {_trans('tax_card.form.address_in_country_of_origin.label')}
            </StLabel>
            <StField>
                {showOnlyText
                    ? <b>{taxCard.attributes.address_in_country_of_origin}</b>
                    : (
                        <Fragment>
                            <Field type="text" name="addressInCountryOfOrigin" />
                            <FormikErrors name="addressInCountryOfOrigin" />
                        </Fragment>
                    )
                }
            </StField>
            <StLabel>
                {_trans('tax_card.form.excludes_sava.label')}
            </StLabel>
            <StField>
                {showOnlyText
                    ? <b>{taxCard.attributes.exludes_sava ? _trans('button.yes') : _trans('button.no')}</b>
                    : (
                        <Fragment>
                            <FormikChoiceList
                                id="excludesSava"
                                name="excludesSava"
                                alignment="horizontal"
                                itemModifierClass="c-choice--one"
                                options={[
                                    {
                                        value: true,
                                        label: _trans('button.yes'),
                                    },
                                    {
                                        value: false,
                                        label: _trans('button.no'),
                                    },
                                ]}
                            />
                            <FormikErrors name="excludesSava" />
                        </Fragment>
                    )
                }
            </StField>
            <TaxCardOverrideVeroFetchField taxCard={taxCard}/>
        </Fragment>
    );
};

SourceTaxCardFields.propTypes = {
    taxCard: taxCardShape,
};

SourceTaxCardFields.defaultProps = {
    taxCard: {},
};
