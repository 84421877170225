export const midWeekHolidays = [
    'newYearsDay', // uudenvuodenpäivä
    'epiphany', // loppiainen
    'goodFriday', // pitkäperjantai
    'holySaturday', // pääsiäislauantai
    'easter', // pääsiäinen
    'easterMonday', // 2. pääsiäispäivä
    'mayDayEve', // vappuaatto
    'mayDay', // vappu
    'pentecost', // helluntai
    'ascensionDay', // helatorstai
    'midSummerEve', // juhannusaatto
    'midSummer', // juhannus
    'allSaintsDay', // pyhäinpäivä
    'finlandNationalDay', // itsenäisyyspäivä
    'christmasEve', // jouluaatto
    'christmasDay', // joulupäivä
    'boxingDay', // tapaninpäivä
    'newYearsEve', // uudenvuodenaatto
];

const midWeekHolidayMap = {
    'newYearsDay': _trans('tes_builder.midweek_holidays.newYearsDay'),
    'epiphany': _trans('tes_builder.midweek_holidays.epiphany'),
    'goodFriday': _trans('tes_builder.midweek_holidays.goodFriday'),
    'holySaturday': _trans('tes_builder.midweek_holidays.holySaturday'),
    'easter': _trans('tes_builder.midweek_holidays.easter'),
    'easterMonday': _trans('tes_builder.midweek_holidays.easterMonday'),
    'mayDayEve': _trans('tes_builder.midweek_holidays.mayDayEve'),
    'mayDay': _trans('tes_builder.midweek_holidays.mayDay'),
    'pentecost': _trans('tes_builder.midweek_holidays.pentecost'),
    'ascensionDay': _trans('tes_builder.midweek_holidays.ascensionDay'),
    'midSummerEve': _trans('tes_builder.midweek_holidays.midSummerEve'),
    'midSummer': _trans('tes_builder.midweek_holidays.midSummer'),
    'allSaintsDay': _trans('tes_builder.midweek_holidays.allSaintsDay'),
    'finlandNationalDay': _trans('tes_builder.midweek_holidays.finlandNationalDay'),
    'christmasEve': _trans('tes_builder.midweek_holidays.christmasEve'),
    'christmasDay': _trans('tes_builder.midweek_holidays.christmasDay'),
    'boxingDay': _trans('tes_builder.midweek_holidays.boxingDay'),
    'newYearsEve': _trans('tes_builder.midweek_holidays.newYearsEve'),
};

export function translateMidWeekHoliday(type) {
    return type in midWeekHolidayMap ? midWeekHolidayMap[type] : '';
}
