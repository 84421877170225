import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Tooltip,
} from 'shared/components';
import resolveUrl from 'shared/utils/resolveUrl';
import routes from 'Commune/BenefitDecision/routes';

const BenefitDecisionActionsCell = ({ communeId, original }) => {

    const isButtonDisabled = () => {
        if (communeId !== _.get(original, 'commune.id')) {
            return true;
        }
    };

    const renderButton = () => {
        const id = _.get(original, 'id');
        const route = resolveUrl(routes.BENEFIT_DECISION_SUMMARY, { id });
        return (
            <Button
                href={route}
                flat
                disabled={isButtonDisabled()}
            >
                {_trans('button.open')}
            </Button>
        );
    };

    if (isButtonDisabled()) {
        return (
            <Tooltip title={_trans('benefit_decision.data_table.pls_change_commune')}>
                { renderButton() }
            </Tooltip>
        );
    }

    return renderButton();
};

BenefitDecisionActionsCell.propTypes = {
    communeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    original: PropTypes.object.isRequired,
};

export default BenefitDecisionActionsCell;
