import { baseBlockSchema } from 'shared/ReForm/schemas/blocks/baseBlock.schema';

/**
 * Yhdistää peruspalikan scheman uuden scheman kanssa.
 * @param schema
 */
export const mergeSchema = (schema) => ({
    ...Object.assign({}, baseBlockSchema, schema),
    // Korvataan title
    title: schema.title ?? baseBlockSchema.title,
    // Yhdistetään propertyt
    properties: Object.assign({}, baseBlockSchema.properties, schema.properties ?? {}),
    // Duplikaatit pois
    required: [...new Set([...baseBlockSchema.required ?? [], ...schema.required ?? []])],
    additionalProperties: false,
});
