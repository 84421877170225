import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Placeholder } from 'shared/components';
import UserBlock from 'shared/components/UserBlock';

const ReportingFormsChild = ({ attributes }) => {
    const isSsnVisible = attributes.isSsnVisible ?? false;
    const customer = useSelector(select.contract.getCustomer);
    const isEmployee = useSelector(select.userMetadata.isEmployee);
    const isLoading = useSelector((state) => state.loading.effects.contract.fetchContract);
    const isBuildMode = useSelector(select.contract.isBuildMode);

    if (isBuildMode) {
        return <b>Tiedot eivät näkyvissä editorissa.</b>;
    }

    const details = [
        [customer.fullAddress],
        [customer.phoneNumber],
    ].filter((detail) => detail.toString().trim() !== '');

    return (
        <Placeholder isPending={isLoading}>
            <UserBlock
                user={customer}
                size="small"
                relationType="customer"
                isSsnVisible={isSsnVisible}
                hasLinkToDetails={! isEmployee}
            />
            {details.map((detail, key) => (
                <Fragment key={key}>
                    {detail}<br/>
                </Fragment>
            ))}
        </Placeholder>
    );
};

ReportingFormsChild.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export default ReportingFormsChild;
