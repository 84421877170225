import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import BenefitDecisionActionsCell from '../../components/BenefitDecisionActionsCell';
import { userActions, userSelectors } from '../../stores/user';
import DataTable from 'shared/containers/DataTable';
import Spinner from 'shared/components/MDSpinner';
import BeneficiaryFullNameSsnCell from 'Contract/CommuneContractList/components/BeneficiaryFullNameSsnCell';
import Panel from 'shared/components/Panel';

@connect((state) => ({
    userId: userSelectors.getUserId(state),
    ui: userSelectors.getUiProps(state, 'employerBenefitDecisions'),
    benefitDecisions: state.employerBenefitDecisions,
    communeId: select.userMetadata.getCommuneId(state),
    isEmployer: userSelectors.isEmployer(state),
}))
export default class EmployerBenefitDecisionList extends Component {

    static propTypes = {
        userId: PropTypes.number,
        ui: PropTypes.object,
        benefitDecisions: PropTypes.instanceOf(Map),
        communeId: PropTypes.number,
        dispatch: PropTypes.func,
        isEmployer: PropTypes.bool,
    };

    static defaultProps = {
        userId: -1,
        ui: {},
        benefitDecisions: Map({}),
        communeId: -1,
        dispatch() {},
        isEmployer: false,
    };

    componentDidMount() {
        this.props.dispatch(userActions.fetchEmployerBenefitDecisions(this.props.userId));
    }
    render() {

        if (! this.props.isEmployer) {
            return null;
        }

        if (this.props.ui.get('isLoading', false)) {
            return (
                <Spinner wrapped/>
            );
        }

        const decisions = this.props.benefitDecisions.toJS();

        const matchFullNameOrSsn = (filter, row) => {
            const fullNameMatch = _.isEmpty(row[filter.id]) ? null : row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
            const ssn = _.get(row, '_original.beneficiarySsn');
            const ssnMatch = _.isEmpty(ssn) ? null : ssn.toLowerCase().includes(filter.value.toLowerCase());

            return fullNameMatch || ssnMatch;
        };

        const columns = [
            {
                Header: _trans('commune_contract.data_table.columns.beneficiary'),
                id: 'beneficiary',
                accessor: (props) => {
                    // Jos avustettava on sama kuin työnantaja ei kohdisteta filtteröintiä tälle riville.
                    const fullName = _.get(props, 'beneficiary.fullName');
                    const isSelfEmployed = _.get(props, 'beneficiaryIsEmployer');
                    return isSelfEmployed ? null : fullName;
                },
                filterMethod: matchFullNameOrSsn,
                Cell: (props) => BeneficiaryFullNameSsnCell(props),
            },
            {
                Header: _trans('commune_contract.data_table.columns.start_date'),
                accessor: 'startDate',
                type: 'date',
                width: 120,
            },
            {
                Header: _trans('commune_contract.data_table.columns.end_date'),
                accessor: 'endDate',
                type: 'date',
                defaultValue: _trans('date_range.indefinitely'),
                width: 120,
            },
            {
                Header: _trans('commune_contract.data_table.columns.termination_date'),
                id: 'state',
                filterable: false,
                sortable: false,
                accessor: 'effectiveEndDate',
                width: 120,
                type: 'date',
            },
            {
                Header: _trans('benefit_decision.data_table.commune'),
                accessor: 'commune.name',
                width: 150,
            },
            {
                Header: _trans('benefit_decision.data_table.commune_controlled'),
                id: 'communeControlled',
                accessor: (props) => {
                    const communeControlled = _.get(props, 'communeControlled');
                    return communeControlled ? _trans('button.yes') : _trans('button.no');
                },
            },
            {
                Header: _trans('benefit_decision.data_table.cost_center'),
                id: 'costCenter',
                accessor: (props) => {
                    const costCenter = _.has(props, 'costCenter');
                    return costCenter ? _.get(props, 'costCenter') : null;
                },
            },
            {
                Header: _trans('benefit_decision.data_table.next_of_kin_employee_allowed_short'),
                id: 'nextOfKin',
                accessor: (props) => {
                    const isNextOfKinAllowed = _.get(props, 'nextOfKinEmployeeAllowed');
                    return isNextOfKinAllowed ? _trans('button.yes') : _trans('button.no');
                },
            },
        ];

        return (
            <Panel heading={_trans('benefit_decision.title')} theme="admin">
                <DataTable
                    data={decisions}
                    columns={columns}
                    actionsColumn={<BenefitDecisionActionsCell communeId={this.props.communeId}/>}
                    defaultSorted={[
                        {
                            id: 'employer',
                            desc: false,
                        }
                    ]}
                    defaultPageSize={10}
                    emptyDataIcon="handshake"
                    emptyDataMessage={_trans('benefit_decision.data_table.empty_data_message')}
                />
            </Panel>
        );
    }
}

