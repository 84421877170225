import { useEffect, useRef, useState } from 'react';

// Haetaan headerin korkeus automaattisesti jos liimaus on yläosaan.
const getHeaderHeight = (stickTo) => (
    stickTo === 'top'
        ? document.getElementById('top-header')?.firstChild?.firstChild.offsetHeight || 0
        : 0
);

/**
 * Laskee onko elementin oltava "sticky" eli liimautuuko näkymään kiinni kunnes
 * sen container ei ole enää näkyvissä.
 * @param stickTo - Kiinnitetäänkö elementti ylä- (top) vai alaosaan (bottom) containeriaan. Oletuksena yläosaan.
 * @returns {[React.MutableRefObject<null>,{position: string},boolean]}
 */
export default function useSticky(stickTo = 'top') {
    const stickyRef = useRef(null);

    const [isSticky, setSticky] = useState(false);
    const eventsToBind = [
        [document, 'scroll'],
        [window, 'resize'],
        [window, 'orientationchange']
    ];

    useEffect(() => {
        // Observe when ref enters or leaves sticky state
        // rAF https://stackoverflow.com/questions/41740082/scroll-events-requestanimationframe-vs-requestidlecallback-vs-passive-event-lis
        function observe() {
            const currentStickyRef = stickyRef.current;
            const refPageOffset = (stickTo === 'bottom'
                ? currentStickyRef?.getBoundingClientRect().bottom
                : currentStickyRef?.getBoundingClientRect().top
            ) || 0;

            const topOffset = currentStickyRef
                ? parseInt(getComputedStyle(currentStickyRef).top, 10)
                : 0;

            const stickyOffset = stickTo === 'bottom'
                ? stickyRef.current?.offsetTop || 0
                : topOffset;

            const stickyActive = stickTo === 'bottom'
                ? refPageOffset >= stickyOffset
                : refPageOffset <= stickyOffset;

            if (stickyActive && !isSticky) setSticky(true);
            else if (!stickyActive && isSticky) setSticky(false);
        }
        observe();

        // Bind events
        eventsToBind.forEach((eventPair) => {
            eventPair[0].addEventListener(eventPair[1], observe);
        });

        return () => {
            eventsToBind.forEach((eventPair) => {
                eventPair[0].removeEventListener(eventPair[1], observe);
            });
        };
    }, [eventsToBind, stickyRef, isSticky, stickTo]);

    // Palauttaa referenssin (=container), style-objektin ja tiedon onko elementti kiinnitettynä.
    return [stickyRef, { position: 'sticky', [stickTo]: getHeaderHeight(stickTo) }, isSticky];
}
