const svgIconTypes = {
    PIGGY_BANK: 'piggy_bank',
    NOTE: 'note',
    NOTE_CIRCLE: 'note_circle',
    COINS: 'coins',
    PEOPLE: 'people',
    ARROW_LEFT: 'arrow_left',
    ARROW_RIGHT: 'arrow_right',
    HANDSHAKE: 'handshake',
    IDENTIFICATION: 'identification',
    CONVERSATION: 'conversation',
    USER_BADGE: 'user_badge',
    HOUSE: 'house',
    UMBRELLA: 'umbrella',
    SAD_FACE: 'sad_face',
    AVATAR: 'avatar',
    PLAY_CIRCLE: 'play_circle',
    INBOX: 'inbox',
    CLOCK: 'clock',
    CLOUD_OFF: 'cloud_off',
    ARROW: 'arrow',
    TYEL_INSURANCE: 'tyel_insurance',
    ACCIDENT_INSURANCE: 'accident_insurance',
    OP_LOGO: 'op_logo',
    ILMARINEN: 'ilmarinen_logo',
    TIME: 'time',
    EMPLOYER: 'employer',
    EMPLOYEE: 'employee',
    COMPANY: 'company',
    BOOKKEEP: 'bookkeep',
    THUMB_UP: 'thumb_up',
    INJURY: 'injury',
    PDF: 'pdf',
    LITE_CASH_PAYMENT: 'lite_cash_payment',
    LITE_FIXED: 'lite_fixed',
    LITE_PER_HOUR: 'lite_per_hour',
};

export default svgIconTypes;
