/**
 * Tekstipalikka. Tukee markdownia.
 */
import { blocks } from 'shared/ReForm/constants/blocks';

export const textSchema = {
    type: 'object',
    title: 'Tekstiä',
    properties: {
        block: { const: blocks.TEXT },
        text: {
            title: 'Tekstisisältö',
            '$ref': '#/definitions/translation',
        },
        attributes: {
            type: 'object',
            properties: {
                useParagraphs: {
                    title: 'Käytä tekstissä kappalejakoa',
                    type: 'boolean',
                }
            },
            additionalProperties: false,
        }
    },
    required: ['block', 'text'],
    additionalProperties: false,
};
