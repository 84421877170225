// 6px
const baseUnit = 12;
const getUnitsInPixels = (units) => `${baseUnit*units}px`;

/**
 * Resolvoi luokat annettujen propertyjen perusteella.
 * Toistaiseksi vain marginaalit.
 * @param style
 * @returns {{}}
 */
export const resolveStyles = (style) => {
    if (! style) return {};

    return Object.entries(style).reduce((acc, [property, value]) => {
        const units = parseInt(value, 10);
        if (isNaN(units)) return acc;

        if (['marginTop', 'marginBottom'].includes(property)) {
            return Object.assign({}, acc, { [property]: getUnitsInPixels(units) });
        }

        return acc;
    }, {});
};
