import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { MonthSpanInput } from 'shared/components/MonthSpanInput';

export const FormikMonthSpanInput = (props) => {
    const { name } = props;
    const [field,, helpers] = useField(name);
    const { setValue, } = helpers;

    return (
        <MonthSpanInput
            onChange={(value) => {
                setValue(value);
                // setTouched(true);
            }}
            value={field.value}
            {...props}
        />
    );
};

FormikMonthSpanInput.propTypes = {
    name: PropTypes.string.isRequired,
};
