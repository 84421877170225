import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import React from 'react';
import { List } from 'immutable';
import AutoComplete from 'shared/containers/AutoComplete';
import { MDIcon } from 'shared/components';

export const DefaultDimensionsField = ( { index, original, isContraAccount }) => {
    let objectKey = 'debitAccountDefaultDimensions';
    if (isContraAccount) {
        objectKey = 'creditAccountDefaultDimensions';
    }

    const dispatch = useDispatch();
    const dimensions = useSelector(select.postingsDimensions.getPostingDefaultDimensionTypes);
    const posting = useSelector((state) => select.bookkeepingAccount.getPostingByIncomeType(state, original?.incomeType));
    const tabIndex = resolveTabIndex(index);
    const dimensionList = [];
    dimensions.forEach(({ id: typeId, dimensions: typeDimensions, name: typeName }) => {
        typeDimensions.forEach((dimension) => {
            dimensionList.push({
                typeId,
                id: dimension?.id,
                name: `${typeName} - ${dimension?.value}`,
                value: dimension.value,
                originalDimension: dimension,
            });
        });
    });

    const selected = (posting.get(objectKey) ?? List()).toJS()
        .map((dimension) => dimensionList.find((item) => +item.id === +dimension?.id))
        .filter(Boolean)
        .sort((a, b) => a.typeId - b.typeId)
    ;

    return (
        <div tabIndex={tabIndex}>
            <AutoComplete
                hasClearValueOnSelect
                // jos on joku dimensiotyyppi valittu jo niin karsitaan kaikki samantyyppiset pois että ei voi olla
                // ku yksi per dimensiotyyppi
                items={dimensionList.filter((item) => !selected.find((other) => item.typeId === other.typeId))}
                onSelect={(value) => {
                    const newSelected = [value, ...selected];
                    dispatch.bookkeepingAccount.setPostingDefaultDimensions({
                        incomeType: original.incomeType,
                        defaultDimensions: List((newSelected ?? []).map((item) => item.originalDimension)),
                        defaultDimensionAccount: objectKey,
                        exist: posting.size > 0,
                    });
                }}
                // disabloidaan jos kaikkia dimensiotyyppejä on yksi valittuna
                isDisabled={dimensionList.filter((item) => !selected.find((other) => item.typeId === other.typeId)).length <= 0}
                itemKey={'value'}
                itemValue={'name'}
            />
            {/* tässä custom ratkasu ChipStackin sijaan koska se ei tuntunut sopivan yhteen datatablen celliin
             pitkillä teksteillä. jos joku fronttipätevämpi tätä joskus kahtoo niin voi keksiä jonkun nätimmän tavan */}
            <div>
                <table className="u-padding-none u-margin-none">
                    <tbody>
                        {selected.map((item, key) => (
                            <tr key={key}>
                                <td valign="center" align="center" className="u-margin-bottom-tiny">
                                    <button onClick={() => {
                                        const newSelected = selected.toSpliced(key, 1);
                                        dispatch.bookkeepingAccount.setPostingDefaultDimensions({
                                            incomeType: original?.incomeType,
                                            defaultDimensions: List((newSelected ?? []).map((item) => item.originalDimension)),
                                            defaultDimensionAccount: objectKey,
                                            exist: posting.size > 0,
                                        });
                                    }}>
                                        <MDIcon icon="cancel"/>
                                    </button>
                                </td>
                                <td>
                                    { item.name }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const resolveTabIndex = (index) => {
    if (index === 0) {
        return index + 2;
    }
    return index * 3;
};

DefaultDimensionsField.propTypes = {
    index: PropTypes.number,
    original: PropTypes.object,
    isContraAccount: PropTypes.bool,
};

DefaultDimensionsField.defaultProps = {
    index: 0,
    original: {},
    isContraAccount: false,
};
