import PropTypes from 'prop-types';

const menuBarItem = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    href: PropTypes.string,
    label: PropTypes.string,
};

export default PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
        ...menuBarItem,
        items: PropTypes.arrayOf(PropTypes.shape(menuBarItem)),
    }),
]);
