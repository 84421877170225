import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Placeholder } from 'shared/components';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import UserBlock from 'shared/components/UserBlock';
import { Value } from 'shared/components/Value';

const Child = ({ attributes: { isSsnVisible } }) => {
    const customer = useSelector(select.contract.getCustomer);
    const isEmployee = useSelector(select.userMetadata.isEmployee);
    const isLoading = useSelector((state) => state.loading.effects.contract.fetchContract);

    if (! customer) {
        return (
            <SummaryListItem term={_trans('Hoidettava', {}, 'common')}>
                <Value>{_trans('Ei valittu', {}, 'common')}</Value>
            </SummaryListItem>
        );
    }

    const details = [
        [customer.fullAddress],
        [customer.phoneNumber],
    ].filter((detail) => detail.toString().trim() !== '');

    return (
        <SummaryListItem term={_trans('Hoidettava', {}, 'common')}>
            <Placeholder isPending={isLoading}>
                {/*TODO: User/constants/routes.js refaktorointi => exporttaa vain routet ulos, ei komponentteja*/}
                <UserBlock
                    user={customer}
                    size="small"
                    relationType="customer"
                    isSsnVisible={isSsnVisible}
                    hasLinkToDetails={! isEmployee}
                />
                {details.map((detail, key) => (
                    <Fragment key={key}>
                        {detail}<br/>
                    </Fragment>
                ))}
            </Placeholder>
        </SummaryListItem>
    );
};

Child.propTypes = {
    attributes: PropTypes.shape({
        translations: PropTypes.object,
        isSsnVisible: PropTypes.bool,
    }).isRequired,
};

export default Child;
