import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { select } from '@rematch/select';
import PostingActionsColumn from './PostingActionsColumn';
import DataTable from 'shared/containers/DataTable';
import PostingField from 'shared/Bookkeeping/containers/PostingField';
import { DefaultDimensionsField } from 'shared/Bookkeeping/containers/DefaultDimensionsField';
import { ActionBar, Button, Feedback, MDSpinner, Page } from 'shared/components';

@connect((state, props) => ({
    incomeTypes: select.earnedIncomes.getIncomeTypes(state),
    accounts: select.bookkeepingAccount.getAccounts(state),
    modifiedFields: state.bookkeepingAccount.get('postings', List([])).filter((posting) => posting.get('modified')),
    personnelGroup: select.personnelGroup.getGroupById(state, parseInt(_.get(props, 'match.params.id'), 10)),
    isLoading: _.get(state, 'loading.effects.bookkeepingAccount.fetchPostings', false) ||
        _.get(state, 'loading.effects.earnedIncomes.fetchIncomeTypes', false)
}))
export default class BookkeepingPostingsList extends Component {

    static propTypes = {
        dispatch: PropTypes.func,
        isLoading: PropTypes.bool,
        incomeTypes: PropTypes.array,
        modifiedFields: PropTypes.instanceOf(List),
        personnelGroup: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
        }),
    };

    static defaultProps = {
        dispatch() {},
        isLoading: false,
        incomeTypes: [],
        modifiedFields: List(),
        personnelGroup: {},
    };

    constructor(props) {
        super(props);
        props.dispatch.earnedIncomes.fetchIncomeTypes(true);
        props.dispatch.bookkeepingAccount.fetchPostings(_.get(props, 'match.params.id', ''));
        props.dispatch.postingsDimensions.fetchDimensionTypes({ isBookkeepDefaultDimension: true });
    }

    componentDidUpdate(prevProps) {
        // Jos id vaihtuu, ladataan uudet tiliöinnit
        if (_.get(this.props, 'match.params.id', '') !== _.get(prevProps, 'match.params.id', '')) {
            this.props.dispatch.bookkeepingAccount.fetchPostings(_.get(this.props, 'match.params.id', ''));
        }
    }

    renderPostingsList() {
        return (
            <DataTable
                columns={[
                    {
                        Header: _trans('company_manager.bookkeeping_accounts.table.postings.income_type'),
                        accessor: 'name',
                    },
                    {
                        Header: _trans('company_manager.bookkeeping_accounts.table.postings.account'),
                        id: 'account',
                        Cell: (props) => (<PostingField {...props} />),
                        filterable: false,
                        overflow: false,
                    },
                    {
                        Header: _trans('Oletusdimensiot (Debet tili)', {}, 'bookkeeping'),
                        id: 'defaultDimensions',
                        accessor: 'defaultDimensions',
                        Cell: (props) => (<DefaultDimensionsField {...props} />),
                        filterable: false,
                        overflow: false,
                        wordWrap: true,
                        width: 240,
                    },
                    {
                        Header: _trans('company_manager.bookkeeping_accounts.table.postings.contra_account'),
                        accessor: 'contraAccount',
                        Cell: (props) => (<PostingField isContraAccount {...props} />),
                        filterable: false,
                        overflow: false,
                    },
                    {
                        Header: _trans('Oletusdimensiot (Kredit tili)', {}, 'bookkeeping'),
                        id: 'defaultDimensions',
                        accessor: 'defaultDimensions',
                        Cell: (props) => (<DefaultDimensionsField isContraAccount {...props} />),
                        filterable: false,
                        overflow: false,
                        wordWrap: true,
                        width: 240,
                    },
                ]}
                actionsColumn={<PostingActionsColumn/>}
                data={this.props.incomeTypes}
            />
        );
    }

    render() {
        const { dispatch, modifiedFields, isLoading } = this.props;

        if (isLoading) {
            return <MDSpinner wrapped/>;
        }

        return (
            <Page
                heading={_.get(this.props, 'personnelGroup.name', '-')}
            >
                <Feedback type="info" modifierClass="u-margin-bottom-small">
                    {_transMd('company_manager.bookkeeping_accounts.table.postings.help')}
                </Feedback>
                {this.renderPostingsList()}
                <ActionBar alignItems="right">
                    <Button
                        disabled={isLoading}
                        primary
                        onClick={() => dispatch.bookkeepingAccount.handleFields(
                            modifiedFields,
                            _.get(this.props, 'match.params.id', '')
                        )}
                    >
                        {_trans('button.save')}
                    </Button>
                </ActionBar>
            </Page>
        );
    }
}
