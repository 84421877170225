import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import MDIcon from 'shared/components/MDIcon';

/**
 * Mahdollistaa näkyvämmän "laatikko"-linkin luonnin. Linkin osoitteena voidaan käyttää joko normaalia URL:ia tai
 * React Routerin routea.
 * @param to - Käytetään React Routerin yhteydessä
 * @param href - Normaali linkki
 * @param children - Linkin sisältö
 * @param mdIcon - Ikoni
 * @param iconAfterText - Onko ikoni tekstin jälkeen vai sitä ennen
 * @param modifierClass - Lisä luokitusta itse linkille
 * @param target - A tagin target attribuutti, jos ei anneta ei target tagia lisätä.
 * @returns {*}
 * @constructor
 */
function OutlineLink({
    to = null,
    href = null,
    children,
    mdIcon = null,
    iconAfterText = false,
    modifierClass = '',
    target = null,
}) {
    if (to === null && href === null) {
        console.error('href or to parameter is missing.');
        return null;
    }

    const childrenCount = React.Children.count(children);

    const iconClass = classNames({
        'u-margin-right-tiny': childrenCount > 0 && iconAfterText === false,
        'u-margin-left-tiny': childrenCount > 0 && iconAfterText === true,
    });

    const linkClass = classNames('c-outline-link u-text-truncate', {
        [modifierClass]: modifierClass !== '',
    });

    const outlineLinkContent = (
        <span>
            {(mdIcon && iconAfterText === false) && <MDIcon icon={mdIcon} modifierClass={iconClass} />}
            {children}
            {(mdIcon && iconAfterText === true) && <MDIcon icon={mdIcon} modifierClass={iconClass} />}
        </span>
    );

    if (href !== null) {
        return (
            <a href={href} className={linkClass} target={target}>
                {outlineLinkContent}
            </a>
        );
    }

    return (
        <Link to={to} className={linkClass}>
            {outlineLinkContent}
        </Link>
    );
}

OutlineLink.propTypes = {
    to: PropTypes.string,
    href: PropTypes.string,
    mdIcon: PropTypes.string,
    modifierClass: PropTypes.string,
    iconAfterText: PropTypes.bool,
    target: PropTypes.string,
    children: PropTypes.node,
};

export default OutlineLink;