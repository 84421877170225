import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Renderöi sivupalkin elementin.
 *
 * @param isSelected
 * @param isDisabled
 * @param href
 * @param scrollTo
 * @param children
 * @param modifierClass
 * @param target
 * @param isAdmin
 * @returns {*}
 * @constructor
 */
function SideBarItem({ isSelected, isDisabled, href, children, modifierClass, target, isAdmin }) {
    const contentProps = {
        className: classNames('c-side-bar__item ', {
            'is-selected': isSelected,
            'is-disabled': isDisabled,
            'is-admin': isAdmin,
            [modifierClass]: modifierClass,
        }),
    };


    if (href !== '') {
        contentProps.href = href;
    }

    if (target !== '') {
        contentProps.target = target;
        // Jos targetti uus tabi, lisäillään rel attribuutit
        if (target === '_blank') {
            contentProps.rel = 'nofollow noreferrer';
        }
    }

    // Jos elementti ei ole disabloitu ja sillä on href tehdään siitä linkki.
    return React.createElement(isDisabled || href === '' ? 'div' : 'a', contentProps, children);
}

SideBarItem.defaultProps = {
    isSelected: false,
    isDisabled: false,
    href: '',
    modifierClass: null,
    target: '',
    isAdmin: false,
};

SideBarItem.propTypes = {
    /**
     * Onko linkkielementti valittu.
     */
    isSelected: PropTypes.bool,

    /**
     * Onko linkkielementti disabloitu.
     */
    isDisabled: PropTypes.bool,

    /**
     * Linkki.
     */
    href: PropTypes.string,

    /**
     * Linkkielementin sisältö.
     */
    children: PropTypes.node.isRequired,

    /**
     * CSS:n muokkausluokka.
     */
    modifierClass: PropTypes.string,

    /**
     * Linkin mahdollinne target
     */
    target: PropTypes.string,

    /**
     * Korostaa linkkiä admin värillä
     */
    isAdmin: PropTypes.bool,
};

export default SideBarItem;
