import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { select } from '@rematch/select';
import { useHistory } from 'react-router-dom';
import Page from 'shared/components/Page';
import { ActionBar } from 'shared/components';
import MDSpinner from 'shared/components/MDSpinner';
import Button from 'shared/components/Button';
import { ReportingForm } from 'shared/ReportingForms/containers/ReportingForm';
import { userSelectors } from 'shared/UserDetails/stores/user';
import { ReportingFormsList } from 'shared/UserDetails/containers/shared/ReportingForms/ReportingFormsList';
import EmptyState from 'shared/components/EmptyState';
import { reportingFormResourceTypes } from 'shared/ReportingForms/constants/reportingFormResourceTypes';
import { ReportingFormSummary } from 'shared/ReportingForms/containers/ReportingFormSummary';
import { ReportingFormTemplateList } from 'shared/ReportingForms/containers/ReportingFormTemplateList';
import { getParameterByName } from 'shared/utils/commonUtils';

export const ReportingForms = () => {
    const dispatch = useDispatch();
    const selectedReportingForm = useSelector(select.reportingForms.getReportingForm);
    const hasSelectedReportingForm = selectedReportingForm?.id;
    const idFromQuery = getParameterByName('id');
    const history = useHistory();

    const clearQueryParam = () => {
        if (idFromQuery) {
            const query = new URLSearchParams(location.search);
            query.delete('id');
            history.replace({
                search: query.toString(),
                hash: history.location.hash,
            });
        }
    };

    useEffect(() => {
        if (idFromQuery && !hasSelectedReportingForm) {
            dispatch.reportingForms.fetchReportingForm(idFromQuery);
        }


        return () => {
            clearQueryParam();
        };
    }, []);

    const [isCreatingNewForm, setIsCreatingNewForm] = useState(false);
    const [isEditingForm, setIsEditingNewForm] = useState(false);
    const [reportingFormGroup, setReportingFormGroup] = useState(null);

    const userId = useSelector((state) => userSelectors.getUserId(state));
    const isLoadingReportingFormTemplates = useSelector((state) => state.loading.effects.reportingForms.fetchReportingFormTemplates);
    const isLoadingReportingForm = useSelector((state) => state.loading.effects.reportingForms.fetchReportingForm);
    const isConfirming = useSelector((state) => state.loading.effects.reportingForms.postReportingFormConfirmation);
    const templates = useSelector(select.reportingForms.getReportingFormTemplates);

    const [template, setTemplate] = useState(null);

    if (isLoadingReportingFormTemplates || isLoadingReportingForm) {
        return <MDSpinner wrapped />;
    }

    if (isCreatingNewForm || hasSelectedReportingForm || isEditingForm) {
        if (! template && ! hasSelectedReportingForm) {
            if (templates.length === 0) {
                return (
                    <EmptyState message={_trans('Sinulla ei ole yhtään lomaketta valittavana.', {}, 'reporting_forms')} />
                );
            }

            return (
                <Page
                    maxWidth="medium-2"
                    heading={_trans('Valitse lomake', {}, 'reporting_forms')}
                >
                    <Button
                        flat
                        mdIcon="arrow_back"
                        onClick={() => {
                            setIsEditingNewForm(false);
                            setIsCreatingNewForm(false);
                        }}
                        modifierClass="u-margin-bottom"
                    >
                        {_trans('Takaisin', {}, 'common')}
                    </Button>
                    <ReportingFormTemplateList onSelectTemplate={setTemplate} />
                </Page>
            );
        }

        if (! isEditingForm && ! isCreatingNewForm) {
            const reportingFormId = selectedReportingForm?.id;
            return (
                <Page maxWidth="medium">
                    <Button
                        flat
                        mdIcon="arrow_back"
                        onClick={() => {
                            dispatch.reportingForms.clearReportingForm();
                            setIsEditingNewForm(false);
                            clearQueryParam();
                            //setReportingFormGroup(null);
                        }}
                        modifierClass="u-margin-bottom"
                    >
                        {_trans('Takaisin', {}, 'common')}
                    </Button>
                    <ReportingFormSummary
                        reportingForm={selectedReportingForm}
                        isConfirming={isConfirming}
                        onEdit={() => setIsEditingNewForm(true)}
                        onUnconfirm={() => {
                            dispatch.reportingForms.unconfirmReportingForm(reportingFormId).then(() => {
                                dispatch.reportingForms.fetchReportingForm(reportingFormId);
                            });
                        }}
                        onConfirm={() => {
                            dispatch.reportingForms.confirmReportingForm(reportingFormId).then((response) => {
                                if (response.status === 'ok') {
                                    dispatch.notifications.addSuccess(_trans('Lomake vahvistettu.', {}, 'reporting_forms'));
                                    dispatch.reportingForms.fetchReportingForm(reportingFormId);
                                }
                            });
                        }}
                        onRemove={() => {
                            dispatch.reportingForms.deleteReportingForm(reportingFormId).then(() => {
                                dispatch.notifications.addSuccess(_trans('Lomake poistettu.', {}, 'reporting_forms'));
                                dispatch.reportingForms.fetchReportingForms({
                                    resourceId: userId,
                                    resourceType: reportingFormResourceTypes.RELATION_USER
                                });
                                dispatch.reportingForms.clearReportingForm();
                                setIsEditingNewForm(false);
                                setReportingFormGroup(null);
                            });
                        }}
                    />
                </Page>
            );
        }

        return (
            <ReportingForm
                template={hasSelectedReportingForm ? selectedReportingForm?.template : template}
                selectedReportingForm={selectedReportingForm}
                onSubmit={({ response }) => {
                    if (response.status === 'ok') {
                        dispatch.notifications.addSuccess(hasSelectedReportingForm
                            ? _trans('Lomakkeen tiedot päivitetty.', {}, 'reporting_forms')
                            : _trans('Lomake tallenettu.', {}, 'reporting_forms'));
                        setIsCreatingNewForm(false);
                        setIsEditingNewForm(false);
                        dispatch.reportingForms.fetchReportingForms({ resourceId: userId, resourceType: reportingFormResourceTypes.RELATION_USER });
                        dispatch.reportingForms.fetchReportingForm(selectedReportingForm?.id || response?.data?.id);
                        window.scrollTo(0, 0);
                    } else {
                        dispatch.notifications.addError(_trans('Lomaketta ei voitu tallentaa.', {}, 'reporting_forms'));
                    }
                }}
                onGoBack={() => {
                    dispatch.reportingForms.clearReportingForm();
                    setTemplate(null);
                    setIsCreatingNewForm(false);
                    setIsEditingNewForm(false);
                    window.scrollTo(0, 0);
                }}
                relatedResourceType={reportingFormResourceTypes.RELATION_USER}
                relatedResourceId={userId}
            />
        );
    }

    return (
        <Page
            maxWidth="medium-2"
            heading={isCreatingNewForm
                ? _trans('Uusi lomake', {}, 'reporting_forms')
                : _trans('Lomakkeet', {}, 'reporting_forms')}
            actionItems={! isCreatingNewForm && (
                <ActionBar>
                    <Button
                        mdIcon="add"
                        primary
                        onClick={() => {
                            const template = templates.find((template) => template?.name === reportingFormGroup);
                            if (template) {
                                setTemplate(template);
                            }
                            setIsCreatingNewForm(true);
                        }}
                    >
                        {_trans('Uusi lomake', {}, 'reporting_forms')}
                    </Button>
                </ActionBar>
            )}
        >
            <ReportingFormsList
                reportingFormGroup={reportingFormGroup}
                onSelectReportingFormGroup={(groupName) => setReportingFormGroup(groupName)}
                onSelectReportingForm={(reportingFormId) => {
                    dispatch.reportingForms.fetchReportingForm(reportingFormId);
                }}
            />
        </Page>
    );
};
