import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ActionBar, Button } from 'shared/components';
import { WAGES_CERT } from 'shared/UserDetails/containers/shared/WeeklyHoursStatement/constants';

export const ActionBarContainer = ({ type, heading, handleSubmit, setFieldValue }) => (
    <Fragment>
        <h3 className="c-heading-subheading u-margin-top-small u-margin-bottom-small">{heading}</h3>
        <ActionBar modifierClass="u-margin-top-small" alignItems="left">
            {false && <Button disabled name={`${type}.excel`} onClick={() => {
                setFieldValue('submitType', `${type}.excel`, false);
                handleSubmit();
            }}>
                {_trans('Tulosta Excel', {}, 'extract')}
            </Button>}
            <Button disabled={type===WAGES_CERT} name={`${type}.pdf`} onClick={() => {
                setFieldValue('submitType', `${type}.pdf`, false);
                handleSubmit();
            }}>
                {_trans('Tulosta PDF', {}, 'extract')}
            </Button>
        </ActionBar>
    </Fragment>
);

ActionBarContainer.propTypes = {
    type: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};
