import { fromJS, List, Map } from 'immutable';
import _ from 'lodash';
import { push } from 'connected-react-router';
import api from 'api';
import { actions as notificationActions } from 'shared/stores/notifications';


export default {
    state: fromJS({
        accounts: [],
        postings: [{}],
        account: {},
        postingErrors: [{}],
    }),
    reducers: {
        setAccounts: (state, payload) => state.set('accounts', fromJS(payload)),
        setAccount: (state, payload) => state.set('account', fromJS(payload)),
        setPostings: (state, payload) => state.set('postings', fromJS(payload)),
        setIncomeTypes: (state, payload) => state.set('incomeTypes', fromJS(payload)),
        setPosting: (state, { incomeType, accountType, accountValue, exist, defaultDimensions }) => {
            const postings = state.get('postings');
            if (exist) {
                const index = postings.findIndex((posting) => posting.get('incomeType') === incomeType);
                return state.set('postings', postings.update(index, (posting) =>
                    posting.set(accountType, fromJS(accountValue), defaultDimensions).set('modified', true)));
            }
            return state.set('postings', postings.push(fromJS({ incomeType, [accountType]: accountValue, modified: true, defaultDimensions })));
        },
        setPostingDefaultDimensions: (state, { incomeType, defaultDimensions, defaultDimensionAccount, exist }) => {
            const postings = state.get('postings');
            if (exist) {
                const index = postings.findIndex((posting) => posting.get('incomeType') === incomeType);
                return state.set('postings', postings.update(index, (posting) =>
                    posting.set(defaultDimensionAccount, defaultDimensions).set('modified', true)));
            }
            return state.set('postings', postings.push(fromJS({ incomeType, [defaultDimensionAccount]: defaultDimensions, modified: true })));
        },
        setErrors: (state, payload) => state.set('postingErrors', fromJS(payload)),
        addError: (state, payload) => state.set('postingErrors', (state.get('postingErrors').push(fromJS(payload)))),
        deletePostingByIncomeType: (state, incomeType) =>
            state.set(
                'postings',
                state.get('postings')
                    .delete(
                        state.get('postings').findIndex((posting) => posting.get('incomeType') === incomeType)
                    )
            )
    },
    selectors: {
        getAccounts: (state) => state.get('accounts'),
        getAccountsNameAndNumberCombined: (state) => state.get('accounts').map((account) => account.set('combined', `${account.get('number')} - ${account.get('name')}`)),
        getPostings: (state) => state.get('postings'),
        getAccount: (state) => state.get('account'),
        getPostingByIncomeType: (state, incomeType) => state
            .get('postings')
            .find((posting) => posting.get('incomeType') === incomeType) || Map({}),
        getPostingErrors: (state, incomeType) => state.get('postingErrors').find((error) => error.get('incomeType') === incomeType) || Map({}),
    },
    effects: (dispatch) => ({
        async fetchAccounts() {
            try {
                const json = await api.get(Routing.generate('get_bookkeepingaccounts'));
                this.setAccounts(json.data);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchPostings(personnelGroup) {
            try {
                const json = await api.get(Routing.generate('get_bookkeepingpostings', { personnelGroup }));
                this.setPostings(json.data);
            } catch (e) {
                console.error(e);
            }
        },
        async postAccount(model) {
            try {
                await api.post(Routing.generate('post_bookkeepingaccounts'), _.omit(model, 'returnUrl'));
                this.fetchAccounts();
                await dispatch(push(model.returnUrl));
            } catch (e) {
                console.error(e);
            }
        },
        async putAccount(model) {
            try {
                await api.put(Routing.generate('put_bookkeepingaccount', { account: model.id }), _.omit(model, ['id', 'returnUrl']));
                await dispatch(push(model.returnUrl));
                this.fetchAccounts();
            } catch (e) {
                console.error(e);
            }
        },
        async deleteAccount(id) {
            try {
                await api.del(Routing.generate('delete_bookkeepingaccount', { account: id }));
                this.fetchAccounts();
            } catch (e) {
                console.error(e);
            }
        },
        async fetchAccount(id) {
            try {
                const json = await api.get(Routing.generate('get_bookkeepingaccount', { account: id }));
                this.setAccount(json.data);
            } catch (e) {
                console.error(e);
            }
        },
        async deletePosting(posting) {
            try {
                await api.del(Routing.generate('delete_bookkeepingposting', { posting: posting.get('id') }));
                await this.deletePostingByIncomeType(posting.get('incomeType'));
                dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('company_manager.bookkeeping_accounts.notifications.success.posting_deleted')
                }));
            } catch (e) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('company_manager.bookkeeping_accounts.notifications.error.delete_posting'),
                }));
                console.error(e);
            }
        },
        async postPosting(model) {
            try {
                await api.post(Routing.generate('post_bookkeepingpostings'), model);
            } catch (e) {
                console.error(e);
            }
        },

        async putPosting(model) {
            try {
                const json = await api.put(Routing.generate('put_bookkeepingposting', { posting: model.id }), _.omit(model, 'id'));
                console.log(json);
            } catch (e) {
                console.error(e);
            }
        },
        handleFields(fields, rootState, personnelGroup) {
            // Jos ei löydy yhtään muutoksia, niin turha mennä eteenpäin, ilmoitetaan käyttäjälle kuitenkin.
            if (fields.size === 0) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('company_manager.bookkeeping_accounts.notifications.error.no_changes'),
                }));
                return;
            }
            // Resetoidaan virheet
            this.setErrors(fromJS([{}]));
            // Tutkitaan onko kaikki kentät valideita (löytyy molemmat tilit)
            const validations = fields.map((field) => {
                if ((! field.hasIn(['account', 'id'])) || (! field.hasIn(['contraAccount', 'id']))) {
                    this.addError(field);
                    return false;
                }
                return true;
            });
            // Jos löytyy virheitä, niin pysäytetään eteneminen
            if (validations.includes(false)) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('company_manager.bookkeeping_accounts.notifications.error.errors_in_form'),
                }));
                return;
            }
            // Käydään kaikki kentät läpi yksitellen. put tai postataan riippuen onko id:tä.
            fields.map((field) => {
                if (field.has('id')) {
                    this.putPosting({
                        id: field.get('id'),
                        account: field.getIn(['account', 'id']),
                        contraAccount: field.getIn(['contraAccount', 'id']),
                        incomeType: field.get('incomeType'),
                        creditAccountDefaultDimensions: (field.get('creditAccountDefaultDimensions') ?? List()).toJS().map((dimension) => dimension.id),
                        debitAccountDefaultDimensions: (field.get('debitAccountDefaultDimensions') ?? List()).toJS().map((dimension) => dimension.id),
                        personnelGroup,
                    });
                } else {
                    this.postPosting({
                        incomeType: field.get('incomeType'),
                        account: field.getIn(['account', 'id']),
                        contraAccount: field.getIn(['contraAccount', 'id']),
                        creditAccountDefaultDimensions: (field.get('creditAccountDefaultDimensions') ?? List()).toJS().map((dimension) => dimension.id),
                        debitAccountDefaultDimensions: (field.get('debitAccountDefaultDimensions') ?? List()).toJS().map((dimension) => dimension.id),
                        personnelGroup,
                    });
                }
            });
            {/* Puolen sekunnin timeoutti, koska muuten ei välttämättä aina
             päivity oikein painaessa tallenna nappia. Allekirjoittaneella toistui
             erityisesti jos päivitti yhtä kerrallaan. */}
            setTimeout(() => this.fetchPostings(personnelGroup), 500);
        },
    }),
};
