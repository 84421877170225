import PropTypes from 'prop-types';

const workExp = PropTypes.shape({
    balance: PropTypes.number,
    delta: PropTypes.number,
    type: PropTypes.number,
    dateCreated: PropTypes.string,
});

export default PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    subtitle: PropTypes.string,
    history: PropTypes.arrayOf(workExp),
});

