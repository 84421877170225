import { fromJS } from 'immutable';
import moment from 'moment';
import { actions } from 'react-redux-form/lib/immutable';
import _ from 'lodash';
import api from 'api';

const startOfLastMonth = moment().subtract(1, 'months').startOf('month');
const endOfLastMonth = moment().subtract(1, 'months').endOf('month');
/**
 *
 * initialState
 */

const initialState = {
    dimensions: [],
    selectedDimensions: [],
    dateRange: {
        start: startOfLastMonth.format('YYYY-MM-DD'),
        end: endOfLastMonth.format('YYYY-MM-DD'),
    },
};

/**
 * Actions
 */

/**
 * Hakee dimensiot
 *
 * @returns {function(*): Request}
 */
const fetchDimensions = () => (dispatch) => {
    dispatch(actions.setPending('dimensionReport.dimensions', true));
    api
        .get(Routing.generate('get_dimension-types', { restrictDimensionVisibility: 1 }))
        .then((json) => {
            const dimensions = _.get(json, 'data', []);
            dispatch(actions.change('dimensionReport.dimensions', fromJS(dimensions)));
            dispatch(actions.setPending('dimensionReport.dimensions', false));
        });
};

const onSelectDimension = (selectedDimension) => (dispatch, getState) => {
    const state = getState();
    const dimensions = getDimensions(state);
    dispatch(actions.push('dimensionReport.selectedDimensions', fromJS(selectedDimension)));
    dispatch(actions.remove('dimensionReport.dimensions', dimensions.findIndex((dimension) => dimension.get('name') === selectedDimension.name)));
};

const onRemoveSelectedDimension = (selectedDimensionIndex) => (dispatch, getState) => {
    const state = getState();
    const selectedDimensions = getSelectedDimensions(state);
    dispatch(actions.push('dimensionReport.dimensions', selectedDimensions.get(selectedDimensionIndex)));
    dispatch(actions.remove('dimensionReport.selectedDimensions', selectedDimensionIndex));

};

const resetSelectedDimensions = () => (dispatch) => dispatch(actions.change('dimensionReport.selectedDimensions', fromJS([])));

/**
 * Selectors
 */

const isFetchingDimensions = (state) => _.get(state, 'forms.dimensionReport.$form.pending', false);
const getDimensions = (state) => state.dimensionReport.get('dimensions');
const getSelectedDimensions = (state) => state.dimensionReport.get('selectedDimensions');
const getStartDate = (state) => state.dimensionReport.getIn(['dateRange', 'start']);
const getEndDate = (state) => state.dimensionReport.getIn(['dateRange', 'end']);
const getIsExploded = (state) => state.dimensionReport.get('exploded');
const getIsWithTotals = (state) => state.dimensionReport.get('withTotals');
const getIsWithWaitingForPayment = (state) => state.dimensionReport.get('withWaitingForPayment');

/**
 * Exports
 */

export default {
    dimensionReport: fromJS(initialState),
};

export const dimensionReportActions = {
    fetchDimensions,
    onRemoveSelectedDimension,
    onSelectDimension,
    resetSelectedDimensions,
};

export const dimensionReportSelectors = {
    isFetchingDimensions,
    getDimensions,
    getSelectedDimensions,
    getStartDate,
    getEndDate,
    getIsExploded,
    getIsWithTotals,
    getIsWithWaitingForPayment,
};
