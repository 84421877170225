import React from 'react';
import PropTypes from 'prop-types';
import { Field as FormikField, useField } from 'formik';
import { getFormikErrorClassName, hasFormikErrors } from 'shared/utils/formUtils';

export const Field = ({ id, type, children, ...rest }) => {
    const name = rest.name;
    // Onko kentällä virheitä? Jos on muuta kentän reunaväri.
    const [, meta,] = useField(name);
    const hasErrors = hasFormikErrors(meta);
    const errorClassName = getFormikErrorClassName(meta);

    return (
        <FormikField
            type={type}
            name={name}
            // Radio-elementti ei tarvitse id:tä koska muuten näillä kaikilla olisi sama id ja niitä voi olla vain yksi
            id={type !== 'radio' ? (id || name) : null}
            className={errorClassName}
            // Jos kentällä on virheitä kerrotaan myös ruudunlukijoille
            aria-invalid={hasErrors ? true : null}
            // Virheestä kertovan elementin id (FormikErrors)
            {...(hasErrors && { 'aria-errormessage': `${name}Errors` })}
            {...rest}
        >
            {children}
        </FormikField>
    );
};

Field.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

Field.defaultProps = {
    type: 'text',
    id: undefined,
    children: undefined,
};
