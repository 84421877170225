import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxField } from 'shared/components/Formik';

/**
 * Säännöllinen työaika valinta.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */

const HasWeeklyWorkHours = (props) => {
    const { name, label } = props;

    return (
        <CheckboxField
            name={name}
            label={label}
        />
    );
};
HasWeeklyWorkHours.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};
export default HasWeeklyWorkHours;
