/**
 * @deprecated Älä käytä koskaan enää missään
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DOMPurify from 'dompurify';
import classNames from 'classnames';
import { Transition, animated } from 'react-spring/renderprops.cjs';
import Notification from 'shared/components/Notification';

const notifiable = (InnerComponent) => {
    class Notifications extends Component {
        static propTypes = {
            onTimeout: PropTypes.func,
        };

        static defaultProps = {
            onTimeout() {},
        };

        static childContextTypes = {
            addNotification: PropTypes.func,
        };

        /**
         * Varmistetaan että notifikaatiot varmasti näkyvät vasta ylävalikon alla.
         */
        constructor(props) {
            super(props);
            const rootElement = document.getElementById('root');

            this.state = {
                notifications: [],
                top: rootElement.offsetTop,
            };
        }

        getChildContext() {
            return {
                addNotification: this.addNotification,
            };
        }

        /**
         * Lisää uuden notifikaatin
         * @param type - Notifikaatin tyyppi (success, info, warning, error)
         * @param message - Itse viesti. Max 2 riviä.
         * @param attributes - Lisäattribuutit: timeout millisekunneissa ja isDismissable eli voidaanko sulkea.
         */
        addNotification = (type, message, attributes) => {
            this.setState({
                notifications: [
                    ...this.state.notifications,
                    {
                        key: new Date().getTime(),
                        type,
                        message,
                        timeout: _.get(attributes, 'timeout', 5000),
                        isDismissable: _.get(attributes, 'isDismissable', true),
                    }
                ]
            });
        };

        /**
         * Poistetaa nootti listalta.
         * @param notification
         */
        onTimeout = (notification) => {
            const key = notification.key;
            this.setState({
                notifications: this.state.notifications.filter((notification) => notification.key !== key)
            });
        };

        render() {
            return (
                <div>
                    <InnerComponent
                        {...this.props}
                        addNotification={this.addNotification}
                    />
                    {this.renderNotifications()}
                </div>
            );
        }

        renderNotifications = () => {
            const { top, notifications } = this.state;
            return (
                <div
                    className={classNames('c-notifications o-pin o-pin--fixed o-pin--right u-margin-top-small u-margin-right-small', {
                        'u-pointer-events-none': _.size(notifications) === 0,
                    })}
                    style={{ top }}
                >
                    <ul className="c-notification-list o-list-bare u-margin-bottom-none">
                        <Transition
                            native
                            items={notifications}
                            keys={(notification) => notification.key}
                            from={{
                                opacity: 0,
                                marginTop: 0,
                                maxHeight: 0,
                            }}
                            leave={{
                                opacity: 0,
                                marginTop: 0,
                                maxHeight: 0,
                            }}
                            enter={{
                                opacity: 1,
                                marginTop: 12,
                                maxHeight: 66,
                            }}
                        >
                            {(item) => (style) => (
                                <animated.li style={style}>
                                    { this.renderNotification(item)}
                                </animated.li>
                            )}
                        </Transition>
                    </ul>
                </div>
            );
        };

        /**
         * Renderöi joko puhtaan tekstin tai React Markdown mankelista ulos tulevan React-komponentin.
         * Testaa käytännössä vain onko viesti objekti joten olettaa että devaaja tietää mitä tekee.
         * @param notification
         * @returns {*}
         */
        renderNotification = (notification) => (
            <Notification
                type={notification.type}
                timeout={_.get(notification, 'timeout', 5000)}
                isDismissable={notification.isDismissable}
                onTimeout={() => this.onTimeout(notification)}
            >
                { typeof notification.message === 'object'
                    ? notification.message
                    : <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notification.message) }} />
                }
            </Notification>
        );
    }

    return Notifications;
};

export default notifiable;
