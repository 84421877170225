export const payslipDeliveryMethodTypes = {
    PAYSLIP_DELIVERY_METHOD_S_MAIL: 1,
    PAYSLIP_DELIVERY_METHOD_E_MAIL: 2,
    PAYSLIP_DELIVERY_METHOD_COMMUNE_SENDS: 3,
    PAYSLIP_DELIVERY_METHOD_COMPANY_SENDS: 31,
    PAYSLIP_DELIVERY_METHOD_ENCRYPTED_E_MAIL: 4,
    PAYSLIP_DELIVERY_METHOD_EMAIL_NOTIFICATION: 5,
    PAYSLIP_DELIVERY_METHOD_E_PAYSLIP: 6,

    PAYSLIP_DELIVERY_METHOD_NO_DELIVERY: 10,
};

const payslipDeliveryMethodTypeMap = {
    [payslipDeliveryMethodTypes.PAYSLIP_DELIVERY_METHOD_S_MAIL]: Translator.trans('job_contract.form.payslip_delivery_method.choice.s_mail'),
    [payslipDeliveryMethodTypes.PAYSLIP_DELIVERY_METHOD_E_MAIL]: Translator.trans('job_contract.form.payslip_delivery_method.choice.e_mail'),
    [payslipDeliveryMethodTypes.PAYSLIP_DELIVERY_METHOD_COMMUNE_SENDS]: Translator.trans('job_contract.form.payslip_delivery_method.choice.commune_sends'),
    [payslipDeliveryMethodTypes.PAYSLIP_DELIVERY_METHOD_COMPANY_SENDS]: Translator.trans('job_contract.form.payslip_delivery_method.choice.company_sends'),
    [payslipDeliveryMethodTypes.PAYSLIP_DELIVERY_METHOD_ENCRYPTED_E_MAIL]: Translator.trans('job_contract.form.payslip_delivery_method.choice.encrypted_e_mail'),
    [payslipDeliveryMethodTypes.PAYSLIP_DELIVERY_METHOD_EMAIL_NOTIFICATION]: Translator.trans('job_contract.form.payslip_delivery_method.choice.email_notification'),
    [payslipDeliveryMethodTypes.PAYSLIP_DELIVERY_METHOD_E_PAYSLIP]: _trans('Verkkopalkka (Maventa)', {}, 'common'),
    [payslipDeliveryMethodTypes.PAYSLIP_DELIVERY_METHOD_NO_DELIVERY]: Translator.trans('job_contract.form.payslip_delivery_method.choice.no_delivery'),
};

export function translatePayslipDeliveryMethodType(type) {
    return type in payslipDeliveryMethodTypeMap ? payslipDeliveryMethodTypeMap[type] : '';
}

