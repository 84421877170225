import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { isAfter, isBefore, addDays, addMonths, differenceInCalendarDays, format, subDays } from 'date-fns';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { DateInputField } from 'shared/components/Formik';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { contractTypes } from 'shared/constants/index';
import { isValidDate } from 'shared/utils/dateUtils';

/**
 * Koeajan päättymispäivä valinta.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const TrialPeriodEndDate = (props) => {

    const { name, placeholder } = props;
    const [hasTrialPeriodField] = useField(fieldNames.HAS_TRIAL_PERIOD);
    const hasTrialPeriod = hasTrialPeriodField.value;
    const [field,,helpers] = useField(name);
    const restrictions = useSelector(select.contract.getCollectiveAgreementRestrictions);
    const [originalStartDateField] = useField(fieldNames.ORIGINAL_START_DATE);

    const [startDateField] = useField(fieldNames.START_DATE);
    const isAcceptedContract = useSelector(select.contract.isAcceptedContract);
    const acceptedContractStartDate = useSelector(select.contract.getStartDate);
    // Haetaan aloituspäivä joko lomakkeelta tai sopparin tiedoista
    const startDate = isAcceptedContract
        ? acceptedContractStartDate
        : startDateField.value;

    const [endDateField] = useField(fieldNames.END_DATE);
    const [contractTypeField] = useField(fieldNames.CONTRACT_TYPE);

    const contractType = parseInt(contractTypeField.value, 10);
    const endDate = new Date(endDateField.value);
    const maxTrialPeriodLength = restrictions?.maxTrialPeriodLength !== undefined
        && restrictions?.maxTrialPeriodLength;

    const trialEndDate = (startDate) => {
        if (! isValidDate(startDate)) return null;

        if (contractType === contractTypes.OPEN_ENDED) {
            return startDate ? addMonths(subDays(startDate, 1),maxTrialPeriodLength) : null;
        }
        else if (contractType === contractTypes.FIXED_TERM && isValidDate(endDate)) {
            const durationInDays = differenceInCalendarDays(endDate, startDate) ;
            return startDate
            //Koeaika saa olla maksimissaan 6 kuukautta (182,5pv). Maht.min valitsee pienemmän arvon.
                ? addDays(startDate, Math.floor(Math.min(durationInDays / 2, 182.5)))
                : null;
        }
        return null;
    };
    useEffect(() => {
        const maximumDate = new Date(trialEndDate(originalStartDateField.value
            ? new Date(originalStartDateField.value)
            : new Date(startDate)));
        
        if (! isValidDate(maximumDate)) {
            return;
        }
        helpers.setValue(format(maximumDate, 'yyyy-MM-dd'));
    }, [originalStartDateField.value, startDate, endDateField.value, contractTypeField.value]);

    const trialPeriodEndDate = new Date(field.value);
    const minDate = originalStartDateField.value ?? startDate;

    return (
        <DateInputField
            name={name}
            placeholder={placeholder}
            maximumDate={trialPeriodEndDate && isValidDate(trialPeriodEndDate) && isAfter(trialPeriodEndDate, isValidDate(startDate))
                ? trialPeriodEndDate
                : addMonths(subDays(new Date(minDate), 1), 6) }
            validate={(value) => {
                if (hasTrialPeriod && isBefore(new Date(value), new Date(minDate))) {
                    return _trans('Anna päivämäärä joka on %originalStartDate% tai sen jälkeen', { originalStartDate: _toLocaleDate(minDate) }, 'jobContract');
                }
            }
            }
        />
    );
};

TrialPeriodEndDate.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,

};
export default TrialPeriodEndDate;
