import React from 'react';
import PropTypes from 'prop-types';
import { Value } from 'shared/components/Value';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';

/**
 * Työtehtävän sijainti.
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const JobTargetAddress = ({ name, value, label }) => {
    if (! Array.isArray(value) || value.length === 0) return null;

    return (
        <SummaryListItem term={label}>
            <Value ariaDescribedBy={name}>
                {(value ?? []).map((address, key) => (
                    <div key={key}>{address}</div>
                ))}
            </Value>
        </SummaryListItem>
    );
};

JobTargetAddress.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
};

export default JobTargetAddress;
