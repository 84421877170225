import { Map } from 'immutable';
import _ from 'lodash';
import { payDayModes } from 'shared/constants/payDayModes';

const initialState = Map({
    restrictions: Map([]),
    restrictionsPending: false,
    isValid: false,
    errors: Map({
        contractType: null,
        salaryType: null,
        payPeriodLength: null,
        paydayMode: null,
        holidayPayMethod: null,
        payday: null,
    }),

    contractType: null,
    salaryType: null,
    payPeriodLength: null,
    paydayMode: null,
    holidayPayMethod: null,
    payday: null,
});

export const fieldNames = {
    CONTRACT_TYPE: 'contractType',
    SALARY_TYPE: 'salaryType',
    HOLIDAY_PAY_METHOD: 'holidayPayMethod',
    PAY_PERIOD_LENGTH: 'payPeriodLength',
    PAYDAY_MODE: 'paydayMode',
    PAYDAY: 'payday',
    MAX_TRIAL_PERIOD_LENGTH: 'maxTrialPeriodLength',
};

const privateFieldNames = {
    RESTRICTIONS: 'restrictions',
    RESTRICTIONS_PENDING: 'restrictionsPending',
    IS_VALID: 'isValid',
    ERRORS: 'errors',
};

const namespace = 'TESRestrictedField';

/**
 * Action types
 */

const CHANGE_CONTRACT_TYPE = `${namespace}/CHANGE_CONTRACT_TYPE`;
const CHANGE_SALARY_TYPE = `${namespace}/CHANGE_SALARY_TYPE`;
const CHANGE_PAY_PERIOD_LENGTH = `${namespace}/CHANGE_PAY_PERIOD_LENGTH`;
const CHANGE_PAYDAY_MODE = `${namespace}/CHANGE_PAYDAY_MODE`;
const CHANGE_HOLIDAY_PAY_METHOD = `${namespace}/CHANGE_HOLIDAY_PAY_METHOD`;
const CHANGE_PAYDAY = `${namespace}/CHANGE_PAYDAY`;
const RESET_PAYDAY = `${namespace}/RESET_PAYDAY`;
const SET_RESTRICTIONS = `${namespace}/SET_RESTRICTIONS`;
const SET_RESTRICTIONS_PENDING = `${namespace}/SET_RESTRICTIONS_PENDING`;
const LOAD_RESTRICTED_FIELDS = `${namespace}/LOAD_RESTRICTED_FIELDS`;
const SET_DEFAULT_RESTRICTIONS = `${namespace}/SET_DEFAULT_RESTRICTIONS`;
const RESET = `${namespace}/RESET`;
const VALIDATE = `${namespace}/VALIDATE`;
const SET_ERROR = `${namespace}/SET_ERROR`;
const SET_VALIDITY = `${namespace}/SET_VALIDITY`;

/* eslint-disable no-case-declarations */
/**
 * Reducer
 */
function TESRestrictedFieldReducer(state = initialState, action) {
    const salaryRestrictions = state.getIn([privateFieldNames.RESTRICTIONS, 'allowedContractTypesAndSalaryTypes']);
    const periodLengthRestrictions = state.getIn([privateFieldNames.RESTRICTIONS, 'allowedSalaryTypesAndPayPeriodLengths']);
    const payDayRestrictions = state.getIn([privateFieldNames.RESTRICTIONS, 'allowedPayPeriodLengthsAndPayDayModes']);
    const holidayPayMethodRestrictions = state.getIn([privateFieldNames.RESTRICTIONS, 'allowedContractTypesAndHolidayPaymethods']);

    let paydayMode = null;

    switch (action.type) {
        case CHANGE_CONTRACT_TYPE:
            let defSalaryType = state.get(fieldNames.SALARY_TYPE);
            let defHolidayPayMethod = state.get(fieldNames.HOLIDAY_PAY_METHOD);
            let defPayPeriodLength = state.get(fieldNames.PAY_PERIOD_LENGTH);
            let defPaydayMode = state.get(fieldNames.PAYDAY_MODE);
            if (salaryRestrictions) {
                _.each(salaryRestrictions, (item) => {
                    if (item.primaryType == action.value) {
                        if (item.allowedSubtypes.length == 1) {
                            defSalaryType = _.head(item.allowedSubtypes);
                        } else if (_.indexOf(item.allowedSubtypes, defSalaryType) === -1) {
                            defSalaryType = null;
                            defHolidayPayMethod = null;
                            defPayPeriodLength = null;
                            defPaydayMode = null;
                        }
                    }
                });
            }
            if (holidayPayMethodRestrictions) {
                _.each(holidayPayMethodRestrictions, (item) => {
                    if (item.primaryType == action.value) {
                        if (item.allowedSubtypes.length == 1) {
                            defHolidayPayMethod = _.head(item.allowedSubtypes);
                        } else if (_.indexOf(item.allowedSubtypes, defHolidayPayMethod) === -1) {
                            defHolidayPayMethod = null;
                        }
                    }
                });
            }
            if (periodLengthRestrictions) {
                _.each(periodLengthRestrictions, (item) => {
                    if (item.primaryType == defSalaryType) {
                        if (item.allowedSubtypes.length == 1) {
                            defPayPeriodLength = _.head(item.allowedSubtypes);
                        } else if (_.indexOf(item.allowedSubtypes, defPayPeriodLength) === -1) {
                            defPayPeriodLength = null;
                            defPaydayMode = null;
                        }
                    }
                });
            }
            if (payDayRestrictions) {
                _.each(payDayRestrictions, (item) => {
                    if (item.primaryType == defPayPeriodLength) {
                        if (item.allowedSubtypes.length == 1) {
                            defPaydayMode = _.head(item.allowedSubtypes);
                        } else if (_.indexOf(item.allowedSubtypes, defPaydayMode) === -1) {
                            defPaydayMode = null;
                        }
                    }
                });
            }
            return state.set(fieldNames.CONTRACT_TYPE, parseInt(action.value, 10))
                .set(fieldNames.SALARY_TYPE, defSalaryType)
                .set(fieldNames.PAY_PERIOD_LENGTH, defPayPeriodLength)
                .set(fieldNames.PAYDAY_MODE, defPaydayMode)
                .set(fieldNames.HOLIDAY_PAY_METHOD, defHolidayPayMethod);
        case CHANGE_SALARY_TYPE:
            let payPeriodLength = null;

            // Jos vain yksi sallittu jakson pituus, kaiva se ja aseta myöhemmin stateen
            if (periodLengthRestrictions) {
                _.each(periodLengthRestrictions, (item) => {
                    if (item.primaryType == action.value) {
                        if (item.allowedSubtypes.length == 1) {
                            payPeriodLength = _.head(item.allowedSubtypes);
                        }
                    }
                });
            }

            // TODO: Tämä alkaa mennä todella häiriintyneeksi. Pitäisi jotenkin rullata siistimmin läpi tämä arpominen.
            // Samaten jos äsken kaivetulla yksinäisellä jaksonpituudella vain yksi sallittu palkkapäivän määräytyminen
            // aseta sekin stateen myöhemmin (ding dong kello, pimpeli pom)
            if (payPeriodLength && payDayRestrictions) {
                // Hmm.. eikös tässä tutkita payDayRestrictioiden primarytyyppillä, joka on tuo payPeriodLength?
                // Rupeaa olemaan kyllä tämä toteutus suorastaan perseestä.
                _.each(payDayRestrictions, (item) => {
                    if (item.primaryType == payPeriodLength) {
                        if (item.allowedSubtypes.length == 1) {
                            paydayMode = _.head(item.allowedSubtypes);
                        }
                    }
                });
            }

            // Tämä on "myöhemmin"
            return state.set(fieldNames.SALARY_TYPE, parseInt(action.value, 10))
                .set(fieldNames.PAY_PERIOD_LENGTH, payPeriodLength)
                .set(fieldNames.PAYDAY_MODE, paydayMode);
        case CHANGE_PAY_PERIOD_LENGTH:
            if (payDayRestrictions) {
                _.each(payDayRestrictions, (item) => {
                    if (item.primaryType == action.value) {
                        if (item.allowedSubtypes.length == 1) {
                            paydayMode = _.head(item.allowedSubtypes);
                        }
                    }
                });
            }
            return state.set(fieldNames.PAY_PERIOD_LENGTH, parseInt(action.value, 10))
                .set(fieldNames.PAYDAY_MODE, paydayMode);
        case CHANGE_PAYDAY_MODE:
            return state.set(fieldNames.PAYDAY_MODE, parseInt(action.value, 10));
        case CHANGE_HOLIDAY_PAY_METHOD:
            return state.set(fieldNames.HOLIDAY_PAY_METHOD, parseInt(action.value, 10));
        case CHANGE_PAYDAY:
            return state.set(fieldNames.PAYDAY, parseInt(action.value, 10));
        case RESET_PAYDAY:
            if (action.value != payDayModes.PAYDAY_USER_CHOICE
                && action.value != payDayModes.PAYDAY_IN_PAY_PERIOD
            ) {
                return state.set(fieldNames.PAYDAY, null);
            } else {
                return state;
            }
        case SET_RESTRICTIONS:
            return state.set(privateFieldNames.RESTRICTIONS, action.value);
        case SET_RESTRICTIONS_PENDING:
            return state.set(privateFieldNames.RESTRICTIONS_PENDING, action.value);
        case LOAD_RESTRICTED_FIELDS:
            return state.set(fieldNames.CONTRACT_TYPE, action.value.contractType)
                .set(fieldNames.SALARY_TYPE, action.value.salaryType)
                .set(fieldNames.PAY_PERIOD_LENGTH, action.value.payPeriodLength)
                .set(fieldNames.PAYDAY_MODE, action.value.paydayMode)
                .set(fieldNames.PAYDAY, action.value.payday)
                .set(fieldNames.HOLIDAY_PAY_METHOD, action.value.holidayPayMethod);
        case SET_DEFAULT_RESTRICTIONS:
            let defaultSalaryType = state.get(fieldNames.SALARY_TYPE);
            let defaultHolidayPayMethod = state.get(fieldNames.HOLIDAY_PAY_METHOD);
            let defaultPayPeriodLength = state.get(fieldNames.PAY_PERIOD_LENGTH);
            let defaultPaydayMode = state.get(fieldNames.PAYDAY_MODE);
            if (salaryRestrictions) {
                _.each(salaryRestrictions, (item) => {
                    if (item.primaryType == state.get(fieldNames.CONTRACT_TYPE)) {
                        if (item.allowedSubtypes.length == 1) {
                            defaultSalaryType = _.head(item.allowedSubtypes);
                        }
                    }
                });
            }
            if (holidayPayMethodRestrictions) {
                _.each(holidayPayMethodRestrictions, (item) => {
                    if (item.primaryType == state.get(fieldNames.CONTRACT_TYPE)) {
                        if (item.allowedSubtypes.length == 1) {
                            defaultHolidayPayMethod = _.head(item.allowedSubtypes);
                        }
                    }
                });
            }
            if (periodLengthRestrictions) {
                _.each(periodLengthRestrictions, (item) => {
                    if (item.primaryType == defaultSalaryType) {
                        if (item.allowedSubtypes.length == 1) {
                            defaultPayPeriodLength = _.head(item.allowedSubtypes);
                        }
                    }
                });
            }
            if (payDayRestrictions) {
                _.each(payDayRestrictions, (item) => {
                    if (item.primaryType == defaultPayPeriodLength) {
                        if (item.allowedSubtypes.length == 1) {
                            defaultPaydayMode = _.head(item.allowedSubtypes);
                        }
                    }
                });
            }
            return state.set(fieldNames.SALARY_TYPE, defaultSalaryType)
                .set(fieldNames.PAY_PERIOD_LENGTH, defaultPayPeriodLength)
                .set(fieldNames.PAYDAY_MODE, defaultPaydayMode)
                .set(fieldNames.HOLIDAY_PAY_METHOD, defaultHolidayPayMethod);
        case RESET:
            return initialState;
        case SET_ERROR:
            return state.setIn([privateFieldNames.ERRORS, action.fieldName], action.fieldError);
        case SET_VALIDITY:
            return state.set(privateFieldNames.IS_VALID, action.value);
        case VALIDATE:
            let isValid = true;
            let newState = state;
            if (state.get(fieldNames.CONTRACT_TYPE) === null) {
                newState = newState.setIn([privateFieldNames.ERRORS, fieldNames.CONTRACT_TYPE], 'contract.tes_fields.validation.selection_required');
                isValid = false;
            } else {
                newState = newState.setIn([privateFieldNames.ERRORS, fieldNames.CONTRACT_TYPE], null);
            }
            if (state.get(fieldNames.SALARY_TYPE) === null) {
                newState = newState.setIn([privateFieldNames.ERRORS, fieldNames.SALARY_TYPE], 'contract.tes_fields.validation.selection_required');
                isValid = false;
            } else {
                newState = newState.setIn([privateFieldNames.ERRORS, fieldNames.SALARY_TYPE], null);
            }
            if (state.get(fieldNames.PAY_PERIOD_LENGTH) === null) {
                newState = newState.setIn([privateFieldNames.ERRORS, fieldNames.PAY_PERIOD_LENGTH], 'contract.tes_fields.validation.selection_required');
                isValid = false;
            } else {
                newState = newState.setIn([privateFieldNames.ERRORS, fieldNames.PAY_PERIOD_LENGTH], null);
            }
            if (state.get(fieldNames.PAYDAY_MODE) === null) {
                newState = newState.setIn([privateFieldNames.ERRORS, fieldNames.PAYDAY_MODE], 'contract.tes_fields.validation.selection_required');
                isValid = false;
            } else {
                newState = newState.setIn([privateFieldNames.ERRORS, fieldNames.PAYDAY_MODE], null);
            }
            if (state.get(fieldNames.HOLIDAY_PAY_METHOD) === null) {
                newState = newState.setIn([privateFieldNames.ERRORS, fieldNames.HOLIDAY_PAY_METHOD], 'contract.tes_fields.validation.selection_required');
                isValid = false;
            } else {
                newState = newState.setIn([privateFieldNames.ERRORS, fieldNames.HOLIDAY_PAY_METHOD], null);
            }
            // jostakin javascriptin mystisyydestä johtuen jos valihtet alasvetovalikosta arvon ja sen jälkeen vaihdat
            // sen takaisin placeholderiksi muuttuu arvo storessa nulliksi mutta state.get antaa ulos NaNin
            if (((state.get(fieldNames.PAYDAY_MODE) == payDayModes.PAYDAY_USER_CHOICE
                || state.get(fieldNames.PAYDAY_MODE) == payDayModes.PAYDAY_IN_PAY_PERIOD
                || state.get(fieldNames.PAYDAY_MODE) == payDayModes.PAYDAY_NTH_WEEK_DAY)
                && (state.get(fieldNames.PAYDAY) === null || state.get(fieldNames.PAYDAY) === undefined || isNaN(state.get(fieldNames.PAYDAY))))) {
                newState = newState.setIn([privateFieldNames.ERRORS, fieldNames.PAYDAY], 'contract.tes_fields.validation.selection_required');
                isValid = false;
            } else {
                newState = newState.setIn([privateFieldNames.ERRORS, fieldNames.PAYDAY], null);
            }
            if (isValid) {
                return newState.set(privateFieldNames.IS_VALID, isValid)
                    .set(privateFieldNames.ERRORS, initialState.get(privateFieldNames.ERRORS));
            }
            return newState.set(privateFieldNames.IS_VALID, isValid);
        default:
            return state;
    }
}
/* eslint-enable no-case-declarations */

/**
 * Actions
 */

const changeContractType = (value) => ({
    type: CHANGE_CONTRACT_TYPE,
    value,
});

const changeSalaryType = (value) => ({
    type: CHANGE_SALARY_TYPE,
    value,
});

const changePayPeriodLength = (value) => ({
    type: CHANGE_PAY_PERIOD_LENGTH,
    value,
});

const changePaydayMode = (value) => ({
    type: CHANGE_PAYDAY_MODE,
    value,
});

const changeHolidayPayMethod = (value) => ({
    type: CHANGE_HOLIDAY_PAY_METHOD,
    value,
});

const changePayday = (value) => ({
    type: CHANGE_PAYDAY,
    value,
});

const resetPayday = (value) => ({
    type: RESET_PAYDAY,
    value,
});

const setRestrictions = (value) => ({
    type: SET_RESTRICTIONS,
    value,
});

const setRestrictionsPending = (value) => ({
    type: SET_RESTRICTIONS_PENDING,
    value,
});

const loadRestrictedFields = (value) => ({
    type: LOAD_RESTRICTED_FIELDS,
    value,
});

const setDefaultRestrictions = () => ({
    type: SET_DEFAULT_RESTRICTIONS,
});

const reset = () => ({
    type: RESET,
});

const validate = () => ({
    type: VALIDATE,
});

const setFieldError = (fieldName, fieldError) => ({
    type: SET_ERROR,
    fieldName,
    fieldError,
});

const newValidate = () => (dispatch, getState) => {
    let isValid = true;
    const state = getState().TESRestrictedField;
    let errors = {};
    if (state.get(fieldNames.CONTRACT_TYPE) === null) {
        dispatch(setFieldError(fieldNames.CONTRACT_TYPE, 'contract.tes_fields.validation.selection_required'));
        errors = { ...errors, [fieldNames.CONTRACT_TYPE]: true };
        isValid = false;
    } else {
        dispatch(setFieldError(fieldNames.CONTRACT_TYPE, null));
    }
    if (state.get(fieldNames.SALARY_TYPE) === null) {
        errors = { ...errors, [fieldNames.SALARY_TYPE]: true };
        dispatch(setFieldError(fieldNames.SALARY_TYPE, 'contract.tes_fields.validation.selection_required'));
        isValid = false;
    } else {
        dispatch(setFieldError(fieldNames.SALARY_TYPE, null));
    }
    if (state.get(fieldNames.PAY_PERIOD_LENGTH) === null) {
        errors = { ...errors, [fieldNames.PAY_PERIOD_LENGTH]: true };
        dispatch(setFieldError(fieldNames.PAY_PERIOD_LENGTH, 'contract.tes_fields.validation.selection_required'));
        isValid = false;
    } else {
        dispatch(setFieldError(fieldNames.PAY_PERIOD_LENGTH, null));
    }
    if (state.get(fieldNames.PAYDAY_MODE) === null) {
        errors = { ...errors, [fieldNames.PAYDAY_MODE]: true };
        dispatch(setFieldError(fieldNames.PAYDAY_MODE, 'contract.tes_fields.validation.selection_required'));
        isValid = false;
    } else {
        dispatch(setFieldError(fieldNames.PAYDAY_MODE, null));
    }
    if (state.get(fieldNames.HOLIDAY_PAY_METHOD) === null) {
        errors = { ...errors, [fieldNames.HOLIDAY_PAY_METHOD]: true };
        dispatch(setFieldError(fieldNames.HOLIDAY_PAY_METHOD, 'contract.tes_fields.validation.selection_required'));
        isValid = false;
    } else {
        dispatch(setFieldError(fieldNames.HOLIDAY_PAY_METHOD, null));
    }
    // jostakin javascriptin mystisyydestä johtuen jos valihtet alasvetovalikosta arvon ja sen jälkeen vaihdat
    // sen takaisin placeholderiksi muuttuu arvo storessa nulliksi mutta state.get antaa ulos NaNin
    const payDayRequiredPayDayModes = [
        payDayModes.PAYDAY_USER_CHOICE,
        payDayModes.PAYDAY_IN_PAY_PERIOD,
        payDayModes.PAYDAY_NTH_WEEK_DAY,
    ];
    if (
        _.includes(payDayRequiredPayDayModes, parseInt(state.get(fieldNames.PAYDAY_MODE))) &&
        (state.get(fieldNames.PAYDAY) === null || state.get(fieldNames.PAYDAY) === undefined || isNaN(state.get(fieldNames.PAYDAY)))
    )
    {
        errors = { ...errors, [fieldNames.PAYDAY]: true };
        dispatch(setFieldError(fieldNames.PAYDAY, 'contract.tes_fields.validation.selection_required'));
        isValid = false;
    } else {
        dispatch(setFieldError(fieldNames.PAYDAY, null));
    }

    dispatch({
        type: SET_VALIDITY,
        value: isValid,
    });

    return { isValid, errors };
};

export const actions = {
    changeContractType,
    changeSalaryType,
    changePayPeriodLength,
    changePaydayMode,
    changeHolidayPayMethod,
    changePayday,
    resetPayday,
    setRestrictions,
    setRestrictionsPending,
    loadRestrictedFields,
    setDefaultRestrictions,
    reset,
    validate,
    newValidate,
};

/**
 * Selectors
 */
const getRoot = (state) => state.TESRestrictedField;
const getContractType = (state) => getRoot(state).get(fieldNames.CONTRACT_TYPE);
const getSalaryType = (state) => getRoot(state).get(fieldNames.SALARY_TYPE);
const getPayPeriodLength = (state) => getRoot(state).get(fieldNames.PAY_PERIOD_LENGTH);
const getPaydayMode = (state) => getRoot(state).get(fieldNames.PAYDAY_MODE);
const getHolidayPayMethod = (state) => getRoot(state).get(fieldNames.HOLIDAY_PAY_METHOD);
const getPayday = (state) => getRoot(state).get(fieldNames.PAYDAY);
const getRestrictions = (state) => getRoot(state).get(privateFieldNames.RESTRICTIONS);
const isRestrictionsPending = (state) => getRoot(state).get(privateFieldNames.RESTRICTIONS_PENDING);
const isValid = (state) => getRoot(state).get(privateFieldNames.IS_VALID);
const getErrors = (state) => getRoot(state).get(privateFieldNames.ERRORS);
const getMaxTrialPeriod = (state) => getRestrictions(state).get(fieldNames.MAX_TRIAL_PERIOD_LENGTH);

export const selectors = {
    getContractType,
    getSalaryType,
    getPayPeriodLength,
    getPaydayMode,
    getHolidayPayMethod,
    getPayday,
    getRestrictions,
    isRestrictionsPending,
    isValid,
    getErrors,
    getMaxTrialPeriod,
};

export default TESRestrictedFieldReducer;
