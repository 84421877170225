/**
 * Aakkosjärjestykseen. Jos yksiulotteinen lista voi käyttää natiivia .sort.
 * @param a
 * @param b
 * @param key
 * @returns {number}
 */
import { get } from 'shared/utils/get';

export const sortAlphabeticallyByKey = (a, b, key) => {
    const firstValue = get(a, key);
    const secondValue = get(b, key);
    if (firstValue < secondValue) {
        return -1;
    } else if (firstValue > secondValue) {
        return 1;
    }
    return 0;
};

/**
 * Järjestää listan avaimen mukaan.
 * @param items
 * @param key
 * @param order(asc|desc)
 */
export const sortBy = (items, key, order = 'desc') => {
    const sortedItems = items.sort((a, b) => sortAlphabeticallyByKey(a, b, key));

    // Kummin päin lista palautetaan
    return order === 'desc' ? sortedItems : sortedItems.slice().reverse();
};
