import produce from 'immer';
import api from 'api';

/**
 * Liitteet.
 */
export const attachments = {
    state: {
        publicAttachments: [],
        personalAttachments: [],
    },

    reducers: {
        setPublicAttachments: (state, publicAttachments) => ({ ...state, publicAttachments }),
        addAttachment: (state, attachment) => produce(state, (draftState) => {
            if (attachment.isPublic ?? true) {
                draftState.publicAttachments.unshift(attachment);
            } else {
                draftState.personalAttachments.unshift(attachment);
            }
        }),
    },

    selectors: {
        getPersonalAttachments: (state) => state,
        /**
         * Liitteet. Hakee vain julkiset. Muutetaan kun attachmentsV2 tulilla.
         */
        getPublicAttachments: (state) => state.publicAttachments ?? [],
    },

    effects: () => ({
        /**
         * Sopimuksen liitteet.
         * @returns {Promise<void>}
         */
        async fetchPublicAttachments() {
            try {
                const response = await api.get(`/api/v2/attachments`);
                this.setPublicAttachments(
                    (response.data ?? []).filter((attachment) => attachment.isPublic)
                );
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * Päivittää liitteen tiedot sen jälkeen kun tiedosto
         * on saatu lähetettyä.
         * Tämän saa heittää roskiin kun FineUploader on korvattu jollain paremmalla.
         * @param attachment
         * @returns {Promise<void>}
         */
        async updateAttachmentDetails(attachment) {
            try {
                const { id, description, isPublic } = attachment;
                const response = await api.put(`/api/v2/attachments/${id}`, { description, isPublic });
                if (response.status === 'ok') {
                    this.addAttachment(attachment);
                    return attachment;
                }
                return response;
            } catch (e) {
                console.error(e);
            }
        },
    })
};
