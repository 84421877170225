import React from 'react';
import { select } from '@rematch/select';
import { useSelector } from 'react-redux';
import Feedback from 'shared/components/Feedback';
import { Placeholder } from 'shared/components';

/**
 * Kertoo jos lisämaksuissa on puutteita ja ohjaa korjaamaan ne.
 * @returns {JSX.Element}
 * @constructor
 */
export const SurchargeConflicts = () => {
    const surchargeProducts = useSelector(select.surcharges.getSurchargePricingList);
    const missingPrices = surchargeProducts.filter((product) => !product.validPricingItem);
    const missingProductCodes = surchargeProducts.filter((product) => !product.productCode);
    const conflictingProducts = surchargeProducts.filter((product) => !product.validPricingItem || !product.productCode);
    const isLoading = useSelector((state) => state.loading.effects.surcharges.fetchSurcharges);

    const generalMessage = _transMd('Käy korjaamassa lisämaksujen tiedot [admin-hinnoittelunäkymässä](/service/pricing#tab-surcharges)', {}, { targetBlank: true });

    if (conflictingProducts.length >= surchargeProducts.length) {
        return (
            <Placeholder isPending={isLoading}>
                <Feedback type="error" title="Yhtään lisämaksua ei voida lisätä" modifierClass="u-margin-bottom">
                    {generalMessage}
                </Feedback>
            </Placeholder>

        );
    }

    // Kaikki kunnossa ::jankonbetoni
    if (! missingPrices.length && ! missingProductCodes.length) return null;

    return (
        <Placeholder isPending={isLoading}>
            <Feedback
                type="warning"
                title="Kaikkia lisämaksuja ei voi lisätä"
                modifierClass="u-margin-bottom"
            >
                <ul>
                    {missingPrices.length > 0 && (
                        <li>
                            {`${missingPrices.length} lisämaksulta puuttuu hintatieto.`}
                        </li>
                    )}
                    {missingProductCodes.length > 0 && (
                        <li>
                            {`${missingProductCodes.length} lisämaksulta puuttuu tuotekoodi.`}
                        </li>
                    )}
                </ul>
                {generalMessage}
            </Feedback>
        </Placeholder>

    );
};
