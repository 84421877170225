import api from 'api';

/**
 * Sopimuksen muokkaaja tiedot.
 */
export const contractAuthorDetails = {
    state: {
        authorDetails: {},
    },

    reducers: {
        setAuthorDetails: (state, authorDetails) => ({ ...state, authorDetails }),
    },

    selectors: {
        getAuthorDetails: (state) => state.authorDetails,
    },

    effects: () => ({
        /**
         * Hakee sopimuksen muokkaaja tiedot
         * @returns {Promise<*>}
         */
        async fetchAuthorDetails(jobContract) {
            const json = await api
                .get(`/api/v3/contracts/${jobContract}/getAuthorData`);

            this.setAuthorDetails(json.data ?? []);
            return json;
        },
    }),
};
