import { produce } from 'immer';
import _ from 'lodash';
import api from 'api';
import { actions as notificationActions } from 'shared/stores/notifications';

export default {
    state: {
        employeeWorkExperience: [],
        selectableCollectiveAgreements: [],
        isEditForm: false,
        isAddForm: false,
        editType: null,
        selectedJobTitle: null,
        changedContracts: [],
    },
    reducers: {
        setWorkExperience: (state, payload) => produce(state, (draftState) => {
            draftState.employeeWorkExperience = payload;
        }),
        setSelectableCollectiveAgreements: (state, payload) => produce(state, (draftState) => {
            draftState.selectableCollectiveAgreements = payload;
        }),
        setIsEditForm: (state, payload) => produce(state, (draftState) => {
            draftState.isEditForm = payload;
        }),
        setEditType: (state, payload) => produce(state, (draftState) => {
            draftState.editType = payload;
        }),
        setSelectedJobTitle: (state, payload) => produce(state, (draftState) => {
            draftState.selectedJobTitle = payload;
        }),
        setIsAddForm: (state, payload) => produce(state, (draftState) => {
            draftState.isAddForm = payload;
        }),
        setChangedContracts: (state, payload) => produce(state, (draftState) => {
            draftState.changedContracts = payload;
        }),
    },
    selectors: {
        getEmployeeWorkExperience: (state) => _.get(state, 'employeeWorkExperience', {}),
        getSelectableCollectiveAgreements: (state) => state.selectableCollectiveAgreements,
        isEditForm: (state) => _.get(state, 'isEditForm', false),
        getEditType: (state) => _.get(state, 'editType', null),
        getSelectedJobTitle: (state) => _.get(state, 'selectedJobTitle', null),
        isAddForm: (state) => _.get(state, 'isAddForm', false),
        getChangedContracts: (state) => _.get(state, 'changedContracts', false),
        // Huom saattaa palauttaa undefined, jossei ole mitään kokemusta
        // Concat + pop => ottaa viimesimmän lapsen array:sta
        getEmployeeWorkExperienceWithJobTitle: (state, jobTitle, selectedCollectiveAgreement = null) => {
            const { workExpMasterTesId } = selectedCollectiveAgreement
                ? selectedCollectiveAgreement
                : { workExpMasterTesId: null };

            /**
             * Jos workExpMasterTesId serialisoidaan tesseille niin tiedetään, että ha:lla monitasokokemukset
             * päällä eli kokemuskuukaudet pitää kohdistaa vielä tätä kautta, jotta saadaan oikea saldo ulos.
             */
            const groupMatcher = workExpMasterTesId
                ? (group) => group.targetCollectiveAgreementId === workExpMasterTesId
                : () => true;

            const targetGroup =
                state.employeeWorkExperience.find((group) => group.id === parseInt(jobTitle, 10) && groupMatcher(group)) ??
                /**
                 * Tässä joudutaan tekemään vielä fallback jos monikokemustasot käytössä ja kaikki rivit
                 * kohdistamattomia eli sopivat kaikkiin tesseihin.
                 */
                state.employeeWorkExperience.find((group) => group.id === parseInt(jobTitle, 10) && (
                    (group.targetCollectiveAgreementId ?? null) === null
                )) ??
                // Tässä säilytetty bc, palauttanut undefinedin jos ei kokemusta
                { history: [undefined] };

            return targetGroup.history[0];
        },
    },
    effects: (dispatch) => ({

        /**
         * Hakee työntekijän kokemuskuukaudet
         *
         * @return {Promise<void>}
         * @param user
         */
        async fetchEmployeeWorkExperience(user) {
            try {
                const json = await api.get(Routing.generate('api_1_get_user_workexperience', { user }));
                const data = json.data ?? [];
                const metadata = json.meta ?? {};

                this.setWorkExperience(data);
                this.setSelectableCollectiveAgreements(metadata.selectableCollectiveAgreements ?? []);
            } catch (e) {
                console.error(e);
                if (dispatch.notifications) {
                    dispatch.notifications.addError(_trans('users.error.fetch_work_experience'));
                } else {
                    dispatch(notificationActions.addNotification({
                        type: 'error',
                        message: _trans('users.error.fetch_work_experience'),
                    }));
                }
            }
        },

        /**
         * Hakee työntekijän kokemuskuukaudet työnantajalle
         *
         * @return {Promise<void>}
         * @param user
         * @param rootState
         * @param benefitDecision
         */
        async fetchEmployeeWorkExperienceByBenefitDecision(user, rootState, benefitDecision) {
            try {
                const json = await api.get(Routing.generate('api_1_get_user_work_experience_by_benefitDecision', { user, benefitDecision }));
                const data = json.data ?? [];
                const metadata = json.meta ?? {};

                this.setWorkExperience(data);
                this.setSelectableCollectiveAgreements(metadata.selectableCollectiveAgreements ?? []);
            } catch (e) {
                console.error(e);
                if (dispatch.notifications) {
                    dispatch.notifications.addError(_trans('users.error.fetch_work_experience'));
                } else {
                    dispatch(notificationActions.addNotification({
                        type: 'error',
                        message: _trans('users.error.fetch_work_experience'),
                    }));
                }
            }
        },

        /**
         * Palauttaa työntekijän työkokemusrivit, jotka liittyvät tietyn työsopimuksen jobTitleen
         *
         * @param user
         * @param rootState
         * @param jobContract
         * @returns {Promise<void>}
         */
        async fetchEmployeeWorkExperienceByContract(user, rootState, jobContract) {
            try {
                const json = await api.get(Routing.generate('api_1_get_user_workexperience_by_contract', { user, jobContract }));
                const data = json.data ?? [];
                const metadata = json.meta ?? {};

                this.setWorkExperience(data);
                this.setSelectableCollectiveAgreements(metadata.selectableCollectiveAgreements ?? []);
            } catch (e) {
                console.error(e);
                if (dispatch.notifications) {
                    dispatch.notifications.addError(_trans('users.error.fetch_work_experience'));
                } else {
                    dispatch(notificationActions.addNotification({
                        type: 'error',
                        message: _trans('users.error.fetch_work_experience'),
                    }));
                }
            }
        },

        /**
         * Luo uuden kokemusrivin
         *
         * @param model
         * @param rootState
         * @param userId
         * @return {Promise<void>}
         */
        async postEmployeeWorkExperience(model, rootState, userId) {
            try {
                const json = await api.post(Routing.generate('api_1_post_user_workexperience', { user: userId }), model);
                this.setChangedContracts(_.get(json, 'changedContracts', false));
                this.fetchEmployeeWorkExperience(userId);
                this.resetForm();
                if (dispatch.notifications) {
                    dispatch.notifications.addSuccess(_trans('users.note.saved'));
                } else {
                    dispatch(notificationActions.addNotification({
                        type: 'success',
                        message: _trans('users.note.saved')
                    }));
                }
            } catch (e) {
                console.error(e);
                if (dispatch.notifications) {
                    dispatch.notifications.addError(_trans('users.error.post_work_experience'));
                } else {
                    dispatch(notificationActions.addNotification({
                        type: 'error',
                        message: _trans('users.error.post_work_experience'),
                    }));
                }
            }
        },

        resetForm() {
            dispatch.employeeWorkExperience.setIsEditForm(false);
            dispatch.employeeWorkExperience.setEditType(null);
            dispatch.employeeWorkExperience.setIsAddForm(false);
        }
    }),
};

export const loadingSelectors = {
    isLoading: (state) => _.get(state, 'loading.effects.employeeWorkExperience.fetchEmployeeWorkExperienceByContract', false)
        || _.get(state, 'loading.effects.employeeWorkExperience.fetchEmployeeWorkExperience', false),
    isPosting: (state) => _.get(state, 'loading.effects.employeeWorkExperience.postEmployeeWorkExperience', false),
};
