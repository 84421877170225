import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { Button, Menu, Wrapper } from 'react-aria-menubutton';
import MediaQuery from 'react-responsive';
import { animateScroll as scroll } from 'react-scroll';
import settings from './constants';
import SideBarItem from './SideBarItem';
import SideBarList from './SideBarList';
import {
    GoBackLink,
    MDIcon,
} from 'shared/components';
import { sideBarContentWidths } from 'shared/constants';
import { customEvents } from 'shared/utils/customEvents';
import Badge from 'shared/components/Badge';

const SIDE_BAR_WIDTH = 200;

/**
 * Palauttaa puumaisesta route-rakenteesta flatin.
 * @param routes
 * @returns {*}
 */
function getRoutes(routes) {
    return _.reduce(routes.filter(Boolean), (result, route) => {
        if (_.has(route, settings.GROUP_ROUTES)) {
            return result.concat(getRoutes(route[settings.GROUP_ROUTES]));
        }

        return result.concat(route);
    }, []);
}

export default class SideBar extends Component {
    static defaultProps = {
        children: null,
        initialRouteName: '',
        isInactiveContentUnmounted: true,
        goBackRoute: null,
        goBackText: _trans('link.back'),
        maxContentWidth: 'large',
        sideBarAdditionalDetails: null,
    };

    static propTypes = {
        /**
         * Routes-taulukko.
         */
        routes: PropTypes.array.isRequired,

        /**
         * Oletuksena valittu route. Jos tätä ei ole annettu valitaan automaattiseti ensimmäinen route.
         */
        initialRouteName: PropTypes.string,

        /**
         * Poistetaanko sivunavigaationviereinen sisältö DOMista kun sivunavigaation linkki ei ole aktiivinen.
         * Hyvä käyttää jos on tiedossa että sivulla tulee olemaan paljon raskaita requesteja.
         */
        isInactiveContentUnmounted: PropTypes.bool,

        /**
         * Vaihtoehtoinen sisältö. Jos tätä ei ole annettu, renderöidään routet automaattisesti.
         */
        children: PropTypes.node,

        /**
         * Sisällön maksimileveys. Annetaan CSS-luokkana.
         */
        maxContentWidth: PropTypes.oneOf(Object.keys(sideBarContentWidths)),

        /**
         * Mahdollinen back linkki. Linkki sijaitsee contentin päällä ActionBar:ssa.
         */
        goBackRoute: PropTypes.string,

        /**
         * Takaisinpaluulinkin teksti. Oletuksena "Takaisin".
         */
        goBackText: PropTypes.string,

        /**
         * Jos halutaan näyttää sidebarissa "takaisin" ja "valintojen" välisssä jotain tietoja.
         */
        sideBarAdditionalDetails: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const currentRouteName = this.getCurrentLocation();
        window.addEventListener('hashchange', this.onHashChange, false);

        const routes = getRoutes(props.routes);

        // Validoidaan routet
        routes.forEach((route, idx) => {
            // Löytyykö route-objektista vaaditut avaimet
            if (! _.every(settings.obligatoryKeys, _.partial(_.has, route))) {
                console.error(`Route item #${idx + 1} is missing following keys: `, _.filter(settings.obligatoryKeys, (key) => !_.has(route, key)).join(', '));
            }
        });

        const firstRoute = _.head(routes);
        let initialRouteName = props.initialRouteName || currentRouteName;

        // Jos alustavaa routea ei ole annettu käytä ensimmäistä routea listalla.
        if (initialRouteName === '') {
            initialRouteName = _.get(firstRoute, 'routeName', null);
        }
        let currentRoute = this.findRouteByName(routes, initialRouteName);
        if (typeof currentRoute === 'undefined') {
            currentRoute = firstRoute;
            // Asetetaan hash myöskin, koska jos luodaan routeja dynaamisesti routea ei voi accessoida
            location.hash = firstRoute[settings.ROUTE_NAME];
        }

        this.state = {
            currentRoute,
            routes,
            dynamicBadge: {},
        };
    }

    componentDidMount() {
        document.addEventListener(customEvents.BADGE_UPDATE, this.updateBadge);
    }

    /**
     * Päivitetään badge jos ulkopuolelta osuu custom event tähän.
     * @param detail
     */
    updateBadge = ({ detail }) => {
        // Id oltava routeName:n mukainen joka SideBarille annettu.
        const { id, count } = detail;
        if (! id) return;

        this.setState({
            dynamicBadge: {
                [id]: count,
            }
        });
    }

    /**
     * Kun unmountamaan, niin poistetaan listeneri
     */
    componentWillUnmount() {
        window.removeEventListener('hashchange', this.onHashChange);
    }

    /**
     * Päivitetään routet mikäli propseista tulee päivitystä
     *
     * @param nextProps
     * @param prevState
     * @returns {{routes: *}|null}
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        const newRoutes = getRoutes(nextProps.routes);
        if (! _.isEqual(newRoutes, prevState.routes)) {
            return {
                routes: newRoutes,
            };
        }
        return null;
    }

    /**
     * Etsii routen nimen perusteella.
     * @param routes
     * @param routeName
     * @returns {number | * | T}
     */
    findRouteByName = (routes, routeName) => _.find(routes, (route) => routeName === route.routeName);

    /**
     * Palautetaan hashista nykyinen sijainti.
     * @returns {string | string}
     */
    getCurrentLocation = () => window.location.hash.substring(1) || '';

    /**
     * Haetaan nykyinen route sijainnin perusteella.
     * @returns {number | * | T}
     */
    getCurrentRoute = () => this.findRouteByName(this.state.routes, this.getCurrentLocation());

    /**
     * Kun hash muuttuu scrollataan sivun yläreunaan ja päivitetään nykyinen route vain jos se löytyy routelistalta.
     */
    onHashChange = () => {
        scroll.scrollToTop({
            duration: 75
        });

        const currentRoute = this.getCurrentRoute();

        // Vaihdetaan route vain jos se löytyi
        if (typeof currentRoute !== 'undefined') {
            this.setState({
                isOpen: false,
                currentRoute,
            });
        }
    };

    /**
     * Mikäli paluulinkin route on annettu renderöidään itse linkkikin.
     * @returns {*}
     */
    renderGoBackRoute() {
        const { goBackRoute, goBackText } = this.props;

        if (goBackRoute) {
            return (
                <div className="u-margin-bottom-small">
                    <GoBackLink
                        to={goBackRoute}
                        text={goBackText}
                        modifierClass="c-side-bar__go-back-link u-text-left u-display-block"
                    />
                </div>
            );
        }
    }

    /**
     * Iteroi route-objektin ja renderöi linkit ja mahdolliset ryhmän nimet.
     * @param routes
     * @param groupName
     * @returns {*}
     */
    renderRoutes = (routes, groupName = 'root') => (
        <li role="presentation" key={`${groupName}`}>
            {_.map(routes, (route, idx) => {
                const hasGroupHeader = route[settings.GROUP_HEADER] ?? false;
                // Jos route on navigaatioryhmä käydään se läpi
                if ((route[settings.GROUP_NAME] ?? false) && (route[settings.GROUP_ROUTES] ?? false)
                    || hasGroupHeader) {
                    const groupName = route[settings.GROUP_NAME];
                    const groupIcon = route[settings.GROUP_ICON] ?? false;
                    // Näkyvien routejen määrä
                    const visibleRouteCount = (route[settings.GROUP_ROUTES] ?? []).filter((route) => route[settings.ROUTE_VISIBLE] ?? true).length;

                    // Jos grouppille annettu visible: false, niin eipä näytetä mittää tai jos ei (ryhmän alla) ole routeja.
                    if (! (route[settings.ROUTE_VISIBLE] ?? true) || visibleRouteCount === 0) {
                        return null;
                    }

                    // Joko vapaasisältöinen groupHeader tai groupIcon + groupName combo.
                    const groupHeader = hasGroupHeader
                        ? route[settings.GROUP_HEADER]
                        : (
                            <h2 className="c-side-bar__title o-stack">
                                {groupIcon &&
                                <MDIcon icon={groupIcon} modifierClass="u-margin-right-tiny"/>}
                                {groupName}
                            </h2>
                        );

                    return (
                        <div key={`${groupName}${idx}`}>
                            {groupHeader}
                            <SideBarList>
                                {this.renderRoutes(route[settings.GROUP_ROUTES], groupName)}
                            </SideBarList>
                        </div>
                    );
                }

                const routeName = route[settings.ROUTE_NAME];
                const DynamicBadge = this.state.dynamicBadge[routeName] > 0
                    ? <Badge value={this.state.dynamicBadge[routeName]} />
                    : null;

                const RouteBadge = route[settings.ROUTE_BADGE];
                const currentRouteName = _.get(this.state, 'currentRoute.routeName', null);
                const routeTitle = route[settings.ROUTE_TITLE];
                // Ulkoinen linkki, johon navigoidaan.
                const isOutLink = !! route[settings.ROUTE_LINK];
                if (_.get(route, settings.ROUTE_VISIBLE, true)) {
                    return (
                        <SideBarItem
                            target={isOutLink ? '_blank' : ''}
                            href={isOutLink ? route.href : `#${routeName}`}
                            key={`${groupName}${routeName}${idx}`}
                            isSelected={routeName === currentRouteName}
                            isAdmin={!! route[settings.ROUTE_IS_ADMIN]}
                        >
                            <div className="o-stack o-stack--justify">
                                {routeTitle}
                                {RouteBadge ? RouteBadge : DynamicBadge}
                            </div>
                        </SideBarItem>
                    );
                }
            })}
        </li>
    );

    /**
     * Renderöidään vain mobiilikoossa näkyvä alasvetovalikon avaava nappi.
     * @returns {*}
     */
    renderTrigger() {
        return (
            <div className="c-side-bar-trigger-container">
                <Wrapper
                    className={classNames('u-1/1', {
                        'is-open': this.state.isOpen,
                    })}
                    onSelection={this.onChange}
                    onMenuToggle={({ isOpen }) => {
                        this.setState({ isOpen });
                    }}
                    id="sidebar"
                >
                    <Button
                        className="c-button o-stack o-stack--justify u-text-no-wrap u-text-left u-1/1"
                    >
                        {_.get(this.state, 'currentRoute.title')}
                        <i className="c-dropdown__arrow material-icons">keyboard_arrow_down</i>
                    </Button>
                    <Menu>
                        <SideBarList>
                            {this.props.sideBarAdditionalDetails}
                            {this.renderRoutes(this.state.routes)}
                        </SideBarList>
                    </Menu>
                </Wrapper>
            </div>
        );
    }

    /**
     * Itse sisältö valitun valikkoitemin mukaan.
     * @returns {*}
     */
    renderContent() {
        const {
            isInactiveContentUnmounted,
        } = this.props;

        const currentRouteName = _.get(this.state, 'currentRoute.routeName');

        return _.map(this.state.routes, (route) => {
            const key = `route_${route[settings.ROUTE_NAME]}`;
            const routeFound = route[settings.ROUTE_NAME] === currentRouteName;

            //Jos löytyy visible-propertie, joka on false, niin ei näytetä mitään.
            if (! _.get(route, settings.ROUTE_VISIBLE, true)) {
                return null;
            }

            // Jos ei haluta sisältöä liitettävän DOMiin jos sitä ei ole erikseen valittu ei renderöidä sitä.
            // Muutoin vain piilotetaan sisältö CSS-luokalla.
            if (isInactiveContentUnmounted && routeFound) {
                return (
                    <div key={key}>
                        { route[settings.ROUTE_CONTENT] }
                    </div>
                );
            } else if (routeFound) {
                return (
                    <div
                        key={key}
                        className={routeFound ? '' : 'u-hide'}
                    >
                        { route[settings.ROUTE_CONTENT] }
                    </div>
                );
            }
        });
    }

    /**
     * Renderöidään sivupalkki (mobiilissa / pienissä selainkoi'issa alasvetovalikko)
     * @returns {*}
     */
    render() {
        const {
            goBackRoute,
            maxContentWidth,
        } = this.props;

        const sideBarContainerWidth = SIDE_BAR_WIDTH + sideBarContentWidths['medium'] + 48;

        return (
            <div className={classNames('c-side-bar-container', {
                [`c-side-bar-container--${maxContentWidth}`]: _.has(sideBarContentWidths, maxContentWidth),
            })}>
                <MediaQuery maxWidth={sideBarContainerWidth - 1}>
                    <ul className="o-layout hidden--print">
                        { goBackRoute && (
                            <li className="o-layout__item u-1/2">
                                {this.renderGoBackRoute()}
                            </li>
                        )}
                        <li className="o-layout__item u-1/2">
                            {this.renderTrigger()}
                        </li>
                    </ul>
                </MediaQuery>
                <MediaQuery minWidth={sideBarContainerWidth}>
                    <div className="c-side-bar-tack">
                        <div className="c-side-bar">
                            <div className="c-side-bar-scroll o-pin o-pin--corners">
                                <SideBarList>
                                    <li role="presentation">
                                        {this.renderGoBackRoute()}
                                    </li>
                                    <li role="presentation">
                                        {this.props.sideBarAdditionalDetails}
                                    </li>
                                    {this.renderRoutes(this.props.routes)}
                                </SideBarList>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <div className={classNames('c-side-bar-content')}>
                    {this.renderContent()}
                </div>
            </div>
        );
    }
}
