import React from 'react';
import PropTypes from 'prop-types';

export const NameAndDescription = ({ original }) => (
    <div className="u-text-truncate">
        {original.name}<br/>
        <span className="u-muted">
            {original.description}
        </span>
    </div>
);

NameAndDescription.propTypes = {
    original: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
};

