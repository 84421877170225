import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import SignaturesEmployer from '../Employer';
import { signatureTypes } from 'shared/constants/signatureTypes';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { Value } from 'shared/components/Value';

const SignaturesCommuneOperator = ({ attributes, value }) => {
    const hasCommuneSigner = useSelector(select.contract.hasCommuneSigner);
    const isCommune = useSelector(select.userMetadata.isCommune);

    if (! hasCommuneSigner && isCommune) {
        return (
            <SummaryListItem term={_trans('Hyvinvointialueen edustaja', {}, 'jobContract')}>
                <Value>
                    {_transMd('Hyvinvointialueen edustajaa ei vielä valittu.\nMuokkaa sopimusta ja lisää allekirjoittaja.', {}, 'jobContract')}
                </Value>
            </SummaryListItem>
        );
    }

    return (
        <SignaturesEmployer
            attributes={attributes}
            value={value}
            employerSignatureType={signatureTypes.TYPE_COMMUNE_USER_SPECIFIC}
        />
    );
};

SignaturesCommuneOperator.propTypes = {
    attributes: PropTypes.object.isRequired,
    value: PropTypes.number.isRequired,
};

export default SignaturesCommuneOperator;
