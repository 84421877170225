
export const TYPE_SALARY = 1;

export const TYPE_COST_REIMBURSEMENT = 2;

export const TYPE_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE = 3;

const translationMap = {
    [TYPE_SALARY]: 'Normaali palkkio',
    [TYPE_COST_REIMBURSEMENT]: 'Kustannustenkorvaus: Peruskulukorvaus',
    [TYPE_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE]: 'Kustannustenkorvaus: erityslisä',
};

/**
 * Virhe tilanteissa, joissa löytyy vanhastaan annetuilla arvoilla taulukoista, mutta annetulla päivällä ei.
 * Useiminten tilanne, että indeksikorotukset ei ole vielä tehty
 */
export const ERROR_NOT_FOUND_FOR_DATE = 'not_found_for_date';

export const tableSalaryKeys = {
    /**
     * Metadatan avain sarakkeiden nimille
     */
    COLUMN_NAMES: 'names',

    /**
     * Metadatan avain sarakedatalle
     */
    COLUMN_DATA_KEYS: 'column_data_keys',

    /**
     * Alue
     *
     * Tällä hetkellä yleensä PK-seutu tai Muu Suomi
     */
    AREA: 'table_salary_area',

    /**
     * Vaativuustaso
     */
    LEVEL: 'table_salary_level',

    /**
     * Palkkaryhmä
     */
    SALARY_GROUP: 'table_salary_salary_group',

    /**
     * Työaikatyyppi
     *
     * Käytössä ainakin Golf TESsillä
     *
     * vikkotyön pituus ja tunti-/kuukausipalkka vaikuttaa palkan määrään
     */
    WORK_TIME_TYPE: 'table_salary_work_time_type',

    /**
     * G-luokka
     *
     * käytössä JHL:n sosiaalipalvelualan TESsillä
     */
    G_CLASS: 'table_salary_g_class',

    /**
     * Työkokemus
     */
    WORK_EXPERIENCE: 'table_salary_work_experience',

    /**
     * Työnimike
     *
     * MaRaVa-TESsissä erilliset taulut eri työnimikkeille
     */
    JOB_TITLE: 'table_salary_job_title',
};

export const translateTableSalaryType = (type) => type in translationMap ? translationMap[type] : 'Tuntematon';
