import api from 'api';

/**
 * Materiaalianalytiikka (esim. Varman työkykysivu ja oppimisalusta.
 */
export const materialAnalytics = {
    state: {
        analytics: []
    },

    reducers: {
        setAnalytics: (state, analytics) => ({ ...state, analytics }),
    },

    selectors: {
        getAnalytics: (state) => state.analytics,
        getAnalyticsByMaterialId: (state, id) => {
            const materialId = id?.toString();
            return state.analytics.filter((analytics) => analytics.materialId === materialId);
        },
    },

    effects: (dispatch) => ({
        async fetchMaterialAnalytics(analyticsCategory) {
            try {
                const response = await api.get(`/api/v1/material-analytics?analyticsCategory=${analyticsCategory}`);
                this.setAnalytics(response.data ?? []);
            } catch (e) {
                console.error(e);
            }
        },

        async postAction(analytics) {
            try {
                const response = await api.post('/api/v1/material-analytics', analytics);
                if (response.status !== 'ok') {
                    dispatch.notifications.addError('Toimintoa ei voitu tallentaa.', {}, 'workAbility');
                }
            } catch (e) {
                dispatch.notifications.addError('Toimintoa ei voitu tallentaa.', {}, 'workAbility');
                console.error(e);
            }
        },

        async putAction(analytics) {
            try {
                const response = await api.put(`/api/v1/material-analytics/${analytics.id}`, analytics);
                if (response.status !== 'ok') {
                    dispatch.notifications.addError('Toimintoa ei voitu tallentaa.', {}, 'workAbility');
                }
            } catch (e) {
                dispatch.notifications.addError('Toimintoa ei voitu tallentaa.', {}, 'workAbility');
                console.error(e);
            }
        }
    })
};
