import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import { ChoiceField } from 'shared/components/Formik/ChoiceField';
import { FormField } from 'shared/components/Formik';
import { Value } from 'shared/components/Value';
import { fieldNames } from 'ContractV3/constants/fieldNames';

/**
 * Dimensiopalikka jolla dimensiovalitsimen voi tunkea minne kohtaa sopparipohjaa haluaa.
 * Voi myös antaa "readonlynä" jolloin arvo on esivalittu eikä sitä voi vaihtaa.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const Dimension = (props) => {
    const { name, index, defaultValue, isValueFixed } = props;
    // dimensions.id212 => ["dimensions", "id212"]
    const namePieces = name.split('.');
    // id212 => 212
    const dimensionTypeId = namePieces[1]?.substring(2);
    const dimensionType = useSelector((state) => select.dimensions.getDimensionsByDimensionTypeId(state, parseInt(dimensionTypeId), 10));
    const [dimensionField] = useField(name);
    // Hyväksytyltä sopparilta haetaan dimensiot suoraan. Näitä ei voi enää muuttaa.
    const isAcceptedContract = useSelector(select.contract.isAcceptedContract);
    const contractDimension = useSelector((state) => select.contract.getDimensionById(state, dimensionField.value));

    const [salariesField,,salariesHelpers] = useField(`${fieldNames.SALARIES}.${index}`);

    useEffect(() => {
        if (dimensionType.isSalaryTypeDimension && dimensionField.value) {
            // Asetetaan ensimmäiselle salaarille automaagisesti valittu salaryTypen arvo dimensiolle.
            // Näin se saadaa filtteröityä pois muiden mahdollisten lisättyjen salaarien dimensiolistalta
            // SalaryDimensionType:ssä.
            const salaryValue = salariesField.value;
            salariesHelpers.setValue({
                ...salaryValue,
                [fieldNames.SALARY_DIMENSION_TYPE]: dimensionField.value,
            });
        }
    }, [dimensionType, dimensionField.value]);

    const dimensions = useMemo(() => (dimensionType.dimensions ?? []).map((dimension) => ({
        value: [dimension.value, dimension.description].filter(Boolean).join(' - '),
        id: dimension.id.toString(),
    })), [dimensionType.dimensions]);

    // Tämä ei saisi koskaan näkyä loppukäyttäjälle...
    // TODO: Visuaaliseen editoriin fix tälle
    if (!dimensions.length) {
        return (
            <div
                style={{
                    backgroundColor: 'rgba(192, 0, 0, .1)',
                    boxShadow: 'inset 0 0 0 6px #ff0000',
                }}
                className="u-padding-small u-margin-bottom-small"
            >
                Dimensiota <b>{dimensionTypeId}</b> ei löydy.
            </div>
        );
    }

    // Otetaan nimi suoraan dimensiolta jos sitä ei ole erikseen pohjalla annettu
    const { label, ...rest } = getFieldProps(props);

    // Jos dimensiolla on esiasetettu arvo tai jos soppari on hyväksytyssä tilassa ei dimensiota voi käyttäjä valita.
    if ((isValueFixed && defaultValue !== '') || (isAcceptedContract && contractDimension)) {
        const dimension = isAcceptedContract
            ? contractDimension
            : dimensions.find((dimension) => dimension.id === defaultValue);

        if (dimension) {
            // Sopparilta dimensio tulee eriteltynä value / description. Muutoin yhdistetty valueksi.
            const value = isAcceptedContract
                ? [dimension.value, dimension.description].filter(Boolean).join(' - ')
                : dimension.value;

            const dimensionLabel = isAcceptedContract
                ? dimension.type
                : dimensionType.name;

            return (
                <FormField label={label ?? dimensionLabel} {...rest} isContentFormField={false}>
                    <Value>{value}</Value>
                </FormField>
            );
        } else {
            return (
                <div>Dimensiota <b>{defaultValue}</b> ei löydy.</div>
            );
        }
    }

    return (
        <FormField
            label={label ?? dimensionType.name}
            {...rest}
            // name={`['${name}']`}
            name={name}
        >
            <ChoiceField
                placeholder={_trans('contract.select')}
                options={dimensions}
                labelKey="value"
                valueKey="id"
                isFullWidth

            />
        </FormField>
    );
};

Dimension.propTypes = {
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    isValueFixed: PropTypes.bool,
};

Dimension.defaultProps = {
    defaultValue: null,
    isValueFixed: false,
};

// Dynaaminen importti vaatii default importin :-#
// https://github.com/reactjs/rfcs/pull/64#issuecomment-431507924
export default Dimension;
