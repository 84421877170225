export const salesInvoiceState = {
    /**
     * Lasku on uusi/avoin eikä sitä ole vielä lähetetty eteenpäin
     */
    OPEN: 1,

    /**
     * Lasku on lähetetty Procountoriin
     */
    SENT: 2,
};
