import { blocks as blockTypes } from 'shared/ReForm/constants/blocks';
import { getBlockProps } from 'shared/ReForm/utils/getBlockProps';

const parseDependencies = (dependencies, arrayName) => Object.entries(dependencies)
    .reduce((acc, [name, dependency]) => {
        // Mikäli key:stä löytyy maagisella $ placeholderilla varustettu merkki korvataan se mahdollisella iteraationimellä
        // (esim. salaries.0.isTableSalaryInUse). Toimii siis vain arrayn sisällä.
        const key = name.replace(/\$/g, arrayName);

        return Object.assign({}, acc, { [key]: dependency });
    }, {});

// Luo flatin listan kaikista palikoista
export const flattenBlocks = (json, arrayName = '') => json.reduce((acc, item, index) => {
    const { blocks } = item;
    const { name, type, validations, validationType, dynamicValidation, dependencies } = getBlockProps(item);

    if (Array.isArray(blocks)) {
        // Jätetään toistaiseksi "array"-tyyppiset blokit validoinnin ulkopuolelle.
        // Nämä kuitenkin voivat validoida itse itsensä.
        if (item.block === blockTypes.ARRAY) {
            return [
                ...acc,
                { [name]: flattenBlocks(blocks, `${name}.${index}`) },
            ];
        }

        return [
            ...acc,
            ...flattenBlocks(blocks),
        ];
    }

    if (! name) {
        return acc;
    }

    return [
        ...acc,
        // Jos löytyy validaatio tai tyyppi tungetaan ne mukaan:
        // firstName: {
        //     type: 'string',
        //     minLength: 10,
        // }
        {
            [name]: {
                ...((validations || validationType) && {
                    ...{ validations },
                    ...{ validationType },
                }),
                ...(dependencies && { dependencies: parseDependencies(dependencies, arrayName) }),
                // Palikan tyyppi matkaan niin voidaan tehdä automaattinen validaatio sen mukaan
                type: type ?? 'custom',
                dynamicValidation: dynamicValidation ?? false,
            }
        }
    ];
}, []);
