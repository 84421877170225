import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import classNames from 'classnames';
import { Button, ActionBar, ButtonGroup, Badge } from 'shared/components';
import { AutoCompleteVirtualized } from 'shared/containers';
import { badgeTypes } from 'shared/components/Badge';
import offlinePaymentStates from 'shared/constants/offlinePaymentStates';
import { salesInvoiceState } from 'shared/constants/salesInvoice';

export const HandledSurchargeActionBar = () => {
    const dispatch = useDispatch();
    const isExternal = useSelector(select.surcharges.isExternalTypeSelected);
    const offlinePayments = useSelector(select.surcharges.getOfflinePayments);
    const salesInvoices = useSelector(select.surcharges.getSalesInvoices);
    const selectedOfflinePayment = useSelector(select.surcharges.getSelectedOfflinePayment);
    const selectedSalesInvoice = useSelector(select.surcharges.getSelectedSalesInvoice);
    const externalTotalCount = useSelector(select.surcharges.getHandledExternalTotalCount);
    const internalTotalCount = useSelector(select.surcharges.getHandledInternalTotalCount);

    const offlinePaymentOptions = offlinePayments.map((offlinePayment) => ({
        ...offlinePayment,
        id: offlinePayment.data.id,
        label: _toLocaleDate(offlinePayment.data.defaultPayday),
    }));

    const salesInvoiceOptions = salesInvoices.map((salesInvoice) => ({
        ...salesInvoice,
        id: salesInvoice.data.id,
        label: `${salesInvoice.data.dimension ?? ''} ${_toLocaleDate(salesInvoice.data.deliveryStartDate)} - ${_toLocaleDate(salesInvoice.data.deliveryEndDate)}`,
    }));

    return (
        <ActionBar>
            <ButtonGroup>
                <Button
                    active={! isExternal}
                    onClick={() => dispatch.surcharges.setIsExternalTypeSelected(false)}
                >
                    Sisäinen laskutus <Badge value={internalTotalCount} type="neutral" isBall={false}/>
                </Button>
                <Button
                    active={isExternal}
                    onClick={() => dispatch.surcharges.setIsExternalTypeSelected(true)}
                >
                    Ulkoinen laskutus <Badge value={externalTotalCount} type="neutral" isBall={false}/>
                </Button>
            </ButtonGroup>
            <div className="u-1/2">
                <label>{isExternal ? 'Laskutusjakso' : 'Maksuerä'}</label>
                <AutoCompleteVirtualized
                    items={isExternal ? salesInvoiceOptions : offlinePaymentOptions }
                    labelKey="label"
                    valueKey="id"
                    onSelect={(value) => {
                        if (isExternal) {
                            dispatch.surcharges.fetchHandledExternalSurcharges(value?.data?.id);
                            dispatch.surcharges.setSelectedSalesInvoice(value);
                        } else {
                            dispatch.surcharges.fetchHandledInternalSurcharges(value?.data?.id);
                            dispatch.surcharges.setSelectedOfflinePayment(value);
                        }

                    }}
                    value={isExternal ? selectedSalesInvoice : selectedOfflinePayment}
                    placeholder={isExternal ? 'Valitse laskutusjakso' : 'Valitse maksuerä'}
                    style={{ minWidth: '25em' }}
                    optionRenderer={({ focusedOption, key, option, selectValue, style }) => {
                        const isClosed = isExternal
                            ? option.data.state === salesInvoiceState.SENT
                            : option.data.state !== offlinePaymentStates.DRAFT;

                        return (
                            <button
                                onClick={() => selectValue(option)}
                                className={classNames('VirtualizedSelectOption', {
                                    'VirtualizedSelectOption-has-focus': focusedOption === option,
                                })}
                                key={key}
                                style={style}
                            >
                                <span className="o-stack o-stack--justify u-1/1">
                                    <span className="u-text-left">
                                        {isExternal
                                            ? `${option.data.dimension ?? ''} ${_toLocaleDate(option.data?.deliveryStartDate)} - ${_toLocaleDate(option.data?.deliveryEndDate)}`
                                            : _toLocaleDate(option.data?.defaultPayday)
                                        }
                                    </span>
                                    <div>
                                        <Badge
                                            value={
                                                <div>
                                                    {isClosed ? 'Suljettu' : 'Avoin' }, <span className="u-margin-left-tiny">{option.count} kpl</span>
                                                </div>
                                            }
                                            type={isClosed ? badgeTypes.NEUTRAL : badgeTypes.POSITIVE}
                                            isBall={false}
                                        />
                                    </div>

                                </span>
                            </button>
                        );
                    }}
                />
            </div>

        </ActionBar>
    );
};
