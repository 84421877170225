import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { promised as request, cache } from 'BubbleWrapAgent';
import classNames from 'classnames';
import ReactAutoComplete from 'react-autocomplete';
import _get from 'lodash/get';

import Spinner from 'shared/components/Spinner';

class AutoComplete extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : '',
            items: [],
            isMenuOpen: false,
            isLoading: false
        };
    }

    UNSAFE_componentWillMount = () => {
        this.idleTimer = null;
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({ value: nextProps.value });
        }
    }

    isAsync = () => (this.props.asyncEndPoint !== '');

    matchStateToTerm = (state, value) => {
        const { itemValue } = this.props;

        return (
            //state[itemKey].toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
            _.get(state, itemValue, '').toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
    };

    asyncRequest(value) {
        const { asyncEndPoint, dataKey, cacheTTL } = this.props;

        clearTimeout(this.idleTimer);
        this.idleTimer = null;

        this.idleTimer = setTimeout(() => {
            this.setState({ isLoading: true });
            request
                .get(asyncEndPoint + value)
                .use(cache(cacheTTL))
                .end((error, response) => {
                    if (error || !response.ok) {
                        this.props.onError(error);
                    } else {
                        this.setState({
                            items: dataKey === '' ? response.body : _get(response, 'body.' + dataKey)
                        });
                        this.props.onLoad(response);
                    }

                    this.setState({ isLoading: false });
                });
        }, 250);
    }

    render() {
        const { ariaLabel, minLength, itemKey, itemValue, hasClearValueOnSelect } = this.props;

        const menuClass = classNames('c-dropdown__menu u-1/1', {
            'is-open': this.state.isMenuOpen
        });

        return (
            <div className="c-autocomplete">
                <ReactAutoComplete
                    inputProps={{
                        type: 'text',
                        name: this.props.name,
                        id: this.props.id,
                        placeholder: this.props.placeholder,
                        disabled: this.props.isDisabled,
                    }}
                    value={this.state.value}
                    items={this.isAsync() ? this.state.items : this.props.items}
                    getItemValue={(item) => item[itemValue]}
                    shouldItemRender={this.isAsync() ? () => true : this.matchStateToTerm}

                    wrapperStyle={{
                        display: 'block',
                        width: '100%'
                    }}

                    wrapperProps={{
                        className: 'c-autocomplete__input'
                    }}

                    onSelect={(value, item) => {
                        if (hasClearValueOnSelect) {
                            this.setState({ value: '' });
                        } else {
                            // Set the menu to only the selected item
                            this.setState({ value, items: [item] });
                        }

                        this.props.onSelect(item);
                        // or you could reset it to a default list again
                        // this.setState({ unitedStates: getStates() })
                    }}

                    onMenuVisibilityChange={(isMenuOpen) => {
                        this.setState({ isMenuOpen });
                    }}

                    onChange={(event, value) => {
                        this.setState({ value });

                        if (value.length < minLength) return false;

                        if (this.isAsync()) {
                            this.asyncRequest(value);
                        }

                        // TODO: Filtering items if not async
                        this.props.onChange(event, value);
                    }}

                    renderMenu={(items) => (
                        <div className={menuClass}>
                            <ul
                                className="o-list-bare"
                                role="menu"
                                aria-label={ariaLabel}
                            >
                                {items}
                            </ul>
                        </div>
                    )}

                    renderItem={(item, isHighlighted) => (
                        <li
                            key={item[itemKey]}
                            id={item[itemKey]}
                            role="presentation"
                            className={isHighlighted ? 'has-focus' : ''}
                        >
                            <span className="c-dropdown__item" role="menuitem">
                                {item[itemValue]}
                            </span>
                        </li>
                    )}
                />
                { this.state.isLoading &&
                <div className="c-autocomplete__spinner">
                    <Spinner size="small" primary />
                </div>
                }
            </div>
        );
    }
}

AutoComplete.defaultProps = {
    ariaLabel: '',

    asyncEndPoint: '',
    asyncOptions: {},

    isDisabled: false,

    minLength: 3,

    items: [],
    itemKey: 'id',
    itemValue: 'name',
    dataKey: '',

    id: '',
    name: '',
    placeholder: '',

    onLoad: () => {
    },
    onError: () => {
    },
    onChange: () => {
    },
    onSelect: () => {
    },

    cacheTTL: 300,

    hasClearValueOnSelect: false,

    value: '',
};

AutoComplete.propTypes = {
    ariaLabel: PropTypes.string,

    asyncEndPoint: PropTypes.string,
    asyncOptions: PropTypes.object,

    // Input attributes
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,

    // Minimum length until async req fired or list filtered
    minLength: PropTypes.number,

    items: PropTypes.array,
    itemKey: PropTypes.string,
    itemValue: PropTypes.string,

    // Key of the response object
    dataKey: PropTypes.string,

    onLoad: PropTypes.func,
    onError: PropTypes.func,
    onSelect: PropTypes.func,
    onChange: PropTypes.func,


    hasClearValueOnSelect: PropTypes.bool,

    cacheTTL: PropTypes.number,

    value: PropTypes.string,
};

export default AutoComplete;
