import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { YearSelector } from './YearSelector';
import { MonthButtons } from './MonthButtons';
import { MONTH_YEAR_FORMAT } from 'shared/constants/formats';

/**
 * Vuosivalitsin ja kuukausinapit.
 * @param value
 * @param disableRanges
 * @param onSelect
 * @param hasSingleInput
 * @param hasMonthNames
 * @param minimumMonth
 * @param maximumMonth
 * @returns {JSX.Element}
 * @constructor
 */
export const MonthSpanPicker = ({ value, disableRanges, onSelect, hasSingleInput, hasMonthNames, minimumMonth, maximumMonth }) => {
    const thisYear = new Date().getFullYear();
    const [start, setStart] = useState(-1);
    const [end, setEnd] = useState(-1);
    const [initialYear, setInitialYear] = useState(thisYear);

    useEffect(() => {
        const start = moment(hasSingleInput ? value : value.start, MONTH_YEAR_FORMAT);
        if (start.isValid()) {
            const startDate = start.toDate();
            setStart(startDate.getTime());
            setInitialYear(startDate.getFullYear());
        }

        if (! hasSingleInput) {
            const end = moment(value.end, MONTH_YEAR_FORMAT);
            if (end.isValid()) {
                const endDate = end.toDate();
                setEnd(endDate.getTime());
            }
        }
    }, [hasSingleInput, value]);

    return (
        <div className="DateRangePicker">
            <YearSelector
                initialYear={initialYear}
                onChange={(value) => setInitialYear(value)}
            />
            <table className="u-margin-none">
                <tbody>
                    <MonthButtons
                        initialYear={initialYear}
                        initialStart={start}
                        initialEnd={end}
                        hasMonthNames={hasMonthNames}
                        disableRanges={disableRanges}
                        onSelect={onSelect}
                        hasSingleInput={hasSingleInput}
                        minimumMonth={minimumMonth}
                        maximumMonth={maximumMonth}
                    />
                </tbody>
            </table>
        </div>
    );
};

MonthSpanPicker.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string,
        }),
        PropTypes.string,
    ]),
    disableRanges: PropTypes.arrayOf(
        PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string,
        })
    ),
    onSelect: PropTypes.func,
    hasSingleInput: PropTypes.bool.isRequired,
    hasMonthNames: PropTypes.bool.isRequired,
    minimumMonth: PropTypes.instanceOf(Date),
    maximumMonth: PropTypes.instanceOf(Date),
};

MonthSpanPicker.defaultProps = {
    value: {
        start: '',
        end: '',
    },
    disableRanges: [],
    onSelect() {},
    minimumMonth: undefined,
    maximumMonth: undefined,
};
