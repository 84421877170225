import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import moment from 'moment';
import { LocalForm, Control } from 'react-redux-form';
import { select } from '@rematch/select';
import DataTable from 'shared/containers/DataTable';
import Button from 'shared/components/Button';
import { StLabel, StField } from 'shared/components/StForm/index';
import DateInput from 'shared/components/DateInput';
import SubmitButton from 'shared/components/SubmitButton';
import Layout from 'shared/components/Layout/index';
import ActionBar from 'shared/components/ActionBar/ActionBar';
import validators from 'shared/validators';
import StErrors from 'shared/components/StForm/StErrors';
import Dialog from 'shared/components/Dialog';
import DialogFooter from 'shared/components/Dialog/DialogFooter';
import { MDSpinner, Page } from 'shared/components';
import TradeUnionReferenceForm from 'shared/containers/TradeUnion/TradeUnionReferenceForm';
import { referenceNumberTypes } from 'shared/constants/tradeUnion';

@connect((state) => ({
    membershipData: select.tradeUnion.getMemberships(state),
    isReferenceNumbersPage: select.tradeUnion.isReferenceNumbersPage(state),
    isEditMode: select.tradeUnion.isMembershipEditMode(state),
    isSaving: _.get(state, 'loading.effects.tradeUnion.postEmployeeTradeUnionMembership')
        || _.get(state, 'loading.effects.tradeUnion.patchEmployeeTradeUnionMembership'),
    isLoading: _.get(state, 'loading.effects.tradeUnion.fetchEmployeeTradeUnionMemberships'),
}))
export default class UserTradeUnionMembership extends Component {

    state = {
        isDialogOpen: false,
        isChangeJoinDateData: false,
    };

    static propTypes = {
        userId: PropTypes.number,
        membershipData: PropTypes.instanceOf(Map),
        options: PropTypes.instanceOf(List),
        dispatch: PropTypes.func,
        isEditMode: PropTypes.bool,
        isSaving: PropTypes.bool,
        isLoading: PropTypes.bool,
        isReferenceNumbersPage: PropTypes.bool,
        isAdminBundle: PropTypes.bool,
        isAdmin: PropTypes.bool,
    };

    static defaultProps = {
        userId: null,
        membershipData: Map(),
        options: List(),
        dispatch() {},
        isEditMode: false,
        isSaving: false,
        isLoading: false,
        isReferenceNumbersPage: false,
        isAdminBundle: false,
        isAdmin: false,
    };

    componentDidMount() {
        const { dispatch, userId } = this.props;
        dispatch.tradeUnion.fetchEmployeeTradeUnionMemberships(userId);
    }

    onSubmit = (model) => {
        const { dispatch, userId } = this.props;
        dispatch.tradeUnion.postEmployeeTradeUnionMembership(model, userId);
    };

    openEndMembershipDialog = (membership) => {
        this.setState({ isDialogOpen: true, membership });
    };

    openChangeJoinDateDialog = (membership) => {
        this.setState({ isDialogOpen: true, membership, isChangeJoinDateData: true });
    };

    openReferenceNumberForm = (membership) => {
        this.props.dispatch.tradeUnion.setReferenceNumbersPage(true);
        this.setState({ membership });
    };

    renderMembershipList() {
        const { dispatch, membershipData, isEditMode, isLoading, isAdminBundle, userId } = this.props;
        return (
            <Fragment>
                {isAdminBundle && (
                    <Button href={`/service/advanced_reports/210?user=${userId}`} admin target="_blank">
                        AY-jäsenmaksujen selvittely-raportti
                    </Button>
                )}
                <DataTable
                    isLoading={isLoading}
                    emptyDataMessage={_trans('trade_union_membership.empty')}
                    emptyDataCallToAction={isEditMode ? null : <Button onClick={() => dispatch.tradeUnion.setMembershipEditMode(true)} >{_trans('trade_union_membership.add')}</Button>}
                    actionsColumn={
                        <ActionsColumn
                            openEndMembershipDialog={(membership) => this.openEndMembershipDialog(membership)}
                            openChangeJoinDateDialog={(membership) => this.openChangeJoinDateDialog(membership)}
                            openReferenceNumberForm={(membership) => this.openReferenceNumberForm(membership)}
                            isAdminBundle={this.props.isAdminBundle}
                            isAdmin={this.props.isAdmin}
                        />
                    }
                    data={_.get(membershipData.toJS(), 'subscriptions')}
                    isFilterable={false}
                    columns={[
                        {
                            Header: _trans('trade_union_membership.union'),
                            id: 'tradeUnionName',
                            accessor: (row) => `${row.subscription.name} (${_.get(row.subscription, 'activePaymentData.percentage', 'Ei vahvistettu ')}%)`,
                            width: 430,
                        },
                        {
                            Header: _trans('trade_union_membership.join_date'),
                            accessor: 'startDate',
                            type: 'date',
                        },
                        {
                            Header: _trans('trade_union_membership.leave_date'),
                            accessor: 'endDate',
                            type: 'date',
                            defaultValue: 'Voimassa'
                        }
                    ]}
                />
            </Fragment>
        );
    }

    renderAddTradeUnionForm() {
        const { membershipData, isEditMode, dispatch, isSaving } = this.props;
        const memberships = membershipData.toJS();
        const subscriptions = _.get(memberships, 'subscriptions');

        let minimumDate = null;
        if (!_.isEmpty(subscriptions)) {
            minimumDate = new Date(moment(_.maxBy(subscriptions, (subscription) => moment(subscription.startDate)).startDate).add(1, 'd'));
        }
        if (isEditMode) {
            // Akkoostetaan järjestykseen
            const subscriptionOptions = (memberships?.options ?? []).sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                } else if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            return (
                <LocalForm
                    className="o-form o-form--vertical"
                    onSubmit={(model) => this.onSubmit(model)}
                >
                    <Layout.Container>
                        <Layout.Item medium="2/5" large="4/8">
                            <StLabel>
                                {_trans('trade_union_membership.union')}
                            </StLabel>
                            <StField>
                                <Control.select
                                    model=".subscription"
                                    validators={{
                                        isRequired: validators.isRequired,
                                    }}
                                >
                                    <option value={null}>{_trans('dropdown.choose')}</option>
                                    {subscriptionOptions.map((option) => (
                                        <option key={option.id} value={option.id}>{`${option.name} (${option.percentage}%)`}</option>
                                    ))}
                                </Control.select>
                            </StField>
                            <StErrors model=".subscription" />
                        </Layout.Item>
                        <Layout.Item medium="2/5" large="2/8">
                            <StLabel
                                tooltip={_trans('trade_union_membership.join_date_tooltip')}
                            >
                                {_trans('trade_union_membership.join_date')}
                            </StLabel>
                            <StField>
                                <Control
                                    minimumDate={minimumDate}
                                    model=".startDate"
                                    component={DateInput}
                                    validators={{
                                        isDate: validators.isISODate,
                                    }}
                                />
                            </StField>
                            <StErrors model=".startDate" />
                        </Layout.Item>
                        <Layout.Item medium="1/5" large="2/8">
                            <StField isLabeless>
                                <ActionBar alignItems="left">
                                    <SubmitButton isPending={isSaving}>
                                        {_trans('button.save')}
                                    </SubmitButton>
                                    <Button
                                        disabled={isSaving}
                                        preventDefault
                                        onClick={() => dispatch.tradeUnion.setMembershipEditMode(false)}
                                    >
                                        {_trans('button.cancel')}
                                    </Button>
                                </ActionBar>
                            </StField>
                        </Layout.Item>
                    </Layout.Container>
                </LocalForm>
            );
        }

        if (!_.isEmpty(memberships.subscriptions)) {
            return (
                <Button onClick={() => dispatch.tradeUnion.setMembershipEditMode(true)} flat mdIcon="add">{_trans('trade_union_membership.add')}</Button>
            );
        }
    }

    renderDialog() {
        const { dispatch, userId, isSaving } = this.props;

        const isChangeJoinDate = this.state.isChangeJoinDateData;
        const title = isChangeJoinDate ? _trans('trade_union_membership.change_join_date') : _trans('trade_union_membership.end_membership');
        const label = isChangeJoinDate ? _trans('trade_union_membership.new_date') : _trans('trade_union_membership.leave_date');
        const submitText = isChangeJoinDate ? _trans('trade_union_membership.change') : _trans('trade_union_membership.end_membership');
        const minimumDate = isChangeJoinDate ? moment().subtract(50, 'years').toDate() : new Date(_.get(this.state, 'membership.startDate'));
        const path = isChangeJoinDate ? 'startDate' : 'endDate';
        return (
            <Dialog title={title} isOpen={this.state.isDialogOpen} onClose={() => this.setState({ isDialogOpen: false, isChangeJoinDateData: false, })}>
                <LocalForm
                    className="o-form o-form--responsive"
                    initialState={{ membershipId: _.get(this.state, 'membership.id') }}
                    onSubmit={(model) =>
                        dispatch.tradeUnion.patchEmployeeTradeUnionMembership(model, path)
                            .then(() => this.setState({ isDialogOpen: false, membership: null, isChangeJoinDateData: false, }))
                            .then(() => dispatch.tradeUnion.fetchEmployeeTradeUnionMemberships(userId))
                    }
                >
                    <StLabel>
                        {label}
                    </StLabel>
                    <StField>
                        <Control
                            component={DateInput}
                            model=".endDate"
                            minimumDate={minimumDate}
                            validators={{
                                isDate: validators.isISODate,
                                isRequired: validators.isRequired,
                            }}
                        />
                        <StErrors model=".endDate"/>
                    </StField>
                    <DialogFooter>
                        <ActionBar>
                            <Button
                                preventDefault
                                disabled={isSaving}
                                onClick={() => this.setState({ isDialogOpen: false, membership: null, isChangeJoinDateData: false, })}
                            >
                                {_trans('button.cancel')}
                            </Button>
                            <SubmitButton
                                isPending={isSaving}
                            >
                                {submitText}
                            </SubmitButton>
                        </ActionBar>
                    </DialogFooter>
                </LocalForm>
            </Dialog>
        );
    }

    renderContent() {
        const { isLoading, isReferenceNumbersPage, userId } = this.props;
        if (isReferenceNumbersPage) {
            return <TradeUnionReferenceForm membership={this.state.membership} userId={userId} referenceNumberType={referenceNumberTypes.PERSONAL}/>;
        } else {
            return (
                <Page heading={_trans('trade_union_membership.name')} maxWidth="large">
                    {isLoading ? <MDSpinner wrapped/> : this.renderMembershipList()}
                    {this.renderAddTradeUnionForm()}
                    {this.renderDialog()}
                </Page>
            );
        }
    }

    render() {
        return this.renderContent();
    }
}

const ActionsColumn = ({ original, openEndMembershipDialog, openChangeJoinDateDialog, openReferenceNumberForm, isAdminBundle }) => {
    const renderReferenceNumberButton = () => {
        if (! isAdminBundle) {
            return (
                <Button
                    onClick={() => openReferenceNumberForm(original)}
                    modifierClass="u-margin-left-tiny"
                >
                    {_trans('trade_union_membership.reference_numbers.title')}
                </Button>
            );
        }
    };

    //Voimassaolevan voi vain päättää tai muuttaa alkamispäivämäärää
    if (!original.endDate) {
        return (
            <div>
                <Button
                    negative
                    onClick={() => openEndMembershipDialog(original)}
                >
                    {_trans('trade_union_membership.end_membership')}
                </Button>
                <Button
                    onClick={() => openChangeJoinDateDialog(original)}
                    modifierClass="u-margin-left-tiny">
                    {_trans('trade_union_membership.change_join_date')}
                </Button>
                {renderReferenceNumberButton()}
            </div>
        );
    }
    return null;
};

ActionsColumn.propTypes = {
    original: PropTypes.object,
    openEndMembershipDialog: PropTypes.func,
    openChangeJoinDateDialog: PropTypes.func,
    openReferenceNumberForm: PropTypes.func,
    isAdminBundle: PropTypes.bool,
    isAdmin: PropTypes.bool,
};

ActionsColumn.defaultProps = {
    original: {},
    openDialog() {},
    openEndMembershipDialog() {},
    openChangeJoinDateDialog() {},
    openReferenceNumberForm() {},
    isAdminBundle: false,
    isAdmin: false,
};
