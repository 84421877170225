import React from 'react';
import { useDispatch } from 'react-redux';
import { Field, Formik } from 'formik';
import { EmptyState, MDSpinner, OutLink, Page, SubmitButton } from 'shared/components';
import { StField, StLabel, } from 'shared/components/StForm';
import { FormikErrors } from 'shared/components/Formik';
import { useGet, usePost } from 'shared/hooks/useApi';
import validators from 'shared/validators';
import { userActions } from 'shared/UserDetails/stores/user';

const validateForm = (values) => {
    const errors = {};
    const { username, confirmUsername } = values;

    // Required validoi
    if (! (username && confirmUsername)) {
        return errors;
    }

    if (username.trim() !== confirmUsername.trim()) {
        errors.username = _trans('Annetut käyttäjätunnukset eivät täsmää', {}, 'userDetails');
    } else if (! validators.isEmail(username.trim())) {
        errors.username = _trans('Annetut käyttäjätunnukset eivät ole kelvollisia sähköpostiosoitteita', {}, 'userDetails');
    }

    return errors;
};

/**
 * @deprecated Joutaa roskiin ennenpitkää
 */
export const ChangeUsername = () => {
    const dispatch = useDispatch();
    const [{ isPending }, makeRequest] = usePost('/api/v2/users/self/username');
    const [{ data, isLoading }] = useGet('/api/v2/user/self');

    if (isLoading) {
        return (
            <MDSpinner wrapped />
        );
    }

    if (data.isVismaConnectUser) {
        return (
            <Page heading={_trans('Käyttäjätunnuksen vaihto', {}, 'userDetails')} maxWidth="medium">
                <EmptyState
                    icon="identification"
                    message={_trans('Käyttäjätunnuksen vaihto tapahtuu Visma Connect-palvelussa.', {}, 'userDetails')}
                    callToAction={<OutLink href={__DEV__ ? 'https://accountsettings.connect.identity.stagaws.visma.com/' : 'https://accountsettings.connect.visma.com/'}>
                        {_trans('Siirry Visma Connect-palveluun', {}, 'userDetails')}
                    </OutLink>}
                />
            </Page>
        );
    }


    return (
        <Page heading={_trans('Käyttäjätunnuksen vaihto', {}, 'userDetails')} maxWidth="small">
            <Formik
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(model) => makeRequest(model)
                    .then((data) => {
                        const isSuccess = data.status === 'ok';
                        const isKnownError = data.error && (data.error === 'mismatching_usernames' || data.error === 'invalid_email');

                        if (isSuccess) {
                            dispatch.notifications.addSuccess(_trans('Käyttäjätunnuksesi on päivitetty', {}, 'userDetails'));
                            dispatch(userActions.fetchUserSelf());
                        } else if (isKnownError) {
                            dispatch.notifications.addError(data.message);
                        } else {
                            dispatch.notifications.addError(_trans('Tietojen tallentamisen aikana tapahtui virhe', {}, 'userDetails'));
                        }
                    })
                }
                validate={(values) => validateForm(values)}
                initialValues={{
                    username: '',
                    confirmUsername: '',
                }}
            >
                {({ handleSubmit }) => (
                    <form className="o-form o-form--vertical" onSubmit={handleSubmit}>
                        <div>
                            <StLabel htmlFor="username">
                                {_trans('Käyttäjätunnus', {}, 'common')}
                            </StLabel>
                            <StField>
                                <Field required type="text" name="username" id="username" />
                                <FormikErrors name="username" />
                            </StField>
                        </div>
                        <div>
                            <StLabel htmlFor="confirmUsername">
                                {_trans('Käyttäjätunnus uudelleen', {}, 'userDetails')}
                            </StLabel>
                            <StField>
                                <Field required type="text" name="confirmUsername" id="confirmUsername" />
                            </StField>
                        </div>
                        <div>
                            <StField isLabeless>
                                <SubmitButton isPending={isPending}>
                                    {_trans('Tallenna', {}, 'common')}
                                </SubmitButton>
                            </StField>
                        </div>
                    </form>
                )}
            </Formik>
        </Page>
    );
};
