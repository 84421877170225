import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated } from 'react-spring/web.cjs';
import _ from 'lodash';
import classNames from 'classnames';
import utils from 'shared/utils/commonUtils';
import Notification from 'shared/components/Notification';

/**
 * Simppeli notifikaatiolista bäkkäriltä tuleville viesteille.
 * @param notifications
 * @param onTimeout
 * @returns {*}
 * @constructor
 */
const NotificationList = ({ notifications, onTimeout, setManualTop }) => {
    const top = setManualTop ? setManualTop : utils.getRootMarginTop();
    // Passataan notifications ja uniikki id automagic. Keytä käytetään poistamaan oikean notifikaatio.
    const [ items, setItems ] = useState([]);

    useEffect(() => {
        setItems(notifications.map((item, key) => ({ ...item, id: key })));
    }, [notifications]);

    // React Spring transitiot kun komponentti tulee, jää ja lähtee.
    // TODO: Hae oikea komponentin korkeus.
    const transitions = useTransition(items, (item) => item.id, {
        from: {
            opacity: 0,
            marginTop: 0,
            maxHeight: 0,
        },
        leave: {
            opacity: 0,
            marginTop: 0,
            maxHeight: 0,
        },
        enter: {
            opacity: 1,
            marginTop: 12,
            maxHeight: 66,
        },
    });

    return (
        <div
            className={classNames('c-notifications o-pin o-pin--fixed o-pin--right u-padding-right-small u-z-index-1', {
                'u-pointer-events-none': _.size(notifications) === 0,
            })}
            style={{ top }}
        >
            <ul className="c-notification-list o-list-bare u-margin-bottom-none">
                {transitions.map(({ item, props, key }) => (
                    <animated.li style={props} key={key}>
                        <Notification
                            key={key}
                            type={item.type}
                            timeout={_.get(item, 'timeout', 5000)}
                            isDismissable={item.isDismissable}
                            onTimeout={() => {
                                const id = item.id;
                                const filtered = items.filter((item) => item.id !== id);
                                setItems(filtered);
                                onTimeout(item.id);
                            }}
                        >
                            {_transMd(_.get(item, 'message', false))}
                        </Notification>
                    </animated.li>
                ))}
            </ul>
        </div>
    );
};

NotificationList.defaultProps = {
    onTimeout() {},
    setManualTop: null,
};

NotificationList.propTypes = {
    notifications: PropTypes.array.isRequired,
    onTimeout: PropTypes.func,
    setManualTop: PropTypes.number,
};

export default NotificationList;
