import api from 'api';
import { sortAlphabeticallyByKey } from 'shared/utils/sortUtils';

export const users = {
    state: {
        cared: [],
    },
    reducers: {
        setCared: (state, cared) => ({ ...state, cared }),
    },
    selectors: {
        getCared: (state) => state.cared,
    },
    effects: () => ({
        async fetchCared() {
            try {
                const json = await api.get('/api/v2/users?type=1&limit=20000');
                this.setCared(json.data.sort((a, b) => sortAlphabeticallyByKey(a, b, 'fullName')));
            } catch (e) {
                console.error(e);
            }
        },
    })
};
