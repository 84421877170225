import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import TaxCardTodoActions from 'shared/TaxCardTodos/components/TaxCardTodoActions';
import { Feedback, ActionBar, ConfirmButton } from 'shared/components';
import { DataTable } from 'shared/containers';
import { customEvents, dispatchCustomEvent } from 'shared/utils/customEvents';

const TaxCardTodos = () => {
    const dispatch = useDispatch();
    const taxCardTodos = useSelector(select.taxCardTodos.getTaxCardTodos);
    const [selectedTodos, setSelectedTodos] = useState([]);
    const isAllowAssignmentContracts = useSelector(select.userMetadata.allowAssignmentContracts);

    useEffect(() => {
        dispatch.taxCardTodos.fetchTaxCardTodos();
    },[dispatch]);

    const resolveSingle = (todoId) => {
        dispatch.taxCardTodos.postResolveTaxCardTodo(todoId).then(() => {
            dispatchCustomEvent(customEvents.UPDATE_NOTIFICATIONS);
        });
    };

    const resolveMultiple = (todoArray) => {
        dispatch.taxCardTodos.postResolveMultipleTaxCardTodos(todoArray).then(() => {
            dispatchCustomEvent(customEvents.UPDATE_NOTIFICATIONS);
        });
        setSelectedTodos([]);
    };

    const columns = [
        {
            Header: isAllowAssignmentContracts ? _trans('Hoitajan nimi', {}, 'common') : _trans('Työntekijän nimi', {}, 'common'),
            accessor: 'employeeName',
        },
        {
            Header: _trans('Työnantajan nimi', {}, 'common'),
            accessor: 'employerName',
            show: !isAllowAssignmentContracts,
        },
        {
            Header: _trans('Hoidettavan nimi', {}, 'common'),
            accessor: 'caredName',
            show: isAllowAssignmentContracts
        },
        {
            Header: isAllowAssignmentContracts ? _trans('Palkkiojakso', {}, 'common') : _trans('Palkkajakso', {}, 'common'),
            accessor: 'periodDescription',
        },
        {
            Header: _trans('Toiminnot', {}, 'common'),
            accessor: 'data',
            filterable: false,
            Cell: (props) => <TaxCardTodoActions resolveSingle={resolveSingle} isAllowAssignmentContracts={isAllowAssignmentContracts} value={props} />,
            width: 350
        }
    ];

    const handleSelection = (value) => {
        setSelectedTodos(value);
    };

    return (
        <Fragment>
            <Feedback type="info">
                {isAllowAssignmentContracts
                    ? _trans('Hyväksytyn hoitojakson veroprosentti on muuttunut. Jos haluat käyttää uutta veroprosenttia, palauta palkkiolaskelma muokattavaksi ja hyväksy se uudestaan. (Halutessasi voit sivuuttaa ilmoituksen)', {}, 'todo')
                    : _trans('Hyväksyttyjen palkkajaksojen veroprosentti on muuttunut. Jos haluat käyttää uutta veroprosenttia, palauta palkkanauha tuntiensyöttöön ja hyväksy se uudestaan. (Halutessasi voit sivuuttaa ilmoituksen)', {}, 'todo')
                }
            </Feedback>
            <ActionBar modifierClass="u-margin-top-small" alignItems="right">
                <ConfirmButton
                    text={_trans('Sivuuta valitut', {}, 'todo') + ' ' + selectedTodos.length}
                    confirmText={_trans('Haluatko varmasti sivuuttaa %amount% ilmoitusta?', { amount: selectedTodos.length }, 'todo')}
                    onClick={() => resolveMultiple(selectedTodos)}
                    disabled={selectedTodos.length === 0}
                />
            </ActionBar>
            <DataTable
                data={taxCardTodos.toJS()}
                columns={columns}
                isSelectable
                onSelect={(value) => handleSelection(value)}
                hasExternalSelection
                selection={selectedTodos}
                keyField="id"
            />
        </Fragment>
    );
};

export default TaxCardTodos;
