import React from 'react';
import * as PropTypes from 'prop-types';
import { LinkIcon } from 'shared/components/index';

/**
 * Ulkoisiin linkkeihin, uuteeen tabiin avautuu
 *
 * @param href
 * @param children
 * @return {*}
 * @constructor
 */
const OutLink = ({ href, children }) => (
    <LinkIcon
        iconAfterText
        iconSize="tiny"
        mdIcon="open_in_new"
        href={href}
        target="_blank"
        title={children}
    >
        {children}
    </LinkIcon>
);

OutLink.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
};

export default OutLink;
