/**
 * Palauttaa translations-objektista sopivimman käännöksen. Oletuksena kielenä käytetään käyttäjän omaa kieltä mutta sen
 * voi tarvittaessa ohittaa antamalla toiseksi parametriksi kielen. Jos ensisijaiselle kielelle ei löydy käännöstä, niin
 * sitten käytetään fallbackina suomea.
 *
 * @param translations
 * @param locale
 */
function _transObj(translations, locale = _locale) {
    return translations[locale] ?? translations[window['Translator'].fallback];
}

export default _transObj;
