import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import eventKeys from 'shared/constants/eventKeys';

function Card({
    children,
    modifierClass,
    isHoverable,
    onClick,
}) {
    // Jos onClick-event on annettu käytetään reilusti button-elementtiä (saavutettavuus).
    const isInteractive = onClick !== null;

    const cardClass = classNames('c-card', modifierClass, {
        'c-card--hoverable is-focusable': isHoverable,
        'u-text-left': isInteractive,
    });

    const interactiveProps = {
        onClick: (event) => {
            event.preventDefault();
            onClick();
        },
        onKeyDown: (event) => {
            const eventKey = event.key;
            event.preventDefault();
            if (eventKey === eventKeys.ENTER) {
                onClick();
            }
        },
    };

    return React.createElement(isInteractive ? 'button' : 'section', {
        className: cardClass,
        ...isInteractive && interactiveProps,
    }, children);
}

Card.defaultProps = {
    modifierClass: null,
    isHoverable: false,
    onClick: null,
};

Card.propTypes = {
    children: PropTypes.node.isRequired,
    modifierClass: PropTypes.string,
    isHoverable: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Card;
