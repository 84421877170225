import api from 'api';
import { isJobContractType } from 'shared/constants/contract';

/**
 * Palkat/palkkiot.
 */
export const salary = {
    state: {},

    reducers: {
        setSalaries: (state, payload) => payload,
    },

    selectors: {
        getSalaries: (state) => state,
    },

    effects: (dispatch) => ({
        /**
         * Hakee sopparin palkat/palkkiot.
         */
        async fetchContractSalaries(contractId, rootState) {
            const isJobContract = isJobContractType(rootState?.contract.type);
            try {
                const response = await api.get(`/api/contracts/v1/${contractId}/salaries`);
                this.setSalaries(response);
            } catch (e) {
                dispatch.notifications.addError(isJobContract
                    ? _trans('Sopimuksen palkkatietoja ei voitu hakea.', {}, 'jobContract')
                    : _trans('Sopimuksen palkkiotietoja ei voitu hakea.', {}, 'jobContract'));
            }
        },
    })
};
