import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'shared/components';

export const YearSelector = ({ initialYear, onChange }) => {
    const [year, setYear] = useState(initialYear);

    useEffect(() => {
        setYear(initialYear);
    }, [initialYear]);

    const years = Array.from({ length: 50 }).map((_, idx) => {
        const fullYear = idx + parseInt(initialYear, 10) - 25;
        return (
            <option key={idx} value={fullYear}>
                {fullYear}
            </option>
        );
    });

    const parseAndChange = (event) => {
        const nextYear = parseInt(event.target.value, 10);
        setYear(nextYear);
        onChange(nextYear);
    };

    return (
        <div className="DateRangePicker__MonthHeader">
            <Button
                flat
                hasPadding={false}
                mdIcon="arrow_backward"
                onClick={() => {
                    const nextYear = year - 1;
                    setYear(nextYear);
                    onChange(nextYear);
                }}
                modifierClass="u-margin-top-tiny u-1/1"
                ariaLabel={_trans('date_range.calendar.prev_button')}
            />
            <span className="DateRangePicker__MonthHeaderLabel DateRangePicker__MonthHeaderLabel--year">
                <span className="c-dropdown c-dropdown--flat">
                    {year}
                </span>
                <select
                    className="DateRangePicker__MonthHeaderSelect"
                    onChange={parseAndChange}
                    onBlur={parseAndChange}
                    aria-label={_trans('date_range.calendar.month_selection')}
                    value={year}
                >
                    {years}
                </select>
            </span>
            <Button
                flat
                hasPadding={false}
                mdIcon="arrow_forward"
                onClick={() => {
                    const nextYear = year + 1;
                    setYear(nextYear);
                    onChange(nextYear);
                }}
                modifierClass="u-margin-top-tiny u-1/1"
                ariaLabel={_trans('date_range.calendar.next_button')}
            />
        </div>
    );
};

YearSelector.propTypes = {
    initialYear: PropTypes.number.isRequired,
    onChange: PropTypes.func,
};

YearSelector.defaultProps = {
    onChange() {},
};
