import React from 'react';
import PropTypes from 'prop-types';

/**
 * Näyttää peruspalkan mahdollisen muutoksen.
 * @param baseSalary
 * @param newBaseSalary
 * @returns {*}
 * @constructor
 */
function ContractBaseSalary({ baseSalary, newBaseSalary }) {
    // Ennen palkkaryhmän valintaa näytetään peruspalkka.
    if (newBaseSalary === null || baseSalary > newBaseSalary) {
        return (
            <strong>
                {_currency(baseSalary)}
            </strong>
        );
    }

    // Jos palkka päivittyy, näytetään vanha palkka yliviivattuna.

    return (
        <strong>
            <del>
                {_currency(baseSalary)}
            </del>
            <ins>
                {_currency(newBaseSalary)}
            </ins>
        </strong>
    );
}

ContractBaseSalary.propTypes = {
    baseSalary: PropTypes.number.isRequired,
    newBaseSalary: PropTypes.number,
};

ContractBaseSalary.defaultProps = {
    newBaseSalary: -1,
};

export default ContractBaseSalary;
