import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import CirclePlaceholder from './CirclePlaceholder';
import BoxPlaceholder from './BoxPlaceholder';
import MediaPlaceholder from './MediaPlaceholder';
import TablePlaceholder from './TablePlaceholder';
import { componentSizes } from 'shared/constants';
import { range } from 'shared/utils/collectionHelpers';

export {
    CirclePlaceholder,
    BoxPlaceholder,
    MediaPlaceholder,
    TablePlaceholder,
};

const columnWidths = [
    100,
    70,
    80,
    65,
    35,
];

const placeholderTypes = {
    TEXT: 'text',
    MEDIA: 'media',
    BOX: 'box',
    CIRCLE: 'circle',
    LIST: 'list',
    TABLE: 'table',
    AUTO: 'auto',
};

/**
 * Placeholder-komponentti jolla voi luoda placeholdereita
 * listoille, teksteille, medialle (kuvat) ja eri muodoille.
 * Voidaan käyttää esimerkiksi listalle sen aikaa kun itse dataa
 * ladataan. Renderöi siis näennäisen hahmotelman lopullisesta
 * komponentista.
 */
export default class Placeholder extends Component {

    state = {
        showPlaceholder: false,
    };

    static propTypes = {
        /**
         * Millainen placeholder renderöidään.
         */
        type: PropTypes.oneOf(Object.values(placeholderTypes)),

        /**
         * Koko. Esim. listojen ja mediaobjektin koko.
         */
        size: PropTypes.oneOfType([PropTypes.oneOf([..._.map(componentSizes), '']), PropTypes.number]),

        /**
         * Montako riviä renderöidään.
         */
        rows: PropTypes.number,

        /**
         * Tekstityypin ollessa kyseessä montako kappaletta renderöidään.
         */
        paragraphs: PropTypes.number,

        /**
         * Montako saraketta renderöidään.
         * Voi olla joko numero tai DataTable-containerin syömä objekti. Tämän kautta saadaan automaattisesti
         * sekä sarakkeiden määrä että niiden leveys.
         */
        columns: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.number,
            ]),
        )]),

        /**
         * Oma kustomoitu placeholder.
         */
        placeholderComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),

        /**
         * Näytetäänkö edellisen elementin perässä vai omalla rivillään.
         */
        isInline: PropTypes.bool,

        /**
         * Pendaako.
         */
        isPending: PropTypes.bool,

        /**
         * Itse sisältö kun lataus / pendaus on ohi.
         */
        children: PropTypes.node.isRequired,

        modifierClass: PropTypes.string,
    };

    static defaultProps = {
        type: 'auto',
        size: componentSizes.SMALL,
        count: 1,
        rows: 5,
        columns: 1,
        paragraphs: 1,
        isInline: false,
        isPending: true,
        placeholderComponent: null,
        modifierClass: '',
    };

    render() {
        const {
            type,
            size,
            rows,
            paragraphs,
            columns,
            isInline,
            isPending,
            children,
            modifierClass,
        } = this.props;

        // Palauta varsinainen sisältö jos ei pendata enää.
        if (! isPending) return children;

        if (type === placeholderTypes.TEXT) {
            return range(1, paragraphs).map((_, paragraph) => (
                <div key={paragraph} className={classNames({ 'u-margin-top': paragraph > 0, [modifierClass]: modifierClass !== '' })}>
                    {range(1, rows).map((_, key) => (
                        <div key={key}>
                            <BoxPlaceholder
                                {...this.props}
                                width={`${columnWidths[key % 5]}%`}
                            />
                        </div>
                    ))}
                </div>
            ));
        }

        if (type === placeholderTypes.BOX) {
            return <BoxPlaceholder {...this.props} />;
        }

        if (type === placeholderTypes.CIRCLE) {
            return <CirclePlaceholder {...this.props} />;
        }

        if (type === placeholderTypes.MEDIA) {
            return <MediaPlaceholder rows={rows} size={size} />;
        }

        if (type === placeholderTypes.LIST) {
            return <MediaPlaceholder rows={rows} size={size} hasAvatar={false} />;
        }

        if (type === placeholderTypes.TABLE) {
            return (
                <TablePlaceholder
                    rows={rows}
                    columns={columns}
                    size={size}
                    showAvatar={false}
                    isFilterable={_.get(this.props, 'isFilterable', true)}
                />
            );
        }

        if (type === placeholderTypes.AUTO) {
            return (
                <div className={classNames('o-placeholder-container', { 'u-display-inline-block': isInline })}>
                    {children}
                    <BoxPlaceholder autoSize />
                </div>
            );
        }

        console.error('Placeholder type is missing!');
        return null;
    }
}
