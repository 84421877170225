import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useDidUpdate } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { FormField, SwitchField } from 'shared/components/Formik';
import { getCustomComponentByFieldName } from 'ContractV3/utils/getCustomComponentByFieldName';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';
import { resolveDefaultValue } from 'shared/ReForm/utils/resolveDefaultValue';

/**
 * Taulukkopalkan käyttövipu.
 * Piilottaa UI:lta taulukkoliksat ja sallii palkan/palkkion syöttämisen käsin.
 * @returns {JSX.Element}
 * @constructor
 */
const TableSalarySwitch = ({ name, arrayName, label }) => {
    const [field] = useField(name);
    const [,,tableSalaryAttributeHelpers] = useField(resolveArrayName(arrayName, fieldNames.TABLE_SALARY_ATTRIBUTES));
    const [,,costReimbursementAttributeHelpers] = useField(resolveArrayName(arrayName, fieldNames.COST_REIMBURSEMENT_ATTRIBUTES));
    const [,,costReimbursementSpecialAllowanceAttributeHelpers] = useField(resolveArrayName(arrayName, fieldNames.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE_ATTRIBUTES));
    const [,,initialSalaryHelpers] = useField(resolveArrayName(arrayName, fieldNames.INITIAL_SALARY));
    const [,,initialPersonalSalaryHelpers] = useField(resolveArrayName(arrayName, fieldNames.INITIAL_PERSONAL_SALARY));
    const [,,costReimbursementHelpers] = useField(resolveArrayName(arrayName, fieldNames.COST_REIMBURSEMENT));
    const [,,costReimbursementSpecialAllowanceHelpers] = useField(resolveArrayName(arrayName, fieldNames.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE));
    const [,,isPendingSalaryHelpers] = useField(resolveArrayName(arrayName, fieldNames.IS_PENDING_SALARY));
    const [,,isPendingCostReimbursementHelpers] = useField(resolveArrayName(arrayName, fieldNames.IS_PENDING_COST_REIMBURSEMENT));
    const [,,isPendingCostReimbursementSpecialAllowanceHelpers] = useField(resolveArrayName(arrayName, fieldNames.IS_PENDING_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE));
    const isTableSalaryInUse = field.value;
    const hasTableSalaries = useSelector(select.contract.hasTableSalaries);
    const hasCostReimbursementTableSalaries = useSelector(select.contract.hasCostReimbursementTableSalaries);

    useDidUpdate(() => {
        // Jos taulukkoliksat otetaan pois päältä resetoidaan taulukot
        // Näiden resetointi varmistaa myös sen että muitakaan
        // taulukkoliksa-arvoja ei "generoida" sopimusta tallennettaessa
        if (! isTableSalaryInUse) {
            // Vedetään komponenttien defaultValue:t suoraan
            initialPersonalSalaryHelpers.setValue(
                resolveDefaultValue(getCustomComponentByFieldName(fieldNames.INITIAL_PERSONAL_SALARY))
            );
            if (hasCostReimbursementTableSalaries) {
                costReimbursementHelpers.setValue(
                    resolveDefaultValue(getCustomComponentByFieldName(fieldNames.COST_REIMBURSEMENT))
                );
                costReimbursementAttributeHelpers.setValue(
                    resolveDefaultValue(getCustomComponentByFieldName(fieldNames.COST_REIMBURSEMENT_ATTRIBUTES))
                );
                costReimbursementSpecialAllowanceHelpers.setValue(
                    resolveDefaultValue(getCustomComponentByFieldName(fieldNames.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE))
                );
                costReimbursementSpecialAllowanceAttributeHelpers.setValue(
                    resolveDefaultValue(getCustomComponentByFieldName(fieldNames.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE_ATTRIBUTES))
                );
            }
            costReimbursementSpecialAllowanceHelpers.setValue(
                resolveDefaultValue(getCustomComponentByFieldName(fieldNames.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE))
            );
            tableSalaryAttributeHelpers.setValue(
                resolveDefaultValue(getCustomComponentByFieldName(fieldNames.TABLE_SALARY_ATTRIBUTES))
            );
            isPendingSalaryHelpers.setValue(false);
            isPendingCostReimbursementHelpers.setValue(false);
            isPendingCostReimbursementSpecialAllowanceHelpers.setValue(false);
        }

        // Tämä tyhjäksi eikä nollaksi jotta asiakas vapaapalkkiollisilla täyttääkin tähän jotain
        initialSalaryHelpers.setValue('');
    }, [isTableSalaryInUse, hasCostReimbursementTableSalaries]);

    // Ei taulukkoliksoja tessillä lain, early exittaa tästä vain
    if (! hasTableSalaries) return null;

    return (
        <FormField label={_trans('contract.form.tes_variables.table_salary.label')} name={name}>
            <SwitchField name={name} id="tableSalarySwitch">{label}</SwitchField>
        </FormField>
    );
};

TableSalarySwitch.propTypes = {
    name: PropTypes.string.isRequired,
    arrayName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default TableSalarySwitch;
