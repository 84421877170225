import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import { PreviousEmployeeSignature } from 'shared/ReForm/containers/CustomBlocks/ContractV3/components/Signatures/PreviousEmployeeSignature';
import { FormField } from 'shared/components/Formik';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import _transObj from 'shared/utils/_transObj';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { metadataNames } from 'ContractV3/constants/metadataNames';
import UserBlock from 'shared/components/UserBlock';

export const getTranslations = (allowAssignmentContracts) => ({
    waitingForSignature: allowAssignmentContracts
        ? _trans('Odottaa hoitajan %signatureIndex%/%signatureCount% allekirjoitusta', {}, 'jobContract')
        : _trans('Odottaa työntekijän %signatureIndex%/%signatureCount% allekirjoitusta', {}, 'jobContract'),

    employeeNotSelected: allowAssignmentContracts
        ? _trans('Hoitajaa ei ole vielä valittu.', {}, 'jobContract')
        : _trans('Työntekijää ei ole vielä valittu.', {}, 'jobContract'),

    isNotUsingOima: allowAssignmentContracts
        ? _trans('Hoitaja ei käytä Oimaa.', {}, 'jobContract')
        : _trans('Työntekijä ei käytä Oimaa.', {}, 'jobContract'),
});

/**
 * Työntekijän allekirjoitus.
 * Jos työntekijää ei ole vielä valittu, näyttää suoran "linkin" jonka kautta
 * hyppää työntekijän valitsimeen jos työntekijöitä ylipäätään löytyy.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const SignaturesEmployee = (props) => {
    const allowAssignmentContracts = useSelector(select.userMetadata.allowAssignmentContracts);
    const translations = getTranslations(allowAssignmentContracts);
    const { waitingForSignature, employeeNotSelected } = translations;
    const [employeeField] = useField(fieldNames.EMPLOYEE);
    const employeeFieldValue = employeeField.value ?? [];
    const [employeeAccessField,,employeeAccessHelpers] = useField(metadataNames.IS_EMPLOYEE_NOT_USING_OIMA);
    const isLoading = useSelector((state) => state.loading.effects.employees.fetchEmployees);

    const isProposalContract = useSelector(select.contract.isProposalContract);
    const isNewContract = useSelector(select.contract.isNewContract);
    const isCloningContract = useSelector(select.contract.isCloningContract);
    const employees = useSelector((state) => (isNewContract && ! isCloningContract)
        ? select.employees.getEmployees(state)
        : select.contract.getEmployees(state));
    const selectedEmployees = isNewContract
        ? employees.filter((employee) => employeeFieldValue.includes((employee.userId)))
        : employees;

    const [isReSignatureNeededField] = useField(metadataNames.IS_RE_SIGNATURE_NEEDED);
    const isReSignatureNeeded = isReSignatureNeededField.value;

    const hasPreviousSignatures = useSelector(select.contract.hasPreviousSignatures);
    const previousEmployeeSignatures = useSelector(select.contract.getPreviousEmployeeSignatures);
    const employeesNotUsingOima = useSelector(select.contract.isEmployeeNotUsingOima);

    const { label, ...restFieldProps } = getFieldProps(props);
    const resolveLabel = (index) => allowAssignmentContracts
        ? _trans('Hoitaja %signatureIndex%/%signatureCount%', {
            signatureIndex: index + 1,
            signatureCount: (employeeField.value ?? []).length
        }, 'jobContract')
        : _trans('Työntekijä', {}, 'jobContract');

    // Ei vaadita uusia allekirjoituksia. Kerrotaan milloin viimeksi allekirjoitettu ja kenen toimesta.
    if (hasPreviousSignatures && isReSignatureNeeded === false) {
        return previousEmployeeSignatures.map((signature, index) => {
            const signerId = signature?.signerId?.toString();
            const isEmployeeNotUsingOima = (
                signature?.signerDetails?.isSignedOnBehalf
                    && employeesNotUsingOima.find((employeeUserId) => employeeUserId?.toString() === signerId)
            );

            return (
                <FormField
                    {...restFieldProps}
                    label={label ?? resolveLabel(index)}
                    name={`employeeSignature.${index}`}
                    isContentFormField={false}
                    key={index}
                >
                    <PreviousEmployeeSignature
                        signature={signature}
                        isEmployeeNotUsingOima={isEmployeeNotUsingOima}
                    />
                </FormField>
            );
        });
    }

    return (
        (selectedEmployees ?? []).map((employee, index) => {
            const hasEmployeeEmail = employee.email ?? false;
            const hasSelectedEmployee = employeeField.value !== '' && isLoading === false;
            const defaultChecked = Array.isArray(employeeAccessField.value) && employeeAccessField.value.includes(employee?.userId?.toString());

            return (
                <FormField
                    {...restFieldProps}
                    label={label ?? resolveLabel(index)}
                    name={`employeeSignature.${index}`}
                    isContentFormField={false}
                    key={index}
                >
                    <div>
                        {hasSelectedEmployee && !hasEmployeeEmail && (
                            <div className="o-form__text">
                                <strong className="u-display-block">{employee?.fullName ?? '-'}</strong>
                                <p>
                                    {_trans('Sähköpostiosoite puuttuu. Sopimus allekirjoitetaan paperiseen versioon.', {}, 'jobContract')}
                                </p>
                            </div>
                        )}

                        {!hasSelectedEmployee && (
                            <div>
                                {_transObj(employeeNotSelected)}{' '}
                                {employees.length > 0 && (
                                    <button
                                        type="button"
                                        onClick={() => document.getElementById('employee').focus()}
                                        className="u-color-link"
                                    >
                                        {_trans('Siirry valitsemaan.', {}, 'jobContract')}
                                    </button>
                                )}
                            </div>
                        )}

                        {(!isProposalContract && hasEmployeeEmail && hasSelectedEmployee) && (
                            <div aria-describedby="employeeSignature" className="o-form__text" key={index}>
                                <UserBlock user={employee} size="small" isLoading={isLoading} secondaryInfo="email" />
                            </div>
                        )}

                        {(hasSelectedEmployee && hasEmployeeEmail) && (
                            <Fragment>
                                {(employeeAccessField.value === false && isProposalContract) && (
                                    <div className="u-muted">
                                        {_trans(waitingForSignature, {
                                            signatureIndex: index + 2,
                                            signatureCount: (employeeField.value ?? []).length + 1
                                        })}
                                    </div>
                                )}
                                <Fragment>
                                    <label className="o-form__text">
                                        <input
                                            type="checkbox"
                                            defaultChecked={defaultChecked}
                                            onChange={(event) => {
                                                const currentValue = employeeAccessField.value ?? [];
                                                const employeeUserId = employee.userId?.toString();

                                                employeeAccessHelpers.setValue((event.target.checked)
                                                    ? currentValue.concat(employeeUserId)
                                                    : currentValue.filter((userId) => userId !== employeeUserId)
                                                );
                                            }}
                                        />
                                        {`${translations.isNotUsingOima} ${_trans('Allekirjoitus tehdään sopimuksen paperiseen versioon.', {}, 'jobContract')}`}
                                    </label>
                                </Fragment>
                            </Fragment>
                        )}
                    </div>
                </FormField>
            );})
    );
};

SignaturesEmployee.propTypes = {
    name: PropTypes.string.isRequired,
    attributes: PropTypes.object.isRequired,
};

export default SignaturesEmployee;
