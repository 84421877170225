export const payPeriodStates = {
    /**
     * Editoitavana. Ei työtenkijän ehdottama eikä työnantajan hyväksymä
     */
    EDIT: 1,

    /**
     * Työntekijän ehdottamia tunteja
     */
    PROPOSAL: 2,

    /**
     * Työantajan hyväksymät tunnit. Payroll luotu.
     */
    CONFIRMED: 3,

    /**
     * EI KÄYTÖSSÄ. Voisi merkata payperiodin maksettuun tilaan setToInvoiced vaiheessa
     */
    PAID: 4,

    /**
     * Palautetun (negatiivisen palkan!) palkkajakson tila
     */
    NEGATIVE_PAY_PERIOD: 5,

    /**
     * Alkuperäisen palautetun palkkajakson tila,
     * asetetaan kun palkkalaskelma palautetaan
     */
    REFUNDED_PAY_PERIOD: 6,

    /**
     * Ohitettu jakso. Jakso on merkitty ohitetuksi.
     */
    PASSED: 10,

    /**
     * Luotu korjaava palkkajakso
     */
    PENDING_CORRECTION: 11,

    /**
     * Korjaava jakso hyväksytty
     */
    CONFIRMED_CORRECTION: 12,

    /**
     * Korjattu jakso, jaksosta on luoto korvaava joka on hyväksytty.
     */
    CORRECTED: 13,

    /**
     * Tyhjänä ( = ei incomeja generoitu) hyväksytty jakso.
     * Lisätty 10/2016. Aiemmin payperiod, joka hyväksytään mutta josta ei generoitu
     * incomeja meni automaattisesti ohitetuksi (jolloin ei voitu tietää,
     * että onko jakso hyväksytty ja ohitettu vai käyttäjän ohittama).
     */
    CONFIRMED_WITHOUT_PAYROLL: 30,

    /**
     * Odottaa palkkaanuhan laskemista taustalla
     */
    PENDING_CALCULATION: 100,

    /**
     * Virtuaali state frontilla vain (työaika-näkymä).
     * Iffitellään vähän, jos tyhjä ja ei per month
     */
    EMPTY: 999,
};

export const confirmedStates = [
    payPeriodStates.CONFIRMED,
    payPeriodStates.CONFIRMED_WITHOUT_PAYROLL,
    payPeriodStates.PAID,
    payPeriodStates.PASSED,
    payPeriodStates.CONFIRMED_CORRECTION
];

const payPeriodStateTranslationMap = {
    [payPeriodStates.EDIT]: _trans('payperiods.state.edit'),
    [payPeriodStates.PROPOSAL]: _trans('payperiods.state.proposal'),
    [payPeriodStates.CONFIRMED]: _trans('payperiods.state.confirmed'),
    [payPeriodStates.PASSED]: _trans('payperiods.state.passed'),
    [payPeriodStates.EMPTY]: _trans('payperiods.state.empty'),
    [payPeriodStates.NEGATIVE_PAY_PERIOD]: _trans('payperiods.state.nagetive'),
    [payPeriodStates.REFUNDED_PAY_PERIOD]: _trans('payperiods.state.refunded'),
    [payPeriodStates.CONFIRMED_WITHOUT_PAYROLL]: _trans('payperiods.state.confirmed_without_payroll'),
    [payPeriodStates.PENDING_CORRECTION]: _trans('payperiods.state.pending_correction'),
    [payPeriodStates.CONFIRMED_CORRECTION]: _trans('payperiods.state.confirmed_correction'),
    [payPeriodStates.CORRECTED]: _trans('Korjattu (ei voimassa)', {}, 'extract'),
    [payPeriodStates.PENDING_CALCULATION]: _trans('payperiods.state.pending_calculation'),
};

export function translatePayPeriodState(state) {
    return state in payPeriodStateTranslationMap ? payPeriodStateTranslationMap[state] : _trans('general.unknown');
}

const payPeriodStateBadgeTypeMap = {
    [payPeriodStates.EDIT]: 'neutral',
    [payPeriodStates.PROPOSAL]: 'positive',
    [payPeriodStates.CONFIRMED]: 'positive',
    [payPeriodStates.PASSED]: 'yellow',
    [payPeriodStates.EMPTY]: 'neutral',
    [payPeriodStates.NEGATIVE_PAY_PERIOD]: 'yellow',
    [payPeriodStates.REFUNDED_PAY_PERIOD]: 'yellow',
    [payPeriodStates.PENDING_CORRECTION]: 'neutral',
    [payPeriodStates.CONFIRMED_CORRECTION]: 'positive',
    [payPeriodStates.CORRECTED]: 'yellow',
    [payPeriodStates.PENDING_CALCULATION]: 'yellow',
};

export function mapPayPeriodStateBadgeType(state) {
    return state in payPeriodStateBadgeTypeMap ? payPeriodStateBadgeTypeMap[state] : 'hollow';
}

export default payPeriodStates;
