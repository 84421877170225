export const contractDataTypes = {
    MASTER_VERSION: 1,
    PROPOSAL: 3,
    ARCHIVED: 4,
    DRAFT: 6,
    DRAFT_PENDING_SALARIES: 66,
    UNFINISHED: 98,

    // Alla olevat vain UI:n käyttöön kertomaan vielä tarkemmin sopparidatan tilasta
    OLD_VERSION: 30,
    UPCOMING_VERSION: 31,
};

const contractDataTypeMap = {
    [contractDataTypes.MASTER_VERSION]: _trans('Voimassa', {}, 'jobContract'),
    [contractDataTypes.PROPOSAL]: _trans('Hyväksyttävänä', {}, 'jobContract'),
    [contractDataTypes.ARCHIVED]: _trans('contract.version_state.archived'),
    [contractDataTypes.DRAFT]: _trans('Luonnos', {}, 'jobContract'),
    [contractDataTypes.DRAFT_PENDING_SALARIES]: _trans('Luonnos, odottaa indeksikorotuksia', {}, 'jobContract'),
    [contractDataTypes.UNFINISHED]: _trans('Keskeneräinen', {}, 'jobContract'),

    [contractDataTypes.UPCOMING_VERSION]: _trans('Tulossa voimaan', {}, 'jobContract'),
    [contractDataTypes.OLD_VERSION]: _trans('Vanha versio', {}, 'jobContract'),
};

export const translateContractDataType = (contractDataState) => contractDataTypeMap[contractDataState] ?? '';

const contractDataUiState = {
    [contractDataTypes.MASTER_VERSION]: 'positive',
    [contractDataTypes.PROPOSAL]: 'yellow',
    [contractDataTypes.ARCHIVED]: 'hollow',
    [contractDataTypes.DRAFT]: 'neutral',
    [contractDataTypes.DRAFT_PENDING_SALARIES]: 'neutral',
    [contractDataTypes.UNFINISHED]: 'neutral',

    [contractDataTypes.UPCOMING_VERSION]: 'yellow',
    [contractDataTypes.OLD_VERSION]: 'neutral',
};

export const mapContractDataTypeToUi = (contractDataState) => contractDataUiState[contractDataState] ?? '';

