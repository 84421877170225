import { Field } from 'formik';
import React from 'react';
import {
    StField,
    StLabel,
} from 'shared/components';
import { FormikErrors } from 'shared/components/Formik';

/**
 * Asiaa hoitavan virkailijan kentät.
 * @returns {JSX.Element}
 * @constructor
 */
export const ForeclosureContactFields = () => (
    <fieldset>
        <legend>
            {_trans('foreclosure.form.contact.legend')}
        </legend>

        <StLabel htmlFor="contactName" tooltip={_trans('Jos virkailijaa ei ole tiedossa, voi kirjoittaa "Ei tietoa"', {}, 'extract')}>
            {_trans('foreclosure.form.contact.contact_name.label')}
        </StLabel>
        <StField>
            <Field
                type="text"
                id="contactName"
                name="contactName"
                size={32}
                required={true}
            />
            <FormikErrors name="contactName" />
        </StField>

        <StLabel htmlFor="contactPhone">
            {_trans('foreclosure.form.contact.contact_phone.label')}
        </StLabel>
        <StField>
            <Field
                type="tel"
                id="contactPhone"
                name="contactPhone"
                size={32}
            />
            <FormikErrors name="contactPhone" />
        </StField>

        <StLabel htmlFor="contactEmail">
            {_trans('foreclosure.form.contact.contact_email.label')}
        </StLabel>
        <StField>
            <Field
                type="email"
                id="contactEmail"
                name="contactEmail"
                size={32}
            />
            <FormikErrors name="contactEmail" />
        </StField>

        <StLabel htmlFor="enforcementOfficeName">
            {_trans('foreclosure.form.contact.enforcement_office_name.label')}
        </StLabel>
        <StField>
            <Field
                type="text"
                id="enforcementOfficeName"
                name="enforcementOfficeName"
                size={32}
            />
            <FormikErrors name="enforcementOfficeName" />
        </StField>

        <StLabel htmlFor="enforcementOfficeAddress">
            {_trans('foreclosure.form.contact.enforcement_office_address.label')}
        </StLabel>
        <StField>
            <Field
                type="text"
                id="enforcementOfficeAddress"
                name="enforcementOfficeAddress"
                size={32}
            />
            <FormikErrors name="enforcementOfficeAddress" />
        </StField>

        <StLabel htmlFor="enforcementOfficeEmail">
            {_trans('foreclosure.form.contact.enforcement_office_email.label')}
        </StLabel>
        <StField>
            <Field
                type="email"
                id="enforcementOfficeEmail"
                name="enforcementOfficeEmail"
                size={32}
            />
            <FormikErrors name="enforcementOfficeEmail" />
        </StField>
    </fieldset>
);
