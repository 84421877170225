import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { partiesSelectors } from 'shared/Parties/parties';

import UserBlock from 'shared/components/UserBlock';

class Beneficiary extends Component {

    static propTypes = {
        beneficiary: PropTypes.object,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { beneficiary } = this.props;

        return <UserBlock user={beneficiary.toJS()} />;
    }
}

const mapStateToProps = (state) => ({
    beneficiary: partiesSelectors.getBeneficiaryDetails(state),
});

export default connect(mapStateToProps)(Beneficiary);
