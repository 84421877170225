import React, { useState } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { spaceEveryFourth } from 'shared/utils/spaceEveryFourth';

/**
 * Lisää välilyönnin joka 4. merkin jälkeen.
 * Hyvä esimerkiksi syöttäessä pitkiä numero/merkkisarjoja vaikkapa lomakkeelta jossa ne ovat
 * myös ryhmitelty neljän sarjoihin.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const FormikRfReferenceNumber = (props) => {
    const { name } = props;
    const [field,, helpers] = useField(name);
    const { setValue, setTouched } = helpers;
    const [inputValue, setInputValue] = useState(spaceEveryFourth(field.value));

    return (
        <input
            type={props.type}
            onChange={(event) => {
                const groupedValue = spaceEveryFourth(event.target.value);
                setValue(groupedValue);
                setInputValue(groupedValue);
            }}
            onBlur={() => setTouched(true)}
            value={inputValue}
            className="u-text-uppercase"
            {...props}
        />
    );
};

FormikRfReferenceNumber.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
};

FormikRfReferenceNumber.defaultProps = {
    type: 'text',
};

