import { fromJS } from 'immutable';
import api from 'api';

/**
 * CoF modelli
 */
export default {
    state: fromJS({
        certificate: {},
        isEmployerTerminated: false,
    }),
    reducers: {
        setCertificate: (state, payload) => fromJS(payload),
    },
    selectors: {
        getCertificate: (state) => state.get('certificate'),
        isEmployerTerminated: (state) => state.get('isEmployerTerminated', false),
        getCauseOfTermination: (state) => state.getIn(['certificate', 'causeOfTermination']),
        getEmploymentEndingCode: (state) => state.getIn(['certificate', 'employmentEndingCode']),
    },
    effects: () => ({
        /**
         * Haetaan sopparin työtodistus
         *
         * @param contractDataId
         * @returns {Promise<void>}
         */
        async fetchCertificateOfEmployment(contractDataId) {
            try {
                const json = await api.get(Routing.generate(
                    'api_1_get_job_contract_data_certificateof_employment',
                    { contractData: contractDataId }
                ));
                this.setCertificate(json);
            } catch (e) {
                console.error(e);
            }
        },
    })
};
