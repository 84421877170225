import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { push } from 'connected-react-router';
import { ActionBar, MDSpinner, Page, SubmitButton } from 'shared/components';
import {
    EmailField, Form,
    FormField,
    MaxTextAreaField,
    StreetAddressAutoCompleteField,
    TextField
} from 'shared/components/Formik';
import { Value } from 'shared/components/Value';
import { setYupLocale } from 'localization/Yup/setYupLocale';
import validators, { errorMessages } from 'shared/validators';
import { isCommune } from 'shared/utils/commonUtils';
import { routes as communeRoutes } from 'Commune/Settings/routes';
import companyRoutes from 'Company/Settings/routes';

const routes = isCommune() ? communeRoutes : companyRoutes;


setYupLocale();
export const ContactForm = () => {
    const dispatch = useDispatch();
    const { dimensionId, contactId } = useParams();
    const dimensions = useSelector(select.contactDimensions.getContractContactDimensions);
    const typeName = useSelector(select.contactDimensions.getContractContactDimensionTypeName);
    const isNew = contactId === 'new';
    const contacts = useSelector(select.contractContactDimensions.getContacts);
    const isLoadingContacts = useSelector((state) => state.loading.effects.contractContactDimensions.fetchContacts);
    const isPending = useSelector((state) =>
        state.loading.effects.contractContactDimensions.postContacts
        || state.loading.effects.contractContactDimensions.putContact
    );

    useEffect(() => {
        if (contacts.length === 0) {
            dispatch.contractContactDimensions.fetchContacts();
        }
    }, [contacts.length, dispatch.contractContactDimensions]);

    const postContacts = useCallback(async (model) => {
        const json = await dispatch.contractContactDimensions.postContacts(dimensionId, model);
        if (json.status === 'ok') {
            await dispatch.contractContactDimensions.fetchContacts();
            dispatch(push(routes.CONTRACT_DIMENSION_CONTACTS));
        } else {
            // Virhettä puukkaa tms
        }
    }, [dimensionId]);

    const putContact = useCallback(async (model) => {
        const json = await dispatch.contractContactDimensions.putContact(contactId, model);
        if (json.status === 'ok') {
            await dispatch.contractContactDimensions.fetchContacts();
            dispatch(push(routes.CONTRACT_DIMENSION_CONTACTS));
        } else {
            // Virhettä puukkaa tms
        }
    }, [contactId]);


    if (isLoadingContacts) {
        return (
            <MDSpinner wrapped />
        );
    }

    const initialState = isNew ? {} : contacts.find((c) => c.id === parseInt(contactId, 10)) ?? {};

    return (
        <Page
            heading={_trans('Yhteystiedot', {}, 'common')}
            goBack={{
                to: routes.CONTRACT_DIMENSION_CONTACTS,
            }}
        >
            <Formik
                initialValues={initialState}
                onSubmit={(model) => isNew ? postContacts(model) : putContact(model)}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email(),
                    phoneNumber: Yup.string()
                        .max(45, errorMessages.maxLength)
                        .test('isPhoneNumber', errorMessages.isPhoneNumber, validators.isPhoneNumber),
                    streetAddress: Yup.string().max(255),
                    postCode: Yup.string().max(9),
                    town: Yup.string().max(45),
                    payslipText: Yup.string().required().max(500),
                })}
            >
                {({ setFieldValue }) => (
                    <Form>
                        <FormField
                            isContentFormField
                            label={typeName}
                        >
                            <Value>{dimensions.find((d) => d.id === parseInt(dimensionId, 10))?.value}</Value>
                        </FormField>
                        {
                            ! isCommune() && (
                                <Fragment>
                                    <FormField name="email" label={_trans('Sähköpostiosoite', {}, 'common')}>
                                        <EmailField />
                                    </FormField>
                                    <FormField name="phoneNumber" label={_trans('Puhelinnumero', {}, 'common')}>
                                        <TextField />
                                    </FormField>
                                    <FormField name="streetAddress" label={_trans('Osoite', {}, 'common')}>
                                        <StreetAddressAutoCompleteField
                                            onSelect={({ postCode, city }) => {
                                                setFieldValue('postCode', postCode);
                                                setFieldValue('town', city);
                                            }}
                                        />
                                    </FormField>
                                    <FormField name="postCode" label={_trans('Postinumero', {}, 'common')}>
                                        <TextField />
                                    </FormField>
                                    <FormField name="town" label={_trans('Postitoimipaikka', {}, 'common')}>
                                        <TextField />
                                    </FormField>
                                </Fragment>
                            )
                        }
                        <FormField name="payslipText" label={_trans('Laskelman lisäteksti', {}, 'common')}>
                            <MaxTextAreaField maxLength={500} cols={50} rows={6} />
                        </FormField>
                        <ActionBar>
                            <SubmitButton isPending={isPending}>
                                {isNew ? _trans('button.save') : _trans('button.save_changes')}
                            </SubmitButton>
                        </ActionBar>
                    </Form>
                )}
            </Formik>
        </Page>
    );
};
