import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { Button, Placeholder } from 'shared/components';
import { Value } from 'shared/components/Value';
import { resolveSalaryPostFix } from 'ContractV3/utils/salaries/resolveSalaryPostFix';
import FormikErrors from 'shared/components/Formik/FormikErrors';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';
import { FormField } from 'shared/components/Formik';
import { getBlockProps } from 'shared/ReForm/utils/getBlockProps';

/**
 * Vähimmäispalkka (HA-puoli).
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const MinimumRequiredTableSalary = (props) => {
    const { name, arrayName } = props;
    
    const [field] = useField(name);
    const minimumRequiredTableSalary = field.value ?? 0;
    const [,,initialSalaryHelpers] = useField(resolveArrayName(arrayName, fieldNames.INITIAL_SALARY));

    const isCommune = useSelector(select.userMetadata.isCommune);
    const isFetchingTableSalary = useSelector((state) => state.loading.effects.tableSalary.fetchTableSalary);
    const hasMinimumTableSalary = useSelector(select.tableSalary.isMinimumTableSalaries);
    const hasSalaryRelatedComponentsVisible = useSelector(select.contract.hasSalaryRelatedComponentsVisible);

    const [salaryTypeField] = useField(fieldNames.SALARY_TYPE);
    const salaryType = salaryTypeField.value;

    if ((!hasMinimumTableSalary && !isFetchingTableSalary) || ! isCommune || ! hasSalaryRelatedComponentsVisible) return null;

    return (
        <FormField {...getBlockProps(props)}>
            <Placeholder isPending={isFetchingTableSalary}>
                <Value ariaDescribedBy={name}>
                    {resolveSalaryPostFix(salaryType, _currency(minimumRequiredTableSalary))}
                </Value>
                <Button
                    onClick={() => initialSalaryHelpers.setValue(minimumRequiredTableSalary)}
                    modifierClass="u-margin-left-small"
                >
                    {_trans('Käytä vähimmäispalkkaa', {}, 'jobContract')}
                </Button>
                <FormikErrors name={name} />
            </Placeholder>
        </FormField>
    );
};
MinimumRequiredTableSalary.propTypes = {
    name: PropTypes.string.isRequired,
    arrayName: PropTypes.string.isRequired,
};
export default MinimumRequiredTableSalary;
