import PropTypes from 'prop-types';

export const earnedIncomeShape = PropTypes.shape({
    name: PropTypes.string,
    unitCount: PropTypes.number,
    calculationOverrideRefData: PropTypes.shape({
        original_unit_price: PropTypes.number,
        original_total: PropTypes.number,
    }),
});
