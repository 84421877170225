import React, { Fragment ,useEffect } from 'react';
import PropTypes from 'prop-types';
import { select } from '@rematch/select';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
    RANGE_DATE_RANGE,
    RANGE_HOURS_PER_WEEK, SUMMARY,
    WAGES_CERT, WAGES_CERT_EXCEL, WAGES_CERT_PDF,
    WEEKLY_HOURS,
    WEEKLY_HOURS_EXCEL,
    WEEKLY_HOURS_PDF
} from './constants';
import { ContractsForm } from './containers/ContractsForm';
import { RangeContainer } from './containers/RangeContainer';
import { Feedback, Page } from 'shared/components';
import validators, { errorMessages } from 'shared/validators';
import { ActionBarContainer } from 'shared/UserDetails/containers/shared/WeeklyHoursStatement/containers/ActionBarContainer';
import { actions as notificationActions } from 'shared/stores/notifications';

export const WeeklyHoursStatement = hot(({ userId }) => {
    const dispatch = useDispatch();
    const isCompany = useSelector(select.userMetadata.isCompany);
    const companyShadowUserId = useSelector(select.userMetadata.getCompanyShadowUserId);
    const selectedContracts = useSelector(select.weeklyHoursStatement.getSelectedContracts);

    useEffect(() => {
        dispatch.weeklyHoursStatement.fetchContracts({
            employee: userId,
            employer: isCompany ? companyShadowUserId : null,
        });
        dispatch.weeklyHoursStatement.resetWeeklyHoursStatement();
    }, [dispatch, userId, isCompany, companyShadowUserId]);

    return (
        <Page heading={_trans('Viikkotuntiselvitys', {}, 'extract')}>
            <Feedback type="info">
                {_trans(`Viikkotuntiselvitys on koekäytössä eikä se toimi sopimuksilla, joilla on ollut kuukausipalkka käytössä
                raporttiin valitulla aikavälillä. Mikäli raportissa ilmenee mielestäsi virheellisyyksiä, ilmoitathan tästä
                tukipyynnöllä tai chatin kautta.`, {}, 'extract')}
            </Feedback>
            <Formik
                initialValues={{
                    contract: undefined,
                    rangeType: undefined,
                    dateRange: {
                        start: undefined,
                        end: undefined
                    },
                    hoursPerWeek: undefined,
                    dateTo: undefined
                }}
                validationSchema={Yup.object().shape({
                    contract: Yup.string(),
                    rangeType: Yup.string().required(_trans('validation.required')),
                    dateRange: Yup.object()
                        .when('rangeType', {
                            is: (val) => val === RANGE_DATE_RANGE,
                            then: Yup.object().required(_trans('validation.required'))
                                .test('dateRange', errorMessages.isDateRange,
                                    (value) => validators.isDateRange({
                                        start: value.start,
                                        end: value.end
                                    }))
                        }),
                    hoursPerWeek: Yup.string().integer()
                        .when('rangeType', {
                            is: RANGE_HOURS_PER_WEEK,
                            then: Yup.string().required(_trans('validation.required'))
                        })
                        .test('isInteger', errorMessages.isInteger, validators.isInteger),
                    dateTo: Yup.string()
                        .when('rangeType', {
                            is: RANGE_HOURS_PER_WEEK,
                            then: Yup.string().required(_trans('validation.required'))
                        })
                        .test('date', errorMessages.isDate, (value) => validators.isDate(value))
                })}
                onSubmit={(model) => {
                    const { submitType, rangeType, dateRange, hoursPerWeek, dateTo } = model;
                    let values;

                    dispatch.weeklyHoursStatement.setRangeType(rangeType);

                    if (rangeType === RANGE_DATE_RANGE) {
                        values = {
                            contracts: selectedContracts,
                            startDate: dateRange.start,
                            endDate: dateRange.end,
                        };
                    } else {
                        values = {
                            contracts: selectedContracts,
                            hoursPerWeek,
                            dateTo
                        };
                        dispatch.weeklyHoursStatement.setHoursPerWeek(hoursPerWeek);
                    }

                    switch (submitType) {
                        case SUMMARY:
                            dispatch.weeklyHoursStatement.fetchSummary(values);
                            break;
                        case WEEKLY_HOURS_PDF:
                            window.open(Routing.generate('get_weekly_salaries_report', values, '_blank'));
                            break;
                        case WEEKLY_HOURS_EXCEL:
                        case WAGES_CERT_PDF:
                        case WAGES_CERT_EXCEL:
                            dispatch(notificationActions.addNotification({
                                type: 'error',
                                message: _trans('Toiminto ei ole vielä käytössä', {}, 'extract'),
                            }));
                            break;
                    }
                }}
            >
                {({ values, handleSubmit, setFieldValue }) => (
                    <Form alignFields="responsive">
                        <ContractsForm />
                        {selectedContracts.length
                            ? <RangeContainer
                                handleSubmit={handleSubmit}
                                setFieldValue={setFieldValue}
                                values={values} />
                            : null}
                        {selectedContracts.length > 0
                            ?
                            <Fragment>
                                <ActionBarContainer
                                    type={WEEKLY_HOURS}
                                    heading={_trans('Viikkotuntiselvitys', {}, 'extract')}
                                    handleSubmit={handleSubmit}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                />
                                {/* Tätä ei vielä näkyville */}
                                {false && <ActionBarContainer
                                    type={WAGES_CERT}
                                    heading={_trans('Palkkatodistus', {}, 'extract')}
                                    handleSubmit={handleSubmit}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                />}
                            </Fragment>
                            : null}
                    </Form>
                )}
            </Formik>
        </Page>
    );
});

WeeklyHoursStatement.propTypes = {
    userId: PropTypes.number.isRequired
};
