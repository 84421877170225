import React, { Fragment } from 'react';
import { StField, StLabel } from 'shared/components/index';
import { Field } from 'formik';
import { FormikErrors } from 'shared/components/Formik/index';

const FirstName = () => (
    <Fragment>
        <StLabel>
            {_trans('userForm.lastName.label')}
        </StLabel>
        <StField>
            <Field type="text" name="lastName" className="u-1/1" />
            <FormikErrors name="lastName"/>
        </StField>
    </Fragment>
);

export default FirstName;
