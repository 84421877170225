import api from 'api';
import { types } from 'shared/constants/contract';
import { sortBy } from 'shared/utils/sortUtils';

/**
 * Työtehtävät.
 * TODO: Kakuta.
 */
export default {
    state: [],

    reducers: {
        setJobTitles: (state, payload) => payload,
    },

    selectors: {
        getJobTitles: (state) => state,
        getJobTitleById: (state, id) => state.find((jobTitle) => jobTitle.value === id),
    },

    effects: {
        async fetchJobTitles(args) {
            try {
                const type = args?.type ?? types.JOB_CONTRACT;
                const json = await api.get(`/api/contracts/v1/job_titles?type=${type}`);
                const jobTitles = json.data.map((jobTitle) => ({
                    value: jobTitle.id.toString(),
                    label: jobTitle.subtitle,
                    name: jobTitle.name,
                }));
                this.setJobTitles(sortBy(jobTitles, 'label'));
            } catch (e) {
                console.error(e);
            }
        }
    }
};

export const loadingSelectors = {
    isLoadingJobtitles: (state) => _.get(state, `loading.effects.jobTitles.fetchAccidentInsurance`, false),
};
