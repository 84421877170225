import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';


/**
 * Kuuntelee Formikin kenttien muutoksia.
 * Käyttö esim.
 *
 * <OnFieldChange
 *     fields={[
 *         'unitCount',
 *         'unitPrice',
 *     ]}
 *     onChange={({ unitCount, unitPrice }) => {
 *         const total = unitCount * unitPrice;
 *         ...
 *    }}
 * />
  * @type {*}
 */
const OnFieldChange = React.memo(({ onChange, fields }) => {
    const formik = useFormikContext();
    React.useEffect(() => {
        const values = fields.reduce((acc, fieldName) => {
            acc[fieldName] = formik.getFieldMeta(fieldName).value;
            return acc;
        }, {});
        onChange(values);
    }, [fields, formik, onChange]);
    return null;
});

OnFieldChange.propTypes = {
    onChange: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired,
};

export default OnFieldChange;
