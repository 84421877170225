import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/Button';

export const CellFilter = ({ column, filter, onChange }) => {
    const inputElement = useRef(null);
    return (
        <div className="u-position-relative">
            <input
                type="text"
                style={{
                    width: '100%',
                }}
                ref={inputElement}
                aria-label={column.Header}
                placeholder={column.Placeholder}
                value={filter ? filter.value : ''}
                onChange={(event) => onChange(event.target.value)}
            />
            {filter && filter !== '' && (
                <Button
                    flat
                    mdIcon="clear"
                    modifierClass="o-pin o-pin--right o-pin--top"
                    onClick={() => {
                        onChange('');
                        // Tekstin tyhjennyksen jälkeen takaisin filtterikenttään
                        inputElement.current.focus();
                    }}
                    title={_trans('Tyhjennä kenttä.', {}, 'common')}
                />
            )}
        </div>
    );
};

CellFilter.propTypes = {
    column: PropTypes.shape({
        Header: PropTypes.string.isRequired,
        Placeholder: PropTypes.string.isRequired,
    }).isRequired,
    filter: PropTypes.shape({
        value: PropTypes.string,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
};
