import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Stackin yksittäinen elementti.
 * Voidaan käyttää Stack-komponentin yhteydessä.
 *
 * @param children
 * @param modifierClass
 * @returns {XML}
 * @constructor
 */
function StackItem({children, modifierClass}) {
    const stackItemClass = classNames('o-stack__item', {
        [modifierClass]: modifierClass !== '',
    });

    return (
        <li role="presentation" className={stackItemClass}>
            {children}
        </li>
    );
}

StackItem.defaultProps = {
    modifierClass: '',
};

StackItem.propTypes = {
    children: PropTypes.node,

    /**
     * Muokkaava CSS-luokka.
     */
    modifierClass: PropTypes.string,
};

export default StackItem;