import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { resolveSalaryPostFix } from 'ContractV3/utils/salaries/resolveSalaryPostFix';
import { Accordion, AccordionItem } from 'shared/components/Accordion';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { hiddenFields } from 'ContractV3/constants/hiddenFields';

/**
 * Näyttää palkkiotiedot haitarissa. Oletuksena avoinna on viimeisin lisätty palkkiotieto.
 * @param name
 * @param uid
 * @param arrayHelpers
 * @param blocks
 * @param values
 * @param iterator
 * @returns {JSX.Element}
 * @constructor
 */
const SalaryList = ({ name, uid, arrayHelpers, blocks, values, iterator }) => {
    const [salaryTypeField] = useField(fieldNames.SALARY_TYPE);
    const salaryType = parseInt(salaryTypeField.value, 10);
    const salaryTypeDimension = useSelector(select.dimensions.findSalaryTypeDimension);
    const dimensions = salaryTypeDimension.dimensions ?? [];
    const [salaryField] = useField(name);
    const salaries = salaryField.value;

    const hasCostReimbursementTableSalaries = useSelector(select.contract.hasCostReimbursementTableSalaries);
    const hasCostReimbursementSpecialAllowanceTableSalaries = useSelector(select.contract.hasCostReimbursementSpecialAllowanceTableSalaries);

    return (
        <div className="u-margin-bottom">
            <Accordion>
                {values[name].map((value, valueIndex) => {
                    const dimension = dimensions.find((dimension) => parseInt(dimension?.id, 10) === parseInt(value?.dimension, 10));

                    const isTableSalaryInUse = Array.isArray(salaries)
                        ? salaries[valueIndex][fieldNames.IS_TABLE_SALARY_IN_USE] ?? false
                        : false;
                    const initialSalary = parseInt(salaries[valueIndex][fieldNames.INITIAL_SALARY], 10);
                    const isInitialSalarySet = isTableSalaryInUse ?
                        !isNaN(initialSalary) && initialSalary > 0
                        : !isNaN(initialSalary) && salaries[valueIndex][fieldNames.INITIAL_SALARY] !== '';

                    const costReimbursement = parseInt(salaries[valueIndex][fieldNames.COST_REIMBURSEMENT], 10);
                    const isCostReimbursementSet = isTableSalaryInUse && hasCostReimbursementTableSalaries
                        ? !isNaN(costReimbursement) && costReimbursement >= 0
                        : true;

                    const costReimbursementSpecialAllowanceLabel = salaries[valueIndex][hiddenFields.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE_LABEL] !== '';
                    const isCostReimbursementSpecialAllowanceLabelSet = isTableSalaryInUse && hasCostReimbursementSpecialAllowanceTableSalaries
                        ? costReimbursementSpecialAllowanceLabel
                        : true;

                    const isAllSet = isTableSalaryInUse
                        ? isInitialSalarySet && isCostReimbursementSet && isCostReimbursementSpecialAllowanceLabelSet
                        : isInitialSalarySet;

                    const title = isAllSet
                        ? `${dimension?.value ?? '-'} - ${resolveSalaryPostFix(salaryType, _currency(value?.initialSalary))}`
                        : (isTableSalaryInUse
                            ? _trans('Valitse kaikki palkkiotiedot', {}, 'jobContract')
                            : _trans('Anna kaikki palkkiotiedot', {}, 'jobContract'));

                    const isSalaryDimensionSet = dimension?.value;

                    return (
                        <AccordionItem
                            key={valueIndex}
                            customTitle={(
                                <div className={classNames({
                                    // Ei kaikkia palkkiotietoja valittuna tai dimensiovalinta puuttuu
                                    'u-muted': ! isAllSet || ! dimension?.value,
                                })}>
                                    {isSalaryDimensionSet
                                        ? title
                                        : _trans('Valitse %dimensionType%', {
                                            dimensionType: salaryTypeDimension?.dimensions[0].type?.toString().toLowerCase() ?? _trans('Palkkiodimensio', {}, 'jobContract')
                                        }, 'jobContract')}
                                </div>
                            )}
                            uuid={valueIndex.toString()}
                        >
                            {iterator(
                                blocks,
                                `${uid}-${valueIndex}`,
                                {
                                    arrayName: `${name}.${valueIndex}`,
                                    rootName: name,
                                    index: valueIndex,
                                    arrayHelpers,
                                    blocks,
                                })}
                        </AccordionItem>
                    );
                })}
            </Accordion>
        </div>
    );
};

SalaryList.propTypes = {
    name: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    arrayHelpers: PropTypes.object.isRequired,
    blocks: PropTypes.object.isRequired,
    values: PropTypes.array.isRequired,
    iterator: PropTypes.func.isRequired,
};

export default SalaryList;
