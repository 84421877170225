import { useEffect, useRef } from 'react';

// Edellinen arvon renderissä.
export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
