import React, { useMemo } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { payDayModes } from 'shared/constants/payDayModes';
import { SelectField } from 'shared/components/Formik';
import { range } from 'shared/utils/collectionHelpers';
import { Value } from 'shared/components/Value';

/**
 * Palkkapäivä.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const Payday = (props) => {
    const { name, label, isValueFixed } = props;
    const [field] = useField(name);
    const payday = parseInt(field.value, 10);
    const [paydayModeField] = useField(fieldNames.PAY_DAY_MODE);

    const options = useMemo(() => {
        // Arkipäivää jakson jälkeen -valinta, sallitaan 5-15
        if (parseInt(paydayModeField.value, 10) === payDayModes.PAYDAY_NTH_WEEK_DAY) {
            return range(5, 16).map((_, idx) => ({
                value: idx + 1,
                label: `${idx + 1}. ${_trans('week_day')}`,
            }));
        }

        return range(1, 29).map((_, idx) => ({
            value: idx + 1,
            label: `${idx + 1}. ${_trans('day')}`,
        }));
    }, [paydayModeField.value]);

    if (isValueFixed && ! isNaN(payday)) {
        return (
            <Value ariaDescribedBy={label}>
                {options[payday - 1].label}
            </Value>
        );
    }

    return (
        <SelectField
            name={name}
            options={options}
            className="u-1/1"
            placeholder={_trans('contract.form.salary.pay_day_dropdown_placeholder')}
        />
    );
};

Payday.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isValueFixed: PropTypes.bool.isRequired,
};

export default Payday;
