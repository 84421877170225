import { inputTypes } from 'shared/ReForm/constants/inputTypes';
import { customComponents } from 'ContractV3/constants/customComponents';
//import { resolveValidationTypeByBlock } from 'shared/ReForm/utils/resolveValidationTypeByBlock';

export const resolveDefaultValue = (block) => {
    const { type, component } = block;
    const customBlock = customComponents[component] ?? {};
    const resolvedType = customBlock.type || type;
    //const validationType = resolveValidationTypeByBlock(block);

    switch (resolvedType) {
        // Jos pvm ei ole vaadittu on sen oletusarvon oltava undefined jotta Yup päästää läpi
        case inputTypes.DATE:
            return undefined;
        case inputTypes.SWITCH:
            return false;
        case inputTypes.CHECKBOX:
            return [];
        default: {
            // Ehkä parempi ottaa pois... ei haluta oletusarvoa (nolla) osa-aikaprosenteille tai palkoille...
            /*if (validationType === 'number' && ! block.hasOwnProperty('defaultValue')) {
                console.log(block, validationType);
                return 0;
            }*/

            return block.defaultValue ?? '';
        }
    }
};
