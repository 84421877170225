import React from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';
import { StLabel, StField, StErrors } from 'shared/components/StForm';
import { validators } from 'shared/validators';
import { StHelp } from 'shared/components/index';

/**
 * Käyttäjän sähköpostiosoite. Jos kyseessä on Y-puolen käyttäjä on kenttä pakollinen.
 * Työntekijän ollessa kyseessä kerrotaan että annettuun osoitteeseen menee palkkakuitti.
 */
const Email = ({
    model,
    isRequired,
    isPrivate,
    isEmployee,
    isCompensationEarner,
    ...rest
}) => (
    <div>
        <StLabel htmlFor="email" isRequired={isRequired} tooltip={isPrivate ? _trans('userForm.email.tooltip') : null}>
            { _trans('userForm.email.label') }
        </StLabel>
        <StField>
            <Control.text
                {...rest}
                id="email"
                aria-describedby="emailHelp"
                model={model}
                size={40}
                maxLength={255}
                validators={{
                    isRequired: (value) => isRequired ? validators.isRequired(value) : true,
                    isEmail: validators.isEmail,
                    noUmlauts: validators.noUmlauts,
                }}
            />
            <StErrors model={model} />
            { isEmployee && (
                <StHelp id="emailHelp">
                    {
                        isCompensationEarner
                            ?_trans('Palkkionsaajan palkkiolaskelma lähetetään tähän osoitteeseen.', {}, 'extract')
                            : _trans('userForm.employeeEmail.help')
                    }
                </StHelp>
            )}
        </StField>
    </div>
);

Email.defaultProps = {
    model: '.email',
    isRequired: false,
    isPrivate: false,
    isEmployee: false,
    isCompensationEarner: false
};

Email.propTypes = {
    model: PropTypes.string,
    isRequired: PropTypes.bool,
    isPrivate: PropTypes.bool,
    isEmployee: PropTypes.bool,
    isCompensationEarner: PropTypes.bool,
};

export default Email;
