import React, { Fragment } from 'react';
import { StField, StLabel } from 'shared/components/index';
import { Field } from 'formik';
import { FormikErrors } from 'shared/components/Formik/index';

const StreetAddress = () => (
    <Fragment>
        <StLabel>
            {_trans('userForm.streetAddress.label')}
        </StLabel>
        <StField>
            <Field type="text" name="streetAddress" className="u-1/1" />
            <FormikErrors name="streetAddress"/>
        </StField>
    </Fragment>
);

export default StreetAddress;
