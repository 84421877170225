/**
 * Alert types
 * @type {{SUCCESS: string, INFO: string, WARNING: string, ERROR: string, TIP: string}}
 */
const alertTypes = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
    TIP: 'tip',
};

export default alertTypes;
