import React, { useEffect } from 'react';
import { init } from '@rematch/core';
import selectPlugin from '@rematch/select';
import createLoadingPlugin from '@rematch/loading';
import { Provider } from 'react-redux';
import surcharges from './models/surcharges';
import { SurchargeList } from './containers/SurchargeList';
import { SurchargeDialog } from './containers/SurchargeDialog';
import { communeSettings } from 'shared/models/communeSettings';
import dimensions from 'shared/models/dimensions';
import notifications from 'shared/models/notifications';
import Notifications from 'shared/containers/Notifications';
import Page from 'shared/components/Page';
import Button from 'shared/components/Button';
import { TabContainer } from 'shared/components';
import { SurchargeConflicts } from 'shared/Admin/Surcharges/containers/SurchargeConflicts';
import { OpenOfflinePaymentFeedback } from 'shared/Admin/Surcharges/containers/OpenOfflinePaymentFeedback';
import { HandledSurchargeList } from 'shared/Admin/Surcharges/containers/HandledSurchargeList';
import { isCommune } from 'shared/utils/commonUtils';

const store = init({
    name: 'Surcharges',
    models: {
        communeSettings,
        dimensions,
        notifications,
        surcharges,
    },
    plugins: [
        selectPlugin(),
        createLoadingPlugin(),
    ]
});

const SurchargesContainer = () => {
    useEffect(() => {
        store.dispatch.surcharges.fetchSurchargePricingList();
        store.dispatch.surcharges.fetchSurcharges();
        store.dispatch.surcharges.fetchHandledInternalSurcharges();
        store.dispatch.surcharges.fetchHandledExternalSurcharges();
        store.dispatch.dimensions.fetchDimensionTypes({ restrictDimensionVisibility: 0 });
        if (isCommune()) {
            store.dispatch.communeSettings.fetchCommuneSettings();
        }
    }, []);

    return (
        <Provider store={store}>
            <Page
                heading="Asiakkaan lisämaksut"
                actionItems={(
                    <Button primary onClick={() => {
                        store.dispatch.surcharges.setIsDialogOpen(true);
                        store.dispatch.surcharges.setEditableSurcharge(null);
                    }}>
                        Uusi lisämaksu
                    </Button>
                )}
            >
                {/* Lisäsysdialogi ja notifikaatiot */}
                <SurchargeDialog />
                <Notifications />

                {/* Ohjetekstit */}
                <SurchargeConflicts />
                <OpenOfflinePaymentFeedback />

                {/* Tabi */}
                <TabContainer
                    isUsingHash
                    initialTabId="open"
                    tabs={{
                        open: {
                            label: 'Avoimet',
                            content: <SurchargeList />,
                        },
                        confirmed: {
                            label: 'Vahvistetut',
                            content: <HandledSurchargeList />,
                        }
                    }}
                />
            </Page>
        </Provider>
    );
};

app.render(SurchargesContainer);

export default SurchargesContainer;

