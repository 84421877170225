import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Value } from 'shared/components/Value';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { parseCustomTranslations } from 'shared/ReForm/utils/parseCustomTranslations';

/**
 * TES-palikka jota muut palikat (SalaryType, PayPeriodLength, PaydayMode ym.) käyttävät.
 * @param value
 * @param customTranslations
 * @param translator
 * @param fieldProps
 * @returns {JSX.Element|null}
 * @constructor
 */
export const CollectiveAgreementValue = ({ value, customTranslations, translator, fieldProps }) => {
    // Onko OPH
    const isAssignmentContract = useSelector(select.contract.isAssignmentContract);
    const { label } = fieldProps;

    const translationDomain = isAssignmentContract ? 'assignment_contract' : 'contract';
    // Vedetään kustomkäännökset listasta objekteja => objektiksi jolloin arvo saadaan suoraan keyllä
    const options = useMemo(() => parseCustomTranslations(customTranslations), [customTranslations]);
    const hasCustomTranslator = Array.isArray(customTranslations) && customTranslations.length > 0;
    const customTranslator = (type) => type in options ? options[type] : '-';

    return (
        <SummaryListItem term={label}>
            <Value>{hasCustomTranslator ? customTranslator(value, translationDomain) : translator(value, translationDomain)}</Value>
        </SummaryListItem>
    );
};

CollectiveAgreementValue.propTypes = {
    value: PropTypes.string.isRequired,
    /**
     * Omat kustomoidut käännökset.
     */
    customTranslations: PropTypes.array,
    /**
     * Minkä kääntäjän läpi valinnat runtataan.
     */
    translator: PropTypes.func.isRequired,
    /**
     * Kentälle menevät propit
     */
    fieldProps: PropTypes.object.isRequired,
};

CollectiveAgreementValue.defaultProps = {
    customTranslations: [],
};
