import * as PropTypes from 'prop-types';

const userStateOfAffairs = PropTypes.shape({
    accidentInsurance: PropTypes.shape({
        renewalCount: PropTypes.number,
    }),
    canCreateContracts: PropTypes.bool,
    identification: PropTypes.shape({
        isIdentifierExpired: PropTypes.bool,
    }),
    isEmailConfirmed: PropTypes.bool,
    steps: PropTypes.shape({
        hasYelEmployees: PropTypes.bool,
        isEverythingComplete: PropTypes.bool,
        isIdentificationComplete: PropTypes.bool,
        isInsurancesComplete: PropTypes.bool,
        isUserDataComplete: PropTypes.bool,
    }),
    userPhotoSource: PropTypes.string,
    userRole: PropTypes.number,
});

export default userStateOfAffairs;
