import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import Button from './Button';
import { sizes } from 'shared/constants.js';
import Spinner from 'shared/components/Spinner';

const SubmitButton = ({ mdIcon, disabled, primary, isPending, children, ...rest }) => (
    <Button {...rest} mdIcon={mdIcon} disabled={disabled || isPending} primary={primary}>
        {children}
        { isPending && <span className="u-margin-left-tiny"><Spinner size="small" primary={primary} /></span> }
    </Button>
);

SubmitButton.defaultProps = {
    primary: true,
    width: '',
    size: '',
    disabled: false,
    isPending: false,
    type: 'submit',
    mdIcon: null,
};

SubmitButton.propTypes = {
    // eslint-disable-next-line react/boolean-prop-naming
    primary: PropTypes.bool,
    children: PropTypes.node.isRequired,
    width: PropTypes.string,
    size: PropTypes.oneOf([..._map(sizes), '']),
    // eslint-disable-next-line react/boolean-prop-naming
    disabled: PropTypes.bool,
    isPending: PropTypes.bool,
    mdIcon: PropTypes.string,
};

export default SubmitButton;
