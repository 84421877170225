import * as Yup from 'yup';
import { flattenBlocks } from 'shared/ReForm/utils/flattenBlocks';
import { ANY_VALUE, DATE_VALUE, NUMBER_VALUE, ZERO_VALUE } from 'shared/ReForm/constants/magicValues';
import { isValidDate } from 'shared/utils/dateUtils';
import validators, { errorMessages } from 'shared/validators';
import { inputTypes } from 'shared/ReForm/constants/inputTypes';

/**
 * Validointi arvon tyypin perusteella.
 * @param value
 * @returns {*|boolean}
 */
const testValueByType = (value) => {
    switch (value) {
        case ANY_VALUE:
            return (value) => value && value?.toString().length > 0;

        case NUMBER_VALUE:
            return (value) => !isNaN(parseInt(value, 10));

        case ZERO_VALUE:
            return (value) => {
                const castValue = parseInt(value, 10);
                return !isNaN(castValue) && castValue === 0;
            };

        case DATE_VALUE:
            return (value) => isValidDate(new Date(value));

        default:
            return value;
    }
};

/**
 * Parsii dependencesit testaten arvoja niiden oletettua tyyppiä vasten (ANY_VALUE, DATE_VALUE, NUMBER_VALUE...).
 * Esim:
 * dependencies: {
 *     'contractType': ANY_VALUE,
 *     'salaryType': NUMBER_VALUE,
 *     'startDate': DATE_VALUE
 * }
 *
 * @param dependencies
 * @returns {T}
 */
const parseDependencies = (dependencies) => (
    Object.entries(dependencies ?? {})
        .reduce((acc, [name, value]) => ({
            ...acc,
            [name]: testValueByType(value),
        }), {})
);

/**
 * Kokonaisluku ja desimaali vaatii hienosäätöä validaatioihinsa.
 * Stringeinähän nämä kaikki menee pohjimmiltaan (koska Formik).
 * @param type
 * @param validations
 * @returns {string[]|*[]}
 */
const resolveValidationsByType = ({ type, validations = [] }) => {
    if (type === inputTypes.INTEGER) {
        return ['integer'].concat(validations);
    }
    if ([inputTypes.DECIMAL, inputTypes.CURRENCY].includes(type)) {
        return ['decimal'].concat(validations);
    }
    return validations;
};

/**
 * Muodostaa Yup-yhteensopivan validaatioscheman.
 * @param json
 * @param fixedValidationSchemas - Schemat jotka toimivat pellin alla. Esim. päivämääräkentät on määriteltävä päivämääriksi tai
 * Yup ei tiedä niiden rakenteesta mitään.
 * @returns {*}
 */
export const generateValidationSchema = (json, fixedValidationSchemas) => {
    const getValidationTypeAndParams = (validation) => {
        // Validaatio pelkkä string tai numero eli ei parametreja. Esim. Yup.required() ilman virhetekstiä.
        if (typeof validation === 'string' || typeof validation === 'number') {
            return [validation, []];
        }

        if (Object.keys(validation).length === 0) return [];
        // Parametrit annettu typen ohella [type, params]
        return Object.entries(validation)[0];
    };

    const parseValidation = (field, name) => {
        // Oletuksena validoinnin lähde aina string
        const { validationType, /*dynamicValidation,*/ dependencies } = field;
        const validations = resolveValidationsByType(field);

        // Validaatiotyyppiä (string, date, number... jne) ei löydy. Vois kait heittää voltinkin tässä jo.
        if (! Yup[validationType]) {
            return false;
        }

        // Jos kentällä on riippuvuuksia muista kentistä mutta ei validaatioita
        // voidaan olettaa että tässä riittää validoinniksi pakollisuuden tarkistus
        // jos kenttien riippuvuustarkistukset täyttyvät.
        const hasOnlyDependencies = (dependencies && Object.keys(dependencies).length > 0) && validations.length === 0;

        return (hasOnlyDependencies
            // Jos ei validaatioita käytetään dependencejä vain kentän pakollisuuden validointiin
            ? [{
                when: {
                    is: parseDependencies(dependencies),
                    then: [ 'required' ]
                }
            }]
            : validations)
            .reduce((acc, validation) => {
                const [type, params] = getValidationTypeAndParams(validation);

                /*if (dynamicValidation) {
                     console.log(field, name);
                }*/

                // Validaatiotyyppiä (required, date, min, max.. jne) ei löydy
                if (!acc[type]) return acc;

                // Erikoiscase jossa vertaillaan tiettyjen kenttien arvojen täyttymistä.
                if (type === 'when') {
                    const conditions = Object.entries(params.is);
                    // Kentät jota vastaan testataan
                    const fields = conditions.map((condition) => condition[0]);
                    return acc[type](fields, {
                        // Kaikki ehdot täytyttävä
                        // TODO: Useampi is. Nyt olettaa että annetaan vain yksi is per testi
                        is: (...args) => conditions.every(([, value], index) => {
                            // Jos arvo on funktio, palauta sen paluuarvo (kts. ylhäällä parseDependencies)
                            if (typeof value === 'function') {
                                return value(args[index]);
                            }

                            // Jos arvo on taulukko tee vertailu siihen
                            if (Array.isArray(value)) {
                                return value.includes(args[index]);
                            }

                            // Tutkitaan vertailtavan arvon sisällön perusteella halutaanko suora arvovertailu
                            // vaiko palautuuko funktio joka tekee vertailun.
                            const tester = testValueByType(value);

                            // Halutaan suora vertailu koska palautui sama arvo
                            if (tester === value) {
                                return args[index] === value;
                            }

                            return tester(args[index]);
                        }),
                        // then:ssä uudet validaatiot jotka täytyttävä jos is-ehdot täyttyvät
                        then: parseValidation({
                            validations: params.then,
                            // Validaatiotyyppi on 99% tapauksista aina sama kuin alkuperäinen vertailtava kohde
                            validationType
                        }, name),
                        ...(params.otherwise && { otherwise: parseValidation({
                            validations: params.otherwise,
                            validationType
                        }, name) })
                    });
                }

                // Test. Validoi yhtä tai useampaa omaa testimetodia vasten (löydyttävä validators.js:stä ja errorMessages:sta).
                // Voidaan antaa myös lisäparametrit taulukkomuodossa tyyliin { testiMetodi: [param1, param2] }
                if (type === 'test') {
                    if (! Array.isArray(params)) {
                        return acc;
                    }

                    // Ajetaan kaikki taulukossa olevat testit läpi ja lisätään osaksi muuta validointiketjua.
                    return params
                        .reduce((tests, param) => {
                            // Parametrit voidaan antaa objektina jossa avain on testimetodin nimi ja parametrit arvoissa.
                            const hasParameters = typeof param === 'object';

                            const testMethodName = hasParameters
                                ? Object.entries(param)[0][0]
                                : param;

                            const testMethodParams = hasParameters
                                ? Object.entries(param)[0][1]
                                : [];

                            // Metodia ei löydy. Devausvaiheessa tämä pitäis huomata. Ei silti rikota tuotantoa.
                            if (!( testMethodName in errorMessages) || !( testMethodName in validators)) {
                                console.error('Test method not found.');
                                return acc;
                            }

                            const errorMessage = typeof errorMessages[testMethodName] === 'function'
                                ? errorMessages[testMethodName](...testMethodParams)
                                : errorMessages[testMethodName];

                            return (
                                tests[type](testMethodName, errorMessage, (value) => validators[testMethodName](value, ...testMethodParams))
                            );
                        }, acc); // <<< Huom: immutable koska aloitusarvot aiemman iteraation jäljiltä
                }

                // Esim. number-typessä numeron on voitava olla tyhjä
                if (type === 'nullable') {
                    return acc[type](validation);
                }

                // Min & max validointi
                if (['date', 'number'].includes(validationType) && ['min', 'max'].includes(type)) {
                    if (validationType === 'number') {
                        return typeof params === 'string'
                            ? acc[type](Yup.ref(params))
                            : acc[type](params);
                    }
                    const date = new Date(params);

                    // Onko annettu paramtri päivämäärä...?
                    return isValidDate(date)
                        // ... on, vertaillaan päivämäärään suoraan
                        ? acc[type](params)
                        // ... jos ei, oletetaan että parametri on toinen kenttä jota vastaan halutaan vertailla
                        : acc[type](Yup.ref(params));
                }

                /***
                 * TODO: Virheiden käännökset per kieli
                 * validationErrors objekti? Jos löytyy sama validaatio"key" hae siitä virheviesti
                 */

                // Tässä seuraava arvo ajetaan nykyisen Yup-validaattorin läpi => chaining, esim. Yup().string().required()
                return acc[type](...params);
            }, Yup[validationType]());
    };
    const validations = flattenBlocks(json);
    const getValidation = (field, name) => {
        // Ei validaatioita eikä riippuvuuksia muista kentistä. Turha rullata läpi.
        if (! field.validations
            && ! field.dependencies
            // Mennään joka tapauksessa parsimiseen mikäli sattuu numerotyyppinen palikka,
            // se kun tarvii tarkennuksen validaation ellei erikseen ole annettu
            && ! [inputTypes.INTEGER, inputTypes.DECIMAL].includes(field.type)
        ) return null;

        return parseValidation(field, name);
    };

    // Objektinotaatiolliset haettava näin omiksi objekteikseen.
    const objectSchemas = validations.reduce((acc, cur) => {
        const field = Object.values(cur)[0];
        const name = Object.keys(cur)[0];

        // Objektinotaatio, siispä tehdään objektivalidaatio.
        const match = /^[A-Za-z0-9]+(\.)[A-Za-z0-9_]+$/.test(name);
        if (match) {
            const namePieces = name.split('.');
            const rootName = namePieces[0];

            // TODO: Jotenkin pitäisi ratkaista TES-variaabeleiden castaus ja schema... nyt vedetään kuivana kantaan eikä
            // vedetä schemaan mukaan.
            if (rootName === 'collectiveAgreementVariables') {
                return acc;
            }

            const subName = namePieces[1];
            return {
                ...acc,
                [rootName]: {
                    ...(acc[rootName] && acc[rootName]),
                    [subName]: getValidation(field, name)
                }
            };
        }
        return acc;
    }, {});

    // Muodostetaan validaatioista objekti jossa avain on kentän nimi ja arvo on annetut validaatiot
    // Validaatio voi muodostua myös dependensseistä ellei validaatioita ole annettu. Tällöin
    // dependencies määrittelee kentän pakolliseksi jos ehdot täyttyvät.
    const schemas = validations.reduce((acc, cur) => {
        const field = Object.values(cur)[0];
        const name = Object.keys(cur)[0];
        const validation = getValidation(field, name);

        // Objektinotaatio. Ei oteta matkaan tässä. Tulevat lopuksi yhtenä pompsina ryhmittäin.
        if (/^[A-Za-z0-9]+(\.)[A-Za-z0-9_]+$/.test(name)) {
            return acc;
        }

        // Lista validaatioita joten luodaan validaatiotaulukko: Yup.array().of(...
        if (Array.isArray(field)) {
            const subValidations = field.reduce((acc, cur) => {
                const subField = Object.values(cur)[0];
                const subName = Object.keys(cur)[0];
                const subValidation = getValidation(subField, subName);
                if (! subValidation) {
                    return acc;
                }

                return {
                    ...acc,
                    [subName]: subValidation,
                };
            }, {});

            return {
                ...acc,
                [name]: Yup.array().of(
                    Yup.object().shape(subValidations)
                ),
            };
        }

        if (! validation) {
            return acc;
        }

        return {
            ...acc,
            [name]: validation,
        };
    }, {});

    // Lopuksi lisätään objektinotaatiolliset ryhminä.
    const finalSchemas = Object.entries(objectSchemas)
        .reduce((acc, [name, schemas]) => (
            Object.assign({}, acc, { [name]: Yup.object().shape(schemas) })
        ), schemas);

    return Yup.object().shape(
        Object.assign({}, finalSchemas, fixedValidationSchemas));
};

