import produce from 'immer';
import api from 'api';
import { holidayDayTypes, isConsumableHolidayDay } from 'shared/constants/holidayDayTypes';
import { actions as tesrfActions } from 'shared/TESRestrictedField/TESRestrictedField';

// Filtteröidään nämä ulos frontista
const filteredHolidayDayTypes = [
    holidayDayTypes.DEBT_BALANCED,
    holidayDayTypes.DEBT,
    holidayDayTypes.INVALIDATED,
];

export default {
    state: {
        holidayDays: [],
        holidayYearPayMethods: [],
    },
    reducers: {
        setHolidayDays: (state, payload) => ({ ...state, holidayDays: payload }),
        setHolidayYearPayMethods: (state, payload) => ({ ...state, holidayYearPayMethods: payload }),
        removeHolidayDay: (state, indexOrHolidayDayId, isHolidayDayId = false) => produce(state, (draftState) => {
            const index = isHolidayDayId
                ? draftState.holidayDays.findIndex((holidayDay) => holidayDay.holidayDayId === indexOrHolidayDayId)
                : indexOrHolidayDayId;

            draftState.holidayDays.splice(index, 1);
        }),
    },
    selectors: {
        getHolidayDays: (state) => state.holidayDays ?? [],
        getHolidayDayById: (state, holidayDayId) => {
            const id = parseInt(holidayDayId, 10);
            return state.holidayDays.find((holidayDay) => holidayDay.holidayDayId === id);
        },
        getConsumedHolidayDays: (state) => state.holidayDays
            .filter((holidayDay) => ! isConsumableHolidayDay(holidayDay.type))
            .filter((holidayDay) => ! filteredHolidayDayTypes.includes(holidayDay.type)),
        getEarnedHolidayDays: (state) => state.holidayDays
            .filter((holidayDay) => isConsumableHolidayDay(holidayDay.type))
            .filter((holidayDay) => ! filteredHolidayDayTypes.includes(holidayDay.type)),
        getHolidayYearPayMethods: (state) => state.holidayYearPayMethods,
    },
    effects: (dispatch) => ({
        async fetchHolidayDays(jobContractId, rootState, context = {}) {
            const isAssignmentContract = !! context.isAssignmentContract;
            const resolveSpentHolidayDays = Number(!! context.resolveSpentHolidayDays);

            try {
                const route = isAssignmentContract
                    ? `/api/contracts/v1/contracts/${jobContractId}/assignment-contract-holiday-days?resolveSpentHolidayDays=${resolveSpentHolidayDays}`
                    : `/api/v1/contracts/${jobContractId}/holidaydays?resolveSpentHolidayDays=${resolveSpentHolidayDays}`;

                const json = await api.get(route);
                this.setHolidayDays(json);
            } catch (e) {
                dispatch.notification.addError(_trans('holiday_days.form.messages.fetch_error'));
            }
        },

        async fetchHolidayYearPayMethods(jobContract) {
            try {
                const json = await api.get(Routing.generate('api_1_get_contract_holiday_pay_methods', { jobContract }));
                if (json.status === 'ok') {
                    this.setHolidayYearPayMethods(json.data);
                } else {
                    dispatch.notification.addError(_trans('holiday_days.form.fetch_error'));
                }
            } catch (e) {
                dispatch.notification.addError(_trans('holiday_days.form.fetch_error'));
            }
        },

        async postHolidayYearPayMethod(jobContract, rootState, model) {
            try {
                const json = await api.post(Routing.generate('api_1_post_contract_holiday_pay_method', { jobContract }), model);
                if (json.status === 'ok') {
                    this.setHolidayYearPayMethods(json.data);
                    const contractOngoingHolidayYear = json.data.find((holidayPayMethod) => holidayPayMethod.isContractOngoingHolidayYear);
                    // Jos löytyy nykyisen lomavuoden lomasääntö, päivitetään se storeen
                    if (contractOngoingHolidayYear) {
                        dispatch(tesrfActions.changeHolidayPayMethod(contractOngoingHolidayYear.holidayPayMethod));
                    }
                    dispatch.notifications.addSuccess(_trans('holiday_days.form.messages.save_success'));
                } else {
                    dispatch.notifications.addError(_trans('holiday_days.form.messages.save_error'));
                }
            } catch (e) {
                dispatch.notifications.addError(_trans('holiday_days.form.messages.save_error'));
            }
        },

        async postHolidayDay({ contractId, values }) {
            try {
                return await api.post(`/api/v1/contracts/${contractId}/holidaydays`, values);
            } catch (e) {
                dispatch.notifications.addError('Vapaapäivää ei voitu lisätä.');
            }
        },

        async putHolidayDay({ contractId, holidayDayId, values }) {
            try {
                return await api.put(`/api/v1/contracts/${contractId}/holidaydays/${holidayDayId}`, values);
            } catch (e) {
                dispatch.notifications.addError('Vapaapäivän tietoja ei voitu tallentaa.');
            }
        },

        async deleteHolidayDay({ contractId, holidayDayId }) {
            try {
                const response = await api.del(`/api/v1/contracts/${contractId}/holidaydays/${holidayDayId}`);
                if (response.status === 'ok') {
                    dispatch.notifications.addSuccess('Vapaapäivä poistettu.');
                }
                return response;
            } catch (e) {
                dispatch.notifications.addError('Vapaapäivää ei voitu poistaa.');
            }
        }
    }),
};
