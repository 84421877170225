import React from 'react';
import PropTypes from 'prop-types';
import { Value } from 'shared/components/Value';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { fieldNames } from 'ContractV3/constants/fieldNames';

/**
 * Valittu alkoituspäivä.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const StartDate = (props) => {
    const { value, values, label } = props;
    const originalStartDate = values[fieldNames.ORIGINAL_START_DATE];

    return (
        <SummaryListItem
            // Jos alkup. aloituspäivää ei ole (tai se ja aloituspäivä ovat samat => käytetään eri termiä
            // koska kyseessä uusi sopimus eikä "siirtymistä" Oimaan ole.
            term={originalStartDate
                ? label
                : _trans('Sopimuksen aloituspäivä', {}, 'jobContract')
            }
        >
            <Value>
                {! originalStartDate
                    ? (
                        <span>
                            {_trans('Sama kuin alkuperäinen aloituspäivä', {}, 'jobContract')}
                        </span>
                    )
                    : _toLocaleDate(value)}
            </Value>
        </SummaryListItem>
    );
};

StartDate.propTypes = {
    value: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
};

export default StartDate;
