import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import createFilterOptions from 'react-select-fast-filter-options';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import NewPartyTypeSelector from './AddParty/NewPartyTypeSelector';
import PartyEmailForm from './AddParty/PartyEmailForm';
import { routeKeys } from 'Contract/constants/routes';
import userRoles from 'shared/constants/userRoles';
import { partiesActions, partiesSelectors } from 'shared/Parties/parties';
import { AutoCompleteVirtualized } from 'shared/containers/index';
import {
    Button,
    MDSpinner,
    Separator,
    OutlineLink,
} from 'shared/components';
import confirm from 'shared/utils/confirm';
import validators, { errorMessages } from 'shared/validators';
import eventKeys from 'shared/constants/eventKeys';
import { contractActions } from 'Contract/stores/contract';
import commonUtils from 'shared/utils/commonUtils';
import companyUserRelationTypes from 'shared/constants/companyUserRelationTypes';

class PreviousParties extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        userRole: PropTypes.number,
        previousParties: PropTypes.object,
        isPreviousPartiesPending: PropTypes.bool,
        hasFetchedPreviousParties: PropTypes.bool,
        isAddingNewParty: PropTypes.bool,
        isCommuneUser: PropTypes.bool,
        user: PropTypes.object,
        employee: PropTypes.object,
        routes: PropTypes.object,
        relationType: PropTypes.number.isRequired,
        isEmployee: PropTypes.bool,
        partyPrefix: PropTypes.string.isRequired,
        hasOnCallCompensationFamilyCare: PropTypes.bool,
    };

    static defaultProps = {
        dispatch() {} ,
        userRole: null,
        previousParties: List(),
        isPreviousPartiesPending: false,
        hasFetchedPreviousParties: false,
        isAddingNewParty: false,
        isCommuneUser: false,
        user: Map(),
        employee: Map(),
        routes: Map(),
        isEmployee: false,
        hasOnCallCompensationFamilyCare: false,
    };

    constructor(props) {
        super(props);
        const { dispatch, relationType } = props;
        dispatch(partiesActions.previousPartiesGet(relationType));

        this.state = {
            ssn: '',
        };
    }

    /**
     * Onko käyttäjä yritys- tai kuntaoperaattoriroolissa.
     * @returns {boolean}
     */
    isCompanyOrCommuneRole = () => _.includes([
        userRoles.COMPANY_OPERATOR,
        userRoles.COMPANY_SHADOW,
        userRoles.COMMUNE_OPERATOR
    ], this.props.userRole);

    setParty(party) {
        const { dispatch, isEmployee } = this.props;
        if (isEmployee) {
            // Asetetaan TT
            dispatch(partiesActions.partySet(party));
            if (this.isCompanyOrCommuneRole()) {
                dispatch.employeeWorkExperience.fetchEmployeeWorkExperience(party.userId);
            }

            dispatch(contractActions.updateEmployerAddress());
        } else {
            dispatch(partiesActions.caredGetOk(party));
        }
    }

    onChangeParty(party) {
        const { dispatch, isCommuneUser, user, employee, routes, relationType } = this.props;
        const isEmployee = relationType === companyUserRelationTypes.RELATION_EMPLOYEE;

        // Esitäytetyt tiedot tyhjäksi
        this.props.dispatch(partiesActions.resetInitialEmployee());

        if (! party) {
            // Jos jostains syystä on null tai undefined (esim. jos tyhjentää valinnan), niin bail out tässä
            return;
        }

        // Jos TT ja ei oo TT kaikki tiedot kunnossa ilmoitetaan siitä.
        if (isEmployee && ! commonUtils.checkEmployeeReadiness(party)) {
            // Jos ei ole validi ohjataan formille
            dispatch(partiesActions.setInitialEmployee(party));
            dispatch(push(routes.get(routeKeys.NEW_EMPLOYEE)));
            return;
        }

        // Tarkistetaan löytyykö joko TT / TA tai hoitaja / hoidettava -pareilta jo aiemmin voimassa oleva sopimus / sopimuksia.
        dispatch(isCommuneUser
            ? partiesActions.fetchCommuneEmployeeEmployerSharedContracts(party.userId, user.get('userId'))
            : partiesActions.fetchExistingContractsBetweenCaredAndEmployee(employee.get('userId'), party.userId)
        )
            .then((value) => {
                if (value) {
                    // Löytyi sopimus henkilöiden väliltä jo, kysytään, että löytyy jos ookko varma
                    confirm(_trans('job_contract.message.existing_contracts'),{
                        proceedLabel: _trans('contract.form.previous_parties.select_new_employee'),
                        cancelLabel: _trans('button.continue')
                    })
                        .then(
                            () => {}, // haluttiin valita uusi osapuoli, ei tehdä mitään
                            () => this.setParty(party) // Haluttiin jatkaa => aseta osapuoli
                        );
                } else {
                    // Ei löytynyt niin asetetaan party
                    this.setParty(party);
                }
            });
    }

    onAddNewParty = () => {
        const { dispatch } = this.props;
        dispatch(partiesActions.partyNew());
    };

    /**
     * Päivitetään hetu stateen. Jos käyttäjää ei löydy hetulla voidaan
     * luoda uusi käyttäjä annetulla hetulla.
     * @param ssn
     */
    onAutoCompleteInputChange = (ssn) => {
        this.updateSsn(ssn);
    };

    /**
     * Jos syötetty ssn on validi ja painetaan enter siirrytään automattisesti uuden työntekijän
     * lisäyssivulle syötetty hetu esitäytettynä (jos ollaan yritys tai kunta).
     * @param event
     */
    onAutoCompleteInputKeyDown = (event) => {
        const { dispatch, routes, relationType } = this.props;

        if (event.key === eventKeys.ENTER && validators.isSsn(this.state.ssn) && this.isCompanyOrCommuneRole()) {
            dispatch(partiesActions.setInitialEmployee({
                socialSecurityNumber: this.state.ssn.toUpperCase(),
            }));
            dispatch(push(`${routes.get(routeKeys.NEW_EMPLOYEE)}?relation=${relationType}`));
        }
    };

    /**
     * Tyhjennetään uuden työntekijän esitiedot autocompletesta lähdettäessä.
     * Tyhjennys timeoutilla siitä syystä että jos käyttäjä päättääkin lisätä
     * uuden työntekijän annetulla (validilla) hetulla ei hetua poisteta
     * heti storesta autocompletesta lähdettäessä.
     */
    onAutocompleteBlur = () => {
        setTimeout(() => {
            this.props.dispatch(partiesActions.resetInitialEmployee());
        }, 125);
    };

    /**
     * Päivitetään staten ssn jos käyttäjä on yritys- tai kunta-roolissa.
     * Kotitalousroolissa kysytään vielä erikseen Oima-tilistä joten suoraviivainen käyttäjän lisääminen
     * ei ole hyvä ratkaisu.
     * @param ssn
     */
    updateSsn = (ssn = '') => {
        const { dispatch } = this.props;

        if (ssn !== '' && this.isCompanyOrCommuneRole()) {
            dispatch(partiesActions.setInitialEmployee({
                socialSecurityNumber: ssn.toUpperCase(),
            }));

            this.setState({ ssn });
        }
    };

    render() {
        const {
            userRole,
            previousParties,
            hasFetchedPreviousParties,
            routes,
            relationType,
            partyPrefix,
            hasOnCallCompensationFamilyCare,
            dispatch,
        } = this.props;

        // Käännökset roolin mukaan
        const partyId = _trans(`contract.${partyPrefix}.singular`);
        const partiesId = _trans(`contract.${partyPrefix}.plural`);
        const userSelectPlaceholder = _trans(`contract.${partyPrefix}.user_select_placeholder`);

        // Edellisiä osapuolia haetaan joten näytä spinneri.
        // if (isPreviousPartiesPending) return <Spinner wrapped />;

        // Edellisiä osapuolia haettu mutta ei löydetty.
        if (hasFetchedPreviousParties && previousParties.count() === 0) {
            return (
                <div className="u-padding-small">
                    {_trans('contract.no_previous_parties', { parties: partiesId })}
                    { userRole === userRoles.COMPANY_OPERATOR || userRole === userRoles.COMPANY_SHADOW &&
                        <span>
                            {_transMd('contract.form.parties.party.company_add_party', {
                                url: Routing.generate('suoratyo_company_users')
                            })}
                            <OutlineLink
                                to={`${routes.get(routeKeys.NEW_EMPLOYEE)}?relation=${relationType}`}
                                mdIcon="person_add"
                                modifierClass="u-1/1 u-text-center"
                            >
                                {_trans('contract.form.parties.party.button.add_new_party_data', { party: partyId })}
                            </OutlineLink>
                        </span>
                    }
                    { userRole === userRoles.COMMUNE_OPERATOR &&
                        <OutlineLink
                            to={`${routes.get(routeKeys.NEW_EMPLOYEE)}?relation=${relationType}`}
                            mdIcon="person_add"
                            modifierClass="u-1/1 u-text-center"
                        >
                            {_trans('contract.form.parties.party.button.add_new_party_data', { party: partyId })}
                        </OutlineLink>
                    }
                    { userRole === userRoles.EMPLOYER && <NewPartyTypeSelector /> }
                    { userRole === userRoles.EMPLOYEE && <PartyEmailForm /> }
                </div>
            );
        }

        // Edellisiä osapuolia löydetty. Pyydetään käyttäjää valitsemaan.
        return (
            <div className="o-box o-box--small">
                {this.renderPreviousPartiesSelector(userSelectPlaceholder, partyId, partiesId)}
                <Separator title={_trans('or')}/>
                {this.renderAddNewParty()}
                {hasOnCallCompensationFamilyCare && (
                    <Button
                        outline
                        width="1/1"
                        mdIcon="person_off"
                        onClick={() => dispatch(partiesActions.caredGetOk({ noop: true }))}
                    >
                        {_trans('Sopimuksella ei ole hoidettavaa', {}, 'extract')}
                    </Button>
                )}
            </div>
        );
    }

    /**
     * Työntekijöiden filtteröitävä lista. Jos työntekijää ei löydy mutta hetu on validi
     * kerrotaan että enteriä painamalla voi siirtyä luomaan uutta työntekijää (hetu esitäytettynä).
     * React-virtualized kanssa 4000 käyttäjän lista toimii hyvin
     */
    renderPreviousPartiesSelector(userSelectPlaceholder, partyId, partiesId) {
        const {
            previousParties,
            isPreviousPartiesPending,
            isEmployee,
        } = this.props;

        const { ssn } = this.state;

        let label = _transChoice('contract.form.previous_parties.party_count_text', previousParties.count(), {
            count: previousParties.count(),
            party: partyId,
            parties: partiesId
        });

        // Näytetään placeholder latauksen aikana
        if (isPreviousPartiesPending) {
            label = (
                <span>
                    {_trans('contract.form.previous_parties.loading_employees')}
                    <MDSpinner size="tiny" modifierClass="u-margin-left-tiny" />
                </span>
            );
        }

        // Onko hetumainen, mutta ei tarkista onko validi
        const isSsnLike = /^\d{6}([-A+])\d{3}[\dA-Z]$/gm.exec(ssn.toUpperCase());
        const isValidSsn = ssn !== '' && validators.isSsn(ssn);
        const ssnNoResultsText = isValidSsn
            ? (isEmployee ? _trans('contract.employee.not_found_add_new') : _trans('contract.cared.not_found_add_new'))
            : errorMessages.isSsn;

        return (
            <Fragment>
                <div className=".label" id="previousPartiesAutocompleteLabel">
                    {label}
                </div>

                <div aria-describedby="previousPartiesAutocompleteLabel">
                    <AutoCompleteVirtualized
                        placeholder={userSelectPlaceholder}
                        items={previousParties.toJS()}
                        labelKey="fullName"
                        valueKey="fullName"
                        onSelect={(party) => this.onChangeParty(party)}
                        optionRenderer="employee"
                        optionHeight={42}
                        filterOptions={this.filterUserList}
                        noResultsText={ isSsnLike ? ssnNoResultsText : _trans('contract.employee.not_found') }
                        disabled={isPreviousPartiesPending}
                        onInputChange={this.onAutoCompleteInputChange}
                        onInputKeyDown={this.onAutoCompleteInputKeyDown}
                        onBlur={this.onAutocompleteBlur}
                    />
                </div>
            </Fragment>
        );
    }

    filterUserList = (options, filter, currentValues) => {
        const { previousParties } = this.props;
        const regex = /^\d{3}/gm;
        // Jos hetumainen, alkaa neljällä numerolla, niin haetaan hetuna, muuten nimellä.
        const key = regex.exec(filter) ? 'socialSecurityNumber' : 'fullName';
        return createFilterOptions({
            options: previousParties.toJS(),
            valueKey: key,
            labelKey: key,
        })(options, filter, currentValues);
    };

    renderAddNewParty() {
        const { userRole, routes, isAddingNewParty, relationType, partyPrefix } = this.props;

        const postFixKey = this.isCompanyOrCommuneRole() ? 'genitive' : 'singular'; // työntekijän / työntekijä
        const partyId = _trans(`contract.${partyPrefix}.${postFixKey}`);

        if (this.isCompanyOrCommuneRole()) {
            return (
                <OutlineLink to={`${routes.get(routeKeys.NEW_EMPLOYEE)}?relation=${relationType}`} mdIcon="person_add" modifierClass="u-1/1 u-text-center">
                    {_trans('contract.form.parties.party.button.add_new_party_data', { party: partyId })}
                </OutlineLink>
            );
        } else {
            return (
                <div>
                    { isAddingNewParty ?
                        (userRole === userRoles.EMPLOYEE) ? <PartyEmailForm /> : <NewPartyTypeSelector /> :
                        <Button outline mdIcon="person_add" onClick={this.onAddNewParty} modifierClass="u-1/1 u-text-center">
                            {_trans('contract.form.parties.party.button.add_new_party', { party: partyId })}
                        </Button>
                    }
                </div>
            );
        }
    }
}


const mapStateToProps = (state, props) => {
    const isCared = props.relationType === companyUserRelationTypes.RELATION_CARED;
    const previousPartyType = isCared ? 'previousCaredParties' : 'previousParties';
    return {
        userRole: partiesSelectors.getUserRole(state),
        isCommuneUser: partiesSelectors.isCommuneUser(state),
        employee: partiesSelectors.getParty(state),
        party: isCared ? partiesSelectors.getCaredParty(state) : partiesSelectors.getParty(state),
        previousParties: partiesSelectors.getPreviousParties(state, previousPartyType),
        hasFetchedPreviousParties: partiesSelectors.hasFetchedPreviousParties(state, previousPartyType),
        isPreviousPartiesPending: partiesSelectors.isFetchingPreviousParties(state, previousPartyType),
        isAddingNewParty: partiesSelectors.isAddingNewParty(state),
        routes: state.routes,
        user: partiesSelectors.getUser(state),
        isEmployee: ! isCared,
    };
};

export default connect(mapStateToProps)(PreviousParties);
