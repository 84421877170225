import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Value } from 'shared/components/Value';
import { HelpText } from 'shared/components';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import _transObj from 'shared/utils/_transObj';
import types from 'shared/constants/collectiveAgreementVariableTypes';
import { BooleanValue } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Summary/shared/BooleanValue';
import SummaryList from 'shared/components/SummaryList/index';

/**
 * Yksittäinen valittu TES-muuttuja.
 * Yhteenvedossa TES-muuttujat haetaan storesta.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Variable = (props) => {
    const { name, helpText, attributes: { options } } = props;
    // Kaivetaan alkuperäinen muuttujan nimi
    const namePieces = name.split('.');
    const variableName = namePieces.length === 2 ? namePieces[1] : '';
    const { value, variable } = useSelector((state) => select.contract.getCollectiveAgreementVariableByKey(state, variableName));
    const type = variable?.type;

    const resolveValue = ({ type, value }) => {
        switch (type) {
            case types.BOOLEAN:
                return <BooleanValue value={value} />;

            case types.DECIMAL:
            case types.DECIMAL_EURO: {
                // Jos löytyy omat optiot, etsitään sielä arvoa selittävä teksti (esim. 3 => 3 vrk / kk).
                if (Array.isArray(options) && options.length > 0) {
                    const option = options.find((option) => option.value === value);
                    return option
                        ? _transObj(option.label)
                        : '-';
                }

                return _numberParser(value);
            }

            case types.INTEGER:
                return _numberParser(value);

            default:
                return value;
        }
    };

    const formFieldLabel = props.label || _trans('contract.form.tes_variables.' + variableName + '.label');

    return (
        <SummaryList>
            <SummaryListItem term={formFieldLabel}>
                <Value ariaDescribedBy={name}>
                    {resolveValue({ type, value })}
                </Value>
                {helpText && <HelpText text={helpText} id={name} />}
            </SummaryListItem>
        </SummaryList>
    );
};

Variable.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    helpText: PropTypes.string,
    attributes: PropTypes.shape({
        options: PropTypes.array,
    }),
    values: PropTypes.object.isRequired,
};

Variable.defaultProps = {
    label: null,
    helpText: null,
    attributes: {
        options: [],
    },
};

export default Variable;
