import { produce } from 'immer';
import api from 'api';
import { leavePeriodTypes } from 'shared/constants/leavePeriodTypes';
import { actions as notificationActions } from 'shared/stores/notifications';
import { contractStates } from 'shared/constants/contractStates';

/**
 * Työntekijän poissaolojaksot.
 *
 */
export const leavePeriods = {
    state: {
        leavePeriods: {},
        employeeContracts: [],
    },
    reducers: {
        setLeavePeriod: (state, type, leavePeriods) => produce(state, (draftState) => {
            draftState.leavePeriods[type] = leavePeriods;
        }),

        setLeavePeriods: (state, leavePeriods) => ({ ...state, leavePeriods }),

        setEmployeeContracts: (state, employeeContracts) => ({ ...state, employeeContracts }),

        setShowPartialDimensionAccessWarning: (state, showPartialDimensionAccessWarning ) => ({ ...state, showPartialDimensionAccessWarning }),
    },
    selectors: {
        getLeavePeriods: (state) => state.leavePeriods,
        /**
         * Palauttaa aikavälilistan (pois lukien muokattavan poissaolon aikaväli) jota
         * käytetään validoimaan poissaolo vapaille päiville.
         * @param state
         * @param omittedId
         * @returns {*}
         */
        getLeavePeriodDateRanges: (state, omittedId) => {
            const periods = state.leavePeriods ?? {};

            // Mapataan objekti ja filtteröidään se: eka item on key, toinen itse arvo(t) taulukossa
            return Object.entries(periods)
                // 1. Filtteröi pois tyhjät aikavälit
                .filter(([, dateRanges]) => dateRanges.length > 0)
                // 2. Object.entriesissä mapin kautta eka lista-item on key, toinen lista arvoja
                .map(([, dateRanges]) => dateRanges)
                // 3. "Flatmap" [[1], [2, 3]] => [1, 2, 3]
                .reduce((acc, cur) => [...acc, ...cur], [])
                // 4. Kalenteri ottaa aikavälit listana objekteja joissa objekti { start: Date, end: Date }
                .map((dateRange) => ({
                    id: dateRange.id,
                    start: dateRange.startDate,
                    end: dateRange.endDate,
                    jobContract: dateRange.jobContract?.jobContractId
                }))
                // 5. Poistetaan vielä itse muokattava poissaolon aikaväli
                .filter((dateRange) => dateRange.id !== omittedId);
        },

        getEmployeeContracts: (state) => state.employeeContracts,

        getShowPartialDimensionAccessWarning: (state) => state.showPartialDimensionAccessWarning,
    },
    effects: (dispatch) => ({
        /**
         * Hakee työntekijän poissaolojaksot
         */
        async fetchLeavePeriods({ userId, type = null }) {
            try {
                const queryString = type ? `?type=${type}` : '';
                const json = await api.get(`/api/v1/users/${userId}/leaveperiods${queryString}`);//
                const periods = json.data ?? [];
                const meta = json.meta ?? {};
                const showPartialDimensionAccessWarning = !! (meta.showPartialDimensionAccessWarning || false);

                //Jos type määritetty muutetaan tyypin jaksot
                //Muuten jaetaan leavePeriodTypen mukaan.
                if (type) {
                    this.setLeavePeriod(type, periods);
                } else {
                    const filteredPeriods = {};
                    Object
                        .values(leavePeriodTypes)
                        .map((leavePeriodType) => {
                            filteredPeriods[leavePeriodType] = periods
                                .filter((period) => (
                                    period.type === leavePeriodType
                                ));
                        });
                    this.setLeavePeriods(filteredPeriods);
                }
                this.setShowPartialDimensionAccessWarning(showPartialDimensionAccessWarning);
            } catch (error) {
                console.error(error);
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('leave_period.error.load'),
                }));
                // dispatch.notifications.addError(_trans('leave_period.error.load'));
            }
        },

        async postLeavePeriod({ userId, type, values }) {
            try {
                await api.post(`/api/v1/users/${userId}/leaves/${type}/periods`, values);
                dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('leave_period.success.save'),
                }));
                // dispatch.notifications.addSuccess(_trans('leave_period.success.save'));
                await dispatch.leavePeriods.fetchLeavePeriods({ userId, type });
            } catch (error) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('leave_period.error.save'),
                }));
                // dispatch.notifications.addError(_trans('leave_period.error.save'));
            }
        },

        async putLeavePeriod({ userId, type, leavePeriodId, values }) {
            try {
                await api.put(`/api/v1/users/${userId}/leaves/${leavePeriodId}/period/${type}`, values);
                dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('leave_period.success.save'),
                }));
                // dispatch.notifications.addSuccess(_trans('leave_period.success.save'));
                await dispatch.leavePeriods.fetchLeavePeriods({ userId });

            } catch (error) {
                console.error(error);
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('leave_period.error.save'),
                }));
                // dispatch.notifications.addError(_trans('leave_period.error.save'));
            }
        },

        async deleteLeavePeriod({ userId, leavePeriodId, type }) {
            try {
                await api.del(`/api/v1/users/${userId}/leaves/${leavePeriodId}/period`);
                dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('leave_period.success.delete'),
                }));
                // dispatch.notifications.addSuccess(_trans('leave_period.success.delete'));
                await dispatch.leavePeriods.fetchLeavePeriods({ userId, type });
            } catch (error) {
                console.error(error);
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('leave_period.error.delete'),
                }));
                // dispatch.notifications.addError(_trans('leave_period.error.delete'));
            }
        },

        /**
         * Pikapurkka: hae vain TYÖNTEKIJÄN sopparit
         */
        async fetchEmployeeContracts({ employee, employer = null }) {
            try {
                const employerId = employer ? `&employerId=${employer}` : '';
                const json = await api.get(`/api/contracts/v1/users/${employee}?states[]=${contractStates.ACCEPTED}${employerId}`);

                const contracts = (json.data ?? [])
                    .map((contract) => ({
                        employerName: contract.employerDetails?.fullName,
                        startDate: contract.startDate,
                        endDate: contract.endDate,
                        jobContractId: contract.jobContractId,
                    }))
                    .sort((a, b) => {
                        if (a.employerName.toUpperCase() < b.employerName.toUpperCase()) {
                            return -1;
                        }
                        if (a.employerName.toUpperCase() > b.employerName.toUpperCase()) {
                            return 1;
                        }
                        return 0;
                    });

                this.setEmployeeContracts(contracts);
            } catch (e) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('leave_period.error.fetch_contracts_failed'),
                }));
                console.log(e);
            }
        }
    }),
};

