import api from 'api';

export const receipts = {
    state: {
        receipts: [],
        selected: {},
        transactions: {},
    },

    reducers: {
        setReceipts: (state, receipt) => ({ ...state, receipt }),
        setSelected: (state, selected) => ({ ...state, selected }),
        setTransactions: (state, [transactions, receiptItemId]) => ({
            ...state,
            transactions: {
                ...state.transactions,
                [receiptItemId]: transactions
            }
        }),
    },

    selectors: {
        getReceipts: (state) => state.receipt,
        getSelected: (state) => state.selected,
        getTransactions: (state) => state.transactions ?? {},
    },

    effects: (dispatch) => ({
        async fetchReceipts() {
            try {
                const response = await api.get(`/api/bookkeeping/user/receipts`);
                if (response.status === 'ok') {
                    this.setReceipts(response.data ?? []);
                } else {
                    dispatch.notifications.addError(response.message ?? _trans('Kirjanpitotositteita ei saatu noudettua.', {}, 'bookkeeping'));
                }
                return response;
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Kirjanpitotositteita ei saatu noudettua.', {}, 'bookkeeping'));
            }
        },
        async fetchReceipt(receipt) {
            try {
                const response = await api.get(`/api/bookkeeping/receipts/${receipt}`);
                if (response.status === 'ok') {
                    this.setSelected(response.data ?? {});
                } else {
                    dispatch.notifications.addError(response.message ?? _trans('Kirjanpitotositetta ei saatu noudettua.', {}, 'bookkeeping'));
                }
                return response;
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Kirjanpitotositetta ei saatu noudettua.', {}, 'bookkeeping'));
            }
        },
        async fetchTransactions(receiptItemId) {
            try {
                const response = await api.get(`/api/bookkeeping/receipts/item/${receiptItemId}/transactions`);
                if (response.status === 'ok') {
                    this.setTransactions([response.data ?? [], receiptItemId]);
                } else {
                    dispatch.notifications.addError(response.message ?? _trans('Transaktioita ei saatu noudettua.', {}, 'bookkeeping'));
                }
                return response;
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Transaktioita ei saatu noudettua.', {}, 'bookkeeping'));
            }
        },
        async postOfflinePaymentRegen(offlinePaymentId) {
            try {
                const response = await api.post(`/api/bookkeeping/offline-payment/${offlinePaymentId}/receipts/regenerate`, []);
                if (response.status === 'ok') {
                    dispatch.notifications.addSuccess(response.message ?? _trans('Maksuerän kirjanpitotositteen uudelleenajo asetettu jonoon.', {}, 'bookkeeping'));
                } else {
                    dispatch.notifications.addError(response.message ?? _trans('Kirjanpitotositteen uudelleenajossa tapahtui virhe', {}, 'bookkeeping'));
                }
                return response;
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Kirjanpitotositteen uudelleen ajossa tapahtui virhe.', {}, 'bookkeeping'));
            }
        },
        async postPayrollRegen(payrollId) {
            try {
                const response = await api.post(`/api/bookkeeping/payroll/${payrollId}/receipts/regenerate`, []);
                if (response.status === 'ok') {
                    dispatch.notifications.addSuccess(response.message ?? _trans('Palkkion kirjanpitotositteen uudelleen ajo suoritettu onnistuneesti.', {}, 'bookkeeping'));
                } else {
                    dispatch.notifications.addError(response.message ?? _trans('Kirjanpitotositteen uudelleen ajossa tapahtui virhe', {}, 'bookkeeping'));
                }
                return response;
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Kirjanpitotositteen uudelleen ajossa tapahtui virhe.', {}, 'bookkeeping'));
            }
        },
    })
};
