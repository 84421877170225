import api from 'api';

export const tyelInsurances = {
    state: {
        status: {},
    },
    reducers: {
        setStatus: (state, payload) => ({ ...state, status: payload }),
    },
    selectors: {
        getStatus: (state) => state.status,
        hasNoEffectiveInsurance: (state) => state.status?.isNoEffectiveInsurance ?? false,
    },
    effects: (dispatch) => ({
        /**
         * Hakee uudesta API:sta tiedon nykyisestä vakuutuksesta
         *
         * @return {Promise<void>}
         */
        async fetchTyelInsuranceStatus() {
            try {
                const json = await api.get('/api/tyel/v1/tyel-insurance-status');
                if (json.status === 'ok') {
                    this.setStatus(json.data);
                } else {
                    dispatch.notifications.addError(json.message);
                }
            } catch (e) {
                dispatch.notifications.addError('');
            }
        },
        /**
         * Hakee uuden YP:n vakuutukset API:sta
         *
         * @return {Promise<void>}
         */
        async postTyelInsurance(model) {
            try {
                const json = await api.post('/api/tyel/v1/tyel-insurances', model);
                if (json.status === 'ok') {
                    dispatch.insurances.fetchTyelInsurance();
                    dispatch.notifications.addSuccess('Vakuutus tallennettu.');
                } else {
                    dispatch.notifications.addError(json.message);
                }
            } catch (e) {
                dispatch.notifications.addError('Vakuutusta ei hakea');
            }
        },
        /**
         * Kuntapuolen TA:n vakuutuksen tila ulkoisesta apista
         *
         * @param employerUser
         * @return {Promise<void>}
         */
        async fetchCommuneTyelInsuranceStatus(employerUser) {
            try {
                const json = await api.get(`/api/tyel/v1/commune/tyel-insurance-status/${employerUser}`);
                if (json.status === 'ok') {
                    this.setStatus(json.data);
                } else {
                    dispatch.notifications.addError(json.message);
                }
            } catch (e) {
                dispatch.notifications.addError('');
            }
        },
        /**
         * Uuden kuntapuolen vakuutuksen ottaminen.
         *
         * @param employerUser int
         * @return {Promise<void>}
         */
        async postCommuneTyelInsurance(employerUser) {
            try {
                const json = await api.post(`/api/tyel/v1/commune/tyel-insurances/${employerUser}`);
                console.log(json);
            } catch (e) {
                //
            }
        },
    }),
};
