import React from 'react';
import { Control } from 'react-redux-form/lib/immutable';
import { fromJS, Iterable } from 'immutable';
import StLabel from 'shared/components/StForm/StLabel';
import StField from 'shared/components/StForm/StField';
import { DateRangeInput, StHelp } from 'shared/components';

const DateRangeSelector = () => (
    <div>
        <StLabel htmlFor="dateRange">
            {_trans('date.range')}
        </StLabel>
        <StField>
            <Control
                parser={fromJS}
                id="dateRange"
                model=".dateRange"
                aria-describedby="dateRangeHelp"
                component={DateRangeInput}
                minimumDate={new Date('1970-01-01')}
                mapProps={{
                    // eslint-disable-next-line react/prop-types
                    value: (props) => Iterable.isIterable(props.modelValue) ? props.modelValue.toJS() : props.modelValue,
                }}
            />
            <StHelp id="dateRangeHelp">
                {_trans('payments.details.bookkeep.date_range_help')}
            </StHelp>
        </StField>
    </div>
);

export default DateRangeSelector;
