/**
 * Lista objekteja (value, label) joista väännetään [value]: label -tyylinen objektipompsi.
 * @param customTranslations
 */
export const parseCustomTranslations = (customTranslations) =>
    Array.isArray(customTranslations)
        ? customTranslations.reduce((acc, cur) => Object.assign({}, acc, {
            [cur.value]: _transObj(cur.label)
        }), {})
        : {};

