// WorkTimeEstimationTypes
// This file is clone of Suoratyo\AppBundle\Common\WorkTimeEstimationType

import _ from 'lodash';
import { workTimeEstimationTypes } from 'shared/constants/index';

export const REGULAR_HOURS_PER_WEEK = 1;
export const IRREGULAR_HOURS_PER_WEEK = 2;
export const IRREGULAR_HOURS_PER_MONTH = 3;
export const MAX_HOURS_PER_WEEK = 4;
export const REGULAR_HOURS_PER_MONTH = 5;

export const translateWorkTimeEstimateType = (type) => {
    switch (parseInt(type, 10)) {
        case REGULAR_HOURS_PER_WEEK:
            return _trans('contract.form.duration.work_time_estimation_type.regular_hours_per_week');
        case REGULAR_HOURS_PER_MONTH:
            return _trans('contract.form.duration.work_time_estimation_type.regular_hours_per_month');
        case IRREGULAR_HOURS_PER_WEEK:
            return _trans('contract.form.duration.work_time_estimation_type.irregular_hours_per_week');
        case IRREGULAR_HOURS_PER_MONTH:
            return _trans('contract.form.duration.work_time_estimation_type.irregular_hours_per_month');
        case MAX_HOURS_PER_WEEK:
            return _trans('contract.form.duration.work_time_estimation_type.max_hours_per_week');
    }
};

export const companyEstimatedWorkTimeTranslator = (value) =>
    companyEstimatedWorkTimeTypes.find((type) => type.id === value)?.name ?? '-';

export const companyEstimatedWorkTimeTypes = [
    {
        id: REGULAR_HOURS_PER_WEEK,
        name: _trans('contract.form.duration.work_time_estimation_type.regular_hours'),
    },
    {
        id: IRREGULAR_HOURS_PER_WEEK,
        name: _trans('contract.form.duration.work_time_estimation_type.irregular_hours'),
    },
];

export const isRegularOrMaxHoursType = (type) =>
    [
        workTimeEstimationTypes.REGULAR_HOURS_PER_MONTH,
        workTimeEstimationTypes.REGULAR_HOURS_PER_WEEK,
        workTimeEstimationTypes.MAX_HOURS_PER_WEEK
    ]
        .includes(parseInt(type, 10));

export const isMonthlyType = (type) =>
    [
        workTimeEstimationTypes.IRREGULAR_HOURS_PER_MONTH,
        workTimeEstimationTypes.REGULAR_HOURS_PER_MONTH,
    ]
        .includes(parseInt(type, 10));

/**
 * Listaa säännöllistä työaikaa edustavat tyypit
 */
export function getRegularTypes() {
    return [
        REGULAR_HOURS_PER_WEEK,
        REGULAR_HOURS_PER_MONTH,
    ];
}

export function isRegularType(type) {
    return _.includes(getRegularTypes(), type);
}

export const resolveWorkHoursUnitLabel = (type) => isMonthlyType(parseInt(type, 10))
    ? _trans('tuntia / kuukausi', {}, 'jobContract')
    : _trans('tuntia / viikko', {}, 'jobContract');

export function isPerWeekType(type) {
    return _.includes([
        REGULAR_HOURS_PER_WEEK,
        IRREGULAR_HOURS_PER_WEEK,
        MAX_HOURS_PER_WEEK,
    ], type);
}

export function isPerMonthType(type) {
    return _.includes([
        IRREGULAR_HOURS_PER_MONTH,
        REGULAR_HOURS_PER_MONTH,
    ], type);
}

export default {
    REGULAR_HOURS_PER_WEEK,
    IRREGULAR_HOURS_PER_WEEK,
    IRREGULAR_HOURS_PER_MONTH,
    MAX_HOURS_PER_WEEK,
    REGULAR_HOURS_PER_MONTH,
};
