import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ButtonGroup = ({ children, hasFixedWidths, modifierClass, ariaLabel }) => {
    const allChildren = React.Children.count(children) > 1 ? children : [children];

    const buttonGroupClass = classNames('c-button-group u-margin-none', modifierClass, {
        'o-list-bare o-stack--inline': !hasFixedWidths,
        'o-pack': hasFixedWidths,
    });

    const buttonGroupItemClass = classNames({
        'o-list-bare__item': !hasFixedWidths,
        'o-pack__item': hasFixedWidths,
    });

    return (
        <ul className={buttonGroupClass} role="group" aria-label={ariaLabel}>
            {allChildren.map((child, key) => React.createElement(
                'li', {
                    key,
                    className: buttonGroupItemClass,
                }, child))}
        </ul>
    );
};

ButtonGroup.propTypes = {
    children: PropTypes.node.isRequired,
    /**
     * Are buttons equal in width.
     */
    hasFixedWidths: PropTypes.bool,
    /**
     * CSS modifying class.
     */
    modifierClass: PropTypes.string,
    /**
     * Group defining label.
     */
    ariaLabel: PropTypes.string.isRequired,
};

ButtonGroup.defaultProps = {
    hasFixedWidths: false,
    modifierClass: null,
};

export default ButtonGroup;
