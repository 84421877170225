/* eslint-disable react/display-name, react/prop-types */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import _ from 'lodash';
import security from 'shared/utils/security';

/**
 * Vetää annetun tekstin Translatorin läpi ja konvertoi markdownit HTML:ksi.
 * Käytä vain jos viesti sisältää Markdownia (duh).
 * @param id
 * @param placeholders - Voi käyttää tekstissä olevien placeholderien korvaamiseen ja/tai esim. inline-hienosäätöön.
 *            Jos tyyppi on boolean käyttäytyy samoin kun alapuolella oleva useParagraphs.
 * @param parameters - Joko parametrit objektina tai pelkkä boolean kertomaan kääritäänkö lauseet kappaleisiin
 *           automaattisesti. Oletuksena ei kääritä.
 * @param domain - Käännösdomaini
 * @returns {*}
 * @private
 */
function _transMd(id, placeholders = {}, parameters = false, domain) {
    const markdownParameters = {};
    let disallowedTypes = ['paragraph'];

    const useParagraphs =
        (typeof parameters === 'boolean' && parameters === true) ||
        (typeof placeholders === 'boolean' && placeholders === true) ||
        (_.get(parameters, 'useParagraphs', false) === true);

    if (useParagraphs) {
        disallowedTypes = [];
    }

    // Haetaan parametreista mahdollinen tieto siitä miten linkit avataan
    const targetBlank = _.get(parameters, 'targetBlank', false);

    const renderers = {};
    // Näytetäänkö MarkDown inlinenä. Hyvä esim. checkboxien kylkeen.
    if (_.get(parameters, 'inline', false) === true) {
        renderers.root = (props) => <span>{props.children}</span>;
    }

    return (
        <ReactMarkdown
            source={security.sanitizeMarkdown(_trans(id, placeholders, domain))}
            unwrapDisallowed
            disallowedTypes={disallowedTypes}
            renderers={
                {
                    ...renderers,
                    heading: (props) => (
                        <h2 className="c-heading-title u-margin-bottom-small">
                            {props.children}
                        </h2>
                    ),
                    link: ({ node, ...props }) => {
                        let href = props.href;
                        let linkAttributes = {};
                        let content = props.children;
                        const hostname = _.get(location, 'hostname', null);

                        // Jos linkki osoittaa palvelun ulkopuolelle tai se on asetettu avautumaan uuteen
                        // ikkunaan lisätään linkin kylkeen myös kuvaava ikoni ja aputeksti.
                        const openInNew = (targetBlank === true)
                            || (props.href.match('http') && !props.href.startsWith(hostname));

                        // Jos linkki osoittaa palvelun ulkopuolelle tehdään tarvittavat attribuuttilisäykset.
                        // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
                        if (openInNew) {
                            linkAttributes = {
                                rel: 'nofollow noreferrer noopener',
                                target: '_blank',
                                title: _trans('text.opens_in_new_window'),
                            };

                            content = (
                                <Fragment>
                                    {props.children}
                                    <i
                                        className="material-icons c-md-icon--tiny"
                                        aria-hidden="true"
                                        style={{
                                            marginLeft: '3px',
                                        }}
                                    >
                                        open_in_new
                                    </i>
                                </Fragment>
                            );
                        }

                        // Jos url alkaa suoratyo-sanalla oletetaan että se on jokin
                        // routing.yml:stä löytyvä polku. Generoidaan se.
                        if (props.href.match(/^[suoratyo].+$/)) {
                            // Jos löytyy query paramateriä
                            const queryStringPosition = props.href.indexOf('?');
                            if (queryStringPosition !== -1) {
                                // Lisäillää query string(it) perään
                                href = Routing.generate(props.href.slice(0, queryStringPosition)) + props.href.slice(queryStringPosition);
                            } else {
                                href = Routing.generate(props.href);
                            }
                        }

                        return (
                            <a
                                href={href}
                                {...linkAttributes}
                            >
                                {content}
                            </a>
                        );
                    }
                }
            }
            skipHtml
            {...markdownParameters}
            plugins={[remarkBreaks]}
        />
    );
}

_transMd.propTypes = {
    children: PropTypes.node.isRequired,
};

export default _transMd;
