import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FileInput from 'react-fine-uploader/file-input';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import { useDispatch } from 'react-redux';
import { IconText } from 'shared/components';
import { FormField } from 'shared/components/Formik';
import Feedback from 'shared/components/Feedback';

/**
 * Liitetiedoston lähetyslomake.
 * @param isPublic
 * @param onUploadComplete
 * @param onUploadFailed
 * @returns {JSX.Element}
 * @constructor
 */
export const AttachmentUploadForm = ({ isPublic, onUploadComplete, onUploadFailed }) => {
    const dispatch = useDispatch();
    const [description, setDescription] = useState('');
    const [hasConfirmedIsPublic, setHasConfirmedIsPublic] = useState(false);

    useEffect(() => {
        setHasConfirmedIsPublic(! isPublic);
    }, [isPublic]);

    const uploader = new FineUploaderTraditional({
        options: {
            request: {
                endpoint: '/_uploader/attachment/upload',
            }
        }
    });

    uploader
        .on('complete', (index, fileName, { id, success }) => {
            if (success === true && id) {
                dispatch.attachments
                    .updateAttachmentDetails({ id, description, created: new Date().toISOString(), isPublic, isSelected: true })
                    .then((attachment) => {
                        dispatch.notifications.addSuccess(_trans('Liite lisätty.', {}, 'common'));
                        onUploadComplete(attachment);
                    });
            } else {
                onUploadFailed();
            }
        });
    uploader.on('error', (index, fileName, errorReason) => {
        dispatch.notifications.addError(_trans('Liitettä ei voitu lisätä.', {}, 'common'));
        console.log(errorReason);
    });

    const isButtonDisabled = description === '' || (isPublic && ! hasConfirmedIsPublic);

    return (
        <div className="o-form o-form--vertical">
            {isPublic && (
                <Fragment>
                    <Feedback
                        type="info"
                        modifierClass="u-margin-bottom-small"
                        message={_trans('Voit ladata palveluun liitetiedoston, joka voidaan valita käytettäväksi valitsemiesi sopimusten liitetiedostona. Ominaisuus on tarkoitettu yleisten sopimusehtojen liittämiseen, kuten tarkempien työehtojen kuvaamiseksi.', {}, 'common')}
                    />
                    <label className="u-margin-bottom u-line-height-default u-font-weight-bold">
                        <input type="checkbox" onChange={(event) => setHasConfirmedIsPublic(event.target.checked)} />
                        {_trans('Ymmärrän, että lisäämäni liite on yleinen ja se on myös muiden valittavissa', {}, 'jobContract')}
                    </label>
                </Fragment>
            )}
            <FormField
                label={_trans('Kirjoita liitteen kuvaus ja valitse sitten liite', {}, 'common')}
                helpText={_transMd('Anna liitettä hyvin kuvaava nimi. Liitteiden maksimikoko on **50 Mb**.', {}, 'common')}
            >
                <div className="o-stack">
                    <input
                        value={description}
                        type="text"
                        onChange={(event) => setDescription(event.target.value)}
                        size={24}
                        maxLength={255}
                        className="o-stack__item--fill"
                        disabled={isPublic && ! hasConfirmedIsPublic}
                    />
                    <FileInput
                        accept=".pdf, .png, .jpg"
                        uploader={uploader}
                        className={classNames('c-button u-text-truncate u-cursor-pointer u-margin-left-small', {
                            'is-disabled': isButtonDisabled,
                            'c-button--primary': !isButtonDisabled
                        })}
                        disabled={isButtonDisabled}
                        text={{
                            selectFile: _trans('Valitse liite', {}, 'common'),
                        }}
                        title={_trans('Liitettä ei valittuna', {}, 'common')}
                    >
                        <IconText
                            mdIcon="attach_file"
                            iconSize="small"
                            text={_trans('Valitse liite', {}, 'common')}
                        />
                    </FileInput>
                </div>
            </FormField>
        </div>
    );
};

AttachmentUploadForm.propTypes = {
    isPublic: PropTypes.bool,
    onUploadComplete: PropTypes.func.isRequired,
    onUploadFailed: PropTypes.func,
};

AttachmentUploadForm.defaultProps = {
    isPublic: false,
    onUploadFailed() {},
};

