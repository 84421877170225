import React from 'react';
import PropTypes from 'prop-types';
import { Field } from './Field';

/**
 * List of radiobuttons.
 * @param children
 * @param name
 * @param value
 * @param label
 * @returns {JSX.Element}
 * @constructor
 */
export const RadioField = ({ name, value, label, ...rest }) => (
    <label>
        <Field type="radio" name={name} value={value} id={name} {...rest} />
        {label}
    </label>
);

RadioField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

