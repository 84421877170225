import React from 'react';
import PropTypes from 'prop-types';
import { DateInputField } from 'shared/components/Formik';

/**
 * Rikosrekisteriotteen esityspvm.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const CriminalRecordDate = (props) => {
    const { name, placeholder } = props;

    return (
        <DateInputField name={name} placeholder={placeholder} />
    );
};

CriminalRecordDate.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
};

CriminalRecordDate.defaultProps = {
    placeholder: _trans('Tarkistuspäivä', {}, 'common'),
};

export default CriminalRecordDate;
