import React from 'react';
import PropTypes from 'prop-types';

const LinkList = ({ links, modifierClass, target }) => (
    <div>
        {links.map((link, index) =>
            <span key={`linkListIndex${index}`}>
                <a className={modifierClass} href={link.route} target={target} rel="noopener noreferrer">
                    {link.name}
                </a>
                {index !== (links.length - 1) ? ' | ' : null}
            </span>)}
    </div>
);

LinkList.propTypes = {
    links: PropTypes.arrayOf(PropTypes.object).isRequired,
    modifierClass: PropTypes.string,
    target: PropTypes.string,
};

LinkList.defaultProps = {
    modifierClass: '',
    target: '',
};

export default LinkList;
