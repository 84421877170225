import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Sticky from 'shared/components/Sticky';

const JUSTIFY = 'justify';
const ALIGN_LEFT = 'left';
const ALIGN_RIGHT = 'right';
const ALIGN_TOP = 'top';
const ALIGN_BOTTOM = 'bottom';
const ALIGN_VERTICAL = 'vertical';

function ActionBar(props) {
    const {
        children,
        alignItems,
        modifierClass,
        hasMargin,
        isSticky,
        stickTo,
        canWrap,
    } = props;

    const actionBarClass = classNames('c-action-bar o-stack', {
        'o-stack--vertical o-stack--with-gaps': alignItems === ALIGN_VERTICAL,
        'o-stack--top': alignItems === ALIGN_TOP,
        'o-stack--bottom': alignItems === ALIGN_BOTTOM,
        'o-stack--left': alignItems === ALIGN_LEFT || canWrap,
        'o-stack--right': alignItems === ALIGN_RIGHT && !canWrap,
        'o-stack--justify': alignItems === JUSTIFY && !canWrap,
        'u-margin-bottom-small': hasMargin,
        'o-stack--wrap c-action-bar--wrapped': canWrap,
        'u-padding-vertical-small': isSticky,
        [modifierClass]: modifierClass !== '',
    });

    if (isSticky) {
        return (
            <Sticky
                stickTo={stickTo}
                stickyClass="is-sticky"
                modifierClass={`c-sticky-bar c-sticky-bar--${stickTo} ${actionBarClass}`}
            >
                {children}
            </Sticky>
        );
    }

    return (
        <div className={actionBarClass}>
            {children}
        </div>
    );
}

ActionBar.propTypes = {
    children: PropTypes.any.isRequired,
    alignItems: PropTypes.oneOf([JUSTIFY, ALIGN_TOP, ALIGN_BOTTOM, ALIGN_LEFT, ALIGN_RIGHT, ALIGN_VERTICAL]),
    modifierClass: PropTypes.string,
    hasMargin: PropTypes.bool,
    isResponsive: PropTypes.bool,
    /**
     * Kiinnittyykö toimintopalkki ylä- tai alaosaan containeriaa jos container menee näkymän yli.
     */
    isSticky: PropTypes.bool,
    /**
     * Kiinnittyykö ylä vai alaosaan containeria.
     */
    stickTo: PropTypes.oneOf(['top', 'bottom']),
    /**
     * Menevätkö sisältöelementit automaattisesti seuraavalle riville mikäli eivät mahdu rinnakkain.
     * Vähän "köyhän miehen (henkilön) versio. Oikea ratkaisu olisi tehdä kokonaan JS:llä.
     */
    canWrap: PropTypes.bool,
};

ActionBar.defaultProps = {
    alignItems: JUSTIFY,
    responsive: true,
    modifierClass: '',
    hasMargin: false,
    isResponsive: false,
    isSticky: false,
    stickTo: 'top',
    canWrap: false,
};

export default ActionBar;
