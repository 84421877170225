import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { ActionBar, ConfirmButton, Feedback, LinkIcon, MDSpinner, Page, Tooltip } from 'shared/components';
import resolveUrl from 'shared/utils/resolveUrl';
import { DataTable } from 'shared/containers';
import personnelGroup from 'shared/shapes/personnelGroup';

/**
 * Henkilöstöryhmien luettelointi
 * Navigaatio muokkaus/luomis lomakeelle
 */
@connect((state) => ({
    groups: select.personnelGroup.getGroups(state),
    isDeleting: _.get(state, 'loading.effects.personnelGroup.deleteGroup', false),
    isLoading: _.get(state, 'loading.effects.personnelGroup.fetchGroups', false),
}))
export default class PersonnelGroupList extends Component {
    static propTypes = {
        routes: PropTypes.object.isRequired,
        groups: PropTypes.arrayOf(personnelGroup),
        isDeleting: PropTypes.bool,
        isLoading: PropTypes.bool,
        dispatch: PropTypes.func,
    };

    static defaultProps = {
        groups: [],
        isDeleting: false,
        isLoading: false,
        dispatch() {},
    };

    onDelete = (id) => {
        this.props.dispatch.personnelGroup.deleteGroup(id);
    };

    render() {
        const { routes, groups, isDeleting, isLoading } = this.props;

        if (isLoading) {
            return (
                <MDSpinner wrapped />
            );
        }

        return (
            <Page
                heading={_trans('company_manager.personnel_groups.heading')}
            >
                <Feedback type="info" modifierClass="u-margin-bottom-small">
                    {_transMd('company_manager.personnel_groups.personnel_group_list.help')}
                </Feedback>
                <ActionBar hasMargin alignItems="right">
                    <LinkIcon
                        mdIcon="add"
                        to={resolveUrl(routes.PERSONNEL_GROUPS_FORM, { id: 'new' })}
                    >
                        {_trans('company_manager.personnel_groups.add_new')}
                    </LinkIcon>
                </ActionBar>
                <DataTable
                    data={groups}
                    columns={[
                        {
                            Header: _trans('company_manager.personnel_groups.form.name'),
                            accessor: 'name',
                        },
                        {
                            Header: _trans('company_manager.personnel_groups.form.description'),
                            accessor: 'description',
                        }
                    ]}
                    actionsColumn={<ActionsColumn routes={routes} onDelete={this.onDelete} isDeleting={isDeleting} />}
                />
            </Page>
        );
    }
}

const ActionsColumn = ({ original, routes, onDelete, isDeleting }) => (
    <Fragment>
        <LinkIcon
            modifierClass="u-margin-right-small"
            mdIcon="edit"
            to={resolveUrl(routes.PERSONNEL_GROUPS_FORM, { id: _.get(original, 'id') })}
        >
            {_trans('button.edit')}
        </LinkIcon>
        <ConfirmButton
            onClick={() => onDelete(_.get(original, 'id'))}
            confirmText={_trans('button.are_you_sure')}
            text={_trans('button.remove')}
            disabled={! _.get(original, 'isRemovable', true)}
            isPending={isDeleting}
        />
        {! _.get(original, 'isRemovable', true) && (
            <Tooltip title={_trans('company_manager.personnel_groups.notifications.error.has_values')}/>
        )}
    </Fragment>
);

ActionsColumn.propTypes = {
    original: PropTypes.object,
    routes: PropTypes.object,
    onDelete: PropTypes.func,
    isDeleting: PropTypes.bool,
};

ActionsColumn.defaultProps = {
    original: {},
    routes: {},
    onDelete() {},
    isDeleting: false,
};
