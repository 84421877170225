import React from 'react';
import PropTypes from 'prop-types';
import { Field } from './Field';

/**
 * Näyttää mobiiliystävällisen numeerisen kokonaislukujen syöttökentän.
 *
 * Miksi inputMode?
 * https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/
 * @param type
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const IntegerField = ({ type, children, ...rest }) => (
    <Field type={type} {...rest} inputMode="numeric">
        {children}
    </Field>
);

IntegerField.propTypes = {
    type: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

IntegerField.defaultProps = {
    type: 'text',
    children: undefined,
};
