import React from 'react';
import ReactDOM from 'react-dom';
import ConfirmDialog from 'shared/components/ConfirmDialog';

const createConfirmation = (Component) => (props) => {
    const wrapper = document.body.appendChild(document.createElement('div'));

    const promise = new Promise((resolve, reject) => {
        try {
            ReactDOM.render(
                <Component
                    reject={reject}
                    resolve={resolve}
                    dispose={dispose}
                    {...props}
                />,
                wrapper
            );
        } catch (e) {
            console.error(e);
            throw e;
        }
    });

    function dispose() {
        ReactDOM.unmountComponentAtNode(wrapper);
        setTimeout(() => wrapper.remove());
    }

    return promise.then((result) => {
        dispose();
        return result;
    }, (result) => {
        dispose();
        return Promise.reject(result);
    });
};

const confirm = createConfirmation(ConfirmDialog);

export default (confirmation, options = {}) => confirm({ confirmation, options });
