import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateRangeInput from './DateRangeInput';

/**
 * Yhden päivämäärän kalenterivalinta.
 */
class DateInput extends Component {
    static propTypes = {
        placeholder: PropTypes.string,
    };

    static defaultProps = {
        placeholder: _trans('date_range.start'),
    };

    render() {
        return (
            <DateRangeInput
                {...this.props}
                hasSingleInput
                startDatePlaceholder={this.props.placeholder}
            />
        );
    }
}

export default DateInput;
