import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import _ from 'lodash';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import routes from '../routes';
import { Feedback, LinkIcon, Page } from 'shared/components';
import DataTable from 'shared/containers/DataTable';
import { tradeUnionSubscriptionTypes } from 'shared/constants/tradeUnionSubscriptionTypes';
import { resolveUrl } from 'shared/utils';
import TradeUnionReferenceForm from 'shared/containers/TradeUnion/TradeUnionReferenceForm';
import { referenceNumberTypes } from 'shared/constants/tradeUnion';

@connect((state) => ({
    tradeUnions: select.tradeUnion.getActiveTradeUnionSubscriptions(state),
    isReferenceNumbersPage: select.tradeUnion.isReferenceNumbersPage(state),
    isLoading: _.get(state, 'loading.effects.tradeUnion.fetchActiveTradeUnionSubscriptions'),
}))
export default class ActiveTradeUnionList extends Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        tradeUnions: PropTypes.instanceOf(List),
        dispatch: PropTypes.func,
        isCommuneBundle: PropTypes.bool,
        isReferenceNumbersPage: PropTypes.bool,
    };

    static defaultProps = {
        isLoading: false,
        tradeUnions: List(),
        dispatch () {},
        isCommuneBundle: false,
        isReferenceNumbersPage: false,
    };

    constructor(props) {
        super(props);
        props.dispatch.tradeUnion.fetchActiveTradeUnionSubscriptions();
    }

    openReferenceNumberForm = (tradeUnion) => {
        this.props.dispatch.tradeUnion.setReferenceNumbersPage(true);
        this.setState({ tradeUnion });
    };

    render() {
        const { isLoading, isCommuneBundle, isReferenceNumbersPage } = this.props;
        const tradeUnions = this.props.tradeUnions.toJS();

        if (isReferenceNumbersPage) {
            return (
                <TradeUnionReferenceForm
                    referenceNumberType={referenceNumberTypes.COMMUNE}
                    tradeUnionId={_.get(this.state, 'tradeUnion.id')}
                />
            );
        }
        return (
            <Page
                heading={isCommuneBundle
                    ? _trans('commune_settings.reference_numbers.title')
                    : _trans('company.settings.trade_union_reference_numbers.title')
                }
            >
                <Feedback
                    type="info"
                    message={isCommuneBundle
                        ? _trans('commune_settings.reference_numbers.info_tooltip')
                        : _trans('company.settings.trade_union_reference_numbers.info_tooltip')
                    }
                />
                <DataTable
                    isLoading={isLoading}
                    data={tradeUnions}
                    columns={[
                        {
                            Header: _trans('company.settings.trade_union.name'),
                            accessor: 'name',
                        },
                        {
                            id: 'percentage',
                            Header: _trans('company.settings.trade_union.percentage'),
                            accessor: (r) => r.percentage ? `${_numberFormat(r.percentage)} %` : '-'
                        },
                        {
                            Header: _trans('company.settings.trade_union.automatic_charge'),
                            accessor: 'type',
                            Cell: (props) =>
                                _.get(props, 'original.type', 0) === tradeUnionSubscriptionTypes.TYPE_JHL
                                    ? _trans('general.yes')
                                    : _trans('general.no'),
                            filterable: false
                        },
                    ]}
                    actionsColumn={
                        <ActionsColumn
                            isCommuneBundle={isCommuneBundle}
                            openReferenceNumberForm={(tradeUnion) => this.openReferenceNumberForm(tradeUnion)}
                        />
                    }
                    emptyDataMessage={_trans('userForm.tradeUnion.data_table.empty_data_message')}
                    emptyDataIcon="sad_face"
                    defaultSorted={[
                        {
                            id: 'type',
                            desc: false,
                        },
                        {
                            id: 'name',
                            desc: false,
                        }
                    ]}
                />
            </Page>
        );
    }
}

const ActionsColumn = ({ original, openReferenceNumberForm, isCommuneBundle }) => {
    const isAutomaticCharged = _.get(original, 'type', 0) === tradeUnionSubscriptionTypes.TYPE_JHL;
    const renderLink = () => (
        isCommuneBundle
            ? <LinkIcon
                mdIcon="reorder"
                href="#reference-numbers"
                isDisabled={isAutomaticCharged}
                onClick={() => openReferenceNumberForm(original)}
            >
                {_trans('trade_union_membership.reference_numbers.title')}
            </LinkIcon>
            : <LinkIcon
                mdIcon="reorder"
                to={resolveUrl(routes.COMPANY_TRADE_UNION_REFERENCE_NUMBERS_FORM, { tradeUnionId: _.get(original,'id') })}
            >
                {_trans('trade_union_membership.reference_numbers.title')}
            </LinkIcon>
    );

    return (isCommuneBundle && isAutomaticCharged) ? null : renderLink();
};

ActionsColumn.propTypes = {
    original: PropTypes.instanceOf(Object),
    openReferenceNumberForm: PropTypes.func,
    isCommuneBundle: PropTypes.bool,
};

ActionsColumn.defaultProps = {
    original: {},
    openReferenceNumberForm() {},
    isCommuneBundle: false,
};
