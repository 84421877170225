import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popover from 'shared/components/Popover';
import MDIcon from 'shared/components/MDIcon';

/**
 * Sivun otsikko.
 */
const PageHeading = ({ children, modifierClass, actionItems, headingTooltip }) => {
    let headingWithTooltip;

    const heading = (
        <h1 className={classNames('c-heading-page u-margin-bottom-none', { [modifierClass]: modifierClass })}>
            {children}
        </h1>
    );

    if (headingTooltip) {
        headingWithTooltip = (
            <span className="o-stack">
                <span>
                    {heading}
                </span>
                <span className="u-line-height-flat">
                    <Popover position="top" >
                        <button className="u-margin-left-small">
                            <MDIcon icon="help_outline"/>
                        </button>
                        <div style={{ maxWidth: '320px' }}>
                            {headingTooltip}
                        </div>
                    </Popover>
                </span>
            </span>
        );
    }

    return (
        <div className="u-padding-vertical-small o-page__heading">
            {headingTooltip ? headingWithTooltip : heading}
            {actionItems}
        </div>
    );
};

PageHeading.propTypes = {
    /**
     * Otsikon sisältö.
     */
    children: PropTypes.node.isRequired,

    /**
     * CSS-muokkausluokka.
     */
    modifierClass: PropTypes.string,

    /**
     * Sivun päätoiminnot. Tulevat otsikon oikealle puolen (deskarissa).
     */
    actionItems: PropTypes.node,

    /**
     * Sivun otsikon tooltip
     */
    headingTooltip: PropTypes.string,
};

PageHeading.defaultProps = {
    modifierClass: null,
    actionItems: null,
    headingTooltip: '',
};

export default PageHeading;
