import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const FormikErrors = ({ name, touchName }) => {
    /*const error = getIn(formik.errors, name);
    const touch = getIn(formik.touched, touchName || name);*/
    // Onko kentällä virheitä? Jos on muuta kentän reunaväri.
    const [, meta,] = useField(name || touchName);

    // Näytetään virheet, mikäli submitoitu tai ollaan koskettu kenttään
    // Koitetaan splitata omille riveilleen, jos on monta virhettä
    if (! (meta.touched && meta.error)) {
        return null;
    }

    const splitErrors = meta.error.split(';');
    return (
        // Id johon kentän (FormField) aria-describedby osoittaa. Ruudunlukija lukee tämän.
        <ul className="o-form__errors o-list-bare u-margin-bottom-none u-display-block" id={`${name}Errors`}>
            {splitErrors.map((err, index) => (
                <li key={index} className="o-form__error-item u-text-pre-line">
                    {typeof err === 'string'
                        ? (
                            <Fragment>
                                <i className="material-icons-outlined u-font-size-18" aria-hidden="true">warning</i>
                                <span className="u-margin-left-tiny u-align-middle" style={{ lineHeight: '18px' }}>{err}</span>
                            </Fragment>
                        )
                        : null
                    }
                </li>
            ))}
        </ul>
    );
};

FormikErrors.propTypes = {
    name: PropTypes.string.isRequired,
    /**
     * Jos tarkempi validaatio kohdistuu objektiin on "touched" silti listan itemillä eikä objektilla.
     */
    touchName: PropTypes.string,
};

FormikErrors.defaultProps = {
    touchName: undefined,
};

export default FormikErrors;
