/**
 * @deprecated
 */
/* eslint-disable */
import React, {PureComponent} from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Scroll from 'react-scroll';

/**
 * Renderöi annetut vaiheet ja niiden tilan.
 * Tarkoitettu yhden sivun pitkiin lomakkeisiin joissa on eri vaiheita.
 * @param steps
 * @returns {XML}
 * @constructor
 */
export default class Steps extends PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            steps,
        } = this.props;

        return (
            <aside className="c-steps-container">
                <nav className="c-steps">
                    <ul className="c-steps-list o-list-bare" role="menubar" aria-label="Vaiheet">
                        {_.map(steps, (step, key) => {
                            const scrollTo = _.get(step, 'id', '');
                            const isDisabled = _.get(step, 'isDisabled', false);

                            const sideNavItemClass = classNames('c-steps-list__item o-stack', {
                                'is-completed': step.isCompleted,
                                'is-disabled': isDisabled,
                            });

                            let content = null;

                            if (scrollTo !== '' && !isDisabled) {
                                content = (
                                    <Scroll.Link
                                        activeClass="is-active"
                                        to={scrollTo}
                                        duration={125}
                                        smooth
                                        isDynamic
                                        offset={-180}
                                        className={sideNavItemClass}
                                    >
                                        <span>{step.title}</span>
                                    </Scroll.Link>
                                );
                            } else {
                                content = (
                                    <span className={sideNavItemClass}>
                                        <span>{step.title}</span>
                                    </span>
                                );
                            }

                            return (
                                <li
                                    role="presentation"
                                    key={key}
                                >
                                    {content}
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </aside>
        );
    }
}

Steps.propTypes = {
    /**
     * Koostuu taulukosta joista jokainen solu
     * on objekti muodossa:
     * title - vaiheen nimi
     * id - vaiheen tunniste (käytetään linkin ankkurissa)
     * isCompleted - onko vaihe suoritettu
     * isDisabled - onko vaihe disabloitu, esim. kun vaaditaan että edellinen vaihe on suoritettu.
     */
    steps: PropTypes.array.isRequired,
};
