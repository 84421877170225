import React, { useState } from 'react';
import { LocalForm, Control, actions } from 'react-redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors, userActions } from '../../stores/user';
import { ActionBar, EmptyState, MDSpinner, OutLink, Page, SubmitButton } from 'shared/components';
import { StField, StLabel, StErrors } from 'shared/components/StForm';
import validators from 'shared/validators';
import { useGet } from 'shared/hooks/useApi';

const ChangePassword = () => {
    const [{ data, isLoading }] = useGet('/api/v2/user/self');
    let formDispatch = null;
    const dispatch = useDispatch();
    const userId = useSelector(userSelectors.getUserId);
    const [isSaving, setIsSaving] = useState(false);

    if (isLoading) {
        return (
            <MDSpinner wrapped />
        );
    }

    if (data.isVismaConnectUser) {
        return (
            <Page heading={_trans('users.password_change.title')} maxWidth="medium">
                <EmptyState
                    icon="identification"
                    message={_trans('Salasanan vaihto tapahtuu Visma Connect-palvelussa.', {}, 'userDetails')}
                    callToAction={<OutLink href={__DEV__ ? 'https://accountsettings.connect.identity.stagaws.visma.com/' : 'https://accountsettings.connect.visma.com/'}>
                        {_trans('Siirry Visma Connect-palveluun', {}, 'userDetails')}
                    </OutLink>}
                />
            </Page>
        );
    }

    return (
        <Page heading={_trans('users.password_change.title')} maxWidth="small">
            <LocalForm
                className="o-form o-form--vertical"
                onSubmit={(model) => {
                    setIsSaving(true);
                    dispatch(userActions.putUserPassword(userId, model))
                        .then(() => {
                            formDispatch(actions.reset('local'));

                        })
                        .finally(() => {
                            setIsSaving(false);
                        });
                }}
                validators={{
                    '': {
                        passwordsMatch: (values) => (values.newPassword === values.passwordConfirmation),
                        sameAsOldPassword: (values) => (values.password !== values.newPassword),
                    },
                }}
                getDispatch={(dispatch) => formDispatch = dispatch}
            >
                <StLabel htmlFor="local.password">
                    {_trans('users.password_change.password')}
                </StLabel>
                <StField>
                    <Control.password
                        id="local.password"
                        model=".password"
                        className="u-1/1"
                        validators={{
                            isRequired: validators.isRequired,
                        }}
                    />
                    <StErrors model=".password" />
                </StField>
                <StLabel htmlFor="local.newPassword">
                    {_trans('users.password_change.new_password')}
                </StLabel>
                <StField>
                    <Control.password
                        id="local.newPassword"
                        model=".newPassword"
                        className="u-1/1"
                        validators={{
                            isRequired: validators.isRequired,
                        }}
                    />
                    <StErrors model=".newPassword"/>
                </StField>
                <StLabel htmlFor="local.passwordConfirmation">
                    {_trans('users.password_change.password_confirmation')}
                </StLabel>
                <StField>
                    <Control.password
                        id="local.passwordConfirmation"
                        model=".passwordConfirmation"
                        className="u-1/1"
                        validators={{
                            isRequired: validators.isRequired,
                        }}
                    />
                    <StErrors model=".passwordConfirmation" />
                    <StErrors model="local"/>
                </StField>
                <ActionBar alignItems="right">
                    <SubmitButton isPending={isSaving} >{_trans('users.action.save_password')}</SubmitButton>
                </ActionBar>
            </LocalForm>
        </Page>
    );
};

export default ChangePassword;
