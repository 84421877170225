import React from 'react';
import ReactDOM from 'react-dom';
import domReady from 'domready';
import { ReactComponent as SafeBrowsingIcon } from 'images/safe-browsing.svg';
import { supportedBrowsers } from 'supportedBrowsers';

/**
 * Tutkii onko käytetty selain tukemiemme listalla (tutkii suoraan browserslistiä vasten).
 * Jos ei, pyytää päivittämään selaimen joko itse tai IT-tuen toimesta.
 *
 * @returns {*}
 * @constructor
 */
const OutdatedBrowser = () => {
    if (supportedBrowsers.test(navigator.userAgent)) {
        return null;
    }
    // Nalkutetaan ikuisesti silti yläpalkissa
    return (
        <div className="u-padding-small c-feedback--warning u-1/1 ">
            <div className="o-flag o-flag--small">
                <span className="o-flag__img">
                    <SafeBrowsingIcon />
                </span>
                <span className="o-flag__body">
                    {_transMd('outdated_browser.message', false)}
                </span>
            </div>
        </div>
    );
};

domReady(function() {
    // Vanhentuneesta selaimesta kertominen
    const rootNode = document.getElementById('root');

    // Luodaan main-tagin sisään bäkkäriltä tuleville notifikaatioille oma alue.
    const odBrowserNode = document.createElement('div');
    rootNode.insertBefore(odBrowserNode, rootNode.firstChild);

    ReactDOM.render(
        <OutdatedBrowser/>, odBrowserNode
    );
});

export default hot(OutdatedBrowser);
