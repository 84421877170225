import writeXlsxFile from 'write-excel-file';
import { customTypes } from 'shared/containers/DataTable';

const FONT_SIZE_FACTOR = 1;

export const exportXLS = async ({ fileName, headers, rows, exportColumnTotals }) => {
    // Otsikot excel-taulukon ekalle riville. Tyylitellään brändin mukaiseksi.
    const styledHeader = headers.map((column) => (
        {
            value: column,
            fontWeight: 'bold',
            height: 16 * FONT_SIZE_FACTOR,
            alignVertical: 'center',
            backgroundColor: '#ffdb70'
        }
    ), []);

    // Alustetaan sarakeleveydet
    const columnWidths = styledHeader.map((column) => (column.value.toString()).length * FONT_SIZE_FACTOR + 1);

    // Käydään kaikki data läpi ja resolvoidaan metadatan mukaisesti arvot, formaatit ja tyypit
    const data = rows
        .reduce((allRows, row) => {
            const rowColumns = row.reduce((allColumns, column, idx) => {
                // Sarakkeen leveyden vertailuun
                const value = transformValueByType(column);

                const columnWidth = value ? (value.toString().length * FONT_SIZE_FACTOR + 1) : 0;
                //console.log(columnWidth, columnWidths[idx], column);
                // Kyllä. Tässä mutatoidaan mutta eipä tällä ole isoa vauriota muuhun dataan.
                if (columnWidth > columnWidths[idx]) {
                    columnWidths[idx] = columnWidth;
                }

                return [
                    ...allColumns,

                    transformColumn(column)
                ];
            }, []);
            return [
                ...allRows,
                rowColumns,
            ];
        }, [styledHeader]);

    // Jos löytyy totals arvoja, niin lisätään ne pohjalle.
    const footerColumns = Object.entries(headers).map(([, key]) => {
        if (! (key in exportColumnTotals)) {
            return {
                value: '',
            };
        }
        return {
            value: parseFloat(exportColumnTotals[key]),
            type: Number,
            align: 'right',
            fontWeight: 'bold',
        };
    });

    return await writeXlsxFile(data.concat([footerColumns]), {
        // Sarakkeiden leveydet
        columns: columnWidths.map((column) => ({ width: column })),
        fileName,
        //fontFamily: 'Courier New',
        fontSize: 12,
        dateFormat: _dateTimeFormat,
    });
};

/**
 * Riippuen sarakkeen tyypistä lisätään sille tarvittavaa metadataa.
 * @param value
 * @param type
 * @param defaultValue
 * @returns {{align: string, value}|{format: string, type: NumberConstructor, value}|{format: string, align: string, type: NumberConstructor, value}|{format: string, align: string, type: DateConstructor, value: Date}|{align: string, value: string}}
 */
const transformColumn = ({ value, type, defaultValue = '' }) => {
    switch (type) {
        case customTypes.NUMBER:
            return {
                value,
                align: 'right',
                type: Number,
                format: '#,##0.00',
            };

        case customTypes.CURRENCY:
            return {
                value,
                align: 'right',
                type: Number,
                // Miinukselle mennessä näytetään punaisena
                format: '#,##0.00 €;[Red]−#,##0.00 €;',
            };

        case customTypes.DATE: {
            // Jos arvoa ei löydy muutetaan tyyppi tekstiksi (oletus) ja käytetään annettua oletusarvoa.
            if (! value) {
                return {
                    align: 'left',
                    value: defaultValue,
                };
            }

            return {
                align: 'left',
                value: new Date(_toLocaleDate(value, defaultValue, 'YYYY-MM-DD')),
                type: Date,
                format: _dateFormat,
            };
        }

        case customTypes.DATETIME:
            // Jos arvoa ei löydy muutetaan tyyppi tekstiksi (oletus) ja käytetään annettua oletusarvoa.
            if (! value) {
                return {
                    align: 'left',
                    value: defaultValue,
                };
            }

            return {
                align: 'left',
                value: new Date(_toLocaleDate(value, defaultValue, 'YYYY-MM-DD HH:mm:ss')),
                type: Date,
                format: _dateTimeFormat,
            };

        case customTypes.BOOLEAN:

            if (! value) {
                return {
                    align: 'center',
                    value: _trans('Ei', {}, 'common'),
                };
            }

            return {
                align: 'center',
                value: _trans('Kyllä', {}, 'common')
            };

        default: {
            return {
                align: 'left',
                value,
            };
        }
    }
};

const transformValueByType = ({ value, type, defaultValue }) => {
    switch (type) {
        case customTypes.CURRENCY:
            return _currency(value);
        case customTypes.DATE:
            return _toLocaleDate(value, defaultValue);
        case customTypes.DATETIME:
            return _toLocaleDate(value, defaultValue, _dateTimeFormat);
        default:
            return value;
    }
};
