import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { select } from '@rematch/select';
import { rolePages } from '../constants';
import { RoleSelector } from './RoleSelector';
import userRoles from 'shared/constants/userRoles';

/**
 * Roolinvalinta kirjautumisen jälkeen. Käyttäjän perusteella näytetään:
 *
 * - Käyttäjällä yrityksiä => yrityslistanäkymään
 * - Kuntia => kuntalistanäkymään
 * - Sekä yrityksiä että kuntia => yritys/kunta-roolit ensin ja sitten loppulista
 * - Ei ole kumpiakaan, mutta vanha TA/TT valinta => ohjataan suoraan aiempaan TA/TT rooliin
 * - Ei kumpaakan, eikä vanhaa valintaa => vain TA/TT valinta
 *
 * @returns {*}
 * @constructor
 */
export const RoleSelectorDialog = () => {
    const dispatch = useDispatch();
    const userRole = useSelector(select.header.getUserRole);
    const hasSelectedRole = useSelector(select.header.hasSelectedRole);
    const companies = useSelector(select.roleSelector.getCompanies);
    const communes = useSelector(select.roleSelector.getCommunes);

    useEffect(() => {
        // Asetetaan listasivut valmiiksi valituiksi mutta vain jos listalla on 5 tai enemmän valintoja.
        if (userRole === userRoles.COMMUNE_OPERATOR && communes.length >= 5) {
            dispatch.roleSelector.setPage(rolePages.COMMUNE_LIST);
        } else if (companies.length >= 5) {
            dispatch.roleSelector.setPage(rolePages.COMPANY_LIST);
        }
    }, [communes, companies, dispatch, userRole]);

    return (
        <div className={classNames('c-role-selector-dialog o-pin o-pin--fixed o-pin--left o-pin--right o-pin--bottom u-animation-slide-in-down u-overflow-vertical--scroll u-z-index-5', {
            'c-role-selector-dialog--has-role': hasSelectedRole,
        })}>
            <div className="c-role-selector__container">
                <RoleSelector />
            </div>
        </div>
    );
};

