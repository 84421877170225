import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated } from 'react-spring/web.cjs';
import _ from 'lodash';
import Feedback from './Feedback';

/**
 * Simppeli palautelista bäkkäriltä tuleville viesteille.
 * Useamman palauteviestin tuki. Ryhmittelee samantyyppiset palautteet listaksi.
 */
const FeedbackList = (props) => {
    // Ryhmitellään palauteviestit tyypin mukaan.
    const [ feedbackGroup, setFeedbackGroup ] = useState(
        _.groupBy(
            _.get(props, 'feedbacks', []),
            (feedback) => feedback.type
        ),
    );

    const transitions = useTransition(feedbackGroup, (feedback) => feedback.key, {
        from: {
            opacity: 0,
            translateY: -24,
        },
        leave: {
            opacity: 0,
            translateY: -24,
        },
        enter: {
            opacity: 1,
            translateY: 0,
        },
    });

    return (
        <ul className="c-feedback-list o-list-bare u-margin-bottom-none" key="feedbackList">
            {transitions.map(({ item, props, key }) => {
                const types = Object.keys(feedbackGroup);
                if (! types.length) return null;

                const type = _.head(types);
                const feedbacks = item[type];

                return (
                    <animated.li className="c-feedback-list__item" style={props} key={key}>
                        <Feedback
                            type={type}
                            message={_.size(feedbacks) > 1 ? feedbacks : _.head(feedbacks).message}
                            isDismissable
                            onFeedbackClose={() => {
                                // Suljetaan yksittäinen palauteryhmä.
                                setFeedbackGroup(
                                    _.pickBy(feedbackGroup, (value, feedbackType) => feedbackType !== type)
                                );
                            }}
                        />
                    </animated.li>
                );
            })}
        </ul>
    );
};

FeedbackList.propTypes = {
    feedbacks: PropTypes.array,
};

FeedbackList.defaultProps = {
    feedbacks: [],
};

export default FeedbackList;
