import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useField } from 'formik';
import { select } from '@rematch/select';
import { useSelector } from 'react-redux';
import { useDidUpdate } from '@mantine/hooks';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { ChoiceField, FormField, HiddenField } from 'shared/components/Formik';
import { errorMessages } from 'shared/validators';
import { Value } from 'shared/components/Value';
import { usePrevious } from 'shared/hooks/previous';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';
import { resolveDefaultValue } from 'shared/ReForm/utils/resolveDefaultValue';
import { getCustomComponentByFieldName } from 'ContractV3/utils/getCustomComponentByFieldName';
import _transObj from 'shared/utils/_transObj';

/**
 * Taulukkopalkan valinnat. Useampi palikka käyttää tätä (TableSalaryAttributes, CostReimbursementAttributes...)
 * @param props
 * @returns {*}
 * @constructor
 */
const TableSalaryChoices = ({ name, originalName, options, arrayName, onChange, onLastOptionChange }) => {
    // Taulukkopalkan arvot (salary.salaries.NN.tableSalaryAttributes)
    const [field,, helpers] = useField(name);
    const [,,initialSalaryHelpers] = useField(resolveArrayName(arrayName, fieldNames.INITIAL_SALARY));

    const isLoadingCollectiveAgreementRestrictions = useSelector((state) => state.loading.effects.contract.fetchCollectiveAgreementRestrictions);
    const isLoadingTableSalaryMetadata = useSelector((state) => state.loading.effects.tableSalary.fetchTableSalaryMetadata);

    // Kentän nimen muodostaminen
    const resolveName = (option) => `${name}.${option.name}`;
    const getOptionValue = (optionName) => field.value ? field.value[optionName] : null;

    // TES
    const [collectiveAgreementField] = useField(fieldNames.COLLECTIVE_AGREEMENT);
    const collectiveAgreementId = collectiveAgreementField.value;
    const previousCollectiveAgreementId = usePrevious(collectiveAgreementId);

    const tableSalaryDate = useSelector(select.contract.getTableSalaryDate);

    // Salaarityyppi
    const [salaryTypeField] = useField(fieldNames.SALARY_TYPE);
    const salaryType = salaryTypeField.value;
    const previousSalaryType = usePrevious(salaryType);
    const previousValues = usePrevious(field.value);

    // Kaikki valinnat tehty. Haetaan taulukkopalkat annetuilla valinnoilla jos virheitä ei löydy.
    useDidUpdate(() => {
        // Ei tarkista onko ne oikeat... ehkä voisi vielä.
        const values = Object.entries(field.value ?? {}).filter(([, value]) => value !== '');
        // Löytyvätkö valitut arvot mahdollisista arvoista. Kaikista tulee löytyä mätsi.
        const hasOptionsMatch = values.every(([name]) => options.find((option) => option?.name === name));

        const isValid = (
            (values.length === options.length || Object.values(field.value ?? {}).includes('no-op'))
            && values.length > 0
            // Varmistetaan että kaikki valinnat menneet perille
            && JSON.stringify(field.value ?? {}) === JSON.stringify(previousValues ?? {})
            && hasOptionsMatch
            // Varmistetaan että TES-valinta on varmasti mennyt läpi
            && previousCollectiveAgreementId === collectiveAgreementId
            && previousSalaryType === salaryType
            && ! isLoadingCollectiveAgreementRestrictions
            && ! isLoadingTableSalaryMetadata
        );

        if (isValid) {
            // Passataan muutos ylöspäin annetuilla arvoilla.
            onChange({
                collectiveAgreement: collectiveAgreementId,
                values: field.value,
                date: tableSalaryDate,
            });
        }
    }, [
        collectiveAgreementId,
        previousCollectiveAgreementId,
        field.value,
        previousValues,
        options.length,
        tableSalaryDate,
        salaryType,
        previousSalaryType,
        isLoadingCollectiveAgreementRestrictions,
        isLoadingTableSalaryMetadata
    ]);

    const optionNames = options.map((option) => option.name);
    const firstOptionValue = getOptionValue(optionNames[0]);

    const filteredOptions = options
        // Tyhjät pois
        .filter((option) => option.options.length > 0);

    const lastOption = filteredOptions[filteredOptions.length - 1];
    const [lastOptionField] = useField(resolveName(lastOption ?? { name: '' }));
    useDidUpdate(() => {
        const option = lastOption?.options.find((option) => option.value === lastOptionField.value);
        if (option) {
            onLastOptionChange(option);
        }
    }, [(filteredOptions ?? []).length, lastOptionField.value, (lastOption?.options ?? []).length]);

    // Ensimmäinen vaihtoehto muuttuu. Resetoidaan (tai esiasetetaan) loppujen arvot jotta eivät jää kummittelemaan.
    useDidUpdate(() => {
        const restOptions = options.slice(1);
        // Luodaan value-objektipompsi muille kuin ensimmäiselle valitulle valinnalle.
        const restValues = restOptions.reduce((acc, option) => {
            // Jos ei löydy yhtä ja vain yhtä arvoa ei lisätä objektipompsiin
            // koska muutoin alempi merge ei ylikirjoita löytyvää tyhjää arvoa.
            if (option.options.length !== 1) return acc;

            return (
                Object.assign({}, acc, {
                    // Vain yksi arvo niin valitaan suoraan
                    [option.name]: option.options[0].value,
                })
            );
        }, {});

        if (originalName === fieldNames.INITIAL_SALARY) {
            initialSalaryHelpers.setValue(
                resolveDefaultValue(getCustomComponentByFieldName(fieldNames.INITIAL_SALARY))
            );
        }
        helpers.setValue(Object.assign({}, field.value, restValues), false);
    }, [firstOptionValue]);

    // Mapataan jokainen valinta omaksi kentäkseen
    return filteredOptions
        .map((option, key) => {
            const name = resolveName(option);

            // Tämänhetkinen _mahdollinen_ arvo. Kaivetaan salaries:n alta koska ei voida vertailla
            // yhtä ja samaa tableSalaries-objektia (salaries.NN.tableSalaries). Tämä aiheuttaisi sen että useamman palkkion ollessa kyseessä
            // validointi menisi päin persettä, validoiden kaikki jos yksikin palkkio olisi kokonaisuudessaan
            // valittu oikein.
            const value = getOptionValue(option.name);

            // puotetaan semmoiset valinnat pois jotka ei ole voimassa
            const filteredOptions = option.options
                .filter((opt) =>
                    opt.expireDate && tableSalaryDate
                        ? new Date(tableSalaryDate) <= new Date(opt.expireDate)
                        : true)
                .filter((opt) =>
                    opt.effectiveDate && tableSalaryDate
                        ? new Date(tableSalaryDate) >= new Date(opt.effectiveDate)
                        : true)
                ;

            // Vain yksi arvo. Valitaan se ja näytetään valittu ihmisluettava arvo.
            if (filteredOptions.length === 1) {
                const { label, value } = filteredOptions[0];
                return (
                    <FormField label={_transObj(option.label)} key={key} isContentFormField={false} name={option.name}>
                        <Fragment>
                            <Value ariaDescribedBy={option.name}>{label}</Value>
                            <HiddenField name={name} value={value} />
                        </Fragment>
                    </FormField>
                );
            }

            // Muuten pyydetään valitsemaan.
            return (
                <Fragment key={key}>
                    <FormField label={_transObj(option.label)} name={name} isRequired>
                        <ChoiceField
                            options={filteredOptions}
                            validate={() => {
                                // Tarkistetaan tässä että arvo varmasti löytyy eikä sinne ole jäänyt vain roskaa
                                // kummittelemaan.
                                const hasValueInOptions = option.options.findIndex((option) => option.value === value);
                                // Ei olla valittu mitään tai valittua arvoa ei löydy
                                if (! value || hasValueInOptions === -1) {
                                    return errorMessages.isRequired;
                                }
                            }}
                        />
                    </FormField>
                    {/*Rumaa mutta ei löytynyt muutakaan keinoa tähän hätään eli luodaan apin palauttaman namen mukainen tyhjä arvo Formikin stateen*/}
                    {!value && <HiddenField name={name} value="" />}
                </Fragment>
            );
        });
};

TableSalaryChoices.propTypes = {
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onLastOptionChange: PropTypes.func,
};

TableSalaryChoices.defaultProps = {
    onLastOptionChange() {},
};

export default TableSalaryChoices;
