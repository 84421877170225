import React from 'react';
import PropTypes from 'prop-types';

const SummaryBlock = ({ heading, children }) => (
    <div className="o-summary-block u-position-relative">
        <strong className="o-summary-block__heading u-margin-bottom-small u-display-block">
            {heading}
        </strong>
        <div className="o-summary-block__content">
            {children}
        </div>
    </div>
);

SummaryBlock.propTypes = {
    heading: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default SummaryBlock;
