import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { Field } from 'shared/components/Formik';

export const DivisorField = ({ children, name, onCustomChange, ...rest }) => {
    const [field,, helpers] = useField(name);

    // Muunnetaan pilkut pisteiksi jotta on yhteensopiva bäkkärin kanssa.
    const convertCommaToPeriod = (event) => {
        helpers.setValue(event.target.value?.toString().replace(',', '.'));
        onCustomChange(parseFloat(event.target.value));
    };

    return (
        <Field
            type="text"
            name={name}
            onChange={convertCommaToPeriod}
            onBlur={convertCommaToPeriod}
            value={field.value?.toString().replace('.', ',')}
            {...rest}
            inputMode="decimal"
        >
            {children}
        </Field>
    );
};

DivisorField.propTypes = {
    onCustomChange: PropTypes.func,
    name: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

DivisorField.defaultProps = {
    onCustomChange() {},
    name: undefined,
    children: undefined,
};
