import { useEffect, useRef } from 'react';

// useEffect, joka "skipataan" mountissa eli käytännössä vastaa componentDidUpdate
export const useDidUpdateEffect = (fn, inputs) => {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current)
            fn();
        else
            didMountRef.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, inputs);
};
