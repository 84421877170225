import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { select } from '@rematch/select';
import { useParams } from 'react-router-dom';
import Separator from 'shared/components/Separator';
import { PartySelector } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Parties/Party/PartySelector';
import { FormField, FormikErrors } from 'shared/components/Formik';
import _transObj from 'shared/utils/_transObj';
import { SelectedParty } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Parties/Party/SelectedParty';
import Button from 'shared/components/Button';
import { generateRoutes } from 'ContractV3/constants/routes';
import { isCommune } from 'shared/utils/commonUtils';
import resolveUrl from 'shared/utils/resolveUrl';
import { getPartyQueryByRelation } from 'ContractV3/utils/getPartyQueryByRelation';
import relationTypes from 'shared/constants/companyUserRelationTypes';
import { fieldNames } from 'ContractV3/constants/fieldNames';

const routes = generateRoutes(isCommune());

/**
 * Uniikki id input-elementille relaation mukaan.
 * @param relationType
 * @returns {string}
 */
const relationToText = (relationType) => {
    switch (relationType) {
        case relationTypes.RELATION_EMPLOYEE:
            return 'employee';

        case relationTypes.RELATION_CARED:
            return 'cared';

        default:
            return 'party';
    }
};

/**
 * Osapuolten filtteröitävä lista.
 */
export const Party = ({ placeholder, fieldProps, selectedParty, asyncEndPoint, isSsnQuery, filterResults, isLoading, translations, userRole, relationType, onRemoveParty, onSelectParty }) => {
    const dispatch = useDispatch();
    const benefitDecisionId = useParams()?.benefitDecisionId;
    const templateId = useSelector(select.contract.getTemplateId);
    const isDemoMode = useSelector(select.contract.isDemoMode);
    const { genitive, noResultsAddNewText, noResultsText } = translations;
    const { name, rootName } = fieldProps;

    // Useampaa hoidettavaa (tai jatkossa myös hoitajia) lisättäessä tarvitaan tieto siitä
    // mihin kohtaan lomaketta henkilö päivitetään (arrayName)
    const arrayName = [fieldNames.EMPLOYEE, fieldNames.CARED].includes(name)
        ? ''
        : `&name=${name}`;

    // Ohjaa takaisin ContractForm:lle jossa queryparametrista katsotaan mikä formi (employee, customer) näytetään.
    const addNewPartyUrl = `${resolveUrl(routes.NEW_CONTRACT, { benefitDecisionId })}?template=${templateId}${getPartyQueryByRelation(relationType)}${arrayName}`;
    const addButton = (
        <Button
            outline
            mdIcon="person_add"
            modifierClass="u-1/1"
            onClick={() => dispatch(push(addNewPartyUrl))}
        >
            {_trans('contract.form.parties.party.button.add_new_party_data', { party: _transObj(genitive) })}
        </Button>
    );

    const id = `${relationToText(relationType)}Description`;

    // Edellisiä osapuolia löydetty. Pyydetään käyttäjää valitsemaan.
    return (
        <FormField {...fieldProps} isContentFormField={! selectedParty} canShowErrors={false}>
            {selectedParty
                ? (
                    <SelectedParty
                        name={name}
                        relationType={relationType}
                        selectedParty={selectedParty}
                        onRemoveParty={onRemoveParty}
                    />
                )
                : (
                    <Fragment>
                        <div aria-describedby={id}>
                            <PartySelector
                                name={name}
                                addNewPartyUrl={addNewPartyUrl}
                                onSelectParty={onSelectParty}
                                asyncEndPoint={asyncEndPoint}
                                isSsnQuery={isSsnQuery}
                                filterResults={filterResults}
                                userRole={userRole}
                                placeholder={placeholder}
                                noResultsText={_transObj(noResultsText)}
                                noResultsAddNewText={_transObj(noResultsAddNewText)}
                                isPending={isLoading}
                            />
                            <FormikErrors name={rootName} />
                        </div>

                        {/*Demotilassa ei sallita käyttäjien lisäämisiä*/}
                        {!(isDemoMode) && (
                            <Fragment>
                                <Separator title={_trans('or')}/>
                                {addButton}
                            </Fragment>
                        )}
                    </Fragment>
                )}
        </FormField>
    );
};

Party.propTypes = {
    placeholder: PropTypes.string.isRequired,
    fieldProps: PropTypes.object.isRequired,
    userRole: PropTypes.number,
    selectedParty: PropTypes.object,
    asyncEndPoint: PropTypes.string.isRequired,
    isSsnQuery: PropTypes.bool,
    filterResults: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    translations: PropTypes.object,
    relationType: PropTypes.number.isRequired,
    onRemoveParty: PropTypes.func.isRequired,
    onSelectParty: PropTypes.func.isRequired,
};

Party.defaultProps = {
    isSsnQuery: false,
    selectedParty: null,
    translations: {},
    userRole: -1,
    filterResults: (results) => results,
};
