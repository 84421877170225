import { produce } from 'immer';
import _ from 'lodash';
import api from 'api';
import { actions as notificationActions } from 'shared/stores/notifications';
import { types } from 'shared/constants/contract';
import { userTypes } from 'shared/constants/userTypes';

const emptyState = {
    contracts: [],
    selectedContracts: [],
    rangeType: null,
    // rangeStart: null,
    // rangeEnd: null,
    hoursPerWeek: null,
    // dateTo: null,
    isSummaryFetched: false,
};

export const weeklyHoursStatement = {
    state: emptyState,
    reducers: {
        setContracts: (state, payload) => produce(state, (newState) => {
            newState.contracts = payload;
        }),
        resetWeeklyHoursStatement: () => emptyState,
        setSelectedContracts: (state, payload) => produce(state, (newState) => {
            newState.selectedContracts = payload;
        }),
        setRangeType: (state, payload) => produce(state, (newState) => {
            newState.rangeType = payload;
        }),
        // setDateRange: (state, payload) => produce(state, (newState) => {
        //     newState.rangeStart = payload.start;
        //     newState.rangeEnd = payload.end;
        // }),
        setHoursPerWeek: (state, payload) => produce(state, (newState) => {
            newState.hoursPerWeek = payload;
        }),
        // setDateTo: (state, payload) => produce(state, (newState) => {
        //     newState.dateTo = payload;
        // }),
    },
    selectors: {
        getContracts: (state) => state.contracts,
        getContractById: (state, id) => state.contracts.find((contract) => contract.jobContractId === id),
        getSelectedContracts: (state) => state.selectedContracts,
        getRangeType: (state) => state.rangeType,
        // getRangeStart: (state) => state.rangeStart,
        // getRangeEnd: (state) => state.rangeEnd,
        getHoursPerWeek: (state) => state.hoursPerWeek,
        // getDateTo: (state) => state.dateTo,
        /**
         * Onko kyseessä toimeksiantosopimus.
         * @param state
         * @param contractId
         * @returns {boolean}
         */
        isAssignmentContract: (state, contractId) => {
            const contract = state.contracts.find((contract) => _.get(contract, 'jobContractId') === parseInt(contractId, 10));
            return contract
                ? _.get(contract, 'contractType') === types.ASSIGNMENT_CONTRACTS
                : false;
        },
        isCompanyContract: (state, contractId) => {
            const contract = state.contracts.find((contract) => _.get(contract, 'jobContractId') === parseInt(contractId, 10));
            return contract
                ? _.get(contract, 'contract.employerDetails.type') === userTypes.COMPANY_SHADOW
                : false;
        }
    },
    effects: (dispatch) => ({
        /**
         * Hakee työntekijän sopimukset
         *
         * @param employee
         * @param employer
         * @return {Promise<void>}
         */
        async fetchContracts({ employee }) {
            try {
                //const employerId = employer ? `&employerId=${employer}` : '';
                //const json = await api.get(`/api/contracts/v1/users/${employee}?${employerId}`);
                const json = await api.get(`/api/contracts/v1/wages-certificate/${employee}?useCase=employee&weeklySalary=1`);

                const contracts = (json.contracts ?? []);
                this.setContracts(contracts);
            } catch (e) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('Sopimuksia ei voitu hakea', {}, 'extract'),
                }));
            }
        },
    })
};
