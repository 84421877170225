
export const additionalBenefitsTypeMap = {
    carBenefit: _trans('contract.form.salary.additional_benefits.carBenefit.label'),
    residenceBenefit: _trans('contract.form.salary.additional_benefits.residenceBenefit.label'),
};

const benefitAdditionalFieldsMap = {
    carBenefitCode: _trans('time_registration.additional_data.car_benefit.fields.car_benefit_code.label'),
    ageGroupCode: _trans('time_registration.additional_data.car_benefit.fields.age_group_code.label'),
    unitPrice: _trans('time_registration.additional_data.car_benefit.fields.unit_price.label'),
};

const carBenefitCodeValueMap = {
    1: _trans('time_registration.additional_data.car_benefit.fields.car_benefit_code.use_benefit'),
    2: _trans('time_registration.additional_data.car_benefit.fields.car_benefit_code.free_benefit'),
};

const carBenefitAgeGroupValueMap = {
    1: 'A',
    2: 'B',
    3: 'C',
    4: 'U'
};

const translateCarBenefitCodeValue = (value) =>
    value in carBenefitCodeValueMap ? carBenefitCodeValueMap[value] : '';

const translateCarBenefitAgeGroupValue = (value) =>
    value in carBenefitAgeGroupValueMap ? carBenefitAgeGroupValueMap[value] : '';

export const translateCarBenefitAdditionalValues = (field, value) => {
    switch (field) {
        case 'carBenefitCode':
            return translateCarBenefitCodeValue(value);
        case 'ageGroupCode':
            return translateCarBenefitAgeGroupValue(value);
        case 'unitPrice':
            return _currency(value);
    }
};

export const translateCarBenefitAdditionalField = (field) =>
    field in benefitAdditionalFieldsMap ? benefitAdditionalFieldsMap[field] : '';

export const translateAdditionalBenefit = (type) =>
    type in additionalBenefitsTypeMap ? additionalBenefitsTypeMap[type] : '';

export const translateResidenceBenefitAdditionalField = (field) =>
    field in benefitAdditionalFieldsMap ? benefitAdditionalFieldsMap[field] : '';
