import React from 'react';
import { select } from '@rematch/select';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton } from 'shared/components';
import { componentSizes } from 'shared/constants';
import confirm from 'shared/utils/confirm';

export const PanelActionItems = ({ taxCardId, isOverrideVeroFetch }) => {
    const dispatch = useDispatch();
    const isEditMode = useSelector(select.taxCards.isEditMode);
    const isReadOnly = useSelector((state) => select.taxCards.isReadOnly(state, taxCardId));
    const isVeroFetched = useSelector((state) => select.taxCards.isVeroFetchedTaxCard(state, taxCardId));

    if (isEditMode) {
        return null;
    }

    return (
        <div>
            <IconButton
                mdIcon="edit"
                size={componentSizes.SMALL}
                modifierClass="u-margin-right-tiny"
                onClick={() => {
                    dispatch.taxCards.setIsEditMode(true);
                    dispatch.taxCards.setSelectedTaxCardId(taxCardId);
                    dispatch.taxCards.setIsOverrideVeroFetch(isOverrideVeroFetch);
                }}
                disabled={isReadOnly && ! isVeroFetched}
            >
                {_trans('button.edit')}
            </IconButton>
            <IconButton
                mdIcon="delete"
                size={componentSizes.SMALL}
                disabled={isReadOnly}
                onClick={() => confirm(_trans('tax_card.remove_tax_card'),
                    {
                        proceedLabel: _trans('button.remove'),
                        cancelLabel: _trans('button.cancel'),
                        alert: true,
                    })
                    .then(() => dispatch.taxCards.deleteTaxCard(taxCardId))
                }
            >
                {_trans('button.remove')}
            </IconButton>
        </div>
    );
};

PanelActionItems.propTypes = {
    taxCardId: PropTypes.number,
    isOverrideVeroFetch: PropTypes.bool
};

PanelActionItems.defaultProps = {
    taxCardId: null,
    isOverrideVeroFetch: null
};
