import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Immutable from 'immutable';
import calendar from 'calendar';

import BemMixin from './utils/BemMixin';
import CustomPropTypes from './utils/CustomPropTypes';
import Legend from './Legend';

import CalendarMonth from './calendar/CalendarMonth';
import CalendarDate from './calendar/CalendarDate';

import isMomentRange from './utils/isMomentRange';
import hasUpdatedValue from './utils/hasUpdatedValue';
import { getYearMonth, getYearMonthProps } from './utils/getYearMonth';
import Button from 'shared/components/Button';

const moment = extendMoment(Moment);

const absoluteMinimum = moment(new Date(-8640000000000000 / 2)).startOf('day');
const absoluteMaximum = moment(new Date(8640000000000000 / 2)).startOf('day');

const date = new Date();

export default class DateRangePicker extends BemMixin {
    static propTypes = {
        bemBlock: PropTypes.string,
        bemNamespace: PropTypes.string,
        className: PropTypes.string,
        dateStates: PropTypes.array, // an array of date ranges and their states
        defaultState: PropTypes.string,
        disableNavigation: PropTypes.bool,
        firstOfWeek: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
        helpMessage: PropTypes.string,
        initialDate: PropTypes.instanceOf(Date),
        initialFromValue: PropTypes.bool,
        initialMonth: PropTypes.number, // Overrides values derived from initialDate/initialRange
        initialRange: PropTypes.object,
        initialYear: PropTypes.number, // Overrides values derived from initialDate/initialRange
        locale: PropTypes.string,
        maximumDate: PropTypes.instanceOf(Date),
        minimumDate: PropTypes.instanceOf(Date),
        numberOfCalendars: PropTypes.number,
        onHighlightDate: PropTypes.func, // triggered when a date is highlighted (hovered)
        onHighlightRange: PropTypes.func, // triggered when a range is highlighted (hovered)
        onSelect: PropTypes.func, // triggered when a date or range is selected
        onSelectStart: PropTypes.func, // triggered when the first date in a range is selected
        selectedLabel: PropTypes.string,
        selectionType: PropTypes.oneOf(['single', 'range']),
        singleDateRange: PropTypes.bool,
        showLegend: PropTypes.bool,
        stateDefinitions: PropTypes.object,
        value: CustomPropTypes.momentOrMomentRange,
    };

    static defaultProps = {
        bemNamespace: null,
        bemBlock: 'DateRangePicker',
        className: '',
        numberOfCalendars: 1,
        firstOfWeek: 0,
        disableNavigation: false,
        nextLabel: '',
        previousLabel: '',
        initialDate: date,
        initialFromValue: true,
        locale: moment().locale(),
        selectionType: 'range',
        singleDateRange: false,
        stateDefinitions: {
            '__default': {
                color: null,
                selectable: true,
                label: null,
            },
        },
        selectedLabel: 'Your selected dates',
        defaultState: '__default',
        dateStates: [],
        showLegend: false,
        onSelect() {},
    };

    constructor(props, context) {
        super(props, context);
        const now = new Date();
        const { initialYear, initialMonth, initialFromValue, value, initialDate } = props;
        let year = now.getFullYear();
        let month = now.getMonth();

        if (initialYear && initialMonth) {
            year = initialYear;
            month = initialMonth;
        }

        if (initialFromValue && value) {
            const yearMonth = getYearMonthProps(props);
            month = yearMonth.month;
            year = yearMonth.year;
        }

        if (!initialFromValue && initialDate) {
            month = initialDate.getMonth();
            year = initialDate.getFullYear();
        }

        this.state = {
            year,
            month,
            selectedStartDate: null,
            highlightedDate: null,
            highlightRange: null,
            hideSelection: false,
            enabledRange: this.getEnabledRange(props),
            dateStates: this.getDateStates(props),
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const nextDateStates = this.getDateStates(nextProps);
        const nextEnabledRange = this.getEnabledRange(nextProps);

        const updatedState = {
            selectedStartDate: null,
            hideSelection: false,
            dateStates: this.state.dateStates && Immutable.is(this.state.dateStates, nextDateStates) ? this.state.dateStates : nextDateStates,
            enabledRange: this.state.enabledRange && this.state.enabledRange.isSame(nextEnabledRange) ? this.state.enabledRange : nextEnabledRange,
        };

        if (hasUpdatedValue(this.props, nextProps) && nextProps.value !== null) {
            const isNewValueVisible = this.isStartOrEndVisible(nextProps);

            if (!isNewValueVisible) {
                const yearMonth = getYearMonthProps(nextProps);

                updatedState.year = yearMonth.year;
                updatedState.month = yearMonth.month;
            }
        }

        this.setState(updatedState);
    }

    getEnabledRange = (props) => {
        const min = props.minimumDate ? moment(props.minimumDate).startOf('day') : absoluteMinimum;
        const max = props.maximumDate ? moment(props.maximumDate).startOf('day') : absoluteMaximum;

        return moment.range(min, max);
    };

    getDateStates = (props) => {
        const { dateStates, defaultState, stateDefinitions } = props;
        const actualStates = [];
        const minDate = absoluteMinimum;
        const maxDate = absoluteMaximum;
        let dateCursor = moment(minDate).startOf('day');

        const defs = Immutable.fromJS(stateDefinitions);

        dateStates.forEach(function (s) {
            const r = s.range;
            const start = r.start.startOf('day');
            const end = r.end.startOf('day');

            if (!dateCursor.isSame(start, 'day')) {
                actualStates.push({
                    state: defaultState,
                    range: moment.range(
                        dateCursor,
                        // start,
                        moment(start).subtract(1, 'days')
                    ),
                });
            } else {
                actualStates.push(s);
            }
            dateCursor = moment(end).add(1, 'days');
        });

        actualStates.push({
            state: defaultState,
            range: moment.range(
                dateCursor,
                maxDate
            ),
        });

        // sanitize date states
        return Immutable.List(actualStates).map(function (s) {
            const def = defs.get(s.state);
            return Immutable.Map({
                range: s.range,
                state: s.state,
                selectable: def.get('selectable', true),
                color: def.get('color'),
            });
        });
    };

    isDateDisabled = (date) => !this.state.enabledRange.contains(date);

    isDateSelectable = (date) => this.dateRangesForDate(date).some((r) => r.get('selectable'));

    nonSelectableStateRanges = () => this.state.dateStates.filter((d) => !d.get('selectable'));

    dateRangesForDate = (date) => this.state.dateStates.filter((d) => d.get('range').contains(date));

    sanitizeRange = (range, forwards) => {
        /* Truncates the provided range at the first intersection
         * with a non-selectable state. Using forwards to determine
         * which direction to work
         */
        const blockedRanges = this.nonSelectableStateRanges().map((r) => r.get('range'));
        let intersect;

        if (forwards) {
            intersect = blockedRanges.find((r) => range.intersect(r));
            if (intersect) {
                return moment.range(range.start, intersect.start);
            }

        } else {
            intersect = blockedRanges.findLast((r) => range.intersect(r));

            if (intersect) {
                return moment.range(intersect.end, range.end);
            }
        }

        if (range.start.isBefore(this.state.enabledRange.start)) {
            return moment.range(this.state.enabledRange.start, range.end);
        }

        if (range.end.isAfter(this.state.enabledRange.end)) {
            return moment.range(range.start, this.state.enabledRange.end);
        }

        return range;
    };

    highlightRange = (range) => {
        this.setState({
            highlightedRange: range,
            highlightedDate: null,
        });
        if (typeof this.props.onHighlightRange === 'function') {
            this.props.onHighlightRange(range, this.statesForRange(range));
        }
    };

    onUnHighlightDate = () => {
        this.setState({
            highlightedDate: null,
        });
    };

    onSelectDate = (date) => {
        const { selectionType } = this.props;
        const { selectedStartDate } = this.state;

        if (selectionType === 'range') {
            if (selectedStartDate) {
                this.completeRangeSelection();
            } else if (!this.isDateDisabled(date) && this.isDateSelectable(date)) {
                this.startRangeSelection(date);
                if (this.props.singleDateRange) {
                    this.highlightRange(moment.range(date, date));
                }
            }

        } else {
            if (!this.isDateDisabled(date) && this.isDateSelectable(date)) {
                this.completeSelection();
            }
        }
    };

    onHighlightDate = (date) => {
        const { selectionType } = this.props;
        const { selectedStartDate } = this.state;

        let datePair;
        let range;
        let forwards;

        if (selectionType === 'range') {
            if (selectedStartDate) {
                datePair = Immutable.List.of(selectedStartDate, date).sortBy((d) => d.unix());
                range = moment.range(datePair.get(0), datePair.get(1));
                forwards = (range.start.unix() === selectedStartDate.unix());
                range = this.sanitizeRange(range, forwards);
                this.highlightRange(range);
            } else if (!this.isDateDisabled(date) && this.isDateSelectable(date)) {
                this.highlightDate(date);
            }
        } else {
            if (!this.isDateDisabled(date) && this.isDateSelectable(date)) {
                this.highlightDate(date);
            }
        }
    };

    startRangeSelection = (date) => {
        this.setState({
            hideSelection: true,
            selectedStartDate: date,
        });
        if (typeof this.props.onSelectStart === 'function') {
            this.props.onSelectStart(moment(date));
        }
    };

    statesForDate = (date) => this.state.dateStates.filter((d) => date.within(d.get('range'))).map((d) => d.get('state'));

    statesForRange = (range) => {
        if (range.start.isSame(range.end, 'day')) {
            return this.statesForDate(range.start);
        }
        return this.state.dateStates.filter((d) => d.get('range').intersect(range)).map((d) => d.get('state'));
    };

    completeSelection = () => {
        const highlightedDate = this.state.highlightedDate;
        if (highlightedDate) {
            this.setState({
                hideSelection: false,
                highlightedDate: null,
            });
            this.props.onSelect(highlightedDate, this.statesForDate(highlightedDate));
        }
    };

    completeRangeSelection = () => {
        const range = this.state.highlightedRange;

        if (range || this.props.singleDateRange) {
            this.setState({
                selectedStartDate: null,
                highlightedRange: null,
                highlightedDate: null,
                hideSelection: false,
            });
            this.props.onSelect(range, this.statesForRange(range));
        }
    };

    highlightDate = (date) => {
        this.setState({
            highlightedDate: date,
        });
        if (typeof this.props.onHighlightDate === 'function') {
            this.props.onHighlightDate(date, this.statesForDate(date));
        }
    };

    getMonthDate = () => moment(new Date(this.state.year, this.state.month, 1));

    isStartOrEndVisible = (props) => {
        const { value, selectionType, numberOfCalendars } = props;

        const isVisible = (date) => {
            const yearMonth = getYearMonth(date);
            const isSameYear = (yearMonth.year === this.state.year);
            const isMonthVisible = (yearMonth.month === this.state.month) || (numberOfCalendars === 2 && (yearMonth.month - 1 === this.state.month));

            return isSameYear && isMonthVisible;
        };

        if (selectionType === 'single') {
            return isVisible(value);
        }

        return isVisible(value.start) || isVisible(value.end);
    };

    canMoveBack = () => !this.getMonthDate().subtract(1, 'days').isBefore(this.state.enabledRange.start);

    moveBack = () => {
        let monthDate;

        if (this.canMoveBack()) {
            monthDate = this.getMonthDate();
            monthDate.subtract(1, 'months');
            this.setState(getYearMonth(monthDate));
        }
    };

    canMoveForward = () => !this.getMonthDate().add(this.props.numberOfCalendars, 'months').isAfter(this.state.enabledRange.end);

    moveForward = () => {
        let monthDate;

        if (this.canMoveForward()) {
            monthDate = this.getMonthDate();
            monthDate.add(1, 'months');
            this.setState(getYearMonth(monthDate));
        }
    };

    changeYear = (year) => {
        const { enabledRange } = this.state;
        let { month } = this.state;

        if (moment({ years: year, months: month, date: 1 }).unix() < enabledRange.start.unix()) {
            month = enabledRange.start.month();
        }

        if (moment({ years: year, months: month + 1, date: 1 }).unix() > enabledRange.end.unix()) {
            month = enabledRange.end.month();
        }

        this.setState({
            year,
            month,
        });
    };

    changeMonth = (date) => {
        this.setState({
            month: date,
        });
    };

    /* eslint-disable react/display-name */
    renderCalendar = (index) => {
        let {
            value,
        } = this.props;

        const {
            bemBlock,
            bemNamespace,
            firstOfWeek,
            numberOfCalendars,
            selectionType,
        } = this.props;

        let {
            highlightedDate,
            highlightedRange,
        } = this.state;

        const {
            dateStates,
            enabledRange,
            hideSelection,
        } = this.state;
        const monthDate = this.getMonthDate();
        const year = monthDate.year();
        const month = monthDate.month();
        const key = `${ index}-${ year }-${ month }`;

        monthDate.add(index, 'months');

        const cal = new calendar.Calendar(firstOfWeek);
        const monthDates = Immutable.fromJS(cal.monthDates(monthDate.year(), monthDate.month()));
        const monthStart = monthDates.first().first();
        const monthEnd = monthDates.last().last();
        const monthRange = moment.range(monthStart, monthEnd);

        if (moment.isMoment(value)) {
            if (!monthRange.contains(value)) {
                value = null;
            }
        } else if (isMomentRange(value)) {
            if (!monthRange.overlaps(value)) {
                value = null;
            }
        }

        if (!moment.isMoment(highlightedDate) || !monthRange.contains(highlightedDate)) {
            highlightedDate = null;
        }

        if (!isMomentRange(highlightedRange) || !monthRange.overlaps(highlightedRange)) {
            highlightedRange = null;
        }

        const props = {
            bemBlock,
            bemNamespace,
            dateStates,
            enabledRange,
            firstOfWeek,
            hideSelection,
            highlightedDate,
            highlightedRange,
            index,
            key,
            selectionType,
            value,
            maxIndex: numberOfCalendars - 1,
            firstOfMonth: monthDate,
            // UX:llisesti parempi näin: kun vaihdat kakkoskalenterista kuukautta ei sitä vaihdeta
            // ekaan kalenteriin vaan nimenomaan siihen kalenteriin mitä käpistelet.
            onMonthChange: (date) => this.changeMonth(index > 0 ? date - 1 : date),
            onYearChange: this.changeYear,
            onSelectDate: this.onSelectDate,
            onHighlightDate: this.onHighlightDate,
            onUnHighlightDate: this.onUnHighlightDate,
            dateRangesForDate: this.dateRangesForDate,
            dateComponent: CalendarDate,
            locale: this.props.locale,
        };

        return <CalendarMonth {...props} />;
    };
    /* eslint-enable react/display-name */

    render() {
        let {
            className,
        } = this.props;

        const {
            numberOfCalendars,
            stateDefinitions,
            selectedLabel,
            showLegend,
            helpMessage
        } = this.props;

        const calendars = Immutable.Range(0, numberOfCalendars).map(this.renderCalendar);
        className = this.cx({ element: null }) + ' ' + className;

        return (
            <div className={className.trim()}>
                <Button
                    flat
                    hasPadding={false}
                    mdIcon="arrow_backward"
                    onClick={this.moveBack}
                    disabled={!this.canMoveBack()}
                    modifierClass="o-pin o-pin--left o-pin--top u-margin-top-tiny"
                    ariaLabel={_trans('date_range.calendar.prev_button')}
                />
                {calendars.toJS()}
                <Button
                    flat
                    hasPadding={false}
                    mdIcon="arrow_forward"
                    onClick={this.moveForward}
                    disabled={!this.canMoveForward()}
                    modifierClass="o-pin o-pin--right o-pin--top u-margin-top-tiny"
                    ariaLabel={_trans('date_range.calendar.next_button')}
                />
                {helpMessage ? <span className={this.cx({ element: 'HelpMessage' })}>{helpMessage}</span> : null}
                {showLegend ? <Legend stateDefinitions={stateDefinitions} selectedLabel={selectedLabel}/> : null}
            </div>
        );
    }
}
