import * as PropTypes from 'prop-types';

const personnelGroup = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    isRemovable: PropTypes.bool,
});

export default personnelGroup;
