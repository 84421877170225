import React, { Fragment } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { InputAbbr, InputGroup } from 'shared/components';

export const IncomeField = ({ fieldName, isReadOnly }) => {
    const [field,, helpers] = useField(fieldName);


    // TODO tämä jotenkin syötettäessä grouppaamaan tuhansiin
    return (
        <Fragment>
            <InputGroup>
                <input
                    {...field}
                    aria-label={_trans('tax_card.form.income_limit_1.label_b')}
                    type="text"
                    size={5}
                    // value={field.value.toString()}
                    value={field.value}
                    // onChange={(ev) => {
                    //     const formattedValue = ev.target.value;
                    //
                    //     // Käsin korjattavat tilanteet, joita _numberFormat ei tue:
                    //     // - value loppuu pilkkuun esim. 10, (tarkoitus syöttää 10,0): lisätään pilkku loppuun
                    //     // - value loppuu pilkun jälkeisiin nolliin esim. 10,0 (tarkoitus syöttää 10,01): lisätään nollat loppuun
                    //     // const match = ev.target.value.toString().match(/(\.0*)$|\.\d+(0+)$/);
                    //     // if (match) {
                    //     //     return helpers.setValue(formattedValue + (match[1] || match[2]).replace('.', ','));
                    //     // }
                    //     return helpers.setValue(formattedValue);
                    // }}
                    // onChange={(ev) => helpers.setValue(_numberFormat(ev.target.value))}
                    onChange={(ev) => helpers.setValue(ev.target.value)}
                    readOnly={isReadOnly}
                />
                <InputAbbr abbr="€" definition={_trans('unit.euro')} />
            </InputGroup>
        </Fragment>
    );
};

IncomeField.propTypes = {
    fieldName: PropTypes.string.isRequired,
    isReadOnly: PropTypes.bool,
};

IncomeField.defaultProps = {
    isReadOnly: false,
};
