import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { sizes } from 'shared/constants';

/**
 * Puhtaasti asioiden korostukseen tarkoitettu komponentti.
 * Rajaa komponentin haalealla pohjavärillä. Paddingin voi säätää itse.
 * @param size - paddingin määrä (tiny, small, large, huge)
 * @param modifierClass - muokkausluokka
 * @param children - sisältö
 * @param isNeutral - neutraali väri
 * @param isInset - onko visuaalisesti sisennetyn näköinen
 * @param isHighlighted - korostettu
 * @returns {*}
 * @constructor
 */
function Island({
    size,
    modifierClass,
    children,
    isNeutral,
    isInset,
    isHighlighted,
}) {
    const islandClass = classNames('c-island o-box', modifierClass, {
        'o-box--tiny': size === sizes.TINY,
        'o-box--small': size === sizes.SMALL,
        'o-box--large': size === sizes.LARGE,
        'o-box--huge': size === sizes.HUGE,
        'c-island--neutral': isInset || isNeutral,
        'c-island--inset': isInset,
        'c-island--highlight': isHighlighted,

        [modifierClass]: modifierClass !== '',
    });

    return (
        <div className={islandClass}>
            {children}
        </div>
    );
}

Island.propTypes = {
    size: PropTypes.oneOf([sizes.TINY, sizes.SMALL, sizes.MEDIUM, sizes.LARGE, sizes.HUGE]),
    modifierClass: PropTypes.string,
    children: PropTypes.node.isRequired,
    isNeutral: PropTypes.bool,
    isInset: PropTypes.bool,
    isHighlighted: PropTypes.bool,
};

Island.defaultProps = {
    size: sizes.SMALL,
    modifierClass: '',
    isNeutral: false,
    isInset: false,
    isHighlighted: false,
};

export default Island;
