import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { DecimalField, FormField } from 'shared/components/Formik';
import InputGroup from 'shared/components/InputGroup';
import InputPostfix from 'shared/components/InputPostfix';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';

const PartTimePercentage = (props) => {
    const { name } = props;
    const hasSalaryRelatedComponentsVisible = useSelector(select.contract.hasSalaryRelatedComponentsVisible);

    if (! hasSalaryRelatedComponentsVisible) return null;

    return (
        <FormField {...getFieldProps(props)}>
            <InputGroup>
                <DecimalField
                    name={name}
                    size={4}
                    maxLength={3}
                />
                <InputPostfix postfix="%" />
            </InputGroup>
        </FormField>
    );
};

PartTimePercentage.propTypes = {
    name: PropTypes.string.isRequired,
};

export default PartTimePercentage;
