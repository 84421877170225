export const paymentStates = {
    /**
     * Vedos
     */
    DRAFT: 10,

    /**
     * Kun maksuerä merkitään maksetuksi, asetetaan tila odottaa maksetuksi asettamista
     * Erän payrollit rullataan ja asetettaan oikein ketjuun
     */
    PENDING_FOR_SET_TO_PAID: 40,

    /**
     * Merkitty maksetuksi
     */
    PAID: 50,

    /**
     * Maksu kuitattu saapuneeksi
     */
    PAYMENT_VERIFIED: 55,

    /**
     * Maksuerän palkka-ajo aloitettu
     */
    PAYMENT_RUN_STARTED: 60,

    /**
     * Maksuerän ajo epäonnistui
     */
    PAYMENT_RUN_FAILED: 80,

    /**
     * Maksuajo valmis lopputoimia varten
     */
    PAYMENT_CHECKOUT: 90,

    /**
     * Maksuerän palkka-ajo valmis
     */
    PAYMENT_RUN_COMPLETED:100,

    /**
     * Vain UI: Myöhässä
     */
    LATE: 500,
};

/**
 * Tilat joissa rahat eivät ole Oiman tiedossa
 * @type {*[]}
 */
export const notVerifiedStates = [
    paymentStates.DRAFT,
    paymentStates.PAID,
    paymentStates.PENDING_FOR_SET_TO_PAID,
];


const paymentStateMap = {
    [paymentStates.DRAFT]: _trans('payments.state.draft'),
    [paymentStates.PENDING_FOR_SET_TO_PAID]: _trans('payments.state.pending_paid'),
    [paymentStates.PAID]: _trans('payments.state.paid'),
    [paymentStates.PAYMENT_VERIFIED]: _trans('payments.state.payment_verified'),
    [paymentStates.PAYMENT_RUN_STARTED]: _trans('payments.state.payment_run_started'),
    [paymentStates.PAYMENT_RUN_FAILED]: _trans('payments.state.payment_run_started'),
    [paymentStates.PAYMENT_RUN_COMPLETED]: _trans('payments.state.payment_run_completed'),
    [paymentStates.PAYMENT_CHECKOUT]: _trans('payments.state.payment_run_completed'),
    [paymentStates.LATE]: _trans('payments.state.late'),
};

/**
 * Antaa maksun tilaa vastaavan käännöksen
 * @param paymentState
 * @returns {*}
 */
export function translatePaymentState(paymentState) {
    return paymentState in paymentStateMap ? paymentStateMap[paymentState] : _trans('job_contract.state.unknown');
}

const paymentUiStates = {
    [paymentStates.DRAFT]: 'neutral',
    [paymentStates.PENDING_FOR_SET_TO_PAID]: 'yellow',
    [paymentStates.PAID]: 'yellow',
    [paymentStates.PAYMENT_VERIFIED]: 'positive',
    [paymentStates.PAYMENT_RUN_STARTED]: 'positive',
    [paymentStates.PAYMENT_RUN_FAILED]: 'positive',
    [paymentStates.PAYMENT_RUN_COMPLETED]: 'positive',
    [paymentStates.PAYMENT_CHECKOUT]: 'positive',
    [paymentStates.LATE]: 'negative',
};

/**
 * Antaa maksun tilaa vastaavan tyylin
 * @param paymentState
 * @returns {string}
 */
export function mapPaymentStateToUi(paymentState) {
    return paymentState in paymentUiStates ? paymentUiStates[paymentState] : 'hollow';
}

const paymentTooltipMap = {
    [paymentStates.DRAFT]: _trans('payments.state_tooltip.draft'),
    [paymentStates.PENDING_FOR_SET_TO_PAID]: _trans('payments.state_tooltip.pending_paid'),
    [paymentStates.PAID]: _trans('payments.state_tooltip.paid'),
    [paymentStates.PAYMENT_VERIFIED]: _trans('payments.state_tooltip.payment_verified'),
    [paymentStates.PAYMENT_RUN_STARTED]: _trans('payments.state_tooltip.payment_run_started'),
    [paymentStates.PAYMENT_RUN_COMPLETED]: _trans('payments.state_tooltip.payment_run_completed'),
    [paymentStates.LATE]: _trans('payments.state_tooltip.late'),
};

/**
 * Antaa maksun tilaa vastaavan tooltip tekstin
 * @param paymentState
 * @returns {*}
 */
export function translatePaymentTooltip(paymentState) {
    return paymentState in paymentTooltipMap ? paymentTooltipMap[paymentState] : _trans('job_contract.state.unknown');
}

/*
 * Erilliset translaatiot omalla tilllä oleville
 */
const ownAccountPaymentStateMap = {
    [paymentStates.DRAFT]: _trans('payments.state.draft'),
    [paymentStates.PENDING_FOR_SET_TO_PAID]: _trans('Lukitaan...', {}, 'payments_new'),
    [paymentStates.PAID]: _trans('Odottaa maksun hyväksymistä', {}, 'payments_new'),
    [paymentStates.PAYMENT_VERIFIED]: _trans('Hyväksytty maksuun', {}, 'payments_new'),
    [paymentStates.PAYMENT_RUN_STARTED]: _trans('Käsittelyssä', {}, 'payments_new'),
    [paymentStates.PAYMENT_RUN_FAILED]: _trans('Käsittelyssä', {}, 'payments_new'),
    [paymentStates.PAYMENT_RUN_COMPLETED]: _trans('Valmis', {}, 'payments_new'),
    [paymentStates.PAYMENT_CHECKOUT]: _trans('payments.state.payment_run_completed'),
    [paymentStates.LATE]: _trans('payments.state.late'),
};

export function translateOwnAccountPaymentState(paymentState) {
    return paymentState in ownAccountPaymentStateMap ? ownAccountPaymentStateMap[paymentState] : _trans('Tuntematon', {}, 'common');
}

const ownAccountPaymentTooltipMap = {
    [paymentStates.DRAFT]: _trans('Avoin maksuerä. Maksuerälle voi edelleen lisätä palkkioita.', {}, 'payments_new'),
    [paymentStates.PENDING_FOR_SET_TO_PAID]: _trans('Maksuerää lukitaan, jonka jälkeen se tulee vielä hyväksyä maksuun.', {}, 'payments_new'),
    [paymentStates.PAID]: _trans('Olet lukinnut maksuerän. Maksu tulee vielä hyväksyä maksuun ennen palkkionmaksupäivää.', {}, 'payments_new'),
    [paymentStates.PAYMENT_VERIFIED]: _trans('Maksu on hyväksytty Oima-palvelussa ja odottaa maksuajoa. Muista varmistaa, että maksuihin käytettävän pankkitilin saldo riittää maksuerän maksamiseen ajallaan. Palkkiot ovat hoitajien tileillä sovittuna palkkionmaksupäivänä ja lakisääteiset maksut tilitetään eri tahoille.', {}, 'payments_new'),
    [paymentStates.PAYMENT_RUN_STARTED]: _trans('Maksuajo käynnissä', {}, 'payments_new'),
    [paymentStates.PAYMENT_RUN_COMPLETED]: _trans('Maksu on hyväksytty maksuun eräpäivään mennessä. Muista varmistaa, että maksuihin käytettävän pankkitilin saldo riittää maksuerän maksamiseen ajallaan. Palkkiot ovat hoitajien tileillä sovittuna palkkionmaksupäivänä ja lakisääteiset maksut tilitetään eri tahoille.', {}, 'payments_new'),
    [paymentStates.LATE]: _trans('Maksuerää ei ole hyväksytty maksuun eräpäivänä – eräpäivän saa muokattua Maksun käsittely-painikkeesta.', {}, 'payments_new'),
};

export function translateOwnAccountPaymentTooltip(paymentState) {
    return paymentState in ownAccountPaymentTooltipMap ? ownAccountPaymentTooltipMap[paymentState] : _trans('Tuntematon', {}, 'common');
}
