import React, { Fragment } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { select } from '@rematch/select';
import { ForeclosureStatus } from './ForeclosureStatus';
import { ActionBar, Badge, Button, EmptyState, Feedback, OutLink, SummaryList } from 'shared/components';
import confirm from 'shared/utils/confirm';
import { spaceEveryFourth } from 'shared/utils/spaceEveryFourth';
import { MONTH_YEAR_FORMAT, YEAR_MONTH_FORMAT } from 'shared/constants/formats';
import {
    EXPIRES_UNTIL_CHARGED, EXPIRES_UNTIL_DATE, INDEFINITE,
    ONCE,
    ONE_THIRD_EMPLOYEE_REFUGE_INCOME
} from 'shared/UserDetails/containers/shared/Foreclosure/constants';
import userRoles from 'shared/constants/userRoles';

/**
 * Ilmoitus avoimista palkkajaksoista.
 * @returns {JSX.Element}
 * @constructor
 */
const OpenPayrollsFeedback = () => (
    <Feedback
        type="warning"
        message={_trans('foreclosure.feedback.has_open_payrolls')}
        modifierClass="u-margin-bottom-small"
    />
);

/**
 * Ulosoton yhteenveto.
 * Sisältää myös napit peruuttamiseen, keskeyttämiseen ja muokkaamiseen.
 */
export const ForeclosureSummary = hot(() => {
    const userRole = useSelector(select.userMetadata.getUserRole);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const employee = useSelector(select.foreclosure.getUserId);
    const canEdit = useSelector(select.foreclosure.canEdit);
    const isAllowAssignmentContracts = useSelector(select.foreclosure.isAllowAssignmentContracts);
    const hasOpenPayrolls = useSelector(select.foreclosure.hasOpenPayrolls);
    const isEnding = useSelector((state) => state.loading?.effects.foreclosure.closeForeclosure ?? false);
    const isCopying = useSelector((state) => state.loading?.effects.foreclosure.makeCopyAndCloseCurrent ?? false);
    const collectedForeclosedAmount = useSelector(select.foreclosure.getCollectedForeclosedAmount);
    const open = useSelector(select.foreclosure.getOpenForeclosure);
    const dispatch = useDispatch();

    if (open.foreclosureId) {
        const {
            startDate,
            payBanNumber,
            payBanRFReferenceNumber,
            foreclosureMaterial,
            employeeRefugeIncome,
            otherReceivedIncome,
            foreclosureAmount,
            employeeRefugeIncomeExtra,
            maxForeclosurePerMonth,
            description,
            isFamilyCareCostReimbursementsExcluded,
            isTravelingCompensationsExcluded,
            noForeclosureMonths,
            restrictions,
            foreclosureType,
            contactName,
            contactPhone,
            contactEmail,
            enforcementOfficeName,
            enforcementOfficeAddress,
            enforcementOfficeEmail,
            endDate
        } = open;

        const isIndefinite = (! foreclosureType && foreclosureAmount >= 99999) || foreclosureType === INDEFINITE;

        const hasMaterial = foreclosureMaterial?.foreclosureMaterialId ?? false;

        const isOperator = [userRoles.COMMUNE_OPERATOR, userRoles.COMPANY_OPERATOR].includes(userRole);

        const routePrefix = isCommune ? 'commune' : 'home';
        const route = `/${routePrefix}/user-action-records/foreclosure/${open.foreclosureId}`;

        // Ulosoton yhteenveto
        return (
            <Fragment>
                {(hasOpenPayrolls && canEdit) && <OpenPayrollsFeedback />}
                <ActionBar modifierClass="u-margin-bottom">
                    <ForeclosureStatus />
                    {canEdit && (
                        <span>
                            <Button
                                mdIcon="clear"
                                disabled={hasOpenPayrolls}
                                title={_trans('foreclosure.actions.cancel_ban_on_payment.title')}
                                onClick={() => {
                                    // Päättää maksukiellon
                                    confirm(_transMd('foreclosure.actions.cancel_ban_on_payment.confirm_dialog_message'), {
                                        proceedLabel: _trans('foreclosure.actions.cancel_ban_on_payment.button'),
                                        alert: true,
                                    }).then(
                                        () => {
                                            dispatch.foreclosure.closeForeclosure({
                                                employee,
                                                foreclosure: open.foreclosureId,
                                            });
                                        },
                                    );
                                }}
                                inProgress={isEnding}
                            >
                                {_trans('foreclosure.actions.cancel_ban_on_payment.button')}
                            </Button>
                            <Button
                                mdIcon="content_copy"
                                disabled={hasOpenPayrolls}
                                title={_trans('foreclosure.actions.make_copy.title')}
                                onClick={() => {
                                    dispatch.foreclosure.setCopy(true);
                                    dispatch.foreclosure.setEditMode(true);
                                }}
                                inProgress={isCopying}
                            >
                                {_trans('foreclosure.actions.make_copy.button')}
                            </Button>
                            <Button
                                mdIcon="edit"
                                disabled={hasOpenPayrolls}
                                onClick={() => dispatch.foreclosure.setEditMode(true)} primary
                            >
                                {_trans('button.edit')}
                            </Button>
                        </span>
                    )}
                </ActionBar>

                <fieldset>
                    <legend>
                        {_trans('foreclosure.form.pay_ban.legend')}
                    </legend>
                    <SummaryList
                        list={[
                            {
                                term: _trans('foreclosure.start_date'),
                                description: _toLocaleDate(startDate),
                            },
                            {
                                term: _trans('foreclosure.form.pay_ban.number.label'),
                                description: payBanNumber,
                            },
                            {
                                term: _trans('foreclosure.form.pay_ban.rf_reference_number.label'),
                                description: spaceEveryFourth(payBanRFReferenceNumber),
                            },
                            {
                                term: _trans('foreclosure.form.pay_ban.type.label'),
                                description: (
                                    <Fragment>
                                        <Badge
                                            mdIcon={ hasMaterial ? 'bolt' : 'article' }
                                            type="neutral"
                                            modifierClass="u-align-middle"
                                            value={_trans(`foreclosure.form.pay_ban.type.options.${ hasMaterial ? 'electronically' : 'on_paper' }`)}
                                        />
                                    </Fragment>
                                ),
                            },
                            {
                                term: _trans('foreclosure.form.foreclosure.description.label'),
                                description: description ?? '-',
                            },
                            (isAllowAssignmentContracts) && {
                                term: _trans('Kulukorvauksien ohitus perinnässä', {}, 'extract'),
                                description: <span className="u-text-capitalize">{isFamilyCareCostReimbursementsExcluded ? _trans('yes') : _trans('no')}</span>
                            },
                            (isAllowAssignmentContracts) && {
                                term: _trans('Kilometrikorvauksien ohitus perinnässä', {}, 'extract'),
                                description: <span className="u-text-capitalize">{isTravelingCompensationsExcluded ? _trans('yes') : _trans('no')}</span>
                            },
                        ]}
                    />
                </fieldset>

                <fieldset>
                    <legend>
                        {_trans('foreclosure.form.foreclosure.legend')}
                    </legend>
                    <SummaryList
                        list={[
                            (employeeRefugeIncome === ONE_THIRD_EMPLOYEE_REFUGE_INCOME ? {
                                term: _trans('1/3 pidätys nettotuloista', {}, 'extract'),
                                description: _trans('yes'),
                            } : {
                                term: _trans('foreclosure.form.foreclosure.debtor_protection_share_per_day.label'),
                                description: _currency(employeeRefugeIncome),
                            }),
                            {
                                term: _trans('foreclosure.form.foreclosure.other_net_income_per_month.label'),
                                description: otherReceivedIncome !== undefined ? _currency(otherReceivedIncome) : '-',
                            },
                            (foreclosureType === ONCE && {
                                term: _trans('Kertapidätys', {}, 'extract'),
                                description: _trans('yes'),
                            }),
                            (isIndefinite && {
                                term: _trans('foreclosure.form.foreclosure.validity.label'),
                                description: _trans('text.indefinite'),
                            }),
                            (foreclosureType === EXPIRES_UNTIL_CHARGED && {
                                term: _trans('foreclosure.form.foreclosure.validity.label'),
                                description: (
                                    <div>
                                        {`${_trans('foreclosure.form.foreclosure.expires_until_charged.label')} `}<b>{_currency(foreclosureAmount)}</b><br/>
                                        {_transMd('foreclosure.form.foreclosure.expires_until_charged.tip', {
                                            amount: _currency(collectedForeclosedAmount)
                                        })}
                                    </div>
                                ),
                            }),
                            (foreclosureType === EXPIRES_UNTIL_DATE && {
                                term: _trans('foreclosure.form.foreclosure.validity.label'),
                                description: _toLocaleDate(endDate),
                            }),
                            {
                                term: _trans('foreclosure.form.foreclosure.debtor_extra_income.label'),
                                description: employeeRefugeIncomeExtra !== undefined ? _currency(employeeRefugeIncomeExtra) : '-',
                            },
                            {
                                term: _trans('foreclosure.form.foreclosure.max_foreclosure_per_month.label'),
                                description: maxForeclosurePerMonth !== undefined ? _currency(maxForeclosurePerMonth) : '-',
                            },
                            {
                                term: _trans('foreclosure.form.no_foreclosure_months.label'),
                                description: (
                                    noForeclosureMonths.length === 0
                                        ? _trans('foreclosure.form.no_foreclosure_months.empty_state.text')
                                        : (
                                            <ul className="o-list o-list--bare">
                                                {noForeclosureMonths.map((noForeclosureMonth, key) => {
                                                    const start = moment(noForeclosureMonth.start, YEAR_MONTH_FORMAT);
                                                    const end = moment(noForeclosureMonth.end, YEAR_MONTH_FORMAT);
                                                    const startDate = start.isValid() ? start.format(MONTH_YEAR_FORMAT) : '-';
                                                    const endDate = end.isValid() ? end.format(MONTH_YEAR_FORMAT) : '-';

                                                    // Jos alku ja loppu sama, näytetään vain alku (vähemmän kohinaa)
                                                    const range = start.isSame(end)
                                                        ? startDate
                                                        : `${startDate} — ${endDate}`;
                                                    return (
                                                        <li key={key} className="u-padding-vertical-tiny">
                                                            {range}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )
                                )
                            }
                        ]}
                    />
                </fieldset>

                <fieldset>
                    <legend>
                        {_trans('foreclosure.form.non_payment_restrictions.legend')}
                    </legend>
                    {restrictions.length === 0 && _trans('foreclosure.form.non_payment_restrictions.empty_state.text')}
                    {restrictions.length > 0 && (
                        <SummaryList
                            list={[
                                {
                                    term: _trans('foreclosure.form.non_payment_restrictions.restrictions.label'),
                                    description: (
                                        <table
                                            className="o-table o-table--plain"
                                            summary={_trans('foreclosure.form.non_payment_restrictions.restrictions.summary')}
                                            style={{ maxWidth: '480px' }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '16.5em' }} className="u-padding-top-none u-padding-horizontal-none">
                                                        {_trans('foreclosure.form.non_payment_restrictions.limitation_period.label')}
                                                    </th>
                                                    <th className="u-padding-top-none u-padding-horizontal-none" colSpan={2}>
                                                        {_trans('foreclosure.form.non_payment_restrictions.limitation_amount.label')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {restrictions.map((restriction, index) => {
                                                    const start = moment(restriction.duration.start, YEAR_MONTH_FORMAT);
                                                    const end = moment(restriction.duration.end, YEAR_MONTH_FORMAT);
                                                    const startDate = start.isValid() ? start.format(MONTH_YEAR_FORMAT) : '-';
                                                    const endDate = end.isValid() ? end.format(MONTH_YEAR_FORMAT) : '-';
                                                    // Jos alku ja loppu sama, näytetään vain alku (vähemmän kohinaa)
                                                    const range = start.isSame(end)
                                                        ? startDate
                                                        : `${startDate} — ${endDate}`;

                                                    return (
                                                        <tr key={index}>
                                                            <td className="u-padding-horizontal-none">
                                                                {range}
                                                            </td>
                                                            <td className="u-padding-horizontal-none">
                                                                {_currency(restriction.amount)}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    )
                                },
                            ]}
                        />
                    )}
                </fieldset>

                <fieldset>
                    <legend>
                        {_trans('foreclosure.form.contact.legend')}
                    </legend>
                    <SummaryList
                        list={[
                            {
                                term: _trans('foreclosure.form.contact.contact_info.term'),
                                description: (
                                    <div>
                                        {contactName && <Fragment>{contactName}<br/></Fragment>}
                                        {contactEmail && <Fragment>{contactEmail}<br/></Fragment>}
                                        {contactPhone}
                                    </div>
                                )
                            },
                            {
                                term: _trans('foreclosure.form.contact.enforcement_office_info.term'),
                                description: (
                                    <div>
                                        {enforcementOfficeName && <Fragment>{enforcementOfficeName}<br/></Fragment>}
                                        {enforcementOfficeAddress && <Fragment>{enforcementOfficeAddress}<br/></Fragment>}
                                        {enforcementOfficeEmail}
                                    </div>
                                )
                            },
                        ]}
                    />
                </fieldset>
                {(isOperator && <OutLink href={route}>
                    {_trans('Lokitiedot', {}, 'common')}
                </OutLink>)}
            </Fragment>
        );
    }

    return (
        <Fragment>
            { (hasOpenPayrolls && canEdit) && <OpenPayrollsFeedback />}
            <EmptyState
                message={_trans('foreclosure.empty_state.text')}
                callToAction={(! hasOpenPayrolls && canEdit) && (
                    <Button primary onClick={() => dispatch.foreclosure.setEditMode(true)}>
                        {_trans('foreclosure.empty_state.call_to_action')}
                    </Button>
                )}
            />
        </Fragment>
    );
});
