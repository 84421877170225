import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import EmptyState from './EmptyState';

/**
 * Geneerinen virheestä kertova empty state joka sisältää
 * call-to-actionin sen toiminnon toistamiseen josta virhe aiheutui.
 * @param message
 * @param callToAction
 * @returns {*}
 * @constructor
 */
export default class ErrorState extends PureComponent {
    static defaultProps = {
        onRetry: undefined,
        callToAction: undefined,
    };

    static propTypes = {
        message: PropTypes.string.isRequired,
        onRetry: PropTypes.func,
        callToAction: PropTypes.node,
    };

    render() {
        const {
            message,
            onRetry,
        } = this.props;

        let { callToAction } = this.props;

        if (! callToAction && onRetry) {
            callToAction = (
                <Button flat mdIcon="refresh" onClick={onRetry}>
                    {_trans('empty_state.call_to_action.retry')}
                </Button>
            );
        }

        return (
            <EmptyState
                icon="cloud_off"
                message={message}
                callToAction={callToAction}
            />
        );
    }
}
