import React from 'react';
import PropTypes from 'prop-types';
import { select } from '@rematch/select';
import { useSelector } from 'react-redux';
import { useQuery } from 'shared/hooks/useQuery';
import utils from 'shared/utils/commonUtils';
import UserAvatar from 'shared/components/UserAvatar';

const communeRelationMap = {
    1: _trans('Avustettava', {}, 'common'),
    2: _trans('Työnantaja', {}, 'common'),
    3: _trans('Työntekijä', {}, 'common')
};

const relationMap = {
    1: _trans('Hoidettava', {}, 'common'),
    2: _trans('Työnantaja', {}, 'common'),
    3: _trans('Hoitaja', {}, 'common')
};

export const UserGroupHeader = ({ initialUser }) => {
    const query = useQuery();
    const relationType = query.get('type');
    const isCommune = useSelector(select.userMetadata.isCommune);

    const roleByRelation = isCommune
        ? communeRelationMap[relationType]
        : relationMap[relationType];

    const userFullname = utils.getUserFullName(initialUser);
    return (
        <div className="u-padding-small">
            <div className="o-stack">
                <span className="u-font-size-0">
                    <UserAvatar user={initialUser.toJS()} size="small"/>
                </span>
                <div className="u-margin-left-tiny u-overflow-hidden">
                    <strong className="c-heading-subheading u-display-block">
                        {userFullname}
                    </strong>
                    {roleByRelation && (
                        <span>{roleByRelation}</span>
                    )}
                </div>
            </div>
        </div>
    );
};

UserGroupHeader.propTypes = {
    initialUser: PropTypes.instanceOf(Map).isRequired,
};
