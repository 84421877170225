import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MDIcon from 'shared/components/MDIcon';
import { Link } from 'react-router-dom';
import { sizes } from 'shared/constants';

/**
 * Linkki ikonilla.
 * HUOM! Käyttää redux-little-routeria kun käytössä on to-props.
 * (https://github.com/FormidableLabs/redux-little-router)
 * @param title
 * @param children
 * @param mdIcon
 * @param iconSize
 * @param iconAfterText
 * @param modifierClass
 * @param href
 * @param target
 * @param to
 * @param onClick
 * @returns {*}
 * @constructor
 */
export default class LinkIcon extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            title = null,
            children,
            mdIcon,
            iconSize,
            iconAfterText = false,
            modifierClass = '',
            href = '',
            target = null,
            to = '',
            onClick = () => {},
        } = this.props;

        if (href !== '' && to !== '') {
            console.error('Use only either href or to prop, not both.');
            return null;
        }

        const linkClass = classNames('o-link', {
            [modifierClass]: modifierClass !== '',
        });

        const iconClass = classNames({
            'u-margin-right-tiny': !iconAfterText,
            'u-margin-left-tiny': iconAfterText,
        });

        const mdIconContainer = <MDIcon icon={mdIcon} key="linkIcon" size={iconSize} modifierClass={iconClass}/>;

        if (to !== '') {
            return (
                <Link to={to} className={linkClass}>
                    {!iconAfterText && mdIconContainer}
                    <span className="u-display-inline-block u-align-middle">
                        {children}
                    </span>
                    {iconAfterText && mdIconContainer}
                </Link>
            );
        }

        return (
            <a href={href} className={linkClass} title={title} target={target} onClick={onClick}>
                {!iconAfterText && mdIconContainer}
                <span className="u-display-inline-block u-align-middle o-link__child">
                    {children}
                </span>
                {iconAfterText && mdIconContainer}
            </a>
        );
    }
}

LinkIcon.propTypes = {
    /**
       Linkin title.
     */
    title: PropTypes.string,

    /**
     * Linkin sisältö.
     */
    children: PropTypes.node.isRequired,

    /**
     * Linkin ikoni.
     */
    mdIcon: PropTypes.string.isRequired,

    /**
     * Ikonin koko.
     */
    iconSize: PropTypes.oneOf([sizes.TINY, sizes.SMALL, sizes.LARGE, sizes.HUGE]),

    /**
     * Tuleeko ikoni tekstin jälkeen vai ennen sitä.
     */
    iconAfterText: PropTypes.bool,

    modifierClass: PropTypes.string,

    /**
     * Linkin osoite. Anna tämä tai to-props.
     */
    href: PropTypes.string,

    /**
     * Miten linkki avataan? _blank = uuteen ikkunaan
     */
    target: PropTypes.string,

    /**
     * Linkin osoite routeria käytettäessä.
     */
    to: PropTypes.string,

    onClick: PropTypes.func,
};
