import React from 'react';
import PropTypes from 'prop-types';
import MDIcon from 'shared/components/MDIcon';

/**
 * TT:n aiempi allekirjoitus.
 * @returns {JSX.Element}
 * @constructor
 */
export const PreviousEmployeeSignature = ({ signature, isEmployeeNotUsingOima }) => {
    const signerDetails = signature?.signerDetails;
    const signTime = _toLocaleDate(signature?.signTime, '', _dateTimeFormat);

    if (isEmployeeNotUsingOima) {
        return (
            <div className="o-form__text">
                <MDIcon icon="check_circle" size="small" modifierClass="u-color-positive u-align-middle u-margin-right-tiny" isFilled />
                <span className="u-align-middle">
                    <strong>{signerDetails?.fullName}</strong>{'. '}
                    {_trans('Allekirjoitus sopimuksen paperisessa versiossa.', {}, 'jobContract')}{' '}
                    {_transMd('%signer% vahvisti nähneensä paperisen sopimuksen **%signTime%** ja arkistoineensa sen.', {
                        signer: signature?.verifiedByName ?? '-',
                        signTime,
                    }, 'jobContract')}
                </span>
            </div>
        );
    }

    return (
        <div className="o-form__text">
            <MDIcon icon="check_circle" size="small" modifierClass="u-color-positive u-align-middle u-margin-right-tiny" isFilled />
            <span className="u-align-middle">
                {_transMd(_trans('**%signer%** allekirjoitti **%signTime%**', {
                    signer: signerDetails?.fullName ?? '-',
                    signTime,
                }, 'jobContract'))}
            </span>
        </div>
    );
};

PreviousEmployeeSignature.propTypes = {
    signature: PropTypes.object.isRequired,
    isEmployeeNotUsingOima: PropTypes.bool.isRequired,
};
