import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { DateRangeInput } from 'shared/components';
import { positions } from 'shared/constants';

const FormikDateInput = (props) => {
    const { name, formik, placeholder, isReadOnly, placement, isRequired } = props;
    const { setFieldValue, values, setFieldTouched } = formik;
    return (
        <DateRangeInput
            {...props}
            isRequired={isRequired}
            startDatePlaceholder={placeholder}
            onChange={(value) => {
                setFieldValue(name, value);
                setFieldTouched(name);
            }}
            value={_.get(values, name)}
            hasSingleInput
            placement={placement}
            readOnly={isReadOnly}
        />
    );
};

FormikDateInput.propTypes = {
    name: PropTypes.string.isRequired,
    isReadOnly: PropTypes.bool,
    formik: PropTypes.object,
    placeholder: PropTypes.string,
    placement: PropTypes.oneOf(positions),
    isRequired: PropTypes.bool,
};

FormikDateInput.defaultProps = {
    formik: {},
    isReadOnly: false,
    placeholder: _trans('date_range.start'),
    placement: 'top-end',
    isRequired: false,
};

export default connect(FormikDateInput);
