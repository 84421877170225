import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import AutoComplete from 'shared/containers/AutoComplete';

// Laskeskellaan indexejä kentille, että tabi toimisi paremmin.
const resolveTabIndex = (index, isContra) => {
    if (index === 0) {
        if (isContra) {
            return index + 1;
        }
        return index;
    }
    if (isContra) {
        return index * 2 + 1;
    }
    return index * 2;
};

const PostingField = ({ accounts, original, posting, dispatch, isContraAccount, error, index }) => {
    let objectKey = 'account';
    if (isContraAccount) {
        objectKey = 'contraAccount';
    }
    const postingValue = `${posting.getIn([objectKey, 'number'], '')} - ${posting.getIn([objectKey, 'name'], '')}`;
    const defaultDimensions = posting.get('defaultDimensions') ?? List();
    const tabIndex = resolveTabIndex(index, isContraAccount);
    return (
        <div tabIndex={tabIndex}>
            <AutoComplete
                items={accounts.toJS()}
                itemKey="combined"
                itemValue="combined"
                onSelect={(account) => dispatch.bookkeepingAccount.setPosting({
                    incomeType: original.incomeType,
                    accountType: objectKey,
                    accountValue: account,
                    defaultDimensions,
                    exist: posting.size > 0,
                })}
                value={posting.size > 0 ? postingValue : ''}
            />
            <MapError error={error} isContraAccount={isContraAccount} />
        </div>
    );
};

PostingField.propTypes = {
    accounts: PropTypes.instanceOf(List),
    original: PropTypes.object,
    posting: PropTypes.instanceOf(Map),
    dispatch: PropTypes.func,
    isContraAccount: PropTypes.bool,
    error: PropTypes.instanceOf(Map),
    index: PropTypes.number,
};

PostingField.defaultProps = {
    accounts: List(),
    original: {},
    posting: Map(),
    dispatch() {},
    isContraAccount: false,
    error: Map(),
    index: 0,
};

const mapStateToProps = (state, props) => {
    const incomeType = _.get(props, 'original.incomeType', null);
    return {
        accounts: select.bookkeepingAccount.getAccountsNameAndNumberCombined(state),
        postings: select.bookkeepingAccount.getPostings(state),
        posting: select.bookkeepingAccount.getPostingByIncomeType(state, incomeType),
        error: select.bookkeepingAccount.getPostingErrors(state, incomeType),
    };
};

const MapError = ({ error, isContraAccount }) => {
    if (error.size > 0) {
        let showError = false;
        if (isContraAccount && ! error.has('contraAccount')) {
            showError = true;
        }
        if (! isContraAccount && ! error.has('account')) {
            showError = true;
        }
        if (showError) {
            return (
                <ul className="o-form__errors o-list-bare u-margin-bottom-none u-display-block">
                    <li className="o-form__error-item">{_trans('validation.required')}</li>
                </ul>
            );
        }
        return null;
    }
    return null;
};

MapError.propTypes = {
    error: PropTypes.instanceOf(Map).isRequired,
    isContraAccount: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(PostingField);
