import React, { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { NotificationList } from './NotificationList';
import commonUtils from 'shared/utils/commonUtils';

export const NotificationContext = React.createContext({
    notifications: [],
    addNotification: () => {},
    removeNotification: () => {},
});

export const NotificationsProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const addNotification = (notification) =>
        setNotifications((prev) => [...prev, { ...notification, id: commonUtils.generateUuid() }]);

    const removeNotification = (id) =>
        setNotifications((prev) => prev.filter((n) => n.id !== id));

    const contextValues = {
        notifications,
        addNotification: useCallback(addNotification, []),
        removeNotification: useCallback(removeNotification, []),
    };

    return (
        <NotificationContext.Provider value={contextValues}>
            {children}
            <NotificationList />
        </NotificationContext.Provider>
    );
};

NotificationsProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useNotifications = () => {
    const { addNotification } = useContext(NotificationContext);

    return {
        addInfo: (message, timeout = 5000, ...rest) => addNotification({ type: 'info', message, timeout, ...rest }),
        addSuccess: (message, timeout = 5000, ...rest) => addNotification({ type: 'success', message, timeout, ...rest }),
        addWarning: (message, timeout = 5000, ...rest) => addNotification({ type: 'warning', message, timeout, ...rest }),
        addError: (message, timeout = 0, ...rest) => addNotification({ type: 'error', message, timeout, ...rest }),
    };
};

