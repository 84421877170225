import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Control, getField} from 'react-redux-form';
import {dispatch, connect} from 'react-redux';
import _isUndefined from 'lodash/isUndefined';

import {errorMessages} from 'shared/validators';

const propTypes = {
    maxLength: PropTypes.number
};

class StTextArea extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {value, model, maxLength} = this.props;
        let maxLengthReached = false;
        let hasMaxLength = false;
        let valueLength = _isUndefined(value) ? 0 : value.length;

        if (!_isUndefined(maxLength)) {
            hasMaxLength = true;
            maxLengthReached = valueLength >= maxLength;
        }

        const checkMaxLength = (event) => {
            if (maxLength && !(event.which == '46' || event.which == '8' || event.which == '13'))
                event.preventDefault();
        };

        return (
            <div>
                {/*<textarea {...this.props}></textarea>*/}
                <Control.textarea
                    {...this.props}
                    onKeyUp={checkMaxLength}
                    disabled={this.props.disabled || maxLengthReached}
                />
                <div className="u-text-right u-muted">
                    { hasMaxLength && <span>{ maxLength - valueLength } / { maxLength } merkkiä jäljellä</span>}
                </div>
            </div>
        )
    }
}

StTextArea.propTypes = propTypes;

export default StTextArea;
/*
const mapStateToProps = (state, props) => ({
    value: getField(state, props.model).value
});

export default connect(mapStateToProps)(StTextArea);*/
