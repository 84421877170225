/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import Filter from './Filter';
import Pagination from './Pagination';
import ResultsPerPage from './ResultsPerPage';
import MDIcon from 'shared/components/MDIcon';
import Overlay from 'shared/components/Overlay';

import Spinner from '../MDSpinner.jsx';

/**
 * @deprecated Käytä container-versiota.
 */
class DataTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filteredResults: null,
            resultsPerPage: 20,

            columns: null,

            isAllSelected: false,
            ascOrder: null,
            currentSortColumn: null,

            currentPage: 0,
            pageCount: 0,
            externalPageCount: null,

            filterTerm: '',
            isFiltering: false,
            filterColumn: null,

            selectedRows: [],
        };
    }

    // TODO: More validations
    validateProps() {
        if ((! _.isArray(this.props.filteringOptions)) && (this.props.useFiltering)) {
            console.warn('You have set filtering on but gave no filtering options.');
        }

        return true;
    }

    getColumns() {
        let usedColumns = null;
        const {
            columns,
            columnMetadata,
        } = this.props;

        if (! columns) {
            if (_.isObject(columnMetadata)) {
                usedColumns = Object.keys(this.props.columnMetadata);
            } else {
                console.error('No columns nor column meta data given.');
            }
        } else {
            usedColumns = this.props.columns;
        }

        this.setState({
            columns: usedColumns
        });
    }

    UNSAFE_componentWillMount() {
        this.validateProps();

        this.setState({
            resultsPerPage: this.props.initialResultsPerPage
        });

        this.getColumns();

        if (this.props.singleSelect && this.props.originalSingleSelection !== false) {
            let originalSingleSelection = this.props.originalSingleSelection;
            if (originalSingleSelection === true) {
                originalSingleSelection = 0;
            }

            const selectedRows = this.state.selectedRows;
            selectedRows.push(originalSingleSelection);
            this.setState({
                selectedRows: selectedRows,
            });
        }
    }

    componentDidMount() {
        this.filteringTimeout = null;

        if (this.props.initialSortingOrder == 'asc') {
            this.setState({ ascOrder: true });
        } else if (this.props.initialSortingOrder == 'desc') {
            this.setState({ ascOrder: false });
        }

        this.setState({
            currentSortColumn: this.props.initialSortColumn
        });

        this.filterResults();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (this.props.initialFilterColumn) {
            this.setState({
                filterColumn: this.props.initialFilterColumn
            });
        }

        // TODO: Mikko: Ei toimi tämä jos antaa päivittyneen listan tälle komponentille
        // Liittyisikö uuden reactin propsien immutabilityyn..
        //if (! this.props.filteringOptions) {
        //    this.props.filteringOptions = this.state.columns;
        //}

        if (this.props.useFiltering) {
            if (this.state.isFiltering) {
                clearTimeout(this.filteringTimeout);
                this.setState({
                    isFiltering: false
                });
            }
        }

        this.setState({
            results: nextProps.results
        });

        if (this.state.filterTerm) {
            this.filterResults(this.state.filterTerm, nextProps.results);
        }
    }

    getCurrentResults() {
        return this.state.filteredResults || this.props.results;
    }

    getAscOrder() {
        if (this.props.useExternal) {
            return this.props.externalAscOrder;
        }
        return this.state.ascOrder;
    }

    getCurrentSortColumn() {
        if (this.props.useExternal) {
            return this.props.externalSortColumn;
        }
        return this.state.currentSortColumn;
    }

    getResultsPerPage() {
        if (this.props.useExternal) {
            return this.props.externalResultsPerPage;
        }
        return this.state.resultsPerPage;
    }

    getPageCount() {
        let results,
            resultCount,
            resultsPerPage = this.getResultsPerPage(),
            pageCount;

        if (this.props.useExternal) {
            return this.props.externalPageCount;
        }

        results = this.getCurrentResults();
        if (resultCount = _.size(results)) {
            if (resultCount > 0) {
                pageCount = parseInt(resultCount / resultsPerPage, 10);

                if (pageCount * resultsPerPage < resultCount) {
                    pageCount++;
                }
            }

            return pageCount - 1;
        } else {
            return 0;
        }
    }

    getCurrentPage() {
        return this.props.externalCurrentPage || this.state.currentPage;
    }

    removeItem(item) {
        const results = this.getCurrentResults();
        const index = results.indexOf(item);

        results.splice(index, 1);

        this.setState({
            results: results
        });
    }

    handleSelectAllRows(event) {
        const isAllSelected = event.target.checked;
        let selectedRows = [];
        const results = this.getCurrentResults();

        if (isAllSelected) {
            selectedRows = results.map((result, key) => key);
            this.props.onAllSelected(results);
        } else {
            this.props.onAllUnselected();
        }

        this.setState({
            isAllSelected: isAllSelected,
            selectedRows: selectedRows
        });
    }

    handleSelectRow = (event, id) => {
        const isRowSelected = event.target.checked;
        let selectedRows = this.state.selectedRows;
        const results = this.getCurrentResults();

        if (isRowSelected) {
            if (! _.includes(selectedRows, id)) {
                selectedRows.push(id);
            }
            this.props.onRowSelected(results[id]);
        } else {
            selectedRows = _.without(selectedRows, id);
            this.props.onRowUnselected(results[id]);
        }

        this.setState({
            selectedRows: selectedRows,
            isAllSelected: false,
        });
    };

    handleSingeSelectRow = (event, id) => {
        const results = this.getCurrentResults();
        this.props.onRowSelected(results[id]);
        this.setState({
            selectedRows: [id],
        });
    };

    setPage = (currentPage) => {
        if (currentPage !== this.state.currentPage) {
            this.setState({
                isAllSelected: false
            });
        }

        if (this.props.useExternal) {
            this.props.externalSetPage(currentPage);
        } else {
            this.setState({
                currentPage: currentPage
            });
        }
    };

    onPrevPage = (event) => {
        let currentPage = this.getCurrentPage();

        event.preventDefault();

        if (this.props.useExternal) {
            this.props.externalPrevPage();
        } else {
            if (currentPage > 0) {
                this.setState({
                    currentPage: --currentPage
                });
            }
        }
    };

    onNextPage = (event) => {
        let currentPage = this.getCurrentPage();

        event.preventDefault();

        if (this.props.useExternal) {
            this.props.externalNextPage();
        } else {
            if (currentPage < this.getPageCount()) {
                this.setState({
                    currentPage: ++currentPage
                });
            }
        }
    };

    updatePagination = (resultsPerPage) => {
        const results = this.getCurrentResults();

        if (_.isUndefined(resultsPerPage)) {
            resultsPerPage = this.state.resultsPerPage;
        }

        if (results && (! this.props.useExternal)) {
            const resultCount = results.length;
            let pageCount = 0,
                currentPage = this.state.currentPage;

            if (resultCount > 0) {
                pageCount = parseInt(resultCount / resultsPerPage, 10);

                if (pageCount * resultsPerPage < resultCount) {
                    pageCount++;
                }
                if (currentPage >= pageCount) {
                    currentPage--;
                }
            }

            this.setState({
                pageCount: pageCount,
                currentPage: currentPage
            });
        }
    };

    onResultsPerPageUpdate = (event) => {
        const value = event.target.value;

        const resultsPerPage = parseInt(value, 10);

        if (this.props.useExternal) {
            this.props.externalSetResultsPerPage(resultsPerPage);
        } else {
            this.setState({
                resultsPerPage: resultsPerPage
            });

            this.updatePagination(resultsPerPage);
        }
    };

    handleSortingResults = (column) => {
        if (this.props.useExternal) {
            this.props.externalSort(column);
            return;
        }

        if (this.state.currentSortColumn == column) {
            this.setState({
                ascOrder: !this.state.ascOrder
            });
        }

        this.setState({
            currentSortColumn: column
        });
    };

    /**
     * Datan filtteröinti.
     *
     * Käyttää joko oletusfiltteriä joka olettaa datan olevan flattia.
     * Muutoin mikäli filterBy tai filterMethod annettu, käyttää niitä.
     * @param filterTerm
     * @param resultSet
     */
    filterResults(filterTerm, resultSet) {
        let results;
        const {
            filterMethod,
            filterBy,
        } = this.props;

        // resultSet may be passed explicitly, otherwise revert to this.props.results
        resultSet = resultSet || this.props.results;

        if (_.isUndefined(filterTerm)) {
            filterTerm = this.state.filterTerm.toLowerCase();
        } else {
            filterTerm = filterTerm.toLowerCase();
        }

        if (this.props.useExternal) {
            this.props.externalSetFilter(filterTerm);
            return;
        }

        results = [];
        if (filterTerm) {
            if (filterMethod) {
                // Jos annettu oma filtteröintimetodi käytä sitä.
                results = filterMethod(filterTerm, resultSet);
            } else if (filterBy) {
                // Jos annettu polku filtteröitävään sisältöön käydään se läpi ja etsitään merkkijonoa.
                results = _.filter(resultSet, (item) =>
                    _.get(item, filterBy, '').toString().toLowerCase().indexOf(filterTerm) > -1
                );
            } else {
                results = _.filter(resultSet, (item) => {
                    const values = _.values(item);

                    for (let i = 0; i < values.length; i++) {
                        const value = (values[i] || '').toString().toLowerCase();

                        if (value.indexOf(filterTerm) >= 0) {
                            return true;
                        }
                    }

                    return false;
                });
            }
            //this.updatePagination();
        } else {
            results = null;
        }

        this.setState({
            filteredResults: results
        });
    }

    handleResultFiltering = (event) => {
        const filterTerm = event.target.value;

        this.setState({
            filterTerm: filterTerm
        });

        this.filterResults(filterTerm);
    };

    handleFilterColumnChange(event) {
        this.setState({
            filterColumn: event.target.value
        });
    }

    renderSortOrderArrow(column) {
        const columnMetadata = this.props.columnMetadata[column];
        const isLocked = 'isLocked' in columnMetadata && columnMetadata['isLocked'] || false;

        const arrowClasses = classNames({
            [this.props.ascendingIcon]: this.getAscOrder(),
            [this.props.descendingIcon]: !this.getAscOrder()
        });

        if (this.getCurrentSortColumn() == column && isLocked === false) {
            return (
                <span>
                    <MDIcon icon={arrowClasses} modifierClass="u-align-middle" size="small" />
                </span>
            );
        } else {
            return null;
        }
    }

    renderThead() {
        let i = 0,
            thClass,
            thStyle;

        if (! this.props.showHeader) return null;

        if (! this.state.columns) return null;

        const columns = _.filter(_.map(this.state.columns, (column) => {
            if (this.props.columnMetadata) {
                const
                    columnMetadata = this.props.columnMetadata[column],
                    columnClassName = _.get(columnMetadata, 'className', ''),
                    isLocked = 'isLocked' in columnMetadata && columnMetadata['isLocked'] || false,
                    isDisabled = 'isDisabled' in columnMetadata && columnMetadata.isDisabled;

                if (isDisabled) return null;

                thClass = classNames(this.props.tableCellClass, {
                    'o-table__th--sortable': !isLocked,
                    [columnClassName]: columnClassName != ''
                });

                if ('style' in columnMetadata) {
                    thStyle = columnMetadata['style'];
                } else {
                    thStyle = null;
                }

                return (
                    <th onClick={isLocked ? null : this.handleSortingResults.bind(null, column)} key={i++} className={thClass} style={thStyle}>
                        {'displayName' in columnMetadata ? columnMetadata.displayName : column} {this.renderSortOrderArrow(column)}
                    </th>
                );
            } else {
                return (
                    <th key={i++}>
                        {column}
                    </th>
                );
            }
        }));

        if (this.props.useSelectableRows) {
            thClass = classNames(this.props.tableCellClass, {
                'cell--checkbox': true
            });

            if (this.props.singleSelect) {
                columns.unshift(
                    <th key={i}/>
                );
            } else {
                columns.unshift(
                    <th className={thClass} key={i}>
                        <input type="checkbox" onChange={this.handleSelectAllRows.bind(this)} checked={this.state.isAllSelected} />
                    </th>
                );
            }
        }

        return (
            <thead className={this.props.tableTheadClass}>
                <tr>
                    {columns}
                </tr>
            </thead>
        );
    }

    renderColumns(result, rowKey) {
        let i = 0,
            index,
            filterTerm = this.state.filterTerm,
            tdStyle,
            rowClass;

        const {
            useClickableRows,
            onRowHover,
            onRowClick,
        } = this.props;

        const isRowDisabled = this.props.isRowDisabled(result);

        const columns = _.filter(_.map(this.state.columns, (column) => {
            let columnMetadata = null,
                customComponent = null,
                data = result[column],
                cellClass,
                customCellClass;

            if ((this.props.columnMetadata) && (this.props.columnMetadata.hasOwnProperty(column))) {
                columnMetadata = this.props.columnMetadata[column];

                if ('isDisabled' in columnMetadata && columnMetadata.isDisabled) return null;

                customCellClass = columnMetadata.hasOwnProperty('className') ? columnMetadata['className'] : null;
            }

            cellClass = classNames(this.props.tableCellClass, {
                [customCellClass]: customCellClass !== null,
                'u-position-relative': isRowDisabled,
            });

            if ('style' in columnMetadata) {
                tdStyle = columnMetadata['style'];
            } else {
                tdStyle = null;
            }

            if (_.has(columnMetadata, 'customComponent')) {
                customComponent = columnMetadata.customComponent;

                const element = React.cloneElement(customComponent, {
                    data: data,
                    className: columnMetadata.className,
                    rowData: result,
                    filterTerm: this.state.filterTerm,
                    removeItem: this.removeItem.bind(null, result),
                    handleClick: customComponent.hasOwnProperty('props') ? customComponent.props.handleClick : null
                });
                return (
                    <td key={i++} className={cellClass} style={tdStyle}>
                        <Overlay isVisible={isRowDisabled} />
                        {element}
                    </td>
                );
            } else {
                return (
                    <td key={i++} className={cellClass} style={tdStyle}>
                        <Overlay isVisible={isRowDisabled} />
                        <Highlighter
                            highlightClassName="o-highlight"
                            searchWords={[filterTerm]}
                            autoEscape={true}
                            textToHighlight={_.toString(data)}
                        />
                    </td>
                );
            }

        }));


        if (this.props.useSelectableRows) {
            index = this.getCurrentResults().indexOf(result);
            if (this.props.singleSelect) {
                columns.unshift(
                    <td className="cell--checkbox" key={i}>
                        <input type="radio" onChange={(event) => this.handleSingeSelectRow(event, index)} checked={_.includes(this.state.selectedRows, index)} key={++i} name="list_select"/>
                    </td>
                );
            } else {
                columns.unshift(
                    <td className="cell--checkbox" key={i}>
                        <input type="checkbox" onChange={(event) => this.handleSelectRow(event, index)} checked={_.includes(this.state.selectedRows, index)} key={++i}/>
                    </td>
                );
            }
        }

        rowClass = classNames(this.props.tableRowClass, {
            [this.props.selectedRowClass]: result.isSelected
        });

        return (
            <tr
                className={rowClass}
                key={rowKey}
                onMouseOver={useClickableRows ? onRowHover.bind(null, result, rowKey) : null}
                onClick={useClickableRows ? onRowClick.bind(null, result, rowKey) : null}
            >
                {columns}
            </tr>
        );
    }

    renderRows(results) {
        // We need column names so we know what to render. Otherwise exit.
        if (! this.state.columns) { return null; }

        var rows = [],
            startIndex,
            endIndex,
            resultsPerPage = this.getResultsPerPage();

        // If we use external rendering we don't care how much data we receive
        if (this.props.useExternal) {
            results.map(function(result, rowKey) {
                return rows.push(this.renderColumns(result, rowKey));
            }.bind(this));
        } else {
            startIndex = resultsPerPage * this.getCurrentPage();
            if (startIndex > results.length) {
                startIndex = 0;
            }
            endIndex = startIndex + resultsPerPage;

            if (endIndex > results.length) {
                endIndex = results.length;
            }
            if (endIndex - startIndex >= resultsPerPage) {
                endIndex = resultsPerPage + startIndex;
            }

            for (let k = startIndex; k < endIndex; k++) {
                rows.push(this.renderColumns(results[k], k));
            }
        }

        return rows;
    }

    renderResults(results) {
        let currentPage = this.getCurrentPage(),
            pageCount = this.getPageCount();

        if (this.props.isLoading) {
            return this.props.spinnerComponent;
        }

        if (_.size(results) > 0) {
            if (currentPage > pageCount) {
                currentPage = (pageCount - 1 > 0 ? pageCount - 1 : 0);
            }

            const { useClickableRows } = this.props;

            const tableClass = classNames(this.props.tableClass, {
                'o-table--interaction': useClickableRows
            });

            return (
                <div>
                    <div className="u-overflow-horizontal">
                        <table className={tableClass}>
                            {this.renderThead()}
                            <tbody>
                                {this.renderRows(results)}
                            </tbody>
                        </table>
                    </div>

                    <Pagination
                        resultsPerPage={this.state.resultsPerPage}
                        prevArrowText={this.props.prevArrowText}
                        prevArrowIcon={this.props.prevArrowIcon}
                        onPrevPage={this.onPrevPage}
                        nextArrowText={this.props.nextArrowText}
                        nextArrowIcon={this.props.nextArrowIcon}
                        onNextPage={this.onNextPage}
                        currentPage={currentPage}
                        pageCount={pageCount}
                        setPage={this.setPage}
                    />
                </div>
            );
        } else {
            return this.props.noResultsMessage;
        }
    }

    render() {
        const {
            isLoading,
            useFiltering,
            columnMetadata,
        } = this.props;

        const {
            currentSortColumn
        } = this.state;

        let results = this.getCurrentResults();

        // Default built-in filter component
        let filterComponent = (
            <Filter
                searchIcon={this.props.searchIcon}
                filterColumn={this.state.filterColumn}
                filterPlaceholderText={this.props.filterPlaceholderText}
                filterButtonText={this.props.filterButtonText}
                filteringOptions={this.props.filteringOptions}
                columnMetadata={this.props.columnMetadata}
                handleResultFiltering={this.handleResultFiltering}
                handleFilterColumnChange={this.handleFilterColumnChange}
            />
        );

        // Default built-in results-per-page component
        let resultsPerPageComponent = (
            <ResultsPerPage
                useFiltering={this.props.useFiltering}
                resultsPerPage={this.getResultsPerPage()}
                resultsPerPageText={this.props.resultsPerPageText}
                onResultsPerPageUpdate={this.onResultsPerPageUpdate}
            />
        );

        // Jos sarakkeella on oma järjestysmetodinsa, käytä sitä.
        if (currentSortColumn !== null) {
            if (_.has(columnMetadata, [currentSortColumn, 'sortBy'])) {
                const sortBy = columnMetadata[currentSortColumn].sortBy;
                results = _.sortBy(results, _.isFunction(sortBy) ? sortBy(results) : sortBy);
            } else {
                results = _.sortBy(results, this.state.currentSortColumn);
            }
        }

        if (! this.state.ascOrder && _.size(results) > 1) {
            results.slice().reverse();
        }

        // If user gives a custom component pass down the needed props to it
        if (this.props.filterComponent !== null) {
            filterComponent = React.createElement(this.props.filterComponent, {
                searchIcon: this.props.searchIcon,
                filterColumn: this.state.filterColumn,
                filterPlaceholderText: this.props.filterPlaceholderText,
                filterButtonText: this.props.filterButtonText,
                filteringOptions: this.props.filteringOptions,
                columnMetadata: this.props.columnMetadata,
                handleResultFiltering: this.handleResultFiltering,
                handleFilterColumnChange: this.handleFilterColumnChange,
            });
        }

        if (this.props.resultsPerPageComponent !== null) {
            resultsPerPageComponent = React.createElement(this.props.resultsPerPageComponent, {
                useFiltering: useFiltering,
                resultsPerPage: this.getResultsPerPage(),
                resultsPerPageText: this.props.resultsPerPageText,
                onResultsPerPageUpdate: this.onResultsPerPageUpdate
            });
        }

        const showActionBar = (useFiltering || _.size(this.props.results) > 10) && !isLoading;

        return (
            <div className={this.props.tableContainerClass}>
                { showActionBar &&
                <div className={this.props.actionBarClass}>
                    { useFiltering && filterComponent }
                    {_.size(this.props.results) > 10 && resultsPerPageComponent }
                </div> }
                {this.renderResults(results)}
            </div>
        );
    }
}

DataTable.defaultProps = {
    isLoading: false,
    useExternal: false,
    externalPageCount: null,
    externalSetResultsPerPage() {},
    externalCurrentPage: null,
    externalPrevPage() {},
    externalNextPage() {},
    externalSort() {},
    externalSortColumn: null,
    externalAscOrder: null,
    externalSelectedRows: [],

    tableContainerClass: '',
    actionBarClass: 'o-layout u-padding-bottom-small',

    results: null,
    initialResultsPerPage: 10,
    resultsPerPageComponent: null,
    resultsPerPageText: 'Tuloksia per sivu',
    columnMetadata: null,
    columns: null,
    tableClass: 'o-table o-table--small',
    tableTheadClass: 'o-table__th',
    tableRowClass: 'o-table__tr',
    tableRowHoverClass: 'o-table__tr--hover',
    tableCellClass: 'o-table__td',

    useSelectableRows: false,
    useClickableRows: false,
    singleSelect: false,
    originalSingleSelection: 0,
    onRowClick: () => {},
    onRowHover: () => {},

    prevArrowText: 'Edellinen',
    prevArrowIcon: 'chevron_left',
    nextArrowText: 'Seuraava',
    nextArrowIcon: 'chevron_right',

    ascendingIcon: 'keyboard_arrow_up',
    descendingIcon: 'keyboard_arrow_down',

    searchIcon: 'search',

    spinnerComponent: <Spinner wrapped={true} />,

    noDataMessage: 'No data',
    noResultsMessage: 'Ei tuloksia',
    updatingMessage: 'Päivitetään...',
    updatingMessageTimeout: 250,

    initialSortColumn: null,
    initialSortingOrder: 'asc',

    selectedRowClass: 'is-selected',

    onAllSelected() {},
    onAllUnselected() {},
    onRowSelected() {},
    onRowUnselected() {},

    currentPage: 0,
    pageCount: null,

    showHeader: true,
    useFiltering: false,
    filterTerm: '',
    filterPlaceholderText: 'Etsi...',
    filterButtonText: 'Etsi',
    filterColumn: null,
    filterComponent: null,

    filterBy: null,
    filterMethod: null,

    isRowDisabled: () => false,
};

DataTable.propTypes = {
    results: PropTypes.array,
    externalSelectedRows: PropTypes.array,

    tableContainerClass: PropTypes.string,
    actionBarClass: PropTypes.string,

    initialResultsPerPage: PropTypes.number,
    resultsPerPageComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),

    prevArrowText: PropTypes.string,
    nextArrowText: PropTypes.string,
    noDataMessage: PropTypes.node,
    noResultsMessage: PropTypes.node,

    updatingMessage: PropTypes.node,
    updatingMessageTimeout: PropTypes.number,

    customNoResultsComponent: PropTypes.element,

    filterComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),

    /**
     * Dataobjektin filtteröintiavain.
     */
    filterBy: PropTypes.string,

    /**
     * Oma filtteröintimetodi datan läpikäymiseen.
     */
    filterMethod: PropTypes.func,

    spinnerComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),

    singleSelect: PropTypes.bool,
    originalSingleSelection: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

    isRowDisabled: PropTypes.func,
};

module.exports = DataTable;
