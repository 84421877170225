import * as PropTypes from 'prop-types';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import React, { Component, Fragment } from 'react';
import FileInput from 'react-fine-uploader/file-input';
import { Button, Feedback } from 'shared/components';

export default class Uploader extends Component {
    static propTypes = {
        uploadUrl: PropTypes.string.isRequired,
        uploadButtonText: PropTypes.string.isRequired,
        uploadErrorMessage: PropTypes.string.isRequired,
        uploadedSuccessfullyMessage: PropTypes.string.isRequired,
        addNotification: PropTypes.func,
        communeName: PropTypes.string,
        isAdminOnly: PropTypes.bool,
        downloadBaseFileName: PropTypes.string,
        downloadBaseFileUrl: PropTypes.string,
        downloadBaseButtonText: PropTypes.string,
        isTaxCardUploader: PropTypes.bool,
    };

    static defaultProps = {
        addNotification () {},
        communeName: '',
        isAdminOnly: false,
        downloadBaseFileName: '',
        downloadBaseFileUrl: '',
        downloadBaseButtonText: '',
        isTaxCardUploader: false,
    };

    state = {
        totalRows: null,
        addedRows: null,
        inProgress: false,
        uploadSuccess: null,
        activatedTaxCards: null,
        hasErrorData: false,
        hasInfoData: false,
        correctionTempFile: null,
    };

    uploader = (url) => (
        new FineUploaderTraditional({
            options: {
                request: {
                    debug: true,
                    endpoint: url,
                    params: {
                        communeName: this.props.communeName,
                    },
                },
                callbacks: {
                    onProgress: () => {
                        this.setState({
                            inProgress: true,
                            totalRows: null,
                            addedRows: null,
                            activatedTaxCards: null,
                            hasErrorData: false,
                            hasInfoData: false,
                            uploadSuccess: null,
                        });
                    },
                    onComplete: (id, name, responseJSON) => {
                        this.setState({
                            correctionTempFile: responseJSON.correctionTempFile,
                            totalRows: responseJSON.totalRows,
                            addedRows: responseJSON.addedRows,
                            inProgress: false,
                            activatedTaxCards: responseJSON.activatedTaxCards,
                            hasErrorData: responseJSON.hasErrorData,
                            hasInfoData: responseJSON.hasInfoData,
                            uploadSuccess: responseJSON.success,
                        });
                    }
                }
            }
        })
    );

    renderUploadButton = () => (
        <FileInput
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            uploader={this.uploader(this.props.uploadUrl)}
        >
            <Button
                mdIcon="cloud_upload"
                iconSize="small"
                admin={this.props.isAdminOnly}
                inProgress={this.state.inProgress}
            >
                {this.props.uploadButtonText}
            </Button>
        </FileInput>
    );

    renderDownloadBaseFileButton = () => (
        <Button
            mdIcon="cloud_download"
            iconSize="small"
            href={Routing.generate('suoratyo_download_file', { fileName: this.props.downloadBaseFileName })}
            target="_blank"
            admin={this.props.isAdminOnly}
        >
            {this.props.downloadBaseButtonText}
        </Button>
    );

    renderDownloadBaseFileUrlButton = () => (
        <Button
            mdIcon="cloud_download"
            iconSize="small"
            href={this.props.downloadBaseFileUrl}
            target="_blank"
            admin={this.props.isAdminOnly}
        >
            {this.props.downloadBaseButtonText}
        </Button>
    );

    renderDownloadCorrectionExcelButton = () => {
        const { uploadSuccess, correctionTempFile } = this.state;

        if (uploadSuccess || correctionTempFile === null) return null;

        return (
            <Button
                negative
                href={Routing.generate('suoratyo_download_error_excel', { correctionFile: correctionTempFile })}
                mdIcon="cloud_download"
                modifierClass="u-text-truncate"
                iconSize="small"
            >
                {_trans('import.button.download_error_excel')}
            </Button>
        );
    };

    renderUploadMessage = () => {
        const { uploadSuccess, addedRows, totalRows, hasErrorData } = this.state;
        const { uploadErrorMessage, uploadedSuccessfullyMessage } = this.props;

        if (uploadSuccess === null) return null;

        const rowsAdded = (
            _trans(
                uploadedSuccessfullyMessage,
                {
                    added: addedRows,
                    total: totalRows
                }
            )
        );

        return (
            <Fragment>
                <Feedback modifierClass="u-margin-top-small" type="info">{rowsAdded}</Feedback>
                {hasErrorData && <Feedback modifierClass="u-margin-top-small" type="error">{uploadErrorMessage}</Feedback>}
            </Fragment>
        );
    };

    renderTaxCardInfoMessages = () => {
        const { addedRows, hasInfoData, activatedTaxCards } = this.state;

        if (hasInfoData) {
            return (
                <Feedback modifierClass="u-margin-top-small" type="warning">
                    {_trans('import.message.tax_cards_activated_successfully', { added: activatedTaxCards, total: addedRows })}
                </Feedback>
            );
        }

    };

    render() {
        return (
            <ul className="o-list-bare">
                <li className="o-list-bare__item u-margin-bottom-small">
                    {this.renderUploadButton()}
                </li>
                {this.props.downloadBaseFileName && (
                    <li className="o-list-bare__item u-margin-bottom-small">
                        {this.renderDownloadBaseFileButton()}
                    </li>
                )}
                {this.props.downloadBaseFileUrl && (
                    <li className="o-list-bare__item u-margin-bottom-small">
                        {this.renderDownloadBaseFileUrlButton()}
                    </li>
                )}
                <li className="o-list-bare__item u-margin-bottom-small">
                    {this.renderDownloadCorrectionExcelButton()}
                </li>
                <li className="o-list-bare__item u-margin-bottom-small">
                    {this.renderUploadMessage()}
                </li>
                {this.props.isTaxCardUploader && (
                    <li className="o-list-bare__item u-margin-bottom-small">
                        {this.renderTaxCardInfoMessages()}
                    </li>
                )}
            </ul>
        );
    }
}
