import { produce } from 'immer';
import api from 'api';
import { isAssignmentContractType, isCompensationEarnerContractType } from 'shared/constants/contract';

// Sopparin api v3 käyttää näitä. Ovat siis yhdistelmä contractStatea ja contractDataStatea.
export const contractMainStates = {
    ACCEPTED: 1,
    PROPOSAL: 2,
    DRAFT: 3,
    TERMINATED: 4,
    UNFINISHED: 98,
    EMPLOYER_PROPOSAL: 11,
};

/**
 * Työsopimukset.
 */
export default {
    state: {
        accepted: [],
        proposals: [],
        drafts: [],
        terminated: [],
        contractsBySSN: {
            [contractMainStates.ACCEPTED]: [],
            [contractMainStates.PROPOSAL]: [],
            [contractMainStates.DRAFT]: [],
            [contractMainStates.TERMINATED]: [],
        },
    },

    reducers: {
        setAcceptedContracts: (state, accepted) => ({ ...state, accepted }),
        setProposalContracts: (state, proposals) => ({ ...state, proposals }),
        setDraftContracts: (state, drafts) => ({ ...state, drafts }),
        setTerminatedContracts: (state, terminated) => ({ ...state, terminated }),
        setContractsBySSN: (state, contractsBySSN) => ({ ...state, contractsBySSN }),
        // Poistetaan vain listalta, ei tehdä turhaa requa
        removeDraftContract: (state, jobContractDataId) => produce(state, (draftState) => {
            draftState.drafts = draftState.drafts.filter((contract) => contract.jobContractDataId !== jobContractDataId);
        }),
        setErrorMessage: (state, contractState, errorMessage) => ({ ...state, ['contractState_' + contractState]: errorMessage }),
    },

    selectors: {
        getContracts: (state) => (
            []
                .concat(state.accepted)
                .concat(state.proposals)
                .concat(state.drafts)
                .concat(state.unfinished)
                .concat(state.terminated)
        ),
        getAcceptedContracts: (state, ssn) => ssn
            ? state.contractsBySSN[contractMainStates.ACCEPTED]
            : state.accepted,
        getProposalContracts: (state, ssn) => ssn
            ? state.contractsBySSN[contractMainStates.PROPOSAL]
            : state.proposals,
        getDraftContracts: (state, ssn) => ssn
            ? state.contractsBySSN[contractMainStates.DRAFT]
            : state.drafts,
        getTerminatedContracts: (state, ssn) => ssn
            ? state.contractsBySSN[contractMainStates.TERMINATED]
            : state.terminated,
        getErrorMessage: (state) => (contractState) => state['contractState_' + contractState],
        /**
         * Onko kyseessä toimeksiantosopimus.
         * @param state
         * @param contractId
         * @returns {boolean}
         */
        isAssignmentContract: (state, contractId) => {
            const contract = state.find((contract) => _.get(contract, 'jobContractId') === parseInt(contractId, 10));
            return contract
                ? isAssignmentContractType(_.get(contract, 'type'))
                : false;
        },
        isCompensationEarnerContract: (state, contractId) => {
            const contract = state.find((contract) => _.get(contract, 'jobContractId') === parseInt(contractId, 10));
            return contract
                ? isCompensationEarnerContractType(_.get(contract, 'type'))
                : false;
        }
    },

    effects: (dispatch) => ({
        /**
         * Hakee sopimukset tilan mukaan.
         * @returns {Promise<*>}
         */
        async fetchContractsByState(contractState) {
            try {
                const json = await api
                    .get(`/api/v3/contracts?state=${contractState}`);

                // Ainakin toistaiseksi virheviesti vain jos kyseessä valtuushallintaongelma
                const isDimensionsError = json.error === 'error_no_access_to_dimensions';
                if (isDimensionsError) {
                    this.setErrorMessage(contractState, json.message);
                }

                return json.data ?? [];
            } catch (e) {
                console.log(e);
                return false;
            }
        },

        /**
         * Aktiiviset.
         */
        async fetchAcceptedContracts() {
            const response = await this.fetchContractsByState(contractMainStates.ACCEPTED);
            if (response) {
                return this.setAcceptedContracts(response);
            }
            dispatch.notifications.addError('Voimassa olevia sopimuksia ei voitua hakea.', {}, 'jobContract');
        },

        /**
         * Hyväksymättömät.
         */
        async fetchProposalContracts() {
            const response = await this.fetchContractsByState(contractMainStates.PROPOSAL);
            if (response) {
                return this.setProposalContracts(response);
            }
            dispatch.notifications.addError('Hyväksyttäviä sopimuksia ei voitua hakea.', {}, 'jobContract');
        },

        /**
         * Luonnokset.
         */
        async fetchDraftContracts() {
            const response = await this.fetchContractsByState(contractMainStates.DRAFT);
            if (response) {
                return this.setDraftContracts(response);
            }
            dispatch.notifications.addError('Luonnostilassa olevia sopimuksia ei voitua hakea.', {}, 'jobContract');
        },

        /**
         * Päättyneet.
         */
        async fetchTerminatedContracts() {
            const response = await this.fetchContractsByState(contractMainStates.TERMINATED);
            if (response) {
                return this.setTerminatedContracts(response);
            }
            dispatch.notifications.addError('Päätettyjä sopimuksia ei voitua hakea.', {}, 'jobContract');
        },

        async fetchContractBySSN(ssn) {
            const route = `/api/v3/contracts-by-ssn?ssn=${ssn}`;
            const json = await api.get(route);
            const data = json?.data ?? [];

            return this.setContractsBySSN(data);
        },

        /**
         * Työnantajan luomat avoimet sopparit.
         */
        async fetchEmployerProposals() {
            const response = await this.fetchContractsByState(contractMainStates.EMPLOYER_PROPOSAL);
            if (response) {
                return this.setDraftContracts(response);
            }
            dispatch.notifications.addError('Luonnostilassa olevia sopimuksia ei voitua hakea.', {}, 'jobContract');
        },

        /**
         * Kaikki sopparit.
         */
        fetchContracts(params) {
            const { canFetchDraftContracts = false, canFetchEmployerProposals = false } = params ?? {};
            this.fetchAcceptedContracts();
            this.fetchProposalContracts();
            if (canFetchDraftContracts) {
                this.fetchDraftContracts();
            }
            // Nämä haetaan normaalisti kun haetaan draft Contractit mutta tässä voidaan hakea ne erikseen
            if (canFetchEmployerProposals) {
                this.fetchEmployerProposals();
            }
            this.fetchTerminatedContracts();
        }
    }),
};
