import _ from 'lodash';

export const tradeUnionSubscriptionTypes = {

    /**
     * Ei erikoista raportointi- tai tilitystapaa
     */
    TYPE_DEFAULT: 0,

    /**
     * JHL-liiton jäsenmaksujen raportointi- ja tilitystapa
     */
    TYPE_JHL: 1,
};

export const tradeUnionSubscriptionTypeAsString = [
    { id: tradeUnionSubscriptionTypes.TYPE_DEFAULT, name:_trans('userForm.tradeUnion.data_table.columns.type_default') },
    { id: tradeUnionSubscriptionTypes.TYPE_JHL, name: _trans('userForm.tradeUnion.data_table.columns.type_jhl') }
];

export function translateTradeUnionSubscriptionType(type) {
    const translateString = _.find(tradeUnionSubscriptionTypeAsString, function (stringType) {
        return stringType.id === type;
    });
    return _.get(translateString, 'name', _trans('general.unknown'));
}
