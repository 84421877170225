import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';
import { InputGroup } from 'shared/components';
import validators from 'shared/validators';
import { StErrors, StHelp, StField } from 'shared/components/StForm';
import { groupDropdownOptions } from 'shared/utils/groupDropdownOptions';
import Dropdown from 'shared/components/Dropdown';

const EmployeeWorkExperience = (props) => {
    const { isReadOnly, model, jobTitles, isMultipleWorkExpLimitsApplied } = props;
    // Olettaa, että jos enemmän kuin yksi title, niin ollaan YP, muuten kunta, jossa vain henkilökohtainen avustaja
    const hasSelectJobTitle = jobTitles.length > 1;

    let workExpLevelHelpText = null;

    if (isMultipleWorkExpLimitsApplied) {
        workExpLevelHelpText = _trans('users.employee_details.tooltip_multiple_work_exp_levels');
    } else {
        workExpLevelHelpText = hasSelectJobTitle
            ? _trans('users.employee_details.tooltip')
            : _trans('users.employee_details.tooltip_commune');
    }

    return (
        <div>
            {! isMultipleWorkExpLimitsApplied && (
                <div id="jobTitleGroupLabel">
                    {hasSelectJobTitle
                        ? _trans('users.employee_details.work_experience')
                        : _trans('users.employee_details.work_experience_commune')}
                </div>
            )}
            <StField>
                <InputGroup aria-describedby="jobTitleHelp" aria-labelledby="jobTitleGroupLabel">
                    {hasSelectJobTitle && (
                        <Control.select
                            model=".jobTitle"
                            className="u-margin-right-small"
                            component={Dropdown}
                            optionValue="value"
                            optionLabel="label"
                            options={groupDropdownOptions(jobTitles)}
                            placeholder={_trans('users.employee_details.select_job_title')}
                            validators={{
                                isRequired: (value) => value ? validators.isRequired(value) : false,
                            }}
                        />
                    )}
                    {! isMultipleWorkExpLimitsApplied && (
                        <Fragment>
                            <Control.text
                                model={model}
                                readOnly={isReadOnly}
                                validators={{
                                    isRequired: validators.isRequired,
                                    isNumeric: validators.isNumeric,
                                }}
                                size={6}
                            />
                            <abbr
                                className="c-button is-disabled"
                                title={_trans('unit.Months')}
                            >
                                {_trans('unit.month')}
                            </abbr>
                        </Fragment>
                    )}
                </InputGroup>
                <StHelp id="jobTitleHelp">
                    {workExpLevelHelpText}
                </StHelp>
                <StErrors model={model} />
                {hasSelectJobTitle && (
                    <StErrors model=".jobTitle" />
                )}
            </StField>
        </div>
    );
};

EmployeeWorkExperience.propTypes = {
    isReadOnly: PropTypes.bool,
    model: PropTypes.string,
    jobTitles: PropTypes.array,
    isMultipleWorkExpLimitsApplied: PropTypes.bool,
};

EmployeeWorkExperience.defaultProps = {
    model: '.amount',
    isReadOnly: false,
    jobTitles: [],
    isMultipleWorkExpLimitsApplied: false,
};

export default EmployeeWorkExperience;
