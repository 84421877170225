import React from 'react';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { MenuBarItem } from 'shared/components/MenuBarItem';
import { default as Switch } from 'shared/components/Switch.jsx';
import api from 'api';

const postIgnoreVisibilityDimension = (ignoreVisibilityDimensions) => api
    .post('/api/bookkeeping/dimensions/full-dimension-visibility', { value: ignoreVisibilityDimensions });

export const IgnoreVisibilityDimension = () => {
    const ignoreVisibilityDimension = useSelector(select.roleSelector.getIgnoreVisibilityDimension);

    return (
        <MenuBarItem tooltip="Mikäli valittuna, ei valtuushallinnan näkyvyysrajoitteita sovelleta">
            <Switch
                id="ignoreVisibilityDimension"
                isOn={ignoreVisibilityDimension}
                onChange={() => {
                    const newValue = ! ignoreVisibilityDimension;

                    postIgnoreVisibilityDimension(newValue)
                        .then(() => window.location.reload());
                }}
                modifierClass="u-color-admin"
            >
                Ohita valtuushallinta
            </Switch>
        </MenuBarItem>
    );
};
