import { rolePages } from '../constants';
import api from 'api';
import userRoles from 'shared/constants/userRoles';

/**
 * Roolinvalinta.
 */
export const roleSelector = {
    state: {
        roles: [],
        companies: [],
        communes: [],
        // Yritys, joka on kutsunut käyttäjän palveluun
        invitedCompanies: [],

        page: rolePages.ROLE_LIST,

        // Onko roolinvaihto käynnissä
        isSwitching: false,

        // Vanha kohde-URL
        target: null,
        // Pitäisikö ohjata homeen vaiko kuntapuolelle targetin perusteella
        isHomeTarget: true,
        hasEndUserRelations: false,
    },

    reducers: {
        setInvitedCompanies: (state, invitedCompanies) => ({ ...state, invitedCompanies }),
        setRoles: (state, roles) => ({ ...state, roles }),
        setCompanies: (state, companies) => ({ ...state, companies }),
        setCommunes: (state, communes) => ({ ...state, communes }),
        setPage: (state, page) => ({ ...state, page }),
        setIsSwitching: (state, isSwitching) => ({ ...state, isSwitching }),
        setTarget: (state, target) => ({ ...state, target }),
        setIsHomeTarget: (state, isHomeTarget) => ({ ...state, isHomeTarget }),
        setIgnoreVisibilityDimension: (state, value) => ({ ...state, ignoreVisibilityDimension: value }),
        setHasEndUserRelations: (state, value) => ({ ...state, hasEndUserRelations: value }),
    },

    selectors: {
        getCompanies: (state) => state.companies,
        getCommunes: (state) => state.communes,
        getPage: (state) => state.page,
        getSelectableRoles: (state) => state.roles,
        isSwitching: (state) => state.isSwitching,
        getTarget: (state) => state.target,
        isHomeTarget: (state) => state.isHomeTarget,
        getIgnoreVisibilityDimension: (state) => state.ignoreVisibilityDimension,
        hasEndUserRelations: (state) => state.hasEndUserRelations,
    },

    effects: () => ({
        /**
         * Hakee vaihdettavissa olevat käyttäjäroolit, yritykset ja kunnat.
         */
        async fetchRoles() {
            try {
                const response = await api.get('/api/v2/roles');

                if (response?.status === 'ok' && response.data) {
                    const roles = response.data ?? [];
                    const { ignoreVisibilityDimension, hasEndUserRelations } = response.meta ?? false;

                    this.setIgnoreVisibilityDimension(ignoreVisibilityDimension);
                    this.setHasEndUserRelations(hasEndUserRelations);
                    this.setInvitedCompanies(response.data.invitedCompanies ?? []);
                    // Otetaan sekä yritysroolit että dimensiohyväksyjät yrityslistan alle.
                    const companies = roles.filter((role) => [userRoles.COMPANY_OPERATOR, userRoles.COMPANY_APPROVER].includes(role.role));
                    // Aakkosjärjestykseen
                    this.setCompanies(companies.sort((a, b) => {
                        if (a.companyName > b.companyName) {
                            return -1;
                        } else if (a.companyName < b.companyName) {
                            return 1;
                        }
                        return 0;
                    }).reverse());
                    const communes = roles.filter((role) => role.role === userRoles.COMMUNE_OPERATOR);
                    this.setCommunes(communes.sort((a, b) => {
                        if (a.communeName > b.communeName) {
                            return -1;
                        } else if (a.communeName < b.communeName) {
                            return 1;
                        }
                        return 0;
                    }).reverse());

                    // Apista saadut roolit.
                    const roleCodes = roles.map((item) => item.role);
                    // Otetaan uniikit nyt näin kun Jest ei syystä tai toisesta ymmärrä ...new Set
                    const uniqueRoles = roleCodes
                        .filter((item, index) =>
                            roleCodes.indexOf(item) === index
                        );
                    const hasCompanies = companies.length > 0;

                    // Poistetaan yritys- ja tuntienhyväksyjärooli päävalinnoista mikäli löytyy valittavissa olevia yrityksiä.
                    // Poistetaan myös kuntapäärooli koska tulee omana listanaan jos kuntavalintoja on.
                    const selectableRoles = uniqueRoles.filter((role) => (
                        !(hasCompanies && [userRoles.COMPANY_OPERATOR, userRoles.COMPANY_APPROVER].includes(role))
                        && (role !== userRoles.COMMUNE_OPERATOR)
                    ));

                    this.setRoles(selectableRoles);
                }
                return false;
            } catch (error) {
                console.log(error);
                // this.setError(true);
            }
        },

        /**
         * Tutkii löytyykö target:ia, varmistaa että on samassa domainissa ja
         * tallentaa tiedon siitä onko target yp vai kuntapuolella.
         */
        resolveTarget() {
            const target = new URLSearchParams(location.search).get('target');

            // Tarkistetaan varmuudeksi ollaanko Oimassa
            const host = window.location.origin;
            const isSameDomain = typeof target === 'string'
                ? target.length >= host.length && target.startsWith(host)
                : false;

            let pathname = '';
            // Pätkitään targetista pathname jos se löytyy
            if (isSameDomain && target) {
                const url = new URL(target);
                pathname = url.pathname;
            }

            this.setTarget(isSameDomain ? target : null);
            // Jos alkaa /commune ei oletettavasti olla menossa yp:lle...
            this.setIsHomeTarget(! pathname.startsWith('/commune'));
        }
    })
};
