/**
 * Vakuutustyypit. Kopsittu InsuranceType.php:stä.
 */

export const insuranceTypes = {
    ACCIDENT: 1,
    TYEL: 2,
    TVR: 3,
    ABSENCE: 4,
    ITEM: 5,
    JUEL: 7,
};

/**
 * Tapaturmavakuutuksen tyypit
 * Kopsittu AccidentInsuranceType.php
 */
export const accidentInsuranceTypes = {
    /**
     * Kattovakuutus
     */
    RING: 2,

    /**
     * Oma vakuutus
     */
    EXTERNAL: 3,

    /**
     * Pohjola Vakuutuksen yrityksen automaattinen vakuutus
     */
    OP_COMPANY: 11,
};

/**
 * Vakuutuksen käännös tyypin mukaan.
 */
const insuranceTypeMap = {
    [insuranceTypes.ACCIDENT]: _trans('insurances.type.accident_insurance', {}, 'insurances'),
    [insuranceTypes.TYEL]: _trans('insurances.type.tyel', {}, 'insurances'),
    [insuranceTypes.JUEL]: _trans('insurances.type.juel', {}, 'insurances'),
};

/**
 * Vakuutuksen ikonityypit.
 */
const insuranceIconMap = {
    [insuranceTypes.ACCIDENT]: 'accident_insurance',
    [insuranceTypes.TYEL]: 'tyel_insurance',
    [insuranceTypes.JUEL]: 'tyel_insurance',
};

export const translateInsuranceType = (type) => type in insuranceTypeMap ? insuranceTypeMap[type] : _trans('insurances.type.unknown', {}, 'insurances');
export const getInsuranceIcon = (type) => type in insuranceIconMap ? insuranceIconMap[type] : null;

/**
 * Tyel-vakuutustyypit. Kopsittu Suoratyo\TyelBundle\Entity\TyelInsurance.php.
 */
export const tyelInsuranceTypes = {
    TEMPORARY_EMPLOYER: 1,
    CONTRACT_EMPLOYER: 5,
    COMMUNE_CONTRACT_EMPLOYER: 10,
    COMMUNE_NOPSA: 11,
    COMMUNE_UNDEFINED: 19,
    JUEL: 30,
};

/**
 * Vakuutuksen käännös tyypin mukaan.
 */
const tyelInsuranceTypeMap = {
    [tyelInsuranceTypes.TEMPORARY_EMPLOYER]: _trans('insurances.tyel.type.temporary_employer', {}, 'insurances'),
};

export const translateTyelInsuranceType = (type) => type in tyelInsuranceTypeMap ? tyelInsuranceTypeMap[type] : _trans('insurances.tyel.type.unknown', {}, 'insurances');


/**
 * Tyel reporting periods
 */
export const tyelReportingPeriods = {
    MONTHLY: 1,
    YEARLY: 2,
};

export const tyelInstitutionsTypes = {
    PARTNER: 1,
    EXTERNAL: 2,
};
