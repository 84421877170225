/**
 * Dynaaminen lista. Renderöi N-kappaletta blocks:eja.
 */
import { blocks } from 'shared/ReForm/constants/blocks';
import { customComponentNames } from 'ContractV3/constants/customComponents';

export const arraySchema = {
    type: 'object',
    properties: {
        name: {
            type: 'string',
            title: 'Listan päänimi',
            description: 'Pakollinen päänimi jonka avulla muut syöttökenttien nimen muodostetaan indeksin kanssa.',
            minLength: 2,
        },
        addComponent: {
            type: 'string',
            title: 'Komponentti jonka avulla listaan voidaan lisätä uusia palikkakokonaisuuksia.',
            enum: customComponentNames,
        },
        block: { const: blocks.ARRAY },
        blocks: {
            '$ref': '#/definitions/blocks'
        },
    },
    required: ['name', 'block', 'blocks'],
    additionalProperties: false,
};
