import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Avatar from 'shared/components/Avatar';
import { componentSizes } from 'shared/constants';

function ListItem({
    children,
    modifierClass,
}) {
    const spanClass = classNames('o-flag o-flag--small', {
        [modifierClass]: modifierClass !== '',
    });

    return (
        <li className="o-list__item">
            <span className={spanClass}>
                {children}
            </span>
        </li>
    );
}

ListItem.propTypes = {
    children: PropTypes.node.isRequired,
    modifierClass: PropTypes.string,
};

ListItem.defaultProps = {
    modifierClass: '',
};

/**
 * to-propsilla muuttuu React-Router linkiksi
 *
 * @param children
 * @param isSelected
 * @param url
 * @param onClick
 * @param size
 * @param to
 * @param target
 * @returns {*}
 */
ListItem.Link = function ({
    children,
    isSelected,
    url,
    onClick,
    size,
    to,
    target,
}) {
    const listItemLinkClass = classNames('o-list__item-link', {
        'is-selected': isSelected,
    });

    const listItemModifierClass = classNames('o-list__item', {
        'u-padding-vertical-small': size === componentSizes.LARGE
    });

    if (to) {
        return (
            <li className={listItemModifierClass}>
                <Link
                    to={to}
                    data-autofocus={isSelected ? true : null}
                    className={listItemLinkClass}
                >
                    <span className="o-flag o-flag--small">
                        {children}
                    </span>
                </Link>
            </li>
        );
    }

    if (onClick) {
        return (
            <li className={listItemModifierClass}>
                <button
                    className={listItemLinkClass}
                    onClick={(event) => {
                        if (onClick !== null) {
                            event.preventDefault();
                            onClick();
                        }
                    }}
                >
                    <span className="o-flag o-flag--tiny">
                        {children}
                    </span>
                </button>
            </li>
        );
    }

    return (
        <li className={listItemModifierClass}>
            <a
                href={url}
                data-autofocus={isSelected}
                className={listItemLinkClass}
                target={target !== '' ? target : null}
            >
                <span className="o-flag o-flag--tiny">
                    {children}
                </span>
            </a>
        </li>
    );
};

ListItem.Link.displayName = 'ListItem.Link';

ListItem.Link.propTypes = {
    children: PropTypes.node.isRequired,
    isSelected: PropTypes.bool,
    url: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string,
    to: PropTypes.string,
    target: PropTypes.string,
};

ListItem.Link.defaultProps = {
    isSelected: false,
    url: '#',
    onClick: null,
    size: 'medium',
    to: null,
    target: '',
};

/* eslint-disable-next-line react/display-name */
ListItem.Avatar = function ({
    src,
    hasPlaceholder
}) {
    return (
        <span className="o-flag__img o-list__item-anchor" style={{ fontSize: 0 }}>
            <Avatar src={src} hasPlaceholder={hasPlaceholder}/>
        </span>
    );
};

ListItem.Avatar.propTypes = {
    src: PropTypes.string,
    hasPlaceholder: PropTypes.bool,
};

ListItem.Avatar.defaultProps = {
    src: '',
    hasPlaceholder: false,
};

/**
 * Listen ankkurielementti (kuva / ikoni / jokin muu graafinen elementti).
 * Ankkurielementtejä voi olla vain yksi per listan rivi.
 * @type {{children}}
 */
ListItem.Anchor = function ({
    children,
    isImage,
}) {
    // Jos kyseessä on kuva (bitmap tai svg) aseta sen parentin fonttikooksi 0 jotta kuvan ympärille ei tule
    // tyhjää tilaa.
    const anchorStyle = isImage ? { fontSize: 0 } : {};

    return (
        <span
            className="o-flag__img u-padding-left-small u-padding-right-tiny u-position-relative"
            style={anchorStyle}
        >
            {children}
        </span>
    );
};

ListItem.Anchor.displayName = 'ListItem.Anchor';

ListItem.Anchor.propTypes = {
    children: PropTypes.node.isRequired,
    isImage: PropTypes.bool,
};

ListItem.Anchor.defaultProps = {
    isImage: true,
};

ListItem.PrimaryContent = ({
    children,
    hasPlaceholder,
    modifierClass,
}) => {
    const PrimaryContentClass = classNames('o-list__item-primary-content o-flag__body', {
        'o-list__item-primary-content--placeholder': hasPlaceholder,
        [modifierClass]: modifierClass !== '',
    });
    return (
        <span className={PrimaryContentClass}>
            {children}
        </span>
    );
};

ListItem.PrimaryContent.displayName = 'ListItem.PrimaryContent';

ListItem.PrimaryContent.propTypes = {
    children: PropTypes.node.isRequired,
    hasPlaceholder: PropTypes.bool,
    modifierClass: PropTypes.string,
};

ListItem.PrimaryContent.defaultProps = {
    hasPlaceholder: false,
    modifierClass: '',
};

ListItem.SecondaryContent = ({
    children,
    hasPlaceholder,
    modifierClass,
}) => {
    const SecondaryContentClass = classNames('o-list__item-secondary-action o-flag__body u-padding-right-small u-text-right', {
        'o-list__item-secondary-action--placeholder': hasPlaceholder,
        [modifierClass]: modifierClass !== '',
    });

    return (
        <span className={SecondaryContentClass}>
            {children}
        </span>
    );
};

ListItem.SecondaryContent.displayName = 'ListItem.SecondaryContent';

ListItem.SecondaryContent.propTypes = {
    children: PropTypes.node.isRequired,
    hasPlaceholder: PropTypes.bool,
    modifierClass: PropTypes.string,
};

ListItem.SecondaryContent.defaultProps = {
    hasPlaceholder: false,
    modifierClass: '',
};

export default ListItem;
