import React from 'react';
import PropTypes from 'prop-types';
import { DateRange } from './../components/DateRange';
import { DateTo } from './../components/DateTo';
import { Feedback } from 'shared/components';

export const RangeContainer = ({ values }) => (
    <div className="u-margin-top-small">
        <h4 className="u-margin-bottom-small">
            {_trans('Tee selvitys väliltä', {}, 'extract')}
        </h4>
        <Feedback type="info">
            {/**
                Disclaimer koska jyvitystä ei osata tehdä päivätarkkuudella, vain viikkotarkkuudella
                Vähän mvp tämä feedback, parempi ois ehkä pakottaa käyttäjä valitteen alku- ja loppuviikko tms
             */}
            {_trans(
                'Tärkeää: valitut päivämäärät kohdistetaan viikon tarkkuudella (alku- ja loppuviikko) eli raporttia ei muodosteta päivätarkkuudella.',
                {},
                'extract'
            )}
        </Feedback>
        <DateRange values={values} />
        <DateTo values={values} />
    </div>
);

RangeContainer.propTypes = {
    values: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired
};
