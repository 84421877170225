import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import classNames from 'classnames';
import { validators, errorMessages, } from 'shared/validators';
import SubmitButton from 'shared/components/SubmitButton';
import MDIcon from 'shared/components/MDIcon';
import InputGroup from 'shared/components/InputGroup';
import MDSpinner from 'shared/components/Spinner';
import { FormikErrors } from 'shared/components/Formik/index';

/**
 * Yleiskäyttöinen komponentti HeTun kyselyyn.
 * Vahvistuksen callbackissa voi tehdä mitä lystää.
 */
export default class SsnQueryForm extends PureComponent {
    onSubmit = (values) => {
        this.props.onConfirm(_.get(values, 'socialSecurityNumber', null));
    };

    renderIcon(showIcon, isValidSsn) {
        if (! showIcon) return null;

        return (
            <MDIcon
                icon={isValidSsn ? 'check_circle' : 'error'}
                modifierClass={classNames('u-text-center', {
                    'u-animation-bounce-in u-color-positive': isValidSsn,
                    'u-animation-shake u-color-negative': ! isValidSsn,
                })}
                size="small"
            />
        );
    }

    render() {
        const {
            hasAutoFocus,
            isPending,
            errorMessage,
        } = this.props;

        // Jos virheviesti ei ole tyhjä ja halutaan näyttää asetetaan luokka joka ottaa vastaan
        // hiiren osoittimen / täppäyksen eventit jotta virheviestin voi halutessaan nähdä.
        const validationIndicatorClass = classNames('o-form__validation-indicator o-pin o-pin--right', {
            'o-form__validation-indicator-has-message': errorMessage !== '',
        });

        return (
            <Formik
                initialValues={{
                    socialSecurityNumber: '',
                }}
                validateOnChange
                onSubmit={this.onSubmit}
                validationSchema={Yup.object().shape({
                    socialSecurityNumber: Yup.string()
                        .required(errorMessages.isRequired)
                        .test('socialSecurityNumber', errorMessages.isSsn, validators.isSsn),
                })}
            >
                {(props) => {
                    const { handleSubmit, handleChange, isValid, dirty, values, errors } = props;
                    const value = _.get(values, 'socialSecurityNumber', '');
                    const errorMessage = _.get(errors, 'socialSecurityNumber', null);

                    return (
                        <form
                            onSubmit={handleSubmit}
                        >
                            <InputGroup>
                                <div className="u-position-relative">
                                    <Field
                                        type="search"
                                        disabled={isPending}
                                        name="socialSecurityNumber"
                                        /* eslint-disable-next-line jsx-a11y/no-autofocus */
                                        autoFocus={hasAutoFocus}
                                        onChange={handleChange}
                                        size={12}
                                        maxLength={11}
                                        className="u-text-uppercase"
                                    />
                                    <span className={validationIndicatorClass}>
                                        {isPending ?
                                            <MDSpinner size="small"/> :
                                            this.renderIcon(
                                                (dirty && value.length > 10),
                                                isValid,
                                                errorMessage,
                                            )
                                        }
                                    </span>
                                </div>
                                <SubmitButton disabled={isPending} modifierClass="u-align-top">
                                    {_trans('button.confirm')}
                                </SubmitButton>
                            </InputGroup>
                            <FormikErrors name="socialSecurityNumber"/>
                        </form>
                    );
                }}
            </Formik>
        );
    }

}

SsnQueryForm.defaultProps = {
    hasAutoFocus: true,
    onConfirm() {},
    isPending: false,
    errorMessage: '',
};

SsnQueryForm.propTypes = {
    /**
     * Fokusoidaanko automaattisesti HeTu-kenttään.
     */
    hasAutoFocus: PropTypes.bool,

    /**
     * Mitä tapahtuu kun käyttäjä painaa hae-nappia.
     */
    onConfirm: PropTypes.func,

    /**
     * Indikoi ssn-requestia.
     */
    isPending: PropTypes.bool,

    /**
     * Mahdollinen virheviesti.
     * Viesti mahdollista näyttää esim. tooltippinä tai feedbackina.
     */
    errorMessage: PropTypes.string,
};
