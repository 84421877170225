export const accessRoles = {
    /**
     * Käyttäjä
     */
    ROLE_USER: 1,

    /**
     * Admin
     */
    ROLE_ADMIN: 2,

    /**
     * Kunta operaattori
     */
    ROLE_COMMUNE: 3,

    /**
     * Kunta admin
     */
    ROLE_COMMUNE_ADMIN: 4,

    /**
     * Kunta pääkäyttäjä
     */
    ROLE_COMMUNE_SUPER_USER: 5,
};

export const accessRoleNames = {
    [accessRoles.ROLE_USER]: _trans('access_role.roles_by_id.1'),
    [accessRoles.ROLE_ADMIN]: _trans('access_role.roles_by_id.2'),
    [accessRoles.ROLE_COMMUNE]: _trans('access_role.roles_by_id.3'),
    [accessRoles.ROLE_COMMUNE_ADMIN]: _trans('access_role.roles_by_id.4'),
    [accessRoles.ROLE_COMMUNE_SUPER_USER]: _trans('access_role.roles_by_id.5'),
};

export const translateAccessRole = (accessRole) => _.get(accessRoleNames, accessRole, _trans('access_role.unknown'));

