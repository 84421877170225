import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Value } from 'shared/components/Value';
import { resolveSalaryPostFix } from 'ContractV3/utils/salaries/resolveSalaryPostFix';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { get } from 'shared/utils/get';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';

/**
 * Kulukorvauksen erityislisä.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CostReimbursementSpecialAllowance = (props) => {
    const { label, value, values, arrayName } = props;
    const salaryType = values[fieldNames.SALARY_TYPE];
    const hasCostReimbursementTableSalaries = useSelector(select.contract.hasCostReimbursementTableSalaries);
    const isPendingSalary = get(values, resolveArrayName(arrayName, fieldNames.IS_PENDING_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE));

    if (! hasCostReimbursementTableSalaries) return null;

    return (
        <SummaryListItem term={label}>
            <Value>
                {isPendingSalary
                    ? _trans('Odottaa indeksikorotuksia', {}, 'jobContract')
                    : resolveSalaryPostFix(salaryType, _currency(value))
                }
            </Value>
        </SummaryListItem>
    );
};

CostReimbursementSpecialAllowance.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    arrayName: PropTypes.string.isRequired,
};

export default CostReimbursementSpecialAllowance;
