import api from 'api';
import { noteTypes } from 'shared/constants/noteTypes';

const mergedState = (state = {}) => Object.assign({}, { note: '' }, state);

/**
 * Muistiinpanot.
 */
export const notes = {
    state: mergedState(),

    reducers: {
        setNotes: (state, payload) => payload,
    },

    selectors: {
        getNotes: mergedState,
        hasNotes: (state) => state?.note && state?.note !== '',
    },

    effects: () => ({
        async fetchNotes({ typeId, noteType }) {
            try {
                const route = noteType === noteTypes.BENEFIT_DECISION
                    ? `/api/v1/benefitdecisions/${typeId}/note`
                    : `/api/v1/contracts/${typeId}/note`;

                const response = await api.get(route);
                this.setNotes(mergedState(response.data));
                return response;
            } catch (e) {
                console.error(e);
            }
        },

        async saveNotes({ typeId, notes, noteType, }) {
            const { noteId, note } = notes;

            const postRoute = noteType === noteTypes.BENEFIT_DECISION
                ? `/api/v1/benefitdecisions/${typeId}/notes`
                : `/api/v1/contracts/${typeId}/notes`;

            try {
                return noteId
                    ? await api.put(`/api/v1/notes/${noteId}`, { note })
                    : await api.post(postRoute, { note });
            } catch (e) {
                console.error(e);
            }
        }
    })
};

