import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import _ from 'lodash';
import MDIcon from './MDIcon';
import { sizes } from 'shared/constants';
import alertTypes from 'shared/constants/alertTypes';

const TIP = 'tip';

/**
 * Normaali palautekomponentti. Yleensä infotekstiä käyttäjälle.
 * Viesti voi sisältää myös HTML:ää.
 *
 * TODO: Selvitettävää.
 * `onFeedbackClose` ei toimi jos sille annettu funktio tekee XHR kutsun.
 */

const Feedback = (props) => {
    const [isVisible, setIsVisible] = useState(true);

    const {
        type,
        title,
        message,
        isOpaque,
        children,
        isDismissable,
        onFeedbackClose,
        modifierClass,
        hasIcon,
        size,
    } = props;

    // Tarkistetaan onko annettu joko message-prop tai lapsielementtejä.
    if (_.isUndefined(message) && React.Children.count(children) === 0) {
        console.error('You must give either message or children.');
        return null;
    }

    const feedbackClass = classNames('c-feedback o-flag', {
        'o-flag--small': !isOpaque,
        'o-flag--tiny': isOpaque,
        'c-feedback--info': type === alertTypes.INFO,
        'c-feedback--success': type === alertTypes.SUCCESS,
        'c-feedback--warning': type === alertTypes.WARNING,
        'c-feedback--error': type === alertTypes.ERROR,
        'c-feedback--tip': type === TIP,
        'c-feedback--opaque': isOpaque,

        'u-padding-tiny': size === sizes.TINY && ! isOpaque,
        'u-padding-small': size === sizes.SMALL && ! isOpaque,

        'o-flag--top': isOpaque,

        [modifierClass]: modifierClass,
    });

    // Riippuen notifikaatin tyypistä määritä miten screen readerit viestin lukevat
    let ariaLiveType = 'polite';

    let feedbackIcon = 'info';

    if (type === alertTypes.SUCCESS) {
        feedbackIcon = 'check_circle';
    }

    if (type === alertTypes.WARNING) {
        feedbackIcon = 'warning';
        ariaLiveType = 'assertive';
    }

    if (type === alertTypes.ERROR) {
        feedbackIcon = 'error';
        ariaLiveType = 'assertive';
    }

    if (type === TIP) {
        feedbackIcon = 'lightbulb_outline';
    }

    let messageBody = null;
    // Lisätään dompurifyn attributteihin target, niin toimii "_blank" linkeissä
    const config = { ADD_ATTR: ['target'] };
    // Jos viesti on lista tee siitä sellainen!
    if (Array.isArray(message)) {
        messageBody = (
            <ul className="u-margin-bottom-none">
                {message.map((singleMessage, key) => (
                    <li key={key}
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(singleMessage.message, config)
                        }}
                    />
                ))}
            </ul>
        );
    } else if (React.Children.count(children) > 0) {
        messageBody = children;
    } else {
        messageBody = (
            <span
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(message, config)
                }}
            />
        );
    }

    if (! isVisible) return null;

    return (
        <span className={feedbackClass}>
            { hasIcon && (
                <span className="c-feedback__icon o-flag__img">
                    <MDIcon icon={feedbackIcon} modifierClass={isOpaque ? 'u-align-top' : ''} isFilled />
                </span>
            )}

            <span className="c-feedback__body o-flag__body" role="alert" aria-live={ariaLiveType}>
                {title && <strong>{title}</strong>}
                <span className="u-display-block">
                    {messageBody}
                    {isDismissable &&
                    <button
                        className="o-pin o-pin--right o-pin--top o-interaction u-margin-top-small u-margin-right-small"
                        onClick={() => {
                            if (typeof onFeedbackClose === 'function') {
                                onFeedbackClose();
                            }

                            setIsVisible(false);
                        }}
                    >
                        <MDIcon icon="close"/>
                    </button>}
                </span>
            </span>
        </span>
    );
};

Feedback.defaultProps = {
    title: '',
    message: '',
    isOpaque: false,
    children: null,
    isDismissable: false,
    onFeedbackClose: () => {},
    modifierClass: null,
    hasIcon: true,
    size: sizes.SMALL,
};

Feedback.propTypes = {
    /**
     * Message type.
     */
    type: PropTypes.oneOf(_.map(alertTypes)).isRequired,

    /**
     * Message title.
     */
    title: PropTypes.string,

    /**
     * The message itself. Either use this or wrap the children with the component.
     */
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

    /**
     * Onko viestillä taustaväriä.
     */
    isOpaque: PropTypes.bool,

    /**
     * Sisältö. Anna joko tämä tai message-prop.
     */
    children: PropTypes.node,

    /**
     * Onko palaute suljettavissa.
     */
    isDismissable: PropTypes.bool,

    /**
     * Mitä tehdään kun suljetaan. Oletuksena piilotetaan koko feedback.
     */
    onFeedbackClose: PropTypes.func,

    /**
     *  CSS-luokka
     */
    modifierClass: PropTypes.string,

    /**
     * Näytetäänkö palautteen tyypin mukainen ikoni.
     */
    hasIcon: PropTypes.bool,

    /**
     * Koko. Lähinnä padding ja ikonin koko.
     */
    size: PropTypes.oneOf(_.map(sizes)),
};

export default Feedback;
