import React from 'react';
import { useSelector } from 'react-redux';
import { useTransition, animated } from 'react-spring/web.cjs';
import { select } from '@rematch/select';
import { rolePages } from '../constants';
import { RoleList } from './RoleList';
import { RoleAutoComplete } from './RoleAutoComplete';
import MDSpinner from 'shared/components/MDSpinner';
import userRoles from 'shared/constants/userRoles';
import { defaultAnimationConfig } from 'shared/constants/animations';

/**
 * Pääroolin valinta.
 * Jos aiemmin valittu rooli on yritys tai kunta ohjataan yritys/kuntalistaan.
 * @returns {*}
 * @constructor
 */
export const RoleSelector = () => {
    const isLoading = useSelector((state) => state.loading.effects.roleSelector.fetchRoles);

    const page = useSelector(select.roleSelector.getPage);
    const companies = useSelector(select.roleSelector.getCompanies);
    const communes = useSelector(select.roleSelector.getCommunes);
    const pages = [
        {
            key: rolePages.ROLE_LIST,
            component: <RoleList />,
        },
        {
            key: rolePages.COMPANY_LIST,
            component: companies.length > 0 && <RoleAutoComplete userRole={userRoles.COMPANY_OPERATOR} items={companies}/>,
        },
        {
            key: rolePages.COMMUNE_LIST,
            component: communes.length > 0 && <RoleAutoComplete userRole={userRoles.COMMUNE_OPERATOR} items={communes}/>,
        },
    ];

    const reverse = (page === rolePages.ROLE_LIST);
    const transitions = useTransition(pages[page], (p) => p.key, {
        unique: true,
        from: {
            opacity: 0,
            position: 'absolute',
            transform: `translate3d(${reverse ? '-10%' : '10%'}, 0, 0)`
        },
        enter: {
            opacity: 1,
            position: 'static',
            transform: 'translate3d(0,0,0)'
        },
        leave: {
            opacity: 0,
            position: 'absolute',
            transform: `translate3d(${reverse ? '10%' : '-10%'}, 0, 0)`
        },
        config: defaultAnimationConfig,
        initial: {
            opacity: 1,
            position: 'static',
            transform: 'translate3d(0,0,0)'
        },
    });

    if (isLoading) {
        return <MDSpinner wrapped />;
    }

    return transitions.map(({ item, props, key }) => (
        <animated.div
            key={key}
            style={{
                ...props,
                width: '100%'
            }}
        >
            <div style={{ maxWidth: '520px', padding: '0 12px', margin: '0 auto' }}>
                {item.component}
            </div>
        </animated.div>
    ));
};
