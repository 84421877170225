import React, { useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Rivien valintaan checkbox.
 */
export const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef();
        const resolvedRef = ref || defaultRef;

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return <input type="checkbox" ref={resolvedRef} {...rest} />;
    }
);

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

IndeterminateCheckbox.propTypes = {
    // eslint-disable-next-line react/boolean-prop-naming
    indeterminate: PropTypes.bool.isRequired,
};
