import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Grid-komponentilta alaspäin valuvat tarvittavat propsit
 * breakpointtien perusteella. Katso Storybookista esimerkki.
 * @param children
 * @param isHidden
 * @param width
 * @param currentBreakpoint
 * @param breakpointsByWidth
 * @param id
 * @param breakpoints
 * @returns {*}
 * @constructor
 */
const GridItem = ({ children, isHidden, width, currentBreakpoint, breakpointsByWidth, id, ...breakpoints }) => {
    const breakpointEntries = Object.entries(breakpoints);
    const lastGivenBreakpoint = breakpointEntries[breakpointEntries.length - 1];

    // Jos koko on alle pienimmän breakpointin, aseta täysi leveys. Muutoin aseta viimeisin annettu breakpoint.
    const size = breakpoints[currentBreakpoint] ?? (width < breakpointsByWidth[0][1] ? '1/1' : lastGivenBreakpoint[1]);

    return (
        <div
            id={id}
            aria-hidden={isHidden}
            className={classNames(`o-layout__item u-${size}`, {
                'u-hidden': isHidden,
            })}>
            {children}
        </div>
    );
};

GridItem.propTypes = {
    children: PropTypes.node.isRequired,
    isHidden: PropTypes.bool,
    width: PropTypes.number,
    breakpointsByWidth: PropTypes.array,
    currentBreakpoint: PropTypes.string,
    id: PropTypes.string,
};

GridItem.defaultProps = {
    isHidden: false,
    width: undefined,
    currentBreakpoint: null,
    breakpointsByWidth: [],
    id: undefined,
};

export default GridItem;
