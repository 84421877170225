import { useCallback } from 'react';
import useMergeState from './mergeState';
import api from 'api';

/**
 * Käyttö:
 * const [{ isPending, error, data }, makeRequest] = usePost/usePut/usePatch/useDel(URL)
 * Koodissa voi kutsuta makeRequest funkkaria, jossa parametri on requestin body
 * esim. makeRequest({ abc: 'def', ... })
 *
 * isPending kertoo onko requesti käynnissä
 * Errorriin mahdollinen virhe
 * data minkä bäkkäripalauttaa
 *
 * @param method
 * @param url
 * @return {[*, (...args: any[]) => any]}
 */
const useRequest = (method, url) => {
    if (! ['post', 'put', 'patch', 'del'].includes(method.toLowerCase())) {
        throw new Error('Not supported method: ' + method);
    }
    const [state, mergeState] = useMergeState({
        data: null,
        error: null,
        isPending: false,
    });

    const makeRequest = useCallback((body = {}) => {
        mergeState({ isPending: true });
        return api[method](url, body)
            .then(
                (data) => {
                    mergeState({ data, error: null, isPending: false });
                    return data;
                },
                (error) => {
                    mergeState({ data: null, error, isPending: false });
                    return error;
                },
            );
    },
    [method, url, mergeState]);

    return [state, makeRequest];
};

export const useApi = () => {
    const [state, mergeState] = useMergeState({
        data: null,
        error: null,
        isPending: false,
    });

    const makeRequestWithOptions = useCallback((method, url, body, options) => {
        if (! ['get', 'post', 'put', 'patch', 'del'].includes(method.toLowerCase())) {
            throw new Error('Not supported method: ' + method);
        }

        mergeState({ isPending: true });

        const req = method === 'get'
            ? api[method](url, options)
            : api[method](url, body, options);

        return req
            .then(
                (data) => {
                    mergeState({ data, error: null, isPending: false });
                    return data;
                },
                (error) => {
                    mergeState({ data: null, error, isPending: false });
                    return error;
                },
            );
    }, [mergeState]);

    const makeRequest = useCallback((method, url, body) =>
        makeRequestWithOptions(method, url, body, {}), [makeRequestWithOptions]);

    return { state, makeRequest, makeRequestWithOptions };
};

export default useRequest;
