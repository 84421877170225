import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { Field } from './Field';

/**
 * Näyttää mobiiliystävällisen numeerisen syöttökentän desimaaleilla.
 * Vääntää pisteet pilkuksi ja päinvastoin.
 *
 * Miksi inputMode?
 * https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/
 * @param type
 * @param children
 * @param name
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const DecimalField = ({ type, children, name, ...rest }) => {
    const [field,, helpers] = useField(name);

    // Muunnetaan pilkut pisteiksi jotta on yhteensopiva bäkkärin kanssa.
    const convertCommaToPeriod = (event) => {
        helpers.setValue(event.target.value?.toString().replace(',', '.'));
    };

    return (
        <Field
            type={type}
            name={name}
            onChange={convertCommaToPeriod}
            onBlur={convertCommaToPeriod}
            value={field.value?.toString().replace('.', ',')}
            {...rest}
            inputMode="decimal"
        >
            {children}
        </Field>
    );
};

DecimalField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

DecimalField.defaultProps = {
    type: 'text',
    name: undefined,
    children: undefined,
};
