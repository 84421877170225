import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SignatureOnPaper } from 'shared/ReForm/components/SignatureOnPaper';

const ReportingFormsSignaturesEmployer = ({ value }) => (
    <Fragment>
        <strong className="u-visibility-screen">{value}</strong>
        <SignatureOnPaper fullName={value} />
    </Fragment>
);

ReportingFormsSignaturesEmployer.propTypes = {
    value: PropTypes.string.isRequired,
};

export default ReportingFormsSignaturesEmployer;
