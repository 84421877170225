import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import { mainBreakPoints } from 'shared/constants/breakpoints';
import MenuBarOld from 'shared/components/MenuBarOld';
import { Logo } from 'shared/components/Logo';
import UserMenu from 'shared/containers/UserMenu';

/**
 * Applikaation responsiivinen headeri.
 * Pienessä selainkoossa heittää navigaation ruudun alalaitaan.
 * TODO: Lisää-nappi jos valikkoitemejä liikaa.
 */
export default class HeaderOld extends Component {
    static propTypes = {
        menuItems: PropTypes.array.isRequired,
        userMenuItems: PropTypes.array.isRequired,
    };

    render() {
        const {
            menuItems,
            userMenuItems,
        } = this.props;

        return (
            <div>
                <header
                    className="c-header o-stack o-stack--left u-padding-left-small"
                    role="banner"
                >
                    <MediaQuery minWidth={mainBreakPoints.MEDIUM}>
                        <Logo className={classNames('', { 'c-logo--testing': __DEV__ })} />
                    </MediaQuery>
                    <MediaQuery minWidth={mainBreakPoints.MEDIUM}>
                        <MenuBarOld
                            menuItems={menuItems}
                            modifierClass="u-margin-left-small"
                        />
                    </MediaQuery>

                    <UserMenu
                        menuItems={userMenuItems}
                    />
                </header>

                <MediaQuery maxWidth={mainBreakPoints.MEDIUM - 1}>
                    <MenuBarOld
                        menuItems={menuItems}
                        modifierClass="c-menu-bar--bottom"
                    />
                </MediaQuery>
            </div>
        );
    }
}
