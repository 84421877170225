import produce from 'immer';
import api from 'api';
import { actions as notificationActions } from 'shared/stores/notifications';
import { TYPE_COST_REIMBURSEMENT, TYPE_SALARY, TYPE_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE } from 'shared/constants/tableSalary';
import { salaryTypes } from 'shared/constants/salaryTypes';

const getCostReimbursementMetadata = (state, type) => state.tableSalaryData
    .find((metadata) => metadata.type === type)?.metadata ?? [];

export default {
    state: {
        tableSalaryData: [],
        tableSalaryMetadata: [],
        tableSalaryMetadataId: null,
        tableSalary: '0',
        useYearsWhenResolvingTableSalary: false,
        isMinimumTableSalaries: false,
        workTimeGroupingSettings: [],
    },
    reducers: {
        setData: (state, payload) => ({ ...state, tableSalaryData: payload }),
        setMetadata: (state, payload) => ({ ...state, tableSalaryMetadata: payload }),
        setMetadataId: (state, payload) => ({ ...state, tableSalaryMetadataId: payload }),
        setTableSalary: (state, payload) => ({ ...state, tableSalary: payload }),
        setUseYearsWhenResolvingTableSalary: (state, payload) => ({ ...state, useYearsWhenResolvingTableSalary: payload }),
        setIsMinimumTableSalaries: (state, isMinimumTableSalaries) => ({ ...state, isMinimumTableSalaries }),
        setWorkTimeGroupingSettings: (state, workTimeGroupingSettings) => ({ ...state, workTimeGroupingSettings }),
    },
    selectors: {
        getData: (state) => state.tableSalaryData,
        getSalaryData: (state) => state.tableSalaryData.find((metadata) => metadata.type === TYPE_SALARY) ?? {},
        getMetadata: (state) => state.tableSalaryMetadata || [],
        getMetadataId: (state) => state.tableSalaryMetadataId,
        getTableSalary: (state) => state.tableSalary,
        isUsingYearsWhenResolvingTableSalary: (state) => state.useYearsWhenResolvingTableSalary,
        isMinimumTableSalaries: (state) => state.isMinimumTableSalaries,
        getCostReimbursementMetadata: (state) => produce(getCostReimbursementMetadata(state, TYPE_COST_REIMBURSEMENT), (draftState) => {
            if (draftState[0]) {
                // Lisätään "Ei makseta" valinta roottiin, tätä osataan lukea bäkkärillä/frontilla
                draftState[0].options.push({
                    value: 'no-op',
                    label: {
                        fi: 'Ei makseta',
                        sv_FI: 'Inte betalad',
                        en_US: 'Not paid',
                    },
                    salaryType: [salaryTypes.PER_MONTH, salaryTypes.PER_DAY, salaryTypes.PER_HOUR],
                });
            }
        }),
        getCostReimbursementMetadataId: (state) => state.tableSalaryData
            .find((metadata) => metadata.type === TYPE_COST_REIMBURSEMENT)?.id ?? null,
        getCostReimbursementSpecialAllowanceMetadata: (state) => produce(getCostReimbursementMetadata(state, TYPE_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE), (draftState) => {
            if (draftState[0]) {
                // Lisätään "Ei makseta" valinta roottiin, tätä osataan lukea bäkkärillä/frontilla
                draftState[0].options.push({
                    value: 'no-op',
                    label: {
                        fi: 'Ei makseta',
                        sv_FI: 'Inte betalad',
                        en_US: 'Not paid',
                    },
                    salaryType: [salaryTypes.PER_MONTH, salaryTypes.PER_DAY, salaryTypes.PER_HOUR],
                });
            }
        }),
        getCostReimbursementSpecialAllowanceMetadataId: (state) => state.tableSalaryData
            .find((metadata) => metadata.type === TYPE_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE)?.id ?? null,
        getWorkTimeGroupingSettings: (state) => state.workTimeGroupingSettings,
        /**
         * TRUE jos kulareille on olemassa erillinen työaikojen grouppausasetus (oph-palkkiot)
         */
        getIsCostReimbursementGroupingSettingEnabled: (state) => {
            const workTimeGroupingSettings = state.workTimeGroupingSettings ?? [];

            return workTimeGroupingSettings.some((setting) => setting.isCostReimbursementSpecific);
        },
    },
    effects: (dispatch) => ({
        /**
         *
         * Hakee taulukkopalkan metadatan, jolla renderöidään taulukkopalkan valitsimet.
         *
         * @param collectiveAgreement
         * @param rootState
         * @param date
         * @return {Promise<object>}
         */
        async fetchTableSalaryMetadata(collectiveAgreement, rootState, date) {
            try {
                const json = await api.get(Routing.generate('api_1_get_tablesalary_metadata', { collectiveAgreement, date }));
                const metadatas = json?.data ?? [];
                this.setData(json?.data ?? []);
                /**
                 * Kellonaikapohjaisiin kirjauksiin liittyvät asetukset
                 */
                const workTimeGroupingSettings = json?.meta?.workTimeGroupingSettings ?? [];
                const salaryMetadata = metadatas.find((meta) => meta.type === TYPE_SALARY) ?? {};
                this.setMetadata(salaryMetadata.metadata ?? []);
                this.setMetadataId(salaryMetadata.id ?? null);
                this.setUseYearsWhenResolvingTableSalary(salaryMetadata.useYearsWhenResolvingTableSalary ?? false);
                this.setIsMinimumTableSalaries(salaryMetadata.isMinimumTableSalaries ?? false);
                this.setWorkTimeGroupingSettings(workTimeGroupingSettings);

                return salaryMetadata;
            } catch (e) {
                // Jokin meni vinoon. Resetoidaan metadatat.
                console.error(e);
                this.setMetadata([]);
                this.setMetadataId(null);
                this.setUseYearsWhenResolvingTableSalary(false);
                this.setIsMinimumTableSalaries(false);
                this.setWorkTimeGroupingSettings([]);
            }
        },
        /**
         *
         * @param collectiveAgreement
         * @param rootState
         * @param values
         * @return {Promise<void>}
         */
        async fetchTableSalary(collectiveAgreement, rootState, values) {
            try {
                const json = await api.get(Routing.generate('api_1_get_tablesalary_salary', { collectiveAgreement, ...values, types: [TYPE_SALARY] }));
                if (json.status !== 'error') {
                    this.setTableSalary(json.data);
                    return json.data;
                } else {
                    dispatch(notificationActions.addNotification({
                        type: 'error',
                        message: _trans('notifications.error.table_salary'),
                    }));
                }
            } catch (e) {
                console.error(e);
            }
        },
    }),
};
