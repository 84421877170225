import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { Switch } from 'shared/components';

/**
 * Käytä AINOASTAAN UI:lla näkyviin muutoksiin ja lomakkeen sisäisiin tarkisteluihin.
 * Jos tiedon halutaan lähtevän lomakkeen mukana on tämän tilalla käytettävä
 * checkboxia.
 * @param id
 * @param name
 * @param label
 * @param isDisabled
 * @param children
 * @param formik
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
export const SwitchField = ({ id, name, label, isDisabled, children, onChange }) => {
    const [field, , helpers] = useField(name);

    return (
        <Switch
            id={id}
            label={label}
            onChange={(isOn) => {
                helpers.setValue(isOn);
                onChange(isOn);
            }}
            isOn={name && (field.value ?? false)}
            disabled={isDisabled}
        >
            {children}
        </Switch>
    );
};

SwitchField.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    isDisabled: PropTypes.bool,
    formik: PropTypes.object,
    onChange: PropTypes.func,
    children: PropTypes.node.isRequired,
};

SwitchField.defaultProps = {
    id: null,
    label: null,
    isDisabled: false,
    formik: {},
    onChange() {},
};

