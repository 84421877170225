import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Dialogin alaosa.
 * @param children
 * @param modifierClass
 * @returns {*}
 * @constructor
 */
const DialogFooter = ({ children, modifierClass, }) => {
    const dialogFooterClass = classNames('c-dialog__footer', {
        [modifierClass]: modifierClass !== '',
    });

    return (
        <footer className={dialogFooterClass}>
            {children}
        </footer>
    );
};

DialogFooter.propTypes = {
    children: PropTypes.node.isRequired,
    modifierClass: PropTypes.string,
};

DialogFooter.defaultProps = {
    modifierClass: 'u-margin-top-small u-margin-top@medium',
};

export default DialogFooter;
