import { blocks as blockTypes } from 'shared/ReForm/constants/blocks';
import { inputTypes } from 'shared/ReForm/constants/inputTypes';
import { customComponents } from 'ContractV3/constants/customComponents';

/**
 * Ylikirjoitetaan validaatiotyyppi perustuen sen inputTypeen.
 * Datella ei voi olla mitään muuta validaatiotyyppiä kuin date.
 * @param block
 * @returns {string|null}
 */
export const resolveValidationTypeByBlock = (block) => {
    const { validationType = 'string' } = block;
    const customBlock = customComponents[block?.component] ?? {};
    const fallbackValidationType = (customBlock.validationType || validationType);

    if (block?.block !== blockTypes.INPUT) return fallbackValidationType;

    switch (block?.type) {
        case inputTypes.DATE:
            return 'date';
        case inputTypes.SWITCH:
            return 'boolean';
        default: return fallbackValidationType;
    }
};
