import React from 'react';
import PropTypes from 'prop-types';

/**
 * Sopimusten valinnassa käytettävä renderöijä.
 * @param focusedOption
 * @param focusOption
 * @param key
 * @param option
 * @param selectValue
 * @param style
 * @returns {*}
 * @constructor
 */
export const ContractOptionRenderer = ({ focusedOption, focusOption, key, option, selectValue, style }) => (
    <button
        onClick={() => selectValue(option)}
        onMouseEnter={() => focusOption(option)}
        className={`VirtualizedSelectOption VirtualizedSelectOption${focusedOption === option ? '-has-focus' : ''} o-stack--start`}
        key={key}
        style={style}
    >
        <div className="u-text-left">
            <strong className="u-display-block">
                {option.employerName ?? '-'}
            </strong>
            <div>
                {option.startDate ? _toLocaleDate(option.startDate) : '-'} –
                {_toLocaleDate(option.endDate, _trans('text.indefinite'))}
            </div>
        </div>
    </button>
);

ContractOptionRenderer.propTypes = {
    /**
     * Itse valinta ja sen arvot.
     */
    option: PropTypes.object.isRequired,
    /**
     * Valittu valinta.
     */
    focusedOption: PropTypes.object.isRequired,
    /**
     * Mitä tehdään kun valinta saa fokuksen.
     */
    focusOption: PropTypes.func.isRequired,
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /**
     * Mitä tehdään kun valinta... valitaan!
     */
    selectValue: PropTypes.func.isRequired,
    style: PropTypes.object.isRequired,
};
