import React, { Fragment } from 'react';
import { TyelHistoryTable } from './components/TyelHistoryTable.jsx';
import { TytalHistoryTable } from './components/TytalHistoryTable';
import Feedback from 'shared/components/Feedback';
import Page from 'shared/components/Page';
import { TabContainer } from 'shared/components/Tab';

export const History = () => (
    <Page
        title={_trans('Vakuutukset', {}, 'common')}
        heading={_trans('Vakuutukset', {}, 'common')}
    >
        <TabContainer
            tabs={{
                'history': {
                    label: _trans('Historia', {}, 'extract'),
                    content: (
                        <Fragment>
                            <Feedback
                                type="info"
                                message={_trans('Palvelu hoitaa mahdollisen työttömyysvakuutusmaksun automaattisesti käyttäjien puolesta.', {}, 'insurance')}
                                modifierClass="u-margin-bottom-small"
                            />
                            <TytalHistoryTable/>
                            <TyelHistoryTable/>
                        </Fragment>
                    )
                }
            }}
        />
    </Page>
);
