import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { fromJS } from 'immutable';
import { useField } from 'formik';
import { select } from '@rematch/select';
import { useParams } from 'react-router-dom';
import { ActionBar, GoBackLink, Page } from 'shared/components';
import UserForm from 'shared/UserDetails/containers/shared/UserForm';
import { generateRoutes } from 'ContractV3/constants/routes';
import resolveUrl from 'shared/utils/resolveUrl';
import { resolvePartyId } from 'shared/Parties/utils/resolvePartyId';
import companyUserRelationTypes from 'shared/constants/companyUserRelationTypes';
import { isCommune } from 'shared/utils/commonUtils';
import MDSpinner from 'shared/components/MDSpinner';
import useMergeState from 'shared/hooks/mergeState';
import api from 'api';
import { FormField } from 'shared/components/Formik';
import { Value } from 'shared/components/Value';
import {
    UserAttachedDimensionsForm
} from 'shared/UserDetails/containers/company/UserAttachedDimensions/UserAttachedDimensionsForm';

const routes = generateRoutes(isCommune());

const fetchUser = async (user) => {
    try {
        return await api.get(`/api/v2/users/${user}`);
    } catch (e) {
        return { status: false, };
    }
};

/**
 * Asiakkaan muokkaus ja lisäys.
 * @param name
 * @param caredId
 * @returns {JSX.Element}
 * @constructor
 */
export const CustomerForm = ({ name, caredId }) => {
    const dispatch = useDispatch();
    // Oletuksena fieldNames.CARED
    const [,,customerHelpers] = useField(name);
    const [state, setState] = useMergeState({
        showVisibilityForm: false,
        userId: null,
        ssn: null,
        user: {},
    });

    const benefitDecisionId = useParams()?.benefitDecisionId;
    const isCloningContract = useSelector(select.contract.isCloningContract);
    // Kloonatessa soppari-id:t saadaan urlista
    const { contractId, contractDataId } = useParams();
    const templateId = useSelector(select.contract.getTemplateId);
    const contractUrl = `${resolveUrl(
        isCloningContract ? routes.CLONE_CONTRACT : routes.NEW_CONTRACT,
        { benefitDecisionId, contractId, contractDataId }
    )}?template=${templateId}`;
    const isAssignmentContract = true;
    const visibilityDimensions = useSelector(select.dimensions.getVisibilityDimensions);
    const visibilityDimensionTypeName = useSelector(select.dimensions.getVisibilityDimensionTypeName);
    const isMonthlySalaryCustomerInvoicingEnabled = useSelector(select.userMetadata.isMonthlySalaryCustomerInvoicingEnabled);

    const userRole = useSelector(select.userMetadata.getUserRole);
    const isCompany = useSelector(select.userMetadata.isCompany);
    const initialUser = useSelector((state) => select.customers.getCustomerById(state, caredId));
    const isLoading = useSelector((state) => (
        state.loading.effects.employees.fetchEmployees
        || state.loading.effects.userMetadata.fetchUserMetadata
    ));
    const jobTitles = useSelector(select.jobTitles.getJobTitles);
    // Muokataanko henkilöä
    const isEditingUser = ! isNaN(caredId) && !! initialUser;
    const isVtjEnabled = useSelector(select.userMetadata.isVtjEnabled);

    const onUserNotVisibilityAccess = useCallback((userId, ssn) => {
        if (visibilityDimensions.length === 1) {
            dispatch.userAttachedDimensions.postUserAttachedDimensions(
                {
                    user: userId,
                    model: {
                        dimensions: visibilityDimensions.map((dim) => dim.id),
                    },
                }
            ).then((json) => {
                if (json.status === 'ok') {
                    dispatch.notifications.addSuccess(
                        _trans('Hoidettavan tiedot on jo tallennettu palveluun. Ole hyvä ja tarkasta tiedot.', {}, 'jobContract')
                    );
                    fetchUser(userId).then((user) => onSuccessUserUpdate(user));
                }
            });
        } else {
            setState({
                ssn,
                userId,
                showVisibilityForm: true,
            });
        }
    }, [dispatch.userAttachedDimensions, visibilityDimensions.length]);

    const onSuccessUserUpdate = useCallback((user) => {
        // Ei haeta partyjä uudestaan apista vaan lisätään vaan se listaan. (Ei tarvi lataa uudestaan 4000 käyttäjää)
        if (! isEditingUser) {
            dispatch.customers.addCustomer(user);
            customerHelpers.setValue(user.userId);
        } else {
            dispatch.customers.updateCustomer(user);
        }

        dispatch(push(`${resolveUrl(routes.NEW_CONTRACT, { benefitDecisionId })}?template=${templateId}`));
    }, [dispatch, customerHelpers, isEditingUser, templateId, benefitDecisionId]);

    const onUserUpdate = useCallback((user) => {
        // Jos valtuushallinta käytössä, niin tehdään taikoja
        if (visibilityDimensions.length > 0) {
            if (visibilityDimensions.length === 1) {
                dispatch.userAttachedDimensions.postUserAttachedDimensions(
                    {
                        user: user.userId,
                        model: {
                            dimensions: visibilityDimensions.map((dim) => dim.id),
                        },
                    }
                ).then((json) => {
                    if (json.status === 'ok') {
                        onSuccessUserUpdate(user);
                    }
                });
            } else {
                setState({
                    ssn: user.socialSecurityNumber,
                    userId: user.userId,
                    showVisibilityForm: true,
                    user,
                });

            }
        } else {
            // EI valtuushallintaan => suoraan valitaan henkillö
            onSuccessUserUpdate(user);
        }
    }, [onSuccessUserUpdate, setState, visibilityDimensions.length]);


    /*componentWillUnmount() {
        // Unmoountissa tyhjennetään intial tiedot, jotta jos muokataan vaikka hoidettavaa, niin ei näy vanhat tiedot
        this.props.dispatch(partiesActions.resetinitialUser());
    }*/
    if (isLoading) {
        return <MDSpinner wrapped />;
    }

    const partyId = resolvePartyId(true, isAssignmentContract, userRole);

    // Luodaan uusi henkilö esitäytetyllä hetulla
    const initialSsn = initialUser?.socialSecurityNumber ?? '';

    const heading = _trans(isEditingUser
        ? `contract.${partyId}.modify.heading`
        : `contract.${partyId}.new.heading`
    );

    const { showVisibilityForm, userId, ssn } = state;

    return (
        <Page
            isBodyClear
            heading={heading}
            title={heading}
            maxWidth="medium"
        >
            <ActionBar hasMargin>
                <GoBackLink to={contractUrl}/>
            </ActionBar>
            {showVisibilityForm && userId !== null
                ? (
                    <UserAttachedDimensionsForm
                        isRequired
                        userId={userId}
                        onUserAttachedDimensionsAdd={() => Object.values(state.user).length > 0
                            ? onSuccessUserUpdate(state.user)
                            : fetchUser(userId).then((user) => onSuccessUserUpdate(user))
                        }
                    >
                        {(dimSelector) => (
                            <Fragment>
                                <p>
                                    {Object.values(state.user).length > 0
                                        ? _trans('Valitse mihin %dimensionType%-ryhmiin hoidettava liitetään. Valitse vähintään yksi.', { dimensionType: visibilityDimensionTypeName.toLowerCase() }, 'jobContract')
                                        : _trans('Hoidettava löytyi jo Oima-palvelusta. Valitse mihin %dimensionType%-ryhmiin hoidettava liitetään. Valitse vähintään yksi.', { dimensionType: visibilityDimensionTypeName.toLowerCase() }, 'jobContract')
                                    }
                                </p>
                                <FormField isContentFormField label={_trans('Henkilötunnus', {}, 'common')}>
                                    <Value>{ssn}</Value>
                                </FormField>
                                {Object.values(state.user).length > 0 && (
                                    <FormField isContentFormField label={_trans('Nimi', {}, 'common')}>
                                        <Value>{state.user.fullName}</Value>
                                    </FormField>
                                )}
                                {dimSelector}
                            </Fragment>
                        )}
                    </UserAttachedDimensionsForm>
                ) : (
                    <UserForm
                        jobTitles={jobTitles}
                        // ;_; IMMUTABLE!
                        userContracts={fromJS([])}
                        initialUser={isEditingUser ? initialUser : null}
                        initialSsn={isEditingUser ? '' : initialSsn}
                        isEditMode={initialSsn.length > 0}
                        isCompany={isCompany}
                        isCommune={false}
                        isNewUser={! isEditingUser}
                        // Jos muokataan, niin nullita menee, muuten tutkitaan query parametristä
                        relationType={isEditingUser ? null : companyUserRelationTypes.RELATION_CARED}
                        isEmployee={false}
                        onUserUpdate={onUserUpdate}
                        onError={(error) => dispatch.notifications.addError(error)}
                        addNotification={(notification) => dispatch.notifications.addNotification(notification)}
                        isAssignmentContract={isAssignmentContract}
                        onUserNotVisibilityAccess={onUserNotVisibilityAccess}
                        isMonthlySalaryCustomerInvoicingEnabled={isMonthlySalaryCustomerInvoicingEnabled}
                        isVtjEnabled={isVtjEnabled}
                    />
                )}
        </Page>
    );
};

CustomerForm.propTypes = {
    name: PropTypes.string.isRequired,
    caredId: PropTypes.number.isRequired,
};
