export const contractStates = {
    /**
     * The contract is open and not sent to employee
     */
    OPEN: 1,

    /**
     * Accepted by both parties
     */
    ACCEPTED: 3,

    /**
     * Contract has been terminated (it's over)
     */
    TERMINATED: 6,

    /**
     * Purettu sopimus
     */
    DISSOLVED: 66,

    /**
     * Keskeneräinen
     */
    UNFINISHED: 98,

    /**
     * Toimii samalla tavalla kuin OPEN mutta työnantajan tekemä
     */
    EMPLOYER_PROPOSAL: 11,
};

const contractStateMap = {
    [contractStates.OPEN]: _trans('job_contract.state.open'),
    [contractStates.ACCEPTED]: _trans('job_contract.state.accepted'),
    [contractStates.TERMINATED]: _trans('job_contract.state.terminated'),
    [contractStates.DISSOLVED]: _trans('job_contract.state.dissolved'),
    [contractStates.UNFINISHED]: _trans('Keskeneräinen', {}, 'common'),
    [contractStates.EMPLOYER_PROPOSAL]: _trans('Työnantajan luonnos', {}, 'jobContract')
};

export const translateContractState = (contractState) => (
    contractStateMap[contractState] ?? _trans('job_contract.state.unknown')
);

const contractUiState = {
    [contractStates.OPEN]: 'neutral',
    [contractStates.ACCEPTED]: 'positive',
    [contractStates.TERMINATED]: 'negative',
    [contractStates.DISSOLVED]: 'hollow',
    [contractStates.EMPLOYER_PROPOSAL]: 'neutral',
};

export const mapContractStateToUi = (contractState) => contractUiState[contractState] ?? '';

