import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CirclePlaceholder from './CirclePlaceholder';
import BoxPlaceholder from './BoxPlaceholder';

/**
 * Taulukon (erityisesti DataTable-containerin) placeholder.
 * Ottaa vastaan myös columns-propsin jolla voi kertoa montako saraketta oletetussa taulukosa on.
 * Columns-props voi olla myös DataTable-containerin syömän columns-propsin kaltainen metadatalista
 * josta poimitaan tyyppi ja leveys joiden perusteella placeholderit piirretään.
 * @param props
 * @returns {*}
 * @constructor
 */
function TablePlaceholder(props) {
    const { rows, isFilterable, } = props;
    let columns;
    let columnMetaData;

    // Lasketaan montako filtteröimätöntä saraketta taulukossa on.
    // Vaikuttaa placeholderin headerin korkeuteen.
    let noFilterableColumnCount = 0;

    if (_.has(props, 'columns') && Array.isArray(props.columns)) {
        columns = props.columns.length;

        // Yritetään kaivella metadatasta oleellista tietoja placeholderin elementeille
        columnMetaData = props.columns.map((column) => {
            let width = _.get(column, 'width', 150);
            const show = _.get(column, 'show', true);
            let content = (
                <BoxPlaceholder width="100%" />
            );
            if (_.get(column, 'filterable', true) === false) {
                noFilterableColumnCount++;
            }

            // Tyypin mukaan voidaan olettaa tiettyjä asioita
            if (_.has(column, 'type')) {
                switch (column.type) {
                    case 'date':
                        width = 120;
                        break;

                    case 'avatar':
                        width = 72;
                        // Avatar-saraketta ei voi filtteröidä
                        noFilterableColumnCount++;
                        content = <CirclePlaceholder/>;
                        break;
                }
            }

            // Palautetaan se metadata mitä löydettiin
            return {
                width,
                content,
                show,
            };
        });
    } else {
        columns = props.columns;
        // Luodaan columns-kokoinen taulukko
        columnMetaData = _.times(columns, null);
    }

    // Renderöidään rivit ja sarakkeet
    const tableRows = [];
    for (let j = 0; j < rows; j++) {
        const tableCols = [];
        for (let i = 0; i < columns; i++) {
            const column = columnMetaData[i];
            const content = _.get(column, 'content', null);
            const width = _.get(column, 'width', false);
            const show = _.get(column, 'show', true);

            const style = {
                display: 'flex',
            };

            // Jos sarakkeelle on annettu leveys käytetään sitä
            if (width) {
                style.flex = `${width} 0 auto`;
                style.width = `${width}`;
                style.maxWidth = `${width}`;
            }

            if (show) {
                tableCols.push(
                    <div
                        className="o-placeholder-table__column"
                        style={style}
                        key={`placeholderColumn${i + 1}`}
                    >
                        { content ? content : <div className="o-placeholder__item" style={{ width: '100%' }} /> }
                    </div>
                );
            }
        }
        tableRows.push(
            <div className="o-placeholder-table__row" key={`placeholderRow${j + 1}`}>
                {tableCols}
            </div>
        );
    }

    // Taulukon headerin korkeus.
    // Jos yhdelläkään sarakkeella ei ole filtteriä on header matalampi.
    const hasFilters = noFilterableColumnCount < columns;
    const tableHeaderHeight = hasFilters ? 48 + 60 : 48;

    return (
        <div className="o-placeholder-table">
            { isFilterable && <BoxPlaceholder height={tableHeaderHeight} /> }
            {tableRows}
        </div>
    );
}

TablePlaceholder.defaultProps = {
    rows: 3,
    columns: 1,
    isFilterable: true,
};

TablePlaceholder.propTypes = {
    /**
     * Montako placeholder-tekstiriviä.
     */
    rows: PropTypes.number,

    /**
     * Montako saraketta. Voi olla lista numeroista tai DataTablen columns-propin kaltainen objekti.
     */
    columns: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.number,
        ]),
    )]),

    /**
     * Onko taulukko filtteröitävä.
     */
    isFilterable: PropTypes.bool,
};

export default TablePlaceholder;
