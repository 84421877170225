import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Feedback, Page, TodoList } from 'shared/components/index';

/**
 * Komponentti joka ottaa vastaan tietoja käyttäjän
 * omien tietojen tilasta (tunnistautuminen, omat tiedot, vakuutukset...) ja
 * tarjoaa linkit kyseisten tietojen täyttämiseen mikäli ne ovat puutteelliset.
 */
export default class UserStateOfAffairs extends Component {
    static propTypes = {
        // Lyhyt tekstikappale joka kertoo miksi nämä tiedot
        // vaaditaan siinä näkymässä missä tätä komponenttia käytetään.
        infoTitle: PropTypes.string.isRequired,

        // Onko omat tiedot täytetty?
        isUserDataComplete: PropTypes.bool,
        // Onko omia tietoja pakko täyttää (esim. jos ollaan jo Omat tiedot -näkymässä).
        isUserDataRequired: PropTypes.bool,

        // Onko tunnistautuminen tehty?
        isIdentificationComplete: PropTypes.bool,
        // Onko tunnistautuminen vanhentunut?
        isIdentifierExpired: PropTypes.bool,
        // Onko se pakollinen?
        isIdentificationRequired: PropTypes.bool,

        // Onko vakuutukset otettu?
        isInsurancesComplete: PropTypes.bool,
        // Onko vakuutusten ottaminen pakollista (esim. jos ollaan jo Vakuutukset -näkymässä)?
        isInsurancesRequired: PropTypes.bool,

        // Ollaanko yritys
        isCompany: PropTypes.bool,
    };

    static defaultProps = {
        isUserDataComplete: false,
        isUserDataRequired: true,

        isIdentificationComplete: false,
        isIdentifierExpired: false,
        isIdentificationRequired: true,

        isInsurancesComplete: false,
        isInsurancesRequired: true,

        isCompany: false,
    };

    render() {
        const {
            infoTitle,

            isUserDataComplete,

            isIdentificationComplete,
            isIdentifierExpired,

            isInsurancesComplete,
            isInsurancesRequired,

            isCompany,
        } = this.props;

        // Yritykselle eri route
        const userDataRoute = isCompany ? 'suoratyo_company_settings' : 'suoratyo_userdata';

        // Kerrotaan jos tunnistautuminen vanhentunut ja ohjataan tunnistautumaan
        if (isIdentifierExpired) {
            return (
                <Page>
                    <Feedback
                        type="warning"
                        title={_trans('home.note.identifier.expired')}
                    >
                        {_transMd('incomplete_steps.identification_expired')}
                    </Feedback>
                </Page>
            );
        }

        // Jotain puuttuu... kerrotaan listalla mitä.
        return (
            <Page>
                <Feedback type="info" title={infoTitle}>
                    <TodoList
                        modifierClass="u-margin-bottom-none"
                        list={[
                            {
                                task: isUserDataComplete
                                    ? _transMd('tasks.complete.user_data')
                                    : (
                                        <a href={Routing.generate(userDataRoute, { wizard: 1 })}>
                                            {_trans('tasks.incomplete.user_data')}
                                        </a>
                                    ),
                                done: isUserDataComplete,
                            },
                            {
                                task: _transMd(
                                    isIdentificationComplete
                                        ? 'tasks.complete.identification'
                                        : 'tasks.incomplete.identification'
                                    , false),
                                done: isIdentificationComplete,
                            },
                            {
                                task: _transMd(
                                    isInsurancesComplete
                                        ? 'tasks.complete.insurances'
                                        : 'tasks.incomplete.insurances'
                                    , false),
                                done: isInsurancesComplete,
                                visible: isInsurancesRequired,
                            },
                        ]}
                    />
                </Feedback>
            </Page>
        );
    }
}
