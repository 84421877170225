import React from 'react';
import PropTypes from 'prop-types';
import { Control, Field } from 'react-redux-form/lib/immutable';
import Dropdown from 'shared/components/Dropdown';
import { StErrors, StField, StLabel } from 'shared/components/StForm';

/**
 * Näyttää joko alasvetovalikon (jos yli 5 valintaa) tai listan radiobuttoneita.
 * Huom! immutable RRF
 * @param model
 * @param label
 * @param options
 * @param validators
 * @param onChange
 * @param isRequired
 * @param isDisabled
 * @returns {*}
 * @constructor
 */
const ChoiceSelectType = ({ model, label, options, validators, onChange, isRequired, isDisabled }) => {
    const useList = options.length <= 5;

    return (
        <div className="u-margin-bottom-small">
            <StLabel htmlFor={model}>
                {label}
            </StLabel>
            <StField>
                {useList
                    ? (
                        <Field
                            id={model}
                            model={model}
                            dynamic
                            validators={validators}
                            required={isRequired}
                        >
                            {options.map((option, key) => (
                                <div key={key}>
                                    <label>
                                        <input type="radio"
                                            disabled={isDisabled}
                                            onChange={onChange}
                                            value={_.get(option, 'value')}
                                        />
                                        {_.get(option, 'label', '-')}
                                    </label>
                                </div>
                            ))}
                        </Field>
                    )
                    : (
                        <Control.select
                            id={model}
                            onChange={onChange}
                            model={model}
                            options={options}
                            component={Dropdown}
                            optionValue="value"
                            optionLabel="label"
                            placeholder={_trans('dropdown.choose')}
                            validators={validators}
                            required={isRequired}
                            isDisabled={isDisabled}
                        />
                    )}

                <StErrors model={model}/>
            </StField>
        </div>
    );
};

ChoiceSelectType.propTypes = {
    model: PropTypes.string.isRequired,
    validators: PropTypes.object,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })).isRequired,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

ChoiceSelectType.defaultProps = {
    validators: {},
    onChange() {
    },
    isRequired: false,
    isDisabled: false,
};

export default hot(ChoiceSelectType);
