import React from 'react';
import PropTypes from 'prop-types';
import MDIcon from 'shared/components/MDIcon';

/**
 * Dialogin otsikko.
 * @param title
 * @param onCloseButtonClick
 * @returns {XML}
 * @constructor
 */
function DialogHeader({title, onCloseButtonClick}) {
    return (
        <header className="c-dialog__header u-padding-small u-padding-horizontal@medium">
            <div className="o-stack o-stack--justify">
                <h3 className="c-dialog__heading">{title}</h3>
                <MDIcon
                    icon="clear"
                    modifierClass="c-dialog__close-button u-margin-left-small"
                    focusable
                    onClick={onCloseButtonClick}
                />
            </div>
        </header>
    )
}

DialogHeader.propTypes = {
    title: PropTypes.string,
    onCloseButtonClick: PropTypes.func,
};

export default DialogHeader;
