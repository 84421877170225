import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';

import SvgIcon from 'shared/components/SvgIcon';
import svgIconTypes from 'shared/constants/svgIconTypes';

/**
 * Näytetään jos käyttäjä ei ole vielä luonut mitään tai ei ole mitään näytettävää.
 * Voi sisältää mahdollisesti linkin toimintoon.
 * @param message
 * @param subText
 * @param callToAction
 * @param icon
 * @returns {*}
 * @constructor
 */
function EmptyState({
    message,
    subText,
    callToAction,
    icon
}) {
    const CallToAction = () => <div className="u-margin-top-small u-1/1">{callToAction}</div>;

    return (
        <div className="o-stack o-stack--vertical o-stack--center u-text-center u-padding-small">
            <div className="u-opacity-10">
                <SvgIcon icon={icon} size="huge"/>
            </div>
            <div className="c-heading-subheading u-margin-top-small">
                {message}
                { subText !== '' && <br/> }
                { subText !== '' && <span className="u-muted">{subText}</span> }
            </div>
            { callToAction && <CallToAction /> }
        </div>
    );
}

EmptyState.propTypes = {
    message: PropTypes.node.isRequired,
    subText: PropTypes.string,
    callToAction: PropTypes.node,
    icon: PropTypes.oneOf(_map(svgIconTypes)),
};

EmptyState.defaultProps = {
    subText: '',
    callToAction: null,
    icon: 'inbox',
};

export default EmptyState;
