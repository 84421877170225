import _ from 'lodash';
import api from 'api';

/**
 * Hakee kirjautuneen käyttäjän metadatan ja attribuutit.
 */
export default {
    state: {
        email: '',
        fullName: '',
        photoSource: '',
        companyGroupName: '',
        metadata: {},
        attributes: {},
        isProcuristOrMasterUser: false,
        hasError: false,
    },

    reducers: {
        setLoggedUserMetadata: (state, payload = {}) => payload,
        setError: (state, hasError) => ({ ...state, hasError }),
    },

    selectors: {
        getUser: (state) => state,

        /**
         * Palauttaa joko käyttäjän koko nimen tai yritysryhmän nimen.
         * Yritysryhmän nimi overraidaa käyttäjän nimen. Toistaiseksi.
         * @param state
         * @returns {*}
         */
        getUserName: ({ fullName = '', companyGroupName = '' }) => (
            companyGroupName !== '' ? companyGroupName : fullName
        ),

        getPhotoSource: (state) => state.photoSource ?? '',
        getUserRole: (state) => state.metadata?.userRole,

        /**
         * Palauttaa käyttäjän nimikirjaimet. Joko oman tai yritysryhmän.
         * @param state
         * @returns {*}
         */
        getUserInitials: (state) => state.initials ?? '',
        /**
         * Palauttaa kirjautuneen käyttäjän emailin
         *
         * @param state
         * @returns {*}
         */
        getEmail: (state) => state.email ?? '-',

        /**
         * Onko kirjautunut käyttäjä prokuristi tai master user aktiiviseen yritykseen
         *
         * @param state
         * @returns {*}
         */
        isProcuristOrMasterUser: (state) => state.isProcuristOrMasterUser ?? false,
    },

    effects: () => ({
        async fetchUserData() {
            try {
                const response = await api.get('/api/v2/user/data');
                this.setLoggedUserMetadata(response.data ?? {});
            } catch (error) {
                console.log(error);
                this.setError(true);
            }
        },
    }),
};

export const loadingSelectors = {
    isLoadingUserData: (state) => _.get(state, 'loading.effects.loggedUser.fetchUserData', false),
};
