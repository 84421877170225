import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SvgIcon } from 'shared/components';

/**
 * Näyttää animoidun peukku-ylös -kuvakkeen ja lentelevät kipinät.
 * @param isUp
 * @returns {*}
 * @constructor
 */
function ThumbUp({ isUp }) {
    if (! isUp) return null;

    return (
        <div className="u-position-relative u-display-inline-block">
            <SvgIcon icon="thumb_up" size="huge" modifierClass="c-thumb-up" />
            <div className={classNames('c-sparks')}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
}

ThumbUp.propTypes = {
    isUp: PropTypes.bool,
};

ThumbUp.defaultProps = {
    isUp: false,
};

export default ThumbUp;
