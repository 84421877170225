import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { Placeholder } from 'shared/components';
import UserBlock from 'shared/components/UserBlock';
//import IconText from 'shared/components/IconText';

/**
 * Valittu työntekijä / työntekijät.
 * @returns {JSX.Element}
 * @constructor
 */
const Employee = ({ label, attributes: { isSsnVisible } }) => {
    const employees = useSelector(select.contract.getEmployees);
    const isCompany = useSelector(select.userMetadata.isCompany);
    const isCommune = useSelector(select.userMetadata.isCommune);
    //const isCommuneContract = useSelector(select.contract.isCommuneContract);
    const isLoading = useSelector((state) => state.loading.effects.contract.fetchContract);

    return (
        <SummaryListItem term={label}>
            <Placeholder isPending={isLoading}>
                {/*TODO: User/constants/routes.js refaktorointi => exporttaa vain routet ulos, ei komponentteja*/}
                {employees.map((employee, key) => {
                    const details = [
                        [employee.fullAddress],
                        [employee.phoneNumber],
                        [isSsnVisible ? employee.email : '']
                    ].filter((detail) => detail.toString().trim() !== '');

                    //const isCommuneTimeRegistrationEnabled = employee?.isCommuneTimeRegistrationEnabled;

                    return (
                        <div key={key} className="u-margin-bottom-small">
                            <div className="u-margin-bottom-tiny">
                                <UserBlock
                                    user={employee}
                                    size="small"
                                    relationType="employee"
                                    isSsnVisible={isSsnVisible}
                                    hasLinkToDetails={isCompany || isCommune}
                                />
                            </div>
                            {details.map((detail, key) => (
                                <Fragment key={key}>
                                    {detail}<br/>
                                </Fragment>
                            ))}
                            <span title={_trans('Tilinumero', {}, 'common')}>
                                {employee.accountNumber}
                            </span><br/>
                            {/*(isCommuneContract && isCommune) && (
                                <div className="u-margin-top-small">
                                    <IconText
                                        mdIcon={isCommuneTimeRegistrationEnabled ? 'check' : 'block'}
                                        text={isCommuneTimeRegistrationEnabled
                                            ? _trans('Tuntiensyöttö sallittu', {}, 'jobContract')
                                            : _trans('Tuntiensyöttöä ei sallittu', {}, 'jobContract')}
                                        iconModifierClass={isCommuneTimeRegistrationEnabled ? 'u-color-positive' : 'u-color-negative'}
                                        iconSize="small"
                                    />
                                </div>
                            )*/}
                        </div>
                    );
                })}
            </Placeholder>
        </SummaryListItem>
    );
};

Employee.propTypes = {
    label: PropTypes.string,
    attributes: PropTypes.shape({
        isSsnVisible: PropTypes.bool,
    }).isRequired,
};

Employee.defaultProps = {
    label: '',
};

export default Employee;
