import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import DataTable from 'shared/containers/DataTable';
import { NameAndDescription } from 'shared/Admin/Surcharges/components/NameAndDescription';
import { HandledSurchargeActionBar } from 'shared/Admin/Surcharges/containers/HandledSurchargeActionBar';
import { HandledSurchargeActions } from 'shared/Admin/Surcharges/containers/HandledSurchargeActions';
import offlinePaymentStates from 'shared/constants/offlinePaymentStates';
import { salesInvoiceState } from 'shared/constants/salesInvoice';

export const HandledSurchargeList = hot(() => {
    const internalList = useSelector(select.surcharges.getHandledInternalSurcharges);
    const externalList = useSelector(select.surcharges.getHandledExternalSurcharges);
    const isExternal = useSelector(select.surcharges.isExternalTypeSelected);
    const offlinePayment = useSelector(select.surcharges.getSelectedOfflinePayment);
    const salesInvoice = useSelector(select.surcharges.getSelectedSalesInvoice);
    const internalTotal = useSelector(select.surcharges.getHandledInternalTotal);
    const externalTotal = useSelector(select.surcharges.getHandledExternalTotal);

    const isLoading = useSelector((state) =>
        state.loading.effects.surcharges.fetchHandledInternalSurcharges
        || state.loading.effects.surcharges.fetchHandledExternalSurcharges
    );

    const isClosed = isExternal
        ? salesInvoice?.data?.state === salesInvoiceState.SENT
        : offlinePayment?.data?.state !== offlinePaymentStates.DRAFT;

    return (
        <Fragment>
            <HandledSurchargeActionBar />
            <div className="u-margin-top">
                <DataTable
                    isLoading={isLoading}
                    emptyDataMessage={isExternal
                        ? 'Ei vahvistettuja lisämaksuja laskutusjaksolla'
                        : 'Ei vahvistettuja lisämaksuja maksuerällä'
                    }
                    data={isExternal ? externalList : internalList}
                    defaultSorted={[{
                        id: 'created',
                        desc: true,
                    }]}
                    columns={[
                        {
                            Header: 'Lisämaksu',
                            id: 'nameAndDescription',
                            Cell: NameAndDescription,
                        },
                        {
                            Header: 'Vahvistettu',
                            accessor: 'edited',
                            type: 'date',
                        },
                        {
                            Header: _trans('unit.count'),
                            accessor: 'unitCount',
                            type: 'number',
                            alignText: 'right',
                            width: 100,
                        },
                        {
                            Header: _trans('unit.price'),
                            accessor: 'unitPrice',
                            type: 'currency',
                            alignText: 'right',
                            width: 150,
                        },
                        {
                            Header: _trans('text.total'),
                            id: 'total',
                            accessor: (props) => (props.unitCount * props.unitPrice),
                            // eslint-disable-next-line react/prop-types,react/display-name
                            Cell: ({ value }) => (
                                <div className={value < 0 ? 'u-color-negative' : 'u-color-default'}>
                                    {_currency(value)}
                                </div>
                            ),
                            alignText: 'right',
                            width: 150,
                        },
                    ]}
                    actionsColumn={<HandledSurchargeActions isClosed={isClosed} />}
                    actionsColumnFooter={(
                        <div className="u-text-right">
                            {`${_trans('text.total')} ${_currency(isExternal ? externalTotal : internalTotal)}`}
                        </div>
                    )}
                    actionsColumnWidth={180}
                />
            </div>
        </Fragment>
    );
});
