import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Value } from 'shared/components/Value';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';

/**
 * Valittu henkilöstöryhmä.
 * @returns {JSX.Element|null}
 * @constructor
 */
const PersonnelGroups = ({ value, label }) => {
    const personnelGroups = useSelector(select.personnelGroup.getGroups);
    const isLoading = useSelector((state) => state.loading.effects.personnelGroup.fetchGroups);

    // Jos lataa tai ei lataa mutta henkilöstöryhmiä on vain yksi => älä näytä mitään
    if (isLoading || (! isLoading && personnelGroups.length === 1)) return null;

    const personnelGroupId = parseInt(value, 10);
    const personnelGroup = personnelGroups.find((personnelGroup) => parseInt(personnelGroup.id, 10) === personnelGroupId);

    return (
        <SummaryListItem term={label}>
            <Value>
                {personnelGroup?.name}
            </Value>
        </SummaryListItem>
    );
};

PersonnelGroups.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default PersonnelGroups;
