import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';
import { StLabel, StField, StErrors } from 'shared/components/StForm';
import { validators } from 'shared/validators';

export default class PostCode extends PureComponent {
    render() {
        const {
            model,
            isRequired,
        } = this.props;

        const props = _.omit(this.props, 'isRequired');

        return (
            <div>
                <StLabel htmlFor="postCode" isRequired={isRequired}>
                    {_trans('userForm.postCode.label')}
                </StLabel>
                <StField>
                    <Control.text
                        {...props}
                        id="postCode"
                        model={model}
                        size={5}
                        maxLength={5}
                        validators={{
                            isRequired: (value) => isRequired ? validators.isRequired(value) : true,
                            isPostCode: (value) => isRequired || !_.isEmpty(value) ? validators.isPostCode(value) : true,
                        }}
                    />
                    <StErrors model={model} />
                </StField>
            </div>
        );
    }
}

PostCode.defaultProps = {
    model: '.postCode',
    isRequired: false,
};

PostCode.propTypes = {
    model: PropTypes.string,
    isRequired: PropTypes.bool,
};
