import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import SummaryList from 'shared/components/SummaryList/index';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import UserBlock from 'shared/components/UserBlock';

const Beneficiary = ({ label, attributes: { isSsnVisible } }) => {
    const isLoading = useSelector((state) => state.loading.effects.contract.fetchContract);
    const beneficiary = useSelector(select.contract.getBeneficiary);
    const isSelfEmployed = beneficiary?.isSelfEmployed ?? false;
    const canResolveBeneficiaryData = useSelector(select.contract.canResolveBeneficiaryData);

    if (isSelfEmployed) return null;

    return (
        <li className="o-summary-list__item">
            <fieldset>
                <legend>
                    {_trans('Avustettava', {}, 'jobContract')}
                </legend>
                <SummaryList>
                    <SummaryListItem term={label}>
                        <UserBlock
                            user={beneficiary}
                            isLoading={isLoading}
                            size="small"
                            isSsnVisible={isSsnVisible}
                            hasLinkToDetails={canResolveBeneficiaryData}
                            relationType="customer"
                        />
                    </SummaryListItem>
                </SummaryList>
            </fieldset>
        </li>
    );
};

Beneficiary.propTypes = {
    label: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
        isSsnVisible: PropTypes.bool,
    }).isRequired,
};

export default Beneficiary;
