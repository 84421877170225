import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { select } from '@rematch/select';
import { Formik } from 'formik';
import { ActionBar, MDSpinner, Page, SubmitButton } from 'shared/components';
import { CheckboxListField, Form, FormField } from 'shared/components/Formik';
import { routes as communeRoutes } from 'Commune/UserAccountsManager/routes';
import routes from 'Company/Settings/routes';
import alert from 'shared/utils/alert';
import { communeSettingsActions, communeSettingsSelectors } from 'Commune/Settings/stores/communeSettings';

export const UserAccessToDimensionRelations = () => {
    const dispatch = useDispatch();
    const { userId } = useParams();
    const isLoadingRelations = useSelector((state) => state.loading.effects.userAccessToDimensionRelations.fetchUserRelations);
    const isLoadingDimensions = useSelector((state) => state.loading.effects.userAccessToDimensionRelations.fetchUserRelations);
    const isLoadingUsers = useSelector((state) => console.log(state));
    const visibilityDimensions = useSelector(select.dimensions.getVisibilityDimensions);
    const visibilityDimensionTypeName = useSelector(select.dimensions.getVisibilityDimensionTypeName);
    const relations = useSelector(select.userAccessToDimensionRelations.getRelations);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isCompany = useSelector(select.userMetadata.isCompany);
    const users = useSelector((state) => {
        if (isCommune) {
            return communeSettingsSelectors.getCommuneOperators(state);
        } else if (isCompany){
            return select.companySettings.getCompanyUsers(state);
        } else {
            return [];
        }
    });
    const user = users.length > 0 ? (users.find((rel) => isCommune ? rel.userId === parseInt(userId, 10) : rel.userData.userId === parseInt(userId, 10))) : null;

    useEffect(() => {
        dispatch.dimensions.fetchDimensionTypes({ isVisibilityDimension: true, restrictDimensionVisibility: false });

    }, []);

    useEffect(() => {
        // Jos käyttäjää ei löydy HVA haetaan kerran käyttäjät (Kun luodaan uusi käyttäjä HVA käyttäjähallinnasta lista ei päivity)
        if (isCommune && !user){
            dispatch(communeSettingsActions.fetchOperators());
        }
    },[]);

    useEffect(() => {
        dispatch.userAccessToDimensionRelations.fetchUserRelations(userId);

    }, [userId]);

    if (isLoadingDimensions || isLoadingRelations || isLoadingUsers) {
        return (
            <MDSpinner wrapped />
        );
    }


    return (
        <Page
            heading={_trans('Valtuushallinta', {}, 'company_settings')}
            title={_trans('Valtuushallinta', {}, 'company_settings')}
            maxWidth="medium-1"
            goBack={ isCommune
                ? { to: communeRoutes.BASE }
                : { to: routes.COMPANY_USER_LIST, }}
        >
            <Formik
                initialValues={{
                    dimensions: relations.map((rel) => rel.dimension.id.toString()),
                }}
                onSubmit={(model) =>
                    dispatch.userAccessToDimensionRelations.postUserRelations(userId, model)
                        .then((json) => {
                            if (json.status === 'ok') {
                                dispatch.notifications.addSuccess(_trans('Valtuushallinta päivitetty', {}, 'company_settings'));
                            } else {
                                if (json.error === 'invalid_data') {
                                    const errors = Array.isArray(json.data) ? json.data : [json.data];
                                    // Näytetään virheet
                                    alert((
                                        <Fragment>
                                            <p>
                                                {_trans('Valtuushallintaa ei voitu päivittää seuraavista syistä', {}, 'company_settings')}
                                            </p>
                                            <ul className="u-margin-bottom-none">
                                                {errors.map(({ property_path, message }, key) => (
                                                    <li key={key}>
                                                        <b>{property_path}: </b>{message}
                                                    </li>
                                                ))}
                                            </ul>
                                        </Fragment>
                                    ), { alert: true });

                                } else {
                                    dispatch.notifications.addError(_trans('Päivittämisen aikana tapahtui tuntematon virhe', {}, 'common'));
                                }
                            }
                        })
                }
            >
                <Form>
                    <FormField isContentFormField label={_trans('Käyttäjä', {}, 'common')}>
                        <strong>
                            {isCommune ? user?.fullName ?? user?.email ?? '' : user?.userData.fullName ?? user?.userData.email ?? ''}
                        </strong>
                    </FormField>
                    <FormField name="dimensions" label={visibilityDimensionTypeName}>
                        <CheckboxListField
                            name="dimensions"
                            options={visibilityDimensions.map((dim) => ({ ...dim, label: `${dim.value} - ${dim.description ?? ''}`, id: dim.id.toString() }))}
                            valueKey="id"
                            labelKey="label"
                        />
                    </FormField>
                    <ActionBar>
                        <SubmitButton>
                            {_trans('button.save')}
                        </SubmitButton>
                    </ActionBar>
                </Form>
            </Formik>
        </Page>
    );
};
