import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DataTable from 'shared/containers/DataTable';
import { translateContractState } from 'shared/constants/contractStates';
import { Feedback } from 'shared/components';
import { alertTypes } from 'shared/constants/index';

export default class UpdateUserContractData extends Component {

    static propTypes  = {
        contracts: PropTypes.object.isRequired,
        onContractSelect: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div>
                <Feedback modifierClass="u-margin-bottom-small" type={alertTypes.INFO}>
                    Valitse sopimukset joille käyttäjäntiedot päivitetään
                </Feedback>
                <DataTable
                    onSelect={(contractIds) => this.props.onContractSelect(contractIds)}
                    isSelectable
                    keyField="jobContractId"
                    data={this.props.contracts.toJS()}
                    columns={[
                        {
                            Header: _trans('role.employer'),
                            accessor: 'contractData.employerName'
                        },
                        {
                            Header: _trans('role.employee'),
                            accessor: 'contractData.employeeName',
                        },
                        {
                            Header: _trans('wages_certificates_report.duration'),
                            Cell: (props) => ContractDurationCell(props),
                        },
                        {
                            Header: _trans('wages_certificates_report.state'),
                            Cell: (props) => translateContractState(_.get(props.original, 'contract.state'))
                        }
                    ]}

                />
            </div>
        );
    }
}

const ContractDurationCell = (props) => {
    const startDate = _.get(props.original, 'contract.startDate');
    if (_.has(props.original, 'contract.terminationTime')) {
        return <span>{`${_toLocaleDate(startDate)} - ${_toLocaleDate(props.original.contract.terminationTime)}`}</span>;
    }
    if (_.get(props.original, 'contract.endDate', false)) {
        return <span>{`${_toLocaleDate(startDate)} - ${_toLocaleDate(props.original.contract.endDate)}`}</span>;
    }
    return <span>{`${_toLocaleDate(startDate)} - ${_trans('wages_certificates_report.job_contract_so_far')}`}</span>;
};

ContractDurationCell.propTypes = {
    original: PropTypes.object,
};