import { allowedUserRoles } from '../constants';
import api from 'api';
import { getBreakPointsByUserRole } from 'shared/containers/PageHeader/constants';

/**
 * Lukitsee html-tagin jotta mobiilissa ei scrollata koko näkymää jos
 * valikko on auki.
 * @param isLocked
 */
const setHtmlLock = (isLocked) => {
    const htmlEl = document.getElementsByTagName('html')[0];
    if (isLocked) {
        htmlEl.classList.add('is-menu-open');
    } else {
        htmlEl.classList.remove('is-menu-open');
    }
};

/**
 * Yläpalkin itemit.
 */
export const header = {
    state: {
        isAdmin: false,
        isPreviousAdmin: false,
        isCommuneAdmin: false,
        currentRoute: '',
        navigationItems: [],
        isMainMenuOpen: false,
        userMenu: {},
        isUserMenuOpen: false,
        breakPoints: {},
        subViewIndex: -1,

        /**
         * Oletusotsikko. Tämä voi muuttua alinäkymissä.
         */
        baseHeading: '',

        isRoleSelectorVisible: false,
    },

    reducers: {
        setCurrentRoute: (state, currentRoute) => ({ ...state, currentRoute }),
        setNavigationItems: (state, navigationItems) => ({ ...state, navigationItems }),
        setMainMenuOpen: (state, isMainMenuOpen) => ({ ...state, isMainMenuOpen }),
        setUserMenu: (state, userMenu) => ({ ...state, userMenu }),
        setIsAdmin: (state, isAdmin) => ({ ...state, isAdmin }),
        setIsPreviousAdmin: (state, isPreviousAdmin) => ({ ...state, isPreviousAdmin }),
        setIsCommuneAdmin: (state, isCommuneAdmin) => ({ ...state, isCommuneAdmin }),
        setUserMenuOpen: (state, isUserMenuOpen) => ({ ...state, isUserMenuOpen }),
        setBreakPoints: (state, breakPoints) => ({ ...state, breakPoints }),
        setSubViewIndex: (state, subViewIndex) => ({ ...state, subViewIndex }),
        setBaseHeading: (state, baseHeading) => ({ ...state, baseHeading }),

        // Avaa roolinvalintamodaalin.
        setIsRoleSelectorVisible: (state, isRoleSelectorVisible) => ({ ...state, isRoleSelectorVisible }),
    },

    selectors: {
        getCurrentRoute: (state) => state.currentRoute,
        getNavigationItems: (state) => state.navigationItems,
        isMainMenuOpen: (state) => state.isMainMenuOpen,
        getUserMenu: (state) => state.userMenu,
        getUserId: (state) => state.userMenu.userId,
        getUserRole: (state) => state.userMenu.userRole,
        getUserMenuItems: (state) => state.userMenu.items ?? [],
        isUserMenuOpen: (state) => state.isUserMenuOpen,
        isAdmin: (state) => state.isAdmin,
        isPreviousAdmin: (state) => state.isPreviousAdmin,
        isCommuneAdmin: (state) => state.isCommuneAdmin,

        /**
         * Palauttaa joko koko nimen, etunimen tai sähköpostin.
         * @param state
         * @returns {string|*}
         */
        getUserName: (state) => {
            const email = state.userMenu.email;
            const firstName = state.userMenu.firstName;
            const lastName = state.userMenu.lastName;

            if (firstName && lastName) {
                return `${firstName} ${lastName}`;
            } else if (firstName) {
                return firstName;
            }

            return email;
        },
        /**
         * Onko valittu rooli sallittujen joukossa.
         * @param state
         * @returns {boolean}
         */
        hasSelectedRole: (state) => allowedUserRoles.includes(state.userMenu.userRole),
        getBreakPoints: (state) => state.breakPoints,
        isNotificationVisible: (state) => state.userMenu.isNotificationVisible,
        isOverlayVisible: (state) => state.isMainMenuOpen || state.isUserMenuOpen,
        getSubViewIndex: (state) => state.subViewIndex,
        getBaseHeading: (state) => state.baseHeading,
        isRoleSelectorVisible: (state) => state.isRoleSelectorVisible,
    },

    effects: () => ({
        /**
         * Hakee ilmoitukset.
         */
        async fetchHeaderItems() {
            try {
                const response = await api.get('/api/v2/navigation');

                if (response?.status === 'ok' && response.data) {
                    // APIn kautta disabloidut pois
                    const navigationItems = (response.data.navigationItems ?? []).map((item) => ({
                        ...item,
                        label: _trans(item.label),
                    })).filter((item) => !item.isDisabled ?? false);

                    this.setIsRoleSelectorVisible(false);
                    this.setCurrentRoute(response.data.currentRoute);
                    this.setNavigationItems(navigationItems);

                    const userRole = response.data.userMenu?.userRole ?? -1;
                    this.setBreakPoints(getBreakPointsByUserRole(userRole));
                    this.setUserMenu(response.data.userMenu ?? {});
                    this.setIsAdmin(response.data.userMenu?.isAdmin ?? false);
                    this.setIsPreviousAdmin(response.data.userMenu?.isPreviousAdmin ?? false);
                    this.setIsCommuneAdmin(response.data.userMenu?.isCommuneAdmin ?? false);

                    // Jos mitään pääroolia ei ole valittuna tarkoittaa,
                    // että oletettavasti ollaan juuri kirjauduttu sisään joten
                    // avataan roolinvalintanäkymä.
                    if (! allowedUserRoles.includes(userRole) && response.data?.currentRoute !== '/home/authenticate') {
                        this.setIsRoleSelectorVisible(true);
                    }

                    return {
                        isNotificationVisible: response.data.userMenu?.isNotificationVisible,
                        userRole,
                    };
                }
                return false;
            } catch (error) {
                console.log(error);
                // this.setError(true);
            }
        },

        /**
         * Onko päävalikko auki. Käytännössä vain "mobiili".
         * @param payload
         * @param rootState
         */
        toggleMainMenuOpen(payload, rootState) {
            const isMainMenuOpen = ! rootState.header.isMainMenuOpen;
            this.setMainMenuOpen(isMainMenuOpen);
            setHtmlLock(isMainMenuOpen);
            this.setUserMenuOpen(false);
        },

        /**
         * Onko käyttäjävalikko auki. Käytännössä vain "mobiili".
         * @param payload
         * @param rootState
         */
        toggleUserMenu(payload, rootState) {
            const isUserMenuOpen = rootState.header.isUserMenuOpen;
            this.setUserMenuOpen(! isUserMenuOpen);
            setHtmlLock(! isUserMenuOpen);
            this.setMainMenuOpen(false);
            if (! isUserMenuOpen) {
                this.setSubViewIndex(-1);
            }
        },

        closeAllMenus() {
            this.setMainMenuOpen(false);
            this.setUserMenuOpen(false);
            this.setSubViewIndex(-1);
            setHtmlLock(false);
        },
    })
};
