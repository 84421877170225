const payrollStates = {
    /**
     * Palkkalaskelma on luotu
     */
    PRINTED: 1,

    /**
     * Työantaja on maksanut palkkalaskelman
     */
    EMPLOYER_PAID: 2,

    /**
     * Palkka on maksettu työntekijälle
     */
    PAID: 3,

    /**
     * Verot maksettu
     */
    TAXED: 4,

    /**
     * TODO: Ei käytössä? 8.12.2015
     */
    COMPLETED: 5,

    /**
     * Palkkalaskelmasta on luotu SEPA-maksu
     */
    CREDIT_TRANSFER: 6,

    /**
     * Palkkalaskelman SEPA-maksuksi luonti epäonnistunut
     */
    CREDIT_TRANSFER_ERROR: 7,

    /**
     * TODO: Ei käytössä? 8.12.2015
     */
    CUSTOMER_ACCOUNT_ERROR: 8,

    /**
     * TODO: Ei käytössä? 8.12.2015
     */
    PENDING_PAYMENT: 10,

    /**
     * Palkkalaskelma on hyväksytty maksuun
     */
    COMMUNE_APPROVED: 11,

    /**
     * Päättyväksi hyväksytty palkkalaskelma TYHJILLÄ TUNNEILLA on hyväksytty maksuun
     */
    COMMUNE_APPROVED_ENDING: 32,

    /**
     * Kunta on ilmoittanut maksaneensa laskun joka kohdistuu tähän palkkalaskelmaan
     */
    COMMUNE_PAID: 12,

    /**
     * Drafti Käytössä lähinnä PaymentCorrectorin kanssa
     */
    DRAFT: 21,

    /**
     * Päättyväksi hyväksytty palkkalaskelma
     */
    CONFIRM_ENDING: 30,

    /**
     * Päättyneeksi maksettu palkkalaskelma
     */
    CONFIRMED_ENDING: 31,

    /**
     * Kunnan palkkalaskelma josta perittiin takaisin.
     */
    COMMUNE_BY_REFUNDED: 91,

    /**
     * Luotu palautettava palkkalaskelma
     */
    COMMUNE_REFUND_PRINTED: 92,

    /**
     *
     * Virhe palautettavan palkkalaskelman luonnissa
     */
    COMMUNE_REFUND_PRINTED_ERROR: 93,

    /**
     *
     * Palkkalaskelma palautettu
     */
    COMMUNE_REFUND_PAID: 94,

    /**
     *
     * Virhe palkkalaskelman palautuksessa
     */
    COMMUNE_REFUND_PAID_ERROR: 95,

    COMMUNE_REFUND_INVOICE_SEND: 96,

    /**
     * Korjaava payroll luotu.
     */
    CORRECTION_PAYROLL_PENDING: 100,
};

export default payrollStates;
