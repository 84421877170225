import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * @deprecated Käytä Formik:ia ja meidän sharedista Form ja FormFieldiä.
 */
function StField({ children, modifierClass = '', isLabeless, ariaLabelledby }) {
    const stFieldClass = classNames('o-form__field', {
        'o-form__field--labeless': isLabeless,
        [modifierClass]: modifierClass !== '',
    });

    return (
        <div className={stFieldClass} aria-labelledby={ariaLabelledby}>
            {children}
        </div>
    );
}

StField.propTypes = {
    isLabeless: PropTypes.bool,
    children: PropTypes.node.isRequired,
    modifierClass: PropTypes.string,
    /**
     * ID joka kertoo mikä tämä kentän sisältö on.
     */
    ariaLabelledby: PropTypes.string,
};

StField.defaultProps = {
    isLabeless: false,
    modifierClass: '',
    ariaLabelledby: null,
};

export default StField;
