/**
 * Lomapäiviä maksetaan etukäteen ulos lomapalkkana, lomarahat päälle jos kertynyt & jäljellä
 */
export const SUB_TYPE_IN_ADVANCE_PAYOUT_HOLIDAY_PAY_AND_BONUS = 1;

/**
 * Lomapäiviä maksetaan etukäteen ulos lomapalkkana, lomarahapäiviä ei makseta eikä syödä
 */
export const SUB_TYPE_IN_ADVANCE_PAYOUT_HOLIDAY_PAY_ONLY = 3;

/**
 * Lomapäiviä maksetaan ulos lomakorvauksena, lomarahaa ei makseta eikä lomarahapäiviä syödä pois
 */
export const SUB_TYPE_COMPENSATION_PAYOUT = 2;

export const subTypes = [
    {
        value: SUB_TYPE_IN_ADVANCE_PAYOUT_HOLIDAY_PAY_AND_BONUS,
        content: _trans('time_registration.additional_data.holiday_consumption.form_name_sub_type_in_advance_payout_holiday_pay_and_bonus'),
    },
    {
        value: SUB_TYPE_IN_ADVANCE_PAYOUT_HOLIDAY_PAY_ONLY,
        content: _trans('time_registration.additional_data.holiday_consumption.form_name_sub_type_in_advance_payout_holiday_pay_only'),
    },
    {
        value: SUB_TYPE_COMPENSATION_PAYOUT,
        content: _trans('time_registration.additional_data.holiday_consumption.form_name_sub_type_compensation_payout'),
    },
];
