import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Control } from 'react-redux-form/lib/immutable';
import { Button, MDSpinner, Page, ActionBar, GoBackLink, Feedback } from 'shared/components';
import { dimensionReportSelectors, dimensionReportActions } from 'shared/DimensionReport/stores/dimensionReport';
import resolveUrl from 'shared/utils/resolveUrl';
import routes from 'Payments/constants/routes';
import DimensionSelector from 'shared/containers/Dimension/DimensionSelector';
import DateRangeSelector from 'shared/DimensionReport/components/DateRangeSelector';
import { StLabel } from 'shared/components/StForm';
import _transMd from 'shared/utils/_transMd';

class BookkeepReport extends Component {
    static propTypes = {
        isFetchingDimensions: PropTypes.bool,
        selectedDimensions: PropTypes.object,
        dimensions: PropTypes.object,
        dispatch: PropTypes.func,
        paymentId: PropTypes.number,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        isCompanyManager: PropTypes.bool,
        isExploded: PropTypes.bool,
        isWithTotals: PropTypes.bool,
        title: PropTypes.string,
        baseRoute: PropTypes.string,
        isWithWaitingForPayment: PropTypes.bool,
    };

    static defaultProps = {
        isFetchingDimensions: false,
        selectedDimensions: {},
        dimensions: {},
        dispatch() {},
        paymentId: null,
        startDate: '',
        endDate: '',
        isCompanyManager: false,
        isExploded: false,
        isWithTotals: false,
        title: '',
        baseRoute: '',
        isWithWaitingForPayment: false,
    };

    constructor(props) {
        super(props);
        // Haetaan dimensionit ja resetoidaan varmuuden vuoksi valitut, ettei tule duplikaatteja valittuihin
        props.dispatch(dimensionReportActions.fetchDimensions(props.isCompanyManager));
        props.dispatch(dimensionReportActions.resetSelectedDimensions());
    }

    generateBookkeepReportLink(format = 'pdf') {
        const {
            paymentId,
            startDate,
            endDate,
            isCompanyManager,
            isExploded,
            isWithTotals,
            isWithWaitingForPayment,
        } = this.props;

        const selectedDimensions = this.props.selectedDimensions.toJS();
        let query = '';
        if (selectedDimensions.length > 0) {
            query = Object.keys(selectedDimensions)
                .map((key) => 'dimension[]=' + _.get(selectedDimensions[key], 'name'))
                .join('&');
        }

        function ensureAmpersand() {
            if (query.length > 0) {
                query = query + '&';
            }
        }

        if (isExploded) {
            ensureAmpersand();
            query = query + 'explode=1';
        }
        if (isWithTotals) {
            ensureAmpersand();
            query = query + 'totals=1';
        }
        if (isWithWaitingForPayment) {
            ensureAmpersand();
            query = query + 'includeWaitingForPayment=1';
        }
        if (format === 'csv') {
            ensureAmpersand();
            query = query + 'format=csv';
        }

        // Ollaan maksut sivulla
        if (paymentId) {
            // Ei osannut symfonyn routing generate lisätä hakasulkuja queryihin
            return `${Routing.generate('book_keep_report', { payment: paymentId })}?${query}`;
        }
        // Raportit sivulla, aikaväliltä rapsa, companyManager kertoo ollaanko yritysryhmän hallinnassa
        return `${Routing.generate('universal_bookkeeping_report', { startDate, endDate })}&${
            isCompanyManager ? 'group=1&' : ''
        }${query}`;
    }

    renderDateRangeSelector() {
        const { paymentId } = this.props;
        if (paymentId) {
            return null;
        }
        return <DateRangeSelector />;
    }

    renderTopActionBar() {
        const { paymentId, isCompanyManager } = this.props;
        // Renderöidään linkki vain jos ollaan maksaminen-sivulla tai yritysryhmän hallinnassa
        // (Jotta <Link> toimii, niin pitää löyty contextista routeri)
        if (paymentId || isCompanyManager) {
            const route = resolveUrl(routes.DETAILS, { paymentId });
            return (
                <ActionBar hasMargin>
                    <GoBackLink to={route} />
                </ActionBar>
            );
        }
    }

    renderForm() {
        const { isFetchingDimensions, selectedDimensions, dimensions, dispatch, paymentId } = this.props;

        if (isFetchingDimensions) {
            return <MDSpinner wrapped />;
        }

        const isPaymentsPage = !! paymentId;
        const paymentPageWarning = _trans('Huom. Yksittäisen maksun kirjanpitoerittely ei välttämättä sisällä kaikkia korjaavia tapahtumia. Suosittelemme ottamaan erittelyn [Raportit-näkymästä](/company/reports/bookkeep).', {}, 'extract');
        // Formi vain, että tyylit toimivat.
        return (
            <Form className="o-form o-form--vertical" model="dimensionReport">
                {/* Jos ollaan maksaminen-sivulla, niin mainitaan, että erittely ei välttämättä sisällä kaikkea tietoa */}
                {isPaymentsPage && (
                    <Feedback
                        modifierClass="u-margin-bottom-small"
                        type="warning"
                    >
                        {_transMd(paymentPageWarning)}
                    </Feedback>
                )}
                <DimensionSelector
                    selectedDimensions={selectedDimensions.toJS()}
                    dimensions={dimensions.toJS()}
                    onSelectDimension={(selectedDimension) =>
                        dispatch(dimensionReportActions.onSelectDimension(selectedDimension))
                    }
                    removeSelectedDimension={(index) =>
                        dispatch(dimensionReportActions.onRemoveSelectedDimension(index))
                    }
                />
                {this.renderDateRangeSelector()}
                <StLabel>
                    <Control.checkbox model=".exploded" />
                    {_trans('payments.details.bookkeep.exploded')}
                </StLabel>
                <StLabel>
                    <Control.checkbox model=".withTotals" />
                    {_trans('payments.details.bookkeep.with_totals')}
                </StLabel>
                <StLabel>
                    <Control.checkbox model=".withWaitingForPayment" />
                    {_trans('payments.details.bookkeep.with_waiting_for_payment')}
                </StLabel>
                <Button primary href={this.generateBookkeepReportLink('pdf')} target="_blank">
                    {_trans('payments.details.bookkeep.create_report_pdf')}
                </Button>
                <Button
                    href={this.generateBookkeepReportLink('csv')}
                    target="_blank"
                    modifierClass="u-margin-left-tiny"
                >
                    {_trans('payments.details.bookkeep.create_report_csv')}
                </Button>
            </Form>
        );
    }

    render() {
        const { title, baseRoute } = this.props;

        return (
            <Page maxWidth="medium" title={title} goBack={{ to: baseRoute !== '' ? baseRoute : null }}>
                {this.renderTopActionBar()}
                <h2 className="c-heading-headline">{_trans('payments.details.bookkeep.title')}</h2>
                {this.renderForm()}
            </Page>
        );
    }
}

const mapStateToProps = (state, props) => ({
    isFetchingDimensions: dimensionReportSelectors.isFetchingDimensions(state),
    dimensions: dimensionReportSelectors.getDimensions(state),
    selectedDimensions: dimensionReportSelectors.getSelectedDimensions(state),
    paymentId: parseInt(_.get(props.match, 'params.paymentId', null)),
    startDate: dimensionReportSelectors.getStartDate(state),
    endDate: dimensionReportSelectors.getEndDate(state),
    isExploded: dimensionReportSelectors.getIsExploded(state),
    isWithTotals: dimensionReportSelectors.getIsWithTotals(state),
    isWithWaitingForPayment: dimensionReportSelectors.getIsWithWaitingForPayment(state),
});

export default connect(mapStateToProps)(BookkeepReport);
