import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { componentSizes } from 'shared/constants';

function BoxPlaceholder({ color, size, width, height, autoSize }) {
    let style = {};

    if (color !== null) {
        style.color = color;
    }

    if (width !== null && !autoSize) {
        style.width = width;
    }

    if (height !== null && !autoSize) {
        style.height = height;
    }

    return (
        <div
            className={classNames('o-placeholder o-placeholder--box', {
                'o-placeholder--tiny': size === componentSizes.TINY && !_.has(style, 'height'),
                'o-placeholder--small': size === componentSizes.SMALL && !_.has(style, 'height'),
                'o-placeholder--large': size === componentSizes.LARGE && !_.has(style, 'height'),
                'u-display-inline-block': _.has(style, 'width'),
                'o-pin o-pin--corners': autoSize,
            })}
            style={style}
        >
            <div className="o-placeholder__item" />
        </div>
    );
}

BoxPlaceholder.defaultProps = {
    color: null,
    size: componentSizes.SMALL,
    width: null,
    height: null,
    autoSize: false,
};

BoxPlaceholder.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOf([..._.map(componentSizes), '']),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    autoSize: PropTypes.bool,
};

export default BoxPlaceholder;
