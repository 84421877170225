export const ANY_VALUE = '@__ANY__@';
export const NUMBER_VALUE = '@__NUMBER__@';
export const DATE_VALUE = '@__DATE__@';
export const ZERO_VALUE = '@__ZERO__@';
export const ARRAY_VALUE = '@__ARRAY__@';

export const magicValueMap = {
    [ANY_VALUE]: 'on mikä tahansa arvo',
    [NUMBER_VALUE]: 'on numero',
    [DATE_VALUE]: 'on päivämäärä',
    [ZERO_VALUE]: 'on numero nolla',
    [ARRAY_VALUE]: 'on taulukko',
};

export const translateMagicValue = (magicValue) => magicValueMap[magicValue] ?? 'Tuntematon taika-arvo';

