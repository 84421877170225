/* eslint-disable */
import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import BemMixin from '../utils/BemMixin';
import CustomPropTypes from '../utils/CustomPropTypes';


class CalendarDate extends BemMixin {
    static propTypes = {
        date: CustomPropTypes.moment,

        firstOfMonth: PropTypes.object.isRequired,

        isSelectedDate: PropTypes.bool,
        isSelectedRangeStart: PropTypes.bool,
        isSelectedRangeEnd: PropTypes.bool,
        isInSelectedRange: PropTypes.bool,

        isHighlightedDate: PropTypes.bool,
        isHighlightedRangeStart: PropTypes.bool,
        isHighlightedRangeEnd: PropTypes.bool,
        isInHighlightedRange: PropTypes.bool,

        highlightedDate: PropTypes.object,
        dateStates: PropTypes.instanceOf(Immutable.List),
        isDisabled: PropTypes.bool,
        isToday: PropTypes.bool,

        dateRangesForDate: PropTypes.func,
        onHighlightDate: PropTypes.func,
        onUnHighlightDate: PropTypes.func,
        onSelectDate: PropTypes.func,
    };

    state = {
        isMouseDown: false,
        lastIndex: -1,
    };

    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    componentWillUnmount() {
        this.isUnmounted = true;
        document.removeEventListener('mouseup', this.onMouseUp);
        document.removeEventListener('touchend', this.onTouchEnd);
    }

    onMouseUp = (event) => {
        this.props.onSelectDate(this.props.date);

        if (this.isUnmounted) {
            return;
        }

        if (this.state.isMouseDown) {
            this.setState({
                isMouseDown: false,
            });
        }

        document.removeEventListener('mouseup', this.onMouseUp);
    };

    onMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            isMouseDown: true,
        });

        document.addEventListener('mouseup', this.onMouseUp);
    };

    onTouchEnd = () => {
        if (this.isUnmounted) {
            return;
        }

        if (this.state.isMouseDown) {
            this.setState({
                isMouseDown: false,
            });
        } else {
            this.props.onHighlightDate(this.props.date);
            this.props.onSelectDate(this.props.date);
        }
        document.removeEventListener('touchend', this.onTouchEnd);
    };

    onTouchStart = (event) => {
        this.setState({
            isMouseDown: this.props.index !== this.state.lastIndex,
            lastIndex: this.props.index,
        });
        document.addEventListener('touchend', this.onTouchEnd);
    };

    onMouseEnter = (event) => {
        event.stopPropagation();
        this.props.onHighlightDate(this.props.date);
    };

    onMouseLeave = (event) => {
        event.stopPropagation();
        if (this.state.isMouseDown) {
            this.props.onSelectDate(this.props.date);

            this.setState({
                isMouseDown: false,
            });
        }
        this.props.onUnHighlightDate(this.props.date);
    };

    getBemModifiers = () => {
        const {
            date,
            firstOfMonth,
            isToday: today,
        } = this.props;

        let otherMonth = false;
        let weekend = false;

        if (date.month() !== firstOfMonth.month()) {
            otherMonth = true;
        }

        if (date.day() === 0 || date.day() === 6) {
            weekend = true;
        }

        return {today, weekend, otherMonth};
    };

    getBemStates = () => {
        const {
            date,
            selectionType,
            isSelectedDate,
            isInSelectedRange,
            isInHighlightedRange,
            isHighlightedDate: highlighted,
            isDisabled: disabled,
            isSelectedRangeStart,
            isHighlightedRangeStart,
            isSelectedRangeEnd,
            isHighlightedRangeEnd,
            dateRangesForDate,
        } = this.props;

        // const color;
        const states = dateRangesForDate(date);
        /*const numStates = states.count();
        let style = {};*/

        // Jos vain yksi state, tarkoittaa ettei olla kahden päivän rajalla
        let unavailable = ! states.getIn([0, 'selectable']);

        // Varaus tarkemmalle säädölle
        /*if (color) {
            style = {
                backgroundColor: color,
            };
        }*/

        const selected = isSelectedDate || isInSelectedRange || isInHighlightedRange;
        const selectedRangeStart = isSelectedRangeStart || isHighlightedRangeStart || (selected && selectionType === 'single');
        const selectedRangeEnd = isSelectedRangeEnd || isHighlightedRangeEnd || (selected && selectionType === 'single');

        return {disabled, unavailable, highlighted, selected, selectedRangeStart, selectedRangeEnd};
    };

    render() {
        const {
            date,
            dateRangesForDate,
        } = this.props;

        const bemModifiers = this.getBemModifiers();
        const bemStates = this.getBemStates();

        const states = dateRangesForDate(date);
        const unavailable = ! states.getIn([0, 'selectable']);

        return (
            <td className={this.cx({ element: 'DateContainer' })}>
                <button
                    type="button"
                    className={this.cx({ element: 'Date', modifiers: bemModifiers, states: bemStates })}
                    onTouchStart={this.onTouchStart}
                    onTouchEnd={this.onTouchEnd}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    onMouseDown={this.onMouseDown}
                    aria-label={_trans('date_range.calendar.select_day')}
                    disabled={unavailable}
                >
                    {date.format('D')}
                </button>
            </td>
        );
    }
}

export default CalendarDate;
