const terminationReasons = {
    TERMINATION_REASON_OF_LEGAL_AGE: 10, // Alaikäinen avustettava täysi-ikäinen
    TERMINATION_REASON_DECEASED: 20, // Avustettava merkitty menehtyneeksi
    TERMINATION_REASON_DECEASED_COMPLETE: 21, // Menehtymisen prosessointi valmis
    TERMINATION_REASON_MOVING: 30, // Avustettava merkitty muuttavaksi
    TERMINATION_REASON_TERMINATION: 40, // Avustuksen päättyminen. Esim. ei hlö. kohtaisen avun tarvetta
    TERMINATION_REASON_TERMINATION_COMPLETE: 41, // Avustuksen päättyminen valmis
};

export default terminationReasons;
