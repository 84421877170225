import React from 'react';
import PropTypes from 'prop-types';
import { Field } from './Field';

/**
 * List of checkboxes.
 * @param children
 * @param name
 * @param label
 * @returns {JSX.Element}
 * @constructor
 */
export const CheckboxField = ({ name, label, modifierClass, ...rest }) => (
    <label className="u-margin-bottom-none u-line-height-default">
        <Field type="checkbox" name={name} id={name} {...rest} />
        <span className={modifierClass}>{label}</span>
    </label>
);

CheckboxField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    modifierClass: PropTypes.string,
};

CheckboxField.defaultProps = {
    modifierClass: '',
};
