import React from 'react';
import PropTypes from 'prop-types';
import { Field } from './Field';

/**
 * Normal text field.
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const TextField = ({ children, ...rest }) => (
    <Field type="text" {...rest}>
        {children}
    </Field>
);

TextField.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

TextField.defaultProps = {
    children: undefined,
};
