import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { MDIcon } from 'shared/components';

/**
 * Renderöi sivupalkin elementin.
 *
 * @param isSelected
 * @param isDisabled
 * @param icon
 * @param path
 * @param children
 * @param modifierClass
 * @param isAdmin
 * @returns {*}
 * @constructor
 */
const SideBarItem = ({ isSelected, icon, path, children, modifierClass, isAdmin }) => (
    <Link
        to={path}
        className={classNames('c-side-bar-v2__item ', {
            'is-selected': isSelected,
            [modifierClass]: modifierClass,
            'u-color-admin': isAdmin
        })}
    >
        { icon && <MDIcon icon={icon} size="small" modifierClass="c-side-bar-v2__item-icon u-margin-right-tiny" /> }
        <span className="c-side-bar-v2__item-title u-align-middle u-1/1">
            {children}
        </span>
    </Link>
);

SideBarItem.defaultProps = {
    isSelected: false,
    icon: null,
    path: '',
    modifierClass: null,
    isAdmin: false,
};

SideBarItem.propTypes = {
    /**
     * Onko linkkielementti valittu.
     */
    isSelected: PropTypes.bool,

    /**
     * Ikoni.
     */
    icon: PropTypes.string,

    /**
     * Linkki.
     */
    path: PropTypes.string,

    /**
     * Linkkielementin sisältö.
     */
    children: PropTypes.node.isRequired,

    /**
     * CSS:n muokkausluokka.
     */
    modifierClass: PropTypes.string,

    /**
     * Lisää admin värin
     */
    isAdmin: PropTypes.bool,
};

export default SideBarItem;
