import { List, Map, fromJS } from 'immutable';
import { createSelector } from 'reselect';
import { userRoles } from 'shared/constants/index';
import { promised as request } from 'BubbleWrapAgent';
import { actions as notificationActions } from 'shared/stores/notifications';
import validators from 'shared/validators';
import api from 'api';
import companyUserRelationTypes from 'shared/constants/companyUserRelationTypes';

/**
 * Action types (actions)
 */
const namespace = 'parties';

const PREVIOUS_PARTIES_GET_REQUEST = `${namespace}/PREVIOUS_PARTIES_GET_REQUEST`;
const previousPartiesGetRequest = (partyType) => ({ type: PREVIOUS_PARTIES_GET_REQUEST, partyType });

const PREVIOUS_PARTIES_GET_OK = `${namespace}/PREVIOUS_PARTIES_GET_OK`;
const previousPartiesOk = (parties, partyType) => ({
    type: PREVIOUS_PARTIES_GET_OK,
    parties,
    partyType,
});

const PREVIOUS_PARTIES_GET_FAIL = `${namespace}/PREVIOUS_PARTIES_GET_FAIL`;
const previousPartiesFail = (error, partyType) => ({
    type: PREVIOUS_PARTIES_GET_OK,
    error,
    partyType,
});

const USER_GET_REQUEST = `${namespace}/USER_GET_REQUEST`;
const userGetRequest = () => ({ type: USER_GET_REQUEST });

const USER_GET_OK = `${namespace}/USER_GET_OK`;
const userGetOk = (user) => ({
    type: USER_GET_OK,
    user,
});

const USER_GET_FAIL = `${namespace}/USER_GET_FAIL`;
const userGetFail = () => ({ type: USER_GET_FAIL });

const USER_POST_REQUEST = `${namespace}/USER_POST_REQUEST`;
const userPostRequest = () => ({ type: USER_POST_REQUEST });

const USER_POST_OK = `${namespace}/USER_POST_OK`;
const userPostOk = (user) => ({ type: USER_POST_OK, user });

const USER_POST_FAIL = `${namespace}/USER_POST_FAIL`;
const userPostFail = (error) => ({ type: USER_POST_FAIL, error });

const USER_POST_TEMP_REQUEST = `${namespace}/USER_POST_TEMP_REQUEST`;
const userPostTempRequest = () => ({ type: USER_POST_TEMP_REQUEST });

const USER_POST_TEMP_OK = `${namespace}/USER_POST_TEMP_OK`;
const userPostTempOk = (user) => ({ type: USER_POST_TEMP_OK, user });

const USER_POST_TEMP_FAIL = `${namespace}/USER_POST_TEMP_FAIL`;
const userPostTempFail = (error) => ({ type: USER_POST_TEMP_FAIL, error });

const USER_SET = `${namespace}/USER_SET`;
const userSet = (user) => ({ type: USER_SET, user });

const SET_USER_ROLE = `${namespace}/SET_USER_ROLE`;
const setUserRole = (role) => ({ type: SET_USER_ROLE, role });


const PARTY_SET = `${namespace}/PARTY_SET`;
const partySet = (party) => ({ type: PARTY_SET, party: Map(party) });

const PARTY_RESET = `${namespace}/PARTY_RESET`;
const partyReset = () => ({ type: PARTY_RESET });

const PARTY_NEW = `${namespace}/PARTY_NEW`;
const partyNew = () => ({ type: PARTY_NEW });

const PARTY_SET_HAS_ACCOUNT = `${namespace}/PARTY_SET_HAS_ACCOUNT`;
const partySetHasAccount = (value) => ({ type: PARTY_SET_HAS_ACCOUNT, value });

// Mahdollista asettaa uutta työntekijää luotaessa työntekijän esitiedot, esim. hetu
const SET_INITIAL_EMPLOYEE = `${namespace}/SET_INITIAL_EMPLOYEE`;
const setInitialEmployee = (initialEmployee) => ({ type: SET_INITIAL_EMPLOYEE, initialEmployee });

// Tyhjentää uuden työntekijän esitiedot
const RESET_INITIAL_EMPLOYEE = `${namespace}/RESET_INITIAL_EMPLOYEE`;
const resetInitialEmployee = () => ({ type: RESET_INITIAL_EMPLOYEE });

const COMPANY_DETAILS = `${namespace}/USER_EMPLOYER_DETAILS`;
const setCompanyDetails = (details) => ({ type: COMPANY_DETAILS, details });

const BENEFICIARY_SET = `${namespace}/BENEFICIARY_SET`;
const setBeneficiary = (beneficiary) => ({ type: BENEFICIARY_SET, beneficiary });

const EMPLOYER_PAYSLIP_DELIVERY_METHOD_SET = `${namespace}/EMPLOYER_PAYSLIP_DELIVERY_METHOD_SET`;

const setEmployerPayslipDeliveryMethod = (method) => ({ type: EMPLOYER_PAYSLIP_DELIVERY_METHOD_SET, method });

const BENEFIT_DECISION_GET_REQUEST = `${namespace}/BENEFIT_DECISION_GET_REQUEST`;
const benefitDecisionGetRequest = () => ({ type: BENEFIT_DECISION_GET_REQUEST });

const BENEFIT_DECISION_GET_OK = `${namespace}/BENEFIT_DECISION_GET_OK`;
const benefitDecisionGetOk = () => ({ type: BENEFIT_DECISION_GET_OK });

const BENEFIT_DECISION_GET_FAIL = `${namespace}/BENEFIT_DECISION_GET_FAIL`;
const benefitDecisionGetFail = (error) => ({ type: BENEFIT_DECISION_GET_FAIL, error });

const CARED_GET_REQUEST = `${namespace}/CARED_GET_REQUEST`;
const caredGetRequest = () => ({ type: CARED_GET_REQUEST });

const CARED_GET_OK = `${namespace}/CARED_GET_OK`;
const caredGetOk = (user) =>({ type: CARED_GET_OK, user });

const CARED_GET_FAIL = `${namespace}/CARED_GET_FAIL`;
const caredGetFail = () => ({ type: CARED_GET_FAIL });

const CARED_PARTY_RESET = `${namespace}/CARED_PARTY_RESET`;
const caredPartyReset = () => ({ type: CARED_PARTY_RESET });

// Laitetaan vaan tyhjä objekti
const resetBenefitDecision = () => ({ type: BENEFICIARY_SET, beneficiary: {} });

const SET_EMPLOYEE_WORK_EXPERIENCE = `${namespace}/SET_EMPLOYEE_WORK_EXPERIENCE`;
const setEmployeeWorkExperience = (workExperience) => ({ type: SET_EMPLOYEE_WORK_EXPERIENCE, workExperience });

/**
 * Initial state
 */
const initialState = {
    user: {
        user: {},
        isFetching: false,
        companyDetails: {},
    },
    party: {
        user: {},
        isFetching: false,
        isPosting: false,
        hasStAccount: false,
        isAddingNewParty: false,
        workExperience: [],
    },
    cared: {
        user: {},
        isFetching: false,
        isPosting: false,
        hasStAccount: false,
        isAddingNewParty: false,
        workExperience: [],
    },

    // Lisättävän työntekijän esitäytetyt tiedot
    initialEmployee: {
    },

    userRole: null,

    previousParties: {
        parties: [],
        isFetching: false,
        hasFetched: false,
        failReason: null,
    },
    previousCaredParties: {
        parties: [],
        isFetching: false,
        hasFetched: false,
        failReason: null,
    },
    beneficiary: {
        user: {},
        isFetching: false,
    },
    employerPayslipDeliveryMethod: null
};


/**
 * Reducer
 */
export const partiesReducer = (state = fromJS(initialState), action) => {
    switch (action.type) {
        case PREVIOUS_PARTIES_GET_REQUEST:
            return state.setIn([action.partyType, 'isFetching'], true);
        case PREVIOUS_PARTIES_GET_OK:
            return state
                .setIn([action.partyType, 'isFetching'], false)
                .setIn([action.partyType, 'hasFetched'], true)
                .setIn([action.partyType, 'parties'], action.parties);
        case PREVIOUS_PARTIES_GET_FAIL:
            return state
                .setIn([action.partyType, 'isFetching'], false)
                .setIn([action.partyType, 'hasFetched'], false)
                .setIn([action.partyType, 'failReason'], action.reason);

        case USER_GET_REQUEST:
            return state.setIn(['user', 'isFetching'], true);
        case USER_GET_OK:
            return state
                .setIn(['user', 'isFetching'], false)
                .setIn(['user', 'user'], action.user);
        case USER_GET_FAIL:
            return state.setIn(['user', 'isFetching'], true);

        case USER_POST_REQUEST:
            return state.setIn(['party', 'isPosting'], true);
        case USER_POST_OK:
            return state
                .setIn(['party', 'user'], action.user)
                .setIn(['party', 'isPosting'], false)
                .setIn(['party', 'hasStAccount'], true)
                .updateIn(['previousParties', 'parties'], List(), (list) => list.push(action.user));
        case USER_POST_FAIL:
            return state
                .setIn(['party', 'user'], Map())
                .setIn(['party', 'isPosting'], false);

        case USER_POST_TEMP_REQUEST:
            return state.setIn(['party', 'isPosting'], true);
        case USER_POST_TEMP_OK:
            return state
                .setIn(['party', 'user'], action.user)
                .setIn(['party', 'isPosting'], false)
                .setIn(['party', 'hasStAccount'], true)
                .updateIn(['previousParties', 'parties'], List(), (list) => list.push(action.user));
        case USER_POST_TEMP_FAIL:
            return state
                .setIn(['party', 'user'], Map())
                .setIn(['party', 'isPosting'], false);
        case USER_SET:
            return state
                .setIn(['user', 'user'], action.user)
                .setIn(['user', 'isFetching'], false);

        case SET_USER_ROLE:
            return state.set('userRole', action.role);

        case PARTY_NEW:
            return state.setIn(['party', 'isAddingNewParty'], true);
        case PARTY_SET:
            return state.setIn(['party', 'user'], action.party);
        case PARTY_RESET:
            return state.set('party', fromJS(initialState.party));
        case PARTY_SET_HAS_ACCOUNT:
            return state.setIn(['party', 'hasStAccount'], action.value);

        case SET_INITIAL_EMPLOYEE:
            return state.set('initialEmployee', fromJS(action.initialEmployee));
        case RESET_INITIAL_EMPLOYEE:
            return state.set('initialEmployee', Map({}));

        case COMPANY_DETAILS:
            return state.setIn(['user', 'companyDetails'], fromJS(action.details));

        case BENEFICIARY_SET:
            return state.setIn(['beneficiary', 'user'], fromJS(action.beneficiary));
        case BENEFIT_DECISION_GET_REQUEST:
            return state.setIn(['beneficiary', 'isFetching'], true);
        case BENEFIT_DECISION_GET_OK:
            return state.setIn(['beneficiary', 'isFetching'], false);
        case BENEFIT_DECISION_GET_FAIL:
            return state.setIn(['beneficiary', 'hasError'], true).setIn(['beneficiary', 'isFetching'], false);
        case SET_EMPLOYEE_WORK_EXPERIENCE:
            return state.setIn(['party', 'workExperience'], fromJS(action.workExperience));
        case CARED_GET_REQUEST:
            return state.setIn(['cared', 'isFetching'], true);
        case CARED_GET_OK:
            return state
                .setIn(['cared', 'user'], fromJS(action.user))
                .setIn(['cared', 'isFetching'], false);
        case CARED_GET_FAIL:
            return state
                .setIn(['cared', 'hasError'], true)
                .setIn(['cared', 'isFetching'], false);
        case CARED_PARTY_RESET:
            return state.set('cared', fromJS(initialState.cared));
        case EMPLOYER_PAYSLIP_DELIVERY_METHOD_SET:
            return state.set('employer_payslip_delivery_method', action.method);
        default:
            return state;
    }
};

/**
 * Action creators
 */
const previousPartiesGet = (type) => (dispatch, getState) => {
    const state = getState();
    const partyType = type === companyUserRelationTypes.RELATION_EMPLOYEE ? 'previousParties' : 'previousCaredParties';

    const hasFetchedPreviousParties = partiesSelectors.hasFetchedPreviousParties(state, partyType);

    // Hae aiemmat käyttäjän vain kerran sivulatauksessa.
    if (!hasFetchedPreviousParties) {
        // Merkitään aiempien työntekijöiden haku -kenttä pending tilaan jolloin näytetään latausindikaattori
        dispatch(previousPartiesGetRequest(partyType));

        request
            .get(Routing.generate('api_1_get_users'))
            .query({ offset: 0, limit: 5000, type })
            .then((response) => {
                if (response.statusCode === 200) {
                    const parties = fromJS(_.get(response.body, 'data', [])).sortBy((party) => party.get('fullName'));
                    dispatch(previousPartiesOk(parties, partyType));
                }
                return response;
            })
            .catch((error) => {
                dispatch(previousPartiesFail(error, partyType));
            });
    }
};

const caredGet = (id) => (dispatch) => {
    dispatch(caredGetRequest());

    api
        .get(Routing.generate('api_1_get_user', { user: id }))
        .then(
            (json) => dispatch(caredGetOk(json.data)),
            () => dispatch(caredGetFail())
        );
};

const userGet = (id) => (dispatch) => {

    dispatch(userGetRequest());

    request
        .get(Routing.generate('api_1_get_user', { user: id }))
        .then((response) => {
            if (response.statusCode === 200) {
                dispatch(userGetOk(Map(response.body)));
            } else {
                throw Error('Blaa');
            }
        })
        .catch((error) => {
            dispatch(userGetFail(error));
        });
};

const userPost = (user) => (dispatch, getState) => {
    dispatch(userPostRequest());

    const state = getState();

    request
        .post(Routing.generate('api_1_post_user'))
        .send(user)
        .then((response) =>
            // Kaikki ok. Haetaan uuden käyttäjän tiedot josta saadaan käyttäjän id.
            request.get(response.header['location'])
        )
        .then((response) => {
            // Tallennetaan työntekijän ja työnantajan tiedot (keskeneräiseen) työsopimukseen. Palautetaan promise.
            const user = response.body;

            const partyName = getUserRole(state) === userRoles.EMPLOYEE ?
                _trans('contract.employer.singular') :
                _trans('contract.employee.singular');

            // TODO: Oikeastaan notfiointi voisi olla hostaavassa komponentissa ja reagoida tämän palasen actioneihin... Ehkä.
            dispatch(notificationActions.addNotification({
                type: 'success',
                message: _trans('notifications.success.new_party_created', { party: partyName })
            }));

            dispatch(userPostOk(Map(user)));

            return response;
        })
        .catch((error) => {
            dispatch(notificationActions.addNotification({
                type: 'error',
                message: 'Työntekijää ei voitu luoda'
            }));
            dispatch(userPostFail(error));
        });
};

const userPostTemp = (user) => (dispatch) => {
    dispatch(userPostTempRequest());

    request
        .post(Routing.generate('api_1_post_user_temp'))
        .send(user)
        .then((response) => {
            if (response.statusCode === 201) {
                // haetaan luotu käyttäjä
                return request.get(response.headers.location).then((response) => {
                    const user = response.body;
                    // tallennetaan käyttäjän tiedot storeen
                    dispatch(userPostTempOk(Map(user)));
                });
            }
        }).catch((error) => {
            dispatch(notificationActions.addNotification({
                type: 'error',
                message: _trans('notifications.error.no_user_found_email'),
            }));
            dispatch(userPostTempFail(error));
        });
};

const fetchCompanyDetails = () => (dispatch) => {
    request
        .get(Routing.generate('get_company_details'))
        .end((error, response) => {
            if (error) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('notifications.error.no_company_details'),
                }));
            } else {
                dispatch(setCompanyDetails(response.body.data));
            }
        });
};

const fetchBenefitDecision = (decisionId) => (dispatch) => {
    dispatch(benefitDecisionGetRequest());
    request
        .get(Routing.generate('api_1_get_benefitdecision', { decision: decisionId }))
        .end((error, response) => {
            if (error) {
                dispatch(benefitDecisionGetFail(error.message));
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('contract.benefit_decision.not_found'),
                }));
            } else {
                const beneficiary = _.get(response.body, 'decision.beneficiary', null);
                const employer = _.get(response.body, 'decision.employer', null);
                const employerPayslipDeliveryMethod = _.get(response.body, 'decision.employerPayslipDeliveryMethod', null);
                dispatch(setBeneficiary(beneficiary));
                dispatch(userSet(fromJS(employer)));
                dispatch(benefitDecisionGetOk());
                dispatch(setEmployerPayslipDeliveryMethod(employerPayslipDeliveryMethod));
            }
        });
};

/**
 * TODO: duplikaattiaction? user.js::fetchEmployeeWorkExperience
 *
 * @param userId
 * @returns {Function}
 */
const fetchEmployeeWorkExperience = (userId) => (dispatch) => {
    request
        .get(Routing.generate('api_1_get_user_workexperience', { user: userId }))
        .end((error, response) => {
            if (error) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('notifications.error.no_employee_work_experience'),
                }));
            } else {
                const body = _.get(response, 'body');
                dispatch(setEmployeeWorkExperience(body ? body : []));
            }
        });
};

const fetchCommuneEmployeeEmployerSharedContracts = (employee, employer) => () => request
    .get(Routing.generate('api_1_get_contract_shared', { employee, employer }))
    .then(
        (resp) => _.get(resp, 'body', []).length > 0,
        (err) => err
    );

/**
 * Löytyykö hoitaja / hoidettava -parilta jo voimassaoleva soppari.
 * @param employee
 * @param cared
 * @returns {(function(): Promise<boolean|undefined>)|*}
 */
const fetchExistingContractsBetweenCaredAndEmployee = (employee, cared) => async () => {
    try {
        if (! employee || ! cared) return false;

        const response = await api.get(`/api/v3/contracts/existing-pairs?cared=${cared}&employee=${employee}`);
        return (response.data ?? []).length > 0;
    } catch (e) {
        console.error(e);
    }
};

/**
 * Selectors
 */
const getRootSelector = (state) => state.parties;

const getPreviousPartiesRoot = (state, partyType) => getRootSelector(state).get(partyType);
const getPreviousParties = (state, partyType) => getPreviousPartiesRoot(state, partyType).get('parties');
const isFetchingPreviousParties = (state, partyType) => getPreviousPartiesRoot(state, partyType).get('isFetching');
const hasFetchedPreviousParties = (state, partyType) => getPreviousPartiesRoot(state, partyType).get('hasFetched');

const getPartyRoot = (state) => getRootSelector(state).get('party');
const getParty = (state) => getPartyRoot(state).get('user');

const getCaredPartyRoot = (state) => getRootSelector(state).get('cared');
const getCaredParty = (state) => getCaredPartyRoot(state).get('user');
const isCaredNoop = (state) => getCaredPartyRoot(state).get('user').has('noop');

/**
 * Palautetaan esitäytetyn työntekijän tiedot vain jos hetu on validi.
 * @param state
 * @returns {Map<string, unknown>|*}
 */
const getInitialEmployee = (state) => {
    const initialEmployee = getRootSelector(state).get('initialEmployee');
    const ssn = initialEmployee.get('socialSecurityNumber', '');
    if (ssn.trim() !== '' && validators.isSsn(ssn)) {
        return initialEmployee;
    } else {
        return Map({});
    }
};

const isFetchingParty = (state) => getPartyRoot(state).get('isFetching');
const isPostingParty = (state) => getPartyRoot(state).get('isPosting');
const isAddingNewParty = (state) => getPartyRoot(state).get('isAddingNewParty');
const partyHasStAccount = (state) => getPartyRoot(state).get('hasStAccount');

const getUserRoot = (state) => getRootSelector(state).get('user');
const getUser = (state) => getUserRoot(state).get('user');
const isFetchingUser = (state) => getUserRoot(state).get('isFetching');
const isPostingUser = (state) => getUserRoot(state).get('isPosting');

const getUserRole = (state) => getRootSelector(state).get('userRole');

const getEmployer = createSelector(
    getUserRole, getUser, getParty,
    (role, user, party) => role === userRoles.EMPLOYEE ? party : user
);
const getEmployee = createSelector(
    getUserRole, getUser, getParty,
    (role, user, party) => role === userRoles.EMPLOYEE ? user : party
);

// Ei anneta jatkaa jos TA ja TT on sama henkilö, tulorekisteri ei tue tätä
const isPartySelected = createSelector(
    getParty, getEmployee, getEmployer,
    (party, employee, employer) => party.count() > 0 && employee.get('userId') !== employer.get('userId')
);

const isCaredPartySelected = (state) => getCaredParty(state).count() > 0;

const getEmployerName = (state) => {
    const lastVersion = state.contractVersions.get('versions').last();
    return lastVersion.employerName;
};

const getEmployeeName = (state) => {
    const lastVersion = state.contractVersions.get('versions').last();
    return lastVersion.employeeName;
};

const getPartyType = (state) => {
    const user = getRootSelector(state).getIn(['party', 'user']);
    return user.get('type');
};

const isCompanyUser = (state) => getRootSelector(state).get('userRole') == userRoles.COMPANY_OPERATOR
        || getRootSelector(state).get('userRole') == userRoles.COMPANY_SHADOW;

const isCommuneUser = (state) => getRootSelector(state).get('userRole') == userRoles.COMMUNE_OPERATOR
        || getRootSelector(state).get('userRole') == userRoles.COMMUNE;

const getCompanyDetails = (state) => state.parties.getIn(['user', 'companyDetails'], Map());

const getBeneficiaryDetails = (state) => state.parties.getIn(['beneficiary', 'user'], Map());

const isSelfEmployed = createSelector(getEmployer, getBeneficiaryDetails, (employer, beneficiary) => employer.get('userId') === beneficiary.get('userId'));

const hasBenefitDecisionError = (state) => state.parties.getIn(['beneficiary', 'hasError'], false);

const getEmployeeWorkExperience = (state) => state.parties.getIn(['party', 'workExperience'], List());

const getEmployerPayslipDeliveryMethod = (state) => getRootSelector(state).get('employer_payslip_delivery_method');
/**
 * Exports
 */
export const partiesActionTypes = {
    USER_POST_OK,
};

export const partiesActions = {
    previousPartiesGet,
    previousPartiesOk,
    userGet,
    userPost,
    userPostTemp,
    userSet,
    setUserRole,
    partyNew,
    partySet,
    partyReset,
    partySetHasAccount,
    setInitialEmployee,
    resetInitialEmployee,
    fetchCompanyDetails,
    fetchBenefitDecision,
    setBeneficiary,
    resetBenefitDecision,
    fetchEmployeeWorkExperience,
    fetchCommuneEmployeeEmployerSharedContracts,
    fetchExistingContractsBetweenCaredAndEmployee,
    caredGet,
    caredGetOk,
    caredPartyReset,
};

export const partiesSelectors = {
    isFetchingPreviousParties,
    hasFetchedPreviousParties,
    getPreviousParties,

    getParty,
    getInitialEmployee,
    isFetchingParty,
    isPostingParty,
    isAddingNewParty,
    partyHasStAccount,
    isPartySelected,
    isCaredPartySelected,

    getUser,
    isFetchingUser,
    isPostingUser,

    getUserRole,

    getEmployer,
    getEmployee,

    getEmployerName,
    getEmployeeName,

    getCompanyDetails,

    getPartyType,
    isCompanyUser,
    isCommuneUser,
    getBeneficiaryDetails,
    isSelfEmployed,
    hasBenefitDecisionError,
    getEmployeeWorkExperience,
    getEmployerPayslipDeliveryMethod,
    getCaredParty,
    isCaredNoop,
};

export default {
    reducer: partiesReducer,
    actions: partiesActions,
    selectors: partiesSelectors,
};
