import React from 'react';
import PersonnelGroupList from '../containers/PersonnelGroupList';
import PersonnelGroupForm from '../containers/PersonnelGroupForm';
import BookkeepingAccountList from '../containers/BookkeepingAccountList';
import BookkeepingAccountForm from '../containers/BookkeepingAccountForm';
import resolvePostingRoutes from './resolvePostingRoutes';
import BookkeepingHelp from 'shared/Bookkeeping/containers/BookkeepingHelp';

/* eslint-disable react/display-name */
const resolveBookkeepingRoutes = (routes, groups, isLoadingGroups) => [
    {
        groupName: _trans('company_manager.personnel_groups.sidebar.groups.bookkeeping.name'),
        paths: [
            {
                title: _trans('company_manager.personnel_groups.sidebar.groups.bookkeeping.path.help'),
                icon: 'help',
                path: routes.BOOKKEEPING,
                component: BookkeepingHelp,
                exact: true,
            },
            {
                title: _trans('company_manager.personnel_groups.sidebar.groups.bookkeeping.path.personnel_group'),
                icon: 'group',
                path: routes.PERSONNEL_GROUPS,
                component: (props) => <PersonnelGroupList routes={routes} {...props} />,
                exact: true,
                paths: [
                    {
                        path: routes.PERSONNEL_GROUPS_FORM,
                        component: (props) => <PersonnelGroupForm routes={routes} {...props} />,
                        exact: true,
                    },
                ],
            },
            {
                title: _trans('company_manager.personnel_groups.sidebar.groups.bookkeeping.path.accounts'),
                icon: 'library_books',
                path: routes.BOOKKEEPING_ACCOUNTS,
                component: () => <BookkeepingAccountList routes={routes} />,
                exact: true,
                paths: [
                    {
                        path: routes.BOOKKEEPING_ACCOUNT_FORM,
                        component: (props) => <BookkeepingAccountForm routes={routes} {...props} />,
                        exact: true,
                    },
                ],
            },
        ]
    },
    {
        groupName: _trans('company_manager.personnel_groups.sidebar.groups.postings.name'),
        isLoadingRoutes: isLoadingGroups,
        paths: resolvePostingRoutes(groups, routes.BOOKKEEPING_POSTINGS),
    },
];
/* eslint-enable react/display-name */

export default resolveBookkeepingRoutes;
