import React from 'react';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Button } from 'shared/components';
import resolveUrl from 'shared/utils/resolveUrl';
import Popover from 'shared/components/Popover';
import { generateRoutes } from 'ContractV3/constants/routes';
import { isCommune } from 'shared/utils/commonUtils';

const routes = generateRoutes(isCommune());

/**
 * Kertoilee miksei yleiset liitteet ole enää käytössä.
 * @returns {JSX.Element}
 * @constructor
 */
export const AttachmentsDisclaimer = () => {
    const isNewContract = useSelector(select.contract.isNewContract);
    const isDocumentToolEnabled = useSelector(select.userMetadata.isDocumentToolEnabled);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isCompany = useSelector(select.userMetadata.isCompany);
    const isOperator = isCommune || isCompany;
    const contractId = useSelector(select.contract.getContractId);
    const contractDataId = useSelector(select.contract.getContractDataId);

    return (
        <Popover maxWidth={320}>
            <Button flat mdIcon="help_outline">
                {_trans('Uusia yleisiä liitteitä ei voi enää lisätä.', {}, 'jobContract')}
            </Button>
            {/*<IconText isIconAfterText mdIcon="help_outline" text={_trans('Uusia yleisiä liitteitä ei voi enää lisätä.', {}, 'jobContract')} iconSize="medium" />*/}
            <div>
                <p>
                    {_trans('Uusia yleisiä liitteitä ei voi enää lisätä.', {}, 'jobContract')}
                </p>
                {isDocumentToolEnabled && (
                    <div>
                        {isNewContract
                            ? _transMd(`
Palvelussa on otettu käyttöön Dokumentit palveluun -ominaisuus, ja sen myötä käytössä on myös Visman tarjoama turvallinen dokumenttivarasto. 
Voit jatkossa lisätä sopimukselle liitteen **Dokumentit-sivulta**. [Lue lisää ominaisuudesta](%readMoreUrl%)
`, {
                                readMoreUrl: isCommune
                                    ? 'https://kuntatuki.oima.fi/support/solutions/articles/101000495978-dokumentit-palveluun-hyvinvointialueelle'
                                    : 'https://ophtuki.oima.fi/support/solutions/articles/101000494162-dokumenttien-tallentaminen-hyvinvointialueelle'
                            }, 'jobContract')
                            : _transMd(`
Palvelussa on otettu käyttöön Dokumentit palveluun -ominaisuus, ja sen myötä käytössä on myös Visman tarjoama turvallinen dokumenttivarasto. 
Voit jatkossa lisätä sopimukselle liitteen [Dokumentit-sivulta](%url%).
`, {
                                url: resolveUrl(routes.DOCUMENTS_LIST, { contractId, contractDataId })
                            }, 'jobContract')}
                    </div>
                )}
                {(! isDocumentToolEnabled && isOperator) && (
                    <div>
                        {_transMd(`
Oimassa on otettu käyttöön Dokumentit palveluun –ominaisuus, ja sen myötä käytössä on myös Visman tarjoama turvallinen dokumenttivarasto. 
Saat Dokumentit palveluun -ominaisuuden käyttöön [Oiman asiakaspalvelusta](%url%). [Lue lisää ominaisuudesta](%readMoreUrl%). 
`, {
                            url: isCommune
                                ? 'https://kuntatuki.oima.fi/support/tickets/new'
                                : 'https://ophtuki.oima.fi/support/tickets/new',
                            readMoreUrl: isCommune
                                ? 'https://kuntatuki.oima.fi/support/solutions/articles/101000495978-dokumentit-palveluun-hyvinvointialueelle'
                                : 'https://ophtuki.oima.fi/support/solutions/articles/101000494162-dokumenttien-tallentaminen-hyvinvointialueelle' }, 'jobContract')
                        }
                    </div>
                )}
            </div>
        </Popover>
    );
};


