import DOMPurify from 'dompurify';

/**
 * Tietoturva-apumetodeja
 */

/**
 * Ottaa vastaan mitä tahansa tekstiä mutta palauttaa ainoastaan markdownissa sallitun sisällön
 * (tällä hetkellä vain text node)
 * @param source
 * @returns {*}
 */
function sanitizeMarkdown(source) {
    return DOMPurify.sanitize(source, { ALLOWED_TAGS: ['#text'] });
}

/**
 * Ottaa vastaan mitä tahansa tekstiä mutta palauttaa ainoastaan puhtaan tekstisisällön.
 * @param source
 * @returns {*}
 */
function sanitizeContent(source) {
    return DOMPurify.sanitize(source, { ALLOWED_TAGS: ['#text'], KEEP_CONTENT: false });
}

export default {
    sanitizeMarkdown,
    sanitizeContent,
};
