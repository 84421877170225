import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useField } from 'formik';
import { select } from '@rematch/select';
import React, { useEffect } from 'react';
import { FormField } from 'shared/components/Formik';
import { tesTypes } from 'shared/constants/tesTypes';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import { Value } from 'shared/components/Value';
import { Placeholder } from 'shared/components';

/**
 * Työsuhteen irtisanominen. Vain HA.
 * @returns {null}
 * @constructor
 */
const NoticePeriodDisclaimerText = (props) => {
    const { name } = props;
    const isLoadingCollectiveAgreements = useSelector((state) => state.loading.effects.collectiveAgreement.fetchCollectiveAgreements);
    const collectiveAgreement = useSelector(select.contract.getCollectiveAgreement);
    const [field,meta,helpers] = useField(name);
    const collectiveAgreementField = field.value;

    const isLoading = isLoadingCollectiveAgreements || ! collectiveAgreement?.baseType;

    useEffect(() => {
        if (meta?.touched || isLoading) return;

        switch (collectiveAgreement?.baseType) {
            case tesTypes.COMMUNE_CONTRACT:
                helpers.setValue('contract.termination.notice_period_disclaimer_text_tyosopimuslaki');
                break;
            case tesTypes.COMMUNE_HETA:
                helpers.setValue('contract.termination.notice_period_disclaimer_text_tyoehtosopimus');
                break;
            default:
                helpers.setValue('');
        }
        helpers.setTouched(true);
    }, [collectiveAgreement, field.value, isLoading]);

    return (
        <FormField {...getFieldProps(props)} isContentFormField={false}>
            <Value>
                <Placeholder isPending={isLoadingCollectiveAgreements}>
                    <div>
                        {collectiveAgreementField !== ''
                            ? _trans(collectiveAgreementField, {}, 'messages')
                            : '-'}
                    </div>
                </Placeholder>
            </Value>
        </FormField>
    );
};

NoticePeriodDisclaimerText.propTypes = {
    name: PropTypes.string.isRequired,
};

export default NoticePeriodDisclaimerText;
