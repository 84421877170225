import React, { Component } from 'react';
import { observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { createStackProxy, createSumProxy } from './proxy';
import request from 'BubbleWrapAgent';

/**
 * Class decorator, joka hoitaa datan lataamisen APIsta. Esimerkkiä kannattaa
 * hakea Form.jsx. Jos
 * käytät myös muita decoraattoreita, niin tämän pitää tulla ensin. Idea
 * varastettu apollo-clientistä.
 *
 * @param {Object} params urlit ja listenerit
 * @param {Object} options lisäoptioita
 * @returns {function(*)}
 * @deprecated
 * @constructor
 */
function SuoraTyoAPI(params, options = {}) {
    return (InnerComponent) => {
        @observer
        class SuoraTyoAPI extends Component {
            // Listassa kaikkien sisäänrakennettujen listenereiden nimet
            static builtInListeners = [];

            /**
             * APIsta saadut datat
             * @type {Array}
             */
            @observable _data = [];
            data = createStackProxy(this._data);

            /**
             * Requesteista aiheutuneet virheet
             * @type {Array}
             * @private
             */
            @observable _error = [];
            error = createStackProxy(this._error);

            /**
             * Menossa olevat requestit
             * @type {Array}
             * @private
             */
            @observable _pending = [];
            pending = createSumProxy(this._pending);

            /**
             * Onko yhtään gettiä menossa
             *
             * @deprecated
             * @returns {boolean}
             */
            @computed get isLoading() {
                // console.warn(`SuoraTyoAPI: this.props.isLoading is deprecated. Use this.props.pending['get'] > 0 instead.`);
                return this.pending['get'] > 0;
            }

            /**
             * Onko yhtään postia, puttia, patchia tai deleteä menossa
             *
             * @deprecated
             * @returns {boolean}
             */
            @computed get isSending() {
                // console.warn(`SuoraTyoAPI: this.props.isSending is deprecated. Use this.props.pending[''] - this.props.pending['get] > 0 instead.`);
                return this.pending[''] - this.pending['get'] > 0;
            }

            /**
             * Onko yhtään requestia menossa
             *
             * @deprecated
             * @returns {boolean}
             */
            @computed get isWorking() {
                // console.warn(`SuoraTyoAPI: this.props.isWorking is deprecated. Use this.props.pending[''] > 0 instead.`);
                return this.isLoading || this.isSending;
            }

            /**
             * Onko yhtään requestia menossa
             *
             * @param {string} key - yhdistelmä muotoa method.identifier (esim. get.userMetadata)
             *
             * @returns {boolean}
             */
            isPending = (key = '') => this.pending[key] > 0;

            UNSAFE_componentWillMount() {
                this.initialLoad();
            }

            /**
             * Lataa aloitusdatan heti komponentin alustuksen yhteydessä
             */
            initialLoad() {
                const identifier = this.resolveInitialLoadIdentifiers();
                if (identifier) {
                    _.forEach(identifier, (i) => {
                        this.get(i);
                    });
                }
            }

            /**
             * Selvittää urlit, josta aloitusdata pitäisi noutaa. Jos
             * initialLoad = false, ei ladata alkudataa ollenkaan. Jos getiksi
             * kelpaavia urleja on vain yksi, käytetään sitä. Jos taas getiksi
             * kelpaavia urleja on monta, käytetään initialLoadissa määriteltyjä
             * urleja.
             *
             * @returns {String[]|null}
             */
            resolveInitialLoadIdentifiers() {
                if (options.initialLoad === false) {
                    return null;
                }
                if (options.initialLoad) {
                    if (! Array.isArray(options.initialLoad)) {
                        options.initialLoad = [options.initialLoad];
                    }
                    _.forEach(options.initialLoad, (il) => {
                        if (params[il] || params['get.' + il]) {
                            return il;
                        }
                        throw new Error(`Url for initialLoad='${il}' is not defined`);
                    });

                    return options.initialLoad;
                }

                const getters = this.resolveGetterIdentifiers();

                if (getters.length === 0) {
                    return null;
                }

                if (getters.length === 1) {
                    return getters;
                }

                throw new Error(`Multiple getters: [${getters.join(', ')}]. Define initialLoad to them or false to disable`);
            }

            /**
             * Filtteröi paramsista getiksi kelpaavat identifierit ja palauttaa
             * ne ilman mahdollista 'get.'-alkuliitettä.
             *
             * @returns {String[]}
             */
            resolveGetterIdentifiers() {
                const pattern = /^(get\.)?(?!(success|failed)$)[^.]+$/;

                const getters = _.filter(_.keys(params), (key) => pattern.test(key));

                const identifiers = getters.map((getter) => getter.startsWith('get.') ? getter.substr(4) : getter);

                return _.uniq(identifiers);
            }

            /**
             * Ylemmän tason GET-metodi, jotka laitetaan alaspäin propsina
             *
             * @param {string} identifier urlin nimi
             * @param {*} data lähetettävä data
             * @param {*} extraParams urlin generoinnissa tarvittavat lisäparametrit
             * @returns {Bluebird|Promise}
             */
            get = (...params) => this.request('get', ...params);

            /**
             * Ylemmän tason POST-metodi, jotka laitetaan alaspäin propsina
             *
             * @param {string} identifier urlin nimi
             * @param {*} data lähetettävä data
             * @param {*} extraParams urlin generoinnissa tarvittavat lisäparametrit
             * @returns {Bluebird|Promise}
             */
            post = (...params) => this.request('post', ...params);

            /**
             * Ylemmän tason PUT-metodi, jotka laitetaan alaspäin propsina
             *
             * @param {string} identifier urlin nimi
             * @param {*} data lähetettävä data
             * @param {*} extraParams urlin generoinnissa tarvittavat lisäparametrit
             * @returns {Bluebird|Promise}
             */
            put = (...params) => this.request('put', ...params);

            /**
             * Ylemmän tason PATCH-metodi, jotka laitetaan alaspäin propsina
             *
             * @param {string} identifier urlin nimi
             * @param {*} data lähetettävä data
             * @param {*} extraParams urlin generoinnissa tarvittavat lisäparametrit
             * @returns {Bluebird|Promise}
             */
            patch = (...params) => this.request('patch', ...params);

            /**
             * Ylemmän tason DELETE-metodi, jotka laitetaan alaspäin propsina
             *
             * @param {string} identifier urlin nimi
             * @param {*} data lähetettävä data
             * @param {*} extraParams urlin generoinnissa tarvittavat lisäparametrit
             * @returns {Bluebird|Promise}
             */
            delete = (...params) => this.request('delete', ...params);

            /**
             * Alemman tason request-metodi, joka laitetaan alaspäin propsina
             *
             * @param {string} method metodin nimi
             * @param {string} identifier urlin nimi
             * @param {*} data lähetettävä data
             * @param {*} extraParams urlin generoinnissa tarvittavat lisäparametrit
             * @returns {Bluebird|Promise}
             */
            request = (method, identifier, data, extraParams = {}) => this.doRequest({ method, identifier, data, extraParams });

            /**
             * Hoitaa kaikki requestiin liittyvät asiat
             *
             * @param {string} method metodin nimi
             * @param {string} identifier urlin nimi
             * @param {*} data lähetettävä data
             * @param {*} extraParams urlin generoinnissa tarvittavat lisäparametrit
             * @returns {Promise}
             */
            doRequest({ method, identifier, data, extraParams }) {
                const url = this.makeUrl({ method, identifier, extraParams });
                const METHOD = method.toUpperCase();

                if (! ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].includes(METHOD)) {
                    throw new Error(`Unsupported method ${METHOD}. Only GET, POST, PUT, PATCH, and DELETE are supported.`);
                }

                const key = `${method}.${identifier}`;
                this.pending[key]++;

                return new Promise((resolve, reject) => {
                    const req = request(METHOD, url);
                    if (METHOD === 'GET') {
                        req.query(data);
                    } else {
                        req.send(data);
                    }
                    req.end(async (error, response) => {
                        try {
                            if (error) {
                                this.data[key] = undefined;
                                this.error[key] = error;
                                await this.failed({ method, identifier, error });
                                reject(error);
                            } else {
                                this.data[key] = this.getDataFromResponse(response);
                                this.error[key] = undefined;
                                await this.success({ method, identifier, response });
                                resolve(response);
                            }
                        } finally {
                            this.pending[key]--;
                        }
                    });
                });
            }

            /**
             * Palauttaa response.body.data || response.body || undefined
             *
             * @param {Response} response
             * @returns {*|undefined}
             */
            getDataFromResponse(response) {
                const body = response.body;
                return body ? body.data || body : undefined;
            }

            /**
             * Kutsuu success-callbackit järjestyksessä
             *
             * @param {string} method metodin nimi
             * @param {string} identifier urlin nimi
             * @param {Response} response response-objekti
             * @returns {Promise<void>}
             */
            success({ method, identifier, response }) {
                return this.callListeners({ method, identifier, listener: 'success', params: response });
            }

            /**
             * Kutsuu failed-callbackit järjestyksessä
             *
             * @param {string} method metodin nimi
             * @param {string} identifier urlin nimi
             * @param error virheilmoitus
             * @returns {Promise<void>}
             */
            failed({ method, identifier, error }) {
                return this.callListeners({ method, identifier, listener: 'failed', params: error });
            }

            /**
             * Kutsuu callbackit järjestyksessä. Jos callback palauttaa false,
             * ei kutsuta jäljellä olevia callbackeja.
             *
             * @param {string} method metodin nimi
             * @param {string} identifier urlin nimi
             * @param {string} listener listenerin nimi
             * @param params callbackeille annettavat parametrit
             * @returns {Promise<void>}
             */
            async callListeners({ method, identifier, listener, params }) {
                const listeners = this.getListeners({ method, identifier, listener });
                for (const listener of listeners) {
                    if (await listener({ ...this.props, ...this.getOwnFunctions(), ...params }) === false) {
                        break;
                    }
                }
            }

            getOwnFunctions() {
                return {
                    get: this.get,
                    post: this.post,
                    put: this.put,
                    patch: this.patch,
                    delete: this.delete,
                };
            }

            /**
             * Mappaa listenerit funktioista ja sisäänrakennettujen
             * listenereiden nimistä Promiseiksi
             *
             * @param {string} method metodin nimi
             * @param {string} identifier urlin nimi
             * @param {string} listener listenerin nimi
             * @returns {Promise[]}
             */
            getListeners({ method, identifier, listener }) {
                const listeners = this.resolveListeners({ method, identifier, listener });

                return listeners.map((fn) => {
                    if (typeof fn === 'string') {
                        if (SuoraTyoAPI.builtInListeners.includes(fn)) {
                            return async (...args) => this[fn](...args);
                        }

                        throw new Error(`Unsupported built-in listener '${fn}' for ${method} ${identifier} ${listener}`);
                    }

                    if (fn instanceof Function) {
                        return async (...args) => fn(...args);
                    }

                    throw new Error(`Unknown listener for ${method} ${identifier} ${listener}`);
                });
            }

            /**
             * Selvittää listenerit. Kutsumajärjestys on
             * 1. get.item:pin.success
             * 2. item:pin.success
             * 3. get.item.success
             * 4. get:pin.success
             * 5. item.success
             * 6. :pin.success
             * 7. get.success
             * 8. success
             *
             * Jos joku listener palauttaa false tai Promise<false>, niin
             * loppuja ei kutsuta.
             *
             * @param {string} method metodin nimi
             * @param {string} identifier urlin nimi
             * @param {string} listener listenerin nimi
             * @returns {Array<string|Function|Promise>}
             */
            resolveListeners({ method, identifier, listener }) {
                const [_identifier, modifier] = identifier ? identifier.split(':') : [];

                const listeners = [];
                // 1. try to find '<method>.<identifier>:<modifier>.<listener>'
                if (method && _identifier && modifier && params[`${method}.${_identifier}:${modifier}.${listener}`]) {
                    listeners.push(params[`${method}.${_identifier}:${modifier}.${listener}`]);
                }

                // 2. try to find '<identifier>:<modifier>.<listener>'
                if (_identifier && modifier && params[`${_identifier}:${modifier}.${listener}`]) {
                    listeners.push(params[`${_identifier}:${modifier}.${listener}`]);
                }

                // 3. try to find '<method>.<identifier>.<listener>'
                if (method && _identifier && params[`${method}.${_identifier}.${listener}`]) {
                    listeners.push(params[`${method}.${_identifier}.${listener}`]);
                }

                // 4. try to find '<method>:<modifier>.<listener>'
                if (method && modifier && params[`${method}.:${modifier}.${listener}`]) {
                    listeners.push(params[`${method}:${modifier}.${listener}`]);
                }

                // 5. try to find '<identifier>.<listener>'
                if (_identifier && params[`${_identifier}.${listener}`]) {
                    listeners.push(params[`${_identifier}.${listener}`]);
                }

                // 6. try to find ':<modifier>.<listener>'
                if (modifier && params[`:${modifier}.${listener}`]) {
                    listeners.push(params[`:${modifier}.${listener}`]);
                }

                // 7. try to find '<method>.<listener>'
                if (method && params[`${method}.${listener}`]) {
                    listeners.push(params[`${method}.${listener}`]);
                }

                // 8. try to find '<listener>'
                if (params[`${listener}`]) {
                    listeners.push(params[`${listener}`]);
                }

                return listeners;
            }

            /**
             * Selvittää käytettävän urlin
             *
             * @param {string} method metodin nimi
             * @param {string} identifier urlin nimi
             * @param {*} extraParams urlin generoinnissa tarvittavat lisäparametrit
             * @returns {string}
             */
            makeUrl({ method, identifier, extraParams }) {
                const urlFn = this.resolveUrl({ method, identifier });
                if (! urlFn) {
                    throw new Error(`${method} ${identifier} is not supported`);
                }

                const url = this.generateUrl(urlFn, extraParams);
                if (typeof url !== 'string') {
                    throw new Error(`${method} ${identifier} resolved to invalid url: '${url}'`);
                }

                return url;
            }

            /**
             * Selvittää käytettävän urlin nimen.
             *
             * @param {string} method metodin nimi
             * @param {string} identifier urlin nimi
             * @returns {string|Function}
             */
            resolveUrl({ method, identifier }) {
                // 1. try to find '<method>.<identifier>'
                if (method && params[`${method}.${identifier}`]) {
                    return params[`${method}.${identifier}`];
                }

                // 2. try to find '<identifier>'
                return params[`${identifier}`];
            }

            /**
             * Generoi urlin, jos url on funktio.
             *
             * @param {string|Function} url url tai url-generaattori
             * @param extraParams urlin generoinnissa tarvittavat lisäparametrit
             * @returns {*}
             */
            generateUrl(url, extraParams) {
                if (url instanceof Function) {
                    return url({
                        ...this.props,
                        ...extraParams,
                    });
                }

                return url;
            }

            render() {
                return (
                    <InnerComponent
                        {...this.props}
                        data={this.data}
                        error={this.error}
                        pending={this.pending}
                        get={this.get}
                        post={this.post}
                        put={this.put}
                        patch={this.patch}
                        delete={this.delete}
                        request={this.request}

                        // @deprecated
                        isLoading={this.isLoading}
                        // @deprecated
                        isSending={this.isSending}
                        // @deprecated
                        isWorking={this.isWorking}
                        isPending={this.isPending}
                    />
                );
            }
        }

        return SuoraTyoAPI;
    };
}

export default SuoraTyoAPI;
