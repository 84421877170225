import _ from 'lodash';
import { produce } from 'immer';
import alertTypes from 'shared/constants/alertTypes';

/**
 * Ilmoitukset.
 */
export default {
    state: [],

    reducers: {
        addNotification: (state, payload) => produce(state, (newState) => {
            newState.unshift(payload);
        }),
        remove: (state, payload) => produce(state, (newState) => {
            newState.splice(newState.findIndex(({ id }) => id === payload), 1);
        })
    },

    selectors: {
        get: (state) => state,
    },

    effects: () => ({
        /**
         * Lisää uuden notifikaation.
         * Häviää oletuksena 5sek kuluttua.
         * @param payload
         */
        add(payload) {
            // Jos kyseessä on virhenotifikaatio älä häivytä sitä koskaan (käyttäjän suljettava).
            const alertType = _.get(payload, 'type');
            const timeout = alertType === alertTypes.ERROR ? 0 : _.get(payload, 'timeout', 5000);

            const notification = {
                ...payload,
                timeout,
                id: new Date().getTime()
            };
            this.addNotification(notification);
        },

        addInfo(message) {
            this.add({
                type: 'info',
                message,
            });
        },

        addSuccess(message) {
            this.add({
                type: 'success',
                message,
            });
        },

        addWarning(message) {
            this.add({
                type: 'warning',
                message,
            });
        },

        addError(message) {
            this.add({
                type: 'error',
                message,
            });
        },
    })
};
