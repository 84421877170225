import { push } from 'connected-react-router';
import { actions } from 'react-redux-form/lib/immutable';
import api from 'api';

export default {
    state: {
        groups: [
            {
                id: 1,
                name: 'Placeholder',
            },
        ],
    },
    reducers: {
        setGroups: (state, payload) => ({ ...state, groups: payload }),
    },
    selectors: {
        getGroups: (state) => state.groups,
        getGroupById: (state, id) => state.groups.find((group) => group.id === id) || {},
    },
    effects: (dispatch) => ({
        async fetchGroups(isContractForm = false) {
            try {
                const json = await api.get(Routing.generate('get_personnel-groups'));
                const groups = _.get(json, 'data', []);
                this.setGroups(groups);
                // Tämä side-effect pois kun v2 roskissa
                if (isContractForm && groups.length === 1) {
                    // Jos vain yksi grouppi ja ollaan soppari lomakeella, niin valitaan automaattisesti
                    dispatch(actions.change('contract.job.personnelGroup', _.head(groups).id));
                }
            } catch (e) {
                console.error(e);
            }
        },
        async postGroups(model, rootState, route) {
            try {
                await api.post(Routing.generate('post_personnel-group'), model);
                dispatch(push(route));
                await this.fetchGroups();
            } catch (e) {
                console.error(e);
            }
        },
        async putGroup(model, rootState, { group, route }) {
            try {
                await api.put(Routing.generate('put_personnel-group', { group }), model);
                dispatch(push(route));
                await this.fetchGroups();
            } catch (e) {
                console.error(e);
            }
        },
        async deleteGroup(group) {
            try {
                await api.del(Routing.generate('delete_personnel-group', { group }));
                this.fetchGroups();
            } catch (e) {
                console.error(e);
            }
        }
    }),
};
