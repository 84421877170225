import React from 'react';
import PropTypes from 'prop-types';
import { mainBreakPoints } from 'shared/constants/index';
import { ConfirmButton, IconButton, Layout } from 'shared/components';

const breakpointMatches = matchMedia(`(min-width: ${mainBreakPoints.MEDIUM}px)`).matches;

/**
 * Dimensiosolu. Renderöi näytön koosta riippuen koko dimension tiedot taikka vain dimension arvon.
 *
 * @param original
 * @param onEdit
 * @param onDelete
 * @returns {*}
 * @constructor
 */
const DimensionCell = ({ original, onEdit, onDelete, isDeleting }) => {
    const id = _.get(original, 'id');
    const description = _.get(original, 'description', '-');
    const value = _.get(original, 'value');
    const additionalInfo = _.get(original, 'additionalInfo', '-');
    const allowedHours = _.get(original, 'allowedHours', '-');
    if (breakpointMatches) {
        return (
            <div>
                {value}
            </div>
        );
    }
    return (
        <div>
            <div className="u-margin-top-small">
                <div className="u-muted">{_trans('company_manager.dimensions.list.cells.value')}</div>
                <div>{value}</div>
            </div>
            <div className="u-margin-top-small">
                <div className="u-muted">{_trans('company_manager.dimensions.list.cells.description')}</div>
                <div>
                    {description}
                </div>
            </div>
            <div className="u-margin-top-small">
                <div className="u-muted">{_trans('company_manager.dimensions.list.cells.additional_info')}</div>
                <div>
                    {additionalInfo}
                </div>
            </div>
            <div className="u-margin-top-small">
                <div className="u-muted">{_trans('company_manager.dimensions.list.cells.allowed_hours')}</div>
                <div>
                    {allowedHours}
                </div>
            </div>
            <Layout.Container>
                <Layout.Item medium="1/2" small="1/2" modifierClass="u-margin-top-small">
                    <IconButton
                        size="small"
                        flat
                        mdIcon="edit"
                        onClick={() => onEdit(id)}
                    >
                        {_trans('button.edit')}
                    </IconButton>
                </Layout.Item>
                <Layout.Item medium="1/2" small="1/2" modifierClass="u-margin-top-small">
                    <ConfirmButton
                        isPending={isDeleting}
                        flat
                        mdIcon="clear"
                        onClick={() => onDelete(id)}
                        confirmText={_trans('button.are_you_sure')}
                        text={_trans('button.remove')}
                    />
                </Layout.Item>
            </Layout.Container>
        </div>
    );
};

DimensionCell.propTypes = {
    original: PropTypes.object,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    isDeleting: PropTypes.bool,
};

DimensionCell.defaultProps = {
    original: {},
    onEdit() {},
    onDelete() {},
    isDeleting: false,
};

export default DimensionCell;
