import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SummaryListItem } from './SummaryListItem';

/**
 * Summary list.
 * @param list
 * @param children
 * @param hasBorder
 * @param termWidth
 * @returns {JSX.Element}
 * @constructor
 */
const SummaryList = ({ list, children, hasBorder, termWidth }) => (
    <ul
        className={classNames('o-summary-list o-list-bare', {
            'o-summary-list--borderless': !hasBorder,
        })}
    >
        {list.length > 0
            ? list.map((item, key) => {
                const term = item.term ?? '';
                const description = item.description ?? null;

                if (term !== '') {
                    return (
                        <SummaryListItem
                            key={key}
                            term={term}
                            termWidth={termWidth}
                        >
                            {description}
                        </SummaryListItem>
                    );
                }
            })
            : children}
    </ul>
);

SummaryList.propTypes = {
    /**
     * List of objects: { term: 'Lorem ipsum', description: 'Dolor sit amet' }.
     */
    list: PropTypes.array,
    /**
     * If children given they're expected to be a list of SummaryListItem components.
     */
    children: PropTypes.node,
    hasBorder: PropTypes.bool,

    /**
     * Width of the term. Either in pixels or as CSS-class (see _utilities.widths.scss).
     */
    termWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SummaryList.defaultProps = {
    list: [],
    children: null,
    hasBorder: true,
    termWidth: null,
};

export default SummaryList;
