import { produce } from 'immer';
import api from 'api';
import { sortBy } from 'shared/utils/sortUtils';

/**
 * Kirjautuneen käyttäjän asiakkaat.
 * @type {{effects: (function(): {fetchCustomers({limit?: *}): Promise<void>}), reducers: {setCustomers: (function(*, *): *)}, state: *[], selectors: {getCustomers: (function(*): *), getCustomer: (function(*, *): *)}}}
 */
export const customers = {
    state: [],

    reducers: {
        // Asetetaan asiakkaat
        setCustomers: (state, payload) => payload,

        // Lisätään uusi asiakas listaan
        addCustomer: (state, payload) => ([
            ...state,
            payload,
        ]),

        // Päivitetään asiakkaan tiedot
        updateCustomer: (state, payload) => produce(state, (draftState) => {
            const userId = payload.userId;
            const index = draftState.findIndex((employee) => employee.userId === userId);
            if (index >= 0) {
                draftState[index] = payload;
            }
        }),
    },

    selectors: {
        getCustomers: (state) => state,
        getCustomerById: (state, id) => {
            const userId = parseInt(id, 10);
            return state.find((customer) => customer.userId === userId);
        },
    },

    effects: () => ({
        /**
         * Hakee käyttäjän asiakkaat.
         *
         * @param limit
         * @returns {Promise<void>}
         */
        async fetchCustomers({ limit = 1000 }) {
            try {
                const json = await api.get(`/api/v2/users?limit=${limit}&type=1`);
                const customers = sortBy((json.data ?? []), 'fullName');

                this.setCustomers(customers);
            } catch (e) {
                console.error(e);
            }
        },
    })
};
