import React from 'react';
import { select } from '@rematch/select';
import { useSelector } from 'react-redux';
import Feedback from 'shared/components/Feedback';
import { isCommune } from 'shared/utils/commonUtils';
import { Placeholder } from 'shared/components';

/**
 * Kertoo avoimen maksuerän puuttumisesta tai sen löytyessä tarjoaa suoran linkin siihen.
 * @returns {JSX.Element|*}
 * @constructor
 */
export const OpenOfflinePaymentFeedback = () => {
    const offlinePaymentId = useSelector(select.surcharges.getOfflinePaymentId);
    const hasNonHandledInternalSurcharges = useSelector(select.surcharges.getNonHandledInternalSurcharges).length > 0;
    const isLoading = useSelector((state) => state.loading.effects.surcharges.fetchSurcharges);

    if (! offlinePaymentId && hasNonHandledInternalSurcharges) {
        return (
            <Placeholder isPending={isLoading}>
                <Feedback type="error" modifierClass="u-margin-bottom">
                    {_trans('surcharges.no_open_offline_payments')}
                </Feedback>
            </Placeholder>

        );
    }

    if (hasNonHandledInternalSurcharges) {
        return (
            <div className="u-margin-bottom">
                {_transMd('surcharges.open_offline_payment_link', {
                    url: isCommune()
                        ? `/commune/payments/show/${offlinePaymentId}`
                        : `/home/payments/payments/${offlinePaymentId}/details`,
                }, { targetBlank: true })}
            </div>
        );
    }

    return null;
};
