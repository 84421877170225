import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { select } from '@rematch/select';
import { animateScroll as scroll } from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { ActionBar, Feedback, GoBackLink, Page, StField, StLabel, SubmitButton } from 'shared/components';
import validators, { errorMessages } from 'shared/validators';
import { FormikErrors } from 'shared/components/Formik';

const initialValues = {
    name: '',
    phoneNumber: '',
    email: '',
    isTransactions: false,
    isWages: false,
    isIncomeRegister: false,
};

export const ContactForm = ({ baseRoute }) => {
    const { contactId } = useParams();
    const dispatch = useDispatch();
    const isNew = contactId === 'new';
    const contact = useSelector((state) => select.contact.getContactById(state, contactId));
    const contacts = useSelector(select.contact.getContacts);
    const hasIncomeRegisterContact = contacts.filter((c) => c.isIncomeRegister).length > 0;
    // Ihanaa ternäryä
    const isIncomeRegisterAvailable = isNew
        // Uuusissa tarkistellaan vain onko ketään tulorekisterissä
        ? ! hasIncomeRegisterContact
        : hasIncomeRegisterContact
            // Jos tulorekisteri on varattu, tutkitaan onko nimenomaan tämä käyttäjä
            ? contacts.filter((c) => c.isIncomeRegister && c.contactId === parseInt(contactId)).length === 1
            : true;

    useEffect(() => {
        // Scrollataan aina mountissa ylös
        scroll.scrollToTop({ duration: 125 });
    }, []);

    const isPutting = useSelector((state) => state.loading.effects.contact.putContact);
    const isPosting = useSelector((state) => state.loading.effects.contact.postContacts);

    /* eslint-disable jsx-a11y/label-has-associated-control */
    return (
        <Page
            heading={isNew ? _trans('contact.form.heading.new') : _trans('contact.form.heading.edit')}
        >
            <Formik
                initialValues={isNew ? initialValues : contact}
                onSubmit={(model) => isNew
                    ? dispatch.contact.postContacts(model, baseRoute)
                    : dispatch.contact.putContact(model, baseRoute)}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .required(_trans('validation.required'))
                        .max(200, errorMessages.maxLength),
                    phoneNumber: Yup.string()
                        .required(_trans('validation.required'))
                        .max(40, errorMessages.maxLength)
                        .test('isPhoneNumber', errorMessages.isPhoneNumber, validators.isPhoneNumber),
                    email: Yup.string()
                        .required(_trans('validation.required'))
                        .max(70, errorMessages.maxLength)
                        .email(errorMessages.isEmail),
                })}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="o-form o-form--responsive">
                        <StLabel htmlFor="name">
                            {_trans('userForm.fullName.label')}
                        </StLabel>
                        <StField>
                            <Field id="name" name="name" type="text" />
                            <FormikErrors name="name" />
                        </StField>
                        <StLabel htmlFor="phoneNumber">
                            {_trans('userForm.phoneNumber.label')}
                        </StLabel>
                        <StField>
                            <Field id="phoneNumber" name="phoneNumber" type="text" />
                            <FormikErrors name="phoneNumber" />
                        </StField>
                        <StLabel htmlFor="email">
                            {_trans('userForm.email.label')}
                        </StLabel>
                        <StField>
                            <Field id="email" name="email" type="text" />
                            <FormikErrors name="email" />
                        </StField>
                        <Feedback type="info">
                            {_transMd('contact.form.help', true)}
                        </Feedback>
                        <StField isLabeless modifierClass="u-margin-bottom-tiny">
                            <label>
                                <Field type="checkbox" name="isTransactions" />
                                {_trans('contact.form.transactions.label')}
                            </label>
                        </StField>
                        <StField isLabeless modifierClass="u-margin-bottom-tiny">
                            <label>
                                <Field type="checkbox" name="isWages" />
                                {_trans('contact.form.wages.label')}
                            </label>
                        </StField>
                        <StField isLabeless modifierClass="u-margin-bottom-tiny">
                            <label>
                                <Field type="checkbox" name="isIncomeRegister" disabled={! isIncomeRegisterAvailable} />
                                {_trans('contact.form.income_register.label')}
                            </label>
                            {! isIncomeRegisterAvailable && (
                                <Feedback type="info">
                                    {_trans('contact.form.income_register.error_occupied')}
                                </Feedback>
                            )}
                        </StField>
                        <StField isLabeless modifierClass="u-margin-bottom-tiny">
                            <label>
                                <Field type="checkbox" name="isBookkeeping" />
                                {_trans('Kirjanpidon yhteyshenkilö')}
                            </label>
                        </StField>
                        <ActionBar>
                            <GoBackLink
                                to={baseRoute}
                                text={_trans('link.return_without_saving')}
                            />
                            <SubmitButton isPending={isPosting || isPutting}>
                                {_trans('button.save')}
                            </SubmitButton>
                        </ActionBar>
                    </form>
                )}
            </Formik>
        </Page>
    );
};

ContactForm.propTypes = {
    baseRoute: PropTypes.string.isRequired,
};
