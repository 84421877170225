/**
 * Resolvoi arvon sen tyypin mukaan (käytännössä TES-juttuset).
 * @param name
 * @param value
 * @returns {boolean|*}
 */
export const resolveValueByType = ({ type, value }) => {
    switch (type) {
        case 'boolean':
            return !! Number(value);

        default:
            return value?.toString();
    }
};
