import React from 'react';
import { Panel, Button } from 'shared/components';

const Gdpr = () => (
    <Panel heading={_trans('user_data.gdpr.heading')}>
        {_transMd('user_data.gdpr.description', true)}

        <Button href={Routing.generate('suoratyo_gdpr_load_data')} primary>
            {_trans('user_data.gdpr.load_data')}
        </Button>
    </Panel>
);

export default Gdpr;
