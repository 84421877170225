import React from 'react';
import PropTypes from 'prop-types';

/**
 * Optimoitu lista.
 * @type {React.NamedExoticComponent<{readonly labelKey?: *, readonly valueKey?: *, readonly options?: *}>}
 */
export const Options = React.memo(({ options, valueKey, labelKey }) => (
    options.map((option, key) => {
        if (option.optGroup && Array.isArray(option.options)) {
            return (
                <optgroup label={option.optGroup} key={`${option.optGroup}${key}`}>
                    <Options options={option.options} labelKey={labelKey} valueKey={valueKey} />
                </optgroup>
            );
        } else {
            return (
                <option value={option[valueKey]} disabled={option.disabled ?? false} label={option[labelKey]} key={key} />
            );
        }
    }))
);

Options.displayName = 'Options';

Options.propTypes = {
    /**
     * Select options.
     */
    options: PropTypes.array.isRequired,
    /**
     * Key to be used for value.
     */
    valueKey: PropTypes.string,
    /**
     * Key to be used for label.
     */
    labelKey: PropTypes.string,
};

Options.defaultProps = {
    valueKey: 'value',
    labelKey: 'label',
};
