// Consts from UserRole.php
const userRoles = {
    /**
     * Työnantaja yksityispuolella
     */
    EMPLOYER: 1,

    /**
     * Työntekijä yksityispuolella
     */
    EMPLOYEE: 2,

    /**
     * Perhehoitaja, toimii hyvin samantapaisesti kuin työntekijä
     * Erona, että sopimukset joita haetaan on toimeksiantosopimuksia eikä työsopimuksia
     */
    FAMILY_CARER: 22,

    /**
     * Ei roolia. Uusi rekisteröitynyt käyttäjä saa tämän roolin. UI:lla lähinnä pyydetään valitsemaan
     * mistä lähdetään liikkeelle.
     */
    NONE: 3,

    /**
     * Uusi käyttäjä, jolla sisäänotto kesken (onboarding)
     */
    NEW: 4,

    /**
     * Kunnan varjokäyttäjä. Jokaisella kunnalla on yksi varjokäyttäjä jota käytetään rajapinoissa, jotka
     * perustuvat User entity paremetriin.
     */
    COMMUNE: 40,

    /**
     * Kunnan palkanmaksua hallitseva työntekijä
     */
    COMMUNE_OPERATOR: 50,

    /**
     * Yrityksen palkanmaksua tekevä työntekijä. Tämä rooli aktiivisena näytetään yksityispuolen sijasta
     * yrityspuolen käyttöliittymä.
     */
    COMPANY_OPERATOR: 100,

    /**
     * Voi hyväksyä tietyn perustein (esim. dimension) yrityksen työntekijöiden tunteja
     * Käyttää omaa applikaatiota.
     */
    COMPANY_APPROVER: 110,

    /**
     * Yrityksen varjokäyttäjä
     */
    COMPANY_SHADOW: 150,

    /**
     * Kirjanpitäjä. Väliaikainen rooli.
     */
    BOOKKEEP: 200,

    /**
     * Pakota roolin valinta. Eri kuin NONE sillä, että ei yritäkään arpoa oletusroolia (häkkyrähän tämä)
     */
    RESET: 999,
};

export const userRoleNames = {
    EMPLOYER: 'employer',
    EMPLOYEE: 'employee',
    FAMILY_CARER: 'family_carer',
    COMMUNE_OPERATOR: 'commune',
    COMPANY_OPERATOR: 'company',
    BOOKKEEP: 'bookkeep',
};

export const userRoleKeys = {
    [userRoles.EMPLOYER]: userRoleNames.EMPLOYER,
    [userRoles.EMPLOYEE]: userRoleNames.EMPLOYEE,
    [userRoles.FAMILY_CARER]: userRoleNames.FAMILY_CARER,
    [userRoles.COMMUNE_OPERATOR]: userRoleNames.COMMUNE_OPERATOR,
    [userRoles.COMPANY_OPERATOR]: userRoleNames.COMPANY_OPERATOR,
    [userRoles.BOOKKEEP]: userRoleNames.BOOKKEEP,
};

export const translateUserRole = function(userRole) {
    //purkka rooli logissa
    if (userRole === 0) {
        return _trans('Taustaprosessi', {}, 'common');
    }

    return userRole in userRoleKeys ? _trans(`role.${userRoleKeys[userRole]}`) : _trans('role.unknown');
};

export default userRoles;

export const getUserRoleKey = (userRole) => userRole in userRoleKeys ? userRoleKeys[userRole] : '';
