import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'shared/components/Badge';

export const BillingType = ({ value }) => (
    <Badge
        type={value ? 'yellow' : 'neutral'}
        value={value ? 'Ulkoinen' : 'Sisäinen'}
        modifierClass="u-1/1"
    />
);

BillingType.propTypes = {
    // eslint-disable-next-line react/boolean-prop-naming
    value: PropTypes.bool,
};

BillingType.defaultProps = {
    value: false,
};
