/**
 * Itse tehty palikka. Käytännössä komponentti / container.
 */
import { blocks } from 'shared/ReForm/constants/blocks';
import { customComponentNames } from 'ContractV3/constants/customComponents';

export const componentSchema = {
    type: 'object',
    properties: {
        block: { const: blocks.COMPONENT },
        label: {
            title: 'Kentän otsikko',
            '$ref': '#/definitions/translation',
        },
        tooltip: {
            title: 'Vihjeteksti',
            '$ref': '#/definitions/translation',
        },
        helpText: {
            title: 'Aputeksti',
            description: 'Aputeksti näkyy kentän yhteydessä.',
            '$ref': '#/definitions/translation',
        },
        placeholder: {
            title: 'Kentän tyhjä teksti',
            description: 'Teksti joka näytetään jos kentällä ei ole sisältöä tai mitään ei ole valittu.',
            '$ref': '#/definitions/translation',
        },
        component: {
            enum: customComponentNames
        },
    },
    required: ['block', 'component'],
    additionalProperties: false,
};
