import React from 'react';
import PropTypes from 'prop-types';
import { Value } from 'shared/components/Value';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { resolveSalaryPostFix } from 'ContractV3/utils/salaries/resolveSalaryPostFix';
import { getInitialSalaryLabel } from 'ContractV3/utils/salaries/getInitialSalaryLabel';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';

/**
 * Erityislisä. OPH-spesifinen.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InitialPersonalSalary = (props) => {
    const { label, value, values } = props;
    const salaryType = values[fieldNames.SALARY_TYPE];
    return (
        <SummaryListItem term={label || getInitialSalaryLabel(parseInt(salaryType, 10))}>
            <Value>
                {resolveSalaryPostFix(salaryType, _currency(value))}
            </Value>
        </SummaryListItem>
    );
};

InitialPersonalSalary.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
};

export default InitialPersonalSalary;
