import React from 'react';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Feedback } from 'shared/components';
import resolveUrl from 'shared/utils/resolveUrl';
import { generateRoutes } from 'ContractV3/constants/routes';
import { isCommune } from 'shared/utils/commonUtils';

const routes = generateRoutes(isCommune());

/**
 * Kertoo mistä dimensioita pääsee muokkaamaan.
 * @returns {JSX.Element|null}
 * @constructor
 */
const DimensionsFeedback = () => {
    const contractId = useSelector(select.contract.getContractId);
    const isAcceptedContract = useSelector(select.contract.isAcceptedContract);

    // Näytetään vain hyväksytyillä
    if (! isAcceptedContract) {
        return null;
    }

    return (
        <Feedback
            type="info"
            modifierClass="u-margin-bottom-small"
        >
            {_transMd('Voit muokata esiasetuksia yhteenvetonäkymän [Sopimusdimensiot-sivulta](%url%).', {
                url: resolveUrl(routes.DIMENSIONS, { contractId }),
            }, 'jobContract')}
        </Feedback>
    );
};

export default DimensionsFeedback;
