import React from 'react';
import PropTypes from 'prop-types';

function Section({ children, header }) {
    return (
        <div className="b-content">
            {header && <h3>{header}</h3>}
            <div className={header ? 'push--top' : ''}>
                {children}
            </div>
        </div>
    );
}

Section.propTypes = {
    children: PropTypes.node.isRequired,
    header: PropTypes.node,
};

export default Section;
