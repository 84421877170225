import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import SearchInput from 'shared/components/SearchInput';

export default function Filter({
    searchIcon = "search",
    filterColumn,
    filterPlaceholderText,
    filterButtonText,
    filteringOptions = [],
    columnMetadata,
    handleResultFiltering,
    handleFilterColumnChange
}) {

    const renderFilteringOptions = () => {
        if (_.isEmpty(filteringOptions)) return null;

        let filterOptionName;

        return (
            <select defaultValue={filterColumn} onChange={handleFilterColumnChange}>
                {
                    _.map(filteringOptions, (filterOption, i) => {
                        if (columnMetadata.hasOwnProperty(filterOption) && 'displayName' in columnMetadata[filterOption]) {
                            filterOptionName = columnMetadata[filterOption].displayName;
                        } else {
                            filterOptionName = filterOption;
                        }

                        return (
                            <option value={filterOption} key={i}>{filterOptionName}</option>
                        )
                    })
                }
            </select>
        );
    };

    const searchColumnClass = classNames({
        'o-layout__item u-2/3': _.isEmpty(filteringOptions)
    });

    return (
        <div className={searchColumnClass}>
            <SearchInput onKeyUp={handleResultFiltering} />
            {renderFilteringOptions()}
        </div>
    );
}

Filter.propTypes = {
    searchIcon: PropTypes.string,
    filteringOptions: PropTypes.array,
    handleResultFiltering: PropTypes.func,
    handleFilterColumnChange: PropTypes.func
};