import React from 'react';
import PropTypes from 'prop-types';
import { FormField, Field, FormikErrors, FormikDateRangeInput } from 'shared/components/Formik';

export const DateRange = ({ values }) => (
    <div className="o-stack o-stack--left o-stack--center u-margin-small">
        <Field
            type="radio"
            className="u-margin-right-small"
            name="rangeType"
            value="dateRange"
        />
        <FormikErrors name="rangeType" />
        <FormField name="dateRange">
            <FormikDateRangeInput
                id="dateRange"
                readOnly={(values.rangeType !== 'dateRange')}
            />
        </FormField>
    </div>
);

DateRange.propTypes = {
    values: PropTypes.object.isRequired,
};
