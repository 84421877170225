import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { animated, useSpring } from 'react-spring/web.cjs';
import useMenu from 'use-menu-hook';
import { usePopper } from 'react-popper/lib/cjs/index';
import menuBarItem from 'shared/shapes/menuBarItem';
import MDIcon from 'shared/components/MDIcon';
import Badge from 'shared/components/Badge';
import { defaultAnimationConfig } from 'shared/constants/animations';

const SEPARATOR_NAME = 'separator';

const hiddenStyles = {
    opacity: 0,
    transform: 'translate3d(0, -24px, 0)',
    config: defaultAnimationConfig,
};

const visibleStyles = {
    opacity: 1,
    transform: 'translate3d(0, 0px, 0)',
    config: defaultAnimationConfig,
};

/**
 * Yläpalkin navigaationappi.
 */
export const MenuBarButton = ({ id, children, menuContent, items, isSelected, selectedItemIndex, hasPadding, hasArrow, ...rest }) => {
    const { getMenuButtonProps, getMenuItemProps, getMenuProps, isExpanded } = useMenu();
    const isMenuOpen = isExpanded(id);
    const [style, set] = useSpring(() => (isMenuOpen ? visibleStyles : hiddenStyles));
    useEffect(() => {
        set(isMenuOpen ? visibleStyles : hiddenStyles);
    }, [set, isMenuOpen]);

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles } = usePopper(referenceElement, popperElement, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 9],
                }
            },
        ]
    });

    return (
        <li
            role="none"
            ref={setReferenceElement}
            className="u-position-relative"
        >
            <button
                className={classNames('c-menu-bar__item', {
                    'u-padding-horizontal-small': hasPadding,
                    'c-menu-bar__item--selected': isSelected,
                })}
                role="menuitem"
                {...rest}
                {...getMenuButtonProps({ id })}
            >
                {children}
                { hasArrow && (
                    <MDIcon
                        size="small"
                        icon="keyboard_arrow_down"
                        modifierClass="c-menu-bar__item-arrow"
                    />
                )}
            </button>
            <div
                aria-hidden={!isMenuOpen}
                ref={setPopperElement}
                style={{
                    ...styles.popper,
                    zIndex: isMenuOpen ? 1 : -1,
                }}
                className={isMenuOpen ? '' : 'u-pointer-events-none'}
            >
                <animated.ul
                    className="c-menu o-list-bare u-margin-none"
                    style={{
                        ...style,
                        minWidth: '220px',
                        maxWidth: '320px',
                        visibility: isMenuOpen ? 'visible' : 'hidden',
                    }}
                    {...getMenuProps({
                        labelledBy: id,
                        //ref,
                    })}
                >
                    {menuContent}
                    {items.map((item, index) => {
                        const key = `${id}${index}`;

                        if (item === SEPARATOR_NAME) {
                            return (
                                <li role="separator" key={key} />
                            );
                        }

                        const icon = item.icon;
                        const label = item.label;
                        const href = item.href ?? '#';
                        const badge = item.badge;

                        return (
                            <li key={key} role="none">
                                <a
                                    href={href}
                                    {...getMenuItemProps({ id: `${id}${index}` })}
                                    className={classNames('c-menu__item u-text-no-wrap', {
                                        'c-menu__item--admin': item.isAdmin,
                                        'c-menu__item--selected': selectedItemIndex === index,
                                    })}
                                >
                                    {icon && (
                                        <MDIcon
                                            icon={icon}
                                            size="small"
                                            modifierClass="u-margin-right-tiny"
                                        />
                                    )}
                                    {label}
                                    { badge && (<Badge value={badge} modifierClass="u-margin-left-tiny" />)}
                                </a>
                            </li>
                        );
                    })}
                </animated.ul>
            </div>
        </li>
    );
};

MenuBarButton.propTypes = {
    /**
     * Uniikki yksilöivä tunniste valikkonapille ja valikolle.
     */
    id: PropTypes.string.isRequired,

    /**
     * Linkin/napin sisältö. Yleensä tekstiä.
     */
    children: PropTypes.node.isRequired,

    /**
     * Valikon sisältö. Jos myös items annettu tulee ennen tätä.
     */
    menuContent: PropTypes.node,

    /**
     * Mahdollinen lista. Luo alasvetovalikon.
     */
    items: PropTypes.arrayOf(menuBarItem),

    /**
     * Onko paddingia.
     */
    hasPadding: PropTypes.bool,

    /**
     * Näytetäänkö alaspäinnuoli.
     */
    hasArrow: PropTypes.bool,

    isSelected: PropTypes.bool,

    /**
     * Valikon valittu index.
     */
    selectedItemIndex: PropTypes.number,
};

MenuBarButton.defaultProps = {
    menuContent: null,
    items: [],
    hasPadding: true,
    hasArrow: true,
    isSelected: false,
    selectedItemIndex: -1,
};

MenuBarButton.displayName = 'MenuBarButton';
