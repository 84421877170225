import React from 'react';
import PropTypes from 'prop-types';
import { select } from '@rematch/select';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'shared/containers';
import { ConfirmButton, LinkIcon, MDIcon, MDSpinner, Page } from 'shared/components';
import { resolveUrl } from 'shared/utils';

const filterOptions = [
    {
        label: '✔️',
        value: true,
    },
    {
        label: '❌',
        value: false,
    }
];

export const ContactList = ({ formRoute }) => {
    const dispatch = useDispatch();
    const data = useSelector(select.contact.getContacts);
    const isLoading = useSelector((state) => state.loading.effects.contact.fetchContacts);
    const route = resolveUrl(formRoute, { contactId: 'new' });

    if (isLoading) {
        return (
            <MDSpinner wrapped />
        );
    }

    return (
        <Page
            maxWidth="huge"
            heading={_trans('contact.list.heading')}
            title={_trans('contact.list.heading')}
            actionItems={data.length > 0
                ? <LinkIcon to={route} mdIcon="person_add">{_trans('contact.list.add_new.generic')}</LinkIcon>
                : null}
        >
            <DataTable
                data={data}
                emptyDataMessage={_trans('contact.list.empty_list')}
                emptyDataCallToAction={
                    <LinkIcon
                        to={route}
                        mdIcon="person_add"
                    >
                        {_trans('contact.list.add_new.first')}
                    </LinkIcon>
                }
                emptyDataIcon="people"
                actionsColumn={<ActionsColumn route={formRoute} onRemove={(id) => dispatch.contact.deleteContact(id)} />}
                columns={[
                    {
                        Header: _trans('userForm.fullName.label'),
                        accessor: 'name',
                    },
                    {
                        Header: _trans('userForm.phoneNumber.label'),
                        accessor: 'phoneNumber',
                    },
                    {
                        Header: _trans('userForm.email.label'),
                        accessor: 'email',
                    },
                    {
                        Header: _trans('contact.list.columns.transactions'),
                        accessor: 'isTransactions',
                        Cell: BooleanCell,
                        filterType: 'checkbox',
                        filterOptions,
                    },
                    {
                        Header: _trans('contact.list.columns.wages'),
                        accessor: 'isWages',
                        Cell: BooleanCell,
                        filterType: 'checkbox',
                        filterOptions,
                    },
                    {
                        Header: _trans('contact.list.columns.income_register'),
                        accessor: 'isIncomeRegister',
                        Cell: BooleanCell,
                        filterType: 'checkbox',
                        filterOptions,
                    },
                    {
                        Header: _trans('Kirjanpidon yhteystieto'),
                        accessor: 'isBookkeeping',
                        Cell: BooleanCell,
                        filterType: 'checkbox',
                        filterOptions,
                    }
                ]}
            />
        </Page>
    );
};

ContactList.propTypes = {
    formRoute: PropTypes.string.isRequired,
};

const ActionsColumn = ({ original, route, onRemove }) => (
    <div className="o-stack o-stack--justify">
        <LinkIcon
            to={resolveUrl(route, { contactId: original.contactId })}
            mdIcon="edit"
        >
            {_trans('button.edit')}
        </LinkIcon>
        <ConfirmButton
            onClick={() => onRemove(original.contactId)}
            confirmText={_trans('button.remove')}
            text={_trans('button.remove')}
        />
    </div>
);

ActionsColumn.propTypes = {
    original: PropTypes.shape({
        contactId: PropTypes.number,
    }),
    route: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
};

ActionsColumn.defaultProps = {
    original: {},
};

const BooleanCell = ({ value }) => (
    <MDIcon
        icon={value ? 'check' : 'cancel'}
        modifierClass={value ? 'u-color-positive' : 'u-color-negative'}
    />
);

BooleanCell.propTypes = {
    // eslint-disable-next-line react/boolean-prop-naming
    value: PropTypes.bool.isRequired,
};
