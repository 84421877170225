/**
 * Palkanmaksujaksotyypit.
 * Copy á la paste feat. PayPeriodLength.php
 */
export const payPeriodLengths = {
    /**
     * Viikon palkkajakso, ei käytössä
     */
    ONE_WEEK: 1,

    /**
     * Kaksi viikkoa. Katkeilee miten sattuu kuukausiin nähden.
     * Käytössä yleisesti rakennusalalla
     */
    TWO_WEEKS: 2,

    /**
     * Kuukauden palkkajakso. Yleinen kuukausipalkalla, mutta käytetty myös tuntipalkalla
     */
    ONE_MONTH: 3,

    /**
     * Kahdesti kuussa. Jakso katkeaa 15. päivä ja kuukauden viimeinen päivä.
     */
    TWICE_A_MONTH: 5,

    /**
     * Palkkajakson pituus määräytyy palkkajaksoa luodessa. API asiakkaille annettujen päivämäärien mukaan tai
     * mahdollisesti joskus frontista "Lisää palkkajakso" toiminnolla pelkästään.
     *
     * Ei generoi automaattisesti palkkajaksoja ollenkaan.
     */
    CUSTOM: 10,
};

export const payPeriodLengthTranslationMap = {
    [payPeriodLengths.ONE_WEEK]: _trans('constant.pay_period_type.one_week'),
    [payPeriodLengths.TWO_WEEKS]: _trans('constant.pay_period_type.two_weeks'),
    [payPeriodLengths.ONE_MONTH]: _trans('constant.pay_period_type.one_month'),
    [payPeriodLengths.TWICE_A_MONTH]: _trans('constant.pay_period_type.twice_a_month'),
    [payPeriodLengths.CUSTOM]: _trans('constant.pay_period_type.custom'),
};

export const translatePayPeriodLength = (payPeriodLength) =>
    payPeriodLength in payPeriodLengthTranslationMap
        ? payPeriodLengthTranslationMap[payPeriodLength]
        : _trans('general.unknown');
