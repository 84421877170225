import React from 'react';
import PropTypes from 'prop-types';
import { MaxTextAreaField } from 'shared/components/Formik';

/**
 * Muuta sovittua.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const MiscMutualAgreement = (props) => {
    const { name, placeholder } = props;

    return (
        <MaxTextAreaField
            name={name}
            maxLength={2000}
            placeholder={placeholder}
            modifierClass="u-1/1"
            rows={5}
        />
    );
};

MiscMutualAgreement.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
};

export default MiscMutualAgreement;
