import { groupBy } from 'shared/utils/collectionHelpers';
import { sortBy } from 'shared/utils/sortUtils';

/**
 * Ryhmittelee ja laittaa valinnat aakkosjärjestykseen alasvetovalikkoa
 * varten. Olettaa että käytetään <option> ja <optGroup> elementtien kanssa.
 * @param options
 */
export const groupDropdownOptions = (options) => {
    const groupedJobTitles = Object.entries(
        // Ryhmiin
        groupBy(options, (option) => option.name)
    )
        .map(([name, options]) => ({
            optGroup: name,
            // Aakkosjärjestykseen
            options: sortBy(options, 'label')
        }));

    return sortBy(groupedJobTitles, 'optGroup');
};
