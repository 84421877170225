import { FieldArray, useFormikContext } from 'formik';
import React, { Fragment } from 'react';
import { ActionBar, Button, StField, StLabel } from 'shared/components';
import { FormikMonthSpanInput } from 'shared/components/Formik/FormikMonthSpanInput';
import { FormikErrors } from 'shared/components/Formik';

/**
 * Vapaakuukaudet
 * @returns {JSX.Element}
 * @constructor
 */
export const NoForeclosureMonthsFields = () => {
    const { values } = useFormikContext();
    const noForeclosureMonths = values.noForeclosureMonths ?? [];

    return (
        <Fragment>
            <StLabel htmlFor="noForeclosureMonths" tooltip={_trans('foreclosure.form.no_foreclosure_months.tooltip')}>
                {_trans('foreclosure.form.no_foreclosure_months.label')}
            </StLabel>
            <FieldArray
                name="noForeclosureMonths"
                render={({ remove, push }) => (
                    <StField>
                        {noForeclosureMonths.length === 0 && (
                            <span>
                                {_trans('foreclosure.form.no_foreclosure_months.empty_state.text')}
                            </span>
                        )}
                        {noForeclosureMonths.length > 0 && (
                            <ul className="o-list o-list-bare u-margin-none">
                                {noForeclosureMonths.map((noForeclosureMonth, index) => {
                                    const fieldName = `noForeclosureMonths.${index}`;
                                    return (
                                        <li key={index} className="u-margin-bottom-small">
                                            <div className="o-stack o-stack--start u-margin-bottom-tiny">
                                                <span>
                                                    <FormikMonthSpanInput name={fieldName} />
                                                </span>
                                                <span className="u-margin-left-small">
                                                    <Button
                                                        flat
                                                        mdIcon="clear"
                                                        title={_trans('button.remove')}
                                                        onClick={() => remove(index)}
                                                    />
                                                </span>
                                            </div>
                                            <FormikErrors name={fieldName} />
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                        <ActionBar>
                            <Button flat mdIcon="add" onClick={() => push({ start: '', end: '' })}>
                                {_trans('foreclosure.form.no_foreclosure_months.add_no_foreclosure_month.button')}
                            </Button>
                        </ActionBar>
                    </StField>
                )}
            />
        </Fragment>
    );
};
