import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { FormikDateInput, FormikErrors } from 'shared/components/Formik';

export const StartDateField = () => {
    const taxCards = useSelector(select.taxCards.getTaxCards);
    const usedTaxCards = useSelector(select.taxCards.getUsedTaxCards);
    const selectedTaxCard = useSelector(select.taxCards.getSelectedTaxCardId);

    // Pitää erikseen filteröidä verokorteista käytettyjen verokorttien tiedot,
    // koska bäkkäriltä tulee pelkästään käytettyjen verokorttien id:t...
    const filteredTaxCards = taxCards
        .filter((taxCard) => taxCard.taxCardId !== selectedTaxCard?.taxCardId)
        .filter((taxCard) => usedTaxCards.includes(taxCard.taxCardId));

    const usedTaxCardStartDate = (filteredTaxCards
        .sort((dateA, dateB) => dateA?.startDate > dateB?.startDate ? 1 : -1) // järjestetään päivämäärä järjestykseen
        .pop() // otetaan viimeisin käytetty verokortti
    )?.startDate; // otetaan alkupvm
    const minimumDate = new Date(usedTaxCardStartDate).setDate(new Date(usedTaxCardStartDate).getDate() + 1);

    return (
        <Fragment>
            <FormikDateInput
                name="startDate"
                minimumDate={minimumDate ? new Date(minimumDate) : new Date('1970-01-01')}
            />
            <FormikErrors name="startDate" />
        </Fragment>

    );
};
