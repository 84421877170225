import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MDIcon from 'shared/components/MDIcon';
import Spinner from 'shared/components/MDSpinner';
import Button from 'shared/components/Button';
import InputGroup from 'shared/components/InputGroup';

/**
 * Hakutekstikenttä. Lähinnä dekoratiivista tarkoitusta varten.
 *
 * Lisää tekstikentän sisään suurennuslasin ja mahdollistaa progress-tilan näyttämisen haettaessa.
 * @constructor
 */
class SearchInput extends PureComponent {
    static propTypes = {
        /**
         * Onko haku käynnissä.
         */
        isPending: PropTypes.bool,

        /**
         * Onko syöttökenttä disabloitu.
         */
        isDisabled: PropTypes.bool,

        /**
         * Placeholder teksti.
         */
        placeholder: PropTypes.string,

        /**
         * Mitä tehdään kun hakuteksti muuttuu.
         */
        onChange: PropTypes.func,

        /**
         * Mitä tehdään kun napista päästetään irti.
         */
        onKeyUp: PropTypes.func,

        /**
         * Mitä tehdään kun syöttökentässä painetaan enter.
         */
        onSearch: PropTypes.func,

        /**
         * Mitä tehdään kun syöttökenttä tyhjennetään.
         */
        onClearSearch: PropTypes.func,

        /**
         * Hakukentän näkyvä pituus.
         */
        size: PropTypes.number,

        /**
         * Hakukentän maksimikirjainmäärä
         */
        maxLength: PropTypes.number,

        /**
         * Näytetäänkö hakunappi.
         */
        hasButton: PropTypes.bool,

        /**
         * Fokusoidaanko hakukenttään automaattisesti.
         */
        hasAutoFocus: PropTypes.bool,
    };

    static defaultProps = {
        isPending: false,
        isDisabled: false,
        placeholder: _trans('Search...'),
        onChange() {},
        onKeyUp() {},
        onSearch() {},
        onClearSearch() {},
        size: 20,
        maxLength: 256,
        hasButton: true,
        hasAutoFocus: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            value: _.get(props, 'initialValue', ''),
        };

        this.searchInput = React.createRef();
    }

    componentDidMount() {
        if (this.props.hasAutoFocus) {
            this.searchInput.current.focus();
        }
    }

    /**
     * Jos ylempi komponentti on alustanut jo hakusanan käytetään sitä.
     * @param nextProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const initialValue = _.get(nextProps, 'initialValue', null);
        if (initialValue !== null) {
            this.setState({
                value: initialValue,
            });
        }
    }

    onChange = (event) => {
        this.setState({
            value: event.target.value,
        }, () => {
            this.props.onChange(this.state.value);
        });
    };

    /**
     * Jos painetaan enteriä aloitetaan haku.
     * @param event
     */
    onKeyUp = (event) => {
        if (event.keyCode === 13) {
            this.onSearch(event);
            this.props.onKeyUp(event);
        }
    };

    onSearch = (event) => {
        event.stopPropagation();
        this.props.onSearch(this.state.value);
    };

    onClearSearch = () => {
        this.setState({
            value: '',
        });
        this.props.onChange('');
        this.props.onClearSearch();
    };

    renderInput = () => {
        const {
            isPending,
            isDisabled,
            placeholder,
            size,
            maxLength,
            hasButton,
        } = this.props;

        return (
            <div className={classNames('c-search-input', {
                'has-button': hasButton,
            })}>
                <span className={classNames('c-search-input__icon o-pin o-pin--right u-margin-right-tiny u-cursor-pointer', {
                    'u-hide': this.state.value === '',
                })}>
                    <MDIcon icon="clear" onClick={this.onClearSearch} />
                </span>
                <span className={classNames('c-search-input__icon o-pin o-pin--left u-pointer-events-none', {
                    'u-margin-left-tiny': ! isPending,
                    'c-search-input__icon--spin': isPending,
                })}>
                    { (isPending && !hasButton)
                        ? <span className="u-margin-left-tiny"><Spinner size="small"/></span>
                        : <MDIcon icon="search" /> }
                </span>
                <input
                    type="search"
                    onChange={this.onChange}
                    onKeyUp={this.onKeyUp}
                    placeholder={placeholder}
                    size={size}
                    maxLength={maxLength}
                    disabled={isDisabled}
                    value={this.state.value}
                    ref={this.searchInput}
                />
            </div>
        );
    };

    render() {
        const {
            isPending,
            hasButton,
        } = this.props;

        if (! hasButton) {
            return this.renderInput();
        }

        return (
            <InputGroup>
                {this.renderInput()}
                { hasButton && (
                    <Button onClick={this.onSearch} inProgress={isPending}>
                        {_trans('button.search')}
                    </Button>
                )}
            </InputGroup>
        );
    }
}

export default SearchInput;
