import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { select } from '@rematch/select';
import { useSelector } from 'react-redux';
import { Value } from 'shared/components/Value';
import workTimeEstimationTypes, {
    companyEstimatedWorkTimeTypes,
    translateWorkTimeEstimateType,
} from 'shared/constants/workTimeEstimationTypes';
/**
 * Sopimuksen mukainen työaika.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */

const WorkTimeEstimationType = ({ value }) => {
    const isCompany = useSelector(select.userMetadata.isCompany);

    const options = useMemo(() => (
        isCompany
            ? Object.values(companyEstimatedWorkTimeTypes).map(({ id, name }) => ({
                id: String(id),
                name,
            }))
            : Object.values(workTimeEstimationTypes).map((workEstType) => ({
                id: String(workEstType),
                name: translateWorkTimeEstimateType(workEstType),
            }))
    ), [isCompany]);

    const label = (options.find((type) => parseInt(type.id, 10) === parseInt(value, 10)))?.name ?? '-';

    return (
        <Value>
            {label}
        </Value>
    );
};

WorkTimeEstimationType.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};
export default WorkTimeEstimationType;
