import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { ChoiceField } from 'shared/components/Formik/ChoiceField';
import { FormField } from 'shared/components/Formik';
import { Value } from 'shared/components/Value';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import DimensionsFeedback from 'shared/ReForm/containers/CustomBlocks/ContractV3/Summary/DimensionsFeedback';

/**
 * Dimensiopalikka joka näyttää kaikki valittavissa olevat dimensiot.
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const Dimensions = ({ attributes: { filteredDimensions } }) => {
    // Hyväksytyltä sopparilta haetaan dimensiot suoraan. Näitä ei voi enää muuttaa.
    const isAcceptedContract = useSelector(select.contract.isAcceptedContract);
    // Hyväksytyltä sopparilta haetaan dimensiot suoraan. Näitä ei voi enää muuttaa.
    const dimensionTypes = useSelector((state) => isAcceptedContract
        ? select.contract.getDimensionTypes(state)
        : select.dimensions.getDimensionTypes(state)
    );

    if (isAcceptedContract) {
        return (
            <Fragment>
                <DimensionsFeedback />
                {dimensionTypes.map((dimensionType, key) => {
                    // Sopparilta dimensio tulee eriteltynä value / description. Muutoin yhdistetty valueksi.
                    const value = isAcceptedContract
                        ? `${dimensionType.value} - ${dimensionType.description ?? ''}`
                        : dimensionType.value;

                    return (
                        <FormField
                            key={key}
                            name={`${fieldNames.DIMENSIONS}.id${dimensionType.id}`}
                            label={dimensionType.type}
                            isContentFormField={false}
                        >
                            <Value>{value}</Value>
                        </FormField>
                    );
                })}
            </Fragment>
        );
    }
    return dimensionTypes.map((dimensionType, key) => {
        const dimensionId = `id${dimensionType.id}`;
        const name = `${fieldNames.DIMENSIONS}.${dimensionId}`;
        const dimensions = (dimensionType.dimensions ?? [])
            .filter((dimension) => {
                if (dimension?.isHiddenOnList) return false;

                if (! filteredDimensions) return true;

                const dimensionTypeId = dimensionType.id?.toString();
                // Ei yhtään osumaa. Bail out.
                if (! Object.keys(filteredDimensions).includes(dimensionTypeId)) return true;

                // Muuten jätetään jäljelle vain ne dimensiot jotka listalla on annettu
                return filteredDimensions[dimensionTypeId].includes(dimension.id);
            })
            .map((dimension) => ({
                value: `${dimension.value} - ${dimension.description ?? ''}`,
                id: dimension.id.toString(),
            }));
        return (
            <FormField
                key={key}
                name={name}
                label={dimensionType.name}
                isRequired
            >
                <ChoiceField
                    options={dimensions}
                    placeholder={_trans('Valitse', {}, 'common')}
                    labelKey="value"
                    valueKey="id"
                    validate={(value) => {
                        if (! value || value === '') {
                            return _trans('Kenttä on pakollinen', {}, 'common');
                        }
                    }}
                />
            </FormField>
        );
    });
};

Dimensions.propTypes = {
    attributes: PropTypes.shape({
        // Mahdollistaa dimensiolistan filtteröinnin.
        // Key on dimension tyyppi jonka löytyessä filtteröidään dimensiolistalle vain annetut
        // dimensiot. Paikkaa räkäisesti hieman dimensioiden puurakenteen puuttumista.
        filteredDimensions: PropTypes.object,
    }),
};

Dimensions.defaultProps = {
    attributes: {
        filteredDimensions: {},
    },
};

export default Dimensions;
