import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import produce from 'immer';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { fromJS } from 'immutable';
import { useField } from 'formik';
import { select } from '@rematch/select';
import { ActionBar, GoBackLink, Page } from 'shared/components';
import UserForm from 'shared/UserDetails/containers/shared/UserForm';
import { generateRoutes } from 'ContractV3/constants/routes';
import resolveUrl from 'shared/utils/resolveUrl';
import api from 'api';
import { resolvePartyId } from 'shared/Parties/utils/resolvePartyId';
import companyUserRelationTypes from 'shared/constants/companyUserRelationTypes';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { isCommune } from 'shared/utils/commonUtils';
import MDSpinner from 'shared/components/MDSpinner';
import useMergeState from 'shared/hooks/mergeState';
import {
    UserAttachedDimensionsForm
} from 'shared/UserDetails/containers/company/UserAttachedDimensions/UserAttachedDimensionsForm';
import { FormField } from 'shared/components/Formik';
import { Value } from 'shared/components/Value';
import validators from 'shared/validators';

const routes = generateRoutes(isCommune());

const fetchUser = async (user) => {
    try {
        return await api.get(`/api/v2/users/${user}`);
    } catch (e) {
        return { status: false, };
    }
};

const resolveHeading = (isCompensationEarnerContract, partyId, isEditingUser) => {
    if (isCompensationEarnerContract) {
        return isEditingUser
            ? _trans('Muokkaa palkkionsaajan tietoja', {}, 'common')
            : _trans('Lisää uusi palkkionsaaja', {}, 'common');
    }

    return _trans(isEditingUser
        ? `contract.${partyId}.modify.heading`
        : `contract.${partyId}.new.heading`
    );
};

/**
 * Työntekijän muokkaus ja lisäys.
 * @param employeeId
 * @param arrayName
 * @param canAddAccountNumberLater
 * @returns {JSX.Element}
 * @constructor
 */
export const EmployeeForm = ({ employeeId, arrayName, canAddAccountNumberLater }) => {
    const dispatch = useDispatch();
    const [employeeField,,employeeHelpers] = useField(fieldNames.EMPLOYEE);
    const [state, setState] = useMergeState({
        showVisibilityForm: false,
        userId: null,
        ssn: null,
        user: {},
    });

    const initialPartySsn = useSelector(select.contract.getInitialPartySsn);

    const benefitDecisionId = useParams()?.benefitDecisionId;
    const isCloningContract = useSelector(select.contract.isCloningContract);
    // Kloonatessa soppari-id:t saadaan urlista
    const { contractId, contractDataId } = useParams();
    const templateId = useSelector(select.contract.getTemplateId);
    const contractUrl = `${resolveUrl(
        isCloningContract ? routes.CLONE_CONTRACT : routes.NEW_CONTRACT,
        { benefitDecisionId, contractId, contractDataId }
    )}?template=${templateId}`;

    const isAssignmentContract = useSelector(select.userMetadata.allowAssignmentContracts);
    const isCompensationEarnerContract = useSelector(select.contract.isCompensationEarnerContract);
    const visibilityDimensions = useSelector(select.dimensions.getVisibilityDimensions);
    const visibilityDimensionTypeName = useSelector(select.dimensions.getVisibilityDimensionTypeName);
    const [userContracts, setUserContracts] = useState([]);

    const userRole = useSelector(select.userMetadata.getUserRole);
    const isCompany = useSelector(select.userMetadata.isCompany);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isEmployer = useSelector(select.userMetadata.isEmployer);
    const initialUser = useSelector((state) => select.employees.getEmployeeById(state, employeeId));
    const isLoading = useSelector((state) => (
        state.loading.effects.employees.fetchEmployees
        || state.loading.effects.userMetadata.fetchUserMetadata
    ));
    const jobTitles = useSelector(select.jobTitles.getJobTitles);
    const isVtjEnabled = useSelector(select.userMetadata.isVtjEnabled);
    const isMultipleWorkExpLimitsApplied = useSelector(select.communeSettings.isMultipleWorkExpLimitsApplied);
    // Muokataanko henkilöä
    const isEditingUser = ! isNaN(employeeId) && !! initialUser;

    const onUserNotVisibilityAccess = useCallback((userId, ssn) => {
        if (visibilityDimensions.length === 1) {
            dispatch.userAttachedDimensions.postUserAttachedDimensions(
                {
                    user: userId,
                    model: {
                        dimensions: visibilityDimensions.map((dim) => dim.id),
                    },
                }
            ).then((json) => {
                if (json.status === 'ok') {
                    dispatch.notifications.addSuccess(
                        isAssignmentContract
                            ? _trans('Hoitajan tiedot on jo tallennettu palveluun. Ole hyvä ja tarkasta tiedot.', {}, 'jobContract')
                            : _trans('Työntekijän tiedot on jo tallennettu palveluun. Ole hyvä ja tarkasta tiedot.', {}, 'jobContract')
                    );
                    fetchUser(userId).then((user) => onSuccessUserUpdate(user));
                }
            });
        } else {
            setState({
                ssn,
                userId,
                showVisibilityForm: true,
                user: {},
            });
        }
    }, [dispatch.userAttachedDimensions, visibilityDimensions.length, isAssignmentContract, visibilityDimensions]);


    useEffect(() => {
        if (initialUser) {
            // Jos muokataan, haetaan sopimukset, jotta voidaan mahdollisesti päivittää niihinkin päivittyneet tiedot
            api.get(`/api/contracts/v1/users/${initialUser?.userId}/contracts`)
                .then((userContracts) => setUserContracts(userContracts));
        }
    }, [initialUser]);

    const onSuccessUserUpdate = useCallback((user) => {
        // Ei haeta partyjä uudestaan apista vaan lisätään vaan se listaan
        const employees = (employeeField.value ?? []);
        // Ota eka TT joka on tyhjä
        const emptyUserIndex = employees.findIndex((employee) => employee === '');

        if (! isEditingUser) {
            // Ei lisätä jo aiemmin lisättyä hihii
            //console.log(employees, user);
            if (employees.find((employeeUserId) => user?.userId === employeeUserId)) {
                dispatch.notifications.addInfo(_trans('Henkilö on jo lisätty sopimukselle', {}, 'jobContract'));
                dispatch(push(contractUrl));
                return;
            }

            dispatch.employees.addEmployee(user);
            // Otetaan indeksi querystringistä "arrayName" joka viittaa TT:n indeksiin (esim. employee.1)
            const arrayNamePieces = arrayName.split('.');
            const arrayIndex = arrayNamePieces.length === 2
                ? parseInt(arrayNamePieces[1], 10)
                : null;

            // Käytetään uuden TT:n indeksinä joko arrayName:sta resolvoitua indeksiä tai otetaan eka tyhjä TT joka löytyy
            const employeeIndex = typeof arrayIndex === 'number' && (arrayIndex < employees.length - 1)
                ? arrayIndex
                : emptyUserIndex;

            // Miksiköhän tässä haetaan tämä? :thinking:
            //dispatch.employeeWorkExperience.fetchEmployeeWorkExperience(user.userId);
            employeeHelpers.setValue(produce(employeeField.value, (draftEmployees) => {
                if (employeeIndex > -1) {
                    draftEmployees[employeeIndex] = user.userId;
                } else {
                    draftEmployees.push(user.userId);
                }
            }));
        } else {
            dispatch.employees.updateEmployee(user);
            const existingUserIndex = (employeeField.value ?? []).findIndex((employee) => employee === user.userId);

            // Tämä on asetettava uudelleen mikäli on valittu TT jolla on ollut puutteelliset
            // osoitetiedot on tämä tieto resetoitu ja ohjattu TT:n lomakesivulle (UserForm).
            employeeHelpers.setValue(produce(employeeField.value, (draftEmployees) => {
                if (existingUserIndex > -1) {
                    draftEmployees[existingUserIndex] = user.userId;
                } else {
                    draftEmployees[emptyUserIndex] = user.userId;
                }
            }));
        }

        dispatch(push(contractUrl));
    }, [dispatch, employeeHelpers, isEditingUser, templateId, benefitDecisionId]);

    const onUserUpdate = useCallback((user) => {
        // Jos valtuushallinta käytössä, niin tehdään taikoja
        if (visibilityDimensions.length > 0) {
            if (visibilityDimensions.length === 1) {
                dispatch.userAttachedDimensions.postUserAttachedDimensions(
                    {
                        user: user.userId,
                        model: {
                            dimensions: visibilityDimensions.map((dim) => dim.id),
                        },
                    }
                ).then((json) => {
                    if (json.status === 'ok') {
                        onSuccessUserUpdate(user);
                    }
                });
            } else {
                setState({
                    ssn: user.socialSecurityNumber,
                    userId: user.userId,
                    showVisibilityForm: true,
                    user,
                });

            }
        } else {
            // EI valtuushallintaan => suoraan valitaan henkillö
            onSuccessUserUpdate(user);
        }
    }, [onSuccessUserUpdate, setState, visibilityDimensions.length]);

    /*componentWillUnmount() {
        // Unmoountissa tyhjennetään intial tiedot, jotta jos muokataan vaikka hoidettavaa, niin ei näy vanhat tiedot
        this.props.dispatch(partiesActions.resetinitialUser());
    }*/
    if (isLoading) {
        return <MDSpinner wrapped />;
    }

    const partyId = resolvePartyId(false, isAssignmentContract, userRole);

    const { showVisibilityForm, userId, ssn } = state;
    const heading = resolveHeading(isCompensationEarnerContract, partyId, isEditingUser);

    return (
        <Page
            isBodyClear
            heading={heading}
            title={heading}
            maxWidth="medium"
        >
            <ActionBar hasMargin>
                <GoBackLink to={contractUrl}/>
            </ActionBar>
            {showVisibilityForm && userId !== null
                ? (
                    <UserAttachedDimensionsForm
                        isRequired
                        userId={userId}
                        onUserAttachedDimensionsAdd={() => Object.values(state.user).length > 0
                            ? onSuccessUserUpdate(state.user)
                            : fetchUser(userId).then((user) => onSuccessUserUpdate(user))
                        }
                    >
                        {(dimSelector) => (
                            <Fragment>
                                <p>
                                    {Object.values(state.user).length > 0
                                        ? _trans('Valitse mihin %dimensionType%-ryhmiin hoitaja liitetään. Valitse vähintään yksi.', { dimensionType: visibilityDimensionTypeName.toLowerCase() }, 'jobContract')
                                        : _trans('Hoitaja löytyi jo Oima-palvelusta. Valitse mihin %dimensionType%-ryhmiin hoitaja liitetään. Valitse vähintään yksi.', { dimensionType: visibilityDimensionTypeName.toLowerCase() }, 'jobContract')
                                    }
                                </p>
                                <FormField isContentFormField label={_trans('Henkilötunnus', {}, 'common')}>
                                    <Value>{ssn}</Value>
                                </FormField>
                                {Object.values(state.user).length > 0 && (
                                    <FormField isContentFormField label={_trans('Nimi', {}, 'common')}>
                                        <Value>{state.user.fullName}</Value>
                                    </FormField>
                                )}
                                {dimSelector}
                            </Fragment>
                        )}
                    </UserAttachedDimensionsForm>
                ) : (
                    <UserForm
                        jobTitles={jobTitles}
                        // ;_; IMMUTABLE!
                        userContracts={fromJS(userContracts)}
                        initialUser={isEditingUser ? initialUser : null}
                        initialSsn={isEditingUser ? '' : initialPartySsn ?? ''}
                        isEditMode={initialPartySsn !== '' && validators.isSsn(initialPartySsn)}
                        isCompany={isCompany}
                        isCommune={isCommune}
                        isNewUser={! isEditingUser}
                        isMultipleWorkExpLimitsApplied={isMultipleWorkExpLimitsApplied}
                        // Jos muokataan, niin nullita menee, muuten tutkitaan query parametristä
                        relationType={isEditingUser ? null : companyUserRelationTypes.RELATION_EMPLOYEE}
                        onUserUpdate={onUserUpdate}
                        onError={(error) => dispatch.notifications.addError(error)}
                        addNotification={(notification) => dispatch.notifications.add(notification)}
                        isAssignmentContract={isAssignmentContract}
                        onUserNotVisibilityAccess={onUserNotVisibilityAccess}
                        isVtjEnabled={isVtjEnabled}
                        canAddAccountNumberLater={canAddAccountNumberLater}
                        isEmployer={isEmployer}
                        benefitDecisionId={benefitDecisionId}
                        isEmployee={true}
                    />
                )}
        </Page>
    );
};

EmployeeForm.propTypes = {
    employeeId: PropTypes.number.isRequired,
    arrayName: PropTypes.string.isRequired,
    canAddAccountNumberLater: PropTypes.bool,
};

EmployeeForm.defaultProps = {
    canAddAccountNumberLater: false,
};
