import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';
import { StLabel, StField, StErrors } from 'shared/components/StForm';
import { validators } from 'shared/validators';

export default class FirstName extends PureComponent {
    render() {
        const {
            model,
            isRequired,
            hasAutoFocus,
            ...rest
        } = this.props;

        return (
            <div>
                <StLabel htmlFor="firstName" isRequired={isRequired}>
                    {_trans('userForm.firstName.label')}
                </StLabel>
                <StField>
                    <Control.text
                        {...rest}
                        /* eslint-disable-next-line jsx-a11y/no-autofocus */
                        autoFocus={hasAutoFocus}
                        model={model}
                        id="firstName"
                        size={25}
                        maxLength={25}
                        validators={{
                            isRequired: validators.isRequired,
                        }}
                    />
                    <StErrors model={model}/>
                </StField>
            </div>
        );
    }
}

FirstName.defaultProps = {
    model: '.firstName',
    hasAutoFocus: false,
    isRequired: false,
};

FirstName.propTypes = {
    model: PropTypes.string,
    hasAutoFocus: PropTypes.bool,
    isRequired: PropTypes.bool,
};
