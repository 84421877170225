import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import _ from 'lodash';
import { userActions, userSelectors } from '../../stores/user';
import DataTable from 'shared/containers/DataTable';
import Spinner from 'shared/components/MDSpinner';
import BeneficiaryFullNameSsnCell from 'Contract/CommuneContractList/components/BeneficiaryFullNameSsnCell';
import FullNameSsnCell from 'Contract/CommuneContractList/components/FullNameSsnCell';
import ContractActionsCell from 'Contract/CommuneContractList/components/ContractActionsCell';
import Page from 'shared/components/Page';
import { userAttributes } from 'shared/decorators';

@userAttributes({
    renderWhilePending: true,
})
@connect((state) => ({
    userId: userSelectors.getUserId(state),
    contracts: state.userContracts,
    ui: userSelectors.getUiProps(state, 'userContracts'),
}))
class UserContractList extends Component {

    static propTypes = {
        dispatch: PropTypes.func,
        userId: PropTypes.number,
        contracts: PropTypes.instanceOf(List),
        ui: PropTypes.instanceOf(Map),
        userAttributes: PropTypes.object,
    };

    static defaultProps = {
        dispatch() {},
        userId: -1,
        contracts: List([]),
        ui: Map({}),
        userAttributes: {},
    };

    componentDidMount() {
        this.props.dispatch(userActions.fetchUserContracts(this.props.userId, 'admin'));
    }

    render() {
        if (this.props.ui.get('isLoading', false)) {
            return (
                <Spinner wrapped/>
            );
        }

        const contracts = this.props.contracts.toJS();

        const matchFullNameOrSsn = (filter, row) => {
            const fullNameMatch = _.isEmpty(row[filter.id]) ? null : row[filter.id].toLowerCase().includes(filter.value.toLowerCase());

            const ssn = _.get(row, '_original.employeeDetails.socialSecurityNumber');
            const ssnMatch = _.isEmpty(ssn) ? null : ssn.toLowerCase().includes(filter.value.toLowerCase());

            return fullNameMatch || ssnMatch;
        };

        const columns = [
            {
                Header:  _trans('commune_contract.data_table.columns.employer'),
                accessor: 'employerDetails.fullName',
                Cell: (props) => FullNameSsnCell(props, 'employer'),
            },
            {
                Header: _trans('commune_contract.data_table.columns.beneficiary'),
                id: 'beneficiary',
                accessor: (props) => {
                    // Jos avustettava on sama kuin työnantaja ei kohdisteta filtteröintiä tälle riville.
                    const fullName = _.get(props, 'beneficiaryDetails.fullName');
                    const isSelfEmployed = _.get(props, 'beneficiaryDetails.selfEmployed');
                    return isSelfEmployed ? null : fullName;
                },
                filterMethod: matchFullNameOrSsn,
                Cell: (props) => BeneficiaryFullNameSsnCell(props),
            },
            {
                Header: _trans('commune_contract.data_table.columns.employee'),
                accessor:'currentData.employeeName',
                filterMethod: matchFullNameOrSsn,
                Cell: (props) => FullNameSsnCell(props, 'employee'),
            },
            {
                Header: _trans('commune_contract.data_table.columns.start_date'),
                accessor: 'startDate',
                type: 'date',
                width: 140,
            },
            {
                Header: _trans('commune_contract.data_table.columns.end_date'),
                accessor: 'endDate',
                type: 'date',
                defaultValue: _trans('date_range.indefinitely'),
                width: 140,
            },
            {
                Header: _trans('commune_contract.data_table.columns.termination_date'),
                id: 'state',
                filterable: false,
                sortable: false,
                accessor: 'terminationTime',
                width: 140,
                type: 'date',
            },
        ];

        return (
            <Page isBodyClear heading={_trans('commune_contract.title')}>
                <DataTable
                    data={contracts}
                    columns={columns}
                    actionsColumn={<ContractActionsCell />}
                    actionsColumnWidth={276}
                    defaultSorted={[
                        {
                            id: 'employer',
                            desc: false,
                        }
                    ]}
                    defaultPageSize={10}
                    emptyDataIcon="handshake"
                    emptyDataMessage={_trans('commune_contract.data_table.empty_data_message')}
                />
            </Page>
        );
    }
}

export default UserContractList;
