import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { scrollToError } from 'shared/utils/formUtils';
import { FormikDebugDialog } from 'shared/components/FormikDebugDialog';

const HORIZONTAL = 'horizontal';
const VERTICAL = 'vertical';
const INLINE = 'inline';
const RESPONSIVE = 'responsive';

/**
 * Formikin kanssa käytettävä komponentti joka scrollaa
 * automaattisesti ensimmäiseen lomakkeen virheeseen.
 * @param props
 * @constructor
 */
export const Form = ({ children, alignFields, hasDebugButton, modifierClass, ...rest }) => {
    const { handleSubmit, isValid, errors, isSubmitting, values, initialValues } = useFormikContext();
    const [isDebugDialogOpen, setIsDebugDialogOpen] = useState(false);

    // Bugi vai mikä mutta jostain syystä ekalla kertaa ei mennä onSubmit:iin joten
    // hoidetaan virhescrollaus näin.
    useEffect(() => {
        if (! isValid && isSubmitting) {
            scrollToError(errors);
        }
    }, [isValid, errors, isSubmitting]);

    const formClass = classNames('o-form', modifierClass, {
        'o-form--horizontal': alignFields === HORIZONTAL,
        'o-form--vertical': alignFields === VERTICAL,
        'o-form--inline': alignFields === INLINE,
        'o-form--responsive': alignFields === RESPONSIVE,
    });

    return (
        <form className={formClass} {...rest} onSubmit={(event) => handleSubmit(event)} spellCheck={false}>
            {children}
            {(__DEV__ && hasDebugButton) && (
                <Fragment>
                    <button
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setIsDebugDialogOpen(true);
                        }}
                        className="c-button c-button--admin-only c-button--small u-margin-bottom-small u-margin-left-small o-pin o-pin--left o-pin--bottom o-pin--fixed"
                    >
                        Form debug
                    </button>
                    <FormikDebugDialog
                        isOpen={isDebugDialogOpen}
                        onClose={() => setIsDebugDialogOpen(false)}
                        initialValues={initialValues}
                        values={values}
                        errors={errors}
                    />
                </Fragment>
            )}
        </form>
    );
};

Form.propTypes = {
    /**
     * Alignment type of labels and fields.
     */
    alignFields: PropTypes.oneOf([HORIZONTAL, VERTICAL, INLINE, RESPONSIVE]),
    /**
     * CSS modifier class.
     */
    modifierClass: PropTypes.string,
    children: PropTypes.node.isRequired,
    hasDebugButton: PropTypes.bool,
};

Form.defaultProps = {
    alignFields: RESPONSIVE,
    modifierClass: null,
    hasDebugButton: false,
};
