import { allBreakPoints } from 'shared/constants/breakpoints';

export const sizes = {
    NONE: 'none',
    TINY: 'tiny',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    HUGE: 'huge'
};

export const componentSizes = {
    TINY: 'tiny',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    HUGE: 'huge',
};

export const positions = [
    'auto',
    'top', 'top-start', 'top-end',
    'bottom', 'bottom-start', 'bottom-end',
    'left', 'left-start', 'left-end',
    'right', 'right-start', 'right-end',
];

export const breakpoints = [
    'small',
    'small-1',
    'medium',
    'medium-1',
    'medium-2',
    'large',
    'huge',
];

export const sideBarContentWidths = {
    'medium': allBreakPoints.MEDIUM,
    'medium-1': allBreakPoints.MEDIUM1,
    'medium-2': allBreakPoints.MEDIUM2,
    'large': allBreakPoints.LARGE,
    'huge': allBreakPoints.HUGE,
};

export const justifyTypes = {
    JUSTIFY: 'justify',
    SPREAD: 'spread',
    CENTER: 'center',
};

export const alignTypes = {
    TOP: 'top',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    LEFT: 'left',
};

export const alignments = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
};

export const stackAlignTypes = {
    START: 'start',
    RIGHT: 'right',
    END: 'end',
    LEFT: 'left',
};

export const COOKIE_BOOLEAN = {
    'false': false,
    'true': true
};

export const skillLevels = {
    1: 'skill_levels.basics',
    2: 'skill_levels.satisfactory',
    3: 'skill_levels.good',
    4: 'skill_levels.excellent',
    5: 'skill_levels.mother_tongue'
};

export const relationRoles = {
    /**
     * Asiakas on avustettava. Jos avustettava toimii itse työnantajana tulee hänelle myös
     * työnantaja rooli.
     */
    RELATION_BENEFICIARY: 1,

    /**
     * Asiakas on työnantaja.
     */
    RELATION_EMPLOYER: 2,

    /**
     * Asiakas on työntekijä. Huom: oltava sama kuin yrityksen työntekijän CompanyUserRelation::RELATION_EMPLOYEE
     */
    RELATION_EMPLOYEE: 3,
};

export const locales = {
    'fi': Translator.trans('locales.fi'),
    'en_US': Translator.trans('locales.en'),
    'sv_FI': Translator.trans('locales.sv_fi')
};

/**
 * State types
 */
export const stateTypes = {
    PRIMARY: 'primary',
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error'
};

/**
 * Note types
 * @type {number}
 */
export const NOTE_BENEFIT_DECISION = 1;
export const NOTE_JOB_CONTRACT = 2;
