import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { select } from '@rematch/select';
import { IconButton } from 'shared/components';

const PostingActionsColumn = ({ posting, dispatch, isDeleting }) => {
    if (posting.has('id')) {
        return (
            <IconButton
                inProgress={isDeleting}
                onClick={() => dispatch.bookkeepingAccount.deletePosting(posting)}
                negative
                mdIcon="clear"
                minDeviceWidth={768}
            >
                {_trans('company_manager.bookkeeping_accounts.table.postings.delete_posting')}
            </IconButton>
        );
    }
    return null;
};

PostingActionsColumn.propTypes = {
    dispatch: PropTypes.func,
    posting: PropTypes.instanceOf(Map),
    isDeleting: PropTypes.bool,
};

PostingActionsColumn.defaultProps = {
    dispatch() {},
    posting: Map(),
    isDeleting: false,
};

const mapStateToProps = (state, props) => ({
    posting: select.bookkeepingAccount.getPostingByIncomeType(state, _.get(props, 'original.incomeType', null)),
    isDeleting: _.get(state, 'loading.effects.bookkeepingAccount.deletePosting', false),
});

export default connect(mapStateToProps)(PostingActionsColumn);
