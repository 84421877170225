import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

export default function ResultsPerPage({
    useFiltering,
    resultsPerPage,
    resultsPerPageText,
    onResultsPerPageUpdate
}) {
    let options = [];

    for (let i = 1; i <= 10; i++) {
        const value = (i*10).toString();

        options.push({
            id: value,
            name: value,
        });
    }

    const containerClass = classNames('o-layout__item u-text-right', {
        'u-1/3 ': useFiltering,
    });

    return (
        <div className={containerClass}>
            {resultsPerPageText}{' '}
            <select
                defaultValue={resultsPerPage}
                onChange={onResultsPerPageUpdate}
            >
                {_.map(options, (option) => (
                    <option value={option.id} key={option.id}>
                        {option.name}
                    </option>
                ))}
            </select>
        </div>
    );
}

ResultsPerPage.propTypes = {
    useFiltering: PropTypes.bool,
    resultsPerPage: PropTypes.number.isRequired,
    resultsPerPageText: PropTypes.string,
    onResultsPerPageUpdate: PropTypes.func,
    containerClass: PropTypes.string,
    textContainerClass: PropTypes.string,
    selectClass: PropTypes.string
};