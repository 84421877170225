import React from 'react';
import PropTypes from 'prop-types';
import { BooleanValue } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Summary/shared/BooleanValue';


/**
 * Työaika autonomia valinta..
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */

const WorkTimeAutonomy = ({ name, value }) => <BooleanValue name={name} value={value} />;

WorkTimeAutonomy.propTypes = {
    name: PropTypes.string.isRequired,
    /* eslint-disable-next-line */
    value: PropTypes.bool.isRequired,
};

export default WorkTimeAutonomy;
