import { useRef, useEffect } from 'react';
import setDocumentTitle from 'shared/utils/setDocumentTitle';

// Palautetaanko unmountissa vanha title
const DEFAULT_USE_TITLE_OPTIONS = {
    restoreOnUnmount: false,
};

export const useDocumentTitle = (title, options = DEFAULT_USE_TITLE_OPTIONS) => {
    const prevTitleRef = useRef(document.title);

    useEffect(() => {
        setDocumentTitle(title);

        if (options && options?.restoreOnUnmount) {
            return () => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                document.title = prevTitleRef.current;
            };
        }
    }, [title, options]);
};

