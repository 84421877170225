import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Value } from 'shared/components/Value';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import { resolveSalaryPostFix } from 'ContractV3/utils/salaries/resolveSalaryPostFix';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { get } from 'shared/utils/get';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';

/**
 * Peruskulukorvauksen määrä.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CostReimbursement = (props) => {
    const { name, value, values, arrayName } = props;
    const salaryType = values[fieldNames.SALARY_TYPE];
    const { label } = getFieldProps(props);

    const hasCostReimbursementTableSalaries = useSelector(select.contract.hasCostReimbursementTableSalaries);
    const isPendingSalary = get(values, resolveArrayName(arrayName, fieldNames.IS_PENDING_COST_REIMBURSEMENT));

    if (! hasCostReimbursementTableSalaries) return null;

    const divisorGroups = values[fieldNames.CONTRACT_GROUP_DIVISORS] ?? [];
    const costDivisor = divisorGroups.map((employee) => ({
        userName: employee.userName,
        costDivisorPercent: employee.costDivisor,
        costDivisor: (employee.costDivisor / 100) * value
    }));

    return (
        <Fragment>
            <SummaryListItem term={label}>
                <Value ariaDescribedBy={name}>
                    {isPendingSalary
                        ? _trans('Odottaa indeksikorotuksia', {}, 'jobContract')
                        : resolveSalaryPostFix(salaryType, _currency(value))
                    }
                </Value>
            </SummaryListItem>
            {costDivisor.length > 1 && costDivisor.map((employee, key) => (
                <SummaryListItem key={key} term={employee.userName}>
                    <Value>
                        {_currency(employee.costDivisor)} ({employee.costDivisorPercent?.toFixed(0)}%)
                    </Value>
                </SummaryListItem>
            ))}
        </Fragment>
    );
};

CostReimbursement.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    arrayName: PropTypes.string.isRequired,
};

export default CostReimbursement;
