import React from 'react';
import { useSelector } from 'react-redux';
import { partiesSelectors } from 'shared/Parties/parties';
import UserBlock from 'shared/components/UserBlock';

const User = () => {
    const user = useSelector(partiesSelectors.getUser);
    const isUserPending = useSelector(partiesSelectors.isFetchingUser);

    return <UserBlock user={user.toJS()} isLoading={isUserPending}/>;
};

export default User;
