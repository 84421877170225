/**
 * Oima asiakasvaratili
 * @type {number}
 */
export const TYPE_OIMA_CUSTOMER_ACCOUNT = 1;
/**
 * Asiakkaan oma tili
 * @type {number}
 */
export const TYPE_CUSTOMER_OWN_ACCOUNT = 2;
