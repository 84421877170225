import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tab from 'vendor/react-aria-tabpanel/Tab';
import { sizes } from 'shared/constants';

export default class TabItem extends Tab {

    onTabItemClick = (event, tabId) => {
        if (this.props.useHash) {
            // Lisätään etuliite hashiin jottei hypätä (scrollata) elementin kohdalle.
            window.location.hash = `#tab-${tabId}`;
        }
    };

    render() {
        const {
            children,
            tabId,
            tabSize,
            hasIcon,
            hidden,
        } = this.props;

        if (hidden) {
            return null;
        }

        const tabContent = (content, tabState) => {
            const tabClass = classNames('c-tab-list__tab-content o-stack o-stack--vertical o-stack--center u-padding-horizontal-small u-padding-horizontal@medium', {
                'is-active': tabState.isActive,
                'c-tab-list__tab-content--has-icon': hasIcon,
                'c-tab-list__tab-content--large': tabSize === sizes.LARGE,
            });

            return (
                <div className={tabClass}>
                    {content}
                </div>
            );
        };

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
            <li className="c-tab-list__item" onClick={(event) => this.onTabItemClick(event, tabId)}>
                <Tab
                    id={tabId}
                    className="c-tab-list__tab"
                >
                    {tabContent.bind(null, children)}
                </Tab>
            </li>
        );
    }
}

TabItem.defaultProps = {
    hasIcon: false,
};

TabItem.propTypes = {
    children: PropTypes.node.isRequired,
    tabId: PropTypes.string.isRequired,
    tabSize: PropTypes.string,
    hidden: PropTypes.bool,
    hasIcon: PropTypes.bool,
};
