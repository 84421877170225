import PropTypes from 'prop-types';
import { MDIcon } from 'shared/components/index';

/**
 * Yksinkertainen nappi jolla alapalkki saadaan auki ja kiinni.
 * @param isOpen
 * @param onClick
 * @returns {*}
 * @constructor
 */
function BottomBarToggle({ isOpen, onClick, }) {
    return (
        <button
            className="o-pin o-pin--top c-bottom-bar__toggle u-font-size-0"
            onClick={onClick}
            aria-label={ isOpen ? _trans('button.close_summary') : _trans('button.open_summary') }
        >
            <MDIcon icon={ isOpen ? 'expand_more' : 'expand_less' } size="tiny" />
        </button>
    );
}

BottomBarToggle.propTypes = {
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
};

BottomBarToggle.defaultProps = {
    isOpen: false,
    onClick() {},
};

export default BottomBarToggle;
