import React from 'react';
import PropTypes from 'prop-types';
import { BooleanValue } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Summary/shared/BooleanValue';


/**
 * Onko työsuhteella koeaikaa.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */

const HasTrialPeriod = (props) => {
    const { value, name } = props;

    return (
        <BooleanValue name={name} value={value} />
    );
};
HasTrialPeriod.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    inputLabel: PropTypes.string.isRequired,
    /* eslint-disable-next-line */
    value: PropTypes.bool.isRequired,
};

export default HasTrialPeriod;
