import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useDidUpdate } from '@mantine/hooks';
import { Party } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Parties/Party';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import companyUserRelationTypes from 'shared/constants/companyUserRelationTypes';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { generateRoutes } from 'ContractV3/constants/routes';
import commonUtils, { isCommune } from 'shared/utils/commonUtils';
import resolveUrl from 'shared/utils/resolveUrl';
import { ContractStateBadge } from 'ContractV3/components/ContractStateBadge';
import { Dialog } from 'shared/components/Dialog';
import Button from 'shared/components/Button';
import ActionBar from 'shared/components/ActionBar';
import { List, ListItem } from 'shared/components/List';
import Feedback from 'shared/components/Feedback';
import { usePrevious } from 'shared/hooks/previous';

const routes = generateRoutes(isCommune());

/**
 * Asiakkaiden valitsinpalikka.
 * Voi myös antaa "readonlynä" jolloin arvo on esivalittu eikä sitä voi vaihtaa.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const Customer = (props) => {
    const { name, placeholder, attributes, hasChildrenOnly, omittedUserIds } = props;
    const { translations } = attributes;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [existingContracts, setExistingContracts] = useState([]);
    const [checkedEmployees, setCheckedEmployees] = useState([]);

    const [employeeField] = useField(fieldNames.EMPLOYEE);
    const [caredField,,caredHelpers] = useField(name);
    const contractCustomer = useSelector(select.contract.getCustomer);
    const contractCustomerUserId = parseInt(contractCustomer?.userId, 10);

    const dispatch = useDispatch();
    const [field,,helpers] = useField(name);
    const fieldProps = getFieldProps(props);
    const userRole = useSelector(select.userMetadata.getUserRole);
    const isNewContract = useSelector(select.contract.isNewContract);
    const isCloningContract = useSelector(select.contract.isCloningContract);

    const customerId = parseInt(field.value, 10);
    const customer = useSelector((state) => (isNewContract && contractCustomerUserId !== customerId)
        ? select.customers.getCustomerById(state, customerId)
        : select.contract.getCustomer(state));
    const isLoading = useSelector((state) => state.loading.effects.userMetadata.fetchUserMetadata);

    const employees = employeeField.value ?? [];
    const previousEmployees = usePrevious(employees);
    const caredId = parseInt(caredField.value, 10);

    useDidUpdate(() => {
        // Tarkistetaan vain sopimusta luotaessa koska myöhemmin näitä ei voi enää vaihtaa
        if (! isNewContract) return;

        // Hoitaja poistetaan
        if (previousEmployees.length > employees.length) {
            setCheckedEmployees(checkedEmployees
                .filter((employeeUserId) => ! previousEmployees.includes(employeeUserId))
            );
            return;
        }

        setExistingContracts([]);
        // TODO: Tarkista paremmin
        const newEmployee = employees.filter((employeeUserId) => (
            ! checkedEmployees.includes(parseInt(employeeUserId, 10))
            && employeeUserId !== '')
        );

        const employeeId = Number(newEmployee[0]);

        // Hoidettava ja hoitaja oltava valittuna. Lisäksi kentän nimen oltava "cared". Eikä saa olla kloonattava soppari.
        if (isNaN(caredId)
            || newEmployee.length === 0
            || name !== fieldNames.CARED
            || previousEmployees.length > employees.length
            || isCloningContract) return;

        dispatch.contract.fetchExistingContractsBetweenCaredAndEmployee({
            cared: caredId,
            employee: employeeId
        }).then((response) => {
            if (! checkedEmployees.includes(employeeId)) {
                setCheckedEmployees(checkedEmployees.concat(employeeId));
            }
            if (response.status === 'ok') {
                const result = response.data ?? [];
                setExistingContracts(result);
                setIsDialogOpen(result.length > 0);
            } else {
                dispatch.notifications.addError(_trans('Sopimusten päällekkäisyyksiä ei voitu tarkistaa. Kaikkia osapuolia ei löytynyt.', {}, 'jobContract'));
            }
        });
    }, [caredId, employees, previousEmployees, isNewContract]);

    return (
        <Fragment>
            {existingContracts.length > 0 && (
                <Feedback type="info" modifierClass="u-margin-bottom-small" size="small">
                    {_transChoice('{1} Osapuolten välillä on %count% sopimus. |]1,Inf] Osapuolten välillä on %count% sopimusta. ', existingContracts.length, {
                        count: existingContracts.length,
                    }, 'jobContract', )}
                    <button
                        className="u-color-link"
                        onClick={(event) => {
                            event.preventDefault();
                            setIsDialogOpen(true);
                        }}
                    >
                        {_trans('Näytä', {}, 'common')}
                    </button>
                </Feedback>
            )}
            <Party
                asyncEndPoint={`/api/v2/users?type=${companyUserRelationTypes.RELATION_CARED}&`}
                // Hoidettavia / avustettavia vain yksi joten yliajetaan olemassa olevat
                onSelectParty={(party) => dispatch.customers.setCustomers([party])}
                onRemoveParty={() => helpers.setValue('')}
                filterResults={(customers) => (
                    hasChildrenOnly
                        ? customers.filter((customer) => commonUtils.getAgeInYearsBySsn(customer?.socialSecurityNumber) < 18)
                        : customers)
                    .filter((customer) => ! omittedUserIds.includes(customer.userId)
                    )}
                fieldProps={{
                    ...fieldProps,
                    rootName: fieldProps?.name,
                }}
                isLoading={isLoading}
                selectedParty={customer}
                userRole={userRole}
                placeholder={placeholder}
                translations={translations}
                relationType={companyUserRelationTypes.RELATION_CARED}
            />
            <Dialog
                title={_transChoice('{1} Osapuolten välillä on sopimus|]1,Inf] Osapuolten välillä on sopimuksia', existingContracts.length, {}, 'jobContract', )}
                isOpen={isDialogOpen}
                maxWidth={480}
            >
                <div className="u-margin-bottom">
                    {_transMd(
                        _transChoice('{1} Huomioithan että hoidettavan **%caredName%** ja hoitajan **%employeeName%** välillä on jo sopimus. Voit tarkistaa sen alta.|]1,Inf] Huomioithan että hoidettavan **%caredName%** ja hoitajan **%employeeName%** välillä on jo sopimuksia. Voit tarkistaa ne alta.',
                            existingContracts.length,
                            {
                                caredName: existingContracts[0]?.caredDetails.fullName ?? '-',
                                employeeName: existingContracts[0]?.employeeDetails[0]?.fullName ?? '-',
                            }, 'jobContract')
                    )}
                </div>
                <List hasLastLine={false} modifierClass="u-margin-bottom-small">
                    {existingContracts.map((contract, key) => (
                        <ListItem key={key}>
                            <ListItem.PrimaryContent>
                                {_transMd('[%startDate% – %endDate%](%url%)', {
                                    startDate: _toLocaleDate(contract.startDate),
                                    endDate: _toLocaleDate(contract.endDate, _trans('Toistaiseksi', {}, 'common')),
                                    url: resolveUrl(routes.DETAILS_SUMMARY, {
                                        contractId: contract.jobContractId,
                                        contractDataId: contract.jobContractDataId,
                                    })
                                }, { targetBlank: true })}
                            </ListItem.PrimaryContent>
                            <ListItem.SecondaryContent>
                                <ContractStateBadge
                                    activeContractDataId={contract.activeContractDataId}
                                    contractData={contract}
                                />
                            </ListItem.SecondaryContent>
                        </ListItem>
                    ))}
                </List>
                <p>
                    {_trans('Voit jatkaa sopimuksen tekemistä tai vaihtaa hoidettavan.', {}, 'jobContract')}
                </p>
                <ActionBar>
                    <Button onClick={() => setIsDialogOpen(false)}>
                        {_trans('Jatka', {}, 'common')}
                    </Button>
                    <Button primary onClick={() => {
                        setIsDialogOpen(false);
                        caredHelpers.setValue('');
                        setExistingContracts([]);
                    }}>
                        {_trans('Vaihda hoidettava', {}, 'jobContract')}
                    </Button>
                </ActionBar>
            </Dialog>
        </Fragment>
    );
};

Customer.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    attributes: PropTypes.object,
    /**
     * Lasulla filtteröidään aikuiset listalta pois.
     */
    hasChildrenOnly: PropTypes.bool,
    /**
     * Pois filtteröitävät käyttäjät.
     */
    omittedUserIds: PropTypes.array,
};

Customer.defaultProps = {
    placeholder: '',
    attributes: {},
    hasChildrenOnly: false,
    omittedUserIds: [],
};

export default Customer;
