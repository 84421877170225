/**
 * Virheitä joita voi lentää kun hyväksytään palkkajaksoa,
 * voidaan näyttää näiden perustella jotain ohjeteksiä, esim. verokortin syöttöön.
 */
export default {
    ERROR_PREVIOUS_UNCONFIRMED_PAYPERIODS: 'previous_unconfirmed_payperiods',
    ERROR_NO_ACTIVE_TAX_CARD:'no_active_tax_card',
    ERROR_PAYPERIOD_CONFIRMED: 'payperiod_confirmed',
    ERROR_PAYPERIOD_NOT_BYPASSABLE_IF_NOT_EMPTY: 'payperiod_not_bypassable_if_not_empty',
    ERROR_PAYPERIOD_NOT_BYPASSABLE_IF_TERMINATING_PERIOD: 'payperiod_not_bypassable_if_terminating_period',
    ERROR_PAYPERIOD_NOT_CONFIRMABLE_IF_CONTRACT_TERMINATES: 'payperiod_not_confirmable_if_contract_terminates',
    ERROR_INVALID_TERMINATION_DATE: 'invalid_termination_date',
    ERROR_USER_LOCKED_PAY_FORBIDDANCE: 'user_locked_pay_forbiddance',
    ERROR_INVALID_EMAIL: 'invalid_email',
    ERROR_SICK_LEAVE_HOURS: 'sick_leave_hours',
    ERROR_TERMINATING_PAYROLL_NOT_LAST_IN_CHAIN: 'terminating_payroll_not_last_in_chain',
    ERROR_NEGATIVE_PAYABLE_NET_PAY: 'negative_payable_net_pay',
    ERROR_EMPLOYER_NOT_IDENTIFIED: 'employer_not_identified',
    ERROR_NEGATIVE_TOTAL_SALARY: 'negative_total_salary',
    ERROR_PAY_PERIOD_NOT_BYPASSABLE_VIA_CONFIRM_MULTIPLE: 'payperiod_not_bypassable_via_confirm_multiple',
    ERROR_EXCEPTION: 'exception',
};
