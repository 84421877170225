import api from 'api';
import { resolveCollectiveAgreementDefaultValues } from 'shared/utils/contractUtils';

/**
 * Valitun TES:in mukaiset valinnat.
 * @type {{effects: (function(): {fetchCollectiveAgreements({jobTitleId: *, employerId: *, quickPayment?: *}): Promise<void>}), reducers: {setCollectiveAgreements: (function(*, *): *)}, state: *[], selectors: {getCollectiveAgreements: (function(*): *)}}}
 */
export const collectiveAgreement = {
    state: [],

    reducers: {
        // Valittava TES tai TESsit
        setCollectiveAgreements: (state, payload) => payload,
    },

    selectors: {
        getCollectiveAgreements: (state) => state,
        getCollectiveAgreementById: (state, id) => {
            const collectiveAgreementId = parseInt(id, 10);
            return state.find((collectiveAgreement) => collectiveAgreement?.collectiveAgreementId === collectiveAgreementId);
        },
        /**
         * Vetää TES-variablet TES-id:n perusteella ja asettaa niille oletusarvot.
         * @param state
         * @param id
         * @returns {{}|*}
         */
        getCollectiveAgreementVariablesByCaId: (state, id) => {
            const collectiveAgreementId = parseInt(id, 10);
            const ca = state.find((collectiveAgreement) => collectiveAgreement?.collectiveAgreementId === collectiveAgreementId);
            if (! ca) return {};

            return resolveCollectiveAgreementDefaultValues(
                (ca.variables ?? []),
                {},
            );
        },
    },

    effects: () => ({
        /**
         * Hakee valitun työtehtävän mukaiset TES-valinnat.
         *
         * @param jobTitleId - valittu työtehtävän id
         * @param employerId - kuntapuolella valinnainen (työnantajan Heta-jäsenyyden resolvointi), ignoroidaan yksityispuolella
         * @param benefitDecisionId
         * @returns {Promise<void>}
         */
        async fetchCollectiveAgreements({ jobTitleId, employerId, benefitDecisionId }) {
            try {
                const response = await api.get(Routing.generate('api_1_get_contract_tes', { jobTitleId, employerId, benefitDecisionId }));
                this.setCollectiveAgreements(response);
                return response;
            } catch (e) {
                console.error(e);
            }
        },
    })
};

export const loadingSelectors = {
    // Onko TES:in lataus käynnissä
    isLoadingTES: (state) => _.get(state, 'loading.effects.collectiveAgreements.fetchCollectiveAgreements', false),
};
