import React from 'react';
import { ContactList } from './containers/ContactList';
import { ContactForm } from './containers/ContactForm';

/* eslint-disable react/display-name */
export const createSidebarRoutes = (base) => {
    const baseRoute = `${base}/contact`;
    const formRoute = `${base}/contact/contact-form/:contactId?`;

    return {
        path: baseRoute,
        title: _trans('contact.list.heading'),
        component: (props) => <ContactList {...props} formRoute={formRoute} />,
        exact: true,
        paths: [
            {
                path: formRoute,
                component: (props) => <ContactForm {...props} baseRoute={baseRoute} />,
            }
        ]
    };
};
