import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { FormField, FormikDateInput } from 'shared/components/Formik';

export const DocumentExpireDateInput = ({ label, categoryToExpiryMap, isInputHidden }) => {
    const { values, setFieldValue, touched } = useFormikContext();


    useEffect(() => {
        if (! touched.expireDate && categoryToExpiryMap[values.category] && values.expireDate !== categoryToExpiryMap[values.category]) {
            setFieldValue('expireDate', categoryToExpiryMap[values.category]);
        }
    }, [values.category]);

    if (isInputHidden ){
        return null;
    }
    return (
        <FormField name="expireDate" label={label}
            tooltip={_trans('Viimeinen voimassaolopäivä tarkoittaa päivää, jolloin dokumentti siirtyy tiedostonhallinnassa ' +
                       'vanhentuneisiin liitteisiin. Dokumentti ei kuitenkaan poistu viimeisen voimassaolopäivän jälkeen vaan poistaminen tapahtuu ' +
                       'erillisellä toiminnallisuudella dokumentinhallinassa.', {}, 'documents')}
        >
            <FormikDateInput
            />
        </FormField>
    );
};

DocumentExpireDateInput.propTypes = {
    label: PropTypes.string.isRequired,
    categoryToExpiryMap: PropTypes.object.isRequired,
    isInputHidden: PropTypes.bool.isRequired,
};
