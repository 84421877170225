// Constants from AccidentInsuranceType.php
export const accidentInsuranceTypes = {
    RING:          2,
    EXTERNAL:      3,
    OP_INDIVIDUAL: 4,
    OP_COMPANY:    11,
};

/**
 * IMPORTANT!! If you change these you must also change the database and php side!
 * TODO: Fetch these from database!!!
 */
export const accidentInsuranceInstitutions = [
    { code: 1, name: 'LähiTapiola Keskinäinen Vakuutusyhtiö' },
    { code: 2, name: 'Folksam Vahinkovakuutus Oy' },
    { code: 3, name: 'A-Vakuutus Oy' },
    { code: 4, name: 'Keskinäinen Vakuutusyhtiö Fennia' },
    { code: 5, name: 'If Vahinkovakuutusyhtiö Oy' },
    { code: 6, name: 'Pohjantähti Keskinäinen Vakuutusyhtiö' },
    { code: 7, name: 'Pohjola Vakuutus' },
    { code: 8, name: 'Redarnas Ömsesidiga ' },
    { code: 9, name: 'Keskinäinen Vakuutusyhtiö Turva' },
    { code: 10, name: 'Valion Keskinäinen Vakuutusyhtiö' },
    { code: 11, name: 'Ålands Ömsesidiga Försäkringsbolag' },
    { code: 12, name: 'Ei tiedossa' },
];

// Alasvetovalikoita varten nämä tänne
export const institutions = [
    { id: 1, name: 'LähiTapiola Keskinäinen Vakuutusyhtiö' },
    { id: 2, name: 'Folksam Vahinkovakuutus Oy' },
    { id: 3, name: 'A-Vakuutus Oy' },
    { id: 4, name: 'Keskinäinen Vakuutusyhtiö Fennia' },
    { id: 5, name: 'If Vahinkovakuutusyhtiö Oy' },
    { id: 6, name: 'Pohjantähti Keskinäinen Vakuutusyhtiö' },
    { id: 7, name: 'Pohjola Vakuutus' },
    { id: 8, name: 'Redarnas Ömsesidiga ' },
    { id: 9, name: 'Keskinäinen Vakuutusyhtiö Turva' },
    { id: 10, name: 'Valion Keskinäinen Vakuutusyhtiö' },
    { id: 11, name: 'Ålands Ömsesidiga Försäkringsbolag' },
    { id: 12, name: 'Ei tiedossa' }
];

// Alasvetovalikoita varten nämä tänne
export const types = [
    { id: accidentInsuranceTypes.RING, name: 'Kattovakuutus' },
    { id: accidentInsuranceTypes.EXTERNAL, name: 'Oma ulkoinen vakuutus' },
    { id: accidentInsuranceTypes.OP_INDIVIDUAL, name: 'Pohjolan yksilövakuutus' },
    { id: accidentInsuranceTypes.OP_COMPANY, name: 'Oiman hallitsema OP:n yritysvakuutus' },
];
