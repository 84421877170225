import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LocalForm, Control } from 'react-redux-form';
import _ from 'lodash';
import classNames from 'classnames';
import { validators } from 'shared/validators';
import { StErrors } from 'shared/components/StForm/';
import SubmitButton from 'shared/components/SubmitButton';
import MDIcon from 'shared/components/MDIcon';
import InputGroup from 'shared/components/InputGroup';
import Spinner from 'shared/components/Spinner';
import Tooltip from 'shared/components/Tooltip';
import commonUtils from 'shared/utils/commonUtils';
import confirm from 'shared/utils/confirm';

/**
 * Yleiskäyttöinen komponentti HeTun kyselyyn. Vahvistuksen callbackissa voi tehdä mitä lystää.
 */
export default class SsnQueryForm extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            lastSsn: null,
            hasSsnChanged: false,
            isValidSsn: false,
            blockVtjUpdate: false,
        };
    }

    onConfirmSsn(values) {
        const ssn = _.toUpper(values?.socialSecurityNumber);
        const blockVtjUpdate = values?.blockVtjUpdate ?? false;
        if (commonUtils.getAgeInYearsBySsn(ssn) > 100) {
            if (this.props.isAdmin) {
                confirm(_trans('userForm.socialSecurityNumber.ssn_over_100_age.title'), {
                    proceedLabel: _trans('userForm.socialSecurityNumber.ssn_over_100_age.yes_i_want'),
                    cancelLabel: _trans('button.cancel')
                }).then(
                    () => {
                        this.onConfirm(ssn, blockVtjUpdate);
                    }, () => {
                    }
                );
            } else {
                alert(_trans('Henkilötunnuksen perusteella yli 100 vuotiasta henkilöä ei voi lisätä! Ota tarvittaessa yhteyttä asiakastukeen.', {}, 'extract'));
            }
            return;
        }
        this.onConfirm(ssn, blockVtjUpdate);

    }

    onConfirm(ssn, blockVtjUpdate) {
        this.props.onConfirm(ssn, blockVtjUpdate);
        this.setState({
            lastSsn: ssn,
            hasSsnChanged: false,
            isValidSsn: true,
            blockVtjUpdate
        });
    }

    onSubmitFailed() {
        this.setState({
            isValidSsn: false,
        });
    }

    renderIcon() {
        const {
            errorMessage,
        } = this.props;

        const {
            hasSsnChanged,
            isValidSsn,
        } = this.state;

        const showErrorIcon = !hasSsnChanged && errorMessage !== '';

        const icon = showErrorIcon ? 'error' : 'check_circle';

        const iconClass = classNames('u-text-center', {
            'u-animation-bounce-in': isValidSsn === true,
            'u-color-positive': !showErrorIcon,
            'u-color-negative': showErrorIcon,
            'u-hide': isValidSsn === false
        });

        const Icon = () => (
            <MDIcon
                icon={icon}
                modifierClass={iconClass}
                size="small"
                isFilled={! showErrorIcon}
            />
        );

        if (showErrorIcon) {
            return (
                <Tooltip title={errorMessage}>
                    <Icon />
                </Tooltip>
            );
        } else return <Icon />;
    }

    render() {
        const {
            hasAutoFocus,
            isPending,
            errorMessage,
            initialSsn,
        } = this.props;

        // Jos virheviesti ei ole tyhjä ja halutaan näyttää asetetaan luokka joka ottaa vastaan
        // hiiren osoittimen / täppäyksen eventit jotta virheviestin voi halutessaan nähdä.
        const validationIndicatorClass = classNames('o-form__validation-indicator o-pin o-pin--right', {
            'o-form__validation-indicator-has-message': errorMessage !== '',
        });

        return (
            <LocalForm
                model="local"
                onSubmitFailed={(values) => this.onSubmitFailed(values)}
                onUpdate={(formValue) => {
                    const ssn = _.get(formValue, 'socialSecurityNumber.value', null);

                    this.setState({
                        hasSsnChanged: ssn !== this.state.lastSsn,
                        isValidSsn: _.get(formValue, 'socialSecurityNumber.valid', false),
                    });
                }}
                onSubmit={(values) => this.onConfirmSsn(values)}
                initialState={{
                    socialSecurityNumber: initialSsn,
                }}
            >
                <InputGroup>
                    <div className="u-position-relative">
                        <Control
                            type="search"
                            disabled={isPending}
                            model=".socialSecurityNumber"
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus={hasAutoFocus}
                            validators={{
                                isRequired: validators.isRequired,
                                isSsn: validators.isSsn,
                            }}
                            size={12}
                            className="u-text-uppercase"
                        />
                        <span className={validationIndicatorClass}>
                            { isPending ?
                                <Spinner size="small"/> :
                                this.renderIcon()
                            }
                        </span>
                    </div>
                    <SubmitButton disabled={isPending} modifierClass="u-align-top" title={_trans('Tarkistaa löytyykö henkilötunnusta Oima-palvelusta.', {}, 'common')}>
                        {_trans('Tarkista', {}, 'common')}
                    </SubmitButton>
                </InputGroup>
                <StErrors model=".socialSecurityNumber"/>

                {this.props.isVtjEnabled && <p>
                    <label>
                        <Control.checkbox
                            model=".blockVtjUpdate"
                        />
                        {_trans('Älä hae henkilön tietoja väestötietojärjestelmästä', {}, 'extract')}
                    </label>
                </p>
                }
            </LocalForm>
        );
    }

}

SsnQueryForm.defaultProps = {
    hasAutoFocus: false,
    onConfirm: () => {},
    isPending: false,
    errorMessage: '',
    initialSsn: '',
    isAdmin: false,
    isVtjEnabled: false,
};

SsnQueryForm.propTypes = {
    /**
     * Fokusoidaanko automaattisesti HeTu-kenttään.
     */
    hasAutoFocus: PropTypes.bool,

    /**
     * Mitä tapahtuu kun käyttäjä painaa hae-nappia.
     */
    onConfirm: PropTypes.func,

    /**
     * Indikoi ssn-requestia.
     */
    isPending: PropTypes.bool,

    /**
     * Mahdollinen virheviesti.
     * Viesti mahdollista näyttää esim. tooltippinä tai feedbackina.
     */
    errorMessage: PropTypes.string,

    /**
     * Valmiiksi annettu hetu.
     */
    initialSsn: PropTypes.string,

    /**
     * Onko Admin käyttäjä
     */
    isAdmin: PropTypes.bool,

    /**
     * Onko Vtj käytettävissä
     */
    isVtjEnabled: PropTypes.bool,
};
