import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    hidden: PropTypes.bool,
    children: PropTypes.node.isRequired,
    modifierClass: PropTypes.string,
};

function VisibilityContainer({
    hidden = false,
    modifierClass = '',
    children
}) {
    if (hidden) return <div />;

    return <div className={modifierClass}>{children}</div>;
}

VisibilityContainer.propTypes = propTypes;

export default VisibilityContainer;