import React from 'react';
import PropTypes from 'prop-types';

import { default as ReactBarCode } from 'react-barcode';
import moment from 'moment/moment';


/**
 * Maksuviivakoodi
 *
 * @param referenceNumber
 * @param amount
 * @param dueDate
 * @param accountNumber
 * @param modifierClass
 * @returns {*}
 * @constructor
 */
export default function BarCode({
    referenceNumber,
    amount,
    dueDate,
    accountNumber = '4183183710010665',
    modifierClass = '',
}) {

    let barcode = null;
    if (referenceNumber.length > 0) {
        // Käytetään string operaatiota, koska finanssia ei saa käsitellä liukulukuina!
        const amountParts = amount.toString().replace(',', '.').split('.');
        if (amountParts.length === 2) {
            const amount = `${amountParts[0].padStart(6, '0')}${amountParts[1].substring(2, 0).padEnd(2, '0')}`;
            const refNum = referenceNumber.padStart(20, '0');
            dueDate = moment(dueDate).format('YYMMDD');
            barcode = `4${accountNumber}${amount}000${refNum}${dueDate}`;
        }
    }

    return (
        <div className={modifierClass}>
            <ReactBarCode value={barcode}/>
        </div>
    );
}

BarCode.propTypes = {
    referenceNumber: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    dueDate: PropTypes.string.isRequired,
    accountNumber: PropTypes.string,
    modifierClass: PropTypes.string,
};

BarCode.defaultProps = {
    accountNumber: '4183183710010665',
    modifierClass: '',
};
