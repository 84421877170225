import api from 'api';

export default {
    state: [],

    reducers: {
        setPayPeriods: (state, payload) => payload,
    },

    selectors: {
        getPayPeriods: (state) => state,
    },

    effects: () => ({
        /**
         * Hakee työsopimukseen liittyvät palkkajaksot.
         * @param jobContractId
         * @param state - Palkkajakson tila.
         * @returns {Promise<void>}
         */
        async fetchJobContractPayPeriods({ jobContractId, state = null }) {
            try {
                const json = await api.get(Routing.generate('get_contract_payperiods', {
                    jobContract: jobContractId,
                    state,
                }));
                this.setPayPeriods(json.data);
            } catch (e) {
                console.error(e);
            }
        },
    })
};

export const loadingSelectors = {
    isLoadingPayperiods: (state) => _.get(state, `loading.effects.payPeriods.fetchJobContractPayPeriods`, false),
};
