import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Dropzone from 'dropzone';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/display-name
export const DropzoneUploader = forwardRef(({
    uploadUrl,
    id,
    onSuccess,
    onFailure,
    dropzoneMessage,
    customParameters,
    acceptedFiles,
    isFullHeight,
    isAutoProcessQueue,
    maxFiles,
    maxFilesizeAsMegabytes,
}, ref) => {
    const dropzoneRef = useRef(null);

    useEffect(() => {
        dropzoneRef.current = new Dropzone(
            `div#${id}`,
            {
                url: uploadUrl,
                acceptedFiles,
                dictDefaultMessage: `${dropzoneMessage} ${_trans('Maksimi tiedostokoko %maxSize%Mt', { maxSize : maxFilesizeAsMegabytes })}`,
                dictFileTooBig: _trans('Tiedosto on liian suuri ({{filesize}}Mt). Maksimi tiedostokoko: {{maxFilesize}}Mt.', {}, 'common'),
                params: customParameters,
                autoProcessQueue: isAutoProcessQueue,
                maxFiles,
                maxFilesize: maxFilesizeAsMegabytes,
            }
        );

        dropzoneRef.current.on('success', (file) => onSuccess(file));
        dropzoneRef.current.on('error', (file, message) => onFailure(file, message));
    }, []);

    useEffect(() => {
        if (dropzoneRef.current) {
            dropzoneRef.current.options.params = customParameters;
        }
    }, [customParameters]);

    useImperativeHandle((ref), () => ({
        processQueue() {
            dropzoneRef.current.processQueue();
        },
        removeAllFiles() {
            dropzoneRef.current.removeAllFiles();
        },
        addFile(file) {
            dropzoneRef.current.addFile(file);
        },
    }));

    return <div id={id} className="dropzone o-stack o-stack--center u-text-center" style={ isFullHeight ? { height: '100%' } : {}} />;
});

DropzoneUploader.propTypes = {
    uploadUrl: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func,
    dropzoneMessage: PropTypes.string,
    customParameters: PropTypes.object,
    acceptedFiles: PropTypes.string,
    isFullHeight: PropTypes.bool,
    isAutoProcessQueue: PropTypes.bool,
    maxFiles: PropTypes.number,
    maxFilesizeAsMegabytes: PropTypes.number,
};

DropzoneUploader.defaultProps = {
    customParameters: {},
    onFailure() {},
    dropzoneMessage: _trans('Pudota tiedosto tähän tai klikkaa valitaksesi tiedosto.', {}, 'common'),
    acceptedFiles: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    isFullHeight: false,
    isAutoProcessQueue: true,
    maxFiles: null,
    maxFilesizeAsMegabytes: 5,
};
