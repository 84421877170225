import { useLocation } from 'react-router-dom';
import React from 'react';

/**
 * Palauttaa querystring-objektin.
 * @returns {URLSearchParams}
 */
export const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
};
