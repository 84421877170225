import { fromJS } from 'immutable';
import api from 'api';

export default {
    state: fromJS({
        taxCardTodos: [],
    }),
    reducers: {
        setTaxCardTodos: (state, payload) => state.set('taxCardTodos', fromJS(payload)),
        removeTaxCardTodo: (state, payload) => state.set(
            'taxCardTodos',
            state.get('taxCardTodos').remove(state.get('taxCardTodos').findIndex((taxViolation) => taxViolation.get('id') === payload))
        ),
    },
    selectors: {
        getTaxCardTodos: (state) => state.get('taxCardTodos'),
    },
    effects: (dispatch) => ({

        /**
         * Hakee todot ja liittää palkkajaksot jossa tyyppi "TAX_CARD_UPDATE_NEEDS_ACTIONS
         *
         * @returns {Promise<void>}
         */
        async fetchTaxCardTodos() {
            try {
                const json = await api.get('/api/v2/todos/taxCardTodos');
                this.setTaxCardTodos(json.data);
            } catch (e) {
                dispatch.notifications.addError(_trans('Ongelma verokorttien ilmoitusten hakemisessa'));
                console.error(e);
            }
        },

        /**
         * Muuttaa todon tehdyksi jolloin ei aiheuta enää notifikaatiota
         *
         * @param todo
         * @returns {Promise<void>}
         */
        async postResolveTaxCardTodo(todo) {
            try {
                await api.post(`/api/v2/todos/${todo}/resolve`);
                this.removeTaxCardTodo(todo);
                dispatch.notifications.addSuccess(_trans('Ilmoitus sivuutettu'));
            } catch(e) {
                dispatch.notifications.addError(_trans('Ongelma ilmoituksen sivuuttamisessa'));
                console.error(e);
            }
        },

        async postResolveMultipleTaxCardTodos(todoArray) {
            try {
                const response = await api.post('/api/v2/todos/resolve_multiple', todoArray);
                response.data.items.map((todo) => {
                    this.removeTaxCardTodo(todo.todoId);
                });
                dispatch.notifications.addSuccess(_trans('Ilmoitukset sivuutettu'));
            } catch(e) {
                dispatch.notifications.addError(_trans('Ongelma ilmoituksien sivuuttamisessa'));
                console.error(e);
            }
        },
    })
};
