import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxField, FormField } from 'shared/components/Formik';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';

/**
 * Lähisukulaisuus valinta.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */

const IsEmployeeRelated = (props) => {
    const { name, inputLabel } = props;

    return (
        <FormField {...getFieldProps(props)}>
            <CheckboxField name={name} label={inputLabel} />
        </FormField>
    );
};
IsEmployeeRelated.propTypes = {
    name: PropTypes.string.isRequired,
    inputLabel: PropTypes.string.isRequired,
};
export default IsEmployeeRelated;
