import React from 'react';
import { getIn, useFormikContext } from 'formik';

export const Total = () => {
    const { values } = useFormikContext();

    // Stringit numeroiksi
    const unitCount = _numberParser(getIn(values, 'unitCount', '0'));
    const unitPrice = _numberParser(getIn(values, 'unitPrice', '0'));

    const total = parseFloat(unitCount) * parseFloat(unitPrice);
    const isTotalInvalid = isNaN(total) || unitPrice < 0;

    return (
        <span className={total < 0 ? 'u-color-negative' : 'u-color-default'}>
            { isTotalInvalid ? '-' : _currency(total) }
        </span>
    );
};

