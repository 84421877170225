import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { rolePages } from '../constants';
import { RoleCardList } from './RoleCardList';
import { RoleCard } from './RoleCard';
import userRoles from 'shared/constants/userRoles';
import { RoleSelectorHeading } from 'shared/containers/PageHeader/containers/RoleSelectorHeading';

/**
 * Pääroolilista.
 * @returns {*}
 * @constructor
 */
export const RoleList = () => {
    const target = useSelector(select.roleSelector.getTarget);
    const isHomeTarget = useSelector(select.roleSelector.isHomeTarget);
    const companies = useSelector(select.roleSelector.getCompanies);
    const communes = useSelector(select.roleSelector.getCommunes);
    const hasSelectedRole = useSelector(select.header.hasSelectedRole);
    const selectableRoles = useSelector(select.roleSelector.getSelectableRoles);

    return (
        <Fragment>
            <RoleSelectorHeading
                id="list-heading"
                heading={_trans(hasSelectedRole ? 'user_menu.change_role' : 'role_selector.heading')}
                labelProps={{}}
            />
            <ul className="o-list-bare c-role-selector" aria-labelledby="list-heading">
                {communes.length > 0 && (
                    <RoleCardList
                        target={isHomeTarget ? null : target}
                        items={communes}
                        iconSize={48}
                        listPageId={rolePages.COMMUNE_LIST}
                        userRole={userRoles.COMMUNE_OPERATOR}
                    />
                )}
                {companies.length > 0 && (
                    <RoleCardList
                        target={isHomeTarget ? target : null}
                        items={companies}
                        iconSize={48}
                        listPageId={rolePages.COMPANY_LIST}
                        userRole={userRoles.COMPANY_OPERATOR}
                    />
                )}
                {selectableRoles.map((userRole, key) => (
                    <RoleCard
                        key={key}
                        target={isHomeTarget ? target : null}
                        iconSize={48}
                        userRole={userRole}
                        roleName={userRole === userRoles.EMPLOYER ? _trans('title.domestic') : ''}
                    />
                ))}
            </ul>
        </Fragment>
    );
};
