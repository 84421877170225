export const backgroundJobStates = {
    OPEN: 1,
    CLOSED: 2,
    IN_PROGRESS: 3,
    FAILED: 4,
    SUCCESSFUL: 5,
};

export const openBackgroundJobStates = [
    backgroundJobStates.OPEN,
    backgroundJobStates.IN_PROGRESS,
];

export const failedBackgroundJobStates = [
    backgroundJobStates.FAILED,
];

export const successfulBackgroundJobStates = [
    backgroundJobStates.SUCCESSFUL,
];

