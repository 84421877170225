import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/Button';
import InputGroup from 'shared/components/InputGroup';

/**
 * Pääsääntöisesti pienten numeroarvojen syöttöön tarkoitettu komponentti.
 * Hyväksyy vain numeroita, filtteröi muut pihalle.
 * @param props
 * @returns {*}
 * @constructor
 */
const NumberInput = (props) => {
    const {
        min,
        max,
        onChange
    } = props;

    const initialValue = props.value || 0;
    const [ value, setValue ] = useState(min > initialValue ? min : initialValue);

    return (
        <InputGroup>
            <Button
                mdIcon="remove"
                disabled={value <= min}
                onClick={() => {
                    if (value >= min) {
                        const numberValue = parseInt(value, 10);
                        if (! isNaN(numberValue)) {
                            const newValue = numberValue - 1;
                            setValue(newValue);
                            onChange(newValue);
                        }
                    }
                }}
            />
            <input
                type="text"
                inputMode="numeric"
                size={3}
                maxLength={3}
                value={value}
                className="u-text-center"
                onFocus={(event) => {
                    // Valitaan koko kentän teksti
                    event.target.select();
                }}
                /*onKeyDown={(event) => {
                    if (/^[0-9]$/i.test(event.key) === false) {
                        event.preventDefault();
                    }
                }}*/
                onChange={(event) => {
                    let filteredValue = parseInt(event.target.value.replace(/[^0-9]+/, ''), 10);
                    if (filteredValue < min) {
                        filteredValue = min;
                    }
                    if (filteredValue > max) {
                        filteredValue = max;
                    }
                    setValue(filteredValue);
                    onChange(filteredValue);
                }}
            />
            <Button
                mdIcon="add"
                disabled={value >= max}
                onClick={() => {
                    if (value <= max) {
                        const numberValue = parseInt(value, 10);
                        if (! isNaN(numberValue)) {
                            const newValue = numberValue + 1;
                            setValue(newValue);
                            onChange(newValue);
                        }
                    }
                }}
            />
        </InputGroup>
    );
};

NumberInput.defaultProps = {
    onChange() {},
    min: 0,
    max: 99,
    value: undefined,
};

NumberInput.propTypes = {
    /**
     * What happens when the value is changed.
     */
    onChange: PropTypes.func,
    /**
     * Minimum value.
     */
    min: PropTypes.number,
    /**
     * Maximum value.
     */
    max: PropTypes.number,
    /**
     * Initial value.
     */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default NumberInput;
