import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogFooter } from 'shared/components/Dialog';
import ActionBar from 'shared/components/ActionBar';
import Button from 'shared/components/Button';
import Feedback from 'shared/components/Feedback';

/**
 * Vahvistusdialogi
 * @param isShowing - tulee confirmable-metodista. Päättää näkyykö dialogi vai ei.
 * @param proceed - tulee confirmable-metodista. Kutsutaan kun dialogi suljetaan ja promised=resolved.
 * @param dismiss - tulee confirmable-metodista. Kutsutaan kun dialogi suljetaan ja promised=rejected.
 * @param cancel - tulee confirmable-metodista. Kutsutaan vain kun dialogi suljetaan.
 * @param confirmation - itse vahvistusteksti.
 * @param options - lisäargumentit, kuten title (dialogin otsikolle), cancelLabel ja proceedLabel (napeille).
 * @returns {*}
 * @constructor
 */
const ConfirmDialog = ({ isShowing, proceed, dismiss, cancel, confirmation, options }) => {
    // Oletusotsikko
    const title = options.title ?? '';
    const hasCancelLabel = options.cancelLabel !== null;
    const cancelLabel = options.cancelLabel ?? _trans('dialog.button.cancel');
    const proceedLabel = options.proceedLabel ?? false;
    const isAlert = options.alert ?? false;
    const showAlertIcon = options.showAlertIcon ?? false;

    // Jos vahvistusnapin tekstiä ei ole annettu, älä renderöi mitään.
    if (!proceedLabel) {
        console.error('ConfirmDialog is missing proceedLabel.');
        return null;
    }

    return (
        <Dialog
            isAlertDialog={isAlert}
            titleId="confirmDialogTitle"
            isOpen={isShowing}
            onClose={dismiss}
            isMounted
            maxWidth={options?.maxWidth ?? 'medium'}
            hasContentPadding
        >
            {title &&
            <p>
                <strong className="c-heading-subheading" id="confirmDialogTitle">
                    {title}
                </strong>
            </p>}
            {showAlertIcon
                ? (
                    <Feedback type="error" isOpaque>
                        {confirmation}
                    </Feedback>
                )
                : confirmation
            }
            <DialogFooter>
                <ActionBar modifierClass="c-dialog__confirm-buttons" alignItems={hasCancelLabel ? 'justify' : 'right'}>
                    {hasCancelLabel && (
                        <Button onClick={cancel}>
                            {cancelLabel}
                        </Button>
                    )}
                    <Button onClick={proceed} negative={isAlert} primary={!isAlert}>
                        {proceedLabel}
                    </Button>
                </ActionBar>
            </DialogFooter>
        </Dialog>
    );
};

ConfirmDialog.propTypes = {
    isShowing: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
    proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
    cancel: PropTypes.func, // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func, // from confirmable. call to only close the dialog.
    confirmation: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired, // arguments of your confirm function
    options: PropTypes.object // arguments of your confirm function
};
ConfirmDialog.defaultProps = {
    isShowing: false,
    proceed() {},
    cancel() {},
    dismiss() {},
    options: {}
};

const confirmable = (Component) => class extends React.Component {
    static displayName = 'Confirmable';

    displayName = 'Confirmable';

    static propTypes = {
        dispose: PropTypes.func,
        reject: PropTypes.func,
        resolve: PropTypes.func,
    };

    static defaultProps = {
        dispose() {},
        reject() {},
        resolve() {},
    };

    constructor(props) {
        super(props);

        this.state = {
            show: true,
        };
    }

    dismiss = () => {
        this.setState({
            show: false,
        }, () => {
            this.props.dispose();
        });
    };

    cancel = (value) => {
        this.setState({
            show: false,
        }, () => {
            this.props.reject(value);
        });
    };

    proceed = (value) => {
        this.setState({
            show: false,
        }, () => {
            this.props.resolve(value);
        });
    };

    render() {
        return (
            <Component
                proceed={this.proceed}
                cancel={this.cancel}
                dismiss={this.dismiss}
                show={this.state.show}
                {...this.props}
            />
        );
    }
};

export default confirmable(ConfirmDialog);
