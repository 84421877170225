import React from 'react';
import PropTypes from 'prop-types';

/**
 * Jaottelee sivulla olevat eri alaosiot omiksi ryhmikseen otsikoineen.
 * @param children
 * @param heading
 * @returns {*}
 * @constructor
 */
function PageSection({ children, heading }) {
    return (
        <div>
            {heading && <h2 className="c-heading-section">{heading}</h2>}
            {children}
        </div>
    );
}

PageSection.propTypes = {
    children: PropTypes.node.isRequired,
    header: PropTypes.node,
};

export default PageSection;
