export const receiptStates = {
    /**
     * Avoin tosite johon vielä lisätään asioita
     */
    OPEN: 'open',

    /**
     * Invalidoitu tosite. Jos uutta tietoa syötetään vanha invalidoidaan
     */
    INVALIDATED: 'invalidated',

    /**
     * Viimeinen avoin tosite muutetaan archived tilaan kun maksu on suoritettu
     */
    ARCHIVED: 'archived',
};

const receiptStateMap = {
    [receiptStates.OPEN]: _trans('Avoin'),
    [receiptStates.INVALIDATED]: _trans('Invalidoitu'),
    [receiptStates.ARCHIVED]: _trans('Suoritettu'),
};

export function translateReceiptState(receiptState) {
    return receiptState in receiptStateMap ? receiptStateMap[receiptState] : _trans('Tuntematon');
}

const receiptStateUiStates = {
    [receiptStates.OPEN]: 'neutral',
    [receiptStates.INVALIDATED]: 'yellow',
    [receiptStates.ARCHIVED]: 'positive',
};

export function mapReceiptStateToUi(receiptState) {
    return receiptState in receiptStateUiStates ? receiptStateUiStates[receiptState] : 'hollow';
}

const receiptStateTooltipMap = {
    [receiptStates.OPEN]: _trans('Avoin tosite, johon vielä mahdollisesti tehdään muutoksia'),
    [receiptStates.INVALIDATED]: _trans('Tositteen tilalle on luotu uusi ja tämä todiste on hylätty'),
    [receiptStates.ARCHIVED]: _trans('Maksu on suoritettu ja tositetta ei enään muuteta'),
};

export function translateReceiptStateTooltip(receiptState) {
    return receiptState in receiptStateTooltipMap ? receiptStateTooltipMap[receiptState] : _trans('Tuntematon');
}

export const receiptTypes = {
    /**
     * Ennuste joka luodaan tuntikirjauksen yhteydessä.
     * Kyseessä siis vielä maksamattomien tuntien arvot
     */
    PREDICTION: 'prediction',

    /**
     * Lopullinen kirjanpito
     */
    FINAL: 'final',
};

const receiptTypeMap = {
    [receiptTypes.PREDICTION]: _trans('Ennuste'),
    [receiptTypes.FINAL]: _trans('Lopullinen'),
};

export function translateReceiptType(receiptType) {
    return receiptType in receiptTypeMap ? receiptTypeMap[receiptType] : _trans('Tuntematon');
}

const receiptUiTypes = {
    [receiptTypes.OPEN]: 'neutral',
    [receiptTypes.INVALIDATED]: 'yellow',
};

export function mapReceiptTypeToUi(receiptType) {
    return receiptType in receiptUiTypes ? receiptUiTypes[receiptType] : 'hollow';
}

const receiptTypeTooltipMap = {
    [receiptTypes.PREDICTION]: _trans(' Ennuste joka luodaan tuntikirjauksen yhteydessä. Kyseessä siis vielä maksamattomien tuntien arvot'),
    [receiptTypes.FINAL]: _trans('Lopullinen kirjanpito'),
};

export function translateReceiptTypeTooltip(receiptType) {
    return receiptType in receiptTypeTooltipMap ? receiptTypeTooltipMap[receiptType] : _trans('Tuntematon');
}

export const relatedEntityTypes = {
    OFFLINE_PAYMENT: 'Suoratyo\\PaymentBundle\\Entity\\OfflinePayment',
    PAYROLL: 'Suoratyo\\AppBundle\\Entity\\Payroll',
};

const relatedEntityTypeMap = {
    [relatedEntityTypes.OFFLINE_PAYMENT]: _trans('Maksuerä'),
    [relatedEntityTypes.PAYROLL]: _trans('Palkkajakso'),
};

export function translateRelatedEntityType(relatedEntityType) {
    return relatedEntityType in relatedEntityTypeMap ? relatedEntityTypeMap[relatedEntityType] : _trans('Tuntematon');
}

const relatedEntityTypeUiTypes = {
    [relatedEntityTypes.OFFLINE_PAYMENT]: 'hollow',
    [relatedEntityTypes.PAYROLL]: 'hollow',
};

export function mapRelatedEntityTypeToUi(relatedEntityType) {
    return relatedEntityType in relatedEntityTypeUiTypes ? relatedEntityTypeUiTypes[relatedEntityType] : 'hollow';
}

const relatedEntityTypeTooltipMap = {
    [relatedEntityTypes.OFFLINE_PAYMENT]: _trans('Maksuerästä luotu kirjanpitotosite'),
    [relatedEntityTypes.PAYROLL]: _trans('Yksittäisestä palkkiosta luotu kirjanpitotosite'),
};

export function translateRelatedEntityTypeTooltip(relatedEntityType) {
    return relatedEntityType in relatedEntityTypeTooltipMap ? relatedEntityTypeTooltipMap[relatedEntityType] : _trans('Tuntematon');
}
