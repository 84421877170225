import PropTypes from 'prop-types';

/**
 * Tulostettavaan versioon tulevat allekirjoitusviivat nimenselvennyksineen.
 * @param fullName
 * @returns {JSX.Element}
 * @constructor
 */
export const SignatureOnPaper = ({ fullName }) => (
    <div className="u-visibility-print o-stack">
        <div className="c-contract-signature c-contract-signature__location">
            <div className="u-visibility-print c-contract-signature__area" />
            {_trans('Paikka', {}, 'jobContract')}
        </div>
        <div className="c-contract-signature c-contract-signature__time">
            <div className="u-visibility-print c-contract-signature__area" />
            {_trans('Aika', {}, 'jobContract')}
        </div>
        <div className="c-contract-signature c-contract-signature__signature">
            <div className="u-visibility-print c-contract-signature__area" />
            {_trans('Nimenselvennys', {}, 'jobContract')} <strong>{fullName}</strong>
        </div>
    </div>
);

SignatureOnPaper.propTypes = {
    fullName: PropTypes.string.isRequired,
};
