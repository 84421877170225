import PropTypes from 'prop-types';

export default PropTypes.shape({
    isImportedFromPreviousHolidayYear: PropTypes.bool,
    holidayDays: PropTypes.number,
    holidayDaysAvailable: PropTypes.number,
    holidayBonusDays: PropTypes.number,
    holidayBonusDaysAvailable: PropTypes.number,
    holidayDayId: PropTypes.number,
    month: PropTypes.string,
    type: PropTypes.number,
    holidayYear: PropTypes.number,
    monetaryValue: PropTypes.number,
    holidayBonusMonetaryValue: PropTypes.number,
    description: PropTypes.string,
    attributes: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.shape({
            earning_rules: PropTypes.arrayOf(PropTypes.number),
        }),
    ])
});
