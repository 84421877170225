import React from 'react';
import PropTypes from 'prop-types';
import { Value } from 'shared/components/Value';
import { resolveSalaryPostFix } from 'ContractV3/utils/salaries/resolveSalaryPostFix';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { getInitialSalaryLabel } from 'ContractV3/utils/salaries/getInitialSalaryLabel';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { get } from 'shared/utils/get';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';

/**
 * Lähtöpalkka.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InitialSalary = (props) => {
    const { label, value, values, arrayName } = props;
    const salaryType = values[fieldNames.SALARY_TYPE];
    const isPendingSalary = get(values, resolveArrayName(arrayName, fieldNames.IS_PENDING_SALARY));

    return (
        <SummaryListItem term={label || getInitialSalaryLabel(parseInt(salaryType, 10))}>
            <Value>
                {isPendingSalary
                    ? _trans('Odottaa indeksikorotuksia', {}, 'jobContract')
                    : (typeof value === 'undefined') ? '' : resolveSalaryPostFix(salaryType, _currency(value))
                }
            </Value>
        </SummaryListItem>
    );
};

InitialSalary.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    arrayName: PropTypes.string.isRequired,
};

InitialSalary.defaultProps = {
    label: null,
};

export default InitialSalary;
