import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import { HiddenField } from 'shared/components/Formik';
import Feedback from 'shared/components/Feedback';
import { fieldNames } from 'ContractV3/constants/fieldNames';

/**
 * Asettaa taulukkopalkan metan id:n.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TableSalary = ({ name }) => {
    const [startDateField] = useField(fieldNames.START_DATE);
    const metadataId = useSelector(select.tableSalary.getMetadataId);
    const isLoading = useSelector((state) => state.loading.effects.tableSalary.fetchTableSalaryMetadata);
    const hasTableSalaries = useSelector(select.contract.hasTableSalaries);
    const isAcceptedContract = useSelector(select.contract.isAcceptedContract);

    if (isLoading) return null;

    // Metadataa ei löytynyt
    if (! isAcceptedContract && metadataId === null && hasTableSalaries) {
        const date = _toLocaleDate(startDateField.value);
        return (
            <Feedback type="error">
                {_transMd('Taulukkopalkkaa ei löytynyt annetulla sopimuksen aloituspäivämäärällä **%date%**. Vaihda päivämäärä.', { date }, 'jobContract')}
            </Feedback>
        );
    }

    return (
        <HiddenField name={name} value={metadataId} />
    );
};

TableSalary.propTypes = {
    name: PropTypes.string.isRequired,
};

export default TableSalary;
