import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SvgIcon from 'shared/components/SvgIcon';
import userRoles, { getUserRoleKey } from 'shared/constants/userRoles';

/**
 * Renderöi roolin mukaisen ikonin.
 * Käytetään esim. roolin valinnassa ja headerissa indikoimaan valittua roolia.
 * @param role
 * @param size
 * @constructor
 */
export default function RoleIcon({ role, size }) {
    return (
        <span className="c-role__icon u-display-inline-block u-font-size-0">
            <SvgIcon icon={getUserRoleKey(role)} size={size} />
        </span>
    );
}

RoleIcon.propTypes = {
    role: PropTypes.oneOf(_.map(userRoles)).isRequired,
    size: PropTypes.number,
};

RoleIcon.defaultProps = {
    size: 48,
};
