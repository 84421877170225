export const reportingFormStates = {
    DRAFT: 1,
    OPEN: 2,
    CONFIRMED: 3,
    INVALIDATED: 99,
};

export const translateReportingFormState = (state) => {
    switch (state) {
        case reportingFormStates.DRAFT:
            return _trans('Luonnos', {}, 'reporting_forms');
        case reportingFormStates.OPEN:
            return _trans('Avoin', {}, 'reporting_forms');
        case reportingFormStates.CONFIRMED:
            return _trans('Vahvistettu', {}, 'reporting_forms');
        case reportingFormStates.INVALIDATED:
            return _trans('Poistettu', {}, 'reporting_forms');
        default:
            return _trans('Tuntematon', {}, 'common');
    }
};

export const getReportingFormBadgeTypeByState = (state) => {
    switch (state) {
        case reportingFormStates.DRAFT:
            return 'neutral';
        case reportingFormStates.OPEN:
            return 'neutral';
        case reportingFormStates.CONFIRMED:
            return 'positive';
        case reportingFormStates.INVALIDATED:
            return 'negative';
        default:
            return 'neutral';
    }
};
