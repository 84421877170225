// Suoratyo\AppBundle\TimeRegistration\WorkTimeAllowance.php

/**
 * Lisät.
 */
export const workTimeAllowanceTypes = {
    EVENING_ALLOWANCE: 1,
    NIGHT_ALLOWANCE: 2,
    SATURDAY_ALLOWANCE: 4,
    SUNDAY_ALLOWANCE: 8,
    MIDWEEK_HOLIDAY: 16,
    EVE_ALLOWANCE: 32,
};

/**
 * Lisien avainkäännökset.
 */
export const workTimeAllowanceTypeKeyMap = {
    [workTimeAllowanceTypes.EVENING_ALLOWANCE]: 'evening_allowance',
    [workTimeAllowanceTypes.NIGHT_ALLOWANCE]: 'night_allowance',
    [workTimeAllowanceTypes.SATURDAY_ALLOWANCE]: 'saturday_allowance',
    [workTimeAllowanceTypes.SUNDAY_ALLOWANCE]: 'sunday_allowance',
    [workTimeAllowanceTypes.MIDWEEK_HOLIDAY]: 'midweek_holiday',
    [workTimeAllowanceTypes.EVE_ALLOWANCE]: 'eve_allowance',
};

export const workTimeAllowanceTypesBinary = {
    //NONE_ALLOWANCE: 0b000000, // 0
    EVENING_ALLOWANCE: 0b000001, // 1
    NIGHT_ALLOWANCE: 0b000010, // 2
    SATURDAY_ALLOWANCE: 0b000100, // 4
    SUNDAY_ALLOWANCE: 0b001000, // 8
    MID_WEEK_HOLIDAY: 0b010000, // 16
    EVE_ALLOWANCE: 0b100000, // 32
    //SATURDAY_EVENING_ALLOWANCE: 0b000101, // SATURDAY_ALLOWANCE | EVENING_ALLOWANCE 5
    //SATURDAY_NIGHT_ALLOWANCE: 0b000110, // SATURDAY_ALLOWANCE | NIGHT_ALLOWANCE   6
    //SUNDAY_EVENING_ALLOWANCE: 0b001001, // SUNDAY_ALLOWANCE   | EVENING_ALLOWANCE 9
    //SUNDAY_NIGHT_ALLOWANCE: 0b001010, // SUNDAY_ALLOWANCE   | NIGHT_ALLOWANCE   10
    //EVE_EVENING_ALLOWANCE: 0b100001, // EVE_ALLOWANCE      | EVENING_ALLOWANCE
    //EVE_NIGHT_ALLOWANCE: 0b100010, // EVE_ALLOWANCE      | NIGHT_ALLOWANCE
    //MID_WEEK_HOLIDAY_EVENING_ALLOWANCE: 0b011001, // SUNDAY_ALLOWANCE   | EVENING_ALLOWANCE | MID_WEEK_HOLIDAY
    //MID_WEEK_HOLIDAY_NIGHT_ALLOWANCE: 0b011010, // SUNDAY_ALLOWANCE   | NIGHT_ALLOWANCE   | MID_WEEK_HOLIDAY
    MID_WEEK_HOLIDAY_NEW_YEARS_DAY: 0b000000000000000001000000, // uuden vuoden päivä
    MID_WEEK_HOLIDAY_EPIPHANY: 0b000000000000000010000000, // loppiainen
    MID_WEEK_HOLIDAY_GOOD_FRIDAY: 0b000000000000000100000000, // pitkäperjantai
    MID_WEEK_HOLIDAY_EASTER: 0b000000000000001000000000, // pääsiäinen
    MID_WEEK_HOLIDAY_EASTER_MONDAY: 0b000000000000010000000000, // 2. pääsiäispäivä
    MID_WEEK_HOLIDAY_MAYDAY_EVE: 0b000000000000100000000000, // vappuaatto
    MID_WEEK_HOLIDAY_MAYDAY: 0b000000000001000000000000, // vappu
    MID_WEEK_HOLIDAY_PENTECOST: 0b000000000010000000000000, // helluntai
    MID_WEEK_HOLIDAY_ASCENSION_DAY: 0b000000000100000000000000, // helatorstai
    MID_WEEK_HOLIDAY_MIDSUMMER_EVE: 0b000000001000000000000000, // juhannusaatto
    MID_WEEK_HOLIDAY_MIDSUMMER: 0b000000010000000000000000, // juhannus
    MID_WEEK_HOLIDAY_ALL_SAINTS_DAY: 0b000000100000000000000000, // pyhäinpäivä
    MID_WEEK_HOLIDAY_FINLAND_NATIONAL_DAY: 0b000001000000000000000000, // itsenäisyyspäivä
    MID_WEEK_HOLIDAY_CHRISTMAS_EVE: 0b000010000000000000000000, // jouluaatto
    MID_WEEK_HOLIDAY_CHRISTMAS_DAY: 0b000100000000000000000000, // joulupäivä
    MID_WEEK_HOLIDAY_BOXING_DAY: 0b001000000000000000000000, // tapaninpäivä
    MID_WEEK_HOLIDAY_NEW_YEARS_EVE: 0b010000000000000000000000, // uuden vuoden aatto
    MID_WEEK_HOLIDAY_HOLY_SATURDAY: 0b100000000000000000000000, // pääsiäislauantai
};

const workTimeAllowanceMap = {
    //[workTimeAllowanceTypesBinary.NONE_ALLOWANCE]: _trans('tes_builder.work_time_allowances.none'),
    [workTimeAllowanceTypesBinary.EVENING_ALLOWANCE]: _trans('tes_builder.work_time_allowances.evening'),
    [workTimeAllowanceTypesBinary.NIGHT_ALLOWANCE]: _trans('tes_builder.work_time_allowances.night'),
    [workTimeAllowanceTypesBinary.SATURDAY_ALLOWANCE]: _trans('tes_builder.work_time_allowances.saturday'),
    [workTimeAllowanceTypesBinary.SUNDAY_ALLOWANCE]: _trans('tes_builder.work_time_allowances.sunday'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY]: _trans('tes_builder.work_time_allowances.midweek'),
    [workTimeAllowanceTypesBinary.EVE_ALLOWANCE]: _trans('tes_builder.work_time_allowances.eve'),
    // [workTimeAllowanceTypesBinary.SATURDAY_EVENING_ALLOWANCE]: _trans('tes_builder.work_time_allowances.'),
    // [workTimeAllowanceTypesBinary.SATURDAY_NIGHT_ALLOWANCE]: _trans('tes_builder.work_time_allowances.'),
    // [workTimeAllowanceTypesBinary.SUNDAY_EVENING_ALLOWANCE]: _trans('tes_builder.work_time_allowances.'),
    // [workTimeAllowanceTypesBinary.SUNDAY_NIGHT_ALLOWANCE]: _trans('tes_builder.work_time_allowances.'),
    // [workTimeAllowanceTypesBinary.EVE_EVENING_ALLOWANCE]: _trans('tes_builder.work_time_allowances.'),
    // [workTimeAllowanceTypesBinary.EVE_NIGHT_ALLOWANCE]: _trans('tes_builder.work_time_allowances.'),
    // [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_EVENING_ALLOWANCE]: _trans('tes_builder.work_time_allowances.'),
    // [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_NIGHT_ALLOWANCE]: _trans('tes_builder.work_time_allowances.'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_NEW_YEARS_DAY]: _trans('tes_builder.work_time_allowances.new_years_day'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_EPIPHANY]: _trans('tes_builder.work_time_allowances.epiphany'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_GOOD_FRIDAY]: _trans('tes_builder.work_time_allowances.good_friday'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_EASTER]: _trans('tes_builder.work_time_allowances.easter'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_EASTER_MONDAY]: _trans('tes_builder.work_time_allowances.easter_monday'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_MAYDAY_EVE]: _trans('tes_builder.work_time_allowances.may_day_eve'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_MAYDAY]: _trans('tes_builder.work_time_allowances.may_day'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_PENTECOST]: _trans('tes_builder.work_time_allowances.pentecost'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_ASCENSION_DAY]: _trans('tes_builder.work_time_allowances.ascension_day'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_MIDSUMMER_EVE]: _trans('tes_builder.work_time_allowances.mid_summer_eve'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_MIDSUMMER]: _trans('tes_builder.work_time_allowances.mid_summer'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_ALL_SAINTS_DAY]: _trans('tes_builder.work_time_allowances.all_saints_day'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_FINLAND_NATIONAL_DAY]: _trans('tes_builder.work_time_allowances.finland_national_day'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_CHRISTMAS_EVE]: _trans('tes_builder.work_time_allowances.christmas_eve'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_CHRISTMAS_DAY]: _trans('tes_builder.work_time_allowances.christmas_day'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_BOXING_DAY]: _trans('tes_builder.work_time_allowances.boxing_day'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_NEW_YEARS_EVE]: _trans('tes_builder.work_time_allowances.new_years_eve'),
    [workTimeAllowanceTypesBinary.MID_WEEK_HOLIDAY_HOLY_SATURDAY]: _trans('tes_builder.work_time_allowances.holy_saturday'),
};

export function translateWorkTimeAllowanceBinary(allowance) {
    return allowance in workTimeAllowanceMap ? workTimeAllowanceMap[allowance] : '';
}
