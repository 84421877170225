import confirm from './confirm';

/**
 * Yksinkertainen alertboksi jossa pelkkä OK-nappi.
 * @param confirmation
 * @param options
 * @returns {*}
 */
export default (confirmation, options = {}) => confirm(confirmation, { cancelLabel: null, proceedLabel: 'OK', showAlertIcon: true, ...options });



