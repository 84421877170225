import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import _ from 'lodash';
import { List, Map } from 'immutable';
import { select } from '@rematch/select';
import { push } from 'connected-react-router';
import { routes as communeRoutes } from 'Commune/Settings/routes';
import validators from 'shared/validators';
import { FormikErrors } from 'shared/components/Formik';
import {
    ActionBar,
    GoBackLink,
    StLabel,
    SubmitButton,
    Panel,
    StField,
    Dropdown,
    Button,
    MDSpinner,
    Feedback,
} from 'shared/components';
import { referenceTypes } from 'shared/constants/tradeUnionMembershipReferenceTypes';
import adminRoutes from 'Admin/TradeUnion/routes';
import routes from 'shared/TradeUnion/routes';
import TradeUnionReferenceNumberGeneratorInput from 'shared/containers/TradeUnion/TradeUnionReferenceNumberGeneratorInput';
import { referenceNumberTypes } from 'shared/constants/tradeUnion';
import { StHelp } from 'shared/components/StForm';

const currentYear = moment().format('YYYY');

@connect((state, props) => ({
    employeeReferenceNumbers: select.tradeUnion.getEmployeeReferenceNumbers(state),
    suoratyoReferenceNumbers: select.tradeUnion.getSuoratyoReferenceNumbers(state),
    tradeUnionSubscription: select.tradeUnion.getTradeUnionSubscription(state),
    subscriptionId: _.get(props, 'match.params.tradeUnionId', ''),
    isEditMode: select.tradeUnion.isReferenceNumbersEditMode(state),
    generatedNumbers: select.tradeUnion.getGeneratedNumbers(state),
    hasGeneratedNumbers: select.tradeUnion.hasGeneratedNumbers(state),
    companyReferenceNumbers: select.tradeUnion.getCompanyReferenceNumbers(state),
    hasCompanyReferenceNumbers: select.tradeUnion.hasCompanyReferenceNumbers(state),
    hasSuoratyoReferenceNumbers: select.tradeUnion.hasSuoratyoReferenceNumbers(state),
    communeReferenceNumbers: select.tradeUnion.getCommuneReferenceNumbers(state),
    hasCommuneReferenceNumbers: select.tradeUnion.hasCommuneReferenceNumbers(state),
    paymentDataForYearError: select.tradeUnion.getPaymentDataForYearError(state),
    adminTradeUnionSubscription: select.tradeUnion.getAdminTradeUnionSubscription(state),
    companyPayerNumber: select.companySettings?.getDetails(state)?.tradeUnionPayerNumber ?? undefined,
    isLoading: _.get(state, 'loading.effects.tradeUnion.fetchEmployeeTradeUnionReferenceNumbers')
        || _.get(state, 'loading.effects.tradeUnion.fetchTradeUnionSubscription')
        || _.get(state, 'loading.effects.tradeUnion.fetchSuoratyoTradeUnionReferenceNumbers')
        || _.get(state, 'loading.effects.tradeUnion.fetchCompanyReferenceNumbers')
        || _.get(state, 'loading.effects.tradeUnion.fetchCommuneReferenceNumbers'),
}))
export default class TradeUnionReferenceForm extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        userId: PropTypes.number,
        employeeReferenceNumbers: PropTypes.instanceOf(List),
        suoratyoReferenceNumbers: PropTypes.instanceOf(List),
        membership: PropTypes.object,
        isEditMode: PropTypes.bool,
        isLoading: PropTypes.bool,
        subscriptionId: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        tradeUnionSubscription: PropTypes.instanceOf(List),
        generatedNumbers: PropTypes.instanceOf(Map),
        hasGeneratedNumbers: PropTypes.bool,
        referenceNumberType: PropTypes.number.isRequired,
        companyReferenceNumbers: PropTypes.instanceOf(List),
        hasCompanyReferenceNumbers: PropTypes.bool,
        hasSuoratyoReferenceNumbers: PropTypes.bool,
        tradeUnionId: PropTypes.number,
        communeReferenceNumbers: PropTypes.instanceOf(List),
        hasCommuneReferenceNumbers: PropTypes.bool,
        paymentDataForYearError: PropTypes.instanceOf(Object),
        adminTradeUnionSubscription: PropTypes.instanceOf(List),
        companyPayerNumber: PropTypes.number,
    };

    static defaultProps = {
        dispatch() {},
        userId: null,
        employeeReferenceNumbers: List(),
        suoratyoReferenceNumbers: List(),
        membership: {},
        isEditMode: false,
        isLoading: false,
        subscriptionId: '',
        tradeUnionSubscription: List(),
        generatedNumbers: Map(),
        hasGeneratedNumbers: false,
        companyReferenceNumbers: List(),
        hasCompanyReferenceNumbers: false,
        hasSuoratyoReferenceNumbers: false,
        tradeUnionId: null,
        communeReferenceNumbers: List(),
        hasCommuneReferenceNumbers: false,
        paymentDataForYearError: {},
        adminTradeUnionSubscription: List(),
        companyPayerNumber: undefined,
    };

    state = {
        selectedYear: currentYear,
        numbersConfirmed: false,
    };

    constructor(props) {
        super(props);
        const { dispatch, membership, referenceNumberType, subscriptionId, tradeUnionId } = props;
        const year = this.state.selectedYear;

        switch (referenceNumberType) {
            case referenceNumberTypes.SUORATYO:
                dispatch.tradeUnion.fetchSuoratyoTradeUnionReferenceNumbers(subscriptionId, year);
                dispatch.tradeUnion.fetchAdminTradeUnionSubscription(subscriptionId, year);
                break;
            case referenceNumberTypes.PERSONAL:
                dispatch.tradeUnion.fetchEmployeeTradeUnionReferenceNumbers(_.get(membership, 'id'), year);
                break;
            case referenceNumberTypes.COMPANY:
                dispatch.tradeUnion.fetchCompanyTradeUnionReferenceNumbers(subscriptionId, year);
                dispatch.tradeUnion.fetchTradeUnionSubscription(subscriptionId, year);
                break;
            case referenceNumberTypes.COMMUNE:
                dispatch.tradeUnion.fetchCommuneTradeUnionReferenceNumbers(tradeUnionId, year);
                dispatch.tradeUnion.fetchTradeUnionSubscription(tradeUnionId, year);
                break;
            default:
                console.error('Reference number type not supported');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const year = this.state.selectedYear;
        if (year !== prevState.selectedYear) {
            const { dispatch, membership, referenceNumberType, subscriptionId, tradeUnionId } = this.props;

            switch (referenceNumberType) {
                case referenceNumberTypes.SUORATYO:
                    dispatch.tradeUnion.fetchSuoratyoTradeUnionReferenceNumbers(subscriptionId, year);
                    dispatch.tradeUnion.fetchAdminTradeUnionSubscription(subscriptionId, year);
                    break;
                case referenceNumberTypes.PERSONAL:
                    dispatch.tradeUnion.fetchEmployeeTradeUnionReferenceNumbers(_.get(membership, 'id'), year);
                    break;
                case referenceNumberTypes.COMPANY:
                    dispatch.tradeUnion.fetchCompanyTradeUnionReferenceNumbers(subscriptionId, year);
                    dispatch.tradeUnion.fetchTradeUnionSubscription(subscriptionId, year);
                    break;
                case referenceNumberTypes.COMMUNE:
                    dispatch.tradeUnion.fetchCommuneTradeUnionReferenceNumbers(tradeUnionId, year);
                    dispatch.tradeUnion.fetchTradeUnionSubscription(tradeUnionId, year);
                    break;
                default:
                    console.error('Reference number type not supported');
            }
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch.tradeUnion.resetReferenceNumbers();
        dispatch.tradeUnion.resetTradeUnionSubscription();
        dispatch.tradeUnion.resetGeneratedNumbers();
        dispatch.tradeUnion.setReferenceNumbersEditMode(false);
    }

    isNewNumbers = () => (
        this.props.employeeReferenceNumbers.size === 0
        && this.props.suoratyoReferenceNumbers.size === 0
        && this.props.companyReferenceNumbers.size === 0
        && this.props.communeReferenceNumbers.size === 0
    );

    resolveRoute = () => {
        switch (this.props.referenceNumberType) {
            case referenceNumberTypes.SUORATYO:
                return adminRoutes.BASE;
            case referenceNumberTypes.PERSONAL:
                return '#';
            case referenceNumberTypes.COMPANY:
                return routes.COMPANY_TRADE_UNION_LIST;
            case referenceNumberTypes.COMMUNE:
                return communeRoutes.TRADE_UNION_REFERENCE_NUMBERS;
            default:
                console.error('Reference number type not supported');
        }
    };

    resolveReferenceNumbers = () => {
        const {
            referenceNumberType,
            suoratyoReferenceNumbers,
            employeeReferenceNumbers,
            companyReferenceNumbers,
            communeReferenceNumbers,
        } = this.props;
        switch (referenceNumberType) {
            case referenceNumberTypes.SUORATYO:
                return suoratyoReferenceNumbers.toJS();
            case referenceNumberTypes.PERSONAL:
                return employeeReferenceNumbers.toJS();
            case referenceNumberTypes.COMPANY:
                return companyReferenceNumbers.toJS();
            case referenceNumberTypes.COMMUNE:
                return communeReferenceNumbers.toJS();
            default:
                console.error('Reference number type not supported');
        }
    };

    renderReferenceFields = () => {
        const { isEditMode } = this.props;

        if (this.state.selectedYear) {
            return moment.months().map((month, key) => (
                <Fragment key={key}>
                    <StLabel htmlFor={'referenceNumber' + key}>
                        {month}
                    </StLabel>
                    <StField>
                        <Field
                            readOnly={(! isEditMode && ! this.isNewNumbers())}
                            type="text"
                            id={'referenceNumber' + key}
                            name={'referenceNumber' + key}
                        />
                        <FormikErrors name={'referenceNumber' + key} />
                    </StField>
                </Fragment>
            ));
        }
    };

    handleInitialValues = () => {
        const { generatedNumbers, hasGeneratedNumbers } = this.props;
        const initialValues = {
            year: this.state.selectedYear,
        };

        if (this.isNewNumbers() && ! hasGeneratedNumbers) {
            for (let i = 0; i < 12; i++) {
                initialValues[`referenceNumber${i}`] = '';
            }
        } else if (hasGeneratedNumbers) {
            _.forEach(generatedNumbers.toJS(), (number, key) => {
                initialValues[key] = number;
            });
        } else {
            const selectedYearNumbers = _.filter(this.resolveReferenceNumbers(), (number) => (
                moment(number.startDate).format('YYYY') === this.state.selectedYear)
            );
            // Varmistetaan vielä, että numerot ovat varmasti oikeassa järjestyksessä
            const sortedNumbers =_.sortBy(selectedYearNumbers, (numbers) => (numbers.startDate));

            _.forEach(sortedNumbers, (number) => {
                // Käytetään kuukauden järjestyslukua arrayn avaimena
                const monthNumber = _.toInteger(moment(number.startDate).format('M')) -1;
                initialValues[`referenceNumber${monthNumber}`] = number.number;
                initialValues[`id${monthNumber}`] = number.id;
            });
        }

        return initialValues;
    };

    postData = (data) => {
        const { dispatch, membership, referenceNumberType, subscriptionId, userId, tradeUnionId } = this.props;

        // Poistetaan tiedot joita ei saa olla postatessa
        data = _.each(data, function (reference) {
            _.unset(reference, 'id');
        });

        const paymentReferences = {
            paymentReferences: data,
        };

        switch (referenceNumberType) {
            case referenceNumberTypes.SUORATYO:
                dispatch.tradeUnion.postSuoratyoTradeUnionReferenceNumbers(paymentReferences, subscriptionId);
                break;
            case referenceNumberTypes.PERSONAL:
                dispatch.tradeUnion.postEmployeeTradeUnionReferenceNumbers(
                    {
                        ...paymentReferences,
                        referenceType: referenceTypes.REFERENCE_TYPE_PERSONAL
                    },
                    [userId, _.get(membership, 'id')]
                );
                break;
            case referenceNumberTypes.COMPANY:
                dispatch.tradeUnion.postCompanyTradeUnionReferenceNumbers(paymentReferences, subscriptionId);
                break;
            case referenceNumberTypes.COMMUNE:
                dispatch.tradeUnion.postCommuneTradeUnionReferenceNumbers(paymentReferences, tradeUnionId);
                break;
            default:
                console.error('Reference number type not supported');
        }
    };

    putData = (data) => {
        const { dispatch, membership, referenceNumberType, subscriptionId, tradeUnionId } = this.props;

        // Poistetaan tiedot, joita ei voi olla putissa
        _.each(data, function (reference) {
            _.unset(reference, 'startDate');
            _.unset(reference, 'expireDate');
        });
        const paymentReferences = {
            paymentReferences: data,
        };

        switch (referenceNumberType) {
            case referenceNumberTypes.SUORATYO:
                dispatch.tradeUnion.putSuoratyoTradeUnionReferenceNumbers(paymentReferences, subscriptionId);
                break;
            case referenceNumberTypes.PERSONAL:
                dispatch.tradeUnion.putEmployeeTradeUnionReferenceNumbers(paymentReferences, _.get(membership, 'id'));
                break;
            case referenceNumberTypes.COMPANY:
                dispatch.tradeUnion.putTradeUnionReferenceNumbers(paymentReferences, subscriptionId).then(() => dispatch(push(this.resolveRoute())));
                break;
            case referenceNumberTypes.COMMUNE:
                dispatch.tradeUnion.putTradeUnionReferenceNumbers(paymentReferences, tradeUnionId).then(() => dispatch(push(this.resolveRoute())));
                break;
            default:
                console.error('Reference number type not supported');
        }
    };

    // Muodostaa validin setin dataa ja tallentaa sen kantaan
    formData = (model) => {
        const references = [];

        // Alustetaan kuukausittainen array
        for (let i = 0; i < 12; i++) {
            const startDate = moment().year(this.state.selectedYear).month(i).startOf('month').format('YYYY-MM-DD');
            const expireDate = moment().year(this.state.selectedYear).month(i).endOf('month').format('YYYY-MM-DD');
            references[i] = {};
            _.set(references[i], 'startDate', startDate);
            _.set(references[i], 'expireDate', expireDate);
        }

        // Läntättän kaikki modelin tiedot kuukausittaiseen arrayhyn
        for (const [key, value] of Object.entries(model)) {
            const month = key.replace(/[^0-9]/g, '');
            const isId = key.substring(0,2) === 'id';
            if (month) {
                if (isId) {
                    _.set(references[month], 'id', value);
                } else {
                    _.set(references[month], 'referenceNumber', value);
                }
            }
        }

        const putData = _.filter(references, function (reference) {
            return _.get(reference, 'id', null);
        });
        const postData = _.filter(references, function (reference) {
            return !_.get(reference, 'id', null);
        });

        if (postData.length > 0) {
            this.postData(postData);
        }
        if (putData.length > 0) {
            this.putData(putData);
        }
    };

    renderActionBar = () => {
        const lastYear = moment().subtract(1, 'year').format('YYYY');
        const nextYear = moment().add(1, 'year').format('YYYY');
        const years = [
            {
                id: lastYear,
                name: lastYear,
            },
            {
                id: currentYear,
                name: currentYear,
            },
            {
                id: nextYear,
                name: nextYear,
            },
        ];
        return (
            <div>
                <b className="u-margin-right-tiny">{_trans('year')}</b>
                <Dropdown
                    value={this.state.selectedYear}
                    options={years}
                    onChange={(year) => this.setState({ selectedYear: year })}
                />
            </div>
        );
    };

    renderConfirmCheckbox = () => {
        if (this.props.hasGeneratedNumbers) {
            return (
                <label className="u-margin-right-small">
                    <input
                        type="checkbox"
                        name="confirmNumbers"
                        checked={this.state.numbersConfirmed}
                        onChange={() => this.setState({ numbersConfirmed: ! this.state.numbersConfirmed })}
                    />
                    {_trans('trade_union_membership.reference_numbers.generator.numbers_confirmed')}
                </label>
            );
        }
    };

    renderSubmitButton = () => {
        const { isEditMode, dispatch, hasGeneratedNumbers } = this.props;

        if (this.isNewNumbers() || isEditMode) {
            return (
                <div>
                    {this.renderConfirmCheckbox()}
                    <SubmitButton disabled={hasGeneratedNumbers && ! this.state.numbersConfirmed}>
                        {this.isNewNumbers() ? _trans('button.save') : _trans('button.save_changes')}
                    </SubmitButton>
                </div>
            );
        } else {
            return (
                <Button onClick={() => dispatch.tradeUnion.setReferenceNumbersEditMode(true)}>
                    {_trans('button.edit')}
                </Button>
            );
        }
    };

    renderFeedbackActionBar = () => {
        const { dispatch, referenceNumberType } = this.props;
        return (
            <ActionBar modifierClass="u-margin-top-small">
                <GoBackLink
                    onClick={
                        ! _.includes([referenceNumberTypes.PERSONAL, referenceNumberTypes.COMMUNE], referenceNumberType)
                            ? () => {}
                            : () => {
                                dispatch.tradeUnion.setReferenceNumbersPage(false);
                                dispatch.tradeUnion.setReferenceNumbersEditMode(false);
                            }
                    }
                    href={this.resolveRoute()}
                    text={_trans('link.return')}
                />
            </ActionBar>
        );
    };

    renderForm = () => {
        const { dispatch, referenceNumberType, isEditMode } = this.props;

        // Validointi
        const validation = {
            year: Yup.string().required(_trans('validation.required')),
        };
        for (let i = 0; i < 12; i++) {
            validation[`referenceNumber${i}`] = Yup.string()
                .test(
                    'Viitenumero',
                    _trans('trade_union_membership.reference_numbers.reference_number_not_valid'),
                    (value) => validators.isTradeUnionReferenceNumber(value)
                );
        }

        return (
            <div>
                { this.isNewNumbers()
                    ? <TradeUnionReferenceNumberGeneratorInput year={this.state.selectedYear} isReadOnly={!this.isNewNumbers() && !isEditMode}/>
                    : null
                }
                <Formik
                    initialValues={this.handleInitialValues()}
                    validationSchema={Yup.object().shape(validation)}
                    onSubmit={(model) => this.formData(model)}
                    enableReinitialize
                >
                    {(props) => {
                        const { handleSubmit } = props;
                        return (
                            <form
                                className="o-form o-form--responsive"
                                onSubmit={handleSubmit}
                            >
                                {this.renderReferenceFields(this.state.selectedYear)}
                                <ActionBar modifierClass="u-margin-top-small">
                                    <GoBackLink
                                        onClick={
                                            ! _.includes([referenceNumberTypes.PERSONAL, referenceNumberTypes.COMMUNE], referenceNumberType)
                                                ? () => {}
                                                : () => {
                                                    dispatch.tradeUnion.setReferenceNumbersPage(false);
                                                    dispatch.tradeUnion.setReferenceNumbersEditMode(false);
                                                }
                                        }
                                        href={this.resolveRoute()}
                                        text={_trans('link.return_without_saving')}
                                    />
                                    {this.renderSubmitButton()}
                                </ActionBar>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        );
    };

    renderFeedback = (type, message) => (
        <div>
            <Feedback type={type} message={message}/>
            {this.renderFeedbackActionBar()}
        </div>
    );

    renderContent = () => {
        const {
            membership,
            tradeUnionSubscription,
            hasCompanyReferenceNumbers,
            hasSuoratyoReferenceNumbers,
            hasCommuneReferenceNumbers,
            paymentDataForYearError,
            isLoading,
            adminTradeUnionSubscription,
            referenceNumberType,
        } = this.props;

        if (isLoading) {
            return <MDSpinner wrapped/>;
        }

        const subscription = referenceNumberType === referenceNumberTypes.SUORATYO
            ? adminTradeUnionSubscription.toJS()
            : tradeUnionSubscription.toJS();

        if (paymentDataForYearError.size > 0) {
            return this.renderFeedback('error', paymentDataForYearError.getIn(['message']));
        } else if (! _.has(membership, 'subscription.activePaymentData.accountNumber')
            && _.get(subscription, 'activePaymentData.accountNumber', '') === '')
        {
            if (referenceNumberType === referenceNumberTypes.COMPANY) {
                return (
                    <Fragment>
                        {this.renderFeedback('error', _trans('trade_union_subscription.notifications.no_account_number'))}
                        {this.renderAccountNumberForm()}
                    </Fragment>
                );
            }
            return this.renderFeedback('error', _trans('trade_union_subscription.notifications.no_account_number_employee'));
        } else if (hasSuoratyoReferenceNumbers && referenceNumberType === referenceNumberTypes.COMMUNE) {
            return this.renderFeedback('info', _trans('trade_union_subscription.notifications.has_suoratyo_numbers'));
        } else if (hasCompanyReferenceNumbers && referenceNumberType === referenceNumberTypes.PERSONAL) {
            return (
                <Fragment>
                    {this.renderFeedback('info', _trans('trade_union_subscription.notifications.has_company_numbers'))}
                    {this.renderForm()}
                </Fragment>
            );
        } else if (hasCommuneReferenceNumbers) {
            return this.renderFeedback('info', _trans('trade_union_subscription.notifications.has_commune_numbers'));
        } else {
            return this.renderForm();
        }
    };

    render() {
        const {
            membership,
            referenceNumberType,
            tradeUnionSubscription,
            adminTradeUnionSubscription,
            companyPayerNumber,
        } = this.props;

        const subscription = referenceNumberType === referenceNumberTypes.SUORATYO
            ? adminTradeUnionSubscription.toJS()
            : tradeUnionSubscription.toJS();

        const tradeUnionName = referenceNumberType !== referenceNumberTypes.PERSONAL
            ? _.get(subscription, 'name')
            : _.get(membership, 'subscription.name');
        const tradeUnionPercentage = referenceNumberType !== referenceNumberTypes.PERSONAL
            ? _.get(subscription, 'latestPaymentData.percentage')
            : _.get(membership, 'subscription.activePaymentData.percentage');
        const text = referenceNumberType !== referenceNumberTypes.PERSONAL
            ? 'trade_union_subscription.reference_numbers.heading'
            : 'trade_union_membership.reference_numbers.heading';

        // Jos yritys viiteet & puuttuva maksatunnus, näytetään herja.
        const noCompanyPayerNumberWarning = referenceNumberType === referenceNumberTypes.COMPANY && ! companyPayerNumber ? (
            <Feedback
                type="warning"
                message={_trans('company.settings.trade_union_reference_numbers.no_payer_number_warning')}
            />
        ) : null;

        return (

            <Panel
                heading={_trans(text, { tradeUnion: tradeUnionName, percentage: tradeUnionPercentage })}
                actionItems={this.renderActionBar()}
                modifierClass="u-margin"
            >
                {noCompanyPayerNumberWarning}
                {this.renderContent()}
            </Panel>
        );
    }

    renderAccountNumberForm() {
        const validation = {
            accountNumber: Yup.string()
                .test(
                    'isIban',
                    _trans('trade_union_membership.reference_numbers.account_number_not_valid'),
                    (value) => validators.isIban(value)
                )
        };

        return (
            <div>
                <Formik
                    initialValues={{ accountNumber: '' }}
                    validationSchema={Yup.object().shape(validation)}
                    onSubmit={(model) => this.submitAccountNumber(model)}
                    enableReinitialize
                >
                    {(props) => {
                        const { handleSubmit } = props;
                        return (
                            <form
                                className="o-form o-form--responsive"
                                onSubmit={handleSubmit}
                            >
                                <StLabel>
                                    {_trans('trade_union_membership.subscription_account_number.label')}
                                </StLabel>
                                <StField>
                                    <Field
                                        id="accountNumber"
                                        type="text"
                                        name={'accountNumber'}
                                    />
                                    <FormikErrors name={'accountNumber'} />
                                    <StHelp id="accountNumber">
                                        {_trans('trade_union_membership.subscription_account_number.help')}
                                    </StHelp>
                                </StField>
                                <ActionBar modifierClass="u-margin-top-small">
                                    <SubmitButton>
                                        {_trans('button.save')}
                                    </SubmitButton>
                                </ActionBar>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        );
    }

    submitAccountNumber(model) {
        const { dispatch, subscriptionId } = this.props;
        dispatch.tradeUnion.postTradeUnionAccountNumber(model, subscriptionId);
    }
}
