import PropTypes from 'prop-types';

export default PropTypes.shape({
    incomeLimit1: PropTypes.number,
    incomeLimit2: PropTypes.number,
    TaxDeduction1: PropTypes.number,
    taxDeduction2: PropTypes.number,
    startDate: PropTypes.string,
    isVeroFetched: PropTypes.bool,
    isOverrideVeroFetch: PropTypes.bool,
});
