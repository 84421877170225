/**
 * Kaikki breakpointit
 * @type {{tiny: number, small: number, medium: number, medium1: number, medium2: number, large: number, huge: number}}
 */
export const allBreakPoints = {
    TINY: 320,
    SMALL: 375,
    SMALL1: 480,
    MEDIUM: 768,
    MEDIUM1: 840,
    MEDIUM2: 1080,
    LARGE: 1280,
    HUGE: 1440,
};


/**
 * Pääasialliset breakpointit
 * @type {{small: number, medium: number, large: number, huge: number}}
 */
export const mainBreakPoints = {
    SMALL: 375,
    MEDIUM: 768,
    LARGE: 1280,
    HUGE: 1440,
};

export default mainBreakPoints;
