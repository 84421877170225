import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { LocalForm, Control } from 'react-redux-form';
import { Map, List } from 'immutable';
import { userActions, userSelectors } from '../../stores/user';
import { relationRoles, userRoles } from 'shared/constants/index';
import Page from 'shared/components/Page';
import DataTable from 'shared/containers/DataTable';
import { translateContractState } from 'shared/constants/contractStates';
import ActionBar from 'shared/components/ActionBar/ActionBar';
import Button from 'shared/components/Button';
import DateRangeInput from 'shared/components/DateRangeInput';
import { StField, StLabel, StErrors } from 'shared/components/StForm';
import Layout from 'shared/components/Layout';
import validators from 'shared/validators';
import { MDSpinner, SubmitButton } from 'shared/components';
import commonUtils from 'shared/utils/commonUtils';

@connect((state) => ({
    userId: userSelectors.getUserId(state),
    isEmployer: userSelectors.isEmployer(state),
    contracts: state.userContracts,
    ui: userSelectors.getUiProps(state, 'userContracts'),
}))
export default class UserWagesCertificateReport extends Component {

    static propTypes = {
        userId: PropTypes.number,
        dispatch: PropTypes.func,
        isEmployer: PropTypes.bool,
        contracts: PropTypes.object,
        ui: PropTypes.object,
    };

    static defaultProps = {
        userId: -1,
        dispatch() {},
        isEmployer: false,
        contracts: List(),
        ui: Map(),
    };

    state = {
        selected: null,
        formValues: null,
    };

    componentDidMount() {
        const { userId, dispatch, isEmployer } = this.props;
        dispatch(userActions.fetchUserContracts(userId, isEmployer ? userRoles.EMPLOYER : userRoles.EMPLOYEE));
    }

    onSubmit = (model) => {
        const { isEmployer, userId } = this.props;

        const selectedUserRole = commonUtils.getParameterByName('type');
        const isSelectedEmployer = String(relationRoles.RELATION_EMPLOYER) === selectedUserRole;

        const normalizeBoolean = (boolean) => boolean ? 1 : 0;

        const startDate = _.get(model, 'selectedDates.start');
        const endDate = _.get(model, 'selectedDates.end');
        const url = Routing.generate('suoratyo_reports_wages_certificates', {
            employeeId: isEmployer ? null : userId,
            employerId: isEmployer ? userId : null,
            startDate,
            endDate,
            contract: this.state.selected,
            inPayperiod: model.inPayperiod,
            employer: selectedUserRole ? normalizeBoolean(isSelectedEmployer) : normalizeBoolean(isEmployer),
        });

        // Rumaa koodia
        window.open(url, '_blank');
    };

    renderContent() {
        const { isEmployer, contracts, } = this.props;
        return (
            <div>
                <ActionBar hasMargin>
                    <Button active={this.state.selected === null} onClick={() => this.setState({ selected: null })} width="1/1">
                        {_trans('wages_certificates_report.all_job_contracts')}
                    </Button>
                </ActionBar>
                <DataTable
                    getTrProps={(state, rowInfo) => (
                        {
                            onClick: () => this.setState({ selected: _.get(rowInfo.original, 'contract.jobContractId', null) }),
                            className: classNames('u-cursor-pointer', {
                                'is-selected': _.get(rowInfo.original, 'contract.jobContractId', null) === this.state.selected,
                            }),
                        }
                    )}
                    isFilterable={false}
                    data={contracts.toJS()}
                    columns={[
                        {
                            Header: isEmployer ? _trans('role.employee') : _trans('role.employer'),
                            accessor: isEmployer ? 'contractData.employeeName' : 'contractData.employerName',
                        },
                        {
                            Header: _trans('wages_certificates_report.state'),
                            id: 'state',
                            Cell: (props) => translateContractState(_.get(props.original, 'contract.state')),
                        },
                        {
                            Header: _trans('wages_certificates_report.duration'),
                            id: 'duration',
                            Cell: (props) => ContractDurationCell(props),
                        }

                    ]}
                />
                <LocalForm
                    className="o-form o-form--vertical"
                    onUpdate={(values) => this.setState({ formValues: values })}
                    onSubmit={this.onSubmit}
                    initialState={{
                        inPayperiod: '0',
                    }}
                >
                    <Layout.Container>
                        <Layout.Item medium="1/3">
                            <StLabel>
                                {_trans('wages_certificates_report.date_range')}
                            </StLabel>
                            <StField>
                                <Control
                                    model=".selectedDates"
                                    component={DateRangeInput}
                                    minimumDate={new Date('1970-01-01')}
                                    validators={{
                                        isDateRange: validators.isDateRange,
                                        isRequired: validators.isRequired,
                                    }}
                                />
                                <StErrors model=".selectedDates" />
                            </StField>
                        </Layout.Item>
                        <Layout.Item medium="1/3">
                            <StField isLabeless>
                                <label>
                                    {_trans('wages_certificates_report.not_in_payperiod')}
                                    <Control.radio
                                        model=".inPayperiod"
                                        value="0"
                                        className="u-margin-left-tiny"
                                    />
                                </label><br />
                                <label>
                                    {_trans('wages_certificates_report.in_payperiod')}
                                    <Control.radio
                                        model=".inPayperiod"
                                        value="1"
                                        className="u-margin-left-tiny"
                                    />
                                </label>
                            </StField>
                        </Layout.Item>
                        <Layout.Item medium="1/3">
                            <StField isLabeless>
                                <SubmitButton>
                                    {_trans('button.search')}
                                </SubmitButton>
                            </StField>
                        </Layout.Item>
                    </Layout.Container>
                </LocalForm>
            </div>
        );
    }

    render() {
        const { ui } = this.props;
        return (
            <Page heading={_trans('wages_certificates_report.wages')}>
                {ui.get('isLoading', false) ? <MDSpinner wrapped/> : this.renderContent()}
            </Page>
        );
    }
}

const ContractDurationCell = (props) => {
    const startDate = _.get(props.original, 'contract.startDate');
    if (_.has(props.original, 'contract.terminationTime')) {
        return <span>{`${_toLocaleDate(startDate)} - ${_toLocaleDate(props.original.contract.terminationTime)}`}</span>;
    }
    if (_.get(props.original, 'contract.endDate', false)) {
        return <span>{`${_toLocaleDate(startDate)} - ${_toLocaleDate(props.original.contract.endDate)}`}</span>;
    }
    return <span>{`${_toLocaleDate(startDate)} - ${_trans('wages_certificates_report.job_contract_so_far')}`}</span>;
};

ContractDurationCell.propTypes = {
    original: PropTypes.object.isRequired,
};
