import React from 'react';
import PropTypes from 'prop-types';

function StatNumber({
  number = "0,00",
  separator = ','
}) {
  const numbers = number.split(separator);
  if (numbers.length > 1) {
    return (
      <span className="c-heading-stat-number">
        {numbers[0]}
        <span className="c-heading-stat-number__decimal">
          {separator}{numbers[1]}
        </span>
      </span>
    );
  } else {
    return <span className="c-heading-stat-number">{number}</span>;
  }
}

StatNumber.propTypes = {
  number: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  separator: PropTypes.string
};

export default StatNumber;