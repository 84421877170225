import React from 'react';
import PropTypes from 'prop-types';

/**
 * Lomakkeella lisäinfoa tarjoava komponentti.
 * @deprecated Käytä Formik:ia ja meidän sharedista Form ja FormFieldiä.
 * @param children
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
const StHelp = ({ children, id }) => (
    <span className="o-help u-margin-top-tiny" id={id}>
        {children}
    </span>
);

StHelp.propTypes = {
    children: PropTypes.node.isRequired,
    /**
     * annetaan id ja elementille (input, textarea) annetaan aria-describedby-proppina tämä i
     *
     * Esimerkki:
     * <textarea aria-describedby="profile" ... />
     * <StHelp id="profile">Lyhyt kuvaus itsestäsi.</StHelp>
     *
     */
    id: PropTypes.string.isRequired,
};

export default StHelp;
