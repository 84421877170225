import React from 'react';
import { connect } from 'formik';
import PropTypes from 'prop-types';
import { NumberInput } from 'shared/components';

const FormikNumberInput = (props) => {
    const { name, formik } = props;
    const { setFieldValue, setFieldTouched, values } = formik;
    return (
        <NumberInput
            {...props}
            onChange={(value) => {
                setFieldTouched(name);
                setFieldValue(name, parseInt(value, 10));
            }}
            value={_.get(values, name)}
        />
    );
};

FormikNumberInput.propTypes = {
    name: PropTypes.string.isRequired,
    isReadOnly: PropTypes.bool,
    formik: PropTypes.object,
};

FormikNumberInput.defaultProps = {
    formik: {},
    isReadOnly: false,
};

export default connect(FormikNumberInput);
