import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Control } from 'react-redux-form';
import { StLabel, StField, StErrors } from 'shared/components/StForm';
import { validators } from 'shared/validators';
import StreetAddressAutoComplete from 'shared/containers/StreetAddressAutoComplete';

const StreetAddress = (props) => {
    const {
        model,
        isRequired,
        ...rest
    } = props;

    return (
        <div>
            <StLabel htmlFor="streetAddress" isRequired={isRequired} id={`${model}Label`}>
                {_trans('userForm.streetAddress.label')}
            </StLabel>
            <StField>
                <Control.custom
                    {...rest}
                    id="streetAddress"
                    component={StreetAddressAutoComplete}
                    model={model}
                    size={40}
                    maxLength={45}
                    required={isRequired}
                    mapProps={{
                        value: (mapProps) => _.get(mapProps, 'modelValue', ''),
                        onChange: (props) => props.onChange,
                    }}
                    validators={{
                        isRequired: (value) => isRequired ? validators.isRequired(value) : true,
                    }}
                    ariaLabelledBy={`${model}Label`}
                />
                <StErrors model={model} />
            </StField>
        </div>
    );
};

StreetAddress.defaultProps = {
    model: '.streetAddress',
    isRequired: false,
    onChange() {},
    onSelect() {},
};

StreetAddress.propTypes = {
    model: PropTypes.string,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func,
    /**
     * Kun osoite valitaan passataan postinumero ja postitoimipaikka eteenpäin.
     */
    onSelect: PropTypes.func,
};

export default StreetAddress;
