import PropTypes from 'prop-types';
import { TextField } from 'shared/components/Formik';

/**
 * Lomakkeen allekirjoittaja hyvinvointialueen puolelta.
 * MVP:nä nimenselvennys kirjoitetaan itse!
 * @param name
 * @returns {JSX.Element}
 * @constructor
 */
const ReportingFormsSignaturesEmployer = ({ name }) => <TextField name={name} />;

ReportingFormsSignaturesEmployer.propTypes = {
    name: PropTypes.string.isRequired,
};

export default ReportingFormsSignaturesEmployer;
