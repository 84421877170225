import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Accordion as AccessibleAccordion,
} from 'react-accessible-accordion';
import { componentSizes } from 'shared/constants';

export const Accordion = ({ canExpandMultiple, children, preExpanded }) => (
    <AccessibleAccordion
        allowZeroExpanded
        allowMultipleExpanded={canExpandMultiple}
        className="c-accordion"
        preExpanded={preExpanded}
    >
        {children}
    </AccessibleAccordion>
);

Accordion.propTypes = {
    /**
     * Elementtien koko.
     */
    size: PropTypes.oneOf(_.map(componentSizes)),

    /**
     * Can expand multiple accordions.
     */
    canExpandMultiple: PropTypes.bool,

    /**
     * Content.
     */
    children: PropTypes.node.isRequired,

    /**
     * Pre expanded accordion items. Array of uuid's.
     */
    preExpanded: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

Accordion.defaultProps = {
    size: componentSizes.MEDIUM,
    canExpandMultiple: false,
    preExpanded: [],
};
