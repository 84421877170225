
const payPeriodApprovalStatus = {
    /**
     * Odottaa hyväksyntää
     */
    STATUS_PENDING: 10000,
    /**
     * Hyväksytty
     */
    STATUS_APPROVED: 10001,
    /**
     * Hylätty
     */
    STATUS_REJECTED: 9999,
};


const payPeriodApprovalStatusTranslationMap = {
    [payPeriodApprovalStatus.STATUS_PENDING]: _trans('payperiods.approval_status.pending'),
    [payPeriodApprovalStatus.STATUS_APPROVED]: _trans('payperiods.approval_status.approved'),
    [payPeriodApprovalStatus.STATUS_REJECTED]: _trans('payperiods.approval_status.rejected'),
};

export const translatePayPeriodApprovalStatus = (status) =>
    status in payPeriodApprovalStatusTranslationMap ? payPeriodApprovalStatusTranslationMap[status] : _trans('general.unknown');

const payPeriodApprovalStatusBadgeTypeMap = {
    [payPeriodApprovalStatus.STATUS_PENDING]: 'yellow',
    [payPeriodApprovalStatus.STATUS_APPROVED]: 'positive',
    [payPeriodApprovalStatus.STATUS_REJECTED]: 'negative',
};

export const mapPayPeriodApprovalStatusBadgeType = (status) =>
    status in payPeriodApprovalStatusBadgeTypeMap ? payPeriodApprovalStatusBadgeTypeMap[status] : _trans('general.unknown');

export default payPeriodApprovalStatus;
