import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { CollectiveAgreementChoiceField } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/shared/CollectiveAgreementChoiceField';
import { translateHolidayPayMethod } from 'shared/constants/holidayPayMethods';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { parseCustomTranslations } from 'shared/ReForm/utils/parseCustomTranslations';

/**
 * Vapaidenmääräytyminen.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const HolidayPayMethod = (props) => {
    const { attributes: { customTranslations } } = props;

    // Vedetään kustomkäännökset listasta objekteja => objektiksi jolloin arvo saadaan suoraan keyllä
    const options = useMemo(() => parseCustomTranslations(customTranslations), [customTranslations]);

    const hasCustomTranslator = Array.isArray(customTranslations) && customTranslations.length > 0;
    const customTranslator = (type) => type in options ? options[type] : _trans('Tuntematon', {}, 'common');
    const [contractTypeField] = useField(fieldNames.CONTRACT_TYPE);

    return (
        <CollectiveAgreementChoiceField
            primaryType={contractTypeField.value}
            restrictionKey="allowedContractTypesAndHolidayPaymethods"
            translator={hasCustomTranslator
                ? customTranslator
                : translateHolidayPayMethod}
            fieldProps={props}
        />
    );
};

HolidayPayMethod.propTypes = {
    name: PropTypes.string.isRequired,
    attributes: PropTypes.object.isRequired,
};

export default HolidayPayMethod;
