import React from 'react';
import { useField } from 'formik';
import { CollectiveAgreementChoiceField } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/shared/CollectiveAgreementChoiceField';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { translatePayDayMode } from 'shared/constants/payDayModes';

/**
 * Palkkapäivätyyppi.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const PaydayMode = (props) => {
    const [payPeriodLengthField] = useField(fieldNames.PAY_PERIOD_LENGTH);

    return (
        <CollectiveAgreementChoiceField
            primaryType={payPeriodLengthField.value}
            restrictionKey="allowedPayPeriodLengthsAndPayDayModes"
            translator={translatePayDayMode}
            fieldProps={props}
        />
    );
};

export default PaydayMode;
