import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'shared/components';

export const CustomTitle = ({ title, count, type }) => (
    <div className="o-stack">
        <span>{title}</span>
        <Badge
            value={count}
            type={count > 0 ? type : 'neutral'}
            isBall={false}
            modifierClass="u-margin-left-tiny"
        />
    </div>
);

CustomTitle.propTypes = {
    title: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
};
