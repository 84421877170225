import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { List } from 'immutable';
import { select } from '@rematch/select';
import _ from 'lodash';
import {
    ButtonGroup,
    Button,
    ConfirmButton,
    ActionBar,
    LinkIcon,
    Page,
    MDSpinner, Feedback
} from 'shared/components';
import DataTable from 'shared/containers/DataTable';
import resolveUrl from 'shared/utils/resolveUrl';

@connect((state) => ({
    accounts: select.bookkeepingAccount.getAccounts(state),
    postings: select.bookkeepingAccount.getPostings(state),
    isDeleting: _.get(state, 'loading.effects.bookkeepingAccount.deleteAccount', false),
    isLoading: _.get(state, 'loading.effects.bookkeepingAccount.fetchAccounts', false),
}))
export default class BookkeepingAccountList extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        accounts: PropTypes.instanceOf(List),
        postings: PropTypes.instanceOf(List),
        isDeleting: PropTypes.bool,
        routes: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
    };

    static defaultProps = {
        dispatch() {},
        accounts: List(),
        postings: List(),
        isDeleting: false,
        isLoading: false,
    };

    renderAccountList() {
        const { dispatch, isDeleting, routes } = this.props;
        return (
            <Page
                heading={_trans('company_manager.bookkeeping_accounts.heading')}
            >
                <Feedback type="info">
                    {_transMd('company_manager.bookkeeping_accounts.help')}
                </Feedback>
                <ActionBar hasMargin alignItems="right">
                    <LinkIcon mdIcon="add" to={resolveUrl(routes.BOOKKEEPING_ACCOUNT_FORM, { id: 'new' })}>{_trans('company_manager.bookkeeping_accounts.create_new')}</LinkIcon>
                </ActionBar>
                <DataTable
                    data={this.props.accounts.toJS()}
                    columns={[
                        {
                            Header: _trans('company_manager.bookkeeping_accounts.table.number'),
                            accessor: 'number',
                        },
                        {
                            Header: _trans('company_manager.bookkeeping_accounts.table.name'),
                            accessor: 'name',
                        },
                    ]}
                    actionsColumn={<ActionColumn
                        isDeleting={isDeleting}
                        onEdit={(id) => dispatch(push(resolveUrl(routes.BOOKKEEPING_ACCOUNT_FORM, { id })))}
                        onDelete={(id) => dispatch.bookkeepingAccount.deleteAccount(id)}
                    />}
                />
            </Page>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return (
                <MDSpinner wrapped />
            );
        }

        return this.renderAccountList();
    }
}

const ActionColumn = ({ original, onEdit, onDelete, isDeleting }) => (
    <ButtonGroup>
        <Button
            disabled={isDeleting}
            mdIcon="edit"
            onClick={() => onEdit(original.id)}
        >
            {_trans('button.edit')}
        </Button>
        <ConfirmButton
            isPending={isDeleting}
            onClick={() => onDelete(original.id)}
            confirmText={_trans('button.are_you_sure')}
            text={_trans('button.remove')}
        />
    </ButtonGroup>
);
ActionColumn.propTypes = {
    original: PropTypes.object,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool,
};

ActionColumn.defaultProps = {
    original: {},
    isDeleting: false,
};
