export const referenceNumberTypes = {
    /**
     * Oiman viitenumerot
     */
    SUORATYO: 1,

    /**
     * Yrityksen omat viitteet
     */
    COMPANY: 2,

    /**
     * Henkilökohtaiset viittet
     */
    PERSONAL: 3,

    /**
     * Kunnan omat viitteet
     */
    COMMUNE: 4,
};
