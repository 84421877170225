export const paymentTypes = {
    /**
     * Normaali palkkoihin liittyvä maksuerä
     */
    TYPE_PAYROLL: 1,

    /**
     * Maksuerä lisämaksuja vartren esim. TVR -tase/korko
     */
    TYPE_EXTRA_PAYMENT: 10,
};
