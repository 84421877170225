import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { templateSubTypes } from 'ContractV3/Builder/constants/templateTypes';
import { FormField } from 'shared/components/Formik';

// Tämä import paskoo koko pohjaeditorin jossa tämä palikka käytössä
//import { userSelectors } from 'shared/UserDetails/stores/user';

/**
 * Lomakkeen allekirjoittaja hoitajan/työntekijän puolelta.
 * @returns {JSX.Element}
 * @constructor
 */
const ReportingFormsSignaturesEmployee = ({ name, templateSubType }) => {
    const isBuildMode = useSelector((state) => select.contract
        ? select.contract.isBuildMode(state)
        : false);

    const resolvedEmployees = useSelector((state) => {
        if (isBuildMode) return [];
        if (templateSubType === templateSubTypes.FAMILY_CARER) {
            return state.user?.toJS();// userSelectors.getUser(state).toJS();
        }
        return select.contract
            ? select.contract.getEmployees(state)
            : [];
    });
    const employees = Array.isArray(resolvedEmployees) ? resolvedEmployees : [resolvedEmployees];

    if (isBuildMode) {
        return (
            <FormField name={name} isContentFormField={false}>
                <b>Tiedot eivät näkyvissä editorissa.</b>
            </FormField>
        );
    }

    return (
        <FormField name={name} isContentFormField={false}>
            <ul className="o-list-bare">
                {employees.map((employee, key) => (
                    <li key={key}>
                        <strong>{employee?.fullName}</strong>
                    </li>
                ))}
            </ul>
        </FormField>
    );
};

ReportingFormsSignaturesEmployee.propTypes = {
    name: PropTypes.string.isRequired,
    /**
     * Pohjan alatyyppi. Tämän perusteella selvitetään hoitajan/työntekijän tiedot.
     */
    templateSubType: PropTypes.oneOf([templateSubTypes.CONTRACT, templateSubTypes.FAMILY_CARER]).isRequired,
};

export default ReportingFormsSignaturesEmployee;
