import moment from 'moment';
import api from 'api';
import { contractDataTypes } from 'shared/constants/ContractV3/contractDataTypes';

/**
 * Sopimuksen versiot.
 */
export const contractRevisions = {
    state: [],

    reducers: {
        setRevisions: (state, revisions) => revisions,
    },

    selectors: {
        getRevisions: (state) => state.sort((a,b) => new Date(a.dataStartDate) - new Date(b.dataStartDate)) ?? [],
        getLatestMasterData: (state) => {
            const latestMasterData = state
                .filter((contractData) => contractData.jobContractDataType === contractDataTypes.MASTER_VERSION)
                .sort((a,b) => new Date(b.dataStartDate) - new Date(a.dataStartDate));

            return latestMasterData[0] ?? {};
        },
        hasDraftRevisions: (state) => state.some((revision) => revision.jobContractDataType === contractDataTypes.DRAFT),
        hasProposalRevisions: (state) => state.some((revision) => revision.jobContractDataType === contractDataTypes.PROPOSAL),
    },

    effects: () => ({
        /**
         * Hakee sopimuksen versiot.
         * @returns {Promise<*>}
         */
        async fetchRevisions(jobContract, isEmployee) {
            const json = await api
                .get(`/api/v3/contracts/${jobContract}/revisions`);
            const data = json.data ?? []
                .filter(({ jobContractDataType, dataStartDate }) => {
                    if (jobContractDataType === contractDataTypes.ARCHIVED) {
                        return false;
                    }

                    // jos data on proposal tilassa ja sitä ei ole hyväksytty päivää ennen datan alkupäivää ei sitä enää näytetä
                    // työntekijälle
                    return !(isEmployee && jobContractDataType === contractDataTypes.PROPOSAL
                        && moment(dataStartDate).subtract(1, 'd').isBefore(moment()));
                });

            this.setRevisions(data);
            return json;
        },
    }),
};
