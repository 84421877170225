import api from 'api';

export const todos = {
    state: {
        todos: [],
    },

    reducers: {
        setTodos: (state, todos) => ({ ...state, todos })
    },

    selectors: {
        getTodos: (state) => state.todos ?? [],
    },

    effects: {
        async fetchTodos() {
            try {
                const response = await api.get('/api/v2/todos');
                if (response.status === 'ok') {
                    this.setTodos(response.data);
                }
                console.log(response);
            } catch (error) {
                console.error(error);
            }
        }
    },
};
