import { customTypes } from 'shared/containers/DataTable';

export const exportCSV = ({ fileName, headers, rows, exportColumnTotals, columnDelimiter, decimalSeparator }) => {
    const lineDelimiter = '\n';

    const data = rows.reduce((allRowsStr, row) => {
        const columns = row.reduce((acc, column) => {
            const defaultValue = column.defaultValue ?? '';
            const value = column.value;

            // käydään läpi jos löytyy jokin formaatteri datalle, oman voi antaa csvFormatter-propertyllä column datalle.
            switch (column.type) {
                case customTypes.DATE:
                    return acc.concat(`"${_toLocaleDate(value, defaultValue)}"`);
                case customTypes.DATETIME:
                    return acc.concat(`"${_toLocaleDate(value, defaultValue, _dateTimeFormat)}"`);
                case customTypes.CURRENCY:
                    // Koska 0 === false, niin tarkistellaa onko undefined, jottei jää tyhjiä sarakkeita csv
                    return acc.concat(value !== undefined ? `"${value.toString().replace('.', decimalSeparator)}"` : `"${defaultValue}"`);
                // Pakotetaan Excel vääntämään puhelinnumerokentästä string eikä numero.
                case customTypes.PHONE_NUMBER:
                    return acc.concat(value ? `="${value}"` : `"${defaultValue}"`);
                default:
                    if (typeof value === 'string' && ! isNaN(value)) {
                        return acc.concat(value.replace('.', decimalSeparator));
                    } else if (typeof value === 'number') {
                        return acc.concat(value.toString().replace('.', decimalSeparator));
                    } else {
                        return acc.concat(value ? value : defaultValue);
                    }
            }
        }, []);
        return allRowsStr + columns.join(columnDelimiter) + lineDelimiter;
    }, headers.join(columnDelimiter) + lineDelimiter);

    // Jos löytyy totals arvoja, niin lisätään ne pohjalle.
    const footer = Object.keys(exportColumnTotals).length > 0
        ? Object.entries(headers)
            .reduce((acc, [, key]) => {
                const value = (exportColumnTotals[key] ?? '').toString().replace('.', decimalSeparator);
                return acc.concat(`"${value}"`);
            }, [])
            .join(columnDelimiter)
        : '';

    const universalBOM = '\uFEFF';

    // Lisätään BOM, jotta toimii excelissä ääkköset
    const blobParts = [universalBOM + data + footer];
    const link = document.createElement('a');
    // Muodostetaan blob, toimii suurienkien listojen kanssa, esim. ~9k rivejä
    link.href = URL.createObjectURL(new Blob(blobParts, { type: 'text/csv' }));
    document.body.appendChild(link); // Firefox vaatii tämän, että toimii
    link.setAttribute('download', fileName);
    link.click();
};

