import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'shared/components';
import utils from 'shared/utils/commonUtils';
import MDIcon from 'shared/components/MDIcon';

/**
 * Työntekijän valinnassa käytettävä renderöijä.
 * @param focusedOption
 * @param focusOption
 * @param key
 * @param option
 * @param selectValue
 * @param style
 * @returns {*}
 * @constructor
 */
const OptionRenderer = ({ focusedOption, focusOption, key, option, selectValue, style }) => (
    <button
        onClick={() => selectValue(option)}
        onMouseEnter={() => focusOption(option)}
        className={`VirtualizedSelectOption VirtualizedSelectOption${focusedOption === option ? '-has-focus' : ''}`}
        key={key}
        style={style}
    >
        <div className="o-stack">
            <Avatar
                src={_.get(option, 'picture_path', '')}
                fullName={_.get(option, 'fullName', '')}
                initials={_.get(option, 'initials', '')}
                size="small"
                fixedSize
            />
            <span className="u-margin-left-tiny u-text-left">
                <strong className="u-display-block u-line-height-relative u-text-truncate">
                    <span className="u-display-inline-block u-align-middle">{utils.getUserFullName(option)}</span>
                    {(option.isUserIdentified ?? false) && (
                        <MDIcon
                            icon="verified_user"
                            isFilled
                            title={_trans('Tunnistautunut.', {}, 'common')}
                            modifierClass="u-color-positive u-margin-left-tiny u-cursor-default"
                            size="small"
                        />
                    )}
                </strong>
                <div className="c-ssn u-muted">{_.get(option, 'socialSecurityNumber', '-')}</div>
            </span>
        </div>
    </button>
);

OptionRenderer.propTypes = {
    /**
     * Itse valinta ja sen arvot.
     */
    option: PropTypes.object.isRequired,
    /**
     * Valittu valinta.
     */
    focusedOption: PropTypes.object.isRequired,
    /**
     * Mitä tehdään kun valinta saa fokuksen.
     */
    focusOption: PropTypes.func.isRequired,
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /**
     * Mitä tehdään kun valinta... valitaan!
     */
    selectValue: PropTypes.func.isRequired,
    style: PropTypes.object.isRequired,
};

export default OptionRenderer;
