import api from 'api';

export const workTimeTypeDefinitions = {
    state: [],
    reducers: {
        setWorkTimeTypeDefinitions: (state, payload) => payload,
    },
    selectors: {
        getDefinitions: (state) => state,
    },
    effects: (dispatch) => ({
        async fetchWorkTimeTypeDefinitions(payPeriod) {
            if (! payPeriod) {
                return;
            }

            try {
                const json = await api.get(`/api/v2/payperiods/${payPeriod}/work-time-types`);
                this.setWorkTimeTypeDefinitions(json?.data ?? []);
            } catch (e) {
                dispatch.notifications.addError(_trans('Päiväkirjauslajeja ei voitu ladata. Yritä päivittää sivu.', {}, 'extract'));
            }
        }
    })
};
