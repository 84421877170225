import React from 'react';

/**
 * Palvelun sulkemisesta kotitalouksille ja pienyrityksille kertova teksti.
 * @returns {JSX.Element}
 * @constructor
 */
export const ClosingAnnouncement = () => (
    <div className="u-font-size-16">
        {_transMd('closing_announcement.body', true)}
    </div>
);
