import { blocks } from 'shared/ReForm/constants/blocks';

/**
 * Palikan perusominaisuudet joita muut palikkatyypit jatkaa.
 */
export const baseBlockSchema = {
    type: 'object',
    title: 'Palikka',
    properties: {
        block: { enum: Object.values(blocks) },
        // Palikan ohjaaminen attribuuteilla
        attributes: {
            title: 'Ominaisuudet',
            type: 'object',
        },
        dependencies: { '$ref': '#/definitions/dependencies' },
        isVisibleOnSummary: {
            type: 'boolean',
            title: 'Näkyy yhteenvedossa',
        },
        isHiddenOnPrint: {
            type: 'boolean',
            title: 'Piilotettu tulosteilta'
        },
        isHiddenFromRolesOnSummary: {
            type: 'array',
            title: 'Piilotettu yhteevedossa rooleilta',
            description: 'Mitkä palikat eivät näy yhteenvedossa. Yliajaa isVisibleOnSummary-attribuutin',
            items: {
                type: 'string',
                enum: ['employer', 'employee']
            },
            minItems: 0
        },
        style: {
            title: 'Tyylit',
            type: 'object',
            properties: {
                marginTop: {
                    type: ['number', 'string'],
                    title: 'Ylämarginaali',
                },
                marginBottom: {
                    type: ['number', 'string'],
                    title: 'Alamarginaali',
                },
                marginLeft: {
                    type: ['number', 'string'],
                    title: 'Vasen marginaali',
                },
                marginRight: {
                    type: ['number', 'string'],
                    title: 'Oikea marginaali',
                }
            }
        }
    },
    required: ['block'],
};
