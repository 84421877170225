import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LinkIcon from 'shared/components/LinkIcon';

/**
 * Takaisin-linkki.
 */
function GoBackLink({ href, to, text, modifierClass, onClick }) {
    const linkIconClass = classNames('c-button c-button--flat c-button--go-back', {
        [modifierClass]: modifierClass !== '',
    });

    return (
        <LinkIcon
            mdIcon="arrow_back"
            modifierClass={linkIconClass}
            href={href}
            to={to}
            onClick={onClick}
        >
            {text}
        </LinkIcon>
    );
}

GoBackLink.defaultProps = {
    href: '',
    to: '',
    text: _trans('link.back'),
    modifierClass: '',
    onClick() {},
};

GoBackLink.propTypes = {
    /**
     * Linkin osoite. Anna tämä tai to-props.
     */
    href: PropTypes.string,

    /**
     * Linkin osoite routeria käytettäessä.
     */
    to: PropTypes.string,

    /**
     * Linkin teksti.
     */
    text: PropTypes.string,

    /**
     * CSS:n muokkausluokka.
     */
    modifierClass: PropTypes.string,

    onClick: PropTypes.func,
};

export default GoBackLink;
