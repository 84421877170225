import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import UserBlock from 'shared/components/UserBlock';
import { FormField } from 'shared/components/Formik';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';

/**
 * Avustettava. Fiksattu.
 * HA-puolella tulee avustuspäätöksen kautta.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const Beneficiary = (props) => {
    const isBuildMode = useSelector(select.contract.isBuildMode);
    const isCommune = useSelector(select.userMetadata.isCommune);

    // Rakentelumoodissa haetaan vaan eka aktiivinen asiakkuus
    const isLoading = useSelector((state) => isCommune
        ? state.loading.effects.benefitDecision.fetchBenefitDecision
        : state.loading.effects.loggedUser.fetchUserData);

    const isLoadingActiveBenefitDecisions = useSelector((state) => state.loading.effects.benefitDecisions.fetchActiveBenefitDecisions);

    // Muutetaan myöhemmin queryn kautta haettavaksi
    const beneficiary = useSelector(select.contract.getBeneficiary);

    if (beneficiary?.isSelfEmployed) return null;

    return (
        <fieldset>
            <legend>
                {_trans('Avustettava', {}, 'jobContract')}
            </legend>
            <FormField {...getFieldProps(props)} isContentFormField={false}>
                <UserBlock
                    user={beneficiary ?? {}}
                    isLoading={isLoading || (isBuildMode && isLoadingActiveBenefitDecisions) || ! beneficiary}
                    size="small"
                    hasLinkToDetails={isCommune}
                    relationType="customer"
                />
            </FormField>
        </fieldset>
    );
};

Beneficiary.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Beneficiary;
