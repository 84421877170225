import { fromJS } from 'immutable';
import { push } from 'connected-react-router';
import { actions as notificationActions } from 'shared/stores/notifications';
import api from 'api';
import adminRoutes from 'Admin/TradeUnion/routes';
import routes from 'shared/TradeUnion/routes';

export default {
    state: fromJS({
        memberships: {},
        employeeReferenceNumbers: [],
        isReferenceNumbersPage: false,
        isReferenceNumbersEditMode: false,
        isMembershipPage: false,
        isMembershipEditMode: false,
        tradeUnionSubscriptions: [],
        tradeUnionSubscription: [],
        suoratyoReferenceNumbers: [],
        generatedNumbers: {},
        activeTradeUnionSubscriptions: [],
        companyReferenceNumbers: [],
        hasCompanyReferenceNumbers: false,
        hasSuoratyoReferenceNumbers: false,
        hasCommuneReferenceNumbers: false,
        paymentDataForYearError: [],
        communeReferenceNumbers: {},
        adminTradeUnionSubscription: [],
        isUpdatePercentageForm: false,
    }),
    reducers: {
        setMemberships: (state, payload) => state.set('memberships', fromJS(payload)),
        setEmployeeReferenceNumbers: (state, payload) => state.set('employeeReferenceNumbers', fromJS(payload)),
        setMembershipEditMode: (state, payload) => state.set('isMembershipEditMode', payload),
        setReferenceNumbersPage: (state, payload) => state.set('isReferenceNumbersPage', payload),
        setReferenceNumbersEditMode: (state, payload) => state.set('isReferenceNumbersEditMode', payload),
        setTradeUnionSubscriptions: (state, payload) => state.set('tradeUnionSubscriptions', fromJS(payload)),
        setTradeUnionSubscription: (state, payload) => state.set('tradeUnionSubscription', fromJS(payload)),
        setSuoratyoReferenceNumbers: (state, payload) => state.set('suoratyoReferenceNumbers', fromJS(payload)),
        setGeneratedNumbers: (state, payload) => state.set('generatedNumbers', fromJS(payload)),
        setActiveTradeUnionSubscriptions: (state, payload) => state.set('activeTradeUnionSubscriptions', fromJS(payload)),
        setCompanyReferenceNumbers: (state, payload) => state.set('companyReferenceNumbers', fromJS(payload)),
        setHasCompanyReferenceNumbers: (state, payload) => state.set('hasCompanyReferenceNumbers', payload),
        setHasSuoratyoReferenceNumbers: (state, payload) => state.set('hasSuoratyoReferenceNumbers', payload),
        setCommuneReferenceNumbers: (state, payload) => state.set('communeReferenceNumbers', fromJS(payload)),
        setHasCommuneReferenceNumbers: (state, payload) => state.set('hasCommuneReferenceNumbers', payload),
        setPaymentDataForYearError: (state, payload) => state.set('paymentDataForYearError', fromJS(payload)),
        setAdminTradeUnionSubscription: (state, payload) => state.set('adminTradeUnionSubscription', fromJS(payload)),
        setIsUpdatePercentageForm: (state, payload) => state.set('isUpdatePercentageForm', payload),
    },
    selectors: {
        getMemberships: (state) => state.get('memberships', fromJS({})),
        getEmployeeReferenceNumbers: (state) => state.get('employeeReferenceNumbers', fromJS([])),
        isReferenceNumbersPage: (state) => state.get('isReferenceNumbersPage', false),
        isMembershipPage: (state) => state.get('isMembershipPage', false),
        isMembershipEditMode: (state) => state.get('isMembershipEditMode', false),
        isReferenceNumbersEditMode: (state) => state.get('isReferenceNumbersEditMode', false),
        getTradeUnionSubscriptions: (state) => state.get('tradeUnionSubscriptions', fromJS([])),
        getTradeUnionSubscription: (state) => state.get('tradeUnionSubscription', fromJS([])),
        getSuoratyoReferenceNumbers: (state) => state.get('suoratyoReferenceNumbers', fromJS([])),
        getGeneratedNumbers: (state) => state.get('generatedNumbers', fromJS({})),
        hasGeneratedNumbers: (state) => state.get('generatedNumbers').size > 0,
        getActiveTradeUnionSubscriptions: (state) => state.get('activeTradeUnionSubscriptions', fromJS([])),
        getCompanyReferenceNumbers: (state) => state.get('companyReferenceNumbers', fromJS([])),
        hasCompanyReferenceNumbers: (state) => state.get('hasCompanyReferenceNumbers', false),
        hasSuoratyoReferenceNumbers: (state) => state.get('hasSuoratyoReferenceNumbers', false),
        getCommuneReferenceNumbers: (state) => state.get('communeReferenceNumbers', fromJS([])),
        hasCommuneReferenceNumbers: (state) => state.get('hasCommuneReferenceNumbers', false),
        getPaymentDataForYearError: (state) => state.get('paymentDataForYearError', fromJS({})),
        getAdminTradeUnionSubscription: (state) => state.get('adminTradeUnionSubscription', fromJS([])),
        isUpdatePercentageForm: (state) => state.get('isUpdatePercentageForm', false),
    },
    effects: (dispatch) => ({
        /**
         * Hakee käyttäjän ammattiliitot (vain tt)
         *
         * @param userId
         * @returns {Promise<null>}
         */
        async fetchEmployeeTradeUnionMemberships(userId) {
            if (userId < 0) {
                console.error('UserId not valid!');
                return null;
            }

            try {
                const json = await api.get(Routing.generate('api_1_get_user_tradeunionmembership', { user: userId }));
                this.setMemberships(json);
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_membership.notifications.get.error'),
                }));
            }
        },

        /**
         * Lisää käyttäjälle ammattiliiton (vain tt)
         *
         * @param model
         * @param state
         * @param userId
         * @returns {Promise<void>}
         */
        async postEmployeeTradeUnionMembership(model, state, userId) {
            try {
                await api.post(Routing.generate('api_1_post_user_tradeunionmembership', { user: userId }), model);
                this.setMembershipEditMode(false);
                this.fetchEmployeeTradeUnionMemberships(userId);
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_membership.notifications.post.error'),
                }));
            }
        },

        /**
         * Päivitä käyttäjän ammattiliiton jäsenyys (vain tt)
         *
         * @param model
         * @param state
         * @param path
         * @returns {Promise<void>}
         */
        async patchEmployeeTradeUnionMembership(model, state, path) {
            try {
                await api.patch(Routing.generate('api_1_patch_user_tradeunionmembership', { membership: model.membershipId }),
                    { op: 'replace', path, date: model.endDate }
                );
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_membership.notifications.patch.error'),
                }));
            }
        },

        /**
         * Hae ammattiliittoon jäsenyyteen lisätyt viitenumerot vuosi kohtaisesti (vain tt)
         *
         * @param membershipId
         * @param state
         * @param year
         * @returns {Promise<void>}
         */
        async fetchEmployeeTradeUnionReferenceNumbers(membershipId, state, year) {
            try {
                this.setPaymentDataForYearError(fromJS({}));
                const json = await api.get(
                    Routing.generate('api_1_get_employee_trade_union_membership_reference_numbers', { membership: membershipId, year })
                );
                this.setEmployeeReferenceNumbers(_.get(json, 'data.referenceNumbers'));
                this.setHasCompanyReferenceNumbers(_.get(json, 'data.hasCompanyReferenceNumbers', false));
                this.setHasSuoratyoReferenceNumbers(_.get(json, 'data.hasSuoratyoReferenceNumbers', false));
                this.setHasCommuneReferenceNumbers(_.get(json, 'data.hasCommuneReferenceNumbers', false));
            } catch (e) {
                console.error(e);
                this.setPaymentDataForYearError({ 'message': e.message });
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: e.message ? e.message : _trans('trade_union_membership.reference_numbers.fetch_error'),
                }));
            }
        },

        /**
         * Lisää viitenumerot käyttäjän ay-jäsenyyteen
         *
         * @param model
         * @param state
         * @param membershipId
         * @param userId
         * @returns {Promise<void>}
         */
        async postEmployeeTradeUnionReferenceNumbers(model, state, [userId, membershipId]) {
            try {
                await api.post(Routing.generate(
                    'api_1_post_employee_trade_union_reference_number', { user: userId, membership: membershipId }), model
                );
                await this.setReferenceNumbersPage(false);
                dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('trade_union_membership.reference_numbers.post_success'),
                }));
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_membership.reference_numbers.post_error'),
                }));
            }
        },

        /**
         * Muokkaa käyttäjän ay-jäsenyyden viitenumeroita
         *
         * @param model
         * @param state
         * @param membershipId
         * @returns {Promise<void>}
         */
        async putEmployeeTradeUnionReferenceNumbers(model, state, membershipId) {
            try {
                await api.put(Routing.generate('api_1_put_employee_trade_union_reference_numbers', { membership: membershipId }), model);
                await this.setReferenceNumbersPage(false);
                dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('trade_union_membership.reference_numbers.post_success'),
                }));
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_membership.reference_numbers.post_error'),
                }));
            }
        },

        /**
         * Lisää Oiman viitenumerot ay-liittoon (vain admin)
         *
         * @param model
         * @param state
         * @param subscription
         * @returns {Promise<void>}
         */
        async postSuoratyoTradeUnionReferenceNumbers(model, state, subscription) {
            try {
                await api.post(Routing.generate(
                    'admin_api_post_suoratyo_trade_union_reference_number', { subscription }), model
                );
                await dispatch(push(adminRoutes.BASE));
                dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('trade_union_membership.reference_numbers.post_success'),
                }));
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_membership.reference_numbers.post_error'),
                }));
            }
        },

        /**
         * Päivittää SuoraTyon hallinnoimat viitenumerot ay-liittoon (vain admin)
         *
         * @param model
         * @param state
         * @param subscription
         * @returns {Promise<void>}
         */
        async putSuoratyoTradeUnionReferenceNumbers(model, state, subscription) {
            try {
                await api.put(Routing.generate('admin_api_put_suoratyo_trade_union_reference_numbers', { subscription }), model);
                await dispatch(push(adminRoutes.BASE));
                dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('trade_union_membership.reference_numbers.post_success'),
                }));
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_membership.reference_numbers.post_error'),
                }));
            }
        },

        /**
         * Lisää yrityksen viitenmuerot ay-liittoon.
         *
         * @param model
         * @param state
         * @param subscription
         * @returns {Promise<void>}
         */
        async postCompanyTradeUnionReferenceNumbers(model, state, subscription) {
            try {
                await api.post(Routing.generate(
                    'api_1_post_company_trade_union_reference_number', { subscription }), model
                );
                await dispatch(push(routes.COMPANY_TRADE_UNION_LIST));
                dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('trade_union_membership.reference_numbers.post_success'),
                }));
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_membership.reference_numbers.post_error'),
                }));
            }
        },

        /**
         * Päivittää ay-liiton viitenumerot
         *
         * @param model
         * @param state
         * @param subscription
         * @returns {Promise<object>}
         */
        async putTradeUnionReferenceNumbers(model, state, subscription) {
            try {
                const json = await api.put(Routing.generate('api_1_put_trade_union_reference_number', { subscription }), model);
                dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('trade_union_membership.reference_numbers.post_success'),
                }));
                return json;
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_membership.reference_numbers.post_error'),
                }));
                return Promise.reject(e);
            }
        },

        /**
         * Lisää kunnan viitenmuerot ay-liittoon.
         *
         * @param model
         * @param state
         * @param subscription
         * @returns {Promise<void>}
         */
        async postCommuneTradeUnionReferenceNumbers(model, state, subscription) {
            try {
                await api.post(Routing.generate(
                    'api_1_post_commune_trade_union_reference_number', { subscription }), model
                );
                await this.setReferenceNumbersPage(false);
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_membership.reference_numbers.post_error'),
                }));
            }
        },


        /**
         * Hae ay-liitot (vain admin)
         *
         * @returns {Promise<void>}
         */
        async fetchTradeUnionSubscriptions() {
            try {
                const json = await api.get(Routing.generate('admin_api_get_trade_union_subscriptions'));
                this.setTradeUnionSubscriptions(json);
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_subscription.notifications.fetch.error'),
                }));
            }
        },

        /**
         * Lisää uuusi ay-liitto (vain admin)
         *
         * @param model
         * @returns {Promise<void>}
         */
        async postTradeUnionSubscription(model) {
            try {
                await api.post(Routing.generate('admin_api_post_trade_union_subscription'), model);
                await this.fetchTradeUnionSubscriptions();
                await dispatch(push(adminRoutes.BASE));
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: e.message ? e.message : _trans('trade_union_subscription.notifications.post.error'),
                }));
            }
        },

        /**
         * Muokkaa ay-littoa (vain admin)
         *
         * @param model
         * @param state
         * @param subscription
         * @returns {Promise<void>}
         */
        async putTradeUnionSubscription(model, state, subscription) {
            try {
                await api.put(Routing.generate('admin_api_put_trade_union_subscription', { subscription }), model);
                await this.fetchTradeUnionSubscriptions();
                await dispatch(push(adminRoutes.BASE));
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_subscription.notifications.put.error'),
                }));
            }
        },

        /**
         * Hae ay-liiton data (vain admin)
         *
         * @param subscription
         * @param state
         * @param year
         * @returns {Promise<void>}
         */
        async fetchAdminTradeUnionSubscription(subscription, state, year = null) {
            try {
                this.setPaymentDataForYearError(fromJS({}));
                const json = await api.get(Routing.generate('admin_api_get_trade_union_subscription', { subscription, year }));
                this.setAdminTradeUnionSubscription(json.data.subscription);
            } catch (e) {
                console.error(e);
                this.setPaymentDataForYearError({ 'message': e.message });
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_subscription.notifications.put.error'),
                }));
            }
        },

        /**
         * Hae ay-liiton data
         *
         * @param subscription
         * @param state
         * @param year
         * @returns {Promise<void>}
         */
        async fetchTradeUnionSubscription(subscription, state, year = null) {
            try {
                this.setPaymentDataForYearError(fromJS({}));
                const json = await api.get(Routing.generate('api_1_get_trade_union_subscription', { subscription, year }));
                this.setTradeUnionSubscription(json.data.subscription);
            } catch (e) {
                console.error(e);
                this.setPaymentDataForYearError({ 'message': e.message });
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_subscription.notifications.put.error'),
                }));
            }
        },

        /**
         * Hae ammattiliittoon lisätyt viitenumerot vuosi kohtaisesti (vain admin)
         *
         * @param subscription
         * @param state
         * @param year
         * @returns {Promise<void>}
         */
        async fetchSuoratyoTradeUnionReferenceNumbers(subscription, state, year) {
            try {
                const json = await api.get(Routing.generate('admin_api_get_suoratyo_trade_union_reference_numbers', { subscription, year }));
                this.setSuoratyoReferenceNumbers(json.data);
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_membership.reference_numbers.fetch_error'),
                }));
            }
        },

        /**
         * Hae aktiiviset ammattiliitot
         * @returns {Promise<void>}
         */
        async fetchActiveTradeUnionSubscriptions() {
            try {
                const json = await api.get(Routing.generate('api_1_get_active_trade_union_subscriptions'));
                this.setActiveTradeUnionSubscriptions(json.data);
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_subscription.notifications.fetch.error'),
                }));
            }
        },

        /**
         * Hae yrityksen ammattiliittoon lisätyt viitenumerot vuosi kohtaisesti
         *
         * @param subscription
         * @param state
         * @param year
         * @returns {Promise<void>}
         */
        async fetchCompanyTradeUnionReferenceNumbers(subscription, state, year) {
            try {
                const json = await api.get(Routing.generate('api_1_get_company_trade_union_reference_numbers', { subscription, year }));
                this.setCompanyReferenceNumbers(json.data);
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_membership.reference_numbers.fetch_error'),
                }));
            }
        },

        /**
         * Hae kunnan ammattiliittoon lisätyt viitenumerot vuosi kohtaisesti
         *
         * @param subscription
         * @param state
         * @param year
         * @returns {Promise<void>}
         */
        async fetchCommuneTradeUnionReferenceNumbers(subscription, state, year) {
            try {
                const json = await api.get(Routing.generate('api_1_get_commune_trade_union_reference_numbers', { subscription, year }));
                this.setCommuneReferenceNumbers(json.data);
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('trade_union_membership.reference_numbers.fetch_error'),
                }));
            }
        },

        /**
         * Tallentaa AY-liitolle tilinumeron aktiiviseen maksutietoon jos sellaista ei jo ole
         *
         * @param model
         * @param state
         * @param subscription
         * @returns {Promise<void>}
         */
        async postTradeUnionAccountNumber(model, state, subscription) {
            // poistetaan tilinumerosta välilyönnit
            model.accountNumber = model.accountNumber.replace(/ /g, '');

            try {
                await api.post(Routing.generate(
                    'api_1_post_trade_union_subscription_account_number', { subscription }), model
                );
                await this.fetchTradeUnionSubscription(subscription);
                await dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('trade_union_subscription.notifications.account_number_saved'),
                }));
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: e.message
                        ? _trans(e.message)
                        : _trans('trade_union_subscription.notifications.account_number_save_error'),
                }));
            }
        },

        resetTradeUnionSubscription() {
            dispatch.tradeUnion.setTradeUnionSubscription(fromJS([]));
            dispatch.tradeUnion.setPaymentDataForYearError(fromJS({}));
            dispatch.tradeUnion.setAdminTradeUnionSubscription(fromJS([]));
        },

        resetReferenceNumbers() {
            dispatch.tradeUnion.setEmployeeReferenceNumbers(fromJS([]));
            dispatch.tradeUnion.setSuoratyoReferenceNumbers(fromJS([]));
            dispatch.tradeUnion.setCompanyReferenceNumbers(fromJS([]));
            dispatch.tradeUnion.setCommuneReferenceNumbers(fromJS([]));
            dispatch.tradeUnion.setHasCompanyReferenceNumbers(false);
            dispatch.tradeUnion.setHasCommuneReferenceNumbers(false);
            dispatch.tradeUnion.setHasSuoratyoReferenceNumbers(false);
        },

        resetGeneratedNumbers() {
            dispatch.tradeUnion.setGeneratedNumbers(fromJS({}));
        },
    })
};
