import { blocks } from 'shared/ReForm/constants/blocks';

/**
 * Pääryhmäpalikka.
 */
export const groupSchema = {
    type: 'object',
    properties: {
        block: { const: blocks.GROUP },
        heading: {
            title: 'Ryhmän otsikko',
            '$ref': '#/definitions/translation',
        },
        // Ryhmän alta löydyttävä läjä muita palikoita.
        blocks: {
            '$ref': '#/definitions/blocks'
        },
        isGroupNumberVisible: {
            title: 'Näytetäänkö ryhmänumeroa',
            type: 'boolean',
        },
    },
    required: ['block', 'heading', 'blocks']
};
