import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import { AttachmentUploadForm } from './AttachmentUploadForm';
import { SelectedAttachments } from './SelectedAttachments';
import { Dialog } from 'shared/components/Dialog';
import { Button } from 'shared/components';
import FormikErrors from 'shared/components/Formik/FormikErrors';
import PublicAttachments from 'shared/ReForm/containers/CustomBlocks/ContractV3/Summary/Attachments';
import {
    AttachmentsDisclaimer
} from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Attachments/AttachmentsDisclaimer';

/**
 * Liitepalikka. Uuden liitteen lisäämislomake avautuu dialogiin.
 * @param name
 * @param attributes
 * @returns {JSX.Element}
 * @constructor
 */
const Attachments = ({ name, attributes = {} }) => {
    const [field,,helpers] = useField(name);
    const isLoading = useSelector((state) => state.loading.effects.attachments.fetchPublicAttachments);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const isBuildMode = useSelector(select.contract.isBuildMode);

    if (isLoading) return null;

    const attachments = field.value ?? [];
    const {
        canUploadAttachments = false,
        canUploadPersonalAttachments = false
    } = attributes;

    const isPublic = canUploadPersonalAttachments === false && !isBuildMode;

    const title = isPublic
        ? _trans('Lisää yleinen liite', {}, 'jobContract')
        : _trans('Lisää henkilökohtainen liite', {}, 'jobContract');
    const buttonText = isPublic
        ? _trans('Lisää yleinen liite', {}, 'jobContract')
        : _trans('Lisää henkilökohtainen liite', {}, 'jobContract');

    return (
        <Fragment>
            {(canUploadAttachments === false || isBuildMode) && (
                <SelectedAttachments
                    attachments={attachments}
                    onDelete={(id) => {
                        helpers.setValue(field.value.filter((attachment) => attachment.id !== id));
                    }}
                />
            )}
            {/*VÄLIAIKAINEN: Rakentelumoodissa voi lisätä julkisia liitteitä (nämä lisättävä nyt käsin publicAttachments-attribuuttina*/}
            {(canUploadPersonalAttachments === false && canUploadAttachments) && (
                <Fragment>
                    <div className="u-margin-bottom-small">
                        {attachments.length > 0
                            ? _transMd('Sopimuksella on **%count%** liitettä.', { count: attachments.length }, 'jobContract')
                            : _trans('Sopimuksella ei ole liitteitä.', {}, 'jobContract')}
                    </div>
                    {attachments.length > 0 && (
                        <PublicAttachments value={attachments} />
                    )}
                    <AttachmentsDisclaimer />
                </Fragment>
            )}
            {isBuildMode && (
                <Fragment>
                    <Button
                        mdIcon="attach_file"
                        preventDefault
                        onClick={() => {
                            setIsDialogOpen(true);
                        }}
                    >
                        {buttonText}
                    </Button>
                    <Dialog
                        isOpen={isDialogOpen}
                        maxWidth={600}
                        title={title}
                        onClose={() => setIsDialogOpen(false)}
                    >
                        <AttachmentUploadForm
                            isPublic={isPublic}
                            onCloseDialog={() => setIsDialogOpen(false)}
                            onUploadComplete={(attachment) => {
                                setIsDialogOpen(false);
                                // Formikin stateen vain id:t
                                helpers.setValue([
                                    ...attachments,
                                    attachment
                                ]);
                            }}
                            onUploadFailed={() => setIsDialogOpen(false)}
                        />
                    </Dialog>
                </Fragment>
            )}
            <FormikErrors name={name} />
        </Fragment>
    );
};

Attachments.propTypes = {
    name: PropTypes.string.isRequired,
    attributes: PropTypes.object,
};

Attachments.defaultProps = {
    attributes: {
        canUploadPersonalAttachments: false,
    },
};

export default Attachments;
