import React from 'react';
import PropTypes from 'prop-types';

const Separator = ({ title }) => (
    <div className="separator" role="presentation">
        {(title && title !== '') && <span>tai</span>}
        <hr/>
    </div>
);

Separator.propTypes = {
    title: PropTypes.string
};

Separator.defaultProps = {
    title: '',
};

export default Separator;
