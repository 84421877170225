import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'shared/components/Dropdown';

/**
 * Filtterin valinta alasvetovalikosta.
 * @param filterValue
 * @param setFilter
 * @param optionLabelKey
 * @param optionValueKey
 * @param filterOptions
 * @returns {JSX.Element}
 * @constructor
 */
export const DropdownFilter = ({ column: { filterValue, setFilter, optionLabelKey, optionValueKey, filterOptions } }) => (
    <Dropdown
        placeholder={_trans('text.all')}
        isFullWidth
        options={filterOptions}
        optionLabel={optionLabelKey ?? 'label'}
        optionValue={optionValueKey ?? 'value'}
        onChange={(value) => setFilter(value || undefined)}
        value={filterValue}
    />
);

DropdownFilter.propTypes = {
    column: PropTypes.shape({
        filterValue: PropTypes.string,
        setFilter: PropTypes.func.isRequired,
        optionLabelKey: PropTypes.string,
        optionValueKey: PropTypes.string,
        filterOptions: PropTypes.array.isRequired,
    }).isRequired,
};
