import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { alignments } from '../constants/';
import { Choice } from 'shared/components';
import eventKeys from 'shared/constants/eventKeys';

export default class ChoiceList extends Component {
    static propTypes = {
        /**
         * Kysymyksen vaihtoehdot.
         */
        options: PropTypes.array.isRequired,

        /**
         * Mitä tehdään kun kysymykseen vastataan.
         */
        onChange: PropTypes.func,

        /**
         * Käytetäänkö pikanäppäimiä.
         */
        canUseAccessKey: PropTypes.bool,

        /**
         * Mahdollinen arvo.
         */
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array, PropTypes.bool]),

        /**
         * Onko listalla kuvia.
         */
        hasPictures: PropTypes.bool,

        /**
         * Miten lista on järjestetty: pystyyn vai vaakaan.
         */
        alignment: PropTypes.oneOf(_.map(alignments)),

        /**
         * CSS-muutosluokka
         */
        modifierClass: PropTypes.string,

        /**
         * Listan rivin CSS-muutosluokka.
         */
        itemModifierClass: PropTypes.string,

        /**
         * Komponentin yksilöivä tunniste. Käytössä pääsääntöisesti Inquisitorissa näppisnavigointiin.
         */
        id: PropTypes.string.isRequired,

        /**
         * Millä avaimella valinnan arvo haetaan.
         */
        valueKey: PropTypes.string,

        /**
         * Millä avaimella valinnan teksti haetaan.
         */
        labelKey: PropTypes.string,

        /**
         * Palautetaanko koko objekti valittaessa eikä vain arvoa.
         */
        hasOptionAsValue: PropTypes.bool,

        /**
         * Voidaanko valita useampi.
         */
        hasMultipleChoices: PropTypes.bool,

        /**
         * Listan labelin id.
         */
        ariaLabelledBy: PropTypes.string,

        /**
         * Listan tarkoituksen selittävän elementin id.
         */
        ariaDescribedBy: PropTypes.string,
    };

    static defaultProps = {
        onChange() {},
        canUseAccessKey: false,
        value: null,
        hasPictures: false,
        modifierClass: '',
        itemModifierClass: '',
        alignment: alignments.VERTICAL,
        valueKey: 'value',
        labelKey: 'label',
        hasOptionAsValue: false,
        hasMultipleChoices: false,
        ariaLabelledBy: undefined,
        ariaDescribedBy: undefined,
    };

    constructor(props) {
        super(props);
        const { value, options, valueKey, } = props;

        // Aseta fokus jos listalla on jo valinta.
        const currentFocusIndex = (value !== null) ? _.findIndex(options, (option) => _.get(option, valueKey) === value) : 0;

        this.state = {
            multipleChoices: options.map((option, index) => ({
                index,
                selected: _.get(value, index) === _.get(option, valueKey)
            })),
            currentFocusIndex,
        };
    }

    getChoiceId = (index) => `${this.props.id}-${index}`;
    getElementAtIndex = (index) => document.getElementById(this.getChoiceId(index));

    /**
     * Tutkitaan mitä näppäintä on painettu:
     * - ylös/alas -näppäimillä liikutaan valintojen välillä
     * - enterillä vahvistetaan valnita
     * @param event
     */
    onKeyDown = (event) => {
        const {
            options,
            onChange,
            valueKey,
            canUseAccessKey,
        } = this.props;

        const { currentFocusIndex } = this.state;
        const optionValue = _.get(options[currentFocusIndex], valueKey, null);

        const eventKey = event.key;
        if (! canUseAccessKey) {
            if (eventKey === eventKeys.ENTER) {
                onChange(optionValue);
            }
        }
        // console.log(eventKey);

        // Jotta voidaan ohjelmallisesti fokusoida haluttuun elementtiin nuolinäppäimillä on
        // näppäinten oletustoimintotapa estettävä (useimmiten scrollaaminen).
        if (_.includes([eventKeys.ARROW_UP, eventKeys.ARROW_DOWN], eventKey)) {
            event.preventDefault();
            event.stopPropagation();
        }

        switch (eventKey) {
            // Edelliseen vastausvaihtoehtoon siirtyminen joko nuoli ylös -napilla.
            case eventKeys.ARROW_UP:
                this.moveChoiceFocus(-1);
                break;

            // Seuraavaan vastausvaihtoehtoon siirtyminen joko nuoli alas- napilla.
            case eventKeys.ARROW_DOWN:
                this.moveChoiceFocus(1);
                break;

            case eventKeys.ENTER:
                if (_.has(options, currentFocusIndex)) {
                    onChange(optionValue);
                }
                break;
        }
    };

    /**
     * Palautetaan joko valittu arvo tai koko valittu objekti.
     */
    onChange = (index) => {
        const {
            options,
            onChange,
            value,
            valueKey,
            hasOptionAsValue,
            hasMultipleChoices,
        } = this.props;

        const option = options[index];
        const optionValue = _.get(option, valueKey, null);
        const selectedValue = hasOptionAsValue ? option : optionValue;

        // Monivalinta
        if (hasMultipleChoices) {
            // Jos alkuarvo ei ole taulukko pusketaan arvo taulukkona eteenpäin.
            if (! Array.isArray(value)) {
                onChange([{
                    [valueKey]: selectedValue,
                }]);
                return;
            }

            // Löytyykö valittu arvo jo aiemmin valituista?
            const optionIndex = _.findIndex(value, (item) => _.get(item, valueKey) === selectedValue);

            if (optionIndex > -1) {
                // Löytyy. Poistetaan.
                onChange([...value.filter((item, key) => key !== optionIndex)]);
            } else {
                // Ei löydy. Lisätään mukaan.
                onChange([...value, {
                    [valueKey]: selectedValue,
                }]);
            }
        } else {
            onChange(selectedValue);
        }
    };

    /**
     * Asetetaan fokuksen indeksi oikeaan valintaan.
     * @param index
     */
    onChoiceFocus = (index) => {
        this.setChoiceFocus(index);
    };

    /**
     * Aseta valinnan fokus indeksin kohtaan.
     * @param index
     */
    setChoiceFocus = (index) => {
        const element = this.getElementAtIndex(index);
        if (element) {
            element.focus();

            this.setState({
                currentFocusIndex: index,
            });
        }
    };

    /**
     * Siirrä focus DOMissa oikeaan elementtiin ja blurraa aiempi.
     * @param amount
     */
    moveChoiceFocus = (amount) => {
        const { options } = this.props;
        const { currentFocusIndex } = this.state;

        let index = currentFocusIndex + amount;
        if (index < 0) index = options.length - 1;
        if (index > options.length - 1) index = 0;

        this.setChoiceFocus(index);
    };

    render() {
        const {
            options,
            canUseAccessKey,
            value,
            hasPictures,
            modifierClass,
            itemModifierClass,
            alignment,
            valueKey,
            labelKey,
            hasMultipleChoices,
            id,
            ariaLabelledBy,
            ariaDescribedBy,
        } = this.props;

        const {
            currentFocusIndex,
        } = this.state;

        if (! options.length) {
            console.error('No options given!');
        }

        if (! id) {
            console.error('Id not set!');
        }

        const isInline = (alignment === alignments.INLINE);

        const choiceListClass = classNames('o-list-bare u-margin-bottom-none', {
            'c-choice-list--vertical': alignment === alignments.VERTICAL,
            'c-choice-list--horizontal o-pack': alignment === alignments.HORIZONTAL && !isInline,
            'u-display-inline-block': isInline,
            'o-layout': hasPictures,
            'c-choice-list': !hasPictures,
            [modifierClass]: modifierClass !== '',
        });

        const choiceClass = classNames('o-list-bare__item', {
            'o-layout__item u-1/3': hasPictures,
            'u-margin-bottom-small': alignment === alignments.VERTICAL,
            'o-pack__item': alignment === alignments.HORIZONTAL && !isInline,
            'u-display-inline-block': isInline,
            'u-padding-right-small': alignment === alignments.HORIZONTAL || isInline,
        });

        const choiceList = options.map((option, key) => {
            const accessKey = _.get(option, 'accessKey', String.fromCharCode(key + 65)); // Aloitetaan accessKeyt A-kirjaimesta jos ei ole annettu
            const optionLabel = _.get(option, labelKey, '-');
            const optionDescription = _.get(option, 'description', '');
            const optionValue = _.get(option, valueKey, null);
            const optionPicture = _.get(option, 'picture', null);
            const isSelected = hasMultipleChoices
                ? Array.isArray(value) && value.findIndex((item) => _.get(item, valueKey) === optionValue) !== -1
                : optionValue === value;
            const isAdmin = _.get(option, 'isAdmin', false);

            const choiceProps = canUseAccessKey
                // Annetaan fokus vain jos tabIndex vastaa nykyistä indeksiä.
                ? { tabIndex: currentFocusIndex === key ? 0 : -1 }
                : {};

            return (
                <li className={choiceClass} key={optionValue}>
                    <Choice
                        isDisabled={option.isDisabled ?? false}
                        id={this.getChoiceId(key)}
                        name={id}
                        choiceAccessKey={accessKey}
                        label={optionLabel}
                        description={optionDescription}
                        onClick={() => this.onChange(key)}
                        onKeyDown={this.onKeyDown}
                        onFocus={() => this.onChoiceFocus(key)}
                        canUseAccessKey={canUseAccessKey}
                        showAccessKey
                        isSelected={isSelected || false}
                        picture={optionPicture}
                        modifierClass={classNames(itemModifierClass, {
                            'u-display-block': ! optionPicture,
                            'o-stack': alignment === alignments.VERTICAL,
                            'u-text-no-wrap u-text-center': alignment === alignments.HORIZONTAL,
                            'u-color-admin': isAdmin,
                        })}
                        {...choiceProps}
                    />
                </li>
            );
        });

        return (
            <ol
                aria-labelledby={ariaLabelledBy}
                aria-describedby={ariaDescribedBy}
                className={choiceListClass}
            >
                {choiceList}
            </ol>
        );
    }
}
