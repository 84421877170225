import React from 'react';
import PropTypes from 'prop-types';

/**
 * Yläpalkin navigaatio.
 */
const MenuBar = ({ children, ...restProps }) => (
    <ul
        className="c-menu-bar o-list-bare o-stack o-stack--start u-margin-bottom-none"
        role="menubar"
        {...restProps}
    >
        {children}
    </ul>
);

MenuBar.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MenuBar;
