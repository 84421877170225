import BookkeepingPostingsList from '../containers/BookkeepingPostingsList';
import { resolveUrl } from 'shared/utils';

const resolvePostingRoutes = (groups, route) => groups.map((group) => ({
    title: group.name,
    // Tämän ryhmän route
    path: resolveUrl(route, { id: group.id, }),
    // Dynaaminen route, josta saadan param kautta id (Ei tarvi kikkailla urlista href.split('/')[href.split('/').length-1])
    dynamicPath: route,
    component: BookkeepingPostingsList,
    exact: true,
}));

export default resolvePostingRoutes;
