import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { componentSizes } from 'shared/constants';

function CardBody({ children, hasPadding, paddingSize }) {
    return (
        <div
            className={classNames('c-card__body', {
                'o-space': hasPadding === true,
                'u-padding-tiny': paddingSize === componentSizes.TINY,
                'u-padding-small': paddingSize === componentSizes.SMALL,
                'u-padding-medium': paddingSize === componentSizes.MEDIUM,
                'u-padding-large': paddingSize === componentSizes.LARGE,
                'u-padding-huge': paddingSize === componentSizes.HUGE,
            })}
        >
            {children}
        </div>
    );
}

CardBody.propTypes = {
    /**
     * Kortin sisältö.
     */
    children: PropTypes.node.isRequired,

    /**
     * Onko kortin sisällöllä ilmaa ympärillään.
     */
    hasPadding: PropTypes.bool,
    /**
     * Paddingin koko
     */
    paddingSize: PropTypes.string,
};

CardBody.defaultProps = {
    hasPadding: false,
    paddingSize: '',
};

export default CardBody;
