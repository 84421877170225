import _transObj from 'shared/utils/_transObj';
import { getBlockProps } from 'shared/ReForm/utils/getBlockProps';
import { parseOptions } from 'shared/ReForm/utils/parseOptions';

/**
 * Kääntää palikan yleisimmät tekstit ja päättelee onko palikka pakollinen.
 * Näitä käytetään FormField:ssä.
 * @param block
 * @param rootName - Käytetään koko nimen resolvointiin
 */
export const parseBlockProperties = (block, rootName = '') => {
    const { type, component, ...rest } = block;

    // Jos kyseessä on custom-komponentti tutkitaan löytyykö siltä jo valmiiksi annettuja proppeja
    const { name, attributes, label, tooltip, inputLabel, inputPostfix, defaultValue, helpText, placeholder, validations, dependencies, blocks, isValueFixed } = getBlockProps(block);

    // Kenttä on vaadittu mikäli:
    // 1. "required" löytyy listalta
    // 2. "required" on listan objektin avaimena
    // 3. Kentällä on riippuvuuksia (dependencies) mutta ei validaatioita
    const hasDependenciesButNoValidations = ! validations && (typeof dependencies === 'object');

    const hasRequired = (validations ?? []).some((validation) => {
        const hasRequiredString = typeof validation === 'string' && validation === 'required';
        const isValidationObject = typeof validation === 'object';
        const hasRequiredKeyInObject = isValidationObject && Object.keys(validation).includes('required');
        // Required löytyy jostain when, then syövereistä
        const hasRequiredKeyInWhenThen = isValidationObject && validation.when?.then.includes('required');

        return hasRequiredString || hasRequiredKeyInObject || hasRequiredKeyInWhenThen;
    });

    const options = parseOptions(block.options);

    return {
        ...rest,
        options,
        attributes,
        placeholder: _transObj(placeholder || {}),
        name: `${rootName}${name}`,
        originalName: name,
        label: _transObj(label || {}),
        inputLabel: _transObj(inputLabel || {}),
        inputPostfix: _transObj(inputPostfix || {}),
        defaultValue,
        tooltip: _transObj(tooltip || {}),
        helpText: _transObj(helpText || {}),
        isRequired: hasRequired || hasDependenciesButNoValidations,
        isValueFixed,
        blocks,
    };
};


