import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import types from 'shared/constants/collectiveAgreementVariableTypes';
import {
    CheckboxField,
    DecimalField,
    FormField,
    HiddenField,
    IntegerField,
    RadioListField
} from 'shared/components/Formik';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import validators, { errorMessages } from 'shared/validators';
import { decimalInputTypes } from 'ContractV3/schemas/custom/CollectiveAgreementVariable.schema';
import { parseOptions } from 'shared/ReForm/utils/parseOptions';
import Feedback from 'shared/components/Feedback';
import { Value } from 'shared/components/Value';
import { boolToText } from 'shared/utils/commonUtils';

/**
 * Yksittäinen TES-muuttuja.
 * @returns {JSX.Element}
 * @constructor
 * @param props
 */
const Variable = (props) => {
    const { name, originalName, attributes = {} } = props;
    const isAcceptedContract = useSelector(select.contract.isAcceptedContract);
    const isLoading = useSelector((state) => state.loading.effects.collectiveAgreement.fetchCollectiveAgreements);
    const namePieces = name.split('.');
    const variableName = namePieces[1] ?? '';
    const { variable } = useSelector((state) => select.contract.getCollectiveAgreementVariableByKey(state, variableName));

    const fieldProps = getFieldProps(props);
    const [field] = useField(name);

    if (isLoading || ! variable) return null;

    const { type } = variable;
    const formFieldLabel = fieldProps.label || _trans('contract.form.tes_variables.' + variable.name + '.label');
    const label = fieldProps.label || _trans('contract.form.tes_variables.' + variable.name + '.text');

    const translationKey = 'contract.form.tes_variables.' + variable.name + '.tooltip';
    const translation = _trans(translationKey);
    // Käytetään TES-variablelta mahdollisesti löytyvää käännöstä ellei helppitekstiä ole eksplisiittisesti asetettu nulliksi pohjassa.
    const hasHelpText = _trans(translationKey) !== translationKey && fieldProps.helpText !== null;

    // Joko pohjasta tuleva aputeksti tai staattinen käännös
    const helpText = hasHelpText ? translation : null;

    // Arvoa ei ole vielä asetettu joten ei voi vielä renderöidä. Tähän olis mukava saada joku järkevämpi ratkaisu
    // kun ei tiedetä mitä bäkkäri palauttaa joten alkuarvot on asetettava vain tyhjäksi stringiksi.
    if (field.value === undefined) return null;

    const { isModifiable = true, isHidden = false, defaultValue } = variable ?? {};

    // Alkuarvo asetetaan jo generateInitialValues:ssa
    if (isHidden) {
        return null;
    }

    // Tyypin mukaan renderöinti. Asetetaan myös oletusarvo jos sitä ei initialValues:sta löydy.
    // Jos muuttuja ei ole muokattavissa näytetään vain oletusarvo.
    switch (type) {
        case types.BOOLEAN: {
            return (
                <FormField label={formFieldLabel} name={name} helpText={helpText} isContentFormField={isModifiable}>
                    {isModifiable
                        ? <CheckboxField name={name} label={label} />
                        : (
                            <Value ariaDescribedBy={name}>
                                {boolToText(defaultValue)}
                            </Value>
                        )
                    }
                </FormField>
            );
        }

        case types.DECIMAL:
        case types.DECIMAL_EURO: {
            const { size = 4, options, inputType } = attributes;
            // Jos hyväksytty soppari ja muutellaan vapaiden kertymisiä, huomautetaan että nämä eivät vaikuta takautuvasti.
            // TODO: Jos tähän alkaa kertyä enemmänkin tällaista iffittelyä mietittävä kestävämpi (=luettavampi) ratkaisu.
            const feedback = (originalName === 'collectiveAgreementVariables.assignment_contract_holiday_generation_amount') && isAcceptedContract
                ? (
                    <Feedback
                        type="info"
                        message={_trans('Huomioithan, että vapaisiin tekemäsi muutokset eivät muuta vapaapäiviä takautuvasti.', {}, 'jobContract')}
                        modifierClass="u-margin-bottom"
                    />
                )
                : '';

            // Syöttötyyppi on jokin sallituista ja löytyy jotain valittavaakin => valinta radiobuttoneilla.
            if (inputType === decimalInputTypes.RADIO && options.length > 0) {
                if (options.length === 1) {
                    return (
                        <Fragment>
                            {feedback}
                            <FormField name={name} label={formFieldLabel} isContentFormField={false}>
                                <Value ariaDescribedBy={name}>
                                    {_transObj(options[0].label)}
                                </Value>
                                <HiddenField name={name} value={options[0].value} />
                            </FormField>
                        </Fragment>
                    );
                }

                const defaultOption = options.find((option) => option?.value === defaultValue);

                return (
                    <Fragment>
                        {feedback}
                        <FormField name={name} label={formFieldLabel} helpText={helpText} isContentFormField={isModifiable}>
                            {isModifiable
                                ? <RadioListField name={name} options={parseOptions(options)} />
                                : (
                                    <Value ariaDescribedBy={name}>
                                        {_transObj(defaultOption?.label)}
                                    </Value>
                                )
                            }
                        </FormField>
                    </Fragment>
                );
            }

            return (
                <Fragment>
                    {feedback}
                    <FormField name={name} label={formFieldLabel} helpText={helpText} isContentFormField={isModifiable}>
                        {isModifiable
                            ? (
                                <DecimalField size={size} name={name} validate={(value) => {
                                    if (! validators.isPositiveDecimal(value)) {
                                        return errorMessages.isPositiveDecimal;
                                    }
                                }} />
                            )
                            : (
                                <Value ariaDescribedBy={name}>
                                    {defaultValue}
                                </Value>
                            )}
                    </FormField>
                </Fragment>
            );
        }

        case types.INTEGER: {
            const { size = 4 } = attributes;
            return (
                <FormField name={name} label={formFieldLabel} helpText={helpText} isContentFormField={isModifiable}>
                    {isModifiable
                        ? (
                            <IntegerField size={size} name={name} validate={(value) => {
                                if (! validators.isInteger(value)) {
                                    return errorMessages.isInteger;
                                }
                            }} />
                        )
                        : (
                            <Value ariaDescribedBy={name}>
                                {defaultValue}
                            </Value>
                        )}
                </FormField>
            );
        }

        // Ei pitäisi tapahtua ikinä
        default:
            return (
                <div>
                    Tyyppiä ei löydy.
                </div>
            );
    }
};

Variable.propTypes = {
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string,
    helpText: PropTypes.string,
    attributes: PropTypes.shape({
        options: PropTypes.array,
    }),
};

Variable.defaultProps = {
    helpText: '',
    originalName: '',
    attributes: {
        options: [],
    },
};

export default Variable;
