import { ANY_VALUE, ARRAY_VALUE, DATE_VALUE, NUMBER_VALUE, ZERO_VALUE } from 'shared/ReForm/constants/magicValues';
import { isValidDate } from 'shared/utils/dateUtils';
import { get } from 'shared/utils/get';

export const ARRAY_INDICATOR = '$.';

/**
 * Onko komponentti näkyvissä? Tarkistaa annettuja dependencejä vasten.
 * @param dependencies
 * @param values
 * @param arrayProps
 */
export const isBlockVisible = (dependencies, values, arrayProps) => {
    const hasDependencies = dependencies && Object.values(dependencies).length > 0;
    const arrayName = arrayProps?.arrayName ?? '';

    // Ei riippuvuuksia tarkistettavana => näytetään
    if (! hasDependencies) return true;

    return Object.entries(dependencies).every(([originalKey, value]) => {
        // Mikäli key:stä löytyy maagisella $ placeholderilla varustettu merkki korvataan se mahdollisella iteraationimellä
        // (esim. salaries.0.isTableSalaryInUse). Toimii siis vain arrayn sisällä.
        const key = originalKey.replace(/\$/g, arrayName);
        // Onko dot-notaatio EIKÄ ala array-placeholderilla ($)
        const namePieces = originalKey.split('.');
        if (namePieces.length > 1 && ! originalKey.startsWith(ARRAY_INDICATOR)) {
            const [name, key] = namePieces;

            //console.log(name, key);
            return Array.isArray(value)
                ? value.some((value) => values[name][key] === value)
                : values[name][key] === value;
        }

        // Jos taulukko onko arvon oltava tosi jonkin niistä kohdalla
        if (Array.isArray(value)) {
            const dependencyValue = get(values, key);
            return value.some((singleValue) => (
                // Jos vertailtava arvo itsessään on taulukko...
                Array.isArray(dependencyValue)
                    ? dependencyValue.includes(singleValue)
                    : singleValue === dependencyValue
            ));
        }

        // Jos arvo on objekti on kaikkien arvojen löydyttävä
        // Käytetään esim. dimensioiden testailuun
        if (! Array.isArray(value) && typeof value === 'object') {
            return Object.entries(value ?? {}).every(([valueKey, conditionValue]) => {
                const comparedValue = values[key][valueKey];

                // Vertailtava arvo on taulukko. Tällöin oletetaan että löytyy joko min/max tai molemmat vertailut.
                // Esim. dependencies: {
                //     json_array_notation: {
                //         [ARRAY_VALUE]: {
                //            min: 1,
                //            max: 3
                //        }
                //     }
                // }
                if (valueKey === ARRAY_VALUE
                    && Array.isArray(values[key])
                    // Löytyy vertailtavaa
                    && Object.entries(conditionValue ?? {}).length > 0) {
                    const array = values[key];
                    return Object.entries(conditionValue).every(([key, value]) => {
                        if (key === 'min' && array.length >= value) return true;
                        if (key === 'max' && array.length <= value) return true;
                    });
                }
                return Array.isArray(conditionValue)
                    ? conditionValue.includes(comparedValue)
                    : comparedValue === conditionValue;
            });
        }

        // Jos "arvo" on funktio ajetaan arvo sen läpi.
        // Esim. dependencies: {
        //     [fieldNames.START_DATE]: (value) => validators.isDate(value)
        // }
        if (typeof value === 'function') {
            return value(values[key]);
        }

        // Oltava numero. Väännetään arvo numeroksi ja vertaillaan.
        if (value === NUMBER_VALUE) {
            const valueType = typeof values[key];
            return valueType === 'number' || (valueType === 'string' && values[key].trim() !== '' && !isNaN(Number(values[key])));
        }

        // Oltava nolla. Väännetään arvo numeroksi ja vertaillaan.
        if (value === ZERO_VALUE) {
            const castValue = Number(get(values, key));
            const valueType = typeof values[key];
            return valueType === 'number' || (valueType === 'string' && values[key].trim() !== '' && !isNaN(castValue) && castValue === 0);
        }

        if (value === DATE_VALUE) {
            // new Date(null) on validi päivämäärä... siksi erillinen null-tsekki
            return values[key] !== null && isValidDate(new Date(values[key]));
        }

        return value === ANY_VALUE
            // Arvoksi vaaditaan mitä vain.
            ? values[key]
            // ... tai sitten täsmälleen vaadittu arvo.
            : get(values, key) === value;
    });
};
