import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import { FormField, HiddenField, SelectField } from 'shared/components/Formik';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import { Value } from 'shared/components/Value';
import { fieldNames } from 'ContractV3/constants/fieldNames';

/**
 * Hoitomuodon valitsin jos useampi palkkio.
 * @constructor
 */
const SalaryDimensionType = (props) => {
    const { name, index } = props;

    // Valitut dimensiot. Parsitaan näistä valittu salaryTypeDimensio joka tulee
    // oletuksena ensimmäisen salaarin dimensionksi.
    const [dimensionsFields] = useField(fieldNames.DIMENSIONS);

    const [salariesField] = useField(fieldNames.SALARIES);
    const salaries = (salariesField.value ?? []);
    const salaryTypeDimension = useSelector(select.dimensions.findSalaryTypeDimension);
    const salaryDimensions = salaryTypeDimension?.dimensions ?? [];

    const selectedDimensionType = useMemo(() => {
        const selectedSalaryDimensions = dimensionsFields.value ?? [];
        return Object.entries(selectedSalaryDimensions).find(([dimensionStr]) => {
            // Eka "id" string pois
            const dimension = Number.parseInt(dimensionStr.slice(2), 10);
            // Onko sama id kuin valitulla salaryTypeDimensiolla (esim. hoitomuoto)
            return dimension === salaryTypeDimension?.id;
        });
    }, [dimensionsFields.value, salaryTypeDimension]);
    const selectedDimension = Array.isArray(selectedDimensionType)
        ? Number.parseInt(selectedDimensionType[1], 10)
        : null;

    // Jo valitut dimensiot, ei voida valita samaa kahta kertaa
    const usedDimensions = salaries
        // Nykyinen valinta pois
        .filter((_, salaryIndex) => salaryIndex !== index)
        // Mapataan dimensiot
        .map((salary) => parseInt(salary.dimension, 10))
        // Jos ei ollut valittu niin filteröidään NaN poijes
        .filter(Boolean);

    const options = salaryDimensions
        .filter((dimension) => ! usedDimensions.includes(dimension.id))
        .map(({ id, value, description }) => ({
            value: id,
            // undefinedit pois
            label: [value, description].filter(Boolean).join(' - '),
        }));

    // Tälle hoitomuodolle valittu dimensio
    const selectedDimensionId = parseInt(salaries[index].dimension, 10);
    const selectedOption = salaryDimensions
        .find((dimension) => selectedDimensionId === dimension.id);

    const showDimensionSelector = salaries.length > 1 && !!salaryTypeDimension;

    if (! showDimensionSelector) {
        // Pitäis olla harvinainen mutta ei päästetä läpi
        if (Number.isNaN(selectedDimension)) {
            return null;
        }
        // Asetetaan ensimmäisen salaarin dimensio jiiriin
        return <HiddenField name={name} value={selectedDimension} />;
    }

    const label = salaryTypeDimension.name ?? '';
    const placeholder = `${_trans('dropdown.choose')} ${label.toLowerCase()}`;
    const fieldProps = getFieldProps(props);

    // undefinedit pois
    const dimensionValue = [
        selectedOption?.value,
        selectedOption?.description
    ].filter(Boolean);

    return (
        <FormField {...fieldProps} label={label || fieldProps.label} isContentFormField={index > 0}>
            {/*Ensimmäiselle valinnalle näytetään fixed arvo*/}
            {(index === 0) && selectedOption
                ? (
                    <Fragment>
                        <Value ariaDescribedBy={name}>{dimensionValue.join(' - ')}</Value>
                        <HiddenField name={name} value={selectedOption.id} />
                    </Fragment>
                )
                : <SelectField name={name} options={options} placeholder={placeholder} />}
        </FormField>
    );

    //contract.form.salary.sub_salary_details
};

SalaryDimensionType.propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
};

export default SalaryDimensionType;
