import commonUtils from './commonUtils';
import resolveUrl from './resolveUrl';
export { default as confirm } from './confirm';
export { default as alert } from './alert';

export {
    resolveUrl,
};

// Tätä importataan kuitenkin useimmiten
export default commonUtils;
