import PropTypes from 'prop-types';
import collectiveAgreementVariableTypes from 'shared/constants/collectiveAgreementVariableTypes';

/**
 * TES-valinnat.
 */
export default PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(collectiveAgreementVariableTypes)),
    options: PropTypes.oneOfType([
        PropTypes.shape({
            metadata: PropTypes.object.isRequired,
            values: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                amount: PropTypes.string.isRequired,
            }))
        }),
        PropTypes.array
    ]),
    defaultValue: PropTypes.string,
    isModifiable: PropTypes.bool,
    isHidden: PropTypes.bool,
});
