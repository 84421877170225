import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { push } from 'connected-react-router';
import Popover from 'shared/components/Popover';
import CirclePlaceholder from 'shared/components/Placeholder/CirclePlaceholder';
import colorUtils from 'shared/utils/colorUtils';
import { loadingSelectors } from 'shared/models';
import { List, ListItem, MDIcon } from 'shared/components';

@connect((state) => ({
    fullName: select.loggedUser.getUserName(state),
    initials: select.loggedUser.getUserInitials(state),
    photoSource: state.loggedUser.photoSource ?? '',
    userRole: state.loggedUser.metadata?.userRole,

    isLoadingUserData: loadingSelectors.isLoadingUserData(state),
}))
export default class UserMenu extends Component {
    static propTypes = {
        fullName: PropTypes.string,
        initials: PropTypes.string,
        photoSource: PropTypes.string,
        userRole: PropTypes.number,
        menuItems: PropTypes.array,
        dispatch: PropTypes.func,
        isLoadingUserData: PropTypes.bool,
    };

    static defaultProps = {
        fullName: '',
        initials: '-',
        photoSource: '',
        userRole: 1,
        menuItems: [],
        dispatch() {},
        isLoadingUserData: false,
    };

    onSelect = (menuItem) => {
        const route = _.get(menuItem, 'value', null);

        if (route !== null) {
            const { dispatch } = this.props;

            dispatch(push(menuItem.value));
        }
    };

    renderMenuItem = (menuItem, index) => {
        const title = _.get(menuItem, 'title', '');
        const route = _.get(menuItem, 'route');
        const href = _.get(menuItem, 'href');
        const icon = _.get(menuItem, 'icon');
        const linkProps = {};
        if (route) {
            linkProps.route = route;
        } else if (href) {
            linkProps.url = href;
        }

        return (
            <ListItem.Link {...linkProps} key={index}>
                { icon && (
                    <ListItem.Anchor>
                        <MDIcon icon={icon} size="small" />
                    </ListItem.Anchor>
                )}
                <ListItem.PrimaryContent>
                    {title}
                </ListItem.PrimaryContent>
            </ListItem.Link>
        );
    };

    /**
     * Renderöi joko käyttäjän kuvan tai käyttäjän nimeen perustuen värillisen pallon jossa nimen lyhenne.
     * @returns {*}
     */
    renderAvatar = () => {
        const {
            fullName,
            initials,
            photoSource,
            isLoadingUserData,
        } = this.props;

        if (isLoadingUserData) return <CirclePlaceholder size={36} isPending={isLoadingUserData} />;

        // Jos kuvaa ei löydy luodaan värillinen pallo nimeen perustuen
        if (photoSource === '') {
            return (
                <span
                    className="c-avatar c-avatar--small c-avatar--name u-shape-round u-text-center u-font-weight-bold"
                    style={colorUtils.getColorsByString(fullName)}
                >
                    {initials}
                </span>
            );
        }
        const photoUrl = Routing.generate('liip_imagine_filter', { filter: 'profile_thumb', path: photoSource });

        return (
            <span className="c-avatar">
                <span className="c-avatar__img" style={{
                    backgroundImage: `url(${photoUrl})`,
                }}/>
            </span>
        );
    };

    render() {
        const {
            fullName,
            menuItems,
        } = this.props;

        return (
            <ul className="c-menu c-menu--right" role="menubar" aria-label="Käyttäjävalikko">
                <li className="c-menu__trigger" role="presentation">
                    <Popover
                        position="bottom-end"
                        popoverType="list"
                        isArrowVisible={false}
                        hasContentPadding={false}
                    >
                        <button
                            aria-haspopup={true}
                            id="notification-menu-trigger"
                            className="c-menu__item"
                        >
                            {this.renderAvatar()}
                            <span className="c-user u-hide@small-and-down o-stack o-stack--inline o-stack--start">
                                <span className="c-user__name u-align-middle u-margin-left-tiny">
                                    {fullName}
                                </span>
                                <span className="c-menu__dropdown-icon u-align-middle">
                                    <i className="material-icons">keyboard_arrow_down</i>
                                </span>
                            </span>
                        </button>

                        <List>
                            {menuItems.map((menuItem, index) => this.renderMenuItem(menuItem, index))}
                        </List>
                    </Popover>
                </li>
            </ul>
        );
    }
}
