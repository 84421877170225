import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import utils from 'shared/utils/commonUtils';
import { promised as request } from 'BubbleWrapAgent';
import SsnQueryForm from 'shared/components/SsnQueryForm';
import confirm from 'shared/utils/confirm';
import validators from 'shared/validators';
import api from 'api';

/**
 * Yleiskäyttöinen container-komponentti HeTun kyselyyn.
 * Suorittaa itse requestin ja palauttaa joko löydetyn käyttäjän tai virheviestin.
 */
export default class SsnQuery extends Component {

    state = {
        isPending: false,
        errorMessage: '',
    };

    constructor(props) {
        super(props);
        const { initialSsn } = props;

        // Jos hetu on esisyötetty lähdetään etsimään käyttäjää.
        if (initialSsn !== '' && validators.isSsn(initialSsn)) {
            this.onConfirm(initialSsn);
        }
    }

    /**
     * Hakee aktiivisia avustuspäätöksiä HeTu:n perusteella jos kyseessä on avustettava.
     * Ellei käyttäjä ole avustettava jatketaan etsintää hetun perusteella.
     * Jos tapahtui virhe palautetaan virheviesti onError-propsin kautta ylemmälle komponentille.
     * @param ssn
     */
    onConfirm = (ssn, blockVtjUpdate) => {
        const {
            isBeneficiary,
            onError,
            onBenefitDecisionFound,
        } = this.props;
        this.setState({ isPending: true });
        if (isBeneficiary) {
            request
                .get(Routing.generate('api_1_get_benefitdecisions'))
                .query({ ssn })
                .end((error, result) => {
                    if (error){
                        this.setState({ isPending: false });
                        onError(_trans('benefit_decision.notifications.error.partial_get_request_failed'));

                        // Something bad happened. Just route back to decision list for now
                        //window.location.replace(Routing.generate('suoratyo_benefits'));
                    } else {
                        // Onko voimassa olevaa avustuspäätöstä
                        const decisions = _.get(result, 'body.data', [])
                            .filter((decision) => ! decision?.attributes?.canBeReplaced);
                        const activeBenefitDecisionId = _.get(_.find(decisions, (decision) => decision.isActive), 'id', null);


                        // Kyllä. Kysytään siirrytäänkö siihen.
                        if (activeBenefitDecisionId) {
                            this.setState({ isPending: false });

                            confirm(_trans('benefit_decision.confirm_dialog.message'), {
                                proceedLabel: _trans('benefit_decision.confirm_dialog.proceed_label'),
                            }).then(
                                () => {
                                    onBenefitDecisionFound(activeBenefitDecisionId);
                                    // window.location.replace(Routing.generate('suoratyo_benefits_show', {id: activeBenefitDecisionId}));

                                });
                        } else if (decisions.length > 0) {
                            this.setState({ isPending: false });
                            const benefitDecision = _.last(_.sortBy(decisions, (decision) => moment(decision?.startDate ?? '')));

                            const confirmContent = (
                                <div>
                                    <div>{_toLocaleDate(benefitDecision.startDate) + ' ' + benefitDecision.beneficiaryFirstName + ' ' + benefitDecision.beneficiaryLastName}</div>
                                    <br/>
                                    <div>{_trans('Haluatko varmasti avata asiakkuuden?', {}, 'commune')}</div>
                                </div>
                            );

                            confirm(confirmContent, {
                                title: _trans('Henkilötunnukselle löytyy jo aiemmin tehty asiakkuus.', {}, 'commune'),
                                proceedLabel: _trans('benefit_decision.confirm_dialog.proceed_label'),
                            }).then(
                                () => {
                                    // Mennään suljetulle.
                                    onBenefitDecisionFound(benefitDecision.id);
                                });
                        } else {
                            // Ei löytynyt. Jatketaan käyttäjän tietojen etsintää hetun perusteella.
                            this.fetchUserBySsn(ssn, blockVtjUpdate);
                        }
                    }
                });
        } else {
            // Kyseessä ei ole avustettava. Jatka käyttäjän etsintää hetun perusteella.
            this.fetchUserBySsn(ssn, blockVtjUpdate);
        }
    };

    /**
     * Hakee käyttäjän tietoja HeTu:n perusteella.
     * Jos tapahtui virhe palautetaan virheviesti onError-propsin kautta ylemmälle komponentille.
     */
    fetchUserBySsn = (ssn, blockVtjUpdate) => {
        const {
            onUserNotFound,
            onError,
        } = this.props;
        request
            .get(Routing.generate('api_1_get_users'))
            .query({ ssn })
            .end((error, response) => {
                if (error) {
                    // Löytyykö bäkkäriltä tullutta tarkempaa virheviestiä?
                    const errorMessage = _.get(response, 'body.message', false);
                    if (errorMessage) {
                        onError(errorMessage);
                        this.setState({
                            errorMessage,
                        });
                    } else {
                        onError(_trans('notifications.user.ssn_query.error'));
                        this.setState({
                            errorMessage: _trans('notifications.user.ssn_query.error'),
                        });
                    }
                } else {
                    const userId = _.get(response.body, 'data.0.userId', null);
                    if (_.isNull(userId)) {
                        onUserNotFound(ssn, blockVtjUpdate);
                    } else {
                        this.fetchUserById(userId, ssn);
                    }
                }
                this.setState({
                    isPending: false,
                });
            });
    };

    /**
     * Hakee käyttäjän tietoja id:n perusteella.
     * Jos kyseessä on alaikäinen avustettava ammu virheviesti ja näytä hetu-lomakkeella.
     * @param user
     * @param ssn
     */
    async fetchUserById(user, ssn) {
        const {
            isSubstituteEmployer,
            onUserFound,
            onError,
            onUserNotVisibilityAccess,
        } = this.props;
        try {
            // TODO Miksi ei perus muotonen response?
            const json = await api.get(`/api/v2/users/${user}`);
            const userId = json?.userId;
            if (userId) {
                if (isSubstituteEmployer) {
                    if (utils.getAgeInYearsBySsn(ssn) < 18) {
                        this.setState({
                            errorMessage: _trans('benefit_decision.notifications.error.underage_error'),
                        });
                        onError(_trans('benefit_decision.notifications.error.underage_error'));

                        return;
                    }
                }

                // Tässä user-objekti ammutaan ylöspäin
                onUserFound(json);
            } else {
                onError(_trans('notifications.user.ssn_query.error'));
            }
        } catch (e) {
            // Tämä pakko olla kätsisäs, koska api.js heittä virhettä, jos tulee 'error_no_access_to_dimensions'-virhe
            if (e?.status === 'error' && e?.error === 'error_no_access_to_dimensions') {
                onUserNotVisibilityAccess(user, ssn);
            } else {
                onError(_trans('notifications.user.ssn_query.error'));

            }
        }
    }

    render() {
        return (
            <SsnQueryForm
                initialSsn={this.props.initialSsn}
                hasAutoFocus={this.props.hasAutoFocus}
                onConfirm={this.onConfirm}
                isPending={this.state.isPending}
                errorMessage={this.state.errorMessage}
                isAdmin={this.props.isAdmin}
                isVtjEnabled={this.props.isVtjEnabled}
            />
        );
    }

}

SsnQuery.defaultProps = {
    hasAutoFocus: false,
    isBeneficiary: false,
    isSubstituteEmployer: false,
    onUserFound: () => {},
    onUserNotFound: () => {},
    onUserNotVisibilityAccess: () => {},
    onError: () => {},
    onBenefitDecisionFound: () => {},
    initialSsn: '',
    isAdmin: false,
    isEmployer: false,
    benefitDecisionId: null,
    isVtjEnabled: false,
};

SsnQuery.propTypes = {
    /**
     * Fokusoidaanko automaattisesti hetu-kenttään.
     */
    hasAutoFocus: PropTypes.bool,

    /**
     * Onko kyseessä avustettava?
     */
    isBeneficiary: PropTypes.bool,

    /**
     * Onko kyseessä sijaistyönantaja?
     */
    isSubstituteEmployer: PropTypes.bool,

    /**
     * Mitä tehdään kun käyttäjä löytyy.
     */
    onUserFound: PropTypes.func,

    /**
     * Mitä tehdään kun käyttäjää ei löydy.
     */
    onUserNotFound: PropTypes.func,

    /**
     * Mitä tehdän, jos käytössä valtuushallinta ja ei ole oikeutta käyttäjään hetun perusteella
     */
    onUserNotVisibilityAccess: PropTypes.func,

    /**
     * Mitä tehdään kun tapahtuu virhe.
     */
    onError: PropTypes.func,

    /**
     * Jos löytyikin jo asiakkuus, niin tällä redirect siihen.
     */
    onBenefitDecisionFound: PropTypes.func,

    /**
     * Etukäteen annettu hetu.
     */
    initialSsn: PropTypes.string,

    /**
     * Onko Admin käyttäjä
     */
    isAdmin: PropTypes.bool,

    /**
     * Onko Työnantaja käyttäjä
     */
    isEmployer: PropTypes.bool,

    /**
     * Työnantajan haussa käytettävä bd.id:tä
     */
    benefitDecisionId: PropTypes.number,

    /**
     * Onko Vtj käytettävissä
     */
    isVtjEnabled: PropTypes.bool,
};
