import useRequest from './request';
import useFetch from './fetch';

/**
 * Käyttö:
 * const [{ isLoading, error, data }, fetchData] = useGet(URL)
 */
export const useGet = (url, options = {}) => useFetch(url, options);

/**
 * Käyttö:
 * const [{ isPending, error, data }, makeRequest] = usePost/usePut/usePatch/useDel(URL)
 */
export const usePost = (url) => useRequest('post', url);
export const usePut = (url) => useRequest('put', url);
export const useDel = (url) => useRequest('del', url);
export const usePatch = (url) => useRequest('patch', url);
