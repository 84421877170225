import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useCombobox } from 'downshift';
import { roleItemKey, roleTranslationIdentifier } from '../constants';
import { RoleCard } from './RoleCard';
import userRoles from 'shared/constants/userRoles';
import eventKeys from 'shared/constants/eventKeys';
import Button from 'shared/components/Button';
import { RoleSelectorHeading } from 'shared/containers/PageHeader/containers/RoleSelectorHeading';

/**
 * Mulkataan vähän comboboxin tilaa koska ei haluta filtteröidä listaa kun valitaan listalta.
 * @param state
 * @param actionAndChanges
 * @returns {(*&{isOpen, highlightedIndex, inputValue})|*}
 */
const stateReducer = (state, actionAndChanges) => {
    const { type, changes } = actionAndChanges;

    switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
            return {
                ...changes, // Muut arvot saa mennä muuttuneina
                inputValue: state.inputValue, // Ei filtteröidä listaa uusiksi kun valitaan, koska muuten näppisvalinta ei ota oikeaa indeksiä
                isOpen: state.isOpen,
                highlightedIndex: state.highlightedIndex,
            };
        default:
            return changes; // otherwise business as usual.
    }
};

/**
 * Useamman roolivalinnan autocomplete.
 * @param items
 * @param userRole
 * @returns {*}
 * @constructor
 */
export const RoleAutoComplete = ({ items, userRole }) => {
    const dispatch = useDispatch();
    // Millä avaimella haetaan
    const nameIndex = roleItemKey[userRole];
    const transId = roleTranslationIdentifier[userRole];

    const target = useSelector(select.roleSelector.getTarget);
    const isHomeTarget = useSelector(select.roleSelector.isHomeTarget);
    /*
        useEffect(() => {
            // Alussa focus sulkemisnappiin jolloin tabilla päästään autocompletekenttään
            // HUOM: Autofocusta syöttökenttään ei suositella koska sen toimivuudesta ei voida
            // esim. ruudunlukijoilla olla 100% varmoja
            document.getElementById('closeRoleSelector').focus();
        }, []);
     */

    const [inputItems, setInputItems] = useState(items);
    const {
        getLabelProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        inputValue,
        setInputValue,
    } = useCombobox({
        stateReducer,
        items: inputItems,
        itemToString: (selectedItem) => selectedItem ? selectedItem[nameIndex]: '',
        onSelectedItemChange: ({ selectedItem , highlightedIndex }) => {
            if (highlightedIndex === -1) return false;

            // Saadaan sieltä elementin id
            const { id } = getItemProps({ selectedItem, index: highlightedIndex });
            // Etsitään ko. elementti DOMista
            const roleElement = document.getElementById(id).querySelector('a');
            // Jos löytyi, simuloidaan klikki koska linkkiä ei voi valita enterillä

            if (roleElement) {
                roleElement.click();
                dispatch.header.setIsRoleSelectorVisible(false);
            }
        },
        onInputValueChange: ({ inputValue }) => {
            setInputItems(
                items.filter((item) =>
                    item[nameIndex].toLowerCase().includes(inputValue.toLowerCase()),
                ),
            );
        },
        menuId: 'role-list'
    });

    const hasOnlyOneResult = inputItems.length === 1;
    const [hasFocus, setHasFocus] = useState(false);
    const [isTipOpen, setIsTipOpen] = useState(false);
    // Tulee ärsyttäviä varoituksia, joista ei pääse eroon, jos ei käytä. Noi menu propsit mitä tuo kuitenki tarjoaa on
    // aika perseestä ja aiheuttaa bugeja, niin käytetään vaa minimit
    const { ref } = getMenuProps();

    // Jos syöttökentällä on focus ja listalla vain yksi rooli
    // kerrotaan että sen voi valita myös enterillä
    useEffect(() => {
        let visibilityTimeout = null;
        if (hasFocus) {
            visibilityTimeout = setTimeout(() => {
                setIsTipOpen(hasOnlyOneResult);
            }, 350);
        } else {
            clearTimeout(visibilityTimeout);
            setIsTipOpen(false);
        }
        if (! hasFocus || ! hasOnlyOneResult) {
            setIsTipOpen(false);
            clearTimeout(visibilityTimeout);
        }

        // Unmountissa keskeytetään setTimeout ettei tule state update virhettä.
        return () => clearTimeout(visibilityTimeout);
    }, [inputItems, hasFocus, hasOnlyOneResult]);

    const tipStyle = useSpring({
        height: isTipOpen ? '1em' : '0em',
        config: {
            mass: 0.5,
            tension: 400,
            friction: 30,
            precision: 0.01,
            velocity: 0,
        }
    });

    return (
        <Fragment>
            <RoleSelectorHeading
                id="list-heading"
                heading={_trans(userRole === userRoles.COMMUNE_OPERATOR
                    ? 'role_selector.commune.select_commune'
                    : 'company.selection.select'
                )} labelProps={getLabelProps()}
            />
            <div className="u-margin-bottom u-position-relative" {...getComboboxProps()}>
                <input
                    type="text"
                    className="u-padding-vertical-small u-padding-left-small u-padding-right-large u-font-size-16 u-1/1 c-role-selector__input"
                    placeholder={_trans(`role_selector.${transId}.placeholder`)}
                    /* eslint-disable-next-line jsx-a11y/no-autofocus */
                    autoFocus
                    {...getInputProps({
                        onBlur: () => setHasFocus(false),
                        onFocus: () => setHasFocus(true),
                        onKeyDown: (event) => {
                            // Jos vain yksi tulos, voidaan se valita suoraan enterillä
                            if (event.key === eventKeys.ENTER && hasOnlyOneResult) {
                                const onlyButton = document.getElementById('roleSelector-role-list').querySelector('.c-card');
                                if (onlyButton) {
                                    onlyButton.click();
                                }
                            }
                        }
                    })}
                />
                {inputValue.length > 0 && (
                    <Button
                        mdIcon="clear"
                        flat
                        aria-label={_trans('button.clear')}
                        modifierClass="o-pin o-pin--right o-pin--top u-margin-top-small u-margin-right-tiny"
                        onClick={() => setInputValue('')}
                    />
                )}
                <animated.div
                    aria-hidden={! isTipOpen}
                    style={tipStyle}
                    className="u-overflow-hidden u-muted u-text-center u-line-height-flat u-margin-top-tiny"
                >
                    {_trans('role_selector.press_enter_to_select')}
                </animated.div>
            </div>
            <ul id="roleSelector-role-list" className="o-list-bare c-role-selector" aria-labelledby="listHeading" ref={ref}>
                {inputItems.map((item, index) => {
                    // Ei käytetä targetia jos valittavana oleva rooli ei ole targetin mukainen
                    const canUseTarget = (
                        (item.role === userRoles.COMMUNE_OPERATOR && !isHomeTarget) ||
                        (item.role !== userRoles.COMMUNE_OPERATOR && isHomeTarget)
                    );
                    return (
                        <RoleCard
                            key={index}
                            item={item}
                            target={canUseTarget ? target : null}
                            iconSize={36}
                            userRole={item.role}
                            roleName={item[nameIndex]}
                            searchText={inputValue}
                            isSelected={highlightedIndex === index}
                            listItemProps={getItemProps({ item, index })}
                        />
                    );
                })}
            </ul>
            {inputItems.length === 0 && (
                <b className="u-muted u-text-center u-display-block">
                    {_trans(`role_selector.${transId}.empty_state_message`)}
                </b>
            )}
        </Fragment>
    );
};

RoleAutoComplete.propTypes = {
    items: PropTypes.array.isRequired,
    userRole: PropTypes.oneOf([
        userRoles.EMPLOYEE,
        userRoles.EMPLOYER,
        userRoles.FAMILY_CARER,
        userRoles.COMPANY_OPERATOR,
        userRoles.COMMUNE_OPERATOR,
    ]).isRequired,
};
