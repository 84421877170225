import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import eventKeys from 'shared/constants/eventKeys';

/**
 * Näkymän alaosaan kiinnityyvä palkki.
 * Käytetään lähinnä toimintonappien ja/tai progression esittämiseen.
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default class BottomBar extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        // Sisennetty visuaalisesti
        isInset: PropTypes.bool,
        // Korostettu väri
        hasAccent: PropTypes.bool,
        // Onko palkki näkyvissä?
        isVisible: PropTypes.bool,
        // Onko alapalkki auki?
        isOpen: PropTypes.bool,
        // Animoidaanko piilotus ja näyttö?
        isAnimated: PropTypes.bool,
        // Onko sisällöllä paddingia?
        hasPadding: PropTypes.bool,
        // Mitä tapahtuu kun palkkia klikataan?
        onClick: PropTypes.func,
    };

    static defaultProps = {
        isInset: false,
        hasAccent: false,
        isVisible: true,
        isOpen: false,
        isAnimated: false,
        hasPadding: true,
        onClick: null,
    };

    constructor(props) {
        super(props);

        this.bottomBarRef = React.createRef();
        this.mainPaddingBottom = 0;
    }

    /**
     * Asettaa main-tagiin paddingin.
     * @param paddingBottom
     */
    setRootPaddingBottom = (paddingBottom) => {
        const mainEl = document.getElementById('root');
        if (mainEl) {
            const nodeStyles = window.getComputedStyle(mainEl);
            this.mainPaddingBottom = nodeStyles.getPropertyValue('padding-bottom');
            mainEl.style.paddingBottom = paddingBottom + 'px';
        }
    };

    /**
     * Asetetaan BottomBar-elementin verran paddingia main-tagiin jotta mobiilissa
     * napit eivät jää palkin alle.
     *
     * Tallennetaan olemassa ollut arvo.
     */
    componentDidMount() {
        const height = this.bottomBarRef.current.clientHeight;
        this.setRootPaddingBottom(height);
    }

    /**
     * Asetetaan arvo takaisin kun komponentti irtoaa DOMista.
     */
    componentWillUnmount() {
        this.setRootPaddingBottom(this.mainPaddingBottom);
    }

    render() {
        const {
            children,
            isInset,
            hasAccent,
            isVisible,
            isOpen,
            isAnimated,
            hasPadding,
            onClick,
        } = this.props;

        // Jos ei haluta näyttää eikä ole animoitu ei renderöidä mitään
        if (! isVisible && ! isAnimated) return null;

        const containerClassName = classNames('c-bottom-bar-container o-pin o-pin--fixed o-pin--left o-pin--bottom u-1/1 u-z-index-4', {
            'c-bottom-bar-container--animated': isAnimated,
            'c-bottom-bar-container--open': isOpen,
            'c-bottom-bar-container--inset': isInset,
            'c-bottom-bar-container--accent': hasAccent,
        });

        const contentClassName = classNames('c-bottom-bar', {
            'c-bottom-bar--visible': isVisible,
            'c-bottom-bar--animated': isAnimated,
            'c-bottom-bar--open': isOpen,
            'c-bottom-bar--accent': hasAccent,
            'u-padding-small': hasPadding,
        });

        let extraProps = {};

        // Jos käytetään klikkieventtiä tehdään alapalkista saavutettava.
        if (onClick !== null) {
            extraProps = {
                role: 'button',
                tabIndex: 0, // tabitettava
                onClick,
                onKeyDown: (event) => {
                    // Jos alapalkilla on focus ja painetaan enter tehdään sama kuin klikattaessa.
                    if (event.key === eventKeys.ENTER) {
                        onClick();
                    }
                }
            };
        }

        return (
            <div
                className={containerClassName}
                {...extraProps}
                ref={this.bottomBarRef}
            >
                <div className={contentClassName}>
                    {children}
                </div>
            </div>
        );
    }
}
