import PropTypes from 'prop-types';

export default PropTypes.shape({
    targetCount: PropTypes.number,
    defaultPayday: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    state: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    dueDate: PropTypes.string.isRequired,
});
