import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { fixedTermContractReason, translateFixedTermContractReason } from 'shared/constants/fixedTermContractReason';
import { contractTypes, translateContractType } from 'shared/constants/contractTypes';
import {
    companyEstimatedWorkTimeTranslator, isMonthlyType, isRegularOrMaxHoursType, isRegularType,
    translateWorkTimeEstimateType
} from 'shared/constants/workTimeEstimationTypes';
import { SummaryList } from 'shared/components';

export const ContractDuration = ({ contractObject, isAssignmentContract, isCompanyContract, isCommune }) => {
    let start = contractObject?.duration.duration.start ?? '';
    let end = contractObject?.duration.duration.end ?? '';
    let trialPeriodEndDate = contractObject?.duration.trialPeriodEndDate ?? '';
    let originalStartDate = contractObject?.duration.originalStartDate ?? '';
    const hasFixedTermContractReason = contractObject?.duration.fixedTermContractReason;
    const fixedTermContractReasonCustom = contractObject?.duration.fixedTermContractReasonCustom ?? '';
    const terminationTime = contractObject?.contract.terminationTime ?? '';
    const estimatedWorkTimeType = contractObject?.duration.workTimeEstimationType ?? null;
    const hasUseWeeklyWorkHours = contractObject?.duration.useWeeklyWorkHours !== null;
    const isRegularWorkHours = isRegularType(contractObject?.duration.workTimeEstimationType ?? null);
    const trialPeriod = contractObject?.duration.trialPeriod ?? false;

    const contractType = contractObject?.contractType ?? '';

    switch (parseInt(contractType)) {
        case contractTypes.OPEN_ENDED: // fall through
        default:
            if (moment.isMoment(start)) {
                start = start.format(_dateFormat);
            } else if (start !== '') {
                start = moment(start).format(_dateFormat);
            } else {
                start = '';
            }
            break;
        case contractTypes.FIXED_TERM:
            if (moment.isMoment(start)) {
                start = start.format(_dateFormat);
            } else if (start !== '') {
                start = moment(start).format(_dateFormat);
            }
            if (moment.isMoment(end)) {
                end = end.format(_dateFormat);
            } else if (end !== '') {
                end = moment(end).format(_dateFormat);
            } else {
                end = '';
            }
            break;
    }

    if (moment.isMoment(trialPeriodEndDate)) {
        trialPeriodEndDate = trialPeriodEndDate.format(_dateFormat);
    } else if (trialPeriodEndDate !== '') {
        trialPeriodEndDate = moment(trialPeriodEndDate).format(_dateFormat);
    } else {
        trialPeriodEndDate = '';
    }

    if (moment.isMoment(originalStartDate)) {
        originalStartDate = originalStartDate.format(_dateFormat);
    } else if (originalStartDate !== '') {
        originalStartDate = moment(originalStartDate).format(_dateFormat);
    } else {
        originalStartDate = '';
    }

    let fixedTermDescription = (
        <span>
            {translateFixedTermContractReason(hasFixedTermContractReason)}
        </span>
    );

    if (hasFixedTermContractReason === fixedTermContractReason.FIXED_TERM_CONTRACT_REASON_OTHER_REASON) {
        fixedTermDescription = <span>{fixedTermContractReasonCustom}</span>;
    }
    let fixedTermReason = null;
    if (contractType === contractTypes.FIXED_TERM) {
        fixedTermReason = {
            term: _trans('contract.form.duration.fixed_term_contract_reason.label'),
            description: fixedTermDescription,
        };
    }

    // Jos löytyy terminationTime, overridetään kaikki endDatet, koska tää viimeisin kuitenkin näistä.
    if (terminationTime !== '') {
        end = _toLocaleDate(terminationTime);
    }

    const duration = [
        {
            term: _trans('contract.form.duration.contract_type.label'),
            description: (
                <span>
                    {translateContractType(contractType)}
                </span>
            ),
        },
        fixedTermReason,
        originalStartDate && {
            term: _trans('contract.form.duration.duration.label'),
            description: (
                <span>
                    {originalStartDate} - {end}
                </span>
            ),
        },
        originalStartDate && {
            term: ' ',
            description: (
                <span>{`${_trans('contract.form.duration.original_start_date.imported_note')} ${start}`}</span>
            ),
        },
        !originalStartDate && {
            term: _trans('contract.form.duration.duration.label'),
            description: (
                <span>
                    {start}
                    {' - '}
                    {end}
                </span>
            ),
        },
        ! isAssignmentContract && {
            term: _trans('contract.form.duration.probation.label'),
            description: (
                <span className="u-text-capitalize">
                    {trialPeriod ? _trans('yes') : _trans('no')}
                </span>
            ),
        },
        ! isAssignmentContract && trialPeriod && {
            term: _trans('contract.form.duration.probationEndDate.label'),
            description: <span>{trialPeriodEndDate}</span>,
        },
    ].filter(Boolean);

    if (! isAssignmentContract) {
        duration.push({
            term: _trans('contract.form.duration.estimatedWorkHours.label'),
            description: isCompanyContract
                ? companyEstimatedWorkTimeTranslator(estimatedWorkTimeType)
                : translateWorkTimeEstimateType(estimatedWorkTimeType)
        });

        const isRegularOrMaxHours = isRegularOrMaxHoursType(estimatedWorkTimeType);
        const isMonthly = isMonthlyType(estimatedWorkTimeType);
        const weeklyWorkHoursLabel = isMonthly
            ? _trans('contract.form.duration.estimatedWorkHours.hours_per_month_label').toLowerCase()
            : _trans('contract.form.duration.estimatedWorkHours.hours_per_week_label').toLowerCase();

        const minWeeklyWorkHours = _numberFormat(contractObject?.duration.weeklyWorkHours ?? '');
        const maxWeeklyWorkHours = _numberFormat(contractObject?.duration.maxWeeklyWorkHours ?? '');
        duration.push({
            term: _trans('contract.form.duration.estimatedWorkHours.label_hours'),
            description: isRegularOrMaxHours
                ? (
                    <span>
                        {minWeeklyWorkHours} {weeklyWorkHoursLabel}
                    </span>
                ) : (
                    <span>
                        {/* Mikäli max on '0' (_numberFormat(null) => 0) ja irregular => ei näytetä tätä,
                        kunnes sopimukselle asetetaan oikea arvo */}
                        {maxWeeklyWorkHours === '0' ? _trans('noin', {}, 'extract') : _trans('text.at_least')} {minWeeklyWorkHours} {weeklyWorkHoursLabel}
                        {maxWeeklyWorkHours !== '0' && (
                            <Fragment>
                                <br />
                                {_trans('text.at_most')} {maxWeeklyWorkHours} {weeklyWorkHoursLabel}
                            </Fragment>
                        )}
                    </span>
                ),
        });
    }

    if (hasUseWeeklyWorkHours && isRegularWorkHours && isCommune) {
        duration.push({
            term: _trans('contract.form.duration.useWeeklyWorkHours.label'),
            description: (
                <span className="u-text-capitalize">
                    {hasUseWeeklyWorkHours ? _trans('yes') : _trans('no')}
                </span>
            ),
        });
    }

    return (
        <SummaryList
            list={[...duration].filter(Boolean)}
        />
    );
};

ContractDuration.propTypes = {
    contractObject: PropTypes.object.isRequired,
    isAssignmentContract: PropTypes.bool.isRequired,
    isCompanyContract: PropTypes.bool.isRequired,
    isCommune: PropTypes.bool.isRequired
};
