import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import {
    fixedTermAssignmentContractReason,
    fixedTermContractReason,
    translateFixedTermContractReason,
} from 'shared/constants/fixedTermContractReason';
import { parseOptions } from 'shared/ReForm/utils/parseOptions';
import { Value } from 'shared/components/Value';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { fieldNames } from 'ContractV3/constants/fieldNames';


/**
 * Valittu määräaikaisuuden syy.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const FixedTermReason = (props) => {
    const { value, values, name, label, attributes } = props;

    const isAssignmentContract = useSelector(select.contract.isAssignmentContract);
    // Toimeksiantosoppareilla eri syyt
    const reasons = isAssignmentContract
        ? fixedTermAssignmentContractReason
        : fixedTermContractReason;

    const fixedOptions = Object.values(reasons)
        .map((reason) => ({
            value: reason,
            label: translateFixedTermContractReason(reason),
        }));

    // Voidaan antaa myös omat optiot eikä esiasetettuja
    const options = (attributes?.options ?? []).length > 0
        ? parseOptions(attributes.options)
        : fixedOptions;

    const reason = options.find((option) => option.value === parseInt(value, 10));
    const fixedTermReasonCustom = values[fieldNames.FIXED_TERM_REASON_CUSTOM];

    return (
        <Fragment>
            <SummaryListItem term={label}>
                <Value ariaDescribedBy={name}>{reason?.label ?? ''}</Value>
            </SummaryListItem>
            {/* Jos muu syy, näytetään se */}
            {parseInt(value, 10) === fixedTermContractReason.FIXED_TERM_CONTRACT_REASON_OTHER_REASON && (
                <SummaryListItem term={_trans('Muu peruste', {}, 'jobContract')}>
                    <Value>{fixedTermReasonCustom}</Value>
                </SummaryListItem>
            )}
        </Fragment>
    );
};

FixedTermReason.propTypes = {
    value: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    attributes: PropTypes.object,
};

FixedTermReason.defaultProps = {
    attributes: {},
};

export default FixedTermReason;
