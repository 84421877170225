/**
 * Palautepalikka.
 */
import { blocks } from 'shared/ReForm/constants/blocks';

export const feedbackSchema = {
    type: 'object',
    title: 'Infoboksi',
    properties: {
        block: { const: blocks.FEEDBACK },
        message: {
            title: 'Tekstisisältö',
            '$ref': '#/definitions/translation',
        },
        type: {
            enum: ['success', 'info', 'warning', 'error', 'tip']
        },
    },
    required: ['block', 'message', 'type'],
    additionalProperties: false,
};
