import { fromJS } from 'immutable';

/**
 * @deprecated
 *
 * Luo Rematchin modellin. Parametreinä initialState sekä mahdolliset omat reduucerit.
 * initialState pitää olla muotoa { x: { isLoading: false, isSaving: false, ... } }
 * ja sitä kutsutaan näin: dispatch.<modellin nimi>.setLoading({ x: true })
 *
 * Reduucereita kun luodaan pitää huomata, että state on immutable.
 *
 * @param initialState
 * @param reducers
 * @returns {{state: any, reducers: *}}
 */
const createUIFlags = (initialState = {}, reducers = {}) => (
    {
        state: fromJS(initialState),

        reducers: Object.assign({}, reducers, {
            setLoading: (state, payload) => state.setIn([Object.keys(payload)[0], 'isLoading'], Object.values(payload)[0]),

            setSaving: (state, payload) => state.setIn([Object.keys(payload)[0], 'isSaving'], Object.values(payload)[0]),

            setError: (state, payload) => state.setIn([Object.keys(payload)[0], 'hasError'], Object.values(payload)[0]),

            setEditMode: (state, payload) => state.setIn([Object.keys(payload)[0], 'editMode'], Object.values(payload)[0]),
        }),

        selectors: (state, key) => state.get(key),
    }
);

export default createUIFlags;
