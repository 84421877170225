import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { Button, Collapse } from 'shared/components';
import { mainBreakPoints } from 'shared/constants/index';

export default class BreakpointToggler extends Component {
    state = {
        isOpen: false,
    };

    static propTypes = {
        /**
         * Kontentti, joka nätetään.
         * Jos haluaa oman triggerin, niin se annetaan ensimmäisenä lapsena
         */
        children: PropTypes.node.isRequired,
        /**
         * Breakpointti jossa esitystapa muuttu
         */
        breakpoint: PropTypes.number,
    };

    static defaultProps = {
        breakpoint: mainBreakPoints.SMALL,
    };

    renderDefaultTrigger() {
        return (
            <Button onClick={() => this.setState({ isOpen: ! this.state.isOpen })}>
                X
            </Button>
        );
    }

    renderCollapse() {
        // Onko oma triggeri, kaksi lasta, ensimmäinen on triggeri tällöin
        const hasOwnTrigger = React.Children.count(this.props.children) === 2;
        return (
            <Fragment>
                {hasOwnTrigger ?
                    React.cloneElement(
                        this.props.children[0],
                        { onClick: () => this.setState({ isOpen: ! this.state.isOpen }) }
                    ) :
                    this.renderDefaultTrigger()
                }
                <Collapse isOpen={this.state.isOpen}>
                    {this.renderContent()}
                </Collapse>
            </Fragment>
        );
    }

    renderContent() {
        if (React.Children.count(this.props.children) === 2) {
            // Jos annetaan oma triggeri (kaksi lasta), niin renderöidään vain pelkästään contentti
            return this.props.children[1];
        }
        return this.props.children;
    }

    render() {
        const { breakpoint } = this.props;
        return (
            <Fragment>
                <MediaQuery query={`(max-device-width: ${breakpoint}px)`} component="span">
                    {this.renderCollapse()}
                </MediaQuery>
                <MediaQuery query={`(min-device-width: ${breakpoint + 1}px)`} component="span">
                    {this.renderContent()}
                </MediaQuery>
            </Fragment>
        );
    }
}
