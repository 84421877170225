import api from 'api';

export const userAttachedDimensions = {
    state: [],
    reducers: {
        setData: (state, payload) => payload,
    },
    selectors: {
        getAttachedDimensions: (state) => state,
    },
    effects: () => ({
        async fetchUserAttachedDimensions(user) {
            try {
                const json = await api.get(`/api/v2/user/${user}/user-attached-dimensions`);
                this.setData(json.data ?? []);
                return json;
            } catch (e) {
                return { status: 'error' };
            }
        },
        async postUserAttachedDimensions({ user, model }) {
            try {
                const json = await api.post(`/api/v2/user/${user}/user-attached-dimensions`, model);
                if (json.status === 'ok') {
                    this.setData(json.data ?? []);
                }
                return json;
            } catch (e) {
                return { status: 'error' };
            }
        },
    }),
};
