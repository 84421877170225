import React from 'react';
import PropTypes from 'prop-types';
import { BooleanValue } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Summary/shared/BooleanValue';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';

/**
 * Lähisukulaisuus valinta.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */

const IsEmployeeRelated = ({ value, name, inputLabel }) => (
    <SummaryListItem term={inputLabel}>
        <BooleanValue name={name} value={value} />
    </SummaryListItem>
);

IsEmployeeRelated.propTypes = {
    name: PropTypes.string.isRequired,
    /* eslint-disable-next-line */
    value: PropTypes.bool.isRequired,
    inputLabel: PropTypes.string.isRequired,
};

export default IsEmployeeRelated;
