import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import StreetAddressAutoComplete from 'shared/containers/StreetAddressAutoComplete';
import { getFormikErrorClassName } from 'shared/utils/formUtils';

/**
 * Katuosoitteen täydentävä komponentti.
 * Valittaessa katuosoitteen antaa myös postinumeron ja kaupungin.
 * @param name
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const StreetAddressAutoCompleteField = ({ name, ...rest }) => {
    const [field, meta, helpers] = useField(name);
    const errorClassName = getFormikErrorClassName(meta);

    return (
        <StreetAddressAutoComplete
            {...rest}
            value={field.value}
            name={name}
            className={errorClassName}
            onChange={(fullAddress) => helpers.setValue(fullAddress)}
        />
    );
};

StreetAddressAutoCompleteField.propTypes = {
    name: PropTypes.string,
};

StreetAddressAutoCompleteField.defaultProps = {
    name: undefined,
};
