import { useState, useCallback } from 'react';
import _ from 'lodash';

/**
 * Helpperi staten muutoksiin, mergee muutokset stateen setState:n tavoin.
 * Voidaan antaa myöskin funktio, jossa parameterinä on currentState
 *
 * @param initialState
 * @return array
 */
const useMergeState = (initialState) => {
    const [state, setState] = useState(initialState || {});

    const mergeState = useCallback((newState) => {
        if (_.isFunction(newState)) {
            setState((currentState) => ({ ...currentState, ...newState(currentState) }));
        } else {
            setState((currentState) => ({ ...currentState, ...newState }));
        }
    }, []);

    return [state, mergeState];
};

export default useMergeState;
