import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Aito oikea kytkin. Myös ruudunlukija "näkee" tämän kytkimenä ja kertoo tilan.
 * @returns {*}
 * @constructor
 */
const Switch = (props) => {
    const { label, onChange, children, id, isOn, modifierClass, ...rest } = props;
    const [isSwitchOn, setIsSwitchOn] = useState(false);

    useEffect(() => {
        setIsSwitchOn(isOn);
    }, [isOn]);

    // Riippuen siitä onko label annettu määritellään aria-attribuutit ruudunlukijoita varten.
    const ariaProps = label
        ? {
            'aria-label': label,
            'aria-describedby': id,
        }
        : {
            'aria-labelledby': id,
        };

    return (
        <div className={classNames('u-display-inline-block', modifierClass)}>
            <button
                {...ariaProps}
                type="button"
                className="c-switch u-text-left"
                aria-pressed={isSwitchOn}
                disabled={rest.disabled ?? false}
                onClick={() => {
                    setIsSwitchOn(!isSwitchOn);
                    onChange(!isSwitchOn);
                }}
            >
                <span className="c-switch__handle" aria-hidden="true" />
            </button>
            <span id={id} className="u-margin-left-tiny">{children}</span>
        </div>
    );
};

Switch.propTypes = {
    label: PropTypes.string,

    /**
     * Callback after switch state changes.
     */
    onChange: PropTypes.func,

    /**
     * Is the switch on by default.
     */
    isOn: PropTypes.bool,

    /**
     * Additional description. Please note that in order for this to work the id is also needed.
     */
    children: PropTypes.node.isRequired,

    /**
     * Identifier of the element that describes this switch.
     */
    id: PropTypes.string.isRequired,

    /**
     * CSS modifier class.
     */
    modifierClass: PropTypes.string,
};

Switch.defaultProps = {
    label: null,
    onChange() {},
    isOn: false,
    modifierClass: '',
};

export default Switch;

