// Flätti lista jossa listan item on [name, value] tyylinen.
import { getBlockProps } from 'shared/ReForm/utils/getBlockProps';

export const getFlatValues = (blocks) => blocks.reduce((acc, cur) => {
    if (cur.blocks) {
        return [
            ...acc,
            ...getFlatValues(cur.blocks),
        ];
    }

    const block = getBlockProps(cur);

    if (!block.name) return acc;

    return [
        ...acc,
        // Otetaan oletusarvo.
        [[block.name], block.defaultValue]
    ];
}, []);
