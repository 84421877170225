export const contractTypes = {
    /**
     * Toistaiseksi voimassa oleva
     */
    OPEN_ENDED: 1,

    /**
     * Määräaikainen
     */
    FIXED_TERM: 2,

    /**
     * Vanha urakkasopimustyyppi (migratoitu omaksi tyypikseen, aiemmin urakkasopimukset
     * olivat JobContractType::FIXED_TERM-tyypillä).
     */
    CONTRACT_WORK: 10,

    /**
     * Väliaikainen
     */
    TEMPORARY: 3,

    /**
     * Osa-aikainen
     */
    PART_TIME: 4,

    /**
     * Lite-sopimus
     */
    LITE: 5,

    /**
     * Muu
     */
    OTHER: 99
};

const contractTypeMap = {
    [contractTypes.OPEN_ENDED]: Translator.trans('constant.contract_type.open_ended', {}, 'contract'),
    [contractTypes.FIXED_TERM]: Translator.trans('constant.contract_type.fixed_term', {}, 'contract'),
    [contractTypes.CONTRACT_WORK]: Translator.trans('constant.contract_type.contract_work', {}, 'contract'),
    [contractTypes.TEMPORARY]: Translator.trans('constant.contract_type.temporary', {}, 'contract'),
    [contractTypes.PART_TIME]: Translator.trans('constant.contract_type.part_time', {}, 'contract'),
    [contractTypes.LITE]: Translator.trans('constant.contract_type.lite', {}, 'contract'),
    [contractTypes.OTHER]: Translator.trans('constant.contract_type.other', {}, 'contract'),
};

export function translateContractType(type) {
    if (type === '0') {
        return _trans('Ei valintaa', {}, 'common');
    }
    return type in contractTypeMap ? contractTypeMap[parseInt(type, 10)] : _trans('Tuntematon', {}, 'common');
}
