import React from 'react';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Value } from 'shared/components/Value';

/**
 * Valittu työnimike.
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const JobTitle = () => {
    const jobTitle = useSelector(select.contract.getJobTitle);

    return (
        <Value>{jobTitle?.subtitle ?? '-'}</Value>
    );
};

export default JobTitle;
