import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import { useDidUpdate } from '@mantine/hooks';
import { FormField, FormikErrors } from 'shared/components/Formik';
import { ChoiceField } from 'shared/components/Formik/ChoiceField';
import Placeholder from 'shared/components/Placeholder';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import { Value } from 'shared/components/Value';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { resolveValueByType } from 'shared/ReForm/constants/resolveValueByType';
import Feedback from 'shared/components/Feedback';
import { resolveDefaultValue } from 'shared/ReForm/utils/resolveDefaultValue';
import { getCustomComponentByFieldName } from 'ContractV3/utils/getCustomComponentByFieldName';
import { usePrevious } from 'shared/hooks/previous';

/**
 * TES-valitsinpalikka.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const CollectiveAgreement = (props) => {
    const { name, placeholder, attributes } = props;
    const fieldProps = getFieldProps(props);

    const dispatch = useDispatch();
    const [field,,helpers] = useField(name);
    const [,,typeHelpers] = useField(fieldNames.TYPE);
    const [employerField] = useField(fieldNames.EMPLOYER);
    const [jobTitleField] = useField(fieldNames.JOB_TITLE);
    const [salaryTypeField] = useField(fieldNames.SALARY_TYPE);
    const isNewContract = useSelector(select.contract.isNewContract);
    const isCloningContract = useSelector(select.contract.isCloningContract);
    const isCompensationEarnerContract = useSelector(select.contract.isCompensationEarnerContract);
    const allowAssignmentContracts = useSelector(select.userMetadata.allowAssignmentContracts);
    const isEmployer = useSelector(select.userMetadata.isEmployer);
    const benefitDecision = useSelector(select.benefitDecision.getBenefitDecision);

    const isAcceptedContract = useSelector(select.contract.isAcceptedContract);
    const isChangingSalary = useSelector(select.contract.isChangingSalary);
    const isJobContract = useSelector(select.contract.isJobContract);

    //Taulukkopalkka käytössä
    const [,, isTableSalaryInUseFieldHelpers] = useField(fieldNames.IS_TABLE_SALARY_IN_USE);

    // Vedetään kaikki salaarit jotta voidaan mahdollisesti resetoida kaikki salaarikytkimet
    const [salariesField,,salariesHelpers] = useField(fieldNames.SALARIES);

    // Nämä ovat Formiki storessa collectiveAgreementVariables-objektin alla joten näiden
    // päivitys TES:sin kautta pitää hoitaa niin että tutkitaan löytyykö ko. variableja objektipompsista
    // ja jos löytyy päivitetään se mahdollisesti löytyvällä defaultValue:lla.
    const [variablesField,,variablesHelper] = useField(fieldNames.COLLECTIVE_AGREEMENT_VARIABLES);

    const hasPresetCollectiveAgreement = useSelector((state) => select.contract.getPresetValueByKey(state, fieldNames.COLLECTIVE_AGREEMENT));

    const loggedUserId = useSelector(select.userMetadata.getUserId);
    // HA-puolella haetaan työnantajan perusteella TES
    const employerId = allowAssignmentContracts
        ? loggedUserId
        : parseInt(employerField.value, 10);

    const isLoading = useSelector((state) => state.loading.effects.collectiveAgreement.fetchCollectiveAgreements);
    const isLoadingBenefitDecision = useSelector((state) => state.loading.effects.benefitDecision.fetchBenefitDecision);
    const isLoadingActiveBenefitDecisions = useSelector((state) => state.loading.effects.benefitDecisions.fetchActiveBenefitDecisions);

    // HA Pirhan-case: https://minun.oima.fi/commune/contract/165564/edit/313913
    // Päättynyt TES
    const existingCollectiveAgreement = useSelector(select.contract.getCollectiveAgreement);
    const existingCollectiveAgreementVariables = useSelector(select.contract.getCollectiveAgreementVariables);
    const selectableCollectiveAgreements = (useSelector(select.collectiveAgreement.getCollectiveAgreements) ?? []);
    // Tutkitaan löytyykö hyväksytyn sopparin TES:iä aktiivisilta tesseiltä. Jos ei => expired
    const hasExistingCollectiveAgreementOnTheList = selectableCollectiveAgreements.find((collectiveAgreement) => (
        collectiveAgreement?.collectiveAgreementId === existingCollectiveAgreement?.collectiveAgreementId
    ));
    const hasToAddExpiredCollectiveAgreementOnTheList = (! isNewContract && ! isCloningContract && ! hasExistingCollectiveAgreementOnTheList);

    const collectiveAgreements = selectableCollectiveAgreements
        // Lisätään hyväksytyltä sopparilta löytyvä TES listalle mikäli sitä ei aktiivisilta tesseiltä löydy.
        // Esim jo expiroitunut TES. Tällä kuitenkin varmistetaan että TESsin voi halutessaan vaihtaa mutta estetään
        // TESsin vaihtuminen "pellin alla".
        .concat(hasToAddExpiredCollectiveAgreementOnTheList
            ? ({
                ...existingCollectiveAgreement,
                name: `${existingCollectiveAgreement?.name} ${_trans('(ei enää valittavissa)', {}, 'jobContract')}`,
                disabled: true
            })
            : [])
        .map((collectiveAgreement) => ({
            ...collectiveAgreement,
            // Purkkafix koska Ansku panikoi
            collectiveAgreementId: String(collectiveAgreement.collectiveAgreementId),
        }));

    // HOX: Here's the beef. Jos muokattavalla sopparilla oleva TES ei ole enää aktiivinen => ei löydy listalta => valitaan vanha TES
    // eikä yritetä etsiä tuoreelta listalta
    const collectiveAgreement = useMemo(() => (collectiveAgreements
        .find((collectiveAgreement) => parseInt(collectiveAgreement.collectiveAgreementId, 10) === parseInt(field.value, 10))
    ), [field.value, collectiveAgreements]);

    // Kun työnimike valitaan haetaan TES
    useEffect(() => {
        const jobTitleId = parseInt(jobTitleField.value, 10);
        const benefitDecisionId = benefitDecision.id;
        // Päivitetään TES jos arvo on eri kuin viimeksi
        if (! isNaN(jobTitleId) && ! isNaN(employerId) && (!isEmployer || benefitDecisionId)) {
            // OPH:ssa haetaan työtehtävän kautta, HA:ssa työnantajan
            dispatch.collectiveAgreement.fetchCollectiveAgreements({
                ...((isEmployer && !allowAssignmentContracts) && { benefitDecisionId }),
                jobTitleId,
                employerId
            })
                .then((collectiveAgreement) => {
                    // Jos palautui vain yksi TES valitaan se suoraan
                    if (Array.isArray(collectiveAgreement) && collectiveAgreement.length === 1 && ! hasToAddExpiredCollectiveAgreementOnTheList) {
                        helpers.setValue(collectiveAgreement[0].collectiveAgreementId?.toString());
                    } else if (! hasPresetCollectiveAgreement && isNewContract && ! isCloningContract) {
                        // Muutoin nollaa mahdollinen aiempi valinta (jos ei esiasetettu eikä olla kloonaamassa)
                        helpers.setValue('');
                    }
                });

        }
    }, [jobTitleField.value, employerId, hasPresetCollectiveAgreement, isCloningContract, allowAssignmentContracts, isNewContract, benefitDecision?.id]);

    const collectiveAgreementId = collectiveAgreement?.collectiveAgreementId;
    const jobContractType = collectiveAgreement?.jobContractType;

    const previousSalaryType = usePrevious(salaryTypeField.value);
    const previousCollectiveAgreementId = usePrevious(collectiveAgreementId);

    // nollaa lisät ja kulukorvaukset palkkioperusteen vaihtuessa
    useDidUpdate(() => {
        // Palkkiosoppareilla ei härvätä palkkioiden kanssa ollenkaan
        // Ei myöskään härväill jos ollaan hyväksytyllä työsopparilla jonka palkkaa ei haluta muuttaa
        if (isCompensationEarnerContract || (isAcceptedContract && isJobContract && ! isChangingSalary)) return;

        const hasSalaryTypeChanged = previousSalaryType && previousSalaryType !== salaryTypeField.value;
        const hasCollectiveAgreementChanged = previousCollectiveAgreementId && previousCollectiveAgreementId !== collectiveAgreementId;

        if (hasSalaryTypeChanged || hasCollectiveAgreementChanged) {
            const salaries = (salariesField?.value ?? []).map((salary) => ({
                ...salary,
                [fieldNames.INITIAL_SALARY]: resolveDefaultValue(getCustomComponentByFieldName(fieldNames.INITIAL_SALARY)),
                [fieldNames.INITIAL_PERSONAL_SALARY]: resolveDefaultValue(getCustomComponentByFieldName(fieldNames.INITIAL_PERSONAL_SALARY)),
                [fieldNames.COST_REIMBURSEMENT]: resolveDefaultValue(getCustomComponentByFieldName(fieldNames.COST_REIMBURSEMENT)),
                [fieldNames.COST_REIMBURSEMENT_CUSTOM]: resolveDefaultValue(getCustomComponentByFieldName(fieldNames.COST_REIMBURSEMENT_CUSTOM)),
                [fieldNames.TABLE_SALARY_ATTRIBUTES]: resolveDefaultValue(getCustomComponentByFieldName(fieldNames.TABLE_SALARY_ATTRIBUTES)),
                [fieldNames.COST_REIMBURSEMENT_ATTRIBUTES]: resolveDefaultValue(getCustomComponentByFieldName(fieldNames.COST_REIMBURSEMENT_ATTRIBUTES)),
                [fieldNames.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE_ATTRIBUTES]: resolveDefaultValue(getCustomComponentByFieldName(fieldNames.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE_ATTRIBUTES)),
            }));
            salariesHelpers.setValue(salaries);
        }
    }, [previousSalaryType, salaryTypeField.value, previousCollectiveAgreementId, collectiveAgreementId, isCompensationEarnerContract, isAcceptedContract, isJobContract, isChangingSalary]);

    // Kun valitaan TES haetaan rajoitukset ja päivitetään TES:n muuttujat (allow_transportations, is_billable, ym.)
    useEffect(() => {
        if (isNaN(collectiveAgreementId) || isNaN(jobContractType) || isLoading) {
            return;
        }

        // Tutkitaan löytykö TES:siltä tulleista variaabeleista samaa namea kuin mitä pohjassa.
        // Jos löytyy ja sille löytyy myös oletusarvo => päivitetään Formikin stateen
        const variables = (hasExistingCollectiveAgreementOnTheList
            ? collectiveAgreement.variables
                .reduce((acc, { name, type, defaultValue }) => (
                    variablesField.value.hasOwnProperty(name) && defaultValue
                        ? ({ ...acc, [name]: resolveValueByType({ type, value: defaultValue }) })
                        : acc
                ), {})
            : Object.entries(existingCollectiveAgreementVariables)
                .reduce((acc, [name, { value }]) => ({
                    ...acc,
                    [name]: {
                        value,
                        variable: {
                            isHidden: false,
                            isModifiable: true,
                        }
                    }
                }), {}));

        // TES:ltä ei löydy taulukkoliksoja. Taulukkoliksakytkimet offille (joka resetoi itse arvot).
        if (! collectiveAgreement?.hasTableSalaries) {
            const salaries = salariesField.value.map((salary) => ({
                ...salary,
                tableSalary: null,
                isTableSalaryInUse: false,
            }));
            salariesHelpers.setValue(salaries);
        }
        isTableSalaryInUseFieldHelpers.setValue(collectiveAgreement?.hasTableSalaries);

        dispatch.contract.fetchCollectiveAgreementRestrictions(collectiveAgreementId);
        // Olemassa olevilla soppareilla TES tulee sopparin mukana
        if (isNewContract) {
            // Lisää purkkaa
            if (variables && Object.keys(variables).length > 0) {
                variablesHelper.setValue(variables);
            }
        }
        if (! collectiveAgreement?.disabled) {
            dispatch.contract.selectCollectiveAgreement({
                collectiveAgreement,
                hasCollectiveAgreementExpired: hasToAddExpiredCollectiveAgreementOnTheList,
            });
        }
        // Sopparin tyyppi TES:n kautta: toimeksianto, työsopimus tms.
        typeHelpers.setValue(jobContractType);
    }, [collectiveAgreementId, jobContractType, isNewContract, isLoading]);

    // Onko valtisin piilotettuna
    if (attributes?.isHidden ?? false) return null;

    // Näytetään latausteksti vain jos tessejä ei ole vielä ladattu yhtään
    if (isLoading && collectiveAgreements.length === 0) {
        return (
            <FormField {...fieldProps} isContentFormField={false}>
                <div>
                    {_trans('Ladataan työehtosopimuksia', {}, 'jobContract')}
                </div>
            </FormField>
        );
    }

    // TES valittu ja niitä on vain yksi joten näytetään vain se yksi valinta.
    const hasSelectedTES = collectiveAgreement && collectiveAgreements.length === 1;

    const content = hasSelectedTES
        ? <Value ariaDescribedBy={name}>{collectiveAgreement.name}</Value>
        : (
            // Muuten näytetään placeholder aiempien tessien päällä latauksen aikana
            <ChoiceField
                placeholder={placeholder}
                name={name}
                options={collectiveAgreements}
                valueKey="collectiveAgreementId"
                labelKey="name"
            />
        );

    return (
        <FormField {...fieldProps} isContentFormField={false}>
            <Placeholder type="text" rows={2} isPending={isLoading || isLoadingBenefitDecision || isLoadingActiveBenefitDecisions}>
                {collectiveAgreements.length === 0
                    ? (
                        <Feedback
                            type="warning"
                            message={_trans('Laskennallisia asetuksia ei löytynyt. Ota yhteyttä asiakaspalveluun.', {}, 'jobContract')}
                        />
                    )
                    : content
                }
            </Placeholder>
            <FormikErrors name={name} />
        </FormField>
    );
};

CollectiveAgreement.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    attributes: PropTypes.object,
};

CollectiveAgreement.defaultProps = {
    placeholder: _trans('Valitse tehtävä työ', {}, 'jobContract'),
    attributes: {},
};

export default CollectiveAgreement;
