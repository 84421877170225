import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { FormField, DecimalField } from 'shared/components/Formik';
import { validators } from 'shared/validators';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import {
    MAX_HOURS_PER_WEEK,
    REGULAR_HOURS_PER_MONTH,
    REGULAR_HOURS_PER_WEEK, resolveWorkHoursUnitLabel
} from 'shared/constants/workTimeEstimationTypes';

/**
 * Sopimuksen mukaiset työtunnit.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */

const translateWorkHoursLabelMap = {
    minWorkHours: _trans('Työtunnit vähintään', {},'jobContract'),
    maxWorkHours: _trans('Työtunnit enintään', {},'jobContract'),
    regularWorkHours: _trans('Työtunnit', {},'jobContract'),
};
const WeeklyWorkHours = (props) => {
    const { name } = props;
    
    const [workEstimationTypeField] = useField(fieldNames.WORK_TIME_ESTIMATION_TYPE);

    const [workTimeEsimationTypeField] = useField(fieldNames.WORK_TIME_ESTIMATION_TYPE);
    const workTimeEstimationType = parseInt(workTimeEsimationTypeField.value, 10);

    const [weeklyHoursField] = useField(fieldNames.WEEKLY_WORK_HOURS);
    const [maxWeeklyHoursField] = useField(fieldNames.MAX_WEEKLY_WORK_HOURS);
    const weeklyWorkHours = parseFloat(weeklyHoursField.value);

    const maxHours = parseFloat(maxWeeklyHoursField.value) || 0;
    const minHours = weeklyWorkHours || 0;

    //säännöllinen työaika tai max työaika
    const regularOrMaxWorkTimeType = [REGULAR_HOURS_PER_WEEK, MAX_HOURS_PER_WEEK, REGULAR_HOURS_PER_MONTH ];
    const isRegularOrMaxHoursType = regularOrMaxWorkTimeType.some((reg) => String(reg) === String(workEstimationTypeField.value));

    return (
        <Fragment>
            {! isRegularOrMaxHoursType ? (
                <div className="u-margin-right-small u-margin-bottom-small">
                    <FormField
                        name={name}
                        isRequired
                        label={translateWorkHoursLabelMap.minWorkHours}
                    >
                        <DecimalField name={name} size={2} validate={() => (
                            validators.isDecimal(weeklyHoursField.value) && weeklyWorkHours >= 0
                                ? null
                                : _trans('Työtunteja on oltava vähintään 0.', {}, 'jobContract')
                        )} />
                        <span className="u-margin-left-tiny">
                            {resolveWorkHoursUnitLabel(workTimeEstimationType)}
                        </span>
                    </FormField>
                    <FormField
                        isRequired
                        name={fieldNames.MAX_WEEKLY_WORK_HOURS}
                        label={translateWorkHoursLabelMap.maxWorkHours}
                    >
                        <DecimalField
                            name={fieldNames.MAX_WEEKLY_WORK_HOURS}
                            size={2}
                            validate={() =>
                                (validators.isDecimal(maxWeeklyHoursField.value) && maxHours > minHours) ?
                                    null :
                                    _trans('Työtuntien enimmäismäärän tulee olla suurempi luku kuin vähimmäismäärän.', {}, 'jobContract')}
                        />
                        <span className="u-margin-left-tiny">
                            {resolveWorkHoursUnitLabel(workTimeEstimationType)}
                        </span>
                    </FormField>
                </div>
            ): (
                <FormField isRequired name={name} label={translateWorkHoursLabelMap.regularWorkHours}>
                    <DecimalField name={name} size={2} validate={() => (
                        validators.isDecimal(weeklyHoursField.value) && weeklyWorkHours > 0
                            ? null
                            : _trans('Työtunteja on oltava vähintään 1.', {}, 'jobContract')
                    )} />
                    <span className="u-margin-left-tiny">
                        {resolveWorkHoursUnitLabel(workTimeEstimationType)}
                    </span>
                </FormField>
            )}
        </Fragment>
    );
};
WeeklyWorkHours.propTypes = {
    name: PropTypes.string.isRequired,
};
export default WeeklyWorkHours;
