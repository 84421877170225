import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { select } from '@rematch/select';
import contractSelectors from '../../../Contract/contractSelectors';
import PreviousParties from './PreviousParties';
import userRoles from 'shared/constants/userRoles';
import Button from 'shared/components/Button';
import UserBlock from 'shared/components/UserBlock';
import { partiesSelectors, partiesActions } from 'shared/Parties/parties';
import { ActionBar } from 'shared/components';
import { routeKeys } from 'Contract/constants/routes';
import companyUserRelationTypes from 'shared/constants/companyUserRelationTypes';
import { resolvePartyId } from 'shared/Parties/utils/resolvePartyId';
import { tesTypes } from 'shared/constants/tesTypes';

class Party extends Component {
    static propTypes = {
        userRole: PropTypes.number,
        party: PropTypes.object,
        hasStAccount: PropTypes.bool,
        partyReset: PropTypes.func,
        isContractOpen: PropTypes.bool,
        isEditMode: PropTypes.bool,
        editEmployeeUrl: PropTypes.string,
        setInitialEmployee: PropTypes.func,
        dispatch: PropTypes.func,
        isCaredParty: PropTypes.bool,
        caredPartyReset: PropTypes.func,
        isAssignmentContract: PropTypes.bool,
        collectiveAgreements: PropTypes.instanceOf(List),
        isCaredNoop: PropTypes.bool,
        workExperience: PropTypes.array,
    };

    static defaultProps = {
        userRole: -1,
        party: {},
        hasStAccount: false,
        partyReset() {},
        isContractOpen: false,
        isEditMode: false,
        editEmployeeUrl: '',
        setInitialEmployee() {},
        dispatch() {},
        isCaredParty: false,
        caredPartyReset() {},
        isAssignmentContract: false,
        collectiveAgreements: List(),
        isCaredNoop: false,
        workExperience: [],
    };

    resolvePartyId() {
        const { isCaredParty, isAssignmentContract, userRole } = this.props;
        return resolvePartyId(isCaredParty, isAssignmentContract, userRole);
    }

    render() {
        const {
            userRole,
            party,
            hasStAccount,
            isContractOpen,
            isEditMode,
            editEmployeeUrl,
            isCaredParty,
            workExperience,

            // Actions
            partyReset,
            caredPartyReset,
            setInitialEmployee,
            dispatch,
            collectiveAgreements,
            isCaredNoop,
        } = this.props;

        const partyId = this.resolvePartyId();

        const hasOnCallCompensationFamilyCare = isCaredParty && !! collectiveAgreements.find((ca) => ca.get('baseType') === tesTypes.FAMILY_CARE_ON_CALL_COMPENSATION);

        const relationType = isCaredParty
            ? companyUserRelationTypes.RELATION_CARED
            : companyUserRelationTypes.RELATION_EMPLOYEE;

        let changePartyButton = (
            <ActionBar>
                <Button outline onClick={() => isCaredParty ? caredPartyReset() : partyReset()} mdIcon="swap_horiz" width="1/1">
                    {_trans('contract.form.parties.party.button.change_party', { 'party': _trans(`contract.${partyId}.singular`) })}
                </Button>
                {userRole !== userRoles.EMPLOYEE && (
                    <Button onClick={() => {
                        setInitialEmployee(party.toJS());
                        dispatch(push(`${editEmployeeUrl}?relation=${relationType}`));
                    }} outline mdIcon="edit" width="1/1">
                        {_trans('contract.button.modify')}
                    </Button>
                )}
            </ActionBar>
        );
        // jos sopimusta muokataan tai se on auki ei anneta enää vaihtaa osapuolia
        if (isContractOpen || isEditMode) changePartyButton = null;

        return (
            <div>
                { party.count() === 0
                    ? (
                        <PreviousParties
                            relationType={isCaredParty
                                ? companyUserRelationTypes.RELATION_CARED
                                : companyUserRelationTypes.RELATION_EMPLOYEE}
                            partyPrefix={partyId}
                            hasOnCallCompensationFamilyCare={hasOnCallCompensationFamilyCare}
                        />
                    ) : (
                        <div>
                            {isCaredNoop
                                ? (
                                    <strong>{_trans('Ei hoidettavaa', {}, 'extract')}</strong>
                                ) : (
                                    <UserBlock
                                        workExperienceHistory={isCaredParty ? [] : workExperience}
                                        hasAccordion={userRole !== userRoles.EMPLOYEE}
                                        user={party.toJS()}
                                        hasMinimalInfo={hasStAccount}
                                        isEmployee={! isCaredParty}
                                        size="small"
                                    />
                                )}
                            <div className="u-margin-top-small">
                                {changePartyButton}
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    userRole: partiesSelectors.getUserRole(state),
    party: props.isCaredParty ? partiesSelectors.getCaredParty(state) : partiesSelectors.getParty(state),
    partyHasStAccount: state.partyHasStAccount,
    isContractOpen: contractSelectors.isContractOpen(state),
    isEditMode: contractSelectors.getEditMode(state),
    editEmployeeUrl: state.routes.get(routeKeys.NEW_EMPLOYEE),
    isAssignmentContract: contractSelectors.isAssignmentContract(state),
    collectiveAgreements: state.collectiveAgreements,
    isCaredNoop: props.isCaredParty && partiesSelectors.isCaredNoop(state),
    workExperience: select.employeeWorkExperience.getEmployeeWorkExperience(state),
});

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(partiesActions, dispatch),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Party);
