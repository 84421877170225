import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { breakpoints } from 'shared/constants';
import GoBackLink from 'shared/components/GoBackLink';
import PageHeading from 'shared/components/PageHeading';
import commonUtils from 'shared/utils/commonUtils';
import setDocumentTitle from 'shared/utils/setDocumentTitle';
import Sticky from 'shared/components/Sticky';

/**
 * Geneerinen sivu-komponentti jolle voi määrittää leveyden halutessaan.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Page = (props) => {
    const {
        heading,
        children,
        maxWidth,
        hasContentPadding,
        modifierClass,
        goBack,
        title,
        isBodyClear,
        isPageHeadingSticky,
        actionItems,
        ...rest
    } = props;


    // useTitle(title);

    useEffect(() => {
        if (title) {
            setDocumentTitle(title);
        }

        // Jos bodyClear, asetetaan CSS-luokka bodyyn
        if (isBodyClear) {
            commonUtils.addClass(document.body, 'body--clear');
        } else {
            // Muutoin poistetaan luokka, jottei se jää päälle.
            commonUtils.removeClass(document.body, 'body--clear');
        }
    }, [title, isBodyClear]);

    const pageClass = classNames('o-page', modifierClass, {
        ['o-page--' + maxWidth]: breakpoints.includes(maxWidth),
        'u-padding-none': ! hasContentPadding,
    });

    let pageStyle = {};
    if (typeof maxWidth === 'number') {
        pageStyle = {
            maxWidth,
        };
    }

    const goBackTo = goBack?.to ?? null;
    const goBackHref = goBack?.href ?? null;
    // Mahdollinen paluulinkki sivulla
    const hasGoBackLink = goBackTo !== null || goBackHref !== null;

    const getPageHeading = () => {
        if (isPageHeadingSticky) {
            return (
                <Sticky stickTo="top" stickyClass="is-sticky" modifierClass="c-sticky-bar c-sticky-bar--top u-margin-bottom@medium">
                    <PageHeading actionItems={actionItems} {...rest}>
                        {heading}
                    </PageHeading>
                </Sticky>
            );
        }

        return (
            <PageHeading actionItems={actionItems} {...rest}>
                {heading}
            </PageHeading>
        );
    };

    return (
        <section className={pageClass} style={pageStyle}>
            { hasGoBackLink && (
                <div className="c-header__back-button u-margin-bottom@medium">
                    <GoBackLink
                        text={goBack?.text ?? _trans('link.back')}
                        to={goBackTo ?? ''}
                        href={goBackHref ?? ''}
                    />
                </div>
            )}
            { heading && getPageHeading() }
            { children }
        </section>
    );
};

Page.defaultProps = {
    heading: '',
    maxWidth: 'large',
    hasContentPadding: true,
    isBodyClear: true,
    isPageHeadingSticky: false,
    goBack: {},
    modifierClass: null,
    title: null,
    actionItems: null,
};

Page.propTypes = {
    /**
     * Sivun vaihtoehtoinen otsikko.
     */
    heading: PropTypes.string,

    /**
     * Sisältö jonka komponentti wrappaa sisäänsä.
     */
    children: PropTypes.node.isRequired,

    /**
     * Sivun maksimileveys. Annetaan CSS-luokkana.
     */
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Onko sivun sisällöllä ilmaa reunoissa?
     */
    hasContentPadding: PropTypes.bool,

    /**
     * Jos halutaan muuttaa sivun titleä, niin tällä voidaan se määritellä. Toimii erityisesti React Routerin alla
     */
    title: PropTypes.string,

    /**
     * Asetetaan body--clear CSS-luokka bodylle, taustaväri muuttuu valkoiseksi.
     */
    isBodyClear: PropTypes.bool,

    /**
     * Kiinnittyykö otsikko yläreunaan.
     */
    isPageHeadingSticky: PropTypes.bool,

    /**
     * Renderöi paluu napin contentin päälle taikka mobiilissa vasempaa yläreunaan.
     * syntaksi: { to: Route, text: Napin Teksti }
     */
    goBack: PropTypes.shape({
        href: PropTypes.string,
        to: PropTypes.string,
        text: PropTypes.string,
    }),

    /**
     * CSS-muokkausluokka.
     */
    modifierClass: PropTypes.string,

    /**
     * Sivun päätoiminnot.
     */
    actionItems: PropTypes.node,
};

export default Page;
