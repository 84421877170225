import Moment from 'moment';
import { extendMoment } from 'moment-range';
import api from 'api';
import { uniqueBy } from 'shared/utils/collectionHelpers';
import { TYPE_COST_REIMBURSEMENT } from 'shared/constants/salaryTypes';

const moment = extendMoment(Moment);

export const salaries = hot({
    state: {
        salariesWithSubSalaries: [],
        hasWorkTimeGrouperSettings: false,
    },
    reducers: {
        setSubSalaries: (state, salariesWithSubSalaries) => ({ ...state, salariesWithSubSalaries }),
        setHasWorkTimeGrouperSettings: (state, metadata) => ({ ...state, hasWorkTimeGrouperSettings: metadata?.hasWorkTimeGrouperSettings ?? false }),
    },
    selectors: {
        getSubSalaries: (state) => state.salariesWithSubSalaries,
        getSubSalariesDimensionsForDate: (state, date) => {
            const salary = state.salariesWithSubSalaries.find((salary) =>
                moment.range(salary.startDate, salary.endDate ?? null).contains(moment(date))
            );

            // Uniikit sub salaarin dimensiot. joita voidaan valita, jotta hoitopalkkio kohdistuu oikein.
            const dimensions = uniqueBy(
                (salary?.subSalaries ?? []),
                (subSalary) => subSalary?.targetDimension?.value ?? '-'
            ).map((subSalary) => subSalary?.targetDimension?.value);

            return {
                // Tämä hirvitys kaivaa salaryTypeDimensionType:n nimen, käytännössä ekan subsalaarin targetNimension typen (=nimi)
                type: (salary?.subSalaries ?? [{}])[0]?.targetDimension?.type ?? null,
                dimensions,
            };
        },
        hasCostReimbursementsSalaries: (state, date) => {
            const salary = state.salariesWithSubSalaries.find((salary) =>
                moment.range(salary.startDate, salary.endDate ?? null).contains(moment(date))
            );

            return (salary?.subSalaries ?? []).some((subSalary) => subSalary.type === TYPE_COST_REIMBURSEMENT);
        },
        /**
         * Onko kellonaikapohjaiset hoitovuorokirjaukset käytössä
         */
        hasWorkTimeGrouperSettings: (state) => state.hasWorkTimeGrouperSettings,
    },
    effects: () => ({
        async fetchSubSalaries(jobContract) {
            try {
                const json = await api.get(`/api/contracts/v1/${jobContract}/sub-salaries`);
                this.setSubSalaries(json?.data ?? []);
                this.setHasWorkTimeGrouperSettings(json?.meta ?? {});
            } catch (e) {
                // möks maks
            }
        }
    }),
});
