// Copied from OfflinePayment.php
const offlinePaymentStates = {
    /**
     * Avonainen erä
     */
    DRAFT                  : 10,

    /**
     * Kun maksuerä merkitään maksetuksi, asetetaan tila odottaa maksetuksi asettamista
     * Erän payrollit rullataan ja asetettaan oikein ketjuun
     */
    PENDING_FOR_SET_TO_PAID: 40,
    /**
     * Merkattu maksetuksi
     */
    PAID                   : 50,
    /**
     * Maksu kuitattu saapuneeksi (pitäisi olla joku päivä rahat myös varattu erään)
     */
    PAYMENT_VERIFIED       : 55,
    /**
     * Maksuajo aloitettu
     */
    PAYMENT_RUN_STARTED    : 60,
    /**
     * Maksuerän ajo epäonnistui
     */
    PAYMENT_RUN_FAILED     : 80,
    /**
     * Maksuerän ajo epäonnistui
     */
    PAYMENT_CHECKOUT_FAILED: 81,

    /**
     * Maksuajo valmis lopputoimia varen
     */
    PAYMENT_CHECKOUT        : 90,

    /**
     * Maksuajo valmis
     */
    PAYMENT_RUN_COMPLETED  : 100,
};

export const inProgressStates = [
    offlinePaymentStates.PENDING_FOR_SET_TO_PAID,
    offlinePaymentStates.PAID,
    offlinePaymentStates.PAYMENT_VERIFIED,
    offlinePaymentStates.PAYMENT_RUN_STARTED,
    offlinePaymentStates.PAYMENT_RUN_FAILED,
    offlinePaymentStates.PAYMENT_CHECKOUT,
    offlinePaymentStates.PAYMENT_CHECKOUT_FAILED,
];

/**
 * Maksuerän pitää olla jossakin näistä tiloista mikäli erän palkkojen maksupäiviä halutaan vielä säätää
 */
export const payDayChangeableStates = [
    offlinePaymentStates.DRAFT,
    offlinePaymentStates.PAID,
];

export const translateState = (state) => {
    switch (state) {
        case offlinePaymentStates.DRAFT:
            return 'Vedos';
        case offlinePaymentStates.PENDING_FOR_SET_TO_PAID:
            return 'Maksetaan...';
        case offlinePaymentStates.PAID:
            return 'Maksetuksi asetettu';
        case offlinePaymentStates.PAYMENT_VERIFIED:
            return 'Maksetuksi vahvistettu';
        case offlinePaymentStates.PAYMENT_RUN_STARTED:
        case offlinePaymentStates.PAYMENT_CHECKOUT:
            return 'Maksuajo aloitettu';
        case offlinePaymentStates.PAYMENT_RUN_FAILED:
            return 'Maksuajo EPÄONNISTUI';
        case offlinePaymentStates.PAYMENT_CHECKOUT_FAILED:
            return 'Maksuerän lopputoimet EPÄONNISTUI';
        case offlinePaymentStates.PAYMENT_RUN_COMPLETED:
            return 'Maksuajo suoritettu';
        default:
            return `Tuntematon tila (${state})`;
    }
};

export default offlinePaymentStates;
