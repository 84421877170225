import React from 'react';
import { useField } from 'formik';
import { CollectiveAgreementChoiceField } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/shared/CollectiveAgreementChoiceField';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { translatePayPeriodType } from 'shared/constants/payPeriodTypes';

/**
 * Palkanmaksujakso.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const PayPeriodLength = (props) => {
    const [salaryTypeField] = useField(fieldNames.SALARY_TYPE);

    return (
        <CollectiveAgreementChoiceField
            primaryType={salaryTypeField.value}
            restrictionKey="allowedSalaryTypesAndPayPeriodLengths"
            translator={translatePayPeriodType}
            fieldProps={props}
        />
    );
};

export default PayPeriodLength;
