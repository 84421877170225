import React from 'react';
import PropTypes from 'prop-types';
import EmployeeWorkExperienceForm from 'shared/components/Formik/EmployeeWorkExperience';
import { translateEditTypeLabel } from 'shared/constants/workExperienceTypes';

const ExperienceForm = ({ jobTitles, selectedEditType, jobContractId, selectedJobTitle, isEditForm, selectableCollectiveAgreements }) => (
    <fieldset>
        <legend>
            {translateEditTypeLabel(selectedEditType)}
        </legend>
        <EmployeeWorkExperienceForm
            jobTitles={jobTitles}
            jobContractId={jobContractId}
            selectedEditType={selectedEditType}
            selectedJobTitle={selectedJobTitle}
            isEditForm={isEditForm}
            selectableCollectiveAgreements={selectableCollectiveAgreements}
        />
    </fieldset>
);

ExperienceForm.propTypes = {
    jobTitles: PropTypes.array.isRequired,
    selectedEditType: PropTypes.number.isRequired,
    jobContractId: PropTypes.number.isRequired,
    selectedJobTitle: PropTypes.number.isRequired,
    isEditForm: PropTypes.bool.isRequired,
    selectableCollectiveAgreements: PropTypes.array.isRequired,
};

export default ExperienceForm;

