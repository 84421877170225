import { fromJS, List } from 'immutable';

import { promised as request } from 'BubbleWrapAgent';
import { actions as notificationActions } from 'shared/stores/notifications';
import { types } from 'shared/constants/contract';

/**
 * Action types and creators
 */
const namespace = 'jobTitles';

const JOB_TITLES_GET_REQUEST = `${namespace}/JOB_TITLES_GET_REQUEST`;
const jobTitlesGetRequest = () => ({ type: JOB_TITLES_GET_REQUEST });

const JOB_TITLES_GET_OK = `${namespace}/JOB_TITLES_GET_OK`;
const jobTitlesGetOk = (list) => ({ type: JOB_TITLES_GET_OK, list: fromJS(list) });

const JOB_TITLES_GET_FAILED = `${namespace}/JOB_TITLES_GET_FAILED`;
const jobTitlesGetFailed = () => ({ type: JOB_TITLES_GET_FAILED });

/**
 * Initial state
 */
const initialState = {
    list: List([]),
    isFetchingJobTitles: false,
};

/**
 * Reducer
 */
export const jobTitlesReducer = (state = fromJS(initialState), action) => {
    switch (action.type) {

        // Get job titles
        case JOB_TITLES_GET_REQUEST:
            return state
                .set('isFetchingJobTitles', true);

        case JOB_TITLES_GET_OK:
            return state
                .set('list', action.list)
                .set('isFetchingJobTitles', false);

        case JOB_TITLES_GET_FAILED:
            return state
                .set('isFetchingJobTitles', false);

        default:
            return state;
    }
};

/**
 * Actions
 */
const fetchJobTitles = (isAssignmentContract) => (dispatch) => {
    dispatch(jobTitlesGetRequest());

    request
        .get(Routing.generate('contracts_api_1_get_job_titles'))
        .query({ type: isAssignmentContract ? types.ASSIGNMENT_CONTRACTS_FAMILY_CARE : types.JOB_CONTRACT })
        //.use(cache(60*60))
        .then((response) => {
            if (response.statusCode === 200) {
                const jobTitles = response.body.data.map((jobTitle) => ({
                    value: jobTitle.id,
                    label: jobTitle.subtitle,
                    name: jobTitle.name,
                }));
                dispatch(jobTitlesGetOk(jobTitles));
            } else {
                dispatch(jobTitlesGetFailed());
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('notifications.error.fetch_job_titles_failed')
                }));
            }
        }, () => {
            dispatch(jobTitlesGetFailed());
            dispatch(notificationActions.addNotification({
                type: 'error',
                message: _trans('notifications.error.fetch_job_titles_failed')
            }));
        });
};

/**
 * Selectors
 */
const getRootSelector = (state) => state.jobTitles;
const getJobTitles = (state) => getRootSelector(state).get('list');
const isFetchingJobTitles = (state) => getRootSelector(state).get('isFetchingJobTitles');

/**
 * Exports
 */
export const jobTitlesActions = {
    fetchJobTitles,
};

export const jobTitlesSelectors = {
    getJobTitles,
    isFetchingJobTitles,
};

export default {
    reducer: jobTitlesReducer,
    actions: jobTitlesActions,
    selectors: jobTitlesSelectors,
};
