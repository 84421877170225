import _ from 'lodash';

/**
 * Pino-tyyppinen handler.
 * Get: hakee filtteriin sopivan viimeisimmän itemin.
 * Set: lisää pinon päälle itemin (ja poistaa mahdollisen edellisen esiintymän).
 */
export const stackHandler = {
    /**
     * Hakee datasta viimeisen [key, value]-itemin, jonka key sisältää namen.
     * Eli esimerkiksi itemiin ['post.item:pin', {}] matchaa namet 'post',
     * 'item', 'pin', 'post.item', 'item:pin' ja 'post.item:pin'.
     *
     * @param {Array.<String,Object|undefined>} target
     * @param {String} name
     * @returns {Object|undefined}
     */
    get(target, name) {
        if (name!== 'get' && (target[name] || typeof name !== 'string')) {
            return target[name];
        }

        const pattern = new RegExp(`(^|[.:])${name.replace('.', '\\.') || '.+'}($|[.:])`);
        const match = _.findLast(target, (item) => pattern.test(item[0]));
        return match ? match[1] : undefined;
    },

    /**
     * Poistaa [key, value]-itemin, jonka key on sama kuin name ja lisätään sen
     * jälkeen jonon perään [name, data]. Sama name voi esiintyä keynä vain
     * kerran, koska uusi korvaa aina edellisen.
     *
     * @param {Array.<String,Object|undefined>} target
     * @param {String} name
     * @param {Object|undefined} value
     * @returns {boolean} True, koska onnistuu aina.
     */
    set(target, name, value) {
        if (target[name]) {
            target[name] = value;
            return true;
        }

        // 1. poistetaan edellinen versio (vain 1 kpl)
        for (let i = target.length - 1; i >= 0; i--) {
            if (target[i][0] === name) {
                target.splice(i, 1);
                break;
            }
        }

        // 2. lisätään uusi versio
        target.push([name, value]);

        return true;
    }
};

/**
 * Pino-tyyppinen summa-handler.
 * Get: summaa filtteriin sopivat itemit.
 * Set: lisää pinon päälle itemin (ja poistaa mahdollisen edellisen esiintymän).
 */
export const sumHandler = {
    /**
     * Hakee datasta kaikki [key, value]-itemit, joiden key sisältää namen ja
     * summaa niiden valuet yhteen. Eli esimerkiksi itemiin ['post.item:pin', 1]
     * matchaa namet 'post', 'item', 'pin', 'post.item', 'item:pin' ja
     * 'post.item:pin'.
     *
     * @param {Array.<String,Object|undefined>} target
     * @param {String} name
     * @returns {Object|undefined}
     */
    get(target, name) {
        if (name!== 'get' && (target[name] || typeof name !== 'string')) {
            return target[name];
        }

        const pattern = new RegExp(`(^|[.:])${name.replace('.', '\\.') || '.+'}($|[.:])`);
        const matches = _.filter(target, (item) => pattern.test(item[0]));
        return _.sumBy(matches, 1);
    },

    /**
     * Poistaa [key, value]-itemin, jonka key on sama kuin name ja lisätään sen
     * jälkeen jonon perään [name, data]. Sama name voi esiintyä keynä vain
     * kerran, koska uusi korvaa aina edellisen.
     *
     * @param {Array.<String,Object|undefined>} target
     * @param {String} name
     * @param {Object|undefined} value
     * @returns {boolean} True, koska onnistuu aina.
     */
    set: stackHandler.set,
};

export function createStackProxy(data) {
    return new Proxy(data, stackHandler);
}

export function createSumProxy(data) {
    return new Proxy(data, sumHandler);
}

export function createProxy(data, handler) {
    return new Proxy(data, handler);
}

export default createProxy;
