import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MDIcon } from 'shared/components';

/**
 * Valintanappi / -kuvake. Käytetään erilaisissa wizardeissa ja kyselyissä.
 *
 */
function Choice({
    label,
    description,
    choiceAccessKey,
    canUseAccessKey,
    isAccessKeyVisible,
    modifierClass,
    onClick,
    onFocus,
    onKeyDown,
    isSelected,
    picture,
    tabIndex,
    id,
    isDisabled,

}) {
    return (
        <button
            id={id}
            className={classNames('c-choice u-padding-small u-position-relative', {
                [modifierClass]: modifierClass,
                'c-choice--access-key': isAccessKeyVisible && canUseAccessKey,
                'is-selected': isSelected,
                // 'u-padding-vertical-small': picture !== null,
            })}
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onClick();
            }}
            onFocus={() => onFocus(id)}
            onKeyDown={onKeyDown}
            tabIndex={tabIndex}
            disabled={isDisabled}
            type="button"
        >
            { isSelected && (
                <span className="o-pin c-choice__check-icon">
                    <MDIcon
                        size="small"
                        icon="check_circle"
                        modifierClass={classNames('u-margin-bottom-tiny u-margin-left-tiny', { 'u-color-link' : !isDisabled })}
                        isFilled
                    />
                </span>
            )}
            {picture && (
                <span className="c-choice__picture u-font-size-0">
                    {picture}
                </span>
            )}
            { (isAccessKeyVisible && canUseAccessKey) && (
                <span className="c-choice__access-key u-text-center o-pin o-pin--left o-pin--top u-margin-top-small u-margin-left-small" aria-hidden="true">
                    <span className="c-choice__access-key-shortcut o-pin o-pin--right o-pin--top u-padding-horizontal-tiny">
                        <span className="c-choice__access-key-label">{_trans('onboarding.access_key.label')} </span>
                        {choiceAccessKey.toUpperCase()}
                    </span>
                </span>
            )}
            <div className={classNames('c-choice__text', {
                'u-display-inline-block': picture && !modifierClass,
            })}>
                <span className="c-choice__label u-display-inline-block u-text-no-wrap" aria-describedby={`${id}Description`}>
                    {label}
                </span>
                {description && (
                    <Fragment>
                        <div className="c-choice__description" id={`${id}Description`}>
                            {description}
                        </div>
                    </Fragment>
                )}
            </div>
        </button>
    );
}

Choice.defaultProps = {
    description: '',
    choiceAccessKey: null,
    canUseAccessKey: false,
    isAccessKeyVisible: true,
    modifierClass: 'c-choice--one',
    isSelected: false,
    picture: null,
    tabIndex: null,
    onFocus() {},
    onKeyDown() {},
    id: '',
    isDisabled: false
};

Choice.propTypes = {
    /**
     * Valinnan teksti.
     */
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,

    /**
     * Mahdollinen selite.
     */
    description: PropTypes.string,

    /**
     * Valinnan pikanäppäin.
     */
    choiceAccessKey: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
    ]),

    /**
     * Käytetäänkö pikanäppäimiä.
     */
    canUseAccessKey: PropTypes.bool,

    /**
     * Näytetäänkö pikanäppäin.
     */
    isAccessKeyVisible: PropTypes.bool,

    /**
     * CSS:n muokkausluokka.
     */
    modifierClass: PropTypes.string,

    /**
     * Mitä tehdään kun valintaa painetaan.
     */
    onClick: PropTypes.func.isRequired,

    /**
     * Mitä tehdään kun valinta saa fokuksen.
     */
    onFocus: PropTypes.func,

    /**
     * Mitä tehdään kun nappia painetaan.
     */
    onKeyDown: PropTypes.func,

    /**
     * Onko vaihtoehto valittu?
     */
    isSelected: PropTypes.bool,

    /**
     * Mahdollinen kuva.
     */
    picture: PropTypes.node,

    /**
     * Tabindex. Määrittelee voiko elementti saada fokuksen. Käytetään jos ollaan osana listaa ja halutaan
     * hoitaa fokusointi ohjelmallisesti.
     */
    tabIndex: PropTypes.number,

    /**
     * Komponentin yksilöivä tunniste. Käytössä pääsääntöisesti Inquisitorissa näppisnavigointiin.
     */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),


    /**
     * Onko valinta disabloitu
     */
    isDisabled: PropTypes.bool,
};

export default Choice;
