import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ReportingForms } from './ReportingForms';
import { userSelectors } from 'shared/UserDetails/stores/user';
import { templateSubTypes } from 'ContractV3/Builder/constants/templateTypes';
import { reportingFormResourceTypes } from 'shared/ReportingForms/constants/reportingFormResourceTypes';

const ReportingFormsContainer = ({ subType }) => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => userSelectors.getUserId(state));

    useEffect(() => {
        dispatch.reportingForms.clearReportingForm();
        dispatch.reportingForms.fetchReportingForms({ resourceId: userId, resourceType: reportingFormResourceTypes.RELATION_USER });
        dispatch.reportingForms.fetchReportingFormTemplates({ subType, resourceId: userId, resourceType: reportingFormResourceTypes.RELATION_USER });
    }, []);

    return <ReportingForms />;
};

ReportingFormsContainer.propTypes = {
    subType: PropTypes.oneOf(Object.values(templateSubTypes)).isRequired,
};

export default ReportingFormsContainer;
