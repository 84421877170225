import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spring } from 'react-spring/renderprops.cjs';

const Collapse = ({ isOpen = false, children }) => {
    // Mountatessa turha näyttää animaatiota
    const [isImmediate, setIsImmediate] = useState(true);
    useEffect(() => {
        setIsImmediate(false);
    }, [isOpen]);

    return (
        <Spring
            from={{
                height: !isOpen ? 'auto' : 0,
            }}
            to={{
                height: !isOpen ? 0 : 'auto',
            }}
            config={{
                mass: 0.5,
                tension: 400,
                friction: 30,
                precision: 0.01,
                velocity: 0,
            }}
            immediate={isImmediate}
        >
            {(props) => (
                <div
                    className={classNames({
                        'u-overflow-hidden': !isOpen,
                        'u-pointer-events-none': !isOpen,
                    })}
                    aria-hidden={!isOpen}
                    style={props}
                >
                    {children}
                </div>
            )}
        </Spring>
    );
};

Collapse.propTypes = {
    isOpen: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

Collapse.defaultProps = {
    isOpen: false,
};

export default Collapse;
