import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { MaxTextArea } from 'shared/components';
import { CheckboxListField, FormField } from 'shared/components/Formik';
import { metadataNames } from 'ContractV3/constants/metadataNames';
import FormikErrors from 'shared/components/Formik/FormikErrors';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';

export const jobDescriptionMap = [
    { label: _trans('Henkilökohtainen avustaja', {}, 'jobContract'), value: '1' },
    { label: _trans('Kodin arkisissa toimissa avustaminen', {}, 'jobContract'), value: '2' },
    { label: _trans('Hygieniassa avustaminen', {}, 'jobContract'), value: '3' },
    { label: _trans('Pukeutumisessa avustaminen', {}, 'jobContract'), value: '4' },
    { label: _trans('Kommunikoinnissa avustaminen', {}, 'jobContract'), value: '5' },
    { label: _trans('Kaupassakäynti', {}, 'jobContract'), value: '6' },
    { label: _trans('Siivous', {}, 'jobContract'), value: '7' },
    { label: _trans('Ruoanlaitto', {}, 'jobContract'), value: '8' },
    { label: _trans('Muu tehtävänkuva', {}, 'jobContract'), value: 'other' },
];

/**
 * Työtehtävän kuvaus valitsin
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const Description = (props) => {
    const { name, placeholder } = props;
    const [field,,helpers] = useField(name);
    const [descriptionTypeField] = useField(metadataNames.JOB_DESCRIPTION_TYPE);
    const fieldType = descriptionTypeField.value;
    const jobDescriptionField = field.value;
    const [jobDescription, setJobDescription] = useState(jobDescriptionField);

    const descriptionTypes = descriptionTypeField.value ?? [];

    return (
        <FormField {...getFieldProps(props)} isContentFormField={false}>
            <CheckboxListField
                name={metadataNames.JOB_DESCRIPTION_TYPE}
                options={jobDescriptionMap}
                valueKey="value"
                labelKey="label"
                validate={() => {
                    if (descriptionTypes.length === 0) {
                        return _trans('Kenttä on pakollinen.', {}, 'common');
                    }
                }}
            />
            <FormikErrors name={metadataNames.JOB_DESCRIPTION_TYPE} />
            { fieldType?.includes('other') && (
                <Fragment>
                    <MaxTextArea
                        name={name}
                        maxLength={500}
                        placeholder={placeholder}
                        modifierClass="u-1/1"
                        rows={5}
                        value={jobDescription}
                        onChange={(value) => {
                            helpers.setValue(value);
                            setJobDescription(value);
                        }}
                    />
                    <FormikErrors name={name} />
                </Fragment>
            )}
        </FormField>
    );
};
Description.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default Description;
