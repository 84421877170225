export const documentsCategoryTypes = {
    JOB_CONTRACT: 'job-contract',
    PAY_PERIOD : 'pay-period',
    BENEFIT_DECISION: 'benefit-decision',
    USER: 'user',
};

export const translateCategoryType = (type, isCommune ) => {
    switch (type) {
        case documentsCategoryTypes.JOB_CONTRACT:
            return _trans('Sopimus', {}, 'common');
        case documentsCategoryTypes.PAY_PERIOD:
            return isCommune ? _trans('Työnseuranta', {}, 'common') : _trans('Hoitopäivät', {}, 'common');
        case documentsCategoryTypes.BENEFIT_DECISION:
            return _trans('Asiakkuus', {}, 'common');
        case documentsCategoryTypes.USER:
            return _trans('Henkilötiedot', {}, 'common');
        default:
            return null;
    }
};

