import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Rajoitetun tekstisisällön syöttö. Näyttää kuinka paljon merkkejä vielä voidaan syöttää.
 * Pätkäisee esim. pastetetun tekstin ja jos annettu alkuteksti on pidempi kuin sallittu, pätkäisee senkin.
 */
export default class MaxTextArea extends Component {
    static propTypes = {
        value: PropTypes.string,
        maxLength: PropTypes.number,
        onChange: PropTypes.func,
        modifierClass: PropTypes.string,
        innerRef: PropTypes.func,
    };

    static defaultProps = {
        value: '',
        maxLength: 250,
        onChange() {},
        modifierClass: null,
        innerRef: null,
    };

    constructor(props) {
        super(props);
        const { maxLength } = props;

        // Rajoitetaan alussa annettu arvo jos se onkin pidempi kuin mitä kenttä sallii.
        const truncatedValue = _.get(props, 'value', '').substr(0, maxLength);

        this.state = {
            value: truncatedValue,
            hasHitBoundary: false,
        };

        this.props.onChange(truncatedValue);
    }

    /**
     * Päivitetään arvo.
     * @param nextProps
     * @param prevState
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { value } = nextProps;

        if (value !== prevState.value) {
            return {
                value,
            };
        }
        return null;
    }

    /**
     * Tarkistaa onko syötetty sisältö sallitun merkkimäärän rajoissa.
     * @param event
     */
    onChange = (event) => {
        const { maxLength } = this.props;

        const value = event.target.value;
        const valueLength = value.length || 0;

        if (valueLength <= maxLength) {
            this.setState({
                value,
            });
            this.props.onChange(value);
        } else {
            const truncatedValue = value.substr(0, maxLength);

            this.setState({
                value: truncatedValue,
                hasHitBoundary: true,
            });

            this.props.onChange(truncatedValue);

            setTimeout(() => {
                this.setState({
                    hasHitBoundary: false,
                });
            }, 500);
        }
    };

    render() {
        const { maxLength, modifierClass, innerRef } = this.props;
        const { value, hasHitBoundary } = this.state;
        const restProps = _.omit(this.props, ['maxLength', 'modifierClass', 'innerRef']);

        return (
            <Fragment>
                <div
                    className={classNames(modifierClass, {
                        'u-display-inline-block': !modifierClass,
                    })}
                >
                    <textarea
                        {...restProps}
                        ref={innerRef}
                        onChange={this.onChange}
                        value={value}
                        className="u-1/1"
                    />
                    <div
                        className={classNames('u-text-right', {
                            'u-animation-shake u-color-negative': hasHitBoundary,
                        })}
                    >
                        {`${value.length} / ${maxLength}`}
                    </div>
                </div>
            </Fragment>
        );
    }
}
