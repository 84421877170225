import { produce } from 'immer';
import api from 'api';

/**
 * Palkkalaskelma.
 */
export default {
    state: {
        hasCorrectionPayroll: false,
    },

    reducers: {
        setPayroll: (state, payload) => payload,
        setEarnedIncomes: produce((state, payload) => {
            state.earnedIncomes = payload;
        }),
        setPendingPayroll: produce((state, payload) => {
            state.pendingPayroll = payload;
        }),
        setCorrectionPayroll: produce((state, payload) => {
            state.correctionPayroll = payload;
        }),
        setHasCorrectionPayroll: (state, payload) => ({ ...state, hasCorrectionPayroll: payload }),
    },

    selectors: {
        getPayroll: (state) => state,
        getPayday: (state) => state.payday,
        getPayPeriod: (state) => state?.payPeriod ?? [],
        getJobContractId: (state) => state.jobContract.jobContractId,
        getEmployee: (state) => state?.employee ?? [],
        getPayableNetPay: (state) => state.payableNetPay,
        getPayPeriodLength: (state) => state.jobContractData.payPeriodLength,
        // Palauttaa siinä järjestyksessä missä lisätty eli vastaa sitä mitä näkyy palkkalaskelmalla
        getEarnedIncomes: (state) => state?.earnedIncomes ?? [],
        getPendingPayroll: (state) => state?.pendingPayroll ?? {},
        getCorrectionPayroll: (state) => state?.correctionPayroll ?? {},
        hasCorrectionPayroll: (state) => state.hasCorrectionPayroll ?? false,
    },

    effects: (dispatch) => ({
        /**
         * Hakee palkkalaskelman id:n perusteella.
         *
         * @param payroll
         */
        async fetchPayroll(payroll) {
            try {
                const response = await api
                    .get(Routing.generate('get_payroll', { payroll }));

                const status = response?.status ?? 'error';
                const data = response.data;

                if (status === 'ok' && data) {
                    // Lomakorvaukset pois ja järjestys id:n mukaan
                    const earnedIncomes = data.earnedIncomes
                        .sort((itemA, itemB) => itemA.earnedIncomeId < itemB.earnedIncomeId ? -1 : 1);

                    const payroll = {
                        ...data,
                        earnedIncomes,
                    };

                    this.setPayroll(payroll);
                    return payroll;
                } else {
                    dispatch.notifications.addError(_trans('payroll.notifications.error.payroll_fetch_failed'));
                }
            } catch (error) {
                const code = error.code;
                const messageKey = code !== 404
                    ? 'payroll.notifications.error.payroll_fetch_failed'
                    : 'payroll.notifications.error.payroll_not_found';

                dispatch.notifications.addError(_trans(messageKey));
            }
        },

        async fetchCorrectionPayroll(payPeriod) {
            try {
                const response = await api.get(Routing.generate('get_correction_payroll', { payPeriod }));
                const data = response?.data;
                // Dataa ei löytynyt, näytä virheviesti.
                if (! data || response.error) {
                    dispatch.notifications.addError(_trans(response?.message) ?? _trans('payroll.editor.notifications.error.accept_failed'));
                    return false;
                } else {
                    this.setCorrectionPayroll(data);
                    return data;
                }
            } catch (error) {
                console.error(error);
                const defaultMessage = 'payroll.editor.notifications.error.accept_failed';
                const message = error?.message ?? defaultMessage;
                dispatch.notifications.addError(_trans(message));
                return false;
            }
        },

        /**
         * Palauttaa uuden pendaavan palkkalaskelman
         *
         * @param payPeriod
         * @returns {Promise<boolean>}
         */
        async postPendingPayrollCorrection(payPeriod) {
            try {
                const response = await api.post(Routing.generate('post_payroll_correction_preview', { payPeriod }), { debug: true });
                const data = response?.data;
                // Dataa ei löytynyt, näytä virheviesti.
                if (! data || response.error) {
                    dispatch.notifications.addError(_trans(response?.message) ?? _trans('payroll.editor.notifications.error.accept_failed'));
                    return false;
                } else {
                    this.setPendingPayroll(data);
                    return true;
                }
            } catch (error) {
                console.error(error);
                const defaultMessage = 'payroll.editor.notifications.error.accept_failed';
                const message = error?.message ?? defaultMessage;
                dispatch.notifications.addError(_trans(message));
                return false;
            }
        },

        /**
         * Korjaavan pendaavan palkkajakson poisto
         *
         * @param payPeriod
         * @returns {Promise<null>}
         */
        async deletePendingPayrollCorrection(payPeriod) {
            try {
                return await api.del(Routing.generate('delete_correction_pay_period', { payPeriod }));
            } catch (error) {
                return error;
            }
        },

        /**
         * Payrollin poisto (takaisin tuntien syöttöön)
         *
         * @param payroll
         * @returns {Promise<boolean>}
         */
        async deletePayroll(payroll) {
            try {
                await api.del(Routing.generate('api_1_delete_payroll', { payroll }));
                return true;
            } catch (error) {
                return false;
            }
        },

        /**
         * Hyväksyy palkankorjauksen palkkalaskelman
         *
         * @param payPeriod
         * @returns {Promise<void>}
         */
        async acceptPayrollCorrection(payPeriod) {
            try {
                const response = await api.post(
                    Routing.generate('post_payroll_correction', { payPeriod }),
                );

                if (response.status === 'error') {
                    dispatch.notifications.addError(response.message);
                    return response;
                }

                dispatch.notifications.addSuccess(_trans('payroll.editor.notifications.success.accept_confirmed'));
                return response;
            } catch (error) {
                return error;
            }
        },
    }),
};

export const loadingSelectors = {
    isLoadingPayroll: (state) => state?.loading.effects.payroll.fetchPayroll ?? false,
    isPostingPendingPayrollCorrection: (state) => state?.loading.effects.payroll.postPendingPayrollCorrection ?? false,
    isAcceptingPayrollCorrection: (state) => state?.loading.effects.payroll.acceptPayrollCorrection ?? false,
};
