import React from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/Button';
import { FormField } from 'shared/components/Formik';

const RemoveSalaryButton = ({ index, arrayHelpers }) => {
    if (index === 0) return null;

    return (
        <FormField isContentFormField={false}>
            <Button
                mdIcon="clear"
                onClick={() => arrayHelpers.remove(index)}
            >
                {_trans('contract.form.salary.remove_sub_salary')}
            </Button>
        </FormField>
    );
};

RemoveSalaryButton.propTypes = {
    arrayHelpers: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
};

export default RemoveSalaryButton;
