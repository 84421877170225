import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ActionBar, Button, Feedback, Page, SubmitButton, ThumbUp } from 'shared/components';
import { confirm } from 'shared/utils';
import { userActions, userSelectors } from 'shared/UserDetails/stores/user';
import { usePost } from 'shared/hooks/useApi';
import { FormField, IntegerField, Form } from 'shared/components/Formik';
import { setYupLocale } from 'localization/Yup/setYupLocale';

const resolveErrorMessage = (errorCode) => {
    switch (errorCode) {
        case 'not_identified':
            return _trans('Sinun tulee olla vahvasti tunnistaunut ottaaksesi Visma Connectin käyttöön.', {}, 'loginForm');
        case 'invalid_email':
            return _trans('Virheellinen sähköpostiosoite, ole hyvä ja päivitä sähköpostiosoitteesi.', {}, 'loginForm');
        case 'error_invalid_code':
            return _trans('Virheellinen vahvistuskoodi. Yritä uudelleen ja lähetä uusi vahvistuskoodi.', {}, 'loginForm');
        case 'error_too_many_retries':
            return _trans('Olet yrittänyt liian monesti väärää vahvistuskoodia. Yritä uudelleen hetken kuluttua.', {}, 'loginForm');
        case 'error_email_already_verified':
            return _trans('Olet jo vahvistanut sähköpostin', {}, 'loginForm');
        default:
            return null;
    }
};

setYupLocale();

export const OnboardVismaConnect = () => {
    const dispatch = useDispatch();
    const user = useSelector(userSelectors.getUser);
    const [isVerifyStage, setIsVerifyStage] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [errorCode, setErrorCode] = useState('');
    const [{ isPending: isPendingOnboard }, makeOnboard] = usePost('/api/v2/visma-connect/start-onboarding');
    const [{ isPending: isPendingVerify }, makeVerifyEmail] = usePost('/api/v2/visma-connect/verify-email');

    if (isSuccess || user.get('isVismaConnectUser')) {
        return (
            <Page heading={_trans('Visma Connect käyttöönotto', {}, 'loginForm')}>
                <div className="u-text-center u-margin">
                    <ThumbUp isUp/>
                </div>
                <Feedback type="success">
                    {_trans('Visma Connect on otettu käyttöön.', {}, 'loginForm')}
                    {isSuccess ? _trans(' Seuraavalla kirjautumisella kirjaudut Visma Connect palvelussa.', {}, 'loginForm') : ''}
                </Feedback>
            </Page>
        );
    }

    return (
        <Page heading={_trans('Visma Connect käyttöönotto', {}, 'loginForm')}>
            <p>
                {_trans('Painamalla Aloita Visma Connect käyttöönotto –nappia, siirryt käyttämään Oiman uutta, tietoturvallisempaa kirjautumistapaa.', {}, 'loginForm')}
            </p>
            <p>
                {_trans('Sähköpostiosoitteesi varmennetaan ennen kuin voit siirtyä käyttämään Visma Connectia.', {}, 'loginForm')}
            </p>
            <p>
                {_trans('Suosittelemme siirtymään Visma Connectin käyttöön mahdollisimman pian.', {}, 'loginForm')}
            </p>
            {isVerifyStage ? (
                <Fragment>
                    <p>{_trans('Lähetimme sähköpostitse vahvistuskoodin. Ole hyvä ja syötä se alapuolelle.', {}, 'loginForm')}</p>
                    <Formik
                        initialValues={{ code: '' }}
                        validationSchema={Yup.object().shape({
                            code: Yup.string().required().length(6, _trans('Anna kuusimerkkinen vahvistuskoodi', {}, 'loginForm')),
                        })}
                        onSubmit={(model, formikHelpers) => makeVerifyEmail(model).then((json) => {
                            setErrorCode('');
                            if (json.status === 'ok') {
                                setIsSuccess(true);
                                dispatch(userActions.fetchUserSelf());

                                return;
                            }

                            setErrorCode(json.error);
                            formikHelpers.setFieldError('code', resolveErrorMessage(json.error));
                        })}
                    >
                        <Form alignFields="vertical">
                            <FormField name="code" label={_trans('Vahvistuskoodi', {}, 'loginForm')}>
                                <IntegerField />
                            </FormField>
                            <ActionBar alignItems="left">
                                {errorCode !== '' && (
                                    <Button inProgress={isPendingOnboard} onClick={() => makeOnboard()}>
                                        {_trans('Lähetä vahvistuskoodi uudestaan', {}, 'loginForm')}
                                    </Button>
                                )}
                                <SubmitButton
                                    isPending={isPendingVerify}
                                >
                                    {_trans('Vahvista sähköposti ja viimeistele käyttöönotto', {}, 'loginForm')}
                                </SubmitButton>
                            </ActionBar>
                        </Form>
                    </Formik>
                </Fragment>
            ) : (
                <Fragment>
                    {errorCode !== '' && (
                        <Feedback type="error" modifierClass="u-margin-bottom-small">
                            {resolveErrorMessage(errorCode)}
                        </Feedback>
                    )}
                    <Button
                        inProgress={isPendingOnboard}
                        primary
                        size="large"
                        onClick={() => confirm(
                            _transMd('Visma Connect otetaan käyttöön sähköpostiosoitteelle **%email%**. Voit vaihtaa sähköpostiosoitetta "Vaihda käyttäjätunnus"-toiminolla. Haluatko aloittaa käyttöönoton?', { email: user.get('email') }, 'common'),
                            {
                                proceedLabel: _trans('Aloita', {}, 'common')
                            }
                        ).then(() => makeOnboard().then((json) => {
                            setErrorCode('');
                            if (json.status === 'ok') {
                                // Jos käyttäjällä on vahvistettu emaili jo, niin ei voida verifioida uudestaan
                                if (json.data.isEmailVerified === true) {
                                    setIsSuccess(true);
                                } else {
                                    setIsVerifyStage(true);
                                }

                                return;
                            }

                            setErrorCode(json.error);
                        }))}
                    >
                        {_trans('Aloita Visma Connect käyttöönotto', {}, 'loginForm')}
                    </Button>
                </Fragment>
            )}
        </Page>
    );
};
