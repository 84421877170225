import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

const months = moment.monthsShort();

/**
 * Renderöi kuukausinapit ja näyttää valitun aikavälin.
 * @returns {unknown[]}
 */
export const MonthButtons = ({
    initialYear,
    initialStart,
    initialEnd,
    disableRanges,
    hasMonthNames,
    onSelect,
    hasSingleInput,
    minimumMonth,
    maximumMonth
}) => {
    const [start, setStart] = useState(initialStart);
    const [end, setEnd] = useState(initialEnd);
    const [hoverIndex, setHoverIndex] = useState(-1);

    useEffect(() => {
        setStart(initialStart);
        setEnd(initialEnd);
    }, [initialStart, initialEnd]);

    const isSelected = (index) => {
        if (start === -1) return false;

        const rangeStart = start < hoverIndex ? start : hoverIndex;
        const rangeEnd = start < hoverIndex ? hoverIndex : start;

        const selectionStart = start < end ? start : end;
        const selectionEnd = start < end ? end : start;

        const range = moment.range(rangeStart, rangeEnd);
        const isOverlappingDisabledRange = disableRanges.some((disableRange) => disableRange.overlaps(range));

        const inRange = (end === -1 && start > -1 && index > rangeStart && index < rangeEnd) && !isOverlappingDisabledRange;
        const inSelection = (start > -1 && end > -1 && index >= selectionStart && index <= selectionEnd);

        return inRange || inSelection;
    };

    return (
        Array.from({ length : 4 }, (_, i) => (
            <tr key={i}>
                {Array.from({ length: 3 }, (_, j) => {
                    const index = j + i * 3;
                    const time = new Date(initialYear, index).getTime();

                    // Onko disabloitu annetun minimikk mukaan
                    const isDisabledByMinimum = minimumMonth
                        ? moment(time).isBefore(moment(minimumMonth), 'month')
                        : false;

                    const isDisabledByMaximum = maximumMonth
                        ? moment(time).isAfter(moment(maximumMonth), 'month')
                        : false;

                    // Onko kuukausi disabloitu.
                    const isDisabledByRange = disableRanges.length > 0
                        ? disableRanges.some((disabledRange) => disabledRange.contains(moment(time)))
                        : false;

                    const isDisabled = isDisabledByRange || isDisabledByMinimum || isDisabledByMaximum;

                    return (
                        <td
                            key={j}
                            className="DateRangePicker__DateContainer"
                        >
                            <button
                                type="button"
                                disabled={isDisabled}
                                aria-disabled={isDisabled}
                                aria-label={_trans('month_span.calendar.select_month')}
                                className={classNames('DateRangePicker__Date', {
                                    'DateRangePicker__Date--is-selected': isSelected(time) && ! hasSingleInput,
                                    'DateRangePicker__Date--is-disabled': isDisabled,
                                    'DateRangePicker__Date--is-selectedRangeStart': start === time,
                                    'DateRangePicker__Date--is-selectedRangeEnd': end === time,
                                })}
                                onMouseDown={() => {
                                    if (hasSingleInput) {
                                        setStart(time);
                                        const isOverlappingDisabledRange = disableRanges.some((disableRange) => disableRange.contains(moment(time)));

                                        if (! isOverlappingDisabledRange) {
                                            onSelect({
                                                start: time,
                                            });
                                        }
                                    } else {
                                        if (start < 0 || end > -1) {
                                            setStart(time);
                                            setEnd(-1);
                                        } else {
                                            // Jos ensiksi valittu pvm on viimeistä myöhemmin => swapataan arvot
                                            const first = start < time ? start : time;
                                            const last = start < time ? time : start;
                                            const range = moment.range(first, last);
                                            const isOverlappingDisabledRange = disableRanges.some((disableRange) => disableRange.overlaps(range));

                                            if (! isOverlappingDisabledRange) {
                                                setEnd(time);

                                                onSelect({
                                                    start: first,
                                                    end: last
                                                });
                                            }
                                        }
                                    }
                                }}
                                onMouseEnter={() => {
                                    setHoverIndex(time);
                                }}
                            >
                                {hasMonthNames ? months[index] : index + 1}
                            </button>
                        </td>
                    );
                })}
            </tr>
        ))
    );
};

MonthButtons.propTypes = {
    initialYear: PropTypes.number.isRequired,
    initialStart: PropTypes.number,
    initialEnd: PropTypes.number,
    disableRanges: PropTypes.arrayOf(
        PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string,
        })
    ),
    hasMonthNames: PropTypes.bool,
    onSelect: PropTypes.func,
    minimumMonth: PropTypes.instanceOf(Date),
};

MonthButtons.defaultProps = {
    initialStart: -1,
    initialEnd: -1,
    disableRanges: [],
    hasMonthNames: true,
    onSelect() {},
    minimumMonth: undefined,
};

