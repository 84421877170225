import React from 'react';
import PropTypes from 'prop-types';
import { MDIcon } from 'shared/components/index';
import bottomNavigationItem from 'shared/shapes/bottomNavigationItem';

/**
 * Alapalkin navigaatio. Käytännössä mobiilinäkymissä.
 */
const BottomNavigation = ({ items, moreItems }) => (
    <nav className="c-bottom-navigation-bar u-text-no-wrap">
        <ul className="o-pack u-margin-none">
            {items.map((item, key) => (
                <li
                    className="o-pack__item u-text-center"
                    key={key}
                >
                    <a
                        href={item.href}
                        className="c-bottom-navigation-bar__item u-display-block u-color-default"
                    >
                        {/*<MDIcon icon={item.icon} />*/}
                        <item.icon width={24} height={24} />
                        <div className="c-bottom-navigation-bar__item-title u-text-truncate">
                            {item.label}
                        </div>
                    </a>
                </li>
            ))}
            { moreItems.length > 0 && (
                <li
                    className="o-pack__item u-text-center"
                >
                    <button className="c-bottom-navigation-bar__item u-1/1 u-color-default">
                        <MDIcon icon="menu" />
                        <div className="c-bottom-navigation-bar__item-title u-text-truncate">
                            {_trans('button.more')}
                        </div>
                    </button>
                </li>
            )}
        </ul>
    </nav>
);

BottomNavigation.propTypes = {
    items: PropTypes.arrayOf(bottomNavigationItem),
    moreItems: PropTypes.arrayOf(bottomNavigationItem),
};

BottomNavigation.defaultProps = {
    items: [],
    moreItems: [],
};

export default BottomNavigation;
