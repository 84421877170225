import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Provider, useDispatch, useSelector } from 'react-redux';
import BroadcastChannel from 'broadcast-channel/dist/lib/index';
import FocusTrap from 'focus-trap-react';
import { select } from '@rematch/select';
import Header from './containers/Header';
import { createStore } from './models/createStore';
import { BROADCAST_CHANNEL } from 'shared/utils/LogoutDialog';
import userRoles from 'shared/constants/userRoles';
import { customEvents } from 'shared/utils/customEvents';

const store = createStore();

export const PageHeader = ({ baseHeading }) => {
    store.dispatch.roleSelector.fetchRoles();
    store.dispatch.header.fetchHeaderItems()
        .then(({ isNotificationVisible, userRole }) => {
            // Hae ilmoitukset jos notifikaatiot on ylipäätään näkyvissä
            if (isNotificationVisible) {
                store.dispatch.notificationHub.fetchNotificationMetadata(userRole);
            }
        });
    // Mahdollinen target talteen
    store.dispatch.roleSelector.resolveTarget();

    useEffect(() => {
        store.dispatch.header.setBaseHeading(baseHeading);
    }, [baseHeading]);

    return (
        <Provider store={ store }>
            <HeaderContainer />
        </Provider>
    );
};

PageHeader.propTypes = {
    baseHeading: PropTypes.string.isRequired,
};

const updateNotificationMetadata = ({ dispatch, userRole }) => {
    dispatch.notificationHub.fetchNotificationMetadata(userRole);
};

export const HeaderContainer = () => {
    const dispatch = useDispatch();
    const [isLogoutDialogActive, setIsLogoutDialogActive] = useState(false);
    const hasSelectedRole = useSelector(select.header.hasSelectedRole);
    const isRoleSelectorVisible = useSelector(select.header.isRoleSelectorVisible);

    const userRole = useSelector(select.header.getUserRole);
    // Kunnan täti ei voi vaihtaa roolia jos on vain yksi kunta mistä valita,
    // mutta voi, jos löytyy vähintään yksi yritys
    const communes = useSelector(select.roleSelector.getCommunes);
    const companies = useSelector(select.roleSelector.getCompanies);
    const hasEndUserRelations = useSelector(select.roleSelector.hasEndUserRelations);
    const canSwitchRole = (
        (userRole === userRoles.COMMUNE_OPERATOR && (communes.length > 1 || hasEndUserRelations))
        || userRole !== userRoles.COMMUNE_OPERATOR
        // Kunta käyttäjä voi kuitenkin vaihtaa roolia, jos löytyy yrityksiä (Vaikka OPH sekä sijaismaksaja käyttäjä)
        || (userRole === userRoles.COMMUNE_OPERATOR && companies.length > 0)
    );

    useEffect(() => {
        document.addEventListener(customEvents.UPDATE_NOTIFICATIONS, () => updateNotificationMetadata({ dispatch, userRole }));

        return () => {
            document.removeEventListener(customEvents.UPDATE_NOTIFICATIONS, updateNotificationMetadata);
        };
    }, [userRole]);

    useEffect(() => {
        // Tutkitaan onko focus Beamerin paska-iframessa
        setTimeout(() => {
            const activeElement = document.activeElement;
            if (activeElement) {
                const activeElementClass = activeElement.getAttribute('class');
                if (activeElementClass && activeElementClass.startsWith('beamer_beamer')) {
                    activeElement.blur();
                }
            }
        }, 3000);

        //document.activeElement.blur()

        // Jos istunnon vanhemenisesta kertova dialogi on aktiivinen vapautetaan
        // focus trap (koska muuten ei voida sulkea koskaan istuntodialogia)
        const onLogoutDialogMessage = (message) => {
            setIsLogoutDialogActive(message === 'active');
        };

        const channel = new BroadcastChannel(BROADCAST_CHANNEL);
        channel.addEventListener('message', onLogoutDialogMessage);
        return () => {
            channel.removeEventListener('message', onLogoutDialogMessage);
        };
    }, [dispatch.header, isRoleSelectorVisible, hasSelectedRole, isLogoutDialogActive, canSwitchRole]);

    const isLoading = useSelector((state) => state.loading.effects.header.fetchHeaderItems);

    const navigationItems = useSelector(select.header.getNavigationItems);
    const breakPoints = useSelector(select.header.getBreakPoints);

    if (isLoading) return null;

    return (
        <Fragment>
            <FocusTrap active={isRoleSelectorVisible} paused={isLogoutDialogActive}>
                <Header
                    navigationItems={navigationItems}
                    breakPoints={breakPoints}
                    canSwitchRole={canSwitchRole}
                />
            </FocusTrap>
        </Fragment>
    );
};
