import { blocks } from 'shared/ReForm/constants/blocks';

/**
 * Ryhmittelypalikka.
 */
export const fieldsetSchema = {
    type: 'object',
    title: 'Ryhmä',
    properties: {
        block: { const: blocks.FIELDSET },
        legend: {
            title: 'Ryhmän otsikko',
            '$ref': '#/definitions/translation',
        },
        // Fieldsetin alta löydyttävä läjä palikoita.
        blocks: {
            '$ref': '#/definitions/blocks'
        },
    },
    required: ['block', 'legend', 'blocks']
};
