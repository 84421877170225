import React from 'react';
import PropTypes from 'prop-types';
import bigListItem from 'shared/shapes/bigListItem';
import BigListItem from 'shared/components/BigListItem';

/**
 * Iso valikkolista.
 * Tarkoitettu yksistään mobiilikokoihin BigMenu-komponentin kanssa.
 * @param items
 * @param currentRoute
 * @param hasSvgIcons
 * @param subViewIndex
 * @param setSubViewIndex
 * @returns {*}
 * @constructor
 */
const BigList = ({ items, currentRoute, hasSvgIcons, subViewIndex, setSubViewIndex }) => {
    // Koska home on jokaisen route alussa pitää tarkistaa näin pöljästi
    const selectedIndex = items.findIndex((item, key) => {
        const href = item.href ?? '';
        return key > 0
            ? href.startsWith(currentRoute)
            : href === currentRoute;
    });

    return (
        <ul className="o-list-bare u-margin-bottom-none">
            {items.map((item, key) => {
                const isSelected = selectedIndex === key;

                return (
                    <BigListItem
                        key={key}
                        index={key}
                        item={item}
                        isSelected={isSelected}
                        hasSvgIcon={hasSvgIcons}
                        subViewIndex={subViewIndex}
                        onSetSubViewIndex={(subViewIndex) => setSubViewIndex(subViewIndex)}
                    />
                );}
            )}
        </ul>
    );
};

BigList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        bigListItem,
    ])).isRequired,
    currentRoute: PropTypes.string,
    hasSvgIcons: PropTypes.bool,
    subViewIndex: PropTypes.number,
    setSubViewIndex: PropTypes.func,
};

BigList.defaultProps = {
    currentRoute: '',
    hasSvgIcons: false,
    subViewIndex: -1,
    setSubViewIndex() {},
};

export default BigList;
