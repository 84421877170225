import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { actions } from 'react-redux-form/lib/immutable';
import {
    actions as tesrfActions,
    selectors as tesrfSelectors,
    fieldNames
} from 'shared/TESRestrictedField/TESRestrictedField';
import { translateContractType } from 'shared/constants/contractTypes';
import { translateSalaryType } from 'shared/constants/salaryTypes';
import { translatePayPeriodType } from 'shared/constants/payPeriodTypes';
import { holidayPayMethods, translateHolidayPayMethod } from 'shared/constants/holidayPayMethods';
import { payDayModes, translatePayDayMode } from 'shared/constants/payDayModes';
import { MDSpinner, Dropdown, RadioList } from 'shared/components';
import contractSelectors from 'Contract/contractSelectors';
import { contractDataStates } from 'shared/constants/contractDataStates';

/**
 * Esittää käyttäjän valittavaksi eri vaihtoehtoja perustuen valitun työtehtävän TESiin. Jos vaihtoehtoja
 * on vain yksi, esitetään se oletuksena valittuna. Jos valinnat vaativat jonkin aiemman valinnan tehdyksi ensin,
 * kerrotaan tästä tekstillä.
 */
class TESRestrictedField extends Component {

    render() {
        const {
            restrictions,
            fieldType,
            contractType,
            salaryType,
            isRestrictionsPending,
            payPeriodLength,
            paydayMode,
            payday,
            holidayPayMethod,
            dispatch,
            onChange,
            isReadOnly,
            isAssignmentContract,
        } = this.props;

        let allOptions = null;
        let singleOption = null;
        let placeholder = null;

        const salaryRestrictions = restrictions.get('allowedContractTypesAndSalaryTypes');
        const periodLengthRestrictions = restrictions.get('allowedSalaryTypesAndPayPeriodLengths');
        const payDayRestrictions = restrictions.get('allowedPayPeriodLengthsAndPayDayModes');
        const holidayPayMethodRestrictions = restrictions.get('allowedContractTypesAndHolidayPaymethods');

        const translationDomain = isAssignmentContract ? 'assignment_contract' : 'contract';

        if (isRestrictionsPending) {
            return <MDSpinner size="tiny" />;
        }

        /* eslint-disable no-case-declarations */
        switch (fieldType) {

            // Sopimuksen tyyppi
            case fieldNames.CONTRACT_TYPE:
                if (!salaryRestrictions) {
                    placeholder = _trans('contract.form.tes_restricted_field.select_cba_type_first');
                    break;
                }

                allOptions = (
                    <div>
                        <RadioList
                            items={_.map(salaryRestrictions, 'primaryType')}
                            name={fieldType}
                            value={contractType}
                            onChange={(event) => {
                                dispatch(tesrfActions.changeContractType(event.target.value))
                                    .then(() => dispatch(tesrfActions.validate()));
                                onChange(event.target.value);
                            }}
                            itemValueTranslator={translateContractType}
                        />
                        {this.getError(fieldNames.CONTRACT_TYPE)}
                    </div>
                );
                break;

            // Palkkatyyppi
            case fieldNames.SALARY_TYPE:
                if (!salaryRestrictions) {
                    placeholder = _trans('contract.form.tes_restricted_field.select_cba_type_first');
                    break;
                }
                if (!contractType) {
                    placeholder = _trans('contract.form.tes_restricted_field.select_contract_type_first');
                    break;
                }
                _.each(salaryRestrictions, (item) => {
                    if (item.primaryType == contractType) {
                        if (item.allowedSubtypes.length == 1) {
                            singleOption = translateSalaryType(item.allowedSubtypes[0], translationDomain);
                        } else {
                            allOptions = (
                                <div>
                                    <RadioList
                                        isReadOnly={isReadOnly}
                                        items={item.allowedSubtypes}
                                        name={fieldType}
                                        value={salaryType}
                                        onChange={(event) => {
                                            dispatch(tesrfActions.changeSalaryType(event.target.value))
                                                .then(() => dispatch(tesrfActions.validate()));
                                            onChange(event.target.value);
                                            // Resetoidaan kielilisän valinnat tässä, ettei jää kummittelemaan
                                            // jos muutetaan palkkatyyppiä
                                            dispatch(actions.change('contract.tes.language_compensation.value', 0));
                                            dispatch(actions.change('contract.salary.isLanguageCompensationInUse', false));
                                        }}
                                        itemValueTranslator={(value) => translateSalaryType(value, translationDomain)}
                                    />
                                    {this.getError(fieldNames.SALARY_TYPE)}
                                </div>
                            );
                        }
                    }
                });
                break;

            // Palkanmaksujakso
            case fieldNames.PAY_PERIOD_LENGTH:
                if (!periodLengthRestrictions) {
                    placeholder = _trans('contract.form.tes_restricted_field.select_cba_type_first');
                    break;
                }
                if (!salaryType) {
                    placeholder = _trans('contract.form.tes_restricted_field.select_salary_type_first');
                    break;
                }
                _.each(periodLengthRestrictions, (item) => {
                    if (item.primaryType == salaryType) {
                        if (item.allowedSubtypes.length == 1) {
                            singleOption = translatePayPeriodType(item.allowedSubtypes[0]);
                        } else {
                            allOptions = (
                                <div>
                                    <RadioList
                                        items={item.allowedSubtypes}
                                        name={fieldType}
                                        value={payPeriodLength}
                                        onChange={(event) => {
                                            dispatch(tesrfActions.changePayPeriodLength(event.target.value))
                                                .then(() => dispatch(tesrfActions.validate()));
                                            onChange(event.target.value);
                                        }}
                                        itemValueTranslator={translatePayPeriodType}
                                    />
                                    {this.getError(fieldNames.PAY_PERIOD_LENGTH)}
                                </div>
                            );
                        }
                    }
                });
                break;

            // Palkkapäivä
            case fieldNames.PAYDAY_MODE:
                if (!payDayRestrictions) {
                    placeholder = _trans('contract.form.tes_restricted_field.select_cba_type_first');
                    break;
                }
                if (!payPeriodLength) {
                    placeholder = _trans('contract.form.tes_restricted_field.select_pay_period_length_first');
                    break;
                }
                _.each(payDayRestrictions, (item) => {
                    if (item.primaryType == payPeriodLength) {
                        if (item.allowedSubtypes.length == 1) {
                            singleOption = translatePayDayMode(item.allowedSubtypes[0], translationDomain);
                        } else {
                            allOptions = (
                                <div>
                                    <RadioList
                                        items={item.allowedSubtypes}
                                        name={fieldType}
                                        value={paydayMode}
                                        onChange={(event) => {
                                            dispatch(tesrfActions.resetPayday(event.target.value));
                                            dispatch(tesrfActions.changePaydayMode(event.target.value))
                                                .then(() => dispatch(tesrfActions.validate()));
                                            onChange(event.target.value);
                                        }}
                                        itemValueTranslator={(value) => translatePayDayMode(value, translationDomain)}
                                    />
                                    {this.getError(fieldNames.PAYDAY_MODE)}
                                </div>
                            );
                        }
                    }
                });
                break;

            // Lomanmääräytyminen
            case fieldNames.HOLIDAY_PAY_METHOD:
                if (!holidayPayMethodRestrictions) {
                    placeholder = _trans('contract.form.tes_restricted_field.select_cba_type_first');
                    break;
                }
                if (!contractType) {
                    placeholder = _trans('contract.form.tes_restricted_field.select_contract_type_first');
                    break;
                }
                _.each(holidayPayMethodRestrictions, (item) => {
                    if (item.primaryType == contractType) {
                        if (item.allowedSubtypes.length == 1) {
                            singleOption = translateHolidayPayMethod(item.allowedSubtypes[0]);
                        } else {
                            allOptions = (
                                <div>
                                    <RadioList
                                        tooltips={{
                                            [holidayPayMethods.BEFORE_HOLIDAY_14]: _trans('holiday_days.holiday_year_pay_method.help_text.14d'),
                                            [holidayPayMethods.BEFORE_HOLIDAY_35]: _trans('holiday_days.holiday_year_pay_method.help_text.35h'),
                                            [holidayPayMethods.ENTITLED_TO_HOLIDAY_COMPENSATION]: _trans('holiday_days.holiday_year_pay_method.help_text.no_generation'),
                                        }}
                                        items={this.filterHolidayPayMethodSubTypes(item.allowedSubtypes)}
                                        name={fieldType}
                                        value={holidayPayMethod}
                                        onChange={(event) => {
                                            dispatch(tesrfActions.changeHolidayPayMethod(event.target.value))
                                                .then(() => dispatch(tesrfActions.validate()));
                                            onChange(event.target.value);
                                        }}
                                        itemValueTranslator={translateHolidayPayMethod}
                                    />
                                    {this.getError(fieldNames.HOLIDAY_PAY_METHOD)}
                                </div>
                            );
                        }
                    }
                });
                break;

            // Valittu palkkapäivä palkkajakson jälkeen/kesken palkkajakson
            case fieldNames.PAYDAY:
                // Älä näytä mitään jos palkkapäivää ei valittuna tai sen tyyppi on väärä
                if (!_.includes([
                    payDayModes.PAYDAY_USER_CHOICE,
                    payDayModes.PAYDAY_IN_PAY_PERIOD,
                    payDayModes.PAYDAY_NTH_WEEK_DAY,
                ], parseInt(paydayMode, 10))) {
                    break;
                }

                let customPayDayChoices = _.range(1, 29).map((v) => ({ id: v, name: `${v}. ${_trans('day')}` }));

                // Arkipäivää jakson jälkeen valinta, sallitaan 5-15
                if (parseInt(paydayMode, 10) === payDayModes.PAYDAY_NTH_WEEK_DAY) {
                    customPayDayChoices = _.range(5, 16).map((v) => ({ id:v, name: `${v}. ${_trans('week_day')}` }));
                }
                return (
                    <div id={fieldType}>
                        <Dropdown
                            options={customPayDayChoices}
                            placeholder={_trans('contract.form.salary.pay_day_dropdown_placeholder')}
                            onChange={(value) => {
                                dispatch(tesrfActions.changePayday(value))
                                    .then(() => dispatch(tesrfActions.validate()));
                            }}
                            value={payday ? payday : ''}
                        />
                        {this.getError(fieldNames.PAYDAY)}
                    </div>
                );
            default:
                break;
        }
        /* eslint-enable no-case-declarations */

        // Jos valintoja ei ole eikä kehoitustekstiä löydy älä näytä mitään
        if (!singleOption && !placeholder && !allOptions) return null;

        // Jos valintamahdollisuuksia on tasan yksi, näytä se tekstinä (indikoiden että se on jo oletuksena valittu)
        if (singleOption) return <strong>{singleOption}</strong>;

        // Jos mitään ei ole vielä valittu, näytä valintaan kehoittava teksti
        if (placeholder) return <span className="u-muted">{placeholder}</span>;

        // Näytä valinnat
        return allOptions;
    }

    getError(field) {
        const { errors } = this.props;
        if (errors.get(field)) {
            return <div className="u-color-negative">{_trans(errors.get(field))}</div>;
        }
        return null;
    }

    // rajoittaa holidayPayMethodin riippuen onko valittu kerryttävä tyyppi vai ei
    // ei estä draftitilassa olevan sopimuksen muokkausta
    filterHolidayPayMethodSubTypes(types) {
        const { holidayPayMethod, isFirstDraft, isAllowNoHolidayGeneration } = this.props;
        if (isFirstDraft || ! holidayPayMethod) {
            return types;
        }

        if (! isAllowNoHolidayGeneration) {
            // Bäkkäri voi kertoa, ettei voi valita ei lomaoikeutta valintaa
            types = _.without(types, holidayPayMethods.ENTITLED_TO_HOLIDAY_COMPENSATION);
        }

        if (_.includes([
            holidayPayMethods.PAID_IMMEDIATELY,
            holidayPayMethods.CUSTOMER_ACCOUNT,
        ], holidayPayMethod)) {
            return _.without(types, ...[
                holidayPayMethods.BEFORE_HOLIDAY_14,
                holidayPayMethods.BEFORE_HOLIDAY_35,
                holidayPayMethods.BEFORE_HOLIDAY_BUNDLED_COMMUNE_RULE,
            ]);
        }
        return _.without(types, ...[
            holidayPayMethods.PAID_IMMEDIATELY,
            holidayPayMethods.CUSTOMER_ACCOUNT,
        ]);
    }
}


TESRestrictedField.propTypes = {
    fieldType: PropTypes.oneOf([
        fieldNames.CONTRACT_TYPE,
        fieldNames.SALARY_TYPE,
        fieldNames.PAY_PERIOD_LENGTH,
        fieldNames.PAYDAY_MODE,
        fieldNames.HOLIDAY_PAY_METHOD,
        fieldNames.PAYDAY,
    ]).isRequired,
    contractType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    salaryType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    payPeriodLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    errors: PropTypes.object.isRequired,
    restrictions: PropTypes.object.isRequired,
    isRestrictionsPending: PropTypes.bool.isRequired,
    paydayMode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    payday: PropTypes.number,
    holidayPayMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    isReadOnly: PropTypes.bool,
    isFirstDraft: PropTypes.bool,
    isAllowNoHolidayGeneration: PropTypes.bool,
    isAssignmentContract: PropTypes.bool,
};

TESRestrictedField.defaultProps = {
    paydayMode: null,
    payday: null,
    holidayPayMethod: null,
    onChange() {},
    isReadOnly: false,
    isFirstDraft: false,
    isAllowNoHolidayGeneration: false,
    isAssignmentContract: false,
    salaryType: null,
};

const mapStateToProps = (state) => ({
    restrictions: tesrfSelectors.getRestrictions(state),
    isRestrictionsPending: tesrfSelectors.isRestrictionsPending(state),
    salaryType: tesrfSelectors.getSalaryType(state),
    contractType: tesrfSelectors.getContractType(state),
    paydayMode: tesrfSelectors.getPaydayMode(state),
    payday: tesrfSelectors.getPayday(state),
    payPeriodLength: tesrfSelectors.getPayPeriodLength(state),
    holidayPayMethod: tesrfSelectors.getHolidayPayMethod(state),
    errors: tesrfSelectors.getErrors(state),
    isAssignmentContract: contractSelectors.isAssignmentContract(state),
    isFirstDraft: contractSelectors.getContractDataState(state) !== contractDataStates.MASTER_VERSION
        && contractSelectors.getContractDataCount(state) <= 1,
    isAllowNoHolidayGeneration: state.contract.getIn(['salary', 'allowNoHolidayGeneration'], false),
});

export default connect(mapStateToProps)(TESRestrictedField);
