import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';
import _ from 'lodash';
import Dropdown from 'shared/components/Dropdown';
import { StLabel, StField } from 'shared/components/StForm';
import { locales } from 'shared/constants';

/**
 * Renderöi asiointikielet.
 * Jos asiointikielivaihtoehtoja on alle 6, renderöidään ne radiobuttoneina.
 * Muutoin alasvetovalikkona.
 */
export default class Locale extends Component {

    renderOptions() {
        const {
            model,
            isReadOnly,
        } = this.props;

        const options = _.map(locales, (name, locale) => ({
            id: locale,
            name,
        }));

        if (_.size(locales) > 5) {
            return (
                <StField>
                    <Control
                        {..._.omit(this.props, 'isReadOnly')}
                        id={model}
                        model={model}
                        component={Dropdown}
                        options={options}
                        readOnly={isReadOnly}
                    />
                </StField>
            );
        } else {
            return (
                <StField ariaLabelledby={`${model}Label`}>
                    {_.map(locales, (name, locale) => (
                        <Fragment key={`Locale_${locale}`}>
                            <label>
                                <Control.radio
                                    {..._.omit(this.props, ['fullWidth', 'isReadOnly'])}
                                    model={model}
                                    value={locale}
                                    readOnly={isReadOnly}
                                />
                                {name}
                            </label>
                            <br/>
                        </Fragment>
                    ))}
                </StField>
            );
        }
    }

    render() {
        const {
            model,
            isReadOnly,
        } = this.props;

        if (isReadOnly) {
            return (
                <div>
                    {`${_trans('userForm.locale.label')}: `}
                    <Control.text
                        component={PlainText}
                        model={model}
                        readOnly
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <StLabel htmlFor={model} id={`${model}Label`}>
                        {_trans('userForm.locale.label')}
                    </StLabel>
                    {this.renderOptions()}
                </div>
            );
        }
    }
}

/**
 * Tulosta valittu kieli.
 * @param props
 * @returns {*}
 * @constructor
 */
function PlainText(props) {
    const locale = _.get(locales, props.value, _trans('text.not_selected'));

    return (
        <strong>{locale}</strong>
    );
}

PlainText.propTypes = {
    value: PropTypes.string.isRequired,
};

Locale.propTypes = {
    model: PropTypes.string,
    isReadOnly: PropTypes.bool,
};

Locale.defaultProps = {
    model: '.locale',
    isReadOnly: false,
};
