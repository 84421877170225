import React from 'react';
import PropTypes from 'prop-types';
import DataTable from 'shared/containers/DataTable';
import { Button, OutLink } from 'shared/components';
import { TYPE_CUSTOMER_OWN_ACCOUNT, TYPE_OIMA_CUSTOMER_ACCOUNT } from 'shared/constants/bankAccountTypes';

/**
 * Päätetyt maksukiellot.
 * @returns {JSX.Element}
 * @constructor
 */
export const ForeclosuresList = ({ foreclosures, isAdmin, isClosed, isCommune }) => (
    <DataTable
        columns={[
            {
                Header: _trans('foreclosure.closed.data_table.number'),
                accessor: 'payBanNumber',
            },
            {
                Header: _trans('foreclosure.closed.data_table.rf_reference_number'),
                accessor: 'payBanRFReferenceNumber',
            },
            {
                Header: _trans('foreclosure.closed.data_table.start'),
                accessor: 'startDate',
                type: 'date',
            },
            {
                Header: _trans('foreclosure.closed.data_table.end'),
                accessor: 'endDate',
                type: 'date',
            },
            {
                Header: _trans('Omistaja', {}, 'extract'),
                accessor: 'owner',
                show: isAdmin,
                Cell: OwnerCell
            },
        ]}
        defaultSorted={[
            {
                id: 'endDate',
                desc: true,
            }
        ]}
        emptyDataMessage={isClosed ? _trans('foreclosure.no_closed_foreclosures') : _trans('Henkilöllä ei ole voimassaolevia ulosottoja.', {}, 'extract')}
        data={foreclosures}
        actionsColumn={<ForeclosureActionColumn isAdmin={isAdmin} isCommune={isCommune} />}
        actionsColumnWidth={275}
    />
);

const ForeclosureActionColumn = (props) => {
    const { foreclosureId } = props.original;

    const routePrefix = props.isCommune ? 'commune' : 'home';
    const route = `/${routePrefix}/user-action-records/foreclosure/${foreclosureId}`;

    const queryString = props.isAdmin
        ? '?isAdmin=1'
        : '';

    return (
        <>
            <Button
                modifierClass="u-margin-right-tiny"
                ghost
                target="_blank"
                href={`/api/v1/reports/user_foreclosure_report/${foreclosureId}${queryString}`}
            >
                {_trans('foreclosure.closed.data_table.show_report')}
            </Button>
            {(! props.isAdmin && <OutLink href={route}>
                {_trans('Lokitiedot', {}, 'common')}
            </OutLink>)}
        </>
    );
};

ForeclosuresList.propTypes = {
    foreclosures: PropTypes.array,
    isAdmin: PropTypes.bool,
    isClosed: PropTypes.bool,
    isCommune: PropTypes.bool,
};

ForeclosuresList.defaultProps = {
    foreclosures: [],
    isAdmin: false,
    isClosed: false,
    isCommune: false
};

ForeclosureActionColumn.propTypes = {
    original: PropTypes.object,
    isAdmin: PropTypes.bool,
    isCommune: PropTypes.bool
};

ForeclosureActionColumn.defaultProps = {
    original: {},
    isAdmin: false,
    isCommune: false
};

function OwnerCell(props) {
    const bankAccount = props.original.bankAccount;
    if (! bankAccount) {
        return 'Ei omistajaa';
    }
    switch (bankAccount.bankAccountType) {
        case TYPE_OIMA_CUSTOMER_ACCOUNT:
            return 'Oima';
        case TYPE_CUSTOMER_OWN_ACCOUNT:
            return bankAccount.name;
        default:
            throw new Error('Tuntematon bankAccountType:'+bankAccount.bankAccountType);
    }
}

OwnerCell.propTypes = {
    original: PropTypes.object.isRequired,
};
