import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import confirm from 'shared/utils/confirm';
import Button from 'shared/components/Button';
import ActionBar from 'shared/components/ActionBar';

export const SurchargeActions = (props) => {
    const dispatch = useDispatch();
    const [inProgress, setInProgress] = useState(false);
    const isRemoving = useSelector((state) => state.loading.effects.surcharges.deleteSurcharge);

    return (
        <ActionBar alignItems="left">
            <Button mdIcon="delete" flat title={_trans('button.remove')} inProgress={isRemoving && inProgress} onClick={() => {
                confirm('Oletko varma että haluat poistaa lisämaksun?', {
                    proceedLabel: 'Poista lisämaksu',
                    alert: true,
                }).then(() => {
                    const surchargeId = props.original?.id ?? null;
                    if (surchargeId !== null) {
                        setInProgress(true);
                        dispatch.surcharges.deleteSurcharge({ surchargeId, index: props.index }).then(() => setInProgress(false));
                    } else {
                        dispatch.notifications.addError('Lisämaksun id:tä ei löytynyt.');
                    }
                });
            }} />
            <Button mdIcon="edit" flat title={_trans('button.edit')} onClick={() => {
                const surchargeId = props.original?.id ?? null;
                if (surchargeId !== null) {
                    dispatch.surcharges.setEditableSurcharge(surchargeId);
                    dispatch.surcharges.setIsDialogOpen(true);
                } else {
                    dispatch.notifications.addError('Lisämaksun id:tä ei löytynyt.');
                }
            }} />
        </ActionBar>
    );
};

SurchargeActions.propTypes = {
    original: PropTypes.object,
    index: PropTypes.number,
};

SurchargeActions.defaultProps = {
    original: {},
    index: 0,
};
