import PropTypes from 'prop-types';

export default PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    type: PropTypes.number.isRequired,
    state: PropTypes.number,
    isValid: PropTypes.bool,
    isUsed: PropTypes.bool,
});
