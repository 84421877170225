import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'shared/components/Avatar';
import ReactSelect from 'react-select';
import utils from 'shared/utils/commonUtils';

/**
 * Käyttäjän valitseminen aiempien työsuhteiden perusteella.
 * @param value
 * @param options
 * @param placeholder
 * @param onSelect
 * @param isLoading
 * @returns {*}
 * @constructor
 */
function UserSelect({
    value,
    options,
    placeholder,
    onSelect,
    isLoading,
}) {
    const arrowComponent = () => <i className="material-icons">keyboard_arrow_down</i>;
    return (
        <ReactSelect
            arrowRenderer={arrowComponent}
            onChange={onSelect}
            optionComponent={UserOption}
            options={options}
            placeholder={<span>{placeholder}</span>}
            value={value}
            valueKey="fullName"
            valueComponent={UserValue}
            autoBlur={true}
            isLoading={isLoading}
            noResultsText={_trans('user_select.no_results_text')}
        />
    );
}

UserSelect.propTypes = {
    value: PropTypes.any,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

UserSelect.defaultProps = {
    value: null,
    placeholder: _trans('user_select.employee_name'),
    isLoading: false,
};

class UserValue extends React.Component {
    render() {
        const { value } = this.props;
        return (
            <div className="Select-value Select-value--user o-stack" title={value.title}>
                <span className="Select-value-label">
                    <span className="u-padding-right-tiny">
                        <Avatar src={value.picture_path} size="small" isFixedSize/>
                    </span>
                    {utils.getUserFullName(value)}
                </span>
            </div>
        );
    }
}

UserValue.propTypes = {
    children: PropTypes.node.isRequired,
    value: PropTypes.object,
};

UserValue.defaultProps = {
    value: {},
};

class UserOption extends React.Component {
    onMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.onSelect(this.props.option, event);
    };

    onMouseEnter = (event) => {
        this.props.onFocus(this.props.option, event);
    };

    onMouseMove = (event) => {
        const {
            isFocused,
            onFocus,
            option,
        } = this.props;

        if (isFocused) return;
        onFocus(option, event);
    };

    render() {
        const { option } = this.props;
        return (
            <div
                className={this.props.className}
                onMouseDown={this.onMouseDown}
                onMouseEnter={this.onMouseEnter}
                onMouseMove={this.onMouseMove}
                title={option.title}
            >
                <div className="o-stack">
                    <span className="u-padding-right-tiny">
                        <Avatar src={option.picture_path} size="small" isFixedSize/>
                    </span>
                    <span>
                        <strong className="u-display-block">
                            {utils.getUserFullName(option)}
                        </strong>
                        <span className="u-muted">{option.socialSecurityNumber}</span>
                        {this.props.children}
                    </span>
                </div>
            </div>
        );
    }
}

UserOption.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
    onFocus: PropTypes.func,
    onSelect: PropTypes.func,
    option: PropTypes.object.isRequired,
};

UserOption.defaultProps = {
    className: PropTypes.string,
    isDisabled: false,
    isFocused: false,
    isSelected: false,
    onFocus() {},
    onSelect() {},
};

export default UserSelect;
