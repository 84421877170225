import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PartyEmailForm from './PartyEmailForm';
import userRoles from 'shared/constants/userRoles';
import TransList from 'shared/components/Translator/TransList';
import OutlineLink from 'shared/components/OutlineLink';
import { partiesActions, partiesSelectors } from 'shared/Parties/parties';
import contractSelectors from 'Contract/contractSelectors';
import { routeKeys } from 'Contract/constants/routes';
import { YesNo } from 'shared/components';

/**
 * Kyselee onko työntekijällä Oima-tili. Jos ei ole siirrytään lisäämään kaikki tiedot.
 */
@connect((state) => {
    // Päätetään tämän perusteella haetaanko perhehoitajia
    const isAssignmentContract = contractSelectors.isAssignmentContract(state);
    return ({
        routes: state.routes,
        userRole: partiesSelectors.getUserRole(state),
        previousParties: partiesSelectors.getPreviousParties(state, isAssignmentContract ? 'previousCaredParties' : 'previousParties'),
        hasPartyStAccount: partiesSelectors.partyHasStAccount(state),
    });
})
export default class NewPartyTypeSelector extends Component {
    static propTypes = {
        userRole: PropTypes.number,
        hasPartyStAccount: PropTypes.bool,
        dispatch: PropTypes.func,
        routes: PropTypes.object,
    };

    static defaultProps = {
        userRole: -1,
        hasPartyStAccount: false,
        dispatch() {},
        routes: {},
    };

    render() {
        const { userRole, hasPartyStAccount, dispatch } = this.props;

        // Käännökset roolin mukaan
        let partyId = _trans('contract.employee.genitive');

        if (userRole === userRoles.EMPLOYEE) {
            partyId = _trans('contract.employer.genitive');
        }

        return (
            <Fragment>
                {_trans('contract.form.parties.employee.does_your_employee_have_st_account')}

                <div className="u-margin-top-small u-margin-bottom">
                    <YesNo
                        value={hasPartyStAccount}
                        onChange={(hasStAccount) => dispatch(partiesActions.partySetHasAccount(hasStAccount))}
                    />
                </div>

                { hasPartyStAccount === true && <PartyEmailForm /> }

                { hasPartyStAccount === false &&
                <div>
                    <strong>{_trans('contract.st_account_benefits')}</strong><br/>
                    <ul>
                        <TransList id="contract.st_account_benefits_list" />
                    </ul>
                    <OutlineLink to={this.props.routes.get(routeKeys.NEW_EMPLOYEE)} mdIcon="person_add" modifierClass="u-1/1 u-text-center">
                        {_trans('contract.form.parties.party.button.add_new_party_data', { party: partyId })}
                    </OutlineLink>
                </div>
                }
            </Fragment>
        );
    }
}
