import React, { Fragment } from 'react';
import { Field } from 'formik';
import { StField, StLabel, StHelp } from 'shared/components/index';
import { FormikErrors } from 'shared/components/Formik/index';

const EmployeeEmail = () => (
    <Fragment>
        <StLabel htmlFor="email">
            {_trans('userForm.employeeEmail.label')}
        </StLabel>
        <StField>
            <Field type="text" name="email" id="email" className="u-1/1" aria-describedby="emailHelp" />
            <StHelp id="emailHelp">
                {_trans('userForm.employeeEmail.help')}
            </StHelp>
            <FormikErrors name="email" />
        </StField>
    </Fragment>
);

export default EmployeeEmail;
