import React, { Fragment } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useDimensions from 'react-cool-dimensions';
import { ResizeObserver } from '@juggle/resize-observer';
//import BottomNavigation from 'shared/components/BottomNavigation';
import { select } from '@rematch/select';
import { useSelector, useDispatch } from 'react-redux';
import UserMenu from './UserMenu';
import { NotificationHub } from './NotificationHub';
import { RoleSelectorDialog } from './RoleSelectorDialog';
import { SurchargeMenuBarItem } from './SurchargeMenuBarItem';
import MDIcon from 'shared/components/MDIcon';
import MenuBar from 'shared/components/MenuBar';
import { MenuBarItem } from 'shared/components/MenuBarItem';
import HamburgerMenu from 'shared/components/HamburgerMenu';
import menuBarItem from 'shared/shapes/menuBarItem';
import Badge from 'shared/components/Badge';
import { eventKeyCodes } from 'shared/constants/eventKeys';
import { mainBreakPoints } from 'shared/containers/PageHeader/constants';
import { MenuBarButton } from 'shared/components/MenuBarButton';
import IconText from 'shared/components/IconText';
import userRoles from 'shared/constants/userRoles';
import { PageHeaderLogo } from 'shared/containers/PageHeader/components/PageHeaderLogo';
import { IgnoreVisibilityDimension } from 'shared/containers/PageHeader/containers/IgnoreVisibilityDimension';
import { todoTypes } from 'shared/constants/todoTypes';
//import menuBar from 'MenuBar/menuBar';

const getSliceAmount = (breakPoints, containerWidth) => {
    let sliceAmount = 0;
    _.map(breakPoints, (itemCount, breakPointWidth) => {
        if (containerWidth <= parseInt(breakPointWidth, 10) && itemCount > sliceAmount) {
            sliceAmount = itemCount;
        }
    });
    return sliceAmount;
};

const Header = ({ navigationItems, breakPoints, canSwitchRole, /*tabBreakPoints,*/ }) => {
    const { ref: headerRef, width: containerWidth, currentBreakpoint } = useDimensions({
        breakpoints: { large: 1280 },
        polyfill: ResizeObserver,
    });

    const dispatch = useDispatch();
    const userRole = useSelector(select.header.getUserRole);
    const isCommuneAdmin = useSelector(select.header.isCommuneAdmin);
    const isPreviousAdmin = useSelector(select.header.isPreviousAdmin);
    const isCommune = userRole === userRoles.COMMUNE_OPERATOR;
    const currentRoute = useSelector(select.header.getCurrentRoute);
    // Koska home on jokaisen route alussa pitää tarkistaa näin pöljästi
    const selectedIndex = navigationItems.findIndex((item, key) => key > 0
        ? item.href.startsWith(currentRoute)
        : item.href === currentRoute);

    // Onko roolinvaihto käynnissä
    const isSwitching = useSelector(select.roleSelector.isSwitching);

    const baseHeading = useSelector(select.header.getBaseHeading);
    const isNotificationVisible = useSelector(select.header.isNotificationVisible);
    const hasSelectedRole = useSelector(select.header.hasSelectedRole);
    const isOverlayVisible = useSelector(select.header.isOverlayVisible);
    const notifications = useSelector(select.notificationHub.getAll);

    const hasNewMessages = notifications.some((notification) => [todoTypes.TYPE_OWN_INSTANT_MESSAGES, todoTypes.TYPE_WELLBEING_AREA_INSTANT_MESSAGES].includes(notification.type));
    const notificationCount = notifications.reduce((acc, cur) => acc + cur?.count, 0);

    const hasNavigationItems = navigationItems.length > 0;
    const sliceAmount = getSliceAmount(breakPoints, containerWidth);
    const menuBarItems = navigationItems.slice(0, navigationItems.length - sliceAmount);
    const menuItems = navigationItems.slice(-sliceAmount);
    const hasMenuItems = sliceAmount > 0;

    /*const tabSliceAmount = getSliceAmount(tabBreakPoints, containerWidth);
    const tabItems = navigationItems.slice(0, navigationItems.length - tabSliceAmount);
    const moreItems = tabItems.slice(tabSliceAmount, navigationItems.length);*/

    const isMediumSize = containerWidth >= mainBreakPoints.MEDIUM;
    const isLargeSize = currentBreakpoint === 'large';
    const isMenuBarVisible = menuBarItems.length > 0 && isMediumSize;

    // Näytetäänkö roolinvaihtodialogi
    const isRoleSelectorVisible = useSelector(select.header.isRoleSelectorVisible);

    const headerClass = classNames('c-header o-stack o-pin o-pin--fixed o-pin--top o-pin--left u-1/1 u-z-index-10', {
        'c-header--large': isMenuBarVisible
    });

    return (
        <Fragment>
            <header
                className={headerClass}
                role="banner"
                ref={headerRef}
            >
                {(! isMenuBarVisible && hasNavigationItems) && (
                    <Fragment>
                        <HamburgerMenu items={navigationItems} />
                        <h1 className="c-header__heading u-text-center u-pointer-events-none u-text-truncate">{baseHeading}</h1>
                    </Fragment>
                )}

                {isMenuBarVisible && <PageHeaderLogo isCommune={isCommune} />}
                { (isMenuBarVisible && hasNavigationItems) && (
                    <nav className="u-position-relative u-text-no-wrap">
                        <MenuBar aria-label={_trans('menubar.label')}>
                            {menuBarItems.map((menuBarItem, key) => {
                                const badge = menuBarItem.badge;
                                const href = menuBarItem.href ?? '';
                                const isSelected = key === selectedIndex;

                                if (menuBarItem.items) {
                                    return (
                                        <MenuBarButton
                                            id={`menuBarItem${key}`}
                                            isSelected={isSelected}
                                            key={key}
                                        >
                                            {menuBarItem.label}
                                            { badge && <Badge value={badge} overlap animate /> }
                                        </MenuBarButton>
                                    );
                                }

                                return (
                                    <MenuBarItem
                                        id={menuBarItem?.id}
                                        href={href}
                                        isSelected={isSelected}
                                        key={key}
                                    >
                                        {menuBarItem.label}
                                        { badge && <Badge value={badge} overlap animate /> }
                                    </MenuBarItem>
                                );
                            })}
                            { hasMenuItems && (
                                <MenuBarButton id="moreMenuBar" items={menuItems} hasArrow={false}>
                                    <MDIcon icon="more_horiz" />
                                </MenuBarButton>
                            )}
                        </MenuBar>
                    </nav>
                )}

                <MenuBar style={{ marginLeft: 'auto' }}>
                    {(
                        (isPreviousAdmin || isCommuneAdmin)
                        && hasSelectedRole
                        && [userRoles.COMMUNE_OPERATOR, userRoles.COMPANY_OPERATOR].includes(userRole)
                    ) && <SurchargeMenuBarItem />}
                    {isPreviousAdmin && <IgnoreVisibilityDimension/>}
                    {window.FreshworksWidget && (
                        <MenuBarItem onClick={() => window.FreshworksWidget('open')} hasPadding={isMediumSize} aria-label={_trans('menubar.help.tooltip')}>
                            <IconText
                                mdIcon="help_outline"
                                text={isLargeSize && _trans('Ohjeet', {}, 'common')}
                                iconSize="medium"
                            />
                        </MenuBarItem>
                    )}
                    {/*Ei näytetän roolinvaihtoikonia, jos ei ole valittua roolia koska ollaan juuri valitsemassa roolia :O*/}
                    {(isMenuBarVisible && hasSelectedRole && canSwitchRole) && (
                        <MenuBarItem
                            onClick={() => {
                                dispatch.header.setIsRoleSelectorVisible(! isRoleSelectorVisible);
                            }}
                            hasPadding={isMenuBarVisible}
                            disabled={isSwitching}
                            aria-label={_trans('user_menu.change_role')}
                        >
                            <IconText
                                iconSize="medium"
                                mdIcon={ isSwitching ? 'refresh' : 'swap_horiz' }
                                iconModifierClass={ isSwitching ? 'refresh u-animation-rotate u-padding-right-none' : 'swap_horiz' }
                                textModifierClass={ isSwitching ? 'u-padding-left-tiny' : ''}
                                text={isLargeSize && _trans('user_menu.change_role')}
                            />
                        </MenuBarItem>
                    )}
                    { (isMenuBarVisible && isNotificationVisible && hasSelectedRole) && (
                        <MenuBarButton
                            id="notifications"
                            hasPadding
                            menuContent={<div style={{ width: '320px' }}><NotificationHub /></div>}
                            hasArrow={false}
                            aria-label={`${_trans('notification_hub.tooltip')}. ${_transChoice('notification_hub.count', notifications.length, { count: notifications.length })}.`}
                        >
                            <MDIcon
                                icon="notifications"
                                isFilled={hasNewMessages}
                                modifierClass={classNames({
                                    'u-color-primary': hasNewMessages,
                                    'u-animation-shake': hasNewMessages,
                                })}
                            />
                            { notificationCount > 0 && <Badge type="primary" value={notificationCount > 9 ? '+9' : notificationCount} overlap animate /> }
                        </MenuBarButton>
                    )}

                    <UserMenu
                        isNameHidden={!isMenuBarVisible}
                        isBigMenu={!isMenuBarVisible}
                        canSwitchRole={canSwitchRole}
                    />
                </MenuBar>
            </header>
            {/*{! isMenuBarVisible && (
                <BottomNavigation
                    items={tabItems}
                    moreItems={moreItems}
                />
            )}*/}
            { (isOverlayVisible && !isMenuBarVisible) && (
                // TODO: outside click
                // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                <div
                    role="button"
                    className="o-overlay o-overlay--dark o-pin o-pin--corners u-z-index-9"
                    onKeyDown={(event) => {
                        if (event.keyCode === eventKeyCodes.ESC) {
                            dispatch.header.closeAllMenus();
                        }
                    }}
                    onClick={dispatch.header.closeAllMenus}
                />
            )}
            { isRoleSelectorVisible && <RoleSelectorDialog /> }
        </Fragment>
    );
};

Header.propTypes = {
    navigationItems: PropTypes.arrayOf(menuBarItem).isRequired,

    /**
     * Yläosan navigaation breakpointit => miten valikkoitemeitä piilotetaan Lisää-napin alle missäkin breakpointissa.
     * 445: 6 // Leveys 445px, piilota 6
     * 580: 3 // Leveys 580px, piilota 3
     * 710: 2 // ...jne.
     */
    breakPoints: PropTypes.object.isRequired,

    /**
     * Alaosan tabvalikon breakpointit. Poistettu toistaiseksi.
     */
    // tabBreakPoints: PropTypes.object,

    /**
     * Voiko käyttäjä vaihtaa roolia.
     */
    canSwitchRole: PropTypes.bool.isRequired,
};

export default hot(Header);
