import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { select } from '@rematch/select';
import { useSelector } from 'react-redux';
import { useDidUpdate } from '@mantine/hooks';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { SelectField } from 'shared/components/Formik';
import { payslipDeliveryMethodTypes } from 'shared/constants/payslipDeliveryMethodTypes';
import { getPayslipDeliveryMethodOptions } from 'ContractV3/constants/getPayslipDeliveryMethods';
import validators from 'shared/validators';
import { usePrevious } from 'shared/hooks/previous';

/**
 * Palkkalaskelman toimitustapa.
 * @param name
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const PayslipDeliveryMethod = ({ name }) => {
    const isNewContract = useSelector(select.contract.isNewContract);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const [field,,helpers] = useField(name);
    const [employeeField] = useField(fieldNames.EMPLOYEE);

    const employees = useSelector((state) => isNewContract
        ? select.employees.getEmployeesByIds(state, employeeField.value)
        : select.contract.getEmployees(state));
    const isEPayslipEnabled = useSelector(select.userMetadata.isMaventaEPayslipEnabled);

    const options = useMemo(() => (
        getPayslipDeliveryMethodOptions({ isCommune, isEPayslipEnabled })
    ), [isCommune, isEPayslipEnabled]);

    const isCompensationEarnerContract = useSelector(select.contract.isCompensationEarnerContract);
    const isAssigmentContract = useSelector(select.contract.isAssignmentContract);

    // Kaikilla työntekijöillä validi sähköposti
    const hasValidEmails = employees.every((employee) => {
        const employeeEmail = employee?.email;
        return validators.isRequired(employeeEmail) && validators.isEmail(employeeEmail);
    });
    // Kaikilla työntekijöillä validi osoite
    const hasValidAddresses = employees.every((employee) => (employee?.fullAddress ?? '').toString().trim() !== '');
    const payslipDeliveryMethod = parseInt(field.value, 10);
    const selectedOption = options.find((option) => option.value === payslipDeliveryMethod);

    // Valittuna jokin sähköpostitoimitustapa
    const isEmailDelivery = [
        payslipDeliveryMethodTypes.PAYSLIP_DELIVERY_METHOD_E_MAIL,
        payslipDeliveryMethodTypes.PAYSLIP_DELIVERY_METHOD_ENCRYPTED_E_MAIL,
        payslipDeliveryMethodTypes.PAYSLIP_DELIVERY_METHOD_EMAIL_NOTIFICATION,
    ].includes(payslipDeliveryMethod);

    // Etanamaili
    const isSnailMailDelivery = payslipDeliveryMethod === payslipDeliveryMethodTypes.PAYSLIP_DELIVERY_METHOD_S_MAIL;

    const previousEmployees = usePrevious(employees);
    // Pakotetaan sähköpostiosoitteen tarkitus kun työntekijä valitaan
    useDidUpdate(() => {
        if (field.value.length > 0 && employees.length > previousEmployees.length) {
            helpers.setTouched(true);
        }
    }, [field.value, employees, previousEmployees]);

    return (
        <Fragment>
            <SelectField
                name={name}
                aria-describedby={(isEmailDelivery && hasValidEmails) ? 'deliveryInfo' : null}
                validate={() => {
                    // Sähköposti toimitustapana mutta ei sähköpostia
                    if (isEmailDelivery && ! hasValidEmails) {
                        if (isCompensationEarnerContract) {
                            return _trans('Palkkionsaajalta puuttuu sähköpostiosoite.', {}, 'jobContract');
                        }

                        if (isAssigmentContract) {
                            return _trans('Hoitajalta puuttuu sähköpostiosoite.', {}, 'jobContract');
                        }

                        return _trans('Työntekijältä puuttuu sähköpostiosoite.', {}, 'jobContract');
                    }

                    // Jos postitse toimitettava mutta ei osoitetta
                    if (isSnailMailDelivery && ! hasValidAddresses) {
                        if (isCompensationEarnerContract) {
                            return _trans('Palkkionsaajalta puuttuu pakollisia osoitetietoja.', {}, 'jobContract');
                        }

                        if (isAssigmentContract) {
                            return _trans('Hoitajalta puuttuu pakollisia osoitetietoja.', {}, 'jobContract');
                        }

                        return _trans('Työntekijältä puuttuu pakollisia osoitetietoja.', {}, 'jobContract');
                    }

                    // Ei valittua toimitustapaa
                    if (! field.value) {
                        return _trans('Kenttä on pakollinen.', {}, 'common');
                    }
                }}
                placeholder={_trans('dropdown.choose')}
                options={options}
            />
            {(selectedOption?.description && ! isEmailDelivery) && (
                <div className="o-form__text">
                    {_trans(selectedOption?.description,)}
                </div>
            )}
            {(selectedOption?.description && (isEmailDelivery && hasValidEmails)) && (
                <div id="deliveryInfo" className="o-form__text">
                    {employees.map((employee, index) => (
                        <div key={index}>
                            {_transMd(
                                selectedOption?.description,
                                { email: employee.email },
                                { useParagraphs: true },
                                // isAssignmentContract ? 'assignment_contract' : 'contract'
                            )}
                        </div>
                    ))}
                </div>
            )}
        </Fragment>
    );
};

PayslipDeliveryMethod.propTypes = {
    name: PropTypes.string.isRequired,
};

export default PayslipDeliveryMethod;
