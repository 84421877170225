import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

/**
 * Yläpalkin navigaatio.
 */
export default class MenuBarOld extends Component {
    static propTypes = {
        menuItems: PropTypes.array.isRequired,
        modifierClass: PropTypes.string,
    };

    static defaultProps = {
        modifierClass: '',
    };

    renderIcon(menuItem) {
        const icon = _.get(menuItem, 'icon', null);

        if (icon !== null) {
            return (
                <div className="c-menu__item-icon u-margin-bottom-tiny">
                    <i className="material-icons">{icon}</i>
                </div>
            );
        }
    }

    render() {
        const {
            menuItems,
            modifierClass,
        } = this.props;

        return (
            <nav className={classNames('c-menu-bar', modifierClass)} id="nav-menu">
                <ul className="c-menu" role="menubar" aria-label="Päävalikko">
                    {_.map(menuItems, (menuItem) => (
                        <li
                            key={menuItem.route}
                            role="presentation"
                        >
                            <NavLink
                                to={menuItem.route}
                                activeClassName="is-active"
                                role="menuitem"
                                className="c-menu__item"
                            >
                                {this.renderIcon(menuItem)}
                                <span className="c-menu__item-title">{menuItem.title}</span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        );
    }
}
