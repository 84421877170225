import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import _ from 'lodash';
import { IconText, InputGroup } from 'shared/components/index';
import { CheckboxField, FormField, MaxTextAreaField, SelectField, TextField } from 'shared/components/Formik/index';
import { editType, translateEditTypeDescription } from 'shared/constants/workExperienceTypes';
import Feedback from 'shared/components/Feedback';
import InputPostfix from 'shared/components/InputPostfix';

const EmployeeWorkExperience = ({ isReadOnly, jobTitles, jobContractId, selectedEditType, selectedJobTitle, selectableCollectiveAgreements }) => {
    const contractHelpTooltip = jobContractId
        ? _trans('users.employee_details.contract_tooltip')
        : _trans('users.employee_details.tooltip');

    const latestHistory = jobContractId
        ? _.last(_.get(_.head(jobTitles), 'history', []))
        : _.last(_.get(_.head(_.filter(jobTitles, ['id', selectedJobTitle])), 'history', []));

    const allowsWorkExperienceReduction = _.includes([editType.TOTAL, editType.SUB_DELTA], selectedEditType);
    const collectiveAgreementOptions = selectableCollectiveAgreements
        .map((row) => ({
            label: row.name,
            value: row.id,
        }));

    return (
        <div>
            {! jobContractId && (
                <FormField name="jobTitle" label={_trans('users.section.work_experience.job_title')}>
                    <SelectField
                        name="jobTitle"
                        options={jobTitles}
                        placeholder={_trans('users.employee_details.select_job_title')}
                    />
                </FormField>
            )}
            {collectiveAgreementOptions.length > 0 && (
                <FormField
                    name="targetCollectiveAgreementId"
                    label={_trans('users.section.work_experience.target_collective_agreement')}
                    helpText={_trans('users.section.work_experience.target_collective_agreement_help')}
                >
                    <SelectField
                        name="targetCollectiveAgreementId"
                        options={collectiveAgreementOptions}
                        placeholder={_trans('users.section.work_experience.select_target_collective_agreement')}
                    />
                </FormField>
            )}
            <FormField
                name="amount"
                label={_trans('users.employee_details.work_experience_commune')}
                helpText={selectedEditType === editType.NEW
                    ? contractHelpTooltip
                    : translateEditTypeDescription(selectedEditType)}
            >
                <InputGroup>
                    <TextField name="amount" readOnly={isReadOnly} size={6} />
                    <InputPostfix postfix={_trans('unit.month')} />
                    { (selectedEditType !== editType.NEW && ! _.isEmpty(latestHistory)) && (
                        <Field
                            type="text"
                            readOnly
                            name="oldAmount"
                            size={6}
                            as={() => (
                                <IconText
                                    modifierClass="u-margin-left-tiny"
                                    iconSize="medium"
                                    mdIcon="history"
                                    text={_.get(latestHistory, 'workExperienceMonths') + ' kk'}
                                    title={_trans('users.employee_details.tooltip')}
                                />
                            )}
                        />
                    )}
                </InputGroup>
            </FormField>
            <FormField name="description" label={_trans('users.section.work_experience.form.description')}>
                <MaxTextAreaField maxLength={200} />
            </FormField>
            <FormField name="disableNewSalaryGeneration" label={_trans('users.section.work_experience.form.salaries')}>
                <CheckboxField label={_trans('users.section.work_experience.form.disable_new_salary_generation')} />
            </FormField>
            {allowsWorkExperienceReduction && (
                <Feedback type="info" modifierClass="u-padding-small u-margin-bottom">
                    {_trans('users.section.work_experience.work_exp_reduction_info')}
                </Feedback>
            )}
        </div>
    );
};

EmployeeWorkExperience.propTypes = {
    isReadOnly: PropTypes.bool,
    jobTitles: PropTypes.array,
    jobContractId: PropTypes.number,
    selectedEditType: PropTypes.number,
    selectedJobTitle: PropTypes.number,
    selectableCollectiveAgreements: PropTypes.array,
};

EmployeeWorkExperience.defaultProps = {
    isReadOnly: false,
    jobTitles: [],
    jobContractId: null,
    selectedEditType: null,
    selectedJobTitle: null,
    selectableCollectiveAgreements: [],
};

export default EmployeeWorkExperience;
