import PropTypes from 'prop-types';

export default PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    href: PropTypes.string,
    label: PropTypes.string.isRequired,

    /**
     * Alanäkymä. Esim. ilmoituksille.
     */
    subView: PropTypes.node,
});
