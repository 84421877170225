import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Value } from 'shared/components/Value';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';

/**
 * Työsuhteen irtisanominen. Vain HA.
 * @returns {null}
 * @constructor
 */
const NoticePeriodDisclaimerText = (props) => {
    const { label } = props;
    const noticeText = useSelector(select.contract.getNoticePeriodDisclaimerText);

    return (
        <SummaryListItem term={label}>
            <Value>
                {_trans(noticeText, {}, 'messages')}
            </Value>
        </SummaryListItem>
    );
};

NoticePeriodDisclaimerText.propTypes = {
    label: PropTypes.string.isRequired,
};

export default NoticePeriodDisclaimerText;
