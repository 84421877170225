import React from 'react';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Badge } from 'shared/components';

/**
 * Ulosoton tila. Voi olla vapaakuukaudellakin.
 * @returns {JSX.Element}
 * @constructor
 */
export const ForeclosureStatus = () => {
    const isOnFreeMonth = useSelector(select.foreclosure.isOnFreeMonth);
    return (
        <span>
            <Badge
                title={_trans('foreclosure.status.title')}
                type={isOnFreeMonth
                    ? 'yellow'
                    : 'positive'
                }
                size="large"
                modifierClass="u-align-middle"
                value={_trans(isOnFreeMonth
                    ? 'foreclosure.status.on_free_month'
                    : 'foreclosure.status.active')}
            />
        </span>
    );
};
