import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from 'shared/components/Tooltip';

/**
 * Yläpalkin navigaatioelementti. Joko linkki tai nappi.
 */
export const MenuBarItem = ({ children, href, hasPadding, isSelected, tooltip, ...rest }) => {
    const props = {
        href,
        className: classNames('c-menu-bar__item', {
            'u-padding-horizontal-small': hasPadding,
            'c-menu-bar__item--selected': isSelected,
        }),
        role: 'menuitem',
        ...rest,
    };

    const isButton = !href || typeof rest.onClick === 'function';
    const content = React.createElement(isButton ? 'button' : 'a', props, children);

    return (
        <li
            role="none"
            className="u-position-relative"
        >
            {tooltip
                ? (
                    <Tooltip title={tooltip}>
                        {content}
                    </Tooltip>
                )
                : content
            }
        </li>
    );
};

MenuBarItem.displayName = 'MenuBarItem';

MenuBarItem.propTypes = {
    /**
     * Linkin / napin sisältö
     */
    children: PropTypes.node.isRequired,

    /**
     * Linkin osoite (ellei sisällä alasvetovalikkoa).
     */
    href: PropTypes.string,

    /**
     * Onko paddingia.
     */
    hasPadding: PropTypes.bool,

    isSelected: PropTypes.bool,
    tooltip: PropTypes.string,
};

MenuBarItem.defaultProps = {
    href: null,
    hasPadding: true,
    isSelected: false,
    tooltip: '',
};
