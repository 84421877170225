import React, { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ExperienceForm from 'shared/UserDetails/components/ExperienceForm';
import EditTypeSelect from 'shared/UserDetails/containers/shared/EmployeeWorkExperience/EditTypeSelect';
import { ActionBar, GoBackLink, SubmitButton } from 'shared/components';
import { totalTypes, updateTypes, changeType, editType } from 'shared/constants/workExperienceTypes';
import EmployeeWorkExperience from 'shared/components/Formik/EmployeeWorkExperience';
import { salaryActions } from 'Contract/stores/salary';
import { groupDropdownOptions } from 'shared/utils/groupDropdownOptions';
import { Form } from 'shared/components/Formik';
import contractSelectors from 'Contract/contractSelectors';
import { uniqueBy } from 'shared/utils/collectionHelpers';

const BaseForm = ({ userId, jobContractId, selectableCollectiveAgreements }) => {
    const dispatch = useDispatch();
    const isAddForm = useSelector(select.employeeWorkExperience.isAddForm);
    const isEditForm = useSelector(select.employeeWorkExperience.isEditForm);
    const selectedEditType = useSelector(select.employeeWorkExperience.getEditType);
    const employeeWorkExperience = useSelector(select.employeeWorkExperience.getEmployeeWorkExperience);
    const jobTitles = useSelector(select.jobTitles.getJobTitles);
    const isPosting = useSelector((state) => state.loading.effects.employeeWorkExperience.postEmployeeWorkExperience);
    const contractJobTitleId = useSelector(select.contract
        ? select.contract.getJobTitleId
        : contractSelectors.getJobTitle
    );

    useEffect(() => {
        if (jobContractId) {
            dispatch.employeeWorkExperience.fetchEmployeeWorkExperienceByContract(userId, jobContractId);
        } else {
            dispatch.employeeWorkExperience.fetchEmployeeWorkExperience(userId);
            dispatch.jobTitles.fetchJobTitles();
        }
    }, [userId, jobContractId]);


    const resolvedJobTitles = useMemo(() => {
        if (jobContractId) return employeeWorkExperience.filter((workExperience) => workExperience.id === contractJobTitleId);

        // Jos ollaan lisämäässä uutta kokemuskuukautta, niin poistetaan jobTitleistä ne työnimikkeet
        // joille on merkattu jo kokemuskuukausia
        const employeeWorkExperienceIds = employeeWorkExperience.map((employeeWorkExperience) => employeeWorkExperience.id);

        const workExperiencesByJobTitle = uniqueBy(
            employeeWorkExperience,
            (group) => group.id, // Jobtiitelin id
        );

        const employeeWorkExperiences = workExperiencesByJobTitle.map((exp) => ({
            history: exp.history ?? [],
            value: exp.id?.toString(),
            label: exp.subtitle,
            name: exp.name,
        }));

        return updateTypes.includes(selectedEditType)
            ? employeeWorkExperiences
            : jobTitles.filter((jobTitle) => ! employeeWorkExperienceIds.includes(jobTitle.value));
    }, [selectedEditType, jobContractId, contractJobTitleId, employeeWorkExperience, jobTitles]);

    const renderStepContainer = (selectedJobTitle) => {
        if (isAddForm) {
            return (
                <EmployeeWorkExperience
                    jobTitles={resolvedJobTitles}
                    selectedEditType={selectedEditType}
                    jobContractId={jobContractId}
                    selectableCollectiveAgreements={selectableCollectiveAgreements}
                />
            );
        }

        return (
            <Fragment>
                <EditTypeSelect jobContractId={jobContractId}/>
                <ExperienceForm
                    jobTitles={groupDropdownOptions(resolvedJobTitles)}
                    selectedEditType={selectedEditType}
                    jobContractId={jobContractId}
                    selectedJobTitle={_.toInteger(selectedJobTitle)}
                    isEditForm={isEditForm}
                    selectableCollectiveAgreements={selectableCollectiveAgreements}
                />
            </Fragment>
        );
    };

    const latestHistory = _.last(_.get(_.head(employeeWorkExperience), 'history', {}));
    const latestWorkExperienceMonth = _.get(latestHistory, 'workExperienceMonths', '');

    return (
        <div>
            <Formik
                initialValues={{
                    oldAmount: latestWorkExperienceMonth + ' kk',
                    jobTitle: resolvedJobTitles.length === 1 ? resolvedJobTitles[0]?.id ?? '' : '',
                }}
                onSubmit={(model) => {
                    const data = {
                        targetCollectiveAgreementId: model.targetCollectiveAgreementId ?? null,
                        amount: selectedEditType === editType.SUB_DELTA ? -model.amount : model.amount,
                        changeType: _.includes(totalTypes, selectedEditType) ? changeType.TOTAL : changeType.DELTA,
                        jobTitleId: jobContractId ? contractJobTitleId : model.jobTitle,
                        description: model.description,
                        disableNewSalaryGeneration: !! _.get(model, 'disableNewSalaryGeneration', false),
                    };

                    dispatch.employeeWorkExperience.postEmployeeWorkExperience(data, userId)
                        .then(() => {
                            // Jos sopparilla, niin haetaan mahdolliset kokemuslisän vahvistukset.
                            // Esim. jos editoidaan kuukaudet 60 niin näytetään samantien, että odottavia toimintoja.
                            if (jobContractId) {
                                dispatch(salaryActions.fetchSalaryUpdates(jobContractId));
                            }
                        });
                }}
                validationSchema={Yup.object().shape({
                    amount: Yup.number()
                        .typeError(_trans('users.section.work_experience.form.errors.numeric_experience'))
                        .positive(_trans('validation.positive_number'))
                        .required(_trans('users.section.work_experience.form.errors.experience_required')),
                    ...(selectedEditType === editType.SUB_DELTA && {
                        amount: Yup.number()
                            .max(latestWorkExperienceMonth, _trans('users.section.work_experience.form.errors.experience_max'))
                            .typeError(_trans('users.section.work_experience.form.errors.numeric_experience'))
                            .positive(_trans('validation.positive_number'))
                            .required(_trans('users.section.work_experience.form.errors.experience_required')),
                    }),
                    jobTitle: ! jobContractId
                        && Yup.string().required(_trans('users.section.work_experience.form.errors.job_title_required'))
                })}
                enableReinitialize
            >
                {({ values }) => (
                    <Form>
                        {renderStepContainer(_.get(values, 'jobTitle', ''))}
                        <ActionBar>
                            <GoBackLink
                                text={_trans('link.return_without_saving')}
                                href="#"
                                onClick={() => dispatch.employeeWorkExperience.resetForm()}
                            />
                            {selectedEditType && (
                                <SubmitButton isPending={isPosting}>
                                    {_trans('button.save')}
                                </SubmitButton>
                            )}
                        </ActionBar>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

BaseForm.propTypes = {
    userId: PropTypes.number.isRequired,
    isTypeSelected: PropTypes.bool,
    jobContractId: PropTypes.number,
    selectableCollectiveAgreements: PropTypes.array,
};

BaseForm.defaultProps = {
    isTypeSelected: false,
    jobContractId: null,
    selectableCollectiveAgreements: [],
};

export default BaseForm;
