import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { hasFormikErrors } from 'shared/utils/formUtils';
import DateRangeInput from 'shared/components/DateRangeInput';

/**
 * Yksittäinen päivämääräkenttä.
 * @param name
 * @param onChange
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const DateRangeInputField = ({ name, onChange, ...rest }) => {
    const [field, meta, helpers] = useField(name);

    return (
        <DateRangeInput
            {...rest}
            {...field}
            // Jos kentällä on virheitä kerrotaan myös ruudunlukijoille
            hasErrors={hasFormikErrors(meta)}
            onBlur={(value) => {
                helpers.setTouched(true);
                helpers.setValue(value);
            }}
            onChange={(value) => {
                helpers.setTouched(true);
                helpers.setValue(value);
            }}
        />
    );
};

DateRangeInputField.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
};

DateRangeInputField.defaultProps = {
    name: '',
    onChange() {},
};
