// Liikaa hommaa korjata tämän eslint-virheet. Tästä pitäs tehdä paranneltu versio...
/* eslint-disable react/boolean-prop-naming */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import { componentSizes } from 'shared/constants';
import MDIcon from 'shared/components/MDIcon';
import Spinner from 'shared/components/Spinner';

const buttonTypes = {
    NORMAL: 'button',
    RESET: 'reset',
    SUBMIT: 'submit'
};

const Button = React.forwardRef(({
    id,
    title,
    width,
    size,
    type,

    children,

    primary,
    positive,
    negative,
    outline,
    flat,
    ghost,
    admin,

    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,

    onClick,
    preventDefault,

    mdIcon,
    iconSize,
    iconAfterText,
    isIconFilled,
    disabled,
    active,

    style,
    modifierClass,
    inProgress,
    hasPadding,

    href = '',
    target = '',

    isFocusable = true,
}, ref) => {
    const childrenCount = React.Children.toArray(children).length;

    const buttonClass = classNames('c-button u-text-truncate', {
        'c-button--primary': primary,
        'c-button--positive': positive,
        'c-button--negative': negative,
        'c-button--outline': outline,
        'c-button--flat': flat,
        'c-button--flat c-button--ghost': ghost,
        'c-button--admin-only': admin,

        'c-button--tiny': size === componentSizes.TINY,
        'c-button--small': size === componentSizes.SMALL,
        'c-button--medium': size === componentSizes.MEDIUM,
        'c-button--large': size === componentSizes.LARGE,
        'c-button--huge': size === componentSizes.HUGE,

        'c-button--icon-before-text': childrenCount > 1 && iconAfterText === false,
        'c-button--icon-after-text': childrenCount > 1 && iconAfterText === true,

        'u-padding-none': childrenCount === 0 && !hasPadding,
        'u-padding-horizontal-none': flat,

        'is-active': active,

        ['u-' + width]: width !== '',

        [modifierClass]: modifierClass !== '',
    });

    const spinnerClass = classNames({
        'u-margin-left-tiny': !inProgress || childrenCount > 0,
    });

    let mdIconContainer = null;

    if (mdIcon != null) {
        mdIconContainer = (
            <MDIcon
                icon={mdIcon}
                isFilled={isIconFilled}
                size={iconSize}
                key="buttonIcon"
                modifierClass={classNames({
                    'u-margin-right-tiny': childrenCount >= 1 && iconAfterText === false,
                    'u-margin-left-tiny': childrenCount >= 1 && iconAfterText === true,
                })}
            />
        );
    }

    const onButtonClick = (event) => {
        if (preventDefault === true) {
            event.preventDefault();
            event.stopPropagation();
        }
        onClick(event);
    };

    const elementProps = {
        id,
        className: buttonClass,
        style,
        onClick: onButtonClick,
        title,
        disabled: disabled || inProgress,
        tabIndex: isFocusable ? 0 : -1,
        ref,
    };
    let elementType = 'button';

    if (href !== '' && !disabled) {
        elementType = 'a';
        elementProps.href = href;
        elementProps.target = target;
        if (target === '_blank') {
            // Jos targetti uus tabi, lisäillään rel attribuutit
            elementProps.rel = 'noopener noreferrer';
        }
        elementProps.className = classNames(buttonClass, 'u-align-middle');
    } else {
        // Tyyppiä tarvitaan vain napeille
        elementProps.type = type;
        elementProps.role = 'button';
        elementProps.className = classNames(buttonClass, 'u-align-middle');
    }

    // Aria label
    if (ariaLabel !== null) {
        elementProps['aria-label'] = ariaLabel;
    }
    if (ariaLabelledBy !== null) {
        elementProps['aria-labelledby'] = ariaLabelledBy;
    }
    if (ariaDescribedBy !== null) {
        elementProps['aria-describedby'] = ariaDescribedBy;
    }

    return React.createElement(elementType, elementProps, [
        (mdIcon && iconAfterText === false && !inProgress) && mdIconContainer,
        children,
        (mdIcon && iconAfterText === true && !inProgress) && mdIconContainer,
        inProgress
            ? (
                <span className={spinnerClass} key="buttonSpinner">
                    <Spinner size="small" primary={primary}/>
                </span>
            )
            : null,
    ]);
});

Button.displayName = 'Button';

Button.defaultProps = {
    id: null,
    title: null,
    width: '',
    size: '',
    type: buttonTypes.NORMAL,
    children: null,

    primary: false,
    positive: false,
    negative: false,
    outline: false,
    flat: false,
    ghost: false,
    admin: false,

    ariaLabel: null,
    ariaLabelledBy: null,
    ariaDescribedBy: null,

    onClick() {},
    preventDefault: false,

    mdIcon: null,
    iconSize: componentSizes.MEDIUM,
    isIconFilled: false,
    iconAfterText: false,
    disabled: false,
    active: false,

    style: {},
    modifierClass: '',
    inProgress: false,
    hasPadding: true,

    href: '',
    target: '',

    isFocusable: true,
};

Button.propTypes = {
    /**
     * Button identifier. Mostly used in conjunction with tests.
     */
    id: PropTypes.string,

    /**
     Button title. Preferably use this over the ariaLabel.
     */
    title: PropTypes.string,

    width: PropTypes.string,

    /**
     * Napin koko.
     */
    size: PropTypes.oneOf(['', ..._.map(componentSizes)]),

    /**
     * Button type: either submit for forms or button for default actions.
     */
    type: PropTypes.oneOf(_.map(buttonTypes)),

    /**
     * Content.
     */
    children: PropTypes.node,

    primary: PropTypes.bool,
    positive: PropTypes.bool,
    negative: PropTypes.bool,
    outline: PropTypes.bool,
    flat: PropTypes.bool,
    admin: PropTypes.bool,
    ghost: PropTypes.bool,

    /**
     * For screen readers in case the context is not clear or you want to add some additional info.
     */
    ariaLabel: PropTypes.string,
    /**
     * Id of describing label. Must be id or list of id's.
     */
    ariaLabelledBy: PropTypes.string,
    /**
     * Id of a longer describing element
     */
    ariaDescribedBy: PropTypes.string,

    onClick: PropTypes.func,
    preventDefault: PropTypes.bool,

    /**
     * Material Design icon name.
     */
    mdIcon: PropTypes.string,
    iconSize: PropTypes.oneOf(_.map(componentSizes)),
    isIconFilled: PropTypes.bool,
    iconAfterText: PropTypes.bool,

    /**
     * Disables any interaction with the button.
     */
    disabled: PropTypes.bool,

    /**
     * Makes the button look active (pressed) visually.
     */
    active: PropTypes.bool,

    style: PropTypes.object,
    modifierClass: PropTypes.string,
    inProgress: PropTypes.bool,

    /**
     * Does the content have padding. I.e. single icon without text might benefit from this. Possibly deprecated.
     */
    hasPadding: PropTypes.bool,

    /**
     * In rare cases when the button is a link. Should be avoided.
     */
    href: PropTypes.string,

    /**
     * In case the button is a link you can use this to determine if the link must be opened in new window / tab.
     */
    target: PropTypes.string,

    /**
     * Is the button focusable. Possibly deprecated and should not be used.
     */
    isFocusable: PropTypes.bool,
};

export default Button;
