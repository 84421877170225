import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import { AutoCompleteField } from 'shared/components/Formik';
import { useGet } from 'shared/hooks/useApi';
import Button from 'shared/components/Button';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { SelectedParty } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Parties/Party/SelectedParty';

/**
 * Valintapalikka operaattoreille, joilla samat valtuushallinta-asetukset kuin kirjautuneella käyttäjällä.
 * USE CASE: esim. perhehoitajan vastuutyöntekijä. Metatietoa.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const Operator = (props) => {
    const { name, placeholder } = props;
    const [field,,helpers] = useField(name);
    const [hasFetched, setHasFetched] = useState(false);
    // 1. Haetaan valtuushallintadimensiot.
    const visibilityDimensions = useSelector(select.dimensions.getVisibilityDimensions);
    // 2. Selvitetään mikä valituista dimensioista osuu näihin ja valitaan se "päädimensioksi" jonka
    // perusteella haetaan operaattorit.
    const [dimensionField] = useField(fieldNames.DIMENSIONS);
    const dimension = Object.entries(dimensionField?.value ?? {})
        .find(([, dimensionId]) => (
            visibilityDimensions.find((dimension) => dimension?.id?.toString() === dimensionId)
        ));
    // 3. Haluttu dimensio-id on arrayn toisena.
    // Fallback: tyhjä string => näytetään kaikki operaattorit joiden näkyvyysasetukset on sama kuin kirjautuneen
    const dimensionId = Array.isArray(dimension) && dimension.length > 0 ? dimension[1] : '';

    const isCommune = useSelector(select.userMetadata.isCommune);
    // Pääkäyttäjä tahi prokura
    const canAuthorizeUsers = useSelector(select.userMetadata.canAuthorizeUsers);
    const [{ isLoading, data }, makeRequest] = useGet(`/company/api/v1/operators/by-logged-in-user-visibility-dimensions/${dimensionId}`, { lazy: true });
    const isLoadingDimensions = useSelector((state) => state.loading.effects.dimensions.fetchDimensionTypes);

    useEffect(() => {
        // MVP: ei kuntia
        if (! isCommune) {
            makeRequest();
            setHasFetched(true);
        }
    }, [isCommune, makeRequest]);

    // Voisipa tässä olla jotain placeholderia mutta nyt näin. Mennään tähän vain ekalla kerralla jos myöhemmin
    // joudutaan päivittämään allekirjoitusoikeudellinen lista => UI ei pompi
    if (isLoading && hasFetched === false) return null;

    const operators = (data?.data ?? [])
        // Varmistetaan että vain oleelliset tiedot tulevat käyttöön (=tallentuu metadatana) eikä mitään ekstraa
        .map((operator) => ({
            userId: operator.userData?.userId ?? '-',
            initials: operator.userData?.initials ?? '-',
            fullName: operator.userData?.fullName ?? '-',
            email: operator.userData?.email ?? '-',
        }));

    const operatorId = field.value?.userId;
    const operator = operators.find((operator) => operator.userId === operatorId);

    // Valittu operaattori. Näytä tiedot ja napit vaihtoon ja allekirjoitukseen.
    if (operator?.userId && operator?.fullName && operator?.email) {
        return (
            <Fragment>
                <SelectedParty name={name} selectedParty={operator} secondaryInfo="email" onRemoveParty={() => helpers.setValue('')} />
            </Fragment>
        );
    }

    if (operators.length === 0 && ! isLoading && ! isLoadingDimensions && hasFetched) {
        return (
            <div aria-labelledby={name} className="o-form__text">
                {_trans('Henkilöitä ei löytynyt.', {}, 'jobContract')}{' '}

                {/*Ohjataan yrityksen asetuksiin vain jos sinne on oikeudet*/}
                {canAuthorizeUsers && (
                    <Fragment>
                        {_transMd('Tarkista yrityksen [Valtuutetut käyttäjät](%url%) -näkymästä haluamiesi henkilöiden valtuushallinta-asetukset.', { url: '/company/settings/users' }, {
                            targetBlank: true,
                            useParagraphs: true,
                        })}
                        {_trans('Tämän jälkeen ', {}, 'jobContract')}<Button mdIcon="refresh" size="small" iconSize="small" inProgress={isLoading} onClick={() => makeRequest()}>{_trans('päivitä lista')}</Button>
                    </Fragment>
                )}
            </div>
        );
    }

    return (
        <AutoCompleteField
            name={name}
            items={operators}
            optionHeight={42}
            labelKey="fullName"
            valueKey="userId"
            placeholder={placeholder}
            isDisabled={isLoading || isLoadingDimensions}
            hasSingleValue={false}
        />
    );
};

Operator.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    attributes: PropTypes.object,
};

Operator.defaultProps = {
    placeholder: _trans('Valitse henkilö', {}, 'common'),
    attributes: {},
};

export default Operator;
