import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LazyLoad from 'react-lazyload';
import _ from 'lodash';
import CirclePlaceholder from 'shared/components/Placeholder/CirclePlaceholder';
import Spinner from 'shared/components/Spinner';
import { sizes } from 'shared/constants.js';
import colorUtils from 'shared/utils/colorUtils';
import { ReactComponent as CompanyIcon } from 'images/roles/company.svg';
import { ReactComponent as CommuneIcon } from 'images/roles/commune.svg';
import { ReactComponent as AvatarIcon } from 'images/roles/avatar.svg';

const sizeToPixels = {
    'tiny': 24,
    'small': 36,
};

/**
 * Käyttäjän kuva. Mikäli kuvaa ei löydy, näytetään placeholder. Kuvan koko voidaan määrittää myös muuttumattomaksi.
 * @param src
 * @param size
 * @param isFixedSize
 * @param modifierClass
 * @param lazyLoadOffset - Scrollauksen offset kunnes kuvaa aletaan ladata
 * @param hasPlaceholder - Renderöidäänkö sisällön placeholder (http://www.callumhart.com/blog/non-blocking-uis-with-interface-previews)
 * @param isCompany
 * @param isCommune
 * @param fullName
 * @param initials
 * @param isLoading
 * @param isOnlineStatusVisible
 * @param isOnline
 * @returns {*}
 * @constructor
 */
function Avatar({ src, size, isFixedSize, modifierClass, lazyLoadOffset, hasPlaceholder, isCompany, isCommune, fullName, initials, isLoading, isOnlineStatusVisible, isOnline }) {
    let style = {};
    const hasPhoto = !_.isEmpty(src);

    if (!hasPhoto && !isCompany && fullName !== '' && !isLoading) {
        style = colorUtils.getColorsByString(fullName);
    }

    const avatarClass = classNames('c-avatar u-text-center u-accessibility-speak-none', {
        'c-avatar--tiny': size === sizes.TINY,
        'c-avatar--small': size === sizes.SMALL,
        'c-avatar--large': size === sizes.LARGE,
        'c-avatar--huge': size === sizes.HUGE,
        [modifierClass]: modifierClass !== '',
        'c-avatar--responsive': isFixedSize === false,
        'c-avatar__online-status': isOnlineStatusVisible,
        'c-avatar__online-status--online': isOnline,
    });

    const avatarPlaceholder = () => {
        if (hasPlaceholder || isLoading) {
            return <CirclePlaceholder size={size} isPending />;
        }

        const iconSize = sizeToPixels[size] ?? 36;

        if (isCompany) {
            return <CompanyIcon width={iconSize} height={iconSize} />;
        }

        if (isCommune) {
            return <CommuneIcon width={iconSize} height={iconSize} />;
        }

        return (
            <span
                className="c-avatar__name u-text-center"
            >
                { initials !== ''
                    ? initials
                    : <AvatarIcon width={size} height={size} />}
            </span>
        );
    };

    const avatarImage = () => (
        <LazyLoad
            offset={[lazyLoadOffset, 0]}
            placeholder={<Spinner size={sizes.SMALL}/>}
            once
        >
            <span
                className="c-avatar__img"
                style={{
                    backgroundImage: `url(${src})`,
                }}
            />
        </LazyLoad>
    );

    const title = isOnline ? _trans('Kirjautuneena', {}, 'common') : _trans('Ei kirjautuneena', {}, 'common');

    return (
        <span
            className={avatarClass}
            style={initials ? style : {}}
            aria-hidden
            title={isOnlineStatusVisible ? title : null}
        >
            { (! hasPhoto || isCompany || isLoading) ? avatarPlaceholder() : avatarImage() }
        </span>
    );
}

Avatar.defaultProps = {
    src: '',
    size: 'medium',
    isFixedSize: false,
    modifierClass: '',
    lazyLoadOffset: 64,
    hasPlaceholder: false,
    isCompany: false,
    isCommune: false,
    fullName: '',
    initials: '',
    isLoading: false,
    isOnlineStatusVisible: false,
    isOnline: false,
};

Avatar.propTypes = {
    /**
     * Kuvan lähde.
     */
    src: PropTypes.string,

    /**
     * Kuvan koko.
     */
    size: PropTypes.oneOf(Object.values(sizes)),

    /**
     * Muuttuuko kuvan koko selaimen koosta riippuen?
     */
    isFixedSize: PropTypes.bool,

    /**
     * CSS:n muokkausluokka.
     */
    modifierClass: PropTypes.string,

    /**
     * Kuvan etäisyys näkyvästä osasta selainikkunaa. Määrittää koska kuvaa aletaan ladatan. Oletuksena 0.
     */
    lazyLoadOffset: PropTypes.number,

    /**
     * Näytetäänkö placeholderia ikonin sijaan kun kuvaa ladataan?
     */
    hasPlaceholder: PropTypes.bool,

    /**
     * Onko kyseessä yrityksen käyttäjäkuva?
     */
    isCompany: PropTypes.bool,

    isCommune: PropTypes.bool,

    /**
     * Käyttäjän nimi. Avatarin luontia varten jos käyttäjällä ei ole kuvaa.
     */
    fullName: PropTypes.string,

    /**
     * Käyttäjän nimikirjaimet. Näytetään kuvan sijasta jos sitä ei ole.
     */
    initials: PropTypes.string,

    /**
     * Onko käyttäjän kuvan lataus kesken.
     */
    isLoading: PropTypes.bool,

    isOnlineStatusVisible: PropTypes.bool,
    isOnline: PropTypes.bool,
};

export default Avatar;
