/**
 * Vakuutusten tilat. Osa kopsittu InsuranceStates.php:stä.
 */
export const insuranceStates = {
    /**
     * Aktiivinen.
     */
    ACTIVE: 1,

    /**
     * Arkistoitu.
     */
    ARCHIVED: 2,

    /**
     * Luonnos.
     * (used mainly between when new individual accident insurance is created for the user and
     * when the first payroll is paid.)
     */
    DRAFT: 3,

    /**
     * Validoinnissa
     */
    VALIDATION: 4,

    /**
     * Uuden vakuutuksen käsittelyn aloitus Varman prosessissa.
     */
    INITIALISATION: 10,

    NEW: 20,

    TRANSFER: 30,

    PENDING: 40,

    PENDING_ACTIVE: 41,

    PENDING_TRANSFER: 42,

    /**
     * Odottaa päättämistä.
     */
    WAITING_TERMINATION: 45,

    ERROR: 90,

    API_ERROR: 91,

    /**
     * Päättynyt.
     */
    EXPIRED: -1,

    /**
     * Ei voimassa.
     */
    INACTIVE: null,

    POHJOLA_API_COMPLETED: 'COMPLETED',

    POHJOLA_API_ACTIVATING: 'ACTIVATING',

    POHJOLA_API_PENDING: 'PENDING',

    POHJOLA_API_FAILED: 'FAILED',

};

/**
 * Vakuutuksen käännös tilan mukaan.
 */
const insuranceStateMap = {
    [insuranceStates.ACTIVE]: _trans('insurances.state.active', {}, 'insurances'),
    [insuranceStates.EXPIRED]: _trans('insurances.state.expired', {}, 'insurances'),
    [insuranceStates.INACTIVE]: _trans('insurances.state.inactive', {}, 'insurances'),
};


/**
 * Vakuutuksen badgen tyyli tilan mukaan.
 */
const insuranceUiStates = {
    [insuranceStates.ACTIVE]: 'positive',
    [insuranceStates.EXPIRED]: 'negative',
    [insuranceStates.INACTIVE]: 'neutral',
};

/**
 * Vakuutuksen badgen ikonit
 * @param type
 * @returns {*}
 */
const insuranceBadgeIconMap = {
    [insuranceStates.ACTIVE]: 'check',
    [insuranceStates.EXPIRED]: 'warning',
};

export const pendingStates = [
    insuranceStates.POHJOLA_API_PENDING,
    insuranceStates.POHJOLA_API_ACTIVATING
];

export const expiredState = [
    insuranceStates.ARCHIVED,
    insuranceStates.WAITING_TERMINATION,
    insuranceStates.EXPIRED,
    insuranceStates.INACTIVE
];

export const varmaPendingStates = [
    insuranceStates.PENDING,
    insuranceStates.PENDING_ACTIVE,
    insuranceStates.PENDING_TRANSFER
];

export const translateInsuranceState = (state) => state in insuranceStateMap ? insuranceStateMap[state] : _trans('insurances.state.unknown', {}, 'insurances');
export const mapInsuranceStateToUi = (state) => state in insuranceUiStates ? insuranceUiStates[state] : 'hollow';
export const getInsuranceBadgeIcon = (state) => state in insuranceBadgeIconMap ? insuranceBadgeIconMap[state] : null;
export const isPendingState = (state) => state in pendingStates;
