import React from 'react';
import { useSpring, animated } from 'react-spring/web.cjs';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defaultAnimationConfig } from 'shared/constants/animations';

const animationNames = {
    FROM_LEFT: 'fromLeft',
    FROM_RIGHT: 'fromRight',
};

/**
 * Tylsä hampurilaismenu (mäkkäri).
 * Mahdollisesti asteittain muutetaan myöhemmin alaosavalikoihin mobiilissa.
 * @param children
 * @param ariaLabelledBy
 * @param hasPadding
 * @param isOpen
 * @param animation
 * @returns {*}
 * @constructor
 */
const BigMenu = ({ children, ariaLabelledBy, hasPadding, isOpen, animation }) => {
    const getAnimation = (animationName) => {
        switch (animationName) {
            case animationNames.FROM_LEFT:
                return {
                    transform: `translate3d(${isOpen ? '0%' : '-100%'}, 0, 0)`,
                    boxShadow: '-1px 5px 5px rgba(0, 0, 0, .35)',
                };
            case animationNames.FROM_RIGHT:
                return {
                    transform: `translate3d(${isOpen ? '0%' : '100%'}, 0, 0)`,
                    boxShadow: '1px 5px 5px rgba(0, 0, 0, .35)',
                };
            default: return {};
        }
    };

    // Pieni animaatio
    const bigMenuProps = useSpring({
        visibility: isOpen ? 1 : 0,
        ...getAnimation(animation),
        config: defaultAnimationConfig,
    });

    return (
        <animated.div
            className={classNames('o-pin o-pin--fixed o-pin--top o-pin--bottom c-big-menu', {
                'c-big-menu--padding': hasPadding,
                'o-pin--left': animation === animationNames.FROM_LEFT,
                'o-pin--right': animation === animationNames.FROM_RIGHT,
                'u-pointer-events-none': !isOpen,
            })}
            aria-labelledby={ariaLabelledBy}
            role="menu"
            style={{
                ...bigMenuProps,
                zIndex: 4,
            }}
            aria-hidden={!isOpen}
        >
            {children}
        </animated.div>
    );
};

BigMenu.propTypes = {
    /**
     * Valikon sisältö.
     */
    children: PropTypes.node.isRequired,

    /**
     * Sen napin id joka kuvaa tätä valikkoa (ruudunlukijoille).
     */
    ariaLabelledBy: PropTypes.string.isRequired,

    /**
     * Onko valikko auki.
     */
    isOpen: PropTypes.bool,

    /**
     * Onko valikon yläosassa whitespacea.
     */
    hasPadding: PropTypes.bool,

    /**
     * Animointitapa.
     */
    animation: PropTypes.oneOf(Object.values(animationNames)),
};

BigMenu.defaultProps = {
    isOpen: false,
    hasPadding: false,
    animation: animationNames.FROM_LEFT,
};

export default BigMenu;
