import { produce } from 'immer';
import api from 'api';
import { userRoles } from 'shared/constants/index';
import { sortBy } from 'shared/utils/sortUtils';
import { createQueryParams } from 'shared/utils/commonUtils';

/**
 * Kirjautuneen käyttäjän työntekijät.
 */
export default {
    state: [],

    reducers: {
        // Asetetaan työntekijät
        setEmployees: (state, payload) => payload,

        // Lisätään uusi työntekijä listaan (vain jos ei jo löydy ennestään)
        addEmployee: (state, employee) => {
            const employeeUserId = employee?.userId;
            const hasExistingEmployee = state.find(({ userId }) => userId === employeeUserId);
            return hasExistingEmployee ? state : ([ ...state, employee ]);
        },

        removeEmployeeAtIndex: (state, index) => produce(state, (draftState) => {
            draftState.splice(index, 1);
        }),

        // Päivitetään työntekijän tiedot
        updateEmployee: (state, payload) => produce(state, (draftState) => {
            const userId = payload.userId;
            const index = draftState.findIndex((employee) => employee.userId === userId);
            if (index >= 0) {
                draftState[index] = payload;
            }
        }),
    },

    selectors: {
        getEmployees: (state) => state ?? [],
        getEmployeeById: (state, id) => {
            if (! id) return null;
            const userId = parseInt(id, 10);
            return state.find((employee) => employee.userId === userId);
        },
        getEmployeesByIds: (state, ids) => {
            const userIds = (ids ?? []).map((id) => parseInt(id, 10));
            return state.filter((employee) => userIds.includes(employee.userId));
        }
    },

    effects: () => ({
        /**
         * Hakee käyttäjän työntekijät. Jos ollaan työnantaja-roolissa filtteröidään
         * käyttäjistä käyttäjä itse pois.
         *
         * @param userRole - kirjautuneen käyttäjän rooli
         * @param userId - kirjautuneen käyttäjän id
         * @param limit
         * @param type
         * @returns {Promise<void>}
         */
        async fetchEmployees({ userRole = -1, userId = -1, type, limit = 1000 }) {
            try {
                const qp = createQueryParams({ limit, type }, { omitEmptyValues: true });
                const json = await api.get(`/api/v2/users?${qp}`);
                const users = sortBy((json.data ?? []), 'fullName');

                // Filtteröidään työnantaja itse pois listalta jos ollaan työntekijä-roolissa.
                if (userRole === userRoles.EMPLOYER) {
                    const employees = users.filter((user) => user.userId !== userId);
                    this.setEmployees(employees);
                } else {
                    this.setEmployees(users);
                }
                return json;
            } catch (e) {
                console.error(e);
            }
        },

        async fetchEmployeeBySsn(ssn) {
            try {
                return await api.get(`/api/v2/users?ssn=${ssn}`);
            } catch (e) {
                console.log(e);
            }
        },

        /**
         * Lisää uuden käyttäjän. Jos kaikki meni putkeen palataan mahdolliseen redirectRouteen.
         * @param values - uuden työntekijän tiedot
         * @returns {Promise<void>}
         */
        async postEmployee(values = {}) {
            try {
                return await api.post('/api/v2/users', values);
            } catch (errorResponse) {
                console.log(errorResponse);
                return errorResponse;
            }
        },

        /**
         * Päivittää käyttäjän tiedot. Jos kaikki meni putkeen palataan mahdolliseen redirectRouteen.
         * @param userId
         * @param values - työntekijän päivitetyt tiedot
         * @returns {Promise<void>}
         */
        async putEmployee({ userId = null, values = {} }) {
            try {
                return await api.put(`/api/v2/users/${userId}`, values);
            } catch (e) {
                console.log(e);
            }
        },
    })
};
