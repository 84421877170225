import React from 'react';
import PropTypes from 'prop-types';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { Value } from 'shared/components/Value';
import { BooleanValue } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Summary/shared/BooleanValue';

/**
 * Taulukkopalkan käyttö.
 * @returns {JSX.Element}
 * @constructor
 */
const TableSalarySwitch = ({ label, value }) => (
    <SummaryListItem term={label}>
        <Value>
            <BooleanValue value={value} />
        </Value>
    </SummaryListItem>
);

TableSalarySwitch.propTypes = {
    // eslint-disable-next-line react/boolean-prop-naming
    value: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
};

export default TableSalarySwitch;
