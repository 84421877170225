import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { select } from '@rematch/select';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormField, MaxTextAreaField } from 'shared/components/Formik';
import { Page, Placeholder, SubmitButton } from 'shared/components';
import { noteTypes } from 'shared/constants/noteTypes';
import ActionBar from 'shared/components/ActionBar';

/**
 * Muistiinpanot. Joko sopimukseen tai kunnan asiakkuuteen liittyvät.
 * @param typeId
 * @param noteType
 * @returns {JSX.Element}
 * @constructor
 */
export const Notes = ({ typeId, noteType }) => {
    const dispatch = useDispatch();

    const notes = useSelector(select.notes.getNotes);
    const isLoading = useSelector((state) => state.loading.effects.notes.fetchNotes);
    const isSaving = useSelector((state) => state.loading.effects.notes.saveNotes);

    return (
        <Page
            title={_trans('Muistiinpanot', {}, 'common')}
            heading={_trans('Muistiinpanot', {}, 'common')}
        >
            <Formik
                initialValues={notes}
                enableReinitialize
                onSubmit={(values, actions) => {
                    dispatch.notes
                        .saveNotes({ typeId, notes: values, noteType })
                        .then(({ status, message, data }) => {
                            if (status === 'ok') {
                                actions.resetForm({ values });
                                dispatch.notifications.addSuccess(_trans('Muistiinpano tallennettu.', {}, 'jobContract'));
                                dispatch.notes.setNotes(data);
                            } else {
                                dispatch.notifications.addError(message ?? _trans('Muistiinpanoa ei voitu tallentaa.', {}, 'jobContract'));
                            }
                        });
                }}
            >
                {({ dirty }) => (
                    <Form alignFields="vertical">
                        <FormField
                            name="note"
                            label={noteType === noteTypes.BENEFIT_DECISION
                                ? _trans('Asiakkuuteen liittyvät muistiinpanot.', {}, 'jobContract')
                                : _trans('Vapaamuotoiset muistiinpanot. Muistiinpanojen sisältö ei vaikuta työsopimukseen tai palkanlaskentaan.', {}, 'jobContract')}
                        >
                            <Placeholder isPending={isLoading}>
                                <MaxTextAreaField
                                    rows={8}
                                    name="note"
                                    modifierClass="u-1/1"
                                    maxLength={10000}
                                />
                            </Placeholder>
                        </FormField>
                        <ActionBar alignItems="right">
                            <SubmitButton isPending={isSaving} disabled={! dirty}>
                                {_trans('Tallenna', {}, 'common')}
                            </SubmitButton>
                        </ActionBar>
                    </Form>
                )}
            </Formik>
        </Page>
    );
};

Notes.propTypes = {
    /**
     * Haetaanko kunnan asiakkuuteen liittyvät muistiinpanot vai sopparin.
     */
    noteType: PropTypes.oneOf([noteTypes.CONTRACT, noteTypes.BENEFIT_DECISION]).isRequired,
    /**
     * Sopimuksen tai asiakkuuden id.
     */
    typeId: PropTypes.number.isRequired,
};
