import React from 'react';
import { select } from '@rematch/select';
import { useDispatch, useSelector } from 'react-redux';
import { ChoiceList } from 'shared/components';
import {
    translateEditTypeDescription,
    translateEditTypeLabel,
    updateTypes
} from 'shared/constants/workExperienceTypes';

const EditTypeSelect = () => {
    const dispatch = useDispatch();
    const selectedEditType = useSelector(select.employeeWorkExperience.getEditType);

    return (
        <fieldset>
            <legend>
                {_trans('users.section.work_experience.choice.title')}
            </legend>
            <ChoiceList
                id="changeType"
                onChange={(value) => dispatch.employeeWorkExperience.setEditType(value)}
                value={selectedEditType}
                options={updateTypes.map((type) => ({
                    label: translateEditTypeLabel(type),
                    value: type,
                    description: translateEditTypeDescription(type),
                }))}
            />
        </fieldset>
    );
};

export default EditTypeSelect;
