/**
 * Syöttöpalikoiden sallitut tyypit. Esim:
 * {
 *     block: 'input',
 *     type: 'autocomplete'
 * }
 */
export const inputTypes = {
    AUTOCOMPLETE: 'autocomplete',
    TEXT: 'text',
    INTEGER: 'integer',
    DECIMAL: 'decimal',
    CURRENCY: 'currency', // Lisää euromerkin perään automaattisesti
    TEXTAREA: 'textarea',
    DATE: 'date',
    DATE_RANGE: 'dateRange',
    SWITCH: 'switch',

    // Syöttöpalikat joille valinnat voi antaa listana.
    CHOICE: 'choice',
    DROPDOWN: 'dropdown',
    CHECKBOX: 'checkbox',
    RADIO: 'radio',

    // Monimutkaisemmat syöttökentät. Esim. työntekijän/työnantajan valinnat ja TES-palikat on näitä.
    COMPONENT: 'component',

    // Kryptauskenttä. Kaikki sisältö tässä kryptataan.
    ENCRYPTED_TEXT: 'encryptedText',
    ENCRYPTED_TEXTAREA: 'encryptedTextarea',
    ENCRYPTED_SSN: 'encryptedSsn',

    // blocks-listan renderöinti useampaan kertaan (vrt. useampi palkkio).
    //ARRAY: 'array',
};

/**
 * Syöttöpalikat joille valinnat voi antaa listana.
 * Miksi näin? Koska esim. checkbox-lista on semanttisesti ryhmä johon
 * label päätasolla viittaa. Lisäksi checkboxlistan itemeillä on itsessään oma label.
 * Esim:
 * {
 *     block: 'input',
 *     type: 'dropdown',
 *     options: [...]
 * }
 */
export const choiceInputTypes = [
    inputTypes.CHOICE,
    inputTypes.DROPDOWN,
    inputTypes.CHECKBOX,
    inputTypes.RADIO,
];

export const encryptedInputTypes = [
    inputTypes.ENCRYPTED_TEXT,
    inputTypes.ENCRYPTED_TEXTAREA,
    inputTypes.ENCRYPTED_SSN,
];

/**
 * Keskenään tyypin vaihtoon sopivat inputit.
 * Yllä olevat choiceInputit voi vaihtaa muutenkin tyyppiä keskenään.
 * @type {(string)[]}
 */
export const interchangeableInputs = [
    inputTypes.TEXT,
    inputTypes.TEXTAREA,
    inputTypes.ENCRYPTED_TEXT,
    inputTypes.ENCRYPTED_TEXTAREA,
    inputTypes.ENCRYPTED_SSN,
];

/**
 * block: 'input',
 * type: 'custom'
 */

