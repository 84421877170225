import React from 'react';
import PropTypes from 'prop-types';
import { SummaryList } from 'shared/components';
import { translateSalaryType, translateSalaryUnit } from 'shared/constants/salaryTypes';

export const ContractSalary = ({ salaryType, salary }) => (
    <SummaryList list={[{
        term: _trans('Palkkaustapa', {}, 'extract'),
        description: <span>{translateSalaryType(salaryType)} {_currency(salary, true)} {translateSalaryUnit(salaryType)}</span>,
    }]}/>
);

ContractSalary.propTypes = {
    salaryType: PropTypes.number.isRequired,
    salary: PropTypes.object.isRequired
};
