import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MDIcon from 'shared/components/MDIcon';

/**
 * Renderöi chipin. Käytetään indikoimaan valittuja arvoja.
 * Chip voi olla myös poistettavissa.
 *
 * @param text
 * @param isRemovable
 * @param onRemove
 * @param onToggle
 * @param isSelected
 * @returns {*}
 * @constructor
 */
function Chip({
    text,
    isRemovable,
    onRemove,
    onToggle,
    isSelected,
}) {
    const chipClass = classNames('c-chip o-stack o-stack--inline u-padding-horizontal-tiny u-align-middle', {
        'c-chip--removable': isRemovable,
        'c-chip--unselected': ! isRemovable && ! isSelected,
    });

    const onChipRemove = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onRemove();
    };

    return React.createElement(isRemovable ? 'div' : 'button', {
        className: chipClass,
        ...(! isRemovable && { onClick: (event) => {
            event.preventDefault();
            event.stopPropagation();
            onToggle();
        } }),
    }, (
        <Fragment>
            {(! isRemovable && isSelected) && (
                <MDIcon icon="check" size="small" />
            )}
            <span className="c-chip__text u-margin-horizontal-tiny">{text}</span>
            {isRemovable && (
                <button type="button" className="c-chip__action" onClick={onChipRemove}>
                    <MDIcon icon="cancel" size="small" />
                </button>
            )}
        </Fragment>
    ));
}

Chip.defaultProps = {
    onRemove: () => {},
    isRemovable: false,
    onToggle: () => {},
    isSelected: false,
};

Chip.propTypes = {
    /**
     * Short text on the chip.
     */
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    /**
     * Is it removable.
     */
    isRemovable: PropTypes.bool,

    /**
     * Callback when chip is removed.
     */
    onRemove: PropTypes.func,

    /**
     * Callback when chip is toggled.
     */
    onToggle: PropTypes.func,

    isSelected: PropTypes.bool,
};

export default Chip;
