import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import {
    PreviousEmployerSignature
} from '../../../components/Signatures/PreviousEmployerSignature';
import Button from 'shared/components/Button';
import MDIcon from 'shared/components/MDIcon';
import { signatureTypes } from 'shared/constants/signatureTypes';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import Switch from 'shared/components/Switch';
import { SignatureOnPaper } from 'shared/ReForm/components/SignatureOnPaper';

/**
 * Työnantajan allekirjoitus.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const BenefitDecisionEmployer = (props) => {
    const { value } = props;
    const [hasSeenSignature, setHasSeenSignature] = useState(false);

    const dispatch = useDispatch();

    const isSigning = useSelector((state) => state.loading.effects.postSignature);
    const jobContactDataId = useSelector(select.contract.getContractDataId);
    const userId = useSelector(select.userMetadata.getUserId);

    const isEmployer = useSelector(select.userMetadata.isEmployer);
    const isCompany = useSelector(select.userMetadata.isCompany);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isOperator = isCommune || isCompany;

    const isSelfEmployed = useSelector(select.contract.isBeneficiarySelfEmployed);
    const isEmployerIdentified = useSelector(select.contract.isEmployerIdentified);

    const isProposalContract = useSelector(select.contract.isProposalContract);
    const isFullySigned = useSelector(select.contract.isFullySigned);
    const isBuildMode = useSelector(select.contract.isBuildMode);
    const signatures = useSelector(select.contract.getSignatures);
    const hasCommuneUserSigned = useSelector(select.contract.hasCommuneUserSigned);

    const signerId = parseInt(value, 10);
    const signature = signatures.find((signer) => signer?.type === signatureTypes.TYPE_EMPLOYER_SPECIFIC && signer.signerId === signerId);
    const signerDetails = signature?.signerDetails;
    const signTime = signature?.signTime;

    const hasPreviousSignatures = useSelector(select.contract.hasPreviousSignatures);
    const isReSignatureNeeded = useSelector(select.contract.isReSignatureNeeded);

    const summaryLabel = _trans('Työnantaja', {}, 'common');

    // Ei vaadita uusia allekirjoituksia. Kerrotaan milloin viimeksi allekirjoitettu ja kenen toimesta.
    if (hasPreviousSignatures && isReSignatureNeeded === false) {
        return (
            <SummaryListItem term={summaryLabel}>
                <PreviousEmployerSignature />
            </SummaryListItem>
        );
    }

    // Allekirjoitettu
    const isSigned = signature?.isSigned ?? false;

    // Onko samat käyttäjäid:t.
    const canEmployerSign = isSelfEmployed && isEmployerIdentified;
    const isUserAllowedToSign = userId === signerId && canEmployerSign;

    /* eslint-disable-next-line */
    const SignButton = ({ buttonText = _trans('Allekirjoita', {}, 'jobContract'), signatureContractDataId, isSigningOnBehalf = false }) => (
        <Button
            inProgress={isSigning}
            positive={! isBuildMode}
            mdIcon="check"
            disabled={isBuildMode}
            modifierClass="u-1/1 u-margin-top-small"
            onClick={() => {
                dispatch.contract.postSignature({
                    contractDataId: isSigningOnBehalf
                        ? signatureContractDataId
                        : jobContactDataId,
                    isSigningOnBehalf,
                    signatureType: signatureTypes.TYPE_EMPLOYER_SPECIFIC
                })
                    .then(({ data, status, message }) => {
                        if (status === 'ok') {
                            if (signatures.every((signature) => signature.isSigned)) {
                                window.scrollTo(0, 0);
                            }
                            dispatch.contract.setContractData(data);
                        } else {
                            // Joko virheviesti jos löytyy tai geneerinen käsienlevittelyviesti
                            dispatch.notifications.addError(message ?? _trans('Sopimusta ei voitu allekirjoittaa.', {}, 'jobContract'));
                        }
                    });
            }}
        >
            {buttonText}
        </Button>
    );

    // Valittu allekirjoittaja. Näytä tiedot ja napit vaihtoon ja allekirjoitukseen.
    return (
        <SummaryListItem term={summaryLabel}>
            {! isSigned && (
                <div className="u-visibility-screen">
                    <strong>
                        {signerDetails?.fullName ?? '-'}
                    </strong><br/>
                    {signerDetails?.email ?? '-'}
                    {(! canEmployerSign && ! isProposalContract) && (
                        <div className="o-form__text">
                            {_trans('Työnantaja ei käytä Oimaa. Allekirjoitus tehdään sopimuksen paperiseen versioon.', {}, 'jobContract')}
                        </div>
                    )}
                </div>
            )}
            {(() => {
                if (isSigned) {
                    return (
                        <div>
                            <MDIcon icon="check_circle" size="small" modifierClass="u-color-positive u-align-middle u-margin-right-tiny" isFilled />
                            <span className="u-align-middle">
                                {! canEmployerSign
                                    ? (
                                        <Fragment>
                                            <strong>
                                                {signerDetails?.fullName ?? '-'}
                                            </strong>{'. '}
                                            {_trans('Allekirjoitus sopimuksen paperisessa versiossa.', {}, 'jobContract')}{' '}
                                            {_transMd('%signer% vahvisti nähneensä paperisen sopimuksen **%signTime%** ja arkistoineensa sen.', {
                                                signer: isEmployer
                                                    ? _trans('Hyvinvointialue', {}, 'common')
                                                    : signature?.verifiedByName ?? '-',
                                                signTime: _toLocaleDate(signTime, '', _dateTimeFormat)
                                            }, 'jobContract')}
                                        </Fragment>
                                    )
                                    : (
                                        _transMd(_trans('**%signer%** allekirjoitti **%signTime%**', {
                                            signer: signerDetails?.fullName ?? '-',
                                            signTime: _toLocaleDate(signTime, '', _dateTimeFormat)
                                        }, 'jobContract'))
                                    )
                                }
                            </span>
                        </div>
                    );
                }

                if (canEmployerSign) {
                    return (
                        <Fragment>
                            {(isProposalContract && isUserAllowedToSign) && <SignButton />}
                            {! isUserAllowedToSign && (
                                <div className="u-muted">
                                    {_trans('Odottaa työnantajan allekirjoitusta.', {} ,'jobContract')}
                                </div>
                            )}
                        </Fragment>
                    );
                }

                // Ei voi itse allekirjoittaa. Henkkari vahvistaa.
                if (isOperator && hasCommuneUserSigned) {
                    return (
                        <Fragment>
                            {/* Paikka, aika ja allekirjoitus. Vain tulosteissa / PDF. */}
                            {(isProposalContract && ! canEmployerSign && ! isFullySigned) && (
                                <SignatureOnPaper fullName={signature.fullName} />
                            )}
                            <div className="u-visibility-screen">
                                <div className="u-margin-bottom-small">
                                    <p>
                                        {_trans('Työnantaja ei käytä Oimaa.', {}, 'jobContract')}
                                    </p>
                                    {` ${_trans('Tulosta sopimus ja pyydä siihen allekirjoitus. Nähtyäsi allekirjoituksen voit vahvistaa sopimuksen Oimassa.', {}, 'jobContract')}`}
                                </div>
                                <Switch
                                    id="hasSeenSignature"
                                    onChange={(isOn) => {
                                        setHasSeenSignature(isOn);
                                    }}
                                    modifierClass="u-margin-bottom-small"
                                >
                                    {_trans('Olen nähnyt paperisen sopimuksen allekirjoituksen ja arkistoinut sen.', {}, 'jobContract')}
                                </Switch>

                                {hasSeenSignature && (
                                    <SignButton
                                        signatureContractDataId={signature?.signerDetails?.contractDataId}
                                        isSigningOnBehalf
                                        buttonText={_trans('Vahvista', {}, 'common')}
                                    />
                                )}
                            </div>
                        </Fragment>
                    );
                }

                // Työnantaja voi itse allekirjoittaa
                /*if (isProposalContract) {
                    if (canEmployerSign) {
                        return (
                            <div>
                                <SignButton />
                                {isUserAllowedToSign
                                    ? _trans('Odottaa allekirjoitustasi.', {}, 'jobContract')
                                    : _trans('Odottaa allekirjoitusta.', {}, 'jobContract')}
                            </div>
                        );
                    }
                }

                // Ennen allekirjoitettavaksi hyväksymistä...
                if (! canEmployerSign) {
                    return (
                        <div>
                            {_trans('Työnantaja ei käytä Oimaa. Allekirjoitus tehdään sopimuksen paperiseen versioon.', {}, 'jobContract')}
                        </div>
                    );
                }*/
            })()}
        </SummaryListItem>
    );
};

BenefitDecisionEmployer.propTypes = {
    attributes: PropTypes.object.isRequired,
    value: PropTypes.number.isRequired,
};

export default BenefitDecisionEmployer;
