import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'shared/components/Dropdown';
import { groupDropdownOptions } from 'shared/utils/groupDropdownOptions';

function JobTitlesDropdown(props) {
    return (
        <Dropdown
            optionValue="value"
            optionLabel="label"
            options={groupDropdownOptions(props.jobTitles)}
            {...props}
        />
    );
}

JobTitlesDropdown.propTypes = {
    jobTitles: PropTypes.array.isRequired,
};

export default JobTitlesDropdown;
