import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { select } from '@rematch/select';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import DimensionTypeCell from '../components/DimensionTypeCell';
import DataTable from 'shared/containers/DataTable';
import { Button, LinkIcon, MDSpinner, Page, Tooltip } from 'shared/components';
import { mainBreakPoints } from 'shared/constants/index';
import resolveUrl from 'shared/utils/resolveUrl';
import { dimensionType } from 'shared/shapes/dimensions';
import ActionBar from 'shared/components/ActionBar';
import confirm from 'shared/utils/confirm';
import { boolToText } from 'shared/utils/commonUtils';

const breakpointMatches = matchMedia(`(min-width: ${mainBreakPoints.MEDIUM}px)`).matches;

@connect((state) => ({
    dimensionTypes: select.dimensions.getDimensionTypes(state),
    isLoadingDimensions: state.loading?.effects.dimensions.fetchDimensionTypes,
    isDeleting: state.loading?.effects.dimensions.deleteDimensionType,
    isCommune: select.userMetadata.isCommune(state),
}))
export default class DimensionsTypeList extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        dimensionTypes: PropTypes.arrayOf(dimensionType),
        isLoadingDimensions: PropTypes.bool,
        isDeleting: PropTypes.bool,
        routes: PropTypes.object.isRequired,
        isCommune: PropTypes.bool,
    };

    static defaultProps = {
        dispatch() {},
        dimensionTypes: [],
        isLoadingDimensions: false,
        isDeleting: false,
        isCommune: false
    };
    constructor(props) {
        super(props);
        props.dispatch.dimensions.fetchDimensionTypes({ restrictDimensionVisibility: 0 });
    }

    onEdit = (typeId) => this.props.dispatch(push(resolveUrl(this.props.routes.DIMENSION_TYPE_FORM, { typeId })));

    onDelete = (id) => this.props.dispatch.dimensions.deleteDimensionType(id);

    render() {
        const { dimensionTypes, isLoadingDimensions, isDeleting, routes, isCommune } = this.props;
        if (isLoadingDimensions) {
            return <MDSpinner wrapped/>;
        }

        return (
            <Page
                title={_trans('company_manager.dimensions.types.title')}
                heading={_trans('company_manager.dimensions.types.heading')}
                isBodyClear
                actionItems={(
                    <LinkIcon mdIcon="add" to={resolveUrl(routes.DIMENSION_TYPE_FORM, { typeId: 'new' })} modifierClass="c-button c-button--primary">
                        {_trans('company_manager.dimensions.types.heading_create')}
                    </LinkIcon>
                )}
                maxWidth="huge"
            >
                <DataTable
                    data={dimensionTypes.reverse()}
                    emptyDataMessage={_trans('company_manager.dimensions.list.no_dimension_types')}
                    actionsColumnWidth={340}
                    isLoading={isLoadingDimensions}
                    actionsColumn={(breakpointMatches ?
                        <ActionColumn
                            onDelete={this.onDelete}
                            onEdit={this.onEdit}
                            isDeleting={isDeleting}
                        />
                        : null
                    )}
                    columns={[
                        {
                            Header: _trans('company_manager.dimensions.list.cells.name'),
                            accessor: 'name',
                            width: 200,
                            Cell: (props) =>
                                <DimensionTypeCell
                                    isDeleting={isDeleting}
                                    onDelete={this.onDelete}
                                    onEdit={this.onEdit}
                                    route={routes.DIMENSIONS}
                                    { ...props }
                                />
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_contract'),
                            id: 'isContract',
                            accessor: ({ isContract }) => boolToText(isContract),
                            show: ! isCommune && breakpointMatches,
                            width: 120,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_time_registration'),
                            id: 'isTimeRegistration',
                            accessor: ({ isTimeRegistration }) => boolToText(isTimeRegistration),
                            show: ! isCommune && breakpointMatches,
                            width: 120,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_search_dimension'),
                            id: 'isSearchDimension',
                            accessor: ({ isSearchDimension }) => boolToText(isSearchDimension),
                            show: ! isCommune && breakpointMatches,
                            width: 120,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_payment_focusing_dimension'),
                            id: 'isPaymentFocusingDimension',
                            accessor: ({ isPaymentFocusingDimension }) => boolToText(isPaymentFocusingDimension),
                            show: ! isCommune && breakpointMatches,
                            width: 120,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_service_charge_invoicing_dimension'),
                            id: 'isServiceChargeInvoicingDimension',
                            accessor: ({ isServiceChargeInvoicingDimension }) => boolToText(isServiceChargeInvoicingDimension),
                            show: ! isCommune && breakpointMatches,
                            width: 120,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_restricted'),
                            id: 'isRestricted',
                            show: ! isCommune && breakpointMatches,
                            accessor: ({ isRestricted }) => boolToText(isRestricted),
                            width: 120,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_salary_type'),
                            id: 'isSalaryType',
                            show: ! isCommune && breakpointMatches,
                            accessor: ({ isSalaryTypeDimension }) => boolToText(isSalaryTypeDimension),
                            width: 120,
                        },
                        {
                            Header: _trans('company_manager.dimensions.types.form.is_benefit_decision_dimension.text'),
                            id: 'isBenefitDecisionDimension',
                            show: isCommune,
                            accessor: ({ isBenefitDecisionDimension }) => boolToText(isBenefitDecisionDimension),
                            width: 180,
                        },
                        {
                            Header: _trans('company_manager.dimensions.types.form.is_visibility_dimension.text'),
                            id: 'isVisibilityDimension',
                            show: isCommune,
                            accessor: ({ isVisibilityDimension }) => boolToText(isVisibilityDimension),
                            width: 180,
                        },
                        {
                            Header: _trans('company_manager.dimensions.types.form.is_contract_contact_dimension.text'),
                            id: 'isContractContactDimension',
                            show: isCommune,
                            accessor: ({ isContractContactDimension }) => boolToText(isContractContactDimension),
                            width: 180,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_search_dimension.text'),
                            id: 'isSearchDimension',
                            accessor: ({ isSearchDimension }) => boolToText(isSearchDimension),
                            show: isCommune,
                            width: 180,
                        },
                        {
                            Header: _trans('Kirjanpidon oletusdimensio', {}, 'company_manager'),
                            id: 'isBookkeepDefaultDimension',
                            accessor: ({ isBookkeepDefaultDimension }) => boolToText(isBookkeepDefaultDimension),
                            show: ! isCommune && breakpointMatches,
                            width: 180,
                        },
                    ]}

                />
            </Page>
        );
    }
}


const ActionColumn = ({ onEdit, onDelete, isDeleting, original: { id, dimensions } }) => (
    <ActionBar canWrap alignItems="left">
        <Button
            ghost
            onClick={() => onEdit(id)}
            mdIcon="edit"
        >
            {_trans('button.edit')}
        </Button>
        <Button
            ghost
            mdIcon="delete"
            disabled={(dimensions ?? []).length > 0}
            inProgress={isDeleting ?? false}
            onClick={() => {
                confirm(_trans('button.are_you_sure'), {
                    proceedLabel: _trans('Poista', {}, 'common'),
                    alert: true,
                }).then(() => onDelete(id));
            }}
        >
            {_trans('button.remove')}
        </Button>
        {(dimensions ?? []).length > 0 && (
            <Tooltip title={_trans('company_manager.dimensions.notifications.error.has_values')}/>
        )}
    </ActionBar>
);

ActionColumn.propTypes = {
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    original: PropTypes.object,
};

ActionColumn.defaultProps = {
    original: {},
};


