import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { AutoCompleteVirtualized } from 'shared/containers';
import Placeholder from 'shared/components/Placeholder';

/**
 * Lisämaksulista. Jos lisämaksu ei ole enää validi (hinta ei ole enää voimassa) näytetään punaisella.
 * Ei ole tällöin myöskään valittavissa.
 * @param onSelect
 * @returns {JSX.Element}
 * @constructor
 */
export const SurchargeAutoComplete = ({ onSelect }) => {
    const surchargeProducts = useSelector(select.surcharges.getSurchargePricingList);
    const isLoadingProducts = useSelector((state) => state.loading.effects.surcharges.fetchSurchargePricingList);

    const [, meta, helpers] = useField('standardPricingId');
    const { setValue } = helpers;
    const { value } = meta;

    return (
        <Placeholder isPending={isLoadingProducts}>
            <AutoCompleteVirtualized
                items={surchargeProducts}
                id="surchargeSelector"
                onSelect={(product) => {
                    setValue(product.standardPricingId);
                    onSelect(product);
                }}
                defaultValue={value}
                labelKey="name"
                valueKey="standardPricingId"
                placeholder="Valitse lisämaksu"
                optionRenderer={({ focusedOption, key, option, selectValue, style }) => {
                    const validPricingItem = option.validPricingItem ?? false;
                    const minServiceFee = option.validPricingItem?.minServiceFee;
                    const productCode = option.productCode ?? false;
                    const isValidSurcharge = validPricingItem && productCode;
                    //const maxServiceFee = option.validPricingItem?.maxServiceFee;

                    return (
                        <button
                            disabled={! isValidSurcharge}
                            onClick={() => validPricingItem
                                ? selectValue(option)
                                : selectValue({ ...option, validPricingItem: { minServiceFee: '0' } })
                            }
                            className={classNames('VirtualizedSelectOption', {
                                'VirtualizedSelectOption-has-focus': focusedOption === option,
                                'u-color-negative': !isValidSurcharge,
                            })}
                            key={key}
                            style={style}
                        >
                            <span className="o-stack o-stack--justify u-1/1">
                                <span className="u-text-left">
                                    {option.name}
                                </span>
                                <span className="u-text-right">
                                    {minServiceFee ? _currency(minServiceFee) : '0'}
                                </span>
                            </span>
                        </button>
                    );
                }}
            />
        </Placeholder>
    );
};

SurchargeAutoComplete.propTypes = {
    onSelect: PropTypes.func.isRequired,
};
