import React, { Suspense, StrictMode } from 'react';
import PropTypes from 'prop-types';

const componentsMap = new Map();

/**
 * Yksinkertainen kakutus koska ilman kakutusta dynamic import mounttaa komponentin aina
 * uudelleen ja uudelleen kun esim. Formikin tila muuttuu. Johtaa pahimmillaan siihen että
 * mikäli custom komponentilla on sisällään request tapauksessa että sille on valittu arvo
 * tykitetään requesteja aina kun tila muuttuu. Eli vaikka jokaisella näppäinpainalluksella.
 * @param componentName
 * @returns {V|React.LazyExoticComponent<React.ComponentType<any>>}
 */
const getCachedLazy = (componentName) => {
    if (componentsMap.has(componentName)) {
        return componentsMap.get(componentName);
    }
    // eslint-disable-next-line no-unsanitized/method
    const Component = React.lazy(() => import(`${componentName}`));
    componentsMap.set(componentName, Component);

    return Component;
};

/**
 * Lataa komponentit dynaamisesti ja hoitaa kakutuksen.
 * @param componentName
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const CustomComponent = ({ componentName, props }) => {
    // eslint-disable-next-line no-unsanitized/method
    const Component = getCachedLazy(componentName);

    return (
        <Suspense fallback={<div>Lataa...</div>}>
            {/*https://reactjs.org/docs/strict-mode.html*/}
            <StrictMode>
                <Component {...props} />
            </StrictMode>
        </Suspense>
    );
};

CustomComponent.propTypes = {
    componentName: PropTypes.string.isRequired,
    props: PropTypes.object.isRequired,
};
