import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isCommune } from 'shared/utils/commonUtils';
import StLabel from 'shared/components/StForm/StLabel';
import StField from 'shared/components/StForm/StField';
import { FormikAutoComplete, FormikErrors } from 'shared/components/Formik';
import { Placeholder } from 'shared/components';

/**
 * Kustannuspaikan / dimension valitsin
 * @returns {JSX.Element}
 * @constructor
 */
export const SurchargeDimensionSelector = ({ dimensions, isLoadingDimensions, name, label, placeholder }) => (
    <Fragment>
        <StLabel htmlFor="dimension">
            {label}
        </StLabel>
        <StField>
            <Placeholder isPending={isLoadingDimensions}>
                {dimensions.length === 0 && (
                    <div className="u-muted">
                        {isCommune()
                            ? 'Ei kustannuspaikkoja'
                            : 'Ei dimensioita'
                        }
                    </div>
                )}
                {dimensions.length > 0 && (
                    <FormikAutoComplete
                        hasSingleValue
                        name={name}
                        items={[
                            {
                                value: '',
                                name: '--- Ei mitään ---'
                            },
                            ...dimensions.sort(),
                        ]}
                        labelKey="name"
                        valueKey="value"
                        placeholder={placeholder}
                    />
                )}
            </Placeholder>
            <FormikErrors name={name} />
        </StField>
    </Fragment>
);

SurchargeDimensionSelector.propTypes = {
    dimensions: PropTypes.oneOfType([PropTypes.number, PropTypes.array]).isRequired,
    isLoadingDimensions: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
};
