import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useSticky from 'shared/hooks/useSticky';

/**
 * Lapsestaan stickyn tekevä komponentti.
 * Tee komponentille tyylillisesti mitä haluat "is-sticky"-CSS-luokan avulla.
 * @param children
 * @param stickTo
 * @param modifierClass
 * @param stickyClass
 * @returns {*}
 * @constructor
 */
const Sticky = ({ children, stickTo, modifierClass, stickyClass }) => {
    const [parentRef, style, isSticky] = useSticky(stickTo);

    return (
        <div
            ref={parentRef}
            className={classNames('c-sticky', modifierClass, {
                [stickyClass]: isSticky,
            })}
            style={style}
        >
            {children}
        </div>
    );
};

Sticky.propTypes = {
    children: PropTypes.node.isRequired,

    /**
     * Kiinnittyykö ylä vai alaosaan containeria.
     */
    stickTo: PropTypes.oneOf(['top', 'bottom']),

    /**
     * CSS-muokkausluokka.
     */
    modifierClass: PropTypes.string,
    /**
     * Minkä CSS-luokan elementti saa ollessaan sticky.
     */
    stickyClass: PropTypes.string,
};

Sticky.defaultProps = {
    modifierClass: '',
    stickTo: 'top',
    stickyClass: 'is-sticky',
};

export default Sticky;
