import React from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/Button';

const CollapseTrigger = ({ title, isOpen, onClick }) => (
    <Button mdIcon={ isOpen ? 'remove' : 'add'} onClick={onClick} flat modifierClass="u-padding-horizontal-none">
        {title}
    </Button>
);

CollapseTrigger.propTypes = {
    title: PropTypes.string.isRequired,
    /**
     * Indikoi ikonissa onko sisältö avattuna.
     */
    isOpen: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

CollapseTrigger.defaultProps = {
    isOpen: false,
};

export default CollapseTrigger;
