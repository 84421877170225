import React from 'react';
import PropTypes from 'prop-types';
import MDIcon from 'shared/components/MDIcon';
import classNames from 'classnames';

import {sizes} from 'shared/constants';

function Overlay({isVisible = false,}) {
    return isVisible ? <div className="o-pin o-pin--left o-pin--right o-pin--bottom o-pin--top o-overlay" /> : null;
}

Overlay.propTypes = {
    isVisible: PropTypes.bool,
};

export default Overlay;