import React from 'react';
import PropTypes from 'prop-types';
import { BooleanValue } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Summary/shared/BooleanValue';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';

/**
 * Kerrytetäänkö vapaita.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const HolidayGeneration = (props) => {
    const { value, name, label } = props;
    if (props?.attributes?.isHidden) return null;

    return (
        <SummaryListItem term={label}>
            <BooleanValue name={name} value={value} />
        </SummaryListItem>
    );
};

HolidayGeneration.propTypes = {
    // eslint-disable-next-line react/boolean-prop-naming
    value: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    attributes: PropTypes.object.isRequired,
};

export default HolidayGeneration;
