import React from 'react';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Feedback } from 'shared/components';

const SalaryFeedback = () => {
    const contract = useSelector(select.contract.getContractData);
    const hasSalaryChanged = contract?.hasSalaryChanged;
    const isDraftContract = useSelector(select.contract.isDraftContract);
    const isAssignmentContract = useSelector(select.contract.isAssignmentContract);
    const isInitialSalaryResolved = useSelector(select.contract.isInitialSalaryResolved);
    const isLaterThanInitialSalaryResolvable = useSelector(select.contract.isLaterThanInitialSalaryResolvable);

    if (! hasSalaryChanged || isDraftContract) {
        return null;
    }

    // Jos non-oph sopimus niin menee vanhalla logiikalla eli näytetään aina initial
    if (! isAssignmentContract) {
        return (
            <li className="o-summary-list__item">
                <div className="u-visibility-screen">
                    <Feedback message={_trans('contract.form.salary.summary_initial_salary_info')} type="info" modifierClass="u-margin-bottom-small" />
                </div>
            </li>
        );
    }

    // Jos initialsalaaria myöhempää nykyhetkenä voimassaolevaa palkkiota ei ole niin ei näytetä mitään feedbackia.
    if (! isLaterThanInitialSalaryResolvable) {
        return null;
    }

    // Parsitaan seuraava url näin, koska postfixi vaikuttaa vaan näytettävään salaariin
    const urlPostfix = isInitialSalaryResolved ? '' : '?resolveContractInitialSalary=1';
    const currentUrl = new URL(window.location.href);
    const nextUrl = currentUrl.origin + currentUrl.pathname + urlPostfix;

    const feedbackMessage = isInitialSalaryResolved
        ? _trans('Huomaa, että sopimuksen yhteenvedossa näytetään sopimusversion alkuperäiset palkkiotiedot.', {}, 'jobContract')
        : _trans('Huomaa, että sopimuksen yhteenvedossa näytetään tällä hetkellä voimassaolevat palkkiotiedot.', {}, 'jobContract');
    const linkMessage = isInitialSalaryResolved
        ? _trans('Näytä ajantasaiset tiedot', {}, 'jobContract')
        : _trans('Näytä alkuperäiset tiedot', {}, 'jobContract');

    return (
        <li className="o-summary-list__item">
            <div className="u-visibility-screen">
                <Feedback
                    type="info"
                    modifierClass="u-margin-bottom-small"
                >
                    {feedbackMessage}
                    &nbsp;<a href={nextUrl}>{linkMessage}</a>.
                </Feedback>
            </div>
        </li>
    );
};

export default SalaryFeedback;
