import { fromJS, List } from 'immutable';
import _ from 'lodash';
import api from 'api';
import { actions as notificationActions } from 'shared/stores/notifications';

/**
 * Vakuutusyhtiöiden malli ja actionit.
 */
export default {
    state: fromJS({
        accidentInsurance: [],
        tyelInsurance: [],
    }),

    reducers: {
        setAccidentInsuranceInstitutions: (state, payload) => state.set('accidentInsurance', List(payload)),
        setTyelInsuranceInstitutions: (state, payload) => state.set('tyelInsurance', List(payload)),
    },

    selectors: {
        getAccidentInsuranceInstitutions: (state) => state.get('accidentInsurance'),
        getTyelInsuranceInstitutions: (state) => state.get('tyelInsurance'),
    },

    effects: (dispatch) => ({
        /**
         * Haetaan tapaturmavakuutusyhtiöt.
         */
        async fetchAccidentInsuranceInstitutions() {
            try {
                const response = await api
                    .get(Routing.generate('api_1_get_accident'));

                // Filtteröidään "Tuntematon" pois
                const insuranceInstitutions = _.filter(_.get(response, 'data', []), (institution) => _.get(institution, 'code', null) !== 99);
                this.setAccidentInsuranceInstitutions(_.sortBy(insuranceInstitutions, 'name'));
            } catch (error) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('insurances.notifications.error.accident_insurance_request_failed')
                }));
            }
        },

        /**
         * Haetaan eläkevakuutusyhtiöt.
         */
        async fetchTyelInsuranceInstitutions() {
            try {
                const response = await api
                    .get(Routing.generate('api_1_get_tyel'));

                const institutions = _.filter(_.get(response, 'data', []), (institution) => _.get(institution, 'code', null) !== 1);
                this.setTyelInsuranceInstitutions(_.sortBy(institutions, 'name'));
            } catch (error) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('insurances.notifications.error.tyel_insurance_request_failed')
                }));
            }
        },
    }),
};

const getAccidentInsuranceInstitutions = (state) => state.insurances.getIn(['institutions', 'accidentInsurance']);
const getTyelInsuranceInstitutions = (state) => state.insurances.getIn(['institutions', 'tyelInsurance']);


export const selectors = {
    getAccidentInsuranceInstitutions,
    getTyelInsuranceInstitutions,
};
