import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Value } from 'shared/components/Value';
import { DateInputField, FormField, HiddenField } from 'shared/components/Formik';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { isValidDate } from 'shared/utils/dateUtils';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import Switch from 'shared/components/Switch';
import FormikErrors from 'shared/components/Formik/FormikErrors';
import { HelpText } from 'shared/components';

/**
 * Voimassaolon alkupäivä.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const StartDate = (props) => {
    const { name } = props;
    const [field,, helpers] = useField(name);
    const [originalStartDateField,,originalStartDateHelpers] = useField(fieldNames.ORIGINAL_START_DATE);
    const originalStartDate = new Date(originalStartDateField.value);
    const dispatch = useDispatch();
    const [collectiveAgreementField] = useField(fieldNames.COLLECTIVE_AGREEMENT);
    const collectiveAgreementId = collectiveAgreementField.value;

    const isAcceptedContract = useSelector(select.contract.isAcceptedContract);
    const currentTableSalaryDate = useSelector(select.contract.getTableSalaryDate);
    const [dataStartDateField] = useField(fieldNames.DATA_START_DATE);
    // const isAcceptedContract = useSelector(select.contract.isAcceptedContract);
    // const storeStartDate = useSelector(select.contract.getStartDate);

    const startDate = useMemo(() => isValidDate(new Date(field.value))
        ? new Date(field.value)
        : null
    , [field.value]);

    const hasTableSalaries = useSelector(select.contract.hasTableSalaries);

    // Switch päällä jos päivämäärät samat ja validi.
    const isSameDate = (field.value === originalStartDateField.value && isValidDate(originalStartDate));
    const [isSwitchOn, setIsSwitchOn] = useState(isSameDate);

    // Jos ei ole uusi soppari taulukkoliksat haetaan datastartdatesta
    const tableSalaryDate = ! isAcceptedContract
        ? field.value
        : dataStartDateField.value ?? field.value;

    useEffect(() => {
        const currentTableSalaryObj = new Date(currentTableSalaryDate ?? null);
        const tableSalaryDateObj = new Date(tableSalaryDate);

        if (startDate !== null) {
            dispatch.contract.setContractStartDate(field.value);
        }

        if (hasTableSalaries && isValidDate(tableSalaryDateObj)) {
            // Taulukkoliksa on validi ja eroaa annetusta juuri valitusta pvm:stä => haetaan liksat
            if (isValidDate(currentTableSalaryObj)) {
                dispatch.contract.setTableSalaryDate(tableSalaryDate);
                dispatch.tableSalary.fetchTableSalaryMetadata(collectiveAgreementId, tableSalaryDate).then((salaryMetadata) => {
                    // Metadataa ei löytynyt. Pukkaa virhe kentälle.
                    // TODO: Tämähän ei säily jos yrittää submitata. Pitäis laitella
                    // validaatioon itsessään.
                    if (! salaryMetadata) {
                        const errorMessage = _trans('Taulukkopalkkaa ei löytynyt päivämäärällä %date%. Vaihda päivämäärä.', {
                            date: _toLocaleDate(tableSalaryDate)
                        }, 'jobContract');

                        if (isSwitchOn) {
                            originalStartDateHelpers.setError(errorMessage);
                        } else {
                            helpers.setError(errorMessage);
                        }
                    }
                });
            }
        }
    }, [tableSalaryDate, currentTableSalaryDate, collectiveAgreementId, field.value, dataStartDateField.value, hasTableSalaries, isSwitchOn]);

    useEffect(() => {
        // Jos alkuperäinen aloituspäivä meni yli Oimassa aloituspäivän => nollataan aloituspäivä
        if (startDate !== null && originalStartDate > startDate) {
            helpers.setValue('');
        }

        // Alkup. aloituspäivää tutkitaan tässä vain jos switch päällä
        if (isValidDate(originalStartDate) && isSwitchOn) {
            helpers.setValue(originalStartDateField.value);
        }
    }, [originalStartDateField.value, isSwitchOn]);

    // Tarkistetaan että on oikeasti Date-objekti ennen kuin lisätään mitään
    const minimumDate = (originalStartDateField.value && isValidDate(originalStartDate))
        ? originalStartDate
        : null;

    // Hyväksytyn sopimuksen aloituspäivää ei voi enää muuttaa
    if (isAcceptedContract) {
        return (
            <FormField {...getFieldProps(props)} isContentFormField={false}>
                <Value ariaDescribedBy="fixedStartDate">
                    {_toLocaleDate(field.value)}
                </Value>
                <FormikErrors name={name} />
                <HelpText text={_trans('Voimassa olevan sopimuksen käyttöönottopäivää ei voi enää muuttaa.', {}, 'jobContract')} id="fixedStartDate" />
            </FormField>
        );
    }

    return (
        <FormField {...getFieldProps(props)} isContentFormField={false} name={name} canShowErrors>
            <div aria-describedby={name}>
                {/* Näytetään vain jos alkup. aloituspvm annettu */}
                {originalStartDateField.value && isValidDate(originalStartDate) && (
                    <Switch
                        id="isSameAsOriginalStartDate"
                        modifierClass="u-margin-bottom-small"
                        isOn={isSwitchOn}
                        onChange={(isOn) => {
                            setIsSwitchOn(isOn);
                            if (isValidDate(originalStartDate) && isOn) {
                                // originaaliksi
                                helpers.setValue(
                                    moment(originalStartDate).format('YYYY-MM-DD')
                                );
                            } else {
                                helpers.setValue('');
                            }
                        }}
                    >
                        {_trans('Sama kuin alkuperäinen aloituspäivä', {}, 'jobContract')}
                    </Switch>
                )}
                {isSwitchOn
                    ? <HiddenField name={name} value={originalStartDateField.value} />
                    : (
                        <DateInputField
                            name={name}
                            minimumDate={minimumDate}
                        />
                    )}
                <FormikErrors name={name} />
            </div>
        </FormField>
    );
};

StartDate.propTypes = {
    name: PropTypes.string.isRequired,
};

export default StartDate;
