import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'shared/components/index';

export const FreshdeskGuideLink = ({ articleId }) => {
    if (! articleId) {
        throw new Error('articleId missing');
    }

    return (
        <Button
            flat
            mdIcon="help_outline"
            title={_trans('text.open_guide')}
            onClick={() => {
                if (typeof FreshworksWidget !== 'function') {
                    return;
                }

                FreshworksWidget('open', 'article', {
                    id: articleId
                });
            }}
        >
            {_trans('link.guide')}
        </Button>
    );
};

FreshdeskGuideLink.propTypes = {
    articleId: PropTypes.number.isRequired,
};
