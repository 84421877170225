import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const noop = () => {};

export const pageSizeOptions = [10, 20, 30, 40, 50];

export const Pagination = (props) => {
    const {
        canPrevious,
        previousText,
        canNext,
        nextText,

        ofText,
        rowsText,
        page,
        pages,
        pageSize,
        pageSizeOptions,
        pageText,
        onPageChange,
        onPageSizeChange,
    } = props;

    const onSelectPageSizeChange = (event) => {
        const pageSize = parseInt(event.target.value, 10);
        onPageSizeChange(pageSize);
    };

    const onSelectPageChange = (event) => {
        const page = parseInt(event.target.value, 10);
        onPageChange(page);
    };

    return (
        <Fragment>
            <select
                onBlur={onSelectPageSizeChange}
                onChange={onSelectPageSizeChange}
                value={pageSize}
                aria-label={_trans('Tuloksia sivulla', {}, 'common')}
            >
                {pageSizeOptions.map((pageSizeOption) => (
                    <option value={pageSizeOption} key={`paginationOption${pageSizeOption}`}>
                        {`${pageSizeOption} ${rowsText}`}
                    </option>
                ))}
            </select>
            <span className="u-margin-left u-margin-right">
                {pageText}
                <select
                    onChange={onSelectPageChange}
                    onBlur={onSelectPageChange}
                    className="u-margin-left-tiny u-margin-right-tiny"
                    value={page}
                    aria-label={`${pageText} ${page + 1} ${ofText} ${pages}`}
                >
                    {Array.from({ length: pages }, (_, page) => (
                        <option value={page} key={`page${page}`}>
                            {page + 1}
                        </option>
                    ))}
                </select>
                {`${ofText} ${pages}`}
            </span>
            <Button
                flat
                disabled={! canPrevious}
                mdIcon="first_page"
                onClick={() => {
                    if (page > 0) {
                        onPageChange(0);
                    }
                }}
                title={_trans('Ensimmäinen sivu', {}, 'common')}
            />
            <Button
                flat
                disabled={! canPrevious}
                mdIcon="arrow_back"
                modifierClass="u-margin-left-tiny"
                onClick={() => {
                    if (page > 0) {
                        onPageChange(page - 1);
                    }
                }}
                title={_trans(previousText)}
            />
            <Button
                flat
                disabled={! canNext}
                mdIcon="arrow_forward"
                modifierClass="u-margin-left-tiny"
                onClick={() => {
                    if (page < pages) {
                        onPageChange(page + 1);
                    }
                }}
                title={_trans(nextText)}
            />
            <Button
                flat
                disabled={! canNext}
                mdIcon="last_page"
                modifierClass="u-margin-left-tiny"
                onClick={() => {
                    if (page < pages - 1) {
                        onPageChange(pages - 1);
                    }
                }}
                title={_trans('Viimeinen sivu', {}, 'common')}
            />
        </Fragment>
    );
};

Pagination.propTypes = {
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    canNext: PropTypes.bool.isRequired,
    canPrevious: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string,
    ofText: PropTypes.string,
    rowsText: PropTypes.string,
    pageText: PropTypes.string,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number.isRequired),
};

Pagination.defaultProps = {
    onPageChange: noop,
    onPageSizeChange: noop,
    previousText: _trans('Edellinen', {}, 'common'),
    nextText: _trans('Seuraava', {}, 'common'),
    ofText: '/',
    rowsText: _trans('riviä', {}, 'common'),
    pageText: _trans('Sivu', {}, 'common'),
    pageSizeOptions,
};
