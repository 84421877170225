/**
 * Sallitut custom eventit. Lisää tarvittavat.
 */

export const customEvents = {
    BADGE_UPDATE: 'customBadgeUpdate',
    UPDATE_NOTIFICATIONS: 'customUpdateNotifications',
};

/**
 * Shortcut custom eventeille.
 * @param customEventName
 * @param detail
 */
export const dispatchCustomEvent = (customEventName, detail) => {
    if (! customEventName.startsWith('custom')) {
        console.error('Custom events must start with "custom"');
        return false;
    }

    if (! Object.values(customEvents).includes(customEventName)) {
        console.error('Invalid custom event name. Please check customEvents.js.');
    }

    document.dispatchEvent(
        new CustomEvent(customEventName, { detail })
    );
};
