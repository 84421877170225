export const contractDataStates = {
    MASTER_VERSION: 1,
    ACCEPTED_PROPOSAL: 2,
    PROPOSAL: 3,
    ARCHIVED: 4,
    REJECTED_PROPOSAL: 5,
    DRAFT: 6,
    CANCELED_PROPOSAL: 7,
    UNFINISHED: 98,
};

const contractDataStateMap = {
    [contractDataStates.MASTER_VERSION]: _trans('contract.version_state.master'),
    [contractDataStates.ACCEPTED_PROPOSAL]: _trans('contract.version_state.accepted_proposal'),
    [contractDataStates.PROPOSAL]: _trans('contract.version_state.proposal'),
    [contractDataStates.ARCHIVED]: _trans('contract.version_state.archived'),
    [contractDataStates.REJECTED_PROPOSAL]: _trans('contract.version_state.rejected_proposal'),
    [contractDataStates.DRAFT]: _trans('contract.version_state.draft'),
    [contractDataStates.CANCELED_PROPOSAL]: _trans('contract.version_state.canceled_proposal'),
    [contractDataStates.UNFINISHED]: _trans('Keskeneräinen', {}, 'JobContract'),
};

export function translateContractDataState(contractDataState) {
    return contractDataState in contractDataStateMap ? contractDataStateMap[contractDataState] : '';
}

const contractDataUiState = {
    [contractDataStates.MASTER_VERSION]: 'positive',
    [contractDataStates.ACCEPTED_PROPOSAL]: 'yellow',
    [contractDataStates.PROPOSAL]: 'yellow',
    [contractDataStates.ARCHIVED]: 'hollow',
    [contractDataStates.REJECTED_PROPOSAL]: 'negative',
    [contractDataStates.DRAFT]: 'neutral',
    [contractDataStates.CANCELED_PROPOSAL]: 'negative',
    [contractDataStates.UNFINISHED]: 'neutral',
};

export function mapContractDataStateToUi(contractDataState) {
    return contractDataState in contractDataUiState ? contractDataUiState[contractDataState] : '';
}
