import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Hampurilaisnappi.
 * @param isOpen
 * @param onClick
 * @returns {*}
 * @constructor
 */
const HamburgerButton = ({ isOpen, onClick, ...rest }) => (
    <button
        className={classNames('c-hamburger c-hamburger--squeeze', { 'is-active': isOpen })}
        id="hamburgerButton"
        role="menuitem"
        onClick={onClick}
        title={_trans('menubar.label')}
        {...rest}
    >
        <span className="c-hamburger-box" aria-hidden="true">
            <span className="c-hamburger-inner" />
        </span>
    </button>
);

HamburgerButton.propTypes = {
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
};

HamburgerButton.defaultProps = {
    isOpen: false,
    onClick() {},
};

export default HamburgerButton;
