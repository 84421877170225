import createUiFlags from 'shared/utils/createUiFlags';
/**
 * UI-vipujen käpistely.
 */
const initialState = {
    userContracts: {
        isLoading: false,
        isSaving: false,
        hasError: false,
    },
    employerBenefitDecisions: {
        isLoading: false,
        isSaving: false,
        hasError: false,
    },
    employeeWorkExperience: {
        isLoading: false,
        isSaving: false,
        editMode: false,
    },
    passwordChange: {
        isSaving: false,
    }
};

const uiModel = createUiFlags(initialState);

export default uiModel;
