import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Chip from './Chip';

/**
 * Renderöi useamman chipin.
 *
 * @param items
 * @param itemAsValue
 * @param itemValueKey
 * @param itemLabelKey
 * @param itemMap
 * @param matchValueToKey
 * @param onRemove
 * @param onToggle
 * @param isToggleable
 * @param hasRunningNumbers
 * @param defaultSelected
 * @returns {*}
 * @constructor
 */
function ChipStack({
    items,
    itemAsValue,
    itemValueKey,
    itemLabelKey,
    itemMap,
    matchValueToKey,
    onRemove,
    onToggle,
    isToggleable,
    hasRunningNumbers,
    defaultSelected,
}) {
    const [selected, setSelected] = useState(defaultSelected);
    return (
        <span>
            {(Array.isArray(items) ? items : [items]).map((item, key) => {
                let text = item;

                if (itemAsValue === false) {
                    if (itemMap.length > 0) {
                        // Map item to itemMap by itemValueKey
                        if (matchValueToKey) {

                            const matchedItem = _.find(itemMap, (itemMapItem) => itemMapItem[itemValueKey] === item);
                            if (!_.isUndefined(matchedItem)) {
                                text = matchedItem[itemLabelKey];
                            } else {
                                text = null;
                            }
                        } else {
                            text = itemMap.find((itemMapItem) => itemMapItem[itemValueKey] === item[itemValueKey])[itemLabelKey];
                        }
                    } else if (itemLabelKey !== '') {
                        text = item[itemLabelKey];
                    }
                }
                if (hasRunningNumbers) {
                    text = `${key + 1}. ${text}`;
                }

                if (text !== null) {
                    return (
                        <span className="u-margin-right-tiny u-margin-top-tiny u-display-inline-block" key={key}>
                            <Chip
                                text={text}
                                isRemovable={!isToggleable}
                                onRemove={onRemove.bind(null, key)}
                                isSelected={selected.includes(item[itemValueKey])}
                                onToggle={() => {
                                    const selectedId = item[itemValueKey];
                                    const selectedChips = ! selected.includes(selectedId)
                                        ? selected.concat(selectedId)
                                        : selected.filter((id) => id !== selectedId);

                                    setSelected(selectedChips);
                                    onToggle(selectedChips);
                                }}
                            />
                        </span>
                    );
                }
            })}
        </span>
    );
}

ChipStack.defaultProps = {
    itemAsValue: false,
    itemValueKey: 'id',
    itemLabelKey: 'name',
    itemMap: [],
    matchValueToKey: false,
    onRemove: () => {},
    onToggle: () => {},
    defaultSelected: [],
    isToggleable: false,
    hasRunningNumbers: false,
};

ChipStack.propTypes = {
    /**
     * Data of the chips.
     */
    items: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array, PropTypes.object]).isRequired,
    /**
     * Key of the object.
     */
    itemValueKey: PropTypes.string,
    /**
     * Label of the object.
     */
    itemLabelKey: PropTypes.string,
    itemMap: PropTypes.array,
    /**
     * Should the whole item used as a value or just the key.
     */
    // eslint-disable-next-line react/boolean-prop-naming
    itemAsValue: PropTypes.bool,
    // eslint-disable-next-line react/boolean-prop-naming
    matchValueToKey: PropTypes.bool,
    /**
     * Returns the index of the chip in the stack when the remove button is pressed.
     */
    onRemove: PropTypes.func,
    onToggle: PropTypes.func,
    isToggleable: PropTypes.bool,
    defaultSelected: PropTypes.array,
    /**
     * Should the chips have running numbers added in front of their labels.
     */
    hasRunningNumbers: PropTypes.bool,
};

export default ChipStack;
