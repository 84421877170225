import { useEffect, useCallback } from 'react';

export const useOnClickOutside = (ref, handler) => {
    const memoizedHandler = useCallback((event) => handler(event), [handler]);
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                memoizedHandler(event);
            };
            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        [ref, memoizedHandler]
    );
};
