import React from 'react';
import PropTypes from 'prop-types';
import logoSrc from 'images/oima-logo.svg';

/**
 * Oiman logo SVG:nä.
 * TODO: Vaihda itse logo.
 * @param size
 * @param props
 * @returns {*}
 * @constructor
 */
export const Logo = ({ size, ...props }) => <img src={logoSrc} alt="Oima-logo" height={size} {...props} />;

Logo.defaultProps = {
    size: 32,
};

Logo.propTypes = {
    size: PropTypes.number,
};

export default Logo;
