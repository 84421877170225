import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import { useParams } from 'react-router-dom';
import benefitDecisionRoutes from 'Commune/BenefitDecision/routes';
import UserBlock from 'shared/components/UserBlock';
import { FormField } from 'shared/components/Formik';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import resolveUrl from 'shared/utils/resolveUrl';
import { generateRoutes } from 'ContractV3/constants/routes';
import { isCommune } from 'shared/utils/commonUtils';

const routes = generateRoutes(isCommune());

/**
 * Työnantaja. Fiksattu.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const Employer = (props) => {
    const { name } = props;
    const dispatch = useDispatch();
    const [field,,helpers] = useField(name);
    const benefitDecisionId = useSelector(select.contract.getBenefitDecisionId);
    const paramBenefitDecisionId = useParams()?.benefitDecisionId;
    const activeBenefitDecisions = useSelector(select.benefitDecisions.getActiveBenefitDecisions);
    const isNewContract = useSelector(select.contract.isNewContract);
    const isCloningContract = useSelector(select.contract.isCloningContract);
    const isBuildMode = useSelector(select.contract.isBuildMode);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isEmployer = useSelector(select.userMetadata.isEmployer);
    const allowAssignmentContracts = useSelector(select.userMetadata.allowAssignmentContracts);
    const resolvedBenefitDecisionId = useMemo(() => {
        if (isBuildMode) return activeBenefitDecisions[0]?.id;
        return (isNewContract && ! isCloningContract)
            ? paramBenefitDecisionId
            : benefitDecisionId;
    }, [isBuildMode, paramBenefitDecisionId, benefitDecisionId, activeBenefitDecisions, isNewContract, isCloningContract]);

    // Rakentelumoodissa haetaan vaan eka aktiivinen asiakkuus
    const isLoading = useSelector((state) => allowAssignmentContracts
        ? state.loading.effects.loggedUser.fetchUserData
        : state.loading.effects.benefitDecision.fetchBenefitDecision);

    const isLoadingActiveBenefitDecisions = useSelector((state) => state.loading.effects.benefitDecisions.fetchActiveBenefitDecisions);

    // Muutetaan myöhemmin queryn kautta haettavaksi
    const employer = useSelector(resolvedBenefitDecisionId
        ? select.contract.getEmployer
        : select.loggedUser.getUser);

    useEffect(() => {
        if (allowAssignmentContracts || isLoadingActiveBenefitDecisions) return;

        const effectName = isEmployer
            ? 'fetchBenefitDecisionForEmployer'
            : 'fetchBenefitDecision';

        dispatch.benefitDecision[effectName](resolvedBenefitDecisionId)
            .then((response) => {
                const decision = response?.decision;
                if (! decision) {
                    dispatch(push(benefitDecisionId && ! isEmployer
                        ? resolveUrl(benefitDecisionRoutes.BENEFIT_DECISION_SUMMARY, { id: benefitDecisionId })
                        : routes.CONTRACT_HOME));
                }

                dispatch.contract.setBenefitDecisionId(decision?.id);
                dispatch.contract.setEmployer({
                    userId: decision?.employer?.userId,
                    initials: decision?.employer?.initials,
                    fullName: decision?.employer?.fullName,
                    socialSecurityNumber: decision?.employer?.socialSecurityNumber,
                    isUserIdentified: decision?.isEmployerIdentified ?? false,
                });
                dispatch.contract.setBeneficiary({
                    userId: decision?.beneficiary?.userId,
                    initials: decision?.beneficiary?.initials,
                    fullName: decision?.beneficiary?.fullName,
                    socialSecurityNumber: decision?.beneficiary?.socialSecurityNumber,
                    isSelfEmployed: decision?.beneficiaryIsEmployer ?? false,
                    isUserIdentified: decision?.isBeneficiaryIdentified ?? false,
                });
            });
    }, [allowAssignmentContracts, paramBenefitDecisionId, activeBenefitDecisions, isBuildMode, isLoadingActiveBenefitDecisions, isEmployer]);

    useEffect(() => {
        const userId = employer?.userId;
        if (!isLoading && !field.value && userId) {
            helpers.setValue(userId);
        }
    }, [isLoading, employer, helpers, field.value, isBuildMode]);

    return (
        <FormField {...getFieldProps(props)} isContentFormField={false}>
            <UserBlock
                user={employer ?? {}}
                isLoading={isLoading || (isBuildMode && isLoadingActiveBenefitDecisions || ! employer)}
                size="small"
                hasLinkToDetails={isCommune}
                relationType="employer"
            />
        </FormField>
    );
};

Employer.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Employer;
