import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { actions, Control, LocalForm } from 'react-redux-form';
import _ from 'lodash';
import Layout from 'shared/components/Layout';
import { StField, StLabel } from 'shared/components/StForm';
import request from 'BubbleWrapAgent';
import SubmitButton from 'shared/components/SubmitButton';
import Button from 'shared/components/Button';
import ActionBar from 'shared/components/ActionBar';

/**
 * Näytetään käyttäjän tiedoissa dimensiohinnoitteluun liittyviä kenttiä
 * tällä hetkellä näyttää vain yhden tuntihinnan mutta myöhemmin varmaan tulee
 * työaikatyyppiin sidottuja hintoja
 */
export default class DimensionPricing extends Component {

    static defaultProps = {
        isReadOnly: true,
        isCompany: false,
        isEmployee: false,
        isDimensionPricingEnabled: false,
        onSubmitFailed: () => {},
    };

    static propTypes = {
        user: PropTypes.object.isRequired,
        isReadOnly: PropTypes.bool,
        isCompany: PropTypes.bool,
        isEmployee: PropTypes.bool,
        isDimensionPricingEnabled: PropTypes.bool,
        onError: PropTypes.func.isRequired,
        onUserUpdate: PropTypes.func.isRequired,
        onSubmitFailed: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            isEditMode: false,
            isLoading: false,
            isReadOnly: props.isReadOnly,
        };
    }

    updateUserAttributes(data) {
        this.setState({ isLoading: true });

        request
            .patch(Routing.generate('api_1_patch_user', { user: this.state.user.userId }))
            .send({ data })
            .end((error, response) => {
                const user = _.cloneDeep(this.state.user);
                if (error) {
                    this.props.onError(response.body.message ? response.body.message : _trans('form.save.error'));
                } else {
                    this.setAttributes(data, user);
                }
                this.setState({ isLoading: false, isEditMode: false });
            });
    }

    setAttributes(data, user) {

        if (_.isEmpty(user.exposedAttributes) && _.isArray(user.exposedAttributes)) {
            user.exposedAttributes = _.reduce(data, (result, datum) => {
                result[datum.path] = datum.value;
                return result;
            }, {});
        } else {
            _.each(data, (datum) => {
                user.exposedAttributes[datum.path] = datum.value;
            });
        }

        this.setState({ user });
        //pävitetään mahdolliseen storeen pävitetty data
        this.props.onUserUpdate(user);
    }

    onSubmit(model) {
        const data = _.map(model, (value, key) => ({
            op: 'replace',
            path: key,
            value,
        }));

        this.updateUserAttributes(data);
    }

    onSubmitFailed(model) {
        this.props.onSubmitFailed(model);
    }

    onEnableEditMode = (event) => {
        event.preventDefault();
        this.setState({ isEditMode: true });
    };

    onDisableEditMode = (event) => {
        event.preventDefault();
        this.setState({ isEditMode: false });
        this.formDispatch(actions.reset('dimensionPricing'));
    };

    renderActionButtons() {
        const {
            isEditMode,
        } = this.state;

        const SaveButton = () => (
            <SubmitButton
                primary
                isPending={this.state.isLoading}
            >
                Tallenna
            </SubmitButton>
        );

        const EditButton = () => (
            <Button
                onClick={this.onEnableEditMode}
                mdIcon="edit"
            >
                Muokkaa
            </Button>
        );

        const CancelButton = () => (
            <Button
                onClick={this.onDisableEditMode}
                disabled={this.state.isLoading}
            >
                {_trans('users.action.cancel')}
            </Button>
        );

        return (
            <div className="u-padding-top-small u-padding-top@medium">
                <ActionBar alignItems={ isEditMode ? '' : 'right' }>
                    { !isEditMode && <EditButton/> }
                    { isEditMode && <CancelButton/> }
                    { isEditMode && <SaveButton/> }
                </ActionBar>
            </div>
        );
    }

    render() {
        const {
            isCompany,
            isEmployee,
            isDimensionPricingEnabled,
            user,
        } = this.props;

        if (! user || ! isDimensionPricingEnabled || ! isCompany || ! isEmployee) {
            return null;
        }

        const initialState = {
            dimensionPricingHourValue: _.get(user, 'exposedAttributes.dimensionPricingHourValue'),
        };

        return (
            <LocalForm
                model="dimensionPricing"
                initialState={initialState}
                className="o-form o-form--vertical o-form--small"
                onSubmit={this.onSubmit.bind(this)}
                onSubmitFailed={this.onSubmitFailed.bind(this)}
                getDispatch={ (dispatch) => this.formDispatch = dispatch }
            >
                <fieldset className="u-margin-top-small">
                    <Layout.Container verticalGutter gutterSize="small">
                        <legend>Dimensiohinnoittelu</legend>
                        <Layout.Item>
                            <StLabel htmlFor="dimensionPricingHourValue">
                                Työntekijän työtunnin arvo dimensiohinnoittelua varten (€/h)
                            </StLabel>
                            <StField>
                                <Control.text
                                    model=".dimensionPricingHourValue"
                                    parser={(value) => String(value || '').replace(',', '.')}
                                    formatter={(value) => String(value || '').replace('.', ',')}
                                    readOnly={!this.state.isEditMode}
                                />
                            </StField>
                        </Layout.Item>
                    </Layout.Container>
                </fieldset>
                {this.renderActionButtons()}
            </LocalForm>
        );
    }
}
