import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { UserAttachedDimensionsForm } from './UserAttachedDimensionsForm';
import { Page } from 'shared/components';

export const UserAttachedDimensions = () => {
    const { userId } = useParams();
    const visibilityDimensionTypeName = useSelector(select.dimensions.getVisibilityDimensionTypeName);
    return (
        <Page
            heading={visibilityDimensionTypeName}
        >
            <UserAttachedDimensionsForm userId={parseInt(userId, 10)}>
                {(dimSelector) => (
                    <Fragment>
                        <p>{_trans('Valitse mihin ryhmiin henkilö liitetään. Jos yhtään ryhmää ei ole valittuna ei henkilö näy jatkossa enää Henkilöt-listailla. Tiedot ovat kuitenkin tallessa Oima-palvelussa ja henkilö voidaan lisätä uudestaan henkilötunnuksella.', {}, 'common')}</p>
                        {dimSelector}
                    </Fragment>
                )}
            </UserAttachedDimensionsForm>
        </Page>
    );
};
