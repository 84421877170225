import React, { Fragment } from 'react';
import { Field } from 'formik';
import { StField, StLabel } from 'shared/components/index';
import { FormikErrors } from 'shared/components/Formik/index';

const FirstName = () => (
    <Fragment>
        <StLabel htmlFor="firstName">
            {_trans('userForm.firstName.label')}
        </StLabel>
        <StField>
            <Field type="text" name="firstName" id="firstName" className="u-1/1" />
            <FormikErrors name="firstName"/>
        </StField>
    </Fragment>
);

export default FirstName;
