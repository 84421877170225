import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LocalForm, Control } from 'react-redux-form';
import Layout from 'shared/components/Layout';
import { StLabel, StErrors } from 'shared/components/StForm/';
import SubmitButton from 'shared/components/SubmitButton';
import Dropdown from 'shared/components/Dropdown';
import { errorMessages, validators } from 'shared/validators';
import { DateInput } from 'shared/components';
import { AutoCompleteVirtualized } from 'shared/containers/index';
import RadioList from 'shared/components/RadioList';
import { MonthInput } from 'shared/components/MonthInput';

export default class ParameterCollector extends Component {

    constructor(props) {
        super(props);

        // Jos parametrien ja initial valueiden length on sama, niin ammutaan haku samantien.
        if (_.get(props, 'parameters', []).length === Object.keys(props.initialValues).length) {
            if (typeof props.onSubmit === 'function') {
                props.onSubmit(props.initialValues);
            }
        }

        this.state = {
            formModel: {},
        };
    }

    onSubmitForm(data) {
        if (this.props.onSubmit) {
            this.props.onSubmit(data);
        } else {
            const url = Routing.generate(this.props.targetUrl,
                _.assign({},
                    { filename: this.props.filename },
                    data
                )
            );
            window.location.assign(url);
        }
    }

    getValidations(validationKeys) {
        let validationObject = {};
        validationKeys.forEach((validationKey) => {
            if (_.has(validators, validationKey)) {
                validationObject = { ...validationObject, [validationKey]: validators[validationKey] };
            }
        });
        return validationObject;
    }

    renderTextInput(parameter) {
        const validations = _.get(parameter, 'validations', []);
        return (
            <Layout.Item small="5/12" key={parameter.name}>
                <StLabel>{_.get(parameter, 'label', 'Label puuttuu!')}</StLabel>
                <Control.text
                    model={`.${parameter.name}`}
                    className="u-1/1"
                    validators={this.getValidations(validations)}
                />
                <StErrors
                    model={`.${parameter.name}`}
                    messages={errorMessages}
                />
            </Layout.Item>
        );
    }

    renderIntInput(parameter) {
        const validations = _.get(parameter, 'validations', []);
        return (
            <Layout.Item small="5/12" key={parameter.name}>
                <StLabel>{_.get(parameter, 'label', 'Label puuttuu!')}</StLabel>
                <Control.text
                    model={`.${parameter.name}`}
                    className="u-1/1"
                    validators={{
                        isInteger: validators.isInteger,
                        ...this.getValidations(validations)
                    }}
                />
                <StErrors
                    model={`.${parameter.name}`}
                    messages={errorMessages}
                />
            </Layout.Item>
        );
    }

    renderChoiceInput(parameter) {
        const options = _.map(parameter.options, ({ key, value }) => ({
            id: key,
            name: value
        }));
        const validations = _.get(parameter, 'validations', []);
        const defaultValue = options?.length === 1 ? options[0].id : null;

        return (
            <Layout.Item small="5/12" key={parameter.name}>
                <StLabel>{_.get(parameter, 'label', 'Label puuttuu!')}</StLabel>
                <Control.select
                    model={`.${parameter.name}`}
                    placeholder=" "
                    component={Dropdown}
                    options={options}
                    isFullWidth
                    defaultValue={_.get(parameter, 'default', defaultValue)}
                    validators={this.getValidations(validations)}
                />
                <StErrors
                    model={`.${parameter.name}`}
                    messages={errorMessages}
                />
            </Layout.Item>
        );
    }

    renderAutoComplete(parameter) {
        const options = _.map(parameter.options, ({ key, value }) => ({
            id: key,
            name: value
        }));
        const validations = _.get(parameter, 'validations', []);

        return (
            <Layout.Item small="5/12" key={parameter.name}>
                <StLabel>{_.get(parameter, 'label', 'Label puuttuu!')}</StLabel>
                <Control.custom
                    model={`.${parameter.name}`}
                    placeholder=" "
                    component={AutoCompleteVirtualized}
                    labelKey="name"
                    valueKey="id"
                    hasSingleValue
                    items={options}
                    isFullWidth
                    defaultValue={_.get(parameter, 'default', null)}
                    validators={this.getValidations(validations)}
                    mapProps={{
                        onSelect: (props) => props.onChange,
                        value: (props) => props.modelValue,
                    }}
                />
                <StErrors
                    model={`.${parameter.name}`}
                    messages={errorMessages}
                />
            </Layout.Item>
        );
    }

    renderDateInput(parameter) {
        const validations = _.get(parameter, 'validations', []);
        return (
            <Layout.Item small="5/12" key={parameter.name}>
                <StLabel>{_.get(parameter, 'label', 'Label puuttuu!')}</StLabel>
                <Control
                    model={`.${parameter.name}`}
                    component={DateInput}
                    className="u-1/1"
                    validators={this.getValidations(validations)}
                />
                <StErrors
                    model={`.${parameter.name}`}
                    messages={errorMessages}
                />
            </Layout.Item>
        );
    }

    renderMonthInput(parameter) {
        const validations = _.get(parameter, 'validations', []);
        return (
            <Layout.Item small="5/12" key={parameter.name}>
                <StLabel>{_.get(parameter, 'label', 'Label puuttuu!')}</StLabel>
                <Control
                    model={`.${parameter.name}`}
                    component={MonthInput}
                    className="u-1/1"
                    validators={this.getValidations(validations)}
                />
                <StErrors
                    model={`.${parameter.name}`}
                    messages={errorMessages}
                />
            </Layout.Item>
        );
    }


    renderCheckbox(parameter) {
        return (
            <Layout.Item small="5/12" key={parameter.name}>
                <StLabel>
                    <Control.checkbox
                        model={'.' + parameter.name}
                    />
                    {_.get(parameter, 'label', 'Label puuttuu!')}
                </StLabel>

                <StErrors
                    model={parameter.name}
                    messages={errorMessages}
                />
            </Layout.Item>
        );
    }

    renderRadioButton(parameter) {
        const options = parameter.options.map(({ key, value }) => ({
            id: key,
            name: value
        }));

        return (
            <Layout.Item small="5/12" key={parameter.name}>
                <StLabel>{parameter.label ?? 'Label puuttuu'}</StLabel>
                <Control
                    model={'.' + parameter.name}
                    component={RadioList}
                    items={options}
                    validators={this.getValidations(parameter.validations ?? [])}
                />
                <StErrors model={'.' + parameter.name} />
            </Layout.Item>
        );
    }

    renderInputs() {
        if (_.isEmpty(this.props.parameters)) {
            return;
        }

        const inputs = this.props.parameters.map((parameter) => {
            const dependant = parameter.dependant ?? null;
            if (dependant !== null && ! this.state.formModel[dependant]) {
                return null;
            }

            switch (parameter.type) {
                // hidden-kenttää ei tarvi renderöidä ollenkaan, koska riittää, että value on rrf:n initialStatessa
                case 'hidden':
                    return null;
                case 'text':
                    return this.renderTextInput(parameter);
                case 'int':
                    return this.renderIntInput(parameter);
                case 'choice':
                    return this.renderChoiceInput(parameter);
                case 'date':
                    return this.renderDateInput(parameter);
                case 'month':
                    return this.renderMonthInput(parameter);
                case 'boolean':
                    return this.renderCheckbox(parameter);
                case 'choiceAutoComplete':
                    return this.renderAutoComplete(parameter);
                case 'radio':
                    return this.renderRadioButton(parameter);
                default:
                    return (
                        <Layout.Item small="5/12" key={parameter.name}>
                            <strong>Tuntematon parametrin tyyppi: {parameter.type}!</strong>
                        </Layout.Item>
                    );
            }
        });

        return (
            <LocalForm
                model="data"
                initialState={_.isArray(this.props.initialValues) ? {} : this.props.initialValues}
                onSubmit={(values) => this.onSubmitForm(values)}
                onSubmitFailed={this.onSubmitFailed}
                onChange={(model) => this.setState({ formModel: model })}
                className="o-form o-form--vertical"
            >
                <Layout.Container verticalGutter>
                    {inputs}
                    <Layout.Item>
                        <SubmitButton>
                            Hae
                        </SubmitButton>
                    </Layout.Item>
                </Layout.Container>
            </LocalForm>
        );
    }

    render() {
        return (
            <section className="u-max-width-medium">
                <div className="u-margin-vertical-small u-margin-vertical@medium">
                    {this.renderInputs()}
                </div>
            </section>
        );
    }
}

ParameterCollector.propTypes = {
    targetUrl: PropTypes.string,
    filename: PropTypes.string,
    parameters: PropTypes.array,
    onSubmit: PropTypes.func,
    initialValues: PropTypes.object,
};

ParameterCollector.defaultProps = {
    targetUrl: null,
    filename: null,
    parameters: [],
    onSubmit: null,
    initialValues: {},
};

