import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { ChoiceList } from 'shared/components';

export const ChoiceFieldList = ({ name, onChange, ...rest }) => {
    const [field, , helpers] = useField(name);
    return (
        <ChoiceList
            {...rest}
            {...field}
            id={name}
            ariaDescribedBy={name}
            onChange={(value) => helpers.setValue(value)}
            hasSingleInput
        />
    );
};

ChoiceFieldList.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
};

ChoiceFieldList.defaultProps = {
    name: undefined,
    onChange() {},
};

