import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { MaxTextArea } from 'shared/components/index';
// import { getFormikErrorClassName } from 'shared/utils/formUtils';

export const MaxTextAreaField = ({ maxLength, ...rest }) => {
    const name = rest.name;
    const [field, , helpers] = useField(name);
    // const errorClassName = getFormikErrorClassName(meta);

    return (
        <MaxTextArea
            {...rest}
            {...field}
            onChange={(value) => helpers.setValue(value)}
            maxLength={maxLength}
        />
    );
};

MaxTextAreaField.propTypes = {
    maxLength: PropTypes.number,
};

MaxTextAreaField.defaultProps = {
    maxLength: 250,
};
