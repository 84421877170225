import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Korttimainen linkki.
 * @param children
 * @param href
 * @param modifierClass
 * @param isTargetBlank
 * @returns {*}
 * @constructor
 */
function LinkBlock({
    children,
    href,
    modifierClass,
    isTargetBlank,
    title,
}) {
    const cardClass = classNames(
        'c-card c-card--link o-box o-box--small u-display-inline-block', modifierClass
    );

    const linkAttributes = {};
    if (isTargetBlank) {
        linkAttributes.target = '_blank';
    }

    return (
        <a
            href={href}
            className={cardClass}
            {...linkAttributes}
            title={title}
        >
            {children}
        </a>
    );
}

LinkBlock.defaultProps = {
    isTargetBlank: false,
    modifierClass: null,
    title: null,
};

LinkBlock.propTypes = {
    /**
     * Linkin osoite.
     */
    href: PropTypes.string.isRequired,
    /**
     * Avataanko linkki uuteen ikkunaan.
     */
    isTargetBlank: PropTypes.bool,
    children: PropTypes.node.isRequired,
    /**
     * CSS-muokkausluokka.
     */
    modifierClass: PropTypes.string,
    title: PropTypes.string,
};

export default LinkBlock;
