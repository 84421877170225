import { actions } from 'react-redux-form/lib/immutable';
import { fromJS } from 'immutable';
import { promised as request } from 'BubbleWrapAgent';
import { userRoles } from 'shared/constants/index';

const USER_METADATA = 'userMetadata';

/**
 *
 * initialState
 */

const userMetadata = {};

/**
 * Actions
 */

/**
 * Hakee käyttäjän oikeudet.
 */

const fetchUserMetadata = () => (dispatch, getState) => {
    const state = getState();

    //Ei haeta metadataa uudestaan jos se löytyy jo
    if (state.userMetadata.size > 0) {
        return null;
    }
    dispatch(actions.setPending(USER_METADATA, true));

    return request
        .get(Routing.generate('api_1_get_user_metadata'))
        .end((error, response) => {
            const userMetadata = _.get(response, 'body', null);

            if (error || userMetadata !== null) {
                dispatch(actions.change(USER_METADATA, fromJS(userMetadata)));
            }
            dispatch(actions.setPending(USER_METADATA, false));
        });
};

/**
 * Selectors
 */

const isUserMetadataPending = (state) => _.get(state.forms, `${USER_METADATA}.$form.pending`, false);
const getUserMetadata = (state) => state.userMetadata;
const isAdmin = (state) => getUserMetadata(state).get('isAdmin', false);
const isCommune = (state) => getUserMetadata(state).get('isCommune', false);
const isCommuneAdmin = (state) => getUserMetadata(state).get('isCommuneAdmin', false);
const isCommuneSuperUser = (state) => getUserMetadata(state).get('isCommuneSuperUser', false);
const getCommuneId = (state) => getUserMetadata(state).get('getCommuneId', -1);
const isEmployee = (state) => [userRoles.EMPLOYEE, userRoles.FAMILY_CARER].includes(getUserMetadata(state).get('userRole', 0));
const isAssignmentContract = (state) => getUserMetadata(state).get('allowAssignmentContracts', false);

export const userMetadataActions = {
    fetchUserMetadata,
};

export const selectors = {
    isUserMetadataPending,
    getUserMetadata,
    isAdmin,
    isCommune,
    isCommuneAdmin,
    isCommuneSuperUser,
    getCommuneId,
    isEmployee,

    isAssignmentContract,
};

export const initialUserMetadata = {
    userMetadata: fromJS(userMetadata),
};
