import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Avatar,
    MDSpinner,
} from 'shared/components';

/**
 * Pieni henkilökortti.
 */
export default class UserMiniCard extends PureComponent {
    static propTypes = {
        // Käytännössä APIsta tuleva käyttäjädata.
        user: PropTypes.shape({
            initials: PropTypes.string,
            picturePath: PropTypes.string,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            email: PropTypes.string,
        }).isRequired,

        onClick: PropTypes.func,
        isPending: PropTypes.bool,
    };

    static defaultProps = {
        onClick() {},
        // Onko jokin request kesken
        isPending: false,
    };

    getPicturePath = () => _.get(this.props.user, 'picturePath', '');
    getInitials = () => _.get(this.props.user, 'initials', '-');
    getFirstName = () => _.get(this.props.user, 'firstName', '-');
    getLastName = () => _.get(this.props.user, 'lastName', '-');
    getFullName = () => `${this.getLastName()} ${this.getFirstName()}`;

    /**
     * Renderöi pienen spinnerin oikeaan yläkulmaan jos halutaan indikoida että
     * jokin asia on kesken (tietojen lataus, ym.)
     * @returns {null|*}
     */
    renderSpinner = () => {
        if (! this.props.isPending) return null;

        return (
            <span className="o-pin o-pin--top o-pin--right u-padding-right-small">
                <MDSpinner
                    size="small"
                />
            </span>
        );
    };

    render() {
        const fullName = this.getFullName();

        return (
            <button
                className="c-card is-focusable c-user-mini-card u-text-center u-padding-vertical-small u-cursor-pointer u-display-inline-block"
                onClick={this.props.onClick}
            >
                {this.renderSpinner()}
                <div className="u-font-size-0 u-margin-bottom-tiny">
                    <Avatar
                        src={this.getPicturePath()}
                        fullName={fullName}
                        initials={this.getInitials()}
                    />
                </div>
                <span className="c-user-mini-card__full-name">
                    {this.getLastName()}
                    <span className="u-display-block">{this.getFirstName()}</span>
                </span>
            </button>
        );
    }
}
