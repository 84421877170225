export const payDayModes = {
    PAYDAY_FIFTH_WEEK_DAY: 1,
    PAYDAY_SEVENTH_WEEK_DAY: 2,
    PAYDAY_NTH_WEEK_DAY: 3,
    PAYDAY_COMMUNE_MONTH: 5,
    PAYDAY_COMMUNE_TWICE_A_MONTH: 6,
    PAYDAY_1527: 7,
    PAYDAY_LAST_DAY_OF_PAY_PERIOD: 10,

    /**
     * Seuraavan kuukauden viimeinen päivä. Mahdollinen vain kuukauden mittaiselle
      * palkkajaksolle.
      */
    PAYDAY_LAST_DAY_OF_NEXT_PAY_PERIOD: 11,
    PAYDAY_USER_CHOICE: 20,
    PAYDAY_IN_PAY_PERIOD: 21,
    PAYDAY_ASAP: 30,
};

const payDayModeMap = {
    [payDayModes.PAYDAY_FIFTH_WEEK_DAY]: (domain) => _trans('constant.pay_day_mode.fifth_week_day', {}, domain),
    [payDayModes.PAYDAY_SEVENTH_WEEK_DAY]: (domain) => _trans('constant.pay_day_mode.seventh_week_day', {}, domain),
    [payDayModes.PAYDAY_NTH_WEEK_DAY]: (domain) => _trans('constant.pay_day_mode.nth_week_day', {}, domain),
    [payDayModes.PAYDAY_COMMUNE_MONTH]: (domain) => _trans('constant.pay_day_mode.commune_month', {}, domain),
    [payDayModes.PAYDAY_COMMUNE_TWICE_A_MONTH]: (domain) => _trans('constant.pay_day_mode.commune_twice_a_month', {}, domain),
    [payDayModes.PAYDAY_1527]: (domain) => _trans('constant.pay_day_mode.1527', {}, domain),
    [payDayModes.PAYDAY_LAST_DAY_OF_PAY_PERIOD]: (domain) => _trans('constant.pay_day_mode.last_day_of_pay_period', {}, domain),
    [payDayModes.PAYDAY_LAST_DAY_OF_NEXT_PAY_PERIOD]: (domain) => _trans('constant.pay_day_mode.last_day_of_next_pay_period', {}, domain),
    [payDayModes.PAYDAY_USER_CHOICE]: (domain) => _trans('constant.pay_day_mode.user_choice', {}, domain),
    [payDayModes.PAYDAY_IN_PAY_PERIOD]: (domain) => _trans('constant.pay_day_mode.pay_day_in_pay_period', {}, domain),
    [payDayModes.PAYDAY_ASAP]: (domain) => _trans('constant.pay_day_mode.asap', {}, domain),
};

export function translatePayDayMode(type, domain = 'contract') {
    if (type === '') {
        return _trans('Ei valintaa', {}, 'common');
    }
    return type in payDayModeMap ? payDayModeMap[type](domain) : _trans('Tuntematon', {}, 'common');
}

/**
 * Eli missä voidaan valita itse palkkapäivä
 *
 * @type {(number)[]}
 */
export const selectablePayDayPayDayModes = [
    payDayModes.PAYDAY_IN_PAY_PERIOD,
    payDayModes.PAYDAY_USER_CHOICE,
    payDayModes.PAYDAY_NTH_WEEK_DAY,
];
