export const creditTransferPaymentStates = {
    /**
     * Maksu on luotu, mutta SEPA aineistoa ei ole vielä muodostettu.
     *
     * Tässä tilassa olevista maksuista ei välitetä automatiikassa.
     */
    STATE_DRAFT: 10,

    /**
    * Maksu on auki. Odottaa lähettämistä pankkiin.
    *
    * Käytännössä maksuaineisto on olemassa ja odottaa pankkiin lähettämistä.
    */
    STATE_WAITING: 20,

    /**
    * Maksua ollaan lähettämässä pankkiin.
    *
    * Tämä synkronointia varten lähinnä.
    */
    STATE_BEING_SENT: 30,

    /**
    * Maksuaineisto on lähetty pankkiin.
    *
    * Pankista ei ole vielä vastausta.
    */
    STATE_SENT_TO_BANK: 50,

    /**
    * Pankki on ottanut maksuaineiston hyväksytysti vastaan.
    *
    * Vanhassa maailmassa kansio olisi tässä kohtaa suljettu käsin.
    */
    STATE_BANK_ACCEPTED: 55,

    /**
     * Maksu näkyy maksettuna pankin tiliotteella.
     *
     * Eli ns. toista kautta verifioitu. Tulee viiveellä tiliotteiden mukana.
    */
    STATE_BANK_PAID: 100,
};

export const creditTransferPaymentStateAdditionalInfoMap = {
    [creditTransferPaymentStates.STATE_DRAFT]: 'Maksu on luotu, mutta SEPA aineistoa ei ole vielä muodostettu.',
    [creditTransferPaymentStates.STATE_WAITING]: 'Maksu on auki. Odottaa lähettämistä pankkiin.',
    [creditTransferPaymentStates.STATE_BEING_SENT]: 'Maksua ollaan lähettämässä pankkiin.',
    [creditTransferPaymentStates.STATE_SENT_TO_BANK]: 'Maksuaineisto on lähetty pankkiin.',
    [creditTransferPaymentStates.STATE_BANK_ACCEPTED]: 'Pankki on ottanut maksuaineiston hyväksytysti vastaan.',
    [creditTransferPaymentStates.STATE_BANK_PAID]: 'Maksu näkyy maksettuna pankin tiliotteella.',
};

export const creditTransferPaymentStateMap = {
    [creditTransferPaymentStates.STATE_DRAFT]: _trans('credit_transfer.payments.states.draft'),
    [creditTransferPaymentStates.STATE_WAITING]: _trans('credit_transfer.payments.states.waiting'),
    [creditTransferPaymentStates.STATE_BEING_SENT]: _trans('credit_transfer.payments.states.being_sent'),
    [creditTransferPaymentStates.STATE_SENT_TO_BANK]: _trans('credit_transfer.payments.states.sent_to_bank'),
    [creditTransferPaymentStates.STATE_BANK_ACCEPTED]: _trans('credit_transfer.payments.states.bank_accepted'),
    [creditTransferPaymentStates.STATE_BANK_PAID]: _trans('credit_transfer.payments.states.bank_paid'),
};

export const translateCreditTransferPaymentState = (state) =>
    state in creditTransferPaymentStateMap ? creditTransferPaymentStateMap[state] : 'Tuntematon tila';
export const translateCreditTransferPaymentStateAdditionalInfo = (state) =>
    state in creditTransferPaymentStateAdditionalInfoMap ? creditTransferPaymentStateAdditionalInfoMap[state] : 'Tuntematon tila';
