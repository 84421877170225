import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ForeclosureContainer } from './ForeclosureContainer';

export const Foreclosure = ({ userId, canEdit, isAdmin, isAllowAssignmentContracts }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch.foreclosure.setUserId(userId);
        dispatch.foreclosure.setIsAdmin(isAdmin);
        dispatch.foreclosure.fetchForeclosure(userId);
        dispatch.foreclosure.setCanEdit(canEdit);
        dispatch.foreclosure.setIsAllowAssignmentContracts(isAllowAssignmentContracts);
        dispatch.foreclosure.setEditMode(false);
    }, [userId, canEdit, isAdmin, isAllowAssignmentContracts, dispatch.foreclosure]);

    return <ForeclosureContainer />;
};

Foreclosure.propTypes= {
    userId: PropTypes.number.isRequired,
    canEdit: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isAllowAssignmentContracts: PropTypes.bool,
};

Foreclosure.defaultProps = {
    canEdit: false,
    isAdmin: false,
    isAllowAssignmentContracts: false,
};
