import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { ChoiceField, FormField, HiddenField } from 'shared/components/Formik';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import { getOptionByValue } from 'shared/ReForm/utils/getOptionByValue';
import { Value } from 'shared/components/Value';

/**
 * Henkilöstöryhmävalitsin.
 * @returns {JSX.Element|null}
 * @constructor
 * @param props
 */
const PersonnelGroups = (props) => {
    const { name, attributes, defaultValue, isValueFixed } = props;
    const personnelGroups = useSelector(select.personnelGroup.getGroups);
    const options = useMemo(() => personnelGroups.map((personnelGroup) => ({
        value: personnelGroup.id?.toString(),
        label: personnelGroup.name,
    })), [personnelGroups]);
    const isLoading = useSelector((state) => state.loading.effects.personnelGroup.fetchGroups);

    // Jos ladataan tai piilotettu valitsin
    if (isLoading || attributes?.isHidden) return null;

    // Jos vain yksi ryhmä => valitaan suoraan pellin alla
    if (personnelGroups.length === 1) {
        return <HiddenField name={name} value={personnelGroups[0]?.id} />;
    }

    // Fiksattu valinta
    if (isValueFixed && defaultValue !== '') {
        const option = getOptionByValue(defaultValue, options);
        if (option) {
            return (
                <FormField {...getFieldProps(props)}>
                    <Value ariaDescribedBy={name}>
                        {option.label ?? '-'}
                    </Value>
                </FormField>
            );
        }
    }

    return (
        <FormField {...getFieldProps(props)}>
            <ChoiceField options={options} />
        </FormField>
    );
};

PersonnelGroups.propTypes = {
    name: PropTypes.string.isRequired,
    attributes: PropTypes.object.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isValueFixed: PropTypes.bool,
};

PersonnelGroups.defaultProps = {
    defaultValue: '',
    isValueFixed: false,
};

export default PersonnelGroups;
