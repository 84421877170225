import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {sizes} from 'shared/constants';

function Spinner({
    wrapperClass = 'u-padding-small u-padding@medium u-text-center',
    message = '',
    size = '',
    wrapped = false,
    primary = false,
    inverted = false,
}) {

    const spinnerClass = classNames('c-spinner', {
        'c-spinner--primary': primary,
        'c-spinner--inverted': inverted,
        'c-spinner--center': wrapped === false,

        'c-spinner--small': size === sizes.SMALL,
        'c-spinner--large': size === sizes.LARGE,
    });

    const spinnerElement = (
        <div className={spinnerClass}>
            { message != '' ? <span className="u-text-italic u-margin-right-tiny">{message}</span> : null }
            <span className="c-spinner__dot"/>
            <span className="c-spinner__dot c-spinner__dot--second"/>
            <span className="c-spinner__dot c-spinner__dot--third"/>
        </div>
    );

    if (wrapped) {
        return (
            <div className={wrapperClass}>
                {spinnerElement}
            </div>
        );
    } else return spinnerElement;
}

Spinner.propTypes = {
    wrapperClass: PropTypes.string,
    message: PropTypes.string,
    wrapped: PropTypes.bool,
    size: PropTypes.oneOf([sizes.SMALL, sizes.LARGE]),
    primary: PropTypes.bool,
    inverted: PropTypes.bool,
};

export default Spinner;