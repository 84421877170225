import FirstName from './FirstName';
import LastName from './LastName';
import StreetAddress from './StreetAddress';
import PostCode from './PostCode';
import Town from './Town';
import Email from './Email';
import PhoneNumber from './PhoneNumber';
import Locale from './Locale';
import AccountNumber from './AccountNumber';
import StartDate from './StartDate';

export {
    FirstName,
    LastName,
    StreetAddress,
    PostCode,
    Town,
    Email,
    PhoneNumber,
    Locale,
    AccountNumber,
    StartDate,
};
