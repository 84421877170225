import React from 'react';
import PropTypes from 'prop-types';
import { Value } from 'shared/components/Value';

/**
 * Rikosrekisteriotteen esityspvm.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const CriminalRecordDate = (props) => {
    const { value } = props;

    // Ei arvoa. Kerrotaan että ei ole tarkistettu.
    if (value?.toString().trim() === '') {
        return (
            <Value>
                {_trans('Ei tarkistettu', {}, 'jobContract')}
            </Value>
        );
    }

    return (
        <Value>{_toLocaleDate(value)}</Value>
    );
};

CriminalRecordDate.propTypes = {
    value: PropTypes.string.isRequired,
};

export default CriminalRecordDate;
