import PropTypes from 'prop-types';

export const dimension = PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.string,
});

export const dimensionType = PropTypes.shape({
    id: PropTypes.number,
    isContract: PropTypes.bool,
    isTimeRegistration: PropTypes.bool,
    name: PropTypes.string,
    dimensions: PropTypes.arrayOf(dimension),
});
