export { default as bookkeepingAccount } from './bookkeepingAccount';
export { default as certificateOfEmployment } from './certificateOfEmployment';
export { collectiveAgreement } from './collectiveAgreement';
export { default as contracts } from './contracts';
export { default as dimensions } from './dimensions';
export { default as earnedIncomes } from './earnedIncomes';
export { default as employees } from './employees';
export { default as employeeWorkExperience } from './employeeWorkExperience';
export { default as holidayDays } from './holidayDays';
export { default as insurances } from './insurances';
export { default as insuranceInstitutions } from './insuranceInstitutions';
export { default as jobTitles } from './jobTitles';
export { default as loggedUser } from './loggedUser';
export { default as notifications } from './notifications';
export { default as payPeriods } from './payPeriods';
export { default as payroll } from './payroll';
export { default as personnelGroup } from './personnelGroup';
export { default as taxCard } from './taxCard';
export { default as tradeUnion } from './tradeUnion';
export { default as userAttributes } from './userAttributes';
export { default as userMetadata } from './userMetadata';
export { tyelInsurances } from './tyelInsurances';
export { salaries } from './salaries';
export { workTimeTypeDefinitions } from './workTimeTypeDefinitions';
export { contract } from './contract';
export { userAttachedDimensions } from './userAttachedDimensions';
export { receipts } from './receipts';

// Kaikki loading-selectorit
import { loadingSelectors as contractSelectors } from './contracts';
import { loadingSelectors as earnedIncomeSelectors } from './earnedIncomes';
import { loadingSelectors as insuranceSelectors } from './insurances';
import { loadingSelectors as jobTitleSelectors } from './jobTitles';
import { loadingSelectors as loggedUserSelectors } from './loggedUser';
import { loadingSelectors as payrollSelectors } from './payroll';
import { loadingSelectors as payperiodSelectors } from './payPeriods';
import { loadingSelectors as userAttributeSelectors } from './userAttributes';
import { loadingSelectors as userMetadataSelectors } from './userMetadata';
import { loadingSelectors as collectiveAgreementSelectors } from 'shared/models/collectiveAgreement';

// Yhdistetään
export const loadingSelectors = {
    // Vakuutukset
    ...insuranceSelectors,
    isInsuranceViewPending: (state) => (
        insuranceSelectors.isLoadingAccidentInsurance(state)
        && insuranceSelectors.isLoadingTyelInsurance(state)
        && userMetadataSelectors.isLoadingMetaData(state)
    ),

    ...collectiveAgreementSelectors,
    ...contractSelectors,
    ...earnedIncomeSelectors,
    ...loggedUserSelectors,
    ...jobTitleSelectors,
    ...payrollSelectors,
    ...payperiodSelectors,
    ...userAttributeSelectors,
    ...userMetadataSelectors,
};
