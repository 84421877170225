import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { ChoiceField } from 'shared/components/Formik/ChoiceField';
import { groupDropdownOptions } from 'shared/utils/groupDropdownOptions';
import SummaryJobTitle from 'shared/ReForm/containers/CustomBlocks/ContractV3/Summary/JobTitle';
import { types } from 'shared/constants/contract';

/**
 * Työnimikeen valitsinpalikka.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const JobTitle = (props) => {
    const { name, placeholder } = props;
    const jobTitles = useSelector(select.jobTitles.getJobTitles);
    const contractType = useSelector(select.contract.getType);
    const isNewContract = useSelector(select.contract.isNewContract);
    const isLoading = useSelector((state) => state.loading.effects.jobTitles.fetchJobTitles);

    if (isLoading) {
        return _trans('Ladataan työnimikkeitä', {}, 'jobContract');
    }

    // Vain uudella sopparilla voi muuttaa työnimikettä
    if (! isNewContract) {
        return <SummaryJobTitle />;
    }

    return (
        <ChoiceField
            placeholder={placeholder}
            name={name}
            options={contractType === types.JOB_CONTRACT
                ? groupDropdownOptions(jobTitles)
                : jobTitles}
            isFullWidth
        />
    );
};

JobTitle.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
};

JobTitle.defaultProps = {
    placeholder: _trans('Valitse tehtävä työ', {}, 'jobContract'),
};

export default JobTitle;
