import { FieldArray } from 'formik';
import React, { Fragment, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { getBlockProps } from 'shared/ReForm/utils/getBlockProps';
import { isBlockVisible } from 'shared/ReForm/utils/isBlockVisible';
import { blocks as blockTypes } from 'shared/ReForm/constants/blocks';
import _transObj from 'shared/utils/_transObj';
import { CustomComponent } from 'shared/ReForm/containers/CustomBlocks/CustomComponent';
import { Block } from 'shared/ReForm/containers/Block';
import { getTemplateFieldNames } from 'shared/ReForm/utils/getTemplateFieldNames';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { resolveStyles } from 'shared/ReForm/utils/resolveStyles';
import { arrayBlockNames } from 'ContractV3/constants/arrayBlocks';

/**
 * Renderöi valitun pohjan mukaiset kentät.
 * @param json
 * @param values
 * @param componentRootPath
 * @param templateSubType
 * @constructor
 */
export const Blocks = ({ json, values, componentRootPath, templateSubType }) => {
    // Pohjassa käytettyjen kenttien nimet talteen
    const templateFieldNames = useMemo(() => getTemplateFieldNames(json), [json]);

    // Renderöi koko json-pompsin.
    const iterate = useCallback((json, id = '', arrayProps = {}) => {
        let groupIndex = 1;
        return json.map((item, index) => {
            const { block, style } = item;
            const uid = `uid${id}-${index}`;
            //const level = uid.split('-')?.length;

            const { name, blocks, dependencies, isVisibleOnSummary, isGroupNumberVisible } = getBlockProps(item);
            const alteredItem = Object.assign({}, item, {
                // Tarkka polku itse komponenttiin
                fullComponentName: `${componentRootPath}/${item.component}`,
                templateFieldNames: Object.keys(templateFieldNames),
                values,
                templateSubType,
            });

            // Tutkitaan näytetäänkö kenttää
            if (! isBlockVisible(dependencies, values, arrayProps)) {
                return null;
            }

            switch (block) {
                case blockTypes.GROUP: {
                    const hasSubHeading = arrayProps?.rootName === arrayBlockNames.salaries;
                    return (
                        <section
                            key={uid}
                            style={resolveStyles(style)}
                            className={classNames('u-margin-bottom', { 'is-hidden-on-summary': ! isVisibleOnSummary })}
                            role="group"
                            id={uid}
                        >
                            {React.createElement(hasSubHeading ? 'h3' : 'h2', {
                                className: hasSubHeading
                                    ? 'c-heading-subheading u-margin-bottom'
                                    : 'c-heading-subtitle u-margin-bottom'
                            }, (
                                <Fragment>
                                    {(isGroupNumberVisible && ! hasSubHeading) && <span>{`${groupIndex++}. `}</span> }
                                    {_transObj(item.heading)}
                                </Fragment>
                            ))}
                            {Array.isArray(item.blocks) && iterate(item.blocks, `${id}-${index}`, arrayProps)}
                        </section>
                    );
                }

                case blockTypes.FIELDSET:
                    return (
                        <fieldset
                            key={uid}
                            id={uid}
                            style={resolveStyles(style)}
                        >
                            <legend>{_transObj(item.legend)}</legend>
                            {Array.isArray(item.blocks) && iterate(item.blocks, `${id}-${index}`, arrayProps)}
                        </fieldset>
                    );

                // Erikoispalikkatyyppi jolla voidaan luoda N-kappaletta blocks-ryhmiä.
                // Think about useampi palkkio OPH:ssa
                case blockTypes.ARRAY: {
                    if (! Array.isArray(blocks)
                        || blocks.length === 0
                        || ! values[name]
                        || ! Array.isArray(values[name])
                    ) {
                        return (
                            <div>
                                {_trans('Lista-tyyppisellä palikalla ei ole määriteltyä listaa.', {}, 'contractTemplate')}
                            </div>
                        );
                    }
                    // Nimen mukaan listakomponentti
                    const listComponent = (name === fieldNames.SALARIES)
                        ? `${componentRootPath}/Salaries/SalaryList`
                        : null;

                    // Jos kyseessä on dynaaminen palikkataulukko renderöidään se FieldArray:n sisään jotta
                    // saadaa myös arrayHelper käyttöön.
                    return (
                        <FieldArray
                            name={name}
                            key={uid}
                            render={(arrayHelpers) => (
                                <Fragment>
                                    {/*Lisää palikoita lisäävä komponentti.*/}
                                    {(item.addComponent && (values[name] ?? []).length === 1) && (
                                        <CustomComponent
                                            componentName={`${componentRootPath}/${item.addComponent}`}
                                            props={{
                                                name,
                                                index,
                                                arrayHelpers,
                                                blocks,
                                            }}
                                        />
                                    )}
                                    {/* Ah shit here we go again... iteroidaan arraysetistä löytyvät palikat koska voi
                                  löytyä fieldset jolla taas on lisää palikoita. Kääritään oletuksena SalaryList-haitariin
                                  ellei muuta komponenttia ole annettu. */}
                                    {(listComponent && Array.isArray(values[name]) && values[name].length > 1)
                                        ? (
                                            <CustomComponent
                                                componentName={listComponent}
                                                props={{
                                                    name,
                                                    uid,
                                                    arrayHelpers,
                                                    blocks: blocks.map((block) => getBlockProps(block)),
                                                    values,
                                                    iterator: iterate,
                                                }}
                                            />
                                        )
                                        : values[name].map((value, valueIndex) => iterate(
                                            blocks,
                                            `${uid}-${valueIndex}`,
                                            {
                                                arrayName: `${name}.${valueIndex}`,
                                                rootName: name,
                                                index: valueIndex,
                                                arrayHelpers,
                                                blocks: blocks.map((block) => getBlockProps(block))
                                            }))
                                    }
                                    {/*Lisää palikoita lisäävä komponentti.*/}
                                    {(item.addComponent && (values[name] ?? []).length > 1) && (
                                        <CustomComponent
                                            componentName={`${componentRootPath}/${item.addComponent}`}
                                            props={{
                                                name,
                                                index,
                                                arrayHelpers,
                                                blocks,
                                            }}
                                        />
                                    )}
                                </Fragment>
                            )}
                        />
                    );
                }


                default: {
                    return (
                        <Block item={alteredItem} {...arrayProps} key={uid} uid={uid} />
                    );
                }
            }
        });
    }, [templateFieldNames, values, componentRootPath]);

    return useMemo(() => iterate(json), [json, iterate]);
};
