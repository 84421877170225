import React from 'react';
import PropTypes from 'prop-types';
import { Value } from 'shared/components/Value';

/**
 * Koeajan päättymispäivä.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const EndDate = (props) => {
    const { value } = props;
    return (
        <Value>{_toLocaleDate(value)}</Value>
    );
};

EndDate.propTypes = {
    value: PropTypes.string.isRequired,
};

export default EndDate;
