import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';

function TransList({id, parameters, domain, locale}) {
    const translation = Translator.trans(id, parameters, domain, locale);
    const translationList = translation.split('- ');

    return (
        <ul>
            {_map(translationList, (translationListItem, key) => (
                (translationListItem != '') ? <li key={key}>{translationListItem}</li> : null
            ))}
        </ul>
    );
}

TransList.propTypes = {
    id: PropTypes.string.isRequired,
    parameters: PropTypes.object,
    domain: PropTypes.string,
    locale: PropTypes.string
};

export default TransList;