import React from 'react';
import PropTypes from 'prop-types';
import { MonthSpanInput } from 'shared/components/MonthSpanInput';

/**
 * Kuukausivalitsen yhdelle kuukaudelle, MonthSpanInput kuukausivälille.
 *
 * @param onChange
 * @param value
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const MonthInput = ({ onChange, value, ...rest }) => (
    <MonthSpanInput
        {...rest}
        value={value}
        onChange={(value) => onChange(value)}
        hasSingleInput
    />
);

MonthInput.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
};

MonthInput.defaultProps = {
    onChange() {},
    value: '',
};
