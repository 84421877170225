import React, { Fragment } from 'react';
import { useBooleanToggle } from '@mantine/hooks';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import PropTypes from 'prop-types';
import { Button, Dialog, SubmitButton } from 'shared/components';
import { usePost } from 'shared/hooks/useApi';
import { ChoiceField, Form, FormField } from 'shared/components/Formik';
import { taxCardTypes, translateTaxCardType } from 'shared/constants/taxCardTypes';

const taxCardTypeOptions = [
    taxCardTypes.COMPENSATION_TAX_CARD_PERSONAL,
    taxCardTypes.COMPENSATION_TAX_CARD_FAMILY_CARE,
    taxCardTypes.COMPENSATION_TAX_CARD_RELATIVE_CARE,
].map((type) => ({ value: type.toString(), label: translateTaxCardType(type) }));

export const TaxCardTypeOverride = ({ jobContractData }) => {
    const dispatch = useDispatch();
    const taxCardTypeOverride = useSelector(select.taxCards.getTaxCardTypeOverride);
    const [isOpen, toggleOpen] = useBooleanToggle(false);
    const [, post] = usePost(`/api/v1/contracts/${jobContractData}/tax-card-type-override`);
    return (
        <Fragment>
            <Button onClick={() => toggleOpen()} mdIcon="swap_horiz">
                {_trans('Vaihda verokortin tyyppiä', {}, 'tax_card')}
            </Button>
            <Dialog
                isOpen={isOpen}
                onClose={() => toggleOpen()}
                title={_trans('Vaihda verokortin tyyppiä', {}, 'tax_card')}
            >
                <Formik
                    initialValues={{
                        taxCardType: taxCardTypeOverride
                            ? taxCardTypeOverride.toString()
                            : taxCardTypes.COMPENSATION_TAX_CARD_PERSONAL.toString(),
                    }}
                    validationSchema={Yup.object().shape({
                        taxCardType: Yup.string().required(),
                    })}
                    onSubmit={(model, actions) => post(model).then((json) => {
                        if (json.status === 'ok') {
                            dispatch.taxCards.fetchTaxCards(jobContractData);
                            toggleOpen();

                            return;
                        }

                        actions.setErrors({ taxCardType: json.message });
                    })}
                >
                    <Form>
                        <FormField name="taxCardType" label={_trans('Verokortti-tyyppi')}>
                            <ChoiceField options={taxCardTypeOptions} />
                        </FormField>
                        <SubmitButton>
                            {_trans('Tallenna', {}, 'common')}
                        </SubmitButton>
                    </Form>
                </Formik>
            </Dialog>
        </Fragment>
    );
};

TaxCardTypeOverride.propTypes = {
    jobContractData: PropTypes.number.isRequired,
};
