// import { produce } from 'immer';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import api from 'api';
const moment = extendMoment(Moment);
import { actions as notificationActions } from 'shared/stores/notifications';
import {
    MAX_FORECLOSURE_AMOUNT,
    ONE_THIRD_EMPLOYEE_REFUGE_INCOME
} from 'shared/UserDetails/containers/shared/Foreclosure/constants';
import { YEAR_MONTH_DAY_FORMAT, YEAR_MONTH_FORMAT } from 'shared/constants/formats';
import { TYPE_OIMA_CUSTOMER_ACCOUNT } from 'shared/constants/bankAccountTypes';

/**
 * Mankeloi tiedot UI:lle sopivaan muotoon
 * @param values
 * @returns {{restrictions: {duration: {start: string, end: string}, amount: *}[], noForeclosureMonths: *[]}}
 */
const parseForeclosureValues = (values = {}) => {
    const { foreclosureAmount, employeeRefugeIncome, isIndefiniteAmount, restrictions, startDate, noForeclosureMonths, created, edited, ...rest } = values;

    let lastTime = null;
    /**
     * Parsitaan bäkkäridatasta UI:lle oikeanmuotoiset arvot
     * Runnotaan ensin aikajärjestykseen jonka jälkeen jaetaan ryhmiin sen mukaan ovatko
     * kuukaudet peräjälkeen vai onko välissä yksi tai useampi kuukausi.
     * TODO: Tämä ja vapaat kuukaudet voisi hoitaa yhdellä utilityllä
     */
    const parsedRestrictions = (restrictions ?? [])
        .map(({ startYearMonth, restriction }) => ({
            // Varuiksi muutetaan - => /
            date: moment(startYearMonth, YEAR_MONTH_FORMAT).toDate(),
            restriction,
        }))
        // Aikajärjestykseen. Varmuuden vuoksi.
        .sort((a, b) => a.date.getTime() > b.date.getTime())
        .reduce((acc, { date, restriction }, idx, src) => {
            // Rullataan kuukausia läpi ja ryhmitellään pvm:t sen mukaan
            // ovatko ne toistensa lähellä. Jos ei => omaksi ryhmäkseen.
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const lastMonth = (lastTime || date).getMonth() + 1;
            const lastYear = (lastTime || date).getFullYear();

            const nextRestriction = src[idx + 1]?.restriction ?? restriction;
            const nextMonth = (src[idx + 1]?.date || date).getMonth() + 1;
            const nextYear = (src[idx + 1]?.date || date).getFullYear();

            const monthDiff = month - lastMonth + (12 * (year - lastYear));
            const nextMonthDiff = nextMonth - month + (12 * (nextYear - year));

            // Eroa seuraavaan kuukausi/vuosikomboon vain yksi kuukausi,
            // otetaan talteen sekvenssin alku. Summa on myös oltava sama kuin seuraava
            // koska muuten annetut eriävät rajoitusmäärät läjätään yhden saman määrän alle.
            // Esim. 1/2021-4/2021, 500 ja 5/2021-7/2021, 700 => 1-7/2021 700
            if (nextMonthDiff === 1 && nextRestriction === restriction) {
                if (lastTime === null) {
                    lastTime = date;
                }
                return acc;
            } else {
                lastTime = null;
                // Kuukausien erotuksen verran (=sekvenssin alku) indeksiä taaksepäin
                const start = (src[idx - monthDiff]?.date || date);

                return [
                    ...acc,
                    {
                        duration: {
                            start: `${start.getFullYear()}-${start.getMonth() + 1}`,
                            end: `${date.getFullYear()}-${date.getMonth() + 1}`,
                        },
                        amount: restriction,
                    }
                ];
            }
        }, []);

    // Kaivetaan vuositieto startDate-tiedosta jos sitä ei parsitusta datasta löydy.
    const startYear = new Date(startDate).getFullYear();

    /**
     * State-of-the-art taaksepäin yhteensopiva legacydatan oikeaan muotoon
     * survova parseri. Muuntaa kuukausi/vuositiedon muotoon MM/YYYY, vääntää niistä
     * js-timen ja vertailee keskenään jotta arvot menevät taulussa oikeaan järjestykseen.
     * Sen jälkeen redusoidaan aikaväli-taulukoksi.
     */
    lastTime = null;

    const parsedNoForeclosureMonths = (noForeclosureMonths ?? '')
        .replaceAll('/', '-') // < tämä koska sallittu menneisyydessä kahdenlainen syöttötapa
        .replaceAll(/\s/g, '')
        .split(',')
        .map((monthAndYear) => {
            const pcs = monthAndYear.split('-');
            if (pcs[0] === '') {
                return false;
            }
            // Tarkistus koska sallittu kantaa tallennus myös muodossa YYYY-MM että MM/YYYY
            const isYearReversed = pcs.length > 1 && pcs[1].length > 2;
            const year = isYearReversed ? pcs[1] : pcs[0];
            const month = isYearReversed ? pcs[0] : pcs[1];

            if (pcs.length === 1) {
                // Vuotta ei löydy. Kaivetaan se edited-tiedosta ja käytetään kuukautena ainoaa arvoa mikä löytyi.
                return new Date(startYear, parseInt(pcs[0], 10) - 1);
            } else if (pcs.length === 2) {
                // Muutoin muodostetaan aika parsituilla vuosi/kk arvoilla
                return new Date(year, parseInt(month, 10) - 1);
            }
        })
        .filter(Boolean)
        .sort((a, b) => a.getTime() - b.getTime())
        .reduce((acc, cur, idx, src) => {
            // Rullataan kuukausia läpi ja ryhmitellään pvm:t sen mukaan
            // ovatko ne toistensa lähellä. Jos ei => omaksi ryhmäkseen.
            const month = cur.getMonth() + 1;
            const year = cur.getFullYear();
            const lastMonth = (lastTime || cur).getMonth() + 1;
            const lastYear = (lastTime || cur).getFullYear();

            const nextMonth = (src[idx + 1] || cur).getMonth() + 1;
            const nextYear = (src[idx + 1] || cur).getFullYear();

            const monthDiff = month - lastMonth + (12 * (year - lastYear));
            const nextMonthDiff = nextMonth - month + (12 * (nextYear - year));

            // Eroa seuraavaan kuukausi/vuosikomboon vain yksi kuukausi,
            // otetaan talteen sekvenssin alku.
            if (nextMonthDiff === 1) {
                if (lastTime === null) {
                    lastTime = cur;
                }
                return acc;
            } else {
                lastTime = null;
                // Kuukausien erotuksen verran (=sekvenssin alku) indeksiä taaksepäin
                const start = (src[idx - monthDiff] || cur);

                return [
                    ...acc,
                    {
                        start: `${start.getFullYear()}-${start.getMonth() + 1}`,
                        end: `${cur.getFullYear()}-${cur.getMonth() + 1}`,
                    }
                ];
            }
        }, []);

    return {
        ...rest,
        foreclosureAmount,
        employeeRefugeIncome,
        // Legacy check. 99999 näytti olevan pienin syötetty summa jonka tarkoitus oli aiemmin merkata "toistaiseksi".
        isIndefiniteAmount: isIndefiniteAmount
            ? isIndefiniteAmount
            : foreclosureAmount >= 99999,
        startDate,
        noForeclosureMonths: parsedNoForeclosureMonths,
        restrictions: parsedRestrictions,
        isOneThird: employeeRefugeIncome === ONE_THIRD_EMPLOYEE_REFUGE_INCOME,
    };
};

/**
 * Maksukielto / ulosotto.
 */
export const foreclosure = {
    state: {
        openForeclosure: {},
        openForeclosures: [],
        openOimaForeclosures: [],
        closedForeclosures: [],
        hasOpenPayrolls: false,
        userId: null,
        isCopy: false,
        isEditMode: false,
        isOnFreeMonth: false,
        canEdit: false,
        isAdmin: false,
        isAllowAssignmentContracts: false,
    },

    reducers: {
        setOpenForeclosure: (state, openForeclosure) => ({ ...state, openForeclosure }),
        setOpenForeclosures: (state, openForeclosures) => ({ ...state, openForeclosures }),
        setOpenOimaForeclosures: (state, openOimaForeclosures) => ({ ...state, openOimaForeclosures }),
        setClosedForeclosures: (state, closedForeclosures) => ({ ...state, closedForeclosures }),
        setHasOpenPayrolls: (state, hasOpenPayrolls) => ({ ...state, hasOpenPayrolls }),
        setUserId: (state, userId) => ({ ...state, userId }),
        setEditMode: (state, isEditMode) => ({ ...state, isEditMode }),
        setCopy: (state, isCopy) => ({ ...state, isCopy }),
        setCanEdit: (state, canEdit) => ({ ...state, canEdit }),
        setIsAdmin: (state, isAdmin) => ({ ...state, isAdmin }),
        setIsAllowAssignmentContracts: (state, isAllowAssignmentContracts) => ({ ...state, isAllowAssignmentContracts }),

        /**
         * Ollaanko vapaakuukaudella.
         * @param state
         * @returns {*&{isOnFreeMonth: boolean}}
         */
        checkFreeMonth: (state) => {
            const { noForeclosureMonths } = state.openForeclosure;
            const todayStartOfMonth = moment().startOf('month').toDate();
            const isOnFreeMonth = (noForeclosureMonths ?? []).findIndex(({ start, end }) => {
                const startPcs = start.split('/');
                const endPcs = end.split('/');

                // Ei luoteta siihen että moment osaisi vääntää oikeantyyppisen pvm:n joten
                // väännetään käsin.
                const range = moment.range(
                    `${startPcs[1]}/${startPcs[0]}/1`,
                    `${endPcs[1]}/${endPcs[0]}/1`
                );
                // Tarkistetaan ekaa päivää vasten
                return range.contains(todayStartOfMonth);
            });

            return ({ ...state, isOnFreeMonth });
        }
    },

    selectors: {
        getOpenForeclosure: (state) => state.openForeclosure ?? {},
        getOpenForeclosures: (state) => state.openForeclosures ?? [],
        getOpenOimaForeclosures: (state) => state.openOimaForeclosures ?? [],
        getClosedForeclosures: (state) => state.closedForeclosures ?? [],
        hasOpenPayrolls: (state) => state.hasOpenPayrolls,
        getUserId: (state) => state.userId,
        isEditMode: (state) => state.isEditMode,
        isCopy: (state) => state.isCopy,
        isOnFreeMonth: (state) => state.isOnFreeMonth > -1,
        canEdit: (state) => state.canEdit,
        isAdmin: (state) => state.isAdmin,
        isAllowAssignmentContracts: (state) => state.isAllowAssignmentContracts,
        // Jo peritty määrä
        getCollectedForeclosedAmount: (state) => {
            const foreclosureAmount = state.openForeclosure?.foreclosureAmount ?? 0;
            const foreclosureAmountLeft = state.openForeclosure?.foreclosureAmountLeft ?? 0;

            return foreclosureAmount - foreclosureAmountLeft;
        },
    },

    effects: (dispatch) => ({
        async goBack() {
            await this.setEditMode(false);
            await this.setCopy(false);
            window.scrollTo(0, 0);
        },

        async fetchForeclosure(employee, state) {
            try {
                const json = state.foreclosure.isAdmin
                    ? await api.get(`/api/v1/users/${employee}/admin_foreclosure`)
                    : await api.get(`/api/v1/users/${employee}/foreclosure`);
                const { openForeclosures, closedForeclosures, hasOpenPayrolls } = json;

                // Yleensä voimassa vain yksi, mutta jos sattuu olemaan useampi. Otetaan ensimmäinen.
                const openOimaForeclosures = openForeclosures.filter((openForeclosure) => openForeclosure.bankAccount.bankAccountType === TYPE_OIMA_CUSTOMER_ACCOUNT);
                const openForeclosure = state.foreclosure.isAdmin
                    ? openOimaForeclosures.length >= 1 ? openOimaForeclosures[0]: null
                    : openForeclosures && openForeclosures.length >= 1 ? openForeclosures[0] : null;

                this.setOpenForeclosures(openForeclosures.filter((openForeclosure) => openForeclosure.bankAccount.bankAccountType !== TYPE_OIMA_CUSTOMER_ACCOUNT));
                this.setOpenOimaForeclosures(openForeclosures.filter((openForeclosure) => openForeclosure.bankAccount.bankAccountType === TYPE_OIMA_CUSTOMER_ACCOUNT));
                this.setOpenForeclosure(openForeclosure ? parseForeclosureValues(openForeclosure) : {});
                this.setClosedForeclosures(closedForeclosures);
                this.setHasOpenPayrolls(hasOpenPayrolls);
                this.checkFreeMonth(openForeclosure);
            } catch (e) {
                console.error(e);
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('foreclosure.notifications.error.fetch_failed'),
                }));
            }
        },

        /**
         * Tallennus. Joko post tai put.
         * @param employee
         * @param values
         * @param rootState
         * @returns {Promise<void>}
         */
        async saveForeclosure({ employee, values }, rootState) {
            const { foreclosureAmount, isIndefiniteAmount, foreclosureId } = values;

            try {
                // Väännetään taulukko stringipitkoksi
                const noForeclosureMonths = values.noForeclosureMonths
                    .reduce((acc, cur) => {
                        const startPcs = cur.start.split('-');
                        const startYear = parseInt(startPcs[0], 10);
                        const startMonth = parseInt(startPcs[1], 10);

                        const monthDiff = moment(cur.end).diff(moment(cur.start), 'months', true);
                        // Sama alku ja loppu
                        if (monthDiff === 0) {
                            return [
                                ...acc,
                                cur.start
                            ];
                        } else {
                            let nextYear = startYear;
                            let nextMonth = startMonth;

                            // Täytetään pvm:ien välit (5/2011-7/2011 => 2011-5,2011-6,2011-7)
                            const dates = Array.from({ length: monthDiff + 1 }, () => {
                                if (nextMonth > 12) {
                                    nextMonth = 1;
                                    nextYear++;
                                }

                                return (
                                    `${nextYear}-${nextMonth++}`
                                );
                            });
                            return [...acc, ...dates];
                        }
                    }, [])
                    .join(', ');

                // Käännetään taulukko kuukausi-vuosistringejä stringipötköksi standardiin muotoon
                const restrictions = (values.restrictions ?? []).reduce((acc, { duration, amount }) => {
                    if (! duration?.start || ! duration?.end) {
                        return acc;
                    }

                    const startDate = moment(duration.start, YEAR_MONTH_DAY_FORMAT);
                    const endDate = moment(duration.end, YEAR_MONTH_DAY_FORMAT);
                    if (! startDate.isValid() || ! endDate.isValid()) {
                        return acc;
                    }

                    const initialStartDate = startDate.format(YEAR_MONTH_FORMAT);
                    const monthDiff = endDate.diff(startDate, 'months', true);
                    // const start = startDate.format(YEAR_MONTH_FORMAT);
                    // Rullataan kuukausieron verran taulukkoa läpi, lisäten yhdellä kk:lla per iteraatio
                    const dates = Array
                        .from({ length: monthDiff }, (_, i) => i)
                        .reduce((acc, ) => {
                            const start = startDate.add(1, 'months').format(YEAR_MONTH_FORMAT);
                            return acc + `${start};${start};${amount}\n`;
                        }, `${initialStartDate};${initialStartDate};${amount}\n`);

                    return acc + dates;
                }, '');

                const payload = {
                    ...values,
                    employeeRefugeIncome: values.employeeRefugeIncome.toString().replace(',', '.'),
                    employeeRefugeIncomeExtra: values.employeeRefugeIncomeExtra.toString().replace(',', '.'),
                    maxForeclosurePerMonth: values.maxForeclosurePerMonth.toString().replace(',', '.'),
                    otherReceivedIncome: values.otherReceivedIncome.toString().replace(',', '.'),
                    // Jos "toistaiseksi" voimassa pusketaan pellin alla tarpeeksi iso luku bäkkäriin
                    foreclosureAmount: isIndefiniteAmount
                        ? MAX_FORECLOSURE_AMOUNT
                        : foreclosureAmount.replace(',', '.'),
                    payBanRFReferenceNumber: values.payBanRFReferenceNumber.replace(/ /g, '').toUpperCase(),
                    restrictions,
                    noForeclosureMonths,
                };

                let message = _trans('foreclosure.notifications.success.post');

                const queryString = rootState.foreclosure.isAdmin
                    ? '?isAdmin=1'
                    : '';

                // Post tai put sen mukaan löytyykö id jo
                if (foreclosureId) {
                    await api.post(`/api/v1/users/${employee}/foreclosures/${foreclosureId}${queryString}`, payload);
                    message = _trans('foreclosure.notifications.success.put');
                } else {
                    // Jos luotiin kopiosta niin eri viesti
                    if (rootState.foreclosure?.isCopy ?? false) {
                        message = _trans('foreclosure.notifications.success.make_copy');
                    }

                    await api.post(`/api/v1/users/${employee}/foreclosures${queryString}`, payload);
                }

                dispatch(notificationActions.addNotification({
                    type: 'success',
                    message
                }));
                await this.fetchForeclosure(employee);
                await this.checkFreeMonth(values);
                await this.setEditMode(false);
                await this.setCopy(false);
            } catch (e) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: foreclosureId
                        ? _trans('foreclosure.notifications.error.put')
                        : _trans('foreclosure.notifications.error.post')
                }));
                console.error(e);
            }
        },

        /**
         * Päättää maksukiellon.
         * @param employee
         * @param foreclosure
         * @param isDuplicateEnd
         * @param rootState
         * @returns {Promise<void>}
         */
        async closeForeclosure({ employee, foreclosure, isDuplicateEnd }, rootState) {
            try {
                const queryString = rootState.foreclosure.isAdmin
                    ? '?isAdmin=1'
                    : '';
                await api.patch(`/api/v1/closes/${employee}/users/${foreclosure}/foreclosure${queryString}`, isDuplicateEnd ? { 'isDuplicateEnd': 1 } : null);
                await this.fetchForeclosure(employee);
                dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('foreclosure.notifications.success.close_foreclosure')
                }));
            } catch (e) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('foreclosure.notifications.error.close_foreclosure')
                }));
                console.error(e);
            }
        },

        /**
         * Luo kopion nykyisestä ja päättää vanhan.
         * @param values
         * @returns {Promise<void>}
         */
        async makeCopyAndCloseCurrent(values) {
            try {
                const { foreclosureId, startDate, state, employee, foreclosureAmountLeft, ...restValues } = values;
                await api.patch(`/api/v1/closes/${employee.userId}/users/${foreclosureId}/foreclosure`, {
                    endDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                });
                await this.saveForeclosure({
                    employee: employee.userId,
                    values: restValues,
                });
                await this.fetchForeclosure(employee.userId);
            } catch (e) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('foreclosure.notifications.error.make_copy')
                }));
                console.error(e);
            }
        },
    })
};
