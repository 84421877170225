import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { RadioListField } from 'shared/components/Formik';
import workTimeEstimationTypes, {
    companyEstimatedWorkTimeTypes,
    translateWorkTimeEstimateType,
} from 'shared/constants/workTimeEstimationTypes';

/**
 * Sopimuksen mukainen työaika.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */

const WorkTimeEstimationType = ({ name }) => {
    const isCompany = useSelector(select.userMetadata.isCompany);

    const options = useMemo(() => {
        if (isCompany) {
            return Object.values(companyEstimatedWorkTimeTypes).map(({ id, name }) => ({
                id: String(id),
                name,
            }));
        }
        return Object.values(workTimeEstimationTypes).map((workEstType) => ({
            id: String(workEstType),
            name: translateWorkTimeEstimateType(workEstType),
        }));
    }, [isCompany]);

    return (
        <RadioListField
            name={name}
            options={options}
            valueKey="id"
            labelKey="name"
        />
     
    );
};
WorkTimeEstimationType.propTypes = {
    name: PropTypes.string.isRequired,
};
export default WorkTimeEstimationType;
