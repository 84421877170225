/**
 * SideBar-containerin omat sisäiset asetukset.
 * @type {string}
 */

const GROUP_NAME = 'groupName';
const GROUP_ICON = 'groupIcon';
// Ryhmän otsikon sisältö. Käytännössä mitä vain mutta 99% tapauksista riittää groupName ja groupIcon.
const GROUP_HEADER = 'groupHeader';
const GROUP_PATHS = 'paths';

const PATH_NAME = 'path';
const PATH_ICON = 'icon';
const PATH_IS_BADGE_VISIBLE = 'isBadgeVisible';
const PATH_BADGE_VALUE = 'badgeValue';
const PATH_TITLE = 'title';
const PATH_COMPONENT = 'component';
const PATH_VISIBLE = 'visible';
const PATH_HAS_ROUTES = 'hasRoutes';

const GROUP_LOADING_ROUTES = 'isLoadingRoutes';

const obligatoryKeys = [PATH_NAME, PATH_TITLE, PATH_COMPONENT];

export default {
    GROUP_NAME,
    GROUP_ICON,
    GROUP_HEADER,
    GROUP_PATHS,
    GROUP_LOADING_ROUTES,

    PATH_NAME,
    PATH_ICON,
    PATH_IS_BADGE_VISIBLE,
    PATH_BADGE_VALUE,
    PATH_TITLE,
    PATH_COMPONENT,
    PATH_VISIBLE,
    PATH_HAS_ROUTES,

    obligatoryKeys,
};
