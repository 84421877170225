import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { promised as request } from 'BubbleWrapAgent';
import Page from 'shared/components/Page';
import Feedback from 'shared/components/Feedback';
import Spinner from 'shared/components/MDSpinner';
import { userRoles } from 'shared/constants/index.js';

/**
 * Hakee käyttäjän metatiedot kuten oikeudet ja antaa ne eteenpäin.
 * Ladattaessa näytetään vain spinneri. Kun lataus on valmis näytetään
 * itse sisempi komponentti.
 * getAffairs-parameterilla haetaan userStateOfAffairs mukaan.
 * @param params object
 * @return function
 */
export const gatekeeper = (params = { getAffairs: false, getEndpoints: false }) => (DecoratedComponent) => {
    @observer
    class Gatekeeper extends Component {
        static childContextTypes = {
            userMetadata: PropTypes.object,
            isCommune: PropTypes.func,
            isCommuneAdmin: PropTypes.func,
            isCommuneSuperUser: PropTypes.func,
            getCommuneId: PropTypes.func,
        };

        @observable userMetadata = {};
        @observable errorMessage = null;
        @observable isLoading = false;

        constructor() {
            super();
            this.isLoading = true;
            let query = {};
            if (_.get(params, 'getAffairs', false)) {
                query = { affairs: 1 };
            }
            if (_.get(params, 'getEndpoints', false)) {
                query = { ...query, endpoints: 1 };
            }

            request
                .get(Routing.generate('api_1_get_user_metadata'))
                .query(query)
                .end((error, response) => {
                    const userMetadata = _.get(response, 'body', null);

                    if (error || userMetadata === null) {
                        this.errorMessage = _trans('notifications.user.load.error');
                    } else {
                        this.userMetadata = userMetadata;

                        // Käyttäjällä ei ole roolia. Ohjaa roolinvalintasivulle (etusivu).
                        if (this.getUserRole() === userRoles.NONE && userMetadata?.isIdentified) {
                            window.location.replace(Routing.generate('suoratyo_home'));
                        }
                    }

                    this.isLoading = false;
                });
        }

        getUserRole = () => _.get(this.userMetadata, 'userRole', null);

        getChildContext() {
            return {
                userMetadata: this.userMetadata,
            };
        }

        render() {
            if (this.isLoading) return <Spinner wrapped />;

            // Käyttäjän metadataa ei jostain syystä voitu ladata. Bail out.
            if (this.errorMessage !== null) {
                return (
                    <Page maxWidth="large">
                        <Feedback type="error" message={this.errorMessage} />
                    </Page>
                );
            }

            return (
                <DecoratedComponent
                    {...this.props}
                    userMetadata={this.userMetadata}
                    getAffairs={() => _.get(this.userMetadata, 'userStateOfAffairs', {})}
                    getUserRole={() => _.get(this.userMetadata, 'userRole', null)}
                    isAdmin={() => _.get(this.userMetadata, 'isAdmin', false)}
                    isCommune={() => _.get(this.userMetadata, 'isCommune', false)}
                    isCommuneAdmin={() => _.get(this.userMetadata, 'isCommuneAdmin', false)}
                    isCommuneSuperUser={() => _.get(this.userMetadata, 'isCommuneSuperUser', false)}
                    getCommuneId={() => _.get(this.userMetadata, 'communeId', null)}

                    isIdentifierExpired={() => _.get(this.userMetadata, 'userStateOfAffairs.identification.isIdentificationExpired', null)}
                />
            );
        }
    }

    return Gatekeeper;
};

export default gatekeeper;
