import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover } from 'shared/components';
import { useLocalStorage } from 'shared/hooks';
import { exportXLS } from 'shared/containers/DataTableExport/exportXLS';
import ButtonGroup from 'shared/components/ButtonGroup';
import { exportCSV } from 'shared/containers/DataTableExport/exportCSV';

const DEFAULT_EXPORT_FORMAT = 'defaultExportFormat';
export const XLS_EXPORT_FORMAT = 'xls_format';
export const CSV_EXPORT_FORMAT = 'csv_format';

const DEFAULT_DELIMITER = 'defaultDelimiter';
const COMMA_DELIMITER = ',';
const SEMICOLON_DELIMITER = ';';

const DEFAULT_DECIMAL_SEPARATOR = 'defaultDecimalSeparator';
const COMMA_SEPARATOR = ',';
const PERIOD_SEPARATOR = '.';

/**
 * Exports DataTable data either to XLS or CSV format (defaults to XLS).
 * @param fileName
 * @param onGetData
 * @returns {JSX.Element}
 * @constructor
 */
export const DataTableExport = ({ fileName, onGetData }) => {
    const [inProgress, setInProgress] = useState(false);
    const [exportFormat, setExportFormat] = useLocalStorage(DEFAULT_EXPORT_FORMAT, XLS_EXPORT_FORMAT);
    const [columnDelimiter, setColumnDelimiter] = useLocalStorage(DEFAULT_DELIMITER, ';');
    const [decimalSeparator, setDecimalSeparator] = useLocalStorage(DEFAULT_DECIMAL_SEPARATOR, ',');

    const onChangeDelimiter = (event) => {
        const value = event.target.value;
        setColumnDelimiter(value);
        // Vaihdetaan desimaalierotin jos sarakkeen erottimena käytetty pilkkua
        setDecimalSeparator(columnDelimiter === COMMA_DELIMITER
            ? PERIOD_SEPARATOR
            : COMMA_SEPARATOR);
    };

    return (
        <div>
            <Button
                mdIcon="download"
                title={exportFormat === XLS_EXPORT_FORMAT
                    ? _trans('Lataa taulukon XLS-muodossa. XLS-tiedoston voi avata Excelissä. Taulukkoon tekemäsi hakutermisuodatukset näkyvät myös XLS-tiedostossa.', {}, 'common')
                    : _trans('Lataa taulukon CSV-muodossa. CSV-tiedoston voi avata esim. Excelissä. Taulukkoon tekemäsi hakutermisuodatukset näkyvät myös CSV:ssä ladattuasi sen.', {}, 'common')
                }
                inProgress={inProgress}
                onClick={() => {
                    const { headers, rows, exportColumnTotals } = onGetData();
                    // Timestampppi DD-MM-YYYY muodossa lisätään mukaan.
                    const timestamp = _toLocaleDate(new Date().toISOString()).replace(/\./g, '-');
                    const fullFileName = `${fileName}-${timestamp}.xlsx`;
                    if (exportFormat === XLS_EXPORT_FORMAT) {
                        setInProgress(true);
                        exportXLS({ fileName: fullFileName, headers, rows, exportColumnTotals }).then(() => setInProgress(false));
                    } else {
                        exportCSV({ fileName, headers, rows, exportColumnTotals, columnDelimiter, decimalSeparator });
                    }
                }}
            >
                {exportFormat === XLS_EXPORT_FORMAT
                    ? _trans('Lataa XLS', {}, 'common')
                    : _trans('Lataa CSV', {}, 'common')}
            </Button>
            <Popover maxWidth={240} title={_trans('Vientiasetukset', {}, 'common')} position="bottom">
                <Button
                    flat
                    mdIcon="settings"
                    title={_trans('Vientiasetukset', {}, 'common')}
                    modifierClass="u-margin-left-tiny"
                />
                <div className="o-form o-form--vertical">
                    <fieldset>
                        <label htmlFor="formatGroup">
                            {_trans('Vaihda vientimuoto', {}, 'common')}
                        </label>
                        <ButtonGroup hasFixedWidths ariaLabel="Vientimuodot">
                            <Button title={_trans('Excelissä avattava tiedostomuoto', {}, 'common')} active={exportFormat === XLS_EXPORT_FORMAT} onClick={() => setExportFormat(XLS_EXPORT_FORMAT)}>
                                XLS
                            </Button>
                            <Button title={_trans('Tekstitiedosto, jonka taulukkorakenteen eri kentät on eroteltu toisistaan pilkuilla ja rivinvaihdoilla.', {}, 'common')} active={exportFormat === CSV_EXPORT_FORMAT} onClick={() => setExportFormat(CSV_EXPORT_FORMAT)}>
                                CSV
                            </Button>
                        </ButtonGroup>
                        {/*CSV:lle vähän lisäasetuksia*/}
                        {exportFormat === CSV_EXPORT_FORMAT && (
                            <Fragment>
                                <label htmlFor="formatGroup" className="u-margin-top">
                                    {_trans('Sarake-erotin', {}, 'common')}
                                </label>
                                <ul role="radiogroup" id="formatGroup" className="o-list-bare u-margin-bottom-none">
                                    <li className="o-list-bare__item u-margin-vertical-tiny">
                                        <label>
                                            <input type="radio" value={SEMICOLON_DELIMITER} checked={columnDelimiter === SEMICOLON_DELIMITER} onChange={onChangeDelimiter} />
                                            {_trans('Puolipiste', {}, 'common')}
                                        </label>
                                    </li>
                                    <li className="o-list-bare__item">
                                        <label>
                                            <input type="radio" value={COMMA_DELIMITER} checked={columnDelimiter === COMMA_DELIMITER} onChange={onChangeDelimiter} />
                                            {_trans('Pilkku', {}, 'common')}
                                        </label>
                                    </li>
                                </ul>
                            </Fragment>
                        )}
                    </fieldset>
                </div>
            </Popover>
        </div>
    );
};

DataTableExport.propTypes = {
    fileName: PropTypes.string.isRequired,
    onGetData: PropTypes.func.isRequired,
    exportColumnTotals: PropTypes.object,
};

DataTableExport.defaultProps = {
    exportColumnTotals: {},
};

