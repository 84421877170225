/**
 * Palauttaa valitun TES:n oletusarvot.
 * @param collectiveAgreementVariables
 * @param currentCollectiveAgreement
 */
export const resolveCollectiveAgreementDefaultValues = (collectiveAgreementVariables, currentCollectiveAgreement = {}) => (
    collectiveAgreementVariables
        // Tämä ignorataan, koska kaikki data tulee myöhemmin choice_chain-typen mukana.
        .filter((item) => item.type !== 'choice_chain_sub')
        .reduce((acc, item) => {
            const { name, type, options = [], isModifiable = true, defaultValue } = item;
            const currentValue = currentCollectiveAgreement[name]?.value;

            switch (type) {
                case 'choice_chain': {
                    const choiceChainValues = options.map((option) => {
                        // Jos löytyy sub variable, asetetaan se, muuten tämä. Voi aiheuttaa duplikaatteja frontilla, jos
                        // asetetaan monta samaa variablea.
                        const subVariable = collectiveAgreementVariables
                            .find((variable) => variable?.name === option.name);

                        const value = currentCollectiveAgreement[option.name]
                            ? currentCollectiveAgreement[option.name].value
                            : option.metadata?.defaultValue ?? '';

                        return [
                            // Key...
                            option.name,
                            // ...ja value kun ajetaan Object.fromEntries:n läpi myöhemmin
                            {
                                value,
                                variable: subVariable ? subVariable : item,
                            }
                        ];
                    });

                    return Object.assign(
                        acc,
                        Object.fromEntries(choiceChainValues)
                    );
                }
                case 'boolean':
                default: {
                    // Jos variable ei ole muokattava, asetetaan aina defaultValue
                    // Jotkut tessit pakottaa lomarahan => ei voi muokata edes => varmistetaan, että aina menee defaulttina
                    const value = (isModifiable && currentValue)
                        // Arvo jo olemassa, esim vaihdetaan tessiä ja myös muokattava => käytetään olemassa olevaa arvoa
                        ? currentValue
                        // Muutoin oletusarvo
                        : defaultValue;

                    return Object.assign(
                        acc,
                        {
                            [name]: {
                                // Castataanko stringi booleaniksi vai ei (Number('0') => Boolean(0) = false)
                                value: (type === 'boolean') ? Boolean(Number(value)) : value,
                                variable: item,
                            }
                        }
                    );
                }
            }
        }, {})
);

