import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SuoraTyoAPI from 'shared/decorators/SuoraTyoAPI';
import { MDSpinner } from 'shared/components';

/**
 * Hakee kirjautuneen käyttäjän attribuutit, parsii ne helpompaan muotoon ja passaa ne eteenpäin.
 * TODO: 1) Mahdollisuus hakea annetun käyttäjän perusteella. 2) Liitä tämä gatekeeperiin jos/kun SPA on käytössä.
 * @param parameters - parametri-objekti. Nyt vain renderWhilePending (renderöidäänkö sisempi komponentti vaikka attribuuttien haku on vielä käynnissä).
 * @returns {function(*): UserAttributes}
 */
const userattributes = (parameters) => (DecoratedComponent) => {
    @SuoraTyoAPI({
        'get.userAttributes': ({ userId = '_self' }) => Routing.generate('api_1_get_user_attributes', { user: userId }),
        'put.userAttribute': ({ userAttributeId, userId = '_self' }) => Routing.generate('api_1_put_user_attribute', {
            user: userId,
            attribute: userAttributeId,
        }),
        'post.userAttribute': ({ userId = '_self' }) => Routing.generate('api_1_post_user_attribute', {
            user: userId,
        }),
    }, {
        initialLoad: false,
    })
    class UserAttributes extends Component {
        state = {
            initialLoad: true,

            /**
             * Haetut kirjautuneen käyttäjän attribuutit.
             */
            userAttributes: {},
        };

        static propTypes = {
            get: PropTypes.func.isRequired,
            put: PropTypes.func.isRequired,
            post: PropTypes.func.isRequired,
            pending: PropTypes.object,
        };

        static defaultProps = {
            pending: {
                get: 0,
            },
        };

        constructor(props) {
            super(props);
            if (_.get(parameters, 'initialLoad', true) === true) {
                props.get('userAttributes');
            }
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            this.parseAttributes(nextProps);
        }

        /**
         * Hakee attribuutin.
         * @param attributeName - attribuutin nimi
         * @param stringToBoolean - muunnetaanko '0' = false ja '1' = true
         * @returns {*}
         */
        getAttribute = (attributeName, stringToBoolean = true) => {
            const attribute = _.get(this.state.userAttributes, attributeName, undefined);
            const value = _.get(attribute, 'value', false);

            if (value) {
                // Halutaan tarkasti tutkia onko saatu arvo 0 tai 1 eikä yrittää vääntää sitä minkään mankelin läpi.
                if (stringToBoolean) {
                    if (value === '0') return false;
                    if (value === '1') return true;
                }

                // Muutoin palautetaan mitä tulikaan
                return value;
            }

            return undefined;
        };

        /**
         * Muuntaa booleanit stringeiksi.
         * @param value
         * @returns {*}
         */
        booleanToString = (value) => {
            if (value === true) return '1';
            if (value === false) return '0';

            return value;
        };

        /**
         * Tallennetaan käyttäjän attribuutti. Jos attribuutti löytyy jo haetuista attribuuteista päivitetään se.
         * Ei tallenna mitään ennen kuin käyttäjän tietojen haku on päättynyt.
         * @param name - joko attribuutti-objekti tai attribuutin nimi attribuuttia tallennettaessa
         * @param value - attribuutin arvo attribuuttia tallennettaessa
         * @param userId - käyttäjän id jolle attribuutti tallennetaan. Oletuksena kirjautunut käyttäjä.
         */
        saveAttribute = (name, value, userId = '_self') => {
            if (this.props.pending['get'] > 0) return;

            let userAttribute;

            const isNewAttribute = !_.has(this.state.userAttributes, name);

            const backendValue = this.booleanToString(value);

            if (isNewAttribute) {
                this.props.post('userAttribute', {
                    name,
                    value: backendValue,
                }, {
                    userId,
                });
            } else {
                const attribute = this.state.userAttributes[name];
                const attributeId = _.get(attribute, 'userAttributeId', false);

                if (attributeId) {
                    userAttribute = {
                        name: name,
                        value: backendValue,
                    };

                    this.props.put('userAttribute', userAttribute, {
                        userAttributeId: attribute.userAttributeId,
                        userId,
                    });
                } else {
                    console.error(`userAttributeId not found for attribute ${name}. Won't update.`);
                }
            }
        };

        parseAttributes = (props) => {
            let userAttributes = [];
            const data = _.get(props, 'data.userAttributes', []);

            // Kun lataus on valmis parsitaan attribuutit key / value pareiksi.
            if (props.pending['get'] === 0) {
                userAttributes = data.slice();

                // Poistetaan objektin name turhana
                const parsedAttributes = _.map(userAttributes, (attribute) => _.omit(attribute, ['name']));


                // Yhdistetään objektin name / objektin sisältö
                userAttributes = _.zipObject(_.map(userAttributes, 'name'), parsedAttributes);

                // Esimerkki:

                // Ennen:
                // [
                //     0: {
                //         name: 'hideFullSSN',
                //         value: "0",
                //         userAttributeId: 19523,
                //     }
                // ]

                // Jälkeen:
                // {
                //     hideFullSSN: {
                //         value: "0",
                //         userAttributeId: 19523,
                //     }
                // }
                this.setState({
                    userAttributes,
                });
            }
        };

        render() {
            if (_.get(parameters, 'renderWhilePending', true) || this.props.pending['get'] === 0) {
                return (
                    <DecoratedComponent
                        {...this.props}
                        userAttributes={this.state.userAttributes}
                        saveAttribute={this.saveAttribute}
                        getAttribute={this.getAttribute}
                    />
                );
            }

            return (
                <MDSpinner wrapped />
            );
        }
    }

    return UserAttributes;
};

export default userattributes;
