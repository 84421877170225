import produce from 'immer';
import { push } from 'connected-react-router';
import api from 'api';

export const contact = {
    state: [],
    reducers: {
        setContacts: (state, payload) => payload,
    },
    selectors: {
        getContacts: (state) => state,
        getContactById: (state, id) => state.find((contact) => contact.contactId === parseInt(id)),
    },
    effects: (dispatch) => ({
        /**
         * @return {Promise<void>}
         */
        async fetchContacts() {
            try {
                const { data, status } = await api.get('/api/v2/contacts');
                if (status === 'ok') {
                    this.setContacts(data);
                }
            } catch (e) {
                dispatch.notifications.addError(_trans('contact.notifications.error.get'));
                console.error(e);
            }
        },
        /**
         *
         * @param model
         * @param rootState
         * @param route
         * @return {Promise<void>}
         */
        async postContacts(model, { contact: rootState }, route) {
            try {
                const { status, data } = await api.post('/api/v2/contacts', model);
                if (status === 'ok') {
                    this.setContacts(rootState.concat([data]));
                    dispatch(push(route));
                    dispatch.notifications.addSuccess(_trans('contact.notifications.success.post'));
                }
            } catch (e) {
                dispatch.notifications.addError(_trans('contact.notifications.error.post'));
                console.error(e);
            }
        },
        /**
         *
         * @param contactId
         * @param model
         * @param rootState
         * @param route
         * @return {Promise<void>}
         */
        async putContact({ contactId, ...model }, { contact: rootState }, route) {
            try {
                const { status, data } = await api.put(`/api/v2/contacts/${contactId}`, model);
                if (status === 'ok') {
                    this.setContacts(produce(rootState, (draftState) => {
                        const index = rootState.findIndex((c) => c.contactId === parseInt(contactId));
                        if (index !== -1) {
                            draftState[index] = data;
                        }
                    }));
                    dispatch(push(route));
                    dispatch.notifications.addSuccess(_trans('contact.notifications.success.post'));
                }
            } catch (e) {
                dispatch.notifications.addError(_trans('contact.notifications.error.post'));
                console.error(e);
            }
        },
        /**
         *
         * @param contactId
         * @param rootState
         * @return {Promise<void>}
         */
        async deleteContact(contactId, { contact: rootState }) {
            try {
                await api.del(`/api/v2/contacts/${contactId}`);
                this.setContacts(produce(rootState, (draftState) => {
                    const index = rootState.findIndex((c) => c.contactId === parseInt(contactId));
                    if (index !== -1) {
                        draftState.splice(index, 1);
                    }
                }));
                dispatch.notifications.addSuccess(_trans('contact.notifications.success.del'));
            } catch (e) {
                dispatch.notifications.addError(_trans('contact.notifications.error.del'));
                console.error(e);
            }
        }
    })
};
