import * as Yup from 'yup';
import validators, { errorMessages } from 'shared/validators';
import { taxCardTypes, validationTaxCardTypes } from 'shared/constants/taxCardTypes';

export const validationSchema = {
    // Kaikkien verokorttien validoinnit
    type: Yup.number().required(),
    taxDeduction1: Yup.string()
        .required(_trans('validation.required'))
        .test('isDecimal', errorMessages.isDecimal, validators.isPositiveDecimal)
        .test('isLTE', errorMessages.isLTE, (value) => validators.isLTE(value,90)),

    // Lähdeverokortin ja perusverokortin validoinnit
    startDate: Yup.string()
        .when('type', {
            is: (type) => validationTaxCardTypes.includes(type) || type === taxCardTypes.TAX_AT_SOURCE,
            then: Yup.string()
                .required(_trans('validation.required'))
                .test('isDate', errorMessages.isDate, validators.isISODate),
        }),

    // Perusverokortin validoinnit
    taxDeduction2: Yup.string()
        .when('type', {
            is: (type) => validationTaxCardTypes.includes(type),
            then: Yup.string()
                .required(_trans('validation.required'))
                .test('isDecimal', errorMessages.isDecimal, (value) => value ? validators.isPositiveDecimal(value) : true)
                .test('isLTE', _trans('tax_card.form.tax_deduction_2.max_percent_error'), (value) => validators.isLTE(value,90)),
        })
        .when('taxDeduction1', (taxDeduction1, Schema) => Schema.test('isLT', _trans('tax_card.form.tax_deduction.error'),
            (value) => value
                ? validators.isLTE(taxDeduction1, value)
                : true
        )),
    incomeLimit1: Yup.string()
        .when('type', {
            is: (type) => validationTaxCardTypes.includes(type),
            then: Yup.string()
                .required(_trans('validation.required'))
                .test('isDecimal', errorMessages.isDecimal, validators.isPositiveDecimal)
        }),
    previousSalary: Yup.string()
        .when('type', {
            is: (type) => type === taxCardTypes.TYPE_B,
            then: Yup.string()
                .required(_trans('validation.required'))
                .test('isDecimal', errorMessages.isDecimal, validators.isPositiveDecimal)
        }),

    // Lähdeverokortin validoinnit
    dateOfArrival: Yup.string()
        .when('type', {
            is: (type) => type === taxCardTypes.TAX_AT_SOURCE,
            then: Yup.string()
                .required(_trans('validation.required'))
                .test('isDate', errorMessages.isDate, validators.isISODate),
        }),
    citizenship: Yup.string()
        .when('type', {
            is: (type) => type === taxCardTypes.TAX_AT_SOURCE,
            then: Yup.string().required(_trans('validation.required'))
        }),
    hasA1E101: Yup.bool()
        .when('type', {
            is: (type) => type === taxCardTypes.TAX_AT_SOURCE,
            then: Yup.bool().required(_trans('validation.required')),
        }),
    entitledToIncomeReduction: Yup.bool()
        .when('type', {
            is: (type) => type === taxCardTypes.TAX_AT_SOURCE,
            then: Yup.bool().required(_trans('validation.required')),
        }),
    addressInCountryOfOrigin: Yup.string()
        .when('type', {
            is: (type) => type === taxCardTypes.TAX_AT_SOURCE,
            then: Yup.string().required(_trans('validation.required')),
        }),
    excludesSava: Yup.bool()
        .when('type', {
            is: (type) => type === taxCardTypes.TAX_AT_SOURCE,
            then: Yup.bool().required(_trans('validation.required')),
        }),
};

// Haetusta verokortista ei voi muokata, veroprosenttia alemmaksi kuin alkuperäinen veroprosentti
export const veroFetchedTaxDeduction1Validation = (event, taxCard) => {
    if (validationTaxCardTypes.includes(taxCard?.type) && taxCard?.isVeroFetched) {
        const taxDeduction = taxCard.originalVeroTaxCard?.taxDeduction1 ?? taxCard.taxDeduction1;

        if (event < taxDeduction) {
            return _trans('tax_card.form.tax_deduction_1.vero_fetched_error', { originalTaxDeduction: taxDeduction });
        }
    }
};
