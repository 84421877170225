import React from 'react';
import PropTypes from 'prop-types';
import { Value } from 'shared/components/Value';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { payDayModes } from 'shared/constants/payDayModes';

/**
 * Valittu maksupäivä.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const Payday = ({ value, values }) => {
    if (value === '') {
        return _trans('Ei valintaa', {}, 'common');
    }
    const paydayMode = values[fieldNames.PAY_DAY_MODE];

    const translationKey = parseInt(paydayMode, 10) === payDayModes.PAYDAY_NTH_WEEK_DAY
        ? 'week_day'
        : 'day';

    return (
        <Value>{value}. {_trans(translationKey)}</Value>
    );
};

Payday.propTypes = {
    value: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
};

export default Payday;
