import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { select } from '@rematch/select';
import { connect } from 'react-redux';
import { InputGroup, StField, StLabel, StHelp } from 'shared/components';
import numberGenerator from 'shared/utils/tradeUnionReferenceNumberGenerator';
import Button from 'shared/components/Button';

@connect((state) => ({
    hasGeneratedNumbers: select.tradeUnion.hasGeneratedNumbers(state),
}))
export default class TradeUnionReferenceNumberGeneratorInput extends Component {
    state = {
        value: '',
    };

    static propTypes = {
        year: PropTypes.string.isRequired,
        isReadOnly: PropTypes.bool.isRequired,
        dispatch: PropTypes.func,
        hasGeneratedNumbers: PropTypes.bool,
    };

    static defaultProps = {
        dispatch() {},
        hasGeneratedNumbers: false,
    };

    onParse = (event) => {
        const { dispatch } = this.props;
        event.stopPropagation();
        dispatch.tradeUnion.setGeneratedNumbers(numberGenerator.generate(this.state.value, this.props.year));
    };

    render() {
        return (
            <div className="o-form o-form--vertical u-margin-bottom-large">
                <StLabel>
                    {_trans('trade_union_membership.reference_numbers.generator.title')}
                </StLabel>
                <StField>
                    <InputGroup>
                        <input
                            type="text"
                            size={20}
                            maxLength={20}
                            placeholder={_trans('trade_union_membership.reference_numbers.generator.placeholder')}
                            onChange={(event) => this.setState({ value: event.target.value })}
                            disabled={this.props.isReadOnly}
                            aria-describedby="generatorInput"
                        />
                        <Button
                            onClick={this.onParse}
                            disabled={this.state.value.length < 4}
                            primary
                        >
                            {_trans('button.fill')}
                        </Button>
                        <Button
                            onClick={() => this.props.dispatch.tradeUnion.resetGeneratedNumbers()}
                            disabled={! this.props.hasGeneratedNumbers}
                            negative
                        >
                            {_trans('button.clear')}
                        </Button>
                    </InputGroup>
                    <StHelp id="generatorInput">
                        {_trans('trade_union_membership.reference_numbers.generator.tooltip')}
                    </StHelp>
                </StField>
            </div>

        );
    }
}
