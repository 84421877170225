export const errorMessages = {
    isRequired:                         'Kenttä on pakollinen. ',
    isSsn:                              'Anna oikeanmuotoinen henkilötunnus. ',
    isValidIban:                        'Tilinumero ei ole kelvollinen IBAN-tilinumero. ',
    isYTunnus:                          'Anna oikeanmuotoinen Y-tunnus. ',
    isEmail:                            'Anna oikeanmuotoinen sähköpostiosoite. ',
    noUmlauts:                          'Syöte ei saa sisältää ääkkösiä. ',
    isInteger:                          'Anna kokonaisluku. ',
    isDecimal:                          'Anna numeerinen arvo. ',
    isNumeric:                          'Anna numeerinen arvo. ',
    isNonNegative:                      'Anna positiivinen arvo. ',
    isNegative:                         'Anna negatiivinen arvo. ',
    minLength:                          'Vähimmäismäärää ei saavutettu. ',
    maxLength:                          'Enimmäismäärä ylitetty. ',
    '<':                                'Anna pienempi arvo. ',
    '<=':                               'Anna pienempi arvo. ',
    '>':                                'Anna suurempi arvo. ',
    '>=':                               'Anna suurempi arvo. ',
    isDate:                             'Anna oikeanmuotoinen päivämäärä. ',
    isGTDate:                           'Anna myöhempi päivämäärä. ',
    isLTDate:                           'Anna aikaisempi päivämäärä. ',
    isGTNowDate:                        'Anna myöhempi päivämäärä. ',
    holidayBonusAndDayCount:            Translator.trans('contract.holiday_data_import.virhe_lomaraha_lomakorvaus'),
    earnedAndSpentHolidayDayCount:      Translator.trans('contract.holiday_data_import.virhe_kaytetyt_tuodut'),
    holidayDayCountAndFullWorkingYear:  Translator.trans('contract.holiday_data_import.virhe_kertyneet_tyovuosi_ei_taynna'),
    isHolidayDayDay:                    'Arvo voi olla joko n tai n.5 (esim 2 tai 5.5)',
    isHolidayDayMonth:                  'Syötä arvo muodossa YYYY-MM',
    isHolidayDayYear:                   'Syötä arvo muodossa YYYY',
    isDecimalOrEmpty:                   'Anna numeerinen arvo tai jätä kenttä tyhjäksi. ',
    isHolidayDayDayOrEmpty:             'Arvo voi olla joko n tai n.5 (esim 2 tai 5.5) tai jätä kenttä tyhjäksi. ',
    isDefaultRequiredValue:             'Kenttä ei saa olla tyhjä. '
};
