export const tyelInsuranceStates = {
    // Nämä haettu InsuranceState.php:stä
    /**
     * Active insurance
     */
    ACTIVE: 1,
    /**
     * Archived insurance
     * Vakuutuksen päättämisprosessi on tehty
     */
    ARCHIVED: 2,
    /**
     * Draft insurance. Used mainly between when new individual accident insurance
     * is created for the user and when the first payroll is paid.
     */
    DRAFT: 3,
    /**
     * Tarkistuksessa että asiakas voi käyttää syöttämäänsä vakuutusta
     */
    VALIDATION: 4,
};

const tyelInsuranceStateMap = {
    [tyelInsuranceStates.ACTIVE]:'Aktiivinen',
    [tyelInsuranceStates.ARCHIVED]: 'Päätetty',
    [tyelInsuranceStates.DRAFT]: 'Luonnos',
    [tyelInsuranceStates.VALIDATION]: 'Selvityksessä',
};

export function translateTyelInsuranceState(state) {
    return state in tyelInsuranceStateMap ? tyelInsuranceStateMap[state] : '';
}
