import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import Button from 'shared/components/Button';

/**
 * React-routerin kanssa toimiva nappi.
 * @param to
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const RouteButton = ({ to, children, ...rest }) => {
    const dispatch = useDispatch();
    return (
        <Button onClick={() => dispatch(push(to))} {...rest}>
            {children}
        </Button>
    );
};

RouteButton.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node,
};

RouteButton.defaultProps = {
    children: null,
};
