import React from 'react';
import PropTypes from 'prop-types';
import { CustomComponent } from 'shared/ReForm/containers/CustomBlocks/CustomComponent';
import { InputBlock } from 'shared/ReForm/containers/InputBlock';
import { blocks as blockTypes } from 'shared/ReForm/constants/blocks';
import _transObj from 'shared/utils/_transObj';
import Feedback from 'shared/components/Feedback';
import { isBlockValid } from 'shared/ReForm/utils/isBlockValid';
import { parseBlockProperties } from 'shared/ReForm/utils/parseBlockProperties';
import Separator from 'shared/components/Separator';
import { resolveStyles } from 'shared/ReForm/utils/resolveStyles';
import { customComponents } from 'ContractV3/constants/customComponents';
import { FormField } from 'shared/components/Formik';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';

export const Block = ({ item, arrayName, rootName, blocks, index, arrayHelpers, uid }) => {
    const { block, type, attributes, style } = item;
    if (! isBlockValid(item)) {
        return (
            <div>
                Unknown block.
            </div>
        );
    }

    switch (block) {
        // Vain printissä
        case blockTypes.PAGE_BREAK_AFTER:
            return null;

        case blockTypes.SEPARATOR:
            return <Separator id={uid} />;

        case blockTypes.FEEDBACK:
            return (
                <div style={resolveStyles(style)}>
                    <Feedback type={type} id={uid}>
                        {_transMd(_transObj(item.message ?? {}))}
                    </Feedback>
                </div>
            );

        // Puhdasta tekstiä. Tukee markdownia.
        case blockTypes.TEXT: {
            const { useParagraphs, ...restAttributes } = attributes ?? {};
            return (
                <div {...restAttributes} style={resolveStyles(style)} id={uid}>
                    {_transMd(_transObj(item.text ?? {}), useParagraphs ?? false)}
                </div>
            );
        }

        case blockTypes.COMPONENT: {
            const blockProperties = parseBlockProperties(item, rootName !== '' ? `${arrayName}.` : '');
            const fieldProps = getFieldProps(blockProperties);
            const componentName = item?.component;

            // Custom-komponenteila saadaan tietyt oletusarvot.
            const { hasFormField = false } = customComponents[componentName] ?? {};
            const props = Object.assign({}, blockProperties, { arrayName, rootName, index, blocks, arrayHelpers, id: uid });

            return hasFormField
                ? (
                    <CustomComponent
                        componentName={item.fullComponentName}
                        props={props}
                    />
                )
                : (
                    <FormField {...fieldProps} isContentFormField={false}>
                        <CustomComponent
                            componentName={item.fullComponentName}
                            props={props}
                        />
                    </FormField>
                );
        }

        case blockTypes.INPUT:
            return (
                <InputBlock
                    blocks={blocks}
                    arrayName={arrayName}
                    rootName={rootName}
                    index={index}
                    arrayHelpers={arrayHelpers}
                    block={item}
                />
            );

        default:
            return (
                <div>
                    Unknown block.
                </div>
            );
    }
};

Block.propTypes = {
    item: PropTypes.object.isRequired,
    // Mahdolliset monistettavat palikat (FieldArrayn sisällä)
    blocks: PropTypes.array,
    // FieldArrayn kanssa käytettävä päänimen ja indeksin yhdistelmä (esim. salaries.0)
    arrayName: PropTypes.string,
    // FieldArrayn kanssa käytettävä päänimi
    rootName: PropTypes.string,
    // FieldArrayn antama indeksi.
    index: PropTypes.number,
    // FieldArray helpperit.
    arrayHelpers: PropTypes.object,
    // Uniikki id johon voidaan DOMissa viitata
    uid: PropTypes.string.isRequired,
};

Block.defaultProps = {
    blocks: [],
    arrayName: '',
    rootName: '',
    index: 0,
    arrayHelpers: {},
};
