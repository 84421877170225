import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { parseValue } from './TotalSalary';
import { CostReimbursementAmount } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Salaries/CostReimbursementAmount';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';
import { hiddenFields } from 'ContractV3/constants/hiddenFields';
import { Divisors } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Salaries/Divisors';

/**
 * Peruskulukorvauksen määrä.
 * Päivittää myös piilossa olevat arvot.
 * Suuri osa väännetty CostReimbursements.jsx:stä (soppariv2:sta) Formikkimuotoon.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CostReimbursement = ({ arrayName, label }) => {
    const [isTableSalaryInUseField] = useField(resolveArrayName(arrayName, fieldNames.IS_TABLE_SALARY_IN_USE));
    const isTableSalaryInUse = isTableSalaryInUseField?.value ?? true;
    const hasCostReimbursementTableSalaries = useSelector(select.contract.hasCostReimbursementTableSalaries);

    // Päivitetään "pellin alla" kuljeteltavat kentät. Nämä tulevat arrayBlocks.js:n kautta piilokenttinä.
    const [,,metadataHelpers] = useField(resolveArrayName(arrayName, hiddenFields.COST_REIMBURSEMENT_METADATA_ID));
    const [,,specialMetadataHelpers] = useField(resolveArrayName(arrayName, hiddenFields.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE_METADATA_ID));

    const metadataId = useSelector(select.tableSalary.getCostReimbursementMetadataId);
    const metadataSpecialAllowanceId = useSelector(select.tableSalary.getCostReimbursementSpecialAllowanceMetadataId);

    const isNewContract = useSelector(select.contract.isNewContract);
    const isUnfinishedContract = useSelector(select.contract.isUnfinishedContract);
    const employees = useSelector(isNewContract
        ? select.employees.getEmployees
        : select.contract.getEmployees);
    const isLoadingEmployees = useSelector((state) => state.loading.effects.employees.fetchEmployees);
    const [employeeIdField] = useField(fieldNames.EMPLOYEE);
    const selectedEmployees = useMemo(() => employees.filter((employee) => employeeIdField.value.includes(employee.userId)), [employees, employeeIdField.value]);

    const [divisorField ,, divisorHelpers] = useField(fieldNames.CONTRACT_GROUP_DIVISORS);
    const divisorValue = Array.isArray(divisorField.value) ? divisorField.value : [];
    const [costReimbursementField] = useField(resolveArrayName(arrayName, fieldNames.COST_REIMBURSEMENT));
    const totalSalary = parseValue(costReimbursementField.value);

    const costDivisorFields = divisorValue.filter((divisor) => divisor.hasOwnProperty('costDivisor'));

    // Päivitetään metadatan id
    useEffect(() => {
        metadataHelpers.setValue(metadataId);
    }, [metadataId]);

    useEffect(() => {
        specialMetadataHelpers.setValue(metadataSpecialAllowanceId);
    }, [metadataSpecialAllowanceId]);

    return (
        <>
            {(isTableSalaryInUse && hasCostReimbursementTableSalaries) && (
                <CostReimbursementAmount
                    label={label}
                    arrayName={arrayName}
                    name={resolveArrayName(arrayName, fieldNames.COST_REIMBURSEMENT)}
                />
            )}
            {(! isLoadingEmployees
                && selectedEmployees.length > 1
                && totalSalary !== 0
            ) && (
                <Divisors
                    divisorKey="costDivisor"
                    canChangeDivisors={isNewContract || isUnfinishedContract}
                    divisors={costDivisorFields}
                    divisorHelpers={divisorHelpers}
                    employees={selectedEmployees}
                    totalSalary={totalSalary}
                />
            )}
        </>
    );
};

CostReimbursement.propTypes = {
    arrayName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default CostReimbursement;
