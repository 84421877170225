/**
 * Resolvoi URL templaatin, jossa parametrejä Route systeemiä varten.
 *
 * Esimerkiksi routen määritelmä on
 * const MY_ROUTE = `${Routing.generate('backend_route')/:param1/something}`;
 *
 * Tällöin kyseisen templaatin voi resolvoida näin:
 * const url = resolveUrl(MY_ROUTE, {param1: 12345});
 *
 * Lopullinen url tällöin on:
 * http://annettu/backend/osuus/12345/something
 *
 * @param template
 * @param params
 * @returns {string}
 */
export default function (template, params) {
    const resolvedTemplate = Object.entries(params ?? {})
        // Jätetään tyhjät avaimet lisäämättä
        .reduce((acc, [key, value]) => (value !== undefined
            ? acc.replace(`:${key}`, value)
            : acc), template);
    //Poisteaan ylimääräset parametrit templaatista sekä viimeinen / ja tyhjät query stringit
    return resolvedTemplate.replace(/:\w+\??\/?/gm, '').replace(/\/$/gm, '').replace(/\?$/gm, '');
}
