export const materialActionTypes = {
    THUMBS: 'thumbs',
    WATCH_MATERIAL: 'watch_material',
};

export const materialTypes = {
    LINK: 'link',
    VIDEO: 'video',
    PDF: 'pdf',
};
