import React from 'react';
import PropTypes from 'prop-types';
import { StLabel, StField } from 'shared/components/StForm';
import { ChipStack } from 'shared/components';
import AutoComplete from 'shared/containers/AutoComplete';

const DimensionSelector = ({
    dimensions,
    selectedDimensions,
    onSelectDimension,
    removeSelectedDimension,
    itemKey,
    label,
}) => {
    // Jos ei löydy yhtään dimensiota tai valittua niin piilotetaan valitsin
    if (dimensions.length > 0 || selectedDimensions.length > 0) {
        return (
            <div>
                <StLabel>{label ? label : _trans('dimension.choose_dimensions')}</StLabel>
                <StField>
                    <AutoComplete
                        hasClearValueOnSelect
                        items={dimensions}
                        itemKey={itemKey}
                        itemValue={itemKey}
                        onSelect={onSelectDimension}
                    />
                    <div className="u-margin-top-small">
                        <ChipStack
                            hasRunningNumbers
                            onRemove={removeSelectedDimension}
                            items={selectedDimensions}
                            itemLabelKey={itemKey}
                            itemValueKey={itemKey}
                        />
                    </div>
                </StField>
            </div>
        );
    }
    return <div>{_trans('dimension.no_dimensions')}</div>;
};

DimensionSelector.propTypes = {
    dimensions: PropTypes.arrayOf(PropTypes.object),
    selectedDimensions: PropTypes.arrayOf(PropTypes.object),
    onSelectDimension: PropTypes.func,
    removeSelectedDimension: PropTypes.func,
    itemKey: PropTypes.string,
    label: PropTypes.string,
};

DimensionSelector.defaultProps = {
    dimensions: [],
    selectedDimensions: [],
    onSelectDimension() {},
    removeSelectedDimension() {},
    itemKey: 'name',
    label: '',
};

export default DimensionSelector;
