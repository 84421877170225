import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import {
    getEmployerPayslipDeliveryMethodOptions,
} from 'ContractV3/constants/getPayslipDeliveryMethods';
import { Value } from 'shared/components/Value';

/**
 * Valittu työnantajan palkkalaskelman toimitustapa.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const EmployerPayslipDeliveryMethod = ({ value }) => {
    // Tallennetulla sopparilla TT saadaan suoraan sopparin tiedoista
    const employer = useSelector(select.contract.getEmployer);

    const benefitDecisionId = useSelector(select.contract.getBenefitDecisionId);
    const options = useMemo(() => (
        // Jos avustuspäätös löytyy oletetaan että ollaan henkkaripuolella
        getEmployerPayslipDeliveryMethodOptions(! isNaN(benefitDecisionId))
    ), [benefitDecisionId]);
    const payslipDeliveryMethod = options.find((method) => parseInt(method.value, 10) === parseInt(value, 10));

    return (
        <div>
            <Value ariaDescribedBy="employerDeliveryInfo">{payslipDeliveryMethod?.label ?? _trans('Ei toimitusta', {}, 'jobContract')}</Value>
            {payslipDeliveryMethod?.description && (
                <div id="employerDeliveryInfo">
                    {_transMd(
                        payslipDeliveryMethod.description,
                        { email: employer?.email },
                        {},
                        'contract'
                    )}
                </div>
            )}
        </div>
    );
};

EmployerPayslipDeliveryMethod.propTypes = {
    value: PropTypes.string.isRequired,
};

export default EmployerPayslipDeliveryMethod;
