import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button, Popover } from 'shared/components/index';

const RadioList = ({
    valueKey,
    labelKey,
    items,
    name,
    value,
    isReadOnly,
    onChange,
    itemValueTranslator,
    tooltips,
    isDisabled,
}) => (
    <ol className="o-list-bare c-radio-list u-margin-none" id={name}>
        {_.map(items, (item, key) => {
            const isItemsObject = _.isObject(item);
            const itemValue = isItemsObject ? item[valueKey] : item;
            const itemLabel = isItemsObject ? item[labelKey] : itemValue;
            const isItemReadOnly = (isItemsObject ? !!item['isReadOnly'] : isReadOnly) || isReadOnly;
            const hasTooltip = _.has(tooltips, itemValue);

            return (
                <li role="presentation" key={key}>
                    <label>
                        <input
                            type="radio"
                            name={name}
                            value={itemValue}
                            checked={value === itemValue}
                            onChange={onChange}
                            disabled={isItemReadOnly || isDisabled}
                        />
                        <span>{itemValueTranslator ? itemValueTranslator(itemValue) : itemLabel}</span>
                        {hasTooltip && (
                            <span className="u-line-height-flat">
                                <Popover>
                                    <Button mdIcon="help_outline" flat modifierClass="u-margin-left-tiny u-align-middle" />
                                    <div style={{ 'maxWidth': '320px' }}>
                                        {tooltips[itemValue]}
                                    </div>
                                </Popover>
                            </span>
                        )}
                    </label>
                </li>
            );
        })}
    </ol>
);

RadioList.defaultProps = {
    valueKey: 'id',
    labelKey: 'name',
    isReadOnly: false,
    itemValueTranslator: false,
    onChange() {},
    value: '',
    tooltips: {},
    isDisabled: false,
};

RadioList.propTypes = {
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
    items: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    isReadOnly: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    /**
     * Tooltippejä voidaan antaa, annetaan objekti muodossa, jossa key on value ja value on tooltippi
     */
    tooltips: PropTypes.object,

    /**
     * Funktio jonka läpi arvot mankeloidaan josta saadaan label.
     */
    itemValueTranslator: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),

    /**
     * Yleinen disable kaikille radio buttoneille
     */
    isDisabled: PropTypes.bool,
};

export default RadioList;
