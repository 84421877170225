import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';
import Tooltip from './Tooltip';

function ProgressInfo(props) {
    const {
        value,
        max,
        startText,
        endText,
        progressText,
        header,
        isAnimated,
    } = props;

    let progress = value / max;

    if (progress > 1) progress = 1;

    const progressPercent = (progress * 100).toFixed(0);

    return (
        <article className="c-progress-info">
            <header className="u-margin-bottom-small">
                {header}
            </header>
            <div className="o-position-relative">
                <ProgressBar
                    value={value}
                    max={max}
                    size="small"
                    isValueVisible={false}
                    isAnimated={isAnimated}
                />
                <div
                    className="c-progress-info__value-knob-container o-pin o-pin--top"
                    style={{
                        left: `${progressPercent}%`,
                    }}
                >
                    <Tooltip title={progressText} modifierClass="c-progress-info__value-knob" />
                </div>
            </div>
            <footer className="o-stack o-stack--justify u-margin-top-tiny c-progress-info__legend">
                <span>{startText}</span>
                <span>{endText}</span>
            </footer>
        </article>
    );
}

ProgressInfo.defaultProps = {
    max: 100,
    startText: '',
    endText: '',
    isAnimated: false,
};

ProgressInfo.propTypes = {
    /**
     * Nykyinen arvo.
     */
    value: PropTypes.number.isRequired,

    /**
     * Maksimiarvo. Tästä ja arvosta lasketaan prosentuaalinen arvo.
     */
    max: PropTypes.number,

    /**
     * Palkin alapuolella vasemmalla puolella oleva teksti.
     */
    startText: PropTypes.string,

    /**
     * Palkin alapuolella oikealla puolella oleva teksti.
     */
    endText: PropTypes.string,

    /**
     * Pallossa oleva tooltip-teksti.
     */
    progressText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    /**
     * Infoheaderin sisältö.
     */
    header: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,

    /**
     * Onko progresspalkki animoitu
     */
    isAnimated: PropTypes.bool,
};

export default ProgressInfo;
