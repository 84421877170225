import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import {
    fixedTermAssignmentContractReason,
    fixedTermContractReason,
    translateFixedTermContractReason,
} from 'shared/constants/fixedTermContractReason';
import { ChoiceField } from 'shared/components/Formik/ChoiceField';
import { FormField, TextField } from 'shared/components/Formik';
import { parseOptions } from 'shared/ReForm/utils/parseOptions';
import { fieldNames } from 'ContractV3/constants/fieldNames';

/**
 * Määräaikaisuuden syy -valitsin. Jos valitaan Muu syy, pyytää kirjoittamaan sen.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const FixedTermReason = (props) => {
    const { attributes } = props;

    const [field] = useField(fieldNames.FIXED_TERM_REASON);
    const value = parseInt(field.value, 10);

    const isAssignmentContract = useSelector(select.contract.isAssignmentContract);
    // Toimeksiantosoppareilla eri syyt
    const reasons = isAssignmentContract
        ? fixedTermAssignmentContractReason
        : fixedTermContractReason;

    const fixedOptions = Object.values(reasons)
        .map((reason) => ({
            value: reason.toString(),
            label: translateFixedTermContractReason(reason),
        }));

    // Voidaan antaa myös omat optiot eikä esiasetettuja
    const options = (attributes?.options ?? []).length > 0
        ? parseOptions(attributes.options)
        : fixedOptions;

    return (
        <Fragment>
            <FormField {...getFieldProps(props)}>
                <ChoiceField options={options} />
            </FormField>
            {/* Jos muu syy, pyydetään kirjoittamaan se */}
            {value === fixedTermContractReason.FIXED_TERM_CONTRACT_REASON_OTHER_REASON && (
                <FormField
                    name={fieldNames.FIXED_TERM_REASON_CUSTOM}
                    label={_trans('Kirjoita määräaikaisuuden muu peruste', {}, 'jobContract')}
                    isRequired
                >
                    <TextField
                        validate={(value) => {
                            if (! value || value === '') {
                                return _trans('Kenttä on pakollinen.', {}, 'common');
                            }
                        }}
                    />
                </FormField>
            )}
        </Fragment>
    );
};

FixedTermReason.propTypes = {
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    attributes: PropTypes.object,
};

FixedTermReason.defaultProps = {
    attributes: {},
};

export default FixedTermReason;
