/**
 * Yleisiä määrityksiä esim. kenttien pituuksille.
 */

export default {
    // JobContractData.php:stä.
    DESCRIPTION_LENGTH: 500,
    MISC_MUTUAL_AGREEMENT_LENGTH: 2000,
    JOB_TARGET_ADDRESS_LENGTH: 200,
    POSITION: 40,
};
