import React from 'react';
import PropTypes from 'prop-types';
import { resolveTableSalaryValues } from 'ContractV3/utils/salaries/resolveTableSalaryValues';
import { Value } from 'shared/components/Value';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';

/**
 * Taulukkopalkkojen valinnat.
 * @param values
 * @param metadata
 * @returns {unknown[]}
 * @constructor
 */
export const TableSalaryValues = ({ values, metadata }) => resolveTableSalaryValues(values, metadata)
    .map(({ label, value }, key) => (
        <SummaryListItem term={label} key={key}>
            <Value modifierClass={value ? '' : 'u-muted'}>{value ?? 'Ei löydy'}</Value>
        </SummaryListItem>
    ));

TableSalaryValues.propTypes = {
    values: PropTypes.object.isRequired,
    metadata: PropTypes.array.isRequired,
};

