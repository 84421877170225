import React, { Fragment } from 'react';
import { StField, StLabel } from 'shared/components/index';
import { Field } from 'formik';
import { FormikErrors } from 'shared/components/Formik/index';

const PostCode = () => (
    <Fragment>
        <StLabel>
            {_trans('userForm.postCode.label')}
        </StLabel>
        <StField>
            <Field type="text" name="postCode" className="u-1/1" maxLength={5} />
            <FormikErrors name="postCode"/>
        </StField>
    </Fragment>
);

export default PostCode;
