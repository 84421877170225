export const payPeriodTypes = {
    ONE_WEEK: 1,
    TWO_WEEKS: 2,
    ONE_MONTH: 3,
    TWICE_A_MONTH: 5,
    CUSTOM: 10,
};

/**
 * 2 viikon palkkajakson 1. puoliskon alkupäivä
 */
export const FIRST_TWO_WEEK_PERIOD_START_DAY = '01';

/**
 * 2 viikon palkkajakson 1. puoliskon loppupäivä
 */
export const FIRST_TWO_WEEK_PERIOD_END_DAY = '15';

/**
 * 2 viikon palkkajakson 2. puoliskon loppupäivä
 */
export const LAST_TWO_WEEK_PERIOD_START_DAY = '16';

const payPeriodTypeMap = {
    [payPeriodTypes.ONE_WEEK]: _trans('constant.pay_period_type.one_week', {}, 'contract'),
    [payPeriodTypes.TWO_WEEKS]: _trans('constant.pay_period_type.two_weeks', {}, 'contract'),
    [payPeriodTypes.ONE_MONTH]: _trans('constant.pay_period_type.one_month', {}, 'contract'),
    [payPeriodTypes.TWICE_A_MONTH]: _trans('constant.pay_period_type.twice_a_month', {}, 'contract'),
    [payPeriodTypes.CUSTOM]: _trans('constant.pay_period_type.custom', {}, 'contract')
};

export function translatePayPeriodType(type) {
    if (type === '') {
        return _trans('Ei valintaa', {}, 'common');
    }
    return type in payPeriodTypeMap ? payPeriodTypeMap[type] : _trans('Tuntematon', {}, 'common');
}
