export const lockCorrectionTypes = {
    NOT_LOCKED: 0,
    INVOICE: 1,
    PERIOD: 2,
};

export const lockedCorrectionPaymentStateTranslationMap = {
    [lockCorrectionTypes.NOT_LOCKED]: _trans('Ei lukitusta', {}, 'common'),
    [lockCorrectionTypes.INVOICE]: _trans('Lasku', {}, 'common'),
    [lockCorrectionTypes.PERIOD]: _trans('Jakso', {}, 'common'),
};

// Huomaa, että jos lukitusta ei ole koskaan asetettu, niin sitä ei ole myöskään alustettu (se on undefined).
// Kannattaa tilanteesta riippuen huomioida koodissa.
export function translateLockedCorrectionPaymentState(lockedCorrectionState = 0) {
    return lockedCorrectionPaymentStateTranslationMap[lockedCorrectionState];
}
