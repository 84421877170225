import userRoles from 'shared/constants/userRoles';

/**
 * Päävalikon breakpointit: selaimen leveys pikseleinä.
 *
 * */
const navigationBreakPoints = {
    [userRoles.EMPLOYER]: {
        767: 99,
        790: 2,
    },
    [userRoles.EMPLOYEE]: {
        767: 99,
    },
    [userRoles.FAMILY_CARER]: {
        767: 99,
    },
    [userRoles.COMMUNE_OPERATOR]: {
        767: 99,
        783: 8,
        980:  7,
        1070: 5,
        1330: 4,
        1300: 3,
        1425: 2,
    },
    [userRoles.COMPANY_OPERATOR]: {
        767: 99,
        800: 6,
        920: 5,
        1010: 4,
        1100: 3,
        1340: 2,
    },
};

/**
 * Pääbreakpointit.
 * Tämän perusteella päätetään mitä piilotetaan käyttäjävalikosta ja mistä
 * kohtaa pienennetään yläpalkki.
 * @type {{MEDIUM: number, LARGE: number}}
 */
export const mainBreakPoints = {
    MEDIUM: 768,
    LARGE: 1024,
};

/**
 * Haetaan breakpointit navigaatioitemien piilottamiselle roolin mukaan.
 * @param userRole
 * @returns {*}
 */
export const getBreakPointsByUserRole = (userRole) => (
    userRole in navigationBreakPoints
        ? navigationBreakPoints[userRole]
        : { 767: 99 }
);

export const animationConfig = { mass: 2, tension: 3500, friction: 250 };

export const rolePages = {
    // Pääroolivalitsin (TT / TA, OPH)
    ROLE_LIST: 0,
    COMPANY_LIST: 1,
    COMMUNE_LIST: 2,
};

export const allowedUserRoles = [
    userRoles.EMPLOYEE,
    userRoles.EMPLOYER,
    userRoles.FAMILY_CARER,
    userRoles.BOOKKEEP,
    userRoles.COMPANY_OPERATOR,
    userRoles.COMMUNE_OPERATOR,
];

/**
 * Hakuavaimet roolilistaan roolin mukaisesti.
 */
export const roleItemKey = {
    [userRoles.COMPANY_OPERATOR]: 'companyName',
    [userRoles.COMMUNE_OPERATOR]: 'communeName',
};

/**
 * Käännösavain roolin mukaan.
 */
export const roleTranslationIdentifier = {
    [userRoles.COMPANY_OPERATOR]: 'company',
    [userRoles.COMMUNE_OPERATOR]: 'commune',
};
