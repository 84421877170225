import React from 'react';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { ConfirmButton } from 'shared/components';
import { MDIcon } from 'shared/components';

const TaxCardTodoActions = (props) => (
    <div>
        <Link
            className="c-button c-button--primary u-margin-right-small"
            to={props.value.value.url}
            target="_blank"
        >
            {props.isAllowAssignmentContracts
                ? _trans('Palkkiolaskelmaan ', {}, 'todo')
                : _trans('Palkkalaskelmaan ', {}, 'todo')
            }
            <MDIcon icon="open_in_new" />
        </Link>
        <ConfirmButton
            mdIcon="delete"
            negative
            flat
            text={_trans('Sivuuta', {}, 'common')}
            confirmText= {_trans('Haluatko varmasti sivuuttaa ilmoituksen?', {}, 'todo')}
            onClick={() => props.resolveSingle(props.value.original.id)}
        />
    </div>
);

TaxCardTodoActions.propTypes = {
    value: PropTypes.object.isRequired,
    resolveSingle: PropTypes.func.isRequired,
    isAllowAssignmentContracts: PropTypes.bool.isRequired
};

export default TaxCardTodoActions;
