/**
 * Sallitut bäkkäriltä tulevat TES-valintojen arvot.
 */
export default {
    NUMBER: 'number',
    BOOLEAN: 'boolean',
    CHOICE: 'choice',
    CHOICE_CHAIN: 'choice_chain',
    CHOICE_CHAIN_SUB: 'choice_chain_sub',
    DECIMAL_EURO: 'decimal_euro',
    DECIMAL: 'decimal',
    INTEGER: 'integer',
};

