import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renderöi syöttökentän viereen tarkoitetun laatikon jossa on lyhennyksen merkki (esim. €, %, kpl) ja
 * vaihtoehtoinen tooltippinä hoveroitaessa näytettävä selite.
 *
 * Esimerkkikoodi:
 *
 * <InputGroup>
 *     <input type="text" />
 *     <InputAbbr abbr="€" definition="Euroa" />
 * </InputGroup>
 *
 * @param abbreviation
 * @param definition
 * @returns {*}
 * @constructor
 */
const InputAbbr = ({ abbr, definition, }) => (
    <abbr title={definition} className="c-input-postfix u-muted">
        {abbr}
    </abbr>
);

InputAbbr.propTypes = {
    /**
     * Lyhenne tai akronyymi.
     */
    abbr: PropTypes.string.isRequired,

    /**
     * Selite.
     */
    definition: PropTypes.string,
};

InputAbbr.defaultProps = {
    definition: null,
};

export default InputAbbr;
