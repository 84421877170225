import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { LocalForm, Control } from 'react-redux-form';
import AffectedContracts from './HetaJoin/AffectedContracts';
import api from 'api';
import { StLabel, StErrors, StField } from 'shared/components/StForm/index';
import DateInput from 'shared/components/DateInput';
import Button from 'shared/components/Button';
import { Dialog, DialogFooter } from 'shared/components/Dialog/index';
import ActionBar from 'shared/components/ActionBar/ActionBar';
import SubmitButton from 'shared/components/SubmitButton';
import validators from 'shared/validators';
import { Feedback, } from 'shared/components';

export default class ChangeHeta extends Component {
    static propTypes = {
        isHetaMember: PropTypes.bool.isRequired,
        userId: PropTypes.number.isRequired,
        onError: PropTypes.func,
        onHetaSave: PropTypes.func,
        hetaJoinDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    };

    static defaultProps = {
        onError() {},
        onHetaSave() {},
        hetaJoinDate: new Date().toISOString(),

    };

    state = {
        errorMessage: '',
        isDialogOpen: false,
        isPending: false, // Onko lie request käynnissä
        dialogTitle: '',
        formLabel: '',
        hasHetaJoinAffectedContracts: false,
        contracts: [],
        contractTableSalaryMetadata: [],
        communeSalarySettings:  [],
        tableSalaryGroup: {},
        isEditingJoinDate: false,
        hetaJoinDate: '',
        isJoiningHeta: false,
        /**
         * Hack statenkäsittelyyn: tieto heta-jäsenyydestä ei päivity UI:lle heti liittymisen jälkeen
         *
         * @deprecated
         */
        hasSuccessfullHetaJoin: false,
    };

    onCloseDialog = () => {
        this.setState({
            isEditingJoinDate: false,
            isDialogOpen: false,
            hasHetaJoinAffectedContracts: false,
        });
    };

    /**
     * @deprecated
     */
    isHetamember = () => {
        const { isHetaMember } = this.props;

        return this.state.hasSuccessfullHetaJoin || isHetaMember;
    };

    /**
     * Hetaan liittyminen / liittymispäivän muokkaaminen.
     * TODO: Alkaa olla koko Hetaan liittyminen sen verran häiriintynyttä propsien eestakasheiluttelukoodia
     * että seuraavalla kerralla saa heittää sharedin alta mäkeen ja omaan storeen tämä.
     * Tämä yks _NAPPI_ tekee AIVAN LIIKAA asioita.
     *
     * @param hetaDate
     */
    onHetaChange = (hetaDate) => {
        this.setState({
            isDialogOpen: false,
            hasHetaJoinAffectedContracts: false,
            isEditingJoinDate: false,
            isJoiningHeta: false,
            hasSuccessfullHetaJoin: true,
        });

        this.props.onHetaSave({ hetaJoinDate: hetaDate, isHeta: true, });
    };

    onLeaveHeta = async (model) => {
        const isHetaMember = this.isHetamember();
        const { isEditingJoinDate } = this.state;

        this.setState({ isPending: true });

        const hetaDate = moment(model.date).format('YYYY-MM-DD');
        let data = [{}];
        if (isEditingJoinDate) {
            data = [{
                op: 'replace',
                path: 'hetaJoinDate',
                value: hetaDate,
            }];
        } else {
            data = [{
                op: 'replace',
                path: 'isHeta',
                value: !isHetaMember,
            }, {
                op: 'replace',
                path: 'hetaLeaveDate',
                value: hetaDate,
            }];
        }

        try {
            await api
                .patch(Routing.generate('api_1_patch_user', { user: this.props.userId }), { data })
                .then(() => {
                    this.setState({
                        isPending: false,
                        isDialogOpen: false,
                        hasSuccessfullHetaJoin: false,
                    });

                    if (this.state.isEditingJoinDate) {
                        this.props.onHetaSave({ hetaJoinDate: hetaDate, isHeta: isHetaMember });
                    } else {
                        this.props.onHetaSave({ hetaLeaveDate: hetaDate, isHeta: !isHetaMember });
                    }
                });
        } catch (error) {
            console.log(error);
            this.props.onError(_.get(error, 'response.body.message', _trans('userForm.hetaJoinAffectedContracts.notifications.error.heta_leave')));
        }
    };

    /**
     * Haetaan annetulla päivämäärällä sopparit ja tutkitaan löytyykö niiltä palkkaryhmätieto.
     * Jos ei löydy hoidetaan Hetaan liittyminen / eroaminen normaaliin tapaan.
     *
     * @param model
     * @returns {Promise<void>}
     */
    onCheckHetaChangeableContracts = async (model) => {
        this.setState({ isPending: true });
        const hetaDate = moment(model.date).format('YYYY-MM-DD');

        try {
            const response = await api
                .get(Routing.generate('contracts_api_1_get_heta_changeable_contracts', {
                    employer: this.props.userId,
                    hetaJoinDate: hetaDate,
                }));

            this.setState({ isPending: false });
            const data = _.get(response, 'data', false);
            if (! data) {
                this.setState({
                    errorMessage: _trans('userForm.hetaMember.notifications.error'),
                });
            } else {
                const contracts = _.get(data, 'contracts', []);
                const hasHetaJoinAffectedContracts = contracts.length > 0;
                // Väännetään palkkojen pilkuista pisteet koska huoh.
                const communeSalarySettings = _.map(
                    _.get(data, 'communeSalarySettings', []),
                    (communeSalarySetting) => ({
                        ...communeSalarySetting,
                        value: parseFloat(_.get(communeSalarySetting, 'value', '').replace(',', '.')),
                    }));

                this.setState({
                    // Suljetaan dialogi jos palkkaryhmättömiä soppareita ei ole.
                    // isDialogOpen: hasHetaJoinAffectedContracts,
                    hasHetaJoinAffectedContracts,
                    contracts,
                    contractTableSalaryMetadata: _.get(data, 'contractTableSalaryMetadata', []),
                    hetaTesId: _.get(data, 'hetaTesId', null),
                    communeSalarySettings,
                    hetaJoinDate: hetaDate,
                    tableSalaryGroup: _.get(data, 'tableSalaryGroup', null),

                    // Jos palkkaryhmättömiä soppareita ei ole liitytään suoraan Hetaan.
                    isJoiningHeta: ! hasHetaJoinAffectedContracts,
                });
            }
        } catch (error) {
            this.setState({
                errorMessage: _trans('userForm.hetaMember.notifications.error'),
            });
        }
    };

    /**
     * Kysytään liittymis / eroamispäivä. Jos liitytään, tutkitaan onko
     * palkkaryhmättömiä soppareita. Jos ei ole suoritetaan vain liittyminen.
     * @returns {*}
     */
    renderForm() {
        const { hetaJoinDate } = this.props;
        const { errorMessage } = this.state;

        const isHetaMember = this.isHetamember();

        return (
            <LocalForm
                model="hetaChange"
                onSubmit={ isHetaMember
                    ? this.onLeaveHeta
                    : this.onCheckHetaChangeableContracts
                }
                className="o-form o-form--responsive"
                initialState={{
                    isHeta: isHetaMember,
                    date: this.state.isEditingJoinDate ? hetaJoinDate : null,
                }}
            >
                <div className="u-padding-top-small">
                    <StLabel>
                        {this.state.formLabel}
                    </StLabel>
                    <StField>
                        <Control
                            model=".date"
                            component={DateInput}
                            minimumDate={new Date('1970-01-01')}
                            validators={{
                                isDate: validators.isISODate,
                            }}
                        />
                    </StField>
                    <StErrors model=".date" />
                </div>
                { errorMessage !== '' && (
                    <Feedback type="error" message={errorMessage} />
                )}
                <DialogFooter>
                    <ActionBar>
                        <Button
                            preventDefault
                            onClick={() => this.setState({ isDialogOpen: false })}
                        >
                            {_trans('button.cancel')}
                        </Button>
                        <SubmitButton
                            isPending={this.state.isPending}
                        >
                            {_trans('button.confirm')}
                        </SubmitButton>
                    </ActionBar>
                </DialogFooter>
            </LocalForm>
        );
    }

    /**
     * Näyttää joko pvm-lomakkeen tai palkkaryhmättömät sopimukset.
     * @returns {*}
     */
    renderDialog() {
        const {
            hetaJoinDate,
            hasHetaJoinAffectedContracts,
            isJoiningHeta,
            contracts,
            communeSalarySettings,
            contractTableSalaryMetadata,
            tableSalaryGroup,
            hetaTesId,
        } = this.state;

        return (
            <Dialog
                isOpen={this.state.isDialogOpen}
                title={this.state.dialogTitle}
                onClose={this.onCloseDialog}
                maxWidth={480}
                canUnderlayClickExit={false}
            >
                { (hasHetaJoinAffectedContracts || isJoiningHeta) ?
                    <AffectedContracts
                        contracts={contracts}
                        communeSalarySettings={communeSalarySettings}
                        onCloseDialog={this.onCloseDialog}
                        userId={this.props.userId}
                        hetaJoinDate={hetaJoinDate}
                        hasHetaJoinAffectedContracts={hasHetaJoinAffectedContracts}
                        onHetaJoin={() => this.onHetaChange(hetaJoinDate)}
                        contractTableSalaryMetadata={contractTableSalaryMetadata}
                        tableSalaryGroup={tableSalaryGroup}
                        hetaTesId={hetaTesId}
                    />
                    : this.renderForm()
                }
            </Dialog>
        );
    }

    render() {
        const { hetaJoinDate } = this.props;
        const isHetaMember = this.isHetamember();

        const title = isHetaMember ? _trans('userForm.hetaMember.leave_heta') : _trans('userForm.hetaMember.join_heta');
        const label = isHetaMember ? _trans('userForm.hetaMember.leaving_date') : _trans('userForm.hetaMember.joining_date');
        return (
            <div>
                <span className="u-display-inline-block">{`${_trans('userForm.hetaMember.label')}:`}</span>
                <strong className="u-text-uppercase u-align-middle u-margin-tiny">
                    { _trans(isHetaMember ? 'yes' : 'no') }
                </strong>
                <br />
                {isHetaMember ?
                    <div>
                        {hetaJoinDate ? _trans('userForm.hetaMember.join_date', { date: _toLocaleDate(hetaJoinDate) }) : _trans('userForm.hetaMember.unknown_date')}
                    </div> : null
                }
                <br />
                <Button
                    preventDefault
                    onClick={() => this.setState({
                        isDialogOpen: true,
                        dialogTitle: title,
                        formLabel: label
                    })}
                >
                    {title}
                </Button>
                {isHetaMember ? (
                    <Button
                        modifierClass="u-margin-left-small"
                        onClick={() => this.setState({
                            isDialogOpen: true,
                            dialogTitle: _trans('userForm.hetaMember.edit_joining_date'),
                            formLabel: _trans('userForm.hetaMember.joining_date'),
                            isEditingJoinDate: true,
                        })}
                    >
                        {_trans('userForm.hetaMember.edit_joining_date')}
                    </Button>
                ) : null}
                {this.renderDialog()}
            </div>
        );
    }
}
