import React from 'react';
import PropTypes from 'prop-types';
import { Field } from './Field';
import { Options } from 'shared/components/Options';

/**
 * Alasvetovalikko.
 * @param options
 * @param valueKey
 * @param labelKey
 * @param placeholder
 * @param isFullWidth
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const SelectField = ({ options, valueKey, labelKey, placeholder, isFullWidth, ...rest }) => {
    const { className, ...restWithoutClassName } = rest;
    return (
        <Field component="select" {...restWithoutClassName} className={isFullWidth ? 'u-1/1' : null}>
            {placeholder && (
                <option value="">
                    {placeholder}
                </option>
            )}
            <Options
                options={options}
                labelKey={labelKey}
                valueKey={valueKey}
            />
        </Field>
    );
};

SelectField.propTypes = {
    /**
     * Select options.
     */
    options: PropTypes.array.isRequired,
    /**
     * Key to be used for value.
     */
    valueKey: PropTypes.string,
    /**
     * Key to be used for label.
     */
    labelKey: PropTypes.string,
    /**
     * Placeholder. In this case it is added as first option with null value since
     * select element doesn't support placeholder attribute.
     */
    placeholder: PropTypes.string,
    /**
     * Is the dropdown full width.
     */
    isFullWidth: PropTypes.bool,
};

SelectField.defaultProps = {
    valueKey: 'value',
    labelKey: 'label',
    placeholder: null,
    isFullWidth: false,
};
