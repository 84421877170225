import React from 'react';
import PropTypes from 'prop-types';
import { translateHolidayPayMethod } from 'shared/constants/holidayPayMethods';
import { CollectiveAgreementValue } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Summary/shared/CollectiveAgreementValue';

/**
 * Valittu vapaidenmääräytyminen.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const HolidayPayMethod = (props) => {
    const { value, attributes: { customTranslations } } = props;

    return (
        <CollectiveAgreementValue
            value={value}
            customTranslations={customTranslations}
            translator={translateHolidayPayMethod}
            fieldProps={props}
        />
    );
};

HolidayPayMethod.propTypes = {
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    attributes: PropTypes.object.isRequired,
};

export default HolidayPayMethod;
