import React, { Component } from 'react';
import { Page } from 'shared/components';

export default class BookkeepingHelp extends Component {
    render() {
        return (
            <Page
                heading={_trans('company_manager.personnel_groups.help.heading')}
            >
                {_transMd('company_manager.personnel_groups.help.text')}
            </Page>
        );
    }
}
