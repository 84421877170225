import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { TableSalaryValues } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Summary/shared/TableSalaryValues';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { Value } from 'shared/components/Value';

/**
 * Valitut peruskulukorvaukset.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CostReimbursementAttributes = ({ value, values }) => {
    const metadata = useSelector(select.tableSalary.getCostReimbursementMetadata);
    const isLoading = useSelector((state) => state.loading.effects.tableSalary.fetchTableSalaryMetadata);
    const isTableSalaryInUse = values[fieldNames.IS_TABLE_SALARY_IN_USE];
    const hasCostReimbursementTableSalaries = useSelector(select.contract.hasCostReimbursementTableSalaries);
    
    if (isLoading || (! isLoading && ! isTableSalaryInUse)) return null;

    if (! hasCostReimbursementTableSalaries) {
        return (
            <SummaryListItem>
                <Value>
                    {_trans('Ei makseta.', {}, 'common')}
                </Value>
            </SummaryListItem>
        );
    }

    return (
        <TableSalaryValues values={value} metadata={metadata} />
    );
};

CostReimbursementAttributes.propTypes = {
    value: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
};

export default CostReimbursementAttributes;
