/**
 * Kunta-asetukset. CommuneSetting.php.
 */

// Palkkaryhmät
const HETA_HOURLY_WAGE_GROUP_A = 'heta_hourly_wage';
const HETA_HOURLY_WAGE_GROUP_B = 'heta_hourly_wage_b';
const HETA_HOURLY_WAGE_GROUP_B1 = 'heta_hourly_wage_b1';
const HETA_HOURLY_WAGE_GROUP_B2 = 'heta_hourly_wage_b2';
const HETA_HOURLY_WAGE_GROUP_C = 'heta_hourly_wage_c';
const COMMUNE_HOURLY_WAGE = 'commune_hourly_wage';
const COST_CENTERS = 'cost_center';

export const salaryGroups = {
    HETA_HOURLY_WAGE_GROUP_A,
    HETA_HOURLY_WAGE_GROUP_B,
    HETA_HOURLY_WAGE_GROUP_B1,
    HETA_HOURLY_WAGE_GROUP_B2,
    HETA_HOURLY_WAGE_GROUP_C,
};

const salaryGroupMap = {
    [HETA_HOURLY_WAGE_GROUP_A]: _trans('userForm.hetaJoinAffectedContracts.heta_salary_group_a'),
    [HETA_HOURLY_WAGE_GROUP_B]: _trans('userForm.hetaJoinAffectedContracts.heta_salary_group_b'),
    [HETA_HOURLY_WAGE_GROUP_B1]: _trans('Palkkaryhmä B1', {}, 'common'),
    [HETA_HOURLY_WAGE_GROUP_B2]: _trans('Palkkaryhmä B2', {}, 'common'),
    [HETA_HOURLY_WAGE_GROUP_C]: _trans('Palkkaryhmä C', {}, 'common'),
};

export const translateSalaryGroup = (salaryGroup) => salaryGroup in salaryGroupMap ? salaryGroupMap[salaryGroup] : '-';

export default {
    HETA_HOURLY_WAGE_GROUP_A,
    HETA_HOURLY_WAGE_GROUP_B,
    COMMUNE_HOURLY_WAGE,
    COST_CENTERS,
};
