import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { extendMoment } from 'moment-range';
import calendar from 'calendar';
import Immutable from 'immutable';

import BemMixin from '../utils/BemMixin';
import CustomPropTypes from '../utils/CustomPropTypes';
import isMomentRange from '../utils/isMomentRange';
import Moment from 'localization/localizedMoment';

const moment = extendMoment(Moment);

/* eslint-disable react/display-name */
class CalendarMonth extends BemMixin {
    static propTypes = {
        dateComponent: PropTypes.func,
        disableNavigation: PropTypes.bool,
        enabledRange: CustomPropTypes.momentRange,
        firstOfMonth: CustomPropTypes.moment,
        firstOfWeek: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
        hideSelection: PropTypes.bool,
        highlightedDate: PropTypes.object,
        highlightedRange: PropTypes.object,
        onMonthChange: PropTypes.func,
        onYearChange: PropTypes.func,
        value: CustomPropTypes.momentOrMomentRange,
        locale: PropTypes.string,
    };

    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    setLocale = (locale) => {
        moment.locale(locale);
        this.WEEKDAYS = Immutable.List(moment.weekdays()).zip(Immutable.List(moment.weekdaysMin()));
        this.MONTHS = Immutable.List(moment.months());
    };

    UNSAFE_componentWillMount() {
        const { locale } = this.props;
        this.setLocale(locale);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { locale } = nextProps;
        if (locale !== this.props.locale) {
            this.setLocale(locale);
        }
    }

    renderDay = (date, i) => {
        const { dateComponent: CalendarDate, value, highlightedDate, highlightedRange, hideSelection, enabledRange, selectionType, ...props } = this.props;
        const d = moment(date);
        // Alla olevasta jäätyy selain?
        // let d = moment(date).locale(this.props.locale);

        let isInSelectedRange = false;
        let isSelectedDate = false;
        let isSelectedRangeStart;
        let isSelectedRangeEnd;

        if (!hideSelection && value && moment.isMoment(value) && value.isSame(d, 'day')) {
            isSelectedDate = true;
        } else if (!hideSelection && value && isMomentRange(value) && value.contains(d)) {
            isInSelectedRange = true;

            isSelectedRangeStart = value.start.isSame(d, 'day');
            isSelectedRangeEnd = value.end.isSame(d, 'day');
        }

        return (
            <CalendarDate
                key={i}
                selectionType={selectionType}
                isToday={d.isSame(moment(), 'day')}
                isDisabled={!enabledRange.contains(d)}
                isHighlightedDate={!!(highlightedDate && highlightedDate.isSame(d, 'day'))}
                isHighlightedRangeStart={!!(highlightedRange && highlightedRange.start.isSame(d, 'day'))}
                isHighlightedRangeEnd={!!(highlightedRange && highlightedRange.end.isSame(d, 'day'))}
                isInHighlightedRange={!!(highlightedRange && highlightedRange.contains(d))}
                isSelectedDate={isSelectedDate}
                isSelectedRangeStart={isSelectedRangeStart}
                isSelectedRangeEnd={isSelectedRangeEnd}
                isInSelectedRange={isInSelectedRange}
                date={d}
                {...props} />
        );
    };

    renderWeek = (dates, i) => {
        const days = dates.map(this.renderDay);
        return (
            <tr
                className={this.cx({ element: 'Week' })}
                key={i}
            >
                {days.toJS()}
            </tr>
        );
    };

    renderDayHeaders = () => {
        const { firstOfWeek } = this.props;
        const indices = Immutable.Range(firstOfWeek, 7).concat(Immutable.Range(0, firstOfWeek));

        const headers = indices.map((index) => {
            const weekday = this.WEEKDAYS.get(index);
            return (
                <th
                    className={this.cx({ element: 'WeekdayHeading' })}
                    key={weekday}
                    scope="col"
                >
                    <abbr title={weekday[0]}>
                        {weekday[1]}
                    </abbr>
                </th>
            );
        });

        return (
            <tr className={this.cx({ element: 'Weekdays' })}>{headers.toJS()}</tr>
        );
    };

    handleYearChange = (event) => {
        this.props.onYearChange(parseInt(event.target.value, 10));
    };

    renderYearChoice = (year) => {
        const { enabledRange } = this.props;

        if (year < enabledRange.start.year()) {
            return null;
        }

        if (year > enabledRange.end.year()) {
            return null;
        }

        return (
            <option key={year} value={year}>
                {moment(year, 'YYYY').format('YYYY')}
            </option>
        );

        // return (
        //     <option key={year} value={year}>{moment(year, 'YYYY').locale(this.props.locale).format('YYYY')}</option>
        // );
    };

    renderHeaderYear = () => {
        const { firstOfMonth } = this.props;
        const y = firstOfMonth.year();
        const years = Immutable.Range(y - 5, y).concat(Immutable.Range(y, y + 10));
        const choices = years.map(this.renderYearChoice);
        const modifiers = { year: true };

        return (
            <span className={this.cx({ element: 'MonthHeaderLabel', modifiers })}>
                <span className="c-dropdown c-dropdown--flat">
                    { firstOfMonth.format('YYYY') }
                </span>
                { this.props.disableNavigation ? null :
                    <select
                        className={this.cx({ element: 'MonthHeaderSelect' })}
                        value={y}
                        onChange={this.handleYearChange}
                        onBlur={this.handleYearChange}
                        aria-label={_trans('date_range.calendar.month_selection')}
                    >
                        {choices.toJS()}
                    </select> }
            </span>
        );

        // return (
        //     <span className={this.cx({element: 'MonthHeaderLabel', modifiers})}>
        //         {firstOfMonth.locale(this.props.locale).format('YYYY')}
        //             {this.props.disableNavigation ? null :
        //                 <select className={this.cx({element: 'MonthHeaderSelect'})} value={y}
        //                         onChange={this.handleYearChange}>{choices.toJS()}</select>}
        //   </span>
        // );
    };

    handleMonthChange = (event) => {
        this.props.onMonthChange(parseInt(event.target.value, 10));
    };

    renderMonthChoice = (month, i) => {
        const { firstOfMonth, enabledRange } = this.props;
        let disabled = false;
        const year = firstOfMonth.year();

        if (moment({ years: year, months: i + 1, date: 1 }).unix() < enabledRange.start.unix()) {
            disabled = true;
        }

        if (moment({ years: year, months: i, date: 1 }).unix() > enabledRange.end.unix()) {
            disabled = true;
        }

        return (
            <option key={month} value={i} disabled={disabled ? 'disabled' : null}>
                {month}
            </option>
        );
    };

    renderHeaderMonth = () => {
        const { firstOfMonth } = this.props;

        const choices = this.MONTHS.map(this.renderMonthChoice);
        const modifiers = { month: true };

        return (
            <span className={this.cx({ element: 'MonthHeaderLabel', modifiers })}>
                <span className="c-dropdown c-dropdown--flat">
                    {firstOfMonth.format('MMMM')}
                </span>
                { this.props.disableNavigation ? null :
                    <select
                        className={this.cx({ element: 'MonthHeaderSelect' })}
                        value={firstOfMonth.month()}
                        onChange={this.handleMonthChange}
                        onBlur={this.handleMonthChange}
                    >
                        {choices.toJS()}
                    </select> }
            </span>
        );

        // return (
        //     <span className={this.cx({element: 'MonthHeaderLabel', modifiers})}>
        //         {firstOfMonth.locale(this.props.locale).format('MMMM')}
        //         { this.props.disableNavigation ? null :
        //             <select
        //                 className={this.cx({element: 'MonthHeaderSelect'})}
        //                 value={firstOfMonth.month()}
        //                 onChange={this.handleMonthChange}
        //             >
        //                 {choices.toJS()}
        //             </select> }
        //     </span>
        // );
    };

    renderHeader = () => (
        <div className={this.cx({ element: 'MonthHeader' })}>
            <div className={this.cx({ element: 'Selectors' })}>
                {this.renderHeaderMonth()} {this.renderHeaderYear()}
            </div>
        </div>
    );

    render() {
        const { firstOfWeek, firstOfMonth } = this.props;

        const cal = new calendar.Calendar(firstOfWeek);
        const monthDates = Immutable.fromJS(cal.monthDates(firstOfMonth.year(), firstOfMonth.month()));
        const weeks = monthDates.map(this.renderWeek);

        return (
            <div className={this.cx({ element: 'Month' })}>
                {this.renderHeader()}
                <table className={this.cx({ element: 'MonthDates' })}>
                    <thead>
                        {this.renderDayHeaders()}
                    </thead>
                    <tbody>
                        {weeks.toJS()}
                    </tbody>
                </table>
            </div>
        );
    }
}
/* eslint-enable react/display-name */

export default CalendarMonth;
