
const optionSchema = {
    type: 'object',
    title: 'Valinta',
    properties: {
        name: {
            title: 'Tunniste',
            type: 'string',
            minLength: 3,
        },
        value: {
            title: 'Arvo',
            type: 'string',
            minLength: 1,
        },
        label: {
            title: 'Teksti',
            '$ref': '#/definitions/translation',
        }
    },
    required: ['value', 'label'],
};

/**
 * Optiot:
 * [{
 *     value: 1,
 *     label: 'Yksi
 * },
 * {
 *     value: 2,
 *     label: 'Kaksi
 *  }]
 */
export const optionsSchema = {
    type: 'array',
    title: 'Valinnat',
    items: optionSchema,
};
