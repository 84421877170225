import React, { Fragment } from 'react';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Feedback, StField, StLabel } from 'shared/components';
import taxCardShape from 'shared/shapes/taxCard';

export const NoTaxCardFields = ({ taxCard }) => {
    const showOnlyText = ! useSelector(select.taxCards.isEditMode);

    return (
        <Fragment>
            {showOnlyText &&
                <Fragment>
                    <StLabel>
                        {_trans('tax_card.form.start_date.label')}
                    </StLabel>
                    <StField>
                        <b>{_toLocaleDate(taxCard.startDate)}</b>
                    </StField>
                </Fragment>

            }
            <StLabel>
                {_trans('tax_card.form.tax_deduction.label')}
            </StLabel>
            <StField>
                {showOnlyText
                    ? <b>{taxCard.taxDeduction1} {_trans('unit.percent_short')}</b>
                    : (
                        <Fragment>
                            <Field name="taxDeduction1" type="hidden" />
                            <strong className="u-color-negative">60 %</strong>
                        </Fragment>
                    )
                }
            </StField>
            {! showOnlyText && <Feedback type="error" message={_trans('tax_card.form.messages.no_tax_card')} />}
        </Fragment>
    );
};

NoTaxCardFields.propTypes = {
    taxCard: taxCardShape,
};

NoTaxCardFields.defaultProps = {
    taxCard: {},
};
