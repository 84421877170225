import { fromJS } from 'immutable';
import api from 'api';

/**
 * Käyttäjän verokorttimalli.
 */
export default {
    state: fromJS({}),

    reducers: {
        setTaxCard: (state, payload) => fromJS(payload),
    },

    selectors: {
        getTaxCard: (state) => state,
    },

    effects: () => ({
        /**
         * Lisää uuden verokortin.
         * @param employee
         * @param taxCard
         * @returns {Promise<void>}
         */
        async postTaxCard({ employee, taxCard }) {
            try {
                const json = await api.post(Routing.generate('api_1_post_employee_taxcards', {
                    employee,
                }), taxCard);
                const data = _.get(json, 'data');
                this.setTaxCard(data);
                return json;
            } catch (e) {
                console.error(e);
            }
        }
    })
};
