import React from 'react';
import PropTypes from 'prop-types';
import { CollectiveAgreementValue } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Summary/shared/CollectiveAgreementValue';
import { translateSalaryType } from 'shared/constants/salaryTypes';

/**
 * Valittu palkkatyyppi.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const SalaryType = (props) => {
    const { value } = props;

    return (
        <CollectiveAgreementValue
            value={value}
            translator={translateSalaryType}
            fieldProps={props}
        />
    );
};

SalaryType.propTypes = {
    value: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
};

export default SalaryType;
