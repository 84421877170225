import React from 'react';
import PropTypes from 'prop-types';
import { Field } from './Field';

/**
 * Textarea field.
 * @param children
 * @param className
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const TextareaField = ({ children, className, ...rest }) => (
    <Field as="textarea" className={className} {...rest}>
        {children}
    </Field>
);

TextareaField.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    className: PropTypes.string,
};

TextareaField.defaultProps = {
    children: undefined,
    className: 'u-1/1',
};
