import React from 'react';
import { connect, getIn } from 'formik';
import PropTypes from 'prop-types';
import { AutoCompleteVirtualized } from 'shared/containers';

const FormikAutoComplete = (props) => {
    const { name, formik, items, placeholder, customSelect, defaultValue, isDisabled, } = props;
    const { values, setFieldValue } = formik;
    return (
        <AutoCompleteVirtualized
            {...props}
            onSelect={(value) => {
                setFieldValue(name, value);
                customSelect(value);
            }}
            items={items}
            placeholder={placeholder}
            defaultValue={defaultValue === '' ? getIn(values, name) : defaultValue}
            disabled={isDisabled}
        />
    );
};

FormikAutoComplete.propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    formik: PropTypes.object,
    customSelect: PropTypes.func,
    defaultValue: PropTypes.string,
    isDisabled: PropTypes.bool,
};

FormikAutoComplete.defaultProps = {
    formik: {},
    placeholder: '',
    customSelect() {},
    defaultValue: '',
    isDisabled: false,
};

export default connect(FormikAutoComplete);
