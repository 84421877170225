import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Value } from 'shared/components/Value';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';

/**
 * Vähimmäispalkka (HA-puoli).
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const MinimumRequiredTableSalary = (props) => {
    const { value, label, name } = props;

    const hasMinimumTableSalary = useSelector(select.tableSalary.isMinimumTableSalaries);

    if (!hasMinimumTableSalary || !value) return null;

    return (
        <SummaryListItem term={label}>
            <Value ariaDescribedBy={name}>
                {_currency(value)}
            </Value>
        </SummaryListItem>
    );
};

MinimumRequiredTableSalary.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default MinimumRequiredTableSalary;
