import React, { Component, Fragment, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Field, Formik, Form, } from 'formik';
import * as Yup from 'yup';
import AffectedContractsDisclaimer from './AffectedContractsDisclaimer';
import ContractBaseSalary from './ContractBaseSalary';
import api from 'api';
import { FormikErrors } from 'shared/components/Formik';
import { StLabel, StField, StHelp } from 'shared/components/StForm';
import Button from 'shared/components/Button';
import ActionBar from 'shared/components/ActionBar/ActionBar';
import {
    ChoiceList,
    Feedback,
    Separator,
    MDSpinner,
} from 'shared/components';
import { salaryGroups, translateSalaryGroup } from 'shared/constants/communeSettings';
import SubmitButton from 'shared/components/SubmitButton';
import alertTypes from 'shared/constants/alertTypes';
import { salaryTypes } from 'shared/constants/salaryTypes';
import { actions as notificationActions } from 'shared/stores/notifications';

// Kenttien nimet modelissa
const SALARY_GROUP_FIELD = 'salaryGroup';

/**
 * Kertoo jos työnantajalla on Hetaa liitettäessä työsopimuksia joilta puuttuu palkkaryhmä.
 * Pakottaa käyttäjän myös valitsemaan palkkaryhmän ennen kuin liittyminen tehdään.
 *
 * Jos palkkaryhmättömiä työsopimuksia ei löydy tehdään liittyminen normaaliin tapaan.
 */
@connect()
export default class AffectedContracts extends Component {
    static propTypes = {
        contracts: PropTypes.array.isRequired,
        communeSalarySettings: PropTypes.array.isRequired,
        onCloseDialog: PropTypes.func,
        hetaJoinDate: PropTypes.string.isRequired,
        userId: PropTypes.number.isRequired,

        // Mitä tehdään kun palkkaryhmätiedot on annettu ja Hetaan liitytty.
        onHetaJoin: PropTypes.func.isRequired,

        dispatch: PropTypes.func,
        contractTableSalaryMetadata: PropTypes.arrayOf(PropTypes.shape({
            table_salary_area: PropTypes.string,
            table_salary_work_experience: PropTypes.number,
        })).isRequired,
        hetaTesId: PropTypes.number.isRequired,
        tableSalaryGroup: PropTypes.object.isRequired,
    };

    static defaultProps = {
        onCloseDialog() {},
        dispatch() {},
    };

    constructor(props) {
        super(props);
        const { contracts } = props;

        // Esitäytetään contractData alkup. palkkatiedoilla. Tämä on se data joka lähtee bäkkärille.
        const contractData = _.map(contracts, (contract) => ({
            [SALARY_GROUP_FIELD]: '',
            jobContract: _.get(contract, 'jobContractId', -1),
        }));

        const hasHetaJoinAffectedContracts = _.get(props, 'hasHetaJoinAffectedContracts', false);

        this.state = {
            step: hasHetaJoinAffectedContracts ? 0 : contracts.length,
            salaryGroup: null,
            contractData,

            // Jos palkkaryhmättömiä soppareita ei ole liitytään suoraan Hetaan.
            isJoiningHeta: ! hasHetaJoinAffectedContracts,
            // Ohitetaan tällöin myös infoteksti turhana.
            hasReadDisclaimer: ! hasHetaJoinAffectedContracts,

            hasJoinedHeta: false,

            // Mahdollinen virheviesti
            errorMessage: '',
        };

        // Ei palkkaryhmättömiä soppareita, mennään suoraan liittymään.
        if (! hasHetaJoinAffectedContracts) {
            this.onHetaJoin();
        }
    }

    /**
     * Palauttaa palkkaryhmän mukaisen palkan.
     * @param salaryGroup
     * @returns {*}
     */
    getSalaryByGroup = (salaryGroup) => {
        const wage = _.find(this.props.communeSalarySettings, (communeSalarySetting) => _.get(communeSalarySetting, 'name') === salaryGroup);
        return _.get(wage, 'value', null);
    };

    getContractByStep = () => _.get(this.props.contracts, this.state.step, null);
    getContractDataByStep = (step) => this.state.contractData[step];

    /**
     * Palauttaa sopimuksen alkuperäisen palkan.
     * @param index - Sopimuksen indeksi.
     * @returns {*}
     */
    getSalary = (index = this.getContractByStep()) => _.get(index, 'currentSalary.salary', null);

    /**
     * Edelliseen soppariin.
     */
    onPrevContract = () => {
        if (this.state.step > 0) {
            this.setState({ step: this.state.step - 1 });
        } else {
            this.setState({ hasReadDisclaimer: false });
        }
    };

    /**
     * Tallennetaan valinnat stateen ja siirrytään seuraavaan soppariin.
     * @param model
     * @param formikBag
     */
    onNextContract = (model, formikBag) => {
        const { contracts } = this.props;

        if (this.state.step < contracts.length) {
            const { contractData, step } = this.state;
            const { resetForm, } = formikBag;

            const updatedContractData = Object.assign([...contractData], { [step]: model });

            // Vasaralla hakattu initialValue seuraavalle lomakkeelle siihen asti
            // että Formikin bugi tämän suhteen korjaantuu.
            resetForm({ values: this.getContractDataByStep(step + 1) });

            this.setState({
                step: step + 1,
                contractData: updatedContractData,
            });
        }
    };

    getSalaryGroup = (salaryGroup) => {
        const salaryGroupMap = {
            'A': salaryGroups.HETA_HOURLY_WAGE_GROUP_A,
            'B': salaryGroups.HETA_HOURLY_WAGE_GROUP_B,
            'B1': salaryGroups.HETA_HOURLY_WAGE_GROUP_B1,
            'B2': salaryGroups.HETA_HOURLY_WAGE_GROUP_B2,
            'C': salaryGroups.HETA_HOURLY_WAGE_GROUP_C,
        };
        return salaryGroupMap[salaryGroup];
    };

    /**
     * Vahvistetaan Hetaan liittyminen.
     */
    onHetaJoin = async () => {
        const { hetaJoinDate, userId, dispatch } = this.props;
        const { contractData } = this.state;

        try {
            const response = await api
                .post(Routing.generate('contracts_api_1_post_employer_heta_join', { employer: userId }), {
                    hetaJoinDate,
                    hetaChangedContracts: contractData.map((contract) => _.omit(contract, ['tableSalary', 'isLegacyHetaJoin'])),
                });

            const hasJoinedHeta = _.get(response, 'status', 'error') === 'ok';
            if (hasJoinedHeta) {
                this.props.onHetaJoin();
            }

            // Näytä virheviesti jos liittyminen ei onnistunut.
            this.setState({
                hasJoinedHeta,
                isJoiningHeta: false,
                errorMessage: hasJoinedHeta ? '' : _trans('userForm.hetaJoinAffectedContracts.notifications.error.heta_join'),
            });

            dispatch(notificationActions.addNotification({ type: 'success', message: _trans('userForm.hetaMember.heta_join_complete') }));
        } catch (e) {
            console.log(e);
            this.setState({
                isJoiningHeta: false,
                errorMessage: e.message ? e.message : _trans('userForm.hetaJoinAffectedContracts.notifications.error.heta_join'),
            });
        }
    };

    /**
     * Takaisin sopparilistalla. Tämä ei submitoi tehtyjä muutoksia.
     * @returns {*}
     */
    renderPrevButton = () => {
        const isFirstStep = this.state.hasReadDisclaimer && this.state.step === 0;
        return (
            <Button
                preventDefault
                mdIcon={ isFirstStep ? null : 'arrow_backward' }
                onClick={this.onPrevContract}
                disabled={this.state.isJoiningHeta}
            >
                {_trans( isFirstStep ? 'button.back' : 'button.prev' )}
            </Button>
        );
    };

    /**
     * Näytetään palkka, joka koostuu taulukonosata & mahdollisesta kuntakohtaisesta
     * @returns {null|*}
     */
    renderBaseSalary = (values) => {

        const { salaryGroup, tableSalary = '0' } = values;
        const baseSalary = this.getSalary();

        // Mahdollinen kuntakohtainen osa kunnan asetuksista
        const newCommuneSalaryGroupPersonal = salaryGroup
            ? this.getSalaryByGroup(this.getSalaryGroup(salaryGroup))
            : -1;

        // Uus liksa, jossa taulukosta haettu osa + mahdollinen kuntakohtainen osa
        const newSalary = parseFloat(tableSalary) + parseFloat(newCommuneSalaryGroupPersonal);

        // Jos vanha oli suurempi lisätään vielä se mukaan, jottei ainakaan näytetä pienempää liksaa
        const newSalaryDifference = baseSalary - newSalary;

        return (
            <Fragment>
                <StLabel>
                    {_trans('userForm.hetaJoinAffectedContracts.total_salary.label')}
                </StLabel>
                <StField>
                    <ContractBaseSalary
                        baseSalary={baseSalary}
                        newBaseSalary={
                            // Jos ei olla valittu vielä ryhmää, niin annetaan null
                            tableSalary !== '0'
                                // Jos vanha palkka suurempi, niin lisätään diffi uuteen, jottei palkka laske
                                ? (newSalaryDifference > 0 ? newSalary + newSalaryDifference : newSalary )
                                : null
                        }
                    />
                    <StHelp>
                        {_trans('userForm.hetaJoinAffectedContracts.total_salary.help')}
                    </StHelp>
                </StField>
            </Fragment>
        );
    };

    createMonthlySalaryWarningForm = (contract, step, contractCount) => {
        const jobContractId = _.get(contract, 'jobContractId');
        const employeeName = _.get(contract, 'employeeDetails.fullName', '-');

        return (
            <Formik
                onSubmit={(model, formikBag) => {
                    this.onNextContract(model, formikBag);
                }}
                initialValues={{ jobContract: jobContractId }}
                enableReinitialize
            >
                <Form className="o-form o-form--vertical">
                    <div className="u-margin-bottom o-stack o-stack--justify">
                        <h2 className="c-heading-subtitle">
                            {`${_trans('text.job_contract')} ${step + 1} / ${contractCount}`}
                        </h2>
                    </div>

                    <StLabel>
                        {_trans('text.employee')}
                    </StLabel>
                    <StField>
                        <strong>
                            {employeeName}
                        </strong>
                    </StField>

                    <Feedback type={alertTypes.WARNING}>
                        {_trans('userForm.hetaJoinAffectedContracts.monthly_salary_warning')}
                    </Feedback>

                    <ActionBar>
                        {this.renderPrevButton()}
                        <Button
                            mdIcon="arrow_forward"
                            iconAfterText
                            primary
                            type="submit"
                        >
                            {_trans('button.next')}
                        </Button>
                    </ActionBar>
                </Form>
            </Formik>
        );
    };

    /**
     * Näyttää sopimuksen jolta valittava vähintään palkkaryhmä.
     * Jos peruspalkka on suurempi kuin kumpikaan palkkaryhmä ei palkkatietoja näytetä.
     * @returns {*}
     */
    renderContractForm = () => {
        const { contractData, step, } = this.state;
        const { contracts, contractTableSalaryMetadata, dispatch, hetaTesId, hetaJoinDate, tableSalaryGroup } = this.props;

        const contract = contracts[step];
        const tableSalaryMetadata = contractTableSalaryMetadata[step];
        const isMonthlySalary = _.get(contract, 'currentSalary.salaryType', 0) === salaryTypes.PER_MONTH;

        if (isMonthlySalary) {
            return this.createMonthlySalaryWarningForm(
                contract,
                step,
                contracts.length
            );
        }

        return (
            <Formik
                validationSchema={Yup.object().shape({
                    salaryGroup: Yup.string()
                        .required(_trans('userForm.hetaMember.validations.salaryGroupIsRequired')),
                })}
                onSubmit={(model, formikBag) => {
                    this.onNextContract(model, formikBag);
                }}
                initialValues={contractData[step]}
                enableReinitialize
            >
                {(props) => {
                    const {
                        values,
                        setFieldValue,
                    } = props;

                    const salaryGroupOptions = tableSalaryGroup.optionsMap[0].options.reduce(function(filtered, option) {
                        if (option.expireDate && new Date(option.expireDate) < new Date(hetaJoinDate))
                        {
                            return filtered;
                        }
                        if (option.effectiveDate && new Date(hetaJoinDate) < new Date(option.effectiveDate))
                        {
                            return filtered;
                        }
                        filtered.push({
                            value: option.value,
                            label: _transObj(option.label),
                        });
                        return filtered;
                    }, []);

                    const isLegacyHetaJoin = _.get(tableSalaryMetadata, 'isLegacyHetaJoin', false);
                    return (
                        <Form className="o-form o-form--vertical">
                            <div className="u-margin-bottom o-stack o-stack--justify">
                                <h2 className="c-heading-subtitle">
                                    {`${_trans('text.job_contract')} ${step + 1} / ${contracts.length}`}
                                </h2>
                            </div>

                            <StLabel>
                                {_trans('text.employee')}
                            </StLabel>
                            <StField>
                                <strong>
                                    {_.get(contract, 'employeeDetails.fullName', '-')}
                                </strong>
                            </StField>

                            <StLabel>
                                {_trans('userForm.hetaJoinAffectedContracts.choose_salary_group')}
                            </StLabel>
                            <StField>
                                <Field
                                    name={SALARY_GROUP_FIELD}
                                    as={(props) => {
                                        const { name } = props;

                                        return (
                                            <ChoiceList
                                                id="salaryGroupChoice"
                                                {...props}
                                                onChange={(value) => {
                                                    setFieldValue(name, value);
                                                    if (isLegacyHetaJoin) {
                                                        setFieldValue('isLegacyHetaJoin', true);
                                                    } else {
                                                        dispatch.tableSalary.fetchTableSalary(
                                                            hetaTesId,
                                                            {
                                                                ...tableSalaryMetadata,
                                                                table_salary_salary_group: value,
                                                                date: hetaJoinDate
                                                            }
                                                        ).then((salary) => setFieldValue('tableSalary', salary));
                                                    }
                                                }}
                                                alignment="vertical"
                                                options={salaryGroupOptions}
                                            />
                                        );
                                    }}
                                />
                                <FormikErrors name="salaryGroup" />
                            </StField>

                            {isLegacyHetaJoin && (
                                <Feedback type="warning">
                                    Työntekijän mahdolliset Heta-työehtosopimuksen mukaiset palkkamuutokset
                                    on tehtävä käsin ennen 1.3.2020, jonka jälkeen työntekijälle
                                    luodaan automaattisesti Hetan mukainen taulukkopalkka helmikuun palkkajaksoa vahvistettaessa.
                                </Feedback>
                            )}
                            {this.renderBaseSalary(values)}

                            <ActionBar>
                                {this.renderPrevButton()}
                                <Button
                                    mdIcon="arrow_forward"
                                    iconAfterText
                                    primary
                                    type="submit"
                                >
                                    {_trans('button.next')}
                                </Button>
                            </ActionBar>
                        </Form>
                    );
                }}
            </Formik>
        );
    };



    /**
     * Näytetään aluksi tieto siitä moneltako sopimukselta puuttuu palkkaryhmätieto.
     * Käydään sen jälkeen läpi sopimukset yksi kerrallaan ja lähetetään koko pompsi bäkkärille.
     * @returns {*}
     */
    renderSteps = () => {
        const { contracts, onCloseDialog, hetaJoinDate, } = this.props;

        const { contractData, hasReadDisclaimer, errorMessage, isJoiningHeta, } = this.state;

        if (! hasReadDisclaimer) return (
            <AffectedContractsDisclaimer
                affectedContractsCount={contracts.length}
                onReadDisclaimer={() => this.setState({ hasReadDisclaimer: true })}
                onCloseDialog={onCloseDialog}
            />
        );

        // Ei olla viimeisessä vaiheessa jos alun infoteksti on luettu ja käsiteltävä soppari ei ole vielä viimeinen
        // eikä olla jo tukka putkella liittymässä Hetaan.
        const isNotLastStep = (this.state.hasReadDisclaimer && this.state.step < contracts.length) && !isJoiningHeta;
        if (isNotLastStep) return this.renderContractForm();

        return (
            <div>
                <Feedback
                    type="success"
                    message={_trans('userForm.hetaJoinAffectedContracts.ready_to_join')}
                    modifierClass="u-margin-bottom-small"
                />

                <div className="u-margin-bottom-small">
                    {_trans('userForm.hetaMember.joining_date')}
                    <strong className="u-margin-left-tiny">
                        {_toLocaleDate(hetaJoinDate)}
                    </strong>
                </div>

                <ol>
                    {contracts.map((contract, key) => {
                        const employeeFullName = _.get(contract, 'employeeDetails.fullName');
                        const salary = _.get(contract, 'currentSalary.salary');
                        const isMonthlySalary = _.get(contract, 'currentSalary.salaryType', 0) === salaryTypes.PER_MONTH;
                        const salaryGroup = _.get(contractData[key], 'salaryGroup', null);
                        const tableSalary = _.get(contractData[key], 'tableSalary', 0);
                        const isLegacyHetaJoin = _.get(contractData[key], 'isLegacyHetaJoin', false);
                        const salaryGroupCommuneSettingName = this.getSalaryGroup(salaryGroup);

                        // Mahdollinen kuntakohtainen osa kunnan asetuksista
                        const newCommuneSalaryGroupPersonal = salaryGroup
                            ? this.getSalaryByGroup(salaryGroupCommuneSettingName)
                            : -1;

                        // Uus liksa, jossa taulukosta haettu osa + mahdollinen kuntakohtainen osa
                        const newSalary = parseFloat(tableSalary) + parseFloat(newCommuneSalaryGroupPersonal);

                        // Jos vanha oli suurempi lisätään vielä se mukaan, jottei ainakaan näytetä pienempää liksaa
                        const newSalaryDifference = salary - newSalary;

                        return (
                            <li key={key} className="u-margin-bottom-tiny">
                                <strong>
                                    {employeeFullName} – {isMonthlySalary
                                        ? _trans('userForm.hetaJoinAffectedContracts.monthly_salary_no_salary_group')
                                        : translateSalaryGroup(salaryGroupCommuneSettingName)}
                                </strong>
                                <br/>
                                {isLegacyHetaJoin ? (
                                    <Feedback type="warning">
                                        Työntekijän mahdolliset Heta-työehtosopimuksen mukaiset palkkamuutokset
                                        on tehtävä käsin ennen 1.3.2020, jonka jälkeen työntekijälle
                                        luodaan automaattisesti Hetan mukainen taulukkopalkka helmikuun palkkajaksoa vahvistettaessa.
                                    </Feedback>
                                ) : (
                                    <Fragment>
                                        {_trans('userForm.hetaJoinAffectedContracts.total_salary.label')}
                                        {isMonthlySalary
                                            ? <strong> {_currency(salary)}</strong>
                                            : <ContractBaseSalary
                                                baseSalary={salary}
                                                newBaseSalary={newSalaryDifference > 0 ? newSalary + newSalaryDifference : newSalary}
                                            />}
                                    </Fragment>
                                )}
                                <Separator />
                            </li>
                        );
                    })}
                </ol>

                { errorMessage !== '' && (
                    <Feedback type="error" message={errorMessage} modifierClass="u-margin-bottom" />
                )}

                <ActionBar>
                    {this.renderPrevButton()}
                    <Button
                        mdIcon="check"
                        iconAfterText
                        primary
                        onClick={() => {
                            this.setState({
                                isJoiningHeta: true,
                            });
                            this.onHetaJoin();
                        }}
                        inProgress={this.state.isJoiningHeta}
                    >
                        {_trans('userForm.hetaJoinAffectedContracts.confirm_join')}
                    </Button>
                </ActionBar>
            </div>
        );
    };

    /**
     * Kaikki kunnossa.
     * @returns {*}
     */
    renderSuccess = () => (
        <Fragment>
            <Feedback
                type="success"
                message={_trans('userForm.hetaJoinAffectedContracts.join_succesful')}
                modifierClass="u-margin-bottom-small"
            />

            <Button primary onClick={this.props.onCloseDialog} modifierClass="u-1/1">
                {_trans('button.close')}
            </Button>
        </Fragment>
    );

    /**
     * Ruma ku rullatuoli. Usko ja jaksaminen loppuu. Rematchiin seuraavaksi koko roska.
     * @returns {*}
     */
    render() {
        if (this.state.isJoiningHeta) {
            // Fauxina edelliset liittymisnapit vaikka ollaankin oikeasti ihan eri komponentissa.
            return (
                <Fragment>
                    <MDSpinner wrapped />
                    <ActionBar>
                        <Button
                            disabled
                        >
                            {_trans('button.cancel')}
                        </Button>
                        <SubmitButton
                            disabled
                            isPending
                        >
                            {_trans('button.confirm')}
                        </SubmitButton>
                    </ActionBar>
                </Fragment>
            );
        }

        return this.state.hasJoinedHeta ? this.renderSuccess() : this.renderSteps();
    }
}
