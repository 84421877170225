import React, { Fragment } from 'react';
import { FormField, RadioListField, SelectField } from 'shared/components/Formik/index';

import { locales } from 'shared/constants';

export const Locale = () => {
    const options = Object.entries(locales).map(([locale, name]) => ({
        label: name,
        value: locale
    }));

    if (options.length > 5) {
        return (
            <Fragment>
                <FormField isRequired="true" name="locale" label={_trans('userForm.locale.label')}>
                    <SelectField options={options} />
                </FormField>
            </Fragment>
        );
    }
    return (
        <Fragment>
            <FormField label={_trans('userForm.locale.label')} name="locale">
                <RadioListField
                    options={options}
                />
            </FormField>
        </Fragment>
    );
};
