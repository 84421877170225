/**
 * Sisältää AuthorDetails-apuluodan constantteja, jotta frontilla voidaan näyttää asioitan näitten perusteella
 */

/**
 * Lisääjän roolit
 */
export const ROLE_OIMA = 1;

/**
 * Yritys tai kunta
 */
export const ROLE_ORGANISATION = 2;

export const ROLE_PRIVATE = 3;
