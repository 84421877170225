import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Value } from 'shared/components/Value';
import { get } from 'shared/utils/get';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { resolveSalaryPostFix } from 'ContractV3/utils/salaries/resolveSalaryPostFix';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';

/**
 * Kulukorvauksen erityislisä, erikseen sovittu.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CostReimbursementCustom = (props) => {
    const { name, value, values, arrayName, label } = props;
    const salaryType = parseInt(values[fieldNames.SALARY_TYPE], 10);
    const costReimbursementCustomValue = get(values, resolveArrayName(arrayName, fieldNames.COST_REIMBURSEMENT_CUSTOM));
    const costReimbursementCustom = costReimbursementCustomValue !== ''
        ? parseFloat(costReimbursementCustomValue)
        : 0;

    const costReimbursementSpecialAllowanceValue = get(values, resolveArrayName(arrayName, fieldNames.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE));
    const costReimbursementSpecialAllowance = costReimbursementSpecialAllowanceValue !== ''
        ? parseFloat(costReimbursementSpecialAllowanceValue)
        : 0;

    const total = costReimbursementCustom + costReimbursementSpecialAllowance;

    const divisorGroups = values[fieldNames.CONTRACT_GROUP_DIVISORS] ?? [];
    const costDivisor = divisorGroups.map((employee) => ({
        userName: employee.userName,
        costDivisorPercent: employee.costDivisor,
        costDivisor: (employee.costDivisor / 100) * total
    }));

    return (
        <Fragment>
            <SummaryListItem term={label}>
                <Value ariaDescribedBy={name}>
                    {resolveSalaryPostFix(salaryType, _currency(value))}
                </Value>
            </SummaryListItem>
            <SummaryListItem term={_trans('Kulukorvauksen erityislisä yhteensä', {}, 'jobContract')}>
                <Value ariaDescribedBy={`${name}Total`}>
                    {resolveSalaryPostFix(salaryType, _currency(total))}
                </Value>
            </SummaryListItem>
            {costDivisor.length > 1 && costDivisor.map((employee, key) => (
                <SummaryListItem key={key} term={employee.userName}>
                    <Value>
                        {_currency(employee.costDivisor)} ({employee.costDivisorPercent?.toFixed(0)}%)
                    </Value>
                </SummaryListItem>
            ))}
        </Fragment>
    );
};

CostReimbursementCustom.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    arrayName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default CostReimbursementCustom;
