import { Field, FieldArray, useFormikContext } from 'formik';
import React from 'react';
import {
    ActionBar,
    Button,
    StField,
    StLabel,
} from 'shared/components';
import { FormikErrors } from 'shared/components/Formik';
import { FormikMonthSpanInput } from 'shared/components/Formik/FormikMonthSpanInput';

/**
 * Maksukieltokentät.
 * @returns {JSX.Element}
 * @constructor
 */
export const NonPaymentRestrictionFields = () => {
    const { values } = useFormikContext();
    const restrictions = values.restrictions ?? [];
    const monthSpans = restrictions.map(({ duration }) => ({
        start: duration.start,
        end: duration.end,
    }));

    return (
        <fieldset>
            <legend>
                {_trans('foreclosure.form.non_payment_restrictions.legend')}
            </legend>

            <StLabel tooltip={_trans('foreclosure.form.non_payment_restrictions.restrictions.tooltip')}>
                {_trans('foreclosure.form.non_payment_restrictions.restrictions.label')}
            </StLabel>
            <StField>
                <FieldArray
                    name="restrictions"
                    render={({ remove, push }) => (
                        <div>
                            {restrictions.length === 0 && (
                                <div>
                                    {_trans('foreclosure.form.non_payment_restrictions.empty_state.text')}
                                </div>
                            )}
                            {restrictions.length > 0 && (
                                <table
                                    className="o-table o-table--plain u-margin-bottom-small"
                                    summary={_trans('foreclosure.form.non_payment_restrictions.restrictions.summary')}
                                    style={{ maxWidth: '480px' }}
                                >
                                    <thead>
                                        <tr>
                                            <th style={{ width: '16.5em' }} className="u-padding-left-none u-padding-top-tiny">
                                                {_trans('foreclosure.form.non_payment_restrictions.limitation_period.label')}
                                            </th>
                                            <th className="u-padding-left-none u-padding-top-tiny">
                                                {_trans('foreclosure.form.non_payment_restrictions.limitation_amount.label')}
                                            </th>
                                            <th aria-label={_trans('text.actions')} style={{ width: '3em' }} />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {values.restrictions.map((restriction, index) => {
                                            const durationName = `restrictions.${index}.duration`;
                                            const amountName = `restrictions.${index}.amount`;
                                            const unavailableMonthSpans = monthSpans
                                                .filter((restriction, ridx) => index !== ridx);

                                            return (
                                                <tr key={index}>
                                                    <td className="u-align-top u-padding-left-none">
                                                        <FormikMonthSpanInput
                                                            name={durationName}
                                                            unavailableMonthSpans={unavailableMonthSpans}
                                                        />
                                                        <FormikErrors name={durationName} />
                                                    </td>
                                                    <td className="u-align-top u-padding-left-none">
                                                        <Field
                                                            type="text"
                                                            inputMode="decimal"
                                                            name={amountName}
                                                            size={4}
                                                            maxLength={8}
                                                        />
                                                        <FormikErrors name={amountName} />
                                                    </td>
                                                    <td>
                                                        <Button
                                                            flat
                                                            mdIcon="clear"
                                                            title={_trans('button.remove')}
                                                            onClick={() => remove(index)}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                            <ActionBar>
                                <Button flat mdIcon="add" onClick={() => push({ duration: {}, amount: '' })}>
                                    {_trans('foreclosure.form.non_payment_restrictions.restrictions.add_button')}
                                </Button>
                            </ActionBar>
                        </div>
                    )}
                />
            </StField>
        </fieldset>
    );
};
