export const holidayDayTypes = {
    PREV_EARNED_HOLIDAY_DAYS_FROM_PREVIOUS_HOLIDAY_YEAR: -1,
    PREV_EARNED_HOLIDAY_DAYS_FROM_CURRENT_HOLIDAY_YEAR: 0,
    PREV_EARNED_HOLIDAY_DAYS_FROM_CURRENT_HOLIDAY_YEAR_AUTOGENERATED: 100,
    PREV_EARNED_HOLIDAY_DAYS_FROM_CURRENT_HOLIDAY_YEAR_HETA_WORK_EXPERIENCE_AUTOGENERATED: 101,
    EARNED_AUTOGENERATED: 110,
    HETA_JOIN_AUTOGENERATED: 120,
    HETA_WORK_EXPERIENCE_AUTOGENERATED: 121,
    EARNED_HOLIDAY_BONUS_DAY_CONVERTED: 130,
    EARNED: 1,
    USED: 2,
    BYPASSED_BY_NO_HOLIDAY_PAYOUT: 15, // @deprecated
    DEBT_BY_IN_ADVANCE_PAYOUT: 20, // @deprecated
    DEBT_BY_IN_ADVANCE_PAYOUT_BALANCED: 21, // @deprecated
    DEBT: 50,
    DEBT_BALANCED: 51,
    INVALIDATED: 999,

    // OPH sub tyyppejä
    SUB_TYPE_ASSIGNMENT_CONTRACT_LEAVE_DAY_IN_ADVANCE: 500,
    SUB_TYPE_ASSIGNMENT_CONTRACT_ADDITIONAL_HOLIDAY_DAY: 502,
    SUB_TYPE_ASSIGNMENT_CONTRACT_IMPORTED_DEBT_BALANCED_ON_CONTRACT_TERMINATION: 555,
    SUB_TYPE_ASSIGNMENT_CONTRACT_PSEUDO_SUB_TYPE_GENERATED: 5000,
    SUB_TYPE_ASSIGNMENT_CONTRACT_PSEUDO_SUB_TYPE_USER_CREATED: 5001,
    SUB_TYPE_ASSIGNMENT_CONTRACT_EXPIRED_WITHOUT_PAYOUT: 888
};

const CONSUMABLE_TYPES = [
    holidayDayTypes.PREV_EARNED_HOLIDAY_DAYS_FROM_PREVIOUS_HOLIDAY_YEAR,
    holidayDayTypes.PREV_EARNED_HOLIDAY_DAYS_FROM_CURRENT_HOLIDAY_YEAR,
    holidayDayTypes.PREV_EARNED_HOLIDAY_DAYS_FROM_CURRENT_HOLIDAY_YEAR_AUTOGENERATED,
    holidayDayTypes.PREV_EARNED_HOLIDAY_DAYS_FROM_CURRENT_HOLIDAY_YEAR_HETA_WORK_EXPERIENCE_AUTOGENERATED,
    holidayDayTypes.EARNED,
    holidayDayTypes.EARNED_AUTOGENERATED,
    holidayDayTypes.HETA_JOIN_AUTOGENERATED,
    holidayDayTypes.HETA_WORK_EXPERIENCE_AUTOGENERATED,
    holidayDayTypes.EARNED_HOLIDAY_BONUS_DAY_CONVERTED,
];

export const isConsumableHolidayDay = (holidayDayType) => CONSUMABLE_TYPES.includes(holidayDayType);
