import api from 'api';

export const contractContactDimensions = {
    state: [],
    reducers: {
        setData: (state, list) => list,
    },
    selectors: {
        getContacts: (state) => state,
    },
    effects: () => ({
        async fetchContacts() {
            try {
                const json = await api.get('/api/contracts/v3/contract-contacts/contract_employer_contacts');
                if (json.status === 'ok') {
                    this.setData(json.data ?? []);
                }
            } catch (e) {
                //
            }
        },
        async postContacts(dimension, rootState, model) {
            try {
                return await api.post(`/api/contracts/v3/contract-contacts/${dimension}/contract_employer_contacts`, model);
            } catch (e) {
                return e;
            }
        },
        async putContact(contactDetails, rootState, model) {
            try {
                return await api.put(`/api/contracts/v3/contract-contacts/${contactDetails}`, model);
            } catch (e) {
                return e;
            }
        },
        async deleteContact(contactDetails) {
            try {
                return await api.del(`/api/contracts/v3/contract-contacts/${contactDetails}`);
            } catch (e) {
                return e;
            }
        }
    })
};
