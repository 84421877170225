import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AriaModal from 'react-aria-modal';
import _ from 'lodash';
import DialogHeader from './DialogHeader';
import { sizes } from 'shared/constants';

/**
 * Aria-tuettu dialog-komponentti.
 * Lukitsee fokuksen dialog-elementtiin joten esimerkiksi lomakkeiden kentästä toiseen
 * liikkuminen tabin avulla onnistuu eikä sivun vieritys vieritä alla olevaa näkymää.
 */
class Dialog extends Component {
    state = {
        isOpen: false,
        hasEntered: false,
    };

    componentDidMount() {
        this.updateDialogState(this.props);
    }

    /**
     * Dialogipa ei näkynytkään jos avaustieto päivitettiin myöhemmin. Siksi tämä.
     * @param nextProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateDialogState(nextProps);
    }

    updateDialogState = (props) => {
        this.setState({
            isOpen: props.isOpen,
        });
    };

    onEnterDialog = () => {
        this.setState({
            hasEntered: true,
        });
    };

    onCloseDialog = () => {
        this.setState({
            isOpen: false,
        });
        this.props.onClose();
    };

    render() {
        const {
            title,
            titleId,
            children,
            initialFocus,
            isVerticallyCentered,
            maxWidth,
            hasContentPadding,
            isAlertDialog,
            isMounted,
            canUnderlayClickExit,
            isDarkUnderlay,
            isFocusTrapPaused,
        } = this.props;

        const dialogClass = classNames('c-dialog', {
            'c-dialog--alert': isAlertDialog,
            'has-entered': this.state.hasEntered,
            [`u-max-width-${maxWidth}`]: _.includes(_.map(sizes), maxWidth),
        });

        let dialogStyle = {};
        if (_.isNumber(maxWidth)) {
            dialogStyle = {
                maxWidth,
            };
        }

        const dialogContentClass = classNames('c-dialog__content', {
            'u-padding-small u-padding@medium': hasContentPadding === true,
        });

        return (
            <AriaModal
                alert={isAlertDialog}
                mounted={this.state.isOpen || isMounted}
                titleText={title}
                titleId={titleId}
                onEnter={this.onEnterDialog}
                onExit={this.onCloseDialog}
                initialFocus={initialFocus}
                applicationNode={document.getElementById('root')}
                focusDialog={initialFocus === ''}
                underlayClickExits={canUnderlayClickExit}
                dialogClass={dialogClass}
                dialogStyle={dialogStyle}
                underlayClass={classNames('c-dialog-underlay o-pin o-pin--fixed o-pin--left o-pin--top', { 'o-overlay--dark': isDarkUnderlay })}
                underlayColor={false}
                underlayStyle={{
                    zIndex: 99999, // Varmistutaan siitä että dialogi on VARMASTI päälimmäisenä kaikessa...
                }}
                verticallyCenter={isVerticallyCentered}
                focusTrapPaused={isFocusTrapPaused}
            >
                <article>
                    { title !== '' && (
                        <DialogHeader
                            title={title}
                            onCloseButtonClick={this.onCloseDialog}
                        />
                    )}
                    <div className={dialogContentClass}>
                        {children}
                    </div>
                </article>
            </AriaModal>
        );
    }
}

Dialog.defaultProps = {
    title: '',
    titleId: null,
    isOpen: false,
    onClose: () => {},
    initialFocus: undefined,
    isVerticallyCentered: true,
    maxWidth: 'medium',
    hasContentPadding: true,
    isAlertDialog: false,
    isMounted: false,
    canUnderlayClickExit: false,
    isDarkUnderlay: false,
    isFocusTrapPaused: false,
};

Dialog.propTypes = {
    /**
     * Dialogin otsikko. Anna joko tämä tai titleId.
     */
    title: PropTypes.string,

    /**
     * Dialogin otsikon elementin id. Anna joko tämä tai title.
     */
    titleId: PropTypes.string,

    /**
     * Onko dialogi auki.
     */
    isOpen: PropTypes.bool,

    /**
     * Mitä tehdään kun dialogi sulkeutuu.
     */
    onClose: PropTypes.func,

    /**
     * Dialogin sisältö.
     */
    children: PropTypes.node.isRequired,

    /**
     * Oletuksena fokus on ensimmäisessä focusoitavassa childissa.
     * Halutessaan saa fokusoitumaan selectorissa annettuun elementtiin.
     */
    initialFocus: PropTypes.string,

    /**
     * Onko dialogi keskitetty pystysuoraan linjaan.
     */
    isVerticallyCentered: PropTypes.bool,

    /**
     * Maksimileveys (joko CSS-luokkina small, medium, large, huge tai numeroina).
     */
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Onko sisällöllä paddingia.
     */
    hasContentPadding: PropTypes.bool,

    /**
     * Onko dialogi alert-tyyppinen. Käytössä aria-roolissa.
     * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_alertdialog_role
     */
    isAlertDialog: PropTypes.bool,

    /**
     * Injektoidaanko dialogi mountatessa DOMiin. Tarvitaan esimerkiksi 3rd party utilsien kanssa (react-confirm).
     */
    isMounted: PropTypes.bool,

    /**
     * Sulkeeko tausta klikkaaminen dialogin
     */
    canUnderlayClickExit: PropTypes.bool,

    /**
     * Onko underlay tumma
     */
    isDarkUnderlay: PropTypes.bool,

    /**
     * Onko fokuksen vangitseminen päällä.
     * TODO: Poista kun modaalista modaalissa päästy eroon (looking at you Lisäkorvausmodaalihirviö).
     */
    isFocusTrapPaused: PropTypes.bool,
};

export default Dialog;
