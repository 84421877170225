/**
 * Riippuvuudet: mitä arvoja milläkin kentällä oltava jotta tämä palikka renderöidään.
 */
export const dependenciesSchema = {
    type: 'object',
    title: 'Riippuvuusasetukset',
    description: 'Mitä arvoja milläkin kentällä oltava jotta tämä palikka renderöidään.',
    additionalProperties: {
        oneOf: [
            { type: 'string' },
            { type: 'integer' },
            { type: 'boolean' },
            {
                type: 'object',
                patternProperties: {
                    '': { type: 'string', minLength: 1 },
                },
            },
            {
                type: 'array',
                items: {
                    oneOf: [
                        {
                            type: 'string',
                        },
                        {
                            type: 'integer',
                        },
                        {
                            type: 'boolean'
                        },
                    ],
                },
                minItems: 1,
            },
            /*
            Jos tarve, tee tuki strategioittain
             dependencies: {
                 'checkboxes': {
                     'some': ['a', 'b'],
                     'every': ['d', 'e', 'f']
                 }
             }*/
        ]
    }
};
