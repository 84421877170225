import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useField } from 'formik';
import { select } from '@rematch/select';
import Placeholder from 'shared/components/Placeholder';
import { Value } from 'shared/components/Value';
import { DecimalField, FormField, HiddenField, RadioListField } from 'shared/components/Formik';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { getInitialSalaryLabel } from 'ContractV3/utils/salaries/getInitialSalaryLabel';
import { resolveSalaryPostFix } from 'ContractV3/utils/salaries/resolveSalaryPostFix';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';
import InputGroup from 'shared/components/InputGroup';
import InputPostfix from 'shared/components/InputPostfix';
import FormikErrors from 'shared/components/Formik/FormikErrors';
import {
    getTargetWorkTimeGrouperSetting,
    getWorkTimeGroupingOptions,
    isMinWorkTimeSelectionRequired,
} from 'ContractV3/utils/salaries/workTimeGrouperSettings';
import { salaryTypes, translateSalaryType, TYPE_NORMAL_SALARY } from 'shared/constants/salaryTypes';
import Feedback from 'shared/components/Feedback';
import { errorMessages, validators } from 'shared/validators';

/**
 * Lähtöpalkka.
 * @param label
 * @param name
 * @param arrayName
 * @param isRequired
 * @returns {JSX.Element}
 * @constructor
 */

const InitialSalary = ({ label, name, arrayName, isRequired }) => {
    const [field,,fieldHelper] = useField(name);
    const [isTableSalaryInUseField] = useField(resolveArrayName(arrayName, fieldNames.IS_TABLE_SALARY_IN_USE));
    const isTableSalaryInUse = isTableSalaryInUseField?.value ?? true;
    const [isPendingSalaryField] = useField(resolveArrayName(arrayName, fieldNames.IS_PENDING_SALARY));
    
    const [salaryTypeField] = useField(fieldNames.SALARY_TYPE);
    const salaryType = parseInt(salaryTypeField.value, 10);

    const isNewContract = useSelector(select.contract.isNewContract);
    const isJobContract = useSelector(select.contract.isJobContract);
    const isFetchingTableSalary = useSelector((state) => state.loading.effects.tableSalary.fetchTableSalary);

    const [minimumRequiredTableSalaryField] = useField(resolveArrayName(arrayName, fieldNames.MINIMUM_REQUIRED_TABLE_SALARY));
    const minimumRequiredTableSalary = minimumRequiredTableSalaryField.value ?? 0;
    const hasMinimumTableSalary = useSelector(select.tableSalary.isMinimumTableSalaries);
    const initialSalary = field.value ?? 0;
    const salaryPostFix = resolveSalaryPostFix(salaryType);

    const isEmployer = useSelector(select.userMetadata.isEmployer);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const communeHourlyWage = useSelector((state) => isJobContract
        ? select.communeSettings.getCommuneHourlyWage(state)
        : '0');
    const hourlyWage = communeHourlyWage ? parseFloat(communeHourlyWage.replace(',', '.')) : 0;

    const isHeta = useSelector(select.contract.isHetaCollectiveAgreement);
    const useCommuneHourlyWage = !field.value && salaryType === salaryTypes.PER_HOUR && hourlyWage > 0 && isHeta === false;

    useEffect(() => {
        if (useCommuneHourlyWage) {
            fieldHelper.setValue(hourlyWage);
        }
    }, [useCommuneHourlyWage, hourlyWage, fieldHelper]);

    const isHetaCollectiveAgreement = useSelector(select.contract.isHetaCollectiveAgreement);
    const hasSalaryRelatedComponentsVisible = useSelector(select.contract.hasSalaryRelatedComponentsVisible);
    //Tesiltä tieto onko taulukkopalkat käytössä (HA)
    //const hasTableSalaries = useSelector(select.contract.hasTableSalaries);

    /**
     * OPH: jos kellonaikapohjaiset kirjaukset käytössä ja halutaan käyttää vapaapalkkiota niin palkkiolle pitää valita
     * tieto minimityöajasta, joka palkkioon oikeuttaa. Pakotetaan valitsemaan käytössä olevista asetuksista.
     */
    const workTimeGroupingSettings = useSelector(select.tableSalary.getWorkTimeGroupingSettings);
    const showMinWorkTimeSelectField = isMinWorkTimeSelectionRequired(
        isTableSalaryInUse,
        workTimeGroupingSettings,
        salaryType,
        TYPE_NORMAL_SALARY,
        null,
    );
    const minWorkTimeLabel = workTimeGroupingSettings.length > 1
        ? _trans('Palkkioon vaadittava hoitojakson vähimmäiskesto', [], 'holiday_days')
        : _trans('Palkkioon ja kulukorvauksiin vaadittava hoitojakson vähimmäiskesto', [], 'holiday_days');

    if (! hasSalaryRelatedComponentsVisible) return null;

    return (
        <Fragment>
            {(isHetaCollectiveAgreement && ! isTableSalaryInUse && isCommune) && (
                <Feedback type="warning" modifierClass="u-margin-bottom-small">
                    {_trans('contract.form.salary.heta_non_table_salary_warning')}
                    {salaryType === salaryTypes.PER_MONTH
                        ? (
                            <div className="u-margin-top-tiny">
                                {_trans('contract.form.salary.heta_monthly_salary_warning')}
                            </div>
                        ) : ''}
                </Feedback>
            )}
            <FormField
                name={name}
                label={label || getInitialSalaryLabel(parseInt(salaryTypeField.value, 10))}
                isContentFormField={! isTableSalaryInUse || hasMinimumTableSalary}
                isRequired={isRequired}
            >
                {(isTableSalaryInUse && !hasMinimumTableSalary)
                    ? (
                        <Placeholder isPending={isFetchingTableSalary}>
                            <Value ariaDescribedBy={name}>
                                {isPendingSalaryField.value
                                    ? _trans('Odottaa indeksikorotuksia', {}, 'jobContract')
                                    : initialSalary.value === null
                                        ? '-'
                                        : resolveSalaryPostFix(salaryType, _currency(initialSalary))
                                }
                            </Value>
                            <FormikErrors name={name} />
                        </Placeholder>
                    )
                    : (isEmployer && salaryType === salaryTypes.PER_HOUR)
                        ? (
                            <Value>
                                {field.value ? resolveSalaryPostFix(salaryType, _currency(field.value)) : '-'}
                                <HiddenField name={name} value={hasMinimumTableSalary ? minimumRequiredTableSalary : hourlyWage} />
                            </Value>
                        )
                        : (
                            <InputGroup>
                                <DecimalField
                                    name={name}
                                    size={6}
                                    validate={(value) => {
                                        if (! value) {
                                            return errorMessages.isRequired;
                                        }
                                        if (! validators.isDecimal(value)) {
                                            return errorMessages.isDecimal;
                                        }

                                        const initialSalary = parseFloat(value);
                                        if (initialSalary <= 0 && isJobContract) {
                                            return _trans(`Et voi luoda nollapalkkasopimuksia.`, {}, 'jobContract');
                                        }

                                        // Loput checkit vain jos uusi sopimus
                                        if ((! isEmployer && ! isCommune) || ! isNewContract) return null;

                                        if (hasMinimumTableSalary && initialSalary < minimumRequiredTableSalary) {
                                            return _trans(`%salaryType% ei voi olla alle %minimumSalary%.`, {
                                                salaryType: translateSalaryType(salaryType),
                                                minimumSalary: _currency(minimumRequiredTableSalary)
                                            }, 'jobContract');
                                        }

                                        if (salaryType === salaryTypes.PER_HOUR && useCommuneHourlyWage && initialSalary < hourlyWage) {
                                            return _trans(`Tuntipalkka ei voi olla alle %minimumSalary%.`, { minimumSalary: _currency(hourlyWage) }, 'jobContract');
                                        }
                                    }}
                                />
                                <InputPostfix postfix={salaryPostFix} />
                            </InputGroup>
                        )
                }
            </FormField>
            {showMinWorkTimeSelectField && (
                <FormField label={minWorkTimeLabel} name={resolveArrayName(arrayName, fieldNames.MIN_REQUIRED_WORK_TIME_DEFAULT)}>
                    <RadioListField
                        options={getWorkTimeGroupingOptions(getTargetWorkTimeGrouperSetting(workTimeGroupingSettings, 1, null))}
                    />
                </FormField>)}
        </Fragment>
    );
};

InitialSalary.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    arrayName: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
};

InitialSalary.defaultProps = {
    label: null,
};

export default InitialSalary;
