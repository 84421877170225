import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useField } from 'formik';
import { select } from '@rematch/select';
import Placeholder from 'shared/components/Placeholder';
import { Value } from 'shared/components/Value';
import { FormField } from 'shared/components/Formik';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';
import { resolveSalaryPostFix } from 'ContractV3/utils/salaries/resolveSalaryPostFix';
import { Divisors } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Salaries/Divisors';
import { salaryTypes } from 'shared/constants/salaryTypes';

export const parseValue = (value) => value !== '' && !isNaN(value) ? Number.parseFloat(value) : 0;

/**
 * Palkka yhteensä.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TotalSalary = (props) => {
    const { label, arrayName } = props;
    const isCommune = useSelector(select.userMetadata.isCommune);
    const [salaryTypeField] = useField(fieldNames.SALARY_TYPE);
    const salaryType = parseInt(salaryTypeField.value, 10);

    const isJobContract = useSelector(select.contract.isJobContract);
    const [partTimePercentageField] = useField(resolveArrayName(arrayName, fieldNames.PART_TIME_PERCENTAGE));
    const partTimePercentage = parseValue(partTimePercentageField.value ?? 100);

    const name = 'totalSalary';
    const isNewContract = useSelector(select.contract.isNewContract);
    const isUnfinishedContract = useSelector(select.contract.isUnfinishedContract);
    const [initialPersonalSalaryField] = useField(resolveArrayName(arrayName, fieldNames.INITIAL_PERSONAL_SALARY));
    const isFetchingTableSalary = useSelector((state) => state.loading.effects.tableSalary.fetchTableSalary);

    const [initialSalaryField] = useField(resolveArrayName(arrayName, fieldNames.INITIAL_SALARY));
    const [isPendingSalaryField] = useField(resolveArrayName(arrayName, fieldNames.IS_PENDING_SALARY));
    const initialSalary = initialSalaryField.value ?? 0;
    const initialPersonalSalary = parseValue(initialPersonalSalaryField.value ?? '0');
    const totalSalaryValue = parseValue(initialSalary) + initialPersonalSalary;
    const totalSalary = (isJobContract && salaryType === salaryTypes.PER_MONTH)
        ? isNaN(totalSalaryValue) ? 0 : totalSalaryValue * (partTimePercentage / 100)
        : isNaN(totalSalaryValue) ? 0 : totalSalaryValue;

    const employees = useSelector((state) => isNewContract
        ? select.employees.getEmployees(state)
        : select.contract.getEmployees(state));
    const isLoadingEmployees = useSelector((state) => state.loading.effects.employees.fetchEmployees);
    const [employeeIdField] = useField(fieldNames.EMPLOYEE);
    const employeeId = employeeIdField.value;
    const selectedEmployees = useMemo(() => employees.filter((employee) => (employeeId ?? []).includes(employee.userId)), [employees, employeeId]);

    const [divisorField,, divisorHelpers] = useField(fieldNames.CONTRACT_GROUP_DIVISORS);
    const divisorValue = Array.isArray(divisorField.value) ? divisorField.value : [];

    return (
        <Fragment>
            <FormField
                name={name}
                label={label}
                isContentFormField={false}
            >
                <Placeholder isPending={isFetchingTableSalary}>
                    <Value ariaDescribedBy={name}>
                        {isPendingSalaryField.value
                            ? _trans('Odottaa indeksikorotuksia', {}, 'jobContract')
                            : resolveSalaryPostFix(salaryType, _currency(totalSalary))
                        }
                    </Value>
                </Placeholder>
            </FormField>

            {(! isLoadingEmployees && ! isCommune && selectedEmployees.length > 1) &&
                <Divisors
                    divisorKey="divisor"
                    canChangeDivisors={isNewContract || isUnfinishedContract}
                    divisors={divisorValue}
                    divisorHelpers={divisorHelpers}
                    employees={selectedEmployees}
                    totalSalary={totalSalaryValue}
                />
            }
        </Fragment>
    );
};

TotalSalary.propTypes = {
    arrayName: PropTypes.string.isRequired,
    label: PropTypes.string,
};

TotalSalary.defaultProps = {
    label: null,
};

export default TotalSalary;
