import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, useField } from 'formik';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { FormField } from 'shared/components/Formik';
import StreetAddressAutoComplete from 'shared/containers/StreetAddressAutoComplete';
import FormikErrors from 'shared/components/Formik/FormikErrors';
import Button from 'shared/components/Button';
import Separator from 'shared/components/Separator';

/**
 * Työn sijainti. Voi olla useampia. Erotellaan ; merkillä bäkkärille tuutatessa.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const JobTargetAddress = (props) => {
    const { name, label, placeholder } = props;
    const [field,,helpers] = useField(name);
    const [isAutoCompleteVisible, setIsAutoCompleteVisible] = useState(false);
    const jobTargetAddresses = (field.value ?? []);
    const isNewContract = useSelector(select.contract.isNewContract);
    const isLoadingBenefitDecision = useSelector((state) => state.loading.effects.benefitDecision.fetchBenefitDecision);
    const isLoadingActiveBenefitDecisions = useSelector((state) => state.loading.effects.benefitDecisions.fetchActiveBenefitDecisions);

    const benefitDecision = useSelector(select.benefitDecision.getBenefitDecision);

    useEffect(() => {
        const fullAddress = benefitDecision?.beneficiary?.fullAddress?.toString().trim();
        if (! isLoadingBenefitDecision && ! isLoadingActiveBenefitDecisions && benefitDecision && isNewContract && fullAddress && fullAddress !== '') {
            helpers.setValue([fullAddress]);
        }
    }, [benefitDecision, isLoadingBenefitDecision, isNewContract]);

    return (
        <FormField label={label} isContentFormField={false}>
            <FieldArray
                name={name}
                render={({ remove, push }) => (
                    <Fragment>
                        <div className="u-margin-bottom-small">
                            {jobTargetAddresses.map((jobTargetAddress, index) => (
                                <Fragment key={index}>
                                    <div className="o-stack o-stack--start">
                                        <b className="u-padding-vertical-small u-line-height-flat o-stack__item--fill">{jobTargetAddress}</b>
                                        <Button flat mdIcon="clear" onClick={() => remove(index)} />
                                    </div>
                                    {(index < jobTargetAddresses.length - 1) && <Separator />}
                                </Fragment>
                            ))}
                        </div>
                        {isAutoCompleteVisible && (
                            <div className="u-margin-bottom-small o-stack o-stack--gaps o-stack--start">
                                <div className="o-stack__item--fill">
                                    <StreetAddressAutoComplete
                                        size={44}
                                        className="u-1/1"
                                        id={name}
                                        ariaLabelledBy={name}
                                        placeholder={placeholder}
                                        hasFullAddressOnSelect
                                        onSelect={({ selectedItem }) => {
                                            push(selectedItem.place_name);
                                            setIsAutoCompleteVisible(false);
                                        }}
                                    />
                                </div>
                                <Button mdIcon="clear" flat onClick={() => setIsAutoCompleteVisible(false)} />
                            </div>
                        )}
                        {! isAutoCompleteVisible && (
                            <Button
                                ghost
                                mdIcon="add_location"
                                modifierClass="u-1/1"
                                onClick={() => {
                                    setIsAutoCompleteVisible(true);
                                }}
                            >
                                {_trans('Lisää uusi työn sijainti', {}, 'jobContract')}
                            </Button>
                        )}
                    </Fragment>
                )} />
            <FormikErrors name={name} />
        </FormField>
    );
};

JobTargetAddress.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
};

export default JobTargetAddress;
