import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { allowedUserRoles, roleItemKey } from '../constants';
import { RoleCard } from './RoleCard';

/**
 * Roolilista yhdelle roolille.
 * Jos valittavia rooleja on 5 tai enemmän näytetään yksi roolinappi,
 * joka vie autocomplete-listalle. Muuten puhdas lista.
 * @param items
 * @param iconSize
 * @param listPageId
 * @param userRole
 * @param target
 * @returns {*}
 * @constructor
 */
export const RoleCardList = ({ items, iconSize, listPageId, userRole, target }) => {
    const dispatch = useDispatch();
    // Millä avaimella haetaan
    const nameIndex = roleItemKey[userRole];

    if (items.length >= 5) {
        return (
            <RoleCard
                target={target}
                iconSize={iconSize}
                onClick={() => dispatch.roleSelector.setPage(listPageId)}
                userRole={userRole}
                badgeCount={items.length}
            />
        );
    } else {
        return items.map((item, key) => (
            <RoleCard
                key={key}
                item={item}
                target={target}
                iconSize={iconSize}
                userRole={userRole}
                roleName={item[nameIndex]}
            />
        ));
    }
};

RoleCardList.propTypes = {
    items: PropTypes.array.isRequired,
    iconSize: PropTypes.number,
    userRole: PropTypes.oneOf(allowedUserRoles),
    /**
     * Jos autocomplete-lista on tämä ko. sivun id.
     */
    listPageId: PropTypes.number,
    target: PropTypes.string,
};

RoleCardList.defaultProps = {
    iconSize: 48,
    target: null,
};

