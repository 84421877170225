import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { DateRangeInput } from 'shared/components';

const FormikDateRangeInput = (props) => {
    const { name } = props;
    const [field,,helpers] = useField(name);
    return (
        <DateRangeInput
            onChange={(value) => {
                helpers.setValue(value);
                helpers.setTouched(true);
            }}
            value={field.value}
            {...props}
        />
    );
};

FormikDateRangeInput.propTypes = {
    name: PropTypes.string.isRequired,
};

export default FormikDateRangeInput;
