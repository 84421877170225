import React from 'react';
import PropTypes from 'prop-types';
import { OutLink, SummaryList } from 'shared/components';

export const ContractInfo = ({ collectiveAgreementName, employerName, employerId, isCommune }) => {
    const list = [
        {
            term: _trans('Työnantaja', {}, 'extract'),
            description: isCommune ? <OutLink href={employerId.toString()}>{employerName}</OutLink> : employerName,
        },
        {
            term: _trans('Työehtosopimus', {}, 'extract'),
            description: collectiveAgreementName,
        }
    ];

    return <SummaryList list={list} />;
};

ContractInfo.propTypes = {
    collectiveAgreementName: PropTypes.string,
    employerName: PropTypes.string.isRequired,
    employerId: PropTypes.number,
    isCommune: PropTypes.bool.isRequired
};

ContractInfo.defaultProps = {
    collectiveAgreementName: '',
    employerId: PropTypes.number
};
