import deepmerge from 'deepmerge';
import { customComponents } from 'ContractV3/constants/customComponents';
import { resolveDefaultValue } from 'shared/ReForm/utils/resolveDefaultValue';
import { resolveValidationTypeByBlock } from 'shared/ReForm/utils/resolveValidationTypeByBlock';
import { blocks as blockTypes } from 'shared/ReForm/constants/blocks';

const getDefaultValue = (block,customBlock) => {
    if (block.hasOwnProperty('defaultValue')) {
        return block.defaultValue;
    }

    return customBlock.hasOwnProperty('defaultValue')
        ? customBlock.defaultValue
        : resolveDefaultValue(block);
};

/**
 * Palauttaa joko palikalla suoraan olevat propit tai custom-palikan ollessa
 * kyseessä siltä mahdollisesti löytyvän esiasetetut propit customComponents.js:ssä.
 * Esim. JobTitle-palikka.
 * @param block
 * @returns {*}
 */
export const getBlockProps = (block) => {
    const { name, attributes, options, style, type, component, label, tooltip, inputLabel, inputPostfix, helpText, legend, placeholder, addComponent, ...restBlock } = block;
    const customBlock = customComponents[component] ?? {};
    const blocks = customBlock.blocks || block.blocks;

    const resolvedType = customBlock.type || type;
    return {
        ...restBlock,
        block: block.block,
        // Saisipa tämän selvittää miksi ei resolvoi oikein BlockForm:lla
        type: resolvedType,
        component: customBlock.component || component,
        name: customBlock.name || name,
        attributes: deepmerge(customBlock.attributes ?? {}, attributes ?? {}),
        options: customBlock.options ?? options ?? [],
        style: customBlock.style || style,
        label: label || customBlock.label,
        tooltip: tooltip || customBlock.tooltip,
        inputLabel: inputLabel || customBlock.inputLabel,
        inputPostfix: inputPostfix || customBlock.inputPostfix,
        helpText: helpText || customBlock.helpText,
        legend,
        placeholder: placeholder || customBlock.placeholder,
        validationType: resolveValidationTypeByBlock(block),
        validations: customBlock.validations || block.validations,
        dynamicValidation: customBlock.dynamicValidation || block.dynamicValidation,
        dependencies: block.dependencies || customBlock.dependencies,
        // Onko palikalla sisäkkäisiä palikoita.
        blocks,
        // Palikan lisäämispalikka.
        addComponent: customBlock.addComponent || addComponent,
        // Pakko tarkistaa oletusarvo näin koska esim. päivämäärät voivat olla undefined
        defaultValue: getDefaultValue(block, customBlock),
        isValueFixed: (customBlock.isValueFixed || block.isValueFixed) ?? false,
        // Näytetäänkö (renderöidäänkö) yhteenvedossa. Esim. TableSalaryä turha renderöidä (arvo on jo olemassa).
        isVisibleOnSummary: (customBlock.isVisibleOnSummary ?? block.isVisibleOnSummary) ?? true,
        // Miltä rooleilta palikka on piilotettu.
        isHiddenFromRolesOnSummary: (customBlock.isHiddenFromRolesOnSummary ?? block.isHiddenFromRolesOnSummary) ?? [],
        // Onko piilotettu tulosteelta
        isHiddenOnPrint: (customBlock.isHiddenOnPrint || block.isHiddenOnPrint) ?? false,
        // Onko ryhmänumero näkyvissä
        ...(block.block === blockTypes.GROUP && { isGroupNumberVisible: block?.isGroupNumberVisible ?? true }),
    };
};
