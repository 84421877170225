import _ from 'lodash';

const namespace = 'app/notifications';


export const types = {
    ADD_NOTIFICATION: `${namespace}/ADD_NOTIFICATION`,
    REMOVE_NOTIFICATION: `${namespace}/REMOVE_NOTIFICATION`,
    TIMEOUT_NOTIFICATION: `${namespace}/TIMEOUT_NOTIFICATION`,
};

/**
 * Initial state
 */
const initialNotifications = [];

/**
 * Reducer
 */
function notificationReducer(state = initialNotifications, action) {
    switch (action.type) {
        case types.ADD_NOTIFICATION:
            return [action.payload, ...state.filter(({ id }) => id !== action.payload.id)];

        case types.REMOVE_NOTIFICATION:
            return _.filter(state, (notification) =>
                notification.id !== action.payload
            );

        default:
            return state;
    }
}

/**
 * Action creators
 */
export const actions = {
    addNotification: (notification) => {
        const payload = Object.assign({}, notification);
        if (!payload.id) {
            payload.id = new Date().getTime();
        }

        return (dispatch) => {
            dispatch({
                type: types.ADD_NOTIFICATION,
                payload
            });

            const timeout = _.get(payload, 'timeout', 5000);
            if (timeout > 0) {
                setTimeout(() => {
                    dispatch({
                        type: types.REMOVE_NOTIFICATION,
                        payload: payload.id,
                    });
                }, timeout);
            }
        };
    },

    removeNotification: (index) => ({
        type: types.REMOVE_NOTIFICATION,
        payload: index
    }),
};

export default notificationReducer;
