import { useEffect, useCallback, useRef } from 'react';
import useMergeState from './mergeState';
import api from 'api';
import commonUtils from 'shared/utils/commonUtils';

const hasQueryParams = (url) => {
    // Fallback, jos ei url ei olekkaan järkevä (URL constructori heittää voltin)
    try {
        return new URL(`${location.host}${url}`).search !== '';
    } catch (e) {
        return false;
    }
};
/**
 * Fetch hookki. Käytetään yksinkertaisiin komponentteihin/containereihin, joissa tarvitaan tietoa bäkkäriltä
 * Mikäli tarvitset monimutkaisempaa, niin käytä Rematch modelleja.
 *
 * Käyttö:
 * const [{ isLoading, error, data }, fetchData] = useFetch(URL, { lazy: true, cachePolicy: 'no-cache' })
 * Ensimmäinen lapsi on state, toinen on funkio, jolla voi manuaalisesti kutsua fetchia
 *  -Ensimmäinen argumentti on url
 *  -Toinen on options, jossa:
 *      lazy:ssa ei ladata didMountissa
 *      cachePolicy kertoo kakuttamisen, vaihtoehtoina cache-first (default), no-cache, cache-only
 *
 * @param url
 * @param options
 * @return {[object, function]}
 */
const useFetch = (url, options = {}) => {
    const { lazy = false, cachePolicy = 'cache-first' } = options;

    const wasCalled = useRef(false);

    const isSleeping = lazy && !wasCalled.current;
    const isCacheAvailable = !! cache[url];
    const canUseCache = isCacheAvailable && cachePolicy !== 'no-cache' && !wasCalled.current;

    const [state, mergeState] = useMergeState({
        data: canUseCache ? cache[url] : null,
        error: null,
        // Lataus lähtee käyntiin jo alussa ellei ole lazy päällä eli polkastaan lataus käyntiin itse.
        isLoading: ! lazy,
    });
    const hasQuery = hasQueryParams(url);
    const makeRequest = useCallback((queryParams = {}) => {
        // Jos löytyy kakutus ja sallitaan kakun käyttö, ei toggleta isLoading, mutta ladataan kuitenkin taustalla
        const skipLoading = canUseCache && cachePolicy === 'cache-first';
        if (! skipLoading) {
            mergeState({
                isLoading: true,
            });
        }
        const query = commonUtils.createQueryParams(queryParams);
        // Jos ei käytössä queryä master-urlissa tai kööriparameja ei annettu itse manuaalisessa kutsussa ei laiteta mtn.
        const resp = api.get(`${url}${hasQuery || query === '' ? '' : `?${query}`}`)
            .then(
                (data) => {
                    // Päivitetään stateen & kakkuun uusimmat tiedot
                    mergeState({ data, error: null, isLoading: false });
                    cache[url] = data;

                    return data;
                },
                (error) => {
                    mergeState({ error, data: null, isLoading: false });

                    return error;
                }
            );
        wasCalled.current = true;

        return resp;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    useEffect(() => {
        if (isSleeping) return;
        if (canUseCache && cachePolicy === 'cache-only') return;

        makeRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [makeRequest]);

    return [
        state,
        makeRequest,
    ];
};

const cache = {};

export default useFetch;
