import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import { useDispatch } from 'react-redux';
import { UserRoleIcon } from '../components/UserRoleIcon';
import { getRouteResolverByRole } from '../utils';
import { allowedUserRoles } from '../constants';
import Badge from 'shared/components/Badge';
import MDIcon from 'shared/components/MDIcon';
import userRoles, { translateUserRole } from 'shared/constants/userRoles';

/**
 * Roolikortti joko linkki tai nappi.
 * Linkkinä jos samaa roolia ei ole 5 tai enemmän. Muutoin nappi joka ohjaa autocomplete-näkymään.
 * @param item
 * @param iconSize
 * @param listItemProps
 * @param badgeCount
 * @param userRole
 * @param roleName
 * @param searchText
 * @param isSelected
 * @param target
 * @param rest
 * @returns {React.ReactElement<{className: string}>}
 * @constructor
 */
export const RoleCard = ({ item, iconSize, listItemProps, badgeCount, userRole, roleName, searchText, isSelected, target, ...rest }) => {
    const dispatch = useDispatch();
    const cardTitle = roleName !== '' ? roleName : translateUserRole(userRole);
    const { onClick } = rest;
    // Yritysroolissa: odottaa käyttäjän tunnistautumista
    const isIdentified = item?.isIdentified ?? true;
    const isDisabled = item?.disabled ?? false;
    const route = getRouteResolverByRole(item.role ?? userRole, target);
    const href = (route && !onClick && ! isDisabled) ? route(item) : null;
    const startDate = item?.startDate ?? null;

    return (
        <li
            className={classNames('c-role-selector__item u-margin-bottom-small', {
                'u-muted': isDisabled,
            })}
            {...listItemProps}
        >
            {React.createElement(href ? 'a' : 'button', {
                href,
                disabled: isDisabled,
                // Jos ei ole annettu onClick-proppia asetetaan roolinvaihtostate päälle rooli valitessa ja
                // disabloidaan roolinvaihto
                onClick: onClick ? onClick : () => dispatch.roleSelector.setIsSwitching(true),
                className: classNames('c-card c-card--link u-padding-small u-position-relative u-display-block u-1/1', {
                    'c-card--is-selected': isSelected,
                }),
                'aria-label': badgeCount > 0
                    ? `${cardTitle}. ${_trans('role_selector.n_selectable', { count: badgeCount })}`
                    : cardTitle,
                ...rest,
            }, (
                <div className="c-role__header o-stack">
                    <span className="c-role__icon u-display-inline-block u-font-size-0">
                        <UserRoleIcon userRole={userRole} width={iconSize} height={iconSize} />
                    </span>
                    <div className="u-margin-left-tiny u-text-left">
                        <h2 className="c-role__title u-display-block@medium-1">
                            {searchText !== ''
                                ? (
                                    <Highlighter
                                        highlightClassName="o-highlight"
                                        searchWords={[searchText]}
                                        autoEscape={true}
                                        textToHighlight={cardTitle}
                                    />
                                )
                                : cardTitle
                            }
                        </h2>
                        { ! isIdentified && ! isDisabled && (
                            <div className="u-color-grey-500">
                                {_trans('role_selector.company.waiting_for_authentication')}
                            </div>
                        )}
                        {isDisabled && startDate && (
                            <span>
                                {_trans('Valittavissa %date% alkaen', { date: _toLocaleDate(startDate) }, 'extract')}
                            </span>
                        )}
                        {/*{ userRole === userRoles.EMPLOYER && (
                            <span className="u-color-grey-500">
                                {_trans('role_selector.substitute_payer_employer.commune_administered')}
                            </span>
                        )}*/}
                        { item.role === userRoles.COMPANY_APPROVER && (
                            <span className="u-color-grey-500">
                                {_trans('role_selector.company.company_approver')}
                            </span>
                        )}
                    </div>
                    <span style={{ marginLeft: 'auto' }} className="o-stack">
                        {(badgeCount > 0) && (
                            <Badge
                                value={badgeCount}
                                isBall={false}
                            />
                        )}
                        <span>
                            {! isDisabled && (
                                <MDIcon
                                    icon={ href ? 'arrow_forward' : 'navigate_next' }
                                    modifierClass={ href ? 'u-color-link' : 'u-color-default' }
                                />
                            )}
                        </span>
                    </span>
                </div>
            ))}
        </li>
    );
};

RoleCard.propTypes = {
    /**
     * Roolin kaikki data.
     */
    item: PropTypes.object,
    /**
     * Aria-roolit listaelementille
     */
    listItemProps: PropTypes.object,
    iconSize: PropTypes.number,
    badgeCount: PropTypes.number,
    userRole: PropTypes.oneOf(allowedUserRoles).isRequired,
    roleName: PropTypes.string,
    /**
     * Etsittävä teksti. Jos ei ole tyhjä highlightataan roolin nimi.
     */
    searchText: PropTypes.string,
    isSelected: PropTypes.bool,
    /**
     * Mahdollinen edellinen url johon mennään kirjautumisen jälkeen.
     */
    target: PropTypes.string,
};

RoleCard.defaultProps = {
    item: {},
    listItemProps: {},
    iconSize: 48,
    badgeCount: 0,
    roleName: '',
    searchText: '',
    isSelected: false,
    target: null,
};
