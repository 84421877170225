import PropTypes from 'prop-types';
import { useField } from 'formik';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import Customer from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Parties/Customer';
import Button from 'shared/components/Button';
import { FormField } from 'shared/components/Formik';
import Separator from 'shared/components/Separator';

/**
 * Asiakas. Tukee (hehe) useampaa asiakasta (tai hoidettavaa).
 * @param index
 * @param arrayHelpers
 * @param name
 * @param rootName
 * @param originalName
 * @param attributes
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const OtherCustomer = ({ index, arrayHelpers, name, rootName, originalName, attributes, ...rest }) => {
    const [field] = useField(rootName);
    const customerIds = (field?.value ?? [])
        .filter((customer) => customer[originalName] && customer[originalName] !== '')
        .map((customer) => parseInt(customer[originalName], 10));

    const isNewContract = useSelector(select.contract.isNewContract);
    const isDraftContract = useSelector(select.contract.isDraftContract);
    // Poistaa voi vain uusilla ja luonnossoppareilla.
    const canRemove = isNewContract || isDraftContract;

    return (
        <Fragment>
            {(index > 0 && canRemove) && (
                <Fragment>
                    <Separator />
                    <FormField isContentFormField={false} modifierClass="u-margin-bottom-small">
                        <Button
                            flat
                            mdIcon="clear"
                            onClick={() => arrayHelpers.remove(index)}
                        >
                            {_trans('Poista henkilö', {}, 'common')}
                        </Button>
                    </FormField>
                </Fragment>
            )}
            <Customer
                name={name}
                omittedUserIds={customerIds}
                attributes={attributes}
                {...rest}
            />
        </Fragment>
    );
};

OtherCustomer.propTypes = {
    index: PropTypes.number,
    arrayHelpers: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    rootName: PropTypes.string.isRequired,
    originalName: PropTypes.string.isRequired,
    attributes: PropTypes.object.isRequired,
};

OtherCustomer.defaultProps = {
    index: -1,
};

export default OtherCustomer;
