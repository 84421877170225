import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Tehtävälista.
 * Käytetään esimerkiksi tilanteissa joissa halutaan viestiä käyttäjälle ne kohdat jotka on vielä tehtävä.
 */
export default class TodoList extends Component {
    static propTypes = {
        // Itse tehtävälista
        list: PropTypes.arrayOf(PropTypes.shape({
            done: PropTypes.bool,
            task: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            visible: PropTypes.bool,
        })).isRequired,
        // CSS-muutosluokka.
        modifierClass: PropTypes.string,
    };

    static defaultProps = {
        modifierClass: '',
    };

    renderList = () => this.props.list.map((item, key) => {
        const isTaskDone = _.get(item, 'done', false);
        const isVisible = _.get(item, 'visible', true);

        if (isVisible) {
            return (
                <li key={key} className="o-list-bare__item">
                    <i className={classNames('material-icons u-align-middle u-font-size-18', {
                        'u-color-negative': ! isTaskDone,
                        'u-color-positive': isTaskDone,
                    })}>
                        { isTaskDone ? 'check' : 'clear' }
                    </i>
                    <span className="u-align-middle u-display-inline-block u-margin-left-tiny">
                        {_.get(item, 'task', '-')}
                    </span>
                </li>
            );
        }
    });

    render() {
        return (
            <ul className={classNames('o-list-bare', this.props.modifierClass)}>
                {this.renderList()}
            </ul>
        );
    }
}
