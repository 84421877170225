import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CriticalErrorState } from 'shared/containers/CriticalErrorState';

/**
 * Helpperi joka ottaa kiinni reactin virheitä ja näyttää error staten mielummin kuin valkoisen ruudun.
 */
export class ErrorBoundary extends Component {

    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    state = {
        hasError: false,
        error: null,
    };

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error,
        };
    }

    /**
     * Ammutaan DD:seen virheviesti.
     */
    componentDidCatch(error, info) {
        if (window.DD_RUM) {
            const renderingError = new Error(error.message);
            renderingError.name = `ReactRenderingError`;
            renderingError.stack = info.componentStack;
            renderingError.cause = error;

            window.DD_RUM.addError(renderingError);
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <CriticalErrorState>
                    {__DEV__ && (
                        <div>
                            <div>
                                <b>{this.state.error.message}</b>
                            </div>
                            <pre>
                                {this.state.error.stack}
                            </pre>
                        </div>
                    )}
                </CriticalErrorState>
            );
        }

        return this.props.children;
    }
}
