import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const THEME_ADMIN = 'admin';

/**
 * Geneerinen paneeli-komponentti jolle voi antaa halutessaan otsikon
 * @param children
 * @param heading
 * @param actionItems
 * @param hasContentPadding
 * @param theme
 * @param modifierClass
 * @returns {*}
 * @constructor
 */
function Panel({ children, heading, actionItems, hasContentPadding, theme, modifierClass }) {
    const panelContentClass = classNames('c-panel__content', {
        'u-padding-none': ! hasContentPadding,
    });

    return (
        <section className={classNames('c-panel u-margin-bottom', {
            'c-panel--admin': theme === THEME_ADMIN,
            [modifierClass]: modifierClass !== '',
        })}>
            <PanelHeader
                heading={heading}
                actionItems={actionItems}
            />
            <div className={panelContentClass}>
                {children}
            </div>
        </section>
    );
}

function PanelHeader({ heading, actionItems }) {
    let content = null;

    const panelHeaderClass = classNames('c-panel__header o-stack', {
        'o-stack--justify': heading && actionItems,
    });

    // Jos sekä otsikko että toiminnot löytyvät, jaa ne otsikkoriville niin
    // että otsikko on vasemmalla ja toiminnot oikealla puolella.
    if (heading) {
        heading = <h2 className="c-panel__heading">{heading}</h2>;

        content = (
            <header className={panelHeaderClass}>
                {heading}
                {actionItems}
            </header>
        );
    }

    return content;
}

Panel.propTypes = {
    /**
     * Paneelin sisältö.
     */
    children: PropTypes.node.isRequired,

    /**
     * Paneelin otsikko.
     */
    heading: PropTypes.string,

    /**
     * Otsikko.
     */
    actionItems: PropTypes.node,

    /**
     * Onko paneelin sisällöllä paddingia.
     */
    hasContentPadding: PropTypes.bool,

    /**
     * Teema
     */
    theme: PropTypes.oneOf(['default', 'admin']),

    /**
     * CSS luokkia
     */
    modifierClass: PropTypes.string,
};

Panel.defaultProps = {
    heading: null,
    actionItems: null,
    modifierClass: '',
    theme: 'default',
    hasContentPadding: true,
};

export default Panel;
