import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Feedback } from 'shared/components';
import ActionBar from 'shared/components/ActionBar';
import Button from 'shared/components/Button';

/**
 * Kertoo moniko työsoppari on ilman palkkaryhmää.
 * @param affectedContractsCount
 * @param onReadDisclaimer
 * @param onCloseDialog
 * @returns {*}
 * @constructor
 */
function AffectedContractsDisclaimer({ affectedContractsCount, onReadDisclaimer, onCloseDialog }) {
    return (
        <Fragment>
            <Feedback type="info" modifierClass="u-margin-bottom-small">
                {_transMd('userForm.hetaJoinAffectedContracts.disclaimer', {
                    count: affectedContractsCount,
                }, true)}
            </Feedback>

            <ActionBar>
                <Button
                    onClick={onCloseDialog}
                >
                    {_trans('button.cancel')}
                </Button>
                <Button
                    mdIcon="arrow_forward"
                    iconAfterText
                    primary
                    onClick={onReadDisclaimer}
                >
                    {_trans('button.ok_continue')}
                </Button>
            </ActionBar>
        </Fragment>
    );
}

AffectedContractsDisclaimer.propTypes = {
    /**
     * Soppareiden lukumäärä joilla ei ole palkkaryhmää.
     */
    affectedContractsCount: PropTypes.number.isRequired,
    onReadDisclaimer: PropTypes.func.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
};

export default AffectedContractsDisclaimer;
