import { Fragment } from 'react';
import produce from 'immer';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { FormField } from 'shared/components/Formik';
import { Value } from 'shared/components/Value';
import InputGroup from 'shared/components/InputGroup';
import { DivisorField } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Salaries/DivisorField';
import InputPostfix from 'shared/components/InputPostfix';

/**
 * Palkkioiden jakosuhteen esittäminen.
 * Uudella sopparilla näyttää syöttökentät,
 * voimassa olevalla vain aiemmin syötetyt jakosuhteet ja palkkioiden jakautumisen.
 * @param employees
 * @param divisors
 * @param divisorHelpers
 * @param divisorKey
 * @param canChangeDivisors
 * @param totalSalary
 * @returns {*}
 * @constructor
 */
export const Divisors = ({ employees, divisors, divisorHelpers, divisorKey, canChangeDivisors, totalSalary }) => employees.map((employee) => {
    const index = divisors.findIndex((divisor) => divisor?.userId === employee?.userId);
    const divisorPercentage = divisors[index] && divisors[index][divisorKey]
        ? parseFloat(divisors[index][divisorKey]).toFixed(2)
        : 0;
    const total = divisors.reduce((acc, cur) => acc + parseInt(cur[divisorKey] ?? '0', 10), 0);
    const divisorAmount = divisorPercentage === 0 ? 0 : (divisorPercentage / 100) * totalSalary;

    return (
        <FormField
            key={employee.userId}
            label={employee.fullName}
            name={`${fieldNames.CONTRACT_GROUP_DIVISORS}[${index}].${divisorKey}`}
        >
            {canChangeDivisors && (
                <InputGroup>
                    <DivisorField
                        validate={() => total === 100 ? null : _trans('Jakosuhde tulee olla 100%', {}, 'jobContract')}
                        size={3}
                        name={`${fieldNames.CONTRACT_GROUP_DIVISORS}[${index}].${divisorKey}`}
                        onCustomChange={(value) => {
                            value = isNaN(parseFloat(value)) ? 0 : value;
                            const otherIndex = index === 0 ? 1 : 0;
                            if (value > 100) {
                                value = 100;
                            }

                            divisorHelpers.setValue(produce(divisors, (draftValue) => {
                                draftValue[index][divisorKey] = value;
                                draftValue[otherIndex][divisorKey] = 100 - value;
                            }));
                        }}
                    />
                    <InputPostfix postfix="%"/>
                </InputGroup>
            )}
            {divisors.length > 1 && (
                <Fragment>
                    <Value>
                        {_currency(divisorAmount)}
                    </Value>
                    {! canChangeDivisors && (<span>{` (${_numberFormat(divisorPercentage)} %)`}</span>)}
                </Fragment>
            )}
        </FormField>

    );
});

