import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { getPayslipDeliveryMethodOptions } from 'ContractV3/constants/getPayslipDeliveryMethods';
import { Value } from 'shared/components/Value';

/**
 * Valittu palkkalaskelman toimitustapa.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const PayslipDeliveryMethod = (props) => {
    const { value } = props;
    const isEPayslipEnabled = useSelector(select.userMetadata.isMaventaEPayslipEnabled);

    const isNewContract = useSelector(select.contract.isNewContract);
    const isCommune = useSelector(select.userMetadata.isCommune);

    // Tallennetulla sopparilla TT saadaan suoraan sopparin tiedoista
    const employees = useSelector(false === isNewContract
        ? select.contract.getEmployees
        : select.employees.getEmployees);
    const payslipDeliveryMethod = getPayslipDeliveryMethodOptions({ isCommune, isEPayslipEnabled }).find((method) => parseInt(method.value, 10) === parseInt(value, 10));

    return (
        <div>
            <Value ariaDescribedBy="deliveryInfo">{payslipDeliveryMethod?.label ?? ''}</Value>
            {payslipDeliveryMethod?.description && (
                <div id="deliveryInfo">
                    {employees.map((employee, index) => {
                        const employeeEmail = employee?.email;
                        return (
                            <p key={index}>
                                {_transMd(
                                    payslipDeliveryMethod.description,
                                    { email: employeeEmail },
                                    {},
                                    // isAssignmentContract ? 'assignment_contract' : 'contract'
                                )}
                            </p>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

PayslipDeliveryMethod.propTypes = {
    value: PropTypes.string.isRequired,
};

export default PayslipDeliveryMethod;
