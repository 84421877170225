import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fromJS, Map, List } from 'immutable';
import { select } from '@rematch/select';
import { ActionBar, GoBackLink, Page } from 'shared/components';
import UserForm from 'shared/UserDetails/containers/shared/UserForm';
import { actions as notificationActions } from 'shared/stores/notifications';
import { partiesActions, partiesSelectors } from 'shared/Parties/parties';
import { routeKeys } from 'Contract/constants/routes';
import resolveUrl from 'shared/utils/resolveUrl';
import { contractActions } from 'Contract/stores/contract';
import { jobTitlesSelectors } from 'shared/JobTitles/jobTitles';
import api from 'api';
import companyUserRelationTypes from 'shared/constants/companyUserRelationTypes';
import contractSelectors from 'Contract/contractSelectors';
import { resolvePartyId } from 'shared/Parties/utils/resolvePartyId';
import { dimension } from 'shared/shapes/dimensions';
import {
    UserAttachedDimensionsForm
} from 'shared/UserDetails/containers/company/UserAttachedDimensions/UserAttachedDimensionsForm';
import { FormField } from 'shared/components/Formik';
import { Value } from 'shared/components/Value';

@connect((state) => {
    const relationType = (new URLSearchParams(location.search)).get('relation');
    const isCared = parseInt(relationType) === companyUserRelationTypes.RELATION_CARED;
    return {
        routes: state.routes,
        benefitDecisionId: state.benefitDecisionId,
        previousParties: partiesSelectors.getPreviousParties(state, isCared ? 'previousCaredParties' : 'previousParties'),
        isCommune: partiesSelectors.isCommuneUser(state),
        isCompany: partiesSelectors.isCompanyUser(state),
        initialEmployee: partiesSelectors.getInitialEmployee(state),
        jobTitles: jobTitlesSelectors.getJobTitles(state),
        relationType,
        isEmployee: ! isCared,
        isCared,
        isAssignmentContract: contractSelectors.isAssignmentContract(state),
        userRole: partiesSelectors.getUserRole(state),
        visibilityDimensions: select.dimensions.getVisibilityDimensions(state),
        visibilityDimensionTypeName: select.dimensions.getVisibilityDimensionTypeName(state),
        isMonthlySalaryCustomerInvoicingEnabled: select.userMetadata.isMonthlySalaryCustomerInvoicingEnabled(state),
    };
})
export default class NewEmployeeForm extends Component {

    static propTypes = {
        isCommune: PropTypes.bool,
        routes: PropTypes.instanceOf(Map),
        benefitDecisionId: PropTypes.string,
        previousParties: PropTypes.instanceOf(List),
        dispatch: PropTypes.func,
        isCompany: PropTypes.bool,

        /**
         * Uuden työntekijän mahdolliset esitäytetyt tiedot. Esim. hetu.
         */
        initialEmployee: PropTypes.instanceOf(Map),
        jobTitles: PropTypes.instanceOf(List),
        isEmployee: PropTypes.bool,
        relationType: PropTypes.string,
        isAssignmentContract: PropTypes.bool,
        isCared: PropTypes.bool,
        userRole: PropTypes.number,
        visibilityDimensions: PropTypes.arrayOf(dimension),
        visibilityDimensionTypeName: PropTypes.string,
        isMonthlySalaryCustomerInvoicingEnabled: PropTypes.bool,
    };

    static defaultProps = {
        isCommune: false,
        routes: Map(),
        benefitDecisionId: '',
        previousParties: List(),
        dispatch() {},
        isCompany: false,
        initialEmployee: Map({}),
        jobTitles: List(),
        isEmployee: true,
        relationType: companyUserRelationTypes.RELATION_EMPLOYEE,
        isAssignmentContract: false,
        isCared: false,
        userRole: null,
        visibilityDimensions: [],
        visibilityDimensionTypeName: null,
        isMonthlySalaryCustomerInvoicingEnabled: false,
    };

    state = {
        userContracts: [],
        showVisibilityForm: false,
        userId: null,
        ssn: null,
        user: {},
        isMultipleWorkExpLimitsApplied: null,
    };

    componentDidMount() {
        const { initialEmployee, isEmployee, isCommune } = this.props;
        if (initialEmployee.size > 1 && isEmployee) {
            // Jos muokataan, haetaan sopimukset, jotta voidaan mahdollisesti päivittää niihinkin päivittyneet tiedot
            api.get(Routing.generate('contracts_api_1_get_user_contracts', { user: initialEmployee.get('userId') }))
                .then((userContracts) => this.setState({ userContracts }));
        }

        /**
         * Jos kommuuni ja tietoa monikokemustasoista ei ole haettu, niin pitää hakea tässä. Kehotetaan siis
         * säätämään kokemukset per tes käsin jos nämä käytössä. Perusformilla ei tukea monitasoille.
         */
        if (isCommune && this.state.isMultipleWorkExpLimitsApplied === null) {
            const route = '/api/v1/work-experience/meta';

            api.get(route)
                .then((response) => {
                    const data = response.data;
                    const isMultipleWorkExpLimitsApplied = data.isMultipleWorkExpLimitsApplied;

                    this.setState({ isMultipleWorkExpLimitsApplied });
                });
        }
    }

    componentWillUnmount() {
        // Unmoountissa tyhjennetään intial tiedot, jotta jos muokataan vaikka hoidettavaa, niin ei näy vanhat tiedot
        this.props.dispatch(partiesActions.resetInitialEmployee());
    }

    onUserUpdate = (user) => {
        const { visibilityDimensions, dispatch } = this.props;

        if (visibilityDimensions.length > 0) {
            if (visibilityDimensions.length === 1) {
                dispatch.userAttachedDimensions.postUserAttachedDimensions(
                    {
                        user: user.userId,
                        model: {
                            dimensions: visibilityDimensions.map((dim) => dim.id),
                        },
                    }
                ).then((json) => {
                    if (json.status === 'ok') {
                        this.onUserSuccessUpdate(user);
                    }
                });
            } else {
                this.setState({
                    showVisibilityForm: true,
                    userId: user.userId,
                    ssn: user.socialSecurityNumber,
                    user,
                });
            }
        } else {
            this.onUserSuccessUpdate(user);
        }
    };

    onUserSuccessUpdate = (user) => {
        const { dispatch, previousParties, isEmployee } = this.props;
        const userId = user.userId;
        // Ei haeta partyjä uudestaan apista vaan lisätään vaan se listaan. (Ei tarvi lataa uudestaan 4000 käyttäjää)
        // -> poistetaan vanha versio userista ja lisätään uusi. Näkyy muuten tuplana, jos vaihtaa esim. sukunimeä.
        const userFilteredFromParties = previousParties.filter((user)=> user.toJS().userId !== userId);
        const newPreviousParties = userFilteredFromParties.push(fromJS(user));
        dispatch(partiesActions.previousPartiesOk(
            newPreviousParties.sortBy((party) =>party.get('fullName')),
            isEmployee ? 'previousParties' : 'previousCaredParties'
        ));

        if (isEmployee) {
            dispatch.employeeWorkExperience.fetchEmployeeWorkExperience(userId);
            dispatch(contractActions.updateEmployerAddress());
            dispatch(partiesActions.partySet(user));
        } else {
            dispatch(partiesActions.caredGetOk(user));
        }
        this.pushToForm();
    }

    /**
     * Käyttäjä haluaa vaihtaa työntekijää. Tyhjennetään esitäytetty hetu.
     */
    onUserChange = () => {
        const { dispatch } = this.props;
        dispatch(partiesActions.resetInitialEmployee());
    };

    onUserNotVisibilityAccess = (userId, ssn) => {
        const { visibilityDimensions } = this.props;

        if (visibilityDimensions.length === 1) {
            this.postVisibilityDimensions(userId);
        } else {
            this.setState({
                showVisibilityForm: true,
                userId,
                ssn,
            });
        }
    }

    async postVisibilityDimensions(userId) {
        const { isEmployee, dispatch } = this.props;
        const json = await dispatch.userAttachedDimensions.postUserAttachedDimensions(
            {
                user: userId,
                model: {
                    dimensions: this.props.visibilityDimensions.map((dim) => dim.id),
                },
            }
        );

        if (json.status === 'ok') {
            dispatch.notifications.addSuccess(isEmployee
                ? _trans('Hoitajan tiedot on jo tallennettu palveluun. Ole hyvä ja tarkasta tiedot.', {}, 'jobContract')
                : _trans('Hoidettavan tiedot on jo tallennettu palveluun. Ole hyvä ja tarkasta tiedot.', {}, 'jobContract')
            );
            this.fetchUser(userId).then((user) => this.onUserSuccessUpdate(user));
        }
    }

    async fetchUser(user) {
        try {
            return await api.get(`/api/v2/users/${user}`);
        } catch (e) {
            return { status: false, };
        }
    }

    pushToForm() {
        const { dispatch, routes, benefitDecisionId } = this.props;
        dispatch(push(resolveUrl(routes.get(routeKeys.NEW_CONTRACT), { decisionId: benefitDecisionId || '' })));
    }

    /**
     * Uuden työntekijän tietolomake. Jos esitiedot on saatu aiemmin asetetaan lomake automaattisesti muokkaustilaan.
     * @returns {*}
     */
    render() {
        const {
            dispatch,
            routes,
            benefitDecisionId,
            isCommune,
            isCompany,
            initialEmployee,
            jobTitles,
            isEmployee,
            relationType,
            isAssignmentContract,
            isCared,
            userRole,
            visibilityDimensionTypeName,
            isMonthlySalaryCustomerInvoicingEnabled,
        } = this.props;

        const partyId = resolvePartyId(isCared, isAssignmentContract, userRole);

        // Luodaan uusi henkilö esitäytetyllä hetulla
        const initialSsn = initialEmployee.get('socialSecurityNumber', '');

        // Muokataan koko henkilöä
        const isEditingEmployee = initialEmployee.size > 1;
        const heading = _trans(isEditingEmployee
            ? `contract.${partyId}.modify.heading`
            : `contract.${partyId}.new.heading`
        );

        return (
            <Page
                isBodyClear
                heading={heading}
                title={heading}
                maxWidth="medium"
            >
                <ActionBar hasMargin>
                    <GoBackLink to={resolveUrl(routes.get(routeKeys.NEW_CONTRACT), { decisionId: benefitDecisionId })}/>
                </ActionBar>
                {this.state.showVisibilityForm && this.state.userId !== null
                    ? (
                        <UserAttachedDimensionsForm
                            isRequired
                            userId={this.state.userId}
                            onUserAttachedDimensionsAdd={() => Object.values(this.state.user).length > 0
                                ? this.onUserSuccessUpdate(this.state.user)
                                : this.fetchUser(this.state.userId).then((user) => this.onUserSuccessUpdate(user))}
                        >
                            {(dimSelector) => (
                                <Fragment>
                                    <p>
                                        {Object.values(this.state.user).length > 0
                                            ? _trans('Valitse mihin %dimensionType%-ryhmiin henkilö liitetään. Valitse vähintään yksi.', { dimensionType: visibilityDimensionTypeName.toLowerCase() }, 'jobContract')
                                            : _trans('Henkilö löytyi jo Oima-palvelusta. Valitse mihin %dimensionType%-ryhmiin henkilö liitetään. Valitse vähintään yksi.', { dimensionType: visibilityDimensionTypeName.toLowerCase() }, 'jobContract')
                                        }
                                    </p>
                                    <FormField isContentFormField label={_trans('Henkilötunnus', {}, 'common')}>
                                        <Value>{this.state.ssn}</Value>
                                    </FormField>
                                    {Object.values(this.state.user).length > 0 && (
                                        <FormField isContentFormField label={_trans('Nimi', {}, 'common')}>
                                            <Value>{this.state.user.fullName}</Value>
                                        </FormField>
                                    )}
                                    {dimSelector}
                                </Fragment>
                            )}
                        </UserAttachedDimensionsForm>
                    ) : (
                        <UserForm
                            jobTitles={jobTitles.toJS()}
                            userContracts={fromJS(this.state.userContracts)}
                            initialUser={isEditingEmployee ? initialEmployee.toJS() : null}
                            initialSsn={isEditingEmployee ? '' : initialSsn}
                            isEditMode={initialSsn.length > 0}
                            isCompany={isCompany}
                            isCommune={isCommune}
                            isNewUser={! isEditingEmployee}
                            // Jos muokataan, niin nullita menee, muuten tutkitaan query parametristä
                            relationType={isEditingEmployee ? null : relationType}
                            isEmployee={isEmployee}
                            onUserUpdate={this.onUserUpdate}
                            onUserChange={this.onUserChange}
                            onError={(error) => dispatch(notificationActions.addNotification({ type: 'error', message: error }))}
                            addNotification={(notification) => dispatch(notificationActions.addNotification(notification))}
                            isAssignmentContract={isAssignmentContract}
                            onUserNotVisibilityAccess={this.onUserNotVisibilityAccess}
                            isMultipleWorkExpLimitsApplied={this.state.isMultipleWorkExpLimitsApplied}
                            isMonthlySalaryCustomerInvoicingEnabled={isMonthlySalaryCustomerInvoicingEnabled}
                        />
                    )}
            </Page>
        );
    }
}
