import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { DateInputField } from 'shared/components/Formik';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { isValidDate } from 'shared/utils/dateUtils';

/**
 * Voimassaolon päättymispäivä.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const EndDate = (props) => {
    const { name, placeholder } = props;
    const [startDateField] = useField(fieldNames.START_DATE);
    const startDate = new Date(startDateField.value);

    // Tarkistetaan että on oikeasti Date-objekti ennen kuin lisätään mitään
    const minimumDate = (startDateField.value && isValidDate(startDate))
        ? startDate
        : null;

    return (
        <DateInputField name={name} placeholder={placeholder} minimumDate={minimumDate} />
    );
};

EndDate.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
};

EndDate.defaultProps = {
    placeholder: '',
};

export default EndDate;
