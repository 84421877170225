import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renderöi syöttökentän viereen tarkoitetun laatikon joka kertoo syötetön
 * sisällön kontekstista selkeämmin.
 *
 * Esimerkkikoodi:
 *
 * <InputGroup>
 *     <input type="text" />
 *     <InputPostfix postfix="€ / vrk" />
 * </InputGroup>
 *
 * @param text
 * @returns {*}
 * @constructor
 */
const InputPostfix = ({ postfix, }) => (
    <strong className="c-input-postfix">
        {postfix}
    </strong>
);

InputPostfix.propTypes = {
    /**
     * Pääte.
     */
    postfix: PropTypes.string.isRequired,
};

export default InputPostfix;
