import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import UserBlock from 'shared/components/UserBlock';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';

/**
 * Valittu työnantaja.
 * @returns {JSX.Element}
 * @constructor
 */
const Employer = ({ label, attributes: { isSsnVisible } }) => {
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isLoading = useSelector((state) => state.loading.effects.loggedUser.fetchUserData);
    const employer = useSelector(select.contract.getEmployer);

    const details = [
        [employer.fullAddress],
        [employer.phoneNumber],
        [employer.email]
    ].filter((detail) => detail && detail?.toString().trim() !== '');

    return (
        <SummaryListItem term={label}>
            <div className="u-margin-bottom-tiny">
                <UserBlock
                    user={employer}
                    isLoading={isLoading}
                    size="small"
                    // HA pääsee työnantajan tietoihin. OPH-puolella luonnollisesti ei.
                    hasLinkToDetails={isCommune}
                    relationType="employer"
                    isSsnVisible={isSsnVisible}
                />
            </div>
            {isCommune && (
                <div>
                    {details.map((detail, key) => (
                        <Fragment key={key}>
                            {detail}<br/>
                        </Fragment>
                    ))}
                </div>
            )}
        </SummaryListItem>
    );
};

Employer.propTypes = {
    label: PropTypes.string,
    attributes: PropTypes.shape({
        isSsnVisible: PropTypes.bool,
    }).isRequired,
};

Employer.defaultProps = {
    label: '',
};

export default Employer;
