export default {
    OWN_TERMINATION: 1,
    OTHER_REASON: 2,
    PERSON_REASON: 3,
    PRODUCTIVITY_OR_FINANCIAL_REASON: 4,
    TERMINATION_BY_MUTUAL_AGREEMENT: 5,
    STARTED_PENSION: 6,
    WORK_TIME_END: 7,
    KEVA_WORK_TIME_END: 10,
    TECHNICAL_REASON: 99,

    getHelpText(reason) {
        switch (reason) {
            case this.OWN_TERMINATION:
                return 'contract.termination.reasons.own_termination.help';
            case this.OTHER_REASON:
                return 'contract.termination.reasons.other_reason.help';
            case this.PERSON_REASON:
                return 'contract.termination.reasons.person_reason.help';
            case this.PRODUCTIVITY_OR_FINANCIAL_REASON:
                return 'contract.termination.reasons.productivity_or_financial_reason.help';
            case this.TERMINATION_BY_MUTUAL_AGREEMENT:
                return 'contract.termination.reasons.termination_by_mutual_agreement.help';
            case this.STARTED_PENSION:
                return 'contract.termination.reasons.started_pension.help';
            case this.WORK_TIME_END:
                return 'contract.termination.reasons.work_time_end.help';
            default:
                return '';
        }
    },

    getLabel(reason) {
        switch (reason) {
            case this.OWN_TERMINATION:
                return 'contract.termination.reasons.own_termination.label';
            case this.OTHER_REASON:
                return 'contract.termination.reasons.other_reason.label';
            case this.PERSON_REASON:
                return 'contract.termination.reasons.person_reason.label';
            case this.PRODUCTIVITY_OR_FINANCIAL_REASON:
                return 'contract.termination.reasons.productivity_or_financial_reason.label';
            case this.TERMINATION_BY_MUTUAL_AGREEMENT:
                return 'contract.termination.reasons.termination_by_mutual_agreement.label';
            case this.STARTED_PENSION:
                return 'contract.termination.reasons.started_pension.label';
            case this.WORK_TIME_END:
                return 'contract.termination.reasons.work_time_end.label';
            case this.KEVA_WORK_TIME_END:
                return 'contract.termination.reasons.keva_work_time_end.label';
            case this.TECHNICAL_REASON:
                return 'contract.termination.reasons.technical_reason.label';
            default:
                return '';
        }
    },
};
