import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { TableSalaryValues } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Summary/shared/TableSalaryValues';

/**
 * Taulukkopalkan valinnat.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TableSalaryAttributes = ({ value }) => {
    const metadata = useSelector(select.tableSalary.getMetadata);
    const isLoading = useSelector((state) => state.loading.effects.tableSalary.fetchTableSalaryMetadata);

    if (isLoading) return null;

    if (typeof value !== 'object') return null;

    return (
        <TableSalaryValues values={value} metadata={metadata} />
    );
};

TableSalaryAttributes.propTypes = {
    value: PropTypes.object.isRequired,
};

export default TableSalaryAttributes;
