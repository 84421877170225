import React from 'react';
import PropTypes from 'prop-types';
import { Value } from 'shared/components/Value';

/**
 * Vaihtoehdoista arvon etsiminen.
 * @param name
 * @param value
 * @param options
 * @returns {JSX.Element}
 * @constructor
 */
export const OptionValue = ({ name, value, options }) => {
    const option = options.find((option) => option.value === value);
    return (
        <Value ariaDescribedBy={name}>
            {option?.label ?? '-'}
        </Value>
    );
};

OptionValue.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    options: PropTypes.array.isRequired,
};
