import _ from 'lodash';
import api from 'api';

/**
 * Tulolajit.
 */
export default {
    state: [],

    reducers: {
        setIncomeTypes: (state, payload) => payload,
    },

    selectors: {
        getIncomeTypes: (state) => state,
    },

    effects: () => ({
        /**
         * Palauttaa tulolajit.
         * @param includePseudoTypes - otetaanko pseudotyypit matkaan.
         * @returns {Promise<void>}
         */
        async fetchIncomeTypes(includePseudoTypes = false) {
            try {
                const json = await api.get(Routing.generate('get_earnedincomes_types', includePseudoTypes && { includePseudoTypes }));
                this.setIncomeTypes(_.map(_.orderBy(json.data, 'name')));
            } catch (e) {
                console.error(e);
            }
        },
    }),
};

export const loadingSelectors = {
    isLoadingIncomeTypes: (state) => _.get(state, `loading.effects.earnedIncomes.fetchIncomeTypes`, false),
};
