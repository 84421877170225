import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popover } from 'shared/components';
import { resolveColumnIdentifier } from 'shared/containers/DataTable7/utils/resolveColumnIdentifier';

export const ColumnFilterList = ({ clearFilteredColumns, columns, onColumnFilter, selectedColumns }) => (
    <div className="u-align-item-end u-margin-right-small">
        <Popover position="right">
            <div>
                <Button
                    mdIcon="checklist"
                >
                    {_trans('Näytä tiedot', {}, 'common')}
                </Button>
            </div>
            <div>
                <Button
                    size="small"
                    modifierClass="u-text-center u-margin-bottom-small u-padding-horizontal-none"
                    flat
                    iconSize="small"
                    mdIcon="settings_backup_restore"
                    onClick={() => clearFilteredColumns()}
                >
                    {_trans('Palauta oletukset', {}, 'common')}
                </Button>
                <ul className="o-list-bare u-margin-none">
                    {columns
                        .filter((column) => column.isLocked !== true)
                        .filter((column) => (column.show ?? true) === true)
                        .map((column, index) =>
                            <li key={index}>
                                <label>
                                    <input
                                        type="checkbox"
                                        name="filterColumn"
                                        value={resolveColumnIdentifier(column)}
                                        onChange={(e) => onColumnFilter(e)}
                                        checked={! selectedColumns.includes(resolveColumnIdentifier(column))}
                                    />
                                    {column.Header}
                                </label>
                            </li>
                        )}
                </ul>
            </div>
        </Popover>
    </div>
);

ColumnFilterList.propTypes = {
    clearFilteredColumns: PropTypes.func.isRequired,
    onColumnFilter: PropTypes.func.isRequired,
    selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        isLocked: PropTypes.bool,
        Header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })).isRequired,
};
