import React from 'react';
import PropTypes from 'prop-types';
import { FormField, Field, FormikErrors, IntegerField, FormikDateInput } from 'shared/components/Formik';
import { StLabel } from 'shared/components';

export const DateTo = ({ values }) => (
    <div className="o-stack o-stack--left o-stack--center u-margin-small">
        <Field
            type="radio"
            className="u-margin-right-small"
            name="rangeType"
            value="hoursPerWeek"
        />
        <FormikErrors name="rangeType" />
        <div className="u-margin-right-small">
            <FormField id="hoursPerWeek" name="hoursPerWeek">
                <IntegerField
                    size={2}
                    disabled={(values.rangeType !== 'hoursPerWeek')}
                />
            </FormField>
        </div>
        <StLabel htmlFor="hoursPerWeek" modifierClass="u-margin-right-large u-margin-bottom-none">
            {_trans('Vähintään tuntia viikossa', {}, 'extract')}
        </StLabel>
        <div className="u-margin-right-small">
            <FormField name="dateTo">
                <FormikDateInput
                    id="dateTo"
                    placeholder={_trans('date_range.end')}
                    isReadOnly={(values.rangeType !== 'hoursPerWeek')}
                />
            </FormField>
        </div>
        <StLabel htmlFor="dateTo" modifierClass="u-margin-bottom-none">
            {_trans('Laske tästä päivästä taaksepäin', {}, 'extract')}
        </StLabel>
    </div>
);

DateTo.propTypes = {
    values: PropTypes.object.isRequired,
};
