import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { Field, Formik } from 'formik';
import { select } from '@rematch/select';
import * as Yup from 'yup';
import _ from 'lodash';
import { StLabel, StField } from 'shared/components/StForm';
import FormikErrors from 'shared/components/Formik/FormikErrors';
import { SubmitButton, Page, ActionBar, Placeholder } from 'shared/components';

@connect((state, props) => {
    const id = _.get(props, 'match.params.id', null);
    return {
        id: parseInt(id, 10),
        isNew: ! parseInt(id, 10),
        account: select.bookkeepingAccount.getAccount(state),
        accounts: select.bookkeepingAccount.getAccounts(state),
        isLoading: _.get(state, 'loading.effects.bookkeepingAccount.fetchAccount', false),
        isSaving: _.get(state, 'loading.effects.bookkeepingAccount.postAccount', false) ||
            _.get(state, 'loading.effects.bookkeepingAccount.putAccount', false),
    };
})
export default class BookkeepingAccountForm extends Component {

    static propTypes = {
        id: PropTypes.number.isRequired,
        isNew: PropTypes.bool.isRequired,
        account: PropTypes.instanceOf(Map),
        accounts: PropTypes.instanceOf(List),
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        dispatch: PropTypes.func,
        routes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        account: Map({}),
        accounts: List(),
        isLoading: false,
        isSaving: false,
        dispatch() {},
    };

    constructor(props) {
        super(props);
        if (! props.isNew) {
            props.dispatch.bookkeepingAccount.fetchAccount(props.id);
        }
    }

    onSubmit = (model) => {
        const { dispatch, isNew, routes } = this.props;
        if (isNew) {
            dispatch.bookkeepingAccount.postAccount({ ...model, returnUrl: routes.BOOKKEEPING_ACCOUNTS });
        } else {
            dispatch.bookkeepingAccount.putAccount({ ...model, returnUrl: routes.BOOKKEEPING_ACCOUNTS });
        }
    };

    validateNumber = (value) => {
        const { isNew, accounts, account } = this.props;
        if (isNew) {
            return ! accounts.filter((account) =>
                account.get('number', '') === value
            ).size > 0;
        }
        return ! accounts
            .filter((bookkeepingAccount) => bookkeepingAccount.get('id') !== account.get('id'))
            .filter((account) => account.get('number') === value)
            .size > 0;
    };

    renderForm() {
        const { account, isNew, isSaving, isLoading } = this.props;
        return (
            <Formik
                initialValues={ isNew ? { number: '', name: '', } : account.toJS()}
                enableReinitialize
                onSubmit={this.onSubmit}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required(_trans('validation.required')),
                    number: Yup.string().required(_trans('validation.required'))
                        .test('number-validator', _trans('company_manager.bookkeeping_accounts.form.number.non_unique'), this.validateNumber),

                })}
            >
                {(props) => (
                    <form className="o-form o-form--responsive u-margin-bottom-small" onSubmit={props.handleSubmit}>
                        <StLabel htmlFor="name">
                            {_trans('company_manager.bookkeeping_accounts.form.number.label')}
                        </StLabel>
                        <StField>
                            <Placeholder type="auto" isPending={isLoading} delayMs={0}>
                                <div>
                                    <Field
                                        id="number"
                                        name="number"
                                        type="text"
                                    />
                                    <FormikErrors name="number"/>
                                </div>
                            </Placeholder>
                        </StField>
                        <StLabel htmlFor="name">
                            {_trans('company_manager.bookkeeping_accounts.form.name.label')}
                        </StLabel>
                        <StField>
                            <Placeholder type="auto" isPending={isLoading} delayMs={0}>
                                <div>
                                    <Field
                                        id="name"
                                        name="name"
                                        type="text"
                                    />
                                    <FormikErrors name="name"/>
                                </div>
                            </Placeholder>
                        </StField>
                        <ActionBar alignItems="right">
                            <SubmitButton isPending={isSaving}>{isNew ? _trans('button.save') : _trans('button.save_changes')}</SubmitButton>
                        </ActionBar>
                    </form>
                )}
            </Formik>
        );
    }

    render() {
        const { routes } = this.props;
        return (
            <Page
                goBack={{ to: routes.BOOKKEEPING_ACCOUNTS, text: _trans('link.return'), }}
                isBodyClear
                heading={_trans(this.props.isNew ? 'company_manager.bookkeeping_accounts.create_new' : 'company_manager.bookkeeping_accounts.edit')}
            >
                {this.renderForm()}
            </Page>
        );
    }
}
