import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { componentSizes } from 'shared/constants';

/**
 * Yksinkertainen edistymispalkki.
 *
 * @param value
 * @param valueText
 * @param max
 * @param size
 * @param isAnimated
 * @param isValueVisible
 * @returns {*}
 * @constructor
 */
function ProgressBar({ value, valueText, max, size, isAnimated, isValueVisible }) {
    let progress = value / max;

    if (progress > 1) progress = 1;

    const progressPercent = (progress * 100).toFixed(0);
    const ariaValueText = valueText !== '' ? valueText : `${progressPercent}`;

    const barContent = (
        <div
            role="progressbar"
            aria-valuenow={value}
            aria-valuemin={0}
            aria-valuemax={max}
            aria-valuetext={ariaValueText}
            className="c-progress-bar__background u-position-relative"
        >
            <div
                className={classNames('c-progress-bar__value o-pin o-pin--left o-pin--top')}
                style={{
                    transform: `scaleX(${progress})`,
                }}
            />
        </div>
    );

    let content;

    // Näytetään valmiusaste prosentteina palkin perässä
    if (isValueVisible) {
        content = (
            <div className="c-progress-bar o-pack o-pack--middle">
                <div className="o-pack__item">
                    {barContent}
                </div>
                <div className="c-progress-bar__postfix o-pack__item">
                    {valueText !== '' ? valueText : `${progressPercent} %`}
                </div>
            </div>
        );
    } else {
        // Muutoin pelkkä palkki
        content = barContent;
    }

    return (
        <div
            className={classNames('c-progress-bar', {
                'c-progress-bar--animated': isAnimated,

                // Arvon näyttämisen kanssa palkin ja arvon on oltava vierekkäin joten käytetään
                // display: tablea.
                'o-pack o-pack--middle': isValueVisible,

                'c-progress-bar--tiny': size === componentSizes.TINY,
                'c-progress-bar--small': size === componentSizes.SMALL,
                'c-progress-bar--large': size === componentSizes.LARGE,
            })}
        >
            {content}
        </div>
    );
}

ProgressBar.defaultProps = {
    valueText: '',
    max: 100,
    size: componentSizes.SMALL,
    isAnimated: false,
    isValueVisible: true,
};

ProgressBar.propTypes = {
    /**
     * Nykyinen arvo.
     */
    value: PropTypes.number.isRequired,

    /**
     * Tekstuaalinen, luettavissa oleva arvo.
     * Käytetään saavutettavuuden parantamisessa.
     * Oletuksena prosentuaalinen arvo.
     */
    valueText: PropTypes.string,

    /**
     * Maksimiarvo. Tästä ja arvosta lasketaan prosentuaalinen arvo.
     */
    max: PropTypes.number,

    size: PropTypes.oneOf(_.map(componentSizes)),

    /**
     * Onko arvon muuttuminen animoitu.
     */
    isAnimated: PropTypes.bool,

    /**
     * Näytetäänkö prosentuaalinen valmiusaste palkin lopussa.
     */
    isValueVisible: PropTypes.bool,
};

export default ProgressBar;
