import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Field } from './Field';

/**
 * Social security number. Automatically uppercases alphabets.
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const SocialSecurityNumberField = ({ children, ...rest }) => {
    const [field,,helpers] = useField(rest?.name);
    return (
        <Field
            type="text"
            {...field}
            {...rest}
            onChange={(event) => helpers.setValue(event.target.value?.toString().toUpperCase())}
            onBlur={field.onBlur}
        >
            {children}
        </Field>
    );
};

SocialSecurityNumberField.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

SocialSecurityNumberField.defaultProps = {
    children: undefined,
};
