import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

/**
 * Hidden field. Sets default value automatically.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const HiddenField = ({ name, value }) => {
    const [field,,helpers] = useField(name);
    useEffect(() => {
        // false = älä validoi kun asetetaan koska käyttäjä tod. näk. ei voi tälle näkymättömälle pellin
        // alla asetettavalle arvolle tehdä mitään.
        if (field.value !== value) {
            helpers.setValue(value);
        }
    }, [helpers, field.value, value]);

    return null;
};

HiddenField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
};

HiddenField.defaultProps = {
    value: null,
};

