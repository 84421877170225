import React from 'react';
import PropTypes from 'prop-types';
import { allowedUserRoles } from '../constants';
import { ReactComponent as EmployeeIcon } from 'images/roles/employee.svg';
import { ReactComponent as EmployerIcon } from 'images/roles/employer.svg';
import { ReactComponent as FamilyCarerIcon } from 'images/roles/familyCarer.svg';
import { ReactComponent as BookKeep } from 'images/roles/bookkeep.svg';
import { ReactComponent as CompanyIcon } from 'images/roles/company.svg';
import { ReactComponent as CommuneIcon } from 'images/roles/commune.svg';
import userRoles from 'shared/constants/userRoles';

/**
 * Rooli-ikoni käyttäjän roolin perusteella.
 * @param userRole
 * @param rest
 * @returns {*}
 * @constructor
 */
export const UserRoleIcon = ({ userRole, ...rest }) => {
    switch (userRole) {
        case userRoles.EMPLOYEE:
            return <EmployeeIcon {...rest} />;

        case userRoles.EMPLOYER:
            return <EmployerIcon {...rest} />;

        case userRoles.FAMILY_CARER:
            return <FamilyCarerIcon {...rest} />;

        case userRoles.BOOKKEEP:
            return <BookKeep {...rest} />;

        case userRoles.COMPANY_OPERATOR:
        case userRoles.COMPANY_APPROVER:
            return <CompanyIcon {...rest} />;

        case userRoles.COMMUNE_OPERATOR:
            return <CommuneIcon {...rest} />;
    }
};

UserRoleIcon.propTypes = {
    userRole: PropTypes.oneOf(allowedUserRoles).isRequired,
};
