export const holidayPayMethods = {
    PAID_IMMEDIATELY: 1,
    /**
     * @deprecated Ei voi valita enää sopimuksella
     */
    CUSTOMER_ACCOUNT: 2,

    /**
     * 14 päivän sääntö
     */
    BEFORE_HOLIDAY_14: 3,

    /**
     * 35 tunnin sääntö
     */
    BEFORE_HOLIDAY_35: 4,

    /**
     * Kuntien 'hybridimalli' lomien kertymisestä
     * (hyödyntää sekä 14 päivän & 35 tunnin sääntöä
     *
     * @deprecated
     */
    BEFORE_HOLIDAY_BUNDLED_COMMUNE_RULE: 5,

    /**
     * Ei lomaoikeuden piirissä, lomia ei kerrytetä - työntekijä oikeutettu lomakorvaukseen
     */
    ENTITLED_TO_HOLIDAY_COMPENSATION: 6,

    /**
     * Migratointihelpperi lomavuodelle 2021: ei päätetä maksusääntöä koska ollaan vasta
     * kesäkuussa menossa eli kertymistä ei voida oikein järkevästi päätellä mitään
     */
    UNRESOLVED_PAY_METHOD: 100,

    /**
     * Ei kerrytetä tai makseta yhtään mitään
     */
    NO_HOLIDAY_GENERATION: 555,

    /**
     * Perhehoidon 14vrk sääntö
     */
    FAMILY_CARE_14: 1000,
};

const holidayPayMethodMap = {
    [holidayPayMethods.PAID_IMMEDIATELY]: _trans('constant.holiday_payment_type.paid_immediately', {}, 'contract'),
    [holidayPayMethods.CUSTOMER_ACCOUNT]: _trans('constant.holiday_payment_type.customer_account', {}, 'contract'),
    [holidayPayMethods.BEFORE_HOLIDAY_14]: _trans('constant.holiday_payment_type.before_holiday_14', {}, 'contract'),
    [holidayPayMethods.BEFORE_HOLIDAY_35]: _trans('constant.holiday_payment_type.before_holiday_35', {}, 'contract'),
    [holidayPayMethods.ENTITLED_TO_HOLIDAY_COMPENSATION]: _trans('holiday_days.types.earning_type.entitled_to_holiday_compensation'),
    [holidayPayMethods.UNRESOLVED_PAY_METHOD]: _trans('holiday_days.details.paymethod_not_resolved'),
    [holidayPayMethods.FAMILY_CARE_14]: _trans('constant.holiday_payment_type.family_care_14', {}, 'contract'),
    [holidayPayMethods.NO_HOLIDAY_GENERATION]: _trans('constant.holiday_payment_type.no_holiday_generation'),
};

export const holidayPayMethodChangeTypes = {
    /**
     * Olemassaolevat rivit leimattu tälle tyypille, eivät varsinaisesti takautuvia muutoksia tai olosuhdemuutoksia
     * kun tyyppejä ei rivien generointiaikaan ollut. Lähinnä nyanssi frontille, jotta ei yritetä keksiä
     * olemassaoleville riveille kahta alempaa tyyppiä päälle.
     */
    TYPE_PRE_EXISTING_ROW: 0,
    /**
     * Saldoihin vaikuttava lomasäännön muutos, rullaa aiemmat kertymät uusiksi
     * paitsi jos kohdevapaasta on ehditty jo pitämään
     *
     * Vaihto vapaaoikeuteen tai vapaaoikeudesta pois voi tapahtua ainoastaan tämän kautta
     */
    RETROSPECTIVE_CHANGE: 1,
    /**
     * Olosuhdemuutos, joka astuu voimaan kesken lomavuoden. Huomaa: vapaaoikeuteen tai vapaaoikeudesta pois
     * ei voi vaihtaa olosuhdemuutoksella, sillä vapaaoikeus koskettaa ainoastaan koko lomavuotta.
     */
    CIRCUMSTANCE_CHANGE: 2,
};

export function translateHolidayYearPayMethodType(type) {
    if (type === holidayPayMethodChangeTypes.TYPE_PRE_EXISTING_ROW) {
        return '';
    }

    return type === 1 ? _trans('Takautuva muutos', {}, 'holiday_day') : _trans('Olosuhdemuutos', {}, 'holiday_day');
}

export function translateHolidayPayMethod(type) {
    if (type === '') {
        return _trans('Ei valintaa', {}, 'common');
    }
    return type in holidayPayMethodMap ? holidayPayMethodMap[type] : _trans('Tuntematon', {}, 'common');
}

export const appliesGeneralHolidayCalculation = (payMethod) =>
    [
        holidayPayMethods.BEFORE_HOLIDAY_14,
        holidayPayMethods.BEFORE_HOLIDAY_35,
        holidayPayMethods.FAMILY_CARE_14,
        holidayPayMethods.ENTITLED_TO_HOLIDAY_COMPENSATION,
        holidayPayMethods.UNRESOLVED_PAY_METHOD,
    ].includes(payMethod);
