import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { select } from '@rematch/select';
import { useSpring, animated } from 'react-spring/web.cjs';
import { useSelector, useDispatch } from 'react-redux';
import { UserRoleIcon } from '../components/UserRoleIcon';
import { NotificationHub } from './NotificationHub';
import Avatar from 'shared/components/Avatar';
import Badge from 'shared/components/Badge';
import BigMenu from 'shared/components/BigMenu';
import BigList from 'shared/components/BigList';
import { MenuBarItem } from 'shared/components/MenuBarItem';
import { MenuBarButton } from 'shared/components/MenuBarButton';
import userRoles from 'shared/constants/userRoles';
import { defaultAnimationConfig } from 'shared/constants/animations';

const hiddenStyles = {
    transform: 'translate3d(0%, 0, 0)',
    config: defaultAnimationConfig,
};

const visibleStyles = {
    transform: 'translate3d(-100%, 0, 0)',
    config: defaultAnimationConfig,
};

const UserMenu = (props) => {
    const {
        isNameHidden,
        isBigMenu,
        canSwitchRole,
    } = props;

    const dispatch = useDispatch();
    const isMenuOpen = useSelector(select.header.isUserMenuOpen);
    const isNotificationVisible = useSelector(select.header.isNotificationVisible);
    const currentRoute = useSelector(select.header.getCurrentRoute);

    const items = useSelector(select.header.getUserMenuItems);
    const selectedItemIndex = (items.length > 0 && currentRoute.length > 5)
        ? items.findIndex((item) => item.href?.startsWith(currentRoute)) ?? false
        : -1;

    const {
        email,
        userRole,
        firstName,
        lastName,
        initials,
        companyName,
        communeName,
    } = useSelector((state) => select.header.getUserMenu(state));
    const userName = useSelector(select.header.getUserName);
    const isCompany = userRole === userRoles.COMPANY_OPERATOR;
    const isCommune = userRole === userRoles.COMMUNE_OPERATOR;

    const subViewIndex = useSelector(select.header.getSubViewIndex);
    const isSubViewVisible = subViewIndex > -1;
    const [subViewStyle, setSubViewStyle] = useSpring(() => (isSubViewVisible ? visibleStyles : hiddenStyles));
    useEffect(() => {
        setSubViewStyle(isSubViewVisible ? visibleStyles : hiddenStyles);
    }, [setSubViewStyle, isSubViewVisible]);

    const notifications = useSelector(select.notificationHub.getAll);
    const notificationCount = notifications.length;
    // Näytetäänkö roolinvaihtodialogi
    const isRoleSelectorVisible = useSelector(select.header.isRoleSelectorVisible);

    // Edustetaanko jotain isompaa kokonaisuutta, yritys tai kunta...
    const entityNames = [
        companyName,
        communeName,
    ].filter(Boolean);
    // Otetaan se mikä jää jäljelle
    const entityName = entityNames.pop() ?? '';
    const isEntity = (isCommune || isCompany) && entityName.length > 0;

    // Yritetään kasata nimi
    const namePieces = [firstName, lastName].filter(Boolean);
    const entityNamePieces = isEntity ? entityName.split(' ') : '';
    const hasName = namePieces.length > 0;
    const fullName = hasName ? namePieces.join(' ') : false;
    const userInitials = isEntity
        ? `${entityNamePieces[0][0] ?? ''}${entityNamePieces.length > 1 ? entityNamePieces[1][0] : ''}`
        : initials;
    // Mobiilissa koko nimi, muuten vain etunimi
    const personalName = isBigMenu ? fullName : firstName;

    const visibleName = isEntity
        ? entityName
        : personalName;

    const iconRoles = [
        userRoles.EMPLOYER,
        userRoles.EMPLOYEE,
        userRoles.FAMILY_CARER,
        userRoles.COMPANY_OPERATOR,
        userRoles.COMPANY_APPROVER,
        userRoles.COMMUNE_OPERATOR
    ];
    const userAvatar = iconRoles.includes(userRole)
        ? (
            <UserRoleIcon
                userRole={userRole}
                width={36}
                height={36}
            />
        )
        : (
            <Avatar
                fullName={isEntity ? entityName : userName}
                initials={userInitials}
                size="medium"
                isFixedSize
                // isCompany={userRole === userRoles.COMPANY_OPERATOR}
            />
        );

    if (isBigMenu) {
        return (
            <Fragment>
                <MenuBarItem onClick={() => dispatch.header.toggleUserMenu()} id="userMenu" hasPadding={false} title={_trans('user_menu.label')}>
                    {userAvatar}
                    {notificationCount > 0 && <Badge overlap value={notificationCount} animate /> }
                </MenuBarItem>
                <BigMenu isOpen={isMenuOpen} ariaLabelledBy="userMenu" animation="fromRight">
                    <animated.div
                        style={subViewStyle}
                        className="o-pin o-pin--corners"
                    >
                        <div
                            role="none"
                            className="c-hamburger-bar is-open u-1/1 o-stack o-stack--start o-stack--justify"
                        >
                            <span className="u-padding-left-small u-padding-vertical-small o-stack o-stack--inline">
                                {userAvatar}
                                <span className="c-user u-margin-left-tiny u-align-middle">
                                    {visibleName && (
                                        <Fragment>
                                            <div className="c-user__name u-font-size-16 u-font-weight-bold">
                                                {visibleName}
                                            </div>
                                            {isCommune && (
                                                <div className="u-font-size-14">
                                                    {_trans('Henkilökohtainen apu', {}, 'common')}
                                                </div>
                                            )}
                                        </Fragment>
                                    )}
                                    <span className="c-user__name">
                                        {email}
                                    </span>
                                </span>
                            </span>
                        </div>
                        <BigList
                            id="bigUserMenu"
                            currentRoute={currentRoute}
                            items={[
                                'separator',
                                isNotificationVisible && {
                                    icon: 'notifications',
                                    label: 'Ilmoitukset',
                                    badge: notificationCount,
                                    subView: <NotificationHub />,
                                },
                                canSwitchRole && {
                                    icon: 'swap_horiz',
                                    label: _trans('user_menu.change_role'),
                                    onClick: () => {
                                        dispatch.header.closeAllMenus();
                                        dispatch.header.setIsRoleSelectorVisible(! isRoleSelectorVisible);
                                    },
                                },
                                ...items
                            ].filter(Boolean)}
                            subViewIndex={subViewIndex}
                            setSubViewIndex={(subViewIndex) => {
                                document.activeElement.blur();
                                dispatch.header.setSubViewIndex(subViewIndex);
                            }}
                        />
                    </animated.div>
                </BigMenu>
            </Fragment>
        );
    }

    return (
        <MenuBarButton
            id="userMenu"
            items={items}
            aria-label={_trans('user_menu.label')}
            title={_trans('user_menu.label')}
            selectedItemIndex={selectedItemIndex}
        >
            {userAvatar}
            { !isNameHidden && (
                <span className="c-user u-margin-horizontal-tiny u-text-left">
                    <b className="c-user__name u-align-middle" style={{ maxWidth: '13em' }}>
                        {visibleName}
                    </b>
                    {isCommune && (
                        <span className="u-font-size-12">
                            {_trans('Henkilökohtainen apu', {}, 'common')}
                        </span>
                    )}
                </span>
            )}
            { (isBigMenu && notificationCount > 0) && (<Badge overlap value={notificationCount} />)}
        </MenuBarButton>
    );
};

UserMenu.propTypes = {
    isNameHidden: PropTypes.bool,
    isBigMenu: PropTypes.bool,
    canSwitchRole: PropTypes.bool.isRequired,
};

UserMenu.defaultProps = {
    isNameHidden: false,
    isBigMenu: false,
};

export default UserMenu;
