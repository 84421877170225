import React from 'react';
import PropTypes from 'prop-types';

/**
 * Small help text.
 * @param text
 * @param id
 * @constructor
 */
export const HelpText = ({ text, id }) => (
    <span className="o-help u-margin-top-tiny" id={id}>
        {text}
    </span>
);

HelpText.propTypes = {
    id: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

HelpText.defaultProps = {
    id: null,
};

