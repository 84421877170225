import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelect } from 'downshift';
import { usePopper } from 'react-popper/lib/cjs/index';
import { animated, useSpring } from 'react-spring/web.cjs';
import classNames from 'classnames';
import _ from 'lodash';
import { defaultAnimationConfig } from 'shared/constants/animations';

const hiddenStyles = {
    opacity: 0,
    transform: 'translate3d(0, -12px, 0)',
    config: defaultAnimationConfig,
};

const visibleStyles = {
    opacity: 1,
    transform: 'translate3d(0, 0px, 0)',
    config: defaultAnimationConfig,
};

/**
 * Kustomoitava alasvetovalikko Aria-tuella.
 */
const CustomDropdown = (props) => {
    /**
     * Alasvetovalikon teksti: joko oletus "Valitse", käyttäjän määrittelemä tai valittu arvo.
     * @returns {Dropdown.props.placeholder}
     */
    const {
        name,
        value,
        isLoading,
        isDisabled,
        isFullWidth,
        options,
        toggleButtonContent,
        optionComponent,
        labelKey,
        valueKey,
        onChange,
        ariaLabelledBy,
    } = props;

    const selectedItemIndex = options.findIndex((option) => _.get(option, valueKey) === _.get(value, valueKey));

    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getItemProps
    } = useSelect({
        items: options,
        initialHighlightedIndex: selectedItemIndex,
        defaultHighlightedIndex: selectedItemIndex,
        selectedItem: value,
        // Ohjeteksti otettu toistaiseksi pois.
        getA11yStatusMessage: (/*{ isOpen, resultCount }*/) => ''/*{
            if (! isOpen) return '';
            return resultCount > 0
                ? _trans('text.results_available', { resultCount })
                : _trans('text.no_results_available');
        }*/,
        getA11ySelectionMessage: () => _trans('text.selected'),
        onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem)
    });

    const [style, set] = useSpring(() => (isOpen ? visibleStyles : hiddenStyles));
    useEffect(() => {
        set(isOpen ? visibleStyles : hiddenStyles);
    }, [set, isOpen]);

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles } = usePopper(referenceElement, popperElement, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 6],
                }
            },
        ]
    });

    const dropDownValue = isLoading
        // Lataus käynnissä
        ? _trans('spinner.loading')
        // Muutoin yritetään etsiä valinnoista
        : selectedItemIndex > -1
            ? _.get(options[selectedItemIndex], labelKey)
            : _trans('dropdown.choose');

    return (
        <div
            className={classNames('u-position-relative u-display-inline-block', {
                'u-1/1': isFullWidth,
            })}
        >
            <button
                type="button"
                name={name}
                disabled={isDisabled}
                className={classNames('c-dropdown u-text-left', {
                    'u-1/1': isFullWidth,
                })}
                // Tällä saadaan ruudunlukijoilla luettua sekä tähän liitetty label että valittu arvo
                aria-labelledby={`${ariaLabelledBy} ${_.get(getToggleButtonProps(), 'id', '')}`}
                {..._.omit(getToggleButtonProps({
                    ref: (ref) => {
                        if (ref !== null) {
                            setReferenceElement(ref);
                        }
                    }
                }), ['aria-labelledby'])}
            >
                { toggleButtonContent
                    ? toggleButtonContent(dropDownValue, value)
                    : dropDownValue}
            </button>
            <div
                aria-hidden={!isOpen}
                ref={setPopperElement}
                style={{
                    ...styles.popper,
                    width: 'inherit',
                    zIndex: isOpen ? 2 : -1,
                }}
                className={isOpen ? '' : 'u-pointer-events-none'}
            >
                <animated.ul
                    className={classNames('c-menu o-list-bare', {
                        'u-1/1': isFullWidth,
                    })}
                    style={{
                        ...style,
                        visibility: isOpen ? 'visible' : 'hidden',
                    }}
                    // Tarpeelliset aria-roolit
                    {...getMenuProps()}
                >
                    {isOpen && options.map((option, index) => (
                        <li
                            key={`${option[valueKey]}${index}`}
                            {...getItemProps({
                                item: option,
                                index,
                                /*isActive: highlightedIndex === index,
                                isSelected: selectedItem === option,*/
                            })}
                            className={classNames('c-menu__item u-1/1', {
                                'is-focused': highlightedIndex === index,
                                'is-active': selectedItem === option[valueKey],
                            })}
                        >
                            {/*Renderöi joko arvon tai kustomoidun vaihtoehdo*/}
                            {optionComponent
                                ? optionComponent({
                                    value: option[valueKey],
                                    option,
                                    isHighlighted: highlightedIndex === index,
                                    isSelected: selectedItem === option,
                                    valueKey,
                                    labelKey,
                                })
                                : option[labelKey]
                            }
                        </li>
                    ))}
                </animated.ul>
            </div>
        </div>
    );
};

CustomDropdown.defaultProps = {
    value: null,
    valueKey: 'id',
    labelKey: 'name',
    onChange() {},
    placeholder: '',
    isLoading: false,
    isDisabled: false,
    isFullWidth: false,
    toggleButtonContent: null,
    optionComponent: null,
    modifierClass: '',
    name: '',
    onMenuToggle() {},
    ariaLabelledBy: undefined,
};

CustomDropdown.propTypes = {
    /**
     * Valittu arvo.
     */
    value: PropTypes.oneOfType(
        [PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.number]),
    /**
     * Valinnat.
     */
    options: PropTypes.array.isRequired,
    /**
     * Valinnan arvon avain.
     */
    valueKey: PropTypes.string,
    /**
     * Valinnan tekstin avain.
     */
    labelKey: PropTypes.string,
    /**
     * Alasvetovalikon placeholder.
     */
    placeholder: PropTypes.string,
    /**
     * Mitä tapahtuu kun tehdään valinta.
     */
    onChange: PropTypes.func,
    /**
     * Onko valintojen lataus käynnissä.
     */
    isLoading: PropTypes.bool,
    /**
     * Onko alasvetovalikko disabloitu.
     */
    isDisabled: PropTypes.bool,
    /**
     * Venytetäänkö alasvetovalikko täysilevyiseksi.
     */
    isFullWidth: PropTypes.bool,
    /**
     * Mahdollinen CSS-muokkausluokka.
     */
    modifierClass: PropTypes.string,

    /**
     * Valintanapin kustomoitu sisältö.
     */
    toggleButtonContent: PropTypes.func,

    /**
     * Valinnan renderöivä komponentti.
     */
    optionComponent: PropTypes.func,
    /**
     * Selectille asetettava name. Esim. ilman tätä ei voida scrollata virheeseen frontilla.
     */
    name: PropTypes.string,
    /**
     * Sen elementin id joka kertoo alasvetovalikon labelin (esim. "Palkkajaksot").
     */
    ariaLabelledBy: PropTypes.string,
};

export default CustomDropdown;
