import React, { useState, useEffect } from 'react';
import { select } from '@rematch/select';
import { useSelector, useDispatch } from 'react-redux';
import LeavePeriodList from './LeavePeriodList';
import { LeavePeriodForm } from './LeavePeriodForm';
import { userSelectors } from 'shared/UserDetails/stores/user';
import { Button, Page, Feedback } from 'shared/components';

export const LeavePeriodContainer = () => {
    const isCompany = useSelector(select.userMetadata.isCompany);
    const companyShadowUserId = useSelector(select.userMetadata.getCompanyShadowUserId);
    const showPartialDimensionAccessWarning = useSelector(select.leavePeriods.getShowPartialDimensionAccessWarning);
    const [isNewPeriod, setIsNewPeriod] = useState(false);
    const [editingPeriod, setEditingPeriod] = useState(null);

    const dispatch = useDispatch();
    const isEmployee = useSelector(userSelectors.isEmployee);
    const userId = useSelector(userSelectors.getUserId);

    /**
     * Joko POST tai PUT
     * @param model
     */
    const onSubmit = (model) => {
        const { type, dateRange, description, jobContract } = model;
        const values = {
            startDate: dateRange.start,
            endDate: dateRange.end,
            description,
            jobContract,
        };

        if (editingPeriod && editingPeriod.id) {
            dispatch.leavePeriods.putLeavePeriod({
                userId,
                type,
                leavePeriodId: editingPeriod.id,
                values: {
                    ...values,
                    period: editingPeriod.id,
                },
            }).then(() => setEditingPeriod(null));
        } else {
            dispatch.leavePeriods.postLeavePeriod({ userId, type, values })
                .then(() => {
                    setIsNewPeriod(false);
                    setEditingPeriod(null);
                });
        }
    };

    useEffect(() => {
        dispatch.leavePeriods.fetchLeavePeriods({ userId });
        dispatch.leavePeriods.fetchEmployeeContracts({
            employee: userId,
            employer: isCompany ? companyShadowUserId : null,
        });
    }, [dispatch, userId, isCompany, companyShadowUserId]);

    if (! isEmployee) {
        return null;
    }

    const initialState = editingPeriod
        ? {
            ...editingPeriod,
            jobContract: editingPeriod.jobContract?.jobContractId ?? null
        }
        : {};

    // Ollaanko lisäys / muokkaustilassa
    const showForm = editingPeriod || isNewPeriod;

    return (
        <Page
            heading={showForm
                ? _trans('leave_period.form.add_new')
                : _trans('leave_period.heading')
            }
            actionItems={! showForm && (
                <Button
                    mdIcon="add"
                    onClick={() => setIsNewPeriod(true)}
                    ariaLabel={_trans('leave_period.new_leave')}
                    title={_trans('leave_period.new_leave')}
                    primary
                >
                    {_trans('button.add_new')}
                </Button>
            )}
            maxWidth="medium-2"
        >
            {showPartialDimensionAccessWarning && <strong>
                {_trans('Valtuushallinta-asetusten takia poissaolojaksojen lisäys tai muokkaus kohdistuu vain niiden sopimusten palkkajaksoihin, joihin sinulla on valtuudet.', {}, 'extract')}
            </strong>}
            {showForm
                ? (
                    <>
                        <Feedback modifierClass="u-margin-bottom" message={_trans('Poissaolojaksojen lisäys/muokkaus onnistuu vain työntekijän avoimille palkkajaksoille ja vain niille päiville, joissa ei ole tuntiseurannassa merkintöjä.', [], 'messages')} type="info" />
                        <LeavePeriodForm
                            onCancel={() => {
                                setIsNewPeriod(false);
                                setEditingPeriod(null);
                            }}
                            initialState={initialState}
                            onSubmit={(model) => onSubmit(model)}
                        />
                    </>
                ) : (
                    <LeavePeriodList
                        onAddPeriod={(periodType) => setEditingPeriod({ type: periodType })}
                        onEditPeriod={(period) => setEditingPeriod(period)}
                    />
                )
            }
        </Page>
    );
};

