/**
 * Immutable setIn.
 * https://gist.github.com/xialvjun/6c23f0cd278e1ab81226b1b32a1af1bf
 */

// https://github.com/lodash/lodash/issues/1696
import { clone, setWith, curry } from 'lodash/fp';

// export const setIn = curry((path, value, obj) =>
//   setWith(clone, path, value, clone(obj)),
// );
export const setIn = curry((obj, path, value) =>
    setWith(clone, path, value, clone(obj)),
);
