import React from 'react';
import PropTypes from 'prop-types';
import { Value } from 'shared/components/Value';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import {
    MAX_HOURS_PER_WEEK,
    REGULAR_HOURS_PER_MONTH,
    REGULAR_HOURS_PER_WEEK, resolveWorkHoursUnitLabel
} from 'shared/constants/workTimeEstimationTypes';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';

/**
 * Sopimuksen mukaiset työtunnit.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const WeeklyWorkHours = ({ label, value, values }) => {
    const maxWeeklyWorkHours = values[fieldNames.MAX_WEEKLY_WORK_HOURS];
    const workTypeEstimationType = values[fieldNames.WORK_TIME_ESTIMATION_TYPE];

    //säännöllinen työaika tai max työaika
    const regularOrMaxWorkTimeType = [REGULAR_HOURS_PER_WEEK, MAX_HOURS_PER_WEEK, REGULAR_HOURS_PER_MONTH ];

    const isRegularOrMaxHoursType = regularOrMaxWorkTimeType.some((reg) => String(reg) === String(workTypeEstimationType));

    return (
        <SummaryListItem term={label}>
            {! isRegularOrMaxHoursType
                ? (
                    <Value>
                        {`${value} — ${maxWeeklyWorkHours ?? ''} ${resolveWorkHoursUnitLabel(workTypeEstimationType)}`}
                    </Value>
                )
                : (
                    <Value>
                        {`${value} ${resolveWorkHoursUnitLabel(workTypeEstimationType)}`}
                    </Value>
                )
            }
        </SummaryListItem>
    );
};
WeeklyWorkHours.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    values: PropTypes.object.isRequired,
};
export default WeeklyWorkHours;
