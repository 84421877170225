import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import menuBarItem from 'shared/shapes/menuBarItem';
import Badge from 'shared/components/Badge';
import Button from 'shared/components/Button';
import MDIcon from 'shared/components/MDIcon';

/**
 * Iso valikkolistaitemi. Voi olla joko linkki tai "nappi".
 * Jos nappi, oletetaan että tällä itemillä on alinäkymä kuten notifikaatiot.
 * Tarkoitettu yksistään mobiilikokoihin BigList-komponentin kanssa.
 * @param item
 * @param isSelected
 * @param index
 * @param subViewIndex
 * @param onSetSubViewIndex
 * @param hasSvgIcon
 * @returns {*}
 * @constructor
 */
const BigListItem = ({ item, isSelected, index, subViewIndex, onSetSubViewIndex, hasSvgIcon }) => {
    const href = item.href;

    if (item === 'separator') {
        return <li role="separator"/>;
    }

    const onClick = item.onClick ?? function () {};
    const badge = item.badge;

    // Onko alinäkymää?
    const hasSubView = item?.subView ?? false;
    const events = hasSubView
        ? { onClick: () => onSetSubViewIndex(index) }
        : { onClick };

    return (
        <li role="none">
            {React.createElement(href ? 'a' : 'button', {
                href,
                className: classNames('c-big-menu__item o-stack o-stack--justify u-1/1', {
                    'c-big-menu__item--admin': item.isAdmin,
                    'c-big-menu__item--selected': isSelected,
                }),
                role: 'menuitem',
                ...events,
            }, (
                <Fragment>
                    <span className="o-stack o-stack--inline">
                        {item.icon && (
                            hasSvgIcon ? <item.icon/> : <MDIcon icon={item.icon}/>
                        )}
                        <span className="u-margin-left-small">
                            {item.label}
                        </span>
                        { (badge > 0) && <Badge value={badge} modifierClass="u-margin-left-tiny" /> }
                    </span>
                    {hasSubView && (
                        <MDIcon icon="navigate_next" />
                    )}
                </Fragment>
            ))}
            {index === subViewIndex && (
                <ul className="o-list-bare u-margin-none o-pin o-pin--top u-1/1" style={{ left: '100%' }}>
                    <li role="none">
                        <Button
                            mdIcon="navigate_before"
                            flat
                            modifierClass="u-1/1 u-text-left u-padding-horizontal-small u-padding-vertical-small"
                            onClick={() => onSetSubViewIndex(-1)}
                        >
                            {_trans('button.back')}
                        </Button>
                        {item.subView}
                    </li>
                </ul>
            )}
        </li>
    );
};

BigListItem.propTypes = {
    item: PropTypes.oneOfType([
        PropTypes.string,
        menuBarItem,
    ]).isRequired,
    isSelected: PropTypes.bool,
    index: PropTypes.number.isRequired,

    /**
     * Näytetäänkö SVG- vai Material Designin -ikoni.
     */
    hasSvgIcon: PropTypes.bool,

    onSetSubViewIndex: PropTypes.func.isRequired,
    subViewIndex: PropTypes.number.isRequired,
};

BigListItem.defaultProps = {
    isSelected: false,
    hasSvgIcon: false,
};

export default BigListItem;
