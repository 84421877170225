import React from 'react';
import PropTypes from 'prop-types';
import { Attachment } from 'shared/containers/Attachment';

export const SelectedAttachments = ({ attachments, onDelete }) => {
    if (attachments.length === 0) {
        return (
            <div className="u-margin-bottom-small">
                {_trans('Yhtään liitettä ei löytynyt.', {}, 'common')}
            </div>
        );
    }

    return (
        <ul className="o-list-bare u-margin-bottom-none" aria-label={_trans('Liitelista', {}, 'jobContract')}>
            {attachments
                .map((attachment, key) => (
                    <li
                        key={key}
                        className="o-list-bare__item u-display-inline-block u-margin-right-small u-margin-bottom-small"
                        style={{ maxWidth: 320 }}
                    >
                        <Attachment
                            attachment={attachment}
                            isRemovable={! attachment.isPublic ?? false}
                            onDelete={(response) => {
                                const id = response.data?.id;
                                if (response.status === 'ok' && id) {
                                    onDelete(id);
                                }
                            }}
                        />
                    </li>
                ))}
        </ul>
    );
};

SelectedAttachments.propTypes = {
    attachments: PropTypes.array,
    onDelete: PropTypes.func,
};

SelectedAttachments.defaultProps = {
    attachments: [],
    onDelete() {},
};
