import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useId } from '@mantine/hooks';
import { usePopperTooltip } from 'react-popper-tooltip';
import classNames from 'classnames';
import MDIcon from 'shared/components/MDIcon';

/**
 * Yksittäinen yhteenvetonäkymän rivi.
 * @param term
 * @param tooltip
 * @param termWidth
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const SummaryListItem = ({ term, tooltip, termWidth, children }) => {
    const uuid = useId(term);
    const width = isNaN(termWidth) ? termWidth : `${termWidth}px`;
    const style = termWidth ? { width } : {};
    const {
        // getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
        state
    } = usePopperTooltip({
        placement: 'top',
        trigger: ['hover', 'focus']
    });

    const tooltipId = `${uuid}Tooltip`;
    const placement = state?.placement ?? '';

    return (
        <li className="o-summary-list__item">
            <span className="o-summary-list__term" style={style} role="term">
                <span className="u-align-bottom">{term}</span>
                {tooltip && (
                    <Fragment>
                        <button
                            ref={setTriggerRef}
                            aria-describedby={tooltipId}
                            className="u-margin-left-tiny"
                        >
                            <MDIcon icon="help_outline" />
                        </button>
                        <div
                            ref={setTooltipRef}
                            id={tooltipId}
                            aria-hidden={! visible}
                            {...getTooltipProps({
                                className: classNames(`c-tooltip c-tooltip-placement-${placement}`, {
                                    'u-display-none': ! visible,
                                })
                            })}
                        >
                            {/*<span {...getArrowProps({ className: 'c-tooltip-arrow' })} />*/}
                            <span className="c-tooltip-inner">
                                {tooltip}
                            </span>
                        </div>
                    </Fragment>
                )}
            </span>
            <span className="o-summary-list__description" role="definition">
                {children}
            </span>
        </li>
    );
};

SummaryListItem.propTypes = {
    term: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    // Vähän kyseenalainen tarviiko..
    termWidth: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    children: PropTypes.node.isRequired,
};

SummaryListItem.defaultProps = {
    tooltip: undefined,
    termWidth: null,
};

