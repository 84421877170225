export const contractStateRoutes = {
    CANCEL_DRAFT: 'contracts_api_1_post_contract_data_cancel_draft',
    CANCEL_VERSION_DRAFT: 'contracts_api_1_post_contract_data_cancel_version_draft',
    CANCEL_PROPOSAL: 'contracts_api_1_post_contract_data_cancel_proposal',
    CANCEL_VERSION_PROPOSAL: 'contracts_api_1_post_contract_data_cancel_version_proposal',
    CANCEL_REJECTION: 'contracts_api_1_post_contract_data_cancel_rejection',

    CONFIRM_DRAFT: 'contracts_api_1_post_contract_data_confirm_draft',
    CONFIRM_VERSION_DRAFT: 'contracts_api_1_post_contract_data_confirm_version_draft',

    ACCEPT_PROPOSAL: 'contracts_api_1_post_contract_data_accept',
    ACCEPT_VERSION_PROPOSAL: 'contracts_api_1_post_contract_data_accept_version',

    APPROVE_ACCEPTED_PROPOSAL: 'contracts_api_1_post_contract_data_approve',
    APPROVE_ACCEPTED_VERSION_PROPOSAL: 'contracts_api_1_post_contract_data_approve_version',

    REJECT_PROPOSAL: 'contracts_api_1_post_contract_data_reject',
    REJECT_VERSION_PROPOSAL: 'contracts_api_1_post_contract_data_reject_version',

    ACCEPT_AND_APPROVE_DRAFT: 'contracts_api_1_post_contract_data_accept_and_approve',
    ACCEPT_AND_APPROVE_PROPOSAL: 'contracts_api_1_post_contract_data_accept_and_approve',

    RE_POST_REJECTED_PROPOSAL: 'contracts_api_1_post_contract_data_repost_proposal',

    CHANGE_DATA_START_DATE: 'contracts_api_1_post_contract_data_change_version_start_date',
};
