import React from 'react';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import Badge from 'shared/components/Badge';
import { List, ListItem } from 'shared/components/List';
import MDSpinner from 'shared/components/MDSpinner';
import MDIcon from 'shared/components/MDIcon';
import { todoTypes } from 'shared/constants/todoTypes';

/**
 * Yksinkertainen notifikaatiolistaus.
 * @returns {*}
 * @constructor
 */
// eslint-disable-next-line react/display-name
export const NotificationHub = React.memo(() => {
    const isLoading = useSelector((state) => state.loading?.effects.notificationHub.fetchNotificationMetadata);
    const notifications = useSelector(select.notificationHub.getAll);

    if (isLoading) {
        return (
            <MDSpinner wrapped />
        );
    }

    // Jos ei yhtään ilmoitusta, kerrotaan siitä nyt sitten rautalangasta vääntämällä.
    if (notifications.length === 0) {
        return (
            <div className="c-popover--notifications__empty-state u-muted u-text-center u-text-no-wrap o-stack o-stack--vertical o-stack--center">
                <div>
                    <div className="u-muted">
                        <MDIcon icon="notifications_none" size="huge" />
                    </div>
                    {_trans('notification_hub.empty_message')}
                </div>
            </div>
        );
    }

    return (
        <div style={{ maxHeight: '560px' }} className="u-overflow-vertical">
            <List>
                {notifications.map((notification, key) => (
                    <ListItem.Link key={key} url={notification.url}>
                        <ListItem.PrimaryContent>
                            <span className="u-text-wrap u-padding-right-small">
                                {notification.message}
                            </span>
                        </ListItem.PrimaryContent>
                        <ListItem.SecondaryContent>
                            <Badge
                                type={[todoTypes.TYPE_OWN_INSTANT_MESSAGES, todoTypes.TYPE_WELLBEING_AREA_INSTANT_MESSAGES].includes(notification.type)
                                    ? 'primary'
                                    : 'neutral'}
                                value={notification.count}
                                isBall={false}
                            />
                            <MDIcon icon="navigate_next" />
                        </ListItem.SecondaryContent>
                    </ListItem.Link>
                ))}
            </List>
        </div>
    );
});
