import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popover from 'shared/components/Popover';
import utils from 'shared/utils/commonUtils';

/**
 * @deprecated Käytä Formik:ia ja meidän sharedista Form ja FormFieldiä.
 */
export default class StLabel extends PureComponent {
    render() {
        const {
            id,
            htmlFor,
            tooltip,
            modifierClass,
            children,
            isRequired,
        } = this.props;

        let labelId = id;
        let content = <span>{children}</span>;

        // Jos labelille on annettu tooltip on aria-describedby -attribuutti oltava labelin tekstisisällössä.
        if (tooltip) {
            labelId = utils.generateUuid();

            content = (
                <span className="o-stack--inline">
                    <span aria-describedby={`${labelId}-tooltip`}>
                        {children}
                    </span>
                    <span className="u-line-height-flat">
                        <Popover position="top" describedById={`${labelId}-tooltip`}>
                            {tooltip}
                        </Popover>
                    </span>
                </span>
            );
        }

        const labelClass = classNames({
            'is-required': isRequired,
            [modifierClass]: !!modifierClass,
        });
        return (
            <span className="o-form__label-container">
                <label
                    aria-required={isRequired}
                    {...{
                        htmlFor,
                        id: labelId,
                    }}
                    className={labelClass}
                >
                    {content}
                </label>
            </span>
        );
    }
}

StLabel.defaultProps = {
    id: null,
    tooltip: '',
    modifierClass: '',
    isRequired: false,
};

StLabel.propTypes = {
    /**
     * Yksilöivä tunnus.
     */
    id: PropTypes.string,

    /**
     * Mihin kenttään label kohdistuu.
     * Tärkeä erityisesti lukulaitteille ja käytettävyyden kannalta.
     */
    htmlFor: PropTypes.string.isRequired,

    /**
     * Mahdollinen ohje.
     * Jos ohje on tärkeä on se parempi tulostaa StHelp-komponentilla heti näkyviin kentän alle.
     */
    tooltip: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),

    /**
     * Onko kenttä pakollinen.
     * Jos on, näyttää tähden labelin vieressä.
     */
    isRequired: PropTypes.bool,

    /**
     * Luokitus
     */
    modifierClass: PropTypes.string,

    /**
     * Sisältö.
     */
    children: PropTypes.node.isRequired
};
