import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { OpenOfflinePaymentFeedback } from './OpenOfflinePaymentFeedback';
import Dialog from 'shared/components/Dialog';
import ActionBar from 'shared/components/ActionBar';
import Button from 'shared/components/Button';
import MDSpinner from 'shared/components/MDSpinner';

/**
 * Lisämaksujen vahvistusdialogi.
 * Vahvistuksen jälkeen lisämaksut kiinnitetään avoimelle maksuerälle, johon tarjotaan suoran linkki.
 * @returns {JSX.Element}
 * @constructor
 */
export const SurchargeConfirmDialog = () => {
    const dispatch = useDispatch();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const isConfirming = useSelector((state) => state.loading.effects.handleSurcharges);
    const offlinePaymentId = useSelector(select.surcharges.getOfflinePaymentId);
    const surcharges = useSelector(select.surcharges.getSurcharges);
    const total = useSelector(select.surcharges.getTotal);
    const hasNonHandledInternalSurcharges = useSelector(select.surcharges.getNonHandledInternalSurcharges).length > 0;

    return (
        <Fragment>
            <ActionBar alignItems="right">
                <Button
                    positive
                    mdIcon="arrow_forward"
                    iconAfterText
                    onClick={() => setIsDialogOpen(true)}
                    disabled={! offlinePaymentId && hasNonHandledInternalSurcharges}
                >
                    Vahvista lisämaksut
                </Button>
            </ActionBar>
            <Dialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} title="Vahvista lisämaksut" maxWidth={600}>
                <div className="u-margin-bottom">
                    {isConfirming
                        ? <MDSpinner wrapped />
                        : _transMd('surcharges.confirm_dialog.are_you_sure', {
                            count: surcharges.length,
                            total: _currency(total),
                        }, { useParagraphs: true })
                    }
                </div>
                <div className="u-margin-bottom">
                    <OpenOfflinePaymentFeedback />
                </div>
                <ActionBar>
                    <Button onClick={() => setIsDialogOpen(false)} disabled={isConfirming}>
                        {_trans('button.cancel')}
                    </Button>
                    <Button
                        onClick={(event) => {
                            event.preventDefault();
                            dispatch.surcharges.handleSurcharges().then(() => setIsDialogOpen(false));
                        }}
                        primary
                        inProgress={isConfirming}
                    >
                        Vahvista lisämaksut
                    </Button>
                </ActionBar>
            </Dialog>
        </Fragment>
    );
};
