import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import PropTypes from 'prop-types';
import { ConfirmButton, LinkIcon, MDSpinner, Page } from 'shared/components';
import { DataTable } from 'shared/containers/DataTable7';
import companyRoutes from 'Company/Settings/routes';
import { routes as communeRoutes } from 'Commune/Settings/routes';
import { resolveUrl } from 'shared/utils';
import { isCommune } from 'shared/utils/commonUtils';

const routes = isCommune() ? communeRoutes : companyRoutes;

const Actions = ({ original }) => {
    const dispatch = useDispatch();
    if (Object.values(original.contact).length > 0) {
        return (
            <div>
                <LinkIcon
                    mdIcon="edit"
                    to={resolveUrl(routes.CONTRACT_DIMENSION_CONTACTS_FORM, { dimensionId: original.id, contactId: original.contact.id })}
                >
                    {_trans('button.edit')}
                </LinkIcon>
                <ConfirmButton
                    modifierClass="u-margin-left-small"
                    onClick={async () => {
                        await dispatch.contractContactDimensions.deleteContact(original.contact.id);
                        await dispatch.contractContactDimensions.fetchContacts();
                    }}
                    confirmText={_trans('button.are_you_sure')}
                    text={_trans('button.remove')}
                />
            </div>
        );
    }

    return (
        <div>
            <LinkIcon mdIcon="add" to={resolveUrl(routes.CONTRACT_DIMENSION_CONTACTS_FORM, { dimensionId: original.id, contactId: 'new' })}>
                {_trans('button.add')}
            </LinkIcon>
        </div>
    );
};

Actions.propTypes = {
    original: PropTypes.shape({
        contact: PropTypes.shape({
            id: PropTypes.number,
        }),
        id: PropTypes.number.isRequired,
    }).isRequired,
};
export const ContactList = () => {
    const dispatch = useDispatch();
    const dimensions = useSelector(select.contactDimensions.getContractContactDimensions);
    const typeName = useSelector(select.contactDimensions.getContractContactDimensionTypeName);
    const contacts = useSelector(select.contractContactDimensions.getContacts);
    const isLoadingContacts = useSelector((state) => state.loading.effects.contractContactDimensions.fetchContacts);

    useEffect(() => {
        if (contacts.length === 0) {
            dispatch.contractContactDimensions.fetchContacts();
        }
    }, [contacts.length, dispatch.contractContactDimensions]);


    const dimensionsWithContacts = useMemo(() => dimensions.map((d) => ({
        ...d,
        contact: contacts.find((c) => c.dimension.id === d.id) ?? {},
    })), [dimensions, contacts]);

    if (isLoadingContacts && contacts.length === 0) {
        return (
            <MDSpinner wrapped />
        );
    }

    return (
        <Page
            heading={_trans('Sopimusten yhteystiedot', {}, 'companySettings')}
            maxWidth="huge"
        >
            <DataTable
                actionsColumn={Actions}
                data={dimensionsWithContacts}
                columns={[
                    {
                        Header: typeName,
                        id: 'typeName',
                        accessor: (r) => `${r.value} - ${r.description ?? ''}`
                    },
                    {
                        Header: _trans('Laskelman lisäteksti', {}, 'common'),
                        accessor: (r) => r.contact?.payslipText?? '-',
                    },
                    {
                        Header: _trans('Sähköposti', {}, 'common'),
                        accessor: (r) => r.contact?.email ?? '-',
                        show: ! isCommune(),
                    },
                    {
                        Header: _trans('Puhelinnumero', {}, 'common'),
                        accessor: (r) => r.contact?.phoneNumber ?? '-',
                        show: ! isCommune(),
                    },
                    {
                        Header: _trans('Osoite', {}, 'common'),
                        accessor: (r) => r.contact?.streetAddress ?? '-',
                        show: ! isCommune(),
                    },
                    {
                        Header: _trans('Postinumero', {}, 'common'),
                        accessor: (r) => r.contact?.postCode ?? '-',
                        show: ! isCommune(),
                    },
                    {
                        Header: _trans('Postitoimipaikka', {}, 'common'),
                        accessor: (r) => r.contact?.town ?? '-',
                        show: ! isCommune(),
                    },
                ].filter((c) => c.show ?? true)}
            />
        </Page>
    );
};
