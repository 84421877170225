import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCombobox } from 'downshift';
import classNames from 'classnames';
import api from 'api';

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoib2ltYSIsImEiOiJja2VhMHp6N2swNjA1MnNuenduOW1tcXViIn0.hB2igE95AiKLJdnAIiyvng';

/**
 * Mapboxin api-endpointia käyttävä osoitteen täydentävä pulikka.
 * @param onSelect
 * @param onChange
 * @param id
 * @param ariaLabelledBy
 * @param hasFullAddressOnSelect
 * @param value
 * @param rest (esim. placeholderit)
 * @returns {*}
 * @constructor
 */
const StreetAddressAutoComplete = ({ onChange, onSelect, id, ariaLabelledBy, hasFullAddressOnSelect, value, ...rest }) => {
    const [query, setQuery] = useState('');
    const [places, setPlaces] = useState([]);
    useEffect(() => {
        setQuery(value);
    }, [value]);

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        openMenu,
        closeMenu,
    } = useCombobox({
        items: places,

        // Kun osoite valitaan kaivetaan myös mahdollinen postinumero ja postitoimipaikka joka passataan metodissa eteenpäin.
        onSelectedItemChange: ({ selectedItem }) => {
            // Vanhassa versiossa bugi jossa escin painaminen johtaa "tyhjän" valitsemiseen
            if (! selectedItem) return;

            const postCode = selectedItem?.context[0]?.text ?? '';
            const city = selectedItem?.context[1]?.text ?? '';
            if (postCode && city) {
                onSelect({ postCode, city, selectedItem });
            }

            const text = selectedItem.text;
            const address = selectedItem.address;
            const streetAddress = `${text}${address ? ` ${address}` : ''}`;
            const result = hasFullAddressOnSelect ? selectedItem?.place_name : streetAddress;

            setQuery(result);
            // Päivitetään myös syöttökenttä valitun kaltaiseksi: aapelinkatu => Aapelinkatu,
            // ellei haluta tyhjentää kenttää valittaessa
            onChange(result);
        }
    });

    const sendQuery = async (query) => {
        try {
            // Otetaan laiskasti localesta 2 ekaa kirjainta (https://docs.mapbox.com/api/search/geocoding/, language-kohta)
            const language = (_locale ?? 'fi').slice(0, 2);
            const route = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?country=fi&language=${language}&access_token=${MAPBOX_ACCESS_TOKEN}`;
            // const json = await api.get(Routing.generate('get_street_addresses', { query }));
            const json = await api.get(route, {
                'credentials': 'omit',
            });
            const features = json?.features ?? [];

            if (features.length > 0) {
                // Siivilöidään vain osoitteet
                const places = features.filter((feature) => (feature?.place_type ?? []).some((type) => type === 'address'));
                setPlaces(places);
            }
        } catch (e) {
            console.log(e);
            // Enpä tiedä tarviiko tässä pamahtaa minkään ollenkaan, osoitteenhan voi silti kirjoittaa.
            // Näkynee New Relicissä kuitenkin.
        }
    };

    useEffect(() => {
        // Avataan valikko automaattisesti jos tuloksia
        if (places.length > 0) {
            openMenu();
        }
    }, [places, openMenu]);

    return (
        <div className="u-position-relative">
            <div {...getComboboxProps()}>
                <input
                    {...getInputProps()}
                    {...rest}
                    id={id}
                    aria-labelledby={ariaLabelledBy}
                    type="text"
                    value={query}
                    onChange={(event) => {
                        const value = event.target.value;

                        onChange(value);
                        setQuery(value);
                        // Aletaan etsiä vasta kun yli 3 merkkiä
                        if (value.length > 3) {
                            // Hidastetaan requestin lähetystä jos kirjoiteltu uutta queryä 250ms sisään
                            return sendQuery(value);
                        } else {
                            setPlaces([]);
                            closeMenu();
                        }
                    }}
                />
            </div>
            <ul
                {...getMenuProps()}
                className="c-menu o-pin o-pin--left u-margin-top-tiny"
                style={{
                    top: '100%',
                    visibility: isOpen ? 'visible' : 'hidden',
                }}
            >
                {isOpen && (
                    places.map((place, index) => (
                        <li
                            key={`${place.id}${index}`}
                            {...getItemProps({ item: place, index })}
                            className={classNames('c-menu__item', {
                                'is-focused': highlightedIndex === index,
                            })}
                        >
                            {place.place_name}
                        </li>
                    ))
                )}
            </ul>
        </div>
    );
};

StreetAddressAutoComplete.propTypes = {
    onChange: PropTypes.func,

    /**
     * Kun valitaan osoite listalta. Palauttaa postinumeron sekä valitun osoitteen (objekti).
     */
    onSelect: PropTypes.func,

    /**
     * Syöttökentän uniikki-id.
     */
    id: PropTypes.string.isRequired,

    /**
     * Labelin id (komponentin ulkona).
     */
    ariaLabelledBy: PropTypes.string.isRequired,

    /**
     * Käytetäänkö täydellistä osoitetta valittaessa.
     */
    hasFullAddressOnSelect: PropTypes.bool,

    value: PropTypes.string,
};

StreetAddressAutoComplete.defaultProps = {
    onChange() {},
    onSelect() {},
    hasFullAddressOnSelect: false,
    value: '',
};

export default StreetAddressAutoComplete;
