import React, { Fragment } from 'react';
import { Field } from 'formik';
import { select } from '@rematch/select';
import { useSelector } from 'react-redux';
import { InputAbbr, InputGroup, StField, StLabel } from 'shared/components';
import { FormikErrors } from 'shared/components/Formik';
import { IncomeField } from 'shared/TaxCard/containers/IncomeField';
import taxCardShape from 'shared/shapes/taxCard';
import { StartDateField } from 'shared/TaxCard/containers/StartDateField';
import _transMd from 'shared/utils/_transMd';
import { veroFetchedTaxDeduction1Validation } from 'shared/TaxCard/components/taxCardValidationSchema';
import { TaxCardOverrideVeroFetchField } from 'shared/TaxCard/containers/TaxCardOverrideVeroFetchField';

export const TaxCardFields = ({ taxCard }) => {
    const showOnlyText = ! useSelector(select.taxCards.isEditMode);
    const selectedTaxCardId = useSelector(select.taxCards.getSelectedTaxCardId);
    const selectedTaxCard = useSelector((state) => select.taxCards.getTaxCardById(state, selectedTaxCardId));
    const isVeroFetched = useSelector((state) => select.taxCards.isVeroFetchedTaxCard(state, selectedTaxCardId));


    return (
        <Fragment>
            <StLabel>
                {_trans('tax_card.form.start_date.label')}
            </StLabel>
            <StField>
                {showOnlyText || isVeroFetched
                    ? <b>{_toLocaleDate(selectedTaxCard?.startDate ?? taxCard.startDate)}</b>
                    : <StartDateField />
                }
            </StField>
            <StLabel>
                {_trans('tax_card.form.tax_deduction.label')}
            </StLabel>
            <StField>
                {showOnlyText
                    ? <b>{_numberFormat(taxCard.taxDeduction1)} {_trans('unit.percent_short')}</b>
                    : (
                        <Fragment>
                            <InputGroup>
                                <Field
                                    size={1}
                                    type="text"
                                    name="taxDeduction1"
                                    validate={(event) => veroFetchedTaxDeduction1Validation(event, selectedTaxCard)}
                                />
                                <InputAbbr abbr="%" definition={_trans('unit.percent')} />
                            </InputGroup>
                            <FormikErrors name="taxDeduction1" />
                        </Fragment>
                    )
                }
            </StField>
            <StLabel>
                {_trans('tax_card.form.tax_deduction_2.label')}
            </StLabel>
            <StField>
                {showOnlyText
                    ? <b>{_numberFormat(taxCard.taxDeduction2)} {_trans('unit.percent_short')}</b>
                    : (
                        <Fragment>
                            <InputGroup>
                                <Field size={1} type="text" name="taxDeduction2" />
                                <InputAbbr abbr="%" definition={_trans('unit.percent')} />
                            </InputGroup>
                            <FormikErrors name="taxDeduction2" />
                        </Fragment>
                    )
                }
            </StField>
            <StLabel>
                {_trans('tax_card.form.income_limit_1.label_b')}
            </StLabel>
            <StField>
                {showOnlyText
                    ? <b>{_numberFormat(taxCard.incomeLimit1)} {_trans('unit.currency')}</b>
                    : (
                        <Fragment>
                            <IncomeField fieldName="incomeLimit1" isReadOnly={isVeroFetched}/>
                            <FormikErrors name="incomeLimit1" />
                        </Fragment>
                    )
                }
            </StField>
            <StLabel tooltip={showOnlyText
                ? _transMd('tax_card.form.previously_paid_salary.help', {
                    previouslyPaid: _numberFormat((taxCard?.previouslyPaid ?? 0) - (taxCard?.previousSalary ?? 0)),
                    previousSalary: _numberFormat(taxCard?.previousSalary ?? 0),
                })
                : _trans('tax_card.form.previous_salary.help')}
            >
                {showOnlyText
                    ? _trans('tax_card.form.previously_paid_salary.label')
                    : _trans('tax_card.form.previous_salary.label')
                }
            </StLabel>
            <StField>
                {showOnlyText
                    ? <b>{_numberFormat(taxCard.previouslyPaid ?? 0) } {_trans('unit.currency')}</b>
                    : (
                        <Fragment>
                            <IncomeField fieldName="previousSalary" isReadOnly={isVeroFetched}/>
                            <FormikErrors name="previousSalary" />
                        </Fragment>
                    )
                }
            </StField>
            <TaxCardOverrideVeroFetchField taxCard={taxCard}/>
        </Fragment>
    );
};

TaxCardFields.propTypes = {
    taxCard: taxCardShape,
};

TaxCardFields.defaultProps = {
    taxCard: {},
};
