import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useDidUpdate } from '@mantine/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { salaryTypes } from 'shared/constants/index';
import { FormField } from 'shared/components/Formik';
import Switch from 'shared/components/Switch';
import Feedback from 'shared/components/Feedback';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { resolveDefaultValue } from 'shared/ReForm/utils/resolveDefaultValue';
import { getCustomComponentByFieldName } from 'ContractV3/utils/getCustomComponentByFieldName';

/**
 * Vain henkkaripuoli.
 * Palkanmuuttamiskytkin.
 * Piilottaa UI:lta taulukkoliksat mikäli ei ole päällä.
 * @returns {JSX.Element}
 * @constructor
 */
const SalaryChangeSwitch = ({ label }) => {
    const dispatch = useDispatch();
    const [salaryTypeField] = useField(fieldNames.SALARY_TYPE);
    const salaryType = parseInt(salaryTypeField.value, 10);
    const isChangingSalary = useSelector(select.contract.isChangingSalary);
    const isAcceptedContract = useSelector(select.contract.isAcceptedContract);

    const firstSalaryIndex = `${fieldNames.SALARIES}.0`;
    const [,,tableSalaryAttributeHelpers] = useField(resolveArrayName(firstSalaryIndex, fieldNames.TABLE_SALARY_ATTRIBUTES));
    const hasMinimumTableSalary = useSelector(select.tableSalary.isMinimumTableSalaries);

    // Onko taulukkoliksat käytössä
    const [isFirstSalaryTableSalaryInUseField,,isFirstSalaryTableSalaryHelpers] = useField(resolveArrayName(firstSalaryIndex, fieldNames.IS_TABLE_SALARY_IN_USE));
    const isFirstSalaryTableSalaryInUse = isFirstSalaryTableSalaryInUseField?.value ?? false;

    useDidUpdate(() => {
        if (! isAcceptedContract) return;
        // Jos palkan muokkaus otetaan pois päältä resetoidaan taulukot
        // Näiden resetointi varmistaa myös sen että muitakaan
        // taulukkoliksa-arvoja ei "generoida" sopimusta tallennettaessa
        if (! isFirstSalaryTableSalaryInUse || ! isChangingSalary) {
            // Vedetään komponenttien defaultValue:t suoraan
            tableSalaryAttributeHelpers.setValue(
                resolveDefaultValue(getCustomComponentByFieldName(fieldNames.TABLE_SALARY_ATTRIBUTES))
            );

            // Tämä alla oleva logiikka myös SalaryType:llä
            if (hasMinimumTableSalary) {
                if (salaryType === salaryTypes.PER_HOUR) {
                    // Miten heetta specialiteetti, ettei kk liksalla näytetä?
                    // Tässä taabelisalaarin päälläolo riippuu siitä muutetaanko palkkaa
                    isFirstSalaryTableSalaryHelpers.setValue(isChangingSalary);
                }

                // Jos kk liksa ja minimitaulukot, niin astetaan taulukot pois (HETA-specificistä)
                if (salaryType === salaryTypes.PER_MONTH) {
                    // Miten heetta specialiteetti, ettei kk liksalla näytetä?
                    isFirstSalaryTableSalaryHelpers.setValue(false);
                }
            }
        }
    }, [isFirstSalaryTableSalaryInUse, isChangingSalary]);

    if (! isAcceptedContract) return null;

    return (
        <Fragment>
            <FormField isContentFormField={false}>
                <Switch
                    id="changeSalary"
                    isOn={isChangingSalary}
                    onChange={(isSwitchOn) => dispatch.contract.setIsChangingSalary(isSwitchOn)}
                >
                    {label}
                </Switch>
            </FormField>
            {isChangingSalary && (
                <div>
                    <Feedback type="tip" modifierClass="u-margin-top-small">
                        {_transMd('contract.form.salary.salary_feedback_tip')}
                        {isFirstSalaryTableSalaryInUse && (
                            _trans('contract.form.salary.salary_table_salary_tip')
                        )}
                    </Feedback>
                    {/*TODO: this.props.hasPendingSalaryUpdate && (
                        <Feedback type="error">
                            {_trans('contract.form.salary.pending_salary_notice')}
                        </Feedback>
                    )*/}
                </div>
            )}
        </Fragment>
    );
};

SalaryChangeSwitch.propTypes = {
    label: PropTypes.string.isRequired,
};

export default SalaryChangeSwitch;
