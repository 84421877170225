import PropTypes from 'prop-types';
import { dimension } from 'shared/shapes/dimensions';

export const workTimePeriod = PropTypes.shape({
    type: PropTypes.number,
    begin: PropTypes.string,
    end: PropTypes.string,
    skipCostReimbursement: PropTypes.bool,
    isCareDayInterruptedByCareTaker: PropTypes.bool,
    isCostReimbursementReduced: PropTypes.bool,
    dimensions: PropTypes.arrayOf(dimension),
});
