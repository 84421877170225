import React from 'react';
import PropTypes from 'prop-types';
import { select } from '@rematch/select';
import { useDispatch, useSelector } from 'react-redux';
import { ContractPopover } from './../containers/ContractPopover';
import { EmptyState, MDSpinner } from 'shared/components';
import DataTable from 'shared/containers/DataTable';
import { translateContractState } from 'shared/constants/contractStates';
import { userSelectors } from 'shared/UserDetails/stores/user';

export const ContractsForm = () => {
    const dispatch = useDispatch();
    const isEmployer = useSelector((state) => userSelectors.isEmployer(state));
    const isLoading = useSelector((state) => state.loading?.effects.weeklyHoursStatement.fetchContracts ?? false);
    const contracts = useSelector(select.weeklyHoursStatement.getContracts);

    if (isLoading) {
        return <MDSpinner wrapped />;
    }

    if (!contracts) {
        return <EmptyState message={_trans('Ei sopimuksia', {}, 'extract')} />;
    }

    const onContractSelect = (values) => {
        dispatch.weeklyHoursStatement.setSelectedContracts(values);
    };

    return (
        <div>
            <h2 className="c-heading-title">
                {_trans('Valitse mukaan otettavat työnantajat', {}, 'extract')}
            </h2>
            <DataTable
                isFilterable={false}
                data={contracts}
                isSelectable={true}
                keyField="jobContractId"
                onSelect={onContractSelect}
                columns={[
                    {
                        Header: isEmployer ? _trans('role.employee') : _trans('role.employer'),
                        Cell: (props) => (
                            <ContractPopover
                                isEmployer={isEmployer}
                                data={props.original}
                            />
                        )
                    },
                    {
                        Header: _trans('Tila', {}, 'extract'),
                        id: 'contract.state',
                        Cell: (props) => translateContractState(props.original.contract?.state)
                    },
                    {
                        Header: _trans('Kesto', {}, 'extract'),
                        id: 'contract.duration',
                        Cell: (props) => ContractDurationCell(props)
                    }
                ]}
            />
        </div>
    );
};

ContractsForm.propTypes = {
    original: PropTypes.object
};

ContractsForm.defaultProps = {
    original: {}
};

const ContractDurationCell = (props) => {
    const startDate = props.original.contract?.startDate;
    if (props.original.contract?.terminationTime) {
        return <span>{`${_toLocaleDate(startDate)} - ${_toLocaleDate(props.original.contract.terminationTime)}`}</span>;
    }
    if (props.original.contract?.endDate) {
        return <span>{`${_toLocaleDate(startDate)} - ${_toLocaleDate(props.original.contract.endDate)}`}</span>;
    }
    return <span>{`${_toLocaleDate(startDate)} - ${_trans('Toistaiseksi', {}, 'extract')}`}</span>;
};

ContractDurationCell.propTypes = {
    original: PropTypes.object.isRequired
};
