import React from 'react';
import PropTypes from 'prop-types';
import { Value } from 'shared/components/Value';

/**
 * Humanisoitu booleani.
 * @param name
 * @param value
 * @returns {JSX.Element}
 * @constructor
 */
export const BooleanValue = ({ name, value }) => (
    <Value ariaDescribedBy={name}>
        <span className="u-text-capitalize">
            {value ? _trans('yes') : _trans('no')}
        </span>
    </Value>
);

BooleanValue.propTypes = {
    name: PropTypes.string,
    // eslint-disable-next-line react/boolean-prop-naming
    value: PropTypes.bool.isRequired,
};

BooleanValue.defaultProps = {
    name: null,
};
