import React from 'react';
import PropTypes from 'prop-types';
import { Fieldset } from 'react-redux-form';

/**
 * @deprecated Käytä Formik:ia ja meidän sharedista Form ja FormFieldiä.
 */
const StFieldset = ({ model, children, ...rest }) => (
    <Fieldset
        model={model}
        component="fieldset"
        {...rest}
    >
        {children}
    </Fieldset>
);

StFieldset.propTypes = {
    children: PropTypes.node.isRequired,
    model: PropTypes.string.isRequired,
};

export default StFieldset;
