import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { usePut } from 'shared/hooks/useApi';
import { Page, SubmitButton } from 'shared/components';
import { Locale } from 'shared/components/Formik/Locale';

const ChangeLocale = () => {
    const dispatch = useDispatch();
    const [{isPending}, makeRequest] = usePut('/api/v2/users/self/locale');

    const model = {
        locale: Translator.locale
    };
    return (
        <Page heading={_trans('Vaihda asiointikieli', {}, 'common')} maxWidth="small">
            <Formik
                initialValues={{
                    locale: model.locale,
                }}
                onSubmit={(model) => {
                    makeRequest(model).then((data) => {
                        if (data.status === 'ok') {
                            dispatch.notifications.addSuccess(_trans('Tiedot tallennettu', {}, 'common'));

                            // lataa sivu uudelleen uudella kielelä
                            setTimeout(() => {
                                window.location.reload();
                            }, 500);
                        } else {
                            dispatch.notifications.addError(data.message);
                        }
                    });
                }}
            >
                {({ handleSubmit }) => (
                    <form className="o-form o-form--vertical" onSubmit={handleSubmit}>
                        <Locale />

                        <div>
                            <SubmitButton isPending={isPending}>
                                {_trans('users.action.save')}
                            </SubmitButton>
                        </div>
                    </form>
                )}
            </Formik>
        </Page>
    );
};

export default ChangeLocale;
