import _ from 'lodash';
import useMergeState from 'shared/hooks/mergeState';
import api from 'api';

/**
 * DataTable-containerin kanssa toimiva paginaatio bäkkärin kanssa. Anna urli ilman offset, limit tai q tietoja.
 * DataTable:n onFetchData-proppi kutsuu fetchPage funktiota.
 *
 * @param url
 * @param initialValues
 * @returns {[*, fetchPage]}
 */
export const usePagination = (url, initialValues = { page: 1, pageSize: 20, pages: 1, additionalQueryParams: {} }) => {
    const [paginationState, setPaginationState] = useMergeState(initialValues);
    const [data, setData] = useMergeState({ data: null, error: null, isLoading: false });

    const fetchPage = (page, pageSize, freeQuery = '') => {
        setPaginationState({ page, pageSize });
        setData({ isLoading: true });
        api.get(`${url}?${createQueryParams(page*pageSize, pageSize, freeQuery, paginationState.additionalQueryParams)}`)
            .then((json) => {
                setData({ data: json, isLoading: false, error: null });
                setPaginationState({ pages: Math.ceil(_.get(json, 'total', 1) / pageSize) });
            }, (err) => {
                setData({ data: null, isLoading: false, error: err });
            });
    };

    return [Object.assign({}, paginationState, data), fetchPage];
};

const createQueryParams = (offset, limit, freeQuery, additionalQueryParams) => {
    const params = new URLSearchParams({ offset, limit, q: freeQuery });
    Object.entries(additionalQueryParams).forEach(([key, value]) => {
        if (value instanceof Array) {
            value.forEach((subValue) => {
                params.append(`${key}[]`, subValue);
            });
        } else {
            params.append(key, value);
        }
    });
    return params.toString();
};
