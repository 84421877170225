import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import debounce from 'lodash/debounce';
import isEqual from 'react-fast-compare';

/**
 * Persistoi Formikin staten joko localStorageen tai sessionStorageen.
 * Käyttö:
 * <Formik...>
 *     <Form...>
 *         <FormikPersist />
 *     </Form>
 * </Formik>
 */
class PersistImpl extends React.Component {
    static defaultProps = {
        debounce: 300,
    };

    saveForm = debounce((data) => {
        if (this.props.isSessionStorage) {
            window.sessionStorage.setItem(this.props.name, JSON.stringify(data));
        } else {
            window.localStorage.setItem(this.props.name, JSON.stringify(data));
        }
    }, this.props.debounce);

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.formik, this.props.formik)) {
            this.saveForm(this.props.formik);
        }
    }

    componentDidMount() {
        const maybeState = this.props.isSessionStorage
            ? window.sessionStorage.getItem(this.props.name)
            : window.localStorage.getItem(this.props.name);

        if (maybeState) {
            this.props.formik.setFormikState(JSON.parse(maybeState));
        }
    }

    render() {
        return null;
    }
}

PersistImpl.defaultProps = {
    formik: {},
    isSessionStorage: false,
    debounce: 300,
};

PersistImpl.propTypes = {
    formik: PropTypes.object,
    name: PropTypes.string.isRequired,
    /**
     * Tallennetaanko tiedot sessionStorageen.
     * Tiedot tyhjentyvät kun selaimen / tabin sulkee.
     */
    isSessionStorage: PropTypes.bool,
    debounce: PropTypes.number,
};

export default connect(PersistImpl);
