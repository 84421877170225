import { useField } from 'formik';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SingleEmployee } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Parties/SingleEmployee';
import { fieldNames } from 'ContractV3/constants/fieldNames';

/**
 * Toinen työntekijä.
 * Listalta poistetaan halutut muut työntekijät. Parsitaan name:n avulla.
 * @param name
 * @param values
 * @param attributes
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const OtherEmployee = ({ name, values, attributes, ...rest }) => {
    const { omittedEmployeeFields } = attributes;
    const [field,,helpers] = useField(name);
    const otherEmployeeUserId = parseInt(field.value, 10);

    const omittedIds = omittedEmployeeFields ?? []
        // Lisätään oletuksena työntekijä listaan mukaan
        .concat(fieldNames.EMPLOYEE)
        .filter((fieldName) => fieldName !== name)
        .map((fieldName) => parseInt(values[fieldName], 10))
        .filter(Boolean);

    // Jos sama hoitaja valittu, tyhjennä tämän työntekijän valinta
    useEffect(() => {
        if (! isNaN(otherEmployeeUserId) && omittedIds.includes(otherEmployeeUserId) && field.value !== '') {
            helpers.setValue('');
        }
    }, [otherEmployeeUserId, omittedIds]);

    return <SingleEmployee name={name} attributes={attributes} {...rest} omittedUserIds={omittedIds} />;
};

OtherEmployee.propTypes = {
    name: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    attributes: PropTypes.object.isRequired,
};

export default OtherEmployee;
