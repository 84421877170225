import { push } from 'connected-react-router';
import _ from 'lodash';
import api from 'api';
import { actions as notificationActions } from 'shared/stores/notifications';
import { parseApiErrors } from 'shared/utils/formUtils';

export default {
    state: {
        dimensions: [],
        dimensionType: {},
    },
    reducers: {
        setDimensions: (state, payload) => ({
            ...state,
            dimensions: payload,
        }),
        setDimensionType: (state, payload) => ({
            ...state,
            dimensionType: payload,
        })
    },
    selectors: {
        getDimensionTypes: (state) => _.get(state, 'dimensions') ?? [],
        getDimensionType: (state) => _.get(state, 'dimensionType'),
        getDimensionByDimensionId: (state, id) => _.get(state, 'dimensionType.dimensions', [])
            .find((dimension) => _.get(dimension, 'id') === parseInt(id, 10)) || {},
        // Dimensiot dimensiotyypin id:n perusteella
        getDimensionsByDimensionTypeId: (state, id) => (state.dimensions ?? []).find((dimension) => dimension.id === parseInt(id, 10)) || [],

        // Palvelumaksujen lajitteludimensiot
        getSurchargeDimensionTypes: (state) => (state.dimensions ?? [])
            .filter((dimension) => dimension.isServiceChargeInvoicingDimension ?? false),

        findSalaryTypeDimension: (state) => state.dimensions.find((type) => type.isSalaryTypeDimension),
        getSalaryTypeDimensions: (state) => state.dimensions.filter((type) => type.isSalaryTypeDimension) ?? [],

        getInvoiceGroupingDimensionType: (state) => (state.dimensions ?? [])
            .find((dimension) => dimension.isSalesInvoiceGrouping ?? false) ?? {},
        // Valtuushallinta dimensiot
        getVisibilityDimensions: (state) => (state.dimensions ?? []).find((dt) => dt.isVisibilityDimension)?.dimensions ?? [],
        getVisibilityDimensionTypeName: (state) => (state.dimensions ?? []).find((dt) => dt.isVisibilityDimension)?.name ?? null,
        hasVisibilityDimensions: (state) => ((state.dimensions ?? []).find((dt) => dt?.isVisibilityDimension)?.dimensions ?? []).length > 0,
        // Yhteystiedot dömensiö
        getContractContactDimensions: (state) => (state.dimensions ?? []).find((dt) => dt.isContractContactDimension)?.dimensions ?? [],
        getContractContactDimensionTypeName: (state) => (state.dimensions ?? []).find((dt) => dt.isContractContactDimension)?.name ?? null,
        // kirjanpitodimensiot
        getPostingDefaultDimensionTypes: (state) => (state.dimensions ?? []).filter((dt) => dt?.isBookkeepDefaultDimension),
    },
    effects: (dispatch) => ({
        /**
         * isContract-lipulla haetaan sopparin dimensiot (duh)
         *
         * @param isContract
         * @param isSearchDimension
         * @param timeRegistration
         * @param isVisibilityDimension
         * @param restrictDimensionVisibility
         * @param isContractContactDimension
         * @param isBenefitDecision
         * @param isBookkeepDefaultDimension
         * @returns {Promise<void>}
         */
        async fetchDimensionTypes({
            isContract = false,
            isSearchDimension = false,
            timeRegistration = false,
            isVisibilityDimension = false,
            restrictDimensionVisibility = true,
            isContractContactDimension = false,
            isBenefitDecision = false,
            isBookkeepDefaultDimension = false,
        } = {}) {
            try {
                const json = await api.get(Routing.generate('get_dimension-types', {
                    contract: isContract ? 1 : null,
                    searchDimension: isSearchDimension ? 1 : null,
                    timeRegistration: timeRegistration ? 1 : null,
                    visibility: isVisibilityDimension ? 1 : null,
                    restrictDimensionVisibility: restrictDimensionVisibility ? 1 : null,
                    isContractContactDimension: isContractContactDimension ? 1 : null,
                    benefitDecision: isBenefitDecision ? 1 : null,
                    bookkeepDefaultDimension: isBookkeepDefaultDimension ? 1 : null,
                }));
                this.setDimensions(json.data);
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * Hakee sopparin dimensiot.
         * @param contractId
         * @returns {Promise<void>}
         */
        async fetchContractDimensionTypes(contractId) {
            try {
                // Työajan dimensiot
                const json = await api.get(Routing.generate('get_contract_all_dimension_types', {
                    contract: contractId,
                    timeRegistration: 1,
                }));
                this.setDimensions(_.get(json, 'data', []));
            } catch (e) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('time_registration.error.dimension_fetch_error')
                }));
                console.error(e);
            }
        },

        /**
         * Päivittää sopimuksen dimensiot.
         * @param contractDimensions
         * @param contractId
         * @returns {Promise<void>}
         */
        async postContractDimensions({ contractDimensions, contractId }) {
            try {
                const response = await api.post(`/api/v2/contracts/${contractId}/dimensions`, { dimensions: contractDimensions });
                if (response.status === 'ok') {
                    dispatch.notifications.addSuccess(_trans('dimension.notifications.success.post'));
                } else {
                    dispatch.notifications.addError(response.message ?? _trans('dimension.notifications.error.post'));
                }
                return response;
            } catch (error) {
                dispatch.notifications.addError(_trans('dimension.notifications.error.post'));
            }
        },

        async fetchDimensionType(id) {
            try {
                const json = await api.get(Routing.generate('get_dimension-type', { type: id }));
                this.setDimensionType(json.data);
            } catch (e) {
                console.error(e);
            }
        },
        async putDimension(model, rootState, route) {
            try {
                await api.put(
                    Routing.generate('put_dimension-type_dimension', { type: model.typeId, dimension: model.id }),
                    _.omit(model, ['typeId', 'id', 'type'])
                );
                if (route) {
                    await dispatch(push(route));
                }
                await dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('company_manager.dimensions.notifications.success.dimension_saved')
                }));
            } catch (e) {
                console.error(e);
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: e.message ? e.message : _trans('company_manager.dimensions.notifications.error.dimension_saved'),
                }));
            }
        },
        async postDimension(model, rootState, route) {
            try {
                await api.post(
                    Routing.generate('post_dimension-type_dimensions', { type: model.typeId }),
                    _.omit(model, 'typeId')
                );
                if (route) {
                    await dispatch(push(route));
                }
                await dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('company_manager.dimensions.notifications.success.dimension_saved')
                }));
            } catch (e) {
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: e.message ? e.message : _trans('company_manager.dimensions.notifications.error.dimension_saved'),
                }));
            }

        },
        async putDimensionType(model, rootState, route) {
            try {
                const json = await api.put(
                    Routing.generate('put_dimension-type', { type: model.id }),
                    _.omit(model, ['dimensions', 'id'])
                );
                if (json.status === 'error') {
                    const errors = parseApiErrors(json.data);
                    return await dispatch(notificationActions.addNotification({
                        type: 'error',
                        message: errors,
                    }));
                }
                if (route) {
                    await dispatch(push(route));
                }
                await dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('company_manager.dimensions.notifications.success.dimension_type_saved')
                }));
            } catch (e) {
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('company_manager.dimensions.notifications.error.dimension_saved'),
                }));
                console.error(e);
            }
        },
        async postDimensionType(model, rootState, route) {
            try {
                const json = await api.post(Routing.generate('post_dimension-type'), model);
                if (json.status === 'error') {
                    const errors = parseApiErrors(json.data);
                    return await dispatch(notificationActions.addNotification({
                        type: 'error',
                        message: errors,
                    }));
                }
                if (route) {
                    await dispatch(push(route));
                }
                await dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('company_manager.dimensions.notifications.success.dimension_type_saved')
                }));
            } catch (e) {
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('company_manager.dimensions.notifications.error.dimension_saved'),
                }));
            }
        },
        async deleteDimensionType(id) {
            try {
                await api.del(Routing.generate('delete_dimension-type', { type: id }));
                this.fetchDimensionTypes();
                await dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('company_manager.dimensions.notifications.success.dimension_type_deleted')
                }));
            } catch (e) {
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: e.message ? e.message : _trans('company_manager.dimensions.notifications.error.dimension_type_deleted'),
                }));
                console.error(e);
            }
        },
        async deleteDimension({ id, typeId }) {
            try {
                await api.del(Routing.generate('delete_dimension-type_dimension', { type: typeId, dimension: id }));
                this.fetchDimensionType(typeId);
                await dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: _trans('company_manager.dimensions.notifications.success.dimension_deleted')
                }));
            } catch (e) {
                await dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: e.message ? e.message : _trans('company_manager.dimensions.notifications.error.dimension_deleted'),
                }));
                console.error(e);
            }
        }
    })
};

export const loadingSelectors = {
    isLoadingContractDimensionTypes: (state) => _.get(state, 'loading.effects.dimensions.fetchContractDimensionTypes', false),
};
