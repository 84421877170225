import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MDIcon from 'shared/components/MDIcon';

export default class Pagination extends PureComponent {

    renderPrevArrow() {
        return (
            <button onClick={this.props.onPrevPage} className="o-box o-box--tiny">
                <MDIcon icon={this.props.prevArrowIcon} modifierClass="u-align-middle" /> {this.props.prevArrowText}
            </button>
        );
    }

    renderNextArrow() {
        return (
            <button onClick={this.props.onNextPage} className="o-box o-box--tiny">
                {this.props.nextArrowText} <MDIcon icon={this.props.nextArrowIcon} modifierClass="u-align-middle" />
            </button>
        );
    }

    render() {
        if (! this.props.pageCount) return null;

        const { resultsPerPage } = this.props;

        const currentPage = this.props.currentPage,
            pageCount = this.props.pageCount;

        const options = [];
        for (let i = 0; i <= pageCount; i++) {
            const j = i*resultsPerPage + 1;
            options.push({
                // lisätään id:hen 1 ettei ensimmäinen sivu ole nolla koska sotkii alasvetovalikon
                id: i+1,
                name: `${j} - ${j + resultsPerPage}`,
            });
        }

        // koska id:tä lisättiin yhdellä pitää myös currentPagea nostaa yhdellä
        // ja ulospäin lähtevää arvoa taas vähentää yhdellä
        return (
            <ul className="o-list-bare o-stack o-stack--center u-margin-top-small u-margin-bottom-none">
                {this.renderPrevArrow()}
                <select
                    defaultValue={currentPage+1}
                    onChange={(value) => this.props.setPage(value-1)}
                >
                    {_.map(options, (option) => (
                        <option value={option.id} key={option.id}>
                            {option.name}
                        </option>
                    ))}
                </select>
                {this.renderNextArrow()}
            </ul>
        );
    }
}

Pagination.propTypes = {
    resultsPerPage: PropTypes.number.isRequired,
    prevArrowText: PropTypes.string.isRequired,
    prevArrowIcon: PropTypes.string.isRequired,
    onPrevPage: PropTypes.func.isRequired,
    nextArrowText: PropTypes.string.isRequired,
    nextArrowIcon: PropTypes.string.isRequired,
    onNextPage: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
};
