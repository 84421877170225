/**
 * Välilyönti joka 4. merkin jälkeen.
 * @param value
 */
export const spaceEveryFourth = (value) =>
    value
        // Välilyönnit pois
        .replace(/ /g, '')
        // Välilyönti joka nth. merkin jälkeen
        .replace(/(.{4})/g, '$1 ')
        // Poistetaan viimeinen välilyönti jotta pyyhkiminen onnistuu (lol)
        .replace(/(^\s+|\s+$)/,'');
