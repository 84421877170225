import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';
import { StLabel, StField, StErrors } from 'shared/components/StForm';
import { validators } from 'shared/validators';

export default class LastName extends PureComponent {
    render() {
        const {
            model,
            isRequired,
            ...rest
        } = this.props;

        return (
            <div>
                <StLabel htmlFor="lastName" isRequired={isRequired}>
                    {_trans('userForm.lastName.label')}
                </StLabel>
                <StField>
                    <Control.text
                        {...rest}
                        model={model}
                        id="lastName"
                        size={25}
                        maxLength={25}
                        validators={{
                            isRequired: validators.isRequired,
                        }}
                    />
                    <StErrors model={model}/>
                </StField>
            </div>
        );
    }
}

LastName.defaultProps = {
    model: '.lastName',
    isRequired: false,
};

LastName.propTypes = {
    model: PropTypes.string,
    isRequired: PropTypes.bool,
};
