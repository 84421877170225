import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Feedback } from 'shared/components';
import utils from 'shared/utils';

const COUNTDOWN_INTERVAL = 1000 * 60 * 60; // Tunti

/**
 * Kertoo milloin jokin asia (de)aktivoituu. Käytetään lähinnä
 * kertomaan milloin jokin vanha asia poistuu käytöstä ja/tai uusi tulee tilalle.
 */
export default class CountdownFeedback extends PureComponent {
    static propTypes = {
        /**
         * Viesti joka kertoo kaikkein olennaisimman asian. Viestiin sisällytetään laskurin päivät.
         */
        message: PropTypes.string.isRequired,

        /**
         * H-hetki muodossa DD.MM.YYYY
         */
        doomsDate: PropTypes.string.isRequired,

        /**
         * Triggerin teksti. Optionaalinen. Tällä voidaan esim. aukaista modaali joka
         * kertoo mitä parannuksia feature tuo käyttäjälle.
         */
        triggerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

        /**
         * Mitä tapahtuu kun triggeriä klikataan.
         */
        onTriggerClick: PropTypes.func,
    };

    static defaultProps = {
        triggerText: null,
        onTriggerClick() {},
    };

    constructor(props) {
        super(props);

        this.state = {
            countDownText: this.getCountDownText(props),
        };
    }

    getCountDownText = (props) => `${_transChoice('date.countdown_days', utils.countDown(props.doomsDate))}`;

    componentDidMount() {
        setInterval(() => {
            this.setState({
                countDownText: this.getCountDownText(this.props),
            });
        }, COUNTDOWN_INTERVAL);
    }

    /**
     * Renderöi modaalin avaavan tekstin.
     * @returns {*}
     */
    renderTriggerText() {
        return (
            <span className="u-color-link u-cursor-pointer" onClick={this.props.onTriggerClick}>
                {this.props.triggerText}
            </span>
        );
    }

    render() {
        // Jos pvm on saavutettu älä näytä sisältöä.
        if (utils.countDown(this.props.doomsDate) === 0) return null;

        return (
            <Feedback type="warning" hasIcon={false} modifierClass="u-margin-bottom-small u-margin-bottom@medium">
                {_transMd(this.props.message, {
                    days: this.state.countDownText,
                })}
                {this.renderTriggerText()}
            </Feedback>
        );
    }
}
