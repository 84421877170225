export default {
    /**
     * Käyttäjällä on oikeus allekirjoittaa yrityksen nimissä. Käytetään ennen yrityksen lisäämistä palveluun
     * käyttäjän ja yritysten linkittämiseen kun yrityksen tiedot on haettu valtion tietojärjestelmistä, mutta
     * ei vielä otettu aktiiviseen käyttöön.
     */
    RELATION_PROCURIST: 50,

    /**
     * Yrityksen pääkäyttäjä. Yrityksen allekirjoittaja joka voi antaa muille käyttöoikeksia.
     */
    RELATION_MASTER_USER: 51,

    /**
     * Yrityksen käyttäjä. Käyttäjä joka operoi yrityksen palkanmaksua.
     */
    RELATION_OPERATOR: 52,
};
