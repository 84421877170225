import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AccordionItem, Accordion } from 'shared/components/Accordion';
import { CustomTitle } from 'shared/TaxCard/components/CustomTitle';
import { TaxCard } from 'shared/TaxCard/containers/TaxCard';
import { badgeTypes } from 'shared/components/Badge';
import { Placeholder, Feedback } from 'shared/components';
import { PageActionItems } from 'shared/TaxCard/containers/PageActionItems';

export const TaxCardContainer = hot(({ dataId, contractType, isCommuneUser }) => {
    const dispatch = useDispatch();
    const isEditMode = useSelector(select.taxCards.isEditMode);
    const isVeroApiEnabled = useSelector(select.taxCards.isVeroApiEnabled);
    const isAdmin = useSelector(select.userMetadata.isAdmin);
    const isCommuneAdmin = useSelector(select.userMetadata.isCommuneAdmin);
    // Verokorttien tiedot
    const activeTaxCard = useSelector(select.taxCards.getActiveTaxCard);
    const pendingTaxCards = useSelector(select.taxCards.getPendingTaxCards);
    const expiredTaxCards = useSelector(select.taxCards.getExpiredTaxCards);
    // Ladataanko kortteja
    const isLoading = useSelector((state) => state.loading.effects.taxCards.fetchTaxCards);
    const isPrepaymentRegisterCarer = useSelector(select.taxCards.isPrePaymentRegisterEmployee);
    const {contractDataId} = useParams();

    // Käytetään urli paramsseja jos ei anneta propsina niin ei joudu säätämään SideBar.jssää Bäkkärin pitäisi pitää huoli ettei tietoja pääse vuotamaan
    /**
     * Haetaan verokortit sekä tallennetaan contractDataId ja contractType storeen.
     *
     * @param props
     */
    useEffect(() => {
        if (dataId) {
            dispatch.taxCards.fetchTaxCards(dataId);
            dispatch.taxCards.setContractType(contractType);
            dispatch.taxCards.setJobContractDataId(dataId);
        } else {
            dispatch.taxCards.setJobContractDataId(contractDataId);
            dispatch.taxCards.setContractType(contractType);
            dispatch.taxCards.fetchTaxCards(contractDataId);
        }
    }, [dispatch, contractDataId, contractType, dataId]);

    return (
        <Placeholder isPending={isLoading}>
            {(! isVeroApiEnabled && ! isAdmin && ! isCommuneAdmin && isCommuneUser) &&
                <Feedback type="info" isDismissable message={_trans('tax_card.info.vero_api_disabled')} modifierClass="u-margin-bottom"/>
            }

            {isPrepaymentRegisterCarer && <Feedback
                type="info"
                message={_trans('Hoitaja kuuluu ennakkoperintärekisteriin, verokorttia ei haeta Omaverosta.', {}, 'tax_card')}
                modifierClass="u-margin-bottom"
            />}

            <PageActionItems
                contractDataId={dataId ? dataId : contractDataId}
                contractType={contractType}
            />

            <TaxCard key={activeTaxCard.taxCardId} taxCard={activeTaxCard}/>
            {! isEditMode &&
                <Accordion>
                    <AccordionItem
                        customTitle={<CustomTitle title={_trans('text.pending')} type={badgeTypes.PRIMARY} count={pendingTaxCards.length}/>}
                    >
                        {pendingTaxCards.map((taxCard) => <TaxCard key={taxCard.taxCardId} taxCard={taxCard} />)}
                    </AccordionItem>
                    <AccordionItem
                        customTitle={<CustomTitle title={_trans('text.expired')} type={badgeTypes.NEUTRAL} count={expiredTaxCards.length}/>}
                    >
                        {expiredTaxCards.map((taxCard) => <TaxCard key={taxCard.taxCardId} taxCard={taxCard} />)}
                    </AccordionItem>
                </Accordion>
            }
        </Placeholder>
    );
});

TaxCardContainer.propTypes = {
    contractType: PropTypes.number.isRequired,
    isCommuneUser: PropTypes.bool.isRequired,
};
