import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { TabList as ReactTabList } from 'vendor/react-aria-tabpanel/TabList';
import { sizes } from 'shared/constants';

export default class TabList extends PureComponent {

    static defaultProps = {
        tabSize: 'medium',
        hasMargin: false,
    };

    static propTypes = {
        children: PropTypes.node.isRequired,
        tabSize: PropTypes.oneOf(_.map(sizes)),
        hasMargin: PropTypes.bool,
    };

    render() {
        const {
            children,
            hasMargin,
            tabSize,
        } = this.props;

        const childrenWithProps = React.Children.map(children,
            (child) => React.cloneElement(child, {
                tabSize,
            })
        );

        const tabListClass = classNames('c-tab-list-container', {
            'u-margin-bottom-small u-margin-bottom@medium': hasMargin,
        });

        return (
            <ReactTabList className={tabListClass}>
                <ul className="c-tab-list o-list-bare">
                    {childrenWithProps}
                </ul>
            </ReactTabList>
        );
    }
}
