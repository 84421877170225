import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxField } from 'shared/components/Formik';

/**
 * Koeajan valinta.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */

const HasTrialPeriod = ({ name, inputLabel }) => (
    <CheckboxField
        name={name}
        label={inputLabel}
    />
);

HasTrialPeriod.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    inputLabel: PropTypes.string.isRequired,
};

export default HasTrialPeriod;
