import React from 'react';
import PropTypes from 'prop-types';
import { Field } from './Field';

/**
 * Näyttää mobiiliystävällisen sähköpostin syöttökentän.
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const EmailField = ({ children, ...rest }) => (
    <Field type="email" {...rest}>
        {children}
    </Field>
);

EmailField.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

EmailField.defaultProps = {
    children: undefined,
};
