const templateStates = {
    STATE_ACTIVE: 1,
    STATE_DRAFT: 2,
    STATE_REMOVED: 3,
    STATE_REPLACED: 4,
};

export default templateStates;

export const templateStateMap = {
    [templateStates.STATE_ACTIVE]: 'Voimassa',
    [templateStates.STATE_DRAFT]: 'Luonnos',
    [templateStates.STATE_REMOVED]: 'Poistettu',
    [templateStates.STATE_REPLACED]: 'Korvattu',
};

export const translateTemplateState = (templateState) => templateStateMap[templateState] ?? 'Tuntematon';

export const templateUiState = {
    [templateStates.STATE_ACTIVE]: 'u-color-positive',
    [templateStates.STATE_DRAFT]: 'u-color-grey-500',
    [templateStates.STATE_REMOVED]: 'u-color-negative',
    [templateStates.STATE_REPLACED]: 'u-color-warning',
};

export const mapTemplateStateToUi = (templateState) => templateUiState[templateState] ?? 'u-color-grey-500';
