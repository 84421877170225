/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {Array}           The RGB representation
 */
function hslToRgb(h, s, l) {
    let r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = function hue2rgb(p, q, t) {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function hslToHex(h, s, l) {
    s /= 100;
    l /= 100;

    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs((h / 60) % 2 - 1));
    const m = l - c / 2;

    let r = 0;
    let g = 0;
    let b = 0;

    if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
    }
    // Having obtained RGB, convert channels to hex
    r = Math.round((r + m) * 255).toString(16);
    g = Math.round((g + m) * 255).toString(16);
    b = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (r.length === 1)
        r = '0' + r;
    if (g.length === 1)
        g = '0' + g;
    if (b.length === 1)
        b = '0' + b;

    return '#' + r + g + b;
}

function stringToHslColor(str, s, l) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return [h, s, l];
}

/**
 * Onko väri tumma? Käytetään tekstin värin määrittämiseen taustavärin perusteella.
 * @param r
 * @param g
 * @param b
 */
function isColorDark(r, g, b) {
    return (r * 299 + g * 587 + b * 114) / 1000 < 123;
}

const hexToR = (h) => parseInt((cutHex(h)).substring(0, 2), 16);
const hexToG = (h) => parseInt((cutHex(h)).substring(2, 4), 16);
const hexToB = (h) => parseInt((cutHex(h)).substring(4, 6), 16);
const cutHex = (h) => (h.charAt(0) === '#') ? h.substring(1, 7) : h;

/**
 * From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast

 * Color brightness is determined by the following formula:
 * ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000

 * I know this could be more compact, but I think this is easier to read/explain.
 * @param hex
 * @returns {string}
 */
function getAccessibleTextColor(hex) {
    const threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

    const hRed = hexToR(hex);
    const hGreen = hexToG(hex);
    const hBlue = hexToB(hex);

    const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
    return cBrightness > threshold ? '#000000' : '#ffffff';
}

/**
 * Palauttaa tekstin ja tausta värin annetun stringin perusteella.
 * Käytetään esim. avatarin uniikin taustakuvan ja tekstin määrittämiseen kun kuva puuttuu.
 * @param str
 * @param saturation - värin voimakkuus
 * @param lightness - värin kirkkaus / vaaleus
 * @returns {{backgroundColor: string, color: (string)}}
 */
function getColorsByString(str, saturation = 65, lightness = 85) {
    const [h, s, l] = stringToHslColor(str, saturation, lightness);

    const color = getAccessibleTextColor(hslToHex(h, s, l));
    /*const color = hslToRgb(h / 360, s / 100, l / 100);
    const [r, g, b] = color;*/

    return {
        // Jos taustaväri on tumma käytetään valkoista tekstin väriä ja päinvastoin.
        color,//: isColorDark(r, g, b) ? 'rgba(255, 255, 255, .8)' : 'rgba(0, 0, 0, .8)',
        backgroundColor: 'hsl(' + h + ', ' + s + '%, ' + l + '%)',
    };
}

export default {
    stringToHslColor,
    hslToRgb,
    isColorDark,
    getColorsByString,
};
