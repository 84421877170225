import React, { Fragment } from 'react';
import { Control } from 'react-redux-form';
import PropTypes from 'prop-types';
import { StErrors, StField, StLabel } from 'shared/components/StForm';
import { errorMessages } from 'shared/validators';
import { DateInput } from 'shared/components';

const StartDate = ({ isReadOnly, label, model, minimumDate, customErrorMessages, inputValidators }) => (
    <Fragment>
        <StLabel htmlFor="mysticStartDate">
            {label}
        </StLabel>
        <StField>
            <Control
                id="mysticStartDate"
                minimumDate={minimumDate ? new Date(minimumDate) : new Date('1970-01-01')}
                readOnly={isReadOnly}
                model={model}
                component={DateInput}
                validators={inputValidators}
            />
            <StErrors model={model} messages={{ ...errorMessages, ...customErrorMessages, }} />
        </StField>
    </Fragment>
);

StartDate.propTypes = {
    model: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isReadOnly: PropTypes.bool,
    minimumDate: PropTypes.string,
    customErrorMessages: PropTypes.object,
    inputValidators: PropTypes.object,
};

StartDate.defaultProps = {
    minimumDate: '',
    customErrorMessages: {},
    inputValidators: {},
    isReadOnly: false,
};

export default StartDate;
