/**
 * Luo Duckin nätisti ilman constien ja action creatoreiden käsin luontia.
 *
 * Anna actionHandlers parametrina map, joka sisältää avaimena actionin nimen
 * ja arvona metodi, josta varsinainen action luodaan.
 *
 * Esim:
 * ```
 * const actionHandlers = {
 *     MY_ACTION: (state, payload) => state.set('something', true),
 * }
 *
 * const myduck = createDuck('myduck', fromJs({something: false}, actionHandlers);
 * ```
 * Voit käyttää duckia seuraavalla tavalla:
 * ```
 * // Reducer export
 * export const myReducer = {ui: myduck.reducer};
 * // Action dispatch
 * dispatch(myduck.actions.MY_ACTION());
 * dispatch(myduck.actions.MY_ACTION(somePayload));
 * // Jos johonkin tarvitee action typejä, nekin löytyy
 * myduck.types.MY_ACTION
 * ```
 *
 * @param namespace lokeroi actionit omaan namespaceen. Esim. mynamespace/MY_ACTION
 * @param initialState normaali reducerin alkutilanne
 * @param actionHandlers action handlerit objektina
 * @returns {{reducer: function(*=, *), actions: {} & {}, types: {} & {} & {}}}
 */
export function createDuck(namespace, initialState, actionHandlers) {
    const reducer = (state = initialState, action) => {
        const [module, type] = action.type.split('/');
        const resolvedType = module === namespace ? type : action.type;
        if (actionHandlers.hasOwnProperty(resolvedType)) {
            return actionHandlers[resolvedType](state, action.payload);
        } else {
            return state;
        }
    };
    const actions = Object.keys(actionHandlers).reduce(
        (acc, key) =>
            Object.assign(acc, {
                [key]: (payload = {}) => ({ type: `${namespace}/${key}`, payload })
            }),
        {}
    );
    const types = Object.keys(actionHandlers).reduce((acc, key) => Object.assign({}, acc, { [key]: `${namespace}/${key}` }), {});
    return { reducer, actions, types };
}
