import userRoles from 'shared/constants/userRoles';

const resolveRole = (userRole) => {
    switch (userRole) {
        case userRoles.EMPLOYER:
        case userRoles.COMPANY_OPERATOR:
            return 'employer';

        case userRoles.EMPLOYEE:
        case userRoles.FAMILY_CARER:
            return 'employee';
    }
};

/**
 * Miltä rooleilta palikka on piilotettu.
 * @param userRole
 * @param isHiddenFromRolesOnSummary
 */
export const isBlockHiddenFromRoleOnSummary = (userRole, isHiddenFromRolesOnSummary) => (
    (isHiddenFromRolesOnSummary ?? []).includes(resolveRole(userRole))
);
