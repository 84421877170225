import _ from 'lodash';
import api from 'api';

const getAttributeByName = (state, name) => _.find(state, (attribute) => attribute.name === name) || {};

export default {
    state: [],
    reducers: {
        setAttributes: (state, payload) => payload,
    },
    selectors: {
        getAttributes: (state) => state,
        getAttributeByName: (state, name) => getAttributeByName(state, name),
        hasAttributeByName: (state, name) => _.get(getAttributeByName(state, name), 'name') === name,
        getAttributeValueByName: (state, name, isBoolean = false) => {
            const attribute = getAttributeByName(state, name);
            const attributeValue = _.get(attribute, 'value', null);
            if (attributeValue !== null) {
                return isBoolean ? !!attributeValue : attributeValue;
            }
            return attributeValue;
        },
    },
    effects: () => ({
        /**
         * Hakee käyttäjän kaikki atrribuutit, defaulttina kirjautunut käyttäjä
         *
         * @param user
         * @return {Promise<void>}
         */
        async fetchAttributes(user = '_self') {
            try {
                const json = await api.get(Routing.generate('api_1_get_user_attributes', { user }));
                this.setAttributes(json);
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * Tallentaa attribuutin. Joko POST tai PUT riippuen siitä löytyykö attribuutti jo.
         * @param name
         * @param value
         * @param user
         * @param rootState
         * @returns {Promise<void>}
         */
        async saveAttribute({ name, value, user = '_self' }, rootState) {
            const attribute = getAttributeByName(_.get(rootState, 'userAttributes', []), name);
            const userAttributeId = _.get(attribute, 'userAttributeId');

            if (userAttributeId) {
                await this.putAttribute({
                    attribute: userAttributeId,
                    name,
                    value,
                    user,
                });
            } else {
                await this.postAttribute({
                    name,
                    value,
                    user,
                });
            }
        },

        /**
         * Uuden attribuutin lisääminen
         *
         * @param name
         * @param value
         * @param user
         * @param rootState
         * @return {Promise<void>}
         */
        async postAttribute({ name, value, user = '_self' }, rootState) {
            try {
                const json = await api
                    .post(Routing.generate('api_1_post_user_attribute', { user }), {
                        name,
                        value,
                    });
                await this.setAttributes([
                    ...rootState.userAttributes,
                    json.data,
                ]);
            } catch (e) {
                console.error(e);
            }
        },
        /**
         * Attribuutin arvon päivittämistä
         *
         * @param name
         * @param value
         * @param attribute
         * @param user
         * @param rootState
         * @return {Promise<void>}
         */
        async putAttribute({ name, value, attribute, user = '_self' }, rootState) {
            try {
                const json = await api.put(Routing.generate('api_1_put_user_attribute', { user, attribute }), {
                    name,
                    value
                });
                await this.setAttributes([
                    ..._.filter(rootState.userAttributes, (attr) => attr.userAttributeId !== attribute),
                    json.data
                ]);
            } catch (e) {
                console.error(e);
            }
        },
    }),
};

const USER_ATTRIBUTES_SELECTOR = 'loading.effects.userAttributes';

export const loadingSelectors = {
    isLoadingUserAttributes: (state) => _.get(state, `${USER_ATTRIBUTES_SELECTOR}.fetchAttributes`, false),
    isSavingUserAttributes: (state) => _.get(state, `${USER_ATTRIBUTES_SELECTOR}.postAttribute`, false),
    isUpdatingUserAttributes: (state) => _.get(state, `${USER_ATTRIBUTES_SELECTOR}.putAttribute`, false),
};
