import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import DataTable from 'shared/containers/DataTable';
import Page from 'shared/components/Page';
import { TabContainer } from 'shared/components/Tab/index';
import { userSelectors, userActions } from 'shared/UserDetails/stores/user';
import MDSpinner from 'shared/components/MDSpinner';
import { contractActions } from 'Contract/stores/contract';
import contractSelectors from 'Contract/contractSelectors';

export const PRESENT_YEAR = 0;
export const PREVIOUS_YEAR = 1;
export const ARCHIVE = 2;

export default class PayrollList extends Component {
    render() {
        const { payrollRoute } = this.props;
        return (
            <Page isBodyClear heading={_trans('work_time.extra_action.payrolls')}>
                <TabContainer
                    initialTabId="presentYear"
                    tabs={{
                        presentYear: {
                            label: _trans('users.payroll_list.tabs.present_year'),
                            content: <PayrollDataTable state={PRESENT_YEAR} payrollRoute={payrollRoute} />
                        },
                        PreviousYear: {
                            label: _trans('users.payroll_list.tabs.previous_year'),
                            content: <PayrollDataTable state={PREVIOUS_YEAR} payrollRoute={payrollRoute} />
                        },
                        archive: {
                            label: _trans('users.payroll_list.tabs.archive'),
                            content: <PayrollDataTable state={ARCHIVE} payrollRoute={payrollRoute} />,
                        }
                    }}
                />
            </Page>
        );
    }
}

PayrollList.propTypes = {
    payrollRoute: PropTypes.string,
};

PayrollList.defaultProps = {
    payrollRoute: 'suoratyo_payment_payroll_detail',
};

/**
 *  Aikamoinen sähellys tuli tästä kun vähän tarkistellaan ollaanko henkilöt- vai sopparinäkymässä.
 */
@connect((state, props) => {
    if (props.isContractForm) {
        return {
            payrolls: state.payrollList.get('payrolls', List()),
            isLoading: state.payrollList.get('isLoading'),
            contractId: contractSelectors.getOpenContractId(state),
        };
    }
    return {
        userId: userSelectors.getUserId(state),
        payrolls: state.payrollList.get(String(props.state)), //Hmm numerona ei toiminut, mutta stringinä toimi.
        totalMonetarySalary: state.payrollList.getIn(['totalMonetarySalary', String(props.state)]),
        isLoading: state.payrollList.getIn(['isLoading', String(props.state)]),
    };
})
export class PayrollDataTable extends Component {
    static propTypes = {
        payrolls: PropTypes.instanceOf(List),
        state: PropTypes.number,
        dispatch: PropTypes.func,
        userId: PropTypes.number,
        totalMonetarySalary: PropTypes.string,
        isLoading: PropTypes.bool,
        isContractForm: PropTypes.bool,
        contractId: PropTypes.number,
        payrollRoute: PropTypes.string.isRequired,
    };

    static defaultProps = {
        payrolls: List([]),
        state: null,
        dispatch() {},
        userId: -1,
        totalMonetarySalary: '',
        isLoading: false,
        isContractForm: false,
        contractId: null,
    };

    componentDidMount() {
        const { isContractForm, dispatch, contractId, userId, state, } = this.props;

        // isContractForm määrittelee onko table käytössä sopparilla vai henkilöt-näkymässä.
        if (isContractForm) {
            dispatch(contractActions.fetchContractPayrolls(contractId));
        } else {
            dispatch(userActions.fetchUserPayrolls(userId, state));
        }
    }

    render() {
        const { payrolls, isLoading, totalMonetarySalary, isContractForm, payrollRoute } = this.props;

        if (isLoading) {
            return <MDSpinner wrapped/>;
        }

        return (
            <div>
                <DataTable
                    defaultSorted={isContractForm ? [{
                        id: 'payday',
                        desc: true,
                    }] : [{
                        id: 'employerDetails.name',
                        desc: false,
                    }]}
                    isFilterable={! isContractForm}
                    emptyDataIcon="coins"
                    emptyDataMessage={_trans('users.payroll_list.empty_state')}
                    data={payrolls.toJS()}
                    actionsColumn={<PayslipLink route={payrollRoute} />}
                    columns={[
                        {
                            Header: _trans('role.employer'),
                            accessor: 'employerDetails.name',
                            show: ! isContractForm,
                        },
                        {
                            Header: _trans('role.employee'),
                            accessor: 'employeeDetails.name',
                            show: ! isContractForm,
                        },
                        {
                            Header: _trans('work_time.payroll.payday'),
                            accessor: 'payday',
                            type: 'date',
                        },
                        {
                            Header: _trans('work_time.pay_period.pay_period'),
                            accessor: 'payPeriod.periodDescription',
                        },
                        {
                            Header: _trans('users.payroll_list.paid_salary'),
                            accessor: 'monetarySalary',
                            type: 'currency',
                        },
                    ]}
                />
                {totalMonetarySalary ? <span>{`${_trans('users.payroll_list.total_sum')} ${_currency(+totalMonetarySalary)}`}</span> : null}
            </div>
        );
    }
}

const PayslipLink = (props) =>
    (<a href={Routing.generate(props.route, { id: props.original.payrollId })}>
        {_trans('time_registration.pay_period_selector.button_title')}
    </a>);

PayslipLink.propTypes = {
    original: PropTypes.object,
    route: PropTypes.string.isRequired,
};

PayslipLink.defaultProps= {
    original: { payrollId: null, },
};
