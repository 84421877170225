import React, { Component } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import PropTypes from 'prop-types';
import isISO8601 from 'validator/lib/isISO8601';
import { Manager, Reference, Popper } from 'react-popper';
import _ from 'lodash';
import classNames from 'classnames';
import DateRangePicker from './DateRangePicker/DateRangePicker';
import utils from 'shared/utils/commonUtils';
import {
    MDIcon,
} from 'shared/components';
import { positions } from 'shared/constants/index';

const stateDefinitions = {
    available: {
        color: null,
        label: _trans('date_range.states.available'),
    },
    unavailable: {
        selectable: false,
        label: _trans('date_range.states.unavailable'),
    },
};

/**
 * Selvittää oikean päivämääräformaatin vuotta vertailemalla.
 * @param value
 */
const resolveDateFormat = (value) => {
    if (typeof value === 'string' && isISO8601(value)) {
        return moment(value, 'YYYY-MM-DD');
    }
    // 1. Oletetaan että päivämäärä on annettu D.M.YYYY muodossa (tai DD.MM.YYYY)
    const date = moment(value, _dateFormat);

    // 2. Eipä saatu validia päivämäärää, taisipa olla siis ISO-muotoa.
    if (isNaN(date.year())) {
        return moment(value, 'YYYY-MM-DD');
    }

    return date;
};

/**
 * Renderöi aloitus- ja päättymispäivämääräkentät.
 * Sallii arvojen syöttämisen sekä käsin että avautuvasta kalenterista.
 */
export default class DateRangeInput extends Component {
    static defaultProps = {
        hasErrors: false,
        dateRanges: [],
        unavailableDates: [],
        placement: 'bottom-start',
        minimumDate: null,
        maximumDate: null,
        startDatePlaceholder: _trans('Alkaa', {}, 'common'),
        endDatePlaceholder: _trans('Päättyy', {}, 'common'),
        fixedEndDateValue: '',
        onChange: () => {},
        hasSingleInput: false,
        initialDate: new Date(),
        hasInitialFromValue: true,
        readOnly: false,
        isNullAllowedInEnd: false,

        /**
         * Onko aloituspvm-kentällä fokus mountatessa.
         */
        hasStartDateFocus: false,
        /**
         * RRF ja Formikilta automaattisesti tuleva name jota voidaan käyttää myös suoraan id:nä jos sitä ei ole annettu.
         */
        name: null,
        id: null,
        isRequired: false,
    };

    static propTypes = {
        hasErrors: PropTypes.bool,
        /**
         * Päivien tarkempaan hienosäätöön.
         */
        dateRanges: PropTypes.array,
        /**
         * Shortcut päivämäärille (aikaväli käytännössä) joita ei voi valita.
         */
        unavailableDates: PropTypes.arrayOf(PropTypes.shape({
            start: PropTypes.string.isRequired,
            end: PropTypes.string.isRequired,
        })),
        placement: PropTypes.oneOf(positions),
        minimumDate: PropTypes.instanceOf(Date),
        maximumDate: PropTypes.instanceOf(Date),
        startDatePlaceholder: PropTypes.string,
        endDatePlaceholder: PropTypes.string,
        fixedEndDateValue: PropTypes.string,
        onChange: PropTypes.func,
        hasSingleInput: PropTypes.bool,
        initialDate: PropTypes.instanceOf(Date),
        hasInitialFromValue: PropTypes.bool,
        /* eslint-disable-next-line react/boolean-prop-naming */
        readOnly: PropTypes.bool,
        isNullAllowedInEnd: PropTypes.bool,
        hasStartDateFocus: PropTypes.bool,
        name: PropTypes.string,
        id: PropTypes.string,
        isRequired: PropTypes.bool,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            value: null,
            startDate: '',
            endDate: '',
            hasSelectedStartDate: false,
            hasStartDateFocus: props.hasStartDateFocus,
            hasEndDateFocus: false,
            isDatePickerOpen: false,
            maxNumberOfCalendars: 2,
        };

        this.startDateInput = null;
        this.endDateInput = null;
    }

    componentDidMount() {
        this.onResize();
        this.getInitialValues(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.getInitialValues(nextProps);
    }

    getAriaAttributes() {
        const id = this.props.name || this.props.id;

        return {
            'aria-invalid': this.props.hasErrors,
            'aria-errormessage': this.props.hasErrors ? `${id}Errors` : null,
        };
    }

    /**
     * Asetetaan lähtöarvot kentille jos ne löytyvät.
     */
    getInitialValues(props) {
        if (this.hasSingleInput()) {
            let initialDate = null;
            const value = _.get(props, 'value', null);

            if (_.isEmpty(value)) {
                initialDate = {
                    value: null,
                    startDate: '',
                };
            }

            if (value !== null) {
                const date = resolveDateFormat(value);

                // Asetetaan lähtöarvo vain jos päivämäärä on validi
                if (moment(date).isValid()) {
                    initialDate = {
                        value: date.toDate(),
                        startDate: date.format(_dateFormat),
                    };
                }
            }

            this.setState({
                ...initialDate,
                endDate: Infinity,
            });
        } else if (this.hasFixedEndDateValue()) {
            const value = _.get(props, 'value', '');
            const start = _.get(value, 'start', null);

            if (start !== null) {
                const startDate = resolveDateFormat(start);

                // Asetetaan lähtöarvo jos päivämääärä on validi
                if (startDate.isValid()) {
                    this.setState({
                        value: {
                            start: startDate.toDate(),
                            end: Infinity,
                        },
                        startDate: startDate.format(_dateFormat),
                    });
                }
            }
        } else {
            // Tutkitaan löytyykö aloitus- ja lopetuspäivämäärät
            const value = _.get(props, 'value', '');
            const start = _.get(value, 'start', null);
            const end = _.get(value, 'end', null);
            const nullAllowedInEnd = _.get(props, 'isNullAllowedInEnd', false);

            // Jos sallitaan null endissä, niin asetetaan vaan start.
            if (nullAllowedInEnd && start !== null && end === null) {
                const startDate = resolveDateFormat(start);
                if (startDate.isValid()) {
                    this.setState({
                        value: {
                            start: startDate.toDate(),
                            end: Infinity,
                        },
                        startDate: startDate.format(_dateFormat),
                    });
                }
            }

            if (start !== null && end !== null) {
                const startDate = resolveDateFormat(start);
                const endDate = resolveDateFormat(end);

                // Asetetaan lähtöarvot vain jos päivämäärät ovat valideja ja alkupäivämäärä on ennen loppupvm:ää tai sama pvm
                if (moment(startDate).isValid() && moment(endDate).isValid() && (startDate.isBefore(endDate) || startDate.isSame(endDate))) {
                    this.setState({
                        value: {
                            start: startDate.toDate(),
                            end: endDate.toDate(),
                        },
                        startDate: startDate.format(_dateFormat),
                        endDate: endDate.format(_dateFormat),
                    });
                }
            }
        }
    }

    componentWillUnmount() {
        this.removeEventListener();
    }

    /**
     * Jos klikataan / täpätään johonkin dokumentissa siirrytään tutkimaan osuttiinko tähän
     * komponenttiin vai sen ulkopuolelle.
     */
    addEventListener = () => {
        document.addEventListener('click', this.onClickOutside, false);
        window.addEventListener('resize', this.onResize, false);
    };

    /**
     * Kun kalenteri suljetaan ei ole syytä kuunnella enää eventtejä.
     */
    removeEventListener = () => {
        document.removeEventListener('click', this.onClickOutside, false);
        window.removeEventListener('resize', this.onResize, false);
    };

    /**
     * Kun ikkunan kokoa muutetaan tutkitaan mahtuuko toinen kalenteri enää näyttöön.
     * Syynä se että joissain tilanteissa voi kalenteri jäädä esim. headerin alle.
     */
    onResize = () => {
        const minWidthMatches = matchMedia(`(min-width: 688px)`).matches;
        this.setState({
            maxNumberOfCalendars: minWidthMatches ? 2 : 1,
        });
    };

    /**
     * Kun on klikattu / täpätty tämän komponentin ulkopuolelle, suljetaan kalenteri vain
     * jos on osuttu komponentin ulkopuolelle. Tässäpä ei aiemmin oikein toiminut 3rd party
     * kikkare niin piti tehdä oma...
     * @param event
     */
    onClickOutside = (event) => {
        if (this.node && this.node.contains(event.target)) {
            return;
        }

        this.closeDatePicker();
    };

    hasFixedEndDateValue = () => this.props.fixedEndDateValue !== '';
    hasSingleInput = () => this.props.hasSingleInput;

    leftPad = (str) => {
        const num = parseInt(str, 10);
        if (isNaN(num)) return null;

        return num < 10 ? `0${num}` : num;
    };

    /**
     * Muutetaan ISO-stringiksi.
     * Testaa ensiksi onko annettu vähintään 8 merkkiä (1.1.2019) ja onko pvm validi.
     * @param dateStr
     * @returns {any}
     */
    convertToMoment(dateStr) {
        let rebuiltDateStr = null;

        // Tee parempi kun rantaudutaan Suomen ulkopuolelle.
        const datePieces = dateStr.split('.');
        if (datePieces.length === 3) {
            const day = this.leftPad(datePieces[0]);
            const month = this.leftPad(datePieces[1]);
            const year = this.leftPad(datePieces[2]);

            rebuiltDateStr = `${day}.${month}.${year}`;
        }
        const date = dateStr.length >= 8
            ? moment(rebuiltDateStr, _dateFormat, true)
            : resolveDateFormat(dateStr);

        return date.isValid() ? date.format('YYYY-MM-DD') : dateStr;
    }

    /**
     * Muutetaan vain joko yksittäisen kentän arvoa tai annetaan sekä alku- että
     * loppupäivämäärät riippuen siitä näytetäänkö vain yksi päivämääräkenttä.
     */
    changeDates = () => {
        let values;

        const startDateValue = this.convertToMoment(this.startDateInput.value);

        if (this.hasSingleInput()) {
            values = startDateValue;
        } else {
            const endDateValue = this.convertToMoment(this.endDateInput.value);

            values = {
                start: startDateValue,
                end: this.hasFixedEndDateValue() ? Infinity : endDateValue,
            };
        }

        this.props.onChange(values);
    };

    /**
     * Joko moment range tai yksittäinen moment-objekti,
     * riippuen siitä onko fixedEndDateValue annettu.
     * @param values
     * @param states
     */
    onDateSelect = (values, states) => {
        const startDate = (this.hasSingleInput() || this.hasFixedEndDateValue()) ? values : _.get(values, 'start', '');

        const state = {
            value: values,
            states,
            hasSelectedStartDate: false,
            isDatePickerOpen: false,
        };

        state.startDate = startDate.format(_dateFormat);

        if (!this.hasSingleInput() && !this.hasFixedEndDateValue()) {
            const endDate = _.get(values, 'end', '');
            state.endDate = endDate.format(_dateFormat);
        }

        this.setState(state);
        this.changeDates();
        this.startDateInput.blur();
        if (!this.hasSingleInput()) {
            this.endDateInput.blur();
        }
    };

    isDateInValidRange = (date) => {
        const {
            minimumDate,
            maximumDate,
        } = this.props;

        return moment.isMoment(date) && date.isValid()
            && date.year() >= 1970
            && (minimumDate === null || date.isSameOrAfter(moment(minimumDate).subtract(1, 'days')))
            && (maximumDate === null || date.isSameOrBefore(moment(maximumDate)));
    };

    getDates = () => {
        const startDateStr = this.state.startDate;

        const startDate = utils.convertToMoment(startDateStr);

        if (startDateStr.length >= 8 && this.isDateInValidRange(startDate)) {
            if (this.hasSingleInput() || this.hasFixedEndDateValue()) {
                return startDate;
            }

            const endDateStr = this.state.endDate;
            const endDate = utils.convertToMoment(endDateStr);
            if (endDateStr.length >= 8 && this.isDateInValidRange(endDate)) {
                return moment.range(startDate, endDate);
            }
        }

        return null;
    };

    getInputClass = (hasFocus = false) => classNames('DateRange__Input', { 'has-focus': hasFocus });

    getDateRanges = () => (
        this.props.dateRanges.length > 0
            ? this.props.dateRanges
            : this.props.unavailableDates
                .map((unavailableDate) => ({
                    state: 'unavailable',
                    range: moment.range(
                        moment(unavailableDate.start,).toDate(),
                        moment(unavailableDate.end).toDate(),
                    ),
                }))
                // Kalenteri vaatii ei valittavissa olevien aikavälien olevan aikajärjestyksessä jotta ne voidaan näyttää
                .sort((dateA, dateB) => dateA.range.start > dateB.range.start ? 1 : -1)
    );

    onStartDateFocus = () => {
        if (! this.props.readOnly) {
            this.setState({
                hasStartDateFocus: true,
                hasEndDateFocus: false,
            });
        }
    };

    /**
     * Kun aloituspäivämääräkentässä päästetään napista irti tutkitaan mikä se on.
     * Tutkitaan lähinnä siirtymistä kentästä toiseen nuolinäppäimillä.
     * @param event
     */
    onStartDateKeyUp = (event) => {
        event.stopPropagation();

        const caretPosition = this.startDateInput.selectionStart;

        // Siirrytään endDate-kenttään jos ollaan startDate-kentän alussa ja painetaan nuoli-oikea
        if (event.keyCode === 39
            && caretPosition === this.state.startDate.length
            && !this.hasSingleInput()
        ) {
            this.endDateInput.focus();
        }
    };

    /**
     * Kun aloituspäivämääräkentässä painetaan nappia tutkitaan mikä se on.
     * Esc (keycode 27) sulkee kalenterin.
     * Enter (keycode 13) siirtyy lopetuspäivämääräkenttään ellei käytetä yhtä päivämääräkenttään
     * jolloin suljetaan kalenteri.
     * @param event
     */
    onStartDateKeyDown = (event) => {
        if (event.keyCode === 27) {
            this.closeDatePicker();
        }

        if (event.keyCode === 13) {
            if (this.hasSingleInput() || this.hasFixedEndDateValue()) {
                this.startDateInput.blur();
                this.closeDatePicker();
            } else {
                this.endDateInput.focus();
            }
        }
    };

    /**
     * Aloituspäivämäärää muutetaan.
     * @param event
     */
    onStartDateChange = (event) => {
        event.stopPropagation();
        const startDate = event.target.value;

        this.setState({
            startDate,
        });
    };

    /**
     * Päättymispäiväkenttä saa fokuksen.
     */
    onEndDateFocus = () => {
        if (! this.props.readOnly) {
            this.setState({
                hasStartDateFocus: false,
                hasEndDateFocus: true,
            });
        }
    };

    /**
     * Avaa kalenterin ikonista.
     */
    onCalendarIconClick = () => {
        if (! this.props.readOnly) {
            this.openDatePicker();
        }
    };

    /**
     * Kun päättymispäivämääräkentässä painetaan nappia tutkitaan onko se Esc (27) tai Enter (13), jotka
     * molemmat sulkevat kalenterin ja päättävät päivämäärävalinnat.
     * @param event
     */
    onEndDateKeyUp = (event) => {
        const {
            startDate,
        } = this.state;

        event.stopPropagation();

        const caretPosition = this.endDateInput.selectionStart;

        if (event.keyCode === 27 || event.keyCode === 13) {
            this.endDateInput.blur();
            this.closeDatePicker();
        }

        // Siirrytään startDate-kenttään jos ollaan endDate-kentän alussa ja painetaan nuoli-vasen
        if (event.keyCode === 37 && caretPosition === 0) {
            this.startDateInput.focus();

            const caretPos = startDate.length;
            this.startDateInput.setSelectionRange(caretPos, caretPos);
        }
    };

    /**
     * Päättymispäivämäärää muutetaan.
     * @param event
     */
    onEndDateChange = (event) => {
        event.stopPropagation();
        const endDate = event.target.value;

        this.setState({
            endDate,
        });
    };

    openDatePicker = () => {
        this.addEventListener();

        this.setState({
            isDatePickerOpen: true,
        });
    };

    closeDatePicker = () => {
        this.removeEventListener();

        this.setState({
            hasStartDateFocus: false,
            hasEndDateFocus: false,
            isDatePickerOpen: false,
        });
    };

    onCloseDatePicker() {
        this.closeDatePicker();
    }

    /**
     * Renderöi aikarajaa valittaessa kahden päivämäärän kentän erottavan nuolen.
     * @returns {*}
     */
    renderArrow() {
        if (! this.props.hasSingleInput) {
            return (
                <div className="DateRange__ArrowContainer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                        className="DateRange__Arrow">
                        <line x1="4" y1="12" x2="20" y2="12"></line>
                        <polyline points="14 6 20 12 14 18"></polyline>
                    </svg>
                </div>
            );
        }
    }

    /**
     * Renderöi päättymispäivämääräkentän jos päättymispäivämäärällä ei ole esitäytettyä arvoa.
     * Muutoin renderöi annetun esitäytetyn arvon (esim. "Toistaiseksi").
     * @returns {*}
     */
    renderEndDate() {
        const {
            endDatePlaceholder,
            fixedEndDateValue,
            readOnly
        } = this.props;

        if (! this.hasFixedEndDateValue()) {
            return (
                <input
                    readOnly={readOnly}
                    type="text"
                    inputMode="numeric"
                    maxLength="10"
                    {...this.getAriaAttributes()}
                    className={this.getInputClass(this.state.hasEndDateFocus)}
                    placeholder={endDatePlaceholder}
                    onKeyUp={this.onEndDateKeyUp}
                    onFocus={this.onEndDateFocus}
                    onBlur={this.changeDates}
                    onChange={this.onEndDateChange}
                    value={this.state.endDate}
                    ref={(input) => { this.endDateInput = input; }}
                />
            );
        }

        return (
            <div className="DateRange__FixedValue u-text-truncate">
                {fixedEndDateValue}
                <input
                    type="hidden"
                    value={Infinity}
                    ref={(input) => { this.endDateInput = input; }}
                />
            </div>
        );
    }

    /**
     * Päättää renderöidäänkö päättymispäivämääräkenttä.
     * @returns {*}
     */
    renderEndDateRangeInput() {
        if (! this.props.hasSingleInput) {
            return (
                <div className="DateRange__InputContainer">
                    { this.renderEndDate() }
                </div>
            );
        }
    }

    /**
     * Kalenterin avaava ikoni.
     * @returns {*}
     */
    renderCalendarIcon() {
        return (
            <div
                tabIndex={-1}
                role="button"
                className="DateRange__CalendarIconContainer"
                onClick={this.onCalendarIconClick}
                onKeyUp={this.onCalendarIconClick}
            >
                <MDIcon
                    icon={ this.hasSingleInput() ? 'calendar_today' : 'date_range' }
                    modifierClass="DateRange__CalendarIcon u-padding-horizontal-tiny"
                />
            </div>
        );
    }

    renderDateRangePicker() {
        const {
            isDatePickerOpen,
            hasEndDateFocus,
        } = this.state;

        const {
            placement,
            minimumDate,
            maximumDate,
            initialDate,
            hasInitialFromValue,
        } = this.props;

        if (isDatePickerOpen) {
            const dateRangePickerArrowClass = classNames('DateRangePicker__Arrow', {
                'DateRangePicker__Arrow--StartDateFocus': !hasEndDateFocus && !this.hasSingleInput(),
                'DateRangePicker__Arrow--EndDateFocus': hasEndDateFocus,
            });

            return (
                <Popper
                    placement={placement}
                    modifiers={[
                        {
                            name: 'flip',
                            options: { allowedAutoPlacements: ['left', 'right'] },
                        },
                        {
                            name: 'preventOverflow',
                            options: { enabled: true }
                        }
                    ]}
                    strategy="fixed"
                >
                    {({ ref, style, placement, arrowProps }) => (
                        <div
                            className="c-popover c-popover--clear"
                            ref={ref}
                            style={style}
                            data-placement={placement}
                            data-arrow="true"
                        >
                            <DateRangePicker
                                locale={_locale}
                                firstOfWeek={1}
                                numberOfCalendars={ this.hasSingleInput() || this.hasFixedEndDateValue() ? 1 : this.state.maxNumberOfCalendars }
                                stateDefinitions={stateDefinitions}
                                dateStates={this.getDateRanges()}
                                defaultState="available"
                                onSelect={this.onDateSelect}
                                value={this.getDates()}
                                selectionType={ this.hasFixedEndDateValue() || this.hasSingleInput() ? 'single' : 'range' }
                                minimumDate={minimumDate}
                                maximumDate={maximumDate}
                                initialDate={initialDate}
                                initialFromValue={hasInitialFromValue}
                            />
                            <div
                                className={dateRangePickerArrowClass}
                                style={arrowProps.style}
                                ref={arrowProps.ref}
                            />
                        </div>
                    )}
                </Popper>
            );
        }
    }

    render() {
        const {
            startDatePlaceholder,
            readOnly,
            isRequired,
        } = this.props;

        const dateRangeClass = classNames('DateRange', {
            'DateRange--Single': this.hasSingleInput() || this.hasFixedEndDateValue(),
            'DateRange--StartDateSelected': this.state.hasSelectedStartDate,
        });

        const id = this.props.name || this.props.id;

        // TODO pitää jotenkin validoita, ettei voi syöttää käsin alle / yli annetun pvm rajan
        return (
            <Manager className="u-display-inline-block">
                <div
                    className={dateRangeClass} ref={(node) => { this.node = node; }}
                    role="group"
                    aria-labelledby={`${id}Label`}
                >
                    <Reference>
                        {({ ref }) => (
                            <div className="DateRange__InputContainer" key="startDateRangeInput" ref={ref}>
                                <input
                                    required={isRequired}
                                    readOnly={readOnly}
                                    type="text"
                                    id={id}
                                    inputMode="numeric"
                                    maxLength="10"
                                    {...this.getAriaAttributes()}
                                    /* eslint-disable-next-line jsx-a11y/no-autofocus */
                                    autoFocus={this.props.hasStartDateFocus}
                                    className={this.getInputClass(this.state.hasStartDateFocus)}
                                    placeholder={startDatePlaceholder}
                                    onKeyUp={this.onStartDateKeyUp}
                                    onKeyDown={this.onStartDateKeyDown}
                                    onFocus={this.onStartDateFocus}
                                    onBlur={this.changeDates}
                                    onChange={this.onStartDateChange}
                                    value={this.state.startDate}
                                    ref={(input) => { this.startDateInput = input; }}
                                />
                            </div>
                        )}
                    </Reference>
                    {this.renderArrow()}
                    {this.renderEndDateRangeInput()}
                    {this.renderCalendarIcon()}
                    {!readOnly && this.renderDateRangePicker()}
                </div>
            </Manager>
        );
    }
}
