import React from 'react';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Value } from 'shared/components/Value';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';

const Dimensions = () => {
    const dimensionTypes = useSelector(select.contract.getDimensionTypes);

    if (dimensionTypes.length === 0) {
        return (
            <div>
                Dimensioita ei löytynyt.
            </div>
        );
    }

    return dimensionTypes.map((dimensionType, key) => (
        <SummaryListItem term={dimensionType.type} key={key}>
            <Value>{[dimensionType.value, dimensionType.description].filter(Boolean).join(' - ')}</Value>
        </SummaryListItem>
    ));
};

export default Dimensions;
