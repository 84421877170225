import PropTypes from 'prop-types';

const userShapes = {
    MINIMAL: PropTypes.shape({
        email: PropTypes.string
    }),

    MEDIUM: PropTypes.shape({
        picture_path: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phoneNumber: PropTypes.string
    }),

    COMMUNE: PropTypes.shape({
        email: PropTypes.string,
        firstName: PropTypes.string,
        fullName: PropTypes.string,
        initials: PropTypes.string,
        isAccountNonLocked: PropTypes.bool,
        isCurrentUser: PropTypes.bool,
        isSalaryBlocked: PropTypes.bool,
        type: PropTypes.number,
        userId: PropTypes.number,
    })
};

export default userShapes;
