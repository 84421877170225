import { blocks as blockTypes } from 'shared/ReForm/constants/blocks';
import { inputTypes } from 'shared/ReForm/constants/inputTypes';
import { customComponentNames } from 'ContractV3/constants/customComponents';

/**
 * Löytyykö palikkatyyppi sallituista.
 * @param block
 * @param type
 * @param componentName
 * @returns {boolean|boolean}
 */
export const isBlockValid = ({ block, type, componentName }) => (
    // block=NN
    Object.values(blockTypes).includes(block)
    // block="input" type=NN component=NN
    || (block === blockTypes.INPUT && (! Object.values(inputTypes).includes(type) || ! customComponentNames.includes(componentName)))
);
