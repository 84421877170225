import api from 'api';

export const userAccessToDimensionRelations = {
    state: [],
    reducers: {
        set: (state, relations) => relations,
    },
    selectors: {
        getRelations: (state) => state,
    },
    effects: () => ({
        async fetchRelations() {
            try {
                const json = await api.get('/api/v2/user-access-to-dimension-relations');
                this.set(json.data ?? []);
            } catch (e) {
                return e;
            }
        },
        async fetchUserRelations(user) {
            try {
                const json = await api.get(`/api/v2/user/${user}/user-access-to-dimension-relations`);
                this.set(json.data ?? []);
            } catch (e) {
                return e;
            }
        },
        async postUserRelations(user, rootState, model) {
            try {
                return await api.post(`/api/v2/user/${user}/user-access-to-dimension-relations`, model);
            } catch (e) {
                return e;
            }
        },
    }),
};
