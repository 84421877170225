import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { ContractInfo } from './../components/ContractInfo';
import { ContractSalary } from './../components/ContractSalary';
import { ContractDuration } from './../components/ContractDuration';
import { Button, Popover } from 'shared/components';

export const ContractPopover = ({ isEmployer, data }) => {
    const isAssignmentContract = useSelector((state) => select.weeklyHoursStatement.isAssignmentContract(state, data.jobContractId));
    const isCompanyContract = useSelector((state) => select.weeklyHoursStatement.isCompanyContract(state, data.jobContractId));
    const isCommune = useSelector(select.userMetadata.isCommune);
    const name = isEmployer
        ? data.contract.employeeDetails.fullName
        : data.contract.employerDetails.fullName;

    return (
        <div className="u-align-middle">
            <span>{name}</span>
            <Popover position="right">
                <Button
                    flat
                    size="small"
                    mdIcon="help_outline"
                    iconSize="small"
                    modifierClass="u-align-middle"
                    ariaLabel={_trans('Näytä sopimuksen tiedot', {}, 'extract')}
                />
                <div>
                    <ContractInfo
                        collectiveAgreementName={data.collectiveAgreementName}
                        employerName={data.contract.employerDetails.fullName}
                        employerId={data.contract.employerDetails.userId}
                        isCommune={isCommune}
                    />
                    <ContractDuration
                        contractObject={data}
                        isAssignmentContract={isAssignmentContract}
                        isCompanyContract={isCompanyContract}
                        isCommune={isCommune}
                    />
                    <ContractSalary
                        salaryType={data.salaryType}
                        salary={data.salary}
                        isAssignmentContract={isAssignmentContract}
                    />
                </div>
            </Popover>
        </div>
    );
};

ContractPopover.propTypes = {
    isEmployer: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
};
