import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sizes } from 'shared/constants';

function MDIcon({
    icon,
    isFilled,
    title,
    size,
    onClick,
    modifierClass,
    ariaLabel,
    focusable ,
}) {
    const iconClass = classNames(modifierClass, {
        'material-icons': isFilled,
        'material-icons-outlined': !isFilled,
        'c-md-icon--tiny': size === sizes.TINY,
        'c-md-icon--small': size === sizes.SMALL,
        'c-md-icon--medium': size === sizes.MEDIUM,
        'c-md-icon--large': size === sizes.LARGE,
        'c-md-icon--huge': size === sizes.HUGE,

        'is-focusable': focusable === true,
    });

    return (
        <i
            className={iconClass}
            onClick={onClick}
            title={title}
            aria-hidden="true"
            aria-label={ariaLabel}
            // Fokusointia käytetään Tooltipin yhteydessä
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={focusable ? 0 : undefined}
        >
            {icon}
        </i>
    );
}

MDIcon.propTypes = {
    // For icons see: https://material.io/icons/
    icon: PropTypes.string.isRequired,
    isFilled: PropTypes.bool,
    title: PropTypes.string,
    size: PropTypes.oneOf(['', sizes.TINY, sizes.SMALL, sizes.MEDIUM, sizes.LARGE, sizes.HUGE]),
    onClick: PropTypes.func,

    /**
     * CSS-muokkausluokka.
     */
    modifierClass: PropTypes.string,

    /**
     * Voidaanko ikoniin fokusoida esim. jos ikoni on tooltip.
     */
    /* eslint-disable react/boolean-prop-naming */
    focusable: PropTypes.bool,

    /**
     * Ruudunlukijoita varten. Jos käytät pelkkää ikonia ilman kontekstia kerro mitä tämä ikonia kuvaa.
     */
    ariaLabel: PropTypes.string,
};

MDIcon.defaultProps = {
    title: null,
    size: '',
    isFilled: false,
    onClick() {},
    modifierClass: '',
    focusable: false,
    ariaLabel: null,
};

export default MDIcon;
