import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { customCellTypes } from '../customCellTypes';

/**
 * Formatoi sarakkeen tyypin mukaan.
 * @param value
 * @param defaultValue
 * @param type
 * @returns {string|*}
 */
const formatValueByType = (value, defaultValue, type) => {
    switch (type) {
        case customCellTypes.CURRENCY:
            // Fallback 0, jos ei löydy, muuten tulisi epäluku. Jos tyhjä string, niin käytetään oletus arvoa.
            return value === undefined ? defaultValue : _currency(value ?? 0);

        case customCellTypes.DATE:
            return _toLocaleDate(value, defaultValue ?? '');

        case customCellTypes.DATETIME:
            return _toLocaleDate(value, defaultValue ?? '', _dateTimeFormat);

        case customCellTypes.NUMBER:
            // Fallback 0, jos ei löydy, muuten tulisi epäluku.
            return _numberFormat(value ?? 0);

        // Oletuksena palautetaan muuttumaton sisältö.
        default:
            return value;
    }
};

/**
 * Rikastaa sisältöä annetun tyypin mukaisesti.
 * Esim. type="link" käärii sisällön a-tagiin käyttäen
 * href-attribuutissa Cell:ssä annettua arvoa.
 *
 * Hoitaa myös filtteröinnin.
 * @param cell
 * @param row
 * @param filterValue
 * @constructor
 */
export const CellRenderer = ({ cell, row, filterValue }) => {
    const Cell = cell.render('Cell');
    const column = cell.column;
    const type = column?.type;
    const defaultValue = column?.defaultValue;
    const value = row.values[column.id];

    const formattedValue = formatValueByType(value, defaultValue, type);
    const hasFilter = typeof filterValue === 'string' && filterValue.length > 0 && typeof value === 'string' && value.length > 0;
    const cellContent = hasFilter ?
        (
            <Highlighter
                searchWords={[filterValue]}
                autoEscape={true}
                textToHighlight={formattedValue}
            />
        )
        : formattedValue;

    switch (type) {
        case customCellTypes.CURRENCY:
        case customCellTypes.DATE:
        case customCellTypes.DATETIME:
        case customCellTypes.NUMBER:
            return cellContent;

        case customCellTypes.LINK:
            return <a href={column.route(row)}>{cellContent}</a>;

        case customCellTypes.ROUTER_LINK:
            return <Link to={column.route(row)}>{cellContent}</Link>;

        // Oletuksena palautetaan muuttumaton sisältö.
        default:
            return Cell;
    }
};

CellRenderer.propTypes = {
    cell: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    filterValue: PropTypes.string.isRequired,
};
