import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { translateContractType } from 'shared/constants/contractTypes';
import { Value } from 'shared/components/Value';
import { parseCustomTranslations } from 'shared/ReForm/utils/parseCustomTranslations';

/**
 * Valittu sopimustyyppi.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ContractType = (props) => {
    const { value, attributes: { translations } } = props;

    // Vedetään kustomkäännökset listasta objekteja => objektiksi jolloin arvo saadaan suoraan keyllä
    const options = useMemo(() => parseCustomTranslations(translations), [translations]);

    const hasCustomTranslator = Array.isArray(translations) && translations.length > 0;
    const customTranslator = (type) => type in options ? options[type] : _trans('Tuntematon', {}, 'common');
    const label = hasCustomTranslator
        ? customTranslator(value)
        : translateContractType(value);

    return (
        <Value>
            {label}
        </Value>
    );
};

ContractType.propTypes = {
    value: PropTypes.string.isRequired,
    attributes: PropTypes.object.isRequired,
};

export default ContractType;
