import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { actions } from 'react-redux-form/lib/immutable';
import { ChoiceSelectType } from 'shared/components';
import { StField, StLabel } from 'shared/components/StForm';
import validators from 'shared/validators';
import { locations } from 'shared/constants/tesVariableKeys';
import _transObj from 'shared/utils/_transObj';

const optionsShape = PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
}));

const resolveChoices = (props) => {
    const { options, modelNameResolver } = props;
    return options.map((option) => ({
        ...option,
        model: modelNameResolver(option.name),
        label: _trans(option.label),
        options: resolveOptions(option, props),
    }));
};

const resolveOptions = (select, props) => {
    const { dispatch, values, modelNameResolver, salaryType, area, workExpValue, caredAge, tableSalaryDate } = props;
    // Jos ei oo parenttia, niin on rootti (=päätaso)
    if (! select.parent) {
        let rootSelectOptions = _.get(select, 'options', []);
        if (_.some(rootSelectOptions, (option) => _.has(option, 'salaryType')) && !! salaryType) {
            // Salary typejen filtteröintiä. Filtteröidään vain sallitut typet, esim. kuukausipalkka vain kuukausipalkalla
            rootSelectOptions = rootSelectOptions.filter((option) =>
                _.get(option, 'salaryType', []).includes(parseInt(salaryType, 10))
            );
        }

        if (rootSelectOptions.some((option) => Object.values(locations).includes(option.value ?? null)) && !! area) {
            // Alueiden typejen filtteröintiä. Filtteröidään vain sallitut typet
            rootSelectOptions = rootSelectOptions.filter((option) => (option.value ?? null) === area);
        }

        // Myöskin jos päätasolla on vain yksi, niin valitaan automatically
        if (rootSelectOptions.length === 1) {
            dispatch(actions.change(modelNameResolver(select.name), _.get(_.head(rootSelectOptions), 'value')));
        }

        return translateOptions(rootSelectOptions);
    }
    const parentValue = _.get(values, select.parent, null);
    //Tämän selectin value
    const thisValue = _.get(values, select.name);
    // Ei oo valittua arvoa parentilla, nin ei palauteta mitään vaihtoehtoja
    if (! parentValue) {
        if (thisValue && select.name !== 'table_salary_work_experience') {
            // Resetoidaan myöskin tämä value, jos löytyy value, ettei jää alempien steppien valueita
            dispatch(actions.change(modelNameResolver(select.name), ''));
        }
        return [];
    }
    const optionsMaps = _.get(select, 'optionsMap', []);
    // Haetaan valinnat parentin valuen mukaan
    const optionMap = _.find(optionsMaps, (option) => _.includes(option.trigger, String(parentValue)));
    if (thisValue && ! _.includes(
        // Mapataan pelkät valuet, jotta voidaan tarkistella helpommin.
        _.get(optionMap, 'options', []).map((option) => _.get(option, 'value')),
        String(thisValue)
    )) {
        // Ei voi olla kyseinen arvo, koska muutettiin ylemmällä tasolla, niin resetoidaan tämä value.
        dispatch(actions.change(modelNameResolver(select.name), ''));
    }
    let selectOptions = _.get(optionMap, 'options', []);

    // Jos löytyy yhdeltäkin optiolta salaryType sekä annettu salaryType, filtteröidään sillä
    if (_.some(selectOptions, (option) => _.has(option, 'salaryType')) && !! salaryType) {
        // Salary typejen filtteröintiä. Filtteröidään vain sallitut typet, esim. kuukausipalkka vain kuukausipalkalla
        selectOptions = selectOptions.filter((option) =>
            _.get(option, 'salaryType', []).includes(parseInt(salaryType, 10))
        );
    }

    selectOptions = selectOptions.filter((option) =>
        option.effectiveDate && tableSalaryDate
            ? new Date(tableSalaryDate) >= new Date(option.effectiveDate)
            : true
    );
    selectOptions = selectOptions.filter((option) =>
        option.expireDate && tableSalaryDate
            ? new Date(tableSalaryDate) <= new Date(option.expireDate)
            : true
    );
    // Spesialiteetti: jos work exp valinta filtteröidään annetuilla kuukausilla, jolloin löytyy yks vaihtoehto vain
    if (select.name === 'table_salary_work_experience') {

        // Konkatti + pop = ottaa vimisimmän lapsen arraysta.
        const newValue = [].concat(selectOptions.filter((option) => option.value <= workExpValue)).pop();
        // Jos ei voitu resolvoida tuota oikeata tasoa, niin jätetään alkuperäiset arvot näkyville
        selectOptions = newValue ? [newValue] : selectOptions;
    }
    if (!! area && selectOptions.some((option) => Object.values(locations).includes(option?.value ?? null))) {
        // Alueiden typejen filtteröintiä. Filtteröidään vain sallitut typet
        selectOptions = selectOptions.filter((option) => (option.value ?? null) === area);
    }

    // Specialiteetti osa 999: oph:ssa kulukorvaus voidaan määritellä hoidettavan iän perusteella
    if (caredAge && selectOptions.every((opt) => opt.maxAge !== undefined && opt.minAge !== undefined)) {
        const ageOpts = selectOptions.filter((opt) => caredAge >= opt.minAge && caredAge <= opt.maxAge);
        // Filtteröinti käyttöön vain jos löytyi jokin, muuten näytetään kaikki
        selectOptions = ageOpts.length > 0 ? ageOpts : selectOptions;
    }

    if (selectOptions.length === 1) {
        dispatch(actions.change(modelNameResolver(select.name), _.get(_.head(selectOptions), 'value')));
    }
    return translateOptions(selectOptions);
};

// Ajetaan labelit translaattorin läpi
const translateOptions = (options) => options.map((option) => ({ ...option, label: _trans(option.label) }));


export default class ChoiceChainSelection extends Component {
    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            options: optionsShape,
            optionsMap: PropTypes.arrayOf(PropTypes.shape({
                options: optionsShape,
                trigger: PropTypes.arrayOf(PropTypes.string),
            }))
        })).isRequired,
        dispatch: PropTypes.func,
        values: PropTypes.object,
        modelNameResolver: PropTypes.func,
        onChange: PropTypes.func,
        isRequired: PropTypes.bool,
        isDisabled: PropTypes.bool,
        salaryType: PropTypes.number.isRequired,
        area: PropTypes.string,
        workExpValue: PropTypes.number,
        tableSalaryDate: PropTypes.string,
        isUseTransObjAsLabel: PropTypes.bool,
    };

    static defaultProps = {
        dispatch() {},
        values: {},
        modelNameResolver: (name) => name,
        onChange() {},
        isRequired: false,
        isDisabled: false,
        area: null,
        workExpValue: 0,
        tableSalaryDate: '',
        isUseTransObjAsLabel: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            selects: resolveChoices(props),
            values: props.values,
            salaryType: props.salaryType,
            tableSalaryDate: props.tableSalaryDate,
        };
    }

    static getDerivedStateFromProps(nextProps, state) {
        // Vertaillaan arvoja, jos löytyy samat, eipä oo järkeä päivitellä. (aiheuttaa ikiluupin)
        if (! _.isEqual(nextProps.values, state.values) || nextProps.salaryType !== state.salaryType || nextProps.tableSalaryDate !== state.tableSalaryDate) {
            return {
                selects: resolveChoices(nextProps), //Resolvoidaan tähän selectit
                values: nextProps.values,
                salaryType: nextProps.salaryType,
                tableSalaryDate: nextProps.tableSalaryDate,
            };
        }
        return null;
    }

    render() {
        const { isRequired, onChange, isDisabled, values, isUseTransObjAsLabel } = this.props;
        return (
            <div>
                {this.state.selects.map((select, index) => {
                    const optionCount = select.options.length;

                    // Ei renderöidä mitään, jos ei löydy optioita.
                    if (optionCount === 0) return null;

                    // Jos optioita tasan yksi, niin turha renderöidä mitään inputteja, vaan valitaan suoraan value.
                    if (optionCount === 1) {
                        return (
                            <Fragment key={index}>
                                <StLabel>
                                    {isUseTransObjAsLabel ? _transObj(select.label) : _trans(select.label)}
                                </StLabel>
                                <StField>
                                    <strong>{isUseTransObjAsLabel ? _transObj(select.options[0].label) : _trans(select.options[0].label)}</strong>
                                </StField>
                            </Fragment>
                        );
                    }

                    return (
                        <ChoiceSelectType
                            onChange={(value) => onChange(value, select.model, values, select)}
                            key={index}
                            model={select.model}
                            label={isUseTransObjAsLabel ? _transObj(select.label) : _trans(select.label)}
                            options={isUseTransObjAsLabel ? select.options.map(({ label, ...rest }) => ({ ...rest, label: _transObj(label) })) : select.options}
                            validators={isRequired ? {
                                isRequired: validators.isRequired,
                            } : {}}
                            isRequired={isRequired}
                            isDisabled={isDisabled}
                        />
                    );
                })}
            </div>
        );
    }
}
