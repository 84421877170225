import React, { useRef, useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { usePopper } from 'react-popper';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Button,
} from 'shared/components';
import { useOnClickOutside } from 'shared/hooks/useOnClickOutside';
import { MonthSpanPicker } from 'shared/components/MonthSpanInput/components/MonthSpanPicker';
import { MONTH_YEAR_FORMAT, YEAR_MONTH_FORMAT, YEAR_MONTH_DAY_FORMAT } from 'shared/constants/formats';

const convertToYearMonthDay = (value) => {
    if (! value) return '';

    const pcs = value.split('/');
    if (pcs.length !== 2 || (pcs.length === 2 && pcs[1].length !== 4)) {
        return '';
    }
    const date = new Date(pcs[1], pcs[0] - 1);
    const monthAndYear = moment(date);
    return monthAndYear.isValid()
        ? monthAndYear.format(YEAR_MONTH_DAY_FORMAT)
        : '';
};

const convertToMonthYear = (value) => {
    if (! value) return '';

    const monthAndYear = moment(value, YEAR_MONTH_FORMAT);
    return monthAndYear.isValid()
        ? monthAndYear.format(MONTH_YEAR_FORMAT)
        : '';
};

/**
 * Renderöi aloitus- ja päättymiskuukausikentät.
 * TODO: kuukausien valinta hiirellä
 */
export const MonthSpanInput = (props) => {
    const {
        ariaLabelledBy,
        hasSingleInput,
        onChange,
        isReadOnly,
        fixedEndMonthValue,
        minDate,
        maxDate,
        unavailableMonthSpans,
        placement,
        hasMonthNames,
        minimumMonth,
    } = props;

    const [isMonthPickerOpen, setMonthPickerOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            {
                name: 'arrow',
                options: { element: arrowElement }
            },
            {
                name: 'flip',
                options: { allowedAutoPlacements: ['left', 'right'] },
            },
            {
                name: 'preventOverflow',
                options: { enabled: true }
            }
        ],
        strategy: 'fixed'
    });

    const ref = useRef(null);

    useOnClickOutside(ref, () => {
        setMonthPickerOpen(false);
    });

    const hasFixedEndDateValue = () => fixedEndMonthValue !== '';

    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [hasStartMonthFocus, setStartMonthFocus] = useState(props.hasStartMonthFocus);
    const [hasEndMonthFocus, setEndMonthFocus] = useState(props.hasEndMonthFocus);
    const [disabledRanges, setDisabledRanges] = useState([]);

    const onCalendarIconClick = () => setMonthPickerOpen(true);

    const changeDates = () => {
        onChange(hasSingleInput
            ? convertToYearMonthDay(start)
            : {
                start: convertToYearMonthDay(start),
                end: convertToYearMonthDay(end),
            }
        );
    };

    useEffect(() => {
        if (hasSingleInput) {
            const start = convertToMonthYear(props.value);
            if (start !== '') {
                setStart(start);
            }
        } else {
            const start = convertToMonthYear(props.value?.start);
            const end = convertToMonthYear(props.value?.end);

            if (start !== '' && end !== '') {
                setStart(start);
                setEnd(end);
            }
        }
    }, [hasSingleInput, props.value]);

    // Halutut kuukaudet disabloiduiksi
    useEffect(() => {
        const disabledRanges = unavailableMonthSpans.map((unavailableMonthSpan) => {
            const start = moment(unavailableMonthSpan.start, YEAR_MONTH_FORMAT);
            const end = moment(unavailableMonthSpan.end, YEAR_MONTH_FORMAT);

            return moment.range(start, end);
        });
        setDisabledRanges(disabledRanges);
    }, [unavailableMonthSpans]);

    const minMonthOfSelectableRange = minDate
        ? moment(minDate, YEAR_MONTH_FORMAT).toDate()
        : null;

    const maxMonthOfSelectableRange = maxDate
        ? moment(maxDate, YEAR_MONTH_FORMAT).toDate()
        : null;

    const dateRangeClass = classNames('DateRange', {
        'DateRange--Single': hasSingleInput || hasFixedEndDateValue(),
        'DateRange--StartDateSelected': false,//hasSelectedStartDate,
    });

    return (
        <div className={dateRangeClass} ref={ref}>
            <div className="DateRange__InputContainer--Month" key="startDateRangeInput">
                <input
                    id={props.name || props.id}
                    aria-labelledby={ariaLabelledBy}
                    readOnly={isReadOnly}
                    type="text"
                    inputMode="numeric"
                    maxLength="10"
                    /* eslint-disable-next-line jsx-a11y/no-autofocus */
                    autoFocus={hasStartMonthFocus}
                    className={classNames('DateRange__Input', { 'has-focus': hasStartMonthFocus })}
                    // placeholder={startDatePlaceholder}
                    /*onKeyUp={onStartMonthKeyUp}
                    onKeyDown={onStartMonthKeyDown}*/
                    onFocus={() => {
                        setMonthPickerOpen(false);
                        setStartMonthFocus(true);
                        setEndMonthFocus(false);
                    }}
                    onBlur={changeDates}
                    onChange={(event) => setStart(event.target.value)}
                    value={start}
                    // ref={(input) => { this.startDateInput = input; }}
                />
            </div>

            {! hasSingleInput && (
                <Fragment>
                    <div className="DateRange__ArrowContainer">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className="DateRange__Arrow"
                        >
                            <line x1="4" y1="12" x2="20" y2="12"/>
                            <polyline points="14 6 20 12 14 18"/>
                        </svg>
                    </div>
                    <div className="DateRange__InputContainer--Month">
                        <input
                            readOnly={isReadOnly}
                            type="text"
                            inputMode="numeric"
                            maxLength="10"
                            className={classNames('DateRange__Input', { 'has-focus': hasEndMonthFocus })}
                            // placeholder={endDatePlaceholder}
                            // onKeyUp={this.onEndDateKeyUp}
                            onFocus={() => {
                                setMonthPickerOpen(false);
                                setStartMonthFocus(false);
                                setEndMonthFocus(true);
                            }}
                            onBlur={changeDates}
                            onChange={(event) => setEnd(event.target.value)}
                            value={end}
                            // ref={(input) => { this.endDateInput = input; }}
                        />
                    </div>
                </Fragment>
            )}

            <div
                className="DateRange__CalendarIconContainer"
                ref={setReferenceElement}
            >
                <Button
                    flat
                    onClick={onCalendarIconClick}
                    mdIcon="calendar_view_month"
                    title={_trans('date_range.calendar.month_selection')}
                    modifierClass="DateRange__CalendarIcon u-padding-horizontal-tiny u-cursor-pointer"
                />
            </div>

            {isMonthPickerOpen && (
                <div
                    ref={setPopperElement}
                    style={{
                        ...styles.popper,
                        maxWidth: 280,
                        zIndex: 9,
                    }}
                    {...attributes.popper}
                    className="c-popper c-popover c-popover--clear"
                    data-placement={placement}
                    data-arrow="true"
                >
                    <MonthSpanPicker
                        value={hasSingleInput ? start : { start, end }}
                        disableRanges={disabledRanges}
                        onSelect={(value) => {
                            setMonthPickerOpen(false);
                            const start = moment(value.start);
                            const startDate = start.format(YEAR_MONTH_DAY_FORMAT);
                            setStart(moment(value.start).format(MONTH_YEAR_FORMAT));

                            if (! hasSingleInput) {
                                const end = moment(value.end);
                                const endDate = end.endOf('month').format(YEAR_MONTH_DAY_FORMAT);
                                setEnd(moment(value.end).format(MONTH_YEAR_FORMAT));

                                if (start.isValid() && end.isValid()) {
                                    onChange({
                                        start: startDate,
                                        end: endDate,
                                    });
                                }
                            } else if (start.isValid()) {
                                onChange(startDate);
                            }
                        }}
                        hasSingleInput={hasSingleInput}
                        hasMonthNames={hasMonthNames}
                        minimumMonth={minMonthOfSelectableRange || minimumMonth}
                        maximumMonth={maxMonthOfSelectableRange}
                    />
                    <div ref={setArrowElement} style={styles.arrow} className="c-popover__arrow" />
                </div>
            )}
        </div>
    );
};

MonthSpanInput.propTypes = {
    /**
     * Mikäli komponentilla ei ole suoraa labelia joka viittaa siihen voidaan
     * tässä antaa komponenttia kuvaava teksti (label).
     */
    ariaLabelledBy: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string,
        }),
        PropTypes.string,
    ]),
    hasSingleInput: PropTypes.bool,
    hasStartMonthFocus: PropTypes.bool,
    hasEndMonthFocus: PropTypes.bool,
    fixedEndMonthValue: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    unavailableMonthSpans: PropTypes.arrayOf(
        PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string,
        })
    ),
    onChange: PropTypes.func,
    isReadOnly: PropTypes.bool,
    placement: PropTypes.oneOf(['top', 'bottom']),
    hasMonthNames: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.string,
    minimumMonth: PropTypes.instanceOf(Date),
};

MonthSpanInput.defaultProps = {
    ariaLabelledBy: null,
    value: {
        start: '',
        end: '',
    },
    hasSingleInput: false,
    hasStartMonthFocus: false,
    hasEndMonthFocus: false,
    fixedEndMonthValue: _trans('text.indefinite'),
    /**
     * Jos rajoitat valittavia pvm:iä, niin käytä vain minDatea & maxDatea tai unavailableMonthSpanssia
     * eivät toimi yhdessä todennäköisesti mitenkään järkevästi.
     */
    minDate: undefined,
    maxDate: undefined,
    unavailableMonthSpans: [],
    onChange() {},
    isReadOnly: false,
    placement: 'top',
    hasMonthNames: false,
    name: undefined,
    id: undefined,
    minimumMonth: undefined,
};
