import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
/**
 * Natiivi alasvetovalikko muutamalla shortcutilla.
 * Nopeuttaa alasvetovalikkojen kasaamista erilaisten objektilistojen kanssa.
 */
const Dropdown = (props) => {
    const {
        optionValue,
        optionLabel,
        placeholder,
        isFullWidth,
        isDisabled,
        value,
        modifierClass,
        name,
    } = props;

    const renderOptions = (options, key) => (
        options.map((option, i) => {
            if (_.has(option, 'optGroup')) {
                // Jos löytyi
                return (
                    <optgroup label={option.optGroup} key={`${option.optGroup}${key + i}`}>
                        {renderOptions(option.options, i + 1)}
                    </optgroup>
                );
            } else {
                return (
                    <option
                        value={option[optionValue]}
                        key={key + i}
                        disabled={option.disabled ?? false}
                    >
                        {option[optionLabel]}
                    </option>
                );
            }
        })
    );

    let options = props.options;

    if (placeholder !== '') {
        options = [
            {
                [optionValue]: '',
                [optionLabel]: placeholder,
            },
            ...options,
        ];
    }

    const onChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        props.onChange(event.target.value);
    };

    // Oletuksena mennään natiivitoteutuksella
    return (
        <select
            name={name}
            onChange={onChange}
            onBlur={onChange}
            className={classNames(modifierClass, { 'u-1/1': isFullWidth, })}
            disabled={isDisabled}
            value={value !== null ? value : ''}
        >
            {renderOptions(options, 0)}
        </select>
    );
};

Dropdown.defaultProps = {
    /**
     * Valinnan arvo.
     */
    value: null,

    /**
     * Valinnan arvon avain.
     */
    optionValue: 'id',

    /**
     * Valinnan tekstin avain.
     */
    optionLabel: 'name',

    /**
     * Mitä tapahtuu kun tehdään valinta.
     */
    onChange() {},

    /**
     * Alasvetovalikon placeholder.
     */
    placeholder: '',

    /**
     * Onko valintojen lataus käynnissä.
     */
    isLoading: false,

    /**
     * Onko alasvetovalikko disabloitu.
     */
    isDisabled: false,

    /**
     * Venytetäänkö alasvetovalikko täysilevyiseksi.
     */
    isFullWidth: false,

    /**
     * Mahdollinen CSS-luokka
     */
    modifierClass: '',

    /**
     * Selectille asetettava name. Esim. ilman tätä ei voida scrollata virheeseen frontilla
     */
    name: '',
};

Dropdown.propTypes = {
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.number]),
    options: PropTypes.array.isRequired,
    optionValue: PropTypes.string,
    optionLabel: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    modifierClass: PropTypes.string,
    name: PropTypes.string,
};

export default Dropdown;
