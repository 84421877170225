import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import User from '../containers/User';
import Party from '../containers/Party';
import Beneficiary from '../containers/Beneficiary';
import userRoles from 'shared/constants/userRoles';
import { StLabel, StField } from 'shared/components/StForm';

function Parties({
    userRole,
    isSelfEmployed,
    isAssignmentContract,
}) {
    const employerLegend = _trans(`contract.form.parties.employer.legend`);
    const employeeLegend = _trans(`contract.form.parties.employee.legend`);
    return (
        <Fragment>
            {! isSelfEmployed && userRole === userRoles.COMMUNE_OPERATOR && (
                <Fragment>
                    <StLabel>
                        {_trans('role.beneficiary')}
                    </StLabel>
                    <StField>
                        <Beneficiary />
                    </StField>
                </Fragment>
            )}
            <StLabel id="employerLegend">
                {employerLegend}
            </StLabel>
            <StField ariaLabelledby="employerLegend">
                { userRole === userRoles.EMPLOYER && <User /> }
                { userRole === userRoles.COMPANY_SHADOW && <User /> }
                { userRole === userRoles.EMPLOYEE && <Party /> }
                { userRole === userRoles.COMMUNE_OPERATOR && <User /> }
            </StField>
            <StLabel id="employeeLegend">
                {employeeLegend}
            </StLabel>
            <StField ariaLabelledby="employeeLegend">
                { userRole === userRoles.EMPLOYEE && <User /> }
                { userRole === userRoles.EMPLOYER && <Party /> }
                { userRole === userRoles.COMPANY_SHADOW && <Party /> }
                { userRole === userRoles.COMMUNE_OPERATOR && <Party /> }
            </StField>

            {isAssignmentContract && (
                <Fragment>
                    <StLabel>
                        {_trans('Hoidossa oleva henkilö', {}, 'extract')}
                    </StLabel>
                    <StField>
                        <Party isCaredParty />
                    </StField>
                </Fragment>
            )}
        </Fragment>
    );
}

Parties.propTypes = {
    userRole: PropTypes.number.isRequired,
    isSelfEmployed: PropTypes.bool,
    isAssignmentContract: PropTypes.bool,
};

Parties.defaultProps = {
    isSelfEmployed: true,
    isAssignmentContract: false,
};

export default Parties;
