import { init } from '@rematch/core';
import selectPlugin from '@rematch/select';
import createLoadingPlugin from '@rematch/loading';
import { roleSelector } from './roleSelector';
import { header } from 'shared/containers/PageHeader/models/header';
import { notificationHub } from 'shared/models/notificationHub';

export const createStore = () => init({
    name: 'PageHeader',

    models: {
        header,
        notificationHub,
        roleSelector,
    },

    plugins: [
        selectPlugin(),
        createLoadingPlugin(),
    ],
});
