import React from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/Button';
import { confirm } from 'shared/utils';

const ConfirmButton = (props) => {
    const { isPending, confirmText, proceedLabel, isAlert, onClick, onCancel, text, ...rest } = props;
    return (
        <Button
            {...rest}
            inProgress={isPending}
            onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                confirm(confirmText, {
                    proceedLabel: proceedLabel !== '' ? proceedLabel : text,
                    cancelLabel: _trans('dialog.button.cancel'),
                    alert: isAlert,
                }).then(onClick, onCancel);
            }}
        >
            {text}
        </Button>
    );
};

ConfirmButton.defaultProps = {
    isPending: false,
    isAlert: true,
    onCancel() {},
    proceedLabel: '',
};

ConfirmButton.propTypes = {
    /**
     * What happens on confirm.
     */
    onClick: PropTypes.func.isRequired,
    /**
     * What happens on cancel.
     */
    onCancel: PropTypes.func,
    /**
     * Text on the confirm dialog.
     */
    confirmText: PropTypes.string.isRequired,
    /**
     * Text on the button.
     */
    text: PropTypes.string.isRequired,
    /**
     * Is the action pending.
     */
    isPending: PropTypes.bool,
    /**
     * Label of the proceed button on the dialog.
     */
    proceedLabel: PropTypes.string,
    /**
     * Should the confirm dialog be shown as alert dialog. Should be used in situations where
     * the confirmed action is irreversible, for example.
     */
    isAlert: PropTypes.bool,
};

export default ConfirmButton;
