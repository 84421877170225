import React, { Fragment } from 'react';
import { StField, StLabel } from 'shared/components/index';
import { Field } from 'formik';
import { FormikErrors } from 'shared/components/Formik/index';

const AccountNumber = () => (
    <Fragment>
        <StLabel>
            {_trans('userForm.accountNumber.label')}
        </StLabel>
        <StField>
            <Field type="text" name="accountNumber" className="u-1/1" />
            <FormikErrors name="accountNumber"/>
        </StField>
    </Fragment>
);

export default AccountNumber;
