import { feedbackSchema } from 'shared/ReForm/schemas/blocks/feedback.schema';
import { textSchema } from 'shared/ReForm/schemas/blocks/text.schema';
import { componentSchema } from 'shared/ReForm/schemas/blocks/component.schema';
import { blocks } from 'shared/ReForm/constants/blocks';
import { inputSchema } from 'shared/ReForm/schemas/blocks/input.schema';
import { arraySchema } from 'shared/ReForm/schemas/blocks/array.schema';
import { fieldsetSchema } from 'shared/ReForm/schemas/blocks/fieldset.schema';
import { mergeSchema } from 'shared/ReForm/utils/mergeSchema';
import { groupSchema } from 'shared/ReForm/schemas/blocks/group.schema';
import { pageBreakAfterSchema } from 'shared/ReForm/schemas/blocks/pageBreakAfter.schema';

/**
 * Rakentaa palikan schemaa varten. Vaatii että block oltava annettu predicate.
 * Jos toteutuu määritellään ko. blockin schema.
 */
const blockBuilder = (blockPredicate, blockSchema) => ({
    if: {
        properties: {
            block: blockPredicate,
        },
        required: ['block'],
    },
    then: blockSchema,
});

export const blockSchema = {
    type: 'object',
    title: 'Palikka',
    properties: {
        block: { enum: Object.values(blocks) },
    },
    required: ['block'],
    // Kaikki ehdot täyttyvät eli kunhan jokin näistä schemoista on validi.
    allOf: [
        blockBuilder({ const: blocks.GROUP }, mergeSchema(groupSchema)),
        blockBuilder({ const: blocks.FIELDSET }, mergeSchema(fieldsetSchema)),
        blockBuilder({ const: blocks.INPUT }, mergeSchema(inputSchema)),
        blockBuilder({ const: blocks.ARRAY }, mergeSchema(arraySchema)),
        blockBuilder({ const: blocks.FEEDBACK }, mergeSchema(feedbackSchema)),
        blockBuilder({ const: blocks.COMPONENT }, mergeSchema(componentSchema)),
        blockBuilder({ const: blocks.TEXT }, mergeSchema(textSchema)),
        // Tulostusta ohjaavat palikat eivät tarvi mitään erikoisia muita ominaisuuksia joten ei mergeillä
        blockBuilder({ const: blocks.PAGE_BREAK_AFTER }, pageBreakAfterSchema),
    ],
};

/**
 * Kaikki palikat.
 */
export const blocksSchema = {
    type: 'array',
    title: 'Palikkalista',
    items: {
        '$ref': '#/definitions/block'
    },
};

