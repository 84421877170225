import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import useDimensions from 'react-cool-dimensions';
import { ResizeObserver } from '@juggle/resize-observer';
import classNames from 'classnames';
import { sizes } from 'shared/constants';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Grid = (props) => {
    const {
        children,
        gutterSize,
        hasVerticalGutter,
        verticalAlign,
        width,
    } = props;

    const breakpoints = Object.assign({},
        props.breakpoints, {
            tiny: 320,
            small: 375,
            medium: 768,
            large: 1280,
            huge: 1440,
        });

    // Aseta annetut breakpointin suuruusjärjestykseen breakpointin leveyden mukaan
    const breakpointsByWidth = Object.entries(breakpoints).sort(([, aw], [, bw]) => {
        if (aw > bw) return 1;
        if (bw > aw) return -1;
        return 0;
    });

    const { ref: gridRef, width: containerWidth } = useDimensions({
        breakpoints,
        polyfill: ResizeObserver,
    });

    const comparisonWidth = width ? width : containerWidth;
    // Napataan breakpointeista se joka ylittää Grid-containerin leveyden
    const currentBreakpoint = Object.keys(breakpoints).reduce((prev, current) =>
        comparisonWidth >= breakpoints[current] ? current : prev,
    0);

    return (
        <div
            ref={gridRef}
            className={classNames('o-layout', {
                'o-layout--flush': gutterSize === sizes.NONE,
                'o-layout--tiny': gutterSize === sizes.TINY,
                'o-layout--small': gutterSize === sizes.SMALL,
                'o-layout--medium': gutterSize === sizes.MEDIUM,
                'o-layout--large': gutterSize === sizes.LARGE,
                'o-layout--huge': gutterSize === sizes.HUGE,

                'o-layout--top': verticalAlign === 'top',
                'o-layout--middle': verticalAlign === 'middle',
                'o-layout--bottom': verticalAlign === 'bottom',
                'o-layout--vertical-gutter': hasVerticalGutter,
            })}
        >
            {React.Children.map(children, (child) => React.cloneElement(child, {
                width: comparisonWidth,
                breakpointsByWidth,
                currentBreakpoint,
            }))}
        </div>
    );
};

Grid.propTypes = {
    children: PropTypes.node.isRequired,
    /**
     * Gutter size.
     */
    gutterSize: PropTypes.oneOf(_.map(sizes)),
    /**
     * Do columns have vertical gutter as well.
     */
    hasVerticalGutter: PropTypes.bool,
    /**
     * Column alignment.
     */
    verticalAlign: PropTypes.string,

    /**
     * Width breakpoints. Key can be anything but it is best to follow established terms: small, medium, large, huge...
     *
     * Value is width in pixels.
     *
     * NOTE: GridItem must have the same keys:
     * ```<Grid breakpoints={ small: 480, medium: 720 }>
     *     <GridItem small="1/2" medium="2/6">
     *         ...```
     */
    breakpoints: PropTypes.object,

    /**
     * Optional external width.
     */
    width: PropTypes.number,
};

Grid.defaultProps = {
    hasVerticalGutter: false,
    gutterSize: 'small',
    verticalAlign: 'top',
    breakpoints: {},
    width: undefined,
};

export default Grid;
