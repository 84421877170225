import { fromJS } from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import { push } from 'connected-react-router';
import api from 'api';
import { actions as notificationActions } from 'shared/stores/notifications';
import {
    insuranceStates,
    accidentInsuranceTypes,
    tyelInsuranceTypes,
    tyelInstitutionsTypes,
    translateTyelInsuranceType
} from 'shared/constants/index.js';

/**
 * Tapaturmavakuutuksen malli ja actionit.
 */
export default {
    name: 'insurances',

    state: fromJS({
        accidentInsurance: {},
        tyelInsurance: {},
    }),

    reducers: {
        setAccidentInsurance: (state, payload) => {
            const insurance = _.get(payload, 'insurance', {});

            const id = _.get(insurance, 'accidentInsuranceId', null);
            const contractId = _.get(payload, 'AccidentInsuranceContractId', null);
            let insuranceState = _.get(insurance, 'state', null);
            const insuranceType = _.get(insurance, 'type', null);
            const hasExpired = _.get(payload, 'hasExpired', false);
            const expireDate = _.get(payload, 'expireDate', null);

            if ((insuranceState !== null || insuranceState === insuranceStates.ARCHIVED) && hasExpired) {
                insuranceState = insuranceStates.EXPIRED;
            }

            return state.set('accidentInsurance', fromJS({
                id: id !== null ? parseInt(id, 10) : null,
                contractId: contractId !== null ? parseInt(contractId, 10) : null,
                state: insuranceState !== null ? parseInt(insuranceState, 10) : null,
                type: insuranceType !== null ? parseInt(insuranceType, 10) : null,
                number: _.get(insurance, 'number', '-'),
                institutionName: _.get(insurance, 'institution.name', '-'),
                institutionShortName: _.get(insurance, 'institution.shortName', '-'),
                isExternal: insuranceType === accidentInsuranceTypes.EXTERNAL,
                expireDate,
                hasExpired,
            }));
        },

        setTyelInsurance: (state, payload) => {
            const id = _.get(payload, 'tyelInsuranceId', null);
            const insuranceType = _.get(payload, 'type', null);

            const getState = (tyelInsurance) => {
                const state = _.get(tyelInsurance, 'state', null);
                const expireDate = _.get(tyelInsurance, 'expireDate', null);

                if (state === insuranceStates.ARCHIVED || !! state === insuranceStates.EXPIRED) {
                    return insuranceStates.EXPIRED;
                }

                // Jos state on ACTIVE: 1, mutta on päättyny näytetään päättyneenä
                if (expireDate && new Date(expireDate) < new Date()) {
                    return insuranceStates.EXPIRED;
                }
                return state;
            };

            let number = _.get(payload, 'number', null);

            // Jos tilapäisen työnantajan vakuutus vaihdetaan numeron tilalle teksti.
            if (insuranceType === tyelInsuranceTypes.TEMPORARY_EMPLOYER) {
                number = translateTyelInsuranceType(insuranceType);
            }
            
            return state.set('tyelInsurance', fromJS({
                id: id !== null ? parseInt(id, 10) : null,
                state: getState(payload),
                type: insuranceType,
                number,
                institutionName: _.get(payload, 'tyelInstitution.name', '-'),
                institutionShortName: _.get(payload, 'tyelInstitution.shortName', '-'),
                isExternal: _.get(payload, 'tyelInstitution.type', -1) === tyelInstitutionsTypes.EXTERNAL,
                isTemporaryEmployer: insuranceType === tyelInsuranceTypes.TEMPORARY_EMPLOYER,
            }));
        },
    },

    effects: (dispatch) => ({

        /**
         * Haetaan kirjautuneen käyttäjän tapaturmavakuutustiedot.
         */
        async fetchAccidentInsurance() {
            try {
                const response = await api
                    .get(Routing.generate('api_1_get_user_accident_insurance_contracts'));
                const accidentInsurance = _.maxBy(response, (insurance) => moment(insurance.startDate));
                this.setAccidentInsurance(accidentInsurance);
            } catch (error) {
                console.log(error);
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('insurances.notifications.error.accident_insurance_request_failed')
                }));
            }
        },

        /**
         * Haetaan kirjautuneen käyttäjän työeläkevakuutustiedot.
         */
        async fetchTyelInsurance() {
            try {
                const tyelInsurance = await api
                    .get(Routing.generate('api_1_get_active_tyel_insurance'));

                // Tutkitaan löytyykö instituutiota requestista
                // TODO: Varmempi check sille onko voimassa oleva TyEL
                const tyelInstitution = _.get(tyelInsurance, 'tyelInstitution', null);

                if (tyelInstitution !== null) {
                    this.setTyelInsurance(tyelInsurance);
                }
            } catch (error) {
                console.log(error);
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('insurances.notifications.error.accident_insurance_request_failed')
                }));
            }
        },

        /**
         * Tallentaa eläkevakuutuksen.
         * @param payload - vakuutuksen tiedot ja mahdollinen sijainti johon onnistuneen vakuutuksen tallennuksen
         * jälkeen mahdollisesti mennään.
         * @returns {Promise<void>}
         */
        async postTyelInsurance(payload) {
            try {
                const values = _.get(payload, 'values', {});
                const redirectRoute = _.get(payload, 'redirectRoute', null);

                const response = await api
                    .post(
                        Routing.generate('api_1_post_tyel_insurance'),
                        _.omit(values, 'hasAcceptedTermsOfService'),
                    );

                if (_.get(response, 'status', false) === 'ok') {
                    this.setTyelInsurance(_.get(response, 'data', {}));

                    dispatch(notificationActions.addNotification({
                        type: 'success',
                        message: _trans('insurances.notifications.success.tyel_insurance_activated')
                    }));

                    // Jos redirectpath annettu, ponkaistaan sinne.
                    if (redirectRoute !== null) {
                        dispatch(push(redirectRoute));
                    }
                } else {
                    // Haetaan ensisijaisesti virheviesti. Jos ei löydy näytetään geneerinen virheviesti.
                    const errorMessage = _.get(
                        response,
                        'message',
                        _trans('insurances.notifications.error.tyel_insurance_save_failed')
                    );
                    // Jokin meni pieleen
                    dispatch(notificationActions.addNotification({
                        type: 'error',
                        message: errorMessage,
                    }));
                }
            } catch (error) {
                const errorMessage = _.get(
                    error,
                    'message',
                    _trans('insurances.notifications.error.tyel_insurance_save_failed')
                );
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: errorMessage,
                }));
            }
        },

        /**
         * Tallentaa tapaturmavakuutuksen.
         * @param payload - vakuutuksen tiedot ja mahdollinen sijainti johon onnistuneen vakuutuksen tallennuksen
         * jälkeen mahdollisesti mennään.
         * @returns {Promise<void>}
         */
        async postAccidentInsurance(payload) {
            try {
                const values = _.get(payload, 'values', {});
                const redirectRoute = _.get(payload, 'redirectRoute', null);

                const response = await api
                    .post(
                        Routing.generate('api_1_post_accident_insurance_contract'),
                        values,
                    );

                if (_.get(response, 'status', false) === 'ok') {
                    this.setAccidentInsurance(_.get(response, 'data', {}));

                    dispatch(notificationActions.addNotification({
                        type: 'success',
                        message: _trans('insurances.notifications.success.accident_insurance_activated')
                    }));

                    // Jos redirectpath annettu, ponkaistaan sinne.
                    if (redirectRoute !== null) {
                        dispatch(push(redirectRoute));
                    }
                } else {
                    // Jokin meni pieleen
                    dispatch(notificationActions.addNotification({
                        type: 'error',
                        message: response.message ?
                            response.message :
                            _trans('insurances.notifications.error.accident_insurance_save_failed')
                    }));
                }
            } catch (error) {
                console.log(error);
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: error.message ?
                        error.message :
                        _trans('insurances.notifications.error.accident_insurance_save_failed')
                }));
            }
        },

        /**
         * Hakee käyttäjän metadatan jonka jälkeen joko varjokäyttäjän tai kirjautuneen käyttäjän id:n
         * jonka perusteella haetaan käyttäjän attribuutit joissa on mm. tieto vakuutusmuutospyyntöjen lähettämisestä.
         * @returns {Promise<void>}
         */
        async fetchUserData() {
            const response = await dispatch.userMetadata.fetchUserMetadata();

            const isCompany = _.get(response, 'isCompany', false);
            const userId = isCompany ? _.get(response, 'companyShadowUserId', '_self') : '_self';
            await dispatch.userAttributes.fetchAttributes(userId);
        }
    }),

    selectors: {
        getAccidentInsurance: (state) => state.get('accidentInsurance'),

        // Onko vakuutus väliaikainen
        isTemporaryAccidentInsurance: (state) => {
            const insurance = state.get('accidentInsurance');
            const number = insurance.get('number');
            return number && (number.match(/^temp\d{7}-\d$/) !== null);
        },

        getTyelInsurance: (state) => state.get('tyelInsurance'),
        getTyelInsuranceId: (state) => state.getIn(['tyelInsurance', 'id'], null),
    },
};

const INSURANCES_SELECTOR = 'loading.effects.insurances';

export const loadingSelectors = {
    isLoadingAccidentInsurance: (state) => _.get(state, `${INSURANCES_SELECTOR}.fetchAccidentInsurance`, false),
    isLoadingTyelInsurance: (state) => _.get(state, `${INSURANCES_SELECTOR}.fetchTyelInsurance`, false),

    // Onko käyttäjän tietojen lataus käynnissä
    isLoadingUserData: (state) => _.get(state, `${INSURANCES_SELECTOR}.fetchUserData`, false),
};
