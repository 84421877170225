import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import {
    MDIcon,
} from 'shared/components';

const SEPARATOR_NAME = 'separator';
const TYPE_CRITICAL = 'critical';

/**
 * Aria-roolein varustettu näppäinnavigoitava valikko.
 * !!! HUOM !!! ÄLÄ KÄYTÄ YKSINÄÄN !!!
 * KÄÄRITTÄVÄ react-aria-menubutton:sta importattuun Wrapperiin tai ei toimi. Jos et käytä
 * samasta paikkaa importattua Button:ia on sinun triggeröitävä valikon avautuminen itse edelleen
 * samasta paikkaa importatuilla openMenu- ja closeMenu-utilityillä.
 */
const OptionList = ({ items, onSelect }) => (
    <ul className="c-menu o-list-bare" role="listbox">
        {items.map((item, key) => {
            // Erotin
            if (item === SEPARATOR_NAME) {
                return (
                    <li role="separator" key={`menuOption${key}`}/>
                );
            }

            const icon = _.get(item, 'icon', false);
            const label = _.get(item, 'label', '');
            const type = _.get(item, 'type', null);

            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <li
                    key={`menuOption${key}`}
                    onClick={(event) => {
                        event.preventDefault();
                        onSelect(item);
                    }}
                    /* eslint-disable-next-line jsx-a11y/role-has-required-aria-props */
                    role="option"
                    className={classNames('c-menu__item u-text-no-wrap', {
                        // 'is-focused': isHighlighted,
                        'u-color-negative': type === TYPE_CRITICAL,
                    })}
                >
                    {icon && (
                        <MDIcon
                            icon={icon}
                            size="small"
                            modifierClass="u-margin-right-tiny"
                        />
                    )}
                    {label}
                </li>
            );
        })}
    </ul>
);

OptionList.propTypes = {
    /**
     * Valikon lista.
     */
    items: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
        action: PropTypes.func,
    })),

    /**
     * Mitä tapahtuu kun valitaan jotain listalta.
     */
    onSelect: PropTypes.func,
};

OptionList.defaultProps = {
    items: [],
    onSelect() {},
};

export default OptionList;
