/**
 * Hakee objektin arvon annetun polun perusteella.
 * const obj = { a: { b: { c: 4 } } }
 * get(obj, 'a.b.c');
 * => 4
 * @param obj
 * @param path
 * @param def
 * @returns {*}
 */
export const get = (obj, path, def) => (() =>
    typeof path === 'string' ? path.replace(/\[(\d+)]/g,'.$1') : path.join('.'))()
    .split('.')
    .filter(Boolean)
    .every((step) => ((obj = obj[step]) !== undefined)) ? obj : def;
