export const LinkIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
        <path fill="#fff" d="M0 0h24v24H0z"/>
        <path
            fill="#2A0E09"
            d="M9.75 15.75H6a3.75 3.75 0 0 1 0-7.5h3.75a.75.75 0 1 0 0-1.5H6a5.25 5.25 0 1 0 0 10.5h3.75a.75.75 0 1 0 0-1.5Zm8.25-9h-3.75a.75.75 0 1 0 0 1.5H18a3.75 3.75 0 1 1 0 7.5h-3.75a.75.75 0 1 0 0 1.5H18a5.25 5.25 0 1 0 0-10.5Z"
        />
        <path fill="#3A6CC1" d="M16.5 11.25h-9a.75.75 0 1 0 0 1.5h9a.75.75 0 1 0 0-1.5Z"/>
    </svg>
);
