import React from 'react';
import PropTypes from 'prop-types';
import { Errors } from 'react-redux-form';
import { errorMessages } from 'shared/validators';

/**
 * @deprecated Käytä Formik:ia ja meidän sharedista Form ja FormFieldiä.
 */
const StErrors = ({ messages, model, ...rest }) => (
    <Errors
        {...rest}
        model={model}
        messages={messages}
        wrapper={({ id, children }) => (
            // Kentän aria-describedby osoittaa tähän virhelistalle annettuun id:hen.
            <ul className="o-form__errors o-list-bare u-margin-bottom-none u-display-block" id={id}>
                {children}
            </ul>
        )}
        // eslint-disable-next-line react/prop-types
        component={(props) => <li className="o-form__error-item">{props.children}</li>}
    />
);

StErrors.propTypes = {
    messages: PropTypes.object,
    model: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

StErrors.defaultProps = {
    show: (field) => (!field.pristine && field.touched) || field.submitFailed,
    messages: errorMessages,
};

export default StErrors;
