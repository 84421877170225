import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {sizes} from 'shared/constants';

function CardHeader({children, justifyContent = true, link = ''}) {
    let wrappedChildren = null;
    const cardHeaderClass = classNames('c-card__header', {
        'c-card__header--link': link !== '',
        'o-stack o-stack--justify': justifyContent === true,
    });

    if (link !== '') {
        wrappedChildren = (
            <a href={link} className="o-stack o-stack--justify u-1/1">
                {children}
            </a>
        );
    }

    return (
        <header className={cardHeaderClass}>
            { wrappedChildren ? wrappedChildren : children}
        </header>
    )
}

CardHeader.Icon = function ({
    children
}) {
    return (
        <span className="c-card__title-icon o-stack o-stack--center u-text-center">
            {children}
        </span>
    );
};

CardHeader.Icon.propTypes = {
    children: PropTypes.node,
};

CardHeader.propTypes = {
    children: PropTypes.node,
    link: PropTypes.string,
};

export default CardHeader;