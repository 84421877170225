import PropTypes from 'prop-types';

export const payPeriodDataShape = PropTypes.shape({
    payPeriodDataId: PropTypes.number.isRequired,
    dimensionSets: PropTypes.shape({
        fraction: PropTypes.number,
        type: PropTypes.string,
        value: PropTypes.string,
    }),
    isEditable: PropTypes.bool,
});
