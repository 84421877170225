import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ErrorState } from 'shared/components';
import { DataTable } from 'shared/containers/DataTable7';
import { useGet } from 'shared/hooks/useApi';
import Panel from 'shared/components/Panel';
import { tyelInsuranceStates } from 'shared/containers/History/constants/tyelInsuranceStates';

export const TyelHistoryTable = () => {
    const { id } = useParams();
    const [{ isLoading, error, data }] = useGet(Routing.generate('api_1_get_benefitdecision_tyel_archive', { benefitDecision: id }));

    if (error) {
        return ( <ErrorState message={error.message}/> );
    }

    if (! isLoading && ! data) {
        return '';
    }
    
    return (
        <Panel heading={_trans('Työeläketiedot', {}, 'extract')}>
            <p>{_trans(`Alla olevassa taulukossa esitetään nykyisen avustuspäätöksen ja työnantajan perusteella saatavat 
            historiatiedot työeläketiedoista. Oiman hallinnoima tarkoittaa, että vakuutus kuuluu Oima-palvelun hallinnoiman 
            työeläkevakuutuksen piiriin. Mikäli haluat muuttaa vakuutusta ole yhteydessä asiakaspalveluun.`, {}, 'insurance')}</p>

            <DataTable
                data={data?.data ?? []}
                isResizable
                isLoading={isLoading}
                isFilterable={false}
                emptyDataMessage={_trans('Historiaa ei löytynyt', {}, 'extract')}
                columns={[
                    {
                        'Header': _trans('Yhtiö', {}, 'extract'),
                        'minWidth': 140,
                        'accessor': 'tyelInstitution.shortName'
                    },
                    {
                        'Header': _trans('Eläkejärjestelynumero', {}, 'extract'),
                        'minWidth': 170,
                        'accessor': (props) => {
                            const tyelNumber = props.number;
                            if (tyelNumber) {
                                return tyelNumber;
                            } else {
                                return props.state === tyelInsuranceStates.VALIDATION ? _trans('Selvityksessä', {}, 'extract') : '';
                            }
                        }
                    },
                    {
                        'Header': _trans('Oiman hallinnoima', {}, 'extract'),
                        'minWidth': 140,
                        'accessor': (props) => (props, props.isPartner ? _trans('Kyllä', {}, 'extract') : _trans('Ei', {}, 'extract')),
                    },
                    {
                        'Header': _trans('Alkupäivä', {}, 'extract'),
                        'accessor': 'startDate',
                        'minWidth': 140,
                        'type': 'date',
                    },
                    {
                        'Header': _trans('Päättymispäivä', {}, 'extract'),
                        'accessor': 'expireDate',
                        'type': 'date',
                        'minWidth': 160,
                        'defaultValue': _trans('Toistaiseksi', {}, 'extract')
                    },
                ]}
            />
        </Panel>
    );
};

TyelHistoryTable.propTypes = {
    isPartner: PropTypes.bool,
    state: PropTypes.number,
    number: PropTypes.number,
};

TyelHistoryTable.defaultProps = {
    isPartner: false,
    state: null,
    number: null
};

