import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { componentSizes } from 'shared/constants';
import CirclePlaceholder from './CirclePlaceholder';
import BoxPlaceholder from './BoxPlaceholder';

function MediaPlaceholder({ size, rows, hasAvatar }) {
    const textRows = [];
    for (let i = 1; i <= rows; i++) {
        textRows.push(
            <BoxPlaceholder size={size} key={i}/>
        );
    }

    return (
        <div
            className={classNames('o-placeholder', {
                'o-placeholder--tiny': size === componentSizes.TINY,
                'o-placeholder--small': size === componentSizes.SMALL,
                'o-placeholder--large': size === componentSizes.LARGE,
            })}
        >
            <div className="o-placeholder__list">
                {hasAvatar && (
                    <div className="o-placeholder__img">
                        <CirclePlaceholder size={size}/>
                    </div>
                )}
                <div className="o-placeholder__body">
                    {textRows}
                </div>
            </div>
        </div>
    );
}

MediaPlaceholder.defaultProps = {
    size: componentSizes.LARGE,
    rows: 3,
    hasAvatar: true,
};

MediaPlaceholder.propTypes = {
    /**
     * Komponenttien koko.
     */
    size: PropTypes.oneOf([..._.map(componentSizes), '']),

    /**
     * Montako placeholder-tekstiriviä.
     */
    rows: PropTypes.number,

    /**
     * Näytetäänkö Avatarin placeholder.
     */
    hasAvatar: PropTypes.bool,
};

export default MediaPlaceholder;
