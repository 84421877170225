import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/Button';
import { FormField } from 'shared/components/Formik';
import { getFlatValues } from 'shared/ReForm/utils/getFlatValues';

/**
 * Lisää uuden osapuolen.
 * @param name
 * @param blocks
 * @param arrayHelpers
 * @returns {JSX.Element|null}
 * @constructor
 */
const AddNewPartyButton = ({ blocks, arrayHelpers }) => {
    // Arvot jotka alustetaan.
    const values = useMemo(() => getFlatValues(blocks), [blocks]);

    return (
        <FormField isContentFormField={false}>
            <Button
                flat
                mdIcon="add"
                onClick={() => arrayHelpers.unshift(Object.fromEntries(values))}
            >
                {_trans('Lisää uusi henkilö')}
            </Button>
        </FormField>
    );
};

AddNewPartyButton.propTypes = {
    name: PropTypes.string.isRequired,
    arrayHelpers: PropTypes.object.isRequired,
    blocks: PropTypes.array.isRequired,
};

export default AddNewPartyButton;
