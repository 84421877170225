import React from 'react';
import PropTypes from 'prop-types';
import { Value } from 'shared/components/Value';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { fieldNames } from 'ContractV3/constants/fieldNames';

/**
 * Valittu alkuperäinen alkoituspäivä.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const OriginalStartDate = (props) => {
    const { value, values, label } = props;
    const startDate = values[fieldNames.START_DATE];

    // Jos alkup. aloituspäivää ole, on sen oltava sama kuin oima-palvelun käyttöönottopäivä
    const originalStartDate = (! value || value === '')
        ? startDate
        : value;

    return (
        <SummaryListItem term={label}>
            <Value>{_toLocaleDate(originalStartDate)}</Value>
        </SummaryListItem>
    );
};

OriginalStartDate.propTypes = {
    value: PropTypes.string,
    values: PropTypes.object,
    label: PropTypes.string.isRequired,
};

OriginalStartDate.defaultProps = {
    value: '',
    values: {},
};

export default OriginalStartDate;
