import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { userSelectors } from 'shared/UserDetails/stores/user';
import { translateLeavePeriodType } from 'shared/constants/leavePeriodTypes';
import Button from 'shared/components/Button';
import { ActionBar, EmptyState, Feedback, MDSpinner } from 'shared/components';
import DataTable from 'shared/containers/DataTable';
import confirm from 'shared/utils/confirm';

@connect((state) => ({
    userId: userSelectors.getUserId(state),
    isLoading: state.loading.effects.leavePeriods.fetchLeavePeriods || state.loading.effects.leavePeriods.fetchEmployeeContracts,
    isSaving: state.loading.effects.leavePeriods.postLeavePeriod || state.loading.effects.leavePeriods.putLeavePeriod,
    periods: select.leavePeriods.getLeavePeriods(state),
    contracts: select.leavePeriods.getEmployeeContracts(state),
}))
export default class LeavePeriodList extends Component {
    static propTypes = {
        userId: PropTypes.number,
        periods: PropTypes.object,
        contracts: PropTypes.array,
        periodType: PropTypes.number,
        dispatch: PropTypes.func,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        onEditPeriod: PropTypes.func.isRequired,
        /**
         * Uuden, saman tyyppisen poissaolojakson lisäys
         */
        onAddPeriod: PropTypes.func.isRequired,
    };

    static defaultProps = {
        periods: {},
        contracts: [],
        userId: -1,
        periodType: null,
        dispatch() {},
        isLoading: false,
        isSaving: false,
    };

    render() {
        const { periods, contracts, isLoading, onAddPeriod, onEditPeriod, dispatch, userId } = this.props;
        if (isLoading) {
            return (
                <MDSpinner wrapped />
            );
        }

        const hasActiveContracts = contracts.length > 0;
        if (! hasActiveContracts) {
            return (
                <Feedback type="info" message={_trans('leave_period.error.no_contracts')} />
            );
        }


        // Renderöidään vain ne joissa on sisältöä => vähemmän kohinaa
        const filteredPeriods = Object.entries(periods).filter(([, periodRanges]) => periodRanges.length > 0);

        if (filteredPeriods.length === 0) {
            return (
                <EmptyState message={_trans('leave_period.empty_state')} />
            );
        }
        return filteredPeriods.map(([periodType, periodRanges]) => (
            <div key={periodType} className="u-margin-bottom-large">
                <ActionBar modifierClass="u-margin-bottom-small">
                    <h3 className="c-heading-title">
                        {translateLeavePeriodType(parseInt(periodType, 10))}
                    </h3>
                    <Button
                        mdIcon="add"
                        onClick={() => onAddPeriod(periodType)}
                        title={`${_trans('button.add_new')} ${translateLeavePeriodType(parseInt(periodType, 10))}`}
                        ariaLabel={`${_trans('button.add_new')} ${translateLeavePeriodType(parseInt(periodType, 10))}`}
                    >
                        {_trans('button.add')}
                    </Button>
                </ActionBar>
                <DataTable
                    isFilterable={false}
                    data={periodRanges}
                    defaultSorted={[
                        {
                            id: 'leavePeriodRange',
                            desc: true,
                        }
                    ]}
                    columns={[
                        {
                            id: 'leavePeriodRange',
                            width: 200,
                            accessor: (props) => props.startDate,
                            Cell: ({ original: { startDate, endDate } }) => (
                                `${_toLocaleDate(startDate)}-${_toLocaleDate(endDate)}`
                            ),
                            Header: _trans('leave_period.leave_period'),
                        },
                        {
                            accessor: 'employer',
                            Cell: ({ original }) => {
                                const { jobContract } = original;
                                const fullName = jobContract
                                    ? jobContract.employerDetails.fullName
                                    : '';
                                return (
                                    <div className={! jobContract ? 'u-muted' : ''}>
                                        {jobContract ? fullName : _trans('leave_period.all_contracts')}
                                    </div>
                                );
                            },
                            Header: _trans('text.employer'),
                        },
                    ]}
                    actionsColumnWidth={280}
                    actionsColumn={(
                        <ActionsColumn
                            onEdit={(periodRange) => onEditPeriod(periodRange)}
                            onRemove={(id) => {
                                confirm(_trans('leave_period.form.messages.confirm_delete'),{
                                    title: _trans('leave_period.form.delete_header'),
                                    cancelLabel: _trans('button.cancel'),
                                    proceedLabel: _trans('button.remove'),
                                    alert: true,
                                })
                                    .then(() => dispatch.leavePeriods.deleteLeavePeriod({
                                        userId,
                                        leavePeriodId: id,
                                        type: periodType
                                    }));
                            }}
                        />
                    )}
                />
            </div>
        ));

    }
}

const ActionsColumn = ({ original, onEdit, onRemove }) => {
    const { id } = original;
    return (
        <ActionBar alignItems="left" canWrap>
            <Button
                flat
                mdIcon="edit"
                size="small"
                onClick={() => onEdit(original)}
            >
                {_trans('button.edit')}
            </Button>
            <Button flat mdIcon="clear" size="small" onClick={() => onRemove(id)}>
                {_trans('button.remove')}
            </Button>
        </ActionBar>
    );
};

ActionsColumn.propTypes = {
    original: PropTypes.object,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

ActionsColumn.defaultProps = {
    original: {},
};
