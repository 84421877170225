import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import DataTable from 'shared/containers/DataTable';
import MDSpinner from 'shared/components/MDSpinner';
import { SurchargeActions } from 'shared/Admin/Surcharges/containers/SurchargeActions';
import { BillingType } from 'shared/Admin/Surcharges/components/BillingType';
import { NameAndDescription } from 'shared/Admin/Surcharges/components/NameAndDescription';
import { SurchargeConfirmDialog } from 'shared/Admin/Surcharges/containers/SurchargeConfirmDialog';

/**
 * Lisämaksulista.
 */
export const SurchargeList = hot(() => {
    const surcharges = useSelector(select.surcharges.getSurcharges);
    const isLoading = useSelector((state) => state.loading.effects.surcharges.fetchSurcharges);
    const total = useSelector(select.surcharges.getTotal);

    if (isLoading) {
        return (
            <MDSpinner wrapped />
        );
    }

    return (
        <Fragment>
            <div className="u-margin-bottom">
                <DataTable
                    emptyDataMessage="Ei avoimia lisämaksuja"
                    data={surcharges}
                    defaultSorted={[{
                        id: 'created',
                        desc: true,
                    }]}
                    columns={[
                        {
                            Header: 'Lisämaksu',
                            id: 'nameAndDescription',
                            Cell: NameAndDescription,
                        },
                        {
                            Header: 'Lisätty',
                            accessor: 'created',
                            type: 'date',
                        },
                        {
                            Header: _trans('unit.count'),
                            accessor: 'unitCount',
                            type: 'number',
                            alignText: 'right',
                            width: 100,
                        },
                        {
                            Header: _trans('unit.price'),
                            accessor: 'unitPrice',
                            type: 'currency',
                            alignText: 'right',
                            width: 150,
                        },
                        {
                            Header: _trans('text.total'),
                            id: 'total',
                            accessor: (props) => (props.unitCount * props.unitPrice),
                            // eslint-disable-next-line react/prop-types,react/display-name
                            Cell: ({ value }) => (
                                <div className={value < 0 ? 'u-color-negative' : 'u-color-default'}>
                                    {_currency(value)}
                                </div>
                            ),
                            // type: 'currency',
                            alignText: 'right',
                            width: 150,
                        },
                        {
                            Header: 'Laskutus',
                            accessor: 'isExternal',
                            filterType: 'dropdown',
                            filterOptions: [
                                {
                                    value: false,
                                    label: 'Sisäinen',
                                },
                                {
                                    value: true,
                                    label: 'Ulkoinen',
                                }
                            ],
                            Cell: BillingType,
                            width: 150,
                        }
                    ]}
                    actionsColumn={<SurchargeActions />}
                    actionsColumnFooter={(
                        <div className="u-text-right">
                            {`${_trans('text.total')} ${_currency(total)}`}
                        </div>
                    )}
                    actionsColumnWidth={180}
                />
            </div>
            {surcharges.length > 0 && <SurchargeConfirmDialog /> }
        </Fragment>
    );
});

