

export const additionalContractTypes = {
    QUOTA_OVERTIME: 1,
};

const additionalContractTranslationMap = {
    [additionalContractTypes.QUOTA_OVERTIME]: _trans('constant.additional_contracts.quota_overtime.title'),
};

export const additionalContractStates = {
    STATE_ACTIVE: 1,
    STATE_ENDING: 2,
};

export const translateAdditionalContract = (type) => type in additionalContractTranslationMap ? additionalContractTranslationMap[type] : '';

export const translateAdditionalContractPeriodLengthType = (type) => {
    switch (type) {
        case 'months':
            return _trans('unit.month');
        case 'days':
            return _trans('unit.day');
        default:
            return _trans('Tuntematon', {}, 'common');
    }
};
