const tesVariableKeys = {
    /**
     * Työaikamuoto: päivätyö, vuorotyö, jne
     *
     * Ei käytössä läheskään kaikilla tesseillä
     */
    WORK_TIME_ARRANGEMENT_TYPE: 'work_time_arrangement_type',

    /**
    * Vuorotyöt (metsä-, tekno- & golf-tessit)
    */

    // Päivätyö / yksivuorotyö
    WORK_TIME_ARRANGEMENT_TYPE_SHIFT_SINGLE: 1,

    // Kaksivuorotyö
    WORK_TIME_ARRANGEMENT_TYPE_SHIFT_TWO: 2,

    // Kolmivuorotyö, keskeytyvä
    WORK_TIME_ARRANGEMENT_TYPE_SHIFT_THREE_INTERRUPTED: 3,

    // Kolmivuorotyö, keskeytymätön
    WORK_TIME_ARRANGEMENT_TYPE_SHIFT_THREE_UNINTERRUPTED: 4,

    // Vaihteleva työaika
    WORK_TIME_ARRANGEMENT_TYPE_IRREGULAR: 5,

    /**
    * Yksityinen sosiaalipalveluan tes
    */

    // Toimistotyö (sos. tes)
    WORK_TIME_ARRANGEMENT_TYPE_OFFICE: 6,

    // Jaksotyö (sos. tes)
    WORK_TIME_ARRANGEMENT_TYPE_PERIOD: 7,

    // Muu kuin toimisto- tai jaksotyö (sos. tes)
    WORK_TIME_ARRANGEMENT_TYPE_OTHER_THAN_OFFICE_OR_PERIOD: 8,

    /**
    * Työajan määrä, voi olla säännöllinen
    * jolloin jotain per kk tai epäsäännöllinen
    */
    WORK_TIME_ARRANGEMENT_VALUE: 'work_time_arrangement_value',

    /**
    * Työtuntimäärät viikossa, keyt intteinä koska float-keytä ei voi käyttää.
    *
    * Nämä on lyöty taulukkoon koska joka arvolle ei kannattane tehdä erillistä constia.
    */
    WORK_TIME_ARRANGEMENT_REGULAR_VALUES: {
        348: '34.8',
        349: '34.9',
        355: '35.5',
        358: '35.8',
        362: '36.2',
        365: '36.5',
        3675: '36.75', // 36h 45min / viikko
        370: '37.0',
        371: '37.1',
        375: '37.5',
        380: '38.0',
        385: '38.5',
        3883: '38.83', // 38h 50min / viikko
        400: '40.0',
    },

    /**
    * Epäsäännöllisen työajan stepit
    *
    * TODO: nyt vain lähinnä referenssinä tallessa
    */
    WORK_TIME_ARRANGEMENT_IRREGULAR_VALUES: {
        999: 999,
    },

    /**
     * koeajan palkan alennus
     */
    TRIAL_PERIOD_REDUCED_SALARY: 'trial_period_reduced_salary',

    /**
     * Alue. Sijaintitietoa vaaditaan tiettyjen työehtosopimusten lisien hinnan määrittelyyn
     */
    AREA: 'area',

    /**
     * OPH: Kuljetusten merkkaaminen hoitopäiville
     */
    ALLOW_TRANSPORTATIONS: 'allow_transportations',

    /**
     * OPH: Laskutettava sopimus
     */
    IS_BILLABLE: 'is_billable',

    /**
     * Jos holiday_earning_settings asetettu type "tes_variable", niin tutkitaan täältä kertymä
     */
    ASSIGNMENT_CONTRACT_HOLIDAY_GENERATION_AMOUNT: 'assignment_contract_holiday_generation_amount',
};

export default tesVariableKeys;

export const workTimeArrangementTypes = [
    tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_SHIFT_SINGLE,
    tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_SHIFT_TWO,
    tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_SHIFT_THREE_INTERRUPTED,
    tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_SHIFT_THREE_UNINTERRUPTED,
    tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_IRREGULAR,
    tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_OFFICE,
    tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_PERIOD,
    tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_OTHER_THAN_OFFICE_OR_PERIOD,
];

const workTimeArrangementMap = {
    [tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_SHIFT_SINGLE]: _trans('contract.form.tes_variables.work_time_arrangement_type.shift_single'),
    [tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_SHIFT_TWO]: _trans('contract.form.tes_variables.work_time_arrangement_type.shift_two'),
    [tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_SHIFT_THREE_INTERRUPTED]: _trans('contract.form.tes_variables.work_time_arrangement_type.shift_three_interrupted'),
    [tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_SHIFT_THREE_UNINTERRUPTED]: _trans('contract.form.tes_variables.work_time_arrangement_type.shift_three_uninterrupted'),
    [tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_IRREGULAR]: _trans('contract.form.tes_variables.work_time_arrangement_type.irregular'),
    [tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_OFFICE]: _trans('contract.form.tes_variables.work_time_arrangement_type.office'),
    [tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_PERIOD]: _trans('contract.form.tes_variables.work_time_arrangement_type.period'),
    [tesVariableKeys.WORK_TIME_ARRANGEMENT_TYPE_OTHER_THAN_OFFICE_OR_PERIOD]: _trans('contract.form.tes_variables.work_time_arrangement_type.other_than_office_or_period'),
};

export function translateWorkTimeArrangementType(type) {
    return type in workTimeArrangementMap ? workTimeArrangementMap[type] : '';
}

export const locations = {
    METROPOLITAN_AREA: 'metropolitan_area',
    REST_OF_FINLAND: 'rest_of_finland',
};

const locationMap = {
    [locations.METROPOLITAN_AREA]: _trans('tes_builder.locations.metropolitan_area'),
    [locations.REST_OF_FINLAND]: _trans('tes_builder.locations.rest_of_finland'),
};

export function translateLocation(location) {
    return location in locationMap ? locationMap[location] : '';
}
