import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Staattisen arvon näyttämiskomponetti.
 * Huolehtii (oikein käytettynä) myös saavutettavuusasiat kuntoon.
 * @param value
 * @param ariaDescribedBy
 * @param modifierClass
 * @returns {JSX.Element}
 * @constructor
 */
export const Value = ({ children, ariaDescribedBy, modifierClass }) => (
    <strong aria-describedby={ariaDescribedBy} className={classNames('o-form__text u-display-inline-block', modifierClass)}>{children}</strong>
);

Value.propTypes = {
    children: PropTypes.node.isRequired,
    /**
     * Sen elementin id joka kuvaa mikä tämä arvo on. Esim.
     * Työntekijä: Matti Meikäläinen
     * jossa Työntekijä-elementillä on id ja Matti Meikäläinen käyttää tätä id:tä selittämään itsensä.
     */
    ariaDescribedBy: PropTypes.string,
    modifierClass: PropTypes.string,
};

Value.defaultProps = {
    ariaDescribedBy: null,
    modifierClass: undefined,
};
