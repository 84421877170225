// AppBundle\Entity\LeavePeriod

export const leavePeriodTypes = {
    SICK_LEAVE: 20,
    UNPAID_SICK_LEAVE: 23,
    WORK_ACCIDENT_SICK_LEAVE: 21,
    UNPAID_WORK_ACCIDENT_SICK_LEAVE: 24,
    MATERNITY_LEAVE: 61,
    UNPAID_MATERNITY_LEAVE: 60,
    LAYOFF_LEAVE: 80,
    STUDY_LEAVE: 90,
    OTHER_LEAVE: 91,
    NURSING_LEAVE: 95,
    MILITARY_OR_CIVILIAN_SERVICE: 96,
    ALTERNATION_LEAVE: 120,
    UNPAID_LEAVE: 31,
};

export const leavePeriodTypeMap = {
    [leavePeriodTypes.SICK_LEAVE]: 'sick_leave',
};

export function translateLeavePeriodType(type) {
    return Object.values(leavePeriodTypes).includes(type)
        ? _trans(`leave_period.${type}.header`)
        : _trans('text.unknown');
}
