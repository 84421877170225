import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { sizes } from 'shared/constants';

/**
 * List
 * @param children
 * @param placeholders - Montako riviä sisällön placeholdereita käytetään
 * @param placeholderElement - Elementti jota toistetaan <placeholders> kertaa
 * @param isLoading - Ladataanko listaa. Jos ladataan, näytetään yllä olevat placeholderit.
 * @param size - listan paddingin koko. Ei tällä hetkellä käytössä tosin.
 * @param title - Listan otsikko (ei pakollinen)
 * @param hasFooter - onko listalla footer.
 * @param modifierClass - CSS-muokkausluokka.
 * @param hasLastLine
 * @returns {*}
 * @constructor
 */
function List({
    children,
    placeholders,
    placeholderElement,
    isLoading,
    size,
    title,
    hasFooter,
    modifierClass,
    hasLastLine,
}) {
    let childrenWithProps;

    if (placeholders > 0 && isLoading) {
        childrenWithProps = _.times(placeholders, (key) => React.cloneElement(placeholderElement, { key }));
    } else {
        childrenWithProps = React.Children.map(children,
            (child) => {
                if (!child) return null;
                return React.cloneElement(child, {
                    isLoading,
                });
            }
        );
    }

    const listClass = classNames('o-list', modifierClass, {
        'o-list--no-last-line': ! hasLastLine,
        'o-list--tiny': size === sizes.TINY,
        'o-list--small': size === sizes.SMALL,
        'o-list--large': size === sizes.LARGE,
        'o-list--huge': size === sizes.HUGE,
        'o-list--footer': hasFooter,
    });

    return (
        <Fragment>
            {title !== '' && (
                <header className="o-list-header">
                    {title}
                </header>
            )}
            <ul className={listClass} role="menu">
                {childrenWithProps}
            </ul>
        </Fragment>
    );
}

List.propTypes = {
    children: PropTypes.node.isRequired,
    placeholders: PropTypes.number,
    placeholderElement: PropTypes.node,
    isLoading: PropTypes.bool,
    size: PropTypes.oneOf([..._.map(sizes), '']),
    title: PropTypes.string,
    /**
     * Näytetäänkö tämä listaitemi footerin-mallisena.
     */
    hasFooter: PropTypes.bool,
    /**
     * Näytetäänkö viimeisellä rivillä viiva.
     */
    hasLastLine: PropTypes.bool,
    /**
     * CSS-muokkausluokka.
     */
    modifierClass: PropTypes.string,
};

List.defaultProps = {
    placeholders: 0,
    placeholderElement: <div/>,
    isLoading: false,
    size: '',
    title: '',
    hasFooter: false,
    hasLastLine: true,
    modifierClass: '',
};

export default List;
