import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InputGroup = ({ children, modifierClass, ...rest }) => {
    const inputGroupClass = classNames('o-stack--inline o-stack--stretch o-input-group u-align-top', {
        [modifierClass]: modifierClass !== '',
    });

    return (
        <div className={inputGroupClass} {...rest}>
            {children}
        </div>
    );
};

InputGroup.defaultProps = {
    modifierClass: '',
};

InputGroup.propTypes = {
    /**
     * Sisältö.
     */
    children: PropTypes.node.isRequired,

    /**
     * CSS-luokka.
     */
    modifierClass: PropTypes.string,
};

export default InputGroup;
