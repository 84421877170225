export const editType = {
    NEW: 1,
    TOTAL: 2,
    ADD_DELTA: 3,
    SUB_DELTA: 4,
};

// Bäkkärille tieto miten kokemuskuukautta halutaan muuttaa
export const changeType = {
    TOTAL: 'total',
    DELTA: 'delta',
};

export const totalTypes = [
    editType.NEW,
    editType.TOTAL,
];

export const updateTypes = [
    editType.TOTAL,
    editType.ADD_DELTA,
    editType.SUB_DELTA,
];

const editTypeLabelTranslationMap = {
    [editType.NEW]: _trans('users.section.work_experience.choice.new_title'),
    [editType.TOTAL]: _trans('users.section.work_experience.choice.change_total_title'),
    [editType.ADD_DELTA]: _trans('users.section.work_experience.choice.change_delta_add_title'),
    [editType.SUB_DELTA]: _trans('users.section.work_experience.choice.change_delta_sub_title'),
};

export const translateEditTypeLabel = (status) => (
    status in editTypeLabelTranslationMap ? editTypeLabelTranslationMap[status] : _trans('general.unknown')
);

const editTypeDescriptionTranslationMap = {
    [editType.NEW]: _trans('users.section.work_experience.choice.new_text'),
    [editType.TOTAL]: _trans('users.section.work_experience.choice.change_total_text'),
    [editType.ADD_DELTA]: _trans('users.section.work_experience.choice.change_delta_add_text'),
    [editType.SUB_DELTA]: _trans('users.section.work_experience.choice.change_delta_sub_text'),
};

export const translateEditTypeDescription = (status) => (
    status in editTypeDescriptionTranslationMap ? editTypeDescriptionTranslationMap[status] : ''
);
