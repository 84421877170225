import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { CostReimbursementAmount } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Salaries/CostReimbursementAmount';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';

/**
 * Kulukorvauksen erityislisä.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CostReimbursementSpecialAllowance = ({ arrayName, label }) => {
    // Onko taulukkoliksat käytössä
    const [isTableSalaryInUseField] = useField(resolveArrayName(arrayName, fieldNames.IS_TABLE_SALARY_IN_USE));
    const isTableSalaryInUse = isTableSalaryInUseField?.value ?? true;
    const hasCostReimbursementSpecialAllowanceTableSalaries = useSelector(select.contract.hasCostReimbursementSpecialAllowanceTableSalaries);

    if (! isTableSalaryInUse || ! hasCostReimbursementSpecialAllowanceTableSalaries) return null;

    return (
        <CostReimbursementAmount
            label={label}
            arrayName={arrayName}
            name={resolveArrayName(arrayName, fieldNames.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE)}
        />
    );
};

CostReimbursementSpecialAllowance.propTypes = {
    arrayName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default CostReimbursementSpecialAllowance;
