import { Formik } from 'formik';
import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import * as Yup from 'yup';
import { CheckboxListField, Form, FormField } from 'shared/components/Formik';
import { ActionBar, MDSpinner, SubmitButton } from 'shared/components';
import { confirm } from 'shared/utils';
import { userSelectors } from 'shared/UserDetails/stores/user';
import { routeKeys } from 'User/constants/routes';
import alert from 'shared/utils/alert';

export const UserAttachedDimensionsForm = ({ userId, children, onUserAttachedDimensionsAdd, isRequired, }) => {
    const dispatch = useDispatch();
    const routes = useSelector(userSelectors.getRoutes);
    const visibilityDimensions = useSelector(select.dimensions.getVisibilityDimensions);
    const visibilityDimensionTypeName = useSelector(select.dimensions.getVisibilityDimensionTypeName);
    const userAttachedDimensions = useSelector(select.userAttachedDimensions.getAttachedDimensions);
    const isLoadingUserAttachedDimensions = useSelector((state) => state.loading.effects.userAttachedDimensions.fetchUserAttachedDimensions);
    const isPostingUserAttachedDimensions = useSelector((state) => state.loading.effects.userAttachedDimensions.postUserAttachedDimensions);
    useEffect(() => {
        dispatch.userAttachedDimensions.fetchUserAttachedDimensions(userId);
    }, [userId]);

    const postDimensions = useCallback(async (model) => {
        const json = await dispatch.userAttachedDimensions.postUserAttachedDimensions({ user: userId, model });
        if (json.status === 'ok') {
            onUserAttachedDimensionsAdd(json);
            dispatch.notifications.addSuccess(_trans('Tiedot tallennettu', {}, 'common'));
            if (json.data.length === 0) {
                // Tallentaa ja ei oo valittu yhtään dimenssiota, niin hyppää takas listalle, koska ei oo enää oikeutta
                dispatch(push(routes.getIn([routeKeys.BASE, 'route'])));
            }
        } else {
            if (json.error === 'invalid_data') {
                const errors = Array.isArray(json.data) ? json.data : [json.data];
                // Näytetään virheet
                alert((
                    <Fragment>
                        <p>
                            {_trans('Tietoja ei voitu päivittää seuraavista syistä', {}, 'common')}
                        </p>
                        <ul className="u-margin-bottom-none">
                            {errors.map(({ message }, key) => (
                                <li key={key}>
                                    {message}
                                </li>
                            ))}
                        </ul>
                    </Fragment>
                ), { alert: true });
            } else {
                dispatch.notifications.addError(_trans('Päivittämisen aikana tapahtui tuntematon virhe', {}, 'common'));
            }
        }
    }, [onUserAttachedDimensionsAdd, userId]);


    if (isLoadingUserAttachedDimensions) {
        return <MDSpinner wrapped />;
    }

    const initialValues = userAttachedDimensions.map((uad) => uad.dimension.id.toString());

    const dimensionSelector = (
        <FormField name="dimensions" isRequired={isRequired} label={visibilityDimensionTypeName}>
            <CheckboxListField
                name="dimensions"
                options={visibilityDimensions.map((dim) => ({ ...dim, label: `${dim.value} - ${dim.description ?? ''}`, id: dim.id.toString() }))}
                valueKey="id"
                labelKey="label"
            />
        </FormField>
    );

    return (
        <Formik
            initialValues={{
                dimensions: initialValues,
            }}
            onSubmit={(model) => {
                if (model.dimensions.length === 0) {
                    confirm(
                        _trans('Jos yhtään ryhmää ei ole valittuna ei henkilö  näy jatkossa enää Henkilöt-listailla. Tiedot ovat kuitenkin tallessa Oima-palvelussa ja henkilö voidaan lisätä uudestaan henkilötunnuksella.', {}, 'common'),
                        { proceedLabel: _trans('button.save') }
                    ).then(
                        () => postDimensions(model),
                        () => {}
                    );
                } else {
                    postDimensions(model);
                }
            }}
            validationSchema={isRequired
                ? Yup.object().shape({
                    dimensions: Yup.array().min(1, _trans('Valitse vähintään yksi', {}, 'common'))
                })
                : null
            }
        >
            <Form>
                {/* Jos lapseksi annettu funkkari, niin sillä voidaan renderöidä muutakin sisältöä lomakeelle, esim hetu näkyviin tai omaa tekstiä */}
                {typeof children === 'function' ? children(dimensionSelector) : dimensionSelector}
                <ActionBar>
                    <SubmitButton isPending={isPostingUserAttachedDimensions}>
                        {_trans('button.save')}
                    </SubmitButton>
                </ActionBar>
            </Form>
        </Formik>
    );
};

UserAttachedDimensionsForm.propTypes = {
    userId: PropTypes.number.isRequired,
    children: PropTypes.func,
    onUserAttachedDimensionsAdd: PropTypes.func,
    isRequired: PropTypes.bool,
};

UserAttachedDimensionsForm.defaultProps = {
    children: null,
    onUserAttachedDimensionsAdd: () => {},
    isRequired: false,
};
