import React from 'react';
import PropTypes from 'prop-types';
import MDIcon from 'shared/components/MDIcon';
import classNames from 'classnames';
import StackItem from './StackItem';
import {stackAlignTypes, justifyTypes} from 'shared/constants';
import _ from 'lodash';

/**
 * Abstrakti komponentti elementtilistojen ja -jonojen muodostamiseen.
 * Responsiivisuustuki.
 *
 * Lapsina käytettävä StackItem-komponenttia muuten leiska voi hajota.
 *
 * @param children
 * @param isResponsive
 * @param alignVertical
 * @param wrapItems
 * @param alignType
 * @param justifyType
 * @param modifierClass
 * @returns {XML}
 * @constructor
 */
function Stack({children, isResponsive, alignVertical, wrapItems, alignType, justifyType, modifierClass}) {
    const stackClass = classNames('o-stack o-list-bare', {
        // Responsiivisuus ei käytössä
        'o-stack--vertical': alignVertical, // Normaali pinomodifier

        // Heittää elementit toiselle riville jos tila loppuu.
        'o-stack--wrap': wrapItems,

        // Responsiivisuus käytössä
        'o-stack-responsive': isResponsive,

        // Elementtien sijoittelutapa
        'o-stack--left': alignType === stackAlignTypes.LEFT,
        'o-stack--right': alignType === stackAlignTypes.RIGHT,
        'o-stack--start': alignType === stackAlignTypes.START,
        'o-stack--end': alignType === stackAlignTypes.END,

        // Elementtien tasaustapa
        'o-stack--justify': justifyType === justifyTypes.JUSTIFY,
        'o-stack--spread': justifyType === justifyTypes.SPREAD,
        'o-stack--center': justifyType === justifyTypes.CENTER,

        [modifierClass]: modifierClass !== '',
    });

    return (
        <ul className={stackClass}>
            {children}
        </ul>
    )
}

Stack.defaultProps = {
    alignVertical: false,
    modifierClass: '',
    isResponsive: false,
    wrapItems: false,
};

Stack.propTypes = {
    /**
     * Sisältö.
     */
    children: PropTypes.node,

    /**
     * Onko responsiivisuustuki käytössä.
     */
    isResponsive: PropTypes.bool,

    /**
     * Heitetäänkö elementit toiselle riville jos tila loppuu.
     */
    wrapItems: PropTypes.bool,

    /**
     * Menevätkö elementit pienessä ruutukoossa alekkain.
     */
    alignVertical: PropTypes.bool,

    /**
     * Elementtien sijoittelutapa.
     */
    alignType: PropTypes.oneOf(_.map(stackAlignTypes)),

    /**
     * Elementtien tasaustapa.
     */
    justifyType: PropTypes.oneOf(_.map(justifyTypes)),

    /**
     * Muokkaava CSS-luokka. Tarkoitettu komponentin tarkempaan tyylittelyyn.
     */
    modifierClass: PropTypes.string,
};

export default Stack;