import React from 'react';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Value } from 'shared/components/Value';

/**
 * Muuta sovittua.
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const MiscMutualAgreement = () => {
    const miscMutualAgreement = useSelector(select.contract.getMiscMutualAgreement);

    if (! miscMutualAgreement || miscMutualAgreement === '') {
        return (
            <li className="o-summary-list__item">
                {_trans('Ei muuta sovittua.', {}, 'jobContract')}
            </li>
        );
    }

    return (
        <li className="o-summary-list__item">
            <Value>{_transMd(miscMutualAgreement, {}, { useParagraphs: true })}</Value>
        </li>
    );
};

export default MiscMutualAgreement;
