import { isCommune } from 'shared/utils/commonUtils';

/**
 * Resolvoi linkin käyttäjän tietoihin relaation perusteella.
 * @param userId
 * @param relationType
 * @returns {string|null}
 */
export const resolveUserUrlByRelationType = (userId, relationType) => {
    const relationTypes = {
        'customer': 1,
        'employer': 2,
        'employee': 3,
    };

    if (! userId || ! relationTypes.hasOwnProperty(relationType)) return null;

    const path = isCommune() ? 'commune' : 'company';
    return `/${path}/users/show/${userId}?type=${relationTypes[relationType]}`;
};
