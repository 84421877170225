import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxField } from 'shared/components/Formik';

/**
 * Työaika autonomia valinta.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */

const WorkTimeAutonomy = (props) => {
    const { name, inputLabel } = props;

    return (
        <CheckboxField
            name={name}
            label={inputLabel}
        />

    );
};
WorkTimeAutonomy.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    inputLabel: PropTypes.string.isRequired,
};

export default WorkTimeAutonomy;
