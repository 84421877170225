import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import { Value } from 'shared/components/Value';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';

const Dimension = (props) => {
    const { value, name } = props;

    // dimensions.id212 => ["dimensions", "id212"]
    const namePieces = name.split('.');
    // id212 => 212
    const dimensionTypeId = namePieces[1]?.substring(2);
    const dimensionType = useSelector((state) => select.contract.getDimensionTypeById(state, parseInt(dimensionTypeId), 10));

    if (dimensionType) {
        const { label } = getFieldProps(props);
        return (
            <SummaryListItem term={label || dimensionType.type}>
                <Value>{[dimensionType.value, dimensionType.description].filter(Boolean).join(' - ')}</Value>
            </SummaryListItem>
        );
    } else {
        // Ei saisi koskaan tapahtua.
        return (
            <div>Dimensiota <b>{value}</b> ei löytynyt.</div>
        );
    }
};

Dimension.propTypes = {
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
};

export default Dimension;
