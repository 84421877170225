import api from 'api';

/**
 * Palkkalaskelmat.
 * @type {{effects: (function(): {fetchContractPayrolls({jobContract: *}): Promise<*|undefined>}), reducers: {setPayrolls: (function(*, *): *)}, state: *[], selectors: {getPayrolls: (function(*): *)}}}
 */
export const payrolls = {
    state: [],

    reducers: {
        // Valittava TES tai TESsit
        setPayrolls: (state, payload) => payload,
    },

    selectors: {
        getPayrolls: (state) => state,
    },
    effects: () => ({
        async fetchContractPayrolls(jobContract) {
            try {
                const response = await api.get(`/api/v2/contracts/${jobContract}/payrolls`);
                this.setPayrolls(response.data ?? []);
                return response;
            } catch (e) {
                console.error(e);
            }
        },
    })
};

