export const userTypes = {
    /**
     * Suoratyo system user
     */
    SUORATYO_USER: 0,

    /**
     * Yksityisen puolen rekisteröitynyt käyttäjä
     */
    NORMAL_USER: 1,

    /**
     * Käyttäjä joka on lisätty palveluun kolmannen osapuolen toimesta ja ei
     * ole itse kirjautunut. Voi olle esimeriksi kunnan syöttämä käyttäjä tai
     * yrityksen työntekijä.
     */
    PASSIVE_USER: 2,

    /**
     * Passiivinen työntekijä, joka on jo kutsuttu palveluun todennäköisesti käyttäjän
     * yritettyä rekisteröityä olmeassa olevan passiivisen käyttäjän sähköpostilla
     */
    PASSIVE_USER_CONFIRMATION: 5,

    /**
     * Työsopimuksen kautta lisätty uusi käyttäjä.
     * Käyttäjä on väliaikainen, kunnes se rekisteröidään.
     */
    TEMP_USER: 3,

    /**
     * Vanha käyttäjätili, joka on yhdistettu tunnistautumisen yhteydessä toiseen tiliin
     */
    MERGED_TO_OTHER_ACCOUNT: 11,

    /**
     * The user linked to commune
     */
    COMMUNE_SHADOW: 40,

    /**
     * The user working for a commune and using the commune UI
     */
    COMMUNE_OPERATOR: 41,

    /**
     * The user linked to company
     */
    COMPANY_SHADOW: 150,

    /**
     * The user represents trade union
     */
    TRADE_UNION_SHADOW: 160,

    /**
     * Visma Connect Tenant pohjainen operaattorikäyttöä (OPH tai HA)
     */
    TENANT_AWARE_OPERATOR: 200,
};
