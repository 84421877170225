import { useGet } from 'shared/hooks/useApi';

export const useUserMetadata = () => {
    // Käytetään aina kakkua tässä, koska tämä ei todennäköisesti muutu yhden sivunlatatauksen sisällä
    const [{ data, isLoading }] = useGet('/api/v2/user/metadata?endpoints=1', { cachePolicy: 'cache-only' });

    /**
     * Purkattu näin koska muuten ei toimi tilanteessa, jossa käyttäjä käy ensin excel-importeissa,
     * tämän jälkeen jossain muussa massa-actionitabissa ja palaa jälleen excel-importteihin
     * -> jää spinnaamaan
     */
    return data === null ? [data, isLoading] : [data, false];
};
