import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Control } from 'react-redux-form/lib/immutable';
import { Button, Feedback, MDSpinner, Page } from 'shared/components';
import { dimensionReportSelectors, dimensionReportActions } from 'shared/DimensionReport/stores/dimensionReport';
import DimensionSelector from 'shared/containers/Dimension/DimensionSelector';
import DateRangeSelector from 'shared/DimensionReport/components/DateRangeSelector';
import { StLabel } from 'shared/components/StForm';

// huom. tää on aika suora kopio BookkeepReportista TODO: Uudelleen käytetään kirjanpidon uudistukseen eri nimellä
class NewBookkeepReport extends Component {
    static propTypes = {
        isFetchingDimensions: PropTypes.bool,
        selectedDimensions: PropTypes.object,
        dimensions: PropTypes.object,
        dispatch: PropTypes.func,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        isExploded: PropTypes.bool,
        isWithTotals: PropTypes.bool,
        title: PropTypes.string,
        baseRoute: PropTypes.string,
        companyDetails: PropTypes.object,
    };

    static defaultProps = {
        isFetchingDimensions: false,
        selectedDimensions: {},
        dimensions: {},
        dispatch() {},
        startDate: '',
        endDate: '',
        isExploded: false,
        isWithTotals: false,
        title: '',
        baseRoute: '',
        companyDetails: {},
    };

    constructor(props) {
        super(props);
        // Haetaan dimensionit ja resetoidaan varmuuden vuoksi valitut, ettei tule duplikaatteja valittuihin
        props.dispatch(dimensionReportActions.fetchDimensions(false));
        props.dispatch(dimensionReportActions.resetSelectedDimensions());
    }

    generateBookkeepReportLink(format = 'pdf') {
        const {
            startDate,
            endDate,
            isExploded,
            isWithTotals,
        } = this.props;

        const selectedDimensions = this.props.selectedDimensions.toJS();
        let query = '';
        if (selectedDimensions.length > 0) {
            query = Object.keys(selectedDimensions)
                .map((key) => 'dimension[]=' + _.get(selectedDimensions[key], 'name'))
                .join('&');
        }

        function ensureAmpersand() {
            if (query.length > 0) {
                query = query + '&';
            }
        }

        if (isExploded) {
            ensureAmpersand();
            query = query + 'explode=1';
        }
        if (isWithTotals) {
            ensureAmpersand();
            query = query + 'totals=1';
        }
        if (format === 'csv') {
            ensureAmpersand();
            query = query + 'format=csv';
        } else if (format === 'dat') {
            ensureAmpersand();
            query = query + 'format=dat';
        }

        // Raportit sivulla, aikaväliltä rapsa
        return `${Routing.generate('new_bookkeeping_report', { startDate, endDate })}&${query}`;
    }

    renderForm() {
        const { isFetchingDimensions, selectedDimensions, dimensions, dispatch, companyDetails } = this.props;

        // jos löytyy intime asetuksista ensimmäinen avain vois toivoa että muutkin on jo syötetty
        // eli voidaan näyttää intime raportin generointi nappi
        const hasIntimeSettings = companyDetails.intimeSettings?.hasOwnProperty('companyId');
        const hasBookkeepMaterialSettings = !!companyDetails.bookkeepMaterialType;
        const bookkeepMaterialType = companyDetails.bookkeepMaterialType?.toLowerCase() === 'csv' ? 'CSV' : 'DAT';

        if (isFetchingDimensions) {
            return <MDSpinner wrapped />;
        }

        // Formi vain, että tyylit toimivat.
        return (
            <Form className="o-form o-form--vertical" model="dimensionReport">
                <DimensionSelector
                    selectedDimensions={selectedDimensions.toJS()}
                    dimensions={dimensions.toJS()}
                    onSelectDimension={(selectedDimension) =>
                        dispatch(dimensionReportActions.onSelectDimension(selectedDimension))
                    }
                    removeSelectedDimension={(index) =>
                        dispatch(dimensionReportActions.onRemoveSelectedDimension(index))
                    }
                />
                <DateRangeSelector />
                <StLabel>
                    <Control.checkbox model=".exploded" />
                    {_trans('payments.details.bookkeep.exploded')}
                </StLabel>
                <StLabel>
                    <Control.checkbox model=".withTotals" />
                    {_trans('payments.details.bookkeep.with_totals')}
                </StLabel>
                <Button primary href={this.generateBookkeepReportLink('pdf')} target="_blank">
                    {_trans('payments.details.bookkeep.create_report_pdf')}
                </Button>
                <Button
                    href={this.generateBookkeepReportLink('csv')}
                    target="_blank"
                    modifierClass="u-margin-left-tiny"
                >
                    {_trans('payments.details.bookkeep.create_report_csv')}
                </Button>

                { (hasIntimeSettings || hasBookkeepMaterialSettings) &&
                    <Fragment>
                        <Feedback
                            type={'info'}
                            modifierClass="u-margin-small"
                            message={_trans('Kirjanpitomateriaali käyttää valmiita asetuksia. Syötä vain aikaväli miltä haluat materiaalin.', {}, 'export')}
                        />
                        <Button
                            href={this.generateBookkeepReportLink('dat')}
                        >
                            {_trans(`Lataa kirjanpitomateriaali (${bookkeepMaterialType})`, {}, 'export')}
                        </Button>
                    </Fragment>
                }
            </Form>
        );
    }

    render() {
        const { title, baseRoute } = this.props;

        return (
            <Page maxWidth="medium" title={title} goBack={{ to: baseRoute !== '' ? baseRoute : null }}>
                <h2 className="c-heading-headline">{_trans('Kirjanpitoerittely')} v1.1</h2>
                {this.renderForm()}
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    isFetchingDimensions: dimensionReportSelectors.isFetchingDimensions(state),
    dimensions: dimensionReportSelectors.getDimensions(state),
    selectedDimensions: dimensionReportSelectors.getSelectedDimensions(state),
    startDate: dimensionReportSelectors.getStartDate(state),
    endDate: dimensionReportSelectors.getEndDate(state),
    isExploded: dimensionReportSelectors.getIsExploded(state),
    isWithTotals: dimensionReportSelectors.getIsWithTotals(state),
});

export default connect(mapStateToProps)(NewBookkeepReport);
