import { Field, useFormikContext } from 'formik';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { NoForeclosureMonthsFields } from './NoForeclosureMonthsFields';
import { StField, StLabel, StHelp, Feedback, DateInput, RadioList } from 'shared/components';
import { FormikErrors } from 'shared/components/Formik';
import {
    EXPIRES_UNTIL_CHARGED, EXPIRES_UNTIL_DATE, getForeclosureTypeOptions,
    MAX_FORECLOSURE_AMOUNT,
    ONE_THIRD_EMPLOYEE_REFUGE_INCOME
} from 'shared/UserDetails/containers/shared/Foreclosure/constants';

/**
 * Ulosottotiedot ja sen kentät.
 * @returns {JSX.Element}
 * @constructor
 */
export const ForeclosureInformationFields = () => {
    const isCopy = useSelector(select.foreclosure.isCopy);
    const collectedForeclosedAmount = useSelector(select.foreclosure.getCollectedForeclosedAmount);
    const { setFieldValue, values } = useFormikContext();

    return (
        <fieldset>
            <legend>
                {_trans('foreclosure.form.foreclosure.legend')}
            </legend>
            <Feedback type="info">
                {
                    _transMd(`Oima-palvelu laskee ulosmitattavan määrän [Ulosottolaitoksen ohjeen](%url%) mukaisesti. 
                    Syötä alle muut maksukiellolla eritellyt pidätyksiin vaikuttavat tiedot, kuten vapaakuukaudet.`, {
                        url: 'https://ulosottolaitos.fi/fi/index/tietoaulosotosta/tietoavelalliselle/mitenulosmitattavamaaralasketaan.html',
                    }, {
                        targetBlank: true,
                    }, 'extract')
                }
            </Feedback>
            <StLabel>
                {_trans('1/3 pidätys nettotuloista', {}, 'extract')}
            </StLabel>
            <StField>
                <label>
                    <Field
                        type="checkbox"
                        name="isOneThird"
                        onChange={(event) => {
                            setFieldValue('isOneThird', event.target.checked);

                            // Jos käyttäjä valitsee Infinity, asetetaan perittäväksi määräksi Infinity
                            const employeeRefugeIncome = event.target.checked
                                ? ONE_THIRD_EMPLOYEE_REFUGE_INCOME
                                : '';
                            setFieldValue('employeeRefugeIncome', employeeRefugeIncome);
                        }}
                    />
                </label>
            </StField>
            {! values.isOneThird && (
                <Fragment>
                    <StLabel isRequired htmlFor="employeeRefugeIncome">
                        {_trans('foreclosure.form.foreclosure.debtor_protection_share_per_day.label')}
                    </StLabel>
                    <StField>
                        <Field
                            type="text"
                            id="employeeRefugeIncome"
                            name="employeeRefugeIncome"
                            size={5}
                            inputMode="decimal"
                        />
                        <FormikErrors name="employeeRefugeIncome" />
                    </StField>
                </Fragment>
            )}

            <StLabel htmlFor="otherReceivedIncome" tooltip={_trans('Muulta maksajalta saadun tulon nettomäärä. Tämä on yleensä eriteltynä maksukiellolla sellaisissa tapauksissa joita koskettaa.', {}, 'extract')}>
                {_trans('foreclosure.form.foreclosure.other_net_income_per_month.label')}
            </StLabel>
            <StField>
                <Field
                    type="text"
                    id="otherReceivedIncome"
                    name="otherReceivedIncome"
                    size={5}
                    inputMode="decimal"
                />
                <FormikErrors name="otherReceivedIncome" />
            </StField>

            <StLabel>
                {_trans('foreclosure.form.foreclosure.validity.label')}
            </StLabel>
            <StField>
                <RadioList
                    name="foreclosureType"
                    valueKey="value"
                    labelKey="label"
                    value={values.foreclosureType}
                    items={getForeclosureTypeOptions()}
                    onChange={(ev) => {
                        const foreclosureType = parseInt(ev.target.value);
                        setFieldValue('isIndefiniteAmount', foreclosureType !== EXPIRES_UNTIL_CHARGED);
                        setFieldValue('foreclosureType', foreclosureType);
                        setFieldValue(
                            'foreclosureAmount',
                            foreclosureType === EXPIRES_UNTIL_CHARGED
                                ? ''
                                : MAX_FORECLOSURE_AMOUNT
                        );
                        if (foreclosureType !== EXPIRES_UNTIL_DATE) {
                            setFieldValue('endDate', '');
                        }
                    }}
                />
            </StField>

            {/*Jos ei ole voimassa toistaiseksi pyydetään antamaan summa jonka jälkeen ulosotto lakkaa*/}
            {values.foreclosureType === EXPIRES_UNTIL_CHARGED && (
                <Fragment>
                    <StLabel isRequired>
                        {_trans('foreclosure.form.foreclosure.expires_until_charged.label')}
                    </StLabel>
                    <StField>
                        <Field
                            type="text"
                            inputMode="decimal"
                            size={6}
                            name="foreclosureAmount"
                        />
                        <FormikErrors name="foreclosureAmount" />
                        {/*Näytetään vain jos peritty summa yli 0 eikä ole kopio (koska kopioitaessa peritty summa "resetoituu")*/}
                        { (collectedForeclosedAmount > 0 && !isCopy) && (
                            <div className="o-help">
                                {_transMd('foreclosure.form.foreclosure.expires_until_charged.tip', {
                                    amount: _currency(collectedForeclosedAmount),
                                })}
                            </div>
                        )}
                    </StField>
                </Fragment>
            )}

            {values.foreclosureType === EXPIRES_UNTIL_DATE && (
                <Fragment>
                    <StLabel isRequired>
                        {_trans('Päättymispäivämäärä', {}, 'extract')}
                    </StLabel>
                    <StField>
                        <DateInput
                            key="endDate"
                            type="text"
                            value={values.endDate}
                            onChange={(value) => setFieldValue('endDate', value)}
                            placeholder={_trans('Päivämäärä', {}, 'extract')}
                            gridClasses="one-third"
                            className="one-whole"
                            name="endDate"
                            required={values.foreclosureType === EXPIRES_UNTIL_DATE}
                            size={12}
                            maxLength={10}
                            validations="isDate"
                        />
                    </StField>
                </Fragment>
            )}

            <StLabel htmlFor="employeeRefugeIncomeExtra" tooltip={_trans('Ulosottoon tilitettävästä rahamäärästä velalliselle jätettävä euromäärä/kk.', {}, 'extract')}>
                {_trans('foreclosure.form.foreclosure.debtor_extra_income.label')}
            </StLabel>
            <StField>
                <Field
                    type="text"
                    inputMode="numeric"
                    id="employeeRefugeIncomeExtra"
                    name="employeeRefugeIncomeExtra"
                    aria-labelledby="debtorExtraIncome"
                    size={5}
                />
                <FormikErrors name="employeeRefugeIncomeExtra" />
                <StHelp id="debtorExtraIncome">
                    {_trans('foreclosure.form.foreclosure.debtor_extra_income.help')}
                </StHelp>
            </StField>

            <StLabel htmlFor="maxForeclosurePerMonth">
                {_trans('foreclosure.form.foreclosure.max_foreclosure_per_month.label')}
            </StLabel>
            <StField>
                <Field
                    type="text"
                    inputMode="numeric"
                    id="maxForeclosurePerMonth"
                    name="maxForeclosurePerMonth"
                    size={5}
                />
                <FormikErrors name="maxForeclosurePerMonth" />
            </StField>

            <NoForeclosureMonthsFields />
        </fieldset>
    );
};

ForeclosureInformationFields.propTypes = {
    /**
     * Tähän mennessä peritty määrä.
     */
    foreclosedAmount: PropTypes.number.isRequired,
};

