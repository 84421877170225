export const userStates = {
    /**
   * Inaktiivinen käytäjä
   */
    INACTIVE: 0,

    /**
   * Aktiivinen normaali käyttäjä
   */
    ACTIVE: 1,

    /**
   * User account registration is in progress.
   * User need to set password through unique url received from E-mail
   */
    CONFIRMATION: 10,

    /**
   * User account has been suspended (mostly because user selected the it wasn't my email link
   */
    SUSPENDED: 20,

    /**
   * The user account has been closed down. Can happen when we merge former lite and new user together
   * during tupas phase.
   */
    CLOSED: 30,

    /**
   * Käyttäjälle asetettu sosiaaliturvatunnus ei täsmännyt tupastunnistuksessa.
   * Käyttäjä on lukittu
   */
    SSN_MISMATCH: 40,

    /**
   * Käyttäjä lukittu admin toimesta
   */
    LOCKED: 90,

    /**
   * Käyttäjä lukittu kieltolistan mukaan
   */
    LOCKED_PAY_FORBIDDANCE: 91
};

export default userStates;
