import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Attachment } from 'shared/containers/Attachment';

/**
 * Valitut liitteet.
 * @returns {JSX.Element}
 * @constructor
 */
const Attachments = ({ value }) => {
    const contractData = useSelector(select.contract.getContractDataId);
    if (value.length === 0) {
        return (
            <div className="u-margin-bottom-small">
                {_trans('Ei liitteitä.', {}, 'common')}
            </div>
        );
    }

    return (
        <ul className="o-list-bare u-margin-bottom-none u-visibility-screen" aria-label={_trans('Liitelista', {}, 'jobContract')}>
            {value.map((attachment, key) => (
                <li
                    key={key}
                    className="o-list-bare__item u-display-inline-block u-margin-right-small u-margin-bottom-small"
                    style={{ maxWidth: 320 }}
                >
                    <Attachment
                        attachment={attachment}
                        isRemovable={false}
                        contractData={contractData}
                    />
                </li>
            ))}
        </ul>
    );
};

Attachments.propTypes = {
    value: PropTypes.array.isRequired,
};

export default Attachments;
