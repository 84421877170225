export const contractStates = {
    /**
     * The contract is open and not sent to employee
     */
    OPEN: 1,

    /**
     * The contract has been sent to employee and is waiting for acceptance
     */
    PENDING: 2,

    /**
     * Accepted by both parties
     */
    ACCEPTED: 3,

    /**
     * Contract was rejected
     */
    REJECTED: 4,

    EDITED: 5,

    /**
     * Contract has been terminated (it's over)
     */
    TERMINATED: 6,

    /**
     * Purettu sopimus
     */
    DISSOLVED: 66,

    /**
     * Same as OPEN but for unknown employee
     */
    OPEN_TYPE_B: 7,

    /**
     * Same as PENDING but for unknown employee
     */
    PENDING_TYPE_B: 8,

    /**
     * Same as REJECTED but for unknown employee
     */
    REJECTED_TYPE_B: 9,

    /**
     * Lite contract (Pikapalkka)
     */
    LITE: 20,

    /**
     * Open contract created by commune (not visible to employer or employee yet)
     */
    OPEN_COMMUNE: 57,

    /**
     * Keskeneräinen
     */
    UNFINISHED: 98,

    /**
     * Open contract created by Employer (visible for employer and commune)
     */
    EMPLOYER_PROPOSAL: 11
};

const contractStateMap = {
    [contractStates.OPEN]: _trans('job_contract.state.open'),
    [contractStates.OPEN_TYPE_B]: _trans('job_contract.state.open'),
    [contractStates.PENDING]: _trans('job_contract.state.pending'),
    [contractStates.PENDING_TYPE_B]: _trans('job_contract.state.pending'),
    [contractStates.ACCEPTED]: _trans('job_contract.state.accepted'),
    [contractStates.EDITED]: _trans('job_contract.state.edited'),
    [contractStates.TERMINATED]: _trans('job_contract.state.terminated'),
    [contractStates.DISSOLVED]: _trans('job_contract.state.dissolved'),
    [contractStates.LITE]: _trans('job_contract.state.terminated'),
    [contractStates.EMPLOYER_PROPOSAL]: _trans('Työnantajan luonnos', {}, 'jobContract'),

    [contractStates.REJECTED_TYPE_B]: _trans('job_contract.state.rejected'),
    [contractStates.REJECTED]: _trans('job_contract.state.rejected'),
    [contractStates.UNFINISHED]: _trans('Keskeneräinen', {}, 'jobContract'),
};

export function translateContractState(contractState) {
    return contractState in contractStateMap ? contractStateMap[contractState] : _trans('job_contract.state.unknown');
}

const contractUiState = {
    [contractStates.OPEN]: 'neutral',
    [contractStates.OPEN_TYPE_B]: 'neutral',
    [contractStates.PENDING]: 'yellow',
    [contractStates.PENDING_TYPE_B]: 'yellow',
    [contractStates.ACCEPTED]: 'positive',
    [contractStates.REJECTED]: 'negative',
    [contractStates.EDITED]: 'neutral',
    [contractStates.TERMINATED]: 'hollow',
    [contractStates.DISSOLVED]: 'hollow',
    [contractStates.LITE]: 'hollow',
    [contractStates.UNFINISHED]: 'neutral',
    [contractStates.EMPLOYER_PROPOSAL]: 'neutral',
};

export function mapContractStateToUi(contractState) {
    return contractState in contractUiState ? contractUiState[contractState] : '';
}
