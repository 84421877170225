import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { select } from '@rematch/select';
import { useSelector } from 'react-redux';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { Placeholder } from 'shared/components';
import UserBlock from 'shared/components/UserBlock';

/**
 * Asiakas. Esim. tuettava. Myös useamman hoidettavan kohdalla käytetään tätä.
 * @param value
 * @param isSsnVisible
 * @returns {JSX.Element}
 * @constructor
 */
const OtherCustomer = ({ value, label, attributes: { isSsnVisible } }) => {
    const isLoading = useSelector((state) => (
        state.loading.effects.contract.fetchContract
        || state.loading.effects.customers.fetchCustomers
        || state.loading.effects.userMetadata.fetchUserMetadata
    ));

    const isEmployee = useSelector(select.userMetadata.isEmployee);
    const customers = useSelector(select.customers.getCustomers);
    const customerId = parseInt(value, 10);
    const customer = useMemo(() => {
        if (isLoading || isNaN(customerId)) return [];

        return customers.find((customer) => customer?.userId === customerId);
    }, [isLoading, customers, customerId]);

    const details = [
        [customer?.fullAddress],
        [customer?.phoneNumber],
    ]
        .filter(Boolean)
        .filter((detail) => detail.toString().trim() !== '');

    return (
        <SummaryListItem term={label}>
            <Placeholder isPending={isLoading}>
                {/*TODO: User/constants/routes.js refaktorointi => exporttaa vain routet ulos, ei komponentteja*/}
                <UserBlock
                    user={customer}
                    size="small"
                    relationType="customer"
                    isSsnVisible={isSsnVisible}
                    hasLinkToDetails={! isEmployee}
                />
                {details.map((detail, key) => (
                    <Fragment key={key}>
                        {detail}<br/>
                    </Fragment>
                ))}
            </Placeholder>
        </SummaryListItem>
    );
};

OtherCustomer.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    attributes: PropTypes.object.isRequired,
};

export default OtherCustomer;
