import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Value } from 'shared/components/Value';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';

/**
 * Hoitomuodon valitsin jos useampi palkkio.
 * @constructor
 */
const SalaryDimensionType = (props) => {
    const { value, values, label } = props;
    const salaries = values[fieldNames.SALARIES];

    const salaryTypeDimension = useSelector(select.dimensions.findSalaryTypeDimension);
    const showDimensionSelector = salaries.length > 1 && !!salaryTypeDimension;

    // Ei useampaa palkkiota joten ei renderöidä
    if (! showDimensionSelector) return null;

    const dimensions = salaryTypeDimension?.dimensions ?? [];
    const dimension = dimensions.find((dimension) => dimension.id === parseInt(value, 10));

    return (
        <SummaryListItem term={label}>
            <Value>
                {`${dimension?.value ?? '-'} - ${dimension?.description ?? '-'}`}
            </Value>
        </SummaryListItem>
    );
};

SalaryDimensionType.propTypes = {
    value: PropTypes.number,
    values: PropTypes.object.isRequired,
    label: PropTypes.string,
};

SalaryDimensionType.defaultProps = {
    value: null,
    label: '',
};

export default SalaryDimensionType;
