/**
 * Näppäinkoodit.
 */
const eventKeys = {
    ARROW_LEFT: 'ArrowLeft',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_UP: 'ArrowUp',
    ARROW_DOWN: 'ArrowDown',
    ENTER: 'Enter',
    TAB: 'Tab',
    BACKSPACE: 'Backspace',
};

export const eventKeyCodes = {
    ESC: 27,
    SPACE: 32,
};

export default eventKeys;

export const CURSOR_KEYS = [
    eventKeys.ARROW_LEFT,
    eventKeys.ARROW_RIGHT,
    eventKeys.ARROW_UP,
    eventKeys.ARROW_DOWN,
];
