import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import DateInput from 'shared/components/DateInput';
import { hasFormikErrors } from 'shared/utils/formUtils';

/**
 * Tyhjä date = undefined. Muutoin ei mene Yup:n validaatiosta läpi.
 * @param value
 * @returns {undefined|string}
 */
const getValue = (value) => (
    typeof value === 'string' && value.trim() === ''
        ? undefined
        : value
);

/**
 * Yksittäinen päivämääräkenttä.
 * @param name
 * @param onChange
 * @param validate
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const DateInputField = ({ name, onChange, validate, ...rest }) => {
    const [field, meta, helpers] = useField(validate ? { name, validate } : name);

    return (
        <DateInput
            {...rest}
            {...field}
            // Jos kentällä on virheitä kerrotaan myös ruudunlukijoille
            hasErrors={hasFormikErrors(meta)}
            onChange={(value) => {
                const transformedValue = getValue(value);
                helpers.setTouched(true);
                helpers.setValue(transformedValue);
                onChange(transformedValue);
            }}
        />
    );
};

DateInputField.propTypes = {
    name: PropTypes.string,
    validate: PropTypes.func,
    onChange: PropTypes.func,
};

DateInputField.defaultProps = {
    name: '',
    validate() {},
    onChange() {},
};
