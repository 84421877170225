import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { DateInputField, FormField } from 'shared/components/Formik';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { isValidDate } from 'shared/utils/dateUtils';

/**
 * Työsuhteen alkupäivä.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const OriginalStartDate = (props) => {
    const { name, placeholder } = props;

    const [startDateField] = useField(fieldNames.START_DATE);
    const startDate = new Date(startDateField.value);
    const isAcceptedContract = useSelector(select.contract.isAcceptedContract);

    // Alkuperäinen aloituspäivä ei voi hyväksytyillä soppareilla ylittää Oima-palvelun käyttöönottopäivää
    // koska käyttöönottopäivää ei voi muuttaa jos hyväksytty soppari koska alkuperäisen aloituspäivän
    // ylittäessä käyttöönottopäivän nollautuisi startDate
    const maximumDate = (startDateField.value && isValidDate(startDate) && isAcceptedContract)
        ? startDate
        : null;

    // Hyväksytyn sopimuksen alkuperäistä aloituspäivää ei voi enää muuttaa
    // TODO: Mietitään vielä
    /*if (isAcceptedContract) {
        return (
            <FormField {...getFieldProps(props)} isContentFormField={false}>
                <Value>
                    {_toLocaleDate(originalStartDate)}
                </Value>
            </FormField>
        );
    }*/

    return (
        <FormField {...getFieldProps(props)}>
            <DateInputField
                name={name}
                placeholder={placeholder}
                maximumDate={maximumDate}
            />
        </FormField>
    );
};

OriginalStartDate.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
};

OriginalStartDate.defaultProps = {
    placeholder: _trans('Työsuhteen alkuperäinen aloituspäivä', {}, 'jobContract')
};

export default OriginalStartDate;
