import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import MDIcon from 'shared/components/MDIcon';
import { signatureTypes } from 'shared/constants/signatureTypes';

/**
 * "TA":n aiempi allekirjoitus.
 * @returns {JSX.Element}
 * @constructor
 */
export const PreviousEmployerSignature = ({ isEmployerNotUsingOima, employerSignatureType }) => {
    const signature = useSelector((state) => select.contract.getPreviousEmployerSignature(state, employerSignatureType));

    const signerDetails = signature?.signerDetails;
    const signTime = _toLocaleDate(signature?.signTime, '', _dateTimeFormat);

    if (isEmployerNotUsingOima && employerSignatureType === signatureTypes.TYPE_EMPLOYER_SPECIFIC) {
        return (
            <div className="o-form__text">
                <MDIcon icon="check_circle" size="small" modifierClass="u-color-positive u-align-middle u-margin-right-tiny" isFilled />
                <strong>{signerDetails?.fullName}</strong>{'. '}
                {_trans('Allekirjoitus sopimuksen paperisessa versiossa.', {}, 'jobContract')}{' '}
                <span className="u-align-middle">
                    {_transMd('%signer% vahvisti nähneensä paperisen sopimuksen **%signTime%** ja arkistoineensa sen.', {
                        signer: signature?.verifiedByName ?? '-',
                        signTime,
                    }, 'jobContract')}
                </span>
            </div>
        );
    }

    return (
        <div className="o-form__text">
            <MDIcon icon="check_circle" size="small" modifierClass="u-color-positive u-align-middle u-margin-right-tiny" isFilled />
            <span className="u-align-middle">
                {_transMd(_trans('**%signer%** allekirjoitti **%signTime%**', {
                    signer: signerDetails?.fullName ?? '',
                    signTime,
                }, 'jobContract'))}
            </span>
        </div>
    );
};

PreviousEmployerSignature.propTypes = {
    isEmployerNotUsingOima: PropTypes.bool,
    employerSignatureType: PropTypes.string,
};

PreviousEmployerSignature.defaultProps = {
    // Hihii tuplanegaatiot on kivoja
    isEmployerNotUsingOima: false,
    employerSignatureType: signatureTypes.TYPE_EMPLOYER_SPECIFIC,
};
