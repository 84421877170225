import React from 'react';
import PropTypes from 'prop-types';
import { Value } from 'shared/components/Value';

const PartTimePercentage = ({ value }) => (
    <Value>{value} %</Value>
);

PartTimePercentage.propTypes = {
    value: PropTypes.string.isRequired,
};

export default PartTimePercentage;
