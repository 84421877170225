import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _map from 'lodash/map';
import { sizes, alignTypes } from 'shared/constants';

const Layout = {
    Container: ({
        children,
        reverse,
        gutterSize,
        verticalGutter,
        center,
        stretch,
        modifierClass,
    }) => {
        const layoutClass = classNames('o-layout', {
            'o-layout--flush': gutterSize === sizes.NONE,
            'o-layout--tiny': gutterSize === sizes.TINY,
            'o-layout--small': gutterSize === sizes.SMALL,
            'o-layout--medium': gutterSize === sizes.MEDIUM,
            'o-layout--large': gutterSize === sizes.LARGE,
            'o-layout--huge': gutterSize === sizes.HUGE,

            'o-layout--reverse': reverse,
            'o-layout--vertical-gutter': verticalGutter,
            'o-layout--center': center,
            'o-layout--stretch': stretch,


            [modifierClass]: modifierClass != ''
        });

        return (
            <div className={layoutClass}>
                {children}
            </div>
        );
    },

    Item: ({
        children,
        tiny,
        small,
        medium,
        large,
        huge,
        align,
        offset,
        isHidden,
        alwaysVisibleAt,
        modifierClass,
    }) => {
        const layoutItemClass = classNames('o-layout__item', {
            ['u-' + tiny]: tiny !== '',
            ['u-' + small + '@small']: small !== '',
            ['u-' + medium + '@medium']: medium !== '',
            ['u-' + large + '@large']: large !== '',
            ['u-' + huge + '@huge']: huge !== '',
            ['u-push-' + offset]: offset !== '',

            'u-hide': isHidden,

            'o-layout--left': align === alignTypes.LEFT,
            'o-layout--right': align === alignTypes.RIGHT,

            ['u-display-inline-block@' + alwaysVisibleAt]: alwaysVisibleAt != '',

            [modifierClass]: modifierClass != ''
        });

        return (
            <div className={layoutItemClass}>
                {children}
            </div>
        );
    }
};

Layout.Container.defaultProps = {
    reverse: false,
    gutterSize: 'medium',
    verticalGutter: false,
    center: false,
    stretch: false,
    modifierClass: '',
};

Layout.Container.propTypes = {
    children: PropTypes.any.isRequired,
    reverse: PropTypes.bool,
    gutterSize: PropTypes.oneOf(_map(sizes)),
    verticalGutter: PropTypes.bool,
    center: PropTypes.bool,
    modifierClass: PropTypes.string,
    stretch: PropTypes.bool,
};

Layout.Item.defaultProps = {
    tiny: '',
    small: '',
    medium: '',
    large: '',
    huge: '',

    align: '',
    offset: '',
    isHidden: false,
    alwaysVisibleAt: '',
    modifierClass: ''
};

Layout.Item.propTypes = {
    children: PropTypes.node,
    width: PropTypes.string,
    mobile: PropTypes.string,
    tablet: PropTypes.string,
    desktop: PropTypes.string,
    wide: PropTypes.string,

    align: PropTypes.oneOf(['', alignTypes.LEFT, alignTypes.RIGHT]),
    offset: PropTypes.string,
    isHidden: PropTypes.bool,
    alwaysVisibleAt: PropTypes.string,
    modifierClass: PropTypes.string
};

export default Layout;