import React from 'react';
import { connect } from 'formik';
import PropTypes from 'prop-types';
import { ChoiceList } from 'shared/components';

const FormikChoiceList = (props) => {
    const { name, formik, isReadOnly } = props;
    const { setFieldValue, values } = formik;
    return (
        <ChoiceList
            {...props}
            onChange={(value) => setFieldValue(name, value)}
            value={_.get(values, name)}
            hasSingleInput
            readOnly={isReadOnly}
        />
    );
};

FormikChoiceList.propTypes = {
    name: PropTypes.string.isRequired,
    isReadOnly: PropTypes.bool,
    formik: PropTypes.object,
};

FormikChoiceList.defaultProps = {
    formik: {},
    isReadOnly: false,
};

export default connect(FormikChoiceList);
