import React, { useContext } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { animated, Transition } from 'react-spring/renderprops.cjs';
import DOMPurify from 'dompurify';
import { NotificationContext } from './notifications';
import { Notification } from 'shared/components';

export const NotificationList = () => {
    const { notifications, removeNotification } = useContext(NotificationContext);

    // Varmistetaan että notifikaatiot varmasti näkyvät vasta ylävalikon alla.
    const top = document.getElementById('root').offsetTop;

    return (
        <div
            className={classNames('c-notifications o-pin o-pin--fixed o-pin--right u-margin-top-small u-margin-right-small', {
                'u-pointer-events-none': _.size(notifications) === 0,
            })}
            style={{ top }}
        >
            <ul className="c-notification-list o-list-bare u-margin-bottom-none">
                <Transition
                    native
                    items={notifications}
                    keys={(notification) => notification.id}
                    from={{
                        opacity: 0,
                        marginTop: 0,
                        height: 0,
                    }}
                    leave={{
                        opacity: 0,
                        marginTop: 0,
                        height: 0,
                    }}
                    enter={{
                        opacity: 1,
                        marginTop: 12,
                        height: 'auto',
                    }}
                >
                    {/* eslint-disable-next-line react/display-name */}
                    {(notification) => (style) => (
                        <animated.li style={style}>
                            <Notification
                                type={notification.type}
                                timeout={notification.timeout}
                                isDismissable={notification.isDismissable}
                                onTimeout={() => removeNotification(notification.id)}
                            >
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notification.message) }} />
                            </Notification>
                        </animated.li>
                    )}
                </Transition>
            </ul>
        </div>
    );
};
