import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { userTypes } from 'shared/constants/userTypes';
import Avatar from 'shared/components/Avatar';
import userShapes from 'shared/shapes/userShapes';
import { sizes } from 'shared/constants';

/**
 * Käyttäjän kuva. Jos kuvaa ei löydy näytetään placeholder.
 * HUOM: olettaa user-objektin olevan User-entityn kaltainen!
 * @param props
 * @returns {*}
 * @constructor
 */
const UserAvatar = (props) => {
    const {
        user,
        size,
        hasLastNameFirst,
        ...rest
    } = props;

    // Kumpi näytetään ensiksi: etunimi vai sukunimi?
    const firstPiece = hasLastNameFirst ? 'lastName' : 'firstName';
    const lastPiece = hasLastNameFirst ? 'firstName' : 'lastName';
    const fullName = `${_.get(user, firstPiece, '')} ${_.get(user, lastPiece, '')}`;

    return (
        <Avatar
            src={user.picture_path ? Routing.generate('liip_imagine_filter', { filter: 'profile_thumb', path: user.picture_path }) : null}
            size={size}
            fullName={fullName.trim() !== '' ? fullName : _.get(user, 'fullName', '')}
            initials={_.get(user, 'initials', '')}
            // Yrityksellä näytetään vain ikoni
            isCompany={user.type === userTypes.COMPANY_SHADOW}
            {...rest}
        />
    );
};

UserAvatar.propTypes = {
    user: PropTypes.oneOfType([
        userShapes.MINIMAL,
        userShapes.MEDIUM
    ]).isRequired,
    size: PropTypes.oneOf(_.map(sizes)),
    hasLastNameFirst: PropTypes.bool,
};

UserAvatar.defaultProps = {
    size: 'medium',
    hasLastNameFirst: true,
};

export default UserAvatar;
