import { inputTypes, choiceInputTypes } from 'shared/ReForm/constants/inputTypes';
import { blocks } from 'shared/ReForm/constants/blocks';
import { customComponents, customComponentNames, componentsWithNames, componentsWithRequiredNames } from 'ContractV3/constants/customComponents';
import { mergeSchema } from 'shared/ReForm/utils/mergeSchema';
import { componentSchema } from 'shared/ReForm/schemas/blocks/component.schema';

/**
 * Tietyt vaadittavat schemat per komponentti.
 * Voidaan vaikka vaatia tietty name-notaatio (esim. Dimensio-komponentilla).
 * @param componentName
 */
const componentBuilder = (componentName) => {
    const customComponent = customComponents[componentName];
    const { schema } = customComponent;
    const { properties } = mergeSchema(schema);
    return {
        if: {
            type: 'object',
            properties: {
                block: { const: blocks.INPUT },
                type: { const: inputTypes.COMPONENT },
                component: { const: componentName }
            },
            required: ['block', 'type', 'component'],
        },
        then: {
            type: 'object',
            properties,
            required: ['block', 'type', 'component'],
        }
    };
};

/**
 * Yleinen syötepalikka. Text, textarea, autocomplete, jne.
 */
export const inputSchema = {
    // $id: '#/inputSchema#',
    title: 'Syöttökenttä',
    properties: Object.assign({}, componentSchema.properties, {
        name: {
            title: 'Kentän yksilöivä nimi',
            type: 'string'
        },
        block: { const: blocks.INPUT },
        type: {
            title: 'Palikan tyyppi',
            enum: Object.values(inputTypes),
        },
        // Numero/string
        defaultValue: {
            title: 'Oletusarvo',
            oneOf: [
                {
                    type: 'string',
                },
                {
                    type: 'boolean',
                },
                {
                    type: 'object',
                }
            ],
        },
        // Määrittää sen voiko oletusarvo muuttua käyttäjän toimesta
        isValueFixed: {
            title: 'Kentän arvoa ei voi vaihtaa',
            description: 'Jos kentän arvoa ei voi vaihtaa näytetään se pelkkänä tekstinä',
            type: 'boolean',
        },
        /*validationType: {
            title: 'Mitä tyyppiä validoitava arvo on',
            description: 'Tyyppi määrää millaisia tarkistuksia arvolle voidaan tehdä.',
            type: { enum: ['string', 'number', 'date', 'array', 'object'] },
            // Oletuksena aina string
            // default: 'string',
        },*/
        validations: {
            title: 'Validointi',
            description: 'Millaisten tarkistusten läpi kenttä on validoitava.',
            type: 'array',
            items: {
                anyOf: [
                    { type: 'object' },
                    { type: 'string' }
                ],
            },
            // additionalProperties: false,
        },
        options: {
            '$ref': '#/definitions/options'
        },
        inputLabel: {
            title: 'Kytkimen otsikko',
            '$ref': '#/definitions/translation',
        },
        inputPostfix: {
            title: 'Teksti kentän lopussa',
            '$ref': '#/definitions/translation',
            description: 'Esim. 2 pv / kk',
        },
    }),
    additionalProperties: false,
    required: ['block', 'type'],
    allOf: [
        {
            // Jos typenä jokin choiceInputTypeistä vaadi myös options-property
            if: {
                properties: {
                    type: { enum: choiceInputTypes },
                },
                required: ['type']
            },
            then: {
                required: ['block', 'type', 'options', 'name'],
            },
            else: {
                not: { required: ['options'] }
            }
        },
        {
            if: {
                properties: {
                    type: { const: inputTypes.SWITCH },
                },
                required: ['type']
            },
            then: {
                required: ['block', 'type', 'inputLabel', 'name'],
            },
            else: {
                not: { required: ['inputLabel'] }
            }
        },
        {
            if: {
                properties: {
                    type: { enum: [inputTypes.DECIMAL, inputTypes.INTEGER, inputTypes.CURRENCY, inputTypes.TEXT] },
                },
                required: ['type']
            },
            else: {
                not: { required: ['inputPostfix'] }
            }
        },
        {
            // Jos typenä component vaadi myös component-property.
            if: {
                properties: {
                    type: { const: inputTypes.COMPONENT },
                    component: {
                        not: {
                            enum: componentsWithRequiredNames,
                        }
                    }
                },
                required: ['type']
            },
            then: {
                required: ['block', 'type', 'component'],
                not: { required: ['name'] }
            },
        },
        {
            // Jos customkomponentille on jo annettu hardkoodattu nimi älä vaadi sitä
            if: {
                properties: {
                    type: { const: inputTypes.COMPONENT },
                    component: {
                        enum: componentsWithRequiredNames,
                    }
                },
                required: ['type', 'component']
            },
            then: {
                required: ['block', 'type', 'component', 'name'],
            },
            /*else: {
                required: ['block', 'type'],
                not: { required: ['name'] }
            }*/
        },
        {
            // Jos customkomponentille on jo annettu hardkoodattu nimi älä vaadi sitä
            if: {
                properties: {
                    type: { const: inputTypes.COMPONENT },
                    component: { enum: componentsWithNames }
                },
                required: ['type', 'component']
            },
            then: {
                required: ['block', 'type', 'component'],
                not: { required: ['name'] }
            },
            else: {
                required: ['block', 'type', 'name'],
            }
        },
    ].concat(customComponentNames
        .filter((customComponentName) => {
            // Filtteröidään pois ne customit joilla ei ole schemaa ja array-komponentit
            const customComponent = customComponents[customComponentName];
            const hasSchemaProperties = customComponent.schema?.properties;
            const hasArrayOfBlocks = customComponent.blocks;

            return hasSchemaProperties && !hasArrayOfBlocks;
        })
        .map((customComponentName) => componentBuilder(customComponentName))
    )
};
