import React from 'react';
import PropTypes from 'prop-types';
import Page from './Page';

/**
 * Geneerinen 404-sivu.
 * @param children
 * @returns {*}
 * @constructor
 */
export const PageNotFound = ({ children }) => {
    let bodyKey = 'errors.404.body';

    // Jos on annettu itse sisältöä näytetään alussa vain tiivistetty 404-viesti.
    if (React.Children.count(children)) {
        bodyKey = 'errors.404.page_not_found_message';
    }

    return (
        <Page>
            <div className="u-text-center">
                <svg width="116" height="285" viewBox="0 0 116 285" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <path id="a" d="M43 12h30v14H43z"/>
                    </defs>
                    <g fill="none" fillRule="evenodd">
                        <path d="M58 282.5c46.284 0 53-.672 53-1.5s-23.729-1.5-53-1.5-53 .672-53 1.5 6.716 1.5 53 1.5z" fill="#F2F2F2"/>
                        <path fill="#CCC" d="M53 116h10v164.506H53z"/>
                        <path d="M63 160.751V116H53v44.751c1.634.164 3.303.249 5 .249s3.366-.085 5-.249z" fill="#C1C1C1"/>
                        <rect fill="#2874BD" width="116" height="158" rx="6"/>
                        <rect stroke="#FFF" strokeWidth="3" x="2.5" y="118.483" width="111" height="37" rx="6"/>
                        <text fontFamily="ProximaNova-Semibold, Proxima Nova" fontSize="14" fontWeight="500" fill="#FFF">
                            <tspan x="44.561" y="141.5">404</tspan>
                        </text>
                        <rect stroke="#FFF" strokeWidth="3" x="2.5" y="2.5" width="111" height="111" rx="6"/>
                        <path fill="#FFF" d="M55 25h6v80h-6z"/>
                        <use fill="#EF3E42" xlinkHref="#a" />
                        <path stroke="#FFF" strokeWidth="2" d="M44 13h28v12H44z"/>
                    </g>
                </svg>
                <br />
                <h2 className="c-heading-headline">
                    {_trans('errors.404.heading')}
                </h2>
                {_trans(bodyKey)}
                {children}
            </div>
        </Page>
    );
};

PageNotFound.propTypes = {
    /**
     * Mahdollinen itse lisätty sisältö. Esim. linkkilista sivuihin jotka löytyy.
     */
    children: PropTypes.node,
};

PageNotFound.defaultProps = {
    children: null,
};
